import React from "react";

import Heading from "core/Heading";

import { StyledSearchEmptyResults } from "./styles";
import colors from "core/colors";

const SearchEmptyResults = ({ contentType, query }) => {
    return (
        <StyledSearchEmptyResults>
            <Heading
                type="h3"
                size="xl"
                alignment="center"
                headingColor={colors.secondary.oolong}
            >{`We didn’t find any ${contentType}s for "${query}"`}</Heading>
        </StyledSearchEmptyResults>
    );
};

export default SearchEmptyResults;
