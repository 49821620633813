import React from "react";
import PropTypes from "prop-types";

// styles
import { ActionBarStyled } from "./styles.js";

// exposed components
export { ActionBarLeft } from "./ActionBarLeft";
export { ActionBarRight } from "./ActionBarRight";
export { SelectAllAndMessaging } from "./SelectAllAndMessaging";

const ActionBar = ({ children }) => {
    return <ActionBarStyled>{children}</ActionBarStyled>;
};

export default ActionBar;

// prop-types
ActionBar.propTypes = {
    children: PropTypes.any
};
