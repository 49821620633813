import { actionTypes } from "./actionTypes";
import { DEBUG } from "../index";

/*---------------------------
| Reducer
---------------------------*/
export const reducer = (state, action) => {
    const { type } = action;

    if (DEBUG) {
        console.group("ManageTagsContext");
        console.log({ actionType: type });
        console.log({ action });
        console.groupEnd();
    }

    switch (type) {
        case actionTypes.SET_INPUT_TEXT: {
            return { ...state, inputText: action.inputText };
        }
        case actionTypes.SET_SHOW_SUGGESTION_DROP_DOWN: {
            return {
                ...state,
                showSuggestionDropDown: action.showSuggestionDropDown
            };
        }
        case actionTypes.SET_ENABLED: {
            return {
                ...state,
                enabled: { ...state.enabled, ...action.enabled }
            };
        }
        case actionTypes.SET_ERROR_MESSAGE: {
            return { ...state, errorMessage: action.errorMessage };
        }
        default: {
            return { ...state };
        }
    }
};
