import React, { useContext, useState, useEffect, useRef } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { medium } from "core/fontStyles";

// context
import MessageStackContext from "core/MessageStack/context";
import * as Actions from "core/MessageStack/context/actions";

// styles
import {
    config,
    MessageWrapperStyled,
    MessageWrapperInnerStyled,
    MessageStyled,
    TextStyled
} from "./styles";

const Title = styled.h3`
    ${medium}
`;

const Message = ({ message, placeInLine }) => {
    const { dispatch } = useContext(MessageStackContext);
    const [messageLife, messageLifeUpdate] = useState("mounting");
    const [timerRunning, timerRunningUpdate] = useState(false);

    const {
        id,
        text,
        timed,
        timeout,
        infoType = "error",
        title = null,
        darkMode = false
    } = message;

    let timer = useRef();

    const onDismiss = e => {
        e.stopPropagation();
        exitStage();
    };

    const setTimer = () => {
        if (timed && placeInLine === 1) {
            timerRunningUpdate(true);
        }
    };
    const stopTimer = () => {
        timerRunningUpdate(false);
    };

    const enterStage = () => {
        messageLifeUpdate("fadeIn");
    };

    const exitStage = () => {
        stopTimer();
        messageLifeUpdate("fadeOut");
        setTimeout(() => {
            dispatch(Actions.removeMessage(id));
        }, config.speed.sliding);
    };

    /* Side Effects ---------------------------*/
    useEffect(() => {
        enterStage();

        // cleanup on dismount
        return () => {
            clearTimeout(timer.current);
        };
    }, []);

    useEffect(() => {
        if (placeInLine) {
            setTimer();
        }

        // Cleanup
        return () => {
            clearTimeout(timer.current);
        };
    }, [placeInLine]);

    useEffect(() => {
        if (timerRunning) {
            timer.current = setTimeout(() => {
                exitStage();
            }, timeout);
        } else {
            clearTimeout(timer.current);
        }

        // Cleanup
        return () => {
            clearTimeout(timer.current);
        };
    }, [timerRunning]);

    return (
        <MessageWrapperStyled
            messageLife={messageLife}
            onMouseEnter={stopTimer}
            onMouseLeave={setTimer}
        >
            <MessageWrapperInnerStyled>
                <MessageStyled
                    styleType="solid"
                    infoType={infoType}
                    darkMode={darkMode}
                    overrideDismissClick={onDismiss}
                >
                    {title && <Title>{title}</Title>}
                    <TextStyled dangerouslySetInnerHTML={{ __html: text }} />
                </MessageStyled>
            </MessageWrapperInnerStyled>
        </MessageWrapperStyled>
    );
};

export default Message;

// prop-types
Message.propTypes = {
    message: PropTypes.object.isRequired,
    placeInLine: PropTypes.number.isRequired
};
