import React from "react";
import PropTypes from "prop-types";

// Provider Components
import ReduxProvider from "./ReduxProvider";
import MessageStackMountingWrapper from "./MessageStackMountingWrapper";
import WebsocketProvider from "./WebsocketProvider/index";
import { MultipleTabsAreOpenProvider } from "frontend/hooks/useMultipleTabsAreOpen.js";

// component
export const Providers = ({ children, reduxStore }) => {
    return (
        <ReduxProvider store={reduxStore}>
            <MultipleTabsAreOpenProvider>
                <WebsocketProvider>
                    <MessageStackMountingWrapper>
                        {children}
                    </MessageStackMountingWrapper>
                </WebsocketProvider>
            </MultipleTabsAreOpenProvider>
        </ReduxProvider>
    );
};

export default Providers;

// prop-types
Providers.propTypes = {
    children: PropTypes.any,
    reduxStore: PropTypes.object.isRequired
};
