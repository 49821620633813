import gql from "graphql-tag";

export const APPROVE_PHOTO_UPLOAD_DESCRIPTION = gql`
    mutation approvePhotoUploadDescription($photoUploadId: ID!) {
        payload: approvePhotoUploadDescription(photoUploadId: $photoUploadId) {
            errors
            ok
            photoUpload {
                photoDescriptionApproved
                photoDescription
            }
        }
    }
`;

export const REJECT_PHOTO_UPLOAD_DESCRIPTION = gql`
    mutation rejectPhotoUploadDescription($photoUploadId: ID!) {
        rejectPhotoUploadDescription(photoUploadId: $photoUploadId) {
            errors
            ok
            photoUpload {
                photoDescriptionApproved
                photoDescription
            }
        }
    }
`;

// If Moderator update photo description, they are also approving it
export const UPDATE_AND_APPROVE_PHOTO_UPLOAD_DESCRIPTION = gql`
    mutation updateAndApprovePhotoUploadDescription(
        $photoUploadId: ID!
        $value: String
    ) {
        setPhotoUploadDescription(
            photoUploadId: $photoUploadId
            value: $value
        ) {
            errors
            ok
            photoUpload {
                photoDescription
            }
        }
        approvePhotoUploadDescription(photoUploadId: $photoUploadId) {
            errors
            ok
            photoUpload {
                photoDescriptionApproved
                photoDescription
            }
        }
    }
`;
