import React from "react";

import HomeLayout, {
    HomeLeftCol,
    HomeRightCol
} from "../../../core/Layouts/HomeLayout";
import PhotoModHomeMenu from "../../components/PhotoModHomeMenu";

const HomePage = () => (
    <HomeLayout data-testid="photo-mod-home-page">
        <HomeLeftCol>
            <div>moderator avatar - nice to have</div>
        </HomeLeftCol>
        <HomeRightCol>
            <PhotoModHomeMenu />
        </HomeRightCol>
    </HomeLayout>
);

export default HomePage;
