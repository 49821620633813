import React from "react";
import PropTypes from "prop-types";
import { useViewport } from "js/hooks/useViewport";

import { breakpointBasedImage } from "./helpers";

import { HeroImg } from "./styles";

const HeroImage = ({ heroImageMobile, heroImageTablet, heroImageDesktop }) => {
    const { browserWidth } = useViewport();

    const heroImage = breakpointBasedImage({
        browserWidth,
        heroImageDesktop,
        heroImageTablet,
        heroImageMobile
    });

    return <HeroImg src={heroImage} alt="" />;
};

HeroImage.propTypes = {
    heroImageMobile: PropTypes.string,
    heroImageTablet: PropTypes.string,
    heroImageDesktop: PropTypes.string
};

export default HeroImage;
