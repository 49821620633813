import React from "react";
import { useQuery } from "@apollo/client";

import RoyaltiesNoActivity from "./RoyaltiesNoActivity";
import LoadingSpinner from "core/LoadingSpinner";
import MonthlyReport from "./monthly-report";

import { GET_PHOTO_ROYALTIES_STATEMENT_ALL } from "./queries";

export const userHasPhotoActivity = statements => {
    // in some cases statements is null, or items is empty
    if (!statements?.items?.length) return false;

    // in some cases, all statements have no activity (totalEarned and freeDownloadCount are 0)
    return statements.items.some(
        s => parseFloat(s.totalEarned) > 0 || s.freeDownloadCount > 0
    );
};

const Royalties = ({ username }) => {
    const { data, loading } = useQuery(GET_PHOTO_ROYALTIES_STATEMENT_ALL, {
        variables: {
            username
        }
    });

    if (loading) return <LoadingSpinner />;

    const {
        photoRoyaltyStatementsAndTotal: { statements }
    } = data;

    // statements could be null, or have an empty array for items
    if (!userHasPhotoActivity(statements)) {
        return <RoyaltiesNoActivity />;
    }

    return (
        <>
            <MonthlyReport username={username} statements={statements} />
        </>
    );
};

export default Royalties;
