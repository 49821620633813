import gql from "graphql-tag";

export const GET_PHOTO_SUBMISSIONS_COUNTS = gql`
    query myPhotoSubmissionCounts {
        myPhotoSubmissionCounts {
            publishedCount
            pendingCount
            deniedCount
        }
    }
`;

export const GET_ALL_DENIED_PHOTO_SUBMISSIONS = gql`
    query allDeniedPhotoSubmissions($limit: Int, $offset: Int) {
        allDeniedPhotoSubmissions(limit: $limit, offset: $offset) {
            totalCount
            items {
                id
                thumbnail
                originalFilename
                autoDenied
                status
            }
        }
    }
`;

export const GET_PENDING_SUBMISSIONS_BY_BATCH = gql`
    query listUnmoderatedPhotoBatches(
        $limit: Int
        $offset: Int
        $moderationStatuses: [PhotoModerationStatus!]
    ) {
        listUnmoderatedPhotoBatches(
            limit: $limit
            offset: $offset
            moderationStatuses: $moderationStatuses
        ) {
            totalCount
            items {
                createdAt
                id
                moderationStatus
                photoShoot {
                    title
                    isForSinglePhoto
                }
                photoUploads {
                    id
                    thumbnail
                    originalFilename
                    aspectRatio
                }
            }
        }
    }
`;

export const GET_CREATORS_TOTAL_PHOTO_COUNT = gql`
    query photosPublishedByMe {
        publishedPhotos: photosPublishedByMe {
            totalCount: totalPhotos
        }
    }
`;

export const REFRESH_THUMBNAIL_URLS = gql`
    query photoUploadDetails($photoUploadIds: [ID!]!) {
        photoUploadDetails(photoUploadIds: $photoUploadIds) {
            id
            thumbnail
        }
    }
`;
