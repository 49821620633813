import React from "react";

import Grid2 from "core/Grid2";
import ItemContent from "./ItemContent";
import IndividualUploadLoader from "./IndividualUploadLoader";

import { convertPxToRem } from "frontend/components/helpers";

import { usePhotoUpload, hiddenStatuses } from "frontend/hooks/usePhotoUpload";

const PhotoUploadGrid = () => {
    const { photoUploads } = usePhotoUpload();

    const photoUploadsFiltered = photoUploads.filter(
        upload => !hiddenStatuses.includes(upload.status)
    );

    const content = photoUploadsFiltered.map((upload, index) => {
        if (!upload.thumbnail || upload.thumbnail === "") {
            // must be a loader, not an upload object
            // return loader with unique key
            const { id, filename } = upload;
            return <IndividualUploadLoader key={id} filename={filename} />;
        }

        // Otherwise start the Dropzone processing
        return (
            <ItemContent
                key={upload.id}
                id={upload.id}
                thumbnail={upload.thumbnail}
                name={upload.photoTitle || upload.originalFilename}
                gridIndex={index}
            />
        );
    });

    return (
        <Grid2
            numberOfColumns={5}
            gridSpacingSize={convertPxToRem({ px: 24 })}
            gridItemContent={content}
        />
    );
};

export default PhotoUploadGrid;
