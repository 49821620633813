import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import useTableColHighlight from "js/hooks/useTableColHighlight";
import { MILLIONS_OF_ICONS } from "core/constants";

import Close from "core/ReusableIcons/Close";
import Checkmark from "core/ReusableIcons/Checkmark";

import {
    IconsPricingTableHeaderBasic,
    IconsPricingTableHeaderProDownloads,
    IconsPricingTableHeaderIndividualSub,
    IconsPricingTableHeaderTeamSub
} from "./iconsPricingTableHeaders";
import {
    Table,
    TableRow,
    TableColumnHeading,
    TableData,
    TableBody,
    TableRowHeading,
    TableHead
} from "core/Table";
import colors from "core/colors";
import { IconsPricingTableDesktopContainer } from "./styles";

import {
    iconsPricingTableHeadingIds as headingIds,
    TEAM_SUBSCRIPTION,
    INDIVIDUAL_SUBSCRIPTION
} from "./data";

let IconsPricingTableDesktop = ({
    className,
    planType,
    bestValueHighlight,
    eduPricingLayout,
    individualColumnClick,
    teamColumnClick
}) => {
    const falseIcon = (
        <Close fillColor={colors.accent.darkMode.suenosBuenos} size="md" />
    );
    const trueIcon = (
        <Checkmark fillColor={colors.accent.lightMode.persian} size="md" />
    );

    const [colRef, tableRef, TableColumnHighlight] = useTableColHighlight({
        colNumber: bestValueHighlight === TEAM_SUBSCRIPTION ? 5 : 4,
        color: colors.accent.lightMode.persian
    });

    return (
        <IconsPricingTableDesktopContainer className={className}>
            {planType === "yearly" && <TableColumnHighlight />}
            <Table ref={tableRef}>
                <TableHead>
                    <TableRow>
                        <TableData empty />
                        <TableColumnHeading
                            id={headingIds.basicIconDownload}
                            scope="col"
                        >
                            <IconsPricingTableHeaderBasic />
                        </TableColumnHeading>
                        <TableColumnHeading
                            id={headingIds.proIconDownload}
                            scope="col"
                        >
                            <IconsPricingTableHeaderProDownloads />
                        </TableColumnHeading>
                        <TableColumnHeading
                            id={headingIds.nounProIndividual}
                            scope="col"
                        >
                            <IconsPricingTableHeaderIndividualSub
                                planType={planType}
                                bestValueHighlight={
                                    bestValueHighlight ===
                                    INDIVIDUAL_SUBSCRIPTION
                                }
                                eduPricingLayout={eduPricingLayout}
                                individualColumnClick={individualColumnClick}
                            />
                        </TableColumnHeading>
                        <TableColumnHeading
                            ref={colRef}
                            id={headingIds.nounProTeam}
                            scope="col"
                        >
                            <IconsPricingTableHeaderTeamSub
                                planType={planType}
                                bestValueHighlight={
                                    bestValueHighlight === TEAM_SUBSCRIPTION
                                }
                                teamColumnClick={teamColumnClick}
                                eduPricingLayout={eduPricingLayout}
                            />
                        </TableColumnHeading>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableRowHeading id={headingIds.threeMill} align="left">
                            More than {MILLIONS_OF_ICONS} million icons
                        </TableRowHeading>
                        <TableData
                            headers={`${headingIds.basicIconDownload} ${headingIds.threeMill}`}
                        >
                            {trueIcon}
                        </TableData>
                        <TableData
                            headers={`${headingIds.proIconDownload} ${headingIds.threeMill}`}
                        >
                            {trueIcon}
                        </TableData>
                        <TableData
                            headers={`${headingIds.nounProIndividual} ${headingIds.threeMill}`}
                        >
                            {trueIcon}
                        </TableData>
                        <TableData
                            headers={`${headingIds.nounProTeam} ${headingIds.threeMill}`}
                        >
                            {trueIcon}
                        </TableData>
                    </TableRow>
                    <TableRow>
                        <TableRowHeading
                            headers={headingIds.format}
                            align="left"
                        >
                            PNG and SVG formats
                        </TableRowHeading>
                        <TableData
                            headers={`${headingIds.basicIconDownload} ${headingIds.format}`}
                        >
                            {trueIcon}
                        </TableData>
                        <TableData
                            headers={`${headingIds.proIconDownload} ${headingIds.format}`}
                        >
                            {trueIcon}
                        </TableData>
                        <TableData
                            headers={`${headingIds.nounProIndividual} ${headingIds.format}`}
                        >
                            {trueIcon}
                        </TableData>
                        <TableData
                            headers={`${headingIds.nounProTeam} ${headingIds.format}`}
                        >
                            {trueIcon}
                        </TableData>
                    </TableRow>
                    <TableRow>
                        <TableRowHeading
                            headers={headingIds.withouAttr}
                            align="left"
                        >
                            Use without attribution
                        </TableRowHeading>
                        <TableData
                            headers={`${headingIds.basicIconDownload} ${headingIds.withouAttr}`}
                        >
                            {falseIcon}
                        </TableData>
                        <TableData
                            headers={`${headingIds.proIconDownload} ${headingIds.withouAttr}`}
                        >
                            {trueIcon}
                        </TableData>
                        <TableData
                            headers={`${headingIds.nounProIndividual} ${headingIds.withouAttr}`}
                        >
                            {trueIcon}
                        </TableData>
                        <TableData
                            headers={`${headingIds.nounProTeam} ${headingIds.withouAttr}`}
                        >
                            {trueIcon}
                        </TableData>
                    </TableRow>
                    <TableRow>
                        <TableRowHeading headers={headingIds.edit} align="left">
                            Edit color, background, shape
                        </TableRowHeading>
                        <TableData
                            headers={`${headingIds.basicIconDownload} ${headingIds.edit}`}
                        >
                            {falseIcon}
                        </TableData>
                        <TableData
                            headers={`${headingIds.proIconDownload} ${headingIds.edit}`}
                        >
                            {falseIcon}
                        </TableData>
                        <TableData
                            headers={`${headingIds.nounProIndividual} ${headingIds.edit}`}
                        >
                            {trueIcon}
                        </TableData>
                        <TableData
                            headers={`${headingIds.nounProTeam} ${headingIds.edit}`}
                        >
                            {trueIcon}
                        </TableData>
                    </TableRow>
                    <TableRow>
                        <TableRowHeading
                            headers={headingIds.unlimited}
                            align="left"
                        >
                            Unlimited icon licenses
                        </TableRowHeading>
                        <TableData
                            headers={`${headingIds.basicIconDownload} ${headingIds.unlimited}`}
                        >
                            {falseIcon}
                        </TableData>
                        <TableData
                            headers={`${headingIds.proIconDownload} ${headingIds.unlimited}`}
                        >
                            {falseIcon}
                        </TableData>
                        <TableData
                            headers={`${headingIds.nounProIndividual} ${headingIds.unlimited}`}
                        >
                            {trueIcon}
                        </TableData>
                        <TableData
                            headers={`${headingIds.nounProTeam} ${headingIds.unlimited}`}
                        >
                            {trueIcon}
                        </TableData>
                    </TableRow>
                    <TableRow>
                        <TableRowHeading headers={headingIds.apps} align="left">
                            Adobe, Mac, Office, Google apps
                        </TableRowHeading>
                        <TableData
                            headers={`${headingIds.basicIconDownload} ${headingIds.apps}`}
                        >
                            {falseIcon}
                        </TableData>
                        <TableData
                            headers={`${headingIds.proIconDownload} ${headingIds.apps}`}
                        >
                            {falseIcon}
                        </TableData>
                        <TableData
                            headers={`${headingIds.nounProIndividual} ${headingIds.apps}`}
                        >
                            {trueIcon}
                        </TableData>
                        <TableData
                            headers={`${headingIds.nounProTeam} ${headingIds.apps}`}
                        >
                            {trueIcon}
                        </TableData>
                    </TableRow>
                    <TableRow>
                        <TableRowHeading
                            headers={headingIds.favorites}
                            align="left"
                        >
                            Multiple custom favorites boards
                        </TableRowHeading>
                        <TableData
                            headers={`${headingIds.basicIconDownload} ${headingIds.favorites}`}
                        >
                            {falseIcon}
                        </TableData>
                        <TableData
                            headers={`${headingIds.proIconDownload} ${headingIds.favorites}`}
                        >
                            {falseIcon}
                        </TableData>
                        <TableData
                            headers={`${headingIds.nounProIndividual} ${headingIds.favorites}`}
                        >
                            {trueIcon}
                        </TableData>
                        <TableData
                            headers={`${headingIds.nounProTeam} ${headingIds.favorites}`}
                        >
                            {trueIcon}
                        </TableData>
                    </TableRow>
                    <TableRow>
                        <TableRowHeading
                            headers={headingIds.adFree}
                            align="left"
                        >
                            Ad-free site
                        </TableRowHeading>
                        <TableData
                            headers={`${headingIds.basicIconDownload} ${headingIds.adFree}`}
                        >
                            {falseIcon}
                        </TableData>
                        <TableData
                            headers={`${headingIds.proIconDownload} ${headingIds.adFree}`}
                        >
                            {falseIcon}
                        </TableData>
                        <TableData
                            headers={`${headingIds.nounProIndividual} ${headingIds.adFree}`}
                        >
                            {trueIcon}
                        </TableData>
                        <TableData
                            headers={`${headingIds.nounProTeam} ${headingIds.adFree}`}
                        >
                            {trueIcon}
                        </TableData>
                    </TableRow>
                    <TableRow>
                        <TableRowHeading
                            headers={headingIds.shareTeam}
                            align="left"
                        >
                            Share icons across team
                        </TableRowHeading>
                        <TableData
                            headers={`${headingIds.basicIconDownload} ${headingIds.shareTeam}`}
                        >
                            {falseIcon}
                        </TableData>
                        <TableData
                            headers={`${headingIds.proIconDownload} ${headingIds.shareTeam}`}
                        >
                            {falseIcon}
                        </TableData>
                        <TableData
                            headers={`${headingIds.nounProIndividual} ${headingIds.shareTeam}`}
                        >
                            {falseIcon}
                        </TableData>
                        <TableData
                            headers={`${headingIds.nounProTeam} ${headingIds.shareTeam}`}
                        >
                            {trueIcon}
                        </TableData>
                    </TableRow>
                    <TableRow>
                        <TableRowHeading
                            headers={headingIds.consolBilling}
                            align="left"
                        >
                            Consolidated billing
                        </TableRowHeading>
                        <TableData
                            headers={`${headingIds.basicIconDownload} ${headingIds.consolBilling}`}
                        >
                            {falseIcon}
                        </TableData>
                        <TableData
                            headers={`${headingIds.proIconDownload} ${headingIds.consolBilling}`}
                        >
                            {falseIcon}
                        </TableData>
                        <TableData
                            headers={`${headingIds.nounProIndividual} ${headingIds.consolBilling}`}
                        >
                            {falseIcon}
                        </TableData>
                        <TableData
                            headers={`${headingIds.nounProTeam} ${headingIds.consolBilling}`}
                        >
                            {trueIcon}
                        </TableData>
                    </TableRow>
                </TableBody>
            </Table>
        </IconsPricingTableDesktopContainer>
    );
};

IconsPricingTableDesktop = styled(IconsPricingTableDesktop)``;
IconsPricingTableDesktop.propTypes = {
    className: PropTypes.string,
    planType: PropTypes.oneOf(["yearly", "monthly"]).isRequired,
    bestValueHighlight: PropTypes.string,
    eduPricingLayout: PropTypes.bool,
    individualColumnClick: PropTypes.func.isRequired,
    teamColumnClick: PropTypes.func.isRequired
};

export default IconsPricingTableDesktop;
