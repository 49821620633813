import colors from "core/colors";

const title = "DLS: TagRemoveable:";

// TagRemoveable DLS
// per Figma: https://www.figma.com/file/xTLiaJuirKEJSb5czcp6lK/NounDesignSystem?node-id=12830%3A58156&mode=dev

/*---------------------------
| Light
---------------------------*/
const light = {
    title: `${title}: Light`,
    static: {
        color: colors.secondary.basalt,
        background: colors.primary.diamond
    },
    hover: {
        color: colors.secondary.cosmic,
        background: colors.secondary.ridge
    },
    disabled: {
        color: colors.secondary.dolphin,
        background: colors.primary.whisker
    }
};

/*---------------------------
| Dark
---------------------------*/
// Dark has not been defined in Figma for TagRemoevable yet
let dark = { ...light };

export const dls = { light, dark };
