/**
 * getHeadingText
 * @param {Array} releases
 * @returns {String} Releases message
 */
export const getHeadingText = releases => {
    const count = releases.length;

    let suffix = `For This Batch`;
    let message = `Releases ${suffix}`;

    if (count === 1) {
        message = `${count} Release ${suffix}`;
    } else if (count > 1) {
        message = `${count} Releases ${suffix}`;
    }

    return message;
};
