import { useContext } from "react";

// context
import { SiteHeaderContext } from "../index";
import { actionTypes } from "./store";
import { calcElementPropByScroll } from "./calcElementPropByScroll";
import getDefaultStateByPage from "./getDefaultStateByPage";

// useSiteHeader
export const useSiteHeader = () => {
    const { state, dispatch, nonStateProps } = useContext(SiteHeaderContext);
    const { header, animation, searchBar } = state;
    const {
        config: { visibilityShouldToggle }
    } = searchBar;
    const { config: headerConfig } = header;
    const {
        config: { animateInProps, animateOutProps }
    } = animation;

    // Action Creators
    const resetStateByPage = currentPage => {
        const stateProps = getDefaultStateByPage(currentPage, true);
        dispatch({
            type: actionTypes.RESET_STATE_BY_PAGE,
            stateProps
        });
    };

    const setSearchBarIsVisible = isVisible => {
        dispatch({
            type: actionTypes.SET_SEARCH_BAR,
            searchBar: {
                ...searchBar,
                isVisible
            }
        });
    };

    const setHeader = scrollY => {
        const propsToUpdate = calcElementPropByScroll({
            scrollY,
            elementConfig: headerConfig
        });

        // Update state only when crossing the scrollY breakpoint threshold
        dispatch({
            type: actionTypes.SET_HEADER,
            header: {
                ...header,
                ...propsToUpdate
            }
        });
    };

    const setAnimation = props => {
        dispatch({
            type: actionTypes.SET_ANIMATION,
            animation: {
                ...animation,
                ...props
            }
        });
    };

    const setAnimationSequence = (
        scrollY,
        animationSequenceRef,
        scrollDirectionRef
    ) => {
        const scrollDirection = scrollDirectionRef.current;
        const { down, up } = animation.scrollYBreakpoint;

        let sequence = animationSequenceRef.current;
        if (scrollDirection === "down" && scrollY >= down) {
            sequence = "in";
        } else if (scrollDirection === "up" && scrollY <= up) {
            sequence = "out";
        }

        // Update state only when crossing the scrollY breakpoint down and up thresholds
        if (sequence !== animationSequenceRef.current) {
            animationSequenceRef.current = sequence;
            setAnimation({
                sequence
            });
        }
    };

    const animateIn = () => {
        setAnimation(animateInProps);
        if (visibilityShouldToggle) {
            // need to wait until the above animation is completed - opacity spans the entire duration
            const { delay, speed } = animateInProps.opacity;
            const animationDuration = delay + speed;
            setTimeout(() => setSearchBarIsVisible(true), animationDuration);
        }
    };

    const animateOut = () => {
        setAnimation(animateOutProps);
        if (visibilityShouldToggle) {
            // ok to hide right away
            setSearchBarIsVisible(false);
        }
    };

    return {
        ...nonStateProps,
        ...state,
        resetStateByPage,
        setHeader,
        setAnimationSequence,
        animateIn,
        animateOut
    };
};
