import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";

import { GET_ACTIVE_SOCIAL_LOGIN_DATA } from "./queries";

import { getCsrfTokenFromCookie } from "helpers/csrfToken";
import { TRACK_SIGN_IN, TRACK_SIGN_UP, trackEvent } from "core/trackEvent";

export const validEmail = input => /.+@.+\..+/.test(input);
// some legacy users have a username instead of an email address
export const validNameOrEmail = input => String(input).length;
export const validNewPassword = input => String(input).length >= 8;
/* NOTE: We don't want to validate the length of existing passwords,
 * since requirements can change and we still need to accept old passwords */
export const validPassword = input => !!String(input).length;

export const LOGIN_BACKEND_URL = "/accounts/login/";
export const FORGOT_PASSWORD_BACKEND_URL = "/accounts/password/reset/";
export const RESET_PASSWORD_BACKEND_URL =
    "/accounts/password/reset/key/:resetKey/";
export const SIGNUP_BACKEND_URL = "/accounts/signup/:signupType/";
export const SOCIAL_SIGNUP_BACKEND_URL = "/accounts/3rdparty/signup/";

export const DEFAULT_SIGNUP_TYPE = "basic";

export const attemptAuth = async ({ url, form, onSuccess, onFail }) => {
    // NOTE: Determine the GA4 event name before we manipulate the URL.
    const trackEventName =
        url === SIGNUP_BACKEND_URL || url === SOCIAL_SIGNUP_BACKEND_URL
            ? TRACK_SIGN_UP
            : url === LOGIN_BACKEND_URL
            ? TRACK_SIGN_IN
            : null; // ignore password reset, etc.

    if (url === SIGNUP_BACKEND_URL) {
        form.signup_type = form.signup_type || DEFAULT_SIGNUP_TYPE;
        url = SIGNUP_BACKEND_URL.replace(":signupType", form.signup_type);
    }
    if (url === RESET_PASSWORD_BACKEND_URL) {
        // The reset key should be the last part of the current URL's pathname
        const pathname = window.location.pathname;
        const folders = pathname.replace(/\/$/g, "").split("/"); // create array of path names from the current URL
        const resetKey = folders[folders.length - 1];
        url = RESET_PASSWORD_BACKEND_URL.replace(":resetKey", resetKey);
    }

    // For whatever reason, the backend auth routes don't accept JSON
    const body = new FormData();
    Object.entries(form).forEach(([name, value]) => {
        body.append(name, value);
    });

    const res = await fetch(url, {
        method: "POST",
        headers: {
            "X-Requested-With": "XMLHttpRequest", // backend is filtering out packets without this header
            "X-CSRFToken": getCsrfTokenFromCookie()
        },
        body
    });

    const data = await res.json();

    if (res.status === 200) {
        if (trackEventName) trackEvent(trackEventName);
        const url = new URL(`https://${location.host}${data.location}`);
        const searchParams = new URLSearchParams(url.search);
        const next = searchParams.get("next");
        onSuccess(next);
        return;
    }

    let errorMsg = null;
    if (data.form) {
        const { errors, fields } = data.form;
        if (Array.isArray(errors) && errors.length) {
            errorMsg = errors[0];
        } else if (fields) {
            const fieldName = Object.keys(fields).find(name => {
                const value = fields[name];
                return Array.isArray(value.errors) && value.errors.length;
            });
            if (fieldName) {
                errorMsg = fields[fieldName].errors[0];
            }
        }
    }
    onFail(errorMsg);
};

export const useSignupEmail = ({ isSocialSignup }) => {
    const [signupEmail, setSignupEmail] = useState("");

    const { data = { activeSocialLogin: {} } } = useQuery(
        GET_ACTIVE_SOCIAL_LOGIN_DATA,
        {
            skip: !isSocialSignup
        }
    );
    const {
        provider = "",
        emailAddresses = [],
        canConnect,
        nextUrl
    } = data.activeSocialLogin;

    useEffect(() => {
        const email = emailAddresses[0] || "";
        setSignupEmail(email);
    }, [emailAddresses[0]]);

    return { signupEmail, setSignupEmail, provider, canConnect, nextUrl };
};
