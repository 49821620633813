import React from "react";
import PropTypes from "prop-types";

// styles
import { FormControlGroupStyled } from "./styles.js";

// components
import Label from "./Label.js";
import ErrorMessage from "./ErrorMessage.js";

export const FormControlGroup = ({
    children,
    errorMessage,
    ariaLabel,
    controlId,
    hideLabel
}) => {
    return (
        <FormControlGroupStyled>
            <Label controlId={controlId} hideLabel={hideLabel}>
                {ariaLabel}
            </Label>
            {children}
            <ErrorMessage controlId={controlId}>{errorMessage}</ErrorMessage>
        </FormControlGroupStyled>
    );
};

export default FormControlGroup;

// prop-types
FormControlGroup.propTypes = {
    children: PropTypes.any,
    errorMessage: PropTypes.string,
    ariaLabel: PropTypes.string,
    controlId: PropTypes.string,
    hideLabel: PropTypes.bool
};
