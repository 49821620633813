import styled, { css } from "styled-components";

// styles
import colors from "core/colors";
import { convertPxToRem } from "frontend/components/helpers";

// styled-components
export const ShadowStyled = styled.div`
    background-color: rgba(0 0 0 / 0.75);

    ${({ position, left, right, top, bottom, zIndex }) => {
        return css`
            position: ${position};
            left: ${left};
            right: ${right};
            top: ${top};
            bottom: ${bottom};
            z-index: ${zIndex};
        `;
    }}
`;
export const LightStyled = styled.div`
    background-color: ${colors.primary.snow};
    position: absolute;
    z-index: 1;
    display: flex;
    flex-direction: column;

    ${({ isFullScreen, dimensions }) => {
        if (isFullScreen) {
            return css`
                left: 0px;
                right: 0px;
                top: 0px;
                bottom: 0px;
            `;
        }

        return css`
            width: ${dimensions.w};
            height: ${dimensions.h};
            /* centering regardless of dimensions */
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        `;
    }}
`;
export const HeaderStyled = styled.header`
    flex: 0 0 ${({ headerHeight }) => headerHeight};
    display: grid;
    grid-template-columns: auto 64px;
`;

export const ContentStyled = styled.div`
    flex: 1;
    overflow: auto;
    padding: ${convertPxToRem(24)};
`;

export const TitleStyled = styled.h2`
    margin: 0px;
    padding: 0px;
    font-size: ${convertPxToRem(23)};
    line-height: ${({ headerHeight }) => headerHeight};
    padding: 0px ${convertPxToRem(24)};
`;

export const CloseButtonStyled = styled.button`
    display: block;
    width: 100%;
    height: 100%;
    background-color: transparent;
    outline: none;
    border: none;
    cursor: pointer;
    padding: 0px ${convertPxToRem(24)};

    display: flex;
    justify-content: center;
    align-items: center;
`;
