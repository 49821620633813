// Frontend Moderation Queue Views
export const QUEUES = {
    VIS_MOD: "visMod",
    METADATA_MOD: "metadataMod",
    COMPLETED: "completed",
    SUSPENDED: "suspended"
};

// Backend Data Translation
export const MODERATION_TYPES = {
    [QUEUES.VIS_MOD]: "VISUAL",
    [QUEUES.METADATA_MOD]: "METADATA",
    [QUEUES.COMPLETED]: "COMPLETED",
    [QUEUES.SUSPENDED]: "ANY"
};

export const QUEUE_CONFIGS = {
    [QUEUES.VIS_MOD]: {
        filterCheckboxes: [1, 2, 3, 4, 5, 6]
    },
    [QUEUES.METADATA_MOD]: {
        filterCheckboxes: [7, 4, 5, 6]
    },
    [QUEUES.SUSPENDED]: {
        filterCheckboxes: [1, 2, 3, 4, 5, 6]
    },
    [QUEUES.COMPLETED]: {
        filterCheckboxes: [7, 8, 6]
    }
};

export const QUEUE_VIEWS = {
    ACTIVE: "active",
    SAVED: "saved",
    LARGE_IMAGE: "largeImage"
};
