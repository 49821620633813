import React from "react";

import { useViewport } from "js/hooks/useViewport";
import breakpoints from "core/breakpoints";
import { convertPxToRem } from "frontend/components/helpers";

export const useSubmissionGridItems = () => {
    const { browserWidth } = useViewport();
    const isMobile = browserWidth <= breakpoints.beforeTablet.px;
    const isTablet =
        browserWidth > breakpoints.beforeTablet.px &&
        browserWidth < breakpoints.desktop.px;

    let numberOfColumns = 5;

    if (isTablet) numberOfColumns = 3;
    if (isMobile) numberOfColumns = 1;

    const gridSpacingSize = convertPxToRem({ px: isMobile ? 16 : 24 });

    return {
        numberOfColumns,
        gridSpacingSize
    };
};
