import React, { useState, useEffect, useCallback } from "react";
import { useLazyQuery, useApolloClient } from "@apollo/client";
import PropTypes from "prop-types";
import { useHistory, useLocation } from "react-router";

import LoadMoreButton from "icons/LoadMoreButton";
import { CONTENT_TYPE } from "core/constants";

import {
    getPageQueryParamValue,
    getItemsFromData,
    getTotalItemCountFromData,
    updateDataWithNewPaginationItems,
    getVariables,
    getButtonText,
    goToPage
} from "./helpers";

const useLoadMore = ({
    query,
    queryVariables,
    pathToListKey,
    pathToTotalCountKey,
    limit,
    useLazyFirstLoad = false,
    pageQueryParamKey = "p",
    contentType
} = {}) => {
    const [startingPage, setStartingPage] = useState(0);
    const [loadingFirstBatch, setLoadingFirstBatch] = useState(false);
    const [loadingItemCount, setLoadingItemCount] = useState(limit);

    const client = useApolloClient();
    const location = useLocation();
    const history = useHistory();

    const currentPage = getPageQueryParamValue({
        routerLocation: location,
        pageQueryParamKey
    });

    const setUpPagination = () => {
        setLoadingFirstBatch(true);

        if (!currentPage || currentPage < 1) {
            goToFirstPage();
        } else if (currentPage > 1) {
            const pageWasCached = cacheContainsPageData({ page: 1 });

            if (!pageWasCached) {
                goToFirstPage();
            } else {
                setLoadingItemCount(limit * currentPage);
                setStartingPage(currentPage);
                return;
            }
        }

        setStartingPage(1);

        function goToFirstPage() {
            goToPage({
                page: 1,
                pageQueryParamKey,
                sessionHistory: history,
                routerLocation: location
            });
        }

        function cacheContainsPageData({ page }) {
            /* uses try...catch because Apollo throws error
        if query is not found in cache */
            try {
                client.readQuery({
                    query,
                    variables: getVariables({ page, queryVariables, limit })
                });
            } catch {
                return false;
            }

            return true;
        }
    };

    // on page load
    useEffect(() => {
        if (useLazyFirstLoad) return;
        setUpPagination();
    }, []);

    // query setup
    const [
        fetchFirstBatchOfItems,
        { loading, data, fetchMore, called }
    ] = useLazyQuery(query, {
        variables: getVariables({ page: 1, queryVariables, limit }),
        // necessary to update `loading` boolean during subsequent fetches
        notifyOnNetworkStatusChange: true,
        onCompleted: useCallback(() => setLoadingItemCount(0), [
            setLoadingItemCount
        ])
    });

    const fetchItems = ({ currentPage }) => {
        if (currentPage === startingPage) {
            fetchFirstBatchOfItems();
        } else if (currentPage > startingPage) {
            setLoadingItemCount(limit);
            fetchMore &&
                fetchMore({
                    variables: getVariables({
                        page: currentPage,
                        limit,
                        queryVariables
                    }),
                    updateQuery
                });
        }

        function updateQuery(prevResult, { fetchMoreResult }) {
            const fetchedItems = getItemsFromData({
                data,
                pathToListKey
            });
            if (!fetchedItems.length) return prevResult;

            const updatedData = updateDataWithNewPaginationItems({
                prevResult,
                fetchMoreResult,
                pathToListKey
            });
            return updatedData;
        }
    };

    // on page change
    useEffect(() => {
        !!startingPage && fetchItems({ currentPage });
    }, [currentPage, startingPage]);

    useEffect(() => {
        const firstBatchLoaded = loadingFirstBatch && called && !loading;
        if (firstBatchLoaded) {
            setLoadingFirstBatch(false);
        }
    }, [loadingFirstBatch, called, loading]);

    const loadMore = () => {
        goToPage({
            page: currentPage + 1,
            pageQueryParamKey,
            sessionHistory: history,
            routerLocation: location
        });
    };

    const totalCount =
        getTotalItemCountFromData({ data, pathToTotalCountKey }) || 0;

    const LoadMore = ({ buttonText }) => {
        return items && items.length >= totalCount ? null : (
            <LoadMoreButton
                loadMore={loadMore}
                buttonText={
                    buttonText ||
                    getButtonText({
                        limit,
                        contentType,
                        totalCount,
                        currentPage
                    })
                }
                loading={loading}
            />
        );
    };

    const items = getItemsFromData({ data, pathToListKey });

    const loadFirstBatchLazy = () => {
        if (!useLazyFirstLoad) return;
        setUpPagination();
    };

    return {
        items: items || [],
        loading,
        currentPage,
        loadMore,
        LoadMore,
        totalCount,
        loadFirstBatchLazy, // use only when `useLazyFirstLoad` prop is true
        totalItemsLoaded: items && items.length,
        firstBatchFetched: called,
        loadingFirstBatch,
        loadingMoreItems: !loadingFirstBatch && loading,
        loadingItemCount,
        data,
        limit
    };
};

useLoadMore.propTpyes = {
    query: PropTypes.string.isRequired,
    queryVariables: PropTypes.object,
    pathToListKey: PropTypes.string.isRequired,
    limit: PropTypes.number,
    offset: PropTypes.number,
    pathToTotalCountKey: PropTypes.string.isRequired,
    useLazyFirstLoad: PropTypes.bool,
    pageQueryParamKey: PropTypes.string,
    contentType: PropTypes.oneOf(Object.values(CONTENT_TYPE)).isRequired
};

export default useLoadMore;
