import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

import NotFound from "core/NotFound";
import CreatorDashboardPhotosContent from "./CreatorDashboardPhotosContent";
import CreatorDashboardPhotosMasthead from "./CreatorDashboardPhotosMasthead";
import DashboardPageMeta from "./DashboardPageMeta";

import { PhotosContainer, CreatorDashboardContainer } from "./styles";

import { useCurrentUser } from "js/contexts/CurrentUserContext";
import { userHasUploads } from "./helpers";

import { GET_USER_UPLOAD_COUNTS } from "./queries";

const CreatorDashboardPhotos = () => {
    const {
        username: loggedInUsername,
        isPhotoCreator,
        isSuperuser
    } = useCurrentUser();
    const { username } = useParams();
    const decodedUsername = decodeURIComponent(username);

    if (!isSuperuser && loggedInUsername !== decodedUsername)
        return <NotFound />;

    const redirectUser = () =>
        window.location.replace(`/${decodedUsername}/settings/basics/`);

    if (!isPhotoCreator && !isSuperuser) {
        redirectUser();
        return null;
    }

    // TODO: reinstate code below when all of dashboard is complete
    // const { data, loading } = useQuery(GET_USER_UPLOAD_COUNTS);
    // const { currentUserUploads: uploads } = data;

    // if (!uploads.ok || !userHasUploads({ uploads })) {
    //     redirectUser();
    //     return null;
    // }

    return (
        <>
            <DashboardPageMeta />
            <PhotosContainer topSpacing="2xl">
                <CreatorDashboardPhotosMasthead />
                <CreatorDashboardContainer
                    topSpacing="xl"
                    removeLeftRightPadding
                >
                    <CreatorDashboardPhotosContent />
                </CreatorDashboardContainer>
            </PhotosContainer>
        </>
    );
};

export default CreatorDashboardPhotos;
