import React, { useEffect } from "react";

const handleBeforeUnload = e => {
    e.preventDefault();
    // this is required, but the actual value is ignored
    e.returnValue = "";
};

/**
 * Show confirmation alert before user closes tab or navigates away from page.
 *
 * @param {string} active - Whether to activate the hook or not
 * @returns
 */
const useConfirmBrowserUnload = (active = true) => {
    if (typeof window === "undefined") {
        return;
    }

    useEffect(() => {
        if (!active) {
            return;
        }

        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, [handleBeforeUnload, active]);
};

export default useConfirmBrowserUnload;
