import React from "react";

import Grid from "core/Grid";
import CollectionTitleSkeleton from "core/Collections/CollectionTitleSkeleton";
import CollectionSkeletonBox from "core/Collections/CollectionSkeletonBox";

import { Container } from "./styles";
import {
    CollectionBoxLink,
    CollectionContent,
    TextContainer
} from "core/Collections/styles";

const CollectionLoader = ({ loadingItemCount, topSpacing = "2xl" }) => {
    return (
        <Container topSpacing={topSpacing}>
            <Grid mobileSpacing={32} tabletSpacing={20} desktopSpacing={48}>
                {[...Array(loadingItemCount).keys()].map(idx => {
                    return (
                        <Grid item sm={12} md={4} lg={4} key={idx}>
                            <CollectionBoxLink
                                href={"url"}
                                type={"secondary"}
                                isPhoto={false}
                            >
                                <CollectionContent>
                                    <TextContainer>
                                        <CollectionTitleSkeleton />
                                    </TextContainer>
                                    <CollectionSkeletonBox />
                                </CollectionContent>
                            </CollectionBoxLink>
                        </Grid>
                    );
                })}
            </Grid>
        </Container>
    );
};

export default CollectionLoader;
