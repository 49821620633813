/*---------------------------
| Middleware
---------------------------*/
// import actionLogger from "./actionLogger"; // use during dev to log Redux action details
import { uploadListenerMiddleware } from "./uploadsMiddleware.js";

// Middleware Notes/Gotchas: https://redux-toolkit.js.org/api/getDefaultMiddleware
// if adding middleware, it's desired to use concat like show below, as RTK uses Typescript, and array spread loses the typing info causing issues
// you can override middleware with your own array [], which will then remove the RTK default middleware
export const getMiddleware = getDefaultMiddleware => {
    // defaults are baked in middleware from RTK
    let middleware = getDefaultMiddleware();

    // Uploads
    // NOTE: Since this can receive actions with functions inside,
    // it should go before the serializability check middleware, hence prepend instead of concat
    // middleware.prepend(uploadListenerMiddleware.middleware);
    middleware = middleware.prepend(uploadListenerMiddleware.middleware);

    // logger
    // middleware = middleware.concat(actionLogger);

    return middleware;
};
