import React, { useState, useCallback } from "react";

import Button from "core/Button";
import ClearProcessingPhotosModal from "./ClearProcessingPhotosModal";

import { usePhotoUpload } from "frontend/hooks/usePhotoUpload";

const ClearProcessingPhotosButton = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [hasCancellationError, setHasCancellationError] = useState(false);

    const { totalCancellable, clearProcessingUploads } = usePhotoUpload();

    const onConfirm = useCallback(async () => {
        const isError = await clearProcessingUploads();
        setHasCancellationError(isError);
        if (!isError) {
            setIsModalOpen(false);
        }
    }, [clearProcessingUploads, setIsModalOpen]);

    if (totalCancellable === 0 && !isModalOpen) {
        return null;
    }

    return (
        <>
            <Button
                size="sm"
                styleType="outline"
                onClick={() => setIsModalOpen(true)}
            >
                {`Clear ${totalCancellable} Loading & Continue`}
            </Button>
            <ClearProcessingPhotosModal
                processingPhotosCount={totalCancellable}
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onConfirm={onConfirm}
                hasCancellationError={hasCancellationError}
            />
        </>
    );
};

export default ClearProcessingPhotosButton;
