import { useEffect, useState } from "react";

// utils
import { objectHasProps } from "frontend/components/helpers/index.js";

// hooks
import { useAuth } from "frontend/hooks/useAuth";
import { useModerators } from "frontend/components/fort/mod/photos/hooks/useModerators.js";

export const useHasAccess = () => {
    const [isLoading, setIsLodaing] = useState(true);
    const [hasAccess, setHassAccess] = useState(false);

    const { isLoggedIn, currentUser, isLoading: useAuthIsLodaing } = useAuth();
    const modData = useModerators();
    const myModProfile = modData.myModProfile;
    const email = currentUser?.email;

    useEffect(() => {
        if (!useAuthIsLodaing && myModProfile.profileFetched) {
            setIsLodaing(false);
        }
    }, [useAuthIsLodaing, myModProfile]);

    useEffect(() => {
        const hasTnpEmail = hasTnpEmailCheck(email);
        const hasAccess = myModProfile?.moderatorId;
        setHassAccess(isLoggedIn && hasTnpEmail && hasAccess);
    }, [isLoggedIn, email, myModProfile]);

    return {
        isLoading,
        hasAccess
    };
};

const hasTnpEmailCheck = email => {
    return !!email && email.includes("thenounproject.com");
};
