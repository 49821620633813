import styled from "styled-components";

import { convertPxToRem } from "frontend/components/helpers";
import colors from "core/colors";
import { easingSeconds } from "core/animation";

export const TextContainer = styled.div`
    width: 100%;
    background-color: ${colors.secondary.orca};
    position: absolute;
    bottom: 0;
    text-align: right;
    opacity: 0;
    padding: ${convertPxToRem({ px: 4 })} ${convertPxToRem({ px: 8 })};
    transition: all ${easingSeconds};
`;

export const Container = styled.div`
    width: 100%;
    height: 100%;
    background-color: transparent;
    position: absolute;
    top: 0;
    left: 0;

    &:hover ${TextContainer} {
        opacity: 1;
        transition: all ${easingSeconds};
    }
`;
