import React from "react";

import SearchAndBrowseCollectionGrid from "icons/SearchAndBrowseCollectionGrid";
import SegmentedControlResults from "core/SegmentedControlResults";
import SearchEmptyResults from "core/SearchEmptyResults";
import SearchHeader from "icons/SearchHeader";

import useLoadMore from "frontend/hooks/useLoadMore";
import { CONTENT_TYPE } from "core/constants";

import { GET_ICON_COLLECTIONS } from "icons/SearchAndBrowseCollectionGrid/queries";

import CollectionLoader from "icons/CollectionLoader";

const SearchCollectionsResults = ({ isActive, query, searchType }) => {
    const iconsPerCollectionLimit = 6;
    const {
        items,
        totalCount,
        loading,
        firstBatchFetched,
        LoadMore,
        loadFirstBatchLazy,
        loadingFirstBatch,
        loadingItemCount
    } = useLoadMore({
        query: GET_ICON_COLLECTIONS,
        queryVariables: { query, iconsPerCollectionLimit },
        pathToListKey: "collectionSearch.items",
        pathToTotalCountKey: "collectionSearch.totalCount",
        limit: 99,
        useLazyFirstLoad: true,
        pageQueryParamKey: "collectionspage",
        contentType: CONTENT_TYPE.iconSet
    });

    const showEmptySearchResultsPage =
        !loadingFirstBatch && totalCount === 0 && isActive && firstBatchFetched;

    return (
        <SegmentedControlResults
            isActive={isActive}
            firstBatchFetched={firstBatchFetched}
            loadFirstBatchLazy={loadFirstBatchLazy}
        >
            <SearchHeader
                query={query}
                searchType={searchType}
                showSkeleton={loadingFirstBatch}
            />

            {showEmptySearchResultsPage ? (
                <SearchEmptyResults
                    contentType={CONTENT_TYPE.iconSet}
                    query={query}
                />
            ) : (
                <>
                    <SearchAndBrowseCollectionGrid
                        items={items}
                        totalCount={totalCount}
                        LoadMore={LoadMore}
                        loading={loading}
                    />
                    {loading && (
                        <CollectionLoader loadingItemCount={loadingItemCount} />
                    )}
                </>
            )}
        </SegmentedControlResults>
    );
};

export default SearchCollectionsResults;
