import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { tooltipSizes, alignments } from "core/props";

import ClickToOpen from "./ClickToOpen";
import HoverToOpen from "./HoverToOpen";
import Title from "./Title";

import { TooltipContainer, tooltipThemeStyles } from "./styles";

import { MuiThemeProvider } from "js/helpers/materialUI";
import FocusTrap from "focus-trap-react";

const UnstyledTooltip = ({
    darkMode = false,
    size = "large",
    clickToOpen = false,
    interactive = true,
    textAlignment,
    message,
    children,
    className,
    leaveDelay = 100,
    placement = "bottom"
}) => {
    const [modalExists, setModalExists] = useState(null);
    const isSmallTooltip = size === "small";
    const isBrowser = typeof document !== "undefined";
    // if tooltip has interactive content, we need to add a focus trap
    // to allow keyboard navigation within the tooltip
    const wrappedMessage =
        clickToOpen && interactive && modalExists === false ? (
            <FocusTrap focusTrapOptions={{ allowOutsideClick: true }}>
                <div>{message}</div>
            </FocusTrap>
        ) : (
            message
        );

    const title = (
        <Title
            textAlignment={textAlignment}
            darkMode={darkMode}
            message={wrappedMessage}
            isSmallTooltip={isSmallTooltip}
        />
    );

    // HACK: FocusTrap and Material-UI modals don't play well together
    // so, enable FocusTrap once we confirm no modal in the DOM
    useEffect(() => {
        if (typeof document !== "undefined") {
            const muiModal = document.querySelector(".MuiBackdrop-root");
            setModalExists(!!muiModal);
        }
    }, [isBrowser, setModalExists]);

    const sharedProps = {
        className,
        arrow: true,
        interactive: interactive,
        darkMode: darkMode,
        isSmallTooltip: isSmallTooltip,
        textAlignment: textAlignment,
        placement: placement,
        leaveDelay: leaveDelay,
        title
    };

    return (
        <MuiThemeProvider
            theme={tooltipThemeStyles({ darkMode, isSmallTooltip })}
        >
            <TooltipContainer className={className}>
                {clickToOpen ? (
                    <ClickToOpen {...sharedProps}>{children}</ClickToOpen>
                ) : (
                    <HoverToOpen {...sharedProps}>{children}</HoverToOpen>
                )}
            </TooltipContainer>
        </MuiThemeProvider>
    );
};

const Tooltip = styled(UnstyledTooltip)``;

Tooltip.propTypes = {
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
        .isRequired,
    children: PropTypes.any.isRequired,
    size: PropTypes.oneOf(tooltipSizes),
    darkMode: PropTypes.bool,
    textAlignment: PropTypes.oneOf(alignments),
    interactive: PropTypes.bool,
    clickToOpen: PropTypes.bool,
    zIndex: PropTypes.number
};

export default Tooltip;
