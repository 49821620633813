import React from "react";
import PropTypes from "prop-types";

import RemoveBatchAnimationProvider from "./RemoveBatchAnimationProvider";

// component
export const AnimationProviders = ({ children }) => {
    return (
        <RemoveBatchAnimationProvider>{children}</RemoveBatchAnimationProvider>
    );
};

export default AnimationProviders;

// prop-types
AnimationProviders.propTypes = {
    children: PropTypes.any
};
