import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import colors from "core/colors";

const createHelpers = (width, height, css) => {
    // somehow sizes is ending up in markup, even if it is not a valid svg attribute
    // until we have a better solution, just render it empty, instead to '[Object object]'
    const sanitizeSizes = sizes =>
        Object.defineProperty(sizes, "toString", {
            value: () => "",
            enumerable: false
        });

    const getDimensions = (size, sizes) => {
        if (
            size &&
            typeof size.width === "number" &&
            typeof size.height === "number"
        ) {
            return size;
        }

        return size && sizes[size] ? sizes[size] : { width, height };
    };

    const getCss = (size, sizes, fillColor, fillColorRule, noStyles) => {
        if (noStyles) {
            return "";
        }

        const dimensions = getDimensions(size, sizes);
        const fillRule =
            fillColor && fillColorRule
                ? `${fillColorRule}{ fill: ${fillColor}; }`
                : "";
        return css`
            width: ${dimensions.width}px;
            height: ${dimensions.height}px;
            ${fillRule}
        `;
    };

    const propsToCss = ({ size, sizes, fillColor, fillColorRule, noStyles }) =>
        getCss(size, sizes, fillColor, fillColorRule, noStyles);

    return {
        getCss,
        getDimensions,
        propsToCss,
        sanitizeSizes
    };
};

const width = "24";
const height = "24";
const viewBox = "0 0 24 24";

const { getDimensions, getCss, propsToCss, sanitizeSizes } = createHelpers(
    width,
    height,
    css
);

const sizes = sanitizeSizes({
    xs: { width: 14, height: 14 },
    sm: { width: 16, height: 16 },
    md: { width: 20, height: 20 },
    lg: { width: 24, height: 24 },
    xl: { width: 40, height: 40 }
});

const Image = styled.svg`
    ${propsToCss}
`;

const children = (
    <Fragment>
        <path
            fill={colors.secondary.orca}
            fillRule="evenodd"
            d="M13 5.333V2.667C13 2.298 12.553 2 12 2c-.553 0-1 .298-1 .667v2.666c0 .368.447.667 1 .667.553 0 1-.299 1-.667zm-3.467.394l-1.334-2.31c-.184-.319-.72-.353-1.2-.077-.478.276-.716.758-.532 1.077l1.334 2.31c.184.318.72.353 1.199.077.478-.276.717-.759.533-1.077zm6.666 11.547l1.334 2.309c.184.319-.055.801-.533 1.077-.479.277-1.015.242-1.2-.077l-1.333-2.31c-.184-.318.054-.8.533-1.077.478-.276 1.015-.241 1.2.078zm0-10.548l1.334-2.309c.184-.319-.055-.801-.533-1.077-.478-.277-1.015-.242-1.2.077l-1.333 2.31c-.184.318.055.8.533 1.077.479.276 1.015.241 1.2-.078zM20.583 8.2l-2.31 1.334c-.318.184-.8-.054-1.077-.533-.276-.478-.241-1.015.077-1.2l2.31-1.333c.319-.184.8.055 1.077.533.276.479.242 1.015-.077 1.2zM2.667 11h2.666c.369 0 .667.447.667 1 0 .552-.298 1-.667 1H2.667C2.298 13 2 12.552 2 12c0-.553.298-1 .667-1zm4.06-3.2l-2.31-1.333c-.319-.184-.801.054-1.077.533-.276.478-.242 1.015.077 1.2l2.31 1.333c.319.184.8-.055 1.077-.533.276-.479.242-1.015-.077-1.2zm11.546 6.667l2.31 1.334c.319.184.354.72.077 1.199-.276.478-.758.717-1.077.533l-2.31-1.334c-.318-.184-.353-.721-.077-1.2.276-.478.759-.716 1.077-.532zM12 18c-.553 0-1 .298-1 .667v2.666c0 .368.447.667 1 .667.553 0 1-.299 1-.667v-2.666c0-.369-.447-.667-1-.667zm6.667-7h2.666c.369 0 .667.447.667 1 0 .552-.298 1-.667 1h-2.666c-.369 0-.667-.448-.667-1 0-.553.298-1 .667-1z"
            clipRule="evenodd"
            key="key-0"
        />
    </Fragment>
);

const defaultProps = {
    children,
    viewBox,
    fillColor: null,
    fillColorRule: "&&& path, &&& use, &&& g, &&& circle",
    sizes,
    size: null
};

Image.propTypes /* remove-proptypes */ = {
    fillColor: PropTypes.string,
    fillColorRule: PropTypes.string,
    viewBox: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    size: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            height: PropTypes.number.isRequired,
            width: PropTypes.number.isRequired
        })
    ]),
    sizes: PropTypes.shape({
        height: PropTypes.number,
        width: PropTypes.number
    })
};

export default Object.assign(Image, {
    getDimensions,
    getCss,
    defaultProps,
    displayName: "Processing"
});
