import React from "react";

// context
import { useQueueContext } from "frontend/components/fort/mod/core/Layouts/QueueLayout/QueueContext.js";

// constants
import { UPLOAD_STATUS_BY_QUEUE } from "../../constants/uploadStatuses";

// components
import { Overview as CoreOverview } from "frontend/components/fort/mod/core/Sidebar/Overview/index.js";
import { Profile } from "frontend/components/fort/mod/core/Sidebar/Overview/Profile.js";
import { ReasonsForDenial } from "frontend/components/fort/mod/core/Sidebar/Overview/ReasonsForDenial.js";

import PhotoTitle from "./PhotoTitle";
import PhotoDescription from "./PhotoDescription";
import PhotoLocation from "./PhotoLocation";

//  "Visually Published", "Fully Published", or "Denied"
const overViewTitleMappings = [
    { needle: UPLOAD_STATUS_BY_QUEUE.VISUAL, displayText: "Visual Moderation" },
    { needle: UPLOAD_STATUS_BY_QUEUE.META, displayText: "Visually Published" },
    { needle: UPLOAD_STATUS_BY_QUEUE.FULL, displayText: "Fully Published" },
    { needle: UPLOAD_STATUS_BY_QUEUE.DENIED, displayText: "Denied" }
];

const getOverviewTitle = singleUploadSelected => {
    let overviewTitle = "Overview";
    if (singleUploadSelected) {
        const { status } = singleUploadSelected;

        overViewTitleMappings.forEach(mapping => {
            if (mapping.needle.includes(status)) {
                overviewTitle = `${overviewTitle} (${mapping.displayText})`;
            }
        });
    }

    return overviewTitle;
};

// component
export const Overview = () => {
    const {
        state: { singleUploadSelected }
    } = useQueueContext();

    return (
        <CoreOverview title={getOverviewTitle(singleUploadSelected)}>
            <Profile />
            <PhotoTitle />
            <PhotoDescription />
            <ReasonsForDenial />
            <PhotoLocation />
        </CoreOverview>
    );
};

export default Overview;
