import React from "react";
import styled from "styled-components";

import ProcessingSpinner from "core/ProcessingSpinner";
import colors from "core/colors";

import { convertPxToRem } from "frontend/components/helpers";

const Container = styled.div`
    display: flex;
    position: relative;
    width: 100%;
    aspect-ratio: 1 / 1;
    background-color: ${colors.primary.diamond};
`;

export const ProcessingContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const IndividualUploadLoader = () => {
    return (
        <Container>
            <ProcessingContainer>
                <ProcessingSpinner
                    iconColor={colors.secondary.onyx}
                    width={convertPxToRem({ px: 50 })}
                    height={convertPxToRem({ px: 50 })}
                />
            </ProcessingContainer>
        </Container>
    );
};

export default IndividualUploadLoader;
