if (!global._babelPolyfill) {
    require("@babel/polyfill");
}

import merge from "lodash/merge";
import union from "lodash/union";

import * as actionTypes from "../actions/actionTypes";

// Creates a reducer managing pagination, given the action types to handle,
// and a function telling how to extract the key from an action.
// Pagination section of the state tree keeps track of page count & fetching state.
export default function paginate({ types, mapActionToKey }) {
    if (!Array.isArray(types) || types.length !== 3) {
        throw new Error("Expected types to be an array of three elements.");
    }
    if (!types.every(t => typeof t === "string")) {
        throw new Error("Expected types to be strings.");
    }
    if (typeof mapActionToKey !== "function") {
        throw new Error("Expected mapActionToKey to be a function.");
    }

    const [requestType, successType, failureType] = types;

    function updatePagination(
        state = {
            isFetching: false,
            pageCount: 0
        },
        action
    ) {
        switch (action.type) {
            case actionTypes.SEARCH_RESET_ICONS:
                return merge({}, state, {
                    pageCount: 0
                });
            case requestType:
                return merge({}, state, {
                    isFetching: true
                });
            case successType:
                return merge({}, state, {
                    isFetching: false,
                    pageCount: action.page
                });
            case failureType:
                return merge({}, state, {
                    isFetching: false
                });
            default:
                return state;
        }
    }

    return function updatePaginationByKey(state = {}, action) {
        switch (action.type) {
            case actionTypes.SEARCH_RESET_ICONS:
            case requestType:
            case successType:
            case failureType:
                const key = mapActionToKey(action);
                if (typeof key !== "string") {
                    throw new Error("Expected key to be a string.");
                }
                return merge({}, state, {
                    [key]: updatePagination(state[key], action)
                });
            default:
                return state;
        }
    };
}
