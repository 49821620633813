import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// styles
import colors from "core/colors";
import { convertPxToRem } from "frontend/components/helpers";
import { regular as regularFontWeight } from "core/fontStyles";

const LabelValueStyled = styled.span`
    display: inline-block;
    font-size: ${convertPxToRem(11)};
    ${regularFontWeight};
    color: ${colors.secondary.orca};
    margin-right: ${convertPxToRem(12)};
    font-weight: 500;
`;

const LabelValue = ({ title, id }) => {
    return (
        <LabelValueStyled>
            <span>{title}</span> <span>{id}</span>
        </LabelValueStyled>
    );
};

export default LabelValue;

// prop-types
LabelValue.propTypes = {
    title: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
