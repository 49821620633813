import styled from "styled-components";

// components
import colors from "core/colors";
import Flexbox from "core/Flexbox";
import breakpoints from "core/breakpoints";

// helpers
import { convertPxToRem } from "frontend/components/helpers";

export const TitleContainer = styled.div`
    div:nth-of-type(1) {
        margin-bottom: ${convertPxToRem({ px: 5 })};
    }
`;

export const LoaderContainer = styled(Flexbox).attrs({
    flexDirection: "column"
})`
    width: 100%;
    height: 100%;
    display: block;
    background-color: ${colors.primary.diamond};
`;

export const LoaderHeader = styled(Flexbox).attrs({
    flexDirection: "column",
    justifyContent: "flex-start"
})`
    // MOBILE
    @media (max-width: ${breakpoints.beforeTablet.rem}) {
        padding: ${convertPxToRem({ px: 20 })} 0 0 ${convertPxToRem({ px: 20 })};
    }

    // TABLET
    @media (max-width: ${breakpoints.beforeDesktop.rem}) {
        padding: ${convertPxToRem({ px: 12 })} 0 0 ${convertPxToRem({ px: 12 })};
    }

    // DESKTOP
    @media (min-width: ${breakpoints.beforeDesktop.rem}) {
        padding: ${convertPxToRem({ px: 24 })} 0 0 ${convertPxToRem({ px: 24 })};
    }

    div:nth-of-type(1) {
        margin-bottom: ${convertPxToRem({ px: 8 })};
    }
`;

export const LoaderBox = styled(Flexbox).attrs({
    flexDirection: "column"
})`
    &:last-child {
        // MOBILE
        @media (max-width: ${breakpoints.beforeTablet.rem}) {
            margin-bottom: ${convertPxToRem({ px: 32 })};
        }

        // TABLET
        @media (max-width: ${breakpoints.beforeDesktop.rem}) {
            margin-bottom: ${convertPxToRem({ px: 25 })};
        }

        // DESKTOP
        @media (min-width: ${breakpoints.beforeDesktop.rem}) {
            margin-bottom: ${convertPxToRem({ px: 35 })};
        }
    }
`;

export const CircleContainer = styled(Flexbox).attrs({
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center"
})`
    padding-top: ${convertPxToRem({ px: 68 })};
`;
