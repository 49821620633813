import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { useViewport } from "js/hooks/useViewport";
import useIsomorphicLayoutEffect from "frontend/hooks/useIsomorphicLayoutEffect";
import {
    ProfileMenuDesktop,
    Wrapper as DesktopMenuWrapper,
    Content as DesktopContent
} from "components/ProfileMenuDesktop";
import ProfileMenuMobile from "components/ProfileMenuMobile";
import { LoggedInLinks } from "./LoggedInLinks";
import LoggedOutLinks from "./LoggedOutLinks";
import useApiStatus from "frontend/hooks/useApiStatus";

import {
    scrollToDesktopWithHero,
    scrollToDesktopNoHero
} from "core/SiteHeader/constants";
import Div from "core/Div";
import breakpoints from "core/breakpoints";
import { Wrapper } from "./styles";

const showAdminMenuCheck = ({ currentUser, myPhotoModProfile = {} }) => {
    const moderatorId = myPhotoModProfile?.moderatorId;
    const isSuperuser = currentUser?.isSuperuser;
    const showAdmin = !!(moderatorId || isSuperuser);
    return showAdmin;
};

let ProfileMenu = ({
    currentUser,
    myPhotoModProfile,
    loginLink,
    joinLink,
    handleLogOut,
    isHeroSearchV2
}) => {
    const [profileMenuIsOpen, setProfileMenuIsOpen] = useState(false);
    const toggleProfileMenu = () => setProfileMenuIsOpen(!profileMenuIsOpen);
    const closeProfileMenu = () => setProfileMenuIsOpen(false);
    const showAdminMenu = showAdminMenuCheck({
        currentUser,
        myPhotoModProfile
    });
    const { hasApiKey, getApiKeyStatus } = useApiStatus();

    const trigger = useRef();
    const desktopMenu = useRef();
    const mobileMenu = useRef();

    const loggedOut = !currentUser || currentUser.isAnonymous;

    const { browserWidth } = useViewport();
    const mobileBreakpoint = breakpoints.beforeTablet.px;

    useEffect(() => {
        if (profileMenuIsOpen && hasApiKey === null) {
            getApiKeyStatus();
        }
    }, [profileMenuIsOpen, hasApiKey]);

    useEffect(() => {
        const handleClick = e => {
            const clickedInsideDesktopMenu =
                desktopMenu && desktopMenu.current.contains(e.target);
            const clickedInsideMobileMenu =
                mobileMenu && mobileMenu.current.contains(e.target);
            const clickedTrigger =
                trigger && trigger.current.contains(e.target);

            if (
                clickedInsideMobileMenu ||
                clickedInsideDesktopMenu ||
                clickedTrigger
            ) {
                return;
            }

            closeProfileMenu();
        };

        if (profileMenuIsOpen) {
            document.addEventListener("mousedown", handleClick);
            if (browserWidth < mobileBreakpoint) {
                document.querySelector("body").style.overflowY = "hidden";
            }
        }

        return () => {
            document.removeEventListener("mousedown", handleClick);
            if (browserWidth < mobileBreakpoint) {
                document.querySelector("body").style.overflowY = "auto";
            }
        };
    }, [profileMenuIsOpen]);

    // this is to change the header styles on scroll when it is a hero search w/ dark background:
    const scrollPosition = isHeroSearchV2
        ? scrollToDesktopWithHero
        : scrollToDesktopNoHero;

    const handleScroll = () => {
        if (desktopMenu && desktopMenu.current) {
            if (window.pageYOffset > scrollPosition) {
                isHeroSearchV2 &&
                    desktopMenu.current.classList.add("scrolledDown");
                isHeroSearchV2 &&
                    desktopMenu.current.classList.remove("scrolledUp");
            } else if (window.pageYOffset < scrollPosition) {
                isHeroSearchV2 &&
                    desktopMenu.current.classList.remove("scrolledDown");
                isHeroSearchV2 &&
                    desktopMenu.current.classList.add("scrolledUp");
            }
        }
    };

    useIsomorphicLayoutEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", () => handleScroll);
        };
    }, []);

    // Wrap handleLogOut here so we can close the profile menu. This is a little
    // odd, but probably easier than the alternatives. Otherwise, if you click
    // "Log Out", then the back button, the profile menu can still be showing.
    const closeMenuAndHandleLogOut = () => {
        closeProfileMenu();
        handleLogOut();
    };

    return (
        <Wrapper open={profileMenuIsOpen} isHeroSearchV2={isHeroSearchV2}>
            {loggedOut ? (
                <LoggedOutLinks loginLink={loginLink} joinLink={joinLink} />
            ) : (
                <>
                    <Div
                        onClick={toggleProfileMenu}
                        ref={trigger}
                        data-testid="profile-menu-toggle"
                    >
                        <LoggedInLinks
                            userAvatarTitle={
                                currentUser && currentUser.username
                            }
                            userAvatarUrl={currentUser && currentUser.avatarUrl}
                            active={profileMenuIsOpen}
                        />
                    </Div>
                    <ProfileMenuDesktop open={profileMenuIsOpen}>
                        <DesktopMenuWrapper
                            ref={desktopMenu}
                            isHeroSearchV2={isHeroSearchV2}
                        >
                            <DesktopContent
                                logOut={closeMenuAndHandleLogOut}
                                user={currentUser}
                                hasApiKey={hasApiKey}
                                closeMenu={closeProfileMenu}
                                isHeroSearchV2={isHeroSearchV2}
                                showAdminMenu={showAdminMenu}
                            />
                        </DesktopMenuWrapper>
                    </ProfileMenuDesktop>
                    <div ref={mobileMenu}>
                        <ProfileMenuMobile
                            logOut={closeMenuAndHandleLogOut}
                            user={currentUser}
                            hasApiKey={hasApiKey}
                            closeMenu={closeProfileMenu}
                            showAdminMenu={showAdminMenu}
                        />
                    </div>
                </>
            )}
        </Wrapper>
    );
};

ProfileMenu.propTypes = {
    currentUser: PropTypes.object,
    myPhotoModProfile: PropTypes.object,
    loginLink: PropTypes.oneOfType([PropTypes.node, PropTypes.element])
        .isRequired,
    joinLink: PropTypes.oneOfType([PropTypes.node, PropTypes.element])
        .isRequired,
    handleLogOut: PropTypes.func.isRequired,
    isHeroSearchV2: PropTypes.bool
};

ProfileMenu = styled(ProfileMenu)``;
export { ProfileMenu };
