import React from "react";
import PropTypes from "prop-types";

import { modalSizes } from "core/props";

import Close from "core/ReusableIcons/Close";
import SrOnly from "core/SrOnly";

import { CloseButton } from "./styles";

const CloseModalButton = ({ handleClose, size, heading }) => {
    const closeButtonText = heading
        ? `Close the ${heading} modal window`
        : "Close the modal window";

    const iconSize = size === "fullScreen" ? { width: 24, height: 24 } : "sm";

    return (
        <CloseButton onClick={handleClose} heading={heading}>
            <SrOnly>{closeButtonText}</SrOnly>
            <Close size={iconSize} focusable={false} />
        </CloseButton>
    );
};

CloseModalButton.propTypes = {
    handleClose: PropTypes.func.isRequired,
    size: PropTypes.oneOf(modalSizes).isRequired,
    heading: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};

export default CloseModalButton;
