import React, {
    useCallback,
    useState,
    useEffect,
    createContext,
    useContext,
    useReducer,
    useMemo
} from "react";
import { useQuery, useMutation } from "@apollo/client";
import * as Sentry from "@sentry/react";

import {
    CANCEL_PHOTO_UPLOADS,
    REGISTER_PHOTO_UPLOADS,
    REPORT_AWS_PHOTO_UPLOAD_RESULT,
    REFRESH_PHOTO_UPLOAD_URLS
} from "./mutations";
import { LIST_PHOTO_UPLOADS_IN_DROPZONE } from "frontend/components/creator-tools/PhotoUploadPage/PhotoUploadCore/queries";

import { uploadToAWS } from "./helpers";
export const PhotoUploadContext = createContext();

import { useRateLimitError } from "../useRateLimitError";
import { usePhotoBatch } from "./usePhotoBatch";
import { useMessageStack } from "core/MessageStack/useMessageStack";

const MIN_FILE_SIZE = 1000000; // 1048576 = 1MB, but we would like to be a little more lenient and match BE
const MAX_FILE_SIZE = 52428800; // 50MB

// if true, logs actions & state changes
const DEBUG_MODE = false;
// most uploads to register in one API call
const REGISTER_BATCH_SIZE = 40;
// most files to upload to S3 in parallel
const UPLOAD_BATCH_SIZE = 10;

export const SUBMISSION_SUCCESS = "SUCCESS";
export const SUBMISSION_FAIL = "FAILURE";
export const SUBMISSION_ABORTED = "ABORTED";

const FAIL_MESSAGE = "Upload failed, please try again.";
const DUPLICATE_FILENAME_MESSAGE =
    "Duplicate file name(s). Rename to try again.";

const photoUploadActions = {
    ADD_UPLOADS: "ADD_UPLOADS",
    CLAIM_UPLOADS: "CLAIM_UPLOADS",
    REMOVE_UPLOADS: "REMOVE_UPLOADS",
    REMOVE_ALL_UPLOADS: "REMOVE_ALL_UPLOADS",
    SET_UPLOAD_DATA: "SET_UPLOAD_DATA",
    SET_UPLOAD_SUCCESS: "SET_UPLOAD_SUCCESS",
    SET_EXPIRED_URL: "SET_EXPIRED_URL",
    SET_UPLOAD_FAILURE: "SET_UPLOAD_FAILURE",
    SET_UPLOAD_COMPLETE: "SET_UPLOAD_COMPLETE",
    SET_UPLOAD_CANCELED: "SET_UPLOAD_CANCELED",
    SET_UPLOAD_ERROR: "SET_UPLOAD_ERROR",
    CLEAR_ERRORS: "CLEAR_ERRORS"
};

export const photoUploadStatus = {
    PENDING: "PENDING",
    UPLOADING: "UPLOADING",
    UPLOAD_SUCCESS: "UPLOAD_SUCCESS",
    UPLOAD_FAILURE: "UPLOAD_FAILURE",
    UPLOAD_COMPLETE: "UPLOAD_COMPLETE",
    // removed by user before upload completed
    UPLOAD_CANCELED: "UPLOAD_CANCELED",
    UPLOAD_ERROR: "UPLOAD_ERROR",
    EXPIRED_URL: "EXPIRED_URL",
    // validating with API
    AWAITING_FILE_CHECK: "AWAITING_FILE_CHECK",
    FILE_CHECK_IN_PROGRESS: "FILE_CHECK_IN_PROGRESS",
    // removed by user via API
    WITHDRAWN_BEFORE_SUBMISSION: "WITHDRAWN_BEFORE_SUBMISSION",
    // removed due to validation issues
    FILE_INVALID: "FILE_INVALID",
    FILE_CHECK_FAILED: "FILE_CHECK_FAILED",
    // validated on API & ready to edit
    IN_DROPZONE: "IN_DROPZONE"
};

// returns array of statuses and their claimed versions
function buildStatusArray(statuses = []) {
    return [...statuses, ...statuses.map(status => `_${status}`)];
}

const processingStatuses = buildStatusArray([
    photoUploadStatus.PENDING,
    photoUploadStatus.UPLOADING,
    photoUploadStatus.UPLOAD_SUCCESS,
    photoUploadStatus.UPLOAD_FAILURE,
    // after complete, we wait for API to validate
    // which happens via websocket
    photoUploadStatus.UPLOAD_COMPLETE,
    photoUploadStatus.AWAITING_FILE_CHECK,
    photoUploadStatus.FILE_CHECK_IN_PROGRESS
]);

const cancellableStatuses = buildStatusArray([
    photoUploadStatus.PENDING,
    photoUploadStatus.UPLOADING
]);

const doneStatuses = buildStatusArray([
    photoUploadStatus.UPLOAD_COMPLETE,
    photoUploadStatus.UPLOAD_CANCELED,
    photoUploadStatus.UPLOAD_ERROR
]);

export const hiddenStatuses = buildStatusArray([
    photoUploadStatus.UPLOAD_CANCELED,
    photoUploadStatus.UPLOAD_ERROR,
    photoUploadStatus.WITHDRAWN_BEFORE_SUBMISSION,
    photoUploadStatus.FILE_INVALID,
    photoUploadStatus.FILE_CHECK_FAILED
]);

const initialPhotoUpload = {
    id: null,
    file: null,
    filename: null,
    status: photoUploadStatus.PENDING,
    error: null,
    isDuplicateFilenameError: false,
    isRegistrationError: false,
    urlRefreshAttempts: 0,
    // distinguish between local and API uploads
    _clientState: true
};

// large initial fake ID to put uploads without IDs
// at the end of the list (IDs are used for sorting)
const FAKE_ID_INIT = 1000000;

const photoUploadReducer = (state, action) => {
    if (DEBUG_MODE) {
        console.info(action.type, action.payload);
    }

    const largestId = Math.max(
        ...state
            .filter(u => Number.isInteger(u.id))
            .map(upload => parseInt(upload.id))
    );

    let newState = null;

    switch (action.type) {
        case photoUploadActions.ADD_UPLOADS:
            newState = [
                // when initiating new upload, clear cancelled uploads
                ...state.filter(
                    upload =>
                        upload.status !== photoUploadStatus.UPLOAD_CANCELED
                ),
                ...action.payload.map((file, idx) => ({
                    ...initialPhotoUpload,
                    id:
                        largestId < FAKE_ID_INIT
                            ? String(FAKE_ID_INIT + idx)
                            : String(largestId + idx),
                    filename: file.name,
                    file
                }))
            ];
            break;
        case photoUploadActions.CLAIM_UPLOADS:
            newState = state.map(upload => {
                if (
                    !action.payload.includes(upload.filename) &&
                    !action.payload.includes(upload.id)
                ) {
                    return upload;
                }

                return {
                    ...upload,
                    status: `_${upload.status}`
                };
            });
            break;
        case photoUploadActions.REMOVE_UPLOADS:
            newState = state.filter(upload => {
                if (action.payload.includes(upload.filename)) {
                    return false;
                }

                return true;
            });
            break;
        case photoUploadActions.REMOVE_ALL_UPLOADS:
            newState = [];
            break;
        // this step adds unique IDs and S3 upload URLs to uploads
        // from this point forward, we can generally use ID rather than filename
        case photoUploadActions.SET_UPLOAD_DATA:
            newState = state.map(upload => {
                const update = action.payload.find(
                    p => p.filename === upload.filename
                );

                if (!update || doneStatuses.includes(upload.status)) {
                    return upload;
                }

                const { id, url } = update;

                return {
                    ...upload,
                    id,
                    url,
                    status: photoUploadStatus.UPLOADING
                };
            });
            break;
        case photoUploadActions.SET_UPLOAD_SUCCESS:
            newState = state.map(upload => {
                if (upload.id !== action.payload.id) {
                    return upload;
                }

                if (doneStatuses.includes(upload.status)) {
                    return upload;
                }

                return {
                    ...upload,
                    file: null,
                    status: photoUploadStatus.UPLOAD_SUCCESS
                };
            });
            break;
        case photoUploadActions.SET_EXPIRED_URL:
            newState = state.map(upload => {
                if (upload.id !== action.payload.id) {
                    return upload;
                }

                if (doneStatuses.includes(upload.status)) {
                    return upload;
                }

                if (upload.urlRefreshAttempts >= 3) {
                    console.error(
                        `Upload ${upload.id} has exceeded maximum URL refresh attempts.`
                    );

                    return {
                        ...upload,
                        status: photoUploadStatus.UPLOAD_FAILURE,
                        error: "Upload failed, please try again."
                    };
                }

                return {
                    ...upload,
                    status: photoUploadStatus.EXPIRED_URL,
                    urlRefreshAttempts: upload.urlRefreshAttempts + 1
                };
            });
            break;
        case photoUploadActions.SET_UPLOAD_FAILURE:
            newState = state.map(upload => {
                if (upload.id !== action.payload.id) {
                    return upload;
                }

                if (doneStatuses.includes(upload.status)) {
                    return upload;
                }

                return {
                    ...upload,
                    file: null,
                    error: action.payload.error || null,
                    status: photoUploadStatus.UPLOAD_FAILURE
                };
            });
            break;
        case photoUploadActions.SET_UPLOAD_COMPLETE:
            newState = state.map(upload => {
                if (upload.id !== action.payload.id) {
                    return upload;
                }

                return {
                    ...upload,
                    status: photoUploadStatus.UPLOAD_COMPLETE
                };
            });
            break;
        case photoUploadActions.SET_UPLOAD_ERROR:
            newState = state.map(upload => {
                const update = action.payload.find(
                    p => p.filename === upload.filename || p.id === upload.id
                );

                if (!update) {
                    return upload;
                }

                return {
                    ...upload,
                    status: photoUploadStatus.UPLOAD_ERROR,
                    error: update.error,
                    isDuplicateFilenameError:
                        update.error === DUPLICATE_FILENAME_MESSAGE,
                    isRegistrationError: update.isRegistrationError || false
                };
            });
            break;
        case photoUploadActions.SET_UPLOAD_CANCELED:
            newState = state.map(upload => {
                if (!action.payload.includes(upload.filename)) {
                    return upload;
                }

                // do not cancel uploads if file is already uploaded
                if (!cancellableStatuses.includes(upload.status)) {
                    return upload;
                }

                return {
                    ...upload,
                    file: null,
                    dataUrl: null,
                    status: photoUploadStatus.UPLOAD_CANCELED
                };
            });
            break;
        case photoUploadActions.CLEAR_ERRORS:
            newState = state.filter(upload => {
                if (upload.status !== photoUploadStatus.UPLOAD_ERROR) {
                    return true;
                }

                if (action.payload.isDuplicateFilenameError) {
                    return upload.isDuplicateFilenameError !== true;
                }

                if (action.payload.isRegistrationError) {
                    return upload.isRegistrationError !== true;
                }

                return false;
            });
            break;
        default:
            console.error(`Unhandled action type: ${action.type}`);
    }

    if (newState) {
        if (DEBUG_MODE) {
            console.info(newState);
        }
        return newState;
    }
    return state;
};

export const PhotoUploadProvider = ({
    children,
    // for test data
    initialPhotoUploads = [],
    photoShootId = null
}) => {
    const { handleError: handleRateLimitError } = useRateLimitError();
    const [validationErrors, setValidationErrors] = useState([]);

    // uploads pulled via API
    const { data: uploadsData, refetch: refetchUploadsData } = useQuery(
        LIST_PHOTO_UPLOADS_IN_DROPZONE,
        {
            onError: e => {
                if (DEBUG_MODE) {
                    console.error(e);
                }
                // provide error callback to prevent unhandled promise rejection
            }
        }
    );

    const {
        batch,
        batchError,
        shoot,
        isBatchLoaded,
        isShootLoaded,
        createPhotoBatch,
        createPhotoShoot
    } = usePhotoBatch();

    const currentPhotoShootId = (shoot && shoot.id) || photoShootId;
    const [isCancellingBatch, setIsCancellingBatch] = useState(false);
    const [photoUploads, dispatch] = useReducer(
        photoUploadReducer,
        initialPhotoUploads
    );
    const [queueLoopIsPaused, setQueueLoopIsPaused] = useState(false);

    const apiUploads =
        (uploadsData &&
            uploadsData.listPhotoUploads &&
            uploadsData.listPhotoUploads.items) ||
        [];

    const localUploadIds = photoUploads.map(u => u.id);

    const allUploads = [
        // prevent duplication by removing uploads that are already in state
        ...apiUploads
            .filter(u => !localUploadIds.includes(u.id))
            .map(u => ({ ...u, filename: u.originalFilename })),
        ...photoUploads
    ].sort((a, b) => a.id - b.id);

    // find most recent update for photos on server
    const latestUpdate =
        apiUploads
            .map(u => u.updatedAt)
            .sort()
            .reverse()[0] || 0;

    const newestThumbnail =
        apiUploads
            .map(u => parseInt(u?.thumbnail?.split("Expires=")?.[1] || "0"))
            .sort()
            .reverse()[0] || 0;

    // key should change whenever relevant data in photoUploads changes
    // upload ID, status, tag count, and latest update timestamp
    const memoizeKey =
        allUploads
            .map(u => `${u.id}-${u.status}-tags:${(u.userTags || []).length}`)
            .join(",") + `-${latestUpdate}-${newestThumbnail}`;

    // memoize uploads to prevent unnecessary re-renders
    const memoizedUploads = useMemo(() => {
        return allUploads;
    }, [memoizeKey]);

    useEffect(() => {
        if (batch === null) {
            dispatch({ type: photoUploadActions.REMOVE_ALL_UPLOADS });
        }
    }, [batch && batch.id, dispatch]);

    // build a deterministic string from the file upload statuses
    // if this changes, we know that the file statuses have changed
    const statusHash = Object.values(photoUploads)
        .map(upload => `${upload.filename}:${upload.status}`)
        .sort()
        .join(",");

    // ids of uploads that are ready to upload to S3 (in progress, or pending)
    // these are registered with API, and should be cancelled if user clears
    // processing uploads
    const uploadingIds = allUploads
        .filter(upload =>
            buildStatusArray([photoUploadStatus.UPLOADING]).includes(
                upload.status
            )
        )
        .map(upload => upload.id);

    // uploads that have not completed (may not be uploading yet)
    const currentlyProcessing = allUploads.filter(upload =>
        processingStatuses.includes(upload.status)
    );

    const currentlyProcessingFilenames = currentlyProcessing.map(
        u => u.filename
    );

    const cancellableUploads = allUploads.filter(upload =>
        cancellableStatuses.includes(upload.status)
    );

    //
    // Mutations
    //

    const [registerUploads] = useMutation(REGISTER_PHOTO_UPLOADS, {
        variables: {
            photoBatchId: batch && batch.id,
            photoShootId: currentPhotoShootId
        },
        onError: handleRateLimitError
    });

    const [refreshUrls] = useMutation(REFRESH_PHOTO_UPLOAD_URLS);

    const [cancelPhotoUploads] = useMutation(CANCEL_PHOTO_UPLOADS, {
        variables: { photoUploadIds: uploadingIds },
        onCompleted: ({ cancelPhotoUploads }) => {
            if (!cancelPhotoUploads.ok) {
                const respErrors = cancelPhotoUploads.photoUploads.map(
                    ({ errors }) => errors
                );
                Sentry.captureException(
                    new Error("Failed to cancel photo uploads"),
                    {
                        responseErrors: respErrors
                    }
                );
            }
        }
    });

    const [reportAwsPhotoUploadResult] = useMutation(
        REPORT_AWS_PHOTO_UPLOAD_RESULT
    );

    //
    // Actions
    //

    const addUploads = useCallback(
        (files = []) => {
            if (!files.length) return;

            dispatch({
                type: photoUploadActions.ADD_UPLOADS,
                payload: files
            });
        },
        [dispatch]
    );

    const clearErrors = useCallback(() => {
        dispatch({
            type: photoUploadActions.CLEAR_ERRORS,
            payload: {}
        });
    }, [dispatch]);

    const clearDuplicateFilenameErrors = useCallback(() => {
        dispatch({
            type: photoUploadActions.CLEAR_ERRORS,
            payload: { isDuplicateFilenameError: true }
        });
    }, [dispatch]);

    const clearProcessingUploads = useCallback(async () => {
        setQueueLoopIsPaused(true);

        let apiError = false;

        // withdraw any active uploads via API
        if (!!uploadingIds.length) {
            const resp = await cancelPhotoUploads();
            const respData = resp.data.cancelPhotoUploads;
            if (respData.ok === false) {
                apiError = true;
            }
        }

        const uploadsToCancel = cancellableUploads
            // if API error, don't cancel uploads that were registered with API
            .filter(u => {
                if (apiError && uploadingIds.includes(u.id)) {
                    return false;
                }
                return true;
            })
            // we don't need to cancel uploads from the API, just the local ones
            .filter(u => u._clientState)
            .map(u => u.filename);

        // mark any pending uploads as cancelled
        dispatch({
            type: photoUploadActions.SET_UPLOAD_CANCELED,
            payload: uploadsToCancel
        });

        // clear canceled uploads in Apollo cache
        await refetchUploadsData();

        setQueueLoopIsPaused(false);

        return apiError;
    }, [
        currentlyProcessingFilenames,
        uploadingIds,
        cancelPhotoUploads,
        dispatch
    ]);

    // Remove uploads from reducer state. After upload, they will be fetched and stored in
    // Apollo state; including those with file check errors.
    const removeUploads = useCallback((filenames = []) => {
        if (!filenames.length) return;

        dispatch({
            type: photoUploadActions.REMOVE_UPLOADS,
            payload: filenames
        });
    });

    //
    // Internal functions
    //

    const claimUploads = useCallback(
        (status, limit = null) => {
            let uploads = photoUploads.filter(
                upload => upload.status === status
            );

            if (uploads.length === 0) return [];

            if (limit) {
                uploads = uploads.slice(0, limit);
            }

            dispatch({
                type: photoUploadActions.CLAIM_UPLOADS,
                payload: uploads.map(upload => upload.id || upload.filename)
            });

            return uploads;
        },
        [photoUploads, dispatch, statusHash]
    );

    // Notify API that we are about to upload files to S3
    const registerPhotoUploads = useCallback(
        async ({ filenames, photoShootId }) => {
            const filenamesFiltered = filenames.filter(f =>
                currentlyProcessingFilenames.includes(f)
            );

            const resp = await registerUploads({
                variables: {
                    filenames: filenamesFiltered,
                    photoBatchId: batch && batch.id,
                    photoShootId
                }
            });

            if (!resp || !resp.data) {
                // assume all files failed to register, and move them into failed state
                dispatch({
                    type: photoUploadActions.SET_UPLOAD_ERROR,
                    payload: filenamesFiltered.map(filename => ({
                        filename,
                        error: "Unable to upload. Please try again.",
                        isRegistrationError: true
                    }))
                });
                return { data: null };
            }

            const respErrors = resp.data.photoUpload.errors || [];

            if (respErrors.length) {
                dispatch({
                    type: photoUploadActions.SET_UPLOAD_ERROR,
                    payload: resp.data.photoUpload.errors.map(
                        ({ filename, reason }) => ({
                            filename,
                            error: reason,
                            isRegistrationError: true
                        })
                    )
                });
            }

            return resp;
        },
        [currentlyProcessingFilenames, registerUploads, photoUploads]
    );

    // Given upload ID, confirm with backend that S3 upload was successful or failed
    const reportAwsUploadResult = useCallback(
        async ({ id, uploadSuccess }) => {
            return reportAwsPhotoUploadResult({
                variables: {
                    id,
                    result: uploadSuccess ? SUBMISSION_SUCCESS : SUBMISSION_FAIL
                }
            })
                .then(({ data }) => {
                    if (
                        data &&
                        data.photoUploadFinishedNotification &&
                        data.photoUploadFinishedNotification.error
                    ) {
                        return data.photoUploadFinishedNotification.error;
                    }
                })
                .catch(() => {
                    return FAIL_MESSAGE;
                });
        },
        [reportAwsPhotoUploadResult, statusHash]
    );

    // initialises upload with API and gets S3 upload URL
    const startUploadBatch = useCallback(async () => {
        const uploadsValidated = claimUploads(
            photoUploadStatus.PENDING,
            REGISTER_BATCH_SIZE
        );

        if (uploadsValidated.length === 0) return;

        const filenames = uploadsValidated.map(upload => upload.filename);

        const { data } = await registerPhotoUploads({
            filenames,
            photoShootId: currentPhotoShootId
        });

        const uploads = (data && data.photoUpload.uploads) || [];

        if (!uploads || !uploads.length) {
            // errors handled elsewhere
            return;
        }

        dispatch({
            type: photoUploadActions.SET_UPLOAD_DATA,
            payload: uploads.map(({ id, filename, url }) => ({
                id,
                filename,
                url
            }))
        });
    }, [photoUploads, dispatch, registerPhotoUploads, currentPhotoShootId]);

    // Upload individual file to S3 & handle response
    const uploadAndDispatchResult = useCallback(
        async ({ id, file, url }) => {
            const { ok: uploadSuccess, status } = await uploadToAWS({
                url,
                file,
                contentType: "image/jpeg"
            });

            if (uploadSuccess === false && status === 403) {
                // AWS 403 error, assume signed URL expired
                dispatch({
                    type: photoUploadActions.SET_EXPIRED_URL,
                    payload: { id }
                });
            } else if (uploadSuccess === false) {
                // AWS upload failure - use generic error message
                dispatch({
                    type: photoUploadActions.SET_UPLOAD_FAILURE,
                    payload: { id, error: FAIL_MESSAGE }
                });
            } else {
                // API upload success
                dispatch({
                    type: photoUploadActions.SET_UPLOAD_SUCCESS,
                    payload: { id }
                });
            }
        },
        [dispatch]
    );

    // Upload files to S3 in parallel
    const uploadFiles = useCallback(() => {
        const uploadsInProgress = photoUploads.filter(
            u => u.status === `_${photoUploadStatus.UPLOADING}`
        );

        // wait until previous batch is almost done to initiate next batch
        if (uploadsInProgress.length >= 2) {
            return;
        }

        const readyForUpload = claimUploads(
            photoUploadStatus.UPLOADING,
            UPLOAD_BATCH_SIZE
        );

        if (!readyForUpload.length) return;

        Promise.all(readyForUpload.map(uploadAndDispatchResult));
    }, [statusHash, dispatch, photoUploads, uploadAndDispatchResult]);

    const refreshTokens = useCallback(async () => {
        const uploadsExpired = claimUploads(photoUploadStatus.EXPIRED_URL);

        if (!uploadsExpired.length) return;

        const { data } = await refreshUrls({
            variables: {
                photoUploadIds: uploadsExpired.map(({ id }) => id)
            }
        });

        const uploads = data.getNewUploadUrlsForPhotoUploads || [];

        if (!uploads || !uploads.length) {
            return;
        }

        dispatch({
            type: photoUploadActions.SET_UPLOAD_DATA,
            payload: uploads.map(({ id, filename, url }) => ({
                id,
                filename,
                url
            }))
        });
    }, [dispatch, statusHash]);

    // notify API that upload to S3 succeeded
    const completeSuccessfulUploads = useCallback(async () => {
        const successUploads = claimUploads(photoUploadStatus.UPLOAD_SUCCESS);

        if (!successUploads.length) return;

        for (const { id } of successUploads) {
            const errorMessage = await reportAwsUploadResult({
                id,
                uploadSuccess: true
            });

            if (errorMessage) {
                // API upload completion error
                dispatch({
                    type: photoUploadActions.SET_UPLOAD_ERROR,
                    payload: [{ id, error: errorMessage }]
                });
            } else {
                // API upload success
                dispatch({
                    type: photoUploadActions.SET_UPLOAD_COMPLETE,
                    payload: { id }
                });
            }
        }
    }, [reportAwsUploadResult, dispatch, statusHash]);

    // notify API that upload to S3 failed
    const completeFailedUploads = useCallback(async () => {
        const failureUploads = claimUploads(photoUploadStatus.UPLOAD_FAILURE);

        if (!failureUploads.length) return;

        for (const { id } of failureUploads) {
            // TODO: if this request fails, the API will think it is still uploading.
            // Not sure what the best way to handle this is, but we may need to withdraw
            // the upload at this point.
            await reportAwsUploadResult({
                id,
                uploadSuccess: false
            });

            dispatch({
                type: photoUploadActions.SET_UPLOAD_ERROR,
                payload: [{ id, error: FAIL_MESSAGE }]
            });
        }
    }, [reportAwsUploadResult, dispatch, statusHash]);

    useEffect(() => {
        // helps avoid race conditions while cancelling in progress uploads
        if (queueLoopIsPaused) return;
        startUploadBatch();
        uploadFiles();
        refreshTokens();
        completeSuccessfulUploads();
        completeFailedUploads();
    }, [statusHash, queueLoopIsPaused]);

    const contextValues = {
        // actions
        createPhotoBatch,
        createPhotoShoot,
        uploadPhotos: addUploads,
        refetchUploadsData,
        setIsCancellingBatch,
        // complete list of uploads
        photoUploads: memoizedUploads,
        // batch data
        photoBatch: batch,
        phootShoot: shoot,
        isBatchLoaded,
        isShootLoaded,
        currentPhotoShootId,
        photoBatchError: batchError,
        isCancellingBatch,
        // upload errors
        validationErrors,
        setValidationErrors,
        clearErrors,
        clearDuplicateFilenameErrors,
        // currently processing
        currentlyProcessing,
        removeUploads,
        // cancel uploading
        clearProcessingUploads
    };

    return (
        <PhotoUploadContext.Provider value={contextValues}>
            {children}
        </PhotoUploadContext.Provider>
    );
};

// returns three arrays of uploads in error states
// * uploadErrors: all errors
// * uploadRegistrationErrors: errors that occurred during registration
// * duplicateFilenameErrors: errors that occurred due to duplicate filenames
function getUploadErrors(photoUploads, validationErrors) {
    const uploadErrors = [].concat(
        photoUploads
            .filter(upload => upload.status === photoUploadStatus.UPLOAD_ERROR)
            .map(({ id, filename, error }) => ({
                id,
                filename,
                error
            })),
        validationErrors.map(({ filename, message }) => ({
            filename,
            error: message
        }))
    );

    const uploadRegistrationErrors = photoUploads
        .filter(
            upload =>
                upload.status === photoUploadStatus.UPLOAD_ERROR &&
                upload.isRegistrationError
        )
        .map(({ id, filename, error }) => ({
            id,
            filename,
            error
        }));

    const duplicateFilenameErrors = photoUploads
        .filter(
            upload =>
                upload.status === photoUploadStatus.UPLOAD_ERROR &&
                upload.isDuplicateFilenameError
        )
        .map(({ id, filename, file, error }) => ({
            id,
            filename,
            file,
            error
        }));

    return { uploadErrors, uploadRegistrationErrors, duplicateFilenameErrors };
}

export const usePhotoUpload = () => {
    const context = useContext(PhotoUploadContext);
    const { addMessages } = useMessageStack();

    if (context === undefined) {
        throw new Error(
            "usePhotoUpload must be used within a PhotoUploadProvider"
        );
    }

    const { photoUploads, validationErrors } = context;

    // can still be cancelled by user (upload not yet complete)
    const cancellableUploads = photoUploads.filter(upload =>
        cancellableStatuses.includes(upload.status)
    );
    // upload complete, API still validating
    const completedUploads = photoUploads.filter(
        u => u.status === photoUploadStatus.UPLOAD_COMPLETE
    );
    // validated by API, ready to edit metadata
    const editableUploads = photoUploads.filter(
        u => u.status === photoUploadStatus.IN_DROPZONE
    );

    const {
        uploadErrors,
        uploadRegistrationErrors,
        duplicateFilenameErrors
    } = getUploadErrors(photoUploads, validationErrors);

    const errorCount = uploadErrors.length;
    const hasErrors = errorCount > 0;

    // if there are errors, show toast in message stack
    // generally, this will only be shown when errors *first* occur
    useEffect(() => {
        if (hasErrors) {
            addMessages([
                {
                    id: "photo-upload-error",
                    infoType: "error",
                    text:
                        "File(s) failed to upload. Click “See Details” below for more information."
                }
            ]);
        }
    }, [hasErrors]);

    return {
        ...context,
        totalCancellable: cancellableUploads.length,
        cancellableUploads,
        completedUploads,
        editableUploads,
        uploadErrors,
        uploadRegistrationErrors,
        duplicateFilenameErrors
    };
};
