import $ from "jquery";
import * as Sentry from "@sentry/browser";
import {
    sendPageView,
    sendEvent,
    setAccountType
} from "../../utils/google-analytics";

import { ALACARTE_TYPE } from "../../reducers/stripe";

let Metrics = (() => {
    class Metrics {
        constructor() {
            this.download_flow_purchase_success = this.download_flow_purchase_success.bind(
                this
            );
            this.download_flow_purchase_failure = this.download_flow_purchase_failure.bind(
                this
            );
        }
        download(download_type, file_type, icon) {
            sendEvent("download", download_type, file_type);
            sendPageView("/icon/download/complete/");

            if (window._nounConfig.collectSearchMetrics) {
                let searchParams;
                if (icon && icon.searchData) {
                    searchParams = {
                        icon_id: icon.id,
                        search_query: icon.searchData.query,
                        list_index: icon.searchData.resultIndex,
                        more_like_this_id: icon.searchData.moreLikeThisId,
                        collection_list_index: icon.searchData.collectionIndex
                    };
                    if (
                        searchParams.search_query !== null ||
                        searchParams.more_like_this_id !== null
                    ) {
                        return $.post(
                            "/log_search_download/",
                            searchParams
                        ).fail((xhr, status, error) => console.warn(error));
                    }
                }
            }
        }

        download_failed(download_type, icon) {
            sendEvent("download", "failed", download_type, icon);
            sendPageView("/icon/download/failed/");
        }

        upgrade(data, redirect) {
            let path;
            let subscription;
            subscription = data.subscription;
            path = `/accounts/upgrade/${subscription.plan.type}/complete/`;
            sendPageView(path);
            return sendEvent(
                "account",
                "upgrade",
                subscription.plan.name,
                redirect !== void 0
                    ? {
                          hitCallback() {
                              window.location.href = redirect;
                          }
                      }
                    : void 0
            );
        }

        signup_form(account_type) {
            if (account_type == null) {
                account_type = "basic";
            }
            return sendPageView(`/accounts/signup/${account_type}/`);
        }

        signup_complete() {
            return sendPageView("/accounts/signup/basic/complete/");
        }

        license_options(icon_id) {
            return sendPageView(`/icon/${icon_id}/download-options/`);
        }

        purchase_form(icon_id) {
            return sendPageView(`/icon/${icon_id}/purchase/`);
        }

        upgrade_form(account_type) {
            if (account_type == null) {
                account_type = "pro";
            }
            return sendPageView(`/accounts/upgrade/${account_type}/`);
        }

        icon_hero(icon_id, title, phaseJumping) {
            if (!phaseJumping && icon_id) {
                sendPageView(`/icon/${icon_id}/`, { title });
            }
        }

        downgrade(user_id) {
            return sendEvent("account", "downgrade", user_id);
        }

        page_depth(page) {
            return sendPageView(window.location.pathname);
        }

        term_detail_arrow_keypress(permalink) {
            return sendEvent("term_detail", "keypress", permalink);
        }

        tooltip_share(permalink) {
            return sendEvent("tooltip", "share", permalink);
        }

        failed_upgrade(user_id, data) {
            sendEvent("upgrade", "failed", user_id);
            Sentry.setUser({
                id: user_id
            });

            try {
                data = JSON.stringify(data);
            } catch (e) {}

            return Sentry.captureException(new Error(data), {
                tags: {
                    impact: "Failed upgrade"
                }
            });
        }

        failed_cardsave(user_id, data) {
            sendEvent("cardsave", "failed", user_id);
            Sentry.setUser({
                id: user_id
            });

            try {
                data = JSON.stringify(data);
            } catch (e) {}

            return Sentry.captureException(new Error(data), {
                tags: {
                    impact: "Failed card save"
                }
            });
        }

        failed_alacarte(user_id, data) {
            sendEvent("alacarte", "failed", user_id);
            Sentry.setUser({
                id: user_id
            });
            return Sentry.captureException(new Error(JSON.stringify(data)), {
                tags: {
                    impact: "Failed a la carte purchase"
                }
            });
        }

        hide_lingo_upsell(user_id) {
            sendEvent("lingo_upsell", "do_not_show", user_id);
        }

        close_lingo_upsell(user_id) {
            sendEvent("lingo_upsell", "hide", user_id);
        }

        lingo_cta_click(user_id) {
            sendEvent("lingo_upsell", "cta_click", user_id);
        }

        set_user_dimension(user) {
            let { is_anonymous, has_premium_account } = user;

            if (is_anonymous) {
                setAccountType("Anonymous");
            } else if (has_premium_account) {
                setAccountType("Paid");
            } else {
                setAccountType("Free");
            }
        }

        set_experiments_dimension(experiments) {
            sendEvent("editor", "type", experiments.editor);
            Sentry.setContext(experiments); // doesn't seem to actually be used anywhere
        }

        select_attribution(attributionType) {
            return sendEvent("download", "select_attribution", attributionType);
        }

        select_license(license) {
            sendEvent("download", "select_license", license);
        }

        upsell_result(result) {
            sendEvent("download", "upsell", result);
        }

        download_flow_purchase_success(purchaseType) {
            if (purchaseType.period) {
                this.upgrade({
                    subscription: {
                        plan: {
                            type: purchaseType.key,
                            name: purchaseType.text
                        }
                    }
                });
            }

            let url;
            if (purchaseType.type == ALACARTE_TYPE) {
                url = "/icon/purchase/complete/";
            } else {
                url = `/purchase/${purchaseType.type}/complete/`;
            }
            return sendPageView(url);
        }

        download_flow_purchase_failure(userId, purchaseType, err) {
            if (purchaseType && purchaseType.period) {
                this.failed_upgrade(userId, err);
            } else {
                this.failed_alacarte(userId, err);
            }
        }

        download_flow_color_select(download, color) {
            if (download.active) {
                sendEvent("download", "select_color", color);
            }
        }

        editor_transform(transform, data) {
            sendEvent("editor.transform", transform, data);
        }

        editor_export(format, transforms = {}) {
            sendEvent("editor.export", format, JSON.stringify(transforms));
        }

        editor_export_failed(format, transforms = {}) {
            sendEvent(
                "editor.export.failed",
                JSON.stringify(transforms),
                format
            );
        }

        send_event(category, action, label) {
            sendEvent(category, action, label);
        }
        send_pageview(path, meta) {
            sendPageView(path, meta);
        }
        download_flow_pageview(downloadPhase, phaseJumping) {
            if (downloadPhase && !phaseJumping) {
                let downloadPage = downloadPhase
                    .toLowerCase()
                    .replace(/_/g, "-");
                sendPageView(`/icon/download-flow/${downloadPage}/`);
            }
        }
        report_empty_searches(searchTerm, isEmpty) {
            if (isEmpty) {
                sendPageView(
                    `/search/empty/${searchTerm}/?q=${encodeURIComponent(
                        searchTerm
                    )}`,
                    { title: "No results found" }
                );
            }
        }
    }

    return Metrics;
})();

export default Metrics;
