import * as actionTypes from "../actions/actionTypes";

const defaultState = {
    error: null,
    fieldErrors: {},
    teamName: "",
    login: "",
    password: "",
    processing: false,
    receiveUpdates: false,
    acceptTerms: false
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case actionTypes.SET_GLOBAL_CONFIG: {
            let { config } = action;
            let {
                captchaKey,
                accountSignupRequireCaptcha,
                accountLoginRequireCaptcha
            } = config;
            return {
                ...state,
                captchaKey,
                accountSignupRequireCaptcha,
                accountLoginRequireCaptcha
            };
        }
        case actionTypes.LOGIN:
        case actionTypes.SIGNUP:
            return {
                ...state,
                error: null,
                processing: true,
                fieldErrors: {}
            };

        case actionTypes.AUTH_FORM_INPUT_CHANGE:
            return {
                ...state,
                ...action.input
            };

        case actionTypes.AUTH_FORM_ERROR: {
            let { error: fieldError, field } = action;
            let fieldErrors = state.fieldErrors || {};
            let { error } = state;
            if (field != "general") {
                fieldErrors[field] = fieldError;
            } else {
                error = fieldError;
            }
            return {
                ...state,
                fieldErrors: { ...fieldErrors },
                error
            };
        }

        case actionTypes.LOGIN_SUCCESS:
        case actionTypes.SIGNUP_SUCCESS:
            return {
                ...state,
                processing: false
            };

        case actionTypes.LOGIN_FAILURE:
        case actionTypes.SIGNUP_FAILURE:
            let error;
            let fieldErrors = {};

            const { responseJSON } = action.error;
            const generalError =
                "Something went wrong, please try again in a little bit.";
            if (!responseJSON) {
                error = generalError;
            } else {
                if (responseJSON.form) {
                    const errors = responseJSON.form.errors;
                    if (errors && errors.length) {
                        error = errors[0];
                    }

                    for (let key in responseJSON.form.fields) {
                        let field = responseJSON.form.fields[key];
                        if (field.errors && field.errors.length) {
                            fieldErrors[key] = field.errors[0];
                        }
                    }
                } else {
                    error = generalError;
                }
            }

            return {
                ...state,
                processing: false,
                error: error || state.error,
                fieldErrors
            };

        case actionTypes.AUTH_FORM_CLEAR_ERRORS:
            return {
                ...state,
                error: null,
                fieldErrors: {}
            };

        case actionTypes.AUTH_FORM_CLEAR_DATA:
            return {
                ...state,
                teamName: "",
                login: "",
                password: "",
                receiveUpdates: false,
                acceptTerms: false
            };

        default:
            return state;
    }
};
