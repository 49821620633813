import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";

// core
import { useMessageStack } from "frontend/components/core/MessageStack/useMessageStack.js";

// redux
import { messagesSlice } from "../slices/messagesSlice.js";

// MessageStackMountingWrapper
const MessageStackMountingWrapper = ({ children }) => {
    const { addMessages } = useMessageStack();
    const dispatch = useDispatch();
    const { messages } = useSelector(state => state.messages);

    useEffect(() => {
        messages.forEach(message => {
            addMessages([message]);
            dispatch(messagesSlice.actions.removeMessage(message.id));
        });
    }, [messages]);

    return <>{children}</>;
};

// prop-types
MessageStackMountingWrapper.propTypes = {
    children: PropTypes.any
};

export default MessageStackMountingWrapper;
