import PropTypes from "prop-types";

// helpers
import { cuuid } from "core/helpers";

export const dropZoneFileErrors = ({
    hasRejectedFileErrors,
    rejectedFilesCount,
    initialMaximumFiles,
    addMessagesToMessageStack
}) => {
    const TOO_MANY_FILES = "too-many-files";

    if (rejectedFilesCount === 0) return null;

    const maxLimitUploadError = `You can include up to ${initialMaximumFiles} photos in this submission.`;

    const hasTooManyFiles =
        hasRejectedFileErrors
            ?.map(res => res.errors) // map over array of files with errors
            ?.flatMap(error => error) // map over objects inside errors array
            ?.filter(theError => theError.code === TOO_MANY_FILES) || []; // make sure the error code is "TOO_MANY_FILES"

    //if error === too many files, only return one banner:
    if (hasTooManyFiles.length > 0) {
        addMessagesToMessageStack([
            {
                id: TOO_MANY_FILES,
                infoType: "error",
                text: maxLimitUploadError,
                timed: true,
                timeout: 4000 // 4 seconds
            }
        ]);
    }

    // return banners for each of the other types of errors:
    return hasRejectedFileErrors?.map(file => {
        return file.errors.map(error => {
            const fileInvalidError = `${error.message}. Please remove "${file.name}" and try again.`;

            addMessagesToMessageStack([
                {
                    id: cuuid(10),
                    infoType: "error",
                    text: fileInvalidError,
                    timed: true,
                    timeout: 4000 // 4 seconds
                }
            ]);
        });
    });
};

dropZoneFileErrors.propTypes = {
    hasRejectedFileErrors: PropTypes.array.isRequired,
    rejectedFilesCount: PropTypes.number.isRequired,
    initialMaximumFiles: PropTypes.number.isRequired
};
