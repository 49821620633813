import { useLazyQuery } from "@apollo/client";
import { IS_API_USER } from "./queries";

const useApiStatus = () => {
    // useLazyQuery so that we can load the data when user opens profile menu
    const [getApiKeyStatus, { data: apiKeyStatusData }] = useLazyQuery(
        IS_API_USER
    );

    // null if data is not fetched, otherwise boolean
    const hasApiKey = apiKeyStatusData?.isApiUser?.hasApiKey ?? null;

    return {
        getApiKeyStatus,
        hasApiKey
    };
};

export default useApiStatus;
