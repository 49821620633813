import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import Tab from "./Tab";

import { TabsContainer } from "./styles";

let Tabs = ({ onTabClick, tabData, defaultTabIndex = 0, className }) => {
    const history = useHistory();
    const [activeTabIndex, setActiveTabIndex] = useState(defaultTabIndex);

    useEffect(() => {
        if (activeTabIndex === defaultTabIndex) return;

        const activeTabData = tabData[activeTabIndex];
        const { useRouterLink, href } = activeTabData;
        if (!!href) {
            useRouterLink ? history.push(href) : window.location.assign(href);
        }
    }, [activeTabIndex]);

    return (
        <TabsContainer data-testid="tabs" className={className}>
            {tabData.map((tab, index) => (
                <Tab
                    handleClick={() => {
                        if (activeTabIndex !== index) {
                            !!onTabClick && onTabClick(index);
                        }

                        setActiveTabIndex(index);
                    }}
                    key={tab.name}
                    isActive={activeTabIndex === index}
                    icon={tab.icon}
                >
                    {tab.name}
                </Tab>
            ))}
        </TabsContainer>
    );
};

Tabs.propTypes = {
    tabData: PropTypes.array.isRequired,
    defaultTabIndex: PropTypes.number,
    onTabClick: PropTypes.func
};

Tabs = styled(Tabs)``;

export default Tabs;
