import gql from "graphql-tag";

export const GET_ICON_COLLECTIONS = gql`
    query collectionSearch(
        $query: String!
        $limit: Int
        $offset: Int
        $iconsPerCollectionLimit: Int
    ) {
        collectionSearch(query: $query, limit: $limit, offset: $offset) {
            items {
                id
                name
                slug
                url
                icons(limit: $iconsPerCollectionLimit) {
                    items {
                        id
                        title
                        thumbnails {
                            thumbnail84
                        }
                    }
                    totalCount
                }
            }
            totalCount
        }
    }
`;
