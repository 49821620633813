import React, { useEffect, useRef } from "react";
import styled from "styled-components";

// context
import { useManageTags } from "core/ManageTags/context/useManageTags.js";

// styles
import { convertPxToRem } from "frontend/components/helpers";
import colors from "core/colors";

const SuggestionsStyled = styled.div`
    position: absolute;
    z-index: 1;
    left: 0px;
    right: 0px;
    top: ${convertPxToRem(28)};
    background-color: ${colors.primary.snow};

    border: solid 1px ${colors.primary.diamond};
    border-top: none;
    max-height: ${convertPxToRem(264)};
    overflow: auto;

    box-shadow: 0px 4px 10px 0px rgb(0 0 0 / 10%);
`;

// components
import Suggestion from "./Suggestion";

// utils
import { focusPrevOption, focusNextOption } from "./utils";

// component
export const Suggestions = () => {
    const { id, suggestions, dispatch, actions } = useManageTags();

    // This is all so we only add window.addEventListener onMount
    // this allows us to ref.current in methods passed to DOM listeners without overloading window.addEventListener
    const suggestionsRef = useRef();
    useEffect(() => {
        suggestionsRef.current = suggestions;
    }, [suggestions]);

    /*---------------------------
    | WOW
    ---------------------------*/
    useEffect(() => {
        const onKeyDown = event => {
            if (event.key === "Escape") {
                dispatch(actions.setShowSuggestionDropDown(false));
            }

            switch (event.key) {
                case "ArrowUp":
                    focusPrevOption(suggestionsRef.current, id);
                    break;
                case "ArrowDown":
                    focusNextOption(suggestionsRef.current, id);
                    break;
                default:
                    return;
            }
        };

        // apparently keydown should use window, and focus should use document.
        window.addEventListener("keydown", onKeyDown);

        return () => {
            window.removeEventListener("keydown", onKeyDown);
        };
    }, []);

    const suggWrapperId = `add-tag-suggestions-${id}`;

    return (
        <SuggestionsStyled
            id={suggWrapperId}
            data-testid={suggWrapperId}
            role="listbox"
            aria-label="Tag Suggestions"
        >
            {suggestions.map(suggestion => {
                return (
                    <Suggestion key={suggestion.id} suggestion={suggestion} />
                );
            })}
        </SuggestionsStyled>
    );
};

export default Suggestions;
