import {
    getUploadsSelectedFromUIDs,
    convertUidsToSelectedUidObj,
    setSideEffects
} from "./utils";

// See ../readme.md for details
const getDefaultState = (defaultState, defaultUploadIdsSelected = []) => {
    const { uploadIds } = defaultState;

    const uploadsSelected = getUploadsSelectedFromUIDs(
        convertUidsToSelectedUidObj(defaultUploadIdsSelected),
        uploadIds
    );

    return {
        ...defaultState,
        uploadsSelected: uploadsSelected,
        prevSelectedDirection: "right",
        lastSelectedId: null,
        ...setSideEffects(uploadsSelected)
    };
};

export default getDefaultState;
