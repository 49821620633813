import { createSlice, createSelector } from "@reduxjs/toolkit";

///////////////////////////
//   reducer & actions   //
///////////////////////////

// State will be the user object set by backend, with the following shape:
// {
//     "__typename": "CurrentUserType",
//     "id": null,
//     "username": "",
//     "email": null,
//     "avatarUrl": null,
//     "featureFlags": [...],
//     "isAnonymous": true,
//     "isEduApproved": false,
//     "isCreator": false,
//     "isIconCreator": null,
//     "isPhotoCreator": null,
//     "isSuperuser": false,
//     "hasNotifications": false,
//     "hasSubscription": false,
//     "ownedTeams": []
// }
const initialState = {};

const user = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUser(state, action) {
            return action.payload;
        }
    }
});

export default user.reducer;

export const { setUser } = user.actions;

////////////////////////////
//   selector-functions   //
////////////////////////////

const selectSelf = state => state.user;

export const getUser = createSelector(selectSelf, state => state);

export const getIsAnonymous = createSelector(
    selectSelf,
    state => state.isAnonymous
);

export const hasSubscription = createSelector(
    selectSelf,
    state => state.hasSubscription
);
