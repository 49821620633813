import gql from "graphql-tag";

export const GET_PHOTO_ROYALTIES_STATEMENT_ALL = gql`
    query photoRoyaltyStatementsAndTotal($username: String!) {
        photoRoyaltyStatementsAndTotal(username: $username) {
            ok
            errors
            statements {
                items {
                    month
                    totalEarned
                    totalPaid
                    insightsExist
                    freeDownloadCount
                    largeSales
                    fullResSales
                    premiumPhotoSales
                    standardPhotoSales
                    creatorProDownloads
                    largePhotoEarnings
                    fullResPhotoEarnings
                    standardPhotoEarnings
                    premiumPhotoEarnings
                    creatorProEarnings
                }
                totalCount
            }
        }
    }
`;
