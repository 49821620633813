import React from "react";

import colors from "core/colors";

import SectionContainer from "core/SectionContainer";
import SocialProof from "core/SocialProof";
import HeroSection from "./HeroSection";
import BulletSection from "./BulletSection";
import ProductFundamentalSection from "./ProductFundamentalSection";
import LingoMarketingPageMeta from "./LingoMarketingPageMeta";

import { brandLogos } from "./data";

const LingoMarketingPage = () => (
    <>
        <LingoMarketingPageMeta />
        <HeroSection />
        <BulletSection />
        <SectionContainer bgColor={colors.primary.diamond}>
            <SocialProof icons={brandLogos} />
        </SectionContainer>
        <ProductFundamentalSection />
    </>
);

export default LingoMarketingPage;
