import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

const createHelpers = (width, height, css) => {
    // somehow sizes is ending up in markup, even if it is not a valid svg attribute
    // until we have a better solution, just render it empty, instead to '[Object object]'
    const sanitizeSizes = sizes =>
        Object.defineProperty(sizes, "toString", {
            value: () => "",
            enumerable: false
        });

    const getDimensions = (size, sizes) => {
        if (
            size &&
            typeof size.width === "number" &&
            typeof size.height === "number"
        ) {
            return size;
        }

        return size && sizes[size] ? sizes[size] : { width, height };
    };

    const getCss = (size, sizes, fillColor, fillColorRule, noStyles) => {
        if (noStyles) {
            return "";
        }

        const dimensions = getDimensions(size, sizes);
        const fillRule =
            fillColor && fillColorRule
                ? `${fillColorRule}{ fill: ${fillColor}; }`
                : "";
        return css`
            width: ${dimensions.width}px;
            height: ${dimensions.height}px;
            ${fillRule}
        `;
    };

    const propsToCss = ({ size, sizes, fillColor, fillColorRule, noStyles }) =>
        getCss(size, sizes, fillColor, fillColorRule, noStyles);

    return {
        getCss,
        getDimensions,
        propsToCss,
        sanitizeSizes
    };
};

const width = "16";
const height = "16";
const viewBox = "0 0 16 16";

const { getDimensions, getCss, propsToCss, sanitizeSizes } = createHelpers(
    width,
    height,
    css
);

const sizes = sanitizeSizes({
    xs: { width: 14, height: 14 },
    sm: { width: 16, height: 16 },
    md: { width: 20, height: 20 },
    lg: { width: 24, height: 24 },
    xl: { width: 40, height: 40 }
});

const Image = styled.svg`
    ${propsToCss}
`;

const children = (
    <Fragment>
        <path
            id="Vector"
            d="M11.2645 7.17272L8.42748 2.26368C8.33188 2.09878 8.1729 2 8.00278 2C7.83276 2 7.67379 2.09878 7.57818 2.26368L4.73804 7.17272C4.65338 7.31994 4.51769 7.41586 4.3671 7.43486C4.21663 7.45386 4.06655 7.3939 3.9572 7.27114L1.87303 4.91142C1.76873 4.79127 1.62565 4.72906 1.47972 4.74022C1.33381 4.7515 1.19893 4.83508 1.10889 4.97018C1.01887 5.10517 0.982215 5.27886 1.00814 5.44757L1.90726 12.6689C1.95414 13.0387 2.11282 13.3766 2.35433 13.6209C2.59584 13.8652 2.90408 13.9998 3.22307 14H12.7794C13.0984 13.9998 13.4066 13.8652 13.6481 13.6209C13.8896 13.3766 14.0483 13.0387 14.0952 12.6689L14.9943 5.44757C15.0157 5.28052 14.9762 5.11063 14.8856 4.97945C14.7949 4.84815 14.6614 4.76765 14.5176 4.75744C14.3738 4.74735 14.2329 4.8085 14.1294 4.92603L12.0453 7.27114C11.9359 7.3939 11.7858 7.45385 11.6354 7.43485C11.4848 7.41586 11.3492 7.31994 11.2645 7.17272Z"
            fill="currentColor"
        />
    </Fragment>
);

const defaultProps = {
    children,
    viewBox,
    fillColor: null,
    fillColorRule: "&&& path, &&& use, &&& g, &&& circle",
    sizes,
    size: null
};

Image.propTypes /* remove-proptypes */ = {
    fillColor: PropTypes.string,
    fillColorRule: PropTypes.string,
    viewBox: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    size: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            height: PropTypes.number.isRequired,
            width: PropTypes.number.isRequired
        })
    ]),
    sizes: PropTypes.shape({
        height: PropTypes.number,
        width: PropTypes.number
    })
};

export default Object.assign(Image, {
    getDimensions,
    getCss,
    defaultProps,
    displayName: "Menu"
});
