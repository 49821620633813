import React, { useState, useEffect } from "react";
import { useViewport } from "js/hooks/useViewport";
import { useOnClickOutside } from "js/hooks/useOnClickOutside";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import colors from "core/colors";
import { MILLIONS_OF_ICONS } from "core/constants";

import {
    getSearchMode,
    ICONS_SEARCH_MODE,
    PHOTOS_SEARCH_MODE,
    ICON_SETS_SEARCH_MODE
} from "frontend/store/layout";

import { useViewportText } from "frontend/hooks/useViewportText";

import CaretDown from "core/ReusableIcons/CaretDown";
import Icon from "core/ReusableIcons/Icon";
import Photo from "core/ReusableIcons/Photo";
import Collections from "core/ReusableIcons/Collections";

import SearchForm from "./SearchForm";
import SearchWrapper from "./SearchWrapper";
import {
    Dropdown,
    ToggleButton,
    DropdownContents,
    DropdownOption,
    ButtonText
} from "./dropdownStyles";

export const searchTypeButtons = [
    {
        value: ICONS_SEARCH_MODE,
        icon: <Icon size="lg" fillColor={colors.secondary.oolong} />
    },
    {
        value: ICON_SETS_SEARCH_MODE,
        icon: <Collections size="lg" fillColor={colors.secondary.oolong} />
    },
    {
        value: PHOTOS_SEARCH_MODE,
        icon: <Photo size="lg" fillColor={colors.secondary.oolong} />
    }
];

// placeholder text for search input:
export const heroSearchPlaceholderIcons = {
    desktop: `Search over ${MILLIONS_OF_ICONS} million icons`,
    tablet: `Search over ${MILLIONS_OF_ICONS} million icons`,
    mobile: "Search"
};

export const heroSearchPlaceholderPhotos = {
    desktop: "Search art-quality photos",
    tablet: "Search photos",
    mobile: "Search"
};

// this component has 2 variants: header (default) and hero
// the hero variant is used on the home page, and is larger
// most of the relevant styles are contained in SearchWrapper
const DefaultSearch = ({ variant = "header" }) => {
    const initialSearchType = useSelector(getSearchMode);
    const [searchType, setSearchType] = useState(initialSearchType);
    const isPhotos = searchType === PHOTOS_SEARCH_MODE;
    const placeholderText = useViewportText(
        isPhotos ? heroSearchPlaceholderPhotos : heroSearchPlaceholderIcons
    );

    const currentLocation =
        typeof window !== "undefined" ? window.location : undefined;

    const [menuHidden, setMenuHidden] = useState(true);

    const onClickOutside = e => {
        setMenuHidden(true);
    };
    const { targetRef: mobileMenuRef } = useOnClickOutside({
        onClickOutside
    });

    useEffect(() => {
        if (searchType !== initialSearchType) {
            setSearchType(initialSearchType);
        }
    }, [currentLocation, initialSearchType]);

    // find icon for the selected search type:
    const dropdownButtonIcon = searchTypeButtons.find(
        ({ value }) => value === searchType
    ).icon;

    const handleMobileMenuButtonClick = () => {
        setMenuHidden(!menuHidden);
    };

    const handleKeyDown = event => {
        if (
            event.key === " " ||
            event.key === "Enter" ||
            event.key === "Spacebar"
        ) {
            // "Spacebar" for IE11 support
            // Prevent the default action to stop scrolling when space is pressed
            event.preventDefault();
            setSearchType(event.currentTarget.dataset.id);
            setMenuHidden(!menuHidden);
        }
    };

    const handleSearchType = event => {
        // we get the data-id to set as the value since we're using an icon w/ no text for mobile:
        setSearchType(event.currentTarget.dataset.id);
        setMenuHidden(!menuHidden);
    };

    return (
        <SearchWrapper
            className={variant === "hero" ? "home-page-hero" : ""}
            data-testid={
                variant === "hero" ? "home-hero-search" : "header-search"
            }
        >
            <Dropdown
                role="navigation"
                ref={mobileMenuRef}
                className="search-type-toggle-dropdown"
            >
                <ToggleButton
                    className="mobile-toggle-button"
                    aria-expanded="false"
                    aria-label="Search Type"
                    value={searchType}
                    onClick={handleMobileMenuButtonClick}
                >
                    {dropdownButtonIcon}
                    <ButtonText>{searchType}</ButtonText>
                    <CaretDown
                        fillColor={colors.secondary.oolong}
                        size={{ width: 10, height: 10 }}
                    />
                </ToggleButton>
                {!menuHidden && (
                    <DropdownContents
                        id="mobile-dropdown-menu"
                        className="dropdown-menu"
                    >
                        {searchTypeButtons.map(button => {
                            return (
                                <DropdownOption
                                    tabIndex="0"
                                    onClick={handleSearchType}
                                    onKeyDown={handleKeyDown}
                                    key={button.value}
                                    data-id={button.value}
                                    aria-label={`Search Type ${button.value}`}
                                    className={
                                        searchType === button.value &&
                                        "selected"
                                    }
                                    role="button"
                                >
                                    {button.icon}
                                    <ButtonText>{button.value}</ButtonText>
                                </DropdownOption>
                            );
                        })}
                    </DropdownContents>
                )}
            </Dropdown>

            <SearchForm
                selectedSearchType={searchType}
                placeholder={placeholderText}
                hideButtonOnMobile={true}
                buttonSize={variant === "hero" ? 62 : 36}
            />
        </SearchWrapper>
    );
};

DefaultSearch.propTypes = {
    placeholder: PropTypes.string
};

export default DefaultSearch;
