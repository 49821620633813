import { useApolloMutation } from "frontend/utils/apolloApi/useApolloMutation.js";

// GQL
import { APPROVE_PHOTO_UPLOAD_DESCRIPTION } from "frontend/components/fort/mod/photos/gql/mutations";

const errorHandler = normalizedResp => {
    const { message, errors, actionDesc } = normalizedResp;
    let newMessage = message;

    if (
        errors.includes("You must add a description before you can approve it.")
    ) {
        newMessage = `Sorry, we were unable to ${actionDesc}. A photo shoot description is required and appears to be empty.`;
    }

    return {
        ...normalizedResp,
        message: newMessage
    };
};

export const useApprovePhotoUploadDescription = () => {
    const { apolloMutate, loading } = useApolloMutation({
        actionDesc: "Approve Photo Upload Description",
        gql: APPROVE_PHOTO_UPLOAD_DESCRIPTION,
        errorHandler
    });

    const approvePhotoUploadDescription = async variables => {
        return await apolloMutate({ variables });
    };

    return {
        approvePhotoUploadDescription,
        addTagsLoading: loading
    };
};
