import styled, { css } from "styled-components";

// styles
import { convertPxToRem } from "frontend/components/helpers";

// components
import Skeleton from "core/Skeleton";

// styled-components
export const BatchStyled = styled.div`
    margin-bottom: ${convertPxToRem(24)};
    opacity: 1;
    transition: opacity ease-in-out 1s, margin-bottom ease-in-out 1s;

    ${({ batch }) => {
        if (batch.isFaded) {
            return css`
                opacity: 0.5;
            `;
        }
    }}

    ${({ batch }) => {
        if (batch.isCollapsed) {
            return css`
                margin-bottom: 0px;
            `;
        }
    }}
`;
export const BatchLoadingSkeletonStyled = styled(Skeleton).attrs({
    width: "100%",
    height: convertPxToRem(130)
})`
    margin-bottom: ${convertPxToRem(24)};
`;
