import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { HomeIconContainer, IconContainer } from "./styles";

const UnstyledIcon = ({ className, icon, isHomepage }) => {
    const {
        thumbnails: { thumbnail84 = "" },
        url = "",
        title = ""
    } = icon;

    return isHomepage ? (
        <HomeIconContainer
            className={className}
            srText={title}
            imageSrc={thumbnail84}
        />
    ) : (
        <IconContainer
            className={className}
            href={url}
            srText={title}
            imageSrc={thumbnail84}
        />
    );
};

const Icon = styled(UnstyledIcon)``;

Icon.propTypes = {
    icon: PropTypes.shape({
        id: PropTypes.string.isRequired,
        thumbnails: PropTypes.shape({ thumbnail84: PropTypes.string })
            .isRequired,
        url: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired
    }).isRequired,
    isHomepage: PropTypes.bool
};

export default Icon;
