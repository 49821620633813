import { actionTypes } from "./actionTypes";

/* Api Error Handling ---------------------------*/
const handleApiError = (dispatch, actions, resp) => {
    if (!resp) return null;
    const { success, message } = resp;
    if (!success) {
        console.error("handleApiError error", resp);
        dispatch(
            actions.setErrorMessage(
                message || "Something went wrong, please try again"
            )
        );
    }
};

/* Internal Validations ---------------------------*/
const internalValidate = useManageTagsContext => {
    const { validationRules } = useManageTagsContext;

    let errorMessage = "";

    validationRules?.some(rule => {
        const { isValid, message } = rule(useManageTagsContext);
        if (!isValid) {
            errorMessage = message;
            return true;
        }
        return false;
    });

    return errorMessage;
};

/*---------------------------
| Action Creators
---------------------------*/
export const actions = {
    setInputText: inputText => {
        return {
            type: actionTypes.SET_INPUT_TEXT,
            inputText
        };
    },
    setShowSuggestionDropDown: showSuggestionDropDown => {
        return {
            type: actionTypes.SET_SHOW_SUGGESTION_DROP_DOWN,
            showSuggestionDropDown
        };
    },
    setEnabled: enabled => {
        return {
            type: actionTypes.SET_ENABLED,
            enabled
        };
    },
    setErrorMessage: errorMessage => {
        return {
            type: actionTypes.SET_ERROR_MESSAGE,
            errorMessage
        };
    },
    onSuggest: async (useManageTagsContext, userInput) => {
        const {
            dispatch,
            actions,
            onSuggest: consumerOnSuggest
        } = useManageTagsContext;
        const resp = await consumerOnSuggest(userInput); // from consumer
        handleApiError(dispatch, actions, resp);
    },
    onAddTag: async useManageTagsContext => {
        const {
            dispatch,
            actions,
            state: { inputText },
            onAddTag: consumerOnAddTag
        } = useManageTagsContext;

        // internal actions like validation
        const errorMessage = internalValidate(useManageTagsContext);
        if (errorMessage) {
            return dispatch(actions.setErrorMessage(errorMessage));
        }

        // hide suggestions and clear input
        dispatch(actions.setShowSuggestionDropDown(false));
        dispatch(actions.setInputText(""));

        // Let consumer take it from here...
        const resp = await consumerOnAddTag({ inputText });
        handleApiError(dispatch, actions, resp);
    },
    onRemoveTag: async (useManageTagsContext, tag) => {
        const {
            dispatch,
            actions,
            onRemoveTag: consumerOnRemoveTag
        } = useManageTagsContext;

        // Let consumer take it from here...
        const resp = await consumerOnRemoveTag({ tagObject: tag });
        handleApiError(dispatch, actions, resp);
    },
    onApproveTag: async (useManageTagsContext, tag) => {
        const {
            dispatch,
            actions,
            onApproveTag: consumerOnApproveTag
        } = useManageTagsContext;

        // Let consumer take it from here...
        const resp = await consumerOnApproveTag({ tagObject: tag });
        handleApiError(dispatch, actions, resp);
    },
    onRejectTag: async (useManageTagsContext, tag) => {
        const {
            dispatch,
            actions,
            onRejectTag: consumerOnUnapproveTag,
            isRateLimited
        } = useManageTagsContext;

        // Let consumer take it from here...
        const resp = await consumerOnUnapproveTag({ tagObject: tag });
        handleApiError(dispatch, actions, isRateLimited, resp, { tag });
    }
};
