import gql from "graphql-tag";

/* -------------------------------------------------------------------------- */
/*                                 MODERATORS                                 */
/* -------------------------------------------------------------------------- */
// fetches profile details of signed in moderator
export const MY_MOD_PROFILE = gql`
    query myPhotoModeratorProfile {
        profile: myPhotoModeratorProfile {
            id
            moderatorId
            isManager
        }
    }
`;

// fetches list of all eligible photo moderators
export const FETCH_MOD_LIST = gql`
    query fetchModList {
        modList: listModeratorsEligibleToReceivePhotoAssignments {
            items {
                id
                displayName
                avatarUrl
            }
        }
    }
`;
