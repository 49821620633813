import styled, { css } from "styled-components";

//components
import Heading from "core/Heading";
import BodyText from "core/BodyText";
import Button from "core/Button";

//styles
import { easingSeconds } from "core/animation";
import { spacingWithBreakpoints } from "core/spacing";
import breakpoints from "core/breakpoints";
import colors from "core/colors";
import {
    SquareGridContainer,
    SquareGridRow,
    SquareGridItem
} from "icons/SquareGrid/styles";

//helpers
import { convertPxToRem } from "frontend/components/helpers";
import { handleContainerType, getImageHoverStyle } from "./helpers";

//reusable constants
const SPACING_IN_PX = {
    desktop: 33.945,
    tablet: 19.11,
    mobile: 27.01
};

const SPACING_PERCENTAGES = {
    desktop: 8,
    tablet: 12,
    mobile: 12
};

const PHOTO_HOVER = css`
    filter: grayscale();
`;

const ICON_HOVER = css`
    filter: invert(1);
`;

const DEFAULT_HOVER = css`
    opacity: 0.6;
`;

const STYLE_TYPES = {
    PRIMARY: css`
        background: ${colors.primary.snow};
        color: ${colors.secondary.orca};

        ${Heading} {
            color: ${colors.secondary.orca};
            transition: all ${easingSeconds};
        }

        ${BodyText} {
            color: ${colors.secondary.oolong};
            transition: all ${easingSeconds};
        }

        &:hover {
            background: ${colors.secondary.basalt};
            color: ${colors.primary.diamond};

            ${Heading},
            ${BodyText} {
                color: ${colors.primary.diamond};
            }

            ${Heading} {
                border-color: ${colors.primary.diamond};
            }
        }
    `,
    SECONDARY: css`
        background: ${colors.primary.diamond};
        color: ${colors.secondary.orca};

        ${Heading} {
            color: ${colors.secondary.orca};
            transition: all ${easingSeconds};
        }

        ${BodyText} {
            color: ${colors.secondary.oolong};
            transition: all ${easingSeconds};
        }

        &:hover {
            background: ${colors.secondary.basalt};
            color: ${colors.primary.diamond};

            ${Heading},
            ${BodyText} {
                color: ${colors.primary.diamond};
            }

            ${Heading} {
                border-color: ${colors.primary.diamond};
            }
        }
    `,
    DARKMODE: css`
        background: ${colors.secondary.orca};
        color: ${colors.primary.snow};

        ${Heading},
        ${BodyText} {
            color: ${colors.primary.snow};
            transition: all ${easingSeconds};
        }

        &:hover {
            background: ${colors.secondary.basalt};
            color: ${colors.primary.diamond};

            svg path {
                fill: ${colors.primary.snow};
            }

            ${Heading},
            ${BodyText} {
                color: ${colors.primary.diamond};
            }

            ${Heading} {
                border-color: ${colors.primary.diamond};
            }
        }
    `
};

//styled components
const CollectionsHeadingTablet = css`
    max-width: ${convertPxToRem({ px: 738 })};
    margin: 0 auto;
`;

export const CollectionsHeading = styled(Heading)`
    /* Mobile: */
    max-width: 100%;

    /* Tablet: */
    @media (min-width: ${breakpoints.tablet.rem}) {
        ${CollectionsHeadingTablet};
    }

    /* Desktop: */
    @media (min-width: ${breakpoints.desktop.rem}) {
        max-width: ${convertPxToRem({ px: 911 })};
        margin: 0 auto;
    }

    /* Screens with 700px - 1000px height range: */
    @media ${breakpoints.laptopHeightsRange.rem} {
        @media (min-width: ${breakpoints.desktop.rem}) {
            ${CollectionsHeadingTablet};
        }
    }
`;

export const TextContainer = styled.div`
    flex: 1 0 auto;
    padding: ${convertPxToRem({ px: 24 })};

    /* Mobile-only: */
    min-height: ${convertPxToRem({ px: 96 })};

    /* Tablet: */
    @media (min-width: ${breakpoints.tablet.rem}) {
        min-height: ${convertPxToRem({ px: 113 })};
    }

    /* Desktop: */
    @media (min-width: ${breakpoints.desktop.rem}) {
        min-height: ${convertPxToRem({ px: 136 })};
    }

    /* Screens with 700px - 1000px height range: */
    @media ${breakpoints.laptopHeightsRange.rem} {
        @media (min-width: ${breakpoints.desktop.rem}) {
            min-height: ${convertPxToRem({ px: 113 })};
        }
    }
`;

export const BoxHeadingContainer = styled.div`
    padding-bottom: ${convertPxToRem({ px: 1 })};

    ${Heading} {
        border-bottom: ${convertPxToRem({ px: 1 })} solid transparent;
        display: inline;
    }
`;

export const ImageContainer = styled.div`
    display: contents;
    vertical-align: bottom;

    // to hold the space when images or content is loading:
    /* Mobile: */
    min-height: ${convertPxToRem({ px: 193.33 })};

    /* Tablet: */
    @media (min-width: ${breakpoints.tablet.rem}) {
        min-height: ${convertPxToRem({ px: 153 })};
    }

    /* Desktop: */
    @media (min-width: ${breakpoints.desktop.rem}) {
        min-height: ${convertPxToRem({ px: 272 })};
    }

    /* Screens with 700px - 1000px height range: */
    @media ${breakpoints.laptopHeightsRange.rem} {
        @media (min-width: ${breakpoints.desktop.rem}) {
            min-height: ${convertPxToRem({ px: 153 })};
        }
    }
`;

export const CollectionContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    ${SquareGridContainer} {
        min-height: 0;
        height: 100%;
        justify-content: space-between;

        /* Mobile: */
        padding: ${SPACING_PERCENTAGES.mobile}%
            ${convertPxToRem({ px: SPACING_IN_PX.mobile })};

        /* Tablet: */
        @media (min-width: ${breakpoints.tablet.rem}) {
            padding: ${SPACING_PERCENTAGES.tablet}%
                ${convertPxToRem({ px: SPACING_IN_PX.tablet })};
        }

        /* Desktop: */
        @media (min-width: ${breakpoints.desktop.rem}) {
            padding: ${SPACING_PERCENTAGES.desktop}%
                ${convertPxToRem({ px: SPACING_IN_PX.desktop })};
        }

        /* Screens with 700px - 1000px height range: */
        @media ${breakpoints.laptopHeightsRange.rem} {
            @media (min-width: ${breakpoints.desktop.rem}) {
                padding: ${SPACING_PERCENTAGES.tablet}%
                    ${convertPxToRem({ px: SPACING_IN_PX.tablet })};
            }
        }
    }

    ${SquareGridRow} {
        width: 100%;

        &:last-of-type {
            margin-bottom: 0;
        }

        /* Mobile: */
        height: ${convertPxToRem({ px: SPACING_IN_PX.mobile * 2 })};
        margin-bottom: ${SPACING_PERCENTAGES.mobile * 2}%;

        /* Tablet: */
        @media (min-width: ${breakpoints.tablet.rem}) {
            height: ${convertPxToRem({ px: SPACING_IN_PX.tablet * 2 })};
            margin-bottom: ${SPACING_PERCENTAGES.tablet * 2}%;
        }

        /* Desktop: */
        @media (min-width: ${breakpoints.desktop.rem}) {
            height: ${convertPxToRem({ px: SPACING_IN_PX.desktop * 2 })};
            margin-bottom: ${SPACING_PERCENTAGES.desktop * 2}%;
        }

        /* Screens with 700px - 1000px height range: */
        @media ${breakpoints.laptopHeightsRange.rem} {
            @media (min-width: ${breakpoints.desktop.rem}) {
                height: ${convertPxToRem({ px: SPACING_IN_PX.tablet * 2 })};
                margin-bottom: ${SPACING_PERCENTAGES.tablet * 2}%;
            }
        }

        > ul:first-child {
            width: 100%;
            height: 100%;
        }
    }

    ${SquareGridItem} {
        /* Mobile: */
        margin-right: ${convertPxToRem({ px: SPACING_IN_PX.mobile })};

        &:last-of-type {
            margin-right: 0;
        }

        /* Tablet: */
        @media (min-width: ${breakpoints.tablet.rem}) {
            margin-right: ${convertPxToRem({ px: SPACING_IN_PX.tablet })};
            &:last-of-type {
                margin-right: 0;
            }
        }

        /* Desktop: */
        @media (min-width: ${breakpoints.desktop.rem}) {
            margin-right: ${convertPxToRem({ px: SPACING_IN_PX.desktop })};
            &:last-of-type {
                margin-right: 0;
            }
        }

        /* Screens with 700px - 1000px height range: */
        @media ${breakpoints.laptopHeightsRange.rem} {
            @media (min-width: ${breakpoints.desktop.rem}) {
                margin-right: ${convertPxToRem({ px: SPACING_IN_PX.tablet })};
                &:last-of-type {
                    margin-right: 0;
                }
            }
        }

        > div:first-child {
            position: relative;
        }

        &:after {
            content: unset;
        }
    }
`;

export const CollectionBoxLink = styled.span`
    display: block;
    width: 100%;
    height: 100%;
    transition: all ${easingSeconds};
    text-decoration: none;

    span[role="img"],
    img {
        transition: all ${easingSeconds};
    }

    ${({ type }) => type && handleContainerType({ type, STYLE_TYPES })}

    &:hover {
        text-decoration: none;

        span[role="img"],
        img {
            ${({ type, isPhoto }) =>
                getImageHoverStyle({
                    type,
                    isPhoto,
                    defaultHover: DEFAULT_HOVER,
                    photoHover: PHOTO_HOVER,
                    iconHover: ICON_HOVER
                })}
        }
    }

    /* IE10 and IE11 support - filter: does not work in these browsers */
    @media ${breakpoints.browserSupport.ie10And11} {
        &:hover {
            span[role="img"],
            img {
                ${DEFAULT_HOVER}
            }

            ${({ type }) =>
                type !== "darkMode" &&
                css`
                    background-color: ${colors.accent.darkMode.suenosBuenos};

                    ${Heading},
                    ${BodyText} {
                        color: ${colors.secondary.orca};
                    }

                    ${Heading} {
                        border-bottom: 1px solid ${colors.secondary.orca};
                    }
                `}
        }
    }
`;

// for optional Pills section:
export const PillsContainer = styled.div`
    ${spacingWithBreakpoints.XL4Top};

    ${Button} {
        margin-top: ${convertPxToRem({ px: 24 })};

        &:not(:last-of-type) {
            margin-right: ${convertPxToRem({ px: 24 })};
        }
    }
`;
