import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Link from "core/Link";
import Modal from "core/Modal";
import BodyText from "core/BodyText";
import Button from "core/Button";

import { convertPxToRem } from "frontend/components/helpers";

export const CtaContainer = styled.div`
    margin-top: ${convertPxToRem({ px: 32 })};

    ${Button} {
        margin-right: ${convertPxToRem({ px: 24 })};
    }
`;

const PhotoUploadCancelModal = ({
    modalIsOpen,
    closeModal,
    withdrawPhotoBatch
}) => {
    const handleCancelButtonClick = () => {
        withdrawPhotoBatch();
    };

    return (
        <Modal
            open={modalIsOpen}
            handleClose={closeModal}
            heading="Are you sure you want to cancel your submission?"
            closeOnEscape
            closeOnBackDropClick
        >
            <BodyText size="md">
                Uploaded photos will be removed and any edits will be lost.
            </BodyText>
            <CtaContainer>
                <Button onClick={handleCancelButtonClick}>
                    Yes, cancel and start over
                </Button>
                <Link
                    onClick={closeModal}
                    size="md"
                    styleType="primary"
                    isButton
                    disableVisitedStyle
                >
                    No, stay and keep photos
                </Link>
            </CtaContainer>
        </Modal>
    );
};

PhotoUploadCancelModal.propTypes = {
    modalIsOpen: PropTypes.bool,
    closeModal: PropTypes.func.isRequired,
    withdrawPhotoBatch: PropTypes.func.isRequired
};

export default PhotoUploadCancelModal;
