// https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#Feature-detecting_localStorage
function storageAvailable(type) {
    try {
        if (typeof window === "undefined") return false;
        var storage = window[type],
            x = "__storage_test__";
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
    } catch (e) {
        return (
            e instanceof DOMException &&
            // everything except Firefox
            (e.code === 22 ||
                // Firefox
                e.code === 1014 ||
                // test name field too, because code might not be present
                // everything except Firefox
                e.name === "QuotaExceededError" ||
                // Firefox
                e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
            // acknowledge QuotaExceededError only if there's something already stored
            storage.length !== 0
        );
    }
}

let storage;
if (storageAvailable("localStorage")) {
    storage = window.localStorage;
} else {
    console.warn("window.localStorage unavailable polyfilling");
    // Simple inmemory polyfill
    // https://gist.github.com/juliocesar/926500
    storage = {
        _data: {},
        setItem: function(id, val) {
            return (this._data[id] = String(val));
        },
        getItem: function(id) {
            return this._data.hasOwnProperty(id) ? this._data[id] : null;
        },
        removeItem: function(id) {
            return delete this._data[id];
        },
        clear: function() {
            return (this._data = {});
        }
    };
    // Prevent Assignment to read-only properties not allowed error
    typeof window !== "undefined" &&
        Object.defineProperty(window, "localStorage", {
            get: function() {
                return storage;
            }
        });
}

export default storage;
