import styled, { css } from "styled-components";

// components + styles
import Button from "core/Button";
import Flexbox from "core/Flexbox";
import BodyText from "core/BodyText";
import SectionContainer from "core/SectionContainer";
import { Container as SectionContainerChildContainer } from "core/SectionContainer/styles";
import RadioInput from "core/RadioInput";
import colors from "core/colors";
import { regular as regularFont } from "core/fontStyles";
import { spacingWithBreakpoints } from "core/spacing";
import { LabelContainer } from "core/Form/styles";
import breakpoints from "core/breakpoints";

// helpers + external constants
import { convertPxToRem } from "frontend/components/helpers";
import { desktopHeaderHeight } from "core/SiteHeader/constants";

// constants:
const FOOTER_HEIGHT_DESKTOP = 128;
const FOOTER_HEIGHT_TABLET = 120;

// styled components
export const Italic = styled.span`
    font-style: italic;
`;

export const UploadsSectionContainer = styled(SectionContainer)`
    > ${SectionContainerChildContainer} {
        position: relative;
    }

    ${({ hasHeaderHeight }) => css`
        /* Tablet - for sticky footer: */
        @media (min-width: ${breakpoints.tablet
                .rem}) and (max-width: ${breakpoints.beforeDesktop.rem}) {
            min-height: calc(
                100vh -
                    ${hasHeaderHeight
                        ? convertPxToRem({ px: hasHeaderHeight })
                        : convertPxToRem({ px: desktopHeaderHeight })} -
                    ${convertPxToRem({ px: FOOTER_HEIGHT_TABLET })}
            );
        }

        /* Desktop - for sticky footer: */
        min-height: calc(
            100vh -
                ${hasHeaderHeight
                    ? convertPxToRem({ px: hasHeaderHeight })
                    : convertPxToRem({ px: desktopHeaderHeight })} -
                ${convertPxToRem({ px: FOOTER_HEIGHT_DESKTOP })}
        );
    `}
`;

export const HeaderFlexbox = styled(Flexbox)`
    margin-bottom: ${convertPxToRem({ px: 16 })};
`;

export const MainHeadingContainer = styled.div`
    height: ${convertPxToRem({ px: 35 })};
`;

export const TypeFlexbox = styled(Flexbox)``;

export const UploadedFilesControlsFlexbox = styled(Flexbox)``;

export const RadioFieldset = styled.fieldset`
    display: flex;
    margin-right: ${convertPxToRem({ px: 24 })};
`;

export const RadioLabel = styled.label`
    ${regularFont}
    display: flex;
    font-size: ${convertPxToRem({ px: 16 })};
    line-height: ${convertPxToRem({ px: 24 })};
    color: ${colors.secondary.orca};
    margin-right: ${convertPxToRem({ px: 16 })};

    &:last-child {
        margin-right: 0;
    }

    ${RadioInput} {
        margin-right: ${convertPxToRem({ px: 8 })};
    }
`;

export const ShootTitleBodyText = styled(BodyText)`
    margin-right: ${convertPxToRem({ px: 4 })};
`;

export const ModalChildrenContainer = styled.div`
    ${LabelContainer} {
        // hide label from UI but show to screen readers:
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border: 0;
        //
    }
`;

// DroppedFileErrors:
export const ErrorContainer = styled.div`
    width: 75%;
    position: absolute;
`;

// when files have been uploaded - controls:
export const SelectedPhotosControlsFlexbox = styled(Flexbox)`
    ${spacingWithBreakpoints.XL2Top}
`;

export const SelectedPhotosButton = styled(Button)`
    margin-left: ${convertPxToRem({ px: 24 })};
`;
