import React from "react";

// styles
import { BatchLoadingSkeletonStyled } from "./styles.js";

const BatchesLoadingSkeleton = () => {
    return (
        <>
            <BatchLoadingSkeletonStyled />
            <BatchLoadingSkeletonStyled />
            <BatchLoadingSkeletonStyled />
            <BatchLoadingSkeletonStyled />
        </>
    );
};

export default BatchesLoadingSkeleton;
