import { QUEUES, QUEUE_VIEWS, UPLOAD_STATUSES } from "../../constants";

const batchUploadCollectionTypes = [
    {
        id: 1,
        title: "Visual Moderation",
        activekeys: UPLOAD_STATUSES.VISUAL.active,
        savedForLaterkeys: UPLOAD_STATUSES.VISUAL.savedForLater,
        showActiveAndSavedFilter: true,
        supportedQueues: [QUEUES.VIS_MOD, QUEUES.SUSPENDED]
    },
    {
        id: 2,
        title: "Metadata Moderation",
        activekeys: UPLOAD_STATUSES.META.active,
        savedForLaterkeys: UPLOAD_STATUSES.META.savedForLater,
        showActiveAndSavedFilter: true,
        supportedQueues: [QUEUES.METADATA_MOD, QUEUES.SUSPENDED]
    },
    {
        id: 3,
        title: "Denied",
        activekeys: UPLOAD_STATUSES.DENIED.active,
        savedForLaterkeys: UPLOAD_STATUSES.DENIED.savedForLater,
        showActiveAndSavedFilter: false,
        supportedQueues: [QUEUES.COMPLETED, QUEUES.SUSPENDED]
    },
    {
        id: 4,
        title: "Fully Published",
        activekeys: UPLOAD_STATUSES.FULL.active,
        savedForLaterkeys: UPLOAD_STATUSES.FULL.savedForLater,
        showActiveAndSavedFilter: false,
        supportedQueues: [QUEUES.COMPLETED, QUEUES.SUSPENDED]
    }
];

export const getBatchUploadCollections = ({
    ids,
    entities,
    selectedBatchId,
    currentQueueName = QUEUES.VIS_MOD
}) => {
    return batchUploadCollectionTypes
        .map(
            ({
                id,
                title,
                activekeys,
                savedForLaterkeys,
                supportedQueues,
                showActiveAndSavedFilter
            }) => {
                const allStatusKeys = [...activekeys, ...savedForLaterkeys];

                const showCollectionTitle = [
                    QUEUES.SUSPENDED,
                    QUEUES.COMPLETED
                ].includes(currentQueueName);

                // Organize
                const collectionUploadIds = ids.filter(uid => {
                    const upload = entities[uid];
                    const isInSelectedBatch =
                        selectedBatchId === upload.batchId;
                    const hasThisCollectionStatus = allStatusKeys.includes(
                        upload.status
                    );
                    return isInSelectedBatch && hasThisCollectionStatus;
                });

                const activeIds = collectionUploadIds.filter(cuId =>
                    activekeys.includes(entities[cuId].status)
                );
                const savedForLaterIds = collectionUploadIds.filter(cuId =>
                    savedForLaterkeys.includes(entities[cuId].status)
                );

                return {
                    id: `${selectedBatchId}-${id}`,
                    title,
                    collectionUploadIds,
                    activeIds,
                    savedForLaterIds,
                    view: QUEUE_VIEWS.ACTIVE,
                    showCollectionTitle,
                    supportedQueues,
                    showActiveAndSavedFilter
                };
            }
        )
        .filter(bc => bc.collectionUploadIds.length > 0) // only return those batch collections that have uploads in them.
        .filter(bc => bc.supportedQueues.includes(currentQueueName)); // only return those batch collections that current view supports
};
