import React from "react";

import Link from "core/Link";

import Login from "../Login";
import Signup from "../Signup";
import ForgotPassword from "../ForgotPassword";
import ForgotPasswordSuccess from "../ForgotPasswordSuccess";
import FacebookTryAgain from "../FacebookTryAgain";
import { FullScreenModal, Title, SubTitle } from "./styles";
import { FooterText } from "../styles";

import {
    useAuth,
    useAuthModal,
    LOGIN_MODE,
    SIGNUP_MODE,
    SOCIAL_TRY_AGAIN_MODE,
    FORGOT_PASSWORD_MODE,
    EMAILED_PASSWORD_MODE
} from "frontend/hooks/useAuth";

const AuthModal = () => {
    const { authMode, setAuthMode, afterAuth, cancelAuth } = useAuthModal();

    const linkProps = {
        size: "lg"
    };

    let titleAlignment = "left";
    let Component, title;
    switch (authMode) {
        case SIGNUP_MODE:
            Component = Signup;
            title = "Create a Free Account";
            break;
        case SOCIAL_TRY_AGAIN_MODE:
            Component = FacebookTryAgain;
            break;
        case FORGOT_PASSWORD_MODE:
            Component = ForgotPassword;
            title = "Reset Your Password";
            break;
        case EMAILED_PASSWORD_MODE:
            Component = ForgotPasswordSuccess;
            title = "Another Trip to the Mailbox";
            titleAlignment = "center";
            break;
        default:
            Component = Login;
            title = "Login";
            break;
    }

    let formHandlers = {};
    switch (authMode) {
        case FORGOT_PASSWORD_MODE:
            formHandlers.afterSubmit = () => setAuthMode(EMAILED_PASSWORD_MODE);
            break;
        case SOCIAL_TRY_AGAIN_MODE:
            formHandlers = {
                loginClickHandler: {
                    onClick: () => setAuthMode(LOGIN_MODE)
                },
                signupClickHandler: {
                    onClick: () => setAuthMode(SIGNUP_MODE)
                }
            };
            break;
        default:
            formHandlers.afterAuth = afterAuth;
    }

    const subtitle =
        authMode === SIGNUP_MODE ? (
            <>
                Already have an account?{" "}
                <Link onClick={() => setAuthMode(LOGIN_MODE)} {...linkProps}>
                    Log In
                </Link>
            </>
        ) : authMode === EMAILED_PASSWORD_MODE ||
          authMode === SOCIAL_TRY_AGAIN_MODE ? (
            ""
        ) : (
            <>
                New?{" "}
                <Link onClick={() => setAuthMode(SIGNUP_MODE)} {...linkProps}>
                    Create an Account
                </Link>
            </>
        );

    const footer = authMode === LOGIN_MODE && (
        <Link onClick={() => setAuthMode(FORGOT_PASSWORD_MODE)} {...linkProps}>
            Forgot your password?
        </Link>
    );

    // NOTE: `handleClose` is for the "X" button, and `onClose` is for the escape key
    return (
        <FullScreenModal handleClose={cancelAuth} onClose={cancelAuth}>
            {title && <Title alignment={titleAlignment}>{title}</Title>}
            {subtitle && <SubTitle>{subtitle}</SubTitle>}
            <Component {...formHandlers} />
            {footer && <FooterText>{footer}</FooterText>}
        </FullScreenModal>
    );
};

// Because we render this component on every page, make it cheap to render.
// Only run useAuthModal() if we're actually rendering the modal.
export default () => {
    const { authMode } = useAuth();
    if (!authMode) {
        return null;
    }
    return <AuthModal />;
};
