import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";

import Grid from "core/Grid";
import ImagesContainer from "./ImagesContainer";
import EditModalContentRightCol from "./EditModalContentRightCol";

import {
    EditModal,
    LeftColGridItem,
    RightColGridItem,
    SectionContainer
} from "./styles";

import { useEditPhotoUpload } from "./EditPhotoUploadContext";
import { AddTagsProvider } from "core/AddTags/__hooks__/useAddTags";

const Edit = () => {
    const {
        closeEditModal,
        editModalIsOpen,
        uploads: uploadsCurrentlyEditing,
        setSingleEditByIndex,
        singleEditUploadIndex,
        uploadCount
    } = useEditPhotoUpload();

    // modal is carousel if we are in "single edit mode"
    const isModalCarousel = singleEditUploadIndex !== null;

    const modalHeading = isModalCarousel
        ? "Editing single photo"
        : `Editing ${uploadsCurrentlyEditing.length} selected photos`;

    if (!editModalIsOpen) return null;

    return (
        <AddTagsProvider>
            <EditModal
                open={editModalIsOpen}
                onClose={closeEditModal}
                handleClose={closeEditModal}
                type={isModalCarousel ? "carousel" : "text"}
                heading={modalHeading}
                headingType="h2"
                headingSize="sm"
                size="xl"
                handleChangeItem={setSingleEditByIndex}
                currentItemIndex={singleEditUploadIndex}
                carouselLength={uploadCount - 1}
            >
                <Grid desktopSpacing={24} tabletSpacing={24} mobileSpacing={24}>
                    <LeftColGridItem item sm={4} md={4} lg={6}>
                        <ImagesContainer uploads={uploadsCurrentlyEditing} />
                    </LeftColGridItem>
                    <RightColGridItem item sm={8} md={8} lg={6}>
                        <SectionContainer>
                            <EditModalContentRightCol />
                        </SectionContainer>
                    </RightColGridItem>
                </Grid>
            </EditModal>
        </AddTagsProvider>
    );
};

export default Edit;
