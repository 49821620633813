import React from "react";

import { IconGridItemContainer } from "icons/IconGrid/styles";
import Skeleton from "core/Skeleton";

const SkeletonIconGridItem = () => (
    <IconGridItemContainer>
        <Skeleton width="100%" height="100%" />
    </IconGridItemContainer>
);

export default SkeletonIconGridItem;
