import React from "react";
import PropTypes from "prop-types";

import {
    Label,
    LabelContainer,
    ErrorContainer,
    LabelAndError,
    sharedFormStylesBase,
    sharedFormStylesPlaceholder,
    sharedFormStylesHover,
    sharedFormStylesFocus,
    sharedFormStylesDisabled,
    sharedFormStylesReadOnly
} from "./styles";

// all shared form styles:
export const SHARED_FORM_STYLES = {
    BASE: sharedFormStylesBase,
    PLACEHOLDER: sharedFormStylesPlaceholder,
    HOVER: sharedFormStylesHover,
    FOCUS: sharedFormStylesFocus,
    DISABLED: sharedFormStylesDisabled,
    READONLY: sharedFormStylesReadOnly
};

// shared form container:
export const FormElementContainer = ({
    labelText,
    hideLabel = false,
    errorMessage,
    hideErrorMessage = false,

    className,
    width = "100%",
    darkMode = false,
    children
}) => {
    /**
     * Business Logic
     * - we are swapping label and error message, if errorMessage, do not show label
     * - we also give consumer ability to override and hide each if necessary (e.g. label is sr-only, error message, only when focused)
     *
     * Note: We always show <LabelAndError> container which adds 24 pixel padding. consumer must remove padding if desired
     */
    const showLabel = !hideLabel && !errorMessage;
    const showError = !hideErrorMessage && !!errorMessage;

    return (
        <Label width={width} darkMode={darkMode} className={className}>
            {children}

            <LabelAndError>
                <LabelContainer showFormLabel={showLabel} darkMode={darkMode}>
                    {labelText}
                </LabelContainer>
                <ErrorContainer showError={showError} darkMode={darkMode}>
                    {errorMessage}
                </ErrorContainer>
            </LabelAndError>
        </Label>
    );
};

FormElementContainer.propTypes = {
    labelText: PropTypes.string.isRequired,
    hideLabel: PropTypes.bool,
    errorMessage: PropTypes.string,
    hideErrorMessage: PropTypes.bool,

    className: PropTypes.string,
    width: PropTypes.string,
    darkMode: PropTypes.bool,
    children: PropTypes.any
};
