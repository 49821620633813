import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { BatchHeader } from "../../../core/Batch/BatchHeader";

import { QUEUES } from "../../constants";

import colors from "core/colors";
import { SuspendedIconStyled } from "../../styles";

const PhotoModBatchHeader = ({
    children,
    batch,
    batchContentIsOpen,
    onBatchToggle
}) => {
    const currentQueue = useSelector(state => state.currentQueue.name);

    let backgroundColor;
    let backgroundIcon;

    switch (currentQueue) {
        case QUEUES.COMPLETED: {
            backgroundColor = colors.primary.igloo;
            break;
        }
        case QUEUES.METADATA_MOD: {
            backgroundColor = colors.secondary.greenSherbet;
            break;
        }
        case QUEUES.SUSPENDED: {
            backgroundColor = colors.functional.darkMode.himalayan;
            backgroundIcon = SuspendedIconStyled;
            break;
        }
        default: {
            backgroundColor = colors.secondary.skyBlue;
            break;
        }
    }

    return (
        <BatchHeader
            batch={batch}
            batchContentIsOpen={batchContentIsOpen}
            onBatchToggle={onBatchToggle}
            backgroundColor={backgroundColor}
            backgroundIcon={backgroundIcon}
        >
            {children}
        </BatchHeader>
    );
};

export default PhotoModBatchHeader;

// prop-types
PhotoModBatchHeader.propTypes = {
    children: PropTypes.any,
    batch: PropTypes.object.isRequired,
    batchContentIsOpen: PropTypes.bool.isRequired,
    onBatchToggle: PropTypes.func.isRequired
};
