import React from "react";
import PropTypes from "prop-types";

import Grid from "core/Grid";
import {
    TimelineGridItem,
    TimelineHeading,
    GridItemContent,
    GridItemContentHeading,
    Paragraph
} from "./styles";

const Timeline = ({ title, content }) => {
    return (
        <>
            {title && (
                <TimelineHeading type="h2" size="xl" bottomSpacing="4xl">
                    {title}
                </TimelineHeading>
            )}
            <Grid mobileSpacing={24} tabletSpacing={16} desktopSpacing={64}>
                {content.map(data => {
                    return (
                        <TimelineGridItem
                            item
                            sm={12}
                            md={4}
                            lg={4}
                            key={data.title}
                        >
                            <GridItemContent>
                                <GridItemContentHeading type="h2" size="2xl">
                                    {data.title}
                                </GridItemContentHeading>
                                <GridItemContentHeading type="h6" size="xs">
                                    {data.subtitle}
                                </GridItemContentHeading>
                                <Paragraph>{data.text}</Paragraph>
                            </GridItemContent>
                        </TimelineGridItem>
                    );
                })}
            </Grid>
        </>
    );
};

Timeline.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    content: PropTypes.array.isRequired
};

export default Timeline;
