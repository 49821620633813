import React from "react";
import styled, { css } from "styled-components";

// context
import { useSiteHeader } from "core/SiteHeaderV2/context/useSiteHeader.js";

// Drop Down
import { DropDownProvider, Hotspot } from "core/DropDown/index.js";

// data
import { primaryMenu } from "data/links";

// components
import DropDown from "./DropDown";

// styles
import { linkStyles } from "core/SiteHeaderV2/components/Link";
import { convertPxToRem } from "frontend/components/helpers";

const ToolsStyled = styled.div`
    display: inline-block;
    margin: 0rem ${convertPxToRem(8)};
`;

const HostSpotStyled = styled(Hotspot)`
    ${({ $isDarkMode }) => {
        return css`
            ${linkStyles($isDarkMode)}
        `;
    }};
`;

// component
export const Tools = () => {
    const { isDarkMode } = useSiteHeader();
    return (
        <ToolsStyled>
            <DropDownProvider
                id="tools-menu"
                hasCloseButton={false}
                hideOnScroll
            >
                <HostSpotStyled $isDarkMode={isDarkMode}>
                    {primaryMenu.products.links.tools.name}
                </HostSpotStyled>
                <DropDown />
            </DropDownProvider>
        </ToolsStyled>
    );
};

export default Tools;
