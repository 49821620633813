/*===================================
||
|| MultipleTabsAreOpenContext
||
===================================*/
import React, {
    createContext,
    useEffect,
    useContext,
    useState,
    useMemo
} from "react";
import PropTypes from "prop-types";

/*---------------------------
| Context
---------------------------*/
const MultipleTabsAreOpenContext = createContext();

// Provider
export const MultipleTabsAreOpenProvider = ({ children }) => {
    const [multipleTabsAreOpen, setMultipleTabsAreOpen] = useState(false);

    // Retrieve or generate a unique tab ID for this session
    let tabId = sessionStorage.getItem("tabId") || Date.now().toString();

    // Duplicate Tabs: MDN: Duplicating a tab copies the tab's sessionStorage into the new tab.
    const ensureUniqueTabId = () => {
        const openTabs = JSON.parse(localStorage.getItem("openTabs") || "[]");

        // If tabId already exists in openTabs, generate a new one
        if (openTabs.includes(tabId)) {
            tabId = Date.now().toString();
            sessionStorage.setItem("tabId", tabId);
        } else {
            sessionStorage.setItem("tabId", tabId);
        }

        // Save the unique tabId in localStorage's openTabs
        localStorage.setItem("openTabs", JSON.stringify([...openTabs, tabId]));
    };

    useEffect(() => {
        ensureUniqueTabId();

        // Function to update the status of open tabs
        const updateOpenTabsStatus = () => {
            const openTabs = JSON.parse(
                localStorage.getItem("openTabs") || "[]"
            );

            // Ensure unique values in openTabs
            const uniqueOpenTabs = Array.from(new Set(openTabs)).filter(
                id => id !== null
            );

            // Evaluate if multiple tabs are open based on uniqueOpenTabs count
            setMultipleTabsAreOpen(uniqueOpenTabs.length > 1);

            // Save back the cleaned-up array of open tabs
            localStorage.setItem("openTabs", JSON.stringify(uniqueOpenTabs));
        };

        // Initial update to check current tab state
        updateOpenTabsStatus();

        // Storage event handler to listen for changes from other tabs
        const handleStorageEvent = e => {
            if (e.key === "openTabs") {
                updateOpenTabsStatus();
            }
        };
        window.addEventListener("storage", handleStorageEvent);

        // Remove the tab ID from openTabs on tab close or unload
        const handleBeforeUnload = () => {
            const updatedOpenTabs = JSON.parse(
                localStorage.getItem("openTabs") || "[]"
            ).filter(id => id !== tabId);
            localStorage.setItem("openTabs", JSON.stringify(updatedOpenTabs));
        };
        window.addEventListener("beforeunload", handleBeforeUnload);

        // Cleanup event listeners on component unmount
        return () => {
            window.removeEventListener("storage", handleStorageEvent);
            window.removeEventListener("beforeunload", handleBeforeUnload);

            // Clean up tab ID if component is unmounted
            const updatedOpenTabs = JSON.parse(
                localStorage.getItem("openTabs") || "[]"
            ).filter(id => id !== tabId);
            localStorage.setItem("openTabs", JSON.stringify(updatedOpenTabs));
        };
    }, [tabId]);

    // useMemo so it does not pass value on every render
    const value = useMemo(() => ({ multipleTabsAreOpen }), [
        multipleTabsAreOpen
    ]);

    return (
        <MultipleTabsAreOpenContext.Provider value={value}>
            {children}
        </MultipleTabsAreOpenContext.Provider>
    );
};

// Display Name
MultipleTabsAreOpenContext.displayName = "MultipleTabsAreOpen";

// prop-types
MultipleTabsAreOpenProvider.propTypes = {
    children: PropTypes.any
};

// useMultipleTabsAreOpen
export const useMultipleTabsAreOpen = () => {
    return useContext(MultipleTabsAreOpenContext);
};
