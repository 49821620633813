import React, { useState } from "react";
import { useHistory } from "react-router";
import { useMutation } from "@apollo/client";

import { cuuid } from "core/helpers";
import Button from "core/Button";
import PhotoUploadCancelModal from "./PhotoUploadCancelModal";

import { PHOTO_UPLOAD } from "data/links";
import { WITHDRAW_PHOTO_BATCH } from "./mutations";

import { usePhotoUpload } from "frontend/hooks/usePhotoUpload";
import { useMessageStack } from "core/MessageStack/useMessageStack";
import { useFailedPhotoUploads } from "../../PhotoUploadCore/hooks";

const PhotoUploadCancel = () => {
    const history = useHistory();
    const { addMessages } = useMessageStack();
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const {
        clearErrors,
        setIsCancellingBatch,
        currentlyProcessing
    } = usePhotoUpload();
    const { clearUploadErrors } = useFailedPhotoUploads();
    const isDisabled = currentlyProcessing.length > 0;

    const [withdrawPhotoBatch, { loading }] = useMutation(
        WITHDRAW_PHOTO_BATCH,
        {
            // will redirect to photo upload view page once batch withdrawal is complete
            onCompleted: r => {
                setIsCancellingBatch(false);
                const respData = r.withdrawPhotoBatch;
                if (
                    respData.ok === true ||
                    // if user has not uploaded photos, this error will be returned
                    // perhaps we should not make the call at all in this case
                    respData.error ===
                        "You have no pending batches to withdraw."
                ) {
                    clearErrors();
                    clearUploadErrors();
                    history.replace(PHOTO_UPLOAD);
                } else {
                    addMessages([
                        {
                            id: cuuid(10),
                            infoType: "error",
                            text:
                                respData.error ||
                                "Something went wrong. Please try again."
                        }
                    ]);
                }
            },
            onError: e => {
                addMessages([
                    {
                        id: cuuid(10),
                        infoType: "error",
                        text: "Something went wrong. Please try again."
                    }
                ]);
                setIsCancellingBatch(false);
            }
        }
    );

    const handleWithdrawPhotoBatch = () => {
        setIsCancellingBatch(true);
        setModalIsOpen(false);
        withdrawPhotoBatch();
    };

    const handleCancelButtonClick = () => {
        setModalIsOpen(true);
    };

    return (
        <>
            <Button
                onClick={isDisabled ? null : handleCancelButtonClick}
                size="sm"
                isProcessing={loading}
                isDisabled={isDisabled}
            >
                Cancel
            </Button>
            <PhotoUploadCancelModal
                modalIsOpen={modalIsOpen}
                closeModal={() => setModalIsOpen(false)}
                withdrawPhotoBatch={handleWithdrawPhotoBatch}
            />
        </>
    );
};

export default PhotoUploadCancel;
