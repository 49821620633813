import React from "react";
import queryString from "query-string";

// components
import SearchAndBrowsePageHeadlineWithAvatar from "core/SearchAndBrowsePageHeadlineWithAvatar";
import SearchEmptyResults from "core/SearchEmptyResults";
import SearchAndBrowseIconGridContainer from "icons/SearchAndBrowseIconGridContainer";
import SearchAndBrowsePageContainer from "core/SearchAndBrowsePageContainer";
import PageMeta from "core/PageMeta";

// queries
import { GET_ICON_SEARCH_DATA } from "icons/SearchIconsResults/queries";

// helpers
import { useParams } from "react-router-dom";
import useLoadMore from "frontend/hooks/useLoadMore";
import { capitalizeFirstChar } from "frontend/components/helpers";
import { CONTENT_TYPE, PROD_BASE_URL } from "core/constants";
import { PUBLIC_PROFILE } from "data/links";

const SearchIconsCreator = () => {
    const { username } = useParams();
    const { q, name, avatarUrl } = queryString.parse(location.search);

    const {
        items,
        totalCount,
        firstBatchFetched,
        loadingFirstBatch,
        loading,
        LoadMore,
        loadingItemCount
    } = useLoadMore({
        limit: 99,
        query: GET_ICON_SEARCH_DATA,
        queryVariables: {
            query: q,
            username
        },
        pathToListKey: "iconSearch.items",
        pathToTotalCountKey: "iconSearch.totalCount",
        contentType: CONTENT_TYPE.icon
    });

    const showEmptySearchResultsPage =
        !loadingFirstBatch && totalCount === 0 && firstBatchFetched;

    const pageTitle = `${capitalizeFirstChar({ str: q })} icons by ${name}`;
    const canonicalURL =
        PROD_BASE_URL +
        PUBLIC_PROFILE.replace(":username", encodeURIComponent(username));

    return (
        <>
            <PageMeta
                title={pageTitle}
                indexContent="noindex,follow"
                canonicalURL={canonicalURL}
            />
            <SearchAndBrowsePageContainer>
                <SearchAndBrowsePageHeadlineWithAvatar
                    avatarUrl={avatarUrl}
                    showSkeleton={loadingFirstBatch}
                >
                    Results for "{q}" by{" "}
                    <a
                        href={PUBLIC_PROFILE.replace(
                            ":username",
                            encodeURIComponent(username)
                        )}
                    >
                        {name}
                    </a>
                </SearchAndBrowsePageHeadlineWithAvatar>

                {showEmptySearchResultsPage ? (
                    <SearchEmptyResults
                        contentType={CONTENT_TYPE.icon}
                        query={q}
                    />
                ) : (
                    <SearchAndBrowseIconGridContainer
                        iconData={items}
                        totalCount={totalCount}
                        loadingFirstBatch={loadingFirstBatch}
                        loadingMore={loading}
                        LoadMoreButton={LoadMore}
                        loadingItemCount={loadingItemCount}
                    />
                )}
            </SearchAndBrowsePageContainer>
        </>
    );
};

export default SearchIconsCreator;
