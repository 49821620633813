import React from "react";
import PropTypes from "prop-types";

// Components
import LoadingSpinner from "core/LoadingSpinner";
import { useAuth } from "frontend/hooks/useAuth";
import { authLinks } from "data/links";

/* please only use LoginRequired component
within photos code until icon and photos routers
are unified */
export const LoginRequired = ({
    component: Component,
    loginPath = authLinks.signup.href,
    ...rest
}) => {
    const { isLoading, isLoggedIn } = useAuth();

    if (isLoading) {
        return <LoadingSpinner />;
    }

    if (!isLoggedIn && location.pathname != loginPath) {
        const next = encodeURIComponent(location.pathname + location.search);

        /* using window.location.replace instead of
        reach router's navigate fn because we are redirecting
        to a page that is not found in the photos router */
        window.location.replace(`${loginPath}?next=${next}`);
        return null;
    } else {
        return <Component {...rest} />;
    }
};

LoginRequired.propTypes = {
    component: PropTypes.func,
    loginPath: PropTypes.string
};

export default LoginRequired;
