export const getSuggestionId = (id, suggId) => {
    return `add-tag-suggestion-${id}-${suggId}`;
};

const getFocusedIndex = (suggestions, idPrefix) => {
    const activeElement = document.activeElement;
    let focusedIndex = null;

    suggestions.forEach((s, idx) => {
        const thisElement = document.getElementById(
            getSuggestionId(idPrefix, s.id)
        );

        if (activeElement === thisElement) {
            focusedIndex = idx;
        }
    });

    return focusedIndex;
};

const focusDomElementByOption = (option, idPrefix) => {
    if (option) {
        const optionDom = document.getElementById(
            getSuggestionId(idPrefix, option.id)
        );
        if (optionDom) {
            optionDom.focus();
        }
    }
};

export const focusPrevOption = (suggestions, idPrefix) => {
    const focusedIndex = getFocusedIndex(suggestions, idPrefix);
    const prevIndex = focusedIndex != null ? focusedIndex - 1 : 0;
    focusDomElementByOption(suggestions[prevIndex], idPrefix);
};

export const focusNextOption = (suggestions, idPrefix) => {
    const focusedIndex = getFocusedIndex(suggestions, idPrefix);
    const nextIndex = focusedIndex != null ? focusedIndex + 1 : 0;
    focusDomElementByOption(suggestions[nextIndex], idPrefix);
};
