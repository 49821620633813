import React from "react";
import PropTypes from "prop-types";

// components
import TagList from "./TagList";

// component
export const TagsWithApproval = ({ tags }) => {
    const pendingTags = tags.filter(t => !t.isApproved);
    const approvedTags = tags.filter(t => t.isApproved);
    return (
        <div>
            <TagList title="Pending" tags={pendingTags} />
            <TagList title="Approved" tags={approvedTags} />
        </div>
    );
};

export default TagsWithApproval;

// prop-types
TagsWithApproval.propTypes = {
    tags: PropTypes.array.isRequired
};
