import React, { createContext, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { GET_ICONS_BY_TERM_DATA, GET_PHOTOS_BY_TAG } from "./queries";

import useLoadMore from "frontend/hooks/useLoadMore";
import { TYPES } from "./constants";
import { CONTENT_TYPE } from "core/constants";

export const BrowsePageContext = createContext();

export const BrowsePageProvider = ({ children }) => {
    const [activeType, setActiveType] = useState(null);
    const [iconsTotalCount, setIconsTotalCount] = useState(0);
    const [photosTotalCount, setPhotosTotalCount] = useState(0);

    const { term, type } = useParams();

    useEffect(() => {
        if (!!type && activeType !== type) {
            setActiveType(type);
        }
    }, [type]);

    const useLoadMoreForIcons = useLoadMore({
        limit: 200, // if you update this, also change in noun_project/frontend/views.py browse_icons_by_tag()
        query: GET_ICONS_BY_TERM_DATA,
        queryVariables: {
            term
        },
        pathToListKey: "browseIconsByTerm.items",
        pathToTotalCountKey: "browseIconsByTerm.totalCount",
        useLazyFirstLoad: true,
        contentType: CONTENT_TYPE.icon,
        pageQueryParamKey: "iconspage"
    });

    const useLoadMoreForPhotos = useLoadMore({
        limit: 50,
        query: GET_PHOTOS_BY_TAG,
        queryVariables: {
            tag: term
        },
        pathToListKey: "browsePhotosByTag.items",
        pathToTotalCountKey: "browsePhotosByTag.totalCount",
        useLazyFirstLoad: true,
        contentType: CONTENT_TYPE.photo,
        pageQueryParamKey: "photospage"
    });

    const state = {
        activeType,
        term,
        loadingFirstBatch:
            useLoadMoreForIcons.loadingFirstBatch ||
            useLoadMoreForPhotos.loadingFirstBatch ||
            false,
        [TYPES.ICONS]: useLoadMoreForIcons,
        [TYPES.PHOTOS]: useLoadMoreForPhotos,
        iconsTotalCount,
        setIconsTotalCount,
        photosTotalCount,
        setPhotosTotalCount
    };

    return (
        <BrowsePageContext.Provider value={state}>
            {children}
        </BrowsePageContext.Provider>
    );
};

export const useBrowsePageContext = () => {
    const context = useContext(BrowsePageContext);

    if (context === undefined) {
        throw new Error(
            "useBrowsePageContext must be used within a BrowsePageProvider"
        );
    }

    return context;
};
