import React from "react";
import styled from "styled-components";
import { useLazyQuery } from "@apollo/client";

import colors from "frontend/components/core/colors";

import HoverTextOverlay from "core/HoverTextOverlay";
import ImagesContainerGridView from "./ImagesContainerGridView";

import RefreshingImage from "../../../../core/RefreshingImage";

import { PHOTO_UPLOAD_URLS } from "./queries";

export const IndividualImageBox = styled.div`
    position: relative;
    line-height: 0;
    background-color: ${colors.primary.diamond};

    img {
        object-fit: scale-down;
        max-height: 40rem;
    }
`;

const ImagesContainer = ({ uploads }) => {
    const [refreshImageUrl] = useLazyQuery(PHOTO_UPLOAD_URLS, {
        fetchPolicy: "network-only"
    });

    if (!uploads || !uploads.length) return null;

    const isGridVersion = uploads.length > 1;

    if (!isGridVersion) {
        const { id, photoTitle, originalFilename, thumbnail } = uploads[0];
        const uploadName = photoTitle || originalFilename;

        if (!thumbnail) return null;

        return (
            <IndividualImageBox>
                <RefreshingImage
                    src={thumbnail}
                    alt={uploadName}
                    fetchNewUrl={() =>
                        refreshImageUrl({
                            variables: {
                                photoUploadIds: [id]
                            }
                        })
                    }
                />
                <HoverTextOverlay hoverText={uploadName} />
            </IndividualImageBox>
        );
    } else {
        return <ImagesContainerGridView uploads={uploads} />;
    }
};

export default ImagesContainer;
