/*===================================
||
|| SiteHeaderContext
||
===================================*/
import React, { createContext, useReducer, useMemo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// config
import getDefaultStateByPage from "./context/getDefaultStateByPage";

// store
import { reducer } from "./context/store";

// context
import { useAuth } from "frontend/hooks/useAuth";

// responsive
import { useViewport } from "js/hooks/useViewport";

// components
import MountingWrapper from "./components/MountingWrapper";
import ScrollToPinned from "./components/ScrollToPinned.js";
import Mobile from "./Mobile";
import TabletDesktop from "./TabletDesktop";

//styles
const SiteHeaderStyled = styled.div`
    // TODO: remove once we replace all prototype images with components
    img.prototype {
        display: block;
    }
`;

/*---------------------------
| Context
---------------------------*/
export const SiteHeaderContext = createContext();
SiteHeaderContext.displayName = "SiteHeader"; // Display Name

// Provider
const SiteHeader = ({ isDarkMode, pageIsLoading, currentPage }) => {
    const { isMobile, device } = useViewport();
    const auth = useAuth();

    const nonStateProps = {
        isMobile,
        device,
        currentPage,
        isDarkMode,
        pageIsLoading,
        auth
    };

    const stateProps = getDefaultStateByPage(currentPage);

    // Whatever you want to manage as internal state
    const [state, dispatch] = useReducer(reducer, stateProps);

    // useMemo so it does not pass value on every render
    const value = useMemo(() => ({ state, dispatch, nonStateProps }), [
        state,
        dispatch,
        nonStateProps
    ]);

    return (
        <SiteHeaderContext.Provider value={value}>
            <SiteHeaderStyled>
                <MountingWrapper>
                    <ScrollToPinned>
                        {isMobile ? <Mobile /> : <TabletDesktop />}
                    </ScrollToPinned>
                </MountingWrapper>
            </SiteHeaderStyled>
        </SiteHeaderContext.Provider>
    );
};

export default SiteHeader;

// prop-types
SiteHeader.propTypes = {
    isDarkMode: PropTypes.bool.isRequired,
    pageIsLoading: PropTypes.bool.isRequired,
    currentPage: PropTypes.string
};
