import React from "react";

// components
import TextWithPopOutLink from "./TextWithPopOutLink";

const ContributorID = props => {
    return <TextWithPopOutLink title="Contributor ID" width={104} {...props} />;
};

export default ContributorID;
