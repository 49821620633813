import styled, { css } from "styled-components";

// styles
import colors from "core/colors";
import { convertPxToRem } from "frontend/components/helpers";

/*---------------------------
| Shared Template Components
---------------------------*/
// Asset Container Checkbox Label
export const AssetContainerStyled = styled.div`
    display: block;
    background-color: ${colors.primary.diamond};
    overflow: hidden;

    ${({ cursorIsPointer }) => {
        if (cursorIsPointer) {
            return css`
                cursor: pointer;
            `;
        }
    }}

    /* give asset container 1:1 aspect ratio */
    width: 100%;
    aspect-ratio: 1 / 1;

    /* We can freely position center image in container since it no longer influences dimensions of container */
    position: relative;
    z-index: 1;

    /* Using state isChecked and isFocused so we can style parent elements of checkbox */
    ${({ isChecked }) => {
        if (isChecked) {
            return css`
                outline: solid ${convertPxToRem(4)}
                    ${colors.functional.secondary.parrot};
            `;
        }
    }}

    ${({ isFocused, isChecked }) => {
        if (isFocused) {
            return css`
                box-shadow: ${isChecked
                    ? `0 0 ${convertPxToRem(14)} ${convertPxToRem(2)} #63b1fb`
                    : `0 0 ${convertPxToRem(4)} ${convertPxToRem(3)} #63b1fb`};
            `;
        }
    }}
`;

// Grid Item Content
export const GridItemContentStyled = styled.div`
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
`;
