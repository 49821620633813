import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import Link from "core/Link";

import {
    AuthCheckbox,
    AuthCheckboxError,
    AuthButton,
    AuthCaptcha,
    AlternativeLabel,
    FormErrorContainer
} from "../styles";
import FormInput from "core/FormInput2";
import FormError from "core/FormError";
import FacebookAuthButton from "../FacebookAuthButton";
import GoogleSignupButton from "../GoogleSignupButton";

import {
    validEmail,
    validNewPassword,
    attemptAuth,
    useSignupEmail,
    DEFAULT_SIGNUP_TYPE,
    SIGNUP_BACKEND_URL,
    SOCIAL_SIGNUP_BACKEND_URL
} from "../helpers";
import { getConfig } from "frontend/store/config";
import { FORGOT_PASSWORD } from "data/links";

const Signup = ({
    isSocialSignup = false,
    afterAuth,
    isStandaloneAuthPage
}) => {
    const { signupEmail: email, setSignupEmail: setEmail } = useSignupEmail({
        isSocialSignup
    });

    const [password, setPassword] = useState("");
    const [token, setToken] = useState(null);
    const [acceptMarketing, setAcceptMarketing] = useState(false);
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [acceptTermsError, setAcceptTermsError] = useState(false);
    const [backendError, setBackendError] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);

    const hasTermsError = !acceptTerms && acceptTermsError;

    const { accountSignupRequireCaptcha } = useSelector(getConfig);
    const requireCaptcha = !!accountSignupRequireCaptcha;

    const isDisabled = !(
        validEmail(email) &&
        (isSocialSignup || validNewPassword(password)) &&
        (!requireCaptcha || token)
    );

    const disableButtonOnCertainErrors =
        backendError ===
            "There is already an account for this email address." ||
        backendError ===
            "Unable to connect Google account because email address is already used for another account. Please use it to log in instead." ||
        backendError ===
            "Unable to connect Facebook account because email address is already used for another account. Please use it to log in instead.";

    const form = {
        signup_type: DEFAULT_SIGNUP_TYPE,
        email,
        password1: password,
        captcha_token: token,
        accept_terms: acceptTerms,
        receive_updates: acceptMarketing
    };
    if (!isSocialSignup) {
        form.password1 = password;
    }

    const onClick = () => {
        if (!acceptTerms) {
            setAcceptTermsError(true);
            return;
        }
        setIsProcessing(true);
        setBackendError(null);
        attemptAuth({
            url: isSocialSignup
                ? SOCIAL_SIGNUP_BACKEND_URL
                : SIGNUP_BACKEND_URL,
            form,
            onSuccess: afterAuth, // closes modal after auth is complete
            onFail(errorMsg) {
                setIsProcessing(false);
                setBackendError(errorMsg);
            }
        });
    };

    const formError = isStandaloneAuthPage ? (
        <FormErrorContainer>
            <FormError>{backendError}</FormError>
        </FormErrorContainer>
    ) : (
        <>
            <FormError>{backendError}</FormError>
        </>
    );

    return (
        <>
            {!isSocialSignup && formError}
            <FormInput
                value={email}
                onChange={value => {
                    setEmail(value);
                    if (!!backendError) setBackendError(null);
                }}
                labelText="Email Address"
                placeholderText="yourname@domain.com"
                validation={[
                    {
                        errorMsg: "Please enter a valid email.",
                        isValid: validEmail
                    }
                ]}
                size="lg"
            />
            {!isSocialSignup && (
                <>
                    <FormInput
                        type="password"
                        labelText="Password"
                        placeholderText="Password"
                        value={password}
                        onChange={setPassword}
                        validation={[
                            {
                                errorMsg:
                                    "Password must be a minimum of 8 characters.",
                                isValid: validNewPassword
                            }
                        ]}
                        size="lg"
                    />
                </>
            )}
            <AuthCheckbox
                label="Get free design resources in your inbox"
                onClick={() => setAcceptMarketing(!acceptMarketing)}
                isChecked={acceptMarketing}
                isFlexBox
            />
            <AuthCheckbox
                label={
                    <>
                        I agree to the{" "}
                        <Link
                            href="/legal/terms-of-use/"
                            target="_blank"
                            size="lg"
                        >
                            Terms of Use
                        </Link>
                    </>
                }
                inputValue="I agree to the Terms of Use"
                onClick={() => setAcceptTerms(!acceptTerms)}
                isChecked={acceptTerms}
                hasError={hasTermsError}
                isFlexBox
            />
            <AuthCheckboxError>
                {hasTermsError &&
                    "You must agree to the Terms of Use to create an account."}
            </AuthCheckboxError>
            {requireCaptcha && <AuthCaptcha handleToken={setToken} />}
            <AuthButton
                styleType="cta"
                isProcessing={isProcessing}
                isDisabled={isDisabled || disableButtonOnCertainErrors}
                onClick={onClick}
            >
                Create My Account
            </AuthButton>
            {isSocialSignup && backendError && formError}
            {!isSocialSignup && (
                <>
                    <AlternativeLabel>or</AlternativeLabel>
                    <GoogleSignupButton />
                    <FacebookAuthButton>
                        Sign up with Facebook
                    </FacebookAuthButton>
                </>
            )}
        </>
    );
};

Signup.propTypes = {
    isSocialSignup: PropTypes.bool,
    afterAuth: PropTypes.func.isRequired,
    isStandaloneAuthPage: PropTypes.bool
};

export default Signup;
