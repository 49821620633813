import { isRateLimited } from "frontend/hooks/useRateLimitError";
import { isNotEmpty } from "./utils";
import { getDefaultErrorMessage } from "./messaging";

/**
 * handleError
 * @param {Object} enhancedResponse
 * @param {Function} errorHandler
 * @returns {Object} resp
 */
export const handleError = (enhancedResponse, errorHandler) => {
    let resp = { ...enhancedResponse };

    // global Rate Limit error handling
    if (isRateLimited(resp.ssResp)) {
        resp.message =
            "Editing speed limited for security. Please wait a moment and try again.";
    }

    // See if consumer has anything to say about this...
    if (errorHandler) {
        resp = errorHandler(resp);
    }
    console.error(resp.message, resp);
    return resp;
};

/**
 * extractSsErrors: grab all potential error messages from Server Response
 * @param {Object} ssResp Server Side Response
 * @param {String} actionDesc
 * @param {String} errorMessageOverride
 * @returns {Array} errors array of string errors that consumer can use to create more meaningful errors.
 */
export const extractSsErrors = (ssResp, actionDesc, errorMessageOverride) => {
    let errors = [];

    // SSErrors: graphQLErrors or root message
    if (ssResp?.graphQLErrors) {
        ssResp.graphQLErrors.forEach(ssGraphQLError => {
            if (isNotEmpty(ssGraphQLError?.message)) {
                errors.push(ssGraphQLError.message);
            }
        });
    } else if (ssResp?.message) {
        errors.push(ssResp?.message);
    }

    // SSErrors: Root Errors
    const rootErrors = ssResp?.errors || [];
    rootErrors.forEach(ssRootError => {
        if (isNotEmpty(ssRootError?.message)) {
            errors.push(ssRootError.message);
        }
    });

    // SSErrors: API Data Response Errors
    const data = ssResp?.data || {};
    Object.entries(data).forEach(([key, value]) => {
        // I have not seen anything other than strings on sub data responses thus far
        // Maybe BE sends arrays sometimes, or something else? We should adress if we see it.
        const responseErrors = data[key]?.errors;
        if (isNotEmpty(responseErrors)) {
            if (typeof responseErrors === "string") {
                errors.push(responseErrors);
            } else {
                console.error(
                    `extractSsErrors unhandled "errors" type: ${typeof responseErrors}. We should address this error type.`
                );
            }
        }
    });

    // SSErrors: confirm all ok's returned on respObj's are true
    // sometimes backend sends `ok` as false without `errors` array or sub errors string
    // no need to do this work if we already found errors above
    if (errors.length === 0) {
        let ok = true;
        Object.entries(data).forEach(([key, value]) => {
            const respObj = data[key];
            if (respObj?.ok === false) {
                ok = false;
            }
        });

        if (!ok) {
            // in that case add default error message
            errors.push(
                getDefaultErrorMessage(actionDesc, errorMessageOverride)
            );
        }
    }

    return errors;
};
