import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import SubmissionsHeaderButtons from "./SubmissionsHeaderButtons";
import LinkToApprovedPhotos from "./LinkToApprovedPhotos";

import { SubmissionsHeadingContainer } from "./styles";

const SubmissionsHeader = ({ pendingCount, deniedCount }) => {
    const { username: creatorUsername } = useParams();

    return (
        <SubmissionsHeadingContainer>
            <SubmissionsHeaderButtons
                creatorUsername={creatorUsername}
                pendingCount={pendingCount}
                deniedCount={deniedCount}
            />
            <LinkToApprovedPhotos creatorUsername={creatorUsername} />
        </SubmissionsHeadingContainer>
    );
};

SubmissionsHeader.propTypes = {
    pendingCount: PropTypes.number.isRequired,
    deniedCount: PropTypes.number.isRequired
};

export default SubmissionsHeader;
