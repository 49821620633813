"use client";
import React from "react";
import {
    createTheme,
    StyledEngineProvider,
    ThemeProvider
} from "@mui/material/styles";

import breakpoints from "core/breakpoints";

export const MuiThemeProvider = ({ theme = {}, children }) => {
    const themeStyles = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 0,
                md: breakpoints.tablet.px,
                lg: breakpoints.desktop.px,
                xl: 1440
            }
        },
        zIndex: {
            // we need to bump these up from the defaults to keep modal above site header
            modal: 10001,
            tooltip: 10002
        },
        ...theme
    });

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themeStyles}>{children}</ThemeProvider>
        </StyledEngineProvider>
    );
};
