import React from "react";
import PropTypes from "prop-types";

// styles
import { QueueAsideStyled } from "./styles";

export const QueueAside = ({ children }) => {
    return <QueueAsideStyled>{children}</QueueAsideStyled>;
};

// prop-types
QueueAside.propTypes = {
    children: PropTypes.any
};
