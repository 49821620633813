import styled from "styled-components";

import Grid from "core/Grid";
import Heading from "core/Heading";
import { regular as regularFontWeight, headingMd } from "core/fontStyles";
import breakpoints from "core/breakpoints";
import colors from "core/colors";
import Button from "core/Button";

import { getLinkElementStyles } from "core/Link/styles";
import { convertPxToRem } from "frontend/components/helpers";

export const CollectionSpotlightGridItem = styled(Grid)``;

export const SpotlightTextContentsHeading = styled(Heading)`
    padding-bottom: 1.5rem; /* 24px */
    br.mobile-only-break {
        /* hide this break on tablet + desktop + */
        @media (min-width: ${breakpoints.tablet.rem}) {
            display: none;
        }
    }
`;

export const Figure = styled.figure`
    width: 100%;

    img {
        width: 100%;
    }

    figcaption {
        padding-top: 1rem; /* 16px */
        font-size: 0.9375rem; /* 15px */
        line-height: 160%;
        color: ${colors.secondary.oolong};
        ${regularFontWeight}
    }
`;

export const Blockquote = styled.blockquote`
    margin-top: 2.5rem;
    margin-bottom: 1rem;
    ${headingMd}

    @media (max-width: ${breakpoints.beforeDesktop.rem}) {
        margin-top: 2rem;
    }

    @media (max-width: ${breakpoints.beforeTablet.rem}) {
        margin-top: 1.5rem;
    }

    // When CollectionSpotlight has a quote without a collectionHeading or collectionText
    &:first-child {
        margin-top: 0;
    }
`;

export const Paragraph = styled.p`
    font-size: 1rem; /* 16px */
    line-height: 1.625rem; /* 26px */
    color: ${colors.secondary.orca};
    ${regularFontWeight}

    &:not(:last-of-type) {
        padding-bottom: 1.5rem; /* 24px */
    }

    a {
        ${getLinkElementStyles({
            display: "inline",
            size: "lg"
        })}
    }
`;

export const CollectionSpotlightLink = styled(Button).attrs({
    width: "100%"
})`
    margin-top: ${convertPxToRem({ px: 24 })};

    @media (min-width: ${breakpoints.beforeTablet.rem}) {
        width: auto;
        min-width: ${convertPxToRem({ px: 169 })};

        &:nth-of-type(2) {
            margin-left: ${convertPxToRem({ px: 16 })};
        }
    }
`;
