import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import colors from "core/colors";

const createHelpers = (width, height, css) => {
    // somehow sizes is ending up in markup, even if it is not a valid svg attribute
    // until we have a better solution, just render it empty, instead to '[Object object]'
    const sanitizeSizes = sizes =>
        Object.defineProperty(sizes, "toString", {
            value: () => "",
            enumerable: false
        });

    const getDimensions = (size, sizes) => {
        if (
            size &&
            typeof size.width === "number" &&
            typeof size.height === "number"
        ) {
            return size;
        }

        return size && sizes[size] ? sizes[size] : { width, height };
    };

    const getCss = (size, sizes, fillColor, fillColorRule, noStyles) => {
        if (noStyles) {
            return "";
        }

        const dimensions = getDimensions(size, sizes);
        const fillRule =
            fillColor && fillColorRule
                ? `${fillColorRule}{ fill: ${fillColor}; }`
                : "";

        return css`
            width: ${dimensions.width}px;
            height: ${dimensions.height}px;
            ${fillRule}
        `;
    };

    const propsToCss = ({ size, sizes, fillColor, fillColorRule, noStyles }) =>
        getCss(size, sizes, fillColor, fillColorRule, noStyles);

    return {
        getCss,
        getDimensions,
        propsToCss,
        sanitizeSizes
    };
};

const width = "62";
const height = "40";
const viewBox = "0 0 62 40";

const { getDimensions, getCss, propsToCss, sanitizeSizes } = createHelpers(
    width,
    height,
    css
);

const sizes = sanitizeSizes({});

const Image = styled.svg`
    ${propsToCss}
`;

const children = (
    <Fragment>
        <path
            fill={colors.secondary.orca}
            fillRule="evenodd"
            d="M0 19.916C0 16.002 3.409 13 7.333 13.001V13c3.973 0 7.333 3.026 7.333 6.964 0 3.794-3.213 7.036-7.333 7.036C3.335 27 0 23.854 0 19.916zm20.2-1.55c.07.02.138.041.205.062l.001-.001c2.232.673 4.095 1.753 4.095 4.323 0 2.617-1.961 4.25-4.561 4.25a5.42 5.42 0 01-1.742-.313c-2.011-.696-2.997-2.301-2.997-4.39h2.8c.098 1.368.468 2.422 1.988 2.422 1.128 0 1.962-.673 1.962-1.825 0-1.211-1.173-1.624-2.5-2.09-1.723-.605-3.705-1.302-3.705-3.962 0-2.282 2.036-3.842 4.267-3.842 2.33 0 4.12 1.584 4.168 3.892h-2.55c-.123-.961-.711-1.61-1.741-1.61-.859 0-1.595.529-1.595 1.417 0 1.092 1.015 1.398 1.906 1.667zm12.296-3.084c1.815 0 3.065.937 3.826 2.498h2.82c-.76-2.86-3.752-4.78-6.67-4.78-4.023 0-6.99 3.098-6.99 6.988 0 3.89 2.918 7.012 6.965 7.012 3.114 0 5.69-1.873 6.695-4.73h-2.845c-.711 1.536-2.11 2.449-3.85 2.449-2.723 0-4.415-2.21-4.415-4.731 0-2.617 1.668-4.707 4.464-4.707v.001zM47.088 13c1.757 0 3.507.675 4.529 2.091v-1.718H54.4v13.236h-2.782v-1.851C50.639 26.26 48.89 27 47.088 27c-4.07 0-7.21-2.93-7.21-6.94 0-4.034 3.09-7.06 7.21-7.06zm11.454 1.56l-.142-.003v-1.184h-2.436v13.236H58.8v-7.386c0-1.201.159-2.573 1.36-3.173.49-.264 1.276-.48 1.84-.48V13c-1.374.048-2.625.408-3.458 1.56zM47.064 24.72c-2.6 0-4.635-2.186-4.635-4.683 0-2.593 1.962-4.755 4.66-4.755 2.77 0 4.708 2.09 4.708 4.755 0 2.57-2.085 4.683-4.733 4.683zm-39.731 0c-2.649 0-4.782-2.138-4.782-4.731s2.133-4.707 4.782-4.707 4.782 2.09 4.782 4.683a4.769 4.769 0 01-4.782 4.755z"
            clipRule="evenodd"
            key="key-0"
        />
    </Fragment>
);

const defaultProps = {
    children,
    viewBox,
    fillColor: null,
    fillColorRule: "&&& path, &&& use, &&& g, &&& circle",
    sizes,
    size: null
};

Image.propTypes /* remove-proptypes */ = {
    fillColor: PropTypes.string,
    fillColorRule: PropTypes.string,
    viewBox: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    size: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            height: PropTypes.number.isRequired,
            width: PropTypes.number.isRequired
        })
    ]),
    sizes: PropTypes.shape({
        height: PropTypes.number,
        width: PropTypes.number
    })
};

export default Object.assign(Image, {
    getDimensions,
    getCss,
    defaultProps,
    displayName: "Oscar"
});
