import React, { useState, useEffect } from "react";
import styled from "styled-components";

// context
import { useBatches } from "frontend/components/fort/mod/photos/components/PhotoModQueuePageContent/QueueProviders/BatchesProvider/index.js";

// components
import Button from "core/Button";

// styles
import { convertPxToRem } from "frontend/components/helpers";

export const LoadMoreButtonStyled = styled.div`
    text-align: center;
    margin: ${convertPxToRem(50)} 0px;
`;

// component
export const LoadMoreButton = () => {
    const [isProcessing, setIsProcessing] = useState(false);

    const {
        ids: batches,
        searchParams,
        setUrlSearchParams,
        pagination,
        isCallingApi,
        showLoading
    } = useBatches();

    const {
        batchesPerPage,
        totalCount,
        remainder,
        isSecondToLastPage,
        isLastPage
    } = pagination;

    const { currentPage } = searchParams;

    const onClick = () => {
        setIsProcessing(true);
        setUrlSearchParams([
            {
                key: "currentPage",
                value: Number(currentPage) + 1
            }
        ]);
    };

    useEffect(() => {
        if (!isCallingApi) {
            setIsProcessing(false);
        }
    }, [isCallingApi]);

    // only show Load More if there is more than one page of data
    if (!batches || showLoading || isLastPage) return null;

    let text = `Load next ${batchesPerPage} of ${totalCount}`;
    if (isSecondToLastPage) {
        text = `Load last ${remainder} of ${totalCount}`;
    }

    return (
        <LoadMoreButtonStyled>
            <Button onClick={onClick} isProcessing={isProcessing}>
                {text}
            </Button>
        </LoadMoreButtonStyled>
    );
};

export default LoadMoreButton;
