import { useLayoutEffect, useEffect } from "react";

/*
    If you need to use useLayoutEffect on something that will be rendered by Next (SSR)
    you will see a ton of nasty warnings in pm2. To see these logs, run `yarn next-log`
    in a separate terminal inside www. This pattern was taken from
    https://medium.com/@alexandereardon/uselayouteffect-and-ssr-192986cdcf7a and is used in
    react-redux.
*/

const useIsomorphicLayoutEffect =
    typeof window !== "undefined" ? useLayoutEffect : useEffect;

export default useIsomorphicLayoutEffect;
