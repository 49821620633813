import gql from "graphql-tag";
import { useApolloMutation } from "frontend/utils/apolloApi/useApolloMutation.js";

// GQL
const REJECT_TAGS = gql`
    mutation rejectTagsFromPhotoUploads(
        $photoUploadIds: [ID!]
        $tags: [String!]
    ) {
        payload: rejectTagsFromPhotoUploads(
            photoUploadIds: $photoUploadIds
            tags: $tags
        ) {
            ok
            errors {
                photoUploadId
                reason
                tag
            }
            photoUploads {
                id
                userTags
                warningUserTags
                autoTags
                approvedUserTags
            }
        }
    }
`;

export const useRejectTags = () => {
    const { apolloMutate, loading } = useApolloMutation({
        actionDesc: "Reject Photo Upload Tag",
        gql: REJECT_TAGS
    });

    const rejectTags = async variables => {
        return await apolloMutate({ variables });
    };

    return {
        rejectTags,
        unapproveTagsLoading: loading
    };
};
