import React from "react";

// redux
import { useSelector } from "react-redux";

// Components
import ActionBar, {
    ActionBarLeft,
    ActionBarRight
} from "frontend/components/fort/mod/core/ActionBar";
import SelectAllAndMessaging from "./SelectAllAndMessaging";
import FlagManagement from "./FlagManagement";
import ModerationToggleBand from "./ModerationToggleBand";
import BatchApproval from "./BatchApproval";

import { QUEUES } from "../../constants";

const PhotoModQueueHeader = () => {
    const selectedBatch = useSelector(state => state.selectedBatch);
    const currentQueue = useSelector(state => state.currentQueue.name);
    // TODO: hiding when in large view until we can resolve sychning carousel with upload grid selection
    const { kind, isActive } = useSelector(state => state.largeImageView);
    const hideContent = isActive && kind === "slideshow";

    const aBatchIsSelected = !!selectedBatch?.id;
    const isSuspendedQueue = currentQueue === QUEUES.SUSPENDED;

    return (
        <ActionBar>
            {!hideContent && (
                <>
                    <ActionBarLeft>
                        <SelectAllAndMessaging />
                    </ActionBarLeft>
                    {aBatchIsSelected && (
                        <ActionBarRight>
                            <FlagManagement hide={isSuspendedQueue} />
                            <ModerationToggleBand />
                            <BatchApproval hide={isSuspendedQueue} />
                        </ActionBarRight>
                    )}
                </>
            )}
        </ActionBar>
    );
};

export default PhotoModQueueHeader;
