import React from "react";
import PropTypes from "prop-types";

import { headingSizes, headingTypes } from "core/props";

import Heading from "core/Heading";
import Grid from "core/Grid";

import {
    PostContent,
    ImageContainerWithLink,
    ImageContainer,
    TitleWithLink,
    Title,
    CategoryWithLink,
    Subtitle,
    Links
} from "./styles";
import colors from "core/colors";

const BlogPostsBoxes = ({
    posts,
    subtitleLinkInverse = false,
    blogPostHeadingType,
    blogPostHeadingSize,
    subtitleSize
}) => (
    <Grid mobileSpacing={48} tabletSpacing={20} desktopSpacing={48}>
        {posts.map(post => {
            const {
                imageIsLink,
                headingIsLink,
                subtitleIsLink,
                title,
                subtitle,
                category,
                imgSrc,
                url,
                target,
                links
            } = post;

            // support both webpack (string) & next.js (object) image imports
            const src = !!imgSrc && imgSrc.src ? imgSrc.src : imgSrc;

            return (
                <Grid item sm={12} md={4} lg={4} key={title}>
                    <PostContent>
                        {imageIsLink ? (
                            <ImageContainerWithLink
                                href={url}
                                target={target ? target : "_self"}
                            >
                                <img src={src} title={title} />
                            </ImageContainerWithLink>
                        ) : (
                            <ImageContainer>
                                <img src={src} title={title} />
                            </ImageContainer>
                        )}

                        <Heading
                            type={
                                blogPostHeadingType ? blogPostHeadingType : "h6"
                            }
                            size={
                                blogPostHeadingSize ? blogPostHeadingSize : "xs"
                            }
                        >
                            {headingIsLink ? (
                                <TitleWithLink
                                    href={url}
                                    target={target ? target : "_self"}
                                >
                                    {title}
                                </TitleWithLink>
                            ) : (
                                <Title>{title}</Title>
                            )}
                        </Heading>

                        <Subtitle
                            type="div"
                            size={subtitleSize ? subtitleSize : "lg"}
                            copyColor={colors.secondary.oolong}
                            subtitleIsLink={subtitleIsLink}
                        >
                            {subtitleIsLink ? (
                                <span>
                                    <CategoryWithLink
                                        subtitleLinkInverse={
                                            subtitleLinkInverse
                                        }
                                        href={url}
                                        target={target ? target : "_self"}
                                    >
                                        {category}
                                    </CategoryWithLink>
                                </span>
                            ) : (
                                subtitle
                            )}
                        </Subtitle>

                        {links && (
                            <Links>
                                {links.map(link => {
                                    const Icon = link.icon;
                                    return (
                                        <a
                                            key={link.altInfo}
                                            href={link.url}
                                            target="_blank"
                                            aria-label={link.altInfo}
                                        >
                                            <Icon
                                                title={link.altInfo}
                                                fillColor={
                                                    colors.secondary.oolong
                                                }
                                                size="lg"
                                            />
                                        </a>
                                    );
                                })}
                            </Links>
                        )}
                    </PostContent>
                </Grid>
            );
        })}
    </Grid>
);

BlogPostsBoxes.propTypes = {
    posts: PropTypes.array.isRequired,
    subtitleLinkInverse: PropTypes.bool,
    blogPostHeadingType: PropTypes.oneOf(headingTypes),
    blogPostHeadingSize: PropTypes.oneOf(headingSizes),
    subtitleSize: PropTypes.oneOf(headingSizes)
};

export default BlogPostsBoxes;

// This is being used in nounproject/www/static/assets/js/components/Leadership/index.js
