import React from "react";

// components
import TextWithPopOutLink from "./TextWithPopOutLink";

const FullyPublished = props => {
    return (
        <TextWithPopOutLink
            title="Full Pub"
            titleFull="Fully Published"
            width={69}
            {...props}
        />
    );
};

export default FullyPublished;
