import { createSlice, createSelector } from "@reduxjs/toolkit";

///////////////////////////
//   reducer & actions   //
///////////////////////////

const initialState = {
    clickableIcons: [],
    showingIntlPrice: false
};

const marketing = createSlice({
    name: "marketing",
    initialState,
    reducers: {
        setClickableIcons(state, action) {
            state.clickableIcons = action.payload;
        },
        setShowingIntlPrice(state, action) {
            state.showingIntlPrice = action.payload === true;
        }
    }
});

export default marketing.reducer;

export const { setClickableIcons, setShowingIntlPrice } = marketing.actions;

////////////////////////////
//   selector-functions   //
////////////////////////////

const selectSelf = state => state.marketing;

export const getClickableIcons = createSelector(
    selectSelf,
    state => state.clickableIcons
);

export const getShowingIntlPrice = createSelector(
    selectSelf,
    state => state.showingIntlPrice
);
