import React from "react";
import PropTypes from "prop-types";

// styles
import { HeaderStyled, TitleStyled } from "./styles.js";

// Components
import CloseButton from "./CloseButton.js";

const Header = ({ children, headerHeight }) => {
    return (
        <HeaderStyled headerHeight={headerHeight}>
            <TitleStyled headerHeight={headerHeight}>{children}</TitleStyled>
            <CloseButton />
        </HeaderStyled>
    );
};

export default Header;

// prop-types
Header.propTypes = {
    children: PropTypes.any,
    headerHeight: PropTypes.string
};
