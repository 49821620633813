import styled, { css } from "styled-components";
import PropTypes from "prop-types";

import { OverflowMenuMobile } from "../OverflowMenuMobile";
import { OverflowMenuDesktop } from "../OverflowMenuDesktop";
import { openMobileMenuStyles } from "../OverflowMenuMobile/styles";

import breakpoints from "core/breakpoints";
import colors from "core/colors";
import { easingSeconds } from "core/animation";
import { focusOutline } from "core/links";

// wrapper:
const Wrapper = styled.div`
    position: relative;

    ${OverflowMenuDesktop} {
        /* toggle menu */
        display: ${({ open }) => (open ? "block" : "none")};

        /* hide menu on smaller screens */
        @media (max-width: ${breakpoints.beforeTablet.rem}) {
            display: none;
        }
    }

    ${OverflowMenuMobile} {
        display: none;

        @media (max-width: ${breakpoints.beforeTablet.rem}) {
            /* toggle menu  */
            ${({ open }) => open && openMobileMenuStyles}
        }
    }
`;

Wrapper.propTypes = {
    open: PropTypes.bool
};

// TriggerButton:
const darkModeHover = css`
    background: ${colors.secondary.basalt};

    & path,
    & use,
    & g {
        fill: ${colors.accent.darkMode.suenosBuenos};
    }
`;

const lightModeHover = css`
    background: ${colors.primary.scheifer};

    & path,
    & use,
    & g {
        fill: ${colors.secondary.orca};
    }
`;

const defaultFillColors = css`
    cursor: pointer;
    border: 0;
    padding: 0;
    background: transparent;
    width: 1.5rem; /* 24px */
    height: 1.5rem; /* 24px */
    border-radius: 100%;
    transition: all ${easingSeconds};

    & path,
    & use,
    & g {
        transition: all ${easingSeconds};
        fill: ${({ active, isHeroSearchV2 }) => {
            if (active && isHeroSearchV2)
                return colors.accent.darkMode.suenosBuenos;
            if (isHeroSearchV2) return colors.primary.diamond;
            if (active) return "orange";
            return colors.secondary.oolong;
        }};
    }

    ${({ active, isHeroSearchV2 }) =>
        active && (isHeroSearchV2 ? darkModeHover : lightModeHover)}
`;

const StyledTriggerButton = styled.button`
    ${defaultFillColors}

    &:hover {
        ${({ isHeroSearchV2 }) =>
            isHeroSearchV2 ? darkModeHover : lightModeHover}
    }

    &:focus {
        ${focusOutline}
    }

    ${({ isHeader }) =>
        isHeader &&
        css`
            /* Mobile-only: */
            @media (max-width: ${breakpoints.beforeTablet.rem}) {
                margin-right: 1rem; /* 16px */
            }

            /* Tablet & Desktop only: */
            @media (min-width: ${breakpoints.tablet.rem}) {
                margin-left: 1rem; /* 16px */
            }
        `}

    svg {
        margin-top: 0.125rem; /* 2px */
    }
`;

StyledTriggerButton.propTypes = {
    active: PropTypes.bool,
    isHeroSearchV2: PropTypes.bool
};

export { Wrapper, StyledTriggerButton };
