import gql from "graphql-tag";
import { useApolloMutation } from "frontend/utils/apolloApi/useApolloMutation.js";

// GQL
const REMOVE_TAGS = gql`
    mutation removeTagsFromPhotoUploads(
        $photoUploadIds: [ID!]
        $tags: [String!]
    ) {
        payload: removeTagsFromPhotoUploads(
            photoUploadIds: $photoUploadIds
            tags: $tags
        ) {
            ok
            photoUploads {
                id
                userTags
                warningUserTags
                autoTags
                approvedUserTags
            }
            tagsNotRemoved {
                tag
                reason
                photoUploadId
            }
            tagsRemoved {
                tag
                photoUploadId
            }
        }
    }
`;

export const useRemoveTags = () => {
    const { apolloMutate, loading } = useApolloMutation({
        actionDesc: "Remove Photo Upload Tags",
        gql: REMOVE_TAGS
    });

    const removeTags = async variables => {
        return await apolloMutate({ variables });
    };

    return {
        removeTags,
        removeTagsLoading: loading
    };
};
