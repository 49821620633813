import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import SectionContainer from "core/SectionContainer";
import ManageReleasesButton from "./ManageReleasesButton";
import ClearProcessingPhotosButton from "./ClearProcessingPhotosButton";
import Flexbox from "core/Flexbox";
import AddPhotosButton from "./AddPhotosButton";
import PhotoUploadCancel from "./PhotoUploadCancel";
import PhotoUploadSubmit from "./PhotoUploadSubmit";
import DeletePhotosButton from "./DeletePhotosButton";
import CheckboxButton from "core/CheckboxButton";

import Link from "core/Link";
import EditIcon from "core/ReusableIcons/Edit";

import { useEditPhotoUpload } from "../PhotoUpload/Edit/EditPhotoUploadContext";
import { useSelect } from "frontend/components/core/Grid2/GridItemContentTemplates/Select/SelectContext/index.js";

const Container = styled(SectionContainer)`
    position: sticky;
    top: 106px;
    z-index: 2;
    padding: 1.5rem 0;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15);
`;

const Spacer = styled.span`
    display: inline-block;
    border-left: 1px solid #ccc;
    align-self: stretch;
`;

export const SelectAllPhotosButton = ({ isAllSelected, handleChange }) => {
    return (
        <CheckboxButton
            size="sm"
            label="Select All"
            inputValue="select-all"
            ariaId="select-all-photos"
            isChecked={isAllSelected}
            onChange={handleChange}
        />
    );
};

export const EditPhotosButton = ({ quantitySelected, handleClick }) => {
    if (quantitySelected < 1) return null;
    return (
        <Link size="sm" isButton onClick={handleClick}>
            <EditIcon />
            {`Edit ${quantitySelected} Photo${quantitySelected > 1 ? "s" : ""}`}
        </Link>
    );
};

export const PhotoUploadToolbarUnwrapped = ({
    uploadCount = 0,
    currentlyProcessingCount = 0,
    initialMaximumFiles = 0,
    isAllSelected,
    quantitySelected,
    uploadsSelected,
    getSelectedUploadIds,
    selectAll,
    deselectAll,
    openEditModal
}) => {
    const showManageButtons = uploadCount > 0 && currentlyProcessingCount === 0;
    const showClearPendingButton = currentlyProcessingCount > 0;

    return (
        <Container topSpacing="none" bottomSpacing="none">
            <Flexbox
                justifyContent={showManageButtons ? "space-between" : "end"}
                alignItems="center"
            >
                {showManageButtons && (
                    <SelectAllPhotosButton
                        isAllSelected={isAllSelected}
                        handleChange={isAllSelected ? deselectAll : selectAll}
                        quantitySelected={quantitySelected}
                    />
                )}

                <Flexbox alignItems="center" columnGap="2rem">
                    {showManageButtons && (
                        <Flexbox alignItems="center" columnGap="1rem">
                            <EditPhotosButton
                                handleClick={openEditModal}
                                quantitySelected={quantitySelected}
                            />
                            <DeletePhotosButton
                                quantitySelected={quantitySelected}
                                uploadsSelected={uploadsSelected}
                                getSelectedUploadIds={getSelectedUploadIds}
                                deselectAll={deselectAll}
                            />
                        </Flexbox>
                    )}
                    <Flexbox alignItems="center" columnGap="1rem">
                        {showManageButtons && (
                            <>
                                <AddPhotosButton />
                                <ManageReleasesButton />
                                <Spacer />
                            </>
                        )}
                        {showClearPendingButton && (
                            <ClearProcessingPhotosButton />
                        )}

                        <PhotoUploadCancel />

                        <PhotoUploadSubmit
                            uploadCount={uploadCount}
                            currentlyProcessingCount={currentlyProcessingCount}
                            initialMaximumFiles={initialMaximumFiles}
                        />
                    </Flexbox>
                </Flexbox>
            </Flexbox>
        </Container>
    );
};

PhotoUploadToolbarUnwrapped.propTypes = {
    uploadCount: PropTypes.number.isRequired,
    currentlyProcessingCount: PropTypes.number.isRequired,
    initialMaximumFiles: PropTypes.number.isRequired,
    isAllSelected: PropTypes.bool.isRequired,
    quantitySelected: PropTypes.number.isRequired,
    uploadsSelected: PropTypes.object.isRequired,
    getSelectedUploadIds: PropTypes.func.isRequired,
    selectAll: PropTypes.func.isRequired,
    deselectAll: PropTypes.func.isRequired,
    openEditModal: PropTypes.func.isRequired
};

const PhotoUploadToolbar = ({
    uploadCount = 0,
    currentlyProcessingCount = 0,
    initialMaximumFiles = 0
}) => {
    const { openEditModal } = useEditPhotoUpload();
    const {
        isAllSelected,
        quantitySelected,
        uploadsSelected,
        selectAll,
        deselectAll,
        getSelectedUploadIds
    } = useSelect();

    const props = {
        uploadCount,
        currentlyProcessingCount,
        initialMaximumFiles,
        isAllSelected,
        quantitySelected,
        uploadsSelected,
        getSelectedUploadIds,
        selectAll,
        deselectAll,
        openEditModal
    };

    return <PhotoUploadToolbarUnwrapped {...props} />;
};

PhotoUploadToolbar.propTypes = {
    uploadCount: PropTypes.number.isRequired,
    currentlyProcessingCount: PropTypes.number.isRequired,
    initialMaximumFiles: PropTypes.number
};

export default PhotoUploadToolbar;
