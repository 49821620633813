import React from "react";
import PropTypes from "prop-types";

// context
import { useInlineTextEdit } from "core/InlineTextEdit/index.js";

// styles
import { CheckboxStyled } from "./styles.js";
import colors from "core/colors";

// Components
import CoreCheckbox from "core/Checkbox";

export const Checkbox = ({ isApproved, onToggleApprove, checkboxStyles }) => {
    const { isEditing, disabled } = useInlineTextEdit();

    return (
        <CheckboxStyled isEditing={isEditing}>
            <CoreCheckbox
                isChecked={isApproved}
                onClick={onToggleApprove}
                isApprovedBackground={colors.alternative.lightMode.shamrock}
                checkboxStyles={checkboxStyles}
                isDisabled={disabled}
            />
        </CheckboxStyled>
    );
};

Checkbox.propTypes = {
    isApproved: PropTypes.bool.isRequired,
    onToggleApprove: PropTypes.func.isRequired,
    checkboxStyles: PropTypes.object
};
