import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import MaterialTooltip from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";

import { StyledChildrenContainer } from "./styles";

const UnstyledClickToOpen = ({ className, children, ...tooltipProps }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isFocused, setIsFocused] = useState(false);

    const handleTooltipClose = useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);

    const handleTooltipOpen = useCallback(() => {
        setIsOpen(v => !v);
    }, [setIsOpen]);

    // if focussed, can open with enter key
    const handleKeyPress = useCallback(
        e => {
            if (e.key === "Enter") {
                handleTooltipOpen();
            }
            if (e.key === "Escape") {
                handleTooltipClose();
            }
        },
        [handleTooltipOpen, handleTooltipClose]
    );

    useEffect(() => {
        if (isFocused || isOpen) {
            document.addEventListener("keydown", handleKeyPress);
        } else {
            document.removeEventListener("keydown", handleKeyPress);
        }

        return () => {
            document.removeEventListener("keydown", handleKeyPress);
        };
    }, [isFocused, isOpen, handleKeyPress]);

    return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <MaterialTooltip
                className={className}
                open={isOpen}
                onClose={handleTooltipClose}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                {...tooltipProps}
            >
                <StyledChildrenContainer
                    tabIndex={0}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    onClick={e => {
                        // without this, tooltip will immediately close
                        // if placed within another clickable element (eg radio input)
                        e.stopPropagation();
                        e.preventDefault();
                        handleTooltipOpen();
                    }}
                    className={className}
                    data-testid="click-to-open"
                >
                    {children}
                </StyledChildrenContainer>
            </MaterialTooltip>
        </ClickAwayListener>
    );
};

const ClickToOpen = styled(UnstyledClickToOpen)``;

ClickToOpen.propTypes = {
    children: PropTypes.any.isRequired
};

export default ClickToOpen;
