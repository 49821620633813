import styled from "styled-components";

import { boxShadow } from "core/SiteHeader/constants";
import colors from "core/colors";

export const Wrapper = styled.div`
    background-color: ${({ isHeroSearchV2 }) =>
        isHeroSearchV2 ? colors.primary.snow : colors.secondary.orca};
    ${({ isHeroSearchV2 }) => isHeroSearchV2 && `box-shadow: ${boxShadow}`};
    padding: 1.5625rem 1.875rem 1.875rem 1.875rem;
    display: inline-block;

    ${({ isHeroSearchV2 }) =>
        isHeroSearchV2 &&
        `
            p {
                color: ${colors.secondary.orca};
            }
            
            &.scrolledDown {
                background-color: ${colors.secondary.orca};

                p {
                    color: ${colors.primary.snow};
                }
            }

            &.scrolledUp {
                background-color: ${colors.primary.snow};

                p {
                    color: ${colors.secondary.orca};
                }
            }
        `};
`;
