import React from "react";
import { useSelector } from "react-redux";

// data
import { MOD_PHOTOS } from "data/links";

// styles
import {
    MastheadStyled,
    BreadCrumbLinkStyled,
    PageTitleStyled,
    PageMenuUiStyled
} from "./styles";

// components
import {
    FilterByBatchType,
    Search,
    SortBy,
    StatusMessage
} from "frontend/components/fort/mod/photos/components/PhotoModQueuePageContent/QueueProviders/BatchesProvider/index.js";

import { QUEUES } from "../../constants";

const PhotoModQueueMasthead = () => {
    const currentQueue = useSelector(state => state.currentQueue.name);
    let pageTitle = "";

    if (currentQueue === QUEUES.VIS_MOD) {
        pageTitle = "Visual Moderation";
    } else if (currentQueue === QUEUES.SUSPENDED) {
        pageTitle = "Suspended Batches";
    } else if (currentQueue === QUEUES.METADATA_MOD) {
        pageTitle = "Metadata Moderation";
    } else if (currentQueue === QUEUES.COMPLETED) {
        pageTitle = "Completed Batches";
    }

    return (
        <MastheadStyled>
            <BreadCrumbLinkStyled to={MOD_PHOTOS}>
                Moderation Home
            </BreadCrumbLinkStyled>
            <PageTitleStyled>{pageTitle}</PageTitleStyled>
            <PageMenuUiStyled>
                <FilterByBatchType />
                <Search />
                <SortBy />
                <StatusMessage />
            </PageMenuUiStyled>
        </MastheadStyled>
    );
};

export default PhotoModQueueMasthead;
