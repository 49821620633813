/*---------------------------
| Reducers
---------------------------*/
export const REDUCERS = {
    // Batches/Uploads
    BATCHES: "batches",
    SELECTED_BATCH: "selectedBatch",
    BATCH_UPLOAD_COLLECTIONS: "batchUploadCollections",
    UPLOADS: "uploads",
    UPLOAD_APPROVAL: "uploadApproval",
    UPLOAD_FOOTER_ACTIONS: "uploadFooterActions",

    // Misc
    CURRENT_QUEUE: "currentQueue",
    LARGE_IMAGE_VIEW: "largeImageView",
    ANIMATIONS: "animations",
    MESSAGES: "messages",
    WEBSOCKET_SYNCH_QUEUE: "websocketSynchQueue"
};
