import styled from "styled-components";

// styles
import colors from "core/colors";
import { convertPxToRem } from "frontend/components/helpers";
import { medium as mediumFontWeight } from "core/fontStyles";

// styled-components
export const ButtonLooksLikeLinkStyled = styled.button`
    display: inline-block;
    background: none;
    border: none;
    outline: none;
    padding: 0px;
    cursor: pointer;

    font-size: ${convertPxToRem(12)};
    color: ${colors.accent.lightMode.persian};
    border-bottom: solid ${convertPxToRem(1)} ${colors.accent.lightMode.persian};
    ${mediumFontWeight};
`;
