// https://blog.logrocket.com/developing-responsive-layouts-with-react-hooks/
import { useState } from "react";

import useIsomorphicLayoutEffect from "frontend/hooks/useIsomorphicLayoutEffect";
import breakpoints from "core/breakpoints";

export const useViewport = () => {
    // Note: SSR will return desktop size components
    // but on first client-side render, will be updated with real viewport size
    const serverSize = 1200;

    const [browserWidth, setWidth] = useState(serverSize);
    const [browserHeight, setHeight] = useState(serverSize);

    useIsomorphicLayoutEffect(() => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);

        const handleWindowResize = () => {
            setWidth(window.innerWidth);
            setHeight(window.innerHeight);
        };

        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    const isMobile = browserWidth < breakpoints.tablet.px;
    const isTablet =
        browserWidth >= breakpoints.tablet.px &&
        browserWidth < breakpoints.desktop.px;
    const isDesktop = browserWidth >= breakpoints.desktop.px;

    // always default to mobile
    const device = isDesktop ? "desktop" : isTablet ? "tablet" : "mobile";

    return {
        browserWidth,
        browserHeight,
        isMobile,
        isTablet,
        isDesktop,
        device
    };
};

// TODO: finish this tutorial when we are using 1 App.js for the whole site
// NOTE: we should be using matchMedia as opposed to window.innerWidth an innerHeight
// This brings parity with CSS media queries, and ensures scrollbars are properly taken into account
// https://developer.mozilla.org/en-US/docs/Web/API/Window/matchMedia
// Would also be good to expose isSmall, isTablet, etc, as opposed to having host calculate each time
