import { useSelector } from "react-redux";

// constants
import { QUEUE_VIEWS } from "frontend/components/fort/mod/photos/constants/index.js";

const getAllBatchUploadIds = batchUploadCollections => {
    let allUploadIds = [];

    batchUploadCollections.forEach(bc => {
        allUploadIds = [...allUploadIds, ...bc.collectionUploadIds];
    });

    return allUploadIds;
};

const getAllVisibleUploadIds = batchUploadCollections => {
    let visibleUploadIds = [];
    batchUploadCollections.forEach(buc => {
        const { view, activeIds, savedForLaterIds } = buc;

        const idsToAdd =
            view === QUEUE_VIEWS.ACTIVE ? activeIds : savedForLaterIds;

        visibleUploadIds = [...visibleUploadIds, ...idsToAdd];
    });

    return visibleUploadIds;
};

const useBatchUploads = () => {
    const { entities: uploadEntities } = useSelector(state => state.uploads);

    const batchUploadCollections = useSelector(
        state => state.batchUploadCollections
    );

    const visibleUploadIds = getAllVisibleUploadIds(batchUploadCollections);
    const visibleUploadEntities = visibleUploadIds.map(
        uid => uploadEntities[uid]
    );

    /*---------------------------
    | Helpers
    ---------------------------*/
    const getUploadEntity = uploadId => {
        return uploadEntities[uploadId];
    };

    const getUploadImages = uploadEntity => {
        const { thumbnail, uploadedImageUrl } = uploadEntity;

        return {
            small: {
                url: thumbnail
            },
            large: {
                url: uploadedImageUrl
            }
        };
    };

    return {
        batchUploadCollections,
        allBatchUploadIds: getAllBatchUploadIds(batchUploadCollections),
        visibleUploadIds,
        visibleUploadEntities,
        getUploadEntity,
        getUploadImages
    };
};

export default useBatchUploads;
