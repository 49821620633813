import React from "react";

import { footerMenu } from "data/links";

import Link from "core/Link";
import { TextLink } from "./styles";

export const TextLinksContent = () => (
    <>
        {footerMenu.map(link => {
            return (
                <TextLink key={link.name}>
                    <Link
                        href={link.href}
                        target={link.external ? "_blank" : "_self"}
                        size="md"
                        styleType="secondary"
                        darkMode
                        disableVisitedStyle
                    >
                        {link.name}
                    </Link>
                </TextLink>
            );
        })}
    </>
);
