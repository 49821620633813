/*
 * Redux reducers live here. Read Redux docs for more information:
 * http://redux.js.org/docs/basics/Reducers.html
 */

if (!global._babelPolyfill) {
    require("@babel/polyfill");
}

import { combineReducers } from "redux";

import * as actionTypes from "../actions/actionTypes";

import { creator } from "./creator";
import { collections } from "./collections";
import { icons } from "./icons";
import paginate from "./paginate";
import { plugin } from "./plugin";
import { search } from "./search";
import { user } from "./user";
import apiResponse from "./apiResponse";
import modal from "./modal";
import download from "./download";
import stripe from "./stripe";
import editor from "./editor";
import experiments from "./experiments";
import auth from "./auth";
import viewport from "./viewport";
import marketing from "./marketing";
import banners from "./banners";
import kits from "./kits";
import photos from "./photos";
import { pluginsRecentlyUsed } from "./pluginsRecentlyUsed";

// Updates the pagination data for different actions.
const pagination = combineReducers({
    featuredCreators: paginate({
        mapActionToKey: action => action.id,
        types: [
            actionTypes.FETCH_FEATURED_CREATORS,
            actionTypes.FETCH_FEATURED_CREATORS_SUCCESS,
            actionTypes.FETCH_FEATURED_CREATORS_FAILURE
        ]
    }),
    fetchBestCollectionIcons: paginate({
        mapActionToKey: action => action.id,
        types: [
            actionTypes.FETCH_BEST_COLLECTIONS,
            actionTypes.FETCH_BEST_COLLECTIONS_SUCCESS,
            actionTypes.FETCH_BEST_COLLECTIONS_FAILURE
        ]
    }),
    fetchViewMoreIcons: paginate({
        mapActionToKey: action => action.id,
        types: [
            actionTypes.FETCH_VIEW_MORE_ICONS,
            actionTypes.FETCH_VIEW_MORE_ICONS_SUCCESS,
            actionTypes.FETCH_VIEW_MORE_ICONS_FAILURE
        ]
    }),
    searchIcons: paginate({
        mapActionToKey: action => action.id,
        types: [
            actionTypes.SEARCH_ICONS,
            actionTypes.SEARCH_ICONS_SUCCESS,
            actionTypes.SEARCH_ICONS_FAILURE
        ]
    }),
    searchTerm: paginate({
        mapActionToKey: action => action.id,
        types: [
            actionTypes.SEARCH_ICONS,
            actionTypes.SEARCH_ICONS_SUCCESS,
            actionTypes.SEARCH_ICONS_FAILURE
        ]
    }),
    fetchRecentIcons: paginate({
        mapActionToKey: action => action.id,
        types: [
            actionTypes.SEARCH_ICONS,
            actionTypes.SEARCH_ICONS_SUCCESS,
            actionTypes.SEARCH_ICONS_FAILURE
        ]
    }),
    searchCreatorIcons: paginate({
        mapActionToKey: action => action.id,
        types: [
            actionTypes.SEARCH_ICONS,
            actionTypes.SEARCH_ICONS_SUCCESS,
            actionTypes.SEARCH_ICONS_FAILURE
        ]
    }),
    searchCollectionIcons: paginate({
        mapActionToKey: action => action.id,
        types: [
            actionTypes.SEARCH_ICONS,
            actionTypes.SEARCH_ICONS_SUCCESS,
            actionTypes.SEARCH_ICONS_FAILURE
        ]
    }),
    searchCreatorUploads: paginate({
        mapActionToKey: action => action.id,
        types: [
            actionTypes.SEARCH_ICONS,
            actionTypes.SEARCH_ICONS_SUCCESS,
            actionTypes.SEARCH_ICONS_FAILURE
        ]
    }),
    fetchCollection: paginate({
        mapActionToKey: action => action.id,
        types: [
            actionTypes.SEARCH_ICONS,
            actionTypes.SEARCH_ICONS_SUCCESS,
            actionTypes.SEARCH_ICONS_FAILURE
        ]
    })
});

const rootReducer = combineReducers({
    auth,
    creator,
    collections,
    icons,
    pagination,
    plugin,
    search,
    user,
    apiResponse,
    modal,
    download,
    stripe,
    editor,
    experiments,
    viewport,
    marketing,
    banners,
    kits,
    photos,
    pluginsRecentlyUsed
});

export default rootReducer;
