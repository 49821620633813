// https://github.com/Swizec/useDimensions/
import { useState, useCallback, useLayoutEffect } from "react";

function getDimensionObject(node) {
    const rect = node.getBoundingClientRect();

    return {
        width: rect.width,
        height: rect.height,
        top: "x" in rect ? rect.x : rect.top,
        left: "y" in rect ? rect.y : rect.left,
        x: "x" in rect ? rect.x : rect.left,
        y: "y" in rect ? rect.y : rect.top,
        right: rect.right,
        bottom: rect.bottom
    };
}

// The useDimensions hook is used to get the bounding box for a node
// whenever the window resizes (or optionally when the window is scrolled)
// Note that this won't update automatically if the element changes sizes through
// other means.
//
// The hook can take an existing ref, or will create a ref itself and pass back
// a setter as the first element of the returned array, which can be passed to
// a React component as a ref to set the node this hook will measure.
//
// Potential future optimization: debounce the event listeners
export function useDimensions({
    ref,
    liveMeasure = true,
    measureOnScroll = false
}) {
    const [dimensions, setDimensions] = useState(null);
    const [node, setNode] = useState(ref && ref.current);

    if (ref && ref.current != node) {
        setNode(ref.current);
    }

    const myRef = useCallback(n => {
        setNode(n);
    }, []);

    useLayoutEffect(() => {
        if (node) {
            const measure = () =>
                window.requestAnimationFrame(() => {
                    setDimensions(getDimensionObject(node));
                });

            const time = measure();

            if (liveMeasure) {
                window.addEventListener("resize", measure);
                if (measureOnScroll) {
                    window.addEventListener("scroll", measure);
                }

                return () => {
                    // Clean up event listeners
                    window.cancelAnimationFrame(time);
                    window.removeEventListener("resize", measure);
                    if (measureOnScroll) {
                        window.removeEventListener("scroll", measure);
                    }
                };
            }
        }
    }, [node, liveMeasure, measureOnScroll]);

    return [myRef, dimensions, node];
}
