import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";

// hooks
import { useBatchUploads } from "../../../hooks";
import {
    useClearReleaseFlag,
    useClearExplicitFlag,
    useClearHasWarningText,
    useClearEditorialUseOnlyFlag
} from "../../../hooks/mutations/flags";

// components
import FlagList from "./FlagList";

// styles
import { convertPxToRem } from "frontend/components/helpers";
import colors from "core/colors";

export const FlagsStyled = styled.div`
    padding: ${convertPxToRem(10)};
`;

// component
export const Flags = ({ uploadId }) => {
    const { getUploadEntity } = useBatchUploads();
    const upload = getUploadEntity(uploadId);
    const selectedBatchId = useSelector(state => state.selectedBatch.id);
    const selectedbatch = useSelector(
        state => state.batches.entities[selectedBatchId]
    );

    const { clearReleaseFlag } = useClearReleaseFlag();
    const { clearExplicitFlag } = useClearExplicitFlag();
    const { clearHasWarningText } = useClearHasWarningText();
    const { clearEditorialUseOnlyFlag } = useClearEditorialUseOnlyFlag();

    const generateFlags = useCallback(
        upload => {
            const flags = [];
            const {
                hasRecognizablePeopleOrProperty,
                editorialUseOnly,
                autoWarningText,
                autoNudity,
                autoViolence
            } = upload;

            if (hasRecognizablePeopleOrProperty) {
                flags.push({
                    id: 1,
                    text: "REL",
                    bgColor: colors.alternative.lightMode.tang
                });
            }

            if (editorialUseOnly) {
                flags.push({
                    id: 2,
                    text: "EUO",
                    bgColor: colors.alternative.lightMode.tang
                });
            }

            if (autoWarningText) {
                flags.push({
                    id: 3,
                    text: "TXT",
                    bgColor: colors.alternative.lightMode.tang
                });
            }

            if (autoNudity || autoViolence) {
                flags.push({
                    id: 4,
                    text: "EXP",
                    bgColor: colors.functional.darkMode.tandoori
                });
            }

            return flags;
        },
        [upload]
    );

    const onSingleUploadFlagClear = ({ flagText }) => {
        switch (flagText) {
            case "EXP":
                clearExplicitFlag([upload.id]);
                break;
            case "REL":
                clearReleaseFlag([upload.id]);
                break;
            case "EUO":
                clearEditorialUseOnlyFlag([upload.id]);
                break;
            case "TXT":
                clearHasWarningText([upload.id]);
                break;
        }
    };

    return (
        <FlagList
            flags={generateFlags(upload)}
            upload={upload}
            onFlagClear={onSingleUploadFlagClear}
            isDisabled={!selectedbatch?.isEditable?.uploadFlags}
        />
    );
};

export default Flags;

// prop-types
Flags.propTypes = {
    uploadId: PropTypes.string.isRequired
};
