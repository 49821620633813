import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import SubmissionsPending from "./SubmissionsPending";
import SubmissionsDenied from "./SubmissionsDenied";

const Content = () => {
    const { pageContent } = useParams();

    let content = null;

    if (pageContent === "photos-pending") {
        content = <SubmissionsPending />;
    }

    if (pageContent === "photos-denied") {
        content = <SubmissionsDenied />;
    }

    return content;
};

export default Content;
