import React from "react";
import styled from "styled-components";

// context
import { useSiteHeader } from "core/SiteHeaderV2/context/useSiteHeader.js";

// components
import PricingLink from "core/SiteHeaderV2/components/PricingLink.js";
import LoginButton from "core/SiteHeaderV2/components/LoginButton.js";

//styles
import { UnstyledListItemStyled } from "core/SiteHeaderV2/styles.js";
const UnauthMenuStyled = styled.nav`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

// component
const UnauthMenu = () => {
    const { isDarkMode } = useSiteHeader();
    return (
        <UnauthMenuStyled>
            <PricingLink isDarkMode={isDarkMode} />
            <UnstyledListItemStyled>
                <LoginButton />
            </UnstyledListItemStyled>
        </UnauthMenuStyled>
    );
};

export default UnauthMenu;
