import { useState } from "react";

import { nullFunc } from "./utils.js";

export const useGridItemTemplate = ({
    onMouseEnter = nullFunc,
    onMouseLeave = nullFunc,
    onFocus = nullFunc,
    onBlur = nullFunc
}) => {
    // state
    const [isHovered, isHoveredUpdate] = useState(false);
    const [isFocused, isFocusedUpdate] = useState(false);

    // methods
    const onMouseEnterHandler = () => {
        isHoveredUpdate(true);
        onMouseEnter(); // consumer listener
    };
    const onMouseLeaveHandler = () => {
        isHoveredUpdate(false);
        onMouseLeave(); // consumer listener
    };
    const onFocusHandler = () => {
        isFocusedUpdate(true);
        onFocus(); // consumer listener
    };
    const onBlurHandler = () => {
        isFocusedUpdate(false);
        onBlur(); // consumer listener
    };

    return {
        isHovered,
        isFocused,
        onMouseEnterHandler,
        onMouseLeaveHandler,
        onFocusHandler,
        onBlurHandler
    };
};
