import styled from "styled-components";

const Flexbox = styled.div`
    display: ${props => (props.inlineFlex ? "inline-flex" : "flex")};
    ${props =>
        props.flexDirection && `flex-direction: ${props.flexDirection};`} 
    ${props => props.flexWrap && `flex-wrap: ${props.flexWrap};`} 
    ${props => props.flexFlow && `flex-flow: ${props.flexFlow};`} 
    ${props =>
        props.justifyContent && `justify-content: ${props.justifyContent};`} 
    ${props => props.alignItems && `align-items: ${props.alignItems};`} 
    ${props => props.alignContent && `align-content: ${props.alignContent};`} 
    row-gap: ${props => props.rowGap};
    column-gap: ${props => props.columnGap};
    width: ${props => props.width};
`;

export default Flexbox;
