import breakpoints from "core/breakpoints";

// for HeroImage.js:
export const breakpointBasedImage = ({
    browserWidth,
    heroImageDesktop,
    heroImageTablet,
    heroImageMobile
}) => {
    const isMobile = browserWidth <= breakpoints.beforeTablet.px;
    const isTablet =
        browserWidth >= breakpoints.tablet.px &&
        browserWidth <= breakpoints.beforeDesktop.px;
    const isDesktop = browserWidth >= breakpoints.desktop.px;

    let heroImage;

    if (isDesktop) {
        heroImage = heroImageDesktop;
    } else if (isTablet) {
        heroImage = heroImageTablet;
    } else if (isMobile) {
        heroImage = heroImageMobile;
    } else {
        heroImage = "";
    }

    return heroImage;
};
