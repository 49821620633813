import gql from "graphql-tag";

export const GET_MONTHLY_ROYALTY_INSIGHTS = gql`
    query photoRoyaltyInsights($month: String!, $username: String!) {
        photoRoyaltyInsights(month: $month, username: $username) {
            ok
            errors
            topDownloads {
                items {
                    title
                    url
                    assets {
                        squareSmall
                    }
                    count
                }
                totalCount
            }
            topPurchases {
                items {
                    title
                    url
                    assets {
                        squareSmall
                    }
                    count
                }
                totalCount
            }
        }
    }
`;
