import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";

// hooks
import { useInlineTextEdit } from "./index.js";
import { useOnClickOutside } from "js/hooks/useOnClickOutside.js";

// styles
import { InlineTextEditWrapperStyled } from "./styles.js";

export const InlineTextEditWrapper = ({ children }) => {
    const { text, dispatch, actions } = useInlineTextEdit();

    // Create a ref to keep track of the latest text value
    const textRef = useRef(text);

    // Update ref whenever text changes
    useEffect(() => {
        textRef.current = text;
    }, [text]);

    const onClickOutside = () => {
        // Use textRef.current to get the latest value of text
        dispatch(actions.onAbort(textRef.current));
    };

    const { targetRef } = useOnClickOutside({ onClickOutside });

    return (
        <InlineTextEditWrapperStyled ref={targetRef}>
            {children}
        </InlineTextEditWrapperStyled>
    );
};

// prop-types
InlineTextEditWrapper.propTypes = {
    children: PropTypes.any
};
