import { createSlice } from "@reduxjs/toolkit";

import { REDUCERS } from "../constants";

/**
 * Sample Animation Object
 * {
 *      id: "some-unique-animation-string" // we leverage this for each Animation Recipe, listening for it
 *      payload is an object that could represent anything needed for the animation
 *      payload: {
 *          batchId: 127618,
 *          ...rest
 *      }
 * }
 */
export const animationsSlice = createSlice({
    name: REDUCERS.ANIMATIONS,
    initialState: {
        animations: []
    },
    reducers: {
        addAnimation: (state, action) => {
            state.animations = [...state.animations, action.payload];
        },
        removeAnimation: (state, action) => {
            state.animations = state.animations.filter(
                a => a.id !== action.payload
            );
        }
    }
});

export default animationsSlice.reducer;
