import getSearchBar from "./getSearchBar";
import getHeader from "./getHeader";
import getAnimation from "./getAnimation";

const getDefaultStateByPage = (currentPage, hasMounted = false) => {
    const searchBar = getSearchBar(currentPage);
    const animation = getAnimation(currentPage);
    const header = getHeader(currentPage);

    return {
        searchBar,
        header,
        animation
    };
};

export default getDefaultStateByPage;
