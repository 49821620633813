import React from "react";

import PageMeta from "core/PageMeta";

import lingoLogoPng from "./images/lingo-logo.png";

const LingoMarketingPageMeta = () => (
    <PageMeta
        title="Lingo, a visual asset management tool from Noun Project"
        twitterTitle="Check out Lingo, a visual asset management tool from Noun Project"
        useSocialMediaTags
        previewImg={lingoLogoPng}
        previewImgAlt="Lingo from Noun Project"
        description="Stop wasting time looking for files buried in folders. Visually organize all your assets in one place with Lingo from Noun Project."
    />
);

export default LingoMarketingPageMeta;
