import React from "react";
import PropTypes from "prop-types";

// components
import FormSelectDialog, { defaultTheme } from "core/FormSelectDialog";

// styles
import { SortStyled } from "./styles.js";

const Sort = ({ options, selectedOption, onSelect }) => {
    const createTheme = () => {
        let dt = defaultTheme({ dialogAlignment: "right" });
        return {
            ...dt,
            dialog: {
                ...dt.dialog,
                zIndex: 100
            }
        };
    };

    return (
        <SortStyled>
            <FormSelectDialog
                id={`form-select-sort`}
                theme={createTheme}
                controlLabelOverride="Sort"
                options={options}
                selectedOption={selectedOption}
                setSelectedOption={onSelect}
            />
        </SortStyled>
    );
};

export default Sort;

// prop-types
Sort.propTypes = {
    options: PropTypes.array,
    selectedOption: PropTypes.object,
    onSelect: PropTypes.func
};
