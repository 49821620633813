import { getDropDownProps } from "./dropDown/getDropDownProps";

export const getDefaultProps = device => {
    const dropDown = getDropDownProps(device);

    return {
        dropDown,
        inputValue: ""
    };
};
