import styled from "styled-components";
import Flexbox from "core/Flexbox";
import Modal from "core/Modal";
import { convertPxToRem } from "frontend/components/helpers";
import { ModalContent, ModalFlexbox, Paper } from "core/Modal/styles";
import Link from "core/Link";
import colors from "core/colors";
import BodyText from "core/BodyText";
import breakpoints from "core/breakpoints";
import { Banner } from "core/Banners/styles";
import { STYLE_TYPE_HOVERS } from "core/Link/styles";

// BANNER
export const ContentWrapper = styled(Flexbox).attrs({
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
})`
    @media (max-width: ${breakpoints.beforeTablet.rem}) {
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
    }
`;

export const BannerText = styled.div`
    font-size: ${convertPxToRem({ px: 15 })};

    @media (max-width: ${breakpoints.beforeTablet.rem}) {
        text-align: left;
    }
`;

// NOTE:
// We can't use `position: sticky` because the banner needs to be printed
// _before_ page content (this fixes poor LCP scores due to tall pages
// delaying the cookie banner from painting).
export const Container = styled.div`
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 9999;

    ${Banner} {
        height: ${convertPxToRem({ px: 70 })};
    }

    @media (max-width: ${breakpoints.beforeTablet.rem}) {
        ${Banner} {
            height: ${convertPxToRem({ px: 128 })};
            padding-left: ${convertPxToRem({ px: 14 })};
        }
    }
`;

export const LinkContainer = styled(Flexbox).attrs({
    flexDirection: "row",
    alignItems: "center"
})`
    @media (max-width: ${breakpoints.beforeTablet.rem}) {
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
    }
`;

export const ManageLink = styled(Link)`
    margin-right: ${convertPxToRem({ px: 20 })};
    margin-left: ${convertPxToRem({ px: 40 })};
`;

// MODAL
export const ModalContainer = styled(Modal)`
    //TABLET & DESKTOP
    @media (min-width: ${breakpoints.tablet.rem}) {
        ${ModalFlexbox} {
            width: auto;
            height: auto;
            max-width: 100%;
        }

        ${Paper} {
            width: ${convertPxToRem({ px: 814 })};
            height: ${convertPxToRem({ px: 709 })};

            @media (min-width: ${breakpoints.tablet.rem}) {
                width: ${convertPxToRem({ px: 688 })};
                height: ${convertPxToRem({ px: 728 })};
            }
        }

        ${ModalContent} {
            overflow: visible;
        }
    }

    // MOBILE
    ${Paper} {
        margin: 0;
    }
`;

export const IndividualBox = styled.div`
    border: 1px solid ${colors.primary.scheifer};
    border-radius: ${convertPxToRem({ px: 6 })};
    padding: ${convertPxToRem({ px: 14 })} ${convertPxToRem({ px: 20 })};
    margin-bottom: ${convertPxToRem({ px: 16 })};
`;

export const ModalDescription = styled(BodyText).attrs({
    size: "md",
    type: "div"
})`
    padding-bottom: ${convertPxToRem({ px: 24 })};
`;

export const CookieTitle = styled(Flexbox).attrs({
    flexDirection: "row",
    alignItems: "center"
})``;

export const CookieDesc = styled(BodyText).attrs({
    size: "sm",
    type: "div"
})`
    padding-top: ${convertPxToRem({ px: 14 })};
`;

export const Footer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media (max-width: ${breakpoints.beforeTablet.rem}) {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
`;

export const FooterText = styled(BodyText)`
    ${Link} {
        &:visited {
            border-bottom-color: ${colors.accent.lightMode.persian};
            color: ${colors.accent.lightMode.persian};
            &:hover {
                ${STYLE_TYPE_HOVERS.lightMode.primary};
            }
        }
    }

    @media (max-width: ${breakpoints.beforeTablet.rem}) {
        margin-top: ${convertPxToRem({ px: 16 })};
    }
`;
