import gql from "graphql-tag";

export const GET_ACTIVE_SOCIAL_LOGIN_DATA = gql`
    query getActiveSocialLoginData {
        activeSocialLogin {
            emailAddresses
            provider
            canConnect
            nextUrl
        }
    }
`;

export const CONNECT_SOCIAL_ACCOUNT = gql`
    mutation connectActiveSocialLogin {
        connectActiveSocialLogin {
            ok
            error
            nextUrl
        }
    }
`;

export const CLEAR_PENDING_SOCIAL_LOGIN = gql`
    mutation removeActiveSocialLogin {
        removeActiveSocialLogin {
            ok
            error
        }
    }
`;
