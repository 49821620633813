import React from "react";

import Heading from "core/Heading";
import LoadingSpinner from "core/LoadingSpinner";
import PendingGroups from "./PendingGroups";

import { GET_PENDING_SUBMISSIONS_BY_BATCH } from "./queries";

import useLoadMore from "frontend/hooks/useLoadMore";

const SubmissionsPending = () => {
    const { items, totalCount, totalItemsLoaded, LoadMore } = useLoadMore({
        query: GET_PENDING_SUBMISSIONS_BY_BATCH,
        queryVariables: {
            moderationStatuses: [
                "BATCH_SUBMIT_SCREENING",
                "BATCH_MOD_UNASSIGNED",
                "BATCH_MOD_ASSIGNED"
            ]
        },
        pathToListKey: "listUnmoderatedPhotoBatches.items",
        pathToTotalCountKey: "listUnmoderatedPhotoBatches.totalCount",
        limit: 20
    });

    return (
        <div data-testid="submissions-pending">
            {isNaN(totalItemsLoaded) ? (
                <LoadingSpinner />
            ) : totalCount === 0 ? (
                <Heading size="md">
                    No photos currently queued for moderation.
                </Heading>
            ) : (
                <>
                    <PendingGroups
                        data-testid="submissions-pending"
                        data={items || []}
                    />
                    <LoadMore buttonText="Load More" />
                </>
            )}
        </div>
    );
};

export default SubmissionsPending;
