import styled, { css } from "styled-components";

import { convertPxToRem } from "frontend/components/helpers";

export const getWidthPercentage = ({ col }) => {
    const colWidth = col / 0.12;

    return `${colWidth}%`;
};

export const getDividedSpacing = ({ spacing }) => {
    const spacingMeasurement = spacing / 2;
    return spacing === 0 ? "0" : convertPxToRem({ px: spacingMeasurement });
};

export const getBreakpointCss = ({ spacing, breakpoint, GridItem }) => {
    if (spacing === 0) {
        return css`
            @media (max-width: ${breakpoint}) {
                margin: 0;
                width: 100%;

                ${GridItem} {
                    padding: 0;
                }
            }
        `;
    } else if (spacing > 0) {
        return css`
            @media (max-width: ${breakpoint}) {
                margin: -${convertPxToRem({ px: spacing / 2 })};
                width: calc(100% + ${convertPxToRem({ px: spacing })});

                ${GridItem} {
                    padding: ${getDividedSpacing({
                        spacing: spacing
                    })};
                }
            }
        `;
    } else {
        return;
    }
};
