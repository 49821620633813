import React from "react";
import PropTypes from "prop-types";

import Grid from "core/Grid";
import Heading from "core/Heading";

import { Paragraph } from "./styles";

const Faq = ({
    title,
    values,
    size = "xl",
    headingAlignment = "center",
    headingColor,
    desktopSpacing = 64,
    tabletSpacing = 16,
    mobileSpacing = 48
}) => (
    <>
        {title && (
            <Heading
                type="h2"
                size={size}
                bottomSpacing="4xl"
                alignment={headingAlignment}
                headingColor={headingColor}
            >
                {title}
            </Heading>
        )}
        <Grid
            mobileSpacing={mobileSpacing}
            tabletSpacing={tabletSpacing}
            desktopSpacing={desktopSpacing}
        >
            {values.map(value => (
                <Grid item sm={12} md={4} lg={4} key={value.title}>
                    <Heading type="h6" size="xs" headingColor={headingColor}>
                        {value.title}
                    </Heading>
                    <Paragraph>{value.text}</Paragraph>
                </Grid>
            ))}
        </Grid>
    </>
);

Faq.propTypes = {
    title: PropTypes.string,
    values: PropTypes.array.isRequired,
    size: PropTypes.string,
    headingAlignment: PropTypes.string,
    headingColor: PropTypes.string
};

export default Faq;
