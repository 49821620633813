// the point at which we start animating the header independently of the header search input:
export const scrollToDesktopNoHero = 35;
export const scrollToDesktopWithHero = 100;
export const scrollToTabletWithHero = 75;
export const scrollToMobileWithHero = 50;
export const scrollToMobileWithHeroIcons = 40;
export const scrollToDesktopWithHeroLanding = 85;
export const scrollToTabletWithHeroLanding = 75;
export const scrollToMobileWithHeroLanding = 55;
export const scrollToMobileWithHeroIconsLanding = 45;

// the point at which we show the header search input and hide the hero search input
export const scrollToDesktopHeroSearchBar = 300;
export const scrollToTabletHeroSearchBar = 300;
export const scrollToMobileHeroSearchBar = 170;
export const scrollToMobileHeroSearchBarIcons = 100;
export const scrollToDesktopHeroLandingSearchBar = 415;
export const scrollToTabletHeroLandingSearchBar = 415;
export const scrollToMobileHeroLandingSearchBar = 315;
export const scrollToMobileHeroLandingIconsSearchBar = 250;

export const mobileHeaderHeight = 57;
export const desktopHeaderHeight = 156;
export const desktopHeaderHeightCollapsed = 106;
export const scrollingThreshold = 50;
export const animateElementsThreshold = 5;
export const animateSearchThreshold = {
    default: 282, // Home Page
    icons: 308,
    photos: 360
};

export const boxShadow = "0px 1px 2px rgba(0, 0, 0, 0.164336)";
