import styled, { css } from "styled-components";

// components
import Button from "core/Button";

// styles
import colors from "core/colors";
import { handleBoxShadowOutlineBorder } from "core/Button/helpers.js";
import { STYLE_MODES } from "frontend/components/core/Button/styles.js";

// styled-components
export const DividerStyled = styled.div`
    width: 1px;
    background-color: ${colors.primary.pigeon};
    height: 100%;
`;

/*---------------------------
| Shared Disabled State
---------------------------*/
const disabledGreyState = css`
    background: ${colors.primary.whisker};
    color: ${colors.secondary.dolphin};
    outline: none;
    pointer-events: auto;
    cursor: not-allowed;

    svg path {
        fill: ${colors.secondary.dolphin};
    }

    &:hover {
        background: ${colors.primary.whisker};
        color: ${colors.secondary.dolphin};
        border: none;
        outline: none;
        box-shadow: none;
    }
`;

/*---------------------------
| Figma Button Style Types
---------------------------*/
const clearStyle = css`
    ${STYLE_MODES.OUTLINE.LIGHTMODE};

    box-shadow: ${handleBoxShadowOutlineBorder({
        size: "md",
        color: colors.primary.scheifer
    })};
    ${({ isDisabled }) => isDisabled && disabledGreyState}
`;

const darkStyle = css`
    ${({ isDisabled }) =>
        isDisabled &&
        css`
            ${disabledGreyState};

            background-color: ${colors.secondary.onyx};
            color: ${colors.primary.snow};

            &:hover {
                background-color: ${colors.secondary.onyx};
                color: ${colors.primary.snow};
                border: none;
                outline: none;
                box-shadow: none;
            }
        `}
`;

const blueStyle = css`
    ${STYLE_MODES.CTA.LIGHTMODE};
    ${({ isDisabled }) => isDisabled && disabledGreyState}
`;

const greenStyle = css`
    ${STYLE_MODES.CTA.LIGHTMODE};
    color: ${colors.primary.snow};
    background: ${colors.alternative.lightMode.shamrock};

    svg path {
        fill: ${colors.primary.snow};
    }

    ${({ isDisabled }) => isDisabled && disabledGreyState}
`;

const styleVariantMap = {
    clear: clearStyle,
    dark: darkStyle,
    blue: blueStyle,
    green: greenStyle
};

/*---------------------------
| Shared Base Button Styles
---------------------------*/
export const ButtonStyled = styled(Button)`
    display: block;
    width: 100%;
    height: 28px;
    font-size: 13px;
    padding: 0px;

    svg {
        margin: 0px 0px 0px 4px;
    }

    ${({ variant }) => styleVariantMap[variant]}
`;
