import * as actionTypes from "./actionTypes";
import downloadjs from "../helpers/download";
import callApiPromise from "../helpers/call-api-promise";
import { UnwrappedEditorRenderer } from "../components/editor/editor-renderer";
import ReactDOMServer from "react-dom/server";
import React from "react";
import reduce from "lodash/reduce";
import isError from "lodash/isError";
import * as Sentry from "@sentry/browser";
import { NO_ATTRIBUTION, makeSvgKey } from "../reducers/icons";
import cleanPreviewIcon from "../helpers/clean_svg_string";

const EDITOR_USAGE_REPORT_FAILED = "EDITOR_USAGE_REPORT_FAILED";

export const editorReset = () => (dispatch, getState) => {
    dispatch({
        type: actionTypes.EDITOR_RESET
    });
    // Cache a reset svg
    dispatch(editorTransformed());
};

export const editorTransform = (transform, data) => (dispatch, getState) => {
    dispatch({
        type: actionTypes.EDITOR_TRANSFORM,
        transform,
        data
    });
    dispatch(editorTransformed());
};

export const editorTransformed = () => (dispatch, getState) => {
    const { editor } = getState();
    dispatch({
        type: actionTypes.EDITOR_TRANSFORMED,
        editor
    });

    const { icons, download } = getState();
    const { pendingDownload } = download;
    if (pendingDownload) {
        const { id: iconId } = pendingDownload;
        const icon = icons[iconId];
        if (icon) {
            const cachedSvg = icon[makeSvgKey(NO_ATTRIBUTION)];
            if (cachedSvg) {
                dispatch(editorRender(cachedSvg));
            } else {
                const { active_icon } = icons;
                if (
                    active_icon &&
                    active_icon.id == pendingDownload.id &&
                    active_icon.loadingRawSVG == NO_ATTRIBUTION
                ) {
                    console.log("Editor not ready, but svg loading");
                } else {
                    const msg = "Editor transformed but no svg?";
                    console.warn(msg);
                    Sentry.captureMessage(msg);
                }
            }
        }
    }
};

// This action takes an icon svg, applies all the transforms in the editor
// reducer to it, rendering the EditorRenderer component to a string.
// Rendering in this fashion avoids embedding user generated content (the icon)
// directly into the DOM
export const editorRender = svg => (dispatch, getState) => {
    const { editor } = getState();
    const renderedSvg = doEditorRender(editor, svg);
    dispatch(editorCacheSvg(renderedSvg));
};

const doEditorRender = (editorState, svg, forceWrap = false) => {
    // Having <?xml..?> and <!DOCTYPE> tags in svg is apparently invalid
    const xmlDeclarationRE = /<\?xml(.*?)\?>/gi;
    const docTypeRE = /<!(.*?)>/g;
    // Illustrator adds a switch element with an empty foreignObject
    // with requiredExtensions attribute which trips up PNG export
    const requiredExtensionsRE = /<foreignObject[^<>]*requiredExtensions[^<>]*>[\s\S]*?<\/foreignObject>/;

    // Related to the above, Safari, IE, Edge do not like <switch> tags with no children
    // that explicitly have a truthy requiredFeatures, requiredExtensions or systemLanguage
    // attribute. So just replace switch tags with g's
    svg = svg.replace(/<switch/gi, "<g").replace(/<\/switch>/gi, "</g>");

    [xmlDeclarationRE, docTypeRE, requiredExtensionsRE].forEach(re => {
        svg = svg.replace(re, "");
    });

    const { needsWrap } = editorState;
    let renderedSvg;

    if (!forceWrap && !needsWrap) {
        // For unedited/color transforms just mangle the svg string directly
        renderedSvg = cleanPreviewIcon(
            svg,
            editorState.transforms.color,
            "100px"
        );
    } else {
        renderedSvg = ReactDOMServer.renderToStaticMarkup(
            <UnwrappedEditorRenderer {...editorState.transforms} svg={svg} />
        );
    }

    return renderedSvg;
};

export const editorCacheSvg = svg => ({
    type: actionTypes.EDITOR_CACHE_SVG,
    svg
});
export const editorAddColor = color => ({
    type: actionTypes.EDITOR_ADD_COLOR,
    color
});
export const editorSetPaletteMode = mode => ({
    type: actionTypes.EDITOR_SET_PALETTE_MODE,
    mode
});

// For browsers where drawing an SVG to a canvas taints the canvas
// and makes it impossible to export it as SVG.
// Dynamically imports canvg-browser library which implements an SVG
// renderer using the Canvas API
function canvgFallback(svgString, size) {
    return import(/* webpackChunkName: "canvg-browser" */ "canvg-browser").then(
        ({ default: canvg }) => {
            const canvas = document.createElement("canvas");
            canvas.width = size;
            canvas.height = size;

            canvg(canvas, svgString);

            if (canvas.toBlob) {
                return new Promise((resolve, reject) => {
                    canvas.toBlob(blob => resolve(blob), "image/png");
                });
            } else {
                return canvas.toDataURL("image/png");
            }
        }
    );
}

function svgToPng(svgString, size = 100) {
    return new Promise((resolve, reject) => {
        var DOMURL = self.URL || self.webkitURL || self;
        var img = new Image();
        var svg = new Blob([svgString], {
            type: "image/svg+xml;charset=utf-8"
        });

        var url = DOMURL.createObjectURL(svg);
        img.onload = function() {
            const canvas = document.createElement("canvas");
            canvas.width = size;
            canvas.height = size;
            var ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);

            try {
                canvas.toBlob(blob => {
                    resolve(blob);
                }, "image/png");
            } catch (e) {
                console.log("svgToPng failed", e, "trying canvg fallback");
                canvgFallback(svgString, size).then(resolve, reject);
            }
        };
        img.onerror = e => {
            console.log("svgToPng failed", e, "trying canvg fallback");
            canvgFallback(svgString, size).then(resolve, reject);
        };
        img.src = url;
    });
}

export const editorExport = (format, fileName) => (dispatch, getState) => {
    const { editor } = getState();

    let { svg: sourceSvg, changed } = editor;

    const { exportSize, color } = editor.transforms;

    // Check that the editor is actually ready before
    // reporting usage
    const checkSource = new Promise((resolve, reject) => {
        if (!sourceSvg) {
            console.warn("editor no sourceSvg");
            return reject("Renderer not ready.");
        } else {
            // For the browser to render pngs correctly, the svg needs to be wrapped
            // so it can be resized to fit the export size
            if (format == "png" && !editor.needsWrap) {
                sourceSvg = doEditorRender(editor, sourceSvg, true);
            }
            return resolve();
        }
    });

    return checkSource
        .then(() => dispatch(editorReport(format)))
        .then(() => {
            fileName = fileName || `icon.${format}`;

            let downloaded;
            if (format == "svg") {
                downloaded = downloadjs(sourceSvg, fileName);
            } else {
                downloaded = svgToPng(sourceSvg, exportSize).then(png => {
                    const downloaded = downloadjs(png, fileName);

                    if (downloaded.then) {
                        return downloaded.then(() => {
                            // png is usually returned as a Blob URL, but IE11 does
                            // not support canvas.toBlob and so returns a dataURL
                            if (
                                png &&
                                png.indexOf &&
                                png.indexOf("blob") == 0
                            ) {
                                var DOMURL = self.URL || self.webkitURL || self;
                                DOMURL.revokeObjectURL(png);
                            }
                        });
                    }
                });
            }

            dispatch({
                type: actionTypes.EDITOR_EXPORT,
                format,
                exportSize
            });

            downloaded = downloaded.then ? downloaded : Promise.resolve();
            return downloaded.then(() => {
                dispatch({
                    type: actionTypes.EDITOR_EXPORT_SUCCESS,
                    format,
                    exportSize
                });
            });
        })
        .catch(error => {
            if (error == EDITOR_USAGE_REPORT_FAILED) {
                // This has already been reported to Sentry
                // Create an error to show generic error string to user
                throw new Error(error);
            }

            let msg;
            if (isError(error)) {
                msg = error;
            } else {
                try {
                    msg = JSON.stringify(error);
                } catch (e) {
                    msg = "Unknown error";
                }
            }

            Sentry.captureException(msg, {
                tags: {
                    impact: "Failed editor export"
                }
            });
            dispatch({
                type: actionTypes.EDITOR_EXPORT_FAILURE,
                format,
                exportSize,
                error
            });
            throw error;
        });
};

const editorReport = format => (dispatch, getState) => {
    const { editor, download } = getState();
    const { transforms, changed } = editor;
    const { pendingDownload } = download;
    let iconId;
    if (pendingDownload) {
        iconId = pendingDownload.id;
    } else {
        throw new Error("Editor report failed, no pending download");
    }

    const changedTransforms = getChangedTransforms(changed, transforms);
    const endpoint = `/icon/${iconId}/editor/report/`;
    return dispatch(
        callApiPromise({
            endpoint,
            method: "POST",
            data: JSON.stringify({
                type: format,
                changed: changedTransforms
            })
        })
    ).catch(error => {
        Sentry.captureException(`Editor usage report failed ${error.status}`);
        return Promise.reject(EDITOR_USAGE_REPORT_FAILED);
    });
};

export const getChangedTransforms = (changed, transforms) =>
    reduce(
        changed,
        (acc, transform) => {
            acc[transform] = transforms[transform];
            return acc;
        },
        {}
    );
