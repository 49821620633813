import React from "react";

// Context
import { useSlideshow } from "./index.js";

// styles
import { PrevButtonStyled } from "./styles.js";

// components
import SrOnly from "core/SrOnly";
import CaretLeft from "core/ReusableIcons/CaretLeft.js";

const PrevButton = () => {
    // State/Props/Methods/Hooks
    const {
        slidePrev,
        showControls,
        isCarousel,
        current,
        components,
        onSlidePrevClick
    } = useSlideshow();

    const currSlide = components.find(c => current === c.id);

    const isDisabled = !isCarousel && currSlide.slideNumber === 1;

    const onClick = () => {
        onSlidePrevClick();
        slidePrev();
    };

    if (!showControls) {
        return null;
    }

    return (
        <PrevButtonStyled type="button" onClick={onClick} disabled={isDisabled}>
            <SrOnly>Prev</SrOnly>
            <CaretLeft size={{ width: 40, height: 40 }} />
        </PrevButtonStyled>
    );
};

export default PrevButton;
