import React, { Children } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import {
    linkTargets,
    linkSizes,
    linkStyleTypes,
    displayTypes,
    requiredIf
} from "core/props";

import { LinkElement } from "./styles";

const UnstyledLink = ({
    children,
    className,
    display,
    size = "md",
    styleType = "primary",
    isButton = false,
    href,
    target = "_self",
    onClick,
    darkMode = false,
    disableVisitedStyle = false,
    isDisabled = false,
    ...otherProps
}) => {
    const handleClick = e => {
        onClick && onClick(e);
    };

    const conditionalProps = isButton
        ? {
              onClick: handleClick
          }
        : { href, target, onClick: handleClick };

    // Handle icon inside link - we want the icon to have *no* underline,
    // so we wrap non-svg children in spans, and put border on that
    const hasIcon = Children.toArray(children).some(
        c => c.type && c.type.target === "svg"
    );

    // For icon link, inline-flex helps with vertical alignment, but for text link,
    // we want inline-block so that underline breaks across lines
    const defaultDisplay = hasIcon ? "inline-flex" : "inline-block";

    const spannedChildren = hasIcon ? (
        Children.map(children, child => {
            if (!child.type || child.type.target !== "svg") {
                return <span>{child}</span>;
            }
            return child;
        })
    ) : (
        <span>{children}</span>
    );

    return (
        <LinkElement
            className={className}
            size={size}
            styleType={styleType}
            as={isButton ? "button" : "a"}
            isButton={isButton}
            darkMode={darkMode}
            $disableVisitedStyle={disableVisitedStyle}
            display={display || defaultDisplay}
            aria-disabled={isDisabled}
            {...otherProps}
            {...conditionalProps}
        >
            {spannedChildren}
        </LinkElement>
    );
};

const Link = styled(UnstyledLink)``;

Link.propTypes = {
    size: PropTypes.oneOf(linkSizes),
    styleType: PropTypes.oneOf(linkStyleTypes),
    isButton: PropTypes.bool,
    onClick: requiredIf({
        type: PropTypes.func,
        condition: props => props.isButton === true
    }),
    href: requiredIf({
        type: PropTypes.string,
        condition: props => props.isButton === false
    }),
    target: PropTypes.oneOf(linkTargets),
    darkMode: PropTypes.bool,
    disableVisitedStyle: PropTypes.bool,
    display: PropTypes.oneOf(displayTypes),
    isDisabled: PropTypes.bool
};

export default Link;
