import React from "react";
import styled from "styled-components";

// context
import { PanelOverlay as PanelOverlayContext } from "core/SiteHeaderV2/Mobile/components/PanelOverlay/index.js";

// components
import AuthProfile from "core/SiteHeaderV2/components/AuthProfile.js";
import LogoutButton from "core/SiteHeaderV2/components/LogoutButton.js";

//styles
const PanelOverlayStyled = styled.div`
    padding: 64px 24px 24px;
`;

// component
const PanelOverlay = () => {
    return (
        <PanelOverlayContext>
            <PanelOverlayStyled>
                <AuthProfile />
                <LogoutButton />
            </PanelOverlayStyled>
        </PanelOverlayContext>
    );
};

export default PanelOverlay;
