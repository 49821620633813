import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// styles
export const ButtonPlainStyled = styled.button`
    all: unset;
    display: inline-block;
    cursor: pointer;
`;

// component
export const ButtonPlain = props => {
    return <ButtonPlainStyled {...props}>{props.children}</ButtonPlainStyled>;
};

export default ButtonPlain;

// prop-types
ButtonPlain.propTypes = {
    children: PropTypes.any
};
