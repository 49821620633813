import gql from "graphql-tag";

export const BROWSE_COLLECTIONS_BY_CREATOR = gql`
    query browseCollectionsByCreator(
        $username: String!
        $limit: Int
        $offset: Int
        $iconsPerCollectionLimit: Int
    ) {
        browseCollectionsByCreator(
            username: $username
            limit: $limit
            offset: $offset
        ) {
            items {
                id
                name
                slug
                url
                icons(limit: $iconsPerCollectionLimit) {
                    items {
                        id
                        title
                        thumbnails {
                            thumbnail84
                        }
                    }
                    totalCount
                }
            }
            totalCount
            creator {
                name
                avatarUrl
            }
        }
    }
`;
