import React from "react";
import PropTypes from "prop-types";

// Hooks
import { useSelect } from "./SelectContext";
import { useGridItemTemplate } from "../useGridItemTemplate.js";

// styles
import { AssetContainerStyled, GridItemContentStyled } from "../styles.js";

// components
import FauxCheckbox from "../components/FauxCheckbox.js";
import UploadCount from "../components/UploadCount.js";
import FileName from "../components/FileName.js";
import EditButton from "../components/EditButton.js";

// for optional consumer functions
import { nullFunc } from "../utils.js";

export const GridItemContentSelect = ({
    id,
    name,
    gridIndex,
    gridItemCount,

    children,

    showUploadCount = true,
    showFileName = true,
    showEditButton = false,

    onMouseEnter = nullFunc,
    onMouseLeave = nullFunc,
    onFocus = nullFunc,
    onBlur = nullFunc
}) => {
    // useSelect Hook State
    const select = useSelect();
    const {
        isHovered,
        isFocused,
        onMouseEnterHandler,
        onMouseLeaveHandler,
        onFocusHandler,
        onBlurHandler
    } = useGridItemTemplate({ onMouseEnter, onMouseLeave, onFocus, onBlur });

    // props
    const isChecked = select.uploadsSelected[id] || false;
    const value = isChecked ? `${name} is selected` : name;
    const showContents = isHovered || isFocused;

    // methods
    const onClick = e => {
        select.onClick(e, id, gridIndex);
    };

    // render
    return (
        <AssetContainerStyled
            onMouseEnter={onMouseEnterHandler} // for mouse
            onMouseLeave={onMouseLeaveHandler} // for mouse
            onFocus={onFocusHandler} // for keyboard
            onBlur={onBlurHandler} // for keyboard
            isChecked={isChecked}
            isFocused={isFocused}
            onClick={onClick}
            cursorIsPointer={true}
        >
            {/* Grid Item Content: Provided by Consumer */}
            <GridItemContentStyled>{children}</GridItemContentStyled>

            {/* FAUX CHECKBOX */}
            <FauxCheckbox
                id={id}
                value={value}
                isHovered={showContents}
                isChecked={isChecked}
            />

            {/* OVERLAY META ELEMENTS */}
            <UploadCount
                showUploadCount={showUploadCount}
                isHovered={showContents}
                gridIndex={gridIndex}
                gridItemCount={gridItemCount}
            />
            <FileName
                showFileName={showFileName}
                isHovered={showContents}
                name={name}
            />
            {showEditButton && (
                <EditButton photoId={id} name={name} isVisible={showContents} />
            )}
        </AssetContainerStyled>
    );
};

export default GridItemContentSelect;

// prop-types
GridItemContentSelect.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    gridIndex: PropTypes.number.isRequired,
    gridItemCount: PropTypes.number,

    children: PropTypes.any,

    showUploadCount: PropTypes.bool,
    showFileName: PropTypes.bool,

    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func
};
