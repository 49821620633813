import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { getConfig } from "frontend/store/config";

import Heading from "core/Heading";

import {
    IconsPricingTableHeaderBestValue,
    IconsPricingTableHeaderTitle,
    IconsPricingTableHeaderPrice,
    IconsPricingTableHeader,
    IconsPricingTableHeaderPriceModifier,
    IconsPricingTableHeaderCta,
    IconsPricingTableHeaderBestValuePlaceholder,
    EduStrikeThroughPrice,
    CreativeCommonsIcon,
    EduPrice,
    EduPriceContainer
} from "./styles";

import {
    SUBTEXT_ANNUAL_TEAM_PLAN,
    SUBTEXT_ANNUAL_TEAM_PLAN_AS_MONTHLY,
    SUBTEXT_ANNUAL_PLAN_AS_MONTHLY,
    ANNUAL_PRICE_AS_MONTHLY,
    SUBTEXT_MONTHLY_PLAN,
    MONTHLY_PRICE_PLAN,
    YEARLY_PLAN_TYPE,
    PER_YEAR,
    SUBTEXT_ANNUAL_EDU_PLAN,
    ANNUAL_PRICE_AS_YEARLY
} from "core/constants";
import { truncateToDecimals } from "frontend/components/helpers";

export const getPriceText = ({ priceInCents }) =>
    `$${(priceInCents / 100).toFixed(2)}`;

export const IconsPricingTableHeaderBasic = () => (
    <IconsPricingTableHeader>
        <IconsPricingTableHeaderBestValuePlaceholder />
        <IconsPricingTableHeaderTitle type="h6" size="xs" alignment="center">
            Basic <CreativeCommonsIcon title="Creative Commons license" /> Icon
            <br />
            Downloads
        </IconsPricingTableHeaderTitle>
        <IconsPricingTableHeaderPrice>$0</IconsPricingTableHeaderPrice>
    </IconsPricingTableHeader>
);

export const IconsPricingTableHeaderProDownloads = () => {
    const { singleIconPriceCents } = useSelector(getConfig);
    return (
        <IconsPricingTableHeader>
            <IconsPricingTableHeaderBestValuePlaceholder />
            <IconsPricingTableHeaderTitle
                type="h6"
                size="xs"
                alignment="center"
            >
                Pro Icon
                <br />
                Downloads
            </IconsPricingTableHeaderTitle>
            <IconsPricingTableHeaderPrice>
                {getPriceText({
                    priceInCents: singleIconPriceCents
                })}
            </IconsPricingTableHeaderPrice>
            <IconsPricingTableHeaderPriceModifier>
                /icon
            </IconsPricingTableHeaderPriceModifier>
        </IconsPricingTableHeader>
    );
};

const EduSpecialPricing = () => (
    <EduPriceContainer>
        <EduStrikeThroughPrice>$39.99</EduStrikeThroughPrice>
        <EduPrice>$19.99</EduPrice>
    </EduPriceContainer>
);

const IconsPricingTableHeaderIndividualSub = ({
    planType,
    bestValueHighlight,
    eduPricingLayout,
    individualColumnClick
}) => {
    const isYearly = planType === YEARLY_PLAN_TYPE;
    return (
        <IconsPricingTableHeader>
            {isYearly && bestValueHighlight ? (
                <IconsPricingTableHeaderBestValue>
                    {eduPricingLayout ? "50% off" : "Best Value"}
                </IconsPricingTableHeaderBestValue>
            ) : (
                <IconsPricingTableHeaderBestValuePlaceholder />
            )}
            <IconsPricingTableHeaderTitle
                type="h6"
                size="xs"
                alignment="center"
            >
                {eduPricingLayout ? "Icon Pro for EDU" : "Icon Pro"}
                <br />
                {eduPricingLayout ? "Subscription" : "Individual Subscription"}
            </IconsPricingTableHeaderTitle>
            {eduPricingLayout ? (
                <EduSpecialPricing />
            ) : (
                <IconsPricingTableHeaderPrice>
                    <Heading>
                        {isYearly
                            ? "$" + truncateToDecimals(ANNUAL_PRICE_AS_MONTHLY)
                            : "$" + MONTHLY_PRICE_PLAN}
                    </Heading>
                </IconsPricingTableHeaderPrice>
            )}
            <IconsPricingTableHeaderPriceModifier>
                {eduPricingLayout
                    ? PER_YEAR
                    : isYearly
                    ? SUBTEXT_ANNUAL_PLAN_AS_MONTHLY
                    : SUBTEXT_MONTHLY_PLAN}
            </IconsPricingTableHeaderPriceModifier>
            <IconsPricingTableHeaderCta
                onClick={individualColumnClick}
                styleType={bestValueHighlight ? "cta" : "outline"}
                bestValueHighlight={bestValueHighlight}
            >
                {bestValueHighlight ? "Get Started" : "Learn More"}
            </IconsPricingTableHeaderCta>
        </IconsPricingTableHeader>
    );
};

IconsPricingTableHeaderIndividualSub.propTypes = {
    planType: PropTypes.oneOf(["yearly", "monthly"]).isRequired,
    bestValueHighlight: PropTypes.bool,
    eduPricingLayout: PropTypes.bool,
    individualColumnClick: PropTypes.func.isRequired
};

export { IconsPricingTableHeaderIndividualSub };

const IconsPricingTableHeaderTeamSub = ({
    planType,
    bestValueHighlight,
    teamColumnClick,
    eduPricingLayout
}) => {
    const isYearly = planType === YEARLY_PLAN_TYPE;

    let teamPlanSubtext = eduPricingLayout
        ? SUBTEXT_ANNUAL_EDU_PLAN
        : SUBTEXT_ANNUAL_TEAM_PLAN;

    if (!isYearly) {
        teamPlanSubtext = SUBTEXT_ANNUAL_TEAM_PLAN_AS_MONTHLY;
    }

    return (
        <IconsPricingTableHeader>
            {planType === "yearly" && bestValueHighlight ? (
                <IconsPricingTableHeaderBestValue>
                    Best Value
                </IconsPricingTableHeaderBestValue>
            ) : (
                <IconsPricingTableHeaderBestValuePlaceholder />
            )}
            <IconsPricingTableHeaderTitle
                type="h6"
                size="xs"
                alignment="center"
            >
                Icon Pro
                <br />
                Team Subscription
            </IconsPricingTableHeaderTitle>
            <IconsPricingTableHeaderPrice>
                {eduPricingLayout
                    ? "$" + ANNUAL_PRICE_AS_YEARLY
                    : isYearly
                    ? "$" + truncateToDecimals(ANNUAL_PRICE_AS_MONTHLY)
                    : "$" + MONTHLY_PRICE_PLAN}
            </IconsPricingTableHeaderPrice>
            <IconsPricingTableHeaderPriceModifier>
                {teamPlanSubtext}
            </IconsPricingTableHeaderPriceModifier>
            <IconsPricingTableHeaderCta
                onClick={teamColumnClick}
                styleType={bestValueHighlight ? "cta" : "solid"}
                bestValueHighlight={bestValueHighlight}
            >
                {bestValueHighlight ? "Start a Team" : "Learn More"}
            </IconsPricingTableHeaderCta>
        </IconsPricingTableHeader>
    );
};

IconsPricingTableHeaderTeamSub.propTypes = {
    planType: PropTypes.oneOf(["yearly", "monthly"]).isRequired,
    bestValueHighlight: PropTypes.bool,
    teamColumnClick: PropTypes.func.isRequired,
    eduPricingLayout: PropTypes.bool
};

export { IconsPricingTableHeaderTeamSub };
