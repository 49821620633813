import React from "react";

// components
import Row1 from "./Layout/Row1/index";
import Row2 from "./Layout/Row2/index";

import styled from "styled-components";

//styles
const TabletDesktopStyled = styled.div`
    padding: 0px 1.5rem;
`;

// component
export const TabletDesktop = () => {
    return (
        <TabletDesktopStyled>
            <Row1 />
            <Row2 />
        </TabletDesktopStyled>
    );
};

export default TabletDesktop;
