import gql from "graphql-tag";
import { useApolloMutation } from "frontend/utils/apolloApi/useApolloMutation.js";

// GQL
const ADD_TAGS = gql`
    mutation addTagsToPhotoUploads($photoUploadIds: [ID!], $tags: [String!]) {
        payload: addTagsToPhotoUploads(
            photoUploadIds: $photoUploadIds
            tags: $tags
        ) {
            ok
            photoUploads {
                id
                userTags
                warningUserTags
                autoTags
                approvedUserTags
            }
            tagsNotAdded {
                photoUploadId
                reason
                tag
            }
        }
    }
`;

const errorHandler = normalizedResp => {
    const { ssResp, message } = normalizedResp;
    let newMessage = message;
    const tagsNotAdded = ssResp?.data?.payload?.tagsNotAdded || [];
    tagsNotAdded.forEach(({ reason, tag }) => {
        if (reason.includes("DISALLOWED")) {
            newMessage = `Sorry, the tag ${tag} is one of our disallowed terms.`;
        }
    });
    return {
        ...normalizedResp,
        message: newMessage
    };
};

export const useAddTags = () => {
    const { apolloMutate, loading } = useApolloMutation({
        actionDesc: "Add Photo Upload Tags",
        gql: ADD_TAGS,
        errorHandler
    });

    const addTags = async variables => {
        return await apolloMutate({ variables });
    };

    return {
        addTags,
        addTagsLoading: loading
    };
};
