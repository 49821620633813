import { actionTypes } from "./actionTypes";

/*---------------------------
| Action Creators
---------------------------*/
export const updateUploadIds = uploadIds => {
    return {
        type: actionTypes.UPDATE_UPLOAD_IDS,
        uploadIds
    };
};
export const toggleId = (id, gridIndex) => {
    return {
        type: actionTypes.TOGGLE_ID,
        id,
        gridIndex
    };
};
// Set ALL ids to true
export const selectAll = () => {
    return {
        type: actionTypes.SELECT_ALL
    };
};
// Set ALL ids to false
export const deselectAll = () => {
    return {
        type: actionTypes.DESELECT_ALL
    };
};
// Set specific ids to true
export const selectIds = ids => {
    return {
        type: actionTypes.SELECT_IDS,
        ids
    };
};
// Set specific ids to false
export const deselectIds = ids => {
    return {
        type: actionTypes.DESELECT_IDS,
        ids
    };
};
// Set specific ids to false
export const selectDeselectRange = ({ id, gridIndex }) => {
    return {
        type: actionTypes.SELECT_DESELECT_RANGE,
        id,
        gridIndex
    };
};

export const selectNext = () => {
    return {
        type: actionTypes.SELECT_NEXT
    };
};

export const selectPrev = () => {
    return {
        type: actionTypes.SELECT_PREV
    };
};
