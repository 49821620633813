import React from "react";

import Heading from "core/Heading";
import Link from "core/Link";

import { RoyaltiesNoActivityBodyText } from "./styles";

const RoyaltiesNoActivity = () => {
    return (
        <>
            <Heading size="lg">No Photo Activity</Heading>
            <RoyaltiesNoActivityBodyText>
                Learn more about submitting photos and maximizing sales in the{" "}
                <Link href="https://brand.thenounproject.com/k/Noun-Project-Photographer-Guide-P9J8Wn">
                    Photographer Handbook
                </Link>
                .
            </RoyaltiesNoActivityBodyText>
        </>
    );
};

export default RoyaltiesNoActivity;
