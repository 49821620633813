import gql from "graphql-tag";

export const GET_BROWSE_ICONS_BY_CREATOR_DATA = gql`
    query browseIconsByCreator($username: String!, $limit: Int, $offset: Int) {
        browseIconsByCreator(
            username: $username
            limit: $limit
            offset: $offset
        ) {
            items {
                id
                title
                thumbnails {
                    thumbnail200
                }
                url
            }
            creator {
                id
                username
                name
                avatarUrl
            }
            totalCount
        }
    }
`;
