import React from "react";
import PropTypes from "prop-types";
import justifiedLayout from "justified-layout";

import { GridContainer, GridItemContainer } from "./styles";

import useContainerWidth from "frontend/hooks/useContainerWidth";

const JustifiedGrid = ({
    itemRatios,
    items,
    itemSpacing = 24,
    targetRowHeight = 265
}) => {
    const { containerWidth, containerRef } = useContainerWidth();

    const { boxes, containerHeight } = justifiedLayout(itemRatios, {
        containerWidth: containerWidth || 1,
        targetRowHeight,
        boxSpacing: itemSpacing,
        containerPadding: 0
    });

    return (
        <GridContainer height={containerHeight} ref={containerRef}>
            {boxes.map((box, index) => {
                return (
                    <GridItemContainer key={index} {...box}>
                        {items[index]}
                    </GridItemContainer>
                );
            })}
        </GridContainer>
    );
};

JustifiedGrid.propTypes = {
    items: PropTypes.arrayOf(PropTypes.element).isRequired,
    itemRatios: PropTypes.arrayOf(PropTypes.number).isRequired,
    itemSpacing: PropTypes.number, // in px
    targetRowHeight: PropTypes.number // in px
};

export default JustifiedGrid;
