import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import Button from "core/Button";
import SrOnly from "core/SrOnly";
import { TagRemoveable } from "core/Tag";

import AddTagsContext from "./context";
import TagsInput from "core/AddTags/TagsInput";
import debounce from "lodash/debounce";

import {
    FlexContainer,
    FlexboxRow,
    FlexboxColumn,
    LabelBodyText,
    ErrorTextContainer,
    InformationalTextContainer,
    TagsContainer
} from "./styles";

const AddTags = ({
    ariaId,
    labelText,
    placeholderText,
    isRequired,
    minLength,
    buttonText,
    inputId,
    handleFocus,
    handleBlur,
    handleChange,
    isFocused,
    handleSubmit,
    hasInputError,
    setInputError,
    isTermAdded,
    isDisabled,
    defaultInformationalText,
    handleDeleteTerm,
    disabledLabelText,
    //
    tags,
    handleSuggestedResults,
    suggestedTags,
    suggestedTagsQuery,
    setSuggestedTags,
    tagInputValue,
    setTagInputValue
}) => {
    const addTagsContextConstants = {
        ariaId,
        labelText,
        placeholderText,
        isRequired,
        minLength,
        buttonText,
        inputId,
        handleFocus,
        handleBlur,
        isFocused,
        handleSubmit,
        hasInputError,
        setInputError,
        isDisabled,
        defaultInformationalText,
        handleDeleteTerm,
        disabledLabelText,
        //
        tags
    };

    const [focusedItem, setFocusedItem] = useState(null);
    const tagsDropdownRef = useRef(null);

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    const srTermText =
        isTermAdded.length > 0 &&
        (isTermAdded.length === 1
            ? `The term "${isTermAdded}" has successfully
    been added to your tags list`
            : `The terms "${isTermAdded}" have successfully
    been added to your tags list`);

    const labelConditionalProps = isDisabled && {
        "aria-label": disabledLabelText
    };

    const dropdownId = "suggested-tags-container";
    const hasTagsAdded = tags.length > 0;
    const showDropdown =
        hasInputError.length === 0 &&
        suggestedTags.length > 0 &&
        tagInputValue !== "" &&
        suggestedTagsQuery[0] === tagInputValue[0];

    const debouncedSuggestedResults = React.useRef(
        debounce(handleSuggestedResults, 500)
    );

    useEffect(() => {
        return () => {
            debouncedSuggestedResults.current.cancel();
        };
    }, [debouncedSuggestedResults]);

    const setValueCloseDropdown = tag => {
        setTagInputValue(tag);
        setSuggestedTags([]);
        setFocusedItem(null);
    };

    const handleOnChange = query => {
        setTagInputValue(query);
        handleChange && handleChange(query);
        debouncedSuggestedResults.current(query);
    };

    const handleClickOutside = () => {
        if (tagsDropdownRef.current) {
            setSuggestedTags([]);
            setFocusedItem(null);
        }
    };

    const handleKeyDown = e => {
        if (suggestedTags.length === 0) return;
        const isKeyDown = e.keyCode === 40;
        const isKeyUp = e.keyCode === 38;
        const isEnterPressed = e.keyCode === 13;
        if (!isKeyDown && !isKeyUp && !isEnterPressed) return;

        e.preventDefault();

        if (isKeyDown) {
            setFocusedItem(prevItem => {
                if (prevItem === null) return 0;
                return prevItem < suggestedTags.length - 1
                    ? prevItem + 1
                    : prevItem;
            });
        } else if (isKeyUp) {
            setFocusedItem(prevItem => (prevItem > 0 ? prevItem - 1 : 0));
        } else if (isEnterPressed) {
            const selectedItem = suggestedTags[focusedItem];
            setValueCloseDropdown(selectedItem);
        }
    };

    const onSubmit = e => {
        handleSubmit(e); //allow host to leverage submit listener
        setSuggestedTags([]);
        setFocusedItem(null);
    };

    return (
        <AddTagsContext.Provider value={addTagsContextConstants}>
            <LabelBodyText
                type="label"
                size="lg"
                htmlFor={inputId}
                isRequired={isRequired}
                {...labelConditionalProps}
            >
                {labelText}
            </LabelBodyText>
            <FlexContainer>
                <FlexboxRow>
                    <FlexboxColumn>
                        <TagsInput
                            dropdownId={dropdownId}
                            handleKeyDown={handleKeyDown}
                            inputId={inputId}
                            minLength={minLength}
                            placeholderText={placeholderText}
                            handleOnChange={handleOnChange}
                            handleSubmit={onSubmit}
                            tagInputValue={tagInputValue}
                            labelText={labelText}
                            isRequired={isRequired}
                            showDropdown={showDropdown}
                            dropdownMaxHeight={"16.5rem"}
                            tagsDropdownRef={tagsDropdownRef}
                            suggestedTags={suggestedTags}
                            setValueCloseDropdown={setValueCloseDropdown}
                            focusedItem={focusedItem}
                        />

                        {hasInputError.length > 0 ? (
                            <ErrorTextContainer
                                role="alert"
                                hasTagsAdded={hasTagsAdded}
                            >
                                {hasInputError.map((error, index) => {
                                    if (index !== error.length - 1) {
                                        const key = `error-${index}-${error.reason}`;
                                        return (
                                            <span key={key}>
                                                {error.message} <br />
                                            </span>
                                        );
                                    } else {
                                        return error.message;
                                    }
                                })}
                            </ErrorTextContainer>
                        ) : (
                            <div style={{ marginBottom: "1.3rem" }}></div>
                        )}
                    </FlexboxColumn>
                    <FlexboxColumn>
                        <Button
                            size="md"
                            onClick={e => onSubmit(e)}
                            isDisabled={isDisabled}
                        >
                            {buttonText}
                        </Button>
                    </FlexboxColumn>
                </FlexboxRow>
                <FlexboxRow>
                    <FlexboxColumn>
                        {hasInputError.length === 0 && (
                            <>
                                <InformationalTextContainer
                                    hasTagsAdded={hasTagsAdded}
                                >
                                    {defaultInformationalText}
                                </InformationalTextContainer>
                                {isTermAdded.length > 0 && (
                                    <SrOnly role="alert">{srTermText}</SrOnly>
                                )}
                            </>
                        )}
                    </FlexboxColumn>
                    <FlexboxColumn>
                        {/* intentionally left empty */}
                    </FlexboxColumn>
                </FlexboxRow>
            </FlexContainer>

            {/* Tag display and removal: */}
            {hasTagsAdded && (
                <TagsContainer>
                    {tags.map((term, index) => {
                        const key = `${term.replace(/ /g, "")}-${index}`;

                        return (
                            <TagRemoveable
                                key={key}
                                text={term}
                                onClick={() => handleDeleteTerm(term)}
                                isTruncated
                            />
                        );
                    })}
                </TagsContainer>
            )}
        </AddTagsContext.Provider>
    );
};

AddTags.propTypes = {
    ariaId: PropTypes.string.isRequired,
    handleSuggestedResults: PropTypes.func.isRequired,
    labelText: PropTypes.string.isRequired,
    placeholderText: PropTypes.string,
    isRequired: PropTypes.bool,
    minLength: PropTypes.number,
    buttonText: PropTypes.string,
    hasInformationalText: PropTypes.any,
    tags: PropTypes.array,
    suggestedTags: PropTypes.array,
    disableRemoveFromUI: PropTypes.bool,
    handleChange: PropTypes.func
};

export default AddTags;
