import React from "react";
import { useQuery } from "@apollo/client";
import { GET_CURRENT_USER_ACTIVE_SUBSCRIPTION } from "./query";

// This hook provides specific info on the user currentUser's activieSubscription status
// and paypal/sub info. No need to add this to the general currentUser call
export const useCurrentUserActiveSub = () => {
    const { data, loading } = useQuery(GET_CURRENT_USER_ACTIVE_SUBSCRIPTION, {
        fetchPolicy: "cache-and-network"
    });

    const { currentUser } = data || {};

    const {
        username = "",
        hasSubscription = false,
        activeSubscription = null
    } = currentUser || {};

    return {
        username,
        hasSubscription,
        activeSubscription,
        loading
    };
};
