import React from "react";
import styled from "styled-components";
import { useLazyQuery } from "@apollo/client";

import BodyText from "core/BodyText";
import JustifiedGrid from "core/JustifiedGrid";
import HoverTextOverlay from "core/HoverTextOverlay";

import RefreshingImage from "../../../../core/RefreshingImage";

import { PHOTO_UPLOAD_URLS } from "./queries";

const GridViewContainer = styled.div`
    ${BodyText} {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

const ImagesContainerGridView = ({ uploads }) => {
    const [refreshImageUrl] = useLazyQuery(PHOTO_UPLOAD_URLS, {
        fetchPolicy: "network-only"
    });

    const itemRatios = [];

    const items = uploads.map(upload => {
        const {
            id,
            photoTitle,
            originalFilename,
            thumbnail,
            aspectRatio
        } = upload;
        const uploadName = photoTitle || originalFilename;

        if (!thumbnail) return null;

        itemRatios.push(aspectRatio);

        return (
            <GridViewContainer key={id}>
                <RefreshingImage
                    src={thumbnail}
                    alt={`File name ${uploadName}`}
                    fetchNewUrl={() =>
                        refreshImageUrl({
                            variables: {
                                photoUploadIds: [id]
                            }
                        })
                    }
                />

                <HoverTextOverlay
                    hoverText={uploadName}
                    textSize="xs"
                    textContainerType="div"
                />
            </GridViewContainer>
        );
    });

    return (
        <JustifiedGrid
            items={items}
            itemRatios={itemRatios}
            itemSpacing={16}
            targetRowHeight={120}
        />
    );
};

export default ImagesContainerGridView;
