import React from "react";
import styled from "styled-components";

// context
import { useSiteHeader } from "core/SiteHeaderV2/context/useSiteHeader.js";

// components
import ScrollingResize from "./ScrollingResize";
import SearchBar from "core/SiteHeaderV2/components/SearchBar";

// styles
import { convertPxToRem } from "frontend/components/helpers";

const Row2Styled = styled.div`
    position: relative;
    margin-top: ${({ $marginTop }) => convertPxToRem($marginTop)};
    height: ${({ $height }) => convertPxToRem($height)};
    overflow: ${({ $isVisible }) => ($isVisible ? "visible" : "hidden")};
`;

// component
export const Row2 = () => {
    const {
        isDarkMode,
        device,
        searchBar: { isVisible },
        header: { secondRowHeight, secondRowMarginTop }
    } = useSiteHeader();

    return (
        <Row2Styled
            $isVisible={isVisible}
            $height={secondRowHeight}
            $marginTop={secondRowMarginTop}
        >
            <ScrollingResize>
                <SearchBar device={device} isDarkMode={isDarkMode} />
            </ScrollingResize>
        </Row2Styled>
    );
};

export default Row2;
