import styled from "styled-components";

import { handleTextSizeAndFont } from "./helpers";

import colors from "core/colors";
import { bold as boldTextStyle } from "core/fontStyles";

export const StyledBodyText = styled.span`
    ${({ size }) => handleTextSizeAndFont({ size })}
    ${({ as }) =>
        as === "button" &&
        `
        cursor: pointer; 
        border: 0;
        padding: 0;
        margin: 0;
        background-color: transparent;
    `};
    color: ${({ copyColor }) =>
        copyColor ? copyColor : colors.secondary.orca};
    text-align: ${({ alignment }) => (alignment ? alignment : "left")};
    ${({ bold }) => bold && boldTextStyle};
`;

// TODO: add spacing like Heading
