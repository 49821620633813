/**
 * How many pixels the header will travel within the range: 1:1
 * Why 60 pixels?
 *  - Row1: height: 40px + margin-top 18px = 58px
 *  - Row2: top marging 12px when not scrolled, 10px when scrolled (12-10 = 2)
 *  - 58px + 2px = 60px
 */
export const HEADER_PINNED_SCROLL_Y_BREAKPOINT = 60;

export const SCROLL_Y_BREAKPOINTS = {
    default: HEADER_PINNED_SCROLL_Y_BREAKPOINT,
    home: 300,
    icons: 433,
    photos: 433
};
