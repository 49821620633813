import React from "react";
import styled from "styled-components";

// context
import { usePanelOverlay } from "../usePanelOverlay";

// components
import { UnstyledButton } from "core/Button/styles.js";
import CloseIcon from "core/ReusableIcons/Close";

// styles
import { convertPxToRem } from "frontend/components/helpers";
import colors from "core/colors";
const CloseButtonStyled = styled(UnstyledButton)`
    position: absolute;

    /* 24px - 5px padding = 19 */
    right: ${convertPxToRem(19)};
    top: ${convertPxToRem(19)};
    z-index: 1;

    height: ${convertPxToRem(26)};

    /* fat fingers */
    padding: ${convertPxToRem(5)};

    svg {
        vertical-align: middle;
        path {
            fill: ${colors.secondary.orca};
        }
    }
`;

// component
export const CloseButton = () => {
    const { close } = usePanelOverlay();

    return (
        <CloseButtonStyled onClick={close}>
            <CloseIcon size={{ width: "16", height: "16" }} />
        </CloseButtonStyled>
    );
};

export default CloseButton;
