import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import {
    AuthButton,
    AuthCaptcha,
    AlternativeLabel,
    FormErrorContainer
} from "../styles";
import FormInput from "core/FormInput2";
import FormError from "core/FormError";
import FacebookAuthButton from "../FacebookAuthButton";
import GoogleLoginButton from "../GoogleLoginButton";

import {
    validNameOrEmail,
    validPassword,
    attemptAuth,
    LOGIN_BACKEND_URL
} from "../helpers";
import { getConfig } from "frontend/store/config";

const Login = ({ afterAuth, isStandaloneAuthPage }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [token, setToken] = useState(null);
    const [backendError, setBackendError] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);

    const { accountLoginRequireCaptcha } = useSelector(getConfig);
    const requireCaptcha = !!accountLoginRequireCaptcha;

    const isDisabled = !(
        validNameOrEmail(email) &&
        password &&
        (!requireCaptcha || token)
    );

    const onClick = () => {
        setIsProcessing(true);
        setBackendError(null);
        attemptAuth({
            url: LOGIN_BACKEND_URL,
            form: {
                login: email,
                password,
                captcha_token: token
            },
            onSuccess: afterAuth, // closes modal after auth is complete
            onFail(errorMsg) {
                setIsProcessing(false);
                setBackendError(errorMsg);
            }
        });
    };

    const handleKeyPress = e => {
        if (e.key === "Enter") {
            onClick();
        }
    };

    const formError = isStandaloneAuthPage ? (
        <FormErrorContainer>
            <FormError>{backendError}</FormError>
        </FormErrorContainer>
    ) : (
        <FormError>{backendError}</FormError>
    );

    return (
        <>
            {formError}
            <FormInput
                labelText="Email Address"
                placeholderText="yourname@domain.com"
                value={email}
                onChange={setEmail}
                validation={[
                    {
                        errorMsg: "Please enter a valid email.",
                        isValid: validNameOrEmail
                    }
                ]}
                onKeyPress={handleKeyPress}
                size="lg"
            />
            <FormInput
                type="password"
                labelText="Password"
                placeholderText="Password"
                value={password}
                onChange={setPassword}
                onKeyPress={handleKeyPress}
                validation={[
                    {
                        errorMsg: "Please enter a password.",
                        isValid: validPassword
                    }
                ]}
                size="lg"
                bottomSpacing={requireCaptcha ? "none" : "sm"}
            />
            {requireCaptcha && <AuthCaptcha handleToken={setToken} />}
            <AuthButton
                styleType="cta"
                isProcessing={isProcessing}
                isDisabled={isDisabled}
                onClick={onClick}
            >
                Log In
            </AuthButton>
            <AlternativeLabel>or</AlternativeLabel>
            <GoogleLoginButton />
            <FacebookAuthButton>Sign in with Facebook</FacebookAuthButton>
        </>
    );
};

Login.propTypes = {
    afterAuth: PropTypes.func.isRequired
};

export default Login;
