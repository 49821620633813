import styled, { css } from "styled-components";

// styles + components:
import Heading from "core/Heading";
import boxShadows from "core/boxShadows";
import colors from "core/colors";
import { focusOutline } from "core/links";
import { BlueBorderBottomLinkStyle } from "core/Typography";
import { textSm } from "core/fontStyles";

import { AllButtonStyles } from "core/Button/styles";

// helpers:
import { convertPxToRem } from "frontend/components/helpers";

// reusable constants:
const CONTAINER_SPACING_PX = 24;
const CONTAINER_BOTTOM_SPACING_PX = 16;
const CLOSE_WIDTH_HEIGHT_PX = 24;
const CLOSE_MARGIN_PX = 8;
const CLOSE_RIGHT_PX = CONTAINER_SPACING_PX - 4;

// styled components:
export const TriggerButton = styled.button`
    ${({ isLinkTriggerStyle }) =>
        isLinkTriggerStyle
            ? css`
                  cursor: pointer;
                  padding: 0;
                  margin: 0;
                  border: 0;
                  background: transparent;
                  ${BlueBorderBottomLinkStyle}
                  ${textSm}
                  
                    // only show focus on keyboard not with mouse:
                    &:focus:not(.focus-visible) {
                      outline: none;
                      border-radius: 0;
                      box-shadow: 0;
                  }

                  &.focus-visible {
                      ${focusOutline};
                  }
              `
            : css`
                  ${AllButtonStyles}
              `}
`;

export const DropdownContainer = styled.div`
    position: relative;
`;

export const DropdownBox = styled.div`
    z-index: 1;
    position: absolute;
    background: ${({ bgColor }) => bgColor};

    ${({ width }) => css`
        width: ${width};
    `}

    ${({ absoluteRight }) =>
        absoluteRight &&
        css`
            right: ${absoluteRight};
        `}

    ${({ absoluteLeft }) =>
        absoluteLeft &&
        css`
            left: ${absoluteLeft};
        `}

    ${({ showBoxShadow }) =>
        showBoxShadow &&
        css`
            box-shadow: ${boxShadows.lightMode.overlay};
        `}

    ${({ hasOutsideClick }) =>
        hasOutsideClick
            ? css`
                  display: none;
              `
            : css`
                  display: inline-block;
              `}

    // only show focus on keyboard not with mouse:
    &:focus:not(.focus-visible) {
        outline: none;
        border-radius: 0;
    }

    &.focus-visible, &:focus-visible {
        ${focusOutline};
    }
`;

export const HeaderContainer = styled.div.attrs({ as: "header" })``;

export const DropdownHeading = styled(Heading)`
    ${({ showCloseButton }) =>
        showCloseButton
            ? css`
                  width: calc(
                      100% - ${convertPxToRem({ px: CLOSE_WIDTH_HEIGHT_PX })} -
                          ${convertPxToRem({ px: CLOSE_MARGIN_PX })}
                  );
                  padding-top: ${convertPxToRem({ px: CONTAINER_SPACING_PX })};
                  padding-right: 0;
                  padding-bottom: ${convertPxToRem({
                      px: CONTAINER_SPACING_PX
                  })};
                  padding-left: ${convertPxToRem({ px: CONTAINER_SPACING_PX })};
              `
            : css`
                  width: 100%;
                  padding: ${convertPxToRem({ px: CONTAINER_SPACING_PX })};
              `}
`;

export const CloseButton = styled.button`
    background: transparent;
    cursor: pointer;
    border: 0;
    font-size: 0;
    line-height: 0;
    padding: 0;
    margin: 0 0 0 ${convertPxToRem({ px: CLOSE_MARGIN_PX })};
    height: ${convertPxToRem({ px: CLOSE_WIDTH_HEIGHT_PX })};
    width: ${convertPxToRem({ px: CLOSE_WIDTH_HEIGHT_PX })};

    position: absolute;
    top: ${convertPxToRem({ px: CONTAINER_SPACING_PX })};
    right: ${convertPxToRem({ px: CLOSE_RIGHT_PX })};

    // only show focus on keyboard not with mouse:
    &:focus:not(.focus-visible) {
        outline: none;
        box-shadow: none;
        border: none;
    }

    &:focus {
        ${focusOutline}
    }
`;

export const ChildrenContainer = styled.div`
    max-height: ${convertPxToRem({ px: 350 })};
    overflow-x: hidden;
    overflow-y: auto;
    padding-left: ${convertPxToRem({ px: CONTAINER_SPACING_PX })};
    padding-right: ${convertPxToRem({ px: CONTAINER_SPACING_PX })};
    padding-bottom: ${convertPxToRem({ px: CONTAINER_BOTTOM_SPACING_PX })};

    ${({ heading }) =>
        !heading &&
        css`
            padding-top: ${convertPxToRem({ px: CONTAINER_SPACING_PX })};
        `}
`;

export const Footer = styled.footer`
    text-align: center;
    padding: ${convertPxToRem({ px: CONTAINER_SPACING_PX })} 0;
    height: ${convertPxToRem({ px: 72 })};
    border-top: ${convertPxToRem({ px: 1 })} solid ${colors.primary.pigeon};
`;
