import { css } from "styled-components";

// mobile -> table/desktop
// name represents desktop spacing
export const textMargin = {
    "8-t-b": css`
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    `,
    "8-t": css`
        margin-top: 0.5rem;
    `,
    "8-b": css`
        margin-bottom: 0.5rem;
    `,
    "16-t-b": css`
        margin-top: 1rem;
        margin-bottom: 1rem;
    `,
    "16-t": css`
        margin-top: 1rem;
    `,
    "16-b": css`
        margin-bottom: 1rem;
    `,
    "24-t-b": css`
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
    `,
    "24-t": css`
        margin-top: 1.5rem;
    `,
    "24-b": css`
        margin-bottom: 1.5rem;
    `,
    "32-t-b": css`
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;

        @media (min-width: 48rem) {
            margin-top: 2rem;
            margin-bottom: 2rem;
        }
    `,
    "32-t": css`
        margin-top: 1.5rem;

        @media (min-width: 48rem) {
            margin-top: 2rem;
        }
    `,
    "32-b": css`
        margin-bottom: 1.5rem;

        @media (min-width: 48rem) {
            margin-bottom: 2rem;
        }
    `,
    "40-t-b": css`
        margin-top: 2rem;
        margin-bottom: 2rem;

        @media (min-width: 48rem) {
            margin-top: 2.5rem;
            margin-bottom: 2.5rem;
        }
    `,
    "40-t": css`
        margin-top: 2rem;

        @media (min-width: 48rem) {
            margin-top: 2.5rem;
        }
    `,
    "40-b": css`
        margin-bottom: 2rem;

        @media (min-width: 48rem) {
            margin-bottom: 2.5rem;
        }
    `,
    "48-t-b": css`
        margin-top: 2rem;
        margin-bottom: 2rem;

        @media (min-width: 48rem) {
            margin-top: 3rem;
            margin-bottom: 3rem;
        }
    `,
    "48-t": css`
        margin-top: 2rem;

        @media (min-width: 48rem) {
            margin-top: 3rem;
        }
    `,
    "48-b": css`
        margin-bottom: 2rem;

        @media (min-width: 48rem) {
            margin-bottom: 3rem;
        }
    `,
    "64-t-b": css`
        margin-top: 3rem;
        margin-bottom: 3rem;

        @media (min-width: 48rem) {
            margin-top: 4rem;
            margin-bottom: 4rem;
        }
    `,
    "64-t": css`
        margin-top: 3rem;

        @media (min-width: 48rem) {
            margin-top: 4rem;
        }
    `,
    "64-b": css`
        margin-bottom: 3rem;

        @media (min-width: 48rem) {
            margin-bottom: 4rem;
        }
    `,
    "128-t-b": css`
        margin-top: 4rem;
        margin-bottom: 4rem;

        @media (min-width: 48rem) {
            margin-top: 8rem;
            margin-bottom: 8rem;
        }
    `,
    "128-t": css`
        margin-top: 4rem;

        @media (min-width: 48rem) {
            margin-top: 8rem;
        }
    `,
    "128-b": css`
        margin-bottom: 4rem;

        @media (min-width: 48rem) {
            margin-bottom: 8rem;
        }
    `
};
