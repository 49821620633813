import React, { useEffect, useState } from "react";
import { useDropzone as useReactDropzone } from "react-dropzone";

const MIN_FILE_SIZE = 1 * 1000 * 1000; // ~1 MB
const MAX_FILE_SIZE = 50 * 1024 * 1024; // 50 MB

function getFormattedBytes(bytes, k = 1000) {
    if (bytes === 0) return "0 bytes";
    if (bytes === 1) return "1 byte";

    const sizes = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const decimals = 0;
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    const val = parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)),
        unit = sizes[i];
    return `${val} ${unit}`;
}

const MAX_FILE_SIZE_FORMATTED = getFormattedBytes(MAX_FILE_SIZE, 1024);
const MIN_FILE_SIZE_FORMATTED = getFormattedBytes(MIN_FILE_SIZE, 1000);

function buildErrorMessage(rejectedFile) {
    return rejectedFile.errors
        .map(error => {
            if (error.code === "file-invalid-type") {
                return `Only .jpg files are supported.`;
            }

            const formattedFileSize = getFormattedBytes(rejectedFile.file.size);

            if (error.code === "file-too-small") {
                return `The minumum allowed file size is ${MIN_FILE_SIZE_FORMATTED}. This file is ${formattedFileSize}.`;
            }

            if (error.code === "file-too-large") {
                return `The largest allowed file size is ${MAX_FILE_SIZE_FORMATTED}. This file is ${formattedFileSize}.`;
            }

            return error.message;
        })
        .join(" ");
}

export const useDropzone = ({
    uploadFiles,
    handleRejections,
    initialMaximumFiles,
    filesInDropzoneCount
} = {}) => {
    const [isDisabledDropzone, setDisabledDropzone] = useState(false);
    const [maxFiles, setMaxFiles] = useState(initialMaximumFiles);

    useEffect(() => {
        setMaxFiles(initialMaximumFiles - filesInDropzoneCount);
    }, [initialMaximumFiles, filesInDropzoneCount]);

    const {
        isDragAccept,
        isDragReject,
        acceptedFiles,
        getRootProps,
        getInputProps,
        open: openFilePicker
    } = useReactDropzone({
        disabled: isDisabledDropzone,
        maxFiles: maxFiles,
        useFsAccessApi: false,
        // Accepting file types https://react-dropzone.js.org/#!/Accepting%20specific%20file%20types
        accept: {
            "image/jpeg": [".jpg", ".jpeg"]
        },
        minSize: MIN_FILE_SIZE,
        maxSize: MAX_FILE_SIZE,
        getFilesFromEvent: event => {
            const fromFilePicker = event._reactName === "onChange";
            const fromDragAndDrop = event._reactName === "onDrop";

            if (fromFilePicker) {
                return normalizeFilenames(event.target.files);
            }

            if (fromDragAndDrop) {
                return normalizeFilenames(event.dataTransfer.files);
            }

            return [];

            function normalizeFilenames(files = {}) {
                if (!files || files.length === 0) return [];

                const processedFiles = [];

                for (const key in files) {
                    if (key === "length" || key === "item") continue;

                    const file = files[key];

                    processedFiles.push(
                        new File([file], `${file.name.normalize("NFC")}`, {
                            type: file.type
                        })
                    );
                }

                return processedFiles;
            }
        },
        noDragEventsBubbling: true,
        onDropAccepted: uploadFiles,
        onDropRejected: fileRejections => {
            handleRejections(
                fileRejections.map(rejectedFile => {
                    return {
                        filename: rejectedFile.file.name,
                        size: rejectedFile.file.size,
                        type: rejectedFile.file.type,
                        message: buildErrorMessage(rejectedFile)
                    };
                })
            );
        }
    });

    const isDropzoneEmpty = filesInDropzoneCount === 0;

    return {
        getRootProps,
        getInputProps,
        isDropzoneEmpty,
        isDragAccept,
        isDragReject,
        initialMaximumFiles,
        maxFiles,
        acceptedFiles,
        isDisabledDropzone,
        setDisabledDropzone,
        openFilePicker,
        filesInDropzoneCount
    };
};
