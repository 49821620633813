import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";

import { PHOTO_UPLOAD, PHOTO_UPLOAD_TYPE_SELECT } from "data/links";

const validReferrerPathnames = [PHOTO_UPLOAD, PHOTO_UPLOAD_TYPE_SELECT];

const referrerIsValid = location => {
    let referrerPathname;

    if (
        location.state &&
        location.state.referrer &&
        location.state.referrer.pathname
    ) {
        referrerPathname = location.state.referrer.pathname;
    }

    let result = false;

    validReferrerPathnames.forEach(pathname => {
        if (referrerPathname === pathname) result = true;
    });

    return result;
};

const NoRedirect = () => null;

const RedirectToPhotoUploadView = ({ history }) => {
    useEffect(() => {
        history.replace(PHOTO_UPLOAD);
    }, []);

    return null;
};

const RedirectOnInvalidReferrer = () => {
    const history = useHistory();
    const location = useLocation();

    const [redirect, setRedirect] = useState(<NoRedirect />);

    useEffect(() => {
        if (!referrerIsValid(location)) {
            setRedirect(<RedirectToPhotoUploadView history={history} />);
        }
    }, []);

    return redirect;
};

export default RedirectOnInvalidReferrer;
