import React from "react";
import styled from "styled-components";

// data
import { primaryMenu } from "data/links";

// components
import SrOnly from "core/SrOnly";
import Link from "core/SiteHeaderV2/components/Link";
import Tools from "./Tools";
import TheNounProject from "./TheNounProject";

// styles
const ProductsMenuStyled = styled.nav`
    display: flex;
    align-items: center;
`;

const LinksStyled = styled.ul`
    margin: 0rem;
    padding: 0rem;
`;

const HomLink = styled(Link)`
    margin-left: 0rem;
`;

// component
export const ProductsMenu = ({ className }) => {
    const {
        name,
        links: { home, icons, photos, api }
    } = primaryMenu.products;

    return (
        <ProductsMenuStyled className={className} aria-label="Main Navigation">
            <SrOnly>
                <h2>{name}</h2>
            </SrOnly>
            <LinksStyled>
                <HomLink link={home} />
                <Link link={icons} />
                <Link link={photos} />
                <Link link={api} />
                <Tools />
                <TheNounProject />
            </LinksStyled>
        </ProductsMenuStyled>
    );
};

export default ProductsMenu;

import PropTypes from "prop-types";

// prop-types
ProductsMenu.propTypes = {
    className: PropTypes.string
};
