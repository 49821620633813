import React from "react";
import PropTypes from "prop-types";

// styles
import { ActionBarRightStyled } from "./styles.js";

// components
export const ActionBarRight = ({ children }) => {
    return <ActionBarRightStyled>{children}</ActionBarRightStyled>;
};

// prop-types
ActionBarRight.propTypes = {
    children: PropTypes.any
};
