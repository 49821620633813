import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import BodyText from "core/BodyText";

import colors from "core/colors";
import { Container, TextContainer } from "./styles";

import { bodyTextSizes, bodyTextTypes } from "core/props";

const UnstyledHoverTextOverlay = ({
    className,
    hoverText,
    textSize = "md",
    textContainerType = "span"
}) => (
    <Container className={className}>
        <TextContainer>
            <BodyText
                type={textContainerType}
                copyColor={colors.primary.snow}
                size={textSize}
                title={hoverText}
            >
                {hoverText}
            </BodyText>
        </TextContainer>
    </Container>
);

const HoverTextOverlay = styled(UnstyledHoverTextOverlay)``;

HoverTextOverlay.propTypes = {
    hoverText: PropTypes.string.isRequired,
    textSize: PropTypes.oneOf(bodyTextSizes),
    textContainerType: PropTypes.oneOf(bodyTextTypes)
};

export default HoverTextOverlay;
