import React from "react";

import SectionContainer from "core/SectionContainer";
import OffsetGrid from "core/OffsetGrid";

import colors from "core/colors";
import { LingoMarketingPageBulletSection } from "./styles";

import { bullets } from "./data";

const BulletSection = () => (
    <SectionContainer>
        <OffsetGrid sm={10} md={12} lg={10}>
            <LingoMarketingPageBulletSection
                sectionHeadingColor={colors.secondary.orca}
                bullets={bullets}
                sectionHeading="Create and Share Consistent Experiences"
                hasDesktopAndMobileOffsetOneCol
            />
        </OffsetGrid>
    </SectionContainer>
);

export default BulletSection;
