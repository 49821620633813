import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import colors from "core/colors";

const createHelpers = (width, height, css) => {
    // somehow sizes is ending up in markup, even if it is not a valid svg attribute
    // until we have a better solution, just render it empty, instead to '[Object object]'
    const sanitizeSizes = sizes =>
        Object.defineProperty(sizes, "toString", {
            value: () => "",
            enumerable: false
        });

    const getDimensions = (size, sizes) => {
        if (
            size &&
            typeof size.width === "number" &&
            typeof size.height === "number"
        ) {
            return size;
        }

        return size && sizes[size] ? sizes[size] : { width, height };
    };

    const getCss = (size, sizes, fillColor, fillColorRule, noStyles) => {
        if (noStyles) {
            return "";
        }

        const dimensions = getDimensions(size, sizes);
        const fillRule =
            fillColor && fillColorRule
                ? `${fillColorRule}{ fill: ${fillColor}; }`
                : "";
        return css`
            width: ${dimensions.width}px;
            height: ${dimensions.height}px;
            ${fillRule}
        `;
    };

    const propsToCss = ({ size, sizes, fillColor, fillColorRule, noStyles }) =>
        getCss(size, sizes, fillColor, fillColorRule, noStyles);

    return {
        getCss,
        getDimensions,
        propsToCss,
        sanitizeSizes
    };
};

const width = "22";
const height = "22";
const viewBox = "0 0 22 22";

const { getDimensions, getCss, propsToCss, sanitizeSizes } = createHelpers(
    width,
    height,
    css
);

const sizes = sanitizeSizes({
    xs: { width: 14, height: 14 },
    sm: { width: 16, height: 16 },
    md: { width: 20, height: 20 },
    lg: { width: 24, height: 24 },
    xl: { width: 40, height: 40 }
});

const Image = styled.svg`
    ${propsToCss}
`;

const children = (
    <Fragment>
        <path
            id="double arrow"
            d="M13.6971 17.0755C13.9732 17.0755 14.1971 17.2994 14.1971 17.5755L14.1972 18.75C14.1972 19.0262 13.9733 19.25 13.6972 19.25H3.6972C3.42106 19.25 3.1972 19.0262 3.1972 18.75V8.75C3.1972 8.47386 3.42106 8.25 3.6972 8.25H4.87163C5.1478 8.25 5.37167 8.47391 5.37163 8.75008L5.37055 15.5386L15.9858 4.92335L9.19728 4.92442C8.92111 4.92447 8.6972 4.7006 8.6972 4.42442V3.25C8.6972 2.97386 8.92106 2.75 9.1972 2.75H19.1972C19.4734 2.75 19.6972 2.97386 19.6972 3.25V13.25C19.6972 13.5261 19.4734 13.75 19.1972 13.75L18.0227 13.7499C17.7466 13.7499 17.5227 13.526 17.5227 13.2499V6.46047L6.90768 17.0755H13.6971Z"
            fill={colors.secondary.orca}
        />
    </Fragment>
);

const defaultProps = {
    children,
    viewBox,
    fillColor: null,
    fillColorRule: "&&& path, &&& use, &&& g, &&& circle",
    sizes,
    size: null
};

Image.propTypes /* remove-proptypes */ = {
    fillColor: PropTypes.string,
    fillColorRule: PropTypes.string,
    viewBox: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    size: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            height: PropTypes.number.isRequired,
            width: PropTypes.number.isRequired
        })
    ]),
    sizes: PropTypes.shape({
        height: PropTypes.number,
        width: PropTypes.number
    })
};

export default Object.assign(Image, {
    getDimensions,
    getCss,
    defaultProps,
    displayName: "ArrowDoubleExpand"
});
