import "babel-polyfill";
import React from "react";
import { hydrateRoot, createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { ApolloProvider } from "@apollo/client";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import * as Sentry from "@sentry/react";

import "assets/sass-photos/main.sass";

import store from "js/stores/reduxStore";
import { apolloClient } from "js/apollo/apollo-client";
import App from "./App";
import { preCacheCurrentUser } from "js/helpers/currentUserCache";
import "frontend/utils/SentryClient"; // init Sentry with configs

if (module.hot) {
    module.hot.accept(err => console.warn(err));
}

export const ClientApp = () => (
    <Sentry.ErrorBoundary fallback={"An error has occured in Photos Module"}>
        <Provider store={store}>
            <ApolloProvider client={apolloClient}>
                <PayPalScriptProvider
                    options={{
                        vault: true,
                        intent: "subscription",
                        "client-id": window._nounConfig.paypalClientId
                    }}
                >
                    <App />
                </PayPalScriptProvider>
            </ApolloProvider>
        </Provider>
    </Sentry.ErrorBoundary>
);

// Read the currentUser from the DOM and pre-cache it in Apollo client.
preCacheCurrentUser(apolloClient);

const exists = document.getElementById("root");
if (!exists) {
    const container = document.createElement("div");
    container.id = "root";
    document.body.appendChild(container);
    const root = createRoot(container);
    root.render(<ClientApp />);
} else {
    hydrateRoot(exists, <ClientApp />);
}
