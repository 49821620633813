/*
 * FormError is meant to (eventually) standardize how we display
 * overall form error messages that aren't specific to a particular
 * form element.
 *
 * Typically, FormError sits in between the form's title and the first
 * form element. When forms have no errors, FormError will be just
 * whitespace. When an error message is provided, that error message
 * can be inserted into that whitespace without repositioning the other
 * form elements.
 *
 * - FormError will have a total height of 40 => 36 => 32px (XL2)
 * - Error messages will be vertically centered
 */
import React from "react";
import styled, { css } from "styled-components";

import breakpoints from "core/breakpoints";
import { textSm } from "core/fontStyles";
import colors from "core/colors";
import { convertPxToRem } from "frontend/components/helpers";

const FormError = styled.div`
    min-height: ${convertPxToRem({ px: 24 })};
    color: ${colors.functional.lightMode.sangria};
    ${textSm}

    // This will vertically center the error message with a total height of 40 / 36 / 32
    margin: ${convertPxToRem({ px: 11 })} 0 ${convertPxToRem({ px: 5 })};
    @media (max-width: ${breakpoints.beforeDesktop.rem}) {
        margin: ${convertPxToRem({ px: 9 })} 0 ${convertPxToRem({ px: 3 })};
    }
    @media (max-width: ${breakpoints.beforeTablet.rem}) {
        margin: ${convertPxToRem({ px: 7 })} 0 ${convertPxToRem({ px: 1 })};
    }
`;

export default FormError;
