import styled from "styled-components";

import NotFound404 from "core/IllustrativeIcons/NotFound404";

import SectionContainer from "core/SectionContainer";
import Heading from "core/Heading";

import { spacingWithBreakpoints } from "core/spacing";

export const StyledSectionContainer = styled(SectionContainer)`
    text-align: center;
    height: 100vh;
`;

export const NotFoundImage = styled(NotFound404)`
    ${spacingWithBreakpoints.XL2Bottom}
`;

export const NotFoundMessageText = styled(Heading)`
    ${spacingWithBreakpoints.XLBottom}
`;
