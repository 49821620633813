import styled, { css } from "styled-components";

import TriggerButton from "components/OverflowMenu/TriggerButton";

import NounLogoBrandmark from "core/ReusableIcons/NounLogoBrandmark";

import Link from "core/Link";
import { Section } from "core/Layout";
import {
    mobileHeaderHeight,
    desktopHeaderHeight,
    scrollingThreshold
} from "core/SiteHeader/constants";
import { easingSeconds } from "core/animation";
import Flexbox from "core/Flexbox";
import { OldGrid, Row, Col } from "core/oldGrid";
import breakpoints from "core/breakpoints";
import colors from "core/colors";

import { convertPxToRem } from "frontend/components/helpers";
import SearchWrapper from "core/search/SearchWrapper";

export const Logo = styled(NounLogoBrandmark)`
    width: 2.375rem;
    transition: opacity ${easingSeconds};

    &:hover {
        opacity: 0.6;
    }
`;

export const HeaderLogoLink = styled.a`
    display: inline-block;

    svg {
        transition: opacity ${easingSeconds};
    }
    &:hover svg {
        opacity: 0.6;
    }

    /* from mobile to desktop breakpoint: */
    @media (min-width: ${breakpoints.zero.rem}) and (max-width: ${breakpoints
            .beforeDesktop.rem}) {
        ${({ isHeroSearchV2 }) =>
            isHeroSearchV2 &&
            css`
                height: 0.625rem; /* 10px */
                margin-top: -0.125rem; /* -2px */
            `}
        svg {
            margin-right: 1.25rem; /* 20px */
        }
    }

    /* mobile: */
    @media (min-width: ${breakpoints.zero.rem}) and (max-width: ${breakpoints
            .beforeTablet.rem}) {
        svg {
            width: 38px;
            height: 10px;
        }
    }

    /* Tablet & Desktop: */
    @media (min-width: ${breakpoints.tablet.rem}) {
        height: 23px; /* height of desktop logo */
        svg {
            width: 84px;
            height: 23px;
        }
    }
`;

// non-mobile content
export const NonMobileTopRow = styled(Row)`
    @media (min-width: ${breakpoints.beforeTablet.rem}) {
        position: relative;
    }
`;

export const MainLinksCol = styled(Col)`
    @media (min-width: ${breakpoints.tablet.rem}) {
        transition: all ${easingSeconds};
        position: relative;
        top: 0px;
        opacity: 1;

        ${({ animateElements }) => {
            if (animateElements) {
                return css`
                    top: -15px;
                    opacity: 0;
                `;
            }
        }}
    }
`;

export const ProfileMenuCol = styled(Col)`
    display: flex;
    justify-content: flex-end;
`;
export const ProfileMenuPinnedStyled = styled.div``;

export const SearchContainerRow = styled(Row)`
    transition: margin ${easingSeconds};

    /* prettier-ignore */
    ${Col}:first-of-type {
        height: 23px; /* height of desktop logo */
    }

    /* prettier-ignore */
    ${Col}:nth-child(2) {
        min-height: 42px;
    }
`;

export const TextLinkGrid = styled(OldGrid)`
    overflow-x: visible;
`;

export const MainLinks = styled(Flexbox)`
    ${Link} {
        white-space: nowrap;

        &:not(:last-of-type) {
            margin-right: ${convertPxToRem({ px: 16 })};
        }
    }
`;

export const NonMobileContentWrapper = styled.div`
    ${NonMobileTopRow} {
        margin-bottom: 1.625rem;
        height: 24px;
    }

    ${Logo} {
        width: 5.25rem;
    }

    ${SearchWrapper} {
        width: 100%;
    }

    @media (max-width: ${breakpoints.beforeTablet.rem}) {
        display: none;
    }
`;

// mobile content
export const LogoAndSearch = styled(Flexbox)`
    ${Logo} {
        margin-right: 1.25rem;
    }

    ${SearchWrapper} {
        flex: 1;
    }
`;

export const MenuAndAvatar = styled(Flexbox)`
    ${TriggerButton} {
        ${({ loggedOut }) =>
            loggedOut &&
            css`
                /* Mobile-only: */
                @media (max-width: ${breakpoints.beforeTablet.rem}) {
                    margin-left: 1rem; /* 16px */
                    margin-right: 0;
                }
            `}
    }

    /* up to Tablet size: */
    @media (max-width: ${breakpoints.beforeTablet.rem}) {
        margin-top: -2px;
    }
`;

export const HeaderRow = styled(Row)``;

export const MobileContentWrapper = styled(OldGrid)`
    display: none;

    @media (max-width: ${breakpoints.beforeTablet.rem}) {
        display: block;
        overflow-x: visible;

        ${HeaderRow} {
            height: 2.313rem; /* 37px - prevents jumping of header when scrolling, especially in terms of hero to header search input */
        }
    }
`;

/*
    Site Header Placeholder
    This wrapper element preserves the height of the header
    when positioned fixed removes from DOM flow
    User never sees it, as it sits behind the header
    and above the page content
*/
export const SiteHeaderPlaceholderStyled = styled.div`
    height: ${mobileHeaderHeight}px;
    @media (min-width: ${breakpoints.tablet.rem}) {
        height: ${desktopHeaderHeight}px;
    }
`;

// site header
export const StyledSiteHeader = styled(Section)`
    height: ${mobileHeaderHeight}px;
    @media (min-width: ${breakpoints.tablet.rem}) {
        height: ${desktopHeaderHeight}px;
    }

    position: fixed;
    z-index: 9999;
    transition: background-color ${easingSeconds};

    /* Mobile: */
    padding-top: 0.625rem; /* 10px */
    padding-bottom: 0.625rem; /* 10px */
    /* end mobile styles */

    ${({ transparent }) =>
        transparent &&
        css`
            background-color: transparent;
            border-bottom: 0;
        `}

    ${({ heroSearchBgColor }) =>
        `background-color: ${
            heroSearchBgColor ? heroSearchBgColor : colors.primary.snow
        }; border-bottom: 0;`};


    ${({ transparent }) =>
        !transparent &&
        css`
            background-color: ${colors.primary.snow};
            border-bottom: 1px solid #d8d8d8;
        `}


    /* for new hero search/navbar combo */
    ${({ isHeroSearchV2, heroSearchBgColor, isScrolledDown }) =>
        isHeroSearchV2 &&
        css`
            background-color: ${heroSearchBgColor && !isScrolledDown
                ? heroSearchBgColor
                : colors.primary.snow};
            border-bottom: 0;
        `}

    /* Tablet & Desktop */
    @media (min-width: ${breakpoints.tablet.rem}) {
        padding-top: 2rem;
        padding-bottom: 2rem;

        position: relative;

        ${({ isScrolledDown }) => {
            if (isScrolledDown) {
                return css`
                    position: fixed;
                    left: 0px;
                    right: 0px;
                    top: -${scrollingThreshold}px;
                `;
            }
        }}

        ${ProfileMenuPinnedStyled} {
            transition: top ${easingSeconds};
            position: fixed;
            z-index: 3;
            top: ${({ animateElements }) =>
                animateElements ? `39px` : `30px`};
        }
    }

    ${({ isHeroSearchV2 }) =>
        isHeroSearchV2 &&
        css`
            ${({ isScrolledDown }) => {
                if (isScrolledDown) {
                    /* changes to header UI when scrolling down the page: */
                    return css`
                        /* SiteHeader itself: */
                        border-bottom: 1px solid #d8d8d8;

                        /* overflow menu: */
                        ${TriggerButton} {
                            path {
                                fill: ${colors.secondary.oolong};
                            }

                            &:hover {
                                background-color: ${colors.primary.scheifer};

                                path {
                                    fill: ${colors.secondary.orca};
                                }
                            }
                        }

                        /* NP Logo: */
                        a svg path {
                            fill: ${colors.secondary.onyx};
                        }
                    `;
                } else {
                    /* changes to header UI when scrolling up the page: */
                    return css`
                        /* SiteHeader itself: */
                        border-bottom: 0;

                        /* NP logo */
                        a svg path {
                            fill: ${colors.primary.snow};
                        }
                    `;
                }
            }}

            ${SearchWrapper} {
                display: ${({ animateSearch }) =>
                    animateSearch ? "block" : "none"};
            }
        `}
`;
