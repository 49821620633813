import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// styles
import { SkeletonBox } from "./styles";

let Skeleton = ({
    width,
    height,
    isCircle = false,
    animationSpeedMs = 2,
    className
}) => {
    return (
        <SkeletonBox
            className={className}
            width={width}
            height={height}
            isCircle={isCircle}
            animationSpeedMs={animationSpeedMs}
        />
    );
};

Skeleton.propTypes = {
    width: PropTypes.string.isRequired,
    height: PropTypes.string.isRequired,
    isCircle: PropTypes.bool,
    animationSpeedMs: PropTypes.number,
    className: PropTypes.string
};

Skeleton = styled(Skeleton)``;

export default Skeleton;
