import React from "react";
import { convertPxToRem } from "frontend/components/helpers";

import { appsAndPluginsPath, NOUN_PRO_EDU_APPLICATION } from "data/links";
import { MILLIONS_OF_ICONS } from "core/constants";

import BlogImageOne from "./images/blogPostOne.png";
import BlogImageTwo from "./images/blogPostTwo.png";
import BlogImageThree from "./images/blogPostThree.png";

import Icon from "core/ReusableIcons/Icon";
import Eye from "core/ReusableIcons/Eye";
import Computer from "core/ReusableIcons/Computer";
import Link from "core/Link";

/* -------------------------------------------------------------------------- */
/*                                   CONSTANTS                                  */
/* -------------------------------------------------------------------------- */

export const SHOW_DESKTOP_TABLE_MIN_SCREEN_WIDTH = "60rem";
export const BEFORE_SHOW_DESKTOP_TABLE_MIN_SCREEN_WIDTH = "59.9375rem";

/* -------------------------------------------------------------------------- */
/*                           ICON PLANS AND PRICING                           */
/* -------------------------------------------------------------------------- */

export const INDIVIDUAL_SUBSCRIPTION = "INDIVIDUAL_SUBSCRIPTION";
export const TEAM_SUBSCRIPTION = "TEAM_SUBSCRIPTION";

/* ---------------------------- TABLET AND MOBILE --------------------------- */

export const iconsPricingBasicTable = [
    [`More than ${MILLIONS_OF_ICONS} million icons`, true],
    ["PNG and SVG formats", true],
    ["Use without attribution", false],
    ["Edit color, background, shape", false],
    ["Unlimited icon licenses", false],
    ["Adobe, Mac, Office, Google apps", false],
    ["Multiple custom favorites boards", false],
    ["Ad-Free site", false],
    ["Share icons across team", false],
    ["Consolidated billing", false]
];

export const iconsPricingProDownloadTable = [
    [`More than ${MILLIONS_OF_ICONS} million icons`, true],
    ["PNG and SVG formats", true],
    ["Use without attribution", true],
    ["Edit color, background, shape", false],
    ["Unlimited icon licenses", false],
    ["Adobe, Mac, Office, Google apps", false],
    ["Multiple custom favorites boards", false],
    ["Ad-Free site", false],
    ["Share icons across team", false],
    ["Consolidated billing", false]
];

export const iconsPricingIndividualSubTable = [
    [`More than ${MILLIONS_OF_ICONS} million icons`, true],
    ["PNG and SVG formats", true],
    ["Use without attribution", true],
    ["Edit color, background, shape", true],
    ["Unlimited icon licenses", true],
    ["Adobe, Mac, Office, Google apps", true],
    ["Multiple custom favorites boards", true],
    ["Ad-Free site", true],
    ["Share icons across team", false],
    ["Consolidated billing", false]
];

export const iconsPricingTeamSubTable = [
    [`More than ${MILLIONS_OF_ICONS} million icons`, true],
    ["PNG and SVG formats", true],
    ["Use without attribution", true],
    ["Edit color, background, shape", true],
    ["Unlimited icon licenses", true],
    ["Adobe, Mac, Office, Google apps", true],
    ["Multiple custom favorites boards", true],
    ["Ad-Free site", true],
    ["Share icons across team", true],
    ["Consolidated billing", true]
];

/* --------------------------------- DESKTOP -------------------------------- */

export const iconsPricingTableHeadingIds = {
    basicIconDownload: "basic-icon-download",
    proIconDownload: "pro-icon-download",
    nounProIndividual: "noun-pro-individual",
    nounProTeam: "noun-pro-team",
    threeMill: "icons-three-mill",
    format: "icons-format",
    withouAttr: "icons-without-attr",
    edit: "icons-edit",
    unlimited: "icons-unlimited",
    apps: "icons-apps",
    favorites: "icons-favorites",
    adFree: "icons-add-free",
    shareTeam: "icons-share-team",
    consolBilling: "icons-consol-billing"
};

// meta
export const metaTitle = "Icons for Education | Noun Project";
export const metaDescription =
    "Browse millions of icons for education. Educators, students and edu staff get all the benefits of Icon Pro at half the cost.";

// Section 1: Hero
export const heroTitle = "Supercharge Learning with Icon Pro for EDU";

export const heroSubtitle =
    "Educators, students, and edu staff get unlimited access to the world's most diverse icon collection for half the price. Millions of professionally curated icons, just $19.99/year (regular price $39.99).";

export const heroButton = [
    {
        text: "Get Started",
        url: NOUN_PRO_EDU_APPLICATION,
        darkMode: true
    }
];

// Section 2: Bullet Section
export const sectionHeading = "Icon Pro for EDU Icon Subscription";

export const bullets = [
    {
        icon: <Icon size="xl" />,
        title: "Millions of Icons",
        text:
            "Download unlimited PNG & SVG icons royalty-free, no need to attribute"
    },
    {
        icon: <Computer size="xl" />,
        title: "Create Powerful Presentations",
        text:
            "Easily bring icons into your presentations, templates, and more with our apps and plugins"
    },
    {
        icon: <Eye size="xl" />,
        title: "Unlimited Customization",
        text:
            "Customize any icon’s color, size, and rotation, plus add a background shape and color"
    }
];

// Section 3: Collection
export const collectionTitle =
    "Get Creative in the Classroom with These Free Templates";

export const collectionText = [
    "Download ready-to-use templates for worksheets, flashcards, and more from Noun Project."
];

export const collectionLink = [
    {
        text: "Download Now",
        href:
            "https://blog.thenounproject.com/free-resources-for-teachers-a6a1e32351e6?source=friends_link&sk=9d96d3b6b37f983d4d48613a5c4cfee3",
        target: "_blank"
    }
];

// Section 4: Testimonial
const testimonialText = (
    <>
        <p>
            "I am a volunteer in a transitional classroom for developmentally
            and intellectually disabled students with high needs and Noun
            Project has been instrumental. Noun Project’s{" "}
            <a
                href="https://thankful.thenounproject.com/"
                style={{ paddingBottom: convertPxToRem({ px: 1 }) }}
            >
                Thankful Bot
            </a>{" "}
            allowed us to create the first Thanksgiving wall that every student,
            regardless of ability, could fully understand and contribute to."
        </p>
    </>
);

export const testimonial = [
    {
        text: testimonialText
    }
];

// Section 5: Blog Previews
export const blogPostsTitle = "Educator Resources";

export const blogPostData = [
    {
        title: "How to Design an Effective Infographic with Icons",
        category: "Tutorial",
        imgSrc: BlogImageOne,
        url:
            "https://blog.thenounproject.com/how-to-design-an-effective-infographic-with-icons-f6f9723d66dc?source=friends_link&sk=910d082d6c8fa741b623b54fc72f85d6",
        target: "_blank",
        imageIsLink: true,
        headingIsLink: true,
        subtitleIsLink: false
    },
    {
        title: "How EdTech Expert Matt Miller Uses Icons for Education",
        category: "Interview",
        imgSrc: BlogImageTwo,
        url:
            "https://blog.thenounproject.com/how-edtech-expert-matt-miller-uses-icons-for-education/",
        target: "_blank",
        imageIsLink: true,
        headingIsLink: true,
        subtitleIsLink: false
    },
    {
        title: "Alphabet Coloring Book Tutorial",
        category: "Tutorial",
        imgSrc: BlogImageThree,
        url: "https://blog.thenounproject.com/tutorial-alphabet-coloring-book/",
        target: "_blank",
        imageIsLink: true,
        headingIsLink: true,
        subtitleIsLink: false
    }
];

// Section 7
export const faqTitle = "Common Questions";

const pluginLink = (
    <>
        The entire Noun Project icon collection can be accessed directly in your
        workflow via our{" "}
        <Link href={appsAndPluginsPath} target="_blank">
            Mac App
        </Link>{" "}
        and Adobe, Google, and Microsoft{" "}
        <Link href={appsAndPluginsPath} target="_blank">
            plugins.
        </Link>{" "}
    </>
);

export const faqValues = [
    {
        title: "Is attribution required for icons downloaded with Icon Pro?",
        text:
            "Noun Project pays royalties to creators around the world. All icons downloaded with an Icon Pro subscription are royalty-free and attribution is not required."
    },
    {
        title: "What customization options are included with a subscription?",
        text:
            "With Icon Pro, you can change any icon’s color, size, and rotation, plus add a background shape and color."
    },
    {
        title: "Can I use a downloaded icon multiple times?",
        text:
            "Each icon downloaded with a subscription can be used multiple times as long as it’s used for the same project and/or client."
    },
    {
        title: "What file types are included with Icon Pro?",
        text:
            "Subscribers have access to icon SVG and PNG files, which can be customized to fit your needs. Photos are not included in icon subscriptions."
    },
    {
        title: "What apps and plugins am I able to access?",
        text: pluginLink
    },
    {
        title: "How can I cancel or change my subscription?",
        text:
            "You can cancel your subscription at any time in your account settings. To upgrade from a monthly subscription to an annual subscription, please email info@thenounproject.com."
    }
];
