import { useApolloMutation } from "frontend/utils/apolloApi/useApolloMutation.js";
import { useDispatch } from "react-redux";

// rtk
import { uploadFooterActionsSlice } from "frontend/components/fort/mod/photos/slices/uploadFooterActionsSlice/index.js";

// gql
import { SET_EXPLICIT_STATUS_ON_UPLOADS } from "frontend/components/fort/mod/photos/gql/mutations";

// utils
import { useApiUploadUpdate } from "frontend/components/fort/mod/photos/hooks/useApiUploadUpdate.js";

export const useClearExplicitFlag = () => {
    const dispatch = useDispatch();
    const { apiUploadUpdate } = useApiUploadUpdate();

    const { apolloMutate } = useApolloMutation({
        actionDesc: "Uploads: Flags: Explicit",
        gql: SET_EXPLICIT_STATUS_ON_UPLOADS
    });

    const clearExplicitFlag = async photoUploadIds => {
        const { ssResp } = await apolloMutate({
            variables: {
                photoUploadIds,
                value: false
            }
        });
        // unblur photos
        if (ssResp?.data?.setExplicitStatusOnUploads?.ok) {
            photoUploadIds.forEach(id => {
                dispatch(uploadFooterActionsSlice.actions.unblurUpload(id));
            });
        }

        apiUploadUpdate(ssResp);
    };

    return {
        clearExplicitFlag
    };
};
