import React, { useLayoutEffect } from "react";

import { cancelSocialAuth } from "frontend/hooks/useAuth";

const FacebookCancelledPage = () => {
    // notify parent window & close this window
    useLayoutEffect(() => cancelSocialAuth(), []);
    return null;
};

export default FacebookCancelledPage;
