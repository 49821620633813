import React from "react";
import PropTypes from "prop-types";

// QueueContext
import { useQueueContext } from "frontend/components/fort/mod/core/Layouts/QueueLayout";

// components
import ApproveButtonBelow from "frontend/components/core/InlineTextEdit/variations/ApproveButtonBelow/index.js";

// component
export const Description = ({
    onSaveDescription,
    onToggleApproveDescription
}) => {
    const {
        uploadType,
        selectedBatchObj,
        state: { singleUploadSelected }
    } = useQueueContext();

    if (!singleUploadSelected) {
        return null;
    }

    // Upload
    const { id } = singleUploadSelected;

    const description =
        uploadType === "photo"
            ? singleUploadSelected.photoDescription
            : singleUploadSelected.iconDescription;

    const descriptionApproved =
        uploadType === "photo"
            ? singleUploadSelected.photoDescriptionApproved
            : singleUploadSelected.iconDescriptionApproved;

    const inlineTextHandlers = {
        id: `photo-description-${id}`,
        text: description,
        onSave: onSaveDescription,
        isApproved: !!descriptionApproved,
        onToggleApprove: onToggleApproveDescription
    };

    return (
        <ApproveButtonBelow
            inlineTextHandlers={inlineTextHandlers}
            ariaLabel="Photo Description"
            placeholder="Add Description"
            isDisabled={!selectedBatchObj?.isEditable?.uploadDesc}
        />
    );
};

export default Description;

// prop-types
Description.propTypes = {
    onSaveDescription: PropTypes.func.isRequired,
    onToggleApproveDescription: PropTypes.func.isRequired
};
