import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

// data
import { QUEUES } from "../../constants";

// components
import PhotoModQueuePageContent from "../../components/PhotoModQueuePageContent";

const SuspendedQueuePage = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route path={[path, `${path}/:filter`]}>
                <PhotoModQueuePageContent currentQueue={QUEUES.SUSPENDED} />
            </Route>
        </Switch>
    );
};

export default SuspendedQueuePage;
