import styled, { css } from "styled-components";

import Checkmark from "core/ReusableIcons/Checkmark";

import ProcessingSpinner from "core/ProcessingSpinner";

import colors from "core/colors";
import { focusOutline } from "core/links";
import { easingSeconds } from "core/animation";
import { regular as regularFontFamily } from "core/fontStyles";

import { convertPxToRem } from "frontend/components/helpers";

const INDETERMINATE_STYLES = css`
    background-color: ${colors.accent.lightMode.pansy};
    border-color: transparent;

    svg {
        width: ${convertPxToRem({ px: 9.95 })};
        height: ${convertPxToRem({ px: 9.95 })};
    }
`;

const SIZE_STYLES = {
    xs: css`
        font-size: ${convertPxToRem({ px: 12 })};
        border-width: ${convertPxToRem({ px: 1 })};
        line-height: ${convertPxToRem({ px: 12 })};
        padding: 0 ${convertPxToRem({ px: 10 })};
        height: ${convertPxToRem({ px: 24 })};
    `,
    sm: css`
        font-size: ${convertPxToRem({ px: 13 })};
        border-width: ${convertPxToRem({ px: 1 })};
        line-height: ${convertPxToRem({ px: 14 })};
        padding: 0 ${convertPxToRem({ px: 10 })};
        height: ${convertPxToRem({ px: 28 })};
    `,
    md: css`
        font-size: ${convertPxToRem({ px: 13 })};
        border-width: ${convertPxToRem({ px: 1 })};
        line-height: ${convertPxToRem({ px: 14 })};
        padding: 0 ${convertPxToRem({ px: 13 })};
        height: ${convertPxToRem({ px: 40 })};
    `,
    lg: css`
        font-size: ${convertPxToRem({ px: 15 })};
        border-width: ${convertPxToRem({ px: 2 })};
        line-height: ${convertPxToRem({ px: 14 })};
        padding: 0 ${convertPxToRem({ px: 16 })};
        height: ${convertPxToRem({ px: 48 })};
    `
};

export const CheckboxContainer = styled.div`
    transition: all ${easingSeconds};
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${convertPxToRem({ px: 14 })};
    height: ${convertPxToRem({ px: 14 })};
    line-height: ${convertPxToRem({ px: 14 })};
    border-radius: ${convertPxToRem({ px: 2 })};
    margin-right: ${convertPxToRem({ px: 8 })};
    border-width: ${convertPxToRem({ px: 1 })};
    border-style: solid;
    background-color: transparent;

    ${({ isChecked }) =>
        isChecked &&
        css`
            background-color: ${colors.accent.lightMode.pansy};
            border-color: transparent;
        `}
`;

const DEFAULT_LIGHTMODE_HOVER = css`
    background-color: ${colors.primary.moon};
    border-color: ${colors.accent.darkMode.suenosBuenos};
    color: ${colors.secondary.orca};

    ${CheckboxContainer} {
        border-color: ${colors.secondary.manatee};
    }
`;

const DEFAULT_DARKMODE_HOVER = css`
    background-color: ${colors.secondary.basalt};
    border-color: ${colors.secondary.slate};
    color: ${colors.primary.diamond};

    ${CheckboxContainer} {
        border-color: ${colors.secondary.manatee};
    }
`;

const LABEL_STATE_STYLES = {
    lightMode: {
        default: css`
            background-color: ${colors.primary.snow};
            border-color: ${colors.primary.scheifer};
            color: ${colors.secondary.orca};

            ${CheckboxContainer} {
                border-color: ${colors.secondary.manatee};
            }

            &:hover {
                ${DEFAULT_LIGHTMODE_HOVER}
            }

            &:active {
                background-color: ${colors.primary.whisker};
                border-color: transparent;
                color: ${colors.secondary.orca};
            }
        `,
        processing: css`
            pointer-events: none;
            background-color: ${colors.primary.whisker};
            border-color: transparent;
            color: ${colors.secondary.orca};

            ${CheckboxContainer} {
                border-color: ${colors.secondary.manatee};
            }

            ${ProcessingSpinner} {
                margin-left: ${convertPxToRem({ px: 8 })};
                svg path {
                    fill: ${colors.secondary.orca};
                }
            }

            &:hover {
                pointer-events: none;
                background-color: ${colors.primary.whisker};
                border-color: transparent;
                color: ${colors.secondary.orca};

                ${CheckboxContainer} {
                    border-color: ${colors.secondary.manatee};
                }

                ${ProcessingSpinner} {
                    margin-left: ${convertPxToRem({ px: 8 })};
                    svg path {
                        fill: ${colors.secondary.orca};
                    }
                }
            }

            &:active {
                background-color: ${colors.primary.whisker};
                border-color: transparent;
                color: ${colors.secondary.orca};
            }
        `,
        error: css`
            background-color: ${colors.primary.snow};
            border-color: ${colors.functional.lightMode.sangria};
            color: ${colors.functional.lightMode.sangria};

            ${CheckboxContainer} {
                border-color: ${colors.functional.lightMode.sangria};
            }

            &:hover {
                ${DEFAULT_LIGHTMODE_HOVER}
            }

            &:active {
                background-color: ${colors.primary.whisker};
                border-color: transparent;
                color: ${colors.secondary.orca};
            }
        `
    },
    darkMode: {
        default: css`
            background-color: ${colors.secondary.orca};
            border-color: ${colors.secondary.basalt};
            color: ${colors.primary.diamond};

            ${CheckboxContainer} {
                border-color: ${colors.secondary.slate};
            }

            &:hover {
                ${DEFAULT_DARKMODE_HOVER}
            }

            &:active {
                background-color: ${colors.secondary.vampire};
                border-color: transparent;
                color: ${colors.primary.diamond};
            }
        `,
        processing: css`
            &&,
            &:active {
                pointer-events: none;
                background-color: ${colors.secondary.vampire};
                border-color: transparent;
                color: ${colors.primary.diamond};

                ${CheckboxContainer} {
                    border-color: ${colors.secondary.slate};
                }

                ${ProcessingSpinner} {
                    margin-left: ${convertPxToRem({ px: 8 })};
                }

                &:hover {
                    pointer-events: none;
                    background-color: ${colors.secondary.vampire};
                    border-color: transparent;
                    color: ${colors.primary.diamond};

                    ${CheckboxContainer} {
                        border-color: ${colors.secondary.slate};
                    }

                    ${ProcessingSpinner} {
                        margin-left: ${convertPxToRem({ px: 8 })};
                    }
                }
            }
        `,
        error: css`
            background-color: ${colors.secondary.orca};
            border-color: ${colors.functional.darkMode.desertBloom};
            color: ${colors.functional.darkMode.desertBloom};

            ${CheckboxContainer} {
                border-color: ${colors.functional.darkMode.desertBloom};
            }

            &:hover {
                ${DEFAULT_DARKMODE_HOVER}
            }

            &:active {
                background-color: ${colors.secondary.vampire};
                border-color: transparent;
                color: ${colors.primary.diamond};
            }
        `
    }
};

export const CheckboxButtonContainer = styled.div`
    display: inline-block;
    ${({ width }) =>
        css`
            width: ${width};
        `}
`;

export const Label = styled.label`
    ${regularFontFamily}
    display: flex;
    align-items: center;
    cursor: pointer; // because it appears as button, give it button cursor
    transition: all ${easingSeconds};
    border-style: solid;
    border-radius: ${convertPxToRem({ px: 4 })};

    ${({ size }) => SIZE_STYLES[size]}
    ${({ styleType, darkMode }) =>
        darkMode
            ? LABEL_STATE_STYLES.darkMode[styleType]
            : LABEL_STATE_STYLES.lightMode[styleType]}

    ${({ isChecked }) =>
        isChecked &&
        css`
            ${CheckboxContainer} {
                border-color: transparent;
            }
        `}

    ${({ isDisabled }) =>
        isDisabled &&
        css`
            cursor: not-allowed;
            pointer-events: none;
            background-color: ${colors.accent.darkMode.suenosBuenos};
            border-color: transparent;
            color: ${colors.secondary.orca};

            ${CheckboxContainer} {
                border-color: ${colors.secondary.orca};
            }

            &:hover {
                cursor: not-allowed;
                background-color: ${colors.accent.darkMode.suenosBuenos};
                border-color: transparent;
                color: ${colors.secondary.orca};

                ${CheckboxContainer} {
                    border-color: ${colors.secondary.orca};
                }
            }
        `}

    // sets style when some are selected, but not ALL (in the case of using it as a select all button):
    ${({ isIndeterminate }) =>
        isIndeterminate &&
        css`
            ${CheckboxContainer} {
                ${INDETERMINATE_STYLES}
            }
        `}
`;

export const LabelTextContainer = styled.span`
    display: inline-block;
    user-select: none;
`;

export const Input = styled.input`
    position: absolute;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);

    ${({ isProcessing, isDisabled }) =>
        (isDisabled || isProcessing) &&
        css`
            pointer-events: none;
        `}

    // sets style when some are selected, but not ALL (in the case of using it as a select all button):
    &:indeterminate + ${Label} {
        ${CheckboxContainer} {
            ${INDETERMINATE_STYLES}
        }
    }

    // only show focus on keyboard not with mouse:
    &:focus:not(.focus-visible) ~ ${Label} {
        outline: none;
        box-shadow: none;
    }

    &.focus-visible ~ ${Label} {
        ${focusOutline}

        ${({ styleType, darkMode, isDisabled }) =>
            (styleType === "default" || styleType === "error") &&
            !isDisabled &&
            (darkMode ? DEFAULT_DARKMODE_HOVER : DEFAULT_LIGHTMODE_HOVER)}
    }
`;
