import { useEffect } from "react";
import PropTypes from "prop-types";

const SegmentedControlResults = ({
    firstBatchFetched,
    loadFirstBatchLazy,
    isActive,
    children
}) => {
    useEffect(() => {
        if (isActive && !firstBatchFetched) loadFirstBatchLazy();
    }, [isActive]);

    return isActive ? children : null;
};

SegmentedControlResults.propTypes = {
    isActive: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    firstBatchFetched: PropTypes.bool.isRequired,
    loadFirstBatchLazy: PropTypes.func.isRequired
};

export default SegmentedControlResults;
