import React, { useState, useEffect } from "react";

import { UploadsSectionContainer as StyledUploadsSectionContainer } from "../PhotoUpload/styles";

import { dropZoneFileErrors } from "../PhotoUpload/dropZoneFileErrors.js";
import usePhotoUploadDropzone from "./hooks/usePhotoUploadDropzone.js";

// MessageStack Context
import { useMessageStack } from "core/MessageStack/useMessageStack";

import { desktopHeaderHeight } from "core/SiteHeader/constants";

const UploadsSectionContainer = ({ children }) => {
    const { dropzoneProps } = usePhotoUploadDropzone();
    const [hasHeaderHeight, updateHeaderHeight] = useState(desktopHeaderHeight);
    const siteHeader = document.getElementById("site-header");
    const { rejectedFilesCount } = dropzoneProps;

    const { addMessages } = useMessageStack();

    const handleScroll = () => {
        updateHeaderHeight(siteHeader.offsetHeight);
    };

    // grab header height to pass to sticky footer:
    useEffect(() => {
        if (siteHeader) {
            handleScroll();

            window.addEventListener("scroll", handleScroll);

            return () => {
                window.removeEventListener("scroll", handleScroll);
            };
        }
    }, [updateHeaderHeight, siteHeader, handleScroll]);

    useEffect(() => {
        dropZoneFileErrors({
            ...dropzoneProps,
            addMessagesToMessageStack: addMessages
        });
    }, [rejectedFilesCount]);

    return (
        <StyledUploadsSectionContainer
            topSpacing="none"
            bottomSpacing="none"
            hasHeaderHeight={hasHeaderHeight}
        >
            {children}
        </StyledUploadsSectionContainer>
    );
};

export default UploadsSectionContainer;
