/**
 * augmentUploadsWithTagObjects:normalized tag objects for frontend
 * Combines Photo Upload Tags from backend [userTags, approvedUserTags, warningUserTags]
 * into a single array of objects called modTags
 *
 * @param {Array} uploads array of photo uploads
 * @returns {Array} of objects that includes new modTags {Array} prop of tagObjects
 *
 * Note: The `userTags`, `autoTags`, `approvedUserTags`, and `warningUserTags` buckets returned from backend are all arrays of string names
 * tags can show up in mutluple buckets
 * FE: needs to
 *  - convert to tag objects where name prop represents original string name (e.g. { id, name: "value", hasWarning, isAuto, isApproved})
 *  - remove duplicates
 */
export const augmentUploadsWithTagObjects = (uploads = []) => {
    let enhancedUploads = uploads.map(upload => {
        const { userTags, approvedUserTags, warningUserTags } = upload;

        const allSsTags = [
            ...userTags,
            ...approvedUserTags,
            ...warningUserTags
        ];

        // remove dupes and sort alphabetically
        const ssTags = allSsTags
            .filter((item, index) => allSsTags.indexOf(item) === index)
            .toSorted((a, b) => a.localeCompare(b));

        return {
            ...upload,
            manageTags: ssTags.map((ssTagString, idx) => {
                const isApproved = !!approvedUserTags.find(
                    aut => aut === ssTagString
                );
                const hasWarning = !!warningUserTags.find(
                    aut => aut === ssTagString
                );

                return {
                    id: ssTagString, // by using tag string name, which should always be unique, React should be able to diff better on Approve/Reject groups.
                    name: ssTagString,
                    isApproved,
                    hasWarning
                };
            })
        };
    });

    return enhancedUploads;
};
