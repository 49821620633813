import React, {
    createContext,
    useState,
    useContext,
    useMemo,
    useCallback
} from "react";

import { useSelect } from "frontend/components/core/Grid2/GridItemContentTemplates/Select/SelectContext/index.js";
import { usePhotoUpload } from "frontend/hooks/usePhotoUpload";

export const Context = createContext();

export const EditPhotoUploadProvider = ({ children }) => {
    // upload ID if editing single upload
    const [singleEditUploadId, setSingleEditUploadId] = useState(null);
    const { uploadsSelected, getSelectedUploadIds } = useSelect();
    const { editableUploads } = usePhotoUpload();
    // all selected uploads - batch edit these if singleEditUploadId is null
    const selectedUploadIds = getSelectedUploadIds(uploadsSelected);
    const isBatchEditing = singleEditUploadId === null;
    const uploadIds = editableUploads.map(upload => upload.id);

    const [editModalIsOpen, setEditIsModalOpen] = useState(false);

    const openEditModal = useCallback(
        (id = null) => {
            if (!isNaN(id)) {
                setSingleEditUploadId(id);
            } else if (selectedUploadIds.length === 1) {
                setSingleEditUploadId(selectedUploadIds[0]);
            } else {
                setSingleEditUploadId(null);
            }
            setEditIsModalOpen(true);
        },
        [setEditIsModalOpen, setSingleEditUploadId, selectedUploadIds]
    );

    const closeEditModal = useCallback(() => {
        setEditIsModalOpen(false);
        setSingleEditUploadId(null);
    }, [setEditIsModalOpen, setSingleEditUploadId]);

    const setSingleEditByIndex = useCallback(
        index => {
            setSingleEditUploadId(uploadIds[index]);
        },
        [setSingleEditUploadId, uploadIds]
    );

    const contextValues = {
        isBatchEditing,
        uploadIds: singleEditUploadId
            ? [singleEditUploadId]
            : selectedUploadIds || [],
        editModalIsOpen,
        uploads: singleEditUploadId
            ? editableUploads.filter(u => u.id === singleEditUploadId)
            : editableUploads.filter(u => selectedUploadIds.includes(u.id)),
        setSingleEditUploadId,
        setSingleEditByIndex,
        singleEditUploadId,
        singleEditUploadIndex: singleEditUploadId
            ? uploadIds.indexOf(singleEditUploadId)
            : null,
        uploadCount: uploadIds.length,
        openEditModal,
        closeEditModal
    };

    // useMemo so it does not pass value on every render
    const value = useMemo(() => contextValues, [contextValues]);

    return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useEditPhotoUpload = () => {
    const context = useContext(Context);

    if (context === undefined) {
        throw new Error(
            "useEditPhotoUpload must be used within an EditPhotoUploadProvider"
        );
    }

    return context;
};
