import styled, { css } from "styled-components";

import Link from "core/Link";
import colors from "core/colors";
import breakpoints from "core/breakpoints";
import BodyText from "core/BodyText";

import { focusOutline } from "core/links";

import { convertPxToRem } from "frontend/components/helpers";

export const TableContainer = styled.div`
    ${({ maxTableHeight }) =>
        maxTableHeight &&
        css`
            height: ${maxTableHeight};
            overflow: scroll;
        `}
`;

export const Table = styled.table`
    width: 100%;
    margin: 0 auto;
    overflow: scroll;

    @media (max-width: ${breakpoints.beforeTablet.rem}) {
        display: block;
    }
`;

export const HeaderBodyText = styled(BodyText).attrs({
    size: "sm",
    bold: true,
    type: "div"
})`
    margin-left: ${convertPxToRem({ px: 8 })};
    margin-top: ${convertPxToRem({ px: 16 })};
    margin-bottom: ${convertPxToRem({ px: 16 })};
`;

export const HeaderElement = styled.div`
    margin-left: ${convertPxToRem({ px: 8 })};
    margin-top: ${convertPxToRem({ px: 16 })};
    margin-bottom: ${convertPxToRem({ px: 16 })};
`;

export const BodyTableText = styled(BodyText).attrs({
    size: "md",
    type: "div"
})`
    margin: ${convertPxToRem({ px: 8 })};
`;

export const ColumnHeading = styled.th`
    margin: 0;
    text-align: left;

    // Mobile
    @media (max-width: ${breakpoints.beforeTablet.rem}) {
        min-width: ${convertPxToRem({ px: 115 })};
        height: ${convertPxToRem({ px: 51 })};
    }
`;

export const TableBody = styled.tbody`
    tr {
        &:nth-child(even) {
            background-color: ${colors.primary.diamond};
        }
    }
`;

export const LinkContainer = styled.span`
    ${Link} {
        margin: ${convertPxToRem({ px: 8 })};
        vertical-align: middle;
    }
`;

export const TableData = styled.td`
    height: ${convertPxToRem({ px: 40 })};
    margin: 0;

    &:focus:not(.focus-visible) {
        outline: none;
        box-shadow: none;
        border: none;
    }

    &:focus {
        ${focusOutline}
    }

    &:first-child {
        vertical-align: middle;
    }
`;

export const TableDataAsHead = styled.th`
    height: ${convertPxToRem({ px: 40 })};
    margin: 0;

    &:focus:not(.focus-visible) {
        outline: none;
        box-shadow: none;
        border: none;
    }

    &:focus {
        ${focusOutline}
    }
`;

export const AlignCells = styled.div`
    vertical-align: middle;
`;
