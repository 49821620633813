import React from "react";
import { useRouter } from "next/router";

import SearchAndBrowseIconGridContainer from "icons/SearchAndBrowseIconGridContainer";
import SegmentedControlResults from "core/SegmentedControlResults";
import SearchEmptyResults from "core/SearchEmptyResults";
import SearchHeader from "icons/SearchHeader";
import { buildStyleFilters } from "ssr/helpers";

import useLoadMore from "frontend/hooks/useLoadMore";
import { CONTENT_TYPE } from "core/constants";

import { GET_ICON_SEARCH_DATA } from "./queries";

const SearchIconsResults = ({ isActive, query, searchType }) => {
    const router = useRouter();
    const { style, weight } = router.query;
    const styleFilters = buildStyleFilters({ style, weight });

    const {
        items,
        totalCount,
        firstBatchFetched,
        loadFirstBatchLazy,
        loadingFirstBatch,
        loading,
        LoadMore,
        loadingItemCount
    } = useLoadMore({
        limit: 200,
        query: GET_ICON_SEARCH_DATA,
        queryVariables: { query, styleFilters },
        pathToListKey: "iconSearch.items",
        pathToTotalCountKey: "iconSearch.totalCount",
        useLazyFirstLoad: true,
        contentType: CONTENT_TYPE.icon,
        pageQueryParamKey: "iconspage"
    });

    const showEmptySearchResultsPage =
        !loadingFirstBatch && totalCount === 0 && isActive && firstBatchFetched;

    return (
        <SegmentedControlResults
            isActive={isActive}
            firstBatchFetched={firstBatchFetched}
            loadFirstBatchLazy={loadFirstBatchLazy}
        >
            <SearchHeader
                query={query}
                searchType={searchType}
                showSkeleton={loadingFirstBatch}
            />

            {showEmptySearchResultsPage ? (
                <SearchEmptyResults
                    contentType={CONTENT_TYPE.icon}
                    query={query}
                />
            ) : (
                <SearchAndBrowseIconGridContainer
                    iconData={items}
                    totalCount={totalCount}
                    loadingFirstBatch={loadingFirstBatch}
                    loadingMore={loading}
                    LoadMoreButton={LoadMore}
                    loadingItemCount={loadingItemCount}
                />
            )}
        </SegmentedControlResults>
    );
};

export default SearchIconsResults;
