import styled from "styled-components";

const SectionContentContainer = styled.div`
    margin-left: auto;
    margin-right: auto;
    max-width: 85.38rem; // 1366px
    padding: 0 1.5rem;
`;

export default SectionContentContainer;
