import React, { createContext, useState, useContext } from "react";
import { useSelector } from "react-redux";
import { getConfig } from "frontend/store/config";
import useIsomorphicLayoutEffect from "frontend/hooks/useIsomorphicLayoutEffect";

export const Context = createContext();

export const BannersProvider = ({ children }) => {
    const { banners } = useSelector(getConfig);
    const convertBannersToArr = Object.keys(banners);

    // If no banner exists, set hideBanner to true and nothing gets shown
    const isBannersExist = convertBannersToArr.length > 0;

    const [hideBanner, setHideBanner] = useState(true);

    const bannerKey = convertBannersToArr[0];

    const dismissBanner = () => {
        setHideBanner(true);
        localStorage.setItem(bannerKey, bannerKey);
    };

    useIsomorphicLayoutEffect(() => {
        if (isBannersExist) {
            setHideBanner(!!localStorage.getItem(bannerKey));
        }
    }, []);

    // This needs to be updated to show whatever marketing wants
    const bannerContent = <></>;

    const bannerContentMobile = bannerContent;

    const contextValues = {
        hideBanner,
        dismissBanner,
        bannerContent,
        bannerContentMobile,
        bannerKey
    };

    return (
        <Context.Provider value={contextValues}>{children}</Context.Provider>
    );
};

export const useBanners = () => {
    const context = useContext(Context);

    if (!context) {
        throw new Error("useBanners must be used within a BannersProvider");
    }

    return {
        hideBanner: context.hideBanner,
        dismissBanner: context.dismissBanner,
        bannerContent: context.bannerContent,
        bannerContentMobile: context.bannerContentMobile,
        bannerKey: context.bannerKey
    };
};
