import styled, { css } from "styled-components";

//styles/components:
import colors from "core/colors";
import { rapidEasing } from "core/animation";
import BodyText from "core/BodyText";
import OffsetGrid from "core/OffsetGrid";

import Photo from "core/ReusableIcons/Photo";

//helpers:
import { convertPxToRem } from "frontend/components/helpers";

// the styled components:
export const DropzoneContainer = styled.section``;

export const InnerContainer = styled.div`
    transition: all ${rapidEasing};
`;

export const DropzoneStage = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    transition: border ${rapidEasing};

    // only show focus on keyboard not with mouse:
    &:focus:not(.focus-visible) {
        outline: none;
        box-shadow: none;
    }

    &:focus {
        box-shadow: inset 0 0 0 3px ${colors.functional.secondary.parrot};
        outline: none;
    }

    ${({ isDropzoneEmpty }) =>
        isDropzoneEmpty &&
        css`
            border: ${convertPxToRem({ px: 1 })} dashed
                ${colors.secondary.manatee};
        `}

    ${({ isDragAccept }) =>
        isDragAccept &&
        css`
            border: ${convertPxToRem({ px: 1 })} solid ${colors.secondary.orca};

            ${InnerContainer} {
                opacity: 0.3;
            }
        `}

    ${({ isDragReject }) =>
        isDragReject &&
        css`
            border: ${convertPxToRem({ px: 1 })} solid
                ${colors.functional.lightMode.laser};
        `}

    ${({ currentlyProcessingUploads }) =>
        currentlyProcessingUploads &&
        css`
            border: none;
        `}
`;

export const DropzoneInput = styled.input`
    position: absolute;
    width: 100%;
    height: 100%;
`;

// for Empty.js:
export const EmptyOffsetGrid = styled(OffsetGrid)`
    padding: ${convertPxToRem({ px: 98 })} 0;
`;

export const HeadingContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: ${convertPxToRem({ px: 24 })};
`;

export const IconContainer = styled.div`
    font-size: 0;
    margin-right: ${convertPxToRem({ px: 24 })};

    ${Photo} {
        height: ${convertPxToRem({ px: 100 })};
        width: auto;
    }
`;

export const TitleContainer = styled.div``;

export const InstructionTitle = styled(BodyText)`
    margin-bottom: ${convertPxToRem({ px: 8 })};
`;
