import React from "react";
import PropTypes from "prop-types";

// styles
import { InactiveMessageStyled } from "./styles.js";

const InactiveMessage = ({ message }) => {
    return <InactiveMessageStyled>{message}</InactiveMessageStyled>;
};

export default InactiveMessage;

// prop-types
InactiveMessage.propTypes = {
    message: PropTypes.string
};
