import React from "react";
import PropTypes from "prop-types";

// components
import SideBarSection from "../SideBarSection";
import HistoryList from "./HistoryList";

// QueueContext
import { useQueueContext } from "frontend/components/fort/mod/core/Layouts/QueueLayout";

/**
 * combineModerationNotes: Combining Batch and Upload Moderation notes for History
 * @param {Object} upload
 * @param {Object} batchEntity
 * @returns
 */
const combineModerationNotes = (upload, batchEntity) => {
    const uploadModerationNotes =
        upload?.moderationNotes?.map(umn => {
            return {
                ...umn,
                id: `mod-note-upload-${umn.id}`
            };
        }) || [];

    const batchModerationNotes =
        batchEntity?.moderationNotes?.map(bmn => {
            return {
                ...bmn,
                id: `mod-note-batch-${bmn.id}`
            };
        }) || [];

    let moderationNotes = [...uploadModerationNotes, ...batchModerationNotes];

    // sort newset to oldest by string timestamps
    moderationNotes = moderationNotes.sort(
        (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
    );

    return moderationNotes;
};

export const History = ({ children }) => {
    // state/context
    const {
        uploadTypeTitle,
        selectedBatchObj,
        state: { singleUploadSelected }
    } = useQueueContext();

    if (!singleUploadSelected) return null;

    // Upload
    const moderationNotes = combineModerationNotes(
        singleUploadSelected,
        selectedBatchObj
    );

    return (
        <SideBarSection title={`${uploadTypeTitle} History`}>
            <div>
                <HistoryList moderationNotes={moderationNotes} />
                {children}
            </div>
        </SideBarSection>
    );
};

export default History;

// prop-types
History.propTypes = {
    children: PropTypes.any
};
