import { getArrayOfCollectionIconsWithTitle } from "icons/helpers";

export const handleContainerType = ({ type, STYLE_TYPES }) => {
    switch (type) {
        case "primary":
            return STYLE_TYPES.PRIMARY;
        case "secondary":
            return STYLE_TYPES.SECONDARY;
        case "darkMode":
            return STYLE_TYPES.DARKMODE;
        default:
            return STYLE_TYPES.PRIMARY;
    }
};

export const getImageHoverStyle = ({
    type,
    isPhoto,
    defaultHover,
    photoHover,
    iconHover
}) => {
    const darkModeName = "darkMode";

    if (type !== darkModeName && isPhoto) {
        return defaultHover, photoHover;
    } else if (type !== darkModeName && !isPhoto) {
        return iconHover;
    } else if (type === darkModeName && isPhoto) {
        return defaultHover, photoHover;
    } else if (type === darkModeName && !isPhoto) {
        return; // intentionally left empty - when type is darkMode, we dont affect the image
    } else {
        return defaultHover;
    }
};

export const formatCollections = ({ arrayOfCollectionObjects }) => {
    return arrayOfCollectionObjects.map(collection => {
        return {
            title: collection.name,
            url: collection.url,
            imageSrcAndTitle: getArrayOfCollectionIconsWithTitle({
                icons: collection.icons.items
            }),
            type: "secondary",
            usePlaceholder: true,
            subtitle: collection.icons.totalCount + " icons"
        };
    });
};

export const getCollectionIcons = ({ collection }) => {
    const collectionIconsLength = collection.icons.items.length;

    const totalEmptyIcons = 4 - collectionIconsLength;

    const icons = collection.icons.items.map(icon => {
        return {
            imageSrc: icon.thumbnails.thumbnail84,
            title: icon.title || icon.name
        };
    });

    // if collection icons length is less than 4, add additional empty icons to fill out the square collection card:
    if (collectionIconsLength < 4) {
        for (let index = 1; index <= totalEmptyIcons; index++) {
            icons.push({
                imageSrc: "",
                title: ""
            });
        }
    }

    return icons;
};
