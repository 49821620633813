import React from "react";
import PropTypes from "prop-types";

// components
import Template from "../Template.js";
import PhotoShoot from "./PhotoShoot.js";

const PhotoShootOrIndividual = props => {
    const { photoShoot } = props;
    const headerTitle = photoShoot ? "Photo Shoot" : "Individual Photos";

    return (
        <Template title={headerTitle} width={1117}>
            {photoShoot && <PhotoShoot {...props} />}
        </Template>
    );
};

export default PhotoShootOrIndividual;

// prop-types
PhotoShootOrIndividual.propTypes = {
    photoShoot: PropTypes.object
};
