import { useState, useEffect } from "react";
import useReactWebSocket, { ReadyState } from "react-use-websocket";
import * as Sentry from "@sentry/react";

const getWebSocketUrl = endpoint => {
    const protocol = `${window.location.protocol === "https:" ? "wss" : "ws"}`;
    const domain = window.location.host;
    const wsUrl = `${protocol}://${domain}/${endpoint}`;
    return wsUrl;
};

export const useWebSocket = ({
    endpoint,
    options,
    onReconnectStop = () => {},
    onCloseAndReopen = () => {}
}) => {
    const [wsClosed, setWsClosed] = useState(false);

    const globalOptions = {
        shouldReconnect: () => true,
        reconnectInterval: 5000,
        reconnectAttempts: 20,
        onReconnectStop: numAttempts => {
            onReconnectStop(numAttempts);
            Sentry.captureException(
                new Error(
                    `WebSocket failed to reconnect after ${numAttempts} attempts.`
                )
            );
        },
        onClose: () => {
            setWsClosed(true);
        }
    };

    const combinedOptions = {
        ...globalOptions,
        ...options
    };

    const websocket = useReactWebSocket(
        getWebSocketUrl(endpoint),
        combinedOptions
    );

    const { readyState } = websocket;

    useEffect(() => {
        if (wsClosed && readyState === ReadyState.OPEN) {
            console.info("Websocket was closed and re-opened.");
            setWsClosed(false);
            onCloseAndReopen();
        }
    }, [wsClosed, readyState]);

    return websocket;
};
