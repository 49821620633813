import styled, { css } from "styled-components";

import {
    convertPxToRem,
    handleComponentSize
} from "frontend/components/helpers";

import colors from "core/colors";
import { easingSeconds } from "core/animation";
import { focusBoxShadow } from "core/links";
import { regular as regularFontWeight } from "core/fontStyles";

export const INPUT_BOX_SHADOW_SIZE = 3;

const FORM_ELEMENT_SIZE_TYPES = {
    XS: css`
        height: ${convertPxToRem({ px: 24 })};
        padding-left: ${convertPxToRem({ px: 6 })};
        padding-right: ${convertPxToRem({ px: 6 })};
    `,

    SM: css`
        height: ${convertPxToRem({ px: 28 })};
        padding-left: ${convertPxToRem({ px: 8 })};
        padding-right: ${convertPxToRem({ px: 8 })};
    `,

    MD: css`
        min-height: ${convertPxToRem({ px: 40 })};
        padding-left: ${convertPxToRem({ px: 14 })};
        padding-right: ${convertPxToRem({ px: 14 })};
    `,

    LG: css`
        height: ${convertPxToRem({ px: 48 })};
        padding-left: ${convertPxToRem({ px: 16 })};
        padding-right: ${convertPxToRem({ px: 16 })};
    `
};

export const Label = styled.label`
    display: inline-block;
    position: relative;

    ${({ width }) => css`
        width: ${width};
    `}
`;

export const LabelAndError = styled.div`
    min-height: ${convertPxToRem({ px: 24 })};
`;

export const LabelContainer = styled.div`
    padding-top: ${convertPxToRem({ px: 3 })};
    transition: all ${easingSeconds};
    display: ${({ showFormLabel }) => (showFormLabel ? "block" : "none")};
    color: ${({ darkMode }) =>
        darkMode ? colors.secondary.dolphin : colors.secondary.oolong};
    font-size: ${convertPxToRem({ px: 13 })};
`;

export const ErrorContainer = styled.div`
    transition: all ${easingSeconds};
    width: 100%;
    word-break: break-word;
    color: ${({ darkMode }) =>
        darkMode
            ? colors.functional.darkMode.desertBloom
            : colors.functional.lightMode.sangria};
    font-size: ${convertPxToRem({ px: 13 })};
    line-height: ${convertPxToRem({ px: 16 })};
    display: ${({ showError }) => (showError ? "block" : "none")};

    &:before {
        content: "";
        width: 100%;
        position: relative;
        top: ${convertPxToRem({ px: -3 })};
        height: ${convertPxToRem({ px: 3 })};
        background: ${({ darkMode }) =>
            darkMode
                ? colors.functional.darkMode.desertBloom
                : colors.functional.lightMode.sangria};
        display: block;
    }
`;

export const sharedFormStylesBase = css`
    ${regularFontWeight}
    font-size: ${convertPxToRem({ px: 16 })};
    line-height: ${convertPxToRem({ px: 16 })};
    -webkit-appearance: none;
    box-shadow: ${({ darkMode }) => css`
        inset 0px 0px 0px ${INPUT_BOX_SHADOW_SIZE}px ${
        darkMode ? colors.secondary.basalt : colors.primary.diamond
    }
    `};
    background: ${({ darkMode }) =>
        darkMode ? colors.secondary.orca : colors.primary.snoww};
    transition: all ${easingSeconds};
    border-radius: 0;
    border: none;
    color: ${({ darkMode }) =>
        darkMode ? colors.primary.diamond : colors.secondary.orca};

    /* render sizes: */
    ${({ size }) =>
        handleComponentSize({
            size,
            styles: FORM_ELEMENT_SIZE_TYPES
        })}
    //
`;

export const sharedFormStylesPlaceholder = css`
    color: ${({ darkMode }) =>
        darkMode ? colors.secondary.dolphin : colors.secondary.bushrock};
    transition: all ${easingSeconds};
`;

export const sharedFormStylesHover = css`
    box-shadow: inset 0px 0px 0px 2px
        ${({ darkMode }) =>
            darkMode ? colors.secondary.dolphin : colors.secondary.oolong};
`;

export const sharedFormStylesFocus = css`
    box-shadow: inset 0px 0px 0px 3px
            ${({ darkMode }) =>
                darkMode ? colors.secondary.basalt : colors.primary.diamond},
        ${focusBoxShadow};
    outline: none;
    border: none;

    &::placeholder {
        color: transparent;
    }
`;

export const sharedFormStylesDisabled = css`
    -webkit-appearance: none;
    box-shadow: inset 0px 0px 0px 1px
        ${({ darkMode }) =>
            darkMode ? colors.secondary.oolong : colors.primary.scheifer};
    background-color: ${({ darkMode }) =>
        darkMode ? colors.secondary.oolong : colors.primary.diamond};
    color: ${({ darkMode }) =>
        darkMode ? colors.secondary.dolphin : colors.secondary.oolong};
    cursor: not-allowed;
    opacity: 1;
`;

export const sharedFormStylesReadOnly = css`
    box-shadow: none;

    &&,
    &::placeholder,
    &:disabled {
        color: ${({ darkMode }) =>
            darkMode ? colors.primary.diamond : colors.secondary.orca};
        background: ${({ darkMode }) =>
            darkMode ? colors.secondary.orca : colors.primary.snoww};
    }

    &:hover,
    &:focus {
        box-shadow: none;
    }
`;
