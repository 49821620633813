import React from "react";

import Link from "core/Link";

import Login from "../Login";
import {
    PageTitle,
    PageSubtitle,
    PageWrapper,
    AuthFormPageContent,
    FooterText,
    FormContainer
} from "../styles";

import { useAuthPage } from "frontend/hooks/useAuth";
import { SIGNUP, FORGOT_PASSWORD } from "data/links";

const LoginPage = () => {
    const { afterAuth } = useAuthPage();
    const queries = window.location.search;
    return (
        <PageWrapper darkMode>
            <PageTitle darkMode>Log In</PageTitle>
            <PageSubtitle>
                New?{" "}
                <Link darkMode size="lg" href={SIGNUP + queries}>
                    Create an Account
                </Link>
            </PageSubtitle>
            <AuthFormPageContent>
                <FormContainer>
                    <Login isStandaloneAuthPage afterAuth={afterAuth} />
                    <FooterText>
                        <Link size="lg" href={FORGOT_PASSWORD}>
                            Forgot your password?
                        </Link>
                    </FooterText>
                </FormContainer>
            </AuthFormPageContent>
        </PageWrapper>
    );
};

export default LoginPage;
