import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// components
import {
    InlineTextEdit,
    Checkbox,
    Input,
    SaveButton
} from "core/InlineTextEdit";

// styles
export const PhotoShootStyled = styled.div`
    display: flex;
    align-items: flex-start;
    width: 100%;
`;
import colors from "core/colors";

// component
export const PhotoShoot = ({
    photoShoot,
    onSave,
    onToggleApprove,
    isEditable
}) => {
    const { id, title, titleApproved } = photoShoot;

    return (
        <PhotoShootStyled>
            <InlineTextEdit
                ariaLabel="Photo Shoot"
                id={`photo-shoot-${id}`}
                text={title || ""}
                onSave={onSave}
                disabled={!isEditable}
                maxLength="145"
                placeholder="Add Name"
            >
                <Checkbox
                    isApproved={!!titleApproved}
                    onToggleApprove={onToggleApprove}
                    checkboxStyles={{
                        default: colors.primary.snow,
                        checked: colors.alternative.lightMode.shamrock,
                        disabled: colors.secondary.dolphin
                    }}
                />
                <Input height={36} />
                <SaveButton ariaLabel="Approve">Approve</SaveButton>
            </InlineTextEdit>
        </PhotoShootStyled>
    );
};

export default PhotoShoot;

// prop-types
PhotoShoot.propTypes = {
    photoShoot: PropTypes.object,
    onSave: PropTypes.func.isRequired,
    onToggleApprove: PropTypes.func.isRequired,
    isEditable: PropTypes.bool
};
