import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// context
import { useManageTags } from "core/ManageTags/context/useManageTags.js";

// styles
// TODO: Once implemented in UPL, will need to address these styles.
import { convertPxToRem } from "frontend/components/helpers";
export const TagInlineStyled = styled.div`
    display: inline-block;
    margin-right: ${convertPxToRem(8)};
`;

// components
import Tag from "../Tag";

// component
export const TagInline = ({ tag }) => {
    const { id } = useManageTags();
    return (
        <TagInlineStyled data-testid={`tag-inline-${id}-${tag.id}`}>
            <Tag tag={tag} />
        </TagInlineStyled>
    );
};

export default TagInline;

// prop-types
TagInline.propTypes = {
    tag: PropTypes.object.isRequired
};
