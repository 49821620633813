import React from "react";
import PropTypes from "prop-types";

// context
import { useDropDown } from "../useDropDown";

// components
import CloseButton from "./CloseButton";

import styled from "styled-components";

//styles
const DropDownAnchorStyled = styled.div`
    position: relative;
`;
const DropDownStyled = styled.ul`
    position: absolute;
    top: 0rem;
    z-index: 1;

    /* reset ul */
    margin: 0rem;
    padding: 0rem;
`;

// component
export const DropDown = ({ children, className }) => {
    const { isOpen, hasCloseButton, id } = useDropDown();
    return (
        <DropDownAnchorStyled>
            <DropDownStyled
                className={className}
                id={`${id}-dropdown-panel`}
                role="menu"
                aria-labelledby={`${id}-dropdown-trigger`}
                hidden={!isOpen}
            >
                {children}
                {hasCloseButton && <CloseButton />}
            </DropDownStyled>
        </DropDownAnchorStyled>
    );
};

// prop-types
DropDown.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string
};
