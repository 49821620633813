import styled from "styled-components";

// styles
import { convertPxToRem } from "frontend/components/helpers";

// components
import { InputStyled as CoreInputStyled } from "core/FormControls/styles.js";

// styled-components
export const InputWithIconStyled = styled.div`
    position: relative;
`;

export const InputStyled = styled(CoreInputStyled)`
    padding-left: ${({ paddingLeft }) => convertPxToRem(paddingLeft)};
`;

export const IconWrapperStyled = styled.div`
    position: absolute;
    left: 0px;
    top: 0px;

    width: ${({ width }) => convertPxToRem(width)};
    height: ${({ height }) => convertPxToRem(height)};

    display: flex;
    justify-content: center;
    align-items: center;
`;
