import gql from "graphql-tag";

export const DENY_PHOTO_UPLOADS = gql`
    mutation DenyPhotoUploads($photoUploadIds: [ID!]!) {
        payload: denyPhotoUploads(photoUploadIds: $photoUploadIds) {
            ok
            errors
            photoUploads {
                id
                status
            }
        }
    }
`;

export const FULLY_PUBLISH_PHOTO_UPLOADS = gql`
    mutation FullyPublishPhotoUploads($photoUploadIds: [ID!]!) {
        payload: approvePhotoUploads(
            photoUploadIds: $photoUploadIds
            isMetadataApproved: true
        ) {
            ok
            errors
            photoUploads {
                id
                status
            }
        }
    }
`;

// NOTE: to get around N+1 problems this is a celery task on the backend
// this means that the photos won't get updated immediately but will be fairly soon.
// More Details: https://github.com/TheNounProject/Noun-Project/pull/8124
export const UPDATE_ONLY_PHOTO_UPLOADS = gql`
    mutation UpdateOnlyPhotoUploads($photoUploadIds: [ID!]!) {
        payload: updateMetadataOnPublishedPhotos(
            photoUploadIds: $photoUploadIds
        ) {
            ok
            items {
                ok
                errors
                photoUploadId
            }
        }
    }
`;

export const VISUALLY_PUBLISH_PHOTO_UPLOADS = gql`
    mutation VisuallyPublishPhotoUploads($photoUploadIds: [ID!]!) {
        payload: approvePhotoUploads(
            photoUploadIds: $photoUploadIds
            isMetadataApproved: false
        ) {
            ok
            errors
            photoUploads {
                id
                status
            }
        }
    }
`;
