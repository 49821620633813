import merge from "lodash/merge";

import * as actionTypes from "../actions/actionTypes";

export const search = (state = {}, action) => {
    let nextState = {};

    switch (action.type) {
        case actionTypes.SAVE_QUERY_INFO:
            nextState.queryInfo = action.queryInfo;
            nextState = merge({}, state, nextState);
            return nextState;
            break;
        case actionTypes.SET_SEARCH_QUERY:
            nextState.query = action.query;
            return merge({}, state, nextState);
            break;
        case actionTypes.FETCH_AUTOCOMPLETE_TERMS_SUCCESS:
            state.autocompleteTerms = action.response;
            return state;
            break;
        case actionTypes.SEARCH_REGISTER_RESULTS:
            let { present } = action;
            return {
                ...state,
                resultsComponentPresent: present
            };
        default:
            return state;
            break;
    }

    return Object.assign({}, state, nextState);
};
