import styled, { css } from "styled-components";

export const Grid2Styled = styled.ul`
    display: grid;
    ${({ numberOfColumns, gridSpacingSize }) => {
        return css`
            grid-template-columns: repeat(${numberOfColumns}, 1fr);
            gap: ${gridSpacingSize};
        `;
    }}
`;
