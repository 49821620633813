import React, { useContext } from "react";

import CookieConsentContext from "frontend/context/CookieConsentContext";

const useCookieConsent = () => {
    const { cookies } = useContext(CookieConsentContext);
    return cookies;
};

export default useCookieConsent;
