import React, { useContext } from "react";
import queryString from "query-string";

// Components
import PhotoSearchResults from "photos/PhotoSearchResults";
import NotFound from "core/NotFound";
import { PhotosContext } from "photos/context";

import { SearchGrid } from "./styles";

export const PhotosSearchPage = () => {
    const {
        match: {
            params: { tag, username, shootInfo, photoId, campaignDetail }
        },
        location
    } = useContext(PhotosContext);

    const { q } = queryString.parse(location.search);
    const usernameDecoded = username ? decodeURIComponent(username) : null;

    let shootId = null;
    let campaignId = null;

    if (shootInfo) {
        const extractShootId = shootInfo.split("-");
        shootId = extractShootId[extractShootId.length - 1];
    }

    if (campaignDetail) {
        const extractCampaignId = campaignDetail.split("-");
        campaignId = extractCampaignId[extractCampaignId.length - 1];
    }

    if (!q && !tag && !username && !shootId && !photoId && !campaignId) {
        return <NotFound />;
    }

    return (
        <SearchGrid>
            <PhotoSearchResults
                searchQuery={q}
                tag={tag}
                creator={usernameDecoded}
                shootId={shootId}
                photoId={photoId}
                campaignId={campaignId}
            />
        </SearchGrid>
    );
};

export default PhotosSearchPage;
