import styled, { css } from "styled-components";

import Grid from "core/Grid";

import { spacingWithBreakpoints } from "core/spacing";
import breakpoints from "core/breakpoints";

export const HeroLandingPageContainer = styled.div`
    /* mobile: */
    ${({ mobileMinHeight }) => `min-height: ${mobileMinHeight};`}
    
    ${({ mobileImg, bgSizeMobile, bgPositionMobile }) =>
        mobileImg &&
        css`
            background-image: url(${mobileImg});
            background-repeat: no-repeat;
            background-position: ${bgPositionMobile
                ? bgPositionMobile
                : `center bottom`};
            background-size: ${bgSizeMobile ? bgSizeMobile : `auto 100%`};
        `}

    /* tablet: */
    @media (min-width: ${breakpoints.tablet.rem}) {
        ${({ tabletMinHeight }) => `min-height: ${tabletMinHeight};`}

        ${({ tabletImg, bgSizeTablet, bgPositionTablet }) =>
            tabletImg &&
            css`
                background-image: url(${tabletImg});
                background-repeat: no-repeat;
                background-position: ${bgPositionTablet
                    ? bgPositionTablet
                    : `right bottom`};
                background-size: ${bgSizeTablet ? bgSizeTablet : `cover`};
            `}
    }
    /* desktop: */
    @media (min-width: ${breakpoints.desktop.rem}) {
        ${({ desktopMinHeight }) => `min-height: ${desktopMinHeight};`}

        ${({ desktopImg, bgSizeDesktop, bgPositionDesktop }) =>
            desktopImg &&
            css`
                background-image: url(${desktopImg});
                background-repeat: no-repeat;
                background-position: ${bgPositionDesktop
                    ? bgPositionDesktop
                    : `100%`};
                background-size: ${bgSizeDesktop ? bgSizeDesktop : `auto 100%`};
            `}
    }
`;

export const HeadingAndInputContainer = styled.div`
    h1 {
        ${spacingWithBreakpoints.XL4Top}
    }
`;

export const MobileImg = styled.img`
    width: 100%;
    height: auto;
`;

// for icons landing-specific:
export const IconsLandingPageIconsContainer = styled(Grid)`
    && {
        text-align: right;

        /* mobile-only: */
        @media (max-width: ${breakpoints.beforeTablet.rem}) {
            padding-top: 0 !important; // does not work without !important
            padding-bottom: 0 !important; // does not work without !important
        }
    }
`;
