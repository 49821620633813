const cleanPreviewIcon = function(svgString, selectedColor, imgSize) {
    const re = /fill=["']#([\w]{3,6})["']/g,
        reClassFill = /fill:#([\w]{3,6})/g,
        reStroke = /stroke=["']#([\w]{3,6})["']/g,
        reClassStroke = /stroke:#([\w]{3,6})/g,
        reRGB = /rgba?\([^\)]*\)/g, // eslint-disable-line no-useless-escape
        reWidth = /<svg[^>]*(width=["'][\w]*["'])[^<]*/g,
        reHeight = /<svg[^>]*(height=["'][\w]*["'])[^<]*/g,
        reSvgFill = /<svg[^>]*(fill=["'][\w#]*["'])[^<]*/g;

    let cleanedSVG = svgString;

    if (!svgString) {
        console.log("no svgString");
        return;
    }

    if (selectedColor) {
        if (!reSvgFill.test(svgString)) {
            // If svg tag doesn't have a fill attribute, add one
            cleanedSVG = svgString.replace(
                "<svg ",
                `<svg fill="${selectedColor}" `
            );
        }

        if (svgString.includes("fill=")) {
            // If there are fills, change them to match selected color.
            cleanedSVG = cleanedSVG.replace(re, `fill="${selectedColor}"`);
        }

        if (svgString.includes("stroke=")) {
            // If there are strokes, change stroke colors to match selected color.
            cleanedSVG = cleanedSVG.replace(
                reStroke,
                `stroke="${selectedColor}"`
            );
        }

        if (svgString.includes("style")) {
            // If there are embeded CSS styles for fill or stroke, replace those too.
            cleanedSVG = cleanedSVG.replace(
                /fill:black/g,
                `fill:${selectedColor}`
            );
            cleanedSVG = cleanedSVG.replace(
                reClassFill,
                `fill:${selectedColor}`
            );
            cleanedSVG = cleanedSVG.replace(
                reClassStroke,
                `stroke:${selectedColor}`
            );
        }
    }

    if (imgSize) {
        // Replace the width if it exists, or add it to the SVG
        const reWidthMatch = reWidth.exec(cleanedSVG);
        if (reWidthMatch) {
            // Replaces width prop within the SVG tag
            const reWidthReplace = reWidthMatch[0].replace(
                reWidthMatch[1],
                `width="${imgSize}"`
            );
            // Now we replace the full SVG tag that includes new width
            cleanedSVG = cleanedSVG.replace(reWidth, reWidthReplace);
        } else {
            cleanedSVG = cleanedSVG.replace("<svg", `<svg width='${imgSize}' `);
        }

        // Replace the height if it exists, or add it to the SVG
        const reHeightMatch = reHeight.exec(cleanedSVG);
        if (reHeightMatch) {
            // Replaces height prop within the SVG tag
            const reHeightReplace = reHeightMatch[0].replace(
                reHeightMatch[1],
                `height="${imgSize}"`
            );
            // Now we replace the full SVG tag that includes the new height
            cleanedSVG = cleanedSVG.replace(reHeight, reHeightReplace);
        } else {
            cleanedSVG = cleanedSVG.replace("<svg", `<svg height='${imgSize}'`);
        }
    } else {
        const reWidthMatch = reWidth.exec(cleanedSVG);

        if (reWidthMatch) {
            // Replaces width prop within the SVG tag
            const reWidthReplace = reWidthMatch[0].replace(reWidthMatch[1], "");
            cleanedSVG = cleanedSVG.replace(reWidth, reWidthReplace);
        }
        const reHeightMatch = reHeight.exec(cleanedSVG);
        if (reHeightMatch) {
            // Replaces height prop within the SVG tag
            const reHeightReplace = reHeightMatch[0].replace(
                reHeightMatch[1],
                ""
            );
            // Now we replace the full SVG tag that includes the new height
            cleanedSVG = cleanedSVG.replace(reHeight, reHeightReplace);
        }
    }

    // Replace any RGB colors with selected color
    if (selectedColor && svgString.includes("rgb")) {
        cleanedSVG = cleanedSVG.replace(reRGB, selectedColor);
    }

    return cleanedSVG;
};

export default cleanPreviewIcon;
