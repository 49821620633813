import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// styles
import { convertPxToRem } from "frontend/components/helpers";

const DEBUG_SHOW_BATCH_META = false;

export const MetaBatchStyled = styled.div`
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: ${convertPxToRem({ px: 20 })};

    span {
        margin: 0px 10px;

        &.bid {
            color: red;
        }
        &.pg {
            color: blue;
        }
        &.ct {
            color: purple;
        }
    }
`;

// component
export const MetaBatch = ({ batch }) => {
    const { id: batchId, paginationItemNumber, page } = batch;

    if (!DEBUG_SHOW_BATCH_META) return null;

    return (
        <MetaBatchStyled>
            <span className="bid">
                <b>ID:</b> {batchId}
            </span>
            <span className="pg">
                <b>PG:</b> {page}
            </span>
            <span className="ct">
                <b>CT:</b> {paginationItemNumber}
            </span>
        </MetaBatchStyled>
    );
};

export default MetaBatch;

// prop-types
MetaBatch.propTypes = {
    batch: PropTypes.any.isRequired
};
