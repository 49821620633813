import { convertPxToRem } from "frontend/components/helpers";

const boxShadows = {
    lightMode: {
        overlay: `${convertPxToRem({ px: 1 })} ${convertPxToRem({
            px: 1
        })} ${convertPxToRem({ px: 4 })} rgba(0, 0, 0, 0.1);`,
        hover: `${convertPxToRem({ px: 1 })} ${convertPxToRem({
            px: 4
        })} ${convertPxToRem({ px: 8 })} rgba(0, 0, 0, 0.25)`,
        strong: `0 ${convertPxToRem({ px: 2 })} ${convertPxToRem({
            px: 4
        })} rgba(0, 0, 0, 0.3);`
    },
    darkMode: {
        overlay: `${convertPxToRem({ px: 1 })} ${convertPxToRem({
            px: 1
        })} ${convertPxToRem({ px: 4 })}  rgba(255, 255, 255, 0.13)`,
        hover: `${convertPxToRem({ px: 1 })} ${convertPxToRem({
            px: 4
        })}  ${convertPxToRem({ px: 8 })} rgba(255, 255, 255, 0.22);`,
        strong: `0 ${convertPxToRem({ px: 2 })} ${convertPxToRem({
            px: 4
        })}  rgba(255, 255, 255, 0.35)`
    }
};

export default boxShadows;

export const dialogDropShadow = `0px ${convertPxToRem({
    px: 3
})} ${convertPxToRem({ px: 7 })} rgb(0 0 0 / 24%)`;
