import React from "react";
import PropTypes from "prop-types";

// styles
import {
    InputWithIconStyled,
    InputStyled,
    IconWrapperStyled
} from "./styles.js";

// Components
import { FormControlGroup } from "core/FormControls";

export const InputWithIcon = ({
    children,
    width = 30,
    height = 28,
    ...rest
}) => {
    return (
        <FormControlGroup {...rest}>
            <InputWithIconStyled>
                <InputStyled height={height} paddingLeft={width} {...rest} />
                <IconWrapperStyled height={height} width={width}>
                    {children}
                </IconWrapperStyled>
            </InputWithIconStyled>
        </FormControlGroup>
    );
};

export default InputWithIcon;

// prop-types
InputWithIcon.propTypes = {
    children: PropTypes.any.isRequired,
    height: PropTypes.number,
    width: PropTypes.number
};
