import styled from "styled-components";

const Div = styled.div``;

export default Div;

/*
    Note:
    We could probably get away with not using this & just using an HTML <div> element instead?
*/
