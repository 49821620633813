import styled from "styled-components";

import Grid from "core/Grid";
import Heading from "core/Heading";

import { getLinkElementStyles } from "core/Link/styles";
import { regular as regularFontWeight } from "core/fontStyles";
import breakpoints from "core/breakpoints";
import colors from "core/colors";

export const TimelineHeading = styled(Heading)`
    text-align: left;

    /* Tablet +: */
    @media (min-width: ${breakpoints.tablet.rem}) {
        text-align: center;
    }
`;

export const TimelineGridItem = styled(Grid)``;

export const GridItemContent = styled.div`
    width: 100%;
    height: 100%;
    padding-top: 1.5rem; /* 24px */
    border-top: 2px solid ${colors.primary.scheifer};
`;

export const GridItemContentHeading = styled(Heading)`
    padding-bottom: 1.5rem; /* 24px */
`;

export const Paragraph = styled.p`
    font-size: 1rem; /* 16px */
    line-height: 1.625rem; /* 26px */
    color: ${colors.secondary.orca};
    ${regularFontWeight}

    &:not(:last-of-type) {
        padding-bottom: 1.5rem; /* 24px */
    }

    a {
        ${getLinkElementStyles({
            display: "inline",
            size: "lg"
        })}
    }
`;
