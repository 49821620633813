import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import MaterialTooltip from "@mui/material/Tooltip";

import { StyledChildrenContainer } from "./styles";

const UnstyledHoverToOpen = ({ className, children, ...tooltipProps }) => {
    return (
        <MaterialTooltip className={className} {...tooltipProps}>
            <StyledChildrenContainer className={className} tabIndex={0}>
                {children}
            </StyledChildrenContainer>
        </MaterialTooltip>
    );
};

const HoverToOpen = styled(UnstyledHoverToOpen)``;

HoverToOpen.propTypes = {
    children: PropTypes.any.isRequired
};

export default HoverToOpen;
