import {
    PHOTO_UPLOAD,
    PHOTO_UPLOAD_MAX_QUEUE,
    PHOTO_UPLOAD_MAX_TIME,
    PHOTO_UPLOAD_BAD_ACCOUNT
} from "data/links";

import {
    LOADING_STATUS,
    INCOMPLETE_ACCOUNT_STATUS,
    SUBMIT_BATCH_STATUS,
    MAX_QUEUE_STATUS,
    MAX_TIME_STATUS
} from "frontend/components/creator-tools/PhotoUploadPage/PhotoUploadCore/hooks/usePhotoSubmissionStatus";

export function getPath(status) {
    switch (status) {
        case LOADING_STATUS:
            return null; // don't redirect while loading
        case MAX_QUEUE_STATUS:
            return PHOTO_UPLOAD_MAX_QUEUE;
        case MAX_TIME_STATUS:
            return PHOTO_UPLOAD_MAX_TIME;
        case INCOMPLETE_ACCOUNT_STATUS:
            return PHOTO_UPLOAD_BAD_ACCOUNT;
        case SUBMIT_BATCH_STATUS:
            return PHOTO_UPLOAD;
        default:
            return PHOTO_UPLOAD;
    }
}

export const determineReleaseButtonTitle = numOfReleases => {
    if (numOfReleases === 0) {
        return "Add or Manage Releases";
    } else if (numOfReleases === 1) {
        return `Add or Manage 1 Release`;
    } else {
        return `Add or Manage ${numOfReleases} Releases`;
    }
};

export const getBeToFeErrMsgRemap = (errorMessage, mappings) => {
    return mappings.find(m => m.be === errorMessage);
};
