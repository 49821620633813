import * as actionTypes from "../actions/actionTypes";

const initialState = {
    photosSearchScrollPosition: 0
};

const photos = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SAVE_SEARCH_SCROLL_POSITION:
            state.photosSearchScrollPosition = action.payload;
            return { ...state };
        default:
            return state;
    }
};

export default photos;
