import { css } from "styled-components";

import { convertPxToRem } from "frontend/components/helpers";
import colors from "core/colors";

export const handleBoxShadowOutlineBorder = ({
    isFocus = false,
    isSelected = false,
    isHover = false
}) => {
    const insetWidth = isSelected ? 3 : 2;
    const insetColor = isSelected
        ? colors.accent.lightMode.pansy
        : colors.primary.scheifer;

    if (isHover || isFocus) {
        return css`
            ${`inset 0px 0px 0px ${convertPxToRem({
                px: insetWidth
            })} transparent`}
        `;
    } else {
        return css`
            ${`inset 0px 0px 0px ${convertPxToRem({
                px: insetWidth
            })} ${insetColor}`}
        `;
    }
};

export const handleWidthStyles = ({
    isFluid,
    isFullWidth,
    width,
    additionalFluidStyles,
    additionalFullWidthStyles,
    additionalFixedStyles
}) => {
    if (isFluid && !isFullWidth && !width) {
        return css`
            width: auto;

            ${additionalFluidStyles}
        `;
    }

    if (isFullWidth && !isFluid && !width) {
        return css`
            width: 100%;

            ${additionalFullWidthStyles}
        `;
    }

    if (!!width && !isFluid && !isFullWidth) {
        return css`
            width: ${width};

            ${additionalFixedStyles}
        `;
    }
};

export const getLabelOrText = ({ text, ariaLabel }) => {
    if (!ariaLabel) {
        return text;
    } else if (ariaLabel === text) {
        return text;
    } else if (ariaLabel !== text) {
        return ariaLabel;
    } else {
        // we should never reach this, this is a fallback just in case:
        return "";
    }
};
