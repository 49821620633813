import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Heading from "core/Heading";
import Skeleton from "core/Skeleton";

import breakpoints from "core/breakpoints";
import { convertPxToRem } from "frontend/components/helpers";

const SearchAndBrowsePageHeadlineSkeletonContainer = styled.div`
    height: ${convertPxToRem({ px: 28 })};
    width: ${convertPxToRem({ px: 200 })};

    /* mobile and tablet: */
    @media (max-width: ${breakpoints.beforeDesktop.rem}) {
        height: ${convertPxToRem({ px: 24 })};
    }
`;

let SearchAndBrowsePageHeadline = ({ showSkeleton, children, className }) => {
    return showSkeleton ? (
        <SearchAndBrowsePageHeadlineSkeletonContainer className={className}>
            <Skeleton width="100%" height="100%" />
        </SearchAndBrowsePageHeadlineSkeletonContainer>
    ) : (
        <Heading size="xs" type="h1" className={className}>
            {children}
        </Heading>
    );
};

SearchAndBrowsePageHeadline = styled(SearchAndBrowsePageHeadline)`
    margin-bottom: 1.75rem;
`;

SearchAndBrowsePageHeadline.propTypes = {
    showSkeleton: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string
};

export default SearchAndBrowsePageHeadline;
