import React from "react";
import PropTypes from "prop-types";

// components
import Collections from "core/Collections";

const SearchAndBrowseCollectionGrid = ({
    items,
    totalCount,
    LoadMore,
    isLink
}) => {
    const showLoadMore = items.length < totalCount;

    return (
        <>
            <CollectionMemoized items={items} isLink={isLink} />
            {showLoadMore && <LoadMore />}
        </>
    );
};

const CollectionMemoized = React.memo(
    ({ items, isLink }) => {
        return <Collections collections={items} isLink={isLink} />;
    },
    (prevProps, nextProps) => {
        // if items array in prevProps, and items array in nextProps
        // are the same (eval to true) then we will not render Collections above.
        // if they are false (incoming nextProps is diff than prevProps) then we render.
        // this prevents unnecessary re-renders from the parent when this hasnt changed
        let prev = prevProps.items.map(item => item.id);
        let next = nextProps.items.map(item => item.id);
        return JSON.stringify(prev) === JSON.stringify(next);
    }
);

SearchAndBrowseCollectionGrid.propTypes = {
    items: PropTypes.array.isRequired,
    totalCount: PropTypes.number.isRequired,
    LoadMore: PropTypes.func.isRequired,
    isLink: PropTypes.bool
};

export default SearchAndBrowseCollectionGrid;
