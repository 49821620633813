import { useDispatch } from "react-redux";
import useIsomorphicLayoutEffect from "frontend/hooks/useIsomorphicLayoutEffect";
import { restoreStyleFilters } from "frontend/store/browse";

export const useRestoreStyleFilters = () => {
    const dispatch = useDispatch();

    useIsomorphicLayoutEffect(() => {
        const filters = sessionStorage.getItem("styleFilters");
        // dispatch could be null if this hook is used outside of redux context
        try {
            const { style, weight } = JSON.parse(filters);
            dispatch(restoreStyleFilters({ style, weight }));
        } catch (e) {
            // if filters are invalid, set object with null values
            sessionStorage.removeItem("styleFilters");
            dispatch(restoreStyleFilters({ style: null, weight: null }));
        }
    }, []);
};
