import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import debounce from "lodash/debounce";

// rtk
import { batchesSlice } from "frontend/components/fort/mod/photos/slices/batchesSlice/index.js";
import { uploadsSlice } from "frontend/components/fort/mod/photos/slices/uploadsSlice/index.js";
import { websocketSynchQueueSlice } from "frontend/components/fort/mod/photos/slices/websocketSynchQueueSlice.js";

// hooks
import { useRefetchBatchesAndUploads } from "../../../hooks/useRefetchBatchesAndUploads";

// event listeners
import {
    EVENT_LISTENER_BATCH_NOTE,
    EVENT_LISTENER_UPLOAD_NOTE
} from "../../../Providers/WebsocketProvider/getModerationNote";

const DEBOUNCE_DELAY = 2000;

// component
export const WebsocketSynchProvider = ({ children }) => {
    const dispatch = useDispatch();

    const { shouldRefetchBatches, moderationNotes } = useSelector(
        state => state.websocketSynchQueue
    );
    const { refetchBatches } = useRefetchBatchesAndUploads();

    /**
     * WS Queue Changes: Refetch Batches
     * To account for multiple tabs and multiple moderators
     * refetch all batches based on Moderators current view (e,g, pagination, filters).
     */
    useEffect(() => {
        const synchBatches = debounce(() => {
            dispatch(
                websocketSynchQueueSlice.actions.setShouldRefetchBatches({
                    shouldRefetchBatches: false
                })
            );
            refetchBatches(); // refetch paginated batches
        }, DEBOUNCE_DELAY);

        if (shouldRefetchBatches) {
            synchBatches();
        }
    }, [shouldRefetchBatches]);

    /**
     * WS Queue Changes: Synchronize Batch and Photo Moderation Notes
     * Moderation Notes is not returned with all upload mutations
     * So we WS listen for notes, and fetch those notes separately
     */
    useEffect(() => {
        const synchModerationNotes = debounce(() => {
            moderationNotes.forEach(({ eventType, data: note }) => {
                switch (eventType) {
                    case EVENT_LISTENER_BATCH_NOTE:
                        dispatch(
                            batchesSlice.actions.addModerationNote({ note })
                        );
                        break;
                    case EVENT_LISTENER_UPLOAD_NOTE:
                        dispatch(
                            uploadsSlice.actions.addModerationNote({ note })
                        );
                        break;
                }
            });

            // clear mode notes in redux queue
            dispatch(websocketSynchQueueSlice.actions.clearModNotes());
        }, DEBOUNCE_DELAY);

        if (moderationNotes.length > 0) {
            synchModerationNotes();
        }
    }, [moderationNotes]);

    return <>{children}</>;
};

export default WebsocketSynchProvider;

// prop-types
WebsocketSynchProvider.propTypes = {
    children: PropTypes.any
};
