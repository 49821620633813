import React from "react";
import PropTypes from "prop-types";

// data
import { getLinkTextStrings } from "../utils";

// styles
import { TagWrapper, ProcessingSpinnerIcon } from "../styles.js";
import { TagLinkStyled } from "./styles.js";

export const TagLink = ({
    text,
    path,
    ariaLabel = "",
    isTruncated = false,
    isLoading = false,
    className,
    isDarkMode = false,
    isDisabled = false,
    isStrikethrough = false,
    dataTestId = "tag-link"
}) => {
    const textStrings = getLinkTextStrings({
        text,
        ariaLabel,
        isLoading,
        isTruncated
    });

    return (
        <TagWrapper className={className}>
            <TagLinkStyled
                {...{ isDarkMode, isDisabled, isStrikethrough }}
                href={path}
                title={textStrings.ariaLabel}
                data-testid={dataTestId}
            >
                <span>{textStrings.truncated}</span>
                {isLoading && <ProcessingSpinnerIcon />}
            </TagLinkStyled>
        </TagWrapper>
    );
};

TagLink.propTypes = {
    text: PropTypes.string.isRequired,
    className: PropTypes.string,
    path: PropTypes.string,
    isStrikethrough: PropTypes.bool,
    isDisabled: PropTypes.bool,
    ariaLabel: PropTypes.string,
    isTruncated: PropTypes.bool,
    isLoading: PropTypes.bool,
    disableRemoveFromUI: PropTypes.bool,
    isDarkMode: PropTypes.bool
};
