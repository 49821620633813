import styled, { css } from "styled-components";

// styles + components:
import colors from "core/colors";
import breakpoints from "core/breakpoints";
import { rapidEasing } from "core/animation";
import RadioInput from "core/RadioInput";

//helpers:
import { convertPxToRem } from "frontend/components/helpers";
import { handleTextSizeAndFont } from "core/BodyText/helpers";
import { handleBoxShadowOutlineBorder, handleWidthStyles } from "./helpers";

// reusable constants:
const SELECTED_STYLE = css`
    box-shadow: ${handleBoxShadowOutlineBorder({
        isSelected: true
    })};
`;

const FOCUS_STYLE = css`
    box-shadow: ${handleBoxShadowOutlineBorder({
        isFocus: true
    })};
    background: ${colors.secondary.orca};
    color: ${colors.primary.diamond};

    ${RadioInput} {
        border-color: ${colors.accent.lightMode.pansy};
        background-color: ${colors.secondary.orca};
    }
`;

const HOVER_AND_ACTIVE_STYLE = css`
    background: ${colors.accent.lightMode.dewdrop};
    box-shadow: ${handleBoxShadowOutlineBorder({
        isHover: true
    })};
`;

const MOBILE_HEIGHT_PX = 48;
const MOBILE_LR_PADDING_PX = 16;
const MOBILE_R_MARGIN_PX = 16;
const TABLET_DESKTOP_HEIGHT_PX = 78;
const TABLET_DESKTOP_LR_PADDING_PX = 24;
const TABLET_DESKTOP_R_MARGIN_PX = 20;

const FLUIDWIDTH_FIXEDWIDTH_SHARED = css`
    /* mobile only: */
    @media (max-width: ${breakpoints.beforeTablet.rem}) {
        height: ${convertPxToRem({ px: MOBILE_HEIGHT_PX })};
        padding: 0 ${convertPxToRem({ px: MOBILE_LR_PADDING_PX })};
        margin-right: ${convertPxToRem({ px: MOBILE_R_MARGIN_PX })};

        &:last-of-type {
            margin-right: 0;
        }

        ${RadioInput} {
            margin-left: -${convertPxToRem({ px: MOBILE_R_MARGIN_PX })};
        }
    }

    /* tablet + desktop: */
    @media (min-width: ${breakpoints.tablet.rem}) {
        height: ${convertPxToRem({ px: TABLET_DESKTOP_HEIGHT_PX })};
        padding: 0 ${convertPxToRem({ px: TABLET_DESKTOP_LR_PADDING_PX })};
        margin-right: ${convertPxToRem({
            px: TABLET_DESKTOP_R_MARGIN_PX
        })};

        &:last-of-type {
            margin-right: 0;
        }
    }
`;

const FULLWIDTH_SHARED = css`
    /* mobile only: */
    @media (max-width: ${breakpoints.beforeTablet.rem}) {
        height: ${convertPxToRem({ px: MOBILE_HEIGHT_PX })};
        padding: 0 ${convertPxToRem({ px: MOBILE_LR_PADDING_PX })};
        margin-bottom: ${convertPxToRem({ px: MOBILE_R_MARGIN_PX })};
        justify-content: center;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    /* tablet + desktop: */
    @media (min-width: ${breakpoints.tablet.rem}) {
        height: ${convertPxToRem({ px: TABLET_DESKTOP_HEIGHT_PX })};
        padding: 0 ${convertPxToRem({ px: TABLET_DESKTOP_LR_PADDING_PX })};
        margin-bottom: ${convertPxToRem({
            px: TABLET_DESKTOP_R_MARGIN_PX
        })};

        &:last-of-type {
            margin-bottom: 0;
        }
    }
`;

// the styled components:
export const Container = styled.fieldset``;

export const Options = styled.div`
    display: flex;
    ${({ isFullWidth }) =>
        isFullWidth &&
        css`
            flex-direction: column;
        `};
`;

export const Label = styled.label`
    position: relative;
    display: flex;
    align-items: center;
    transition: all ${rapidEasing};
    cursor: pointer;
    background: ${colors.primary.snow};
    color: ${colors.secondary.orca};
    border: none;
    border-radius: ${convertPxToRem({ px: 4 })};
    box-shadow: ${handleBoxShadowOutlineBorder};

    ${handleTextSizeAndFont({ size: "md" })}

    ${({ isFluid, isFullWidth, width }) =>
        handleWidthStyles({
            isFluid,
            isFullWidth,
            width,
            additionalFluidStyles: FLUIDWIDTH_FIXEDWIDTH_SHARED,
            additionalFullWidthStyles: FULLWIDTH_SHARED,
            additionalFixedStyles: FLUIDWIDTH_FIXEDWIDTH_SHARED
        })}



    &:hover {
        ${HOVER_AND_ACTIVE_STYLE}
    }

    ${({ isSelected }) => isSelected && SELECTED_STYLE}

    ${({ isFocused }) => isFocused && FOCUS_STYLE}

    &:active {
        ${HOVER_AND_ACTIVE_STYLE}
    }

    img,
    svg {
        /* Mobile only: */
        @media (max-width: ${breakpoints.beforeTablet.rem}) {
            height: ${convertPxToRem({ px: 20 })};
            width: auto;
        }

        /* Tablet + Desktop: */
        @media (min-width: ${breakpoints.tablet.rem}) {
            height: ${convertPxToRem({ px: 24 })};
            width: auto;
        }
    }

    ${RadioInput} {
        display: inline-flex;
        cursor: pointer;
        margin-top: 0;
        margin-right: ${convertPxToRem({ px: 16 })};
        transition: all ${rapidEasing};

        &:focus {
            outline: none;
            box-shadow: none;
        }

        /* Mobile only: */
        @media (max-width: ${breakpoints.beforeTablet.rem}) {
            margin-right: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            border-radius: ${convertPxToRem({ px: 4 })};
            background: transparent;
            border: 0;

            &:checked::before {
                background-color: transparent;
            }
        }
    }
`;
