/*===================================
||
|| FormSelectDialogContext
||
===================================*/
import React, {
    createContext,
    useReducer,
    useMemo,
    useEffect,
    useRef,
    useContext
} from "react";
import PropTypes from "prop-types";
import { ThemeProvider } from "styled-components";
import { debounce } from "lodash";

// store
import { reducer, actions } from "./store";

// styles
import { defaultTheme } from "./theme";
import { FormSelectDialogStyled } from "./styles";

// components
import Dialog from "./Dialog";
import Control from "./Control";

// exposure
export { defaultTheme };

/*---------------------------
| Context
---------------------------*/
const FormSelectDialogContext = createContext();

// Provider
const FormSelectDialog = ({
    // required
    id,
    options,
    selectedOption,
    setSelectedOption,

    // optional
    hideOnScroll = false,
    controlType = "caret", // caret or edit
    controlLabelOverride = "",
    isDisabled = false,
    theme = defaultTheme
}) => {
    // These are props managed by consumer, not added to internal useReducer state
    const consumerProps = {
        id,
        options,
        selectedOption,
        setSelectedOption,
        controlType,
        controlLabelOverride,
        isDisabled,
        hideOnScroll
    };

    const initialArg = {
        isOpen: false,
        hasOpenedBefore: false, // we throw focus back onto openCloseButton after they close the dialog, not on mount
        openCloseButtonIsInFocus: false
    };

    const [state, dispatch] = useReducer(reducer, initialArg);

    // useMemo so it does not pass value on every render
    const value = useMemo(() => ({ state, dispatch, ...consumerProps }), [
        state,
        dispatch,
        consumerProps
    ]);

    return (
        <ThemeProvider theme={theme()}>
            <FormSelectDialogContext.Provider value={value}>
                <MountingWrapper />
            </FormSelectDialogContext.Provider>
        </ThemeProvider>
    );
};

export default FormSelectDialog;

// Display Name
FormSelectDialogContext.displayName = "FormSelectDialog";

// prop-types
export const optionShape = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired, // used primarily
    label: PropTypes.any.isRequired, // what shows in dropdown options display, and the Control Display if selected
    controlLabelOverride: PropTypes.any // This overrides the selected option.label in the Control Display
    // ... feel free to add any additional props needed; the object is preserved and passed back to consumer in `setSelectedOption`
};

FormSelectDialog.propTypes = {
    id: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape(optionShape)).isRequired,
    selectedOption: PropTypes.shape(optionShape).isRequired,
    setSelectedOption: PropTypes.func.isRequired,
    controlType: PropTypes.oneOf(["caret", "edit"]),
    controlLabelOverride: PropTypes.string, // This overrides the selected option.controlLabelOverride && option.label in the Control Display
    isDisabled: PropTypes.bool,
    hideOnScroll: PropTypes.bool,
    theme: PropTypes.func
};

// useFormSelectDialog
export const useFormSelectDialog = () => {
    const context = useContext(FormSelectDialogContext);

    const setHasOpenedBefore = hasOpenedBefore => {
        context.dispatch(actions.setHasOpenedBefore(hasOpenedBefore));
    };
    const setIsOpen = isOpen => {
        context.dispatch(actions.setIsOpen(isOpen));
    };
    const setOpenCloseButtonIsInFocus = openCloseButtonIsInFocus => {
        context.dispatch(
            actions.setOpenCloseButtonIsInFocus(openCloseButtonIsInFocus)
        );
    };

    return {
        ...context.state,
        ...context,
        setIsOpen,
        setHasOpenedBefore,
        setOpenCloseButtonIsInFocus
    };
};

// MountingWrapper
const MountingWrapper = () => {
    const {
        id,
        hasOpenedBefore,
        setHasOpenedBefore,
        isOpen,
        setIsOpen,
        hideOnScroll
    } = useFormSelectDialog();

    const dialogRef = useRef();

    // onMount
    useEffect(() => {
        const onKeyDown = event => {
            if (event.key === "Escape") {
                setIsOpen(false);
            }
        };
        const onScroll = debounce(() => {
            if (hideOnScroll) {
                setIsOpen(false);
            }
        }, 300);

        window.addEventListener("keydown", onKeyDown);
        document.addEventListener("scroll", onScroll);

        return () => {
            window.removeEventListener("keydown", onKeyDown);
            document.removeEventListener("scroll", onScroll);
        };
    }, []);

    // Click Outside
    // https://stackoverflow.com/a/64665817
    useEffect(() => {
        const onClickOutside = e => {
            const withinBoundaries = e
                .composedPath()
                .includes(dialogRef.current);
            if (!withinBoundaries && isOpen) {
                setIsOpen(false);
            }
        };
        window.addEventListener("click", onClickOutside);
        return () => {
            window.removeEventListener("click", onClickOutside);
        };
    }, [dialogRef, isOpen]);

    // Handle Option Focus when dialog opens but only after it has been opened before, not on mount
    useEffect(() => {
        if (isOpen && !hasOpenedBefore) {
            setHasOpenedBefore(true);
        }
    }, [isOpen]);

    return (
        <FormSelectDialogStyled id={id} ref={dialogRef}>
            <Control />
            <Dialog />
        </FormSelectDialogStyled>
    );
};

/*---------------------------
| Helpers
---------------------------*/
export const getDialogOptionId = (idPrefix, optionId) => {
    return `${idPrefix}-dialogOption${optionId}`;
};

const getFocusedOptionIdx = (options, idPrefix) => {
    let focusedOptionIdx = 0;

    options.forEach((o, idx) => {
        if (
            document.activeElement ===
            document.getElementById(getDialogOptionId(idPrefix, o.id))
        ) {
            focusedOptionIdx = idx;
        }
    });

    return focusedOptionIdx;
};

const focusDomElementByOption = (option, idPrefix) => {
    if (option) {
        const optionDom = document.getElementById(
            getDialogOptionId(idPrefix, option.id)
        );
        if (optionDom) {
            optionDom.focus();
        }
    }
};

export const focusPrevOption = (options, idPrefix) => {
    const focusedOptionIdx = getFocusedOptionIdx(options, idPrefix);
    focusDomElementByOption(options[focusedOptionIdx - 1], idPrefix);
};

export const focusNextOption = (options, idPrefix) => {
    const focusedOptionIdx = getFocusedOptionIdx(options, idPrefix);
    focusDomElementByOption(options[focusedOptionIdx + 1], idPrefix);
};
