import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import colors from "core/colors";

const createHelpers = (width, height, css) => {
    // somehow sizes is ending up in markup, even if it is not a valid svg attribute
    // until we have a better solution, just render it empty, instead to '[Object object]'
    const sanitizeSizes = sizes =>
        Object.defineProperty(sizes, "toString", {
            value: () => "",
            enumerable: false
        });

    const getDimensions = (size, sizes) => {
        if (
            size &&
            typeof size.width === "number" &&
            typeof size.height === "number"
        ) {
            return size;
        }

        return size && sizes[size] ? sizes[size] : { width, height };
    };

    const getCss = (size, sizes, fillColor, fillColorRule, noStyles) => {
        if (noStyles) {
            return "";
        }

        const dimensions = getDimensions(size, sizes);
        const fillRule =
            fillColor && fillColorRule
                ? `${fillColorRule}{ fill: ${fillColor}; }`
                : "";

        return css`
            width: ${dimensions.width}px;
            height: ${dimensions.height}px;
            ${fillRule}
        `;
    };

    const propsToCss = ({ size, sizes, fillColor, fillColorRule, noStyles }) =>
        getCss(size, sizes, fillColor, fillColorRule, noStyles);

    return {
        getCss,
        getDimensions,
        propsToCss,
        sanitizeSizes
    };
};

const width = "190";
const height = "190";
const viewBox = "0 0 190 190";

const { getDimensions, getCss, propsToCss, sanitizeSizes } = createHelpers(
    width,
    height,
    css
);

const sizes = sanitizeSizes({
    sm: { width: 98, height: 98 },
    md: { width: 101, height: 101 },
    lg: { width: 190, height: 190 }
});

const Image = styled.svg`
    ${propsToCss}
`;

const children = (
    <Fragment>
        <path
            fill={colors.secondary.orca}
            fillRule="evenodd"
            d="M95 44a3 3 0 00-3 3v15a3 3 0 106 0V47a3 3 0 00-3-3zm10.41 37.503l6.02 11.881c1.855-1.45 3.713-2.438 5.586-3.006 2.892-.876 5.596-.686 8.04.044 2.395.714 4.545 1.947 6.399 3.153.817.53 1.611 1.08 2.347 1.589h.001l.303.21a53.44 53.44 0 002.871 1.88c3.053 1.883 5.777 3.563 8.927 4.175 2.703.526 5.93.283 10.047-1.977l-7.815-33.5-42.726 15.551zm30.5 60.199l-21.932-43.287c1.742-1.535 3.288-2.365 4.634-2.773 1.691-.513 3.28-.425 4.872.05 1.641.49 3.27 1.387 4.974 2.494.745.484 1.47.986 2.213 1.5l.309.213a59.324 59.324 0 003.257 2.129l.006.004c2.887 1.788 6.431 3.983 10.611 4.796 3.748.729 7.826.337 12.385-1.856l6.228 26.7-27.557 10.03zm-35.731-64.68l49.084-17.865a3 3 0 013.948 2.138l16.704 71.605a3 3 0 01-1.896 3.5l-32.557 11.85a2.999 2.999 0 01-3.702-1.463l-33.23-65.59a3 3 0 011.649-4.175zm-67.924 27.812l-6.26 26.838 27.557 10.03 22.027-43.477c-1.652-1.414-3.125-2.192-4.415-2.583-1.691-.513-3.28-.425-4.871.05-1.642.49-3.27 1.387-4.974 2.494-.746.484-1.471.986-2.214 1.5l-.309.213a58.887 58.887 0 01-3.261 2.132l-.002.001c-2.886 1.788-6.43 3.983-10.61 4.796-3.826.744-7.995.32-12.668-1.994zm1.292-5.537l7.779-33.345L84.05 81.503l-5.93 11.707c-1.78-1.356-3.564-2.287-5.36-2.832-2.893-.876-5.597-.686-8.04.044-2.396.714-4.546 1.947-6.4 3.153-.816.53-1.61 1.08-2.347 1.59l-.303.209a53.562 53.562 0 01-2.872 1.88c-3.053 1.883-5.776 3.563-8.926 4.175-2.764.537-6.075.271-10.326-2.132zm6.652-40.14l49.084 17.865a3 3 0 011.65 4.175l-33.231 65.59A3 3 0 0154 148.25L21.442 136.4a3 3 0 01-1.895-3.5L36.25 61.295a3 3 0 013.948-2.138zm66.301-6.559a3 3 0 115.196 3l-5 8.66a3 3 0 11-5.196-3l5-8.66zm-14.209 52.428a3 3 0 00-3.845 1.793l-3.42 9.397a3 3 0 005.638 2.052l3.42-9.397a3 3 0 00-1.793-3.845zm-13.79-49.428a3 3 0 115.195-3l5 8.66a3 3 0 01-5.196 3l-5-8.66zm21.318 49.428a3 3 0 00-1.793 3.845l3.42 9.397a3 3 0 005.638-2.052l-3.42-9.397a3 3 0 00-3.845-1.793z"
            clipRule="evenodd"
            key="key-0"
        />
    </Fragment>
);

const defaultProps = {
    children,
    viewBox,
    fillColor: null,
    fillColorRule: "&&& path, &&& use, &&& g",
    sizes,
    size: null
};

Image.propTypes /* remove-proptypes */ = {
    fillColor: PropTypes.string,
    fillColorRule: PropTypes.string,
    viewBox: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    size: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            height: PropTypes.number.isRequired,
            width: PropTypes.number.isRequired
        })
    ]),
    sizes: PropTypes.shape({
        height: PropTypes.number,
        width: PropTypes.number
    })
};

export default Object.assign(Image, {
    getDimensions,
    getCss,
    defaultProps,
    displayName: "Cheers"
});
