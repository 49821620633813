export const getTagObjects = uploads => {
    // no uploads to work with
    if (!uploads || uploads.length === 0) {
        return [];
    }

    // If more than one, we only show shared tags across all selected uploads
    let commonTagObjects = [];

    /**
     * Filter and Evaluate Common Tags
     * only add tags that are shared by all uploads selected
     * Set to rejected if any tags are NOT approved. Set to approved if ALL ARE approved.
     * Then if they approve, it would just approve that tag for all of the uploads.
     * Behind the scenes the originally approved tags would remain approved, unless moderator clicked on the approval checkbox
     */
    // use first upload as template, if any of it's tags are in all the other uploads - add to commonTagObjects
    const firstUpload = uploads[0];
    firstUpload.manageTags.forEach(tagObj => {
        const thisTagName = tagObj.name;
        let isSharedByAll = true;
        let isApproved = tagObj.isApproved;
        uploads.forEach(uwt => {
            const hasTagName = uwt.manageTags.find(to => {
                return to.name === thisTagName;
            });
            if (hasTagName) {
                // Set to rejected if any tags are NOT approved. Set to approved if ALL ARE approved.
                if (isApproved && !hasTagName.isApproved) {
                    isApproved = false;
                }
            } else {
                isSharedByAll = false;
            }
        });

        if (isSharedByAll) {
            commonTagObjects.push({ ...tagObj, isApproved });
        }
    });

    return commonTagObjects;
};

export const toTitleCase = str => {
    return str.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
};

const splitCsvAndTrim = str => {
    return str.split(",").map(item => item.trim());
};

const removeDuplicates = arr => {
    let newArr = [];

    // remove duplicates
    arr.forEach(str => {
        if (!newArr.includes(str)) {
            newArr.push(str);
        }
    });

    return newArr;
};

export const getTagsFromInput = inputText => {
    let tags = splitCsvAndTrim(inputText);
    return removeDuplicates(tags);
};
