import React from "react";
import PropTypes from "prop-types";

// styles
import { ModeratorOptionChosenStyled } from "./styles.js";

const ModeratorOptionChosen = ({ moderator }) => {
    const { displayName, avatarUrl } = moderator;

    return (
        <ModeratorOptionChosenStyled>
            <img src={avatarUrl} alt={displayName} />
        </ModeratorOptionChosenStyled>
    );
};

export default ModeratorOptionChosen;

// prop-types
ModeratorOptionChosen.propTypes = {
    moderator: PropTypes.object
};
