import React from "react";
import { Redirect } from "react-router-dom";
import { useParams } from "react-router-dom";

import Royalties from "./royalties";
import Submissions from "./submissions";
import NotFound from "core/NotFound";

import { CREATOR_DASHBOARD_PHOTOS_DEFAULT } from "data/links";

const CreatorDashboardPhotosContent = () => {
    const { username, pageContent } = useParams();

    if (!pageContent) return <Redirect to={CREATOR_DASHBOARD_PHOTOS_DEFAULT} />;

    if (pageContent === "photo-royalties") {
        return <Royalties username={decodeURIComponent(username)} />;
    } else if (
        pageContent === "photos-pending" ||
        pageContent === "photos-denied"
    ) {
        return <Submissions />;
    } else {
        return <NotFound />;
    }
};

export default CreatorDashboardPhotosContent;
