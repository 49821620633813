import React, { useCallback } from "react";
import PropTypes from "prop-types";

// components
import Template from "../Template.js";
import FormSelectDialog, { defaultTheme } from "core/FormSelectDialog";

// styles
import { convertPxToRem } from "frontend/components/helpers";
import { ModeratorStyled } from "./styles";

// utils
import * as Utils from "./utils.js";

const Moderator = ({
    batchId,
    onModeratorSelect,
    moderator,
    moderators = [],
    filteredModerators = [],
    currentUser,
    isEditable = true
}) => {
    const onSelect = chosenModerator => {
        onModeratorSelect(chosenModerator.id);
    };

    const mods = !isEditable ? moderators : filteredModerators;

    // get options
    const getOptions = useCallback(() => Utils.getOptions(mods, currentUser), [
        mods
    ]);

    const options = getOptions();

    const getSelectedOption = useCallback(
        () => Utils.getSelectedOption(moderator, options),
        [options, moderator]
    );

    const selectedOption = getSelectedOption();

    const createTheme = () => {
        let dt = defaultTheme();
        return {
            ...dt,
            control: {
                ...dt.control,
                backgroundColor: "transparent",
                width: convertPxToRem(200),
                height: convertPxToRem(25),
                border: "none",
                gap: convertPxToRem(8),
                padding: {
                    left: "0rem",
                    right: "0rem"
                }
            },
            dialog: {
                ...dt.dialog,
                width: convertPxToRem(250),
                zIndex: 75
            }
        };
    };

    return (
        <Template title="Mod" titleFull="Moderators" width={200}>
            <ModeratorStyled data-testid="batch-moderator">
                <FormSelectDialog
                    id={`form-select-moderator-${batchId}`}
                    theme={createTheme}
                    options={options}
                    selectedOption={selectedOption}
                    setSelectedOption={onSelect}
                    controlType="edit"
                    isDisabled={!isEditable}
                />
            </ModeratorStyled>
        </Template>
    );
};

export default Moderator;

// prop-types
Moderator.propTypes = {
    batchId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    onModeratorSelect: PropTypes.func.isRequired,
    moderator: PropTypes.object,
    moderators: PropTypes.array.isRequired,
    filteredModerators: PropTypes.array.isRequired,
    currentUser: PropTypes.object.isRequired,
    isEditable: PropTypes.bool
};
