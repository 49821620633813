import styled from "styled-components";

/* hides text in UI and shows it to a screen reader, hence the name "SrOnly" = "Screen Reader Only": */
export const srOnlyStyles = `
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap; /* added line */
    border: 0;
`;

const SrOnly = styled.span`
    ${srOnlyStyles};
`;

export default SrOnly;
