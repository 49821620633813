import { useContext, useState, useEffect } from "react";
import { cuuid } from "core/helpers";

// context
import MessageStackContext from "core/MessageStack/context";
import * as Actions from "core/MessageStack/context/actions";
import { sampleMessages } from "./__stories__/sampleMessages";

export const useMessageStack = ({
    onMessagesAdded,
    onMessagesCleared,
    showSampleMessages = false
} = {}) => {
    const [hasMessages, hasMessagesUpdate] = useState(false);

    const {
        state: { messages },
        dispatch
    } = useContext(MessageStackContext);

    useEffect(() => {
        if (messages.length) {
            hasMessagesUpdate(true);
            onMessagesAdded?.(messages);
        }

        if (hasMessages && !messages.length) {
            hasMessagesUpdate(false);
            onMessagesCleared?.();
        }
    }, [messages, hasMessages]);

    const addMessages = messages => {
        dispatch(
            Actions.addMessages(
                messages.map(message => ({
                    ...message,
                    // if consumer doesn't provide an id, generate one
                    id: message.id || cuuid(10)
                }))
            )
        );
    };

    useEffect(() => {
        if (showSampleMessages) {
            addMessages(sampleMessages);
        }
    }, []);

    return {
        messages,
        hasMessages,
        addMessages
    };
};
