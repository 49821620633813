//
// Helper function to return the value of a cookie
//
// @example
//   getCookie('csrftoken')
//

let getCookie;

getCookie = function(name) {
    let cookie, cookieValue, cookies, i;
    cookieValue = null;
    if (document.cookie && document.cookie !== "") {
        cookies = document.cookie.split(";");
        i = 0;
        while (i < cookies.length) {
            cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === `${name}=`) {
                cookieValue = decodeURIComponent(
                    cookie.substring(name.length + 1)
                );
                break;
            }
            i++;
        }
    }
    return cookieValue;
};

export default getCookie;
