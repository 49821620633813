import React, { createContext, useContext, useReducer, useMemo } from "react";
import PropTypes from "prop-types";

import { usePhotoUpload } from "frontend/hooks/usePhotoUpload";

/*---------------------------
| Select Context
---------------------------*/
export const SelectContext = createContext();
import reducer from "./reducer";
import getDefaultState from "./getDefaultState";
import * as Actions from "./actions";
import * as Utils from "./utils";

/*---------------------------
| Components
---------------------------*/
import MountingWrapper from "./MountingWrapper";

/*---------------------------
| Providers
---------------------------*/
// Default
export const SelectProvider = ({
    children,
    uploadIds = [],
    defaultUploadIdsSelected = [],
    selectNextPrevEnabled = false
}) => {
    const defaultState = {
        uploadIds
    };

    const initialArg = getDefaultState(defaultState, defaultUploadIdsSelected);
    const [state, dispatch] = useReducer(reducer, initialArg);

    // useMemo so it does not pass value on every render
    const value = useMemo(
        () => ({
            state,
            dispatch,
            selectNextPrevEnabled
        }),
        [state, dispatch, selectNextPrevEnabled]
    );

    return (
        <SelectContext.Provider value={value} displayName={"SelectContext"}>
            <MountingWrapper consumerUploadIds={uploadIds}>
                {children}
            </MountingWrapper>
        </SelectContext.Provider>
    );
};

SelectProvider.propTypes = {
    children: PropTypes.any,
    uploadIds: PropTypes.array.isRequired,
    defaultUploadIdsSelected: PropTypes.array,
    selectNextPrevEnabled: PropTypes.bool
};

// DropZone Provider
export const SelectProviderForDropzone = ({
    children,
    selectedUploadIds = []
}) => {
    const { editableUploads } = usePhotoUpload();
    return (
        <SelectProvider
            uploadIds={editableUploads.map(u => u.id)}
            defaultUploadIdsSelected={selectedUploadIds}
        >
            {children}
        </SelectProvider>
    );
};

SelectProviderForDropzone.propTypes = {
    children: PropTypes.any,
    selectedUploadIds: PropTypes.array
};

/*---------------------------
| Hooks
---------------------------*/
// useSelect
export const useSelect = () => {
    const { state, dispatch, selectNextPrevEnabled } = useContext(
        SelectContext
    );
    const { uploadsSelected } = state;
    const uploadCount = Utils.getUploadIds(uploadsSelected).length;

    const onClick = (e, id, gridIndex) => {
        // Only Range select if there are more than 2 uploads in grid
        if (uploadCount > 2 && e.shiftKey) {
            return dispatch(
                Actions.selectDeselectRange({
                    id,
                    gridIndex
                })
            );
        }
        dispatch(Actions.toggleId(id, gridIndex));
    };
    const updateUploadIds = uploadIds => {
        dispatch(Actions.updateUploadIds(uploadIds));
    };
    const selectAll = () => {
        dispatch(Actions.selectAll());
    };
    const deselectAll = () => {
        dispatch(Actions.deselectAll());
    };
    const selectIds = ids => {
        dispatch(Actions.selectIds(ids));
    };
    const deselectIds = ids => {
        dispatch(Actions.deselectIds(ids));
    };
    const selectNext = () => {
        dispatch(Actions.selectNext());
    };
    const selectPrev = () => {
        dispatch(Actions.selectPrev());
    };

    return {
        ...state,
        selectNextPrevEnabled,
        onClick,
        updateUploadIds,
        getSelectedUploadIds: Utils.getSelectedUploadIds,
        selectAll,
        deselectAll,
        selectNext,
        selectPrev,
        selectIds,
        deselectIds // not currently being used
    };
};
