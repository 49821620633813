export const DEBUG = false;
export const MOCK_IS_SUCCESS = true;

export const mockApiCall = ({
    title,
    success = true,
    photoUploadIds = [],
    timer = 2000
}) => {
    const repsonseTemplate = {
        success,
        message: !success ? `We were unable to ${title} Photo Uploads` : ""
    };

    const delay = resp => {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(resp);
            }, timer);
        });
    };

    return async () => {
        let promise = await delay(repsonseTemplate);

        return promise;
    };
};
