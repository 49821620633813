import styled, { css } from "styled-components";

import breakpoints from "core/breakpoints";

import {
    getWidthPercentage,
    getDividedSpacing,
    getBreakpointCss
} from "./helpers";
import { convertPxToRem } from "frontend/components/helpers";

export const GridItem = styled.div`
    ${({ lg, md, sm }) => css`
        /* Desktop (also applies to tablet & mobile if tablet & mobile sizes are not defined):  */
        flex-basis: ${getWidthPercentage({ col: lg })};
        max-width: ${getWidthPercentage({ col: lg })};
        flex-grow: 0;

        /* Tablet: */
        // if md col exists, render md col width:
        ${md &&
            css`
                @media (max-width: ${breakpoints.beforeDesktop.rem}) {
                    flex-basis: ${getWidthPercentage({ col: md })};
                    max-width: ${getWidthPercentage({ col: md })};
                }
            `}


        /* Mobile: */
        // if sm col exists, render md col width:
        ${sm &&
            css`
                @media (max-width: ${breakpoints.beforeTablet.rem}) {
                    flex-basis: ${getWidthPercentage({ col: sm })};
                    max-width: ${getWidthPercentage({ col: sm })};
                }
            `}
    `}
`;

export const GridContainer = styled.div`
    ${({
        desktopSpacing,
        tabletSpacing,
        mobileSpacing,
        justifyContent,
        alignItems,
        alignContent
    }) => css`
        
        ${justifyContent &&
            css`
                justify-content: ${justifyContent};
            `}

        ${alignItems &&
            css`
                align-items: ${alignItems};
            `}

        ${alignContent &&
            css`
                align-content: ${alignContent};
            `}
        
        /* Desktop (also applies to tablet & mobile if tablet & mobile sizes are not defined):  */
        display: flex;
        flex-wrap: wrap;
        box-sizing: border-box;

        width: calc(100% + ${convertPxToRem({ px: desktopSpacing })});
        margin: -${convertPxToRem({ px: desktopSpacing / 2 })};

        ${GridItem} {
            padding: ${getDividedSpacing({
                spacing: desktopSpacing
            })};
        }

        // Tablet:
        ${getBreakpointCss({
            spacing: tabletSpacing,
            breakpoint: breakpoints.beforeDesktop.rem,
            GridItem
        })};

        // Mobile:
        ${getBreakpointCss({
            spacing: mobileSpacing,
            breakpoint: breakpoints.beforeTablet.rem,
            GridItem
        })};
        
                  
    `}
`;
