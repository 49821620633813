import styled from "styled-components";

import BodyText from "core/BodyText";
import { getLinkElementStyles } from "core/Link/styles";
import colors from "core/colors";

import { convertPxToRem } from "frontend/components/helpers";

export const Paragraph = styled(BodyText).attrs({
    size: "lg",
    copyColor: colors.secondary.orca,
    type: "p"
})`
    padding-top: ${convertPxToRem({ px: 24 })};
`;
