import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { reusableComponentSizes } from "core/props";

import { getValidationError } from "core/Form/helpers";

import { FormElementContainer } from "core/Form";

import { IconContainer, Input, SuccessIcon } from "./styles";

let FormInput = ({
    className,
    inputRef,
    placeholderText,
    labelText,
    onChange,
    validation,
    darkMode = false,
    size = "md",
    width = "100%",
    isDisabled = false,
    type = "text",
    value = "",
    iconLeft,
    iconRight,
    onBlur,
    errorMessageOverride,
    hideLabel = false,
    hideErrorMessage = false,
    hideLabelOnBlur = true,
    hideErrorOnFocus = true,
    ...inputAttributes
}) => {
    const [errorMessage, setErrorMessage] = useState(null);
    const [success, setSuccess] = useState(false);
    const [focused, setFocused] = useState(false);

    const validateInput = input => {
        const validationError = getValidationError({ input, validation });
        setErrorMessage(validationError);
        setSuccess(validationError ? false : true);
    };

    const handleFocus = () => {
        setFocused(true);
    };

    const handleBlur = e => {
        setFocused(false);
        typeof onBlur === "function" && onBlur();
        if (validation) validateInput(e.target.value);
    };

    return (
        <FormElementContainer
            className={className}
            width={width}
            darkMode={darkMode}
            labelText={labelText}
            hideLabel={hideLabel || (hideLabelOnBlur && !focused)}
            hideErrorMessage={hideErrorMessage || (hideErrorOnFocus && focused)}
            errorMessage={errorMessageOverride || errorMessage}
        >
            {/* show left icon, dont show right icon */}
            {!!iconLeft && !iconRight && (
                <IconContainer size={size} iconLeft={iconLeft}>
                    {iconLeft}
                </IconContainer>
            )}

            <Input
                {...inputAttributes}
                ref={inputRef}
                type={type}
                width={width}
                placeholder={placeholderText}
                onChange={e => e.target && onChange(e.target.value)}
                onBlur={handleBlur}
                onFocus={handleFocus}
                value={value}
                darkMode={darkMode}
                size={size}
                success={success}
                iconLeft={iconLeft}
                iconRight={iconRight}
                disabled={isDisabled}
            />
            {/*  this is behind a bool because in the event <input type="number"/> this was making the native stepper un-clickable when false which is default */}
            {success && (
                <SuccessIcon
                    darkMode={darkMode}
                    size={size}
                    success={success}
                />
            )}

            {/* dont show left icon, show right icon, only show right icon if success is false */}
            {!iconLeft && !!iconRight && !success && (
                <IconContainer size={size} iconLeft={iconLeft}>
                    {iconRight}
                </IconContainer>
            )}
        </FormElementContainer>
    );
};

FormInput.propTypes = {
    inputRef: PropTypes.object,
    // placeholder and label text should be the same
    placeholderText: PropTypes.string.isRequired,
    labelText: PropTypes.string.isRequired,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    type: PropTypes.string,
    onChange: PropTypes.func,
    validation: PropTypes.arrayOf(
        PropTypes.shape({
            isValid: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
            errorMsg: PropTypes.string
        })
    ),
    readOnly: PropTypes.bool,
    // use for darkMode color styles
    darkMode: PropTypes.bool,
    size: PropTypes.oneOf(reusableComponentSizes),
    required: PropTypes.bool,
    iconLeft: PropTypes.any,
    iconRight: PropTypes.any,
    isDisabled: PropTypes.bool,
    errorMessageOverride: PropTypes.string,
    hideLabel: PropTypes.bool,
    hideErrorMessage: PropTypes.bool
};

FormInput = styled(FormInput)``;

export default FormInput;
