import styled from "styled-components";

// styles
import colors from "core/colors";
import BodyText from "core/BodyText";
import { convertPxToRem } from "frontend/components/helpers";
import { regular as regularFontWeight } from "core/fontStyles";

// styled-components
export const InactiveMessageStyled = styled.div`
    margin: 0px;
    padding: ${convertPxToRem(24)} ${convertPxToRem(16)};
    font-size: ${convertPxToRem(15)};
    ${regularFontWeight};
    color: ${colors.secondary.orca};
`;

export const SidebarContentText = styled(BodyText).attrs({
    type: "p",
    size: "md"
})`
    ${regularFontWeight}

    &:not(:last-of-type) {
        margin-bottom: ${convertPxToRem(16)};
    }
`;
