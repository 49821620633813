import { createSlice } from "@reduxjs/toolkit";

import { REDUCERS } from "../constants";

/**
 * Used to pop messages to message stack anywhere in Photo Mod.
 * Sample Message Object
 * Based on MessageStack shape: frontend/components/core/MessageStack
 * {
 *      id: 123, // needs unique id so we remove the right one when done.
 *      text: "Visual review complete",
 *      timed: true,
 *      timeout: 5000,
 *      infoType: "success"
 * }
 */
export const messagesSlice = createSlice({
    name: REDUCERS.MESSAGES,
    initialState: {
        messages: []
    },
    reducers: {
        addMessage: (state, action) => {
            state.messages = [...state.messages, action.payload];
        },
        removeMessage: (state, action) => {
            state.messages = state.messages.filter(
                m => m.id !== action.payload
            );
        }
    }
});

export default messagesSlice.reducer;
