import React from "react";
import styled from "styled-components";

import colors from "core/colors";
import { convertPxToRem } from "frontend/components/helpers";
import { rapidEasing } from "core/animation";
import { focusOutline } from "core/links";

// context
import { useInlineTextEdit } from "core/InlineTextEdit/index.js";

export const DisplayStyled = styled.div`
    ${({ isEditing }) => isEditing && `display: none;`}
    border: ${convertPxToRem(1)} solid transparent;
    border-left: none;
    padding: ${convertPxToRem(3)} 0px 0px 0px;
    min-height: ${convertPxToRem(28)};
`;
export const DisplayTextStyled = styled.span`
    display: inline;
    font-size: ${convertPxToRem(15)};

    font-style: ${({ isPlaceholder }) => (isPlaceholder ? "italic" : "normal")};
`;

export const EditButtonStyled = styled.button`
    cursor: pointer;
    background: transparent;
    margin: 0 0 0 ${convertPxToRem(8)};
    padding: 0;
    border: 0;
    font-size: 0;
    line-height: 0;

    position: relative;
    top: 3px;

    svg {
        transition: all ${rapidEasing};

        path {
            fill: ${colors.accent.lightMode.persian};
        }
    }

    &:hover {
        svg path {
            fill: ${colors.secondary.oolong};
        }
    }

    &:focus {
        ${focusOutline}
    }

    &:disabled {
        svg path {
            fill: ${colors.secondary.dolphin};
        }
    }

    &:disabled:hover {
        cursor: not-allowed;
    }
`;

// components
import EditIcon from "core/ReusableIcons/Edit";

const Display = () => {
    const {
        text,
        isEditing,
        dispatch,
        actions,
        editButtonRef,
        placeholder,
        disabled
    } = useInlineTextEdit();

    const onClick = e => {
        if (!disabled) {
            dispatch(actions.isEditingUpdate(true));
        }
    };

    return (
        <DisplayStyled onClick={onClick} isEditing={isEditing}>
            <DisplayTextStyled
                isPlaceholder={placeholder && !text}
                data-testid={"inline-edit-text"}
            >
                {text || placeholder}
            </DisplayTextStyled>
            <EditButtonStyled
                type="button"
                data-testid="edit-text-icon-button"
                aria-label="Edit text"
                ref={editButtonRef}
                disabled={disabled}
            >
                <EditIcon size="sm" placeholder={placeholder} />
            </EditButtonStyled>
        </DisplayStyled>
    );
};

export default Display;
