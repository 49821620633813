// helpers
import { handleError } from "./errorHandlers";
import { getEnhancedResponse } from "./responseHandlers";

export const apolloApi = ({
    actionDesc = "Make Apollo Gql Api Call",
    apiCall,
    gql,
    errorHandler,
    successMessageOverride,
    errorMessageOverride
}) => {
    const apolloCall = async gqlParams => {
        const props = {
            gqlParams,
            actionDesc,
            successMessageOverride,
            errorMessageOverride,
            gql
        };

        try {
            const ssResp = await apiCall(gqlParams);
            const enhancedResponse = getEnhancedResponse({
                ...props,
                ssResp
            });
            return enhancedResponse.success
                ? enhancedResponse
                : handleError(enhancedResponse, errorHandler);
        } catch (ssResp) {
            const enhancedResponse = getEnhancedResponse({
                ...props,
                ssResp
            });
            enhancedResponse.message = `Catch Error: ${enhancedResponse.message}`;
            return handleError(enhancedResponse);
        }
    };

    return apolloCall;
};
