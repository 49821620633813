import styled from "styled-components";
import BodyText from "core/BodyText";

import { convertPxToRem } from "frontend/components/helpers";
import colors, { focusColor } from "core/colors";
import { easingSeconds } from "core/animation";
import breakpoints from "core/breakpoints";

export const TabContainer = styled.button`
    border: none;
    background-color: transparent;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    padding: ${convertPxToRem({ px: 10 })} 0 ${convertPxToRem({ px: 16 })};
    cursor: ${({ isActive }) => (isActive ? "default" : "pointer")};
    border-bottom-width: 3px;
    border-bottom-style: solid;

    &:focus {
        outline: 3px solid ${focusColor};
        transition: all ${easingSeconds};
    }

    &,
    &:hover,
    &:visited {
        border-bottom-color: ${({ isActive }) =>
            isActive ? colors.secondary.orca : "transparent"};
    }

    &:not(:last-child) {
        margin-right: ${convertPxToRem({ px: 42.5 })};
    }

    @media (max-width: ${breakpoints.beforeTablet.rem}) {
        &:not(:last-child) {
            margin-right: ${convertPxToRem({ px: 40 })};
        }
    }

    transition: all ${easingSeconds};
`;

export const TabText = styled(BodyText).attrs({ size: "lg" })`
    margin-left: ${convertPxToRem({ px: 10.5 })};

    @media (max-width: ${breakpoints.beforeTablet.rem}) {
        margin-left: 0;
    }
`;

export const TabsContainer = styled.div`
    box-shadow: inset 0px -1px 0px ${colors.primary.scheifer};

    @media (max-width: ${breakpoints.beforeTablet.rem}) {
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }

        white-space: nowrap;
        overflow-x: scroll;
    }
`;
