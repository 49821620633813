import { actionTypes } from "./actionTypes";
/*---------------------------
| Reducer
---------------------------*/
export default (state, action) => {
    switch (action.type) {
        case actionTypes.MODAL2_IS_VISIBLE_UPDATE: {
            return { ...state, isVisible: action.isVisible };
        }
        default: {
            return { ...state };
        }
    }
};
