import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { useDispatch, useSelector } from "react-redux";

// redux
import { blurUpload } from "../../slices/uploadFooterActionsSlice";

// config
import { moderatorIconKeys } from "../../config.js";

// hooks
import { useBatchUploads } from "../../hooks";

// components
import LoadingSpinner from "core/LoadingSpinner";

// styles
import colors from "core/colors";
import { convertPxToRem } from "frontend/components/helpers";

export const UploadImageStyled = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;

const ImageStyled = styled.img`
    display: ${({ isReady }) => (isReady ? "block" : "none")};

    /* center absolutely positioned asset */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;

    /* resize asset so longest side spans parent container */
    max-height: 100%;
    max-width: 100%;

    background-color: ${colors.primary.whisker};

    ${({ imageIsBlurred }) => {
        if (imageIsBlurred) {
            return css`
                filter: blur(${convertPxToRem(15)});
            `;
        }
    }}
`;

const LoadingSpinnerWrapperStyled = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

// component
export const UploadImage = ({ uploadId, isLarge = false }) => {
    const dispatch = useDispatch();
    const [hasLoaded, setHasLoaded] = useState(false);
    const { getUploadEntity, getUploadImages } = useBatchUploads();
    const uploadEntity = getUploadEntity(uploadId);
    const uploadImages = getUploadImages(uploadEntity);
    const uploadImage = isLarge ? uploadImages.large : uploadImages.small;
    const { url } = uploadImage;
    const footerActions =
        useSelector(state => state.uploadFooterActions.entities[uploadId]) ||
        {};

    const onLoad = () => {
        setHasLoaded(true);
    };

    // side effects
    useEffect(() => {
        const previouslyBlurredUploads =
            JSON.parse(localStorage.getItem("modBlurredUploads")) || [];

        if (previouslyBlurredUploads.includes(uploadId)) {
            dispatch(blurUpload(uploadId));
        }
    }, []);

    useEffect(() => {
        if (uploadEntity?.autoNudity || uploadEntity?.autoViolence) {
            dispatch(blurUpload(uploadId));
        }
    }, [uploadEntity]);

    // render
    const isReady = url && hasLoaded;

    return (
        <UploadImageStyled>
            {!isReady && (
                <LoadingSpinnerWrapperStyled>
                    <LoadingSpinner />
                </LoadingSpinnerWrapperStyled>
            )}
            <ImageStyled
                src={url}
                imageIsBlurred={footerActions[moderatorIconKeys.BLUR]}
                onLoad={onLoad}
                isReady={isReady}
            />
        </UploadImageStyled>
    );
};

export default UploadImage;

// prop-types
UploadImage.propTypes = {
    uploadId: PropTypes.string.isRequired,
    isLarge: PropTypes.bool
};
