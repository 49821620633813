import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useParams, useHistory } from "react-router-dom";

import ButtonGroup from "core/ButtonGroup";

import {
    CREATOR_DASHBOARD_PHOTOS_PENDING,
    CREATOR_DASHBOARD_PHOTOS_DENIED
} from "data/links";

let SubmissionsHeaderButtons = ({
    className,
    creatorUsername,
    pendingCount,
    deniedCount
}) => {
    const history = useHistory();
    const { pageContent } = useParams();

    return (
        <ButtonGroup
            className={className}
            buttons={[
                {
                    label: `${pendingCount} Awaiting Moderation`,
                    isActive: pageContent === "photos-pending",
                    onClick: e => {
                        e.preventDefault();

                        history.push(
                            CREATOR_DASHBOARD_PHOTOS_PENDING.replace(
                                ":username",
                                creatorUsername
                            )
                        );
                    }
                },
                {
                    label: `${deniedCount} Denied`,
                    isActive: pageContent === "photos-denied",
                    onClick: e => {
                        e.preventDefault();

                        history.push(
                            CREATOR_DASHBOARD_PHOTOS_DENIED.replace(
                                ":username",
                                creatorUsername
                            )
                        );
                    }
                }
            ]}
        />
    );
};

SubmissionsHeaderButtons = styled(SubmissionsHeaderButtons)``;

SubmissionsHeaderButtons.propTypes = {
    creatorUsername: PropTypes.string.isRequired,
    pendingCount: PropTypes.number.isRequired,
    deniedCount: PropTypes.number.isRequired
};

export default SubmissionsHeaderButtons;
