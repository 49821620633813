import React, { useState } from "react";
import styled from "styled-components";

import LicenseSection from "./LicenseSection";
import TitleSection from "./TitleSection";
import RecognizablePeopleOrPropertySection from "./RecognizablePeopleOrPropertySection";
import ReleasesSection from "./ReleasesSection";
import EditorialUseOnlySection from "./EditorialUseOnlySection";
import AddTagsSection from "./AddTagsSection";
import LocationSection from "./LocationSection";
import { useEditPhotoUpload } from "./EditPhotoUploadContext";
import { convertPxToRem } from "frontend/components/helpers";

const Note = styled.p`
    margin-top: ${convertPxToRem({ px: 24 })};
    font-weight: bold;
    font-size: 1rem;
`;

const EditModalContentRightCol = () => {
    const [
        isRecognizablePeoplePropertyChecked,
        setIsRecognizablePeoplePropertyChecked
    ] = useState(false);

    const [isEditorialUseChecked, setIsEditorialUseChecked] = useState(false);

    const { uploads } = useEditPhotoUpload();

    return (
        <>
            <LicenseSection />
            {/* user can edit only one upload title at a time */}
            {uploads.length === 1 && <TitleSection upload={uploads[0]} />}

            <RecognizablePeopleOrPropertySection
                setIsChecked={setIsRecognizablePeoplePropertyChecked}
                isChecked={isRecognizablePeoplePropertyChecked}
            />
            <ReleasesSection
                isRecognizablePeoplePropertyChecked={
                    isRecognizablePeoplePropertyChecked
                }
                isEditorialUseChecked={isEditorialUseChecked}
            />
            <EditorialUseOnlySection
                setIsChecked={setIsEditorialUseChecked}
                isChecked={isEditorialUseChecked}
            />
            <AddTagsSection />

            <LocationSection />

            <Note>Note: Changes are saved automatically.</Note>
        </>
    );
};

export default EditModalContentRightCol;
