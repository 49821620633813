import React from "react";
import PropTypes from "prop-types";

import Link from "core/Link";
import Menu from "./Menu";
import { Item, Heading } from "js/components/ProfileMenuDesktop";

const LinkComponent = ({ children, href, target, isHeroSearchV2 }) => {
    return (
        <Item>
            <Link
                href={href}
                target={target}
                size="md"
                styleType="secondary"
                darkMode={!isHeroSearchV2}
                disableVisitedStyle
            >
                {children}
            </Link>
        </Item>
    );
};

const CreatorProfileMenuDesktop = ({
    creatorUsername,
    isPhotoCreator,
    isIconCreator,
    isHeroSearchV2
}) => {
    if (!isIconCreator && !isPhotoCreator) return null;

    return (
        <>
            <Heading>Creator Tools</Heading>
            <Menu
                linkComponent={LinkComponent}
                creatorUsername={creatorUsername}
                isPhotoCreator={isPhotoCreator}
                isIconCreator={isIconCreator}
                isHeroSearchV2={isHeroSearchV2}
            />
        </>
    );
};

CreatorProfileMenuDesktop.propTypes = {
    creatorUsername: PropTypes.string.isRequired,
    isPhotoCreator: PropTypes.bool.isRequired,
    isIconCreator: PropTypes.bool.isRequired,
    isHeroSearchV2: PropTypes.bool
};

export default CreatorProfileMenuDesktop;
