import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import colors from "core/colors";

const createHelpers = (width, height, css) => {
    // somehow sizes is ending up in markup, even if it is not a valid svg attribute
    // until we have a better solution, just render it empty, instead to '[Object object]'
    const sanitizeSizes = sizes =>
        Object.defineProperty(sizes, "toString", {
            value: () => "",
            enumerable: false
        });

    const getDimensions = (size, sizes) => {
        if (
            size &&
            typeof size.width === "number" &&
            typeof size.height === "number"
        ) {
            return size;
        }

        return size && sizes[size] ? sizes[size] : { width, height };
    };

    const getCss = (size, sizes, fillColor, fillColorRule, noStyles) => {
        if (noStyles) {
            return "";
        }

        const dimensions = getDimensions(size, sizes);
        const fillRule =
            fillColor && fillColorRule
                ? `${fillColorRule}{ fill: ${fillColor}; }`
                : "";
        return css`
            width: ${dimensions.width}px;
            height: ${dimensions.height}px;
            ${fillRule}
        `;
    };

    const propsToCss = ({ size, sizes, fillColor, fillColorRule, noStyles }) =>
        getCss(size, sizes, fillColor, fillColorRule, noStyles);

    return {
        getCss,
        getDimensions,
        propsToCss,
        sanitizeSizes
    };
};

const width = "24";
const height = "24";
const viewBox = "0 0 24 24";

const { getDimensions, getCss, propsToCss, sanitizeSizes } = createHelpers(
    width,
    height,
    css
);

const sizes = sanitizeSizes({
    xs: { width: 14, height: 14 },
    sm: { width: 16, height: 16 },
    md: { width: 20, height: 20 },
    lg: { width: 24, height: 24 },
    xl: { width: 40, height: 40 }
});

const Image = styled.svg`
    ${propsToCss}
`;

const children = (
    <Fragment>
        <path
            fill={colors.secondary.orca}
            fillRule="evenodd"
            d="M11.996 4.621c2.404 0 2.686.011 3.636.054.879.04 1.354.186 1.672.31.421.165.721.358 1.035.672.315.314.511.614.672 1.036.121.318.271.793.31 1.671.043.95.054 1.232.054 3.636s-.01 2.686-.054 3.636c-.039.878-.185 1.353-.31 1.671a2.792 2.792 0 01-.672 1.036 2.75 2.75 0 01-1.035.671c-.318.122-.793.272-1.672.311-.95.043-1.232.054-3.636.054-2.403 0-2.685-.011-3.635-.054-.879-.04-1.354-.186-1.672-.31a2.793 2.793 0 01-1.035-.672 2.749 2.749 0 01-.672-1.036c-.121-.318-.271-.793-.31-1.671-.043-.95-.054-1.232-.054-3.636s.01-2.686.053-3.636c.04-.878.186-1.353.311-1.671.164-.422.357-.722.672-1.036.314-.314.614-.51 1.035-.671.318-.122.793-.272 1.672-.311.95-.046 1.232-.054 3.635-.054zm0-1.621c-2.442 0-2.75.01-3.71.054-.957.042-1.611.196-2.182.417a4.385 4.385 0 00-1.593 1.04c-.5.5-.807 1.003-1.04 1.593-.221.571-.375 1.225-.417 2.185C3.01 9.246 3 9.554 3 11.996c0 2.443.01 2.75.054 3.711.042.957.196 1.61.417 2.186.229.593.54 1.093 1.04 1.593s1.003.807 1.593 1.039c.571.221 1.225.375 2.185.418.961.043 1.265.053 3.711.053 2.446 0 2.75-.01 3.71-.053.958-.043 1.611-.197 2.186-.418a4.385 4.385 0 001.593-1.04c.5-.5.807-1.003 1.04-1.592.221-.572.375-1.225.417-2.186.043-.96.054-1.264.054-3.71 0-2.447-.01-2.75-.054-3.711-.042-.957-.196-1.611-.417-2.186a4.386 4.386 0 00-1.04-1.593c-.5-.5-1.003-.807-1.593-1.04-.571-.22-1.225-.374-2.185-.417-.965-.04-1.272-.05-3.715-.05zm0 4.375a4.622 4.622 0 000 9.243 4.625 4.625 0 004.622-4.622 4.624 4.624 0 00-4.622-4.621zm0 7.621a3 3 0 110-5.999 3 3 0 010 6zm5.883-7.803a1.079 1.079 0 11-2.158 0 1.079 1.079 0 012.158 0z"
            clipRule="evenodd"
            key="key-0"
        />
    </Fragment>
);

const defaultProps = {
    children,
    viewBox,
    fillColor: null,
    fillColorRule: "&&& path, &&& use, &&& g, &&& circle",
    sizes,
    size: null
};

Image.propTypes /* remove-proptypes */ = {
    fillColor: PropTypes.string,
    fillColorRule: PropTypes.string,
    viewBox: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    size: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            height: PropTypes.number.isRequired,
            width: PropTypes.number.isRequired
        })
    ]),
    sizes: PropTypes.shape({
        height: PropTypes.number,
        width: PropTypes.number
    })
};

export default Object.assign(Image, {
    getDimensions,
    getCss,
    defaultProps,
    displayName: "Instagram"
});
