import React from "react";

import SegmentedControlResults from "core/SegmentedControlResults";
import SearchEmptyResults from "core/SearchEmptyResults";
import Grid from "./Grid";
import SearchHeader from "icons/SearchHeader";
import useLoadMore from "frontend/hooks/useLoadMore";

import { PHOTO_SEARCH } from "js/graphql";
import { CONTENT_TYPE } from "core/constants";

const SearchPhotosResults = ({ isActive, query, searchType }) => {
    const {
        items,
        totalCount,
        firstBatchFetched,
        loadFirstBatchLazy,
        loadingFirstBatch,
        LoadMore
    } = useLoadMore({
        limit: 50,
        query: PHOTO_SEARCH,
        queryVariables: { query },
        pathToListKey: "photoSearch.items",
        pathToTotalCountKey: "photoSearch.totalCount",
        fetchItemsOnLoad: false,
        pageQueryParamKey: "photospage",
        useLazyFirstLoad: true,
        contentType: CONTENT_TYPE.photo
    });

    const showEmptySearchResultsPage =
        !loadingFirstBatch && totalCount === 0 && isActive && firstBatchFetched;

    const allItemsLoaded = items && items.length >= totalCount;
    const showLoadMoreButton = !allItemsLoaded && !!items.length;

    return (
        <SegmentedControlResults
            isActive={isActive}
            firstBatchFetched={firstBatchFetched}
            loadFirstBatchLazy={loadFirstBatchLazy}
        >
            <SearchHeader
                query={query}
                searchType={searchType}
                showSkeleton={loadingFirstBatch}
            />

            {showEmptySearchResultsPage ? (
                <SearchEmptyResults
                    contentType={CONTENT_TYPE.photo}
                    query={query}
                />
            ) : (
                <Grid items={items} />
            )}

            {showLoadMoreButton && <LoadMore />}
        </SegmentedControlResults>
    );
};

export default SearchPhotosResults;
