import React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";

import { fullWidthStyles, Section } from "core/Layout";
import Flexbox from "core/Flexbox";
import { A, H2 } from "core/Typography";
import { OldGrid, Row, Col } from "core/oldGrid";
import colors from "core/colors";

import Close from "core/ReusableIcons/Close";

// header
export const HeaderContent = styled(Flexbox)``;

export const CloseIcon = styled(Close)`
    width: 1.25rem;
    cursor: pointer;
`;

// used for help in centering "Menu" text and right aligning close button
export const DummyElement = styled.div``;

export const Header = styled(Section)`
    ${fullWidthStyles}
    background-color: ${colors.primary.snow};
    padding-top: 1.1875rem;
    padding-bottom: 1.1875rem;
    border-bottom: 1px solid ${colors.secondary.oolong};
`;

// menu items
export const linkHeadingStyles = css`
    padding-top: 1.3125rem;
    padding-bottom: 0.8125rem;
    color: ${colors.secondary.oolong};
`;

export const LinkHeading = styled(({ className, children }) => (
    <Section>
        <ContainedGrid className={className}>
            <H2 size={12}>{children}</H2>
        </ContainedGrid>
    </Section>
))`
    ${linkHeadingStyles}
`;

export const ContainedGrid = styled(({ children, className }) => (
    <OldGrid className={className}>
        <Row>
            <Col sm={12}>{children}</Col>
        </Row>
    </OldGrid>
))``;

export const linkStyles = css`
    background-color: ${colors.primary.snow};
    ${fullWidthStyles}

    margin-bottom: 0.25rem;

    ${Section} {
        padding-top: 0.8125rem;
        padding-bottom: 0.75rem;
        ${fullWidthStyles}
    }

    a,
    a:any-link {
        text-decoration: none;
        color: ${colors.secondary.onyx};

        &:hover {
            color: ${colors.secondary.oolong};
        }
    }
`;

const Link = styled(({ children, className, ...anchorProps }) => (
    <li className={className}>
        <A {...anchorProps}>
            <Section>
                <ContainedGrid>{children}</ContainedGrid>
            </Section>
        </A>
    </li>
))`
    ${linkStyles}
`;

Link.propTypes = {
    href: PropTypes.string
};

export { Link };

export const Links = styled.ul``;

// general
export const openMobileMenuStyles = css`
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100vw;
    overflow-y: auto;
    overflow-x: hidden;
`;

export const Wrapper = styled.div`
    height: 100vh;
    background-color: ${colors.primary.diamond};
    color: ${colors.secondary.onyx};
`;

// for iOS scrolling:
export const MobileSpacing = styled.div`
    height: 125px;
`;
