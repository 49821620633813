import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

// components + styles:
import RadioInput from "core/RadioInput";
import SrOnly from "core/SrOnly";
import ButtonContent from "./ButtonContent";
import { Container, Options, Label } from "./styles";

const UnstyledRadioButtons = ({
    className,
    onClick,
    options,
    fieldsetTitle,
    radioName,
    isFluid = true,
    isFullWidth = false,
    width = "",
    defaultCheckedValue = ""
}) => {
    const [selectedValue, updateSelectedValue] = useState(defaultCheckedValue);

    const handleChange = e => {
        onClick(e.target.value);
        updateSelectedValue(e.target.value);
    };

    return (
        <Container className={className}>
            <SrOnly as="legend">{fieldsetTitle}</SrOnly>

            <Options
                role="radiogroup"
                isFullWidth={isFullWidth}
                onChange={handleChange}
            >
                {options.map((option, index) => {
                    const isSelected = option.value === selectedValue;
                    const [isFocused, updateFocused] = useState(false);

                    return (
                        <Label
                            key={index}
                            isFluid={isFluid}
                            isFullWidth={isFullWidth}
                            width={width}
                            isSelected={isSelected}
                            isFocused={isFocused}
                        >
                            <RadioInput
                                name={radioName}
                                value={option.value}
                                defaultChecked={isSelected}
                                onFocus={() => updateFocused(true)}
                                onBlur={() => updateFocused(false)}
                            />

                            <ButtonContent option={option} />
                        </Label>
                    );
                })}
            </Options>
        </Container>
    );
};

const RadioButtons = styled(UnstyledRadioButtons)``;

RadioButtons.propTypes = {
    onClick: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired,
            img: PropTypes.object
        })
    ).isRequired,
    fieldsetTitle: PropTypes.string.isRequired,
    radioName: PropTypes.string.isRequired,
    isFluid: PropTypes.bool,
    isFullWidth: PropTypes.bool,
    width: PropTypes.string,
    defaultCheckedValue: PropTypes.string
};

export default RadioButtons;
