import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import { useLocation, useHistory } from "react-router-dom";

import { ButtonGroup } from "core/ButtonGroup";
import Skeleton from "core/Skeleton";

import { GET_TOTAL_COUNTS_FOR_SEARCH_TYPES } from "./queries";

import { SEARCH_TYPES } from "./helpers";
import { SEARCH_ICONS, SEARCH_ICON_SETS } from "data/links";
import { convertPxToRem } from "frontend/components/helpers";

let SearchSegmentedControlButtons = ({
    className,
    query,
    activeSearchType,
    showSkeleton
}) => {
    const history = useHistory();
    const location = useLocation();

    const { data, loading } = useQuery(GET_TOTAL_COUNTS_FOR_SEARCH_TYPES, {
        variables: { query }
    });

    let iconsTotal = 0;
    let iconCollectionsTotal = 0;
    let photosTotal = 0;

    if (!loading && !!data) {
        iconsTotal = data && data.icons && data.icons.totalCount;
        iconCollectionsTotal =
            data && data.collections && data.collections.totalCount;
        photosTotal = data && data.photos && data.photos.totalCount;
    }

    const redirectTo = ({ pathname }) => () => {
        const newLocation = {
            ...location,
            pathname
        };

        history.push(newLocation);
    };

    const addPlural = ({ totalCount }) => (totalCount === 1 ? "" : "s");

    return showSkeleton ? (
        <Skeleton
            className={className}
            height={convertPxToRem({ px: 26 })}
            width={convertPxToRem({ px: 300 })}
        />
    ) : (
        <ButtonGroup
            className={className}
            buttons={[
                {
                    label: `${iconsTotal} Icon${addPlural({
                        totalCount: iconsTotal
                    })}`,
                    isActive: SEARCH_TYPES.ICONS === activeSearchType,
                    onClick: redirectTo({ pathname: SEARCH_ICONS })
                },
                {
                    label: `${iconCollectionsTotal} Icon Collection${addPlural({
                        totalCount: iconCollectionsTotal
                    })}`,
                    isActive:
                        SEARCH_TYPES.ICON_COLLECTIONS === activeSearchType,
                    onClick: redirectTo({
                        pathname: SEARCH_ICON_SETS
                    })
                },
                {
                    label: `${photosTotal} Photo${addPlural({
                        totalCount: photosTotal
                    })}`,
                    isActive: SEARCH_TYPES.PHOTOS === activeSearchType,
                    onClick: redirectTo({
                        pathname: "/search/photos/"
                    })
                }
            ]}
        />
    );
};

SearchSegmentedControlButtons.propTypes = {
    className: PropTypes.string,
    query: PropTypes.string.isRequired,
    activeSearchType: PropTypes.string.isRequired
};

SearchSegmentedControlButtons = styled(SearchSegmentedControlButtons)``;

export default SearchSegmentedControlButtons;
