import { useEffect, useState } from "react";

export const useKeyboardListener = () => {
    const [keyPressed, keyPressedUpdate] = useState("");
    const [evObj, evObjUpdate] = useState({});

    useEffect(() => {
        const onKeyDown = e => {
            evObjUpdate(e);
            keyPressedUpdate(e.key);
        };

        window.addEventListener("keydown", onKeyDown);

        return () => {
            window.removeEventListener("keydown", onKeyDown);
        };
    }, []);

    return { keyPressed, evObj };
};
