export const formatDataToAddSkeletonItems = ({
    iconData,
    loadingFirstBatch,
    loadingMore,
    loadingItemCount
}) => {
    let data = [];

    if (loadingFirstBatch) {
        data = getSkeletonGridData();
    }

    if (!loadingFirstBatch) {
        data = iconData;

        if (loadingMore) {
            data = [...iconData, ...getSkeletonGridData()];
        }
    }

    return data;

    function getSkeletonGridData() {
        // because we have id/url/term/thumbnail
        // as required propTypes, we need to have a temp value
        // to prevent console warnings from happening
        const tempIconData = {
            showSkeleton: true,
            id: "",
            url: "",
            title: "",
            thumbnails: { thumbnail200: "" }
        };

        return new Array(loadingItemCount).fill(tempIconData);
    }
};
