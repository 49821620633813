import React from "react";
import PropTypes from "prop-types";

import ImagePreventRightClick from "core/ImagePreventRightClick";

import { IconGridItemContainer, AnchorLink } from "./styles";

const IconGridItem = ({
    iconId,
    iconTitle,
    iconPreviewUrl,
    iconDetailPermalink
}) => {
    return (
        <AnchorLink href={iconDetailPermalink}>
            <IconGridItemContainer>
                <ImagePreventRightClick
                    imageSrc={iconPreviewUrl}
                    srText={`${iconTitle} Icon ${iconId}`}
                    preventPointerEvents
                />
            </IconGridItemContainer>
        </AnchorLink>
    );
};

IconGridItem.propTypes = {
    iconId: PropTypes.string.isRequired,
    iconTitle: PropTypes.string.isRequired,
    iconPreviewUrl: PropTypes.string.isRequired,
    iconDetailPermalink: PropTypes.string.isRequired
};

export default IconGridItem;
