// validation helper for form elements:
export const getValidationError = ({ input, validation }) => {
    for (const rule of validation) {
        if (!rule.isValid(input)) {
            return rule.errorMsg;
        }
    }

    return null;
};

export const minChars = charMin => string => string.trim().length >= charMin;
export const maxChars = charMax => string => string.trim().length <= charMax;
export const validEmail = input => /.+@.+\..+/.test(input);
