import { useApolloLazyQuery } from "frontend/utils/apolloApi/useApolloLazyQuery.js";

// queries
import { MY_MOD_PROFILE } from "frontend/components/fort/mod/photos/gql/queries";

export const useFetchMyModeratorProfile = () => {
    const { apolloQuery } = useApolloLazyQuery({
        actionDesc: "Fetch My Moderator Profile",
        gql: MY_MOD_PROFILE
    });

    const fetchMyModeratorProfile = async () => {
        const { ssResp } = await apolloQuery({
            fetchPolicy: "network-only"
        });
        return ssResp?.data?.profile;
    };

    return {
        fetchMyModeratorProfile
    };
};
