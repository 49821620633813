import React, { createContext } from "react";
import PropTypes from "prop-types";

export const PhotosContext = createContext();

export const PhotosProvider = ({ children, ...rest }) => (
    <PhotosContext.Provider
        value={{
            ...rest
        }}
    >
        {children}
    </PhotosContext.Provider>
);

PhotosProvider.propTypes = {
    children: PropTypes.any.isRequired
};
