import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import RadioButtons from "core/RadioButtons";
import Button from "core/Button";

import { UPLOAD_TYPES } from "../PhotoUploadCore/helpers";

const FormContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const PhotoUploadTypeSelectForm = ({
    setCurrentUploadType,
    currentUploadType,
    handleSubmit,
    isLoading
}) => (
    <FormContainer>
        <RadioButtons
            radioName="upload-type"
            fieldsetTitle="Choose an upload type"
            onClick={value => setCurrentUploadType(value)}
            defaultCheckedValue={currentUploadType}
            options={[
                {
                    value: UPLOAD_TYPES.INDIVIDUAL,
                    text: "Individual Photos"
                },
                {
                    value: UPLOAD_TYPES.PHOTO_SHOOT,
                    text: "Photo Shoot"
                }
            ]}
        />
        <Button onClick={handleSubmit} disabled={isLoading}>
            Continue to Upload
        </Button>
    </FormContainer>
);

PhotoUploadTypeSelectForm.propTypes = {
    setCurrentUploadType: PropTypes.func.isRequired,
    currentUploadType: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired
};

export default PhotoUploadTypeSelectForm;
