import { createSlice } from "@reduxjs/toolkit";
import { REDUCERS } from "../../constants";

import { getBatchUploadCollections } from "./getBatchUploadCollections.js";

export const batchUploadCollectionsSlice = createSlice({
    name: REDUCERS.BATCH_UPLOAD_COLLECTIONS,
    initialState: [],
    reducers: {
        setBatchUploadCollections: (state, action) => {
            return getBatchUploadCollections(action.payload);
        },
        setBatchUploadCollectionView: (state, action) => {
            const { bucId, view } = action.payload;
            const collection = state.find(buc => buc.id === bucId);
            if (collection) {
                collection.view = view;
            }
        }
    }
});

export default batchUploadCollectionsSlice.reducer;
