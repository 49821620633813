import { useApolloMutation } from "frontend/utils/apolloApi/useApolloMutation.js";
import { MESSAGING } from "frontend/components/fort/mod/photos/constants";

// GQL
import { UPDATE_AND_APPROVE_PHOTO_SHOOT_TITLE } from "frontend/components/fort/mod/photos/gql/mutations";

const errorHandler = normalizedResp => {
    const { message, errors } = normalizedResp;
    let newMessage = message;

    errors.forEach(em => {
        if (
            em.includes(
                "has another photo shoot with the same title. Please choose another title and try again."
            )
        ) {
            newMessage = em;
        }
        if (
            em.includes(
                "Shoot name contains disallowed terms. Please try a different name."
            )
        ) {
            newMessage = MESSAGING.errors.containsDisallowedTerms(
                "photo shoot title"
            );
        }
    });

    return {
        ...normalizedResp,
        message: newMessage
    };
};

export const useUpdateAndApprovePhotoShootTitle = () => {
    const { apolloMutate, loading } = useApolloMutation({
        actionDesc: "Update and Approve Photo Shoot",
        gql: UPDATE_AND_APPROVE_PHOTO_SHOOT_TITLE,
        errorHandler
    });

    const updateAndApprovePhotoShootTitle = async variables => {
        return await apolloMutate({ variables });
    };

    return {
        updateAndApprovePhotoShootTitle,
        updateAndApprovePhotoShootTitleLoading: loading
    };
};
