import { useMutation } from "@apollo/client";
import { apolloApi } from "./api/index.js";

export const useApolloMutation = props => {
    const { gql, options = {} } = props;

    const [apiCall, { loading }] = useMutation(gql, options);

    const apolloMutate = apolloApi({
        actionDesc: "Make Apollo Mutation Hook",
        ...props,
        apiCall
    });

    return {
        apolloMutate,
        loading
    };
};
