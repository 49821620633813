import React from "react";

import Link from "core/Link";
import Grid from "core/Grid";
import Heading from "core/Heading";
import BodyText from "core/BodyText";

import Photo from "core/ReusableIcons/Photo";

import {
    EmptyOffsetGrid,
    HeadingContainer,
    IconContainer,
    TitleContainer,
    InstructionTitle
} from "./styles";

const Empty = ({ initialMaximumFiles, setDisabledDropzone }) => {
    const handleFocus = () => {
        // disables dropzone from allowing upload files when link in empty stage is clicked:
        setDisabledDropzone(true);
    };

    const handleBlur = () => {
        // enables dropzone from allowing upload files when link in empty stage is blurred/moved away from:
        setDisabledDropzone(false);
    };

    const stageLinkProps = {
        size: "sm",
        onFocus: handleFocus, // for keyboard
        onBlur: handleBlur, // for keyboard
        onMouseEnter: handleFocus, // for mouse
        onMouseLeave: handleBlur // for mouse
    };

    const instructions = [
        {
            title: "Accepted File Types",
            text: (
                <>
                    Upload JPGs between 10 &amp; 50 megapixels. See{" "}
                    <Link
                        href="https://brand.thenounproject.com/k/Noun-Project-Photographer-Guide-P9J8Wn"
                        target="_blank"
                        {...stageLinkProps}
                    >
                        photographer guide
                    </Link>
                    .
                </>
            )
        },
        {
            title: "Original Photos Only",
            text: (
                <>
                    You made all images, own copyrights &amp; accept{" "}
                    <Link
                        href="/legal/creator-terms/photos/"
                        target="_blank"
                        {...stageLinkProps}
                    >
                        terms
                    </Link>
                </>
            )
        },
        {
            title: "Releases Required",
            text: (
                <>
                    Recognizable people or property require{" "}
                    <Link
                        href="https://help.thenounproject.com/hc/en-us/articles/20487895422235-Model-Property-Release-requirements"
                        target="_blank"
                        {...stageLinkProps}
                    >
                        releases
                    </Link>
                </>
            )
        }
    ];

    return (
        <EmptyOffsetGrid sm={8} md={8} lg={8}>
            <HeadingContainer>
                <IconContainer>
                    <Photo />
                </IconContainer>

                <TitleContainer>
                    <Heading type="h2" size="lg" bottomSpacing="sm">
                        Drag and drop up to {initialMaximumFiles} jpgs
                    </Heading>
                    <Link
                        size="md"
                        styleType="primary"
                        isButton
                        disableVisitedStyle
                        onClick={() => {}}
                        tabIndex={0}
                    >
                        upload from your computer
                    </Link>
                </TitleContainer>
            </HeadingContainer>

            <Grid mobileSpacing={24} tabletSpacing={24} desktopSpacing={24}>
                {instructions.map((info, index) => (
                    <Grid item lg={4} key={index}>
                        <InstructionTitle type="p" size="sm" bold>
                            {info.title}
                        </InstructionTitle>

                        <BodyText type="p" size="sm">
                            {info.text}
                        </BodyText>
                    </Grid>
                ))}
            </Grid>
        </EmptyOffsetGrid>
    );
};

export default Empty;
