import React from "react";
import styled from "styled-components";
import colors from "core/colors";
import { textLg } from "core/fontStyles";
import breakpoints from "core/breakpoints";

import Heading from "core/Heading";
import Button from "core/Button";
import Checkmark from "core/ReusableIcons/Checkmark";

import SectionContentContainer from "./Pricing/SectionContentContainer";

const features = [
    "Millions of icons & photos, human moderated for quality & diversity",
    "Royalty-free, commercial licenses",
    "Directly supports artists, no attribution required",
    "No advertisements",
    "Unlock icon editor, Mac app & plugins, custom kits, & copy/paste for icons",
    "Unlimited icon downloads",
    "Unlimited icon set downloads",
    "Unlimited standard photo downloads",
    "Crop & edit standard photos, make derivatives"
];

const SplitContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3rem;

    @media (min-width: ${breakpoints.tablet.rem}) {
        flex-direction: row;
        gap: 1.5rem;
    }
`;

const CtaSection = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

const CtaContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    h2 {
        text-align: center;
    }

    @media (min-width: ${breakpoints.tablet.rem}) {
        max-width: 28rem;
        padding: 0 1.5rem;
        align-items: flex-start;

        h2 {
            text-align: left;
        }

        a {
            width: 12.5rem;
        }
    }
`;

const FeaturesSection = styled.div`
    flex: 1.25;
`;

const FeaturesList = styled.ul`
    ${textLg}
    display: flex;
    flex-direction: column;
    gap: 1rem;

    li {
        display: flex;
        gap: 0.5rem;
        align-items: flex-start;

        svg {
            flex-shrink: 0;
            margin-top: 0.25rem;
        }
    }
`;

const PlanSection = () => {
    return (
        <SectionContentContainer>
            <Heading type="h2" size="xl" alignment="center" bottomSpacing="4xl">
                Boost Your Creativity with Unlimited Royalty-Free Icon and Photo
                Plans
            </Heading>
            <SplitContainer>
                <CtaSection>
                    <CtaContainer>
                        <Heading type="h2" size="md">
                            Unlock unlimited access to the world’s most
                            extensive and diverse collection.
                        </Heading>
                        <Button href="/pricing/">Explore Pro Plans</Button>
                    </CtaContainer>
                </CtaSection>
                <FeaturesSection>
                    <FeaturesList>
                        {features.map((feature, index) => (
                            <li key={index}>
                                <Checkmark
                                    fillColor={colors.accent.lightMode.persian}
                                    size="sm"
                                />{" "}
                                {feature}
                            </li>
                        ))}
                    </FeaturesList>
                </FeaturesSection>
            </SplitContainer>
        </SectionContentContainer>
    );
};

export default PlanSection;
