import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// styles
import BodyText from "core/BodyText";
import { convertPxToRem } from "frontend/components/helpers";
import { regular as regularFontWeight } from "core/fontStyles";
const TagListStyled = styled.div`
    margin-bottom: ${convertPxToRem(24)};
`;
const TitleStyled = styled(BodyText)`
    margin-bottom: ${convertPxToRem(16)};
    font-size: ${convertPxToRem(11)};
    ${regularFontWeight}
`;

// components
import TagWithApproval from "./TagWithApproval";

const TagList = ({ tags, title }) => {
    return (
        <TagListStyled>
            <TitleStyled type="h4" size="xs">
                {title} ({tags.length})
            </TitleStyled>
            {tags.map(tag => {
                return <TagWithApproval key={tag.id} tag={tag} />;
            })}
        </TagListStyled>
    );
};

// prop-types
TagList.propTypes = {
    tags: PropTypes.array,
    title: PropTypes.string.isRequired
};

export default TagList;
