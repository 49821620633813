export const CONSENT_COOKIE_NAME = "cookie_consent";

export const EDITOR_COOKIE_NAME = "editor_settings";

export const writeCookie = ({ name, value, expires = null }) => {
    if (!name || !value || typeof document === "undefined") {
        return null;
    }

    value = encodeURIComponent(JSON.stringify(value));

    if (!expires) {
        const now = Date.now();
        const expireDate = new Date(now); // easier to mock in jest if start-time is based on Date.now()
        expireDate.setFullYear(expireDate.getFullYear() + 1);
        expires = expireDate.toUTCString();
    }

    // NOTE: samesite=none supports our plugins; & secure is needed to support samesite value.
    document.cookie = `${name}=${value};expires=${expires};path=/;samesite=none;secure`;
};

export const readCookie = name => {
    if (!name || typeof document === "undefined") {
        return null;
    }

    const cookies = document.cookie.split(/;\s*/);
    const cookie = cookies.find(cookie => cookie.indexOf(name + "=") === 0);
    if (cookie) {
        const value = cookie.split("=")[1];
        return JSON.parse(decodeURIComponent(value));
    }

    return null;
};
