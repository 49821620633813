import React, { useEffect } from "react";
import PropTypes from "prop-types";

// styles
import { convertPxToRem } from "frontend/components/helpers";

// Hooks
import { useSelect } from "frontend/components/core/Grid2/GridItemContentTemplates/Select/SelectContext/index.js";

// components
import Grid2 from "core/Grid2";

// helpers
import { objectHasProps } from "frontend/components/helpers/index.js";

// Needs to be SelectProvider aware
export const BatchGrid = ({ gridItemContent }) => {
    const { uploadsSelected, selectIds, getSelectedUploadIds } = useSelect();

    useEffect(() => {
        // this removes dispatch on mount, only applies when user interacts with upload selection
        if (objectHasProps(uploadsSelected)) {
            // convert object to array of upload ids
            const selectedUploadIds = getSelectedUploadIds(uploadsSelected);
            selectIds(selectedUploadIds);
        }
    }, [uploadsSelected]);

    return (
        <Grid2
            gridItemContent={gridItemContent}
            numberOfColumns={6}
            gridSpacingSize={convertPxToRem(24)}
        />
    );
};

BatchGrid.propTypes = {
    gridItemContent: PropTypes.arrayOf(PropTypes.object).isRequired
};
