import React from "react";

import BodyText from "core/BodyText";
import PropTypes from "prop-types";

import {
    Input,
    InputWrapper,
    TagListItem,
    TagsDropdownBox,
    TagsDropdownContainer,
    TagsMainContainer
} from "./styles";

const TagsInput = ({
    dropdownId,
    handleKeyDown,
    inputId,
    minLength,
    placeholderText,
    handleOnChange,
    handleSubmit,
    tagInputValue,
    labelText,
    isRequired,
    showDropdown,
    tagsDropdownRef,
    suggestedTags,
    setValueCloseDropdown,
    focusedItem,
    dropdownMaxHeight
}) => {
    return (
        <TagsMainContainer
            id={dropdownId}
            onKeyDown={handleKeyDown}
            tabIndex="0"
        >
            <InputWrapper>
                <Input
                    id={inputId}
                    minLength={minLength}
                    placeholder={placeholderText}
                    type="text"
                    onChange={e => handleOnChange(e.target.value)}
                    onKeyDown={e => {
                        if (e.key === "ArrowLeft" || e.key === "ArrowRight") {
                            //prevents modal moving to next photo & moves cursor
                            e.stopPropagation();
                        } else if (e.key === "Enter" && focusedItem === null) {
                            e.stopPropagation(); //prevents parent onKeyDown from firing
                            handleSubmit(e);
                        }
                    }}
                    value={tagInputValue}
                    role="combobox"
                    name={labelText}
                    required={isRequired}
                />
            </InputWrapper>
            {showDropdown && (
                <TagsDropdownContainer ref={tagsDropdownRef}>
                    <TagsDropdownBox maxHeight={dropdownMaxHeight}>
                        <ul role="listbox" tabIndex="0">
                            {suggestedTags.map((tag, idx) => {
                                return (
                                    <TagListItem
                                        tabIndex="0"
                                        key={idx}
                                        aria-label={tag}
                                        role="option"
                                        onClick={() =>
                                            setValueCloseDropdown(tag)
                                        }
                                        isHovered={idx === focusedItem}
                                    >
                                        <BodyText size="md">{tag}</BodyText>
                                    </TagListItem>
                                );
                            })}
                        </ul>
                    </TagsDropdownBox>
                </TagsDropdownContainer>
            )}
        </TagsMainContainer>
    );
};

TagsInput.propTypes = {
    dropdownId: PropTypes.string.isRequired,
    handleKeyDown: PropTypes.func.isRequired,
    inputId: PropTypes.string.isRequired,
    minLength: PropTypes.number.isRequired,
    placeholderText: PropTypes.string.isRequired,
    handleOnChange: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    tagInputValue: PropTypes.string.isRequired,
    labelText: PropTypes.string.isRequired,
    isRequired: PropTypes.bool.isRequired,
    showDropdown: PropTypes.bool.isRequired,
    tagsDropdownRef: PropTypes.any.isRequired,
    suggestedTags: PropTypes.array.isRequired,
    setValueCloseDropdown: PropTypes.func.isRequired,
    focusedItem: PropTypes.number
};

export default TagsInput;
