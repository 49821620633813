import React, { useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import throttle from "lodash/throttle";

// context
import { useSiteHeader } from "core/SiteHeaderV2/context/useSiteHeader.js";

// component
const MountingWrapper = ({ children }) => {
    const {
        animation,
        setHeader,
        setAnimationSequence,
        animateIn,
        animateOut,
        resetStateByPage,
        currentPage
    } = useSiteHeader();

    // refs
    const animationSequenceRef = useRef(animation.sequence);
    const lastScrollYRef = useRef(0);
    const scrollDirectionRef = useRef("down");
    const hasMountedRef = useRef(false);
    const currentPageRef = useRef(currentPage);

    const updateScrollDirection = scrollY => {
        if (scrollY > lastScrollYRef.current) {
            scrollDirectionRef.current = "down";
        } else if (scrollY < lastScrollYRef.current) {
            scrollDirectionRef.current = "up";
        }
        lastScrollYRef.current = scrollY;
    };

    // throttled scrollY side effects
    const throttleScrollCalulations = useCallback(
        throttle((scrollY, animationSequenceRef, scrollDirectionRef) => {
            setAnimationSequence(
                scrollY,
                animationSequenceRef,
                scrollDirectionRef
            );
        }, 100),
        []
    );

    // Evaluate scroll logic
    const evaluateScroll = useCallback(() => {
        const scrollY = window.scrollY;

        updateScrollDirection(scrollY);

        // Defer DOM updates to requestAnimationFrame - hard to tell if this made any difference, and does not seem to hurt either
        // schedule a function to be executed just before the next repaint
        // It ensures your function runs at the most optimal time for visual updates, aligning it with the browser’s refresh rate (typically 60fps, or 16.66ms per frame).
        window.requestAnimationFrame(() => {
            // throttling setHeader (which is bound to a 1:1 relationship with window.scrollY) causes choppy animation
            setHeader(scrollY);
            // Not bound by 1:1 window.scrollY; ok to throttle
            throttleScrollCalulations(
                scrollY,
                animationSequenceRef,
                scrollDirectionRef
            );
        });
    }, []);

    // scroll listener
    useEffect(() => {
        window.addEventListener("scroll", evaluateScroll);
        return () => {
            window.removeEventListener("scroll", evaluateScroll);
        };
    }, []);

    // track hasMounted
    useEffect(() => {
        hasMountedRef.current = true;
    }, []);

    // Avoiding doing this on first page load, as we already load this state onMount
    // When we switch between pages using NextJS routing
    // we need to reset the state to take into account page specific differences
    useEffect(() => {
        if (hasMountedRef.current && currentPageRef.current !== currentPage) {
            currentPageRef.current = currentPage;
            resetStateByPage(currentPage);
        }
    }, [currentPage]);

    // trigger second row animation
    useEffect(() => {
        animationSequenceRef.current === "in" ? animateIn() : animateOut();
    }, [animationSequenceRef.current]);

    return <>{children}</>;
};

// prop-types
MountingWrapper.propTypes = {
    children: PropTypes.any
};

export default MountingWrapper;
