import React from "react";
import styled, { css } from "styled-components";

// context
import { usePanelOverlay } from "../usePanelOverlay";

//styles
import colors from "core/colors";
const ShadowStyled = styled.div`
    position: fixed;
    z-index: 2001;
    left: 0px;
    top: 0px;
    right: 100vw;
    bottom: 0px;

    background-color: ${colors.secondary.onyx};
    transition: opacity 0.5s ease-in-out;
    opacity: 0;

    ${({ $isOpen }) => {
        if ($isOpen) {
            return css`
                right: 0vw;
                opacity: 0.6;
            `;
        }
    }}
`;

// component
const Shadow = () => {
    const { isOpen } = usePanelOverlay();
    return <ShadowStyled $isOpen={isOpen} />;
};

export default Shadow;
