import React from "react";
import PropTypes from "prop-types";

import EditShootName from "./EditShootName";

import { Title, NameContainer } from "./styles";

const PhotoUploadPhotoShootName = ({ currentPhotoShootId }) => (
    <NameContainer>
        <Title>Photo Shoot Name:</Title>
        <EditShootName currentPhotoShootId={currentPhotoShootId} />
    </NameContainer>
);

PhotoUploadPhotoShootName.propTypes = {
    currentPhotoShootId: PropTypes.string
};

export default PhotoUploadPhotoShootName;
