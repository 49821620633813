import React from "react";
import { useParams, useLocation } from "react-router-dom";
import querystring from "query-string";

import SearchIconsResults from "icons/SearchIconsResults";
import SearchCollectionsResults from "icons/SearchCollectionsResults";
import SearchPhotosResults from "photos/SearchPhotosResults";
import PageMeta from "core/PageMeta";
import SearchAndBrowsePageContainer from "core/SearchAndBrowsePageContainer";

import { SEARCH_TYPES } from "./helpers";
import { capitalizeFirstChar } from "frontend/components/helpers";

const SearchPage = () => {
    const { searchType } = useParams();
    const location = useLocation();
    const { q: query } = querystring.parse(location.search);

    let pageTitle;

    if (searchType === SEARCH_TYPES.ICONS) {
        pageTitle = "Search Icons | Noun Project";
    } else if (searchType === SEARCH_TYPES.ICON_COLLECTIONS) {
        pageTitle = `${capitalizeFirstChar({
            str: query
        })} Icon Sets | Noun Project`;
    } else if (searchType === SEARCH_TYPES.PHOTOS) {
        pageTitle = "Search Photos | Noun Project";
    }

    const canonicalURL = window.location.origin + "/search/";

    return (
        <>
            <PageMeta
                title={pageTitle}
                canonicalURL={canonicalURL}
                indexContent="noindex,follow"
            />
            <SearchAndBrowsePageContainer containsSegmentedControls>
                <SearchIconsResults
                    query={query}
                    isActive={searchType === SEARCH_TYPES.ICONS}
                    searchType={searchType}
                />
                <SearchCollectionsResults
                    query={query}
                    isActive={searchType === SEARCH_TYPES.ICON_COLLECTIONS}
                    searchType={searchType}
                />
                <SearchPhotosResults
                    query={query}
                    isActive={searchType === SEARCH_TYPES.PHOTOS}
                    searchType={searchType}
                />
            </SearchAndBrowsePageContainer>
        </>
    );
};

export default SearchPage;
