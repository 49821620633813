import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const ScrollingResizeStyled = styled.div`
    position: absolute;
    z-index: 2;
    top: 0px;
    left: 0px;
    right: 0px;
`;

// Holds the Search Bar
export const ScrollingResize = ({ children }) => {
    return <ScrollingResizeStyled>{children}</ScrollingResizeStyled>;
};

export default ScrollingResize;

// prop-types
ScrollingResize.propTypes = {
    children: PropTypes.any
};
