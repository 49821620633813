import { useEffect, useRef } from "react";
import PropTypes from "prop-types";

/**
 * Handles click outside logic
 * @param {Function} onClickOutside callback when clicking outside is true,
 * @param {String} clickOutsideDomSelector Defaults to document root, but if this CSS selector string if provided, we will attempt to use it instead.
 * @returns {Object} {
 *      targetRef: for consumer to assign to target container
 * }
 */

export const useOnClickOutside = ({
    onClickOutside,
    clickOutsideDomSelector
}) => {
    const targetRef = useRef();

    // side effects
    useEffect(() => {
        const handleClickOutside = e => {
            const currentTarget = targetRef?.current;

            if (!currentTarget) {
                return console.error(
                    "useOnClickOutside: Unable to locate the target DOM container you are evaluating for clickOutside. Did you forget to add targetRef?"
                );
            }

            if (!currentTarget.contains(e.target)) {
                onClickOutside(e);
            }
        };

        let consumerEl = null;

        // attempt to grab Consumer provided DOM element if consumer provided CSS selector
        if (clickOutsideDomSelector) {
            consumerEl = document.querySelector(clickOutsideDomSelector);
            if (!consumerEl) {
                console.warn(
                    `useOnClickOutside: The CSS selector you provided (${clickOutsideDomSelector}) did not match anything in the DOM. Falling back to document root.`
                );
            }
        }

        const parentElement = consumerEl || document;

        // assign click outside listener
        parentElement.addEventListener("click", handleClickOutside);

        // cleanup on dismount
        return () =>
            parentElement.removeEventListener("click", handleClickOutside);
    }, [clickOutsideDomSelector]);

    return {
        targetRef
    };
};

useOnClickOutside.propTypes = {
    onClickOutside: PropTypes.func.isRequired,
    clickOutsideDomSelector: PropTypes.string
};
