import React from "react";
import PropTypes from "prop-types";

// QueueContext
import { useQueueContext } from "frontend/components/fort/mod/core/Layouts/QueueLayout";

// components
import SideBarSection from "../SideBarSection";
import LicenseTitle from "./LicenseTitle";
import MetaList from "./MetaList/index.js";

// data
const getUploadLicenseMappingText = (licenseMappings, upload) => {
    const { license } = upload;
    return licenseMappings[license];
};

const getLicenseText = (
    uploadSelectedCount,
    singleUploadSelected,
    selectedUploads,
    licenseMappings
) => {
    if (singleUploadSelected) {
        return getUploadLicenseMappingText(
            licenseMappings,
            singleUploadSelected
        );
    }

    if (uploadSelectedCount > 1) {
        let licenseText = getUploadLicenseMappingText(
            licenseMappings,
            selectedUploads[0]
        );

        // see if any are different than first
        selectedUploads.forEach(ul => {
            const thisLicenseText = getUploadLicenseMappingText(
                licenseMappings,
                ul
            );
            if (licenseText !== thisLicenseText) {
                licenseText = `Multiple`;
            }
        });

        return licenseText;
    }

    return ``;
};

export const License = ({ children }) => {
    const { licenseMappings, state } = useQueueContext();
    const {
        selectedUploads,
        uploadSelectedCount,
        singleUploadSelected
    } = state;

    const licenseText = getLicenseText(
        uploadSelectedCount,
        singleUploadSelected,
        selectedUploads,
        licenseMappings
    );

    if (uploadSelectedCount < 1 || !licenseText) return null;

    return (
        <SideBarSection title="License">
            <LicenseTitle text={licenseText} />
            <MetaList />
            {children}
        </SideBarSection>
    );
};

export default License;

// prop-types
License.propTypes = {
    children: PropTypes.any
};
