import * as actionTypes from "../actions/actionTypes";

export const doRedirect = href => (global.location.href = href);

const redirect = store => next => action => {
    switch (action.type) {
        case actionTypes.REDIRECT:
            let { href } = action;
            doRedirect(href);
            break;
    }

    return next(action);
};

export default redirect;
