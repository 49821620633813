import React from "react";
import { useDispatch } from "react-redux";

// context
import { useQueueContext } from "frontend/components/fort/mod/core/Layouts/QueueLayout/QueueContext.js";

// redux
import { uploadsSlice } from "frontend/components/fort/mod/photos/slices/uploadsSlice/index.js";
import { messagesSlice } from "frontend/components/fort/mod/photos/slices/messagesSlice.js";

// components
import ManagePhotoTags from "core/ManageTags/ManagePhotoTags/index.js";
import SideBarSection from "frontend/components/fort/mod/core/Sidebar/SideBarSection/index.js";

// component
export const Tags = () => {
    const {
        state: { selectedUploads },
        selectedBatchObj
    } = useQueueContext();

    const dispatch = useDispatch();

    const onUpdateTags = ({ resp }) => {
        const ssPhotoUploads = resp?.ssResp?.data?.payload?.photoUploads || [];
        ssPhotoUploads.forEach(spu => {
            const {
                id,
                approvedUserTags,
                userTags,
                warningUserTags,
                autoTags
            } = spu;
            dispatch(
                uploadsSlice.actions.updateUpload({
                    id: id,
                    changes: {
                        approvedUserTags,
                        userTags,
                        warningUserTags,
                        autoTags
                    }
                })
            );
        });
    };

    const onUpdateTagsError = ({ resp }) => {
        const { message } = resp;

        const stackMessage = {
            // id: 123, // needs unique id so we remove the right one when done.
            text: message,
            timed: false,
            // timeout: 5000,
            infoType: "error"
        };

        dispatch(messagesSlice.actions.addMessage(stackMessage));
    };

    if (!selectedUploads || selectedUploads.length === 0) return null;

    return (
        <SideBarSection title="Tags">
            <ManagePhotoTags
                uploads={selectedUploads}
                display="stacked"
                hasApproval={true}
                isDisabled={!selectedBatchObj?.isEditable?.tags}
                onUpdateTags={onUpdateTags}
                onUpdateTagsError={onUpdateTagsError}
            />
        </SideBarSection>
    );
};

export default Tags;
