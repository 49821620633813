import React from "react";
import PropTypes from "prop-types";

import colors from "core/colors";

import { Container, Title, Message, Buttons, IconContainer } from "./styles";
import SubmissionStatusButton from "./SubmissionStatusButton";
import LimitIcon from "core/IllustrativeIcons/Limit";

const MaxTimeError = ({ uploadStatusMessage }) => {
    return (
        <Container>
            <Title>You've reached the 30-day photo submission limit.</Title>
            <Message>{uploadStatusMessage}</Message>
            <Buttons>
                <SubmissionStatusButton />
            </Buttons>
            <IconContainer>
                <LimitIcon fillColor={colors.accent.lightMode.pansy} />
            </IconContainer>
        </Container>
    );
};

MaxTimeError.propTypes = {
    timeRemaining: PropTypes.number
};

export default MaxTimeError;
