import React, { useEffect } from "react";
import { useMutation } from "@apollo/client";

import BodyText from "core/BodyText";

import { EditorialUseOnlyContainer, BlueLink } from "./styles";

import Checkbox from "core/Checkbox";

import { SET_PHOTO_UPLOAD_EDITORIAL_USE } from "./mutations";

import { useEditPhotoUpload } from "./EditPhotoUploadContext";

import { normalizeValues } from "./helpers";

const EditorialUseOnlySection = ({ setIsChecked, isChecked }) => {
    const { uploadIds = [], uploads = [] } = useEditPhotoUpload();

    const [setEditorial] = useMutation(SET_PHOTO_UPLOAD_EDITORIAL_USE);

    useEffect(() => {
        const editorialUseValues = uploads.map(
            upload => upload.editorialUseOnly
        );

        setIsChecked(normalizeValues(editorialUseValues));
    }, [uploads]);

    const handleClick = selectedValue => {
        return setEditorial({
            variables: {
                photoUploadIds: uploadIds,
                value: selectedValue
            }
        });
    };

    return (
        <EditorialUseOnlyContainer>
            <Checkbox
                ariaLabel={`${isChecked ? "Is" : "Is not"} Editorial Use Only`}
                label="Editorial Use Only"
                onClick={selectedValue => handleClick(selectedValue)}
                isChecked={isChecked}
            />

            <BodyText size="sm" type="div">
                This is a news-worthy image with no release.{" "}
                <BlueLink
                    href="https://help.thenounproject.com/hc/en-us/articles/20487895422235-Model-Property-Release-requirements"
                    target="_blank"
                >
                    See rules
                </BlueLink>
            </BodyText>
        </EditorialUseOnlyContainer>
    );
};

export default EditorialUseOnlySection;
