import React from "react";
import PropTypes from "prop-types";

import { StyledContainer } from "./styles";

const SearchAndBrowsePageContainer = ({
    children,
    containsSegmentedControls
}) => (
    <StyledContainer
        containsSegmentedControls={containsSegmentedControls}
        topSpacing="2xl"
        bottomSpacing="3xl"
    >
        {children}
    </StyledContainer>
);

SearchAndBrowsePageContainer.propTypes = {
    children: PropTypes.node.isRequired,
    containsSegmentedControls: PropTypes.bool
};

export default SearchAndBrowsePageContainer;
