import { useState, useEffect } from "react";
import {
    animateElementsThreshold,
    animateSearchThreshold,
    scrollingThreshold
} from "core/SiteHeader/constants";

export const useSiteHeaderScroll = () => {
    const [scrollTop, scrollTopUpdate] = useState(0);
    const [animateElements, animateElementsUpdate] = useState(false);
    const [animateSearch, animateSearchUpdate] = useState(false);
    const [isScrolledDown, isScrolledDownUpdate] = useState(false);
    const [searchThreshold, searchThresholdUpdate] = useState(
        animateSearchThreshold.default
    );

    useEffect(() => {
        const onScroll = e => {
            scrollTopUpdate(getScrollTop());
        };

        document.addEventListener("scroll", onScroll);

        // Grab page URL on mount
        searchThresholdUpdate(getSearchThreshold(window.location.href));

        // cleanup on dismount
        // in theory, this should never happen, but if it does - good practice.
        return () => {
            document.removeEventListener("scroll", onScroll);
        };
    }, []);

    useEffect(() => {
        animateElementsUpdate(scrollTop >= animateElementsThreshold);
        isScrolledDownUpdate(scrollTop >= scrollingThreshold);
        animateSearchUpdate(scrollTop >= searchThreshold);
    }, [scrollTop]);

    return {
        scrollTop,
        animateElements,
        animateSearch,
        isScrolledDown
    };
};

const getScrollTop = () => {
    return window.pageYOffset !== undefined
        ? window.pageYOffset
        : (
              document.documentElement ||
              document.body.parentNode ||
              document.body
          ).scrollTop;
};

const getSearchThreshold = curUrl => {
    let searchThreshold;

    switch (true) {
        case curUrl.includes("/icons/"):
            searchThreshold = animateSearchThreshold.icons;
            break;
        case curUrl.includes("/photos/"):
            searchThreshold = animateSearchThreshold.photos;
            break;
        default:
            searchThreshold = animateSearchThreshold.default;
            break;
    }

    return searchThreshold;
};
