import React, { useState, useEffect } from "react";

import Heading from "core/Heading";
import BodyText from "core/BodyText";
import colors from "core/colors";
import Flexbox from "core/Flexbox";
import breakpoints from "core/breakpoints";
import { useViewport } from "js/hooks/useViewport";

import { petsData } from "./data";

import { PetContainer, PetImage } from "./styles";
import OffsetGrid from "core/OffsetGrid";

const PetDisplay = ({ picture, name, title }) => (
    <PetContainer data-testid="pet-display">
        <PetImage src={picture} alt={name} />
        <Heading
            size="xs"
            headingColor={colors.secondary.orca}
            topSpacing="xl"
            bottomSpacing="sm"
        >
            {name}
        </Heading>
        <BodyText
            copyColor={colors.secondary.oolong}
            size="md"
            alignment="center"
        >
            {title}
        </BodyText>
    </PetContainer>
);

const OfficePets = () => {
    const { browserWidth } = useViewport();
    const [filteredPets, setFilteredPets] = useState([]);

    useEffect(() => {
        // This function allows for random pets to be shown
        // and ensures there are not any duplicate pets shown.
        // it also grabs screen width on load only to display
        // 2 pets on small screens and 4 on desktop/tablet
        let tempArr = [];
        let petsDataCopy = [...petsData];
        const numOfPetsToShow = browserWidth >= breakpoints.tablet.px ? 4 : 2;
        for (let i = 0; i < numOfPetsToShow; i++) {
            const tempPetIdx = Math.floor(Math.random() * petsDataCopy.length);
            let pet = petsDataCopy[tempPetIdx];
            tempArr.push(pet);
            petsDataCopy.splice(tempPetIdx, 1);
        }
        setFilteredPets(tempArr);
    }, []);

    return (
        <>
            <OffsetGrid sm={12} md={12} lg={8}>
                <Heading
                    size="xl"
                    type="h2"
                    alignment="center"
                    bottomSpacing="3xl"
                >
                    Our Four-legged Crew
                </Heading>
                <Flexbox
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                    flexWrap="wrap"
                >
                    {filteredPets.map((petObj, idx) => {
                        return (
                            <PetDisplay
                                picture={petObj.picture}
                                name={petObj.name}
                                title={petObj.title}
                                key={idx}
                            />
                        );
                    })}
                </Flexbox>
            </OffsetGrid>
        </>
    );
};

export default OfficePets;
