import React from "react";

// context
import { useSiteHeader } from "core/SiteHeaderV2/context/useSiteHeader.js";

// components
import UnauthMenu from "./UnauthMenu";
import AuthMenu from "./Auth/AuthMenu";

// component
const SecondaryMenu = () => {
    const { auth } = useSiteHeader();
    return auth?.isLoggedIn ? <AuthMenu /> : <UnauthMenu />;
};

export default SecondaryMenu;
