import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";

import { updateUploads } from "../uploadsSlice";

import { moderatorIconKeys } from "../../config";

import { REDUCERS } from "../../constants";

export const uploadFooterActionsAdapter = createEntityAdapter();

export const uploadFooterActionsSlice = createSlice({
    name: REDUCERS.UPLOAD_FOOTER_ACTIONS,
    initialState: uploadFooterActionsAdapter.getInitialState(),
    reducers: {
        footerActionsCreated: uploadFooterActionsAdapter.setMany,
        blurUpload: (state, action) => {
            uploadFooterActionsAdapter.upsertOne(state, {
                id: action.payload,
                [moderatorIconKeys.BLUR]: true
            });
        },
        unblurUpload: (state, action) => {
            uploadFooterActionsAdapter.upsertOne(state, {
                id: action.payload,
                [moderatorIconKeys.BLUR]: false
            });
        }
    },
    extraReducers: builder => {
        builder.addCase(updateUploads, (state, action) => {
            const uploads = action.payload;
            uploads.forEach(({ id, changes: { status, isGem, mature } }) => {
                uploadFooterActionsAdapter.upsertOne(state, {
                    id,
                    [moderatorIconKeys.SAVE_FOR_LATER]:
                        status === "VISUAL_MOD_SAVED_FOR_LATER",
                    [moderatorIconKeys.HEART]: isGem,
                    [moderatorIconKeys.MATURE_AUDIENCE]: mature
                });
            });
        });
    }
});

export {
    blurUpload,
    unblurUpload,
    initializeUploadFooterActions
} from "./thunks";

export default uploadFooterActionsSlice.reducer;
