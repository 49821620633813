import React, { useCallback } from "react";

import PhotoUploadPhotoShootMasthead from "./PhotoUploadPhotoShootMasthead";
import PhotoUploadLayout from "../PhotoUploadCore/PhotoUploadLayout";
import PhotoUploadProviders from "../PhotoUploadCore/PhotoUploadProviders";
import RedirectOnInvalidReferrer from "../PhotoUploadCore/RedirectOnValidReferrer";

import { usePhotoUpload } from "frontend/hooks/usePhotoUpload";

const PhotoUploadPhotoShootUnwrapped = () => {
    const { currentPhotoShootId } = usePhotoUpload();
    return (
        <>
            <RedirectOnInvalidReferrer />
            <PhotoUploadLayout
                masthead={useCallback(
                    () => (
                        <PhotoUploadPhotoShootMasthead
                            currentPhotoShootId={currentPhotoShootId}
                        />
                    ),
                    [currentPhotoShootId]
                )}
            />
        </>
    );
};

const PhotoUploadPhotoShoot = props => (
    <PhotoUploadProviders>
        <PhotoUploadPhotoShootUnwrapped {...props} />
    </PhotoUploadProviders>
);

export default PhotoUploadPhotoShoot;
