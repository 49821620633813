import styled from "styled-components";

// styles
import colors from "core/colors";

// components
import Skeleton from "core/Skeleton";

// styled-components
export const GridItemLoadingSkeletonStyled = styled(Skeleton).attrs({
    width: "100%",
    height: "0px"
})`
    padding-top: 100%;
    background-color: ${colors.primary.whisker};
`;
