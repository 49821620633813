import React from "react";

// libraries
import PropTypes from "prop-types";

// components
import SearchAndBrowsePageContainer from "core/SearchAndBrowsePageContainer";
import SearchAndBrowseIconGridContainer from "icons/SearchAndBrowseIconGridContainer";
import SearchAndBrowsePageHeadline from "core/SearchAndBrowsePageHeadline";
import PageMeta from "core/PageMeta";
import Link from "core/Link";
import { Italic } from "core/Typography";

// helpers
import useLoadMore from "frontend/hooks/useLoadMore";
import { useQuery } from "@apollo/client";
import {
    GET_SIMILAR_ICONS_DATA,
    GET_ICON_DETAIL_DATA
} from "icons/SimilarIcons/queries";
import { CONTENT_TYPE } from "core/constants";

const BrowseIconsSimilar = ({ match }) => {
    const iconId = match.params.slugWithId.split("-").pop();

    const {
        items,
        loading,
        LoadMore,
        totalCount,
        loadingFirstBatch,
        loadingItemCount
    } = useLoadMore({
        limit: 200,
        query: GET_SIMILAR_ICONS_DATA,
        queryVariables: { iconId },
        pathToListKey: "similarIcons.items",
        pathToTotalCountKey: "similarIcons.totalCount",
        contentType: CONTENT_TYPE.icon
    });

    const { loading: loadingIconDetail, data } = useQuery(
        GET_ICON_DETAIL_DATA,
        { variables: { iconId } }
    );

    if (loadingIconDetail || !data) return null;

    const { url, title } = data.iconDetail;

    const pageMetaTitle = `Icons Related To ${title} (${iconId}) | Noun Project`;
    const pageMetaDescription = `Explore royalty-free icons by at Noun Project. Browse these icons and millions more today!`;

    return (
        <>
            <PageMeta
                title={pageMetaTitle}
                indexContent="noindex,follow"
                description={pageMetaDescription}
            />
            <SearchAndBrowsePageContainer>
                <SearchAndBrowsePageHeadline showSkeleton={loadingFirstBatch}>
                    Icons Related to{" "}
                    <Link
                        size="lg"
                        styleType="primary"
                        display="inline"
                        href={url}
                    >
                        <Italic>{title}</Italic>
                    </Link>
                </SearchAndBrowsePageHeadline>
                <SearchAndBrowseIconGridContainer
                    totalCount={totalCount}
                    loadingFirstBatch={loadingFirstBatch}
                    loadingMore={loading}
                    iconData={items}
                    LoadMoreButton={LoadMore}
                    loadingItemCount={loadingItemCount}
                />
            </SearchAndBrowsePageContainer>
        </>
    );
};

BrowseIconsSimilar.propTypes = {
    match: PropTypes.object.isRequired
};

export default BrowseIconsSimilar;
