import React from "react";

import { useCurrentUser, useLegacyUser } from "contexts/CurrentUserContext";

/*
 * Determine whether user has a feature flag enabled.
 * Props need to be either a string, or array of strings.
 *
 * ```
 * const hasIconAccess = useFeatureFlag("ICON_REDESIGN");
 * const hasFortAccess = useFeatureFlag(["ADMIN", "MODERATOR"]);
 * ```
 */
const useFeatureFlag = requiredFlags => {
    let userFlags = null;

    // for newer pages, grab user via Apollo
    const currentUser = useCurrentUser();
    if (currentUser && Array.isArray(currentUser.featureFlags)) {
        userFlags = currentUser.featureFlags;
    }

    // for legacy pages, grab user via REST -> Redux
    const legacyUser = useLegacyUser();
    if (!userFlags && legacyUser && Array.isArray(legacyUser.feature_flags)) {
        userFlags = legacyUser.feature_flags;
    }

    if (!Array.isArray(userFlags) || !userFlags.length) {
        return false;
    }

    // requiredFlags either needs to be a string or array of strings
    if (typeof requiredFlags === "string") {
        requiredFlags = [requiredFlags];
    } else if (!Array.isArray(requiredFlags)) {
        return false;
    }
    requiredFlags = requiredFlags.filter(
        flag => typeof flag === "string" && flag
    );
    if (!requiredFlags.length) {
        return false;
    }

    // make sure user has all required flags
    return requiredFlags.every(flag => userFlags.indexOf(flag) !== -1);
};

export default useFeatureFlag;
