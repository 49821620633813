import { createSlice, createSelector } from "@reduxjs/toolkit";

import {
    CENTS,
    PERCENT,
    MONTHLY_PRICE_PLAN,
    ANNUAL_PRICE_AS_YEARLY,
    EDU_PER_YEAR_COST,
    SINGLE_ICON_PRICE,
    STANDARD_PHOTO_PRICE,
    PREMIUM_PHOTO_PRICE,
    CPRO_PRICE_AS_YEARLY,
    NON_US_MONTHLY_PRICE_PLAN,
    NON_US_ANNUAL_PRICE_AS_YEARLY
} from "core/constants";

export const CPRO_CHECKOUT_TYPE = "cpro-yearly";
export const CPRO_TEAM_CHECKOUT_TYPE = "cpro-team-yearly";
export const YEARLY_CHECKOUT_TYPE = "pro-yearly";
export const MONTHLY_CHECKOUT_TYPE = "pro-monthly";
export const TEAM_YEARLY_CHECKOUT_TYPE = "team-yearly";
export const TEAM_MONTHLY_CHECKOUT_TYPE = "team-monthly";
export const NON_US_YEARLY_CHECKOUT_TYPE = "pro-intl-yearly";
export const NON_US_MONTHLY_CHECKOUT_TYPE = "pro-intl-monthly";
export const EDU_YEARLY_CHECKOUT_TYPE = "edu-1999";
export const SINGLE_ICON_CHECKOUT_TYPE = "payment";
export const STANDARD_PHOTO_CHECKOUT_TYPE = "STANDARD_PHOTO";
export const PREMIUM_PHOTO_CHECKOUT_TYPE = "PREMIUM_PHOTO";
export const STRIPE_ID_FOR_TEAM_ICON_PRO_YEARLY_PLAN = "team-3999";

export const ICONPRO_CHECKOUT_TYPES = [
    YEARLY_CHECKOUT_TYPE,
    MONTHLY_CHECKOUT_TYPE,
    TEAM_YEARLY_CHECKOUT_TYPE,
    TEAM_MONTHLY_CHECKOUT_TYPE,
    EDU_YEARLY_CHECKOUT_TYPE,
    NON_US_YEARLY_CHECKOUT_TYPE,
    NON_US_MONTHLY_CHECKOUT_TYPE
];

export const CREATORPRO_CHECKOUT_TYPES = [
    CPRO_CHECKOUT_TYPE,
    CPRO_TEAM_CHECKOUT_TYPE
];
export const NON_US_CHECKOUT_TYPES = [
    NON_US_YEARLY_CHECKOUT_TYPE,
    NON_US_MONTHLY_CHECKOUT_TYPE
];

export const YEARLY_CHECKOUT_TYPES = [
    YEARLY_CHECKOUT_TYPE,
    TEAM_YEARLY_CHECKOUT_TYPE,
    EDU_YEARLY_CHECKOUT_TYPE,
    CPRO_CHECKOUT_TYPE,
    CPRO_TEAM_CHECKOUT_TYPE,
    NON_US_YEARLY_CHECKOUT_TYPE,
    STRIPE_ID_FOR_TEAM_ICON_PRO_YEARLY_PLAN
];

export const MONTHLY_CHECKOUT_TYPES = [
    MONTHLY_CHECKOUT_TYPE,
    TEAM_MONTHLY_CHECKOUT_TYPE,
    NON_US_MONTHLY_CHECKOUT_TYPE
];

export const PHOTO_CHECKOUT_TYPES = [
    STANDARD_PHOTO_CHECKOUT_TYPE,
    PREMIUM_PHOTO_CHECKOUT_TYPE
];

export const ICON_ASSET = "icon";
export const PHOTO_ASSET = "photo";
export const ICON_SET = "icon-set";

export const PAYMENT_METHODS = {
    CREDIT_CARD: "CREDIT_CARD",
    PAYPAL: "PAYPAL"
};

// Wallet types (should match `savedCard.brand` values)
const AMAZON_WALLET_TYPE = "amazon_pay";
const CASHAPP_WALLET_TYPE = "cashapp";
const LINK_WALLET_TYPE = "link"; // mostly for document purposes; backend clears savedCard.id if using link
const WECHAT_WALLET_TYPE = "wechat"; // TODO: confirm name
// Convert `savedCard.brand` value into a display name.
const WALLET_DISPLAY_NAMES = {
    [AMAZON_WALLET_TYPE]: "Amazon Pay",
    [CASHAPP_WALLET_TYPE]: "Cash App",
    [WECHAT_WALLET_TYPE]: "WeChat"
};
// Which `savedCard.brand` values should NOT show the `SavedPaymentForm`
const STRIPE_ONLY_WALLET_TYPES = [
    AMAZON_WALLET_TYPE,
    LINK_WALLET_TYPE,
    CASHAPP_WALLET_TYPE
];
// Some wallet types require custom stripe methods to process saved payments
const DEFAULT_STRIPE_METHOD = "confirmCardPayment";
const WALLET_STRIPE_METHODS = {
    [CASHAPP_WALLET_TYPE]: "confirmCashappPayment",
    [WECHAT_WALLET_TYPE]: "confirmWechatPayPayment"
};

export const EMPTY_PROMO_CODE = {
    id: null,
    value: "",
    discountType: null,
    amountOff: 0,
    isValid: null, // `null` for when not yet checked
    // subscription upgrade specific promo props
    totalAmountDiscounted: 0 // price discounted; ex: could be 5.00 if `amountOff` represents a 25% discount
};

export const MAX_RECEIPT_CHARS = 500;

const computeFromType = type => {
    const isMonthly = MONTHLY_CHECKOUT_TYPES.includes(type);
    const isYearly = YEARLY_CHECKOUT_TYPES.includes(type);
    const isCreatorPro = CREATORPRO_CHECKOUT_TYPES.includes(type);
    const isIconPro = ICONPRO_CHECKOUT_TYPES.includes(type);
    const hasLocalizedPricing = NON_US_CHECKOUT_TYPES.includes(type);
    const isSubscription = isMonthly || isYearly;
    const isSinglePurchase =
        type === SINGLE_ICON_CHECKOUT_TYPE ||
        type === STANDARD_PHOTO_CHECKOUT_TYPE ||
        type === PREMIUM_PHOTO_CHECKOUT_TYPE;
    const isSingleIconPurchase = type === SINGLE_ICON_CHECKOUT_TYPE;
    const isSinglePhotoPurchase =
        type === STANDARD_PHOTO_CHECKOUT_TYPE ||
        type === PREMIUM_PHOTO_CHECKOUT_TYPE;

    return {
        isCreatorPro,
        isIconPro,
        isMonthly,
        isYearly,
        isSubscription,
        isSinglePurchase,
        isSingleIconPurchase,
        isSinglePhotoPurchase,
        hasLocalizedPricing
    };
};

const computeFromPaymentMethod = paymentMethod => ({
    isPayPal: paymentMethod === PAYMENT_METHODS.PAYPAL,
    isCreditCard: paymentMethod === PAYMENT_METHODS.CREDIT_CARD
});

const calculatePrice = (price, promoCode) => {
    price = removePriceFormatting(price);
    const { amountOff, discountType } = promoCode || {};

    switch (discountType) {
        case CENTS:
            return Math.max(0, price - amountOff);
        case PERCENT:
            return Math.max(0, price - price * amountOff);
        default:
            return price;
    }
};

const removePriceFormatting = value =>
    Number(String(value || 0).replace(/[^\d.]+/g, ""));

const formatPrices = value => {
    value = removePriceFormatting(value);
    const totalPriceInCents = Math.round(value * 100);
    const priceDollars = Math.floor(totalPriceInCents / 100);
    const priceCents = Math.round(totalPriceInCents % 100);
    const price = `${priceDollars.toLocaleString()}.${
        priceCents < 10 ? "0" + priceCents : priceCents
    }`;
    return {
        price,
        totalPriceInCents,
        priceDollars,
        priceCents
    };
};

export const formatPrice = value => {
    const { price } = formatPrices(value);
    return price;
};

///////////////////////////
//   reducer & actions   //
///////////////////////////

export const initialState = {
    // payment form configs
    canUseSavedCard: false,
    canChangeCard: false,
    canUsePromoUrl: false,
    canUsePromoInput: false,
    canUseReceiptInfo: true,
    canUsePayPal: false,
    creditCardOnly: false,
    isEduCheckout: false,
    isTeamCheckout: false,
    isProrated: false,
    teamName: "",
    teamUser: "",
    teamId: null,
    teamSize: 1,
    teamSizeChange: 0,
    /*  `teamPerSeatCost` is only used for team seat quantity changes
    we can't use price constants for this value because some users
    have been grandfathered into the old price, so we get the team's
    price per seat from BE */
    teamPerSeatCost: null,

    // payment form state
    isProcessing: false,
    error: "",
    paymentMethod: PAYMENT_METHODS.CREDIT_CARD, // stripe vs. paypal
    stripePaymentType: "", // card, link, amazon_pay, etc.
    showSavedCard: false, // whether to show saved CC vs. new CC form
    saveNewCard: false, // whether we should save the new CC entry in Stripe
    receiptInfo: "", // VAT input value
    showReceiptInfo: false,
    type: null, // checkout type: cpro-yearly, pro-yearly, pro-monthly, team-yearly, team-monthly, payment, etc.
    promoCode: EMPTY_PROMO_CODE,
    showPromoInput: false,

    // payment data
    paymentIntentId: "",
    paymentIntentSecret: "",
    hasSavedCard: false,
    savedCard: null, // `null` if we haven't queried for the saved card. `{}` if we have queried, and there is none.
    proratedCharge: 0,

    // asset: icon, photo
    assetType: null,
    item: null,

    // post-payment props
    trackingData: null,
    successRedirect: ""
};

const checkout = createSlice({
    name: "checkout",
    initialState,
    reducers: {
        reset(state, action) {
            return initialState;
        },
        setIsProcessing(state, action) {
            state.isProcessing = action.payload === true;
        },
        setError(state, action) {
            state.error = action.payload || "";
        },
        setType(state, action) {
            state.type = action.payload;
        },
        setTeamCheckout(state, action) {
            state.isTeamCheckout = true;
            state.teamId = parseInt(action.payload.id) || null;
            state.teamName = action.payload.name || state.teamName;
            state.teamSize = parseInt(action.payload.size) || state.teamSize;
            state.teamUser = action.payload.user || state.teamUser;
            state.teamSizeChange = parseInt(action.payload.sizeChange || 0);
            state.teamPerSeatCost = action.payload.perSeatCost || null;
        },
        setEduCheckout(state) {
            state.isEduCheckout = true;
        },
        setPaymentSettings(state, action) {
            state.type = action.payload.type || null;
            state.assetType = action.payload.assetType || null;
            state.isUpgrade = action.payload.isUpgrade === true;
            state.currentSubscription =
                action.payload.currentSubscription || null;
            state.canUseSavedCard = action.payload.canUseSavedCard === true;
            state.canChangeCard = action.payload.canChangeCard === true;
            state.canUsePromoUrl = action.payload.canUsePromoUrl === true;
            state.canUsePromoInput = action.payload.canUsePromoInput === true;
            state.canUseReceiptInfo = action.payload.canUseReceiptInfo === true;
            state.canUsePayPal = action.payload.canUsePayPal === true;
            state.creditCardOnly = action.payload.creditCardOnly === true;
            state.backUrl = action.payload.backUrl || null;
            state.cancelAtPeriodEnd = action.payload.cancelAtPeriodEnd || null;
        },
        setUpgradeDetails(state, action) {
            state.isProrated = true;
            state.proratedCharge = action.payload.proratedCharge || null;
            state.eligibleToUpgrade = action.payload.eligibleToUpgrade === true;
            state.expirationHash = action.payload.expirationHash || null;
            state.expirationTimestamp =
                action.payload.expirationTimestamp || null;
        },
        setPostPaymentSettings(state, action) {
            state.trackingData =
                action.payload.trackingData || initialState.trackingData;
            state.successRedirect =
                action.payload.successRedirect || initialState.successRedirect;
        },
        setProratedCharge(state, action) {
            state.proratedCharge = removePriceFormatting(action.payload);
            state.isProrated = state.proratedCharge > 0;
        },
        setPaymentMethod(state, action) {
            const paymentMethod = action.payload;
            if (PAYMENT_METHODS[paymentMethod]) {
                state.paymentMethod = paymentMethod;
            }
        },
        setStripePaymentType(state, action) {
            state.stripePaymentType =
                typeof action.payload === "string" && action.payload
                    ? action.payload
                    : initialState.stripePaymentType;
        },
        setPaymentIntent(state, action) {
            const { id, secret } = action.payload || {};
            if (id && secret) {
                state.paymentIntentId = id;
                state.paymentIntentSecret = secret;
            }
        },
        setSavedCard(state, action) {
            // Sometimes `savedCard` is the entire card, and sometimes it's just the VAT.
            const savedCard = action.payload || {};
            state.savedCard = { ...savedCard };
            // Some saved payment method types need to use Stripe directly & not our saved payment form
            state.hasSavedCard =
                !!state.savedCard.id &&
                !STRIPE_ONLY_WALLET_TYPES.includes(state.savedCard.brand);
            // initially show saved payment form instead of new payment form (this can be changed later)
            state.showSavedCard = state.hasSavedCard;
            // show alternative wallet type, if applicable
            state.savedCard.isCardType =
                state.showSavedCard && !!state.savedCard.last4;
            state.savedCard.walletName = state.savedCard.isCardType
                ? null
                : WALLET_DISPLAY_NAMES[state.savedCard.brand] ||
                  String(state.savedCard.brand || "").toUpperCase();
            // determine if we're customizing the stripe payment method
            state.savedCard.stripeSavedPaymentMethod =
                WALLET_STRIPE_METHODS[state.savedCard.brand] ||
                DEFAULT_STRIPE_METHOD;
            if (state.savedCard.receiptInfo) {
                // if VAT is too long, trim value to avoid backend error
                state.savedCard.receiptInfo = state.savedCard.receiptInfo.slice(
                    0,
                    MAX_RECEIPT_CHARS
                );
                // if saved card has VAT data, copy over to VAT's input value
                state.receiptInfo = state.savedCard.receiptInfo;
                state.showReceiptInfo = true;
            }
        },
        setShowSavedCard(state, action) {
            state.showSavedCard = action.payload === true;
        },
        toggleSaveNewCard(state) {
            state.saveNewCard = !state.saveNewCard;
        },
        setReceiptInfo(state, action) {
            const receiptInfo = action.payload;
            if (typeof receiptInfo === "string") {
                state.receiptInfo = receiptInfo.slice(0, MAX_RECEIPT_CHARS);
            }
        },
        toggleShowReceiptInfo(state) {
            state.showReceiptInfo = !state.showReceiptInfo;
        },
        setItem(state, action) {
            state.item = action.payload;
        },
        setPromoCodeValue(state, action) {
            if (typeof action.payload === "string") {
                state.promoCode = {
                    ...EMPTY_PROMO_CODE,
                    value: action.payload
                };
            }
        },
        setPromoCodeResults(state, action) {
            const {
                id,
                value,
                discountType,
                amountOff,
                isValid,
                totalAmountDiscounted
            } = action.payload || {};
            if (typeof value === "string") {
                state.promoCode.value = value;
            }
            if (typeof isValid === "boolean") {
                state.promoCode.isValid = isValid;
                state.promoCode.id = id;
            }
            if (discountType && amountOff) {
                state.promoCode.discountType = discountType;
                const value = parseFloat(amountOff);
                state.promoCode.amountOff = Math.max(0, value || 0); // ignore negative numbers, NaN, etc.
            }
            if (totalAmountDiscounted) {
                state.promoCode.totalAmountDiscounted = removePriceFormatting(
                    totalAmountDiscounted
                );
            }
        },
        resetPromoCode(state) {
            state.promoCode = EMPTY_PROMO_CODE;
        },
        setShowPromoInput(state, action) {
            state.showPromoInput = !!action.payload;
        }
    }
});

export default checkout.reducer;

export const {
    reset,
    setIsProcessing,
    setError,
    setType,
    setTeamCheckout,
    setEduCheckout,
    setPaymentSettings,
    setPostPaymentSettings,
    setUpgradeDetails,
    setProratedCharge,
    setPaymentMethod,
    setStripePaymentType,
    setPaymentIntent,
    setSavedCard,
    setShowSavedCard,
    toggleSaveNewCard,
    setReceiptInfo,
    toggleShowReceiptInfo,
    setItem,
    setPromoCodeValue,
    setPromoCodeResults,
    resetPromoCode,
    setShowPromoInput
} = checkout.actions;

////////////////////////////
//   selector-functions   //
////////////////////////////

const selectSelf = state => state.checkout;

export const getError = createSelector(selectSelf, state => state.error);
export const getType = createSelector(selectSelf, state => state.type);
export const getItem = createSelector(selectSelf, state => state.item);

export const getIsFetching = createSelector(
    selectSelf,
    state => state.savedCard === null || !state.paymentIntentId
);

export const getCheckoutFeatures = createSelector(selectSelf, state => ({
    canUseSavedCard: state.canUseSavedCard,
    canChangeCard: state.canChangeCard,
    canUsePromoUrl: state.canUsePromoUrl,
    canUsePromoInput: state.canUsePromoInput,
    canUseReceiptInfo: state.canUseReceiptInfo,
    canUsePayPal: state.canUsePayPal
}));

export const getPaymentData = createSelector(selectSelf, state => {
    const data = {
        ...state,
        ...computeFromType(state.type),
        ...computeFromPaymentMethod(state.paymentMethod)
    };

    // For subscriptions - we always save the card, which will replace any existing default cards.
    // If ALC - some payment methods do not support saving for later. And we don't want to change previously saved payment methods.
    const canSaveNewCard =
        data.isSubscription ||
        (!data.hasSavedCard &&
            !STRIPE_ONLY_WALLET_TYPES.includes(state.stripePaymentType));
    const saveNewCard = canSaveNewCard && data.saveNewCard;

    // yearly price per user
    const _yearlyPriceValue = data.isEduCheckout
        ? EDU_PER_YEAR_COST
        : data.isCreatorPro
        ? CPRO_PRICE_AS_YEARLY
        : data.hasLocalizedPricing
        ? NON_US_ANNUAL_PRICE_AS_YEARLY
        : ANNUAL_PRICE_AS_YEARLY;
    const yearlyPrice = formatPrice(_yearlyPriceValue);
    const yearlyPaidMonthlyPrice = formatPrice(_yearlyPriceValue / 12);

    // monthly price per user
    const _monthlyPriceValue = data.hasLocalizedPricing
        ? NON_US_MONTHLY_PRICE_PLAN
        : MONTHLY_PRICE_PLAN;
    const monthlyPrice = formatPrice(_monthlyPriceValue);

    const teamPriceChange = formatPrice(
        (data.isMonthly ? data.teamPerSeatCost : _yearlyPriceValue) *
            parseInt(data.teamSizeChange)
    );

    // Determine which price is relevant to the current checkout state
    const _teamMultiplier = data.isTeamCheckout ? parseInt(data.teamSize) : 1;
    const _fullPrice = data.isMonthly
        ? _monthlyPriceValue * _teamMultiplier
        : data.isYearly
        ? _yearlyPriceValue * _teamMultiplier
        : data.type === SINGLE_ICON_CHECKOUT_TYPE
        ? SINGLE_ICON_PRICE
        : data.type === STANDARD_PHOTO_CHECKOUT_TYPE
        ? STANDARD_PHOTO_PRICE
        : data.type === PREMIUM_PHOTO_CHECKOUT_TYPE
        ? PREMIUM_PHOTO_PRICE
        : 0;

    // Apply promo code as applicable
    const canUsePromoCode =
        (data.canUsePromoUrl || data.canUsePromoInput) &&
        data.paymentMethod === PAYMENT_METHODS.CREDIT_CARD;
    const _price = data.isProrated
        ? calculatePrice(data.proratedCharge) // prorated charges already include promo discounts
        : calculatePrice(_fullPrice, canUsePromoCode ? data.promoCode : null);
    const { price, priceDollars, priceCents, totalPriceInCents } = formatPrices(
        _price
    );
    const subtotal = formatPrice(_fullPrice);

    const proratedDiscount = data.isProrated
        ? formatPrice(
              _fullPrice -
                  data.proratedCharge -
                  data.promoCode.totalAmountDiscounted
          )
        : formatPrice(0);

    const hasReceiptInfoChanged = data.savedCard?.receiptInfo
        ? data.receiptInfo !== data.savedCard.receiptInfo
        : !!data.receiptInfo;

    // For now, we cannot use promo codes on PayPal
    const hasValidPromoCode = !!(
        data.promoCode.value && data.promoCode.isValid
    );
    const hasPromoCodeApplied = hasValidPromoCode && canUsePromoCode;

    return {
        ...data,
        saveNewCard,
        canSaveNewCard,
        hasValidPromoCode,
        hasPromoCodeApplied,
        hasReceiptInfoChanged,
        // currently selected price (based on checkout type)
        // taking into account promo code, prorations, etc.
        price,
        proratedDiscount,
        // price before promo codes / proration
        subtotal,
        priceDollars,
        priceCents,
        totalPriceInCents,
        // subscription price (based on currently selected team size, if applicable)
        monthlyPrice,
        yearlyPrice,
        yearlyPaidMonthlyPrice,
        teamPriceChange,
        unformattedPrice: _price,
        unformattedSubtotal: _fullPrice
    };
});
