// NOTE:
// When we receive an updated spreadsheet from Squarespace,
// some finessing of the data is required. It's not copy/paste.
//
// See instructions below.
export const ADS = [
    {
        // NOTE:
        // Download the asset from the "link to asset" column.
        // Rename asset to something sensible.
        // If needed, optimize image. I used the following settings:
        //   JPG w/ 1092px wide, 80% quality, and stripped meta-data
        // For performance, place images in:
        //   nginx/public/ads/
        // To test images locally, use:
        //   ```
        //   docker compose down
        //   docker compose build nginx
        //   docker compose up
        //   ```
        img: "/ads/squarespace-Q124-1-728x90.jpg",

        // NOTE:
        // Find value in "Impression Tag (Javascript)" column.
        // Grab only the script src URL and ignore the rest.
        // Clean the URL via:
        // - Remove the `?` at the end of the URL
        // - Make sure `;ltd=` is at the end of URL
        // - Delete `;ord=[timestamp]`
        // - Delete `${GDPR}`
        // - Delete `${GDPR_CONSENT_755}`
        //
        // Example:
        // - URL provided by Squarespace:
        //   https://ad.doubleclick.net/ddm/trackimpi/N718679.3040413THENOUNPROJECT.CO/B29332811.360309386;dc_trk_aid=551331108;dc_trk_cid=187188328;ord=[timestamp];dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ltd=?
        //
        // - URL becomes:
        //   https://ad.doubleclick.net/ddm/trackimpi/N718679.3040413THENOUNPROJECT.CO/B29332811.360309386;dc_trk_aid=551331108;dc_trk_cid=187188328;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;gdpr=;gdpr_consent=;ltd=
        //
        // Structure:
        // - `...THENOUNPROJECT.CO/{Campaign ID}.{Placement ID};dc_trk_aid={Ad ID};dc_trk_cid={Creative ID}`
        //
        impression:
            "https://ad.doubleclick.net/ddm/trackimpj/N718679.3040413THENOUNPROJECT.CO/B29332811.384890332;dc_trk_aid=575938555;dc_trk_cid=207494836;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;gdpr=;gdpr_consent=;dc_tdv=1;ltd=",

        // NOTE:
        // Find value in "Click Tag" column.
        // Make sure link ends in `;ltd=`
        click:
            "https://ad.doubleclick.net/ddm/trackclk/N718679.3040413THENOUNPROJECT.CO/B29332811.384890332;dc_trk_aid=575938555;dc_trk_cid=207494836;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;dc_tdv=1;ltd="
    },
    {
        img: "/ads/squarespace-Q124-2-728x90.jpg",
        impression:
            "https://ad.doubleclick.net/ddm/trackimpj/N718679.3040413THENOUNPROJECT.CO/B29332811.388505361;dc_trk_aid=579630091;dc_trk_cid=207494836;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;gdpr=;gdpr_consent=;dc_tdv=1;ltd=",
        click:
            "https://ad.doubleclick.net/ddm/trackclk/N718679.3040413THENOUNPROJECT.CO/B29332811.388505361;dc_trk_aid=579630091;dc_trk_cid=207494836;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;dc_tdv=1;ltd="
    },
    {
        img: "/ads/squarespace-Q124-3-728x90.jpg",
        impression:
            "https://ad.doubleclick.net/ddm/trackimpj/N718679.3040413THENOUNPROJECT.CO/B29332811.388506222;dc_trk_aid=579520178;dc_trk_cid=207494836;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;gdpr=;gdpr_consent=;dc_tdv=1;ltd=",
        click:
            "https://ad.doubleclick.net/ddm/trackclk/N718679.3040413THENOUNPROJECT.CO/B29332811.388506222;dc_trk_aid=579520178;dc_trk_cid=207494836;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;dc_tdv=1;ltd="
    },
    {
        img: "/ads/squarespace-Q324-1-728x90.jpg",
        impression:
            "https://ad.doubleclick.net/ddm/trackimpj/N718679.3040413THENOUNPROJECT.CO/B29332811.401293672;dc_trk_aid=593420457;dc_trk_cid=207494836;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;gdpr=;gdpr_consent=;dc_tdv=1;ltd=",
        click:
            "https://ad.doubleclick.net/ddm/trackclk/N718679.3040413THENOUNPROJECT.CO/B29332811.401293672;dc_trk_aid=593420457;dc_trk_cid=207494836;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;dc_tdv=1;ltd="
    },
    {
        img: "/ads/squarespace-Q324-2-728x90.jpg",
        impression:
            "https://ad.doubleclick.net/ddm/trackimpj/N718679.3040413THENOUNPROJECT.CO/B29332811.401662560;dc_trk_aid=593420460;dc_trk_cid=207494836;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;gdpr=;gdpr_consent=;dc_tdv=1;ltd=",
        click:
            "https://ad.doubleclick.net/ddm/trackclk/N718679.3040413THENOUNPROJECT.CO/B29332811.401662560;dc_trk_aid=593420460;dc_trk_cid=207494836;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;dc_tdv=1;ltd="
    }
];
