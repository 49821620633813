import React, { useEffect } from "react";
import { useMutation } from "@apollo/client";
import styled from "styled-components";

import { textMd, bold } from "core/fontStyles";
import colors from "core/colors";

import Signup from "../Signup";
import Button from "core/Button";
import Link from "core/Link";
import LoadingSpinner from "core/LoadingSpinner";
import {
    CONNECT_SOCIAL_ACCOUNT,
    CLEAR_PENDING_SOCIAL_LOGIN
} from "frontend/components/auth/queries";
import {
    PageTitle,
    PageSubtitle,
    PageWrapper,
    SocialSignupConfirmationFormContainer,
    FormContainer
} from "../styles";

import { afterSocialAuth } from "frontend/hooks/useAuth";
import { useSignupEmail } from "../helpers";

const ConnectAccountMessage = styled.div`
    ${textMd}
    text-align: center;

    p {
        margin-bottom: 1rem;
    }

    strong {
        ${bold}
    }
`;

const Error = styled.p`
    color: ${colors.functional.lightMode.sangria};
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    margin-top: 2rem;
`;

const SocialSignupConfirmationPage = () => {
    const { provider, canConnect, signupEmail, nextUrl } = useSignupEmail({
        isSocialSignup: true
    });

    const capitalizedProviderName =
        provider && provider[0].toUpperCase() + provider.substring(1);

    const [connectSocialAuth, { data, loading }] = useMutation(
        CONNECT_SOCIAL_ACCOUNT
    );
    const didConnect = data?.connectActiveSocialLogin?.ok;
    const error = data?.connectActiveSocialLogin?.error;

    const [clearPendingSocialLogin, { data: clearData }] = useMutation(
        CLEAR_PENDING_SOCIAL_LOGIN
    );
    const didClear = clearData?.removeActiveSocialLogin?.ok;

    useEffect(() => {
        if (didConnect) {
            afterSocialAuth(nextUrl);
        }
    }, [didConnect]);

    useEffect(() => {
        if (didClear) {
            window.location.href = nextUrl;
        }
    }, [didClear]);

    return (
        <PageWrapper darkMode>
            <PageTitle darkMode>One Last Step</PageTitle>
            <PageSubtitle>
                Make sure your information from {capitalizedProviderName} is
                correct.
            </PageSubtitle>
            <SocialSignupConfirmationFormContainer>
                <FormContainer>
                    {!provider ? (
                        <LoadingSpinner />
                    ) : canConnect ? (
                        <ConnectAccountMessage>
                            <p>
                                To confirm log in to your Noun Project account (
                                <strong>{signupEmail}</strong>) with Google,
                                please click the button below.
                            </p>
                            {error && <Error>{error}</Error>}
                            <ButtonContainer>
                                <Button
                                    isDisabled={loading}
                                    width="100%"
                                    onClick={() => connectSocialAuth()}
                                >
                                    Complete Google Log In
                                </Button>
                                <Link
                                    onClick={() => clearPendingSocialLogin()}
                                    isButton={true}
                                >
                                    Cancel
                                </Link>
                            </ButtonContainer>
                        </ConnectAccountMessage>
                    ) : (
                        <Signup isSocialSignup afterAuth={afterSocialAuth} />
                    )}
                </FormContainer>
            </SocialSignupConfirmationFormContainer>
        </PageWrapper>
    );
};

export default SocialSignupConfirmationPage;
