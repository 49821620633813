import find from "lodash/find";
import findIndex from "lodash/findIndex";
import map from "lodash/map";
import get from "lodash/get";
import defer from "lodash/defer";

import localStorage from "../legacy/helpers/localStorage";
import * as actionTypes from "../actions/actionTypes";
import { DOWNLOAD_ENTER_PHASE } from "./download";

export const ALACARTE_TYPE = "alacarte";
const MAX_SEATS = 999;
export const PREPAID_TYPE = "prepaid";
export const PRO_TYPE = "pro";
export const CONSUMING_PREPAID = "CONSUMING_PREPAID";

const purchaseTypes = [
    {
        type: PRO_TYPE,
        period: "yearly",
        text: "Yearly NounPro Subscription",
        description:
            "You will be charged now and yearly at this time when your account automatically renews. Cancel automatic renewal at any time."
    },
    {
        type: PRO_TYPE,
        period: "monthly",
        text: "Monthly NounPro Subscription",
        description:
            "You will be charged now and monthly at this time when your account automatically renews. Cancel automatic renewal at any time."
    },
    {
        type: ALACARTE_TYPE,
        text: "Single Icon",
        description:
            "Purchasing a single license gives you a perpetual, non-exclusive right to use this icon as many times as you wish, without having to credit the icon’s creator."
    },
    {
        type: CONSUMING_PREPAID,
        hide: true
    }
];

export const defaultPrepaidNumIcons = 10;
export const minPrepaidIcons = 2;
export const maxPrepaidIcons = 100;

const defaultState = {
    saveCard: true,
    prepaidNumIcons: defaultPrepaidNumIcons
};

const setPrepaidPrice = (purchaseTypes, alacartePrice, numIcons) => {
    return purchaseTypes.map(purchaseType => {
        if (purchaseType.type == ALACARTE_TYPE) {
            purchaseType.price = alacartePrice;
        }
        if (purchaseType.type == PREPAID_TYPE) {
            purchaseType.price = numIcons * alacartePrice;
        }

        if (purchaseType.type == CONSUMING_PREPAID) {
            purchaseType.price = alacartePrice;
        }

        return purchaseType;
    });
};

const setProPrices = (purchaseTypes, monthlyProPrice, yearlyProPrice) => {
    return purchaseTypes.map(purchaseType => {
        if (purchaseType.period === "monthly") {
            purchaseType.price = monthlyProPrice;
        }

        if (purchaseType.period === "yearly") {
            purchaseType.price = yearlyProPrice;
        }

        return purchaseType;
    });
};

const makePurchaseTypeKeys = purchaseTypes => {
    return map(purchaseTypes, t => {
        if (t.period) {
            t.key = `${t.type}.${t.period}`;
        } else {
            t.key = `${t.type}`;
        }
        return t;
    });
};

defaultState.purchaseTypes = makePurchaseTypeKeys(purchaseTypes);
export { defaultState };

export default (state = defaultState, action) => {
    let { processing } = state;

    switch (action.type) {
        case "SET_GLOBAL_CONFIG": {
            let { config } = action;
            let { purchaseTypes } = state;
            let {
                singleIconPriceCents,
                monthlyProPlanCents,
                yearlyProPlanCents
            } = config;

            if (
                !singleIconPriceCents ||
                !monthlyProPlanCents ||
                !yearlyProPlanCents
            ) {
                defer(() => {
                    if (!singleIconPriceCents)
                        throw new Error("Single Icon Price not defined");

                    if (!monthlyProPlanCents)
                        throw new Error("Monthly Pro Plan Price not defined");

                    if (!yearlyProPlanCents)
                        throw new Error("Yearly Pro Plan Price not defined");
                });
                return {
                    ...state,
                    error:
                        "Something went wrong. Please reload the page and try again."
                };
            }

            purchaseTypes = setPrepaidPrice(
                purchaseTypes,
                singleIconPriceCents,
                defaultPrepaidNumIcons
            );

            purchaseTypes = setProPrices(
                purchaseTypes,
                monthlyProPlanCents,
                yearlyProPlanCents
            );

            return {
                ...state,
                purchaseTypes
            };
        }
        case DOWNLOAD_ENTER_PHASE.SELECT_PURCHASE_TYPE:
        case actionTypes.DOWNLOAD_SELECT_PURCHASE_TYPE: {
            let { purchaseTypeKey, prepaidBalance } = action;
            let { savedCard, currentPurchaseKey } = state;

            if (purchaseTypeKey) {
                currentPurchaseKey = purchaseTypeKey;
            }

            let hadBalance =
                currentPurchaseKey == CONSUMING_PREPAID && prepaidBalance === 0;

            if (!currentPurchaseKey || hadBalance) {
                if (!savedCard) {
                    currentPurchaseKey = "pro.yearly";
                } else {
                    currentPurchaseKey = ALACARTE_TYPE;
                }
            }

            if (processing) {
                return state;
            }

            return {
                ...state,
                currentPurchaseKey
            };
        }
        case actionTypes.STRIPE_CARD_INPUT_ERROR: {
            let { error, elementType } = action;
            let fieldErrors = state.fieldErrors || {};
            if (elementType) {
                fieldErrors = setFieldError(fieldErrors, elementType, error);
            }
            return {
                ...state,
                error,
                fieldErrors
            };
        }
        case actionTypes.STRIPE_CARD_INPUT_CHANGE: {
            let { elementType } = action;
            let { fieldErrors } = state;

            fieldErrors = unsetFieldError(fieldErrors, elementType);

            return {
                ...state,
                error: null,
                fieldErrors
            };
        }
        case actionTypes.STRIPE_SAVE_CARD_CHANGE: {
            if (processing) {
                return state;
            }

            let { value } = action;
            return {
                ...state,
                saveCard: value
            };
        }
        case actionTypes.STRIPE_GET_SAVED_CARD_SUCCESS: {
            let savedCard = action.response;
            if (!savedCard.ok) {
                return {
                    ...state,
                    savedCard: null
                };
            }

            let { receipt_info: billingInfo } = savedCard;

            let { skipBillingInfo } = action;
            if (skipBillingInfo) {
                billingInfo = "";
            }

            return {
                ...state,
                savedCard,
                billingInfo
            };
        }
        case actionTypes.STRIPE_USE_NEW_CARD: {
            if (processing) {
                return state;
            }

            let useNewCard = state.useNewCard;

            let saveCard = false;

            return {
                ...state,
                error: null,
                useNewCard: !useNewCard,
                saveCard
            };
        }
        case actionTypes.SAVE_CARD: {
            return {
                ...state,
                processing: true,
                error: null
            };
        }
        case actionTypes.SAVE_CARD_SUCCESS: {
            return {
                ...state,
                processing: false,
                useNewCard: false
            };
        }
        case actionTypes.STRIPE_SAVE_BILLING_INFO:
        case actionTypes.STRIPE_CREATE_TOKEN:
        case actionTypes.UPGRADE_BILLING: {
            return {
                ...state,
                error: null,
                processing: true
            };
        }
        case actionTypes.STRIPE_CREATE_TOKEN_FAILURE:
        case actionTypes.STRIPE_CREATE_TOKEN_SUCCESS:
        case actionTypes.STRIPE_SAVE_BILLING_INFO_SUCCESS:
        case actionTypes.STRIPE_SAVE_BILLING_INFO_FAILURE:
        case actionTypes.UPGRADE_BILLING_FAILURE:
        case actionTypes.UPGRADE_BILLING_SUCCESS: {
            return {
                ...state,
                processing: false
            };
        }
        case actionTypes.SAVE_CARD_FAILURE: {
            let { error } = action;

            let message =
                get(error, "error.message") ||
                get(error, "errors.error.message") ||
                "Something went wrong while trying to save your new card.";

            return {
                ...state,
                processing: false,
                error: message
            };
        }
        case actionTypes.STRIPE_UPDATE_BILLING_INFO: {
            if (processing) {
                return state;
            }

            let { value } = action;

            return {
                ...state,
                billingInfo: value
            };
        }

        case actionTypes.STRIPE_DELETE_CARD: {
            return {
                ...state,
                processing: true,
                error: null
            };
        }

        case actionTypes.STRIPE_DELETE_CARD_SUCCESS: {
            return {
                ...state,
                processing: false,
                savedCard: null
            };
        }

        case actionTypes.STRIPE_DELETE_CARD_FAILURE: {
            let { error } = action;

            let message =
                get(error, "error.message") ||
                get(error, "errors.error.message") ||
                "Something went wrong while trying to delete your card.";

            return {
                ...state,
                processing: false,
                error: message
            };
        }

        case actionTypes.DOWNLOAD_SET_FLOW_ACTIVE: {
            let { purchaseTypes } = state;

            return {
                ...state,
                purchaseTypes
            };
        }

        case actionTypes.DOWNLOAD_PURCHASE_START:
        case actionTypes.STRIPE_CLEAR_ERRORS: {
            return {
                ...state,
                fieldErrors: {},
                error: null
            };
        }

        case actionTypes.STRIPE_COUPON_CODE_CHANGE: {
            let { couponCode } = action;
            let { fieldErrors } = state;
            fieldErrors = unsetFieldError(fieldErrors, "couponCode");
            return {
                ...state,
                fieldErrors,
                couponCode
            };
        }

        case actionTypes.STRIPE_COUPON_CODE_CHECK: {
            let coupon = null;
            return { ...state, coupon, processing: true };
        }

        case actionTypes.STRIPE_COUPON_CODE_CHECK_SUCCESS: {
            let { coupon } = action.response;
            let { fieldErrors } = state;
            fieldErrors = unsetFieldError(fieldErrors, "couponCode");
            return {
                ...state,
                coupon,
                fieldErrors,
                processing: false
            };
        }

        case actionTypes.STRIPE_COUPON_CODE_CHECK_FAILURE: {
            let { fieldErrors } = state;
            fieldErrors = setFieldError(
                fieldErrors,
                "couponCode",
                "This is not a valid promotional code"
            );
            return {
                ...state,
                coupon: null,
                fieldErrors,
                processing: false
            };
        }

        case actionTypes.STRIPE_NUM_SEATS_CHANGE: {
            let { seats, minSeats } = action;

            let seatsIsNumber = seats && !isNaN(+seats);

            if (seatsIsNumber) {
                seats = Math.floor(seats);
                if (seats > MAX_SEATS) {
                    seats = state.seats;
                }
            } else if (seats != "") {
                seats = minSeats;
            }

            return {
                ...state,
                seats
            };
        }

        case actionTypes.STRIPE_NUM_SEATS_BLUR: {
            let { seats, minSeats } = action;

            let tooSmall = seats < minSeats;
            let tooBig = seats > MAX_SEATS;

            if (!seats || tooSmall) {
                seats = minSeats;
            }

            if (tooBig) {
                seats = state.seats;
            }

            return {
                ...state,
                seats
            };
        }

        case actionTypes.DOWNLOAD_RESET_FLOW: {
            let { preserveEdits } = action;
            let {
                purchaseTypes,
                currentPurchaseKey,
                billingInfo,
                prepaidNumIcons,
                useNewCard,
                saveCard
            } = state;

            if (!preserveEdits) {
                useNewCard = false;
                saveCard = true;
                currentPurchaseKey = null;
                billingInfo = null;
                prepaidNumIcons = defaultPrepaidNumIcons;

                let alacartePurchase = find(purchaseTypes, {
                    key: ALACARTE_TYPE
                });

                purchaseTypes = setPrepaidPrice(
                    defaultState.purchaseTypes,
                    alacartePurchase.price,
                    defaultPrepaidNumIcons
                );
            }

            return {
                ...state,
                error: null,
                useNewCard,
                saveCard,
                currentPurchaseKey,
                billingInfo,
                purchaseTypes,
                prepaidNumIcons
            };
        }

        case actionTypes.STRIPE_PREPAID_NUM_ICONS_CHANGE: {
            let { numIcons } = action;
            let { error, purchaseTypes } = state;
            let alacartePurchase = find(purchaseTypes, { key: ALACARTE_TYPE });

            let empty = !numIcons;
            let isNumber = numIcons && !isNaN(+numIcons);
            let tooBig = numIcons > maxPrepaidIcons;
            let tooSmall = numIcons < minPrepaidIcons;

            if (isNumber) {
                numIcons = +numIcons;
                numIcons = Math.floor(numIcons);
                if (tooBig) {
                    numIcons = state.prepaidNumIcons || defaultPrepaidNumIcons;
                }
            } else if (numIcons != "") {
                numIcons = state.prepaidNumIcons || minPrepaidIcons;
            }

            let numberForPrice = numIcons;
            if (numIcons < minPrepaidIcons) {
                numberForPrice = 0;
            }

            purchaseTypes = setPrepaidPrice(
                purchaseTypes,
                alacartePurchase.price,
                numberForPrice
            );

            if (!numIcons) {
                error = "Please select an amount to pre-pay.";
            } else if (numIcons < minPrepaidIcons) {
                error = `You must pre-pay for at least ${minPrepaidIcons} icons.`;
            } else {
                error = null;
            }

            return {
                ...state,
                error,
                prepaidNumIcons: numIcons,
                purchaseTypes
            };
        }

        case actionTypes.STRIPE_PREPAID_NUM_ICONS_BLUR: {
            let { prepaidNumIcons, purchaseTypes, error } = state;
            let alacartePurchase = find(purchaseTypes, { key: ALACARTE_TYPE });
            let singleIconPriceCents = alacartePurchase.price;
            let tooSmall = prepaidNumIcons < minPrepaidIcons;
            let tooBig = prepaidNumIcons > maxPrepaidIcons;

            if (tooSmall || !prepaidNumIcons) {
                prepaidNumIcons = minPrepaidIcons;
            }

            if (tooBig) {
                prepaidNumIcons = maxPrepaidIcons;
            }

            purchaseTypes = setPrepaidPrice(
                purchaseTypes,
                singleIconPriceCents,
                prepaidNumIcons
            );

            return {
                ...state,
                purchaseTypes,
                prepaidNumIcons,
                error: null
            };
        }

        default:
            return state;
    }
};

const unsetFieldError = (fieldErrors = {}, key) => {
    fieldErrors = { ...fieldErrors };
    delete fieldErrors[key];
    return fieldErrors;
};

const setFieldError = (fieldErrors = {}, key, error) => {
    fieldErrors = { ...fieldErrors };
    fieldErrors[key] = error;
    return fieldErrors;
};
