import colors from "core/colors";

const title = "DLS: TagLink:";

// TagLink DLS
// per Figma: https://www.figma.com/file/xTLiaJuirKEJSb5czcp6lK/NounDesignSystem?node-id=12830%3A58156&mode=dev

/*---------------------------
| Light
---------------------------*/
const light = {
    title: `${title}: Light`,
    static: {
        color: colors.secondary.basalt,
        background: colors.primary.diamond
    },
    hover: {
        color: colors.secondary.cosmic,
        background: colors.secondary.ridge
    },
    disabled: {
        color: colors.secondary.dolphin,
        background: colors.primary.whisker
    }
};

/*---------------------------
| Dark
---------------------------*/
let dark = {
    title: `${title}: Dark`,
    static: {
        color: colors.accent.darkMode.suenosBuenos,
        background: `transparent`
    },
    hover: {
        color: colors.secondary.sword,
        background: `transparent`
    },
    disabled: {
        color: colors.secondary.dolphin,
        background: colors.primary.whisker
    }
};

export const dls = { light, dark };
