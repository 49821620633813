import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { linkTargets } from "core/props";

import { StyledButton } from "./styles";

const UnstyledIconButton = ({
    className,
    svgElementType,
    darkMode = false,
    width,
    ariaLabel,
    styleType = "primary",
    buttonProps,
    iconProps,
    href,
    hrefTarget = "_self"
}) => {
    const Icon = svgElementType;

    const anchorProps = href && {
        href,
        target: hrefTarget
    };

    return (
        <StyledButton
            as={href ? "a" : "button"}
            className={className}
            darkMode={darkMode}
            width={width}
            aria-label={ariaLabel}
            title={ariaLabel} // shows on hover
            styleType={styleType}
            {...buttonProps}
            {...anchorProps}
        >
            <Icon {...iconProps} />
        </StyledButton>
    );
};

const IconButton = styled(UnstyledIconButton)``;

IconButton.propTypes = {
    svgElementType: PropTypes.elementType.isRequired,
    darkMode: PropTypes.bool,
    width: PropTypes.string,
    ariaLabel: PropTypes.string.isRequired,
    styleType: PropTypes.oneOf(["primary", "secondary"]),
    buttonProps: PropTypes.object,
    iconProps: PropTypes.object,
    href: PropTypes.string,
    hrefTarget: PropTypes.oneOf(linkTargets)
};

export default IconButton;
