import React from "react";

import Tooltip from "core/Tooltip";
import QuestionIcon from "core/ReusableIcons/Question";

const TooltipText = () => (
    <>
        For more information, please read our{" "}
        <a
            href="https://brand.thenounproject.com/k/Noun-Project-Photographer-Guide-P9J8Wn"
            target="_blank"
        >
            photographer guide
        </a>
        .
    </>
);

const MastheadTooltip = () => (
    <Tooltip message={<TooltipText />} clickToOpen>
        <QuestionIcon size={{ height: 24, width: 24 }} />
    </Tooltip>
);

export default MastheadTooltip;
