import { useEffect, useState, useRef } from "react";
import debounce from "lodash/debounce";

export const useWindowResize = (onChange, debounceTime = 300) => {
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });
    const prevWindowSizeRef = useRef(windowSize);

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            });
        };

        // Listen for window resize events
        window.addEventListener("resize", debounce(handleResize, debounceTime));

        // Remove event listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    // trigger listener whenever dimensions change
    useEffect(() => {
        if (
            windowSize.width !== prevWindowSizeRef.current.width ||
            windowSize.height !== prevWindowSizeRef.current.height
        ) {
            prevWindowSizeRef.current = windowSize;
            onChange?.();
        }
    }, [windowSize]);

    // in case consumer wants to keep an eye on these dimensional changes.
    return windowSize;
};
