import styled from "styled-components";

import SectionContainer from "core/SectionContainer";
import Messages from "core/Messages";
import { MastheadHeadingContainer as MastheadHeadingContainerBase } from "../PhotoUploadCore/styles";

import { convertPxToRem } from "frontend/components/helpers";

export const MastheadContainer = styled(SectionContainer).attrs({
    removeLeftRightPadding: true,
    topSpacing: "2xl",
    bottomSpacing: "2xl"
})``;

export const GuidelinesMessage = styled(Messages).attrs({
    styleType: "solid",
    infoType: "info",
    isDismissable: false,
    width: "auto"
})``;

export const MastheadHeadingContainer = styled(MastheadHeadingContainerBase)`
    margin-bottom: ${convertPxToRem({ px: 16 })};
`;
