import { createSlice, createSelector } from "@reduxjs/toolkit";

///////////////////////////
//   reducer & actions   //
///////////////////////////

// State will be the config object set by backend, with the following shape:
// {
//    "debugMode": false,
//    "environment": "production",
//    "release": "802bd5e",
//    "sentryDSN": "...",
//    "collectSearchMetrics": true,
//    "graphqlEndpoint": "https://thenounproject.com/graphql/",
//    "localGraphqlEndpoint": "https://thenounproject.com/graphql/",
//    "singleIconPriceCents": 299,
//    "fullResPhotoPriceCents": 3300,
//    "largePhotoPriceCents": 850,
//    "monthlyProPlanCents": 999,
//    "yearlyProPlanCents": 3999,
//    "captchaKey": "...",
//    "accountSignupRequireCaptcha": true,
//    "accountLoginRequireCaptcha": false,
//    "stripePublishable": "...",
//    "stripeKey": "...",
//    "stripeSuggestAddress": true,
//    "googleAuthClientId": "...",
//    "googleAuthRedirectRelativeUrl": "/accounts/google-jwt/login/callback/",
//    "paypalClientId": "...",
//    "csrfCookieName": "csrftoken",
//    "sessionCookieName": "sessionid",
//    "banners": {...},
//    "analytics": {...},
//    "domain": "https://thenounproject.com"
// }
const initialState = {};

const config = createSlice({
    name: "config",
    initialState,
    reducers: {
        setConfig(state, action) {
            return action.payload;
        }
    }
});

export default config.reducer;

export const { setConfig } = config.actions;

////////////////////////////
//   selector-functions   //
////////////////////////////

const selectSelf = state => state.config;

export const getConfig = createSelector(selectSelf, state => {
    // support legacy pages
    if (state === undefined && typeof _nounConfig !== "undefined") {
        return _nounConfig;
    }
    return state;
});
