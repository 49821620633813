import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

// Components
import { SelectAllAndMessaging as CoreSelectAllAndMessaging } from "frontend/components/fort/mod/core/ActionBar";

// Config
import * as Config from "frontend/components/fort/mod/photos/config";

import { QUEUE_VIEWS } from "../../../core/constants";
import { QUEUES } from "../../constants";

import { useSelect } from "frontend/components/core/Grid2/GridItemContentTemplates/Select/SelectContext/index.js";

const SelectAllAndMessaging = () => {
    const selectedBatch = useSelector(state => state.selectedBatch);
    const {
        quantitySelected,
        isAllSelected,
        selectAll,
        deselectAll
    } = useSelect();

    const { name: currentQueue } = useSelector(state => state.currentQueue);
    const isCompOrSusp =
        currentQueue === QUEUES.SUSPENDED || currentQueue === QUEUES.COMPLETED;
    const selectMessage = Config.actionBar.getSelectMessage(
        quantitySelected,
        isCompOrSusp
    );

    const handleSelectAllClick = () => {
        isAllSelected ? deselectAll() : selectAll();
    };

    return (
        <CoreSelectAllAndMessaging
            selectMessage={selectMessage}
            hasSelectedUploads={quantitySelected > 0}
            allSelected={isAllSelected}
            onClick={handleSelectAllClick}
            showSelectAllCheckbox={
                // currentQueue !== QUEUES.SUSPENDED &&
                currentQueue !== QUEUES.COMPLETED &&
                selectedBatch.contentIsOpen &&
                selectedBatch.currentView !== QUEUE_VIEWS.LARGE_IMAGE
            }
        />
    );
};

export default SelectAllAndMessaging;

// prop-types
SelectAllAndMessaging.propTypes = {
    prop: PropTypes.array
};
