import React from "react";
import PropTypes from "prop-types";

// data
import { getRemoveableTextStrings } from "../utils";

// styles
import { TagWrapper, ProcessingSpinnerIcon } from "../styles.js";
import { TagRemoveableStyled, RemoveButton, CloseIcon } from "./styles.js";

export const TagRemoveable = ({
    text,
    onClick,
    className,
    isDisabled = false,
    ariaLabel = "",
    isTruncated = false,
    isLoading = false,
    isDarkMode = false,
    isStrikethrough = false,
    hasWarning = false,
    dataTestId
}) => {
    const textStrings = getRemoveableTextStrings({
        text,
        ariaLabel,
        isLoading,
        isTruncated,
        isDisabled
    });

    // Handle Click
    const handleClick = e => {
        if (isDisabled) {
            return;
        }

        // consumer handler
        onClick(text);
    };

    return (
        <TagWrapper className={className} data-testid={dataTestId}>
            <TagRemoveableStyled
                {...{ isDarkMode, isDisabled, isStrikethrough, hasWarning }}
            >
                <span>{textStrings.truncated}</span>
                <RemoveButton
                    type="button"
                    aria-disabled={isDisabled}
                    disabled={isDisabled}
                    aria-label={textStrings.ariaLabel}
                    title={textStrings.ariaLabel}
                    onClick={handleClick}
                    data-testid={`${dataTestId}-remove-button`}
                >
                    <CloseIcon size={{ width: "12", height: "12" }} />
                </RemoveButton>
                {isLoading && <ProcessingSpinnerIcon />}
            </TagRemoveableStyled>
        </TagWrapper>
    );
};

// prop-types
TagRemoveable.propTypes = {
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string,
    dataTestId: PropTypes.string,
    isStrikethrough: PropTypes.bool,
    hasWarning: PropTypes.bool,
    isDisabled: PropTypes.bool,
    ariaLabel: PropTypes.string,
    isTruncated: PropTypes.bool,
    isLoading: PropTypes.bool,
    isDarkMode: PropTypes.bool
};
