import styled from "styled-components";

import { BlueBorderBottomLink } from "core/Typography";

import Flexbox from "core/Flexbox";
import { convertPxToRem } from "frontend/components/helpers";

export const NameModalCancelButton = styled(BlueBorderBottomLink).attrs({
    as: "button"
})`
    cursor: pointer;
    margin: 0 0 0 ${convertPxToRem({ px: 24 })};
    background: transparent;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    padding-top: 0;
    padding-right: 0;
    padding-left: 0;
`;

export const NameContainer = styled(Flexbox).attrs({
    flexDirection: "row",
    alignItems: "center"
})``;

export const Title = styled.div`
    margin-right: ${convertPxToRem({ px: 3 })};
`;
