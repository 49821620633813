import { useState } from "react";
import gql from "graphql-tag";

import { useApolloLazyQuery } from "frontend/utils/apolloApi/useApolloLazyQuery.js";

// GQL
const GET_TAG_SUGGESTIONS = gql`
    query tagAutocompleteSuggestions($name: String!) {
        tagAutocompleteSuggestions(name: $name) {
            items
            errors
            totalCount
        }
    }
`;

export const useGetSuggestions = () => {
    const [suggestions, setSuggestions] = useState([]);
    const { apolloQuery: getTagSuggestionsQuery, loading } = useApolloLazyQuery(
        {
            actionDesc: "Get Suggestions",
            gql: GET_TAG_SUGGESTIONS
        }
    );

    const getTagSuggestions = async string => {
        const resp = await getTagSuggestionsQuery({
            variables: { name: string }
        });

        const suggestions =
            resp?.ssResp?.data?.tagAutocompleteSuggestions?.items || [];

        const respSuggestions = suggestions.map((item, idx) => {
            return {
                id: idx + 1,
                text: item.toLowerCase()
            };
        });

        setSuggestions(respSuggestions);

        // ManageTags uses this to display error if necessary.
        return resp;
    };

    const clearSuggestions = () => {
        setSuggestions([]);
    };

    return {
        suggestions,
        getTagSuggestions,
        clearSuggestions,
        loading
    };
};
