import styled from "styled-components";

import { convertPxToRem } from "frontend/components/helpers";

export const Container = styled.div``;

export const IconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: ${({ iconsAlignment }) =>
        iconsAlignment ? iconsAlignment : "center"};
    flex-wrap: wrap;
    margin: 0 auto;
    width: 100%;
    margin-top: ${convertPxToRem({ px: -24 })};
    // ^^ inverse of margin-top for Icon
`;

export const Icon = styled.div`
    margin-top: ${convertPxToRem({ px: 24 })};
    margin-right: ${convertPxToRem({ px: 24 })};
    margin-left: ${convertPxToRem({ px: 24 })};

    &:first-of-type {
        margin-left: 0;
    }

    &:last-of-type {
        margin-right: 0;
    }

    svg {
        display: block;
        height: ${convertPxToRem({ px: 40 })};
    }
`;
