import React from "react";
import PropTypes from "prop-types";

import MenuItem from "./MenuItem";

import {
    MenuGroupNameStyled,
    MenuGroupListStyled,
    MenuGroupStyled
} from "./styles";

const MenuGroup = ({ groupId, groupName = "", groupItems = [] }) => (
    <MenuGroupStyled>
        <MenuGroupNameStyled>{groupName}</MenuGroupNameStyled>
        <MenuGroupListStyled>
            {groupItems.map(({ id, text, href }) => (
                <MenuItem key={`${groupId}-${id}`} href={href}>
                    {text}
                </MenuItem>
            ))}
        </MenuGroupListStyled>
    </MenuGroupStyled>
);

MenuGroup.propTypes = {
    groupId: PropTypes.string.isRequired,
    groupName: PropTypes.string.isRequired,
    groupItems: PropTypes.array.isRequired
};

export default MenuGroup;
