import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

// hooks
import { useMyModerator } from "frontend/components/fort/mod/photos/hooks/useMyModerator.js";
import { useMultipleTabsAreOpen } from "frontend/hooks/useMultipleTabsAreOpen.js";
import { useModWebSocket } from "../../hooks/useModWebSocket.js";

// rtk
import { websocketSynchQueueSlice } from "frontend/components/fort/mod/photos/slices/websocketSynchQueueSlice.js";

// mod photo
import { refetchBatchesCheck } from "./refetchBatchesCheck";
import { getModerationNote } from "./getModerationNote";

// component
export const WebsocketProvider = ({ children }) => {
    const dispatch = useDispatch();
    const { myModProfile } = useMyModerator();
    const { multipleTabsAreOpen } = useMultipleTabsAreOpen();
    const { lastJsonMessage } = useModWebSocket();

    // WS Event Listeners: Detecting Batch and Upload changes through websocket messages
    useEffect(() => {
        const { type: eventType, data } = lastJsonMessage;

        if (!eventType) return;

        // Refetch Batches
        // we need to resynch batch pagaination under some circumstances (e.g. another moderator re-assigns a batch)
        if (
            refetchBatchesCheck({
                eventType,
                data,
                myModProfile,
                multipleTabsAreOpen
            })
        ) {
            dispatch(
                websocketSynchQueueSlice.actions.setShouldRefetchBatches({
                    shouldRefetchBatches: true
                })
            );
        }

        // Update Batch and Upload Moderation Notes
        // Note: Moderation Notes are not always in GQL query and mutation responses.
        // So we need to grab them from WS responses to moderator actions
        const modNote = getModerationNote({ eventType, data });
        if (modNote) {
            dispatch(websocketSynchQueueSlice.actions.addModNote({ modNote }));
        }
    }, [lastJsonMessage, multipleTabsAreOpen]);

    return <>{children}</>;
};

export default WebsocketProvider;

// prop-types
WebsocketProvider.propTypes = {
    children: PropTypes.any
};
