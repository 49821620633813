/**
 * inputTextValidation
 * Note: currently this is assumed that Photo and Icons will share the same validation rules
 * If they need to be separated, might be good to move these to their respective folders and pass as props
 * e.g. ./ManagePhotoTags, ./ManageIconTags
 */
import { detectEmoji } from "frontend/components/helpers";
import * as Utils from "./utils.js";

const validationPropHelper = useManageTagsContext => {
    const {
        state: { inputText },
        label,
        tags,
        tagLimit
    } = useManageTagsContext;

    return {
        label,
        inputText: inputText.trim().toLowerCase(),
        tags,
        tagLimit
    };
};

export const validTermSharedMessage =
    "Please enter a valid term before attempting to add.";

export const inputTextValidation = {
    noEmojis: useManageTagsContext => {
        const { label, inputText } = validationPropHelper(useManageTagsContext);
        return {
            isValid: !detectEmoji(inputText),
            message: `${label} not saved because it contains invalid characters.`
        };
    },
    // not empty and not just spaces
    notEmpty: useManageTagsContext => {
        const { inputText } = validationPropHelper(useManageTagsContext);
        // we trim text in validationPropHelper which accounts for just white space added.
        return {
            isValid: inputText.length > 0,
            message: validTermSharedMessage
        };
    },
    noCommasOnly: useManageTagsContext => {
        const { inputText } = validationPropHelper(useManageTagsContext);
        return {
            isValid: !/^,+$/g.test(inputText),
            message: validTermSharedMessage
        };
    },
    tagLimitCheck: useManageTagsContext => {
        const { inputText, tags, tagLimit } = validationPropHelper(
            useManageTagsContext
        );
        const inputTags = Utils.getTagsFromInput(inputText);
        const totalTagCount = tags.length + inputTags.length;
        const isWithinLimit = totalTagCount <= tagLimit;
        const tagsLeftCount = tagLimit - tags.length;
        return {
            isValid: isWithinLimit,
            message: `Adding ${inputTags.length} tags exceeds the ${tagLimit} limit. You an only add ${tagsLeftCount} more tags.`
        };
    },
    maxLength: length => {
        return useManageTagsContext => {
            const { inputText } = validationPropHelper(useManageTagsContext);

            const tags = Utils.getTagsFromInput(inputText);
            const exceedsMax = tags.find(t => t.length > length);

            return {
                isValid: !exceedsMax,
                message: `A tag can not be greater than ${length} characters long.`
            };
        };
    }
};
