import styled, { css } from "styled-components";

// styles
import colors from "core/colors";
import { convertPxToRem } from "frontend/components/helpers";
import { medium as mediumFontWeight } from "core/fontStyles";

// styled-components
const getCircleBackgroundColor = (isGrayscale, isSolid, circleColor) => {
    if (isGrayscale && isSolid) {
        return colors.secondary.dolphin;
    }
    if (isSolid) {
        return circleColor;
    }

    return "transparent";
};

const getCircleBorderColor = (
    isGrayscale,
    isSolid,
    circleColor,
    circleBorderPxWidth
) => {
    if (isGrayscale) {
        return `solid ${convertPxToRem(circleBorderPxWidth)} ${
            colors.secondary.dolphin
        }`;
    }
    if (isSolid) {
        return `none`;
    }
    return `solid ${convertPxToRem(circleBorderPxWidth)} ${circleColor}`;
};

export const CircleCountStyled = styled.div`
    /* convert to circle */
    border-radius: 50%;

    ${({
        circleBorderPxWidth = 2,
        circleColor = colors.secondary.orca,
        pxWidth = 22,
        pxHeight = 22,
        isSolid = false,
        isGrayscale = false
    }) => {
        return css`
            width: ${convertPxToRem(pxWidth)};
            height: ${convertPxToRem(pxHeight)};
            border: ${getCircleBorderColor(
                isGrayscale,
                isSolid,
                circleColor,
                circleBorderPxWidth
            )};
            background-color: ${getCircleBackgroundColor(
                isGrayscale,
                isSolid,
                circleColor
            )};
        `;
    }}

    display: flex;
    justify-content: center;
    align-items: center;
`;

const getNumberColor = (isGrayscale, isSolid, circleColor) => {
    if (isSolid) {
        return colors.primary.snow;
    }
    if (isGrayscale) {
        return colors.secondary.dolphin;
    }
    return circleColor;
};

export const NumberStyled = styled.div`
    ${mediumFontWeight};
    text-align: center;

    ${({
        circleColor = colors.secondary.orca,
        pxHeight = 22,
        numberPxFontSize = 11,
        isSolid = false,
        isGrayscale = false
    }) => {
        return css`
            font-size: ${convertPxToRem(numberPxFontSize)};
            line-height: ${convertPxToRem(pxHeight)};
            color: ${getNumberColor(isGrayscale, isSolid, circleColor)};
        `;
    }}
`;
