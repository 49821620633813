import React from "react";
import { resetLocalStorage } from "core/localStorageKeys";

import Button from "core/Button";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, isProcessing: false };
    }

    static getDerivedStateFromError(error) {
        console.warn("ErrorBoundary", error);
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, info) {
        // You can also log the error to an error reporting service
        // logErrorToMyService(error, info);
        console.warn("ErrorBoundary", error, info);
    }

    render() {
        const handleClick = () => {
            this.setState({
                isProcessing: true
            });
            window.location.reload();
        };

        if (this.state.hasError) {
            resetLocalStorage(); // in case clearing local storage resolves the issue
            return (
                <div style={{ textAlign: "center" }}>
                    <h1 style={{ fontSize: "1rem", padding: "2rem 1rem 1rem" }}>
                        Something went wrong. Please click the button below to
                        try again.
                    </h1>
                    <Button
                        styleType="cta"
                        isProcessing={this.state.isProcessing}
                        onClick={handleClick}
                    >
                        Try Again
                    </Button>
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
