import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { AnimatedProcessingIcon } from "./styles";

let ProcessingSpinner = ({ className, iconColor, width, height }) => (
    <AnimatedProcessingIcon
        className={className}
        iconColor={iconColor}
        width={width}
        height={height}
        aria-label="Processing"
    />
);

ProcessingSpinner.propTypes = {
    className: PropTypes.string,
    iconColor: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string
};

ProcessingSpinner = styled(ProcessingSpinner)``;

export default ProcessingSpinner;
