import React from "react";
import PropTypes from "prop-types";

import SearchAndBrowsePageHeadline from "core/SearchAndBrowsePageHeadline";
import SearchSegmentedControlButtons from "icons/SearchPage/SearchSegmentedControlButtons";

const SearchHeader = ({ query, searchType, showSkeleton }) => {
    return (
        <>
            <SearchAndBrowsePageHeadline showSkeleton={showSkeleton}>
                {`Results for "${query}"`}
            </SearchAndBrowsePageHeadline>
            <SearchSegmentedControlButtons
                query={query}
                activeSearchType={searchType}
                showSkeleton={showSkeleton}
            />
        </>
    );
};

SearchHeader.propTypes = {
    query: PropTypes.string.isRequired,
    searchType: PropTypes.string.isRequired
};

export default SearchHeader;
