import React, { useEffect } from "react";
import PropTypes from "prop-types";

// hooks
import { useKeyboardListener } from "../../../../../static/assets/js/hooks/useKeyboardListener.js";

// context
import { useModal2 } from "../index.js";

// styles
import { LightStyled, ContentStyled } from "./styles.js";

// components
import Header from "./Header.js";

export const Light = props => {
    const {
        children,
        title,
        isFullScreen = false,
        dimensions = { w: "500px", h: "500px" },
        headerHeight = "77px"
    } = props;

    const { hideModal } = useModal2();
    const { keyPressed } = useKeyboardListener();

    // fx
    useEffect(() => {
        if (["Escape"].includes(keyPressed)) {
            hideModal();
        }
    }, [keyPressed]);

    return (
        <LightStyled
            isFullScreen={isFullScreen}
            dimensions={dimensions}
            onClick={e => {
                e.stopPropagation();
            }}
        >
            <Header headerHeight={headerHeight}>{title}</Header>
            <ContentStyled>{children}</ContentStyled>
        </LightStyled>
    );
};

export default Light;

Light.propTypes = {
    children: PropTypes.any,
    title: PropTypes.string,
    headerHeight: PropTypes.string,
    isFullScreen: PropTypes.bool,
    dimensions: PropTypes.object,
    zIndex: PropTypes.number
};
