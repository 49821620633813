import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import {
    linkTargets,
    reusableComponentSizes,
    buttonStyleTypes,
    positionalAlignments,
    justifyContentPosition
} from "core/props";

import ProcessingSpinner from "core/ProcessingSpinner";

import { StyledButton } from "./styles";

const ButtonElement = ({
    className,
    dataTestid,
    size = "lg",
    styleType = "solid",
    href,
    target = "_self",
    onClick,
    justifyContent = "center",
    iconPosition = "left",
    processingIconPosition = "right",
    width,
    darkMode = false,
    isPill = false,
    isDisabled = false,
    isProcessing = false,
    isActive = false,
    isToggled = false,
    isSubmit = false,
    isError = false,
    children,
    ...buttonProps
}) => {
    // elementProps decides what link-related props to give to the element based on whether it is a button or an anchor element:
    const elementProps = href
        ? {
              href,
              onClick,
              target
          }
        : {
              onClick: event => {
                  if (isDisabled) return event.preventDefault();
                  if (onClick) return onClick(event);
              },
              ...buttonProps
          };

    // processingContents returns element content when it's processing:
    const processingContents = (
        <>
            {processingIconPosition === "left" && <ProcessingSpinner />}
            Processing
            {processingIconPosition !== "left" && <ProcessingSpinner />}
        </>
    );

    return (
        <StyledButton
            as={href ? "a" : "button"}
            data-testid={dataTestid}
            className={className}
            aria-disabled={isDisabled || isProcessing}
            size={size}
            styleType={styleType}
            justifyContent={justifyContent}
            iconPosition={iconPosition}
            width={width}
            darkMode={darkMode}
            isDisabled={isDisabled || isProcessing}
            isProcessing={isProcessing}
            processingIconPosition={processingIconPosition}
            isPill={isPill}
            isActive={isActive}
            isToggled={isToggled}
            isError={isError}
            type={!href && isSubmit ? "submit" : null}
            {...elementProps}
        >
            {isProcessing ? processingContents : children}
        </StyledButton>
    );
};

const Button = styled(ButtonElement)``;

Button.propTypes = {
    href: PropTypes.string,
    target: PropTypes.oneOf(linkTargets),
    onClick: PropTypes.func,
    size: PropTypes.oneOf(reusableComponentSizes),
    styleType: PropTypes.oneOf(buttonStyleTypes),
    width: PropTypes.string,
    darkMode: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isProcessing: PropTypes.bool,
    ref: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.elementType })
    ]),
    iconPosition: PropTypes.oneOf(positionalAlignments),
    processingIconPosition: PropTypes.oneOf(positionalAlignments),
    justifyContent: PropTypes.oneOf(justifyContentPosition),
    isPill: PropTypes.bool,
    isActive: PropTypes.bool,
    isToggled: PropTypes.bool,
    isSubmit: PropTypes.bool,
    isError: PropTypes.bool,
    dataTestid: PropTypes.string
};

export default Button;
