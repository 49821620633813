import gql from "graphql-tag";

const ItemsPartsFragment = gql`
    fragment ItemsParts on PhotoType {
        id
        slugWithId
        title
        creator {
            id
            name
            username
            country
            description
        }
        assets {
            thumbnailSmall
            thumbnailMedium
        }
        ratio
    }
`;

export const GET_PHOTOS_BY_TAG = gql`
    query browsePhotosByTag($tag: String!, $limit: Int, $offset: Int) {
        browsePhotosByTag(tag: $tag, limit: $limit, offset: $offset) {
            items {
                ...ItemsParts
            }
            totalCount
        }
    }
    ${ItemsPartsFragment}
`;

export const GET_ICONS_BY_TERM_DATA = gql`
    query browseIconsByTerm($term: String!, $limit: Int, $offset: Int) {
        browseIconsByTerm(term: $term, limit: $limit, offset: $offset) {
            items {
                id
                license
                title
                thumbnails {
                    thumbnail200
                }
                url
            }
            totalCount
        }
    }
`;

export const GET_TOTAL_COUNTS_FOR_BROWSE_BY_TAG_RESULTS = gql`
    query totalCountsForBrowseByTagResults($term: String!) {
        icons: browseIconsByTerm(term: $term) {
            totalCount
        }
        photos: browsePhotosByTag(tag: $term) {
            totalCount
        }
    }
`;
