import React from "react";
import PropTypes from "prop-types";

// styles
import { ToggleButtonStyled } from "./styles.js";

const ToggleButton = ({
    children,
    moderationIconKey,
    isActivated,
    isDisabled = false,
    onToggle,
    title,
    testId = ""
}) => {
    const onClick = () => {
        onToggle({
            moderationIconKey,
            isActivated: !isActivated
        });
    };

    return (
        <ToggleButtonStyled
            type="button"
            onClick={onClick}
            disabled={isDisabled}
            data-testid={testId}
            title={title}
        >
            {children}
        </ToggleButtonStyled>
    );
};

export default ToggleButton;

// prop-types
ToggleButton.propTypes = {
    children: PropTypes.any,
    moderationIconKey: PropTypes.string.isRequired,
    isActivated: PropTypes.bool.isRequired,
    onToggle: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired
};
