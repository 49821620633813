import React from "react";
import PropTypes from "prop-types";

// hooks
import { useUpdateOnly } from "./useUpdateOnly";

// styles
import { ButtonStyled } from "../styles";

// component
export const UpdateOnlyButton = ({ buttonText = "Update Only", variant }) => {
    const { onUpdateOnly, isEditable } = useUpdateOnly();

    return (
        <ButtonStyled
            onClick={onUpdateOnly}
            isDisabled={!isEditable}
            variant={variant}
        >
            {buttonText}
        </ButtonStyled>
    );
};

export default UpdateOnlyButton;

// prop-types
UpdateOnlyButton.propTypes = {
    buttonText: PropTypes.string,
    variant: PropTypes.string.isRequired
};
