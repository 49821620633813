import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";

import BodyText from "core/BodyText";
import ButtonOptions from "core/ButtonOptions";
import Tooltip from "core/Tooltip";

import InfoIcon from "core/ReusableIcons/Info";

import colors from "core/colors";
import {
    TooltipButton,
    LicenseFlexbox,
    LicenseTypeBodyTextHeading,
    LicenseTextContainer,
    LicenseTooltipContainer,
    LicenseButtonContainer,
    ButtonOptionsStyled
} from "./styles";

import { convertPxToRem } from "frontend/components/helpers";
import { normalizeLicense } from "./helpers";

import { SET_LICENSE_ON_PHOTO_UPLOADS } from "./mutations";

import { PUBLIC_DOMAIN_BE_VALUE, CREATIVE_COMMONS_BE_VALUE } from "./constants";

import { useEditPhotoUpload } from "./EditPhotoUploadContext";

const LicenseSection = () => {
    const [license, setLicense] = useState("");

    const { uploads = [], uploadIds } = useEditPhotoUpload();

    useEffect(() => {
        const licenseValues = uploads.map(upload => upload.license);
        setLicense(normalizeLicense(licenseValues));
    }, [uploads]);

    const [setLicenseOnPhotoUploads] = useMutation(
        SET_LICENSE_ON_PHOTO_UPLOADS
    );

    const licenseTooltipText = `"Creative Commons" includes commercial sale and free low-res licenses. "Public Domain" grants broad, free use.`;

    const handleLicenseClick = ({ licenseValue }) => {
        setLicenseOnPhotoUploads({
            variables: {
                value: licenseValue,
                photoUploadIds: uploadIds
            }
        });
    };

    return (
        <>
            <LicenseFlexbox alignItems="center">
                <LicenseTextContainer>
                    <LicenseTypeBodyTextHeading type="div" size="md" bold>
                        License:
                    </LicenseTypeBodyTextHeading>

                    <BodyText type="div" size="md">
                        {license}
                    </BodyText>
                </LicenseTextContainer>

                <LicenseTooltipContainer>
                    <Tooltip
                        message={
                            <>
                                {licenseTooltipText}{" "}
                                <a
                                    href="/legal/terms-of-use/#photo-licenses"
                                    target="_blank"
                                >
                                    Learn more
                                </a>
                            </>
                        }
                    >
                        <TooltipButton aria-label={licenseTooltipText}>
                            <InfoIcon
                                size="sm"
                                fillColor={colors.secondary.oolong}
                            />
                        </TooltipButton>
                    </Tooltip>
                </LicenseTooltipContainer>

                <LicenseButtonContainer>
                    <ButtonOptionsStyled>
                        <ButtonOptions
                            defaultButtonSelected={license}
                            triggerText="Change license"
                            heading="Change license"
                            width={convertPxToRem({ px: 332 })}
                            buttonData={[
                                {
                                    text: "Creative Commons",
                                    handleButtonClick: () =>
                                        handleLicenseClick({
                                            licenseValue: CREATIVE_COMMONS_BE_VALUE
                                        })
                                },
                                {
                                    text: "Public Domain",
                                    handleButtonClick: () =>
                                        handleLicenseClick({
                                            licenseValue: PUBLIC_DOMAIN_BE_VALUE
                                        })
                                }
                            ]}
                        />
                    </ButtonOptionsStyled>
                </LicenseButtonContainer>
            </LicenseFlexbox>
        </>
    );
};

export default LicenseSection;
