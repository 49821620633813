import { useApolloMutation } from "frontend/utils/apolloApi/useApolloMutation.js";
import { MESSAGING } from "frontend/components/fort/mod/photos/constants";

// GQL
import { UPDATE_AND_APPROVE_PHOTO_UPLOAD_TITLE } from "frontend/components/fort/mod/photos/gql/mutations";

const errorHandler = normalizedResp => {
    const { message, errors } = normalizedResp;
    let newMessage = message;

    if (errors.includes("Submitted value contains disallowed terms")) {
        newMessage = MESSAGING.errors.containsDisallowedTerms("title");
    }

    return {
        ...normalizedResp,
        message: newMessage
    };
};

export const useUpdateAndApprovePhotoUploadTitle = () => {
    const { apolloMutate, loading } = useApolloMutation({
        actionDesc: "Update and Approve Photo Upload Title",
        gql: UPDATE_AND_APPROVE_PHOTO_UPLOAD_TITLE,
        errorHandler
    });

    const upadateAndApprovePhotoUploadTitle = async variables => {
        return await apolloMutate({ variables });
    };

    return {
        upadateAndApprovePhotoUploadTitle,
        upadateAndApprovePhotoUploadTitleLoading: loading
    };
};
