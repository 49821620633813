import styled from "styled-components";

// components and styles:
import { CustomFocusStyle, BorderBottomLinkStyle } from "core/Typography";
import colors from "core/colors";
import BodyText from "core/BodyText";

// helpers:
import { convertPxToRem } from "frontend/components/helpers";

// constants - note they are also imported into core/Popover/styles.js:
export const REUSABLE_BOX_STYLES = {
    BOX_MAX_WIDTH: convertPxToRem({ px: 263 }),
    BOX_TEXT_COLOR: ({ darkMode }) =>
        darkMode ? colors.secondary.orca : colors.primary.diamond,
    BOX_SHADOW_DARK_MODE: `0px 2px 4px rgba(0, 0, 0, 0.3)`,
    BOX_SHADOW_LIGHT_MODE: `1px 1px 4px rgba(0, 0, 0, 0.13)`,
    BOX_BORDER_RADIUS: convertPxToRem({ px: 2 }),
    BOX_BACKGROUND: ({ darkMode }) =>
        darkMode ? colors.primary.snow : colors.secondary.basalt,
    BOX_PADDING: ({ isSmallTooltip }) =>
        convertPxToRem({ px: isSmallTooltip ? 6 : 16 })
};

// the styled components:
export const TooltipContainer = styled.div`
    display: inline-block;
    line-height: 0; // removes extra 4px preventing center alignment with flexbox
    cursor: pointer;
`;

export const StyledChildrenContainer = styled.div`
    ${CustomFocusStyle}
`;

export const StyledBodyText = styled(BodyText)`
    white-space: normal;
    color: ${({ darkMode }) =>
        REUSABLE_BOX_STYLES.BOX_TEXT_COLOR({ darkMode })};    
    
    a {
        ${BorderBottomLinkStyle}
        color: ${({ darkMode }) =>
            darkMode
                ? colors.accent.lightMode.persian
                : colors.accent.darkMode.suenosBuenos};
        border-color: ${({ darkMode }) =>
            darkMode
                ? colors.accent.lightMode.persian
                : colors.accent.darkMode.suenosBuenos};
        &:visited {
            color: ${({ darkMode }) =>
                darkMode
                    ? colors.accent.lightMode.persian
                    : colors.accent.darkMode.suenosBuenos};
        }
        padding-bottom: ${convertPxToRem({ px: 1 })};

        &:hover {
            color: ${({ darkMode }) =>
                darkMode
                    ? colors.accent.lightMode.pansy
                    : colors.primary.diamond};
            border-color: ${({ darkMode }) =>
                darkMode
                    ? colors.accent.lightMode.pansy
                    : colors.primary.diamond};
        }
    }
`;

export const tooltipThemeStyles = ({ darkMode, isSmallTooltip }) => {
    return {
        components: {
            MuiTooltip: {
                styleOverrides: {
                    arrow: {
                        fontSize: convertPxToRem({
                            px: isSmallTooltip ? 13 : 24
                        }), // https://stackoverflow.com/a/54606987 - size of arrow is controlled via font size
                        color: REUSABLE_BOX_STYLES.BOX_BACKGROUND({ darkMode }),
                        "&:before": {
                            boxShadow: darkMode
                                ? REUSABLE_BOX_STYLES.BOX_SHADOW_DARK_MODE
                                : REUSABLE_BOX_STYLES.BOX_SHADOW_LIGHT_MODE
                        }
                    },
                    tooltip: {
                        backgroundColor: REUSABLE_BOX_STYLES.BOX_BACKGROUND({
                            darkMode
                        }),
                        borderRadius: REUSABLE_BOX_STYLES.BOX_BORDER_RADIUS,
                        padding: REUSABLE_BOX_STYLES.BOX_PADDING({
                            isSmallTooltip
                        }),
                        maxWidth: !isSmallTooltip
                            ? REUSABLE_BOX_STYLES.BOX_MAX_WIDTH
                            : "auto",
                        boxShadow: darkMode
                            ? REUSABLE_BOX_STYLES.BOX_SHADOW_DARK_MODE
                            : REUSABLE_BOX_STYLES.BOX_SHADOW_LIGHT_MODE
                    }
                }
            }
        }
    };
};
