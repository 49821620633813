import React from "react";
import PropTypes from "prop-types";

import Grid from "core/Grid";

import {
    IconsPricingTableHeaderBasic,
    IconsPricingTableHeaderProDownloads,
    IconsPricingTableHeaderIndividualSub,
    IconsPricingTableHeaderTeamSub
} from "./iconsPricingTableHeaders";

import { Table, TableHead, TableBody, TableRow } from "core/Table";
import {
    FeatureTableMobileBullet,
    FeatureTableMobileList
} from "core/FeatureTableMobile";

import {
    IconsPricingFeatureTableMobileColumnHeading,
    IconsPricingTableMobileHighlight
} from "./styles";

import {
    TEAM_SUBSCRIPTION,
    INDIVIDUAL_SUBSCRIPTION,
    iconsPricingBasicTable,
    iconsPricingProDownloadTable,
    iconsPricingIndividualSubTable,
    iconsPricingTeamSubTable,
    iconsPricingTableHeadingIds as headingIds
} from "./data";

const IconsPricingTableMobile = ({
    planType,
    bestValueHighlight,
    eduPricingLayout,
    individualColumnClick,
    teamColumnClick
}) => {
    const basicIconDownloadColumn = (
        <>
            <TableHead>
                <TableRow>
                    <IconsPricingFeatureTableMobileColumnHeading
                        id={headingIds.basicIconDownload}
                        scope="col"
                    >
                        <IconsPricingTableHeaderBasic />
                    </IconsPricingFeatureTableMobileColumnHeading>
                </TableRow>
            </TableHead>
            <TableBody>
                <FeatureTableMobileList height="18.125rem">
                    {iconsPricingBasicTable.map(bullet => (
                        <FeatureTableMobileBullet
                            key={bullet[0]}
                            isFeature={bullet[1]}
                            headers={headingIds.basicIconDownload}
                        >
                            {bullet[0]}
                        </FeatureTableMobileBullet>
                    ))}
                </FeatureTableMobileList>
            </TableBody>
        </>
    );

    const proIconDownloadColumn = (
        <>
            <TableHead>
                <TableRow>
                    <IconsPricingFeatureTableMobileColumnHeading
                        id={headingIds.proIconDownload}
                        scope="col"
                    >
                        <IconsPricingTableHeaderProDownloads />
                    </IconsPricingFeatureTableMobileColumnHeading>
                </TableRow>
            </TableHead>
            <TableBody>
                <FeatureTableMobileList height="18.125rem">
                    {iconsPricingProDownloadTable.map(bullet => (
                        <FeatureTableMobileBullet
                            key={bullet[0]}
                            isFeature={bullet[1]}
                            headers={headingIds.proIconDownload}
                        >
                            {bullet[0]}
                        </FeatureTableMobileBullet>
                    ))}
                </FeatureTableMobileList>
            </TableBody>
        </>
    );

    const individualSubColumn = (
        <>
            <TableHead>
                <TableRow>
                    <IconsPricingFeatureTableMobileColumnHeading
                        id={headingIds.nounProIndividual}
                        scope="col"
                    >
                        <IconsPricingTableHeaderIndividualSub
                            planType={planType}
                            bestValueHighlight={
                                bestValueHighlight === INDIVIDUAL_SUBSCRIPTION
                            }
                            eduPricingLayout={eduPricingLayout}
                            individualColumnClick={individualColumnClick}
                        />
                    </IconsPricingFeatureTableMobileColumnHeading>
                </TableRow>
            </TableHead>
            <TableBody>
                <FeatureTableMobileList height="18.125rem">
                    {iconsPricingIndividualSubTable.map(bullet => (
                        <FeatureTableMobileBullet
                            key={bullet[0]}
                            isFeature={bullet[1]}
                            headers={headingIds.nounProIndividual}
                        >
                            {bullet[0]}
                        </FeatureTableMobileBullet>
                    ))}
                </FeatureTableMobileList>
            </TableBody>
        </>
    );

    const teamSubColumn = (
        <>
            <TableHead>
                <TableRow>
                    <IconsPricingFeatureTableMobileColumnHeading
                        id={headingIds.nounProTeam}
                        scope="col"
                    >
                        <IconsPricingTableHeaderTeamSub
                            planType={planType}
                            bestValueHighlight={
                                bestValueHighlight === TEAM_SUBSCRIPTION
                            }
                            teamColumnClick={teamColumnClick}
                            eduPricingLayout={eduPricingLayout}
                        />
                    </IconsPricingFeatureTableMobileColumnHeading>
                </TableRow>
            </TableHead>
            <TableBody>
                <FeatureTableMobileList height="18.125rem">
                    {iconsPricingTeamSubTable.map(bullet => (
                        <FeatureTableMobileBullet
                            key={bullet[0]}
                            isFeature={bullet[1]}
                            headers={headingIds.nounProTeam}
                        >
                            {bullet[0]}
                        </FeatureTableMobileBullet>
                    ))}
                </FeatureTableMobileList>
            </TableBody>
        </>
    );

    const highlightedIndividualSubColumn = (
        <IconsPricingTableMobileHighlight>
            {individualSubColumn}
        </IconsPricingTableMobileHighlight>
    );

    const highlightedTeamSubColumn = (
        <IconsPricingTableMobileHighlight>
            {teamSubColumn}
        </IconsPricingTableMobileHighlight>
    );

    return (
        <Grid mobileSpacing={16} tabletSpacing={16} desktopSpacing={16}>
            <Grid item sm={12} md={6} lg={6}>
                <Table>{basicIconDownloadColumn}</Table>
            </Grid>
            <Grid item sm={12} md={6} lg={6}>
                <Table>{proIconDownloadColumn}</Table>
            </Grid>
            <Grid item sm={12} md={6} lg={6}>
                {planType === "yearly" &&
                bestValueHighlight === INDIVIDUAL_SUBSCRIPTION ? (
                    highlightedIndividualSubColumn
                ) : (
                    <Table>{individualSubColumn}</Table>
                )}
            </Grid>
            <Grid item sm={12} md={6} lg={6}>
                {planType === "yearly" &&
                bestValueHighlight === TEAM_SUBSCRIPTION ? (
                    highlightedTeamSubColumn
                ) : (
                    <Table>{teamSubColumn}</Table>
                )}
            </Grid>
        </Grid>
    );
};

IconsPricingTableMobile.propTypes = {
    planType: PropTypes.oneOf(["yearly", "monthly"]).isRequired,
    bestValueHighlight: PropTypes.string,
    eduPricingLayout: PropTypes.bool // used for special styling when on EDU LP
};

export default IconsPricingTableMobile;
