import styled from "styled-components";

import colors from "core/colors";
import { focusOutline } from "core/links";
import { convertPxToRem } from "frontend/components/helpers";

const RadioInput = styled.input.attrs({ type: "radio" })`
    appearance: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${colors.primary.snow};
    border: 1px solid ${colors.secondary.manatee};
    border-radius: 50%;
    width: ${convertPxToRem(18)};
    height: ${convertPxToRem(18)};
    outline: none;
    margin: ${convertPxToRem({ px: 5 })} 0 0 0;

    &:checked::before {
        content: "";
        background-color: ${colors.accent.lightMode.pansy};
        height: 0.5625rem;
        width: 0.5625rem;
        border-radius: 50%;
    }

    &:focus {
        ${focusOutline}
    }
`;

export default RadioInput;
