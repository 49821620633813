import React from "react";
import PropTypes from "prop-types";

// styles
import colors from "core/colors";
import { OffsetIconStyled } from "./styles";

// Components
import ToggleButton from "./ToggleButton";
import ArrowDoubleExpand from "core/ReusableIcons/ArrowDoubleExpand";

export const Expand = ({
    isDisabled = false,
    isActivated = false,
    onToggle,
    moderationIconKey
}) => {
    const fillColor = isDisabled
        ? colors.secondary.dolphin
        : colors.accent.lightMode.persian;

    return (
        <ToggleButton
            onToggle={onToggle}
            isActivated={isActivated}
            isDisabled={isDisabled}
            moderationIconKey={moderationIconKey}
            title="View Larger"
        >
            <OffsetIconStyled verticalOffset="2px">
                <ArrowDoubleExpand
                    size={{ width: 22, height: 22 }}
                    fillColor={fillColor}
                />
            </OffsetIconStyled>
        </ToggleButton>
    );
};

// prop-types
Expand.propTypes = {
    moderationIconKey: PropTypes.string.isRequired,
    onToggle: PropTypes.func.isRequired,
    isActivated: PropTypes.bool,
    isDisabled: PropTypes.bool
};
