import React, { createContext, useState } from "react";
import { useLazyQuery } from "@apollo/client";

import { LIST_FILE_CHECK_FAILED_PHOTO_UPLOADS } from "frontend/hooks/usePhotoUpload/queries";
import { LIST_PHOTO_UPLOADS_IN_DROPZONE } from "../queries";

const AllPhotoUploadsContext = createContext();
export default AllPhotoUploadsContext;

export const AllPhotoUploadsProvider = ({ children }) => {
    const [
        fetchUploadsInDropzone,
        {
            data: uploadsData,
            called: uploadsInDropzoneQueryCalled,
            loading: uploadsInDropzoneLoading
        }
    ] = useLazyQuery(LIST_PHOTO_UPLOADS_IN_DROPZONE, {
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-only"
    });

    const [
        fetchFileCheckFailedUploads,
        {
            data: fileCheckFailedUploadsData,
            called: fileCheckFailedUploadsQueryCalled,
            loading: fileCheckFailedUploadsLoading
        }
    ] = useLazyQuery(LIST_FILE_CHECK_FAILED_PHOTO_UPLOADS, {
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-only"
    });

    const contextValues = {
        uploadsData,
        fetchUploadsInDropzone,
        uploadsInDropzoneQueryCalled,
        uploadsInDropzoneLoading,
        fetchFileCheckFailedUploads,
        fileCheckFailedUploadsData,
        fileCheckFailedUploadsQueryCalled,
        fileCheckFailedUploadsLoading
    };

    return (
        <AllPhotoUploadsContext.Provider value={contextValues}>
            {children}
        </AllPhotoUploadsContext.Provider>
    );
};
