import { homePath } from "data/links";

export const mainHeading = "Icon Pro for Education Application";
export const subHeading =
    "The Icon Pro for Education plan discount is reserved exclusively for educators, students, and edu staff.";

export const formTitle = "Your Education Qualifications";

export const rolePlaceholderLabel = "Your role (such as student, educator)";

export const schoolPlaceholderLabel = "School name";

export const iconUsageDescription = "How do you use icons?";

export const emailDescription =
    "Please provide your email address so we can contact you once you're approved. If you have an .edu, .k12, .ac.uk (or equivalent) email, including it here will speed up verification.";

export const emailPlaceholderLabel = "Email address";

export const notificationLabel =
    "We usually respond by email in 1-3 business days.";

export const confirmationHeading = "Your application has been sent";

export const confirmationBtn = [
    {
        text: "Get Started",
        url: homePath.url
    }
];
