import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { gql, useLazyQuery } from "@apollo/client";

import colors from "core/colors";

// components
import Grid2 from "core/Grid2";
import { GridItemContentDisplay as CoreGridItemContentDisplay } from "frontend/components/core/Grid2/GridItemContentTemplates/Display/index.js";
import RefreshingImage from "frontend/components/core/RefreshingImage";
import { useSubmissionGridItems } from "./submissionGridItems";
import PhotoIcon from "core/ReusableIcons/Photo";
import { REFRESH_THUMBNAIL_URLS } from "./queries";

// if uploads have these values, the thumbnails should be hidden because they
// may contain harmful imagery
const HIDDEN_STATUS_VALUES = [
    "DENIED_AUTO",
    "AUTO_DENIED_BATCH_HAS_BANNED_IMAGERY"
];

const ImageContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        object-fit: scale-down;
        max-height: 100%;
    }
`;

export const PhotoIconStyled = styled(PhotoIcon)`
    /* center absolutely positioned asset */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    path {
        fill: ${colors.primary.scheifer};
    }
`;

const shouldHideThumbnail = d => {
    // equivalent to status = DENIED_AUTO, but checking in case status not fetched
    if (d.autoDenied) {
        return true;
    }
    if (HIDDEN_STATUS_VALUES.includes(d.status)) {
        return true;
    }
    return false;
};

const SubmissionImageGrid = ({ submissions }) => {
    const { numberOfColumns, gridSpacingSize } = useSubmissionGridItems();
    const itemCount = submissions.length;
    const [shouldRefreshThumbnails, setShouldRefreshThumbnails] = useState(
        false
    );

    const [refreshThumbnails, { loading }] = useLazyQuery(
        REFRESH_THUMBNAIL_URLS,
        {
            fetchPolicy: "network-only"
        }
    );

    // when we see that the thumbnails need to be refreshed, make one query to refresh entire group
    useEffect(() => {
        const refresh = async () => {
            await refreshThumbnails({
                variables: {
                    photoUploadIds: submissions.map(d => d.id)
                }
            });
            setShouldRefreshThumbnails(false);
        };
        if (shouldRefreshThumbnails && !loading) {
            refresh();
        }
    }, [shouldRefreshThumbnails, loading, refreshThumbnails]);

    return (
        <Grid2
            numberOfColumns={numberOfColumns}
            gridSpacingSize={gridSpacingSize}
            gridItemContent={submissions.map((d, index) => {
                return (
                    <CoreGridItemContentDisplay
                        key={d.originalFilename}
                        id={index}
                        name={d.originalFilename}
                        gridIndex={index}
                        gridItemCount={itemCount}
                    >
                        <ImageContainer>
                            {shouldHideThumbnail(d) || !d?.thumbnail ? (
                                <PhotoIconStyled
                                    size={{ width: 100, height: 100 }}
                                />
                            ) : (
                                <RefreshingImage
                                    src={d.thumbnail}
                                    alt={
                                        d.title ||
                                        `File name ${d.originalFilename}`
                                    }
                                    imgTestId={`"grid-item-image"-${index}`}
                                    fetchNewUrl={() =>
                                        setShouldRefreshThumbnails(true)
                                    }
                                />
                            )}
                        </ImageContainer>
                    </CoreGridItemContentDisplay>
                );
            })}
        />
    );
};

SubmissionImageGrid.propTypes = {
    submissions: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default SubmissionImageGrid;
