import React from "react";
import { useSelector } from "react-redux";

// context
import { useBatches } from "frontend/components/fort/mod/photos/components/PhotoModQueuePageContent/QueueProviders/BatchesProvider/index.js";
import {
    BATCH_TYPES,
    QUEUE_CONFIGS
} from "frontend/components/fort/mod/photos/constants";

// components
import CheckboxGroup from "core/CheckboxGroup";

// component
export const FilterByBatchType = () => {
    const { searchParams, setUrlSearchParams, isCallingApi } = useBatches();

    const { name: currentQueue } = useSelector(state => state.currentQueue);
    const { filterCheckboxes } = QUEUE_CONFIGS[currentQueue];

    const onFilterToggle = (isCheckedInverse, e) => {
        const filterKey = e.target.value;
        setUrlSearchParams([
            {
                key: [filterKey],
                value: isCheckedInverse
            }
        ]);
    };

    let checkboxes = [];

    filterCheckboxes.forEach(fc => {
        const batchTypeObj = BATCH_TYPES.find(bt => bt.id === fc);
        const { options } = batchTypeObj;
        options.forEach(o => {
            const { key, label } = o;
            checkboxes.push({
                id: label,
                label: label,
                inputValue: key,
                isChecked: searchParams?.[key] === "true",
                isDisabled: isCallingApi
            });
        });
    });

    return (
        <div id="filter-by-batch-type" data-testid="filter-by-batch-type">
            <CheckboxGroup
                legend="Filter by Batch Type"
                onClick={onFilterToggle}
                checkboxes={checkboxes}
            />
        </div>
    );
};

export default FilterByBatchType;
