import gql from "graphql-tag";

export const GET_CURRENT_PHOTO_BATCH = gql`
    mutation GetCurrentPhotoBatch {
        getPhotoBatchForUploading {
            ok
            error
            batch {
                id
                photoShoot {
                    id
                    title
                    isForSinglePhoto
                    __typename
                }
                __typename
            }
            __typename
        }
    }
`;

export const REGISTER_PHOTO_UPLOADS = gql`
    mutation photoUpload(
        $filenames: [String!]
        $photoBatchId: ID!
        $photoShootId: ID
    ) {
        photoUpload(
            filenames: $filenames
            photoBatchId: $photoBatchId
            photoShootId: $photoShootId
        ) {
            errors {
                filename
                reason
            }
            uploads {
                id
                url
                filename
            }
        }
    }
`;

export const REPORT_AWS_PHOTO_UPLOAD_RESULT = gql`
    mutation photoUploadFinishedNotification(
        $id: ID!
        $result: PhotoUploadResult!
    ) {
        photoUploadFinishedNotification(photoUploadId: $id, result: $result) {
            ok
            error
        }
    }
`;

export const CANCEL_PHOTO_UPLOADS = gql`
    mutation cancelPhotoUploads($photoUploadIds: [ID!]!) {
        cancelPhotoUploads(photoUploadIds: $photoUploadIds) {
            ok
            photoUploads {
                ok
                errors
                photoUploadId
                photoUpload {
                    id
                    photoUrl
                    photoTitle
                    errors
                    createdAt
                    updatedAt
                    status
                }
            }
        }
    }
`;

export const REFRESH_PHOTO_UPLOAD_URLS = gql`
    mutation getNewUploadUrlsForPhotoUploads($photoUploadIds: [ID!]!) {
        getNewUploadUrlsForPhotoUploads(photoUploadIds: $photoUploadIds) {
            id
            filename
            url
        }
    }
`;
