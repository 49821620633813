import styled from "styled-components";

import Heading from "core/Heading";

import { regular as regularFontWeight } from "core/fontStyles";
import breakpoints from "core/breakpoints";
import colors from "core/colors";
import { BorderBottomLinkStyle } from "core/Typography";

export const BulletIcon = styled.div`
    padding-bottom: 1.5rem; /* 24px */
`;

export const BulletGridHeading = styled(Heading)`
    padding: 0;
    margin: 0;
`;

export const Paragraph = styled.p`
    padding: 0;
    padding-top: 1.5rem; /* 24px */
    margin: 0;
    ${regularFontWeight};
    color: ${colors.secondary.orca};
    font-size: 1rem; /* 16px */
    line-height: 1.625rem; /* 26px */

    /* Desktop - only: */
    @media (min-width: ${breakpoints.desktop.rem}) {
        max-width: 308px;
    }

    a {
        ${BorderBottomLinkStyle}
    }
`;
