import React from "react";
import styled, { css } from "styled-components";

// context
import { useManageTags } from "core/ManageTags/context/useManageTags.js";

// hooks
import { useOnClickOutside } from "js/hooks/useOnClickOutside.js";

// styles
import { convertPxToRem } from "frontend/components/helpers";

const FormStyled = styled.form`
    margin-bottom: ${convertPxToRem(24)};
`;

const InputButtonContainerStyed = styled.div`
    display: ${({ $display }) => ($display === "inline" ? "flex" : "block")};
`;
const InputWrapperStyled = styled.div`
    flex: 1;
    position: relative;
    margin-bottom: ${convertPxToRem(8)};
`;
const ButtonWrapperStyled = styled.div`
    flex: 0 0 ${convertPxToRem(40)};

    ${({ $display }) => {
        if ($display === "inline") {
            return css`
                display: flex;
                justify-content: flex-end;
                margin-left: ${convertPxToRem(16)};
            `;
        }
    }};
`;

// components
import Input from "./Input";
import Suggestions from "./Suggestions/index.js";
import AddButton from "./AddButton";

// component
export const AddTagForm = () => {
    const useManageTagsContext = useManageTags();

    const {
        id,
        display,
        dispatch,
        actions,
        state: { showSuggestionDropDown, enabled }
    } = useManageTagsContext;

    const onClickOutside = () => {
        dispatch(actions.setShowSuggestionDropDown(false));
    };
    const { targetRef } = useOnClickOutside({ onClickOutside });

    const onSubmit = e => {
        e.preventDefault();
        actions.onAddTag(useManageTagsContext);
    };

    const disabled = !enabled.add;

    return (
        <FormStyled
            data-testid={`add-tag-form-${id}`}
            onSubmit={onSubmit}
            ref={targetRef}
        >
            <fieldset disabled={disabled}>
                <InputButtonContainerStyed $display={display}>
                    <InputWrapperStyled>
                        <Input />
                        {showSuggestionDropDown && <Suggestions />}
                    </InputWrapperStyled>
                    <ButtonWrapperStyled $display={display}>
                        <AddButton />
                    </ButtonWrapperStyled>
                </InputButtonContainerStyed>
            </fieldset>
        </FormStyled>
    );
};

export default AddTagForm;
