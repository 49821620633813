import merge from "lodash/merge";
import omit from "lodash/omit";

import * as actionTypes from "../actions/actionTypes";

export const user = (state = {}, action) => {
    let user = {},
        nextState = {};

    switch (action.type) {
        case actionTypes.GET_CURRENT_USER_SUCCESS:
            nextState = action.response;
            return merge({}, state, nextState);
            break;
        case actionTypes.SAVE_CARD_SUCCESS:
            nextState = action.response;
            nextState.has_credit_card = true;
            return merge({}, state, nextState);
            break;
        case actionTypes.UPGRADE_BILLING_SUCCESS:
            nextState = merge({}, state);
            nextState.has_premium_account = true;
            return merge({}, state, nextState);
            break;
        default:
            return state;
            break;
    }

    return Object.assign({}, state, nextState);
};
