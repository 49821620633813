import styled, { css } from "styled-components";

import { easingSeconds } from "core/animation";
import { BorderBottomLinkStyle } from "core/Typography";
import { spacingWithBreakpoints } from "core/spacing";
import colors from "core/colors";
import BodyText from "core/BodyText";

export const PostContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const ImageContainerWithLink = styled.a`
    margin-bottom: 1.5rem;
    img {
        transition: opacity ${easingSeconds};
        width: 100%;
        height: auto;
    }
    &:hover {
        img {
            opacity: 0.6;
        }
    }
`;

export const ImageContainer = styled.div`
    img {
        transition: opacity ${easingSeconds};
        width: 100%;
        height: auto;
        margin-bottom: 1.5rem;
    }
`;

export const TitleWithLink = styled.a`
    ${BorderBottomLinkStyle};
    color: ${colors.secondary.orca};
    border-color: transparent;

    &:visited {
        color: ${colors.secondary.orca};
    }

    &:hover {
        color: ${colors.accent.lightMode.persian};
        border-color: ${colors.accent.lightMode.persian};
    }
`;

export const Title = styled.div`
    color: ${colors.secondary.orca};
`;

export const CategoryWithLink = styled.a`
    ${BorderBottomLinkStyle}

    ${({ subtitleLinkInverse }) =>
        !subtitleLinkInverse &&
        css`
            border-color: transparent;
        `};

    &:visited {
        color: ${colors.accent.lightMode.persian};
    }

    &:hover {
        color: ${colors.secondary.cosmic};
    }
`;

export const Subtitle = styled(BodyText)`
    ${({ subtitleIsLink }) =>
        !subtitleIsLink &&
        css`
            padding-top: 1rem; /* 16px */
        `}
`;

export const Links = styled.div`
    padding-top: 1rem; /* 16px */

    a {
        display: inline-block;

        svg {
            opacity: 1;
            transition: opacity ${easingSeconds};
        }

        &:not(:last-of-type) {
            margin-right: 1.5rem; /* 24px */
        }

        &:hover {
            svg {
                opacity: 0.6;
            }
        }
    }
`;

export const CTAsection = styled.div`
    text-align: ${({ buttonAlignment }) =>
        buttonAlignment ? buttonAlignment : "center"};

    ${spacingWithBreakpoints.XL4Top};
`;
