import styled, { css } from "styled-components";

import SearchSegmentedControlButtons from "icons/SearchPage/SearchSegmentedControlButtons";
import BrowseSegmentedControlButtons from "core/BrowsePage/BrowseSegmentedControlButtons";
import SectionContainer from "core/SectionContainer";
import { Container } from "core/SectionContainer/styles";
import SearchAndBrowsePageHeadline from "core/SearchAndBrowsePageHeadline";
import { SiteContentWrapper } from "core/Layout";

import breakpoints from "core/breakpoints";
import { convertPxToRem } from "frontend/components/helpers";

export const StyledContainer = styled(SectionContainer)`
    ${SiteContentWrapper} & {
        display: flex;
        flex: auto;

        ${Container} {
            flex: auto;
            display: flex;
            flex-direction: column;
        }
    }

    ${({ containsSegmentedControls }) => css`
        ${SearchAndBrowsePageHeadline} {
            margin-bottom: ${containsSegmentedControls
                ? convertPxToRem({ px: 24 })
                : convertPxToRem({ px: 32 })};

            /* Tablet: */
            @media (min-width: ${breakpoints.tablet.rem}) {
                margin-bottom: ${containsSegmentedControls
                    ? convertPxToRem({ px: 28 })
                    : convertPxToRem({ px: 40 })};
            }

            /* Desktop: */
            @media (min-width: ${breakpoints.desktop.rem}) {
                margin-bottom: ${containsSegmentedControls
                    ? convertPxToRem({ px: 32 })
                    : convertPxToRem({ px: 48 })};

                /* laptop heights within desktop width reflects tablet top & bottom spacing: */
                @media ${breakpoints.laptopHeightsRange.rem} {
                    margin-bottom: ${containsSegmentedControls
                        ? convertPxToRem({ px: 28 })
                        : convertPxToRem({ px: 40 })};
                }
            }
        }
    `}

    ${SearchSegmentedControlButtons},
    ${BrowseSegmentedControlButtons} {
        margin-bottom: ${convertPxToRem({ px: 32 })};

        /* Tablet: */
        @media (min-width: ${breakpoints.tablet.rem}) {
            margin-bottom: ${convertPxToRem({ px: 40 })};
        }

        /* Desktop: */
        @media (min-width: ${breakpoints.desktop.rem}) {
            margin-bottom: ${convertPxToRem({ px: 48 })};

            /* laptop heights within desktop width reflects tablet top & bottom spacing: */
            @media ${breakpoints.laptopHeightsRange.rem} {
                margin-bottom: ${convertPxToRem({ px: 40 })};
            }
        }
    }
`;
