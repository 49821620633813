import React from "react";
import PropTypes from "prop-types";

import Heading from "core/Heading";
import Button from "core/Button";
import Search from "core/ReusableIcons/Search";

import { PillsContainer } from "./styles";

const PillSection = ({ pillsTitle, pills }) => (
    <PillsContainer data-testid="collections-pills">
        <Heading type="h6" size="xs">
            {pillsTitle}
        </Heading>
        <div>
            {pills.map((pill, index) => (
                <Button
                    dataTestid={`collections-pill-${index + 1}`}
                    href={pill.url}
                    key={pill.title}
                    styleType="outline"
                    iconPosition="left"
                    justifyContent="flex-start"
                    isPill
                >
                    <Search />
                    {pill.title}
                </Button>
            ))}
        </div>
    </PillsContainer>
);

PillSection.propTypes = {
    pillsTitle: PropTypes.string,
    pills: PropTypes.array
};

export default PillSection;
// if we start seeing use cases for these pills outside of this component, we can move the component to a shared location at that time.
