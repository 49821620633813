import React from "react";
import PropTypes from "prop-types";

import {
    PageTitle,
    PageFeedbackContent,
    FeedbackContent,
    FeedbackText,
    FeedbackButtons
} from "../styles";
import Button from "core/Button";

const FacebookTryAgain = ({ loginClickHandler, signupClickHandler }) => (
    <PageFeedbackContent>
        <PageTitle bottomSpacing={24}>You Didn’t Give Us Access</PageTitle>
        <FeedbackContent>
            <FeedbackText bottomSpacing={24}>
                Unable to connect to account without granting permissions.
                Please try again.
            </FeedbackText>
            <FeedbackButtons>
                <Button {...loginClickHandler}>Log In</Button>
                <Button {...signupClickHandler}>Create an Account</Button>
            </FeedbackButtons>
        </FeedbackContent>
    </PageFeedbackContent>
);

FacebookTryAgain.propTypes = {
    // { onClick, ... } or { href, ... }
    loginClickHandler: PropTypes.object.isRequired,
    signupClickHandler: PropTypes.object.isRequired
};

export default FacebookTryAgain;
