// Global constants file
// All const should be a const and be a value that doesn't change.
// If it's a function, please use the components/helpers file
// please follow the upper case pattern. Comments always welcome.

export const MILLIONS_OF_ICONS = 7;
export const MILLIONS_OF_ASSETS = 7;

export const MONTHLY_PRICE_PLAN = 12.99;
export const ANNUAL_PRICE_AS_YEARLY = 39.99;
export const CPRO_PRICE_AS_YEARLY = 119.88;
export const EDU_PER_YEAR_COST = 19.99;
export const SINGLE_ICON_PRICE = 4.99;
export const STANDARD_PHOTO_PRICE = 10.99;
export const PREMIUM_PHOTO_PRICE = 34.99;
// comes out as 3.3325. This # is important for calcs for subscriptions that product wants
export const ANNUAL_PRICE_AS_MONTHLY = ANNUAL_PRICE_AS_YEARLY / 12;
export const CPRO_PRICE_AS_MONTHLY = CPRO_PRICE_AS_YEARLY / 12;

// international pricing experiment
export const NON_US_MONTHLY_PRICE_PLAN = 4.99;
export const NON_US_ANNUAL_PRICE_AS_YEARLY = 19.99;
export const NON_US_ANNUAL_PRICE_AS_MONTHLY =
    NON_US_ANNUAL_PRICE_AS_YEARLY / 12;

export const CENTS = "cents";
export const PERCENT = "percent";

export const SUBTEXT_ANNUAL_TEAM_PLAN = "/month per user paid yearly";
export const SUBTEXT_ANNUAL_TEAM_PLAN_AS_MONTHLY = "/month per user";
export const SUBTEXT_ANNUAL_PLAN_AS_MONTHLY = "/month paid yearly";
export const SUBTEXT_ANNUAL_EDU_PLAN = "/year per user";
export const SUBTEXT_MONTHLY_PLAN = "/month";

export const SHORT_SUBTEXT_ANNUAL_PLAN = "/yearly";
export const SHORT_SUBTEXT_ANNUAL_PLAN_AS_MONTHLY = "/mo paid yearly";
export const SHORT_SUBTEXT_MONTHLY_PLAN = "/mo";

export const PER_MONTH = "/month";
export const PER_YEAR = "/year";

export const YEARLY_PLAN_TYPE = "yearly";

export const YEARLY_SUBSCRIPTION_PLAN_NAME = "Yearly Icon Pro";
export const MONTHLY_SUBSCRIPTION_PLAN_NAME = "Monthly Icon Pro";
export const CPRO_SUBSCRIPTION_PLAN_NAME = "Creator Pro";

export const ICONS = "icons";
export const ICON_SETS = "icon-collections";
export const PHOTOS = "photos";

export const BROWSE_LIST_TYPE = "browse";
export const SEARCH_LIST_TYPE = "search";

// used for load more, view more, etc
export const CONTENT_TYPE = {
    icon: "icon",
    iconSet: "icon set",
    photo: "photo"
};

export const PROD_BASE_URL = "https://thenounproject.com";
