import React from "react";
import styled from "styled-components";

// context
import { useSiteHeader } from "core/SiteHeaderV2/context/useSiteHeader.js";

// components
import PrimaryMenu from "./PrimaryMenu";
import TNPLogo from "core/SiteHeaderV2/components/TNPLogo";
import UnauthMenu from "./UnauthMenu";
import AuthMenu from "./Auth/AuthMenu";

// styles
const Row1Styled = styled.div`
    display: grid;
    grid-template-columns: 28px 183px 1fr 149px;
    gap: 24px;
    height: 40px;
`;

const RightMenuStyled = styled.div`
    display: flex;
    justify-content: flex-end;
`;

// component
export const Row1 = () => {
    const { auth } = useSiteHeader();

    return (
        <Row1Styled>
            <div>
                <PrimaryMenu />
            </div>
            <div>
                <TNPLogo />
            </div>
            <div></div>
            <RightMenuStyled>
                {auth?.isLoggedIn ? <AuthMenu /> : <UnauthMenu />}
            </RightMenuStyled>
        </Row1Styled>
    );
};

export default Row1;
