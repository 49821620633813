import React, { useState, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

// Props:
import { messagesStyleTypes, messagesInfoTypes, alignments } from "core/props";

// helpers
import { convertPxToRem } from "frontend/components/helpers";
import { getCloseButtonColor, getIcon } from "./helpers";

// Styles + Components:
import Close from "core/ReusableIcons/Close";
import TextContainer from "./TextContainer";

import { Banner, LeftIconContainer, DismissBannerIconButton } from "./styles";

const UnstyledMessages = ({
    className,
    children,
    styleType = "transparent",
    infoType = "neutral",
    darkMode = false,
    initiallyVisible = true,
    textAlignment = "left",
    width = "100%",
    isDismissable = styleType === "transparent" ? false : true,
    dismissTimerSeconds = 0, // if > 0, will time out visibility
    hasIcon = true,
    alternativeIcon,
    handleDismissClick,
    contentIcons,
    bannerValue,
    overrideDismissClick
}) => {
    const [isVisible, updateIsVisible] = useState(initiallyVisible);

    const handleCloseClick = e => {
        if (overrideDismissClick) {
            return overrideDismissClick(e);
        }

        updateIsVisible(!isVisible);
        if (handleDismissClick) {
            !!bannerValue
                ? handleDismissClick(bannerValue)
                : handleDismissClick();
        }
    };

    const closeButtonLabel = `Dismiss ${infoType} banner`;
    const closeButtonColors = getCloseButtonColor({ infoType, darkMode });

    const LeftIcon = alternativeIcon ? alternativeIcon : getIcon({ infoType });

    useEffect(() => {
        if (dismissTimerSeconds) {
            setTimeout(() => {
                updateIsVisible(false);
            }, 1000 * dismissTimerSeconds);
        }
    }, []);

    const conditionalProps =
        isDismissable && styleType === "transparent"
            ? {
                  onClick: handleDismissClick,
                  hasClickEvent: true
              }
            : {};

    return (
        <>
            {isVisible && (
                <Banner
                    role="alert"
                    className={className}
                    width={width}
                    darkMode={darkMode}
                    styleType={styleType}
                    infoType={infoType}
                >
                    {hasIcon && !!LeftIcon && (
                        <LeftIconContainer
                            styleType={styleType}
                            infoType={infoType}
                            darkMode={darkMode}
                            {...conditionalProps}
                        >
                            <LeftIcon size="md" />
                        </LeftIconContainer>
                    )}

                    <TextContainer
                        darkMode={darkMode}
                        styleType={styleType}
                        infoType={infoType}
                        alignment={textAlignment}
                        contentIcons={contentIcons}
                    >
                        {children}
                    </TextContainer>

                    {/* Only allow isDismissable for "solid" type */}
                    {styleType === "solid" && isDismissable && (
                        <DismissBannerIconButton
                            styleType="primary"
                            width={convertPxToRem({ px: 16 })}
                            darkMode={darkMode}
                            ariaLabel={closeButtonLabel}
                            svgElementType={Close}
                            buttonProps={{ onClick: handleCloseClick }}
                            closeIconColor={closeButtonColors.closeIconColor}
                            closeIconHoverColor={
                                closeButtonColors.closeIconHoverColor
                            }
                        />
                    )}
                </Banner>
            )}
        </>
    );
};

const Messages = styled(UnstyledMessages)``;

Messages.propTypes = {
    children: PropTypes.any.isRequired,
    width: PropTypes.string,
    darkMode: PropTypes.bool,
    styleType: PropTypes.oneOf(messagesStyleTypes),
    infoType: PropTypes.oneOf(messagesInfoTypes),
    isDismissable: PropTypes.bool,
    dismissTimerSeconds: PropTypes.number,
    hasIcon: PropTypes.bool,
    alternativeIcon: PropTypes.elementType,
    handleDismissClick: PropTypes.func,
    textAlignment: PropTypes.oneOf(alignments),
    contentIcons: PropTypes.arrayOf(
        PropTypes.shape({
            svgElementType: PropTypes.elementType.isRequired,
            ariaLabel: PropTypes.string.isRequired,
            href: PropTypes.string,
            target: PropTypes.string,
            fillColor: PropTypes.string
        })
    ),
    bannerValue: PropTypes.string,
    overrideDismissClick: PropTypes.func
};

export default Messages;
