import styled from "styled-components";

// styles
import { convertPxToRem } from "frontend/components/helpers";

// components
import WarningTriangle from "core/ReusableIcons/WarningTriangle";

// styled-components
export const SuspendedIconStyled = styled(WarningTriangle)`
    position: absolute;
    bottom: ${convertPxToRem(16)};
    right: ${convertPxToRem(24)};
`;
