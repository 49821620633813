import styled, { css } from "styled-components";

import { handleTextSizeAndFont } from "core/BodyText/helpers";
import { convertPxToRem } from "frontend/components/helpers";
import { handleTopBottomSpacing } from "./helpers";

import { SHARED_FORM_STYLES } from "core/Form";

const PADDING_SIZES = {
    XS: css`
        padding-top: ${convertPxToRem({ px: 6 })};
        padding-bottom: ${convertPxToRem({ px: 6 })};
    `,
    SM: css`
        padding-top: ${convertPxToRem({ px: 8 })};
        padding-bottom: ${convertPxToRem({ px: 8 })};
    `,
    MD: css`
        padding-top: ${convertPxToRem({ px: 14 })};
        padding-bottom: ${convertPxToRem({ px: 14 })};
    `,
    LG: css`
        padding-top: ${convertPxToRem({ px: 16 })};
        padding-bottom: ${convertPxToRem({ px: 16 })};
    `
};

export const TextArea = styled.textarea`
    ${SHARED_FORM_STYLES.BASE}
    ${handleTextSizeAndFont({ size: "lg" })};

    height: ${({ height }) => height};
    width: 100%;
    overflow: auto;
    display: block;

    // top & bottom padding depending on size:
    ${({ size }) => handleTopBottomSpacing({ size, PADDING_SIZES })}

    // placeholder:
    &::placeholder {
        ${SHARED_FORM_STYLES.PLACEHOLDER}
    }

    // hover:
    &:hover {
        ${SHARED_FORM_STYLES.HOVER}
    }

    // focus:
    &:focus {
        ${SHARED_FORM_STYLES.FOCUS}
    }

    // disabled:
    &:disabled {
        ${SHARED_FORM_STYLES.DISABLED}
    }

    // read-only:
    &:read-only {
        ${SHARED_FORM_STYLES.READONLY}
    }
`;
