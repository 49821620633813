import React from "react";
import PropTypes from "prop-types";

// components
import SrOnly from "core/SrOnly";

// styles
import { FauxCheckboxStyled, CheckmarkStyled } from "./styles.js";

const FauxCheckbox = ({ id, value, isChecked, isHovered }) => {
    const showCheckbox = isHovered || isChecked;

    return (
        <>
            <SrOnly>
                <label htmlFor={id}>
                    <input
                        type="checkbox"
                        id={id}
                        defaultChecked={isChecked}
                        value={value}
                    />
                </label>
            </SrOnly>
            {showCheckbox && (
                <FauxCheckboxStyled isChecked={isChecked}>
                    {isChecked && <CheckmarkStyled />}
                </FauxCheckboxStyled>
            )}
        </>
    );
};

export default FauxCheckbox;

// prop-types
FauxCheckbox.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    value: PropTypes.string.isRequired,
    isChecked: PropTypes.bool.isRequired,
    isHovered: PropTypes.bool.isRequired
};
