import styled from "styled-components";

// styles
import { convertPxToRem } from "frontend/components/helpers";

// components
import { ModerationToggleBandStyled } from "frontend/components/fort/mod/core/ModerationToggleBand/styles";
import { ButtonLooksLikeLinkStyled } from "frontend/components/core/ButtonLooksLikeLink/styles";

// styled-components

export const ModerationToggleBandContainerStyled = styled.div`
    ${ModerationToggleBandStyled} {
        height: ${convertPxToRem(28)};
        padding: 0px;
    }
`;

export const FlagManagementStyled = styled.div`
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
    min-width: ${convertPxToRem(355)};

    ${ButtonLooksLikeLinkStyled} {
        margin-right: 20px;

        &:last-child {
            margin-right: 0px;
        }
    }
`;
