import { detectEmoji } from "frontend/components/helpers";

export const inlineTextValidation = {
    noEmojis: (name, text) => {
        return {
            isValid: !detectEmoji(text),
            message: `${name} contains invalid characters.`
        };
    },
    minLength: (length, emptyIsValid = true) => {
        return (name, text) => {
            const textLength = text.length;
            const meetsMin = textLength >= length;
            const emptyCheck = emptyIsValid && textLength === 0;
            return {
                isValid: emptyCheck || meetsMin,
                message: `${name} must be at least ${length} characters long.`
            };
        };
    },
    maxLength: length => {
        return (name, text) => {
            const textLength = text.length;
            return {
                isValid: textLength <= length,
                message: `${name} can not be greater than ${length} characters long.`
            };
        };
    }
};
