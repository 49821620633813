import styled from "styled-components";

// styles
import { convertPxToRem } from "frontend/components/helpers";

// styled-components
export const BatchBodyStyled = styled.div`
    overflow: hidden;
    transition: max-height 0.4s ease;
    max-height: ${({ containerHeight }) => convertPxToRem(containerHeight)};
`;
export const BatchInnerChildStyled = styled.div`
    padding: 0px;
`;
