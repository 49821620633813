/*---------------------------
| SortBy
---------------------------*/
export const SORT_BY_VALUES = ["oldeset-to-newest", "newest-to-oldest"];
export const SORT_BY_OPTIONS = [
    {
        id: 1,
        key: "sortBy",
        value: SORT_BY_VALUES[0],
        label: "Oldest - Newest"
    },
    {
        id: 2,
        key: "sortBy",
        value: SORT_BY_VALUES[1],
        label: "Newest - Oldest"
    }
];
