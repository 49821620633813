import React from "react";
import PropTypes from "prop-types";

import TitleWithOffset from "./TitleWithOffset";
import CollectionBoxes from "./CollectionBoxes";
import PillSection from "./PillSection";

import colors from "core/colors";
import { CollectionsHeading } from "./styles";

const Collections = ({
    id,
    title,
    collections,
    pillsTitle,
    pills,
    hasTitleOffset,
    isLink
}) => {
    const hasPills = pills && Object.keys(pills).length > 0;

    return (
        <>
            {hasTitleOffset ? (
                <>
                    {title && <TitleWithOffset title={title} />}
                    <CollectionBoxes collections={collections} />
                    {hasPills && (
                        <PillSection pillsTitle={pillsTitle} pills={pills} />
                    )}
                </>
            ) : (
                <>
                    {title && (
                        <CollectionsHeading
                            type="h2"
                            size="xl"
                            headingColor={colors.secondary.orca}
                            bottomSpacing="5xl"
                            alignment="center"
                        >
                            {title}
                        </CollectionsHeading>
                    )}
                    <CollectionBoxes
                        id={id}
                        collections={collections}
                        isLink={isLink}
                    />
                    {hasPills && (
                        <PillSection pillsTitle={pillsTitle} pills={pills} />
                    )}
                </>
            )}
        </>
    );
};

Collections.propTypes = {
    id: PropTypes.string,
    title: PropTypes.string,
    collections: PropTypes.array.isRequired,
    pillsTitle: PropTypes.string,
    pills: PropTypes.array,
    hasTitleOffset: PropTypes.bool,
    // ^^ this prop means that when true, the title will have offset columns
    isLink: PropTypes.bool
};

export default Collections;
