import React, { useCallback, useEffect, useState, useRef } from "react";
import debounce from "lodash/debounce";

import { useMutation, useQuery } from "@apollo/client";

import { useEditPhotoUpload } from "../EditPhotoUploadContext";

import { SET_LOCATION_ON_PHOTO_UPLOADS } from "../mutations";
import { GET_LOCATION_SUGGESTIONS } from "../queries";

import { LocationContainer } from "./styles";

import SuggestedLocations from "./SuggestedLocations";
import LocationInput from "./LocationInput";

const LocationSection = () => {
    const {
        uploadIds = [],
        uploads = [],
        isBatchEditing
    } = useEditPhotoUpload();

    const inputId = "photo-location-suggestion";
    const placeholderText = "Add location (Optional)";
    const dropdownId = "suggested-location-container";

    const [suggestedLocations, setSuggestedLocations] = useState([]);
    const [photoLocation, setPhotoLocation] = useState("");
    const [isFocused, setFocused] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [hideLocation, setHideLocation] = useState(false);
    const [focusedItem, setFocusedItem] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const dropdownRef = useRef(null);

    const hideLabel = isDropdownOpen;

    const formatLocationValues = () => {
        const locationsArray = uploads.map(upload => {
            const { id, photoLocation } = upload;
            if (uploadIds.includes(id)) {
                return photoLocation;
            }
        });

        // if 1 location, show it
        // if many locations, hide location field
        // if no location, show empty field
        const numLocations = [...new Set(locationsArray)].length;

        if (numLocations === 1) {
            setPhotoLocation(locationsArray[0]);
        } else if (numLocations > 1 && isBatchEditing) {
            setHideLocation(true);
        } else {
            setPhotoLocation("");
            setHideLocation(false);
        }
    };

    const handleClickOutside = useCallback(() => {
        if (dropdownRef.current) {
            setIsDropdownOpen(false);
        }
    }, [dropdownRef.current, setIsDropdownOpen]);

    const handleFocus = useCallback(() => {
        setFocused(true);
    }, [setFocused]);

    const handleBlur = useCallback(() => {
        setFocused(false);
    }, [setFocused]);

    useEffect(() => {
        formatLocationValues();
    }, [uploadIds]);

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const inputEl = document.getElementById(inputId);
        if (inputEl) {
            inputEl.addEventListener("focus", handleFocus);
            inputEl.addEventListener("blur", handleBlur);
        }
        return () => {
            if (inputEl) {
                inputEl.removeEventListener("focus", handleFocus);
                inputEl.removeEventListener("blur", handleBlur);
            }
        };
    }, [handleFocus, handleBlur]);

    const [setLocationOnPhotoUploads] = useMutation(
        SET_LOCATION_ON_PHOTO_UPLOADS,
        {
            refetchQueries: ["listPhotoUploads"]
        }
    );

    const { refetch } = useQuery(GET_LOCATION_SUGGESTIONS, {
        variables: {
            query: ""
        }
    });

    const getSuggestedLocationResults = query => {
        if (query.length < 3) return;
        refetch({ query })
            .then(({ data }) => {
                if (data && data.suggestLocationAutocomplete) {
                    const locationResults = data.suggestLocationAutocomplete.map(
                        place => {
                            return {
                                formattedLocation: place.formattedLocation,
                                placeId: place.placeId
                            };
                        }
                    );

                    setSuggestedLocations(locationResults);
                    if (locationResults.length > 0) {
                        setIsDropdownOpen(true);
                        setErrorMessage(null);
                    } else {
                        setIsDropdownOpen(false);
                        setErrorMessage("No matching locations found.");
                    }
                } else {
                    setErrorMessage(
                        "Unable to load locations. Please try again."
                    );
                    console.error("Error fetching location suggestions");
                }
            })
            .catch(e => {
                setIsDropdownOpen(false);
                setErrorMessage("Unable to load locations. Please try again.");
                console.error("Error fetching location suggestions", e);
            });
    };

    const debouncedGetSuggestedLocationResults = React.useRef(
        debounce(getSuggestedLocationResults, 500)
    );

    useEffect(() => {
        return () => {
            debouncedGetSuggestedLocationResults.current.cancel();
        };
    }, [debouncedGetSuggestedLocationResults]);

    const handleSuggestedLocationChange = value => {
        setErrorMessage(null);
        if (value === "") {
            handleChangePhotoLocation({
                uploadIds,
                placeId: null,
                formattedLocation: ""
            });
        }
        setPhotoLocation(value);
        debouncedGetSuggestedLocationResults.current(value);
    };

    const handleChangePhotoLocation = ({
        uploadIds,
        placeId,
        formattedLocation
    }) => {
        setLocationOnPhotoUploads({
            variables: {
                photoUploadIds: uploadIds,
                placeId,
                locationName: formattedLocation
            }
        }).then(response => {
            if (response.data.setLocationOnPhotoUploads.ok) {
                setPhotoLocation(formattedLocation);
                setIsDropdownOpen(false);
                setFocusedItem(null);
            }
        });
    };

    const handleConfirm = value => {
        if (value) {
            const { formattedLocation, placeId } = value;
            handleChangePhotoLocation({
                uploadIds,
                placeId,
                formattedLocation
            });
            setIsDropdownOpen(false);
        }
    };

    const handleKeyDown = e => {
        if (!isDropdownOpen) return;

        if (e.key === "ArrowDown") {
            e.preventDefault();
            setFocusedItem(prevItem => {
                if (prevItem === null) return 0;
                return prevItem < suggestedLocations.length - 1
                    ? prevItem + 1
                    : prevItem;
            });
        } else if (e.key === "ArrowUp") {
            e.preventDefault();
            setFocusedItem(prevItem => (prevItem > 0 ? prevItem - 1 : 0));
        } else if (e.key === "Enter") {
            e.preventDefault();
            const selectedItem = suggestedLocations[focusedItem];
            if (selectedItem) {
                const { placeId, formattedLocation } = selectedItem;
                handleChangePhotoLocation({
                    uploadIds,
                    placeId,
                    formattedLocation
                });
            }
        }
    };

    if (hideLocation) return null;

    return (
        <LocationContainer onKeyDown={handleKeyDown} id={dropdownId}>
            <LocationInput
                photoLocation={photoLocation}
                placeholderText={placeholderText}
                handleLocationOnChange={handleSuggestedLocationChange}
                inputId={inputId}
                hideLabel={hideLabel}
                isFocused={isFocused}
                error={errorMessage}
            />

            {isDropdownOpen && (
                <SuggestedLocations
                    dropdownRef={dropdownRef}
                    suggestedLocations={suggestedLocations}
                    handleConfirm={handleConfirm}
                    focusedItem={focusedItem}
                />
            )}
        </LocationContainer>
    );
};

export default LocationSection;
