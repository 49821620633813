import gql from "graphql-tag";

export const APPROVE_PHOTO_UPLOAD_TITLE = gql`
    mutation approvePhotoUploadTitle($photoUploadId: ID!) {
        payload: approvePhotoUploadTitle(photoUploadId: $photoUploadId) {
            errors
            ok
            photoUpload {
                photoTitleApproved
                photoTitle
            }
        }
    }
`;

export const REJECT_PHOTO_UPLOAD_TITLE = gql`
    mutation rejectPhotoUploadTitle($photoUploadId: ID!) {
        rejectPhotoUploadTitle(photoUploadId: $photoUploadId) {
            errors
            ok
            photoUpload {
                photoTitleApproved
                photoTitle
            }
        }
    }
`;

// If Moderator update photo title, they are also approving it
export const UPDATE_AND_APPROVE_PHOTO_UPLOAD_TITLE = gql`
    mutation upadateAndApprovePhotoUploadTitle(
        $photoUploadId: ID!
        $value: String
    ) {
        setPhotoUploadTitle(photoUploadId: $photoUploadId, value: $value) {
            errors
            ok
            photoUpload {
                photoTitle
            }
        }
        approvePhotoUploadTitle(photoUploadId: $photoUploadId) {
            errors
            ok
            photoUpload {
                photoTitleApproved
                photoTitle
            }
        }
    }
`;
