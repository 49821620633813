import React, { useEffect, useRef, useState } from "react";
import layoutGeometry from "justified-layout";

import { PhotoGrid } from "photos/PhotosGrid/styles";

import { useCurrentUser } from "js/contexts/CurrentUserContext";
import { Photos, generateAds } from "photos/PhotosGrid/helpers";
import { useViewport } from "js/hooks/useViewport";
import breakpoints from "core/breakpoints";

const SearchPhotosResultsGrid = ({ items }) => {
    const [containerWidth, setContainerWidth] = useState(0);
    const ref = useRef();

    useEffect(() => {
        const handleResize = () => {
            setContainerWidth(ref.current.offsetWidth);
        };

        if (ref.current) {
            handleResize();
        }

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [ref]);

    const { browserWidth: pageWidth } = useViewport();

    const user = useCurrentUser();
    const userHasSubscription = user && user.hasSubscription;

    const isMobile = pageWidth < breakpoints.tablet.px;
    const ratios = items.map(({ ratio }) => ratio);

    const ratioResults = layoutGeometry(ratios, {
        containerWidth,
        targetRowHeight: 265,
        boxSpacing: !isMobile ? 24 : 12,
        containerPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
        }
    });

    const { containerHeight } = ratioResults;
    const mergedResults = items.map((photo, idx) => ({
        ...photo,
        ...ratioResults.boxes[idx]
    }));

    const { resultsWithAds, adCount, adHeightWtihPadding } = generateAds(
        mergedResults,
        isMobile,
        containerWidth,
        false,
        userHasSubscription
    );

    const gridHeight = containerHeight + adHeightWtihPadding * adCount;

    return (
        <PhotoGrid height={gridHeight} ref={ref}>
            <Photos
                subscribed={userHasSubscription}
                mergedResults={mergedResults}
                resultsWithAds={resultsWithAds}
            />
        </PhotoGrid>
    );
};

export default SearchPhotosResultsGrid;
