import gql from "graphql-tag";

export const WITHDRAW_PHOTO_UPLOADS = gql`
    mutation withdrawPhotoUploads($photoUploadIds: [ID!]!) {
        withdrawPhotoUploads(photoUploadIds: $photoUploadIds) {
            ok
            photoUploads {
                ok
                errors
                photoUploadId
                photoUpload {
                    id
                    status
                    __typename
                }
            }
        }
    }
`;
