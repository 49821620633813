import React from "react";
import PropTypes from "prop-types";

// Hooks
import { useGridItemTemplate } from "../useGridItemTemplate.js";

// styles
import { AssetContainerStyled, GridItemContentStyled } from "../styles.js";

// components
import UploadCount from "../components/UploadCount.js";
import FileName from "../components/FileName.js";

// for optional consumer functions
import { nullFunc } from "../utils.js";

export const GridItemContentDisplay = ({
    name,
    gridIndex,
    gridItemCount,

    children,

    showUploadCount = true,
    showFileName = true,

    onMouseEnter = nullFunc,
    onMouseLeave = nullFunc,
    onFocus = nullFunc,
    onBlur = nullFunc
}) => {
    // useSelect Hook State
    const {
        isHovered,
        isFocused,
        onMouseEnterHandler,
        onMouseLeaveHandler,
        onFocusHandler,
        onBlurHandler
    } = useGridItemTemplate({ onMouseEnter, onMouseLeave, onFocus, onBlur });

    // render
    return (
        <AssetContainerStyled
            onMouseEnter={onMouseEnterHandler} // for mouse
            onMouseLeave={onMouseLeaveHandler} // for mouse
            onFocus={onFocusHandler} // for keyboard
            onBlur={onBlurHandler} // for keyboard
            isFocused={isFocused}
        >
            {/* Grid Item Content: Provided by Consumer */}
            <GridItemContentStyled>{children}</GridItemContentStyled>

            {/* OVERLAY META ELEMENTS */}
            <UploadCount
                showUploadCount={showUploadCount}
                isHovered={isHovered}
                gridIndex={gridIndex}
                gridItemCount={gridItemCount}
            />
            <FileName
                showFileName={showFileName}
                isHovered={isHovered}
                name={name}
            />
        </AssetContainerStyled>
    );
};

export default GridItemContentDisplay;

// prop-types
GridItemContentDisplay.propTypes = {
    name: PropTypes.string.isRequired,
    gridIndex: PropTypes.number.isRequired,
    gridItemCount: PropTypes.number,

    children: PropTypes.any,

    showUploadCount: PropTypes.bool,
    showFileName: PropTypes.bool,

    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func
};
