import React from "react";
import { useDispatch } from "react-redux";

// context
import { useQueueContext } from "frontend/components/fort/mod/core/Layouts/QueueLayout/QueueContext.js";

// redux
import { uploadsSlice } from "frontend/components/fort/mod/photos/slices/uploadsSlice/index.js";
import { messagesSlice } from "frontend/components/fort/mod/photos/slices/messagesSlice.js";

// components
import { Title } from "frontend/components/fort/mod/core/Sidebar/Overview/Title.js";

// api
import {
    useUpdateAndApprovePhotoUploadTitle,
    useApprovePhotoUploadTitle,
    useRejectPhotoUploadTitle
} from "./api";

// component
export const PhotoTitle = () => {
    const {
        state: { singleUploadSelected }
    } = useQueueContext();
    const dispatch = useDispatch();

    // TITLE
    const {
        upadateAndApprovePhotoUploadTitle
    } = useUpdateAndApprovePhotoUploadTitle();
    const { approvePhotoUploadTitle } = useApprovePhotoUploadTitle();
    const { rejectPhotoUploadTitle } = useRejectPhotoUploadTitle();

    const onSaveTitle = async ({ editedText: photoTitle }) => {
        const editedTextisEmpty = photoTitle === null || photoTitle === "";

        const resp = editedTextisEmpty
            ? await rejectPhotoUploadTitle({
                  photoUploadId: singleUploadSelected.id
              })
            : await upadateAndApprovePhotoUploadTitle({
                  photoUploadId: singleUploadSelected.id,
                  value: photoTitle
              });

        if (resp.success) {
            // update redux
            dispatch(
                uploadsSlice.actions.updateUpload({
                    id: singleUploadSelected.id,
                    changes: {
                        photoTitle: photoTitle,
                        photoTitleApproved: !editedTextisEmpty
                    }
                })
            );
        } else {
            // send error to inline text edit
            return {
                errors: true, // if set to true, remains in {editing mode} and errorMessage is displayed
                errorMessage: resp.message
            };
        }
    };

    const onToggleApproveTitle = async approve => {
        const gqVariables = { photoUploadId: singleUploadSelected.id };
        const resp = approve
            ? await approvePhotoUploadTitle(gqVariables)
            : await rejectPhotoUploadTitle(gqVariables);

        if (resp.success) {
            // update redux
            dispatch(
                uploadsSlice.actions.updateUpload({
                    id: singleUploadSelected.id,
                    changes: {
                        photoTitleApproved: approve
                    }
                })
            );
        } else {
            // pop message stack error
            dispatch(
                messagesSlice.actions.addMessage({
                    text: resp.message,
                    timed: false,
                    timeout: null,
                    infoType: "error"
                })
            );
        }
    };

    return (
        <Title
            onSaveTitle={onSaveTitle}
            onToggleApproveTitle={onToggleApproveTitle}
        />
    );
};

export default PhotoTitle;
