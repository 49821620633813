/*---------------------------
| Store
---------------------------*/
// Action Types
const actionPrefix = "PanelOverlayContext:";
export const actionTypes = {
    SET_HAS_MOUNTED: `${actionPrefix} setHasOpened`,
    SET_IS_OPEN: `${actionPrefix} setIsOpen`
};

// Reducer
export const reducer = (state, action) => {
    const { type } = action;

    switch (type) {
        case actionTypes.SET_HAS_MOUNTED: {
            return { ...state, hasOpened: action.hasOpened };
        }
        case actionTypes.SET_IS_OPEN: {
            return { ...state, isOpen: action.isOpen };
        }
        default: {
            return { ...state };
        }
    }
};
