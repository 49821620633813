import { actionTypes } from "./actionTypes";

/*---------------------------
| Actions
---------------------------*/
export const next = () => {
    return {
        type: actionTypes.CC_SELECT_NEXT
    };
};
export const previous = () => {
    return {
        type: actionTypes.CC_SELECT_PREVIOUS
    };
};
export const setIsAnimating = isAnimating => {
    return {
        type: actionTypes.CC_SET_IS_ANIMATING,
        isAnimating
    };
};
