import { createSlice } from "@reduxjs/toolkit";

import { MOD_PHOTOS } from "data/links";

///////////////////////////
//   reducer & actions   //
///////////////////////////

export const ICONS_SEARCH_MODE = "Icons";
export const ICON_SETS_SEARCH_MODE = "Icon Sets";
export const PHOTOS_SEARCH_MODE = "Photos";

export const initialState = {
    visibleHeader: true,
    visibleFooter: true,
    darkModeHeader: false,
    darkModeContent: false,
    flexLayout: true,
    searchMode: ICONS_SEARCH_MODE
};

const layout = createSlice({
    name: "layout",
    initialState,
    reducers: {
        setHeaderVisibility(state, action) {
            state.visibleHeader = !!action.payload;
        },
        setFooterVisibility(state, action) {
            state.visibleFooter = !!action.payload;
        },
        setDarkHeader(state, action) {
            state.darkModeHeader = !!action.payload;
        },
        setDarkBackground(state, action) {
            state.darkModeContent = !!action.payload;
        },
        setFlexLayout(state, action) {
            state.flexLayout = !!action.payload;
        },
        setSearchMode(state, action) {
            state.searchMode =
                action.payload === PHOTOS_SEARCH_MODE
                    ? PHOTOS_SEARCH_MODE
                    : action.payload === ICON_SETS_SEARCH_MODE
                    ? ICON_SETS_SEARCH_MODE
                    : ICONS_SEARCH_MODE;
        }
    }
});

export default layout.reducer;

export const {
    setHeaderVisibility,
    setFooterVisibility,
    setDarkHeader,
    setDarkBackground,
    setFlexLayout,
    setSearchMode
} = layout.actions;

////////////////////////////
//   selector-functions   //
////////////////////////////

// NOTE: avoid using `createSelector` because the returned value is cached.
// On non-SSR pages, cached values will stick while navigating to other pages.
// Once the legacy support is removed, we can use `createSelector`.

export const getHeaderVisibility = state => {
    // legacy page support
    if (state.layout === undefined) {
        const path = window.location.pathname;
        return !path.startsWith(MOD_PHOTOS);
    }
    return state.layout.visibleHeader;
};

export const getFooterVisibility = state => {
    // legacy page support
    if (state.layout === undefined) {
        const path = window.location.pathname;
        return !path.startsWith(MOD_PHOTOS);
    }
    return state.layout.visibleFooter;
};

export const getDarkHeader = state => {
    // legacy page support
    if (state.layout === undefined) {
        return false;
    }
    return state.layout.darkModeHeader;
};

export const getDarkBackground = state => {
    // legacy page support
    if (state.layout === undefined) {
        return false;
    }
    return state.layout.darkModeContent;
};

export const getFlexLayout = state => {
    // legacy page support
    if (state.layout === undefined) {
        return true;
    }
    return state.layout.flexLayout;
};

export const getSearchMode = state => {
    const creatorBrowseRegex = /\/browse\/creator\/[^/]+\/photos(\/search)?\//;
    // legacy page support
    if (state.layout === undefined) {
        // TODO:
        // As we SSR pages, clean up this conditional
        const path = window.location.pathname;
        if (
            path.startsWith("/photo/") || // photo detail page
            path.startsWith("/browse/photos/term/") ||
            path.startsWith("/browse/photo-shoot/") ||
            creatorBrowseRegex.test(path) ||
            path.startsWith("/browse/similar/photos/") ||
            path.startsWith("/browse/campaign/") ||
            path.startsWith("/search/photos/")
        ) {
            return PHOTOS_SEARCH_MODE;
        }
        if (
            path.includes("/icon-collections/") ||
            path.startsWith("/browse/collection-icon/")
        ) {
            return ICON_SETS_SEARCH_MODE;
        }
        return ICONS_SEARCH_MODE;
    }

    return state.layout.searchMode;
};
