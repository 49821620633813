import { TAGS_INIT_STATES } from "core/AddTags/constants";
import {
    ADD_TAGS_ERRORS,
    maxTagsError,
    maxTagsAllowed,
    maxCharsAllowed,
    PUBLIC_DOMAIN_BE_VALUE,
    CREATIVE_COMMONS,
    PUBLIC_DOMAIN,
    tagDisallowedError,
    tagInvalidError
} from "./constants";

/******
 *
 * Tag helpers:
 *
 * *******/

// helper to find all common tags and make 1 array to show as added tags in the UI:
export const getBatchMergedTags = ({ data }) => {
    const dataLength = data.length;
    let allTerms = []; // every term in every userTags list in every matchedPhoto object merged into 1 array
    let repeatedTerms = []; // every term that shows up more than once in allTerms
    let termsToDisplay = []; // every term that shows up equal the length of matchedPhoto to ensure it shows up for ALL data
    let allTermsCounter = {}; // counts how many times each term in allTerms shows
    let repeatedTermsCounter = {}; // counts how many times each term thats repeated appears

    data.map(file => allTerms.push(...file.userTags));

    // sort allTerms so we can look for duplicates:
    const sortedTerms = Array.from(allTerms)
        .map(term => term.toLowerCase())
        .sort((a, b) => {
            // sorts alphabetically regardless of casing
            return a.localeCompare(b, undefined, { sensitivity: "base" });
        });

    for (let i = 0; i < sortedTerms.length; i++) {
        if (sortedTerms[i] === sortedTerms[i + 1]) {
            if (repeatedTerms[repeatedTerms.length - 1] !== sortedTerms[i]) {
                repeatedTerms.push(sortedTerms[i]);
            }
        }
    }

    for (let term of sortedTerms) {
        allTermsCounter[term] = allTermsCounter[term]
            ? allTermsCounter[term] + 1
            : 1;
    }

    for (let term of repeatedTerms) {
        repeatedTermsCounter[term] = repeatedTermsCounter[term]
            ? repeatedTermsCounter[term] + 1
            : 1;
    }

    for (let key in repeatedTermsCounter) {
        if (allTermsCounter[key] === dataLength) {
            termsToDisplay.push(key.toLowerCase());
        }
    }

    // sort terms by their original order so they show in correct order in UI:
    const sortedTermsToDisplay = termsToDisplay.sort(
        (a, b) => allTerms.indexOf(a) - allTerms.indexOf(b)
    );

    return sortedTermsToDisplay;
};

// reusable helper to remove duplicate errors that appear because of batch editing;
// when batch editing, errors will show the same amount of times per how many photos are being edited
// ex: if 4 photos are being edited and a duplicate error shows, the duplicate error will show once per photo id,
// so duplicate error shows 4x since 4 photos are being edited;
// this reduces it so the tag only shows 1x in the UI as an error:
const removeBatchRepeatedTerms = ({ originalTerms }) => {
    let repeatedTermsCounter = {};
    let termsToDisplay = [];

    for (let term of originalTerms) {
        repeatedTermsCounter[term] = repeatedTermsCounter[term]
            ? repeatedTermsCounter[term] + 1
            : 1;
    }

    for (let key in repeatedTermsCounter) {
        termsToDisplay.push(key);
    }

    return termsToDisplay;
};

export const handleAddTagErrors = ({
    tagsNotAdded,
    tagsAdded,
    setInputError,
    setSuggestedTags
}) => {
    // grammatically reflect tags in sentence phrasing:
    const handleTermsSentenceCase = ({ allTags }) => {
        return allTags.map((tag, index) => {
            const isLastTag = index === allTags.length - 1;

            return isLastTag ? ` and "${tag}"` : ` "${tag}"`;
        });
    };

    // removes duplicates in the case that the error shows up on some photos but not all, etc:
    const getErrorTags = ({ reason }) => {
        const duplicatesRemoved = [];
        const trueDuplicateTags = [];

        tagsNotAdded.filter(tagNotAdded => {
            if (tagNotAdded.reason === reason) {
                duplicatesRemoved.push(tagNotAdded.tag.toLowerCase());
            }
        });

        const allTagsNotAdded = removeBatchRepeatedTerms({
            originalTerms: duplicatesRemoved
        });

        const allTagsAdded = removeBatchRepeatedTerms({
            originalTerms: tagsAdded.map(item => {
                return item.tag;
            })
        });

        allTagsNotAdded.filter(tagNotAdded => {
            allTagsAdded.indexOf(tagNotAdded) === -1 &&
                trueDuplicateTags.push(tagNotAdded);
        });

        return trueDuplicateTags;
    };

    //flags to detect each kind of error:
    const hasDuplicateErrors = getErrorTags({
        reason: ADD_TAGS_ERRORS.DUPLICATE
    });

    const hasTagTooLongErrors = getErrorTags({
        reason: ADD_TAGS_ERRORS.TAG_TOO_LONG
    });

    const hasMaxTagsErrors = getErrorTags({
        reason: ADD_TAGS_ERRORS.TOO_MANY_TAGS
    });

    const hasDisallowedTerms = getErrorTags({
        reason: ADD_TAGS_ERRORS.DISALLOWED
    });

    const hasInvalidTerms = getErrorTags({
        reason: ADD_TAGS_ERRORS.INVALID
    });

    // Clear out dropdown so error is visible
    setSuggestedTags([]);

    //conditions to show/throw errors:
    if (hasDuplicateErrors.length > 0) {
        const handleDupeTermsMessage = () => {
            const dupesArrayLength = hasDuplicateErrors.length;

            const allTags = handleTermsSentenceCase({
                allTags: hasDuplicateErrors
            });

            switch (dupesArrayLength) {
                case 1:
                    return `The term "${hasDuplicateErrors[0]}" already exists in the list. Please try a different term.`;
                case 2:
                    return `The terms "${hasDuplicateErrors[0]}" and "${hasDuplicateErrors[1]}" already exist in the list. Please try different terms.`;
                default:
                    return `The terms ${allTags} already exist in the list. Please try different terms.`;
            }
        };

        setInputError(prevErrors => [
            ...prevErrors,
            {
                reason: ADD_TAGS_ERRORS.DUPLICATE,
                tags: hasDuplicateErrors,
                message: handleDupeTermsMessage()
            }
        ]);
    }

    if (hasTagTooLongErrors.length > 0) {
        const handleMaxCharsMessage = () => {
            return hasTagTooLongErrors.map((tag, index) => {
                const isLastTag = index === hasTagTooLongErrors.length - 1;

                const charLimit = 10;
                const tagFormatted = `"${tag.substring(0, charLimit)}..."`;

                return isLastTag ? tagFormatted : ` ${tagFormatted}`;
            });
        };

        setInputError(prevErrors => [
            ...prevErrors,
            {
                reason: ADD_TAGS_ERRORS.TAG_TOO_LONG,
                tags: hasTagTooLongErrors,
                message: `Removed tags over ${maxCharsAllowed} characters: ${handleMaxCharsMessage()}`
            }
        ]);
    }

    if (hasMaxTagsErrors.length > 0) {
        setInputError([
            {
                reason: ADD_TAGS_ERRORS.TOO_MANY_TAGS,
                tags: hasMaxTagsErrors,
                message: maxTagsError
            }
        ]);
    }

    if (hasDisallowedTerms.length > 0) {
        setInputError(prevErrors => [
            ...prevErrors,
            {
                reason: ADD_TAGS_ERRORS.DISALLOWED,
                tags: hasDisallowedTerms,
                message: tagDisallowedError
            }
        ]);
    }
    if (hasInvalidTerms.length > 0) {
        setInputError(prevErrors => [
            ...prevErrors,
            {
                reason: ADD_TAGS_ERRORS.INVALID,
                tags: hasInvalidTerms,
                message: tagInvalidError
            }
        ]);
    }
};

export const handleAddTagCompleted = ({
    data,
    hasMaxTags,
    setInputError,
    setSuggestedTags
}) => {
    const tagsAdded = data.addTagsToPhotoUploads.tagsAdded;
    const tagsNotAdded = data.addTagsToPhotoUploads.tagsNotAdded;

    if (tagsNotAdded && tagsNotAdded.length > 0) {
        handleAddTagErrors({
            tagsNotAdded,
            tagsAdded,
            setInputError,
            setSuggestedTags
        });
    } else if (!tagsNotAdded && !hasMaxTags) {
        setInputError(TAGS_INIT_STATES.ERRORS);
    }
};

export const convertLicenseBackendValue = license => {
    if (license === PUBLIC_DOMAIN_BE_VALUE) {
        return PUBLIC_DOMAIN;
    }
    return CREATIVE_COMMONS;
};

// When batch editing many photos with 1 edit modal
// we could have many diff values. This simplifies the values
// to determine if they are all the same or not
export const normalizeValues = valuesArray => {
    const temp = [...new Set(valuesArray)];
    if (temp.length === 1) {
        return temp[0];
    }
    return false;
};

export const normalizeLicense = valuesArray => {
    const res = normalizeValues(valuesArray);
    if (res) {
        return convertLicenseBackendValue(res);
    }
    return "Multiple";
};

export const normalizeLocation = locationsArr => {
    const res = normalizeValues(locationsArr);
    if (res) {
        return res;
    } else {
        return "";
    }
};
