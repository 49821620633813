import React from "react";
import PropTypes from "prop-types";

// styles
import { FlagListStyled } from "./styles";

// components
import Flag from "./Flag";

const FlagList = ({ upload, flags, onFlagClear, isDisabled = false }) => {
    return (
        <FlagListStyled>
            {flags.map(flag => {
                return (
                    <Flag
                        key={flag.id}
                        flag={flag}
                        onFlagClear={onFlagClear}
                        upload={upload}
                        isDisabled={isDisabled}
                    />
                );
            })}
        </FlagListStyled>
    );
};

export default FlagList;

// prop-types
FlagList.propTypes = {
    upload: PropTypes.object.isRequired,
    flags: PropTypes.array.isRequired,
    onFlagClear: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool
};
