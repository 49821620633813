import React from "react";
import PropTypes from "prop-types";

import SearchAndBrowseIconGridContainer from "icons/SearchAndBrowseIconGridContainer";
import SegmentedControlResults from "core/SegmentedControlResults";
import SearchEmptyResults from "core/SearchEmptyResults";

import { useBrowsePageContext } from "core/BrowsePage/context";

import { TYPES } from "core/BrowsePage/constants";
import { CONTENT_TYPE } from "core/constants";

const BrowseIconsByTagResults = ({ term }) => {
    const { icons, activeType } = useBrowsePageContext();

    const {
        firstBatchFetched,
        loadFirstBatchLazy,
        totalCount,
        loadingFirstBatch
    } = icons;

    return (
        <SegmentedControlResults
            firstBatchFetched={firstBatchFetched}
            loadFirstBatchLazy={loadFirstBatchLazy}
            isActive={activeType === TYPES.ICONS}
        >
            {!!firstBatchFetched && !loadingFirstBatch && !totalCount ? (
                <SearchEmptyResults
                    contentType={CONTENT_TYPE.icon}
                    query={term}
                />
            ) : (
                <SearchAndBrowseIconGridContainer
                    {...icons}
                    iconData={icons.items}
                    LoadMoreButton={icons.LoadMore}
                />
            )}
        </SegmentedControlResults>
    );
};

BrowseIconsByTagResults.propTypes = {
    term: PropTypes.string.isRequired
};

export default BrowseIconsByTagResults;
