import breakpoints from "core/breakpoints";
import { useViewport } from "js/hooks/useViewport";

/**
 * Return text based on viewport width. For server-side rendering, defaults to desktop value.
 *
 * @param {object} textValues - object containing text for each device size
 * @param {string} textValues.desktop - text for desktop
 * @param {string} textValues.tablet - text for tablet
 * @param {string} textValues.mobile - text for mobile
 * @returns string
 */
export const useViewportText = textValues => {
    const { browserWidth } = useViewport();

    if (!textValues?.mobile) {
        console.error("useViewportText: textValues prop is required");
        return "";
    }

    if (!browserWidth) {
        return textValues.desktop;
    }

    if (browserWidth > breakpoints.beforeDesktop.px) {
        return textValues.desktop;
    } else if (browserWidth > breakpoints.beforeTablet.px) {
        return textValues.tablet;
    }

    return textValues.mobile;
};
