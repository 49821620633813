import React from "react";

import Skeleton from "core/Skeleton";

import { AdContainer } from "icons/IconGridWithAds/styles";
import {
    SkeletonSquarespaceAdContainer,
    SkeletonLingoAdContainer
} from "./styles";

import { useViewport } from "js/hooks/useViewport";
import breakpoints from "core/breakpoints";

const SkeletonGridAd = () => {
    const { browserWidth } = useViewport();
    const onMobile = browserWidth < breakpoints.tablet.px;

    const SkeletonContainer = onMobile
        ? SkeletonLingoAdContainer
        : SkeletonSquarespaceAdContainer;

    return (
        <AdContainer>
            <SkeletonContainer>
                <Skeleton width="100%" height="100%" />
            </SkeletonContainer>
        </AdContainer>
    );
};

export default SkeletonGridAd;
