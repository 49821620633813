import { SCROLL_Y_BREAKPOINTS } from "./constants";

/*---------------------------
| Animation Props
---------------------------*/
const SPEED = 300;
const SPEED_FADE = 300;
const TOP_OFFSET = 75;

// SCROLL_Y_REVERSE_CUSHION is somewhat arbitrary as the height of the header when compact is 60.
// We just do not want to wait till the 60px threshold to reverse the animation
const SCROLL_Y_REVERSE_CUSHION = 200;
const getScrollYBreakpointRange = currentPage => {
    const downBreakpoint =
        SCROLL_Y_BREAKPOINTS[currentPage] || SCROLL_Y_BREAKPOINTS.default;
    return {
        down: downBreakpoint,
        up: downBreakpoint + SCROLL_Y_REVERSE_CUSHION
    };
};

const getPageProps = currentPage => {
    const scrollYBreakpoint = getScrollYBreakpointRange(currentPage);

    let topIsAnimated;

    switch (currentPage) {
        case "home":
        case "icons":
        case "photos":
            topIsAnimated = true;
            break;
        default:
            topIsAnimated = false;
            break;
    }

    return { scrollYBreakpoint, topIsAnimated };
};

// Get Animate IN props
const getAnimateInProps = currentPage => {
    const { scrollYBreakpoint, topIsAnimated } = getPageProps(currentPage);

    return {
        sequence: "in",
        scrollYBreakpoint,

        // Vertical :: when !topIsAnimated we exclude
        top: { value: 0, speed: topIsAnimated ? SPEED : 0, delay: 0 },

        // Overlay
        left: {
            value: 156,
            speed: SPEED,
            delay: topIsAnimated ? SPEED * 1 : 0
        },
        right: {
            value: 205,
            speed: SPEED,
            delay: topIsAnimated ? SPEED * 1 : 0
        },

        // Underlay
        opacity: {
            value: 1,
            speed: SPEED_FADE,
            delay: topIsAnimated ? SPEED * 2 : SPEED * 1
        }
    };
};

// Get Animate OUT props
// Also an alias for default props
const getAnimateOutProps = currentPage => {
    const { scrollYBreakpoint, topIsAnimated } = getPageProps(currentPage);

    return {
        sequence: "out",
        scrollYBreakpoint,

        // Vertical :: when !topIsAnimated we exclude, when topIsAnimated it gets pushed down offstage
        top: {
            value: topIsAnimated ? TOP_OFFSET : 0,
            speed: topIsAnimated ? SPEED : 0,
            delay: SPEED * 1
        },

        // Overlay
        left: { value: 0, speed: SPEED, delay: 0 },
        right: { value: 0, speed: SPEED, delay: 0 },

        // Underlay
        opacity: { value: 0, speed: SPEED_FADE, delay: 0 }
    };
};

const getAnimateProps = currentPage => {
    const animateInProps = getAnimateInProps(currentPage);
    const animateOutProps = getAnimateOutProps(currentPage);

    return {
        ...animateOutProps, // default to animateOutProps
        config: {
            animateInProps,
            animateOutProps
        }
    };
};

export default getAnimateProps;
