import React, { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router-dom";

import LoadingSpinner from "core/LoadingSpinner";

import { usePhotoUploadRedirect } from "../PhotoUploadCore/hooks";
import { usePhotoBatch } from "frontend/hooks/usePhotoUpload/usePhotoBatch";

import PhotoUploadProviders from "../PhotoUploadCore/PhotoUploadProviders";

const PhotoUploadViewUnwrapped = () => {
    const [redirect, setRedirect] = useState(null);
    const { batch, shoot, isBatchLoaded, isShootLoaded } = usePhotoBatch();

    const location = useLocation();
    const {
        RedirectToTypeSelectPage,
        RedirectToIndividualUploadPage,
        RedirectToPhotoShootUploadPage
    } = usePhotoUploadRedirect(location);

    useEffect(() => {
        if (!isBatchLoaded || !isShootLoaded) {
            return;
        }

        if (!batch) {
            return setRedirect(<RedirectToTypeSelectPage />);
        } else if (batch.id && !shoot) {
            return setRedirect(<RedirectToIndividualUploadPage />);
        } else if (batch.id && shoot && shoot.id) {
            return setRedirect(<RedirectToPhotoShootUploadPage />);
        }

        return setRedirect(<RedirectToTypeSelectPage />);
    }, [
        isBatchLoaded,
        isShootLoaded,
        batch && batch.id,
        shoot && shoot.id,
        setRedirect
    ]);

    // wait to load upload batches
    if (!redirect) return <LoadingSpinner />;

    return redirect;
};

const PhotoUploadView = () => (
    <PhotoUploadProviders>
        <PhotoUploadViewUnwrapped />
    </PhotoUploadProviders>
);

export default PhotoUploadView;
