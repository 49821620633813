import React from "react";
import PropTypes from "prop-types";

import Processing from "core/ReusableIcons/Processing";

import colors from "core/colors";
import { Div } from "./styles";

import { reusableIconSizes } from "core/props";

const LoadingSpinner = ({
    loadingColor,
    hideText = false,
    iconSize = "lg"
}) => (
    <Div data-testid="loading-component" loadingColor={loadingColor}>
        {!hideText && <p>Loading</p>}
        <Processing
            size={iconSize}
            fillColor={
                loadingColor ? loadingColor : colors.accent.lightMode.pansy
            }
        />
    </Div>
);

LoadingSpinner.propTypes = {
    loadingColor: PropTypes.string,
    hideText: PropTypes.bool,
    iconSize: PropTypes.oneOf(reusableIconSizes)
};

export default LoadingSpinner;
