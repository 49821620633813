import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";

import { REDUCERS } from "../../constants";

// helpers
import { addModerationNote } from "frontend/components/fort/mod/photos/helpers.js";

// see frontend/components/fort/mod/photos/slices/ReduxRTKReadme.md #createEntityAdapter
export const batchesAdapter = createEntityAdapter({
    // Keep the "all IDs" array sorted based on paginationItemNumber which is the order the BE delievers
    sortComparer: (a, b) => a.paginationItemNumber - b.paginationItemNumber
});

export const batchesSlice = createSlice({
    name: REDUCERS.BATCHES,
    initialState: batchesAdapter.getInitialState({
        hasLoaded: false, // controls first load of batches single or all page(s)

        // createEntityAdaptor state props
        ids: [], // ids.length === total batches fetched from server in local population
        entities: {},

        // general
        showLoading: true, // show or hide batch loading skeletons
        apiStatus: "idle",

        // pagination
        pagination: {
            batchesPerPage: 10,
            totalCount: 0, // total potential batches on server
            isLastPage: false,
            isSecondToLastPage: false,
            remainder: 0 // calculates remainder batches when not evenly divided by batchesPerPage
        }
    }),
    reducers: {
        // Adding all batchesAdapter Case Reducers into batchesSlice.actions - see frontend/components/fort/mod/photos/slices/ReduxRTKReadme.md
        ...batchesAdapter,

        // Homeade State Actions
        setApiStatusToCalling: state => {
            state.apiStatus = "calling-api";
        },
        setApiStatusToError: state => {
            state.apiStatus = "error";
        },
        setBatches: (state, action) => {
            batchesAdapter.setAll(state, action.payload);
            state.apiStatus = "ready-to-request";
            state.showLoading = false;
        },
        setModToBatch: (state, action) => {
            state.apiStatus = "ready-to-request";
            batchesAdapter.upsertOne(state, action.payload.batch);
        },
        setHasLoaded: (state, action) => {
            state.hasLoaded = action.payload;
        },
        setShowLoading: (state, action) => {
            state.showLoading = action.payload;
        },
        setPagination: (state, action) => {
            const { currentPage, newPagination } = action.payload;
            state.pagination = calculatePagination(
                state.pagination,
                currentPage,
                newPagination
            );
        },
        addModerationNote: (state, action) => {
            const note = action.payload.note;
            const batchId = note.photoBatchId;
            const batchEntity = state.entities[batchId];

            // only add if batch entity already exists
            if (batchEntity) {
                const moderationNotes = addModerationNote(batchEntity, note);
                batchesAdapter.updateOne(state, {
                    id: batchId,
                    changes: { moderationNotes }
                });
            }
        }
    }
});

export default batchesSlice.reducer;

/**
 * calculatePagination evaluate pagination object in state
 * @param {object} prevPagination whole pagination object
 * @param {object} newPagination partial pagination object, only those properties that are changing
 * @returns {object} finalPagination whole pagination object
 */
const calculatePagination = (prevPagination, currentPage, newPagination) => {
    const currentPageNumber = Number(currentPage);
    let finalPagination = { ...prevPagination, ...newPagination }; // merge with partial pagination object provided in newPagination
    const { batchesPerPage, totalCount } = finalPagination;

    // Evaluate isLastPage and isSecondToLastPage
    const numPages =
        totalCount > batchesPerPage
            ? Math.ceil(totalCount / batchesPerPage)
            : 1;

    // isLastPage
    const lastPage = numPages;
    finalPagination.isLastPage = lastPage === currentPageNumber;

    // isSecondToLastPage
    const secondToLastPage = lastPage - 1;
    finalPagination.isSecondToLastPage = secondToLastPage === currentPageNumber;

    // Evaluate remainder: This should only ever change on first page load, once BE gives us totalCount
    if (totalCount && totalCount !== prevPagination.totalCount) {
        // get remainder of batches on last page, if less than batchesPerPage. Otherwise, should be batchesPerPage
        const remainder = totalCount % batchesPerPage;
        finalPagination.remainder = remainder > 0 ? remainder : batchesPerPage;
    }

    return finalPagination;
};
