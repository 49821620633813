import gql from "graphql-tag";

export const GET_CURRENT_USER_ACTIVE_SUBSCRIPTION = gql`
    query currentUser {
        currentUser {
            id
            username
            hasSubscription
            hasCreatorProSubscription
            activeSubscription {
                stripeId
                paypalId
                plan {
                    paypalId
                    stripeId
                    stripeInternalId
                    name
                    amount
                    currency
                    interval
                    paypalStatus
                }
                status
                cancelAtPeriodEnd
                quantity
                created
                start
                paypalNextBillingTime
                paypalLastBillingTime
                canceledAt
                currentPeriodStart
                currentPeriodEnd
            }
        }
    }
`;
