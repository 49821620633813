import React, { useState } from "react";
import PropTypes from "prop-types";

import Modal from "core/Modal";
import Button from "core/Button";
import FormInput from "core/FormInput";

import { NameModalCancelButton } from "./styles";
import { ModalChildrenContainer } from "../PhotoUpload/styles";
import { detectEmoji } from "frontend/components/helpers";

import { getBeToFeErrMsgRemap } from "frontend/components/creator-tools/PhotoUploadPage/helpers.js";
import { errMsgMappings } from "./constants";

const EditShootNameModal = ({
    defaultShootName,
    open,
    onClose,
    updateShootName
}) => {
    const [hasFinalShootName, updateFinalShootName] = useState(
        defaultShootName
    );
    const [errorMessage, setErrorMessage] = useState("");

    const heading = "Name This Photo Shoot";
    const emojiErrorMessage =
        "Shoot name not saved because it contains invalid characters.";

    const handleInputChange = value => {
        updateFinalShootName(value);
    };

    const handleClose = () => {
        onClose();
    };

    const handleSave = e => {
        e.preventDefault();

        const hasEmoji = detectEmoji(hasFinalShootName);

        if (hasEmoji) {
            setErrorMessage(emojiErrorMessage);
            return;
        }

        updateShootName(hasFinalShootName).then(response => {
            const {
                data: {
                    editPhotoShoot: { ok, errors: beErrMsg }
                }
            } = response;

            const customMessage = getBeToFeErrMsgRemap(
                beErrMsg,
                errMsgMappings.shootName
            );

            if (ok) {
                handleClose();
            } else if (customMessage) {
                setErrorMessage(customMessage.fe);
            } else {
                setErrorMessage(beErrMsg);
            }
        });
    };

    const onBlur = () => {
        setErrorMessage("");
    };

    const onKeyDown = e => {
        // enter key
        if (e.keyCode === 13) {
            handleSave(e);
        }
    };

    const footerChildren = (
        <>
            <Button onClick={e => handleSave(e)}>Save</Button>

            <NameModalCancelButton onClick={handleClose}>
                Cancel
            </NameModalCancelButton>
        </>
    );

    return (
        <Modal
            open={open}
            onClose={onClose}
            handleClose={onClose}
            heading={heading}
        >
            <ModalChildrenContainer>
                <FormInput
                    maxLength={145}
                    placeholderText={"Add name"}
                    labelText={heading}
                    value={hasFinalShootName}
                    onChange={value => handleInputChange(value)}
                    errorMessageOverride={errorMessage}
                    onBlur={onBlur}
                    onKeyDown={onKeyDown}
                />

                {footerChildren}
            </ModalChildrenContainer>
        </Modal>
    );
};

EditShootNameModal.propTypes = {
    defaultShootName: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    updateShootName: PropTypes.func.isRequired
};

export default EditShootNameModal;
