import React, { useState } from "react";
import PropTypes from "prop-types";

// styles
import { ReleasesStyled, ButtonStyled } from "./styles";

// components
import Template from "frontend/components/fort/mod/core/Batch/ContentComponents/Template.js";
import ReleasesDialog from "./ReleasesDialog/index";

const Releases = ({ batch }) => {
    const { id: batchId, releaseCount, isEditable } = batch;

    const [isDialogOpen, setIsDialogOpen] = useState(false);

    // Conditional Button text
    let buttonText = "No releases";
    if (releaseCount > 0) {
        buttonText =
            releaseCount > 1
                ? `View ${releaseCount} releases`
                : `View ${releaseCount} release`;
    }

    const onClick = e => {
        if (isEditable.releases) {
            e.stopPropagation();
            setIsDialogOpen(true);
        }
    };

    return (
        <Template title="Attachments" width={114}>
            <ReleasesStyled>
                <ButtonStyled
                    data-testid="batch-header-release-button"
                    styleType="none"
                    onClick={onClick}
                    isDisabled={!isEditable.releases}
                >
                    {buttonText}
                </ButtonStyled>
                <ReleasesDialog
                    setIsDialogOpen={setIsDialogOpen}
                    isDialogOpen={isDialogOpen}
                    batchId={batchId}
                />
            </ReleasesStyled>
        </Template>
    );
};

export default Releases;

// prop-types
Releases.propTypes = {
    batch: PropTypes.object.isRequired
};
