import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Link from "core/Link";
import BodyText from "core/BodyText";
import Modal from "core/Modal";
import LoadingSpinner from "core/LoadingSpinner";

import {
    PhotoRow,
    TitleAndAmount,
    TopHeading,
    Image,
    Container
} from "./styles";

const EmptyContainer = styled.div`
    font-size: 1rem;
    text-align: center;
    padding: 2rem 0;
`;

const ModalInfo = ({ data }) => {
    if (data.length === 0) return <div>No data available.</div>;

    return data.map((photoDetail, index) => {
        const { assets, title, count, url } = photoDetail;
        const { squareSmall } = assets;

        return (
            <PhotoRow key={index}>
                <Image src={squareSmall} alt={title} />
                <TitleAndAmount>
                    <Link href={url} target="_blank" size="lg" type="secondary">
                        {title}
                    </Link>
                    <BodyText size="lg" bold>
                        {count}
                    </BodyText>
                </TitleAndAmount>
            </PhotoRow>
        );
    });
};

const InsightsModal = ({
    isModalOpen,
    modalData,
    handleClose,
    insightLoading
}) => {
    if (!isModalOpen) return null;
    let emptyData = false;

    let formatModalData = [
        { header: "Top Downloads", data: [] },
        { header: "Top Purchases", data: [] }
    ];
    if (modalData && modalData.photoRoyaltyInsights) {
        const { topDownloads, topPurchases } = modalData.photoRoyaltyInsights;
        formatModalData[0].data = topDownloads.items;
        formatModalData[1].data = topPurchases.items;

        if (
            topDownloads.items.length === 0 &&
            topPurchases.items.length === 0
        ) {
            emptyData = true;
        }
    }

    return (
        <Modal
            open={isModalOpen}
            handleClose={handleClose}
            type="video"
            closeOnEscape
            closeOnBackDropClick
        >
            {insightLoading ? (
                <LoadingSpinner />
            ) : emptyData ? (
                <EmptyContainer>
                    No insights available for this month.
                </EmptyContainer>
            ) : (
                formatModalData.map((topStats, idx) => {
                    const { header, data } = topStats;

                    if (data.length === 0) return null;

                    return (
                        <Container key={idx}>
                            <TopHeading>{header}</TopHeading>
                            <ModalInfo data={data} />
                        </Container>
                    );
                })
            )}
        </Modal>
    );
};

InsightsModal.propTypes = {
    isModalOpen: PropTypes.bool.isRequired,
    modalData: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    handleClose: PropTypes.func.isRequired
};

export default InsightsModal;
