import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// context
import { useManageTags } from "core/ManageTags/context/useManageTags.js";

// styles
import { convertPxToRem } from "frontend/components/helpers";
import colors from "core/colors";
import { regular as regularFontWeight } from "core/fontStyles";
const TagWithApprovalStyled = styled.div`
    ${regularFontWeight}
    display: flex;
    align-items: center;
    margin-bottom: ${convertPxToRem(8)};
`;
import { TagWrapper } from "frontend/components/core/Tag/styles.js";
const TagStyled = styled.div`
    flex: 1;

    ${TagWrapper} {
        margin-bottom: 0px;
    }
`;
const CheckboxStyled = styled.div`
    flex: 0 0 ${convertPxToRem(22)};
`;

// components
import Tag from "../Tag";
import Checkbox from "core/Checkbox";

// component
export const TagWithApproval = ({ tag }) => {
    const useManageTagsContext = useManageTags();

    const {
        id,
        actions,
        dispatch,
        state: { enabled }
    } = useManageTagsContext;
    const { isApproved } = tag;

    // toggle approval
    const handleOnApprove = () => {
        if (isApproved) {
            dispatch(actions.onRejectTag(useManageTagsContext, tag));
        } else {
            dispatch(actions.onApproveTag(useManageTagsContext, tag));
        }
    };

    let dataTestId = `tag-${id}-${tag.id}`;
    dataTestId = isApproved
        ? `${dataTestId}-approved`
        : `${dataTestId}-pending`;

    return (
        <TagWithApprovalStyled data-testid={dataTestId}>
            <CheckboxStyled>
                <Checkbox
                    onClick={handleOnApprove}
                    isChecked={isApproved}
                    isDisabled={!enabled.approval}
                    checkboxStyles={{
                        default: colors.primary.snow,
                        checked: colors.alternative.lightMode.shamrock,
                        disabled: colors.secondary.dolphin
                    }}
                />
            </CheckboxStyled>
            <TagStyled>
                <Tag tag={tag} />
            </TagStyled>
        </TagWithApprovalStyled>
    );
};

export default TagWithApproval;

// prop-types
TagWithApproval.propTypes = {
    tag: PropTypes.object.isRequired
};
