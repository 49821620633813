import React from "react";
import PropTypes from "prop-types";

// styles
import { ButtonLooksLikeLinkStyled } from "./styles.js";

const ButtonLooksLikeLink = props => {
    const { children, value, onClick } = props;

    const name = value;

    return (
        <ButtonLooksLikeLinkStyled
            type="button"
            name={name}
            {...props}
            value={value}
            onClick={onClick}
        >
            {children}
        </ButtonLooksLikeLinkStyled>
    );
};

export default ButtonLooksLikeLink;

// prop-types
ButtonLooksLikeLink.propTypes = {
    onClick: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    children: PropTypes.any
};
