/* Helper to send page views to both Google Analytics */
export const sendPageView = (path, fieldsObject = {}) => {
    const { title } = fieldsObject;
    const pageTitle = title || document.title;

    // Send to Google Analytics
    if (typeof window.ga === "function") {
        ga("send", "pageview", path, pageTitle);
    }
};

/* Helper to send events to both Google Analytics */
export const sendEvent = (category, action, label, value) => {
    // Send to Google Analytics
    if (typeof window.ga === "function") {
        ga("send", "event", category, action, label, value);
    }
};

/* Helper to send events to both Google Analytics*/
export const setAccountType = accountType => {
    // Send to Google Analytics
    if (typeof window.ga === "function") {
        ga("set", "dimension1", accountType);
    }
};
