// Photos
export const SAVE_SEARCH_SCROLL_POSITION = "SAVE_SEARCH_SCROLL_POSITION";

// Show/hide modals

export const SHOW_MODAL = "SHOW_MODAL";
export const DISMISS_MODAL = "DISMISS_MODAL";

// search
export const SAVE_QUERY_INFO = "SAVE_QUERY_INFO";
export const SET_SEARCH_QUERY = "SET_SEARCH_QUERY";
export const SEARCH_REGISTER_RESULTS = "SEARCH_REGISTER_RESULTS";

export const FETCH_AUTOCOMPLETE_TERMS = "FETCH_AUTOCOMPLETE_TERMS";
export const FETCH_AUTOCOMPLETE_TERMS_SUCCESS =
    "FETCH_AUTOCOMPLETE_TERMS_SUCCESS";
export const FETCH_AUTOCOMPLETE_TERMS_FAILURE =
    "FETCH_AUTOCOMPLETE_TERMS_FAILURE";

// collections
export const FETCH_FEATURED_COLLECTIONS_METADATA =
    "FETCH_FEATURED_COLLECTIONS_METADATA";
export const FETCH_FEATURED_COLLECTIONS_METADATA_SUCCESS =
    "FETCH_FEATURED_COLLECTIONS_METADATA_SUCCESS";
export const FETCH_FEATURED_COLLECTIONS_METADATA_FAILURE =
    "FETCH_FEATURED_COLLECTIONS_METADATA_FAILURE";

export const FETCH_FORT_COLLECTION = "FETCH_FORT_COLLECTION";
export const FETCH_FORT_COLLECTION_SUCCESS = "FETCH_FORT_COLLECTION_SUCCESS";
export const FETCH_FORT_COLLECTION_FAILURE = "FETCH_FORT_COLLECTION_FAILURE";

export const FORT_REFRESH_MEMBER_SUBSCRIPTION =
    "FORT_REFRESH_MEMBER_SUBSCRIPTION";
export const FORT_REFRESH_MEMBER_SUBSCRIPTION_SUCCESS =
    "FORT_REFRESH_MEMBER_SUBSCRIPTION_SUCCESS";
export const FORT_REFRESH_MEMBER_SUBSCRIPTION_FAILURE =
    "FORT_REFRESH_MEMBER_SUBSCRIPTION_FAILURE";

export const PREVIEW_COLLECTION_ICONS = "PREVIEW_COLLECTION_ICONS";
export const PREVIEW_COLLECTION_ICONS_SUCCESS =
    "PREVIEW_COLLECTION_ICONS_SUCCESS";
export const PREVIEW_COLLECTION_ICONS_FAILURE =
    "PREVIEW_COLLECTION_ICONS_FAILURE";

export const SAVE_COLLECTION = "SAVE_COLLECTION";
export const SAVE_COLLECTION_SUCCESS = "SAVE_COLLECTION_SUCCESS";
export const SAVE_COLLECTION_FAILURE = "SAVE_COLLECTION_FAILURE";

export const DISABLE_COLLECTION = "DISABLE_COLLECTION";
export const DISABLE_COLLECTION_SUCCESS = "DISABLE_COLLECTION_SUCCESS";
export const DISABLE_COLLECTION_FAILURE = "DISABLE_COLLECTION_FAILURE";

// users
export const LOGOUT_USER = "LOGOUT_USER";

export const GET_CURRENT_USER = "GET_CURRENT_USER";
export const GET_CURRENT_USER_SUCCESS = "GET_CURRENT_USER_SUCCESS";
export const GET_CURRENT_USER_FAILURE = "GET_CURRENT_USER_FAILURE";

export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const REQUEST_CODE = "REQUEST_CODE";
export const REQUEST_CODE_SUCCESS = "REQUEST_CODE_SUCCESS";
export const REQUEST_CODE_FAILURE = "REQUEST_CODE_FAILURE";

export const CONFIRM_CODE = "CONFIRM_CODE";
export const CONFIRM_CODE_SUCCESS = "CONFIRM_CODE_SUCCESS";
export const CONFIRM_CODE_FAILURE = "CONFIRM_CODE_FAILURE";

export const CLEAR_LOGIN_CODE = "CLEAR_LOGIN_CODE";
export const CLEAR_LOGIN_CODE_SUCCESS = "CLEAR_LOGIN_CODE_SUCCESS";
export const CLEAR_LOGIN_CODE_FAILURE = "CLEAR_LOGIN_CODE_FAILURE";

export const SIGNUP = "SIGNUP";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILURE = "SIGNUP_FAILURE";

export const CREATE_STRIPE_TOKEN = "CREATE_STRIPE_TOKEN";
export const CREATE_STRIPE_TOKEN_SUCCESS = "CREATE_STRIPE_TOKEN_SUCCESS";
export const CREATE_STRIPE_TOKEN_FAILURE = "CREATE_STRIPE_TOKEN_FAILURE";

export const SAVE_CARD = "SAVE_CARD";
export const SAVE_CARD_SUCCESS = "SAVE_CARD_SUCCESS";
export const SAVE_CARD_FAILURE = "SAVE_CARD_FAILURE";

export const STRIPE_SAVE_BILLING_INFO = "STRIPE_SAVE_BILLING_INFO";
export const STRIPE_SAVE_BILLING_INFO_SUCCESS =
    "STRIPE_SAVE_BILLING_INFO_SUCCESS";
export const STRIPE_SAVE_BILLING_INFO_FAILURE =
    "STRIPE_SAVE_BILLING_INFO_FAILURE";

export const UPGRADE_BILLING = "UPGRADE_BILLING";
export const UPGRADE_BILLING_SUCCESS = "UPGRADE_BILLING_SUCCESS";
export const UPGRADE_BILLING_FAILURE = "UPGRADE_BILLING_FAILURE";

export const STRIPE_DELETE_CARD = "STRIPE_DELETE_CARD";
export const STRIPE_DELETE_CARD_SUCCESS = "STRIPE_DELETE_CARD_SUCCESS";
export const STRIPE_DELETE_CARD_FAILURE = "STRIPE_DELETE_CARD_FAILURE";

export const FORT_FETCH_DUPLICATE_PAYPAL_EMAILS =
    "FORT_FETCH_DUPLICATE_PAYPAL_EMAILS";
export const FORT_FETCH_DUPLICATE_PAYPAL_EMAILS_SUCCESS =
    "FORT_FETCH_DUPLICATE_PAYPAL_EMAILS_SUCCESS";
export const FORT_FETCH_DUPLICATE_PAYPAL_EMAILS_FAILURE =
    "FORT_FETCH_DUPLICATE_PAYPAL_EMAILS_FAILURE";

// creators
export const FETCH_FEATURED_CREATORS = "FETCH_FEATURED_CREATORS";
export const FETCH_FEATURED_CREATORS_SUCCESS =
    "FETCH_FEATURED_CREATORS_SUCCESS";
export const FETCH_FEATURED_CREATORS_FAILURE =
    "FETCH_FEATURED_CREATORS_FAILURE";

export const TOGGLE_FEATURE_CREATOR = "TOGGLE_FEATURE_CREATOR";
export const TOGGLE_FEATURE_CREATOR_SUCCESS = "TOGGLE_FEATURE_CREATOR_SUCCESS";
export const TOGGLE_FEATURE_CREATOR_FAILURE = "TOGGLE_FEATURE_CREATOR_FAILURE";

export const FETCH_CREATOR = "FETCH_CREATOR";
export const FETCH_CREATOR_SUCCESS = "FETCH_CREATOR_SUCCESS";
export const FETCH_CREATOR_FAILURE = "FETCH_CREATOR_FAILURE";

export const DISABLE_CREATOR = "DISABLE_CREATOR";
export const DISABLE_CREATOR_SUCCESS = "DISABLE_CREATOR_SUCCESS";
export const DISABLE_CREATOR_FAILURE = "DISABLE_CREATOR_FAILURE";

export const SUSPEND_CREATOR = "SUSPEND_CREATOR";
export const SUSPEND_CREATOR_SUCCESS = "SUSPEND_CREATOR_SUCCESS";
export const SUSPEND_CREATOR_FAILURE = "SUSPEND_CREATOR_FAILURE";

export const UNSUSPEND_CREATOR = "UNSUSPEND_CREATOR";
export const UNSUSPEND_CREATOR_SUCCESS = "UNSUSPEND_CREATOR_SUCCESS";
export const UNSUSPEND_CREATOR_FAILURE = "UNSUSPEND_CREATOR_FAILURE";

export const BAN_CREATOR = "BAN_CREATOR";
export const BAN_CREATOR_SUCCESS = "BAN_CREATOR_SUCCESS";
export const BAN_CREATOR_FAILURE = "BAN_CREATOR_FAILURE";

export const CREATOR_NOTE_CHANGE = "CREATOR_NOTE_CHANGE";
// Icons

export const FETCH_HERO_ICON = "FETCH_HERO_ICON";
export const FETCH_HERO_ICON_SUCCESS = "FETCH_HERO_ICON_SUCCESS";
export const FETCH_HERO_ICON_FAILURE = "FETCH_HERO_ICON_FAILURE";

export const FETCH_BEST_COLLECTIONS = "FETCH_BEST_COLLECTIONS";
export const FETCH_BEST_COLLECTIONS_SUCCESS = "FETCH_BEST_COLLECTIONS_SUCCESS";
export const FETCH_BEST_COLLECTIONS_FAILURE = "FETCH_BEST_COLLECTIONS_FAILURE";

export const FETCH_VIEW_MORE_ICONS = "FETCH_VIEW_MORE_ICONS";
export const FETCH_VIEW_MORE_ICONS_SUCCESS = "FETCH_VIEW_MORE_ICONS_SUCCESS";
export const FETCH_VIEW_MORE_ICONS_FAILURE = "FETCH_VIEW_MORE_ICONS_FAILURE";

export const FETCH_TOTAL_ICON_COUNT = "FETCH_TOTAL_ICON_COUNT";
export const FETCH_TOTAL_ICON_COUNT_SUCCESS = "FETCH_TOTAL_ICON_COUNT_SUCCESS";
export const FETCH_TOTAL_ICON_COUNT_FAILURE = "FETCH_TOTAL_ICON_COUNT_FAILURE";

export const FETCH_ICONS = "FETCH_ICONS";
export const FETCH_ICONS_SUCCESS = "FETCH_ICONS_SUCCESS";
export const FETCH_ICONS_FAILURE = "FETCH_ICONS_FAILURE";

export const FETCH_SVG_DATA = "FETCH_SVG_DATA";
export const FETCH_SVG_DATA_SUCCESS = "FETCH_SVG_DATA_SUCCESS";
export const FETCH_SVG_DATA_FAILURE = "FETCH_SVG_DATA_FAILURE";

export const PLACE_SVG_FROM_ICONLIST = "PLACE_SVG_FROM_ICONLIST";
export const PLACE_SVG_FROM_ICONLIST_SUCCESS =
    "PLACE_SVG_FROM_ICONLIST_SUCCESS";
export const PLACE_SVG_FROM_ICONLIST_FAILURE =
    "PLACE_SVG_FROM_ICONLIST_FAILURE";

export const SAVE_CLEANED_SVG = "SAVE_CLEANED_SVG";

export const FETCH_ICON = "FETCH_ICON";
export const FETCH_ICON_SUCCESS = "FETCH_ICON_SUCCESS";
export const FETCH_ICON_FAILURE = "FETCH_ICON_FAILURE";

export const SET_ACTIVE_ICON = "SET_ACTIVE_ICON";
export const CLEAR_ACTIVE_ICON = "CLEAR_ACTIVE_ICON";

export const SET_ICON_COLOR_PALETTE = "SET_ICON_COLOR_PALETTE";

export const GET_ICON_META_DATA = "GET_ICON_META_DATA";

export const SET_ICON_SIZE = "SET_ICON_SIZE";

export const SHOW_ICON_DETAIL = "SHOW_ICON_DETAIL";
export const HIDE_ICON_DETAIL = "HIDE_ICON_DETAIL";

export const SEARCH_ICONS = "SEARCH_ICONS";
export const SEARCH_ICONS_SUCCESS = "SEARCH_ICONS_SUCCESS";
export const SEARCH_ICONS_FAILURE = "SEARCH_ICONS_FAILURE";

export const SEARCH_RESET_ICONS = "SEARCH_RESET_ICONS";

export const FETCH_FEATURED_COLLECTIONS = "FETCH_FEATURED_COLLECTIONS";
export const FETCH_FEATURED_COLLECTIONS_SUCCESS =
    "FETCH_FEATURED_COLLECTIONS_SUCCESS";
export const FETCH_FEATURED_COLLECTIONS_FAILURE =
    "FETCH_FEATURED_COLLECTIONS_FAILURE";

export const FETCH_FORT_ICON = "FETCH_FORT_ICON";
export const FETCH_FORT_ICON_SUCCESS = "FETCH_FORT_ICON_SUCCESS";
export const FETCH_FORT_ICON_FAILURE = "FETCH_FORT_ICON_FAILURE";

export const FORT_FETCH_MODERATORS = "FORT_FETCH_MODERATORS";
export const FORT_FETCH_MODERATORS_SUCCESS = "FORT_FETCH_MODERATORS_SUCCESS";
export const FORT_FETCH_MODERATORS_FAILURE = "FORT_FETCH_MODERATORS_FAILURE";
export const FORT_SELECT_MODERATOR = "FORT_SELECT_MODERATOR";

export const REGENERATE_ICON_INFO = "REGENERATE_ICON_INFO";
export const REGENERATE_ICON_INFO_SUCCESS = "REGENERATE_ICON_INFO_SUCCESS";
export const REGENERATE_ICON_INFO_FAILURE = "REGENERATE_ICON_INFO_FAILURE";

export const DISABLE_ICON = "DISABLE_ICON";
export const DISABLE_ICON_SUCCESS = "DISABLE_ICON_SUCCESS";
export const DISABLE_ICON_FAILURE = "DISABLE_ICON_FAILURE";

export const FEATURE_ICON = "FEATURE_ICON";
export const FEATURE_ICON_SUCCESS = "FEATURE_ICON_SUCCESS";
export const FEATURE_ICON_FAILURE = "FEATURE_ICON_FAILURE";

export const UNFEATURE_ICON = "UNFEATURE_ICON";
export const UNFEATURE_ICON_SUCCESS = "UNFEATURE_ICON_SUCCESS";
export const UNFEATURE_ICON_FAILURE = "UNFEATURE_ICON_FAILURE";

export const SELECT_ICON = "SELECT_ICON";
export const DESELECT_ICON = "DESELECT_ICON";
export const SELECT_ALL_ICONS = "SELECT_ALL_ICONS";
export const UNSELECT_ALL_ICONS = "UNSELECT_ALL_ICONS";

export const FEATURE_COLLECTION = "FEATURE_COLLECTION";
export const FEATURE_COLLECTION_SUCCESS = "FEATURE_COLLECTION_SUCCESS";
export const FEATURE_COLLECTION_FAILURE = "FEATURE_COLLECTION_FAILURE";

export const UNFEATURE_COLLECTION = "UNFEATURE_COLLECTION";
export const UNFEATURE_COLLECTION_SUCCESS = "UNFEATURE_COLLECTION_SUCCESS";
export const UNFEATURE_COLLECTION_FAILURE = "UNFEATURE_COLLECTION_FAILURE";

export const APPROVE_ICONS = "APPROVE_ICONS";
export const APPROVE_ICONS_SUCCESS = "APPROVE_ICONS_SUCCESS";
export const APPROVE_ICONS_FAILURE = "APPROVE_ICONS_FAILURE";

export const SAVE_ICON_INFO = "SAVE_ICON_INFO";
export const SAVE_ICON_INFO_SUCCESS = "SAVE_ICON_INFO_SUCCESS";
export const SAVE_ICON_INFO_FAILURE = "SAVE_ICON_INFO_FAILURE";

export const SAVE_ICON_STYLES = "SAVE_ICON_STYLES";
export const SAVE_ICON_STYLES_SUCCESS = "SAVE_ICON_STYLES_SUCCESS";
export const SAVE_ICON_STYLES_FAILURE = "SAVE_ICON_STYLES_FAILURE";

export const DENY_ICONS = "DENY_ICONS";
export const DENY_ICONS_SUCCESS = "DENY_ICONS_SUCCESS";
export const DENY_ICONS_BATCH = "DENY_ICONS_BATCH";
export const DENY_ICONS_BATCH_SUCCESS = "DENY_ICONS_BATCH_SUCCESS";
export const DENY_ICONS_FAILURE = "DENY_ICONS_FAILURE";

export const DENIAL_REASONS = "DENIAL_REASONS";
export const DENIAL_REASONS_SUCCESS = "DENIAL_REASONS_SUCCESS";
export const DENIAL_REASONS_FAILURE = "DENIAL_REASONS_FAILURE";

export const SAVE_NAME = "SAVE_NAME";
export const SAVE_TAG = "SAVE_TAG";

export const ICONS_FETCH_PURCHASED = "ICON_FETCH_PURCHASED";
export const ICONS_FETCH_PURCHASED_SUCCESS = "ICON_FETCH_PURCHASED_SUCCESS";
export const ICONS_FETCH_PURCHASED_FAILURE = "ICON_FETCH_PURCHASED_FAILURE";

// Uploads
export const FETCH_UPLOAD_ICONS = "FETCH_UPLOAD_ICONS";
export const FETCH_UPLOAD_ICONS_SUCCESS = "FETCH_UPLOAD_ICONS_SUCCESS";
export const FETCH_UPLOAD_ICONS_FAILURE = "FETCH_UPLOAD_ICONS_FAILURE";

export const PREVIEW_UPLOAD_ICONS = "PREVIEW_UPLOAD_ICONS";
export const PREVIEW_UPLOAD_ICONS_SUCCESS = "PREVIEW_UPLOAD_ICONS_SUCCESS";
export const PREVIEW_UPLOAD_ICONS_FAILURE = "PREVIEW_UPLOAD_ICONS_FAILURE";

export const FETCH_COMPLETED_ICONS = "FETCH_COMPLETED_ICONS";
export const FETCH_COMPLETED_ICONS_SUCCESS = "FETCH_COMPLETED_ICONS_SUCCESS";
export const FETCH_COMPLETED_ICONS_FAILURE = "FETCH_COMPLETED_ICONS_FAILURE";

export const FETCH_NEW_UPLOADS = "FETCH_NEW_UPLOADS";
export const FETCH_NEW_UPLOADS_SUCCESS = "FETCH_NEW_UPLOADS_SUCCESS";
export const FETCH_NEW_UPLOADS_FAILURE = "FETCH_NEW_UPLOADS_FAILURE";

export const FETCH_SAVED_UPLOADS = "FETCH_SAVED_UPLOADS";
export const FETCH_SAVED_UPLOADS_SUCCESS = "FETCH_SAVED_UPLOADS_SUCCESS";
export const FETCH_SAVED_UPLOADS_FAILURE = "FETCH_SAVED_UPLOADS_FAILURE";

export const FETCH_SUSPENDED_UPLOADS = "FETCH_SUSPENDED_UPLOADS";
export const FETCH_SUSPENDED_UPLOADS_SUCCESS =
    "FETCH_SUSPENDED_UPLOADS_SUCCESS";
export const FETCH_SUSPENDED_UPLOADS_FAILURE =
    "FETCH_SUSPENDED_UPLOADS_FAILURE";

export const FETCH_COMPLETED_UPLOADS = "FETCH_COMPLETED_UPLOADS";
export const FETCH_COMPLETED_UPLOADS_SUCCESS =
    "FETCH_COMPLETED_UPLOADS_SUCCESS";
export const FETCH_COMPLETED_UPLOADS_FAILURE =
    "FETCH_COMPLETED_UPLOADS_FAILURE";

export const COMPLETE_UPLOAD = "COMPLETE_UPLOAD";
export const COMPLETE_UPLOAD_SUCCESS = "COMPLETE_UPLOAD_SUCCESS";
export const COMPLETE_UPLOAD_FAILURE = "COMPLETE_UPLOAD_FAILURE";

export const SAVE_UPLOAD_FOR_LATER = "SAVE_UPLOAD_FOR_LATER";
export const SAVE_UPLOAD_FOR_LATER_SUCCESS = "SAVE_UPLOAD_FOR_LATER_SUCCESS";
export const SAVE_UPLOAD_FOR_LATER_FAILURE = "SAVE_UPLOAD_FOR_LATER_FAILURE";

export const SAVE_CREATOR_NOTE = "SAVE_CREATOR_NOTE";
export const SAVE_CREATOR_NOTE_SUCCESS = "SAVE_CREATOR_NOTE_SUCCESS";
export const SAVE_CREATOR_NOTE_FAILURE = "SAVE_CREATOR_NOTE_FAILURE";

export const REVIEW_UPLOAD = "REVIEW_UPLOAD";
export const REVIEW_UPLOAD_SUCCESS = "REVIEW_UPLOAD_SUCCESS";
export const REVIEW_UPLOAD_FAILURE = "REVIEW_UPLOAD_FAILURE";

export const SEARCH_SAVED_UPLOADS = "SEARCH_SAVED_UPLOADS";
export const SEARCH_SAVED_UPLOADS_SUCCESS = "SEARCH_SAVED_UPLOADS_SUCCESS";
export const SEARCH_SAVED_UPLOADS_FAILURE = "SEARCH_SAVED_UPLOADS_FAILURE";

export const SEARCH_SUSPENDED_UPLOADS = "SEARCH_SUSPENDED_UPLOADS";
export const SEARCH_SUSPENDED_UPLOADS_SUCCESS =
    "SEARCH_SUSPENDED_UPLOADS_SUCCESS";
export const SEARCH_SUSPENDED_UPLOADS_FAILURE =
    "SEARCH_SUSPENDED_UPLOADS_FAILURE";

export const SEARCH_COMPLETED_UPLOADS = "SEARCH_COMPLETED_UPLOADS";
export const SEARCH_COMPLETED_UPLOADS_SUCCESS =
    "SEARCH_COMPLETED_UPLOADS_SUCCESS";
export const SEARCH_COMPLETED_UPLOADS_FAILURE =
    "SEARCH_COMPLETED_UPLOADS_FAILURE";

export const SEARCH_UPLOADS = "SEARCH_UPLOADS";
export const SEARCH_UPLOADS_SUCCESS = "SEARCH_UPLOADS_SUCCESS";
export const SEARCH_UPLOADS_FAILURE = "SEARCH_UPLOADS_FAILURE";

export const INCREMENT_SEARCH_PAGE = "INCREMENT_SEARCH_PAGE";
export const RESET_SEARCH_PAGE = "RESET_SEARCH_PAGE";

// plugin
export const SET_ADOBE_AGENT = "SET_ADOBE_AGENT";

export const REPORT_PLUGIN_USAGE = "REPORT_PLUGIN_USAGE";
export const REPORT_PLUGIN_USAGE_SUCCESS = "REPORT_PLUGIN_USAGE_SUCCESS";
export const REPORT_PLUGIN_USAGE_FAILURE = "REPORT_PLUGIN_USAGE_FAILURE";

export const FETCH_PLUGIN_ICONS = "FETCH_PLUGIN_ICONS";
export const FETCH_PLUGIN_ICONS_SUCCESS = "FETCH_PLUGIN_ICONS_SUCCESS";
export const FETCH_PLUGIN_ICONS_FAILURE = "FETCH_PLUGIN_ICONS_FAILURE";

export const SET_PLUGIN_PAGE = "SET_PLUGIN_PAGE";
export const SET_PLUGIN_HOST = "SET_PLUGIN_HOST";
export const PLACE_ICON = "PLACE_ICON";

export const GET_PNG_URL = "GET_PNG_URL";
export const GET_PNG_URL_SUCCESS = "GET_PNG_URL_SUCCESS";
export const GET_PNG_URL_FAILURE = "GET_PNG_URL_FAILURE";

export const PLACE_PNG_DATA_GSUITE = "PLACE_PNG_DATA_GSUITE";

export const FETCH_PDF_DATA = "FETCH_PDF_DATA";
export const FETCH_PDF_DATA_SUCCESS = "FETCH_PDF_DATA_SUCCESS";
export const FETCH_PDF_DATA_FAILURE = "FETCH_PDF_DATA_FAILURE";

// edu

export const SEND_EDU_APPLICATION = "SEND_EDU_APPLICATION";
export const SEND_EDU_APPLICATION_SUCCESS = "SEND_EDU_APPLICATION_SUCCESS";
export const SEND_EDU_APPLICATION_FAILURE = "SEND_EDU_APPLICATION_FAILURE";

// Downloads
export const DOWNLOAD_RESET_FLOW = "DOWNLOAD_RESET_FLOW";
export const DOWNLOAD_FLOW_GO_BACK = "DOWNLOAD_FLOW_GO_BACK";
export const DOWNLOAD_SET_FLOW_ACTIVE = "DOWNLOAD_SET_FLOW_ACTIVE";
export const DOWNLOAD_REQUEST = "DOWNLOAD_REQUEST";
export const DOWNLOAD_SELECT_LICENSE = "DOWNLOAD_SELECT_LICENSE";
export const DOWNLOAD_COMMIT_LICENSE = "DOWNLOAD_COMMIT_LICENSE";
export const DOWNLOAD_SELECT_PURCHASE_TYPE = "DOWNLOAD_SELECT_PURCHASE_TYPE";
export const DOWNLOAD_COMMIT_PURCHASE_TYPE = "DOWNLOAD_COMMIT_PURCHASE_TYPE";
export const DOWNLOAD_SELECT_FORMAT = "DOWNLOAD_SELECT_FORMAT";

export const DOWNLOAD_PURCHASE_START = "DOWNLOAD_PURCHASE_START";
export const DOWNLOAD_PURCHASE_END = "DOWNLOAD_PURCHASE_END";
export const DOWNLOAD_START = "DOWNLOAD_START";
export const DOWNLOAD_START_SUCCESS = "DOWNLOAD_START_SUCCESS";
export const DOWNLOAD_START_FAILURE = "DOWNLOAD_START_FAILURE";

export const DOWNLOAD_PURCHASE = "DOWNLOAD_PURCHASE";
export const DOWNLOAD_PURCHASE_SUCCESS = "DOWNLOAD_PURCHASE_SUCCESS";
export const DOWNLOAD_PURCHASE_FAILURE = "DOWNLOAD_PURCHASE_FAILURE";

export const DOWNLOAD_UPSELL_ACCEPTED = "DOWNLOAD_UPSELL_ACCEPTED";
export const DOWNLOAD_UPSELL_REJECTED = "DOWNLOAD_UPSELL_REJECTED";
export const DOWNLOAD_REPURCHASE = "DOWNLOAD_REPURCHASE";
export const DOWNLOAD_JUMP_TO_LICENSE = "DOWNLOAD_JUMP_TO_LICENSE";
export const DOWNLOAD_COMPLETE_SUCCESS = "DOWNLOAD_COMPLETE_SUCCESS";
export const DOWNLOAD_COMPLETE_FAILURE = "DOWNLOAD_COMPLETE_FAILURE";

export const DOWNLOAD_PHASE_JUMP_START = "DOWNLOAD_PHASE_JUMP_START";
export const DOWNLOAD_PHASE_JUMP_END = "DOWNLOAD_PHASE_JUMP_END";

export const DOWNLOAD_SHOW_AUTH = "DOWNLOAD_SHOW_AUTH";

export const STRIPE_CREATE_TOKEN = "STRIPE_CREATE_TOKEN";
export const STRIPE_CREATE_TOKEN_SUCCESS = "STRIPE_CREATE_TOKEN_SUCCESS";
export const STRIPE_CREATE_TOKEN_FAILURE = "STRIPE_CREATE_TOKEN_FAILURE";

export const STRIPE_CARD_INPUT_CHANGE = "STRIPE_CARD_INPUT_CHANGE";
export const STRIPE_CARD_INPUT_ERROR = "STRIPE_CARD_INPUT_ERROR";
export const STRIPE_SAVE_CARD_CHANGE = "STRIPE_SAVE_CARD_CHANGE";

export const STRIPE_CLEAR_ERRORS = "STRIPE_CLEAR_ERRORS";

export const STRIPE_GET_SAVED_CARD = "STRIPE_GET_SAVED_CARD";
export const STRIPE_GET_SAVED_CARD_SUCCESS = "STRIPE_GET_SAVED_CARD_SUCCESS";
export const STRIPE_GET_SAVED_CARD_FAILURE = "STRIPE_GET_SAVED_CARD_FAILURE";

export const STRIPE_USE_NEW_CARD = "STRIPE_USE_NEW_CARD";

export const STRIPE_SET_SHOW_BILLING_INFO = "STRIPE_SET_SHOW_BILLING_INFO";
export const STRIPE_UPDATE_BILLING_INFO = "STRIPE_UPDATE_BILLING_INFO";
export const STRIPE_PREPAID_NUM_ICONS_CHANGE =
    "STRIPE_PREPAID_NUM_ICONS_CHANGE";
export const STRIPE_PREPAID_NUM_ICONS_BLUR = "STRIPE_PREPAID_NUM_ICONS_BLUR";

export const STRIPE_COUPON_CODE_CHECK = "STRIPE_COUPON_CODE_CHECK";
export const STRIPE_COUPON_CODE_CHECK_SUCCESS =
    "STRIPE_COUPON_CODE_CHECK_SUCCESS";
export const STRIPE_COUPON_CODE_CHECK_FAILURE =
    "STRIPE_COUPON_CODE_CHECK_FAILURE";
export const STRIPE_COUPON_CODE_CHANGE = "STRIPE_COUPON_CODE_CHANGE";
export const STRIPE_NUM_SEATS_CHANGE = "STRIPE_NUM_SEATS_CHANGE";
export const STRIPE_NUM_SEATS_BLUR = "STRIPE_NUM_SEATS_BLUR";

// UI
export const UI_BODY_CLASS_ADD = "UI_BODY_CLASS_ADD";
export const UI_BODY_CLASS_REMOVE = "UI_BODY_CLASS_REMOVE";
export const UI_SCROLL_TO_ELEMENT = "UI_SCROLL_TO_ELEMENT";
export const UI_WINDOW_RESIZE = "UI_WINDOW_RESIZE";
export const EXPERIMENTS_SET = "EXPERIMENTS_SET";
export const REDIRECT = "REDIRECT";

export const EDITOR_RESET = "EDITOR_RESET";
export const EDITOR_TRANSFORM = "EDITOR_TRANSFORM";
export const EDITOR_TRANSFORMED = "EDITOR_TRANSFORMED";
export const EDITOR_ADD_COLOR = "EDITOR_ADD_COLOR";
export const EDITOR_SET_PALETTE_MODE = "EDITOR_SET_PALETTE_MODE";
export const EDITOR_EXPORT = "EDITOR_EXPORT";
export const EDITOR_EXPORT_SUCCESS = "EDITOR_EXPORT_SUCCESS";
export const EDITOR_EXPORT_FAILURE = "EDITOR_EXPORT_FAILURE";
export const EDITOR_CACHE_SVG = "EDITOR_CACHE_SVG";

export const SET_GLOBAL_CONFIG = "SET_GLOBAL_CONFIG";

export const AUTH_FORM_INPUT_CHANGE = "AUTH_FORM_INPUT_CHANGE";
export const AUTH_FORM_CLEAR_ERRORS = "AUTH_FORM_CLEAR_ERRORS";
export const AUTH_FORM_CLEAR_DATA = "AUTH_FORM_CLEAR_DATA";
export const AUTH_FORM_ERROR = "AUTH_FORM_ERROR";

export const USER_GET_PREPAID_BALANCE = "USER_GET_PREPAID_BALANCE";
export const USER_GET_PREPAID_BALANCE_SUCCESS =
    "USER_GET_PREPAID_BALANCE_SUCCESS";
export const USER_GET_PREPAID_BALANCE_FAILURE =
    "USER_GET_PREPAID_BALANCE_FAILURE";

export const FETCH_CURATION_COUNT = "FETCH_CURATION_COUNT";
export const FETCH_CURATION_COUNT_SUCCESS = "FETCH_CURATION_COUNT_SUCCESS";
export const FETCH_CURATION_COUNT_FAILURE = "FETCH_CURATION_COUNT_FAILURE";

export const FETCH_COLLECTIONS = "FETCH_COLLECTIONS";
export const FETCH_COLLECTIONS_SUCCESS = "FETCH_COLLECTIONS_SUCCESS";
export const FETCH_COLLECTIONS_FAILURE = "FETCH_COLLECTIONS_FAILURE";

export const BANNER_DISMISS = "BANNER_DISMISS";
export const BANNER_DISMISS_SUCCESS = "BANNER_DISMISS_SUCCESS";
export const BANNER_DISMISS_FAILURE = "BANNER_DISMISS_FAILURE";
export const BANNER_SHOW_TOAST = "BANNER_SHOW_TOAST";

export const PAGEVIEW = "PAGEVIEW";

export const LINGO_UPSELL_CHECK = "LINGO_UPSELL_CHECK";
export const LINGO_UPSELL_CHECK_SUCCESS = "LINGO_UPSELL_CHECK_SUCCESS";
export const LINGO_UPSELL_CHECK_FAILURE = "LINGO_UPSELL_CHECK_FAILURE";

export const KITS_ADD_ICON = "KITS_ADD_ICON";
export const KITS_ADD_ICON_SUCCESS = "KITS_ADD_ICON_SUCCESS";
export const KITS_ADD_ICON_FAILURE = "KITS_ADD_ICON_FAILURE";
export const KITS_REMOVE_ICON = "KITS_REMOVE_ICON";
export const KITS_REMOVE_ICON_SUCCESS = "KITS_REMOVE_ICON_SUCCESS";
export const KITS_REMOVE_ICON_FAILURE = "KITS_REMOVE_ICON_FAILURE";

export const KITS_FETCH = "KITS_FETCH";
export const KITS_FETCH_SUCCESS = "KITS_FETCH_SUCCESS";
export const KITS_FETCH_FAILURE = "KITS_FETCH_FAILURE";

export const KITS_SHOW_DROPDOWN = "KITS_SHOW_DROPDOWN";
export const KITS_HIDE_DROPDOWN = "KITS_HIDE_DROPDOWN";

export const KITS_INPUT_CHANGE = "KITS_INPUT_CHANGE";
export const KITS_INITIALIZE_NEW_KIT = "KITS_INITIALIZE_NEW_KIT";

export const KITS_CREATE_KIT = "KITS_CREATE_KIT";
export const KITS_CREATE_KIT_SUCCESS = "KITS_CREATE_KIT_SUCCESS";
export const KITS_CREATE_KIT_FAILURE = "KITS_CREATE_KIT_FAILURE";

export const KITS_SAVE_TO_FAVORITES = "KITS_SAVE_TO_FAVORITES";
export const KITS_SAVE_TO_FAVORITES_SUCCESS = "KITS_SAVE_TO_FAVORITES_SUCCESS";
export const KITS_SAVE_TO_FAVORITES_FAILURE = "KITS_SAVE_TO_FAVORITES_FAILURE";

export const KITS_REMOVE_FROM_FAVORITES = "KITS_REMOVE_FROM_FAVORITES";
export const KITS_REMOVE_FROM_FAVORITES_SUCCESS =
    "KITS_REMOVE_FROM_FAVORITES_SUCCESS";
export const KITS_REMOVE_FROM_FAVORITES_FAILURE =
    "KITS_REMOVE_FROM_FAVORITES_FAILURE";

export const FETCH_RECENTLY_USED_PLUGIN_ICONS =
    "FETCH_RECENTLY_USED_PLUGIN_ICONS";
export const FETCH_RECENTLY_USED_PLUGIN_ICONS_SUCCESS =
    "FETCH_RECENTLY_USED_PLUGIN_ICONS_SUCCESS";
export const FETCH_RECENTLY_USED_PLUGIN_ICONS_FAILURE =
    "FETCH_RECENTLY_USED_PLUGIN_ICONS_FAILURE";

export const FETCH_RECENTLY_USED_ICON = "FETCH_RECENTLY_USED_ICON";
export const FETCH_RECENTLY_USED_ICON_SUCCESS =
    "FETCH_RECENTLY_USED_ICON_SUCCESS";
export const FETCH_RECENTLY_USED_ICON_FAILURE =
    "FETCH_RECENTLY_USED_ICON_FAILURE";

export const SET_ACTIVE_RECENTLY_USED_ICON = "SET_ACTIVE_RECENTLY_USED_ICON";

export const FETCH_VIEW_MORE_ICONS_FOR_RECENTLY_USED_ICON_DETAIL_PAGE =
    "FETCH_VIEW_MORE_ICONS_FOR_RECENTLY_USED_ICON_DETAIL_PAGE";
export const FETCH_VIEW_MORE_ICONS_FOR_RECENTLY_USED_ICON_DETAIL_PAGE_SUCCESS =
    "FETCH_VIEW_MORE_ICONS_FOR_RECENTLY_USED_ICON_DETAIL_PAGE_SUCCESS";
export const FETCH_VIEW_MORE_ICONS_FOR_RECENTLY_USED_ICON_DETAIL_PAGE_FAILURE =
    "FETCH_VIEW_MORE_ICONS_FOR_RECENTLY_USED_ICON_DETAIL_PAGE_FAILURE";

export const SHOW_RECENTLY_USED_ICON_DETAIL = "SHOW_RECENTLY_USED_ICON_DETAIL";
export const HIDE_RECENTLY_USED_ICON_DETAIL = "HIDE_RECENTLY_USED_ICON_DETAIL";

export const FETCH_RECENTLY_USED_SVG_DATA = "FETCH_RECENTLY_USED_SVG_DATA";
export const FETCH_RECENTLY_USED_SVG_DATA_SUCCESS =
    "FETCH_RECENTLY_USED_SVG_DATA_SUCCESS";
export const FETCH_RECENTLY_USED_SVG_DATA_FAILURE =
    "FETCH_RECENTLY_USED_SVG_DATA_FAILURE";

export const SAVE_CLEANED_RECENTLY_USED_SVG = "SAVE_CLEANED_RECENTLY_USED_SVG";
