import gql from "graphql-tag";

/* -------------------------------------------------------------------------- */
/*                            moderator assignment                            */
/* -------------------------------------------------------------------------- */
export const ASSIGN_BATCH_TO_MOD = gql`
    mutation AssignPhotoBatchToModerator($photoBatchId: ID!, $moderatorId: ID) {
        assign: assignPhotoBatchToModerator(
            photoBatchId: $photoBatchId
            moderatorId: $moderatorId
        ) {
            errors
            ok
            photoBatch {
                id
                moderator {
                    displayName
                    id
                    isManager
                    isPhotoModerator
                    username
                }
            }
        }
    }
`;
