import styled, { css } from "styled-components";

// styles
import colors, { focusColor } from "core/colors";
import { convertPxToRem } from "frontend/components/helpers";

// styled-components
export const DividerStyled = styled.div`
    width: ${convertPxToRem(1)};
    height: ${convertPxToRem(18)};
    background-color: ${colors.secondary.tinfoil};
`;

export const ToggleButtonStyled = styled.button`
    border: none;
    outline: none;
    background-color: transparent;
    display: block;
    width: ${convertPxToRem(22)};
    padding: 0px;

    cursor: pointer;

    &:hover,
    &:focus {
        outline: solid 1px ${focusColor};
    }

    &:disabled {
        cursor: not-allowed;
    }
`;

export const OffsetIconStyled = styled.div`
    position: relative;
    ${({ verticalOffset }) => {
        if (verticalOffset) {
            return css`
                top: ${verticalOffset};
            `;
        }
    }}
`;

export const ModerationToggleBandStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: ${convertPxToRem(38)};

    /*
        Custom Breakpoints :: standard breakpoints did not resolve layout
        Granted we are only accounting for Desktop at this point
        But addressing minimal style choices in medium and small view.
    */
    padding: 0px ${convertPxToRem(6)};

    /* 1385 and above */
    @media (min-width: ${convertPxToRem(1385)}) {
        padding: 0px ${convertPxToRem(10)};
    }
    /* Above Desktop */
    @media (min-width: ${convertPxToRem(1650)}) {
        padding: 0px ${convertPxToRem(19)};
    }

    ${({ isWhiteBackground }) => {
        if (isWhiteBackground) {
            return css`
                ${DividerStyled} {
                    background-color: ${colors.primary.pigeon};
                }
            `;
        }
    }}
`;
