import React from "react";

import Grid from "core/Grid";

import Heading from "core/Heading";

import colors from "core/colors";

const TitleWithOffset = ({ title }) => (
    <Grid desktopSpacing={0}>
        <Grid item sm={1} md={1} lg={1} />
        <Grid item sm={10} md={10} lg={10}>
            <Heading
                type="h2"
                size="xl"
                headingColor={colors.secondary.orca}
                bottomSpacing="4xl"
                alignment="center"
            >
                {title}
            </Heading>
        </Grid>
        <Grid item sm={1} md={1} lg={1} />
    </Grid>
);

export default TitleWithOffset;

// the purpose of this file is so we can nest a grid within a grid to allow an offset for the content; the content will go in the middle grid item, and the offset is taken care of with the first and last grid item
