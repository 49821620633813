import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// styles
import { convertPxToRem } from "frontend/components/helpers";
const TagsInlineStyled = styled.div`
    padding: ${convertPxToRem(10)};
`;

// components
import TagInline from "./TagInline";

// component
export const TagsInline = ({ tags }) => {
    return (
        <TagsInlineStyled>
            {tags.map(tag => {
                return <TagInline key={tag.id} tag={tag} />;
            })}
        </TagsInlineStyled>
    );
};

export default TagsInline;

// prop-types
TagsInline.propTypes = {
    tags: PropTypes.array.isRequired
};
