import React, {
    useReducer,
    createContext,
    useContext,
    useMemo,
    useEffect
} from "react";
import PropTypes from "prop-types";

// external context
import {
    SelectProvider,
    useSelect
} from "frontend/components/core/Grid2/GridItemContentTemplates/Select/SelectContext";

/*---------------------------
| Context
---------------------------*/
export const QueueContext = createContext();

/*---------------------------
| Provider
---------------------------*/
export const QueueProvider = props => {
    const {
        children,
        batchesObject,
        LoadMoreButton,
        uploadsObject,
        uploadType = "photo",
        licenseMappings,
        visibleUploadIds = [],
        defaultUploadIdsSelected = []
    } = props;

    // avoiding doing this everywhere
    const selectedBatch = props.selectedBatch;
    const batchIsSelected = !!selectedBatch.id;
    const selectedBatchObj =
        batchesObject?.entities?.[selectedBatch.id] || null;

    let defaultInternalState = {
        selectedUploads: [],
        uploadSelectedCount: 0,
        singleUploadSelected: null,
        allUploadsSelectedInBatch: false
    };

    const refinedProps = {
        ...props,
        batchesObject,
        LoadMoreButton,
        uploadsObject,
        selectedBatch,
        selectedBatchObj,
        batchIsSelected,
        uploadType,
        uploadTypeTitle: uploadType === "photo" ? "Photo" : "Icon",
        licenseMappings
    };
    delete refinedProps.children;

    // Whatever you want to manage as internal state
    const [state, dispatch] = useReducer(reducer, defaultInternalState);

    // useMemo so it does not pass value on every render
    const value = useMemo(() => ({ state, dispatch, ...refinedProps }), [
        state,
        dispatch,
        refinedProps
    ]);

    return (
        <QueueContext.Provider value={value}>
            <SelectProvider
                uploadIds={visibleUploadIds}
                defaultUploadIdsSelected={defaultUploadIdsSelected}
                selectNextPrevEnabled={true}
            >
                <MountingWrapper>{children}</MountingWrapper>
            </SelectProvider>
        </QueueContext.Provider>
    );
};

// prop-types
QueueProvider.propTypes = {
    children: PropTypes.any,
    batchesObject: PropTypes.object,
    LoadMoreButton: PropTypes.any,
    uploadsObject: PropTypes.object,
    selectedBatch: PropTypes.object.isRequired,
    licenseMappings: PropTypes.object.isRequired,
    visibleUploadIds: PropTypes.array,
    defaultUploadIdsSelected: PropTypes.array,
    uploadType: PropTypes.string
};

// MountingWrapper
const MountingWrapper = ({ children }) => {
    const { dispatch, uploadsObject, visibleUploadIds } = useQueueContext();
    const { uploadsSelected, getSelectedUploadIds } = useSelect();

    useEffect(() => {
        const selectedUploadIds = getSelectedUploadIds(uploadsSelected);

        const selectedUploads = selectedUploadIds.map(
            id => uploadsObject.entities[id]
        );

        dispatch(
            actions.selectedUploadsUpdate({
                selectedUploads,
                visibleUploadIds
            })
        );
    }, [JSON.stringify(uploadsSelected), uploadsObject]);

    return <>{children}</>;
};

// prop-types
MountingWrapper.propTypes = {
    children: PropTypes.any
};

/*---------------------------
| useQueueContext
---------------------------*/
export const useQueueContext = () => {
    return useContext(QueueContext);
};

/*---------------------------
| Store
---------------------------*/
// Action Types
const actionPrefix = "QueueContext:";
const actionTypes = {
    SELECTED_UPLOADS_UPDATE: `${actionPrefix} selectedUploadsUpdate`
};

// Action Creators
export const actions = {
    selectedUploadsUpdate: ({ selectedUploads, visibleUploadIds }) => {
        return {
            type: actionTypes.SELECTED_UPLOADS_UPDATE,
            selectedUploads,
            visibleUploadIds
        };
    }
};

// Reducer
export const reducer = (state, action) => {
    const { type } = action;

    switch (type) {
        case actionTypes.SELECTED_UPLOADS_UPDATE: {
            const { selectedUploads, visibleUploadIds } = action;

            const uploadSelectedCount = selectedUploads.length;
            const singleUploadSelected =
                uploadSelectedCount === 1 ? selectedUploads[0] : null;

            const uploadCount = visibleUploadIds?.length;
            const allUploadsSelectedInBatch =
                uploadCount > 0 && uploadCount === selectedUploads.length;

            return {
                ...state,
                selectedUploads,
                uploadSelectedCount,
                singleUploadSelected,
                allUploadsSelectedInBatch
            };
        }
        default: {
            return { ...state };
        }
    }
};
