import React from "react";
import PropTypes from "prop-types";

// styles
import { TextWithPopOutLinkStyled } from "./styles";

// components
import ExternalLink from "core/ReusableIcons/ExternalLink.js";
import Template from "./Template.js";

const TextWithPopOutLink = ({
    title,
    titleFull,
    text,
    url,
    width,
    target = "_self",
    textTestId = "",
    linkTestId = "",
    hideLink = false,
    tempDisable = false
}) => {
    const tempDisableOnClick = e => {
        if (tempDisable) {
            e.preventDefault();
            alert(`TODO: link to ${titleFull || title}`);
        }
    };

    return (
        <Template title={title} titleFull={titleFull} width={width}>
            <TextWithPopOutLinkStyled onClick={tempDisableOnClick}>
                <div>
                    <span data-testid={textTestId}>{text}</span>
                    {!hideLink && (
                        <a data-testid={linkTestId} href={url} target={target}>
                            <ExternalLink size={{ width: 16, height: 16 }} />
                        </a>
                    )}
                </div>
            </TextWithPopOutLinkStyled>
        </Template>
    );
};

export default TextWithPopOutLink;

// prop-types
TextWithPopOutLink.propTypes = {
    title: PropTypes.any.isRequired,
    titleFull: PropTypes.any,
    text: PropTypes.any.isRequired,
    url: PropTypes.string.isRequired,
    width: PropTypes.number,
    target: PropTypes.string,
    textTestId: PropTypes.string,
    linkTestId: PropTypes.string,
    hideLink: PropTypes.bool,
    tempDisable: PropTypes.bool
};
