import React from "react";

import colors from "core/colors";

import { Container, Title, Message, Buttons, IconContainer } from "./styles";
import SubmissionStatusButton from "./SubmissionStatusButton";
import QueueIcon from "core/IllustrativeIcons/Queue";

const MaxQueueError = () => {
    return (
        <Container>
            <Title>Your prior submissions are queued for review.</Title>
            <Message>
                Thanks for your patience. Your prior submissions must be
                reviewed before you can add more.
            </Message>
            <Buttons>
                <SubmissionStatusButton />
            </Buttons>
            <IconContainer>
                <QueueIcon fillColor={colors.accent.lightMode.pansy} />
            </IconContainer>
        </Container>
    );
};

export default MaxQueueError;
