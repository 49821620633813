import React from "react";
import PropTypes from "prop-types";

// styles
import { SideBarSectionStyled, TitleStyled } from "./styles.js";

const SideBarSection = ({ title, children }) => {
    return (
        <SideBarSectionStyled>
            <TitleStyled>{title}</TitleStyled>
            {children}
        </SideBarSectionStyled>
    );
};

export default SideBarSection;

// prop-types
SideBarSection.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.any
};
