import React from "react";

import { SEARCH } from "data/links";

import colors from "core/colors";

// for hero:
import heroDesktopImg from "./images/hero-desktop.png";
import heroTabletImg from "./images/hero-tablet.png";
import heroMobileImg from "./images/hero-mobile.png";

export const heroImages = {
    desktop: heroDesktopImg,
    tablet: heroTabletImg,
    mobile: heroMobileImg
};

export const heroHeading =
    "Art-Quality, Inclusive Stock Photos for Every Project";

export const heroSearchPlaceholder = {
    desktop: "Search art-quality photos",
    tablet: "Search art-quality photos",
    mobile: "Search photos"
};

// for "bullets" section:
import Eye from "core/ReusableIcons/Eye";
import Photo from "core/ReusableIcons/Photo";
import Receipt from "core/ReusableIcons/Receipt";

export const bullets = [
    {
        icon: <Eye fillColor={colors.secondary.orca} size="xl" />,
        title: "Professionally Curated",
        text:
            "​A hand-selected collection of authentic pictures from around the world that celebrate diversity and inclusion."
    },
    {
        icon: <Photo fillColor={colors.secondary.orca} size="xl" />,
        title: "Beautiful & Affordable",
        text:
            "​Art-quality images available at price points for any budget, from free photo downloads to high-res jpgs."
    },
    {
        icon: <Receipt fillColor={colors.secondary.orca} size="xl" />,
        title: "Royalty-Free Licenses",
        text:
            "Support photographers and have peace of mind with royalty-free, commercial licenses including model and property releases."
    }
];

// for "Collections" section:
import AtTheOffice from "./images/at-the-office.jpg";
import Medical from "./images/medical.jpg";
import Winterscapes from "./images/winterscapes.jpg";
import Friends from "./images/friends.jpg";
import Textures from "./images/textures.jpg";
import FlatLaysImg from "./images/flatlays.jpg";

export const collectionsTitle =
    "An Inclusive, Diverse Collection of Art-Quality Photos, Because Representation Matters";

export const collections = [
    {
        title: "Textures",
        subtitle: null,
        url: `${SEARCH}?q=textures`,
        imageSrcAndTitle: {
            imageSrc: Textures,
            title: "Textures"
        },
        type: "primary",
        isPhoto: true
    },
    {
        title: "At the Office",
        subtitle: null,
        url: `${SEARCH}?q=office`,
        imageSrcAndTitle: {
            imageSrc: AtTheOffice,
            title: "At the Office"
        },
        type: "primary",
        isPhoto: true
    },
    {
        title: "Winterscapes",
        subtitle: null,
        url: `${SEARCH}?q=winter`,
        imageSrcAndTitle: {
            imageSrc: Winterscapes,
            title: "Winterscapes"
        },
        type: "primary",
        isPhoto: true
    },
    {
        title: "Friends",
        subtitle: null,
        url: `${SEARCH}?q=friends`,
        imageSrcAndTitle: {
            imageSrc: Friends,
            title: "Friends"
        },
        type: "primary",
        isPhoto: true
    },
    {
        title: "Medical",
        subtitle: null,
        url: `${SEARCH}?q=medical`,
        imageSrcAndTitle: {
            imageSrc: Medical,
            title: "Medical"
        },
        type: "primary",
        isPhoto: true
    },
    {
        title: "Flat Lays",
        subtitle: null,
        url: `${SEARCH}?q=flat+lay`,
        imageSrcAndTitle: {
            imageSrc: FlatLaysImg,
            title: "Flat Lays"
        },
        type: "primary",
        isPhoto: true
    }
];

export const pillsTitle = "Explore Trends";

export const pills = [
    {
        title: "Entrepreneur",
        url: `${SEARCH}?q=entrepreneur`
    },
    {
        title: "Food",
        url: `${SEARCH}?q=food`
    },
    {
        title: "Leadership",
        url: `${SEARCH}?q=leadership`
    },
    {
        title: "People",
        url: `${SEARCH}?q=people`
    },
    {
        title: "Copy Space",
        url: `${SEARCH}?q=copy-space`
    },
    {
        title: "Idea",
        url: `${SEARCH}?q=idea`
    },
    {
        title: "Business",
        url: `${SEARCH}?q=business`
    },
    {
        title: "Happy",
        url: `${SEARCH}?q=happy`
    }
];

// Photography Features section:
import Featured1 from "./images/elevate-your-photos.jpg";
import Featured2 from "./images/using-royalty-free.jpg";
import Featured3 from "./images/presentations-that-pop.jpg";

export const featuredTitle = "Get Inspired: Photography Features";

export const features = [
    {
        title: "Elevate Your Photos",
        category: "Photos",
        imgSrc: Featured1,
        url:
            "https://blog.thenounproject.com/10-tips-to-instantly-upgrade-your-photos-2/",
        target: "_blank",
        imageIsLink: true,
        headingIsLink: true,
        subtitleIsLink: true
    },
    {
        title: "Using Royalty-Free Images",
        category: "Photos",
        imgSrc: Featured2,
        url: "https://blog.thenounproject.com/what-are-royalty-free-images/",
        target: "_blank",
        imageIsLink: true,
        headingIsLink: true,
        subtitleIsLink: true
    },
    {
        title: "Create Presentations That Pop",
        category: "Photos",
        imgSrc: Featured3,
        url:
            "https://blog.thenounproject.com/how-to-create-presentations-that-pop-companion-guide/",
        target: "_blank",
        imageIsLink: true,
        headingIsLink: true,
        subtitleIsLink: true
    }
];
