/*===================================
||
|| BatchesContext
||
===================================*/
import React, {
    useEffect,
    useState,
    useMemo,
    createContext,
    useContext,
    useRef
} from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

// redux
import { batchesSlice } from "frontend/components/fort/mod/photos/slices/batchesSlice";

// apollo queries
import { useFetchPaginatedBatches } from "frontend/components/fort/mod/photos/hooks/queries/useFetchPaginatedBatches";
import { useGetPhotoCountsForBatches } from "frontend/components/fort/mod/photos/hooks/queries/useGetPhotoCountsForBatches";
import { useGetUploadIdsForBatches } from "frontend/components/fort/mod/photos/hooks/queries/useGetUploadIdsForBatches";

// components
export { LoadMoreButton } from "./components/LoadMoreButton.js";
export { FilterByBatchType } from "./components/FilterByBatchType.js";
export { Search } from "./components/Search.js";
export { SortBy } from "./components/SortBy.js";
export { StatusMessage } from "./components/StatusMessage.js";

// hooks
import { useUrlSearchParams } from "frontend/hooks/useUrlSearchParams";
import { useAuth } from "frontend/hooks/useAuth";

// data
import {
    BATCH_TYPE_KEYS,
    SORT_BY_VALUES,
    BATCH_TYPES,
    QUEUES,
    MODERATION_TYPES
} from "frontend/components/fort/mod/photos/constants";

// helpers
import { getEnhancedBatches } from "./helpers/getEnhancedBatches";

const BatchesContext = createContext();

/**
 * getFilterFlags - only add flags if they are true, backend ignores missing variables.
 * @param {Object} searchParams holds the values for batch type filter flags
 * @returns
 */
const getFilterFlags = searchParams => {
    let filterFlags = {};

    BATCH_TYPES.forEach(({ options }) => {
        options.forEach(o => {
            const { key, db } = o;
            // only add if true
            if (searchParams?.[key] === "true") {
                filterFlags[db.key] = db.valueWhenChecked;
            }
        });
    });

    return filterFlags;
};

// Default sorting is oldest to newest by date submitted unless sortNewestToOldest is True."
const getSortNewsetToOldest = sortBy => {
    return sortBy === SORT_BY_VALUES[1];
};

const isSuspendedViewCheck = currentQueue => {
    return [QUEUES.SUSPENDED].includes(currentQueue);
};

// useBatches
export const useBatches = () => {
    const context = useContext(BatchesContext);

    const {
        pagination,
        currentUser,
        searchParams,
        setUrlSearchParams,
        dispatch,
        currentQueueName,
        isCallingApi,
        fetchPaginatedBatches,
        getPhotoCountsForBatches,
        getUploadIdsForBatches
    } = context;

    // Magical Api Async Method
    const fetchQueueBatches = async ({ showLoading = false } = {}) => {
        try {
            if (showLoading) {
                dispatch(batchesSlice.actions.setShowLoading(true));
            }
            dispatch(batchesSlice.actions.setApiStatusToCalling());
            const { currentPage, textSearch, sortBy } = searchParams;
            const { batchesPerPage } = pagination;
            const page = currentPage ? Number(currentPage) : 1;
            const showOnlySuspendedCreators = isSuspendedViewCheck(
                currentQueueName
            );

            // Fetch Paginated Batches
            const gqlVariables = {
                textSearch: textSearch || "",
                moderationType: MODERATION_TYPES[currentQueueName],
                sortNewestToOldest: getSortNewsetToOldest(sortBy),
                showOnlySuspendedCreators,
                ...getFilterFlags(searchParams)
            };

            const {
                batches,
                totalCount,
                newCurrentPage
            } = await fetchPaginatedBatches({
                variables: gqlVariables,
                page,
                numberOfBatches: batchesPerPage * page,
                batchesPerPage
            });

            // cont batchIds
            const batchIds = batches.map(b => b.id);

            // Get Photo Counts
            const photoCountsForBatches = await getPhotoCountsForBatches(
                batchIds,
                showOnlySuspendedCreators
            );

            // Get Photo Counts
            const uploadIdsForBatches = await getUploadIdsForBatches(batchIds);

            // Enhance Batches
            const enhancedBatches = await getEnhancedBatches({
                batches,
                batchesPerPage,
                currentQueue: currentQueueName,
                currentUser,
                photoCountsForBatches,
                uploadIdsForBatches
            });

            // In case user or filter gave us an invalid page number in URL Params (e.g. pagination exceeds total fetched), lets correct the currentPage url param
            if (page !== newCurrentPage) {
                setUrlSearchParams?.([
                    {
                        key: "currentPage",
                        value: newCurrentPage
                    }
                ]);
            }

            // dispatch some things
            dispatch(
                batchesSlice.actions.setPagination({
                    currentPage,
                    newPagination: {
                        totalCount
                    }
                })
            );
            dispatch(batchesSlice.actions.setBatches(enhancedBatches));
        } catch (error) {
            dispatch(batchesSlice.actions.setApiStatusToError());
            const errorMessage = `fetchQueueBatches: Error: ${error}`;
            console.error(errorMessage);
            throw new Error(error);
        }
    };

    return {
        ...context,
        dispatch,
        searchParams,
        setUrlSearchParams,
        isCallingApi,
        fetchQueueBatches
    };
};

// MountingComponent
const MountingComponent = ({ children }) => {
    const [hasMounted, setHasMounted] = useState(false);
    const prevSearchParamsRef = useRef();

    const {
        dispatch,
        fetchQueueBatches,
        hasLoaded,

        // UI changes
        searchParams,
        setUrlSearchParams
    } = useBatches();

    /*---------------------------
    | methods
    ---------------------------*/
    const evaluateSearchParam = ({
        params,
        key,
        defaultValue,
        acceptedValue = () => true
    }) => {
        // see if url param exists
        const exists = searchParams?.[key];

        // does it match trusted value
        const matchesTrustedValues = acceptedValue(exists);

        // set url param if any of the above fails
        if (!exists || !matchesTrustedValues) {
            params.push({ key, value: defaultValue });
        }
    };

    const resolveOnMount = () => {
        let params = [];

        evaluateSearchParam({
            params,
            key: "sortBy",
            defaultValue: SORT_BY_VALUES[0],
            acceptedValue: val => SORT_BY_VALUES.includes(val)
        });

        evaluateSearchParam({
            params,
            key: "textSearch",
            defaultValue: ""
        });

        evaluateSearchParam({
            params,
            key: "currentPage",
            defaultValue: "1"
        });

        // batchTypes
        Object.keys(BATCH_TYPE_KEYS).forEach(objProp => {
            const batchTypeKey = BATCH_TYPE_KEYS[objProp];
            evaluateSearchParam({
                params,
                key: batchTypeKey,
                defaultValue: "false",
                acceptedValue: val => {
                    const acceptableValues = ["true", "false"];
                    const passes = acceptableValues.includes(val);
                    return passes;
                }
            });
        });
        setUrlSearchParams(params);
        dispatch(batchesSlice.actions.setHasLoaded(true));
    };

    // throttling api requests in case user is making multiple filter changes all at once.
    let timeoutId;
    const debounceFilterChangefetchQueueBatches = ({ showLoading = true }) => {
        clearTimeout(timeoutId); // every time this is called, we clear previous requests
        timeoutId = setTimeout(() => {
            // this initiates the api call and resets page to 1
            fetchQueueBatches({ showLoading });
        }, 2000);
    };

    /*---------------------------
    | Side Effects
    ---------------------------*/
    // On Mount
    useEffect(() => {
        setHasMounted(true);
    }, []);

    useEffect(() => {
        if (hasMounted) {
            resolveOnMount();
        }
    }, [hasMounted]);

    // All search params trigger batch loading skeletons, except for currentPage
    useEffect(() => {
        if (hasLoaded) {
            const prevSearchParams = prevSearchParamsRef.current;
            let isCurrentPage = false;
            if (prevSearchParams) {
                isCurrentPage =
                    searchParams.currentPage !== prevSearchParams.currentPage;
            }

            debounceFilterChangefetchQueueBatches({
                showLoading: !isCurrentPage
            });
            prevSearchParamsRef.current = searchParams; // Update the ref with the current searchParams
        }
    }, [hasLoaded, searchParams]);

    if (!hasMounted || !hasLoaded) return <div>"Loading..."</div>;

    return <>{children}</>;
};

// prop-types
MountingComponent.propTypes = {
    children: PropTypes.any
};

const BatchesProvider = ({ children }) => {
    const { currentUser } = useAuth();
    const { searchParams, setUrlSearchParams } = useUrlSearchParams();
    const dispatch = useDispatch();
    const { batches, currentQueue } = useSelector(state => state);
    const { name: currentQueueName } = currentQueue;

    // User should not be able to mess with UI while calling api to avoid race conditions
    const isCallingApi = batches.apiStatus === "calling-api";

    // apollo queries
    const { fetchPaginatedBatches } = useFetchPaginatedBatches();
    const { getPhotoCountsForBatches } = useGetPhotoCountsForBatches();
    const { getUploadIdsForBatches } = useGetUploadIdsForBatches();

    const contextProps = {
        ...batches,
        currentUser,
        searchParams,
        setUrlSearchParams,
        dispatch,
        currentQueue,
        currentQueueName,
        isCallingApi,
        fetchPaginatedBatches,
        getPhotoCountsForBatches,
        getUploadIdsForBatches
    };

    // useMemo so it does not pass value on every render
    const value = useMemo(() => contextProps, [contextProps]);

    return (
        <BatchesContext.Provider value={value}>
            <MountingComponent>{children}</MountingComponent>
        </BatchesContext.Provider>
    );
};

BatchesProvider.propTypes = {
    children: PropTypes.any
};

export default BatchesProvider;
