import React from "react";
import PropTypes from "prop-types";

// components
import Template from "./Template.js";

const Submitted = ({ date }) => {
    return (
        <Template title="Submitted" width={99}>
            <div data-testid="submitted-date">{date}</div>
        </Template>
    );
};

export default Submitted;

// prop-types
Submitted.propTypes = {
    date: PropTypes.string
};
