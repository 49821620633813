import React from "react";
import PropTypes from "prop-types";

// styles
import { ImageStyled, PhotoIconStyled } from "./styles.js";

export const Image = ({
    imageSrc,
    imageIsBlurred = false,
    imgTestId = "",
    name = "",
    showPhotoIcon = false
}) => {
    if (showPhotoIcon || !imageSrc) {
        return <PhotoIconStyled size={{ width: 100, height: 100 }} />;
    }

    return (
        <ImageStyled
            data-testid={imgTestId}
            src={imageSrc}
            imageIsBlurred={imageIsBlurred}
            alt={`File name ${name}`}
        />
    );
};

Image.propTypes = {
    imageSrc: PropTypes.string,
    imageIsBlurred: PropTypes.bool,
    imgTestId: PropTypes.string,
    name: PropTypes.string,
    showPhotoIcon: PropTypes.bool
};
