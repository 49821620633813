import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { getClickableIcons } from "frontend/store/marketing";

import Hidden from "core/Hidden";
import Icon from "./Icon";

import colors from "core/colors";
import {
    OuterContainer,
    InnerContainer,
    RowContainer,
    IconRow,
    InnerRowContainer,
    IconsLandingPageMobileConstraintImg,
    RowFlex
} from "./styles";

import spacerImg from "./images/spacer.gif";

import { getIconsArrays } from "./helpers";

import { useViewport } from "js/hooks/useViewport";

const UnstyledHeroClickableIcons = ({ className, isHomepage = true }) => {
    const { browserWidth } = useViewport();

    const allIcons = useSelector(getClickableIcons);

    const arraysOfIcons = getIconsArrays({
        allIcons,
        isHomepage,
        browserWidth
    });

    const spacerImgSrc =
        !!spacerImg && spacerImg.src ? spacerImg.src : spacerImg;

    return (
        <OuterContainer className={className} isHomepage={isHomepage}>
            <InnerContainer isHomepage={isHomepage}>
                {!isHomepage && (
                    <Hidden breakpoint="mdLg">
                        <IconsLandingPageMobileConstraintImg
                            src={spacerImgSrc}
                            alt=""
                        />
                    </Hidden>
                )}

                <RowContainer isHomepage={isHomepage}>
                    <RowFlex isHomepage={isHomepage}>
                        {arraysOfIcons.map((row, index) => {
                            return (
                                <IconRow key={index} isHomepage={isHomepage}>
                                    <InnerRowContainer isHomepage={isHomepage}>
                                        {arraysOfIcons[index].map(
                                            (icon, idx) => {
                                                return (
                                                    <Icon
                                                        key={idx}
                                                        icon={icon}
                                                        isHomepage={isHomepage}
                                                    />
                                                );
                                            }
                                        )}
                                    </InnerRowContainer>
                                </IconRow>
                            );
                        })}
                    </RowFlex>
                </RowContainer>
            </InnerContainer>
        </OuterContainer>
    );
};

const HeroClickableIcons = styled(UnstyledHeroClickableIcons)``;

HeroClickableIcons.propTypes = {
    isHomepage: PropTypes.bool
};

export default HeroClickableIcons;
