export function getCookie({ name }) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== "") {
        const cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === `${name}=`) {
                cookieValue = decodeURIComponent(
                    cookie.substring(name.length + 1)
                );
                break;
            }
        }
    }
    return cookieValue;
}

export function getCsrfCookieName() {
    // Read from a global var in a Django template until we figure out
    // how to do per-environment js config.
    // This is set in base.html and photos.html.
    return typeof csrfCookieName !== "undefined" ? csrfCookieName : "csrftoken";
}

export function getCsrfTokenFromCookie() {
    return getCookie({ name: getCsrfCookieName() });
}
