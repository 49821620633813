import React from "react";

// context
import { useSiteHeader } from "core/SiteHeaderV2/context/useSiteHeader.js";

// component
export const Hamburger = () => {
    const { isDarkMode } = useSiteHeader();
    const folder = isDarkMode ? "dark" : "light";
    return (
        <div>
            <img
                className="prototype"
                alt="Mobile Menu Hamburger Toggle"
                src={`https://mitchellgohman.com/assets/img/tnp/${folder}/hamburger.png`}
            />
        </div>
    );
};

export default Hamburger;
