import NeptunePhoto from "./images/neptune_dog.png";
import BugsyPhoto from "./images/bugsy_cat.png";
import CelinePhoto from "./images/celine_cat.png";
import PiperPhoto from "./images/piper_dog.png";
import SoliPhoto from "./images/soli_dog.png";
import AlfiePhoto from "./images/alfie_dog.png";
import BeanPhoto from "./images/bean_dog.png";
import ChicoPhoto from "./images/chico_dog.png";
import ClivePhoto from "./images/clive_cat.png";
import ConstantinoplePhoto from "./images/constantinople_cat.png";
import DipperPhoto from "./images/dipper_cat.png";
import DunePhoto from "./images/dune_dog.png";
import FreyaPhoto from "./images/freya_cat.png";
import HenryPhoto from "./images/henry_dog.png";
import LaszloPhoto from "./images/lazlo_dog.png";
import MabelPhoto from "./images/mabel_dog.png";
import MelloPhoto from "./images/mello_dog.png";
import MonkiePhoto from "./images/monkie_dog.png";
import RigginsPhoto from "./images/riggins_dog.png";
import RingoPhoto from "./images/ringo_dog.png";
import TubbyPhoto from "./images/tubby_cat.png";
import TuckerPhoto from "./images/tucker_dog.png";
import ViggoPhoto from "./images/viggo_dog.png";
import ZiggyPhoto from "./images/ziggy_dog.png";

// Meta data
export const titleTag = "Noun Project Jobs | Noun Project";
export const metaDescription =
    "Noun Project believes broad perspectives are fundamental to serving our customers, and that diverse experiences are the key to innovation, collaboration, and awareness of the globalized context we live in.";

// Section 1: Careers with NP
export const sectionOneText = "Careers With Noun Project";
export const sectionOneSubText =
    "Noun Project believes broad perspectives are fundamental to serving our customers, and that diverse experiences are the key to innovation, collaboration, and awareness of the globalized context we live in.";

// Section 2: Team Culture
export const sectionTwoTitle = "Team Culture";
export const sectionTwoValues = [
    {
        title: "Do Exceptional Work",
        text:
            "We are here to do our best work. We trust our teammates to do the same and to ask for input when needed."
    },
    {
        title: "Radical Candor",
        text:
            "We kindly offer honest feedback to encourage continuous growth and improvement."
    },
    {
        title: "A Can-Do Attitude",
        text:
            "We are resourceful and open-minded problem solvers. Together, we seek to create opportunities out of challenges."
    },
    {
        title: "Mindful Collaboration",
        text:
            "We believe in strategically leveraging the collective brain power both within and across teams."
    },
    {
        title: "Disagree and Commit",
        text:
            "We encourage and expect everyone to contribute to finding the best solution, and unite behind the final decision."
    },
    {
        title: "Creativity and Innovation",
        text:
            "As a mission-driven, design-led company, creativity and innovation are at the core of what we do."
    }
];

// Section 3: Testimonial Quote
export const testimonials = [
    {
        text:
            "“Fantastic leadership and CEO who truly cares about the employees and has our best interests at the forefront, which for me, engenders a high sense of loyalty to the company.”",
        source: "Current Employee, Glassdoor Review"
    }
];

// Section 4: Who We Are
export const sectionFourText = "Who We Are";
export const sectionFourSubText =
    "Our small and mighty team includes parents, immigrants, members of the LGBTQIA+ community, teammates with disabilities, and is proudly led by a leadership team that’s 60% women.";

// Section 5: Four-Legged Crew
export const petsTitle = "Our Four-legged Crew";
export const petsData = [
    {
        name: "Neptune",
        title: "Chief Treat Officer",
        picture: NeptunePhoto
    },
    {
        name: "Piper",
        title: "Tug Of War Specialist",
        picture: PiperPhoto
    },
    {
        name: "Bugsy",
        title: "Chief Keyboard Warmer",
        picture: BugsyPhoto
    },
    {
        name: "Celine",
        title: "Fur Distributor",
        picture: CelinePhoto
    },
    {
        name: "Soli",
        title: "Bark Support",
        picture: SoliPhoto
    },
    {
        name: "Alfie",
        title: "QT Analyst",
        picture: AlfiePhoto,
        postImageAlt: "Alfie Dog"
    },
    {
        name: "Bean",
        title: "Jr. Cheese Detective",
        picture: BeanPhoto,
        postImageAlt: "Bean Dog"
    },
    {
        name: "Chico",
        title: "Snooze Specialist",
        picture: ChicoPhoto,
        postImageAlt: "Chico Dog"
    },
    {
        name: "Clive",
        title: "Threat Evaluator",
        picture: ClivePhoto,
        postImageAlt: "Clive Cat"
    },
    {
        name: "Constantinople",
        title: "Sr. Lap Supervisor",
        picture: ConstantinoplePhoto,
        postImageAlt: "Constantinople Dog"
    },
    {
        name: "Dipper",
        title: "Head of Night Patrol",
        picture: DipperPhoto,
        postImageAlt: "Dipper Cat"
    },
    {
        name: "Dune",
        title: "Senior Shredder",
        picture: DunePhoto,
        postImageAlt: "Dune Dog"
    },
    {
        name: "Freya",
        title: "Pawject Manager",
        picture: FreyaPhoto,
        postImageAlt: "Freya Cat"
    },
    {
        name: "Henry",
        title: "Anti-Social Manager",
        picture: HenryPhoto,
        postImageAlt: "Henry Dog"
    },
    {
        name: "Laszlo",
        title: "Lead Borker",
        picture: LaszloPhoto,
        postImageAlt: "Laszlo Dog"
    },
    {
        name: "Mabel",
        title: "Lunch Inspector",
        picture: MabelPhoto,
        postImageAlt: "Mabel Dog"
    },
    {
        name: "Mello",
        title: "Chief Cuddle Officer",
        picture: MelloPhoto,
        postImageAlt: "Mello Dog"
    },
    {
        name: "Monkie",
        title: "Sr. Security Dog",
        picture: MonkiePhoto,
        postImageAlt: "Monkie Dog"
    },
    {
        name: "Riggins",
        title: "Morale Manager, PT",
        picture: RigginsPhoto,
        postImageAlt: "Riggins Dog"
    },
    {
        name: "Ringo",
        title: "Chief Squirrel Hunter",
        picture: RingoPhoto,
        postImageAlt: "Ringo Dog"
    },
    {
        name: "Tubby",
        title: "Nose Freckle Coordinator",
        picture: TubbyPhoto,
        postImageAlt: "Tubby Cat"
    },
    {
        name: "Tucker",
        title: "Kitchen Supervisor",
        picture: TuckerPhoto,
        postImageAlt: "Tucker Dog"
    },
    {
        name: "Viggo",
        title: "Sr. Wiggle Engineer",
        picture: ViggoPhoto,
        postImageAlt: "Viggo Dog"
    },
    {
        name: "Ziggy",
        title: "Information Barkitect",
        picture: ZiggyPhoto,
        postImageAlt: "Ziggy Dog"
    }
];

// Section 6: Perks & Benefits
export const sectionSixTitle = "Perks & Benefits";
export const sectionSixValues = [
    {
        title: "Remote-Friendly",
        text:
            "Work from anywhere with our home-office allowance, or come to our beautiful L.A. office. Dogs and standing-desks included."
    },
    {
        title: "40 Hour Work Week",
        text:
            "No office traps, just 40 hours of focused quality work is our norm. Creativity comes from being well-rounded, not burned out."
    },
    {
        title: "Profitable & Stable Company",
        text:
            "We believe in building a company for the long-term, and are proud to be a self-sustaining, profitable business."
    },
    {
        title: "Health & Education",
        text:
            "We offer excellent health, dental and vision plans, FSA/HSA, 401K, continued education, conference stipend, and more."
    },
    {
        title: "Paid Parental & Family Leave",
        text:
            "We offer fully paid parental and family leave, because no one should lose wages to welcome a child or care for family."
    },
    {
        title: "Competitive Salary",
        text:
            "We monitor industry rates and pay competitive salary and equity based on the L.A. market, no matter where you live."
    }
];

// Section 7: Job Openings
export const sectionSevenText = "Job Openings";
export const sectionSevenSubText =
    "We strongly encourage people from backgrounds currently underrepresented in tech to apply, and look forward to welcoming the best candidate to our team regardless of their national origin, gender, race, ethnicity, religion, sexual orientation, or physical ability.";
export const joinUsBtn = [
    {
        text: "Join Us",
        url: "https://apply.workable.com/nounproject/",
        target: "_blank"
    }
];
