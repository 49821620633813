import { css } from "styled-components";

import { focusBoxShadow } from "core/links";

export const handleBoxShadowOutlineBorder = ({
    size,
    color,
    hasFocusShadow = false
}) => {
    if (hasFocusShadow) {
        return css`
            ${`inset 0px 0px 0px ${
                size === "lg" ? "2px" : "1px"
            } ${color}, ${focusBoxShadow}`}
        `;
    }

    return css`
        ${`inset 0px 0px 0px ${size === "lg" ? "2px" : "1px"} ${color}`}
    `;
};
