import React from "react";
import styled, { css } from "styled-components";

// context
import { useSiteHeader } from "core/SiteHeaderV2/context/useSiteHeader.js";

// data
import { primaryMenu } from "data/links";

// Dropdown
import { DropDown as DropDownContext } from "core/DropDown/index.js";

// components
import LinkDropDown from "core/SiteHeaderV2/components/LinkDropDown";

//styles
import { convertPxToRem } from "frontend/components/helpers";
import colors from "core/colors";
const DropDownStyled = styled(DropDownContext)`
    z-index: 100;
    left: 0rem;
    width: ${convertPxToRem(185)};
    padding: ${convertPxToRem(30)};
    ${({ $isDarkMode }) => {
        return css`
            background-color: ${$isDarkMode
                ? colors.secondary.orca
                : colors.primary.snow};
            box-shadow: 0rem 0rem ${convertPxToRem(8)}
                ${$isDarkMode
                    ? `rgba(255, 255, 255, 0.35)`
                    : `rgba(0, 0, 0, 0.35)`};
        `;
    }}
`;

// component
const DropDown = () => {
    const { isDarkMode } = useSiteHeader();
    const {
        about,
        blog,
        submitIcons,
        submitPhotos,
        customerSupport,
        jobs
    } = primaryMenu.tnp.links;

    return (
        <DropDownStyled $isDarkMode={isDarkMode}>
            <LinkDropDown link={about} />
            <LinkDropDown link={blog} />
            <LinkDropDown link={submitIcons} />
            <LinkDropDown link={submitPhotos} />
            <LinkDropDown link={customerSupport} />
            <LinkDropDown link={jobs} />
        </DropDownStyled>
    );
};

export default DropDown;
