import * as actionTypes from "../actions/actionTypes";
import { experimentsSet } from "../actions/actions";
// import { emitter, Experiment } from "react-ab-test";
// import experimentDebugger from "react-ab-test/lib/debugger";
import getParameterByName from "../legacy/helpers/get_parameter_by_name";

// Shows up when NODE_ENV != 'production', lets you switch between experiments
// experimentDebugger.enable();

export const experiments = {
    // editor: {
    //     name: "Editor",
    //     variants: ["old", "new"],
    //     weights: [90, 10]
    // }
};

let globalEnvironment = global._nounConfig && global._nounConfig.environment;
let isProduction =
    !globalEnvironment ||
    globalEnvironment == "production" ||
    globalEnvironment == "staging";

export default (state = {}, action) => {
    // switch (action.type) {
    //     case actionTypes.GET_CURRENT_USER_SUCCESS:
    //         emitter.defineVariants(
    //             experiments.editor.name,
    //             experiments.editor.variants,
    //             experiments.editor.weights
    //         );

    //         let { response: user } = action;

    //         // let editor = "old";

    //         // if (user.is_beta || user.is_superuser) {
    //         if (user.is_beta || user.is_superuser || !isProduction) {
    //             // editor = "new";
    //             emitter.setActiveVariant(experiments.editor.name, "new");
    //         }

    //         if (user.is_superuser || !isProduction) {
    //             let wantsOld = getParameterByName("old");
    //             if (wantsOld) {
    //                 // editor = "old";
    //                 emitter.setActiveVariant(experiments.editor.name, "old");
    //             }
    //         }

    //         let userIdentifier = user.id;

    //         let checkExperiment = new Experiment({
    //             name: experiments.editor.name,
    //             userIdentifier: userIdentifier && userIdentifier.toString()
    //         });

    //         let editor = checkExperiment.getLocalStorageValue();

    //         let newState = {
    //             editor,
    //             userIdentifier
    //         };

    //         action.asyncDispatch(experimentsSet(newState));

    //         return newState;
    // }

    return state;
};
