import styled from "styled-components";

// styles
import colors from "core/colors";
import { convertPxToRem } from "frontend/components/helpers";
import { regular as regularFontWeight } from "core/fontStyles";

// styled-components
export const HistoryListItemStyled = styled.div`
    ${regularFontWeight}
    margin-bottom: ${convertPxToRem(16)};
`;
export const DateStyled = styled.div`
    color: ${colors.secondary.sword};
    font-size: ${convertPxToRem(11)};
    margin-bottom: ${convertPxToRem(4)};
`;
export const MessageStyled = styled.div`
    font-size: ${convertPxToRem(15)};
    color: ${colors.secondary.orca};
    overflow-wrap: break-word;
`;
