import { useContext, useState, useMemo, useEffect, useCallback } from "react";

import AllPhotoUploadsContext from "../context/AllPhotoUploadsContext";

import { usePhotoUpload } from "frontend/hooks/usePhotoUpload";

import { removeOriginalFilenamePrefix } from "../helpers";

const useFailedPhotoUploads = () => {
    const context = useContext(AllPhotoUploadsContext);

    if (context === undefined) {
        throw new Error(
            "useFailedPhotoUploads must be used within an AllPhotoUploadsProvider"
        );
    }

    const {
        fetchFileCheckFailedUploads,
        fileCheckFailedUploadsData,
        fileCheckFailedUploadsQueryCalled,
        fileCheckFailedUploadsLoading
    } = context;

    const { uploadErrors, clearErrors } = usePhotoUpload();
    const [fileCheckErrors, setFileCheckErrors] = useState([]);

    const allUploadErrors = useMemo(
        () => [...uploadErrors, ...fileCheckErrors].sort((a, b) => a.id - b.id),
        [uploadErrors, fileCheckErrors]
    );

    const clearUploadErrors = useCallback(() => {
        setFileCheckErrors([]);
        clearErrors();
    }, [setFileCheckErrors, clearErrors]);

    const getFailedUploads = () => {
        return (
            (fileCheckFailedUploadsData &&
                fileCheckFailedUploadsData.listPhotoUploads &&
                fileCheckFailedUploadsData.listPhotoUploads.items) ||
            []
        );
    };

    const fileCheckFailedUploads = useMemo(getFailedUploads, [
        fileCheckFailedUploadsData
    ]);

    useEffect(() => {
        if (fileCheckFailedUploads && !fileCheckFailedUploads.length) return;

        const newErrors = [];

        fileCheckFailedUploads.forEach(upload => {
            const errorHasBeenRecorded = fileCheckErrors.find(
                recordedError => recordedError.id === upload.id
            );

            if (!errorHasBeenRecorded) {
                newErrors.push({
                    id: upload.id,
                    filename: removeOriginalFilenamePrefix(
                        upload.originalFilename
                    ),
                    error: upload.errors
                });
            }
        });

        setFileCheckErrors(prevErrors => [...newErrors, ...prevErrors]);
    }, [fileCheckFailedUploads]);

    return {
        fetchFileCheckFailedUploads,
        fileCheckFailedUploads,
        fileCheckFailedUploadsDataLoaded:
            fileCheckFailedUploadsQueryCalled && !fileCheckFailedUploadsLoading,
        allFailedUploadsCount: allUploadErrors.length || 0,
        allUploadErrors,
        clearUploadErrors
    };
};

export default useFailedPhotoUploads;
