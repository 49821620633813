import React from "react";
import PropTypes from "prop-types";
import format from "date-fns/format";

// hooks
import { useAuth } from "frontend/hooks/useAuth";
import { useAssignBatchToMod } from "frontend/components/fort/mod/photos/hooks/mutations/useAssignBatchToMod.js";

// redux
import { useModerators } from "../../hooks/useModerators";

// core components
import ContentComponentRow from "frontend/components/fort/mod/core/Batch/ContentComponents/ContentComponentRow";
import BatchID from "frontend/components/fort/mod/core/Batch/ContentComponents/BatchID";
import Submitted from "frontend/components/fort/mod/core/Batch/ContentComponents/Submitted";
import Contributor from "frontend/components/fort/mod/core/Batch/ContentComponents/Contributor";
import ContributorID from "frontend/components/fort/mod/core/Batch/ContentComponents/ContributorID";
import VisuallyApproved from "frontend/components/fort/mod/core/Batch/ContentComponents/VisuallyApproved";
import FullyPublished from "frontend/components/fort/mod/core/Batch/ContentComponents/FullyPublished";
import Total from "frontend/components/fort/mod/core/Batch/ContentComponents/Total";
import Moderator from "frontend/components/fort/mod/core/Batch/ContentComponents/Moderator";

// photo specific components
import Releases from "./Releases";

const PhotoModBatchProfileRow1 = ({ batch }) => {
    const { currentUser } = useAuth();
    const { filteredModerators, moderators } = useModerators();
    const { assignBatchToMod } = useAssignBatchToMod();

    const {
        id,
        moderator,
        submittedAt,
        creator = {},
        containsReleaseOrEditorialFlags,
        containsExplicitOrTextFlags,
        photoCounts = {},
        isEditable
    } = batch;

    const {
        fullyPublishedCount = 0,
        photoUploadCount = 0,
        visuallyPublishedCount = 0
    } = photoCounts;

    const onModeratorSelect = chosenModeratorId => {
        const moderatorId =
            chosenModeratorId === "unassigned" ? null : chosenModeratorId;
        assignBatchToMod({ photoBatchId: id, moderatorId });
    };

    return (
        <ContentComponentRow>
            <BatchID
                batchId={id}
                showFlag={
                    containsReleaseOrEditorialFlags ||
                    containsExplicitOrTextFlags
                }
            />
            <Submitted date={format(new Date(submittedAt), "MM/DD/YYYY")} />
            <ContributorID
                linkTestId="creator-fort-link"
                textTestId="creator-id"
                text={creator.id}
                target="_blank"
                url={`/fort/members/${creator.id}`}
            />
            <Contributor
                avatarUrl={creator.avatarUrl}
                name={creator.name}
                isFeatured={creator.isPremiumPhotographer}
            />
            <Total total={photoUploadCount} />
            {/* TODO: remove tempDisable when link to visually published browse page is ready */}
            <VisuallyApproved
                tempDisable={true}
                textTestId="vis-published-count"
                url=""
                text={visuallyPublishedCount}
                hideLink={visuallyPublishedCount === 0}
            />
            {/* TODO: remove tempDisable when link to visually published browse page is ready */}
            <FullyPublished
                tempDisable={true}
                textTestId="fully-published-count"
                url=""
                text={fullyPublishedCount}
                hideLink={fullyPublishedCount === 0}
            />
            <Releases batch={batch} />
            <Moderator
                batchId={id}
                moderator={moderator}
                moderators={moderators}
                filteredModerators={filteredModerators}
                onModeratorSelect={onModeratorSelect}
                currentUser={currentUser}
                isEditable={isEditable.moderator}
            />
        </ContentComponentRow>
    );
};

export default PhotoModBatchProfileRow1;

// prop-types
PhotoModBatchProfileRow1.propTypes = {
    batch: PropTypes.object.isRequired
};
