import { uploadsAdapter } from "./slices/uploadsSlice";
import { uploadFooterActionsAdapter } from "./slices/uploadFooterActionsSlice";

export const uploadsSelectors = uploadsAdapter.getSelectors(
    state => state.uploads
);

export const uploadFooterActionsSelectors = uploadFooterActionsAdapter.getSelectors(
    state => state.uploadFooterActions
);
