import React, { useCallback, useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";

import AddTagsContext from "../context";

import { TAGS_INIT_STATES, TAGS_PRESUBMIT_ERRORS } from "../constants";
import { detectEmoji } from "frontend/components/helpers";

/*****
 *
 * when user attempts to add duplicates, if dupe term shows more
 * than once in input, this prevents the term from showing more than once
 * example: if user enters comma list of "cat, cats, cat, animal" -
 * without transforming the data, it will show "the terms cat and cat are already in the list" -
 * removeRepeatedTerms makes it so cat only shows once in that case.
 *
 *****/
const removeRepeatedTerms = ({ originalTerms }) => {
    let repeatedTermsCounter = {};
    let termsToDisplay = [];

    for (let term of originalTerms) {
        repeatedTermsCounter[term] = repeatedTermsCounter[term]
            ? repeatedTermsCounter[term] + 1
            : 1;
    }

    for (let key in repeatedTermsCounter) {
        termsToDisplay.push(key);
    }

    return termsToDisplay;
};

export const useAddTags = ({
    ariaId,
    labelText,
    placeholderText = "",
    isRequired = false,
    isDisabled = false,
    minLength = 3,
    buttonText = "Add",
    defaultInformationalText = "",
    onSubmit,
    onDelete,
    photoUploadIds
} = {}) => {
    const {
        isFocused,
        updateFocused,
        hasInputError,
        setInputError,
        isTermAdded,
        setTermAdded,
        suggestedTags,
        suggestedTagsQuery,
        setSuggestedTags,
        tagInputValue,
        setTagInputValue
    } = useContext(AddTagsContext);

    // form full ID for input association:
    const inputId = `add-tags-${ariaId}`;

    //create disabled text:
    const disabledLabelText = `${labelText} input is disabled`;

    // clears error & informational text when focused:
    const handleFocus = useCallback(() => {
        if (!isDisabled) {
            updateFocused(true);
            setInputError(TAGS_INIT_STATES.ERRORS);
            setTermAdded(TAGS_INIT_STATES.TERM_ADDED);
        }
    }, [isDisabled, isTermAdded, hasInputError, photoUploadIds]);

    const handleBlur = useCallback(() => {
        updateFocused(false);
    }, []);

    const handleClearInput = () => {
        setTagInputValue("");
    };

    // begin storing pre-submission input values so we can validate the values before allowing them to be added:
    let allInputValues = [];

    // logic for adding a term
    const handleAddTerm = useCallback(
        async allInputValues => {
            if (onSubmit) await onSubmit(allInputValues);

            await setTermAdded([...allInputValues]);
            handleClearInput();
        },
        [photoUploadIds]
    );

    // logic for removing a term:
    const handleDeleteTerm = useCallback(
        async val => {
            if (onDelete) await onDelete(val);
        },
        [photoUploadIds]
    );

    // all error & submission handling:
    const handleSubmit = useCallback(
        e => {
            e.preventDefault();

            allInputValues = [];

            // get input value:
            const hasInputValue = document.getElementById(inputId).value.trim();

            // manipulate values by splitting + storing if a comma is found or just storing if no comma is found:
            if (hasInputValue.includes(",")) {
                allInputValues.push(...hasInputValue.split(","));
            } else {
                allInputValues.push(hasInputValue);
            }

            let hasEmoji;
            //remove all array items only containing spaces & format values to lowercase + remove trailing spaces:
            allInputValues = allInputValues
                .filter(element => {
                    if (detectEmoji(element)) {
                        hasEmoji = true; // filters out emoji value
                    } else if (element !== "") {
                        return element;
                    }
                })
                .map(val => val.trim());

            const termsToSubmit = removeRepeatedTerms({
                originalTerms: allInputValues
            });

            //validation rules:
            const isEmpty =
                !hasInputValue ||
                termsToSubmit.length === 0 ||
                hasInputValue === "";

            const isOnlyCommaAndOrSpace = /^[, ]*$/.test(hasInputValue);

            if (hasEmoji) {
                setTermAdded(TAGS_INIT_STATES.TERM_ADDED);
                setInputError([
                    {
                        reason: TAGS_PRESUBMIT_ERRORS.INVALID_CHAR,
                        tags: "",
                        message:
                            "Tag not saved because it contains invalid characters."
                    }
                ]);
            } else if (isEmpty || isOnlyCommaAndOrSpace) {
                setTermAdded(TAGS_INIT_STATES.TERM_ADDED);
                setInputError([
                    {
                        reason: TAGS_PRESUBMIT_ERRORS.EMPTY_TERM,
                        tags: "",
                        message: "Please enter a term before attempting to add."
                    }
                ]);
            } else {
                handleAddTerm(termsToSubmit);
            }
        },
        [setInputError, photoUploadIds]
    );

    // handle disabled input:
    const handleDisableInput = useCallback(() => {
        // set to read-only because "disabled" attr is not SR/keyboard-friendly:
        document.getElementById(inputId).setAttribute("readonly", "true");
        document
            .getElementById(inputId)
            .setAttribute("aria-label", disabledLabelText);
    }, []);

    // create async/await function out of disabled input func:
    const handleIsDisabled = useCallback(async () => {
        await handleDisableInput();

        await handleClearInput();
    }, []);

    // handle re-enable input:
    const handleIsEnabled = useCallback(() => {
        document.getElementById(inputId).removeAttribute("readonly");
        document.getElementById(inputId).removeAttribute("aria-label");
    }, []);

    // do isDisabled stuff:
    useEffect(() => {
        if (document.getElementById(inputId)) {
            if (isDisabled) {
                handleIsDisabled();
            } else if (!isDisabled) {
                handleIsEnabled();
            }
        }
    }, [isDisabled]);

    // do focus/blur stuff:
    useEffect(() => {
        document.getElementById(inputId).addEventListener("focus", handleFocus);

        document
            .getElementById(inputId)
            .addEventListener("blur", () => handleBlur);
    }, [handleFocus, handleBlur]);

    return {
        inputId,
        handleFocus,
        handleBlur,
        isFocused,
        handleSubmit,
        hasInputError,
        setInputError,
        ariaId,
        labelText,
        placeholderText,
        isRequired,
        minLength,
        buttonText,
        isTermAdded,
        setTermAdded,
        isDisabled,
        defaultInformationalText,
        handleDeleteTerm,
        disabledLabelText,
        suggestedTags,
        suggestedTagsQuery,
        setSuggestedTags,
        tagInputValue,
        setTagInputValue
    };
};

useAddTags.propTypes = {
    ariaId: PropTypes.string.isRequired,
    labelText: PropTypes.string.isRequired,
    placeholderText: PropTypes.string,
    isRequired: PropTypes.bool,
    isDisabled: PropTypes.bool,
    minLength: PropTypes.number,
    buttonText: PropTypes.string,
    defaultInformationalText: PropTypes.any,
    onSubmit: PropTypes.func,
    onDelete: PropTypes.func,
    photoUploadIds: PropTypes.array
};

export const AddTagsProvider = ({ children }) => {
    const [isFocused, updateFocused] = useState(false);
    const [isTermAdded, setTermAdded] = useState(TAGS_INIT_STATES.TERM_ADDED);
    const [hasInputError, setInputError] = useState(TAGS_INIT_STATES.ERRORS);
    const [suggestedTags, setSuggestedTags] = useState([]);
    const [suggestedTagsQuery, setSuggestedTagsQuery] = useState("");
    const [tagInputValue, setTagInputValue] = useState("");

    const setSuggestedTagsAndQuery = (tags, query = "") => {
        setSuggestedTags(tags);
        setSuggestedTagsQuery(query);
    };

    return (
        <AddTagsContext.Provider
            value={{
                isFocused,
                updateFocused,
                hasInputError,
                setInputError,
                isTermAdded,
                setTermAdded,
                suggestedTags,
                setSuggestedTags: setSuggestedTagsAndQuery,
                suggestedTagsQuery,
                tagInputValue,
                setTagInputValue
            }}
        >
            {children}
        </AddTagsContext.Provider>
    );
};
