import React from "react";
import styled from "styled-components";

import avatar from "assets/img/avatar-placeholder.jpg";

const Img = styled.img`
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
`;

const AvatarPlaceholderImg = () => {
    return <Img src={avatar} alt="User profile image" />;
};

export default AvatarPlaceholderImg;
