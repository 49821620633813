import React from "react";

import { CREATOR_DASHBOARD_PHOTOS_PENDING } from "data/links";
import { useCurrentUser } from "contexts/CurrentUserContext";

import { Button } from "./styles";

const SubmissionStatusButton = () => {
    const user = useCurrentUser();
    const username = (user && user.username) || "";

    return (
        <Button
            href={CREATOR_DASHBOARD_PHOTOS_PENDING.replace(
                ":username",
                username
            )}
        >
            See Submission Status
        </Button>
    );
};

export default SubmissionStatusButton;
