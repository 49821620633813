import { useApolloMutation } from "frontend/utils/apolloApi/useApolloMutation.js";

// gql
import { SET_NEEDS_RELEASE_STATUS_ON_UPLOADS } from "frontend/components/fort/mod/photos/gql/mutations";

// utils
import { useApiUploadUpdate } from "frontend/components/fort/mod/photos/hooks/useApiUploadUpdate.js";

// hooks
import { useFetchNonModMutationSynch } from "../../queries/useFetchNonModMutationSynch";

export const useClearReleaseFlag = () => {
    const { fetchNonModMutationSynch } = useFetchNonModMutationSynch();
    const { apiUploadUpdate } = useApiUploadUpdate();

    const { apolloMutate } = useApolloMutation({
        actionDesc: "Uploads: Flags: Releases",
        gql: SET_NEEDS_RELEASE_STATUS_ON_UPLOADS
    });

    const clearReleaseFlag = async photoUploadIds => {
        const { ssResp } = await apolloMutate({
            variables: {
                photoUploadIds,
                value: false
            }
        });

        apiUploadUpdate(ssResp);

        // Cannot rely on above query to synch all MOD props
        fetchNonModMutationSynch();
    };

    return {
        clearReleaseFlag
    };
};
