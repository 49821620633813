import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import Empty from "./Empty";

import {
    DropzoneContainer,
    DropzoneStage,
    InnerContainer,
    DropzoneInput
} from "./styles";

const UnstyledDropzone = ({
    className,
    getRootProps,
    getInputProps,
    initialMaximumFiles,
    isDropzoneEmpty,
    isDragAccept,
    isDragReject,
    setDisabledDropzone,
    currentlyProcessingUploads
}) => {
    return (
        <DropzoneContainer className={className}>
            <DropzoneStage
                data-testid="dropzone-stage"
                isDropzoneEmpty={isDropzoneEmpty}
                isDragAccept={isDragAccept}
                isDragReject={isDragReject}
                currentlyProcessingUploads={currentlyProcessingUploads}
                {...getRootProps()}
            >
                <InnerContainer data-testid="dropzone-div">
                    <DropzoneInput
                        {...getInputProps()}
                        data-testid="dropzone-input"
                    />
                    {!currentlyProcessingUploads && isDropzoneEmpty && (
                        <Empty
                            initialMaximumFiles={initialMaximumFiles}
                            setDisabledDropzone={setDisabledDropzone}
                        />
                    )}
                </InnerContainer>
            </DropzoneStage>
        </DropzoneContainer>
    );
};

const Dropzone = styled(UnstyledDropzone)``;

Dropzone.propTypes = {
    initialMaximumFiles: PropTypes.number,
    maxFiles: PropTypes.number,
    minFileSizeBytes: PropTypes.number,
    maxFileSizeBytes: PropTypes.number,
    getRootProps: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    getInputProps: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    isDropzoneEmpty: PropTypes.bool,
    isDragAccept: PropTypes.bool,
    isDragReject: PropTypes.bool,
    acceptedFiles: PropTypes.array,
    isDisabledDropzone: PropTypes.bool,
    setDisabledDropzone: PropTypes.func,
    handleClearErrors: PropTypes.func,
    hasRejectedFileErrors: PropTypes.array,
    rejectedFilesCount: PropTypes.number
};

export default Dropzone;
