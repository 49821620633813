import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import sample from "lodash/sample";
import reduce from "lodash/reduce";
import difference from "lodash/difference";
import localStorage from "../legacy/helpers/localStorage";
import * as actionTypes from "../actions/actionTypes";
import * as actions from "../actions/actions";

const FLIP_HORIZONTAL = "FLIP_HORIZONTAL";
const FLIP_VERTICAL = "FLIP_VERTICAL";
const ROTATE_CCW = "ROTATE_CCW";
const ROTATE_CW = "ROTATE_CW";
const SET_ROTATION = "SET_ROTATION";
const ICON_COLOR = "ICON_COLOR";
const BACKGROUND_COLOR = "BACKGROUND_COLOR";
const BACKGROUND_SHAPE = "BACKGROUND_SHAPE";
const EXPORT_SIZE = "EXPORT_SIZE";

const PALETTE = "PALETTE";
const PALETTE_MODE = "PALETTE_MODE";

const TRANSPARENT = "transparent";
export const BLACK = "#000000";
export const WHITE = "#FFFFFF";

export const EditorTransforms = {
    FLIP_VERTICAL,
    FLIP_HORIZONTAL,
    ROTATE_CCW,
    ROTATE_CW,
    ICON_COLOR,
    BACKGROUND_COLOR,
    BACKGROUND_SHAPE,
    EXPORT_SIZE,
    SET_ROTATION
};

const PersistentTransforms = [
    EXPORT_SIZE,
    ICON_COLOR,
    BACKGROUND_COLOR,
    BACKGROUND_SHAPE
];

const TransformStateKeys = {
    FLIP_VERTICAL: "scaleX",
    FLIP_HORIZONTAL: "scaleY",
    ROTATE_CCW: "rotate",
    ROTATE_CW: "rotate",
    ICON_COLOR: "color",
    BACKGROUND_COLOR: "backgroundColor",
    BACKGROUND_SHAPE: "backgroundShape",
    EXPORT_SIZE: "exportSize"
};

const MODE_PICKER = "MODE_PICKER";
const MODE_PALETTE = "MODE_PALETTE";
export const PaletteModes = {
    MODE_PICKER,
    MODE_PALETTE
};

const StateKeyTransforms = reduce(
    TransformStateKeys,
    (acc, stateKey, transform) => {
        acc[stateKey] = transform;
        return acc;
    },
    {}
);

export const UpsellTransforms = Object.keys(EditorTransforms).filter(
    t => t == EXPORT_SIZE
);

export const calculateShouldUpsell = (changed = []) => {
    return !!changed.filter(
        t => !UpsellTransforms.includes(StateKeyTransforms[t])
    ).length;
};

export const ExportSizes = [100, 128, 512, 1200, 3600];

export const ExportSizeLabels = {
    100: "xS",
    128: "S",
    512: "M",
    1200: "L",
    3600: "xL"
};

const RING = "RING";
const CIRCLE = "CIRCLE";
const SQUARE = "SQUARE";
const ROUNDED_RECT = "ROUNDED_RECT";
const NONE = "NONE";

export const EditorBackgroundShapes = {
    RING,
    CIRCLE,
    SQUARE,
    ROUNDED_RECT,
    NONE
};

export const EditorBackgroundShapeNames = {
    RING: "Ring",
    CIRCLE: "Circle",
    SQUARE: "Square",
    ROUNDED_RECT: "Rounded",
    NONE: "None"
};

let initialTransforms = {
    scaleX: 0.69,
    scaleY: 0.69,
    backgroundColor: TRANSPARENT,
    backgroundShape: NONE,
    color: BLACK,
    exportSize: 1200,
    rotate: 0
};

let initialPalette = [
    BLACK,
    WHITE,
    "#EC5D57",
    "#F38F19",
    "#F5D327",
    "#70C041",
    "#51A7F9",
    "#B36AE2"
];

let maxPaletteLength = 10;

const makeTransformKey = transform => `editor.${transform}`;
const persistTransform = (transform, data) => {
    let transformKey = makeTransformKey(transform);
    localStorage.setItem(transformKey, JSON.stringify(data));
};
const clearTransform = transform => {
    let transformKey = makeTransformKey(transform);
    localStorage.removeItem(transformKey);
};
const loadTransform = transform => {
    let transformKey = makeTransformKey(transform);
    let data = localStorage.getItem(transformKey);

    try {
        data = JSON.parse(data);
    } catch (e) {
        // console.warn("load error", transformKey, e);
        data = null;
    }

    return data;
};

/**
 * Gets the sign of a number
 *
 * @param {number} r Number to check sign of
 * returns {number} 1 or -1 depending on the sign of the number (or NaN)
 */
export function sign(x) {
    return typeof x === "number"
        ? x
            ? x < 0
                ? -1
                : 1
            : x === x
            ? 0
            : NaN
        : NaN;
}

let isPlugin = global._nounConfig && !!global._nounConfig.pluginHost;
const getStoredOrInitialPalette = () => {
    let palette = loadTransform(PALETTE);

    let legacyPaletteKey = "customColorPalette";
    let legacyPalette = localStorage.getItem(legacyPaletteKey);
    if (!palette && legacyPalette) {
        try {
            legacyPalette = JSON.parse(legacyPalette);
            legacyPalette = legacyPalette.map(c => c.hex);
        } catch (e) {
            // console.warn("loading legacy palette failed", legacyPalette);
        }
        persistTransform(PALETTE, legacyPalette);
    }

    if (!palette) {
        palette = initialPalette;
    }

    return palette;
};

const getStoredOrInitialPaletteMode = () =>
    loadTransform(PALETTE_MODE) || MODE_PALETTE;

const loadPersistedState = () => {
    let loadState = PersistentTransforms.reduce((loadState, transform) => {
        let stateKey = TransformStateKeys[transform];
        let stored = loadTransform(transform);
        if (typeof stored != "undefined" && stored != null) {
            loadState[stateKey] = stored;
        }

        return loadState;
    }, {});

    let palette = getStoredOrInitialPalette();
    let paletteMode = getStoredOrInitialPaletteMode();
    let newState = {
        transforms: {
            ...initialTransforms,
            ...loadState
        },
        palette,
        paletteMode
    };

    newState.initial = isEqual(initialTransforms, newState.transforms);
    newState.changed = calculateChanged(initialTransforms, newState.transforms);
    newState.shouldUpsell = calculateShouldUpsell(newState.changed);

    // newState.initial = hasChanged(newState.changed);
    return newState;
};

const calculateChanged = (initialTransforms, newTransforms) => {
    let changed = [];
    Object.keys(initialTransforms).forEach(transform => {
        if (newTransforms[transform] != initialTransforms[transform]) {
            changed.push(transform);
        }
    });
    return changed;
};

// unedited/icons with only color changed don't need the SVG wrapped
const calculateNeedsWrap = (changed = []) =>
    !!difference(changed, ["color", "exportSize"]).length;

const hasChanged = changed => {
    return isEmpty(changed);
};

export default (state = null, action) => {
    if (state == null) {
        state = loadPersistedState();
    }
    let { processing } = state;

    switch (action.type) {
        case "EDITOR_RESET": {
            if (action.preserveEdits) {
                return state;
            }

            PersistentTransforms.forEach(clearTransform);

            return {
                initial: true,
                transforms: initialTransforms,
                palette: getStoredOrInitialPalette(),
                paletteMode: getStoredOrInitialPaletteMode(),
                changed: [],
                needsWrap: false,
                iconId: state.iconId
            };
        }

        // Render the edited version of the current icon
        // when its SVG loads
        case "FETCH_SVG_DATA_SUCCESS": {
            if (!state.svg && action.iconId == state.iconId) {
                let svg = action.response;
                action.asyncDispatch(actions.editorRender(svg));
            }
            return state;
        }
        // When an icon is selected, if the svg is already available
        // render out a version for the editor
        case "DOWNLOAD_SET_FLOW_ACTIVE": {
            let { id } = action.icon;
            if (id != state.iconId) {
                let newState = loadPersistedState();

                if (action.cachedSvg) {
                    action.asyncDispatch(
                        actions.editorRender(action.cachedSvg)
                    );
                }

                let needsWrap = calculateNeedsWrap(newState.changed);

                return {
                    ...newState,
                    iconId: id,
                    svg: null,
                    needsWrap
                };
            } else {
                return state;
            }
        }

        case "DOWNLOAD_RESET_FLOW":
            if (action.preserveEdits) {
                return state;
            }

            return loadPersistedState();
        case "EDITOR_TRANSFORM": {
            let { transform, data } = action;
            if (!EditorTransforms[transform]) {
                console.error("unknown transform", action);
                return state;
            }

            let { palette } = state;

            let {
                scaleX,
                scaleY,
                backgroundColor,
                backgroundShape,
                color,
                exportSize,
                rotate
            } = state.transforms;

            let changedTransforms = [transform];
            switch (transform) {
                case FLIP_HORIZONTAL: {
                    scaleX *= -1;
                    break;
                }
                case FLIP_VERTICAL: {
                    scaleY *= -1;
                    break;
                }
                case ROTATE_CW: {
                    rotate += 15 * sign(scaleX) * sign(scaleY);
                    break;
                }
                case ROTATE_CCW: {
                    rotate -= 15 * sign(scaleX) * sign(scaleY);
                    break;
                }
                case SET_ROTATION: {
                    rotate = data % 360;
                    break;
                }
                case BACKGROUND_COLOR: {
                    backgroundColor = data;
                    if (
                        backgroundShape == NONE &&
                        backgroundColor != TRANSPARENT
                    ) {
                        backgroundShape = SQUARE;
                        changedTransforms.push(BACKGROUND_SHAPE);
                    }
                    break;
                }
                case BACKGROUND_SHAPE: {
                    backgroundShape = data;
                    if (
                        backgroundColor == TRANSPARENT &&
                        backgroundShape != NONE
                    ) {
                        if (backgroundShape == RING) {
                            backgroundColor = color;
                        } else {
                            backgroundColor =
                                sample(palette.filter(c => c != color)) ||
                                WHITE;
                        }
                        changedTransforms.push(BACKGROUND_COLOR);
                    }

                    if (backgroundShape == NONE) {
                        backgroundColor = TRANSPARENT;
                        changedTransforms.push(BACKGROUND_COLOR);
                    }
                    break;
                }
                case ICON_COLOR: {
                    color = data;
                    break;
                }
                case EXPORT_SIZE: {
                    exportSize = data;
                    break;
                }
            }

            let newState = {
                ...state,
                transforms: {
                    scaleX,
                    scaleY,
                    backgroundColor,
                    backgroundShape,
                    color,
                    exportSize,
                    rotate: rotate % 360
                },
                initial: false
            };

            changedTransforms.forEach(transform => {
                if (PersistentTransforms.includes(transform)) {
                    let key = TransformStateKeys[transform];
                    let value = newState.transforms[key];
                    persistTransform(transform, value);
                }
            });

            let changed = calculateChanged(
                initialTransforms,
                newState.transforms
            );
            let shouldUpsell = calculateShouldUpsell(changed);
            let needsWrap = calculateNeedsWrap(changed);

            return {
                ...newState,
                changed,
                initial: hasChanged(changed),
                shouldUpsell,
                needsWrap
            };
        }

        case actionTypes.EDITOR_ADD_COLOR: {
            let { palette } = state;
            let { color } = action;
            if (color != TRANSPARENT && !palette.includes(color)) {
                palette = palette.slice();
                palette.push(color);
                if (palette.length > maxPaletteLength) {
                    palette = palette.slice(1, maxPaletteLength + 1);
                }
                persistTransform(PALETTE, palette);
            }

            return {
                ...state,
                palette
            };
        }

        case actionTypes.EDITOR_CACHE_SVG: {
            let { svg } = action;
            return {
                ...state,
                svg
            };
        }

        case actionTypes.EDITOR_SET_PALETTE_MODE: {
            let { mode } = action;
            persistTransform(PALETTE_MODE, mode);
            return {
                ...state,
                paletteMode: mode
            };
        }

        default:
            return state;
    }
};
