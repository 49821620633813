import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";

import { GET_CURRENT_PHOTO_BATCH } from "./mutations";
import { GET_PHOTO_SHOOT_FOR_ADDING_UPLOADS } from "./queries";
import { CREATE_PHOTO_SHOOT } from "frontend/components/creator-tools/PhotoUploadPage/PhotoUploadPhotoShoot/mutations";
import { FETCH_UPLOAD_BATCHES } from "frontend/components/creator-tools/PhotoUploadPage/PhotoUploadCore/queries";

import { useMessageStack } from "core/MessageStack/useMessageStack";

const DEBUG_MODE = false;

/**
 * Manage photo batch & photo shoot for photo uploads.
 *
 * This hook is mostly meant to be called from usePhotoUpload hook, to bundle
 * fetch & create logic for photo upload "container" objects. Displays global
 * error messages if there are any.
 */
export const usePhotoBatch = () => {
    const [photoBatchError, setPhotoBatchError] = useState(null);
    const { addMessages } = useMessageStack();

    const { data: batchData, loading: batchDataLoading } = useQuery(
        FETCH_UPLOAD_BATCHES,
        {
            variables: { moderationStatuses: ["BATCH_UNSUBMITTED"] },
            fetchPolicy: "network-only",
            onError: e => {
                if (DEBUG_MODE) console.error(e);
            }
        }
    );

    const { data: shootData, loading: shootDataLoading } = useQuery(
        GET_PHOTO_SHOOT_FOR_ADDING_UPLOADS,
        {
            fetchPolicy: "network-only"
        }
    );

    // get or create photo batch for uploading
    const [createPhotoBatch, { data: createdBatchData }] = useMutation(
        GET_CURRENT_PHOTO_BATCH,
        {
            refetchQueries: ["FetchUploadBatches"],
            onCompleted: ({ getPhotoBatchForUploading }) => {
                if (getPhotoBatchForUploading.ok === false) {
                    setPhotoBatchError(getPhotoBatchForUploading.error);
                }
            },
            onError: e => {
                if (DEBUG_MODE) console.error(e);
                setPhotoBatchError("Unable to initialize photo batch.");
            }
        }
    );

    // create a photo shoot, if needed
    const [createPhotoShoot, { data: createdShootData }] = useMutation(
        CREATE_PHOTO_SHOOT,
        {
            refetchQueries: ["GetPhotoShootForAddingPhotoUploads"],
            onError: e => {
                if (DEBUG_MODE) console.error(e);
                setPhotoBatchError("Unable to create photo shoot.");
            }
        }
    );

    const isBatchLoaded =
        !batchDataLoading && (!!batchData || !!createdBatchData);
    const isShootLoaded =
        !shootDataLoading && (!!shootData || !!createdShootData);

    const batch =
        batchData &&
        batchData.batches &&
        batchData.batches.items.length &&
        batchData.batches.items[0];

    const createdBatch =
        createdBatchData &&
        createdBatchData.getPhotoBatchForUploading &&
        createdBatchData.getPhotoBatchForUploading.batch;

    const shoot = shootData && shootData.shoot;

    const createdShoot =
        createdShootData && createdShootData.createPhotoShoot.photoShoot;

    useEffect(() => {
        if (photoBatchError) {
            addMessages([
                {
                    id: "photo-batch-error",
                    infoType: "error",
                    text: photoBatchError
                }
            ]);
        }
    }, [photoBatchError]);

    return {
        batch: batch || createdBatch,
        isBatchLoaded,
        error: photoBatchError,
        shoot: shoot || createdShoot,
        isShootLoaded,
        createPhotoBatch,
        createPhotoShoot
    };
};
