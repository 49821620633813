//
// TODO:
//
// Remove this file once the home page is launched on SSR:

export const LEGACY_SET_ICONS = "marketing/set-icons";

const initialState = {
    clickableIcons: []
};

export default (state = initialState, action) => {
    if (action.type === LEGACY_SET_ICONS) {
        return {
            ...state,
            clickableIcons: action.icons
        };
    }
    return state;
};
