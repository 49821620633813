import { useSelector } from "react-redux";
import { useSelect } from "frontend/components/core/Grid2/GridItemContentTemplates/Select/SelectContext";

// This must be done in the view state, when we have access to useSelect and Selected Batch
export const useModerationBandIsEnabled = () => {
    const { entities: uploadEntities } = useSelector(state => state.uploads);
    const { entities: batchEntities } = useSelector(state => state.batches);
    const selectedBatch = useSelector(state => state.selectedBatch);
    const selectedBatchObj = batchEntities[selectedBatch.id];
    const { getSelectedUploadIds, uploadsSelected = {} } = useSelect();
    const selectedUploadIds = getSelectedUploadIds(uploadsSelected);
    const uploadToggleBand = selectedBatchObj?.isEditable?.uploadToggleBand;
    const multipleSelected = selectedUploadIds.length > 1;

    // we only can rely on this check if uploads are selected
    // individual unselected uploads will have to check at the upload object level.
    // e.g. heartIsEnabled && !upload.creatorIsPremium
    const creatorIsPremium = selectedUploadIds.find(suid => {
        const thisUpload = uploadEntities[suid];
        return thisUpload.creatorIsPremium;
    });

    return {
        upload: {
            defaultIsEnabled: !multipleSelected && uploadToggleBand,
            expandIsEnabled: !multipleSelected,
            heartIsEnabled: !multipleSelected && uploadToggleBand
        },
        queueHeader: {
            defaultIsEnabled: multipleSelected && uploadToggleBand,
            expandIsEnabled: multipleSelected,
            heartIsEnabled:
                multipleSelected && uploadToggleBand && !creatorIsPremium
        }
    };
};
