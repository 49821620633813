import React from "react";
import PropTypes from "prop-types";

// styles
import { RootMessageStackZoneStyled } from "./styles";

// components
import MessageList from "./MessageList";

// See Readme about this special component
export const RootMessageStackZone = ({ anchorPosition }) => {
    return (
        <RootMessageStackZoneStyled>
            <MessageList anchorPosition={anchorPosition} />
        </RootMessageStackZoneStyled>
    );
};

// prop-types
RootMessageStackZone.propTypes = {
    anchorPosition: PropTypes.object
};
