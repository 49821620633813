import styled from "styled-components";
import PropTypes from "prop-types";

import { buildGrid } from "./helpers";

let SquareGrid = ({
    className,
    gridItemContent,
    numberOfColumns,
    gridSpacingSize
}) => {
    if (!numberOfColumns) return null;

    return buildGrid({
        className,
        numberOfColumns,
        gridItemContent,
        gridSpacingSize
    });
};

SquareGrid = styled(SquareGrid)``;

SquareGrid.propTypes = {
    className: PropTypes.string,
    gridItemContent: PropTypes.arrayOf(PropTypes.object).isRequired,
    numberOfColumns: PropTypes.number.isRequired,
    gridSpacingSize: PropTypes.string.isRequired
};

export default SquareGrid;
