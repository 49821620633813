import React from "react";
import PropTypes from "prop-types";

// context
import { useDropDown } from "../useDropDown";

// components
import { UnstyledButton } from "core/Button/styles.js";

// component
export const Hotspot = ({ className, children }) => {
    const { toggleIsOpen, id, isOpen } = useDropDown();
    return (
        <UnstyledButton
            className={className}
            onClick={toggleIsOpen}
            id={`${id}-dropdown-trigger`}
            aria-haspopup="menu"
            aria-expanded={isOpen ? "true" : "false"}
            aria-controls={`${id}-dropdown-panel`}
        >
            {children}
        </UnstyledButton>
    );
};

export default Hotspot;

// prop-types
Hotspot.propTypes = {
    className: PropTypes.string,
    children: PropTypes.any
};
