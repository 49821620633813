import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// QueueContext
import { useQueueContext } from "frontend/components/fort/mod/core/Layouts/QueueLayout";

// components
import ApproveButtonBelow from "frontend/components/core/InlineTextEdit/variations/ApproveButtonBelow/index.js";

// styles
import { convertPxToRem } from "frontend/components/helpers";
export const TitleStyled = styled.div`
    margin-top: ${convertPxToRem(16)};
`;

// component
export const Title = ({ onSaveTitle, onToggleApproveTitle }) => {
    const {
        uploadType,
        selectedBatchObj,
        state: { singleUploadSelected }
    } = useQueueContext();

    if (!singleUploadSelected) {
        return null;
    }
    // Upload
    const { id } = singleUploadSelected;

    const title =
        uploadType === "photo"
            ? singleUploadSelected.photoTitle
            : singleUploadSelected.iconTitle;

    const titleApproved =
        uploadType === "photo"
            ? singleUploadSelected.photoTitleApproved
            : singleUploadSelected.iconTitleApproved;

    const inlineTextHandlers = {
        id: `photo-title-${id}`,
        text: title,
        onSave: onSaveTitle,
        isApproved: !!titleApproved,
        onToggleApprove: onToggleApproveTitle
    };

    return (
        <TitleStyled>
            <ApproveButtonBelow
                inlineTextHandlers={inlineTextHandlers}
                ariaLabel="Photo Title"
                placeholder="Add Title"
                isDisabled={!selectedBatchObj?.isEditable?.uploadTitle}
            />
        </TitleStyled>
    );
};

export default Title;

// prop-types
Title.propTypes = {
    onSaveTitle: PropTypes.func.isRequired,
    onToggleApproveTitle: PropTypes.func.isRequired
};
