import React from "react";

import { MILLIONS_OF_ICONS } from "core/constants";

// components
import OptionLabel from "./OptionLabel";
import OptionLabelOverrideMobile from "./OptionLabelOverrideMobile";

//  icons
import Icon from "core/ReusableIcons/Icon";
import IconSets from "core/ReusableIcons/Collections";
import Photo from "core/ReusableIcons/Photo";

// Placeholders
const icons = {
    desktop: `Search over ${MILLIONS_OF_ICONS} million icons`,
    tablet: `Search over ${MILLIONS_OF_ICONS} million icons`,
    mobile: "Search"
};
const placeholders = {
    icons,
    iconCollections: { ...icons },
    photos: {
        desktop: "Search art-quality photos",
        tablet: "Search photos",
        mobile: "Search"
    }
};

export const getDropDownOptions = device => {
    let icons = {
        id: 1,
        searchParamKey: "icons",
        placeholder: placeholders.icons[device],
        label: <OptionLabel text="Icons" Icon={Icon} />
    };

    let iconSets = {
        id: 2,
        searchParamKey: "icon-collections",
        placeholder: placeholders.iconCollections[device],
        label: <OptionLabel text="Icon Sets" Icon={IconSets} />
    };
    let photos = {
        id: 3,
        searchParamKey: "photos",
        placeholder: placeholders.photos[device],
        label: <OptionLabel text="Photos" Icon={Photo} />
    };

    if (device === "mobile") {
        icons.controlLabelOverride = (
            <OptionLabelOverrideMobile text="Icons" Icon={Icon} />
        );
        iconSets.controlLabelOverride = (
            <OptionLabelOverrideMobile text="Icon Sets" Icon={IconSets} />
        );
        photos.controlLabelOverride = (
            <OptionLabelOverrideMobile text="Photos" Icon={Photo} />
        );
    }

    return [icons, iconSets, photos];
};

const getSelectedOption = (options, id) => {
    return options.find(o => o.id === id) || options[0];
};

export const getDropDownProps = (device, selectedOptionId = 1) => {
    const options = getDropDownOptions(device);
    const selectedOption = getSelectedOption(options, selectedOptionId);
    return {
        selectedOption,
        options
    };
};
