import React, { useEffect } from "react";
import { useMutation } from "@apollo/client";

import BodyText from "core/BodyText";
import Checkbox from "core/Checkbox";

import { BlueLink, RecognizablePeopleOrPropertyContainer } from "./styles";

import { SET_HAS_RECOGNIZABLE_PEOPLE_PROPERTY } from "./mutations";

import { useEditPhotoUpload } from "./EditPhotoUploadContext";

import { normalizeValues } from "./helpers";

const RecognizablePeopleOrPropertySection = ({ setIsChecked, isChecked }) => {
    const { uploadIds = [], uploads = [] } = useEditPhotoUpload();

    const [setRecognizablePeopleProperty] = useMutation(
        SET_HAS_RECOGNIZABLE_PEOPLE_PROPERTY
    );

    useEffect(() => {
        const recognizablePeoplePropertyValues = uploads.map(
            upload => upload.hasRecognizablePeopleOrProperty
        );

        setIsChecked(normalizeValues(recognizablePeoplePropertyValues));
    }, [uploads]);

    const handleClick = selectedValue => {
        return setRecognizablePeopleProperty({
            variables: {
                photoUploadIds: uploadIds,
                value: selectedValue
            }
        });
    };

    return (
        <RecognizablePeopleOrPropertyContainer>
            <Checkbox
                ariaLabel={`${
                    isChecked ? "Has" : "Does not have"
                } recognizable people or property`}
                label="Recognizable people or property?"
                onClick={selectedValue => handleClick(selectedValue)}
                isChecked={isChecked}
            />

            <BodyText size="sm" type="div">
                Read about our{" "}
                <BlueLink
                    href="https://help.thenounproject.com/hc/en-us/articles/20487895422235-Model-Property-Release-requirements"
                    target="_blank"
                >
                    release requirements
                </BlueLink>
            </BodyText>
        </RecognizablePeopleOrPropertyContainer>
    );
};

export default RecognizablePeopleOrPropertySection;
