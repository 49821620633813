import React from "react";
import PropTypes from "prop-types";

import { Grid2Styled } from "./styles";

/*
    Note: This Component used to truly be a Square Grid handled by Flexbox and splitting up the rows
    With CSS Grid, this is drastically simplified and easier to manage.

    We also started using this component for grids where the content was not truly square, rather vertically rectangle
    Adding a footer in Image Uploads for example. The older solution made it difficult to calculate true row height

    Now the children dertermine the height of each row
    And CSS Grid handles gap spacing fluidly.
*/

const Grid2 = ({
    className,
    gridItemContent,
    numberOfColumns,
    gridSpacingSize
}) => {
    if (!numberOfColumns) return null;

    return (
        <Grid2Styled
            data-testid="Grid2"
            className={className}
            numberOfColumns={numberOfColumns}
            gridSpacingSize={gridSpacingSize}
        >
            {gridItemContent.map((gic, idx) => {
                const key = gic.id || idx;
                return (
                    <li key={key} data-testid="Grid2-item">
                        {gic}
                    </li>
                );
            })}
        </Grid2Styled>
    );
};

Grid2.propTypes = {
    className: PropTypes.string,
    gridItemContent: PropTypes.arrayOf(PropTypes.object).isRequired,
    numberOfColumns: PropTypes.number.isRequired,
    gridSpacingSize: PropTypes.string.isRequired
};

export default Grid2;
