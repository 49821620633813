import React from "react";
import PropTypes from "prop-types";
import layoutGeometry from "justified-layout";

// Components
import LoadingSpinner from "core/LoadingSpinner";

// Helpers
import { generateAds, Photos } from "./helpers";

// Styles
import { PhotoInfiniteScroll, PhotoGrid } from "./styles";

// eslint-disable-next-line max-statements
const PhotosGrid = ({
    data,
    fetchMore,
    itemKey,
    subscribed = false,
    maxRows,
    pageWidth
}) => {
    if (!data || !data[itemKey]) {
        return null;
    }
    let { items, totalCount } = data[itemKey];
    const hasMaxRows = Boolean(maxRows);

    // Catch empty results
    items = items ? items : [];

    const isMobile = pageWidth < 768;
    const containerWidth = pageWidth > 1330 ? 1330 : pageWidth;
    const ratios = items.map(({ ratio }) => ratio);

    // This is were all the magic happens
    const ratioResults = layoutGeometry(ratios, {
        containerWidth,
        targetRowHeight: 265,
        boxSpacing: !isMobile ? 24 : 12,
        maxNumRows: maxRows
    });
    const { containerHeight } = ratioResults;
    const mergedResults = items.map((photo, idx) => ({
        ...photo,
        ...ratioResults.boxes[idx]
    }));

    const { resultsWithAds, adCount, adHeightWtihPadding } = generateAds(
        mergedResults,
        isMobile,
        containerWidth,
        hasMaxRows,
        subscribed
    );

    const loadMorePhotos = () => {
        fetchMore({
            variables: {
                offset: items.length
            },
            updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;

                return {
                    [itemKey]: {
                        ...fetchMoreResult[itemKey],
                        items: [
                            ...prev[itemKey].items,
                            ...fetchMoreResult[itemKey].items
                        ]
                    }
                };
            }
        });
    };

    const gridHeight = containerHeight + adHeightWtihPadding * adCount;

    return (
        <PhotoInfiniteScroll
            dataLength={items.length}
            next={loadMorePhotos}
            hasMore={items.length < totalCount && !hasMaxRows}
            loader={<LoadingSpinner />}
        >
            <PhotoGrid height={gridHeight}>
                <Photos
                    subscribed={subscribed}
                    mergedResults={mergedResults}
                    resultsWithAds={resultsWithAds}
                />
            </PhotoGrid>
        </PhotoInfiniteScroll>
    );
};

PhotosGrid.propTypes = {
    data: PropTypes.object.isRequired,
    itemKey: PropTypes.string.isRequired,
    pageWidth: PropTypes.number.isRequired,
    fetchMore: PropTypes.func,
    subscribed: PropTypes.bool,
    maxRows: PropTypes.number
};

export default PhotosGrid;
