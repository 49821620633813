import React from "react";
import PropTypes from "prop-types";

// context
export { QueueProvider } from "./QueueContext";
export { useQueueContext } from "./QueueContext";

// components
export { QueueHeader } from "./QueueHeader";
export { QueueMain } from "./QueueMain";
export { QueueAside } from "./QueueAside";

// styles
import { QueueLayoutStyled, QueueContainerStyled } from "./styles";

const QueueLayout = ({ children }) => {
    return (
        <QueueLayoutStyled>
            <QueueContainerStyled>{children}</QueueContainerStyled>
        </QueueLayoutStyled>
    );
};

export default QueueLayout;

// prop-types
QueueLayout.propTypes = {
    children: PropTypes.any
};
