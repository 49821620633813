/* eslint-disable no-mixed-operators */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { TableColumnHighlight as StyledTableColumnHighlight } from "core/Table";

import { useDimensions } from "./useDimensions";

function useTableColHighlight({ borderWidth = 6, colNumber, color }) {
    const [colRef, colDimensions] = useDimensions({});
    const [tableRef, tableDimensions] = useDimensions({});

    const [colWidth, setColWidth] = useState(0);
    const [colHeight, setColHeight] = useState(0);

    useEffect(() => {
        if (colDimensions) setColWidth(colDimensions.width);
        if (tableDimensions) setColHeight(tableDimensions.height);
    }, [colDimensions, tableDimensions]);

    const TableColumnHighlight = ({ className }) => (
        <StyledTableColumnHighlight
            width={colWidth}
            height={colHeight}
            color={color}
            borderWidth={borderWidth}
            colNumber={colNumber}
            className={className}
        />
    );

    TableColumnHighlight.propTypes = {
        className: PropTypes.string
    };

    return [colRef, tableRef, TableColumnHighlight];
}

export default useTableColHighlight;
