import { useApolloMutation } from "frontend/utils/apolloApi/useApolloMutation.js";

// GQL
import { APPROVE_PHOTO_UPLOAD_TITLE } from "frontend/components/fort/mod/photos/gql/mutations";

const errorHandler = normalizedResp => {
    const { message, errors, actionDesc } = normalizedResp;
    let newMessage = message;

    if (errors.includes("You must add a title before you can approve it.")) {
        newMessage = `Sorry, we were unable to ${actionDesc}. A photo upload title is required and appears to be empty.`;
    }

    return {
        ...normalizedResp,
        message: newMessage
    };
};

export const useApprovePhotoUploadTitle = () => {
    const { apolloMutate, loading } = useApolloMutation({
        actionDesc: "Approve Photo Upload Title",
        gql: APPROVE_PHOTO_UPLOAD_TITLE,
        errorHandler
    });

    const approvePhotoUploadTitle = async variables => {
        return await apolloMutate({ variables });
    };

    return {
        approvePhotoUploadTitle,
        approvePhotoUploadTitleLoading: loading
    };
};
