import styled, { css } from "styled-components";

// styles
import colors from "core/colors";
import { easingSeconds } from "core/animation";
import BodyText from "core/BodyText";
import { convertPxToRem } from "frontend/components/helpers";

// components
import checkIcon from "img/icons/check-mark-approve-white.svg";
import PhotoIcon from "core/ReusableIcons/Photo";

/*---------------------------
| Faux Checkbox
---------------------------*/
export const FauxCheckboxStyled = styled.div`
    position: absolute;
    top: ${convertPxToRem(12)};
    left: ${convertPxToRem(12)};
    /* Always sits over GridItemContent */
    z-index: 3;

    display: flex;
    align-items: center;
    justify-content: center;

    width: ${convertPxToRem({ px: 16 })};
    height: ${convertPxToRem({ px: 16 })};

    border-radius: ${convertPxToRem({ px: 3 })};
    border: solid ${convertPxToRem({ px: 1 })} ${colors.secondary.castle};
    background-color: ${colors.primary.snow};

    transition: all ${easingSeconds};

    ${({ isChecked }) => {
        if (isChecked) {
            return css`
                border-color: transparent;
                background-color: ${colors.accent.lightMode.pansy};
            `;
        }
    }}
`;
export const CheckmarkStyled = styled.div`
    width: ${convertPxToRem(12)};
    height: ${convertPxToRem(12)};
    background-image: url(${checkIcon.src || checkIcon});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
`;

/*---------------------------
| META
---------------------------*/
const OverlayTextSharedStyled = css`
    padding: ${convertPxToRem(3)};
    border-radius: ${convertPxToRem(3)};
    background-color: rgba(255, 255, 255, 0.6);

    position: absolute;
    /* Always sits over GridItemContent */
    z-index: 3;
    right: ${convertPxToRem(12)};
`;

// META: Upload Count
export const UploadCountStyled = styled(BodyText)`
    ${OverlayTextSharedStyled};
    bottom: ${convertPxToRem(12)};
`;

// META: File Name
export const FileNameStyled = styled(BodyText)`
    ${OverlayTextSharedStyled};
    top: ${convertPxToRem(12)};
`;

/*---------------------------
| Consumer Opt In Components
---------------------------*/
export const ImageStyled = styled.img`
    /* center absolutely positioned asset */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    /* resize asset so longest side spans parent container */
    max-height: 100%;
    max-width: 100%;

    ${({ imageIsBlurred }) =>
        imageIsBlurred &&
        css`
            filter: blur(15px);
        `}
`;

export const PhotoIconStyled = styled(PhotoIcon)`
    /* center absolutely positioned asset */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    path {
        fill: ${colors.primary.scheifer};
    }
`;
