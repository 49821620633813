import { css } from "styled-components";

// mobile -> table/desktop
// TODO - refactor so the name of the option is the desktop name, not mobile name
export const typeScale = {
    12: css`
        font-size: 0.8125rem;
        line-height: 0.9375rem;

        @media (min-width: 48rem) {
            font-size: 0.8125rem;
            line-height: 1.03125rem;
        }
    `,
    14: css`
        font-size: 0.875rem;
        line-height: 1.09375rem;

        @media (min-width: 48rem) {
            font-size: 0.875rem;
            line-height: 1.203125rem;
        }
    `,
    16: css`
        font-size: 1rem;
        line-height: 1.25rem;

        @media (min-width: 48rem) {
            font-size: 1.125rem;
            line-height: 1.374375rem;
        }
    `,
    18: css`
        font-size: 1.125rem;
        line-height: 1.249375rem;

        @media (min-width: 48rem) {
            font-size: 1.5rem;
            line-height: 2.186875rem;
        }
    `,
    30: css`
        font-size: 1.875rem;
        line-height: 2.34375rem;

        @media (min-width: 48rem) {
            font-size: 2rem;
            line-height: 2.5rem;
        }
    `
};

// TODO- move these to colors.ts:
export const fontColors = {
    light: {
        primary: "#FFFFFF",
        secondary: "#949494",
        link: "#BABABA"
    },
    dark: {
        primary: "#000000",
        link: "#6D6D6D"
    }
};
