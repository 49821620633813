/*===================================
||
|| InlineTextEditContext
||
===================================*/
import React, {
    createContext,
    useReducer,
    useMemo,
    useEffect,
    useContext,
    useRef,
    useCallback
} from "react";
import PropTypes from "prop-types";

// store
import { reducer, actions } from "./store";

// validation
import { inlineTextValidation } from "./validation";

// components
import { InlineTextEditWrapper } from "./InlineTextEditWrapper";

// Opt-in Components
export { Input } from "./InputControls/Input";
export { TextArea } from "./InputControls/TextArea";
export { Checkbox } from "./Checkbox";
export { SaveButton } from "./SaveButton";

/*---------------------------
| Context
---------------------------*/
const InlineTextEditContext = createContext();

// Provider
export const InlineTextEdit = ({
    children,

    // Consumer Managed State
    text,

    // Consumer Managed Handlers
    onSave, // asynchronous handler that returns any server-side errors

    // InlineTextEdit config (defaults on component mount)
    id,
    ariaLabel = "Title (Optional)",
    placeholder = "Add Text",
    saveOnBlur = false,
    validationRules = [], // Client Side Validation Rules
    disabled,
    minLength = "3",
    maxLength = "255"
}) => {
    const vRules =
        validationRules.length === 0
            ? [
                  inlineTextValidation.noEmojis,
                  inlineTextValidation.minLength(minLength),
                  inlineTextValidation.maxLength(maxLength)
              ]
            : validationRules;

    const refinedProps = {
        text,
        consumerOnSave: onSave,
        id,
        ariaLabel,
        saveOnBlur,
        validationRules: vRules,
        placeholder,
        disabled,
        minLength,
        maxLength
    };

    const defaultState = {
        hasMounted: false,
        isEditing: false,
        editedText: text, // updates whenever user is editing text in the input field, defaults to what consumer provides onMount
        errorMessage: "",
        editButtonRef: useRef(),
        inputRef: useRef()
    };

    const [state, dispatch] = useReducer(reducer, defaultState);

    // if ID changes, disable editing mode - hopefully object ID is part of `id` prop
    useEffect(() => {
        dispatch(actions.isEditingUpdate(false));
        dispatch(actions.editedTextUpdate(text));
        dispatch(actions.errorMessageUpdate(""));
    }, [id]);

    // useMemo so it does not pass value on every render
    const value = useMemo(() => ({ state, dispatch, refinedProps }), [
        state,
        dispatch,
        refinedProps
    ]);

    return (
        <InlineTextEditContext.Provider
            value={value}
            displayName={"InlineTextEditContext"}
        >
            <MountingWrapper>
                <InlineTextEditWrapper>{children}</InlineTextEditWrapper>
            </MountingWrapper>
        </InlineTextEditContext.Provider>
    );
};

// prop-types
InlineTextEdit.propTypes = {
    text: PropTypes.string.isRequired,
    onSave: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    children: PropTypes.any,
    ariaLabel: PropTypes.string,
    saveOnBlur: PropTypes.bool,
    validationRules: PropTypes.array,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    minLength: PropTypes.string,
    maxLength: PropTypes.string
};

// useInlineTextEdit
export const useInlineTextEdit = () => {
    const { state, dispatch, refinedProps } = useContext(InlineTextEditContext);

    const {
        consumerOnSave,
        validationRules,
        ariaLabel,
        saveOnBlur
    } = refinedProps;

    const onSave = async () => {
        await actions.onSave(
            state,
            dispatch,
            consumerOnSave,
            validationRules,
            ariaLabel
        );
    };

    const onInputChange = e => {
        dispatch(actions.editedTextUpdate(e.target.value));
    };

    const onInputBlur = () => {
        if (saveOnBlur) {
            onSave();
        }
    };

    return {
        ...state,
        ...refinedProps,
        onSave,
        onInputChange,
        onInputBlur,
        dispatch,
        actions
    };
};

// MountingWrapper
const MountingWrapper = ({ children }) => {
    const {
        text,
        editedText,
        dispatch,
        isEditing,
        actions,
        onSave,
        hasMounted,
        editButtonRef,
        inputRef
    } = useInlineTextEdit();

    useEffect(() => {
        dispatch(actions.hasMountedUpdate(true));
        return () => {
            dispatch(actions.hasMountedUpdate(false));
        };
    }, []);

    useEffect(() => {
        if (editedText !== text) {
            // We need to update `editedText` whenever consumer changes `text`
            // this way if they go back to editing, it will be the new value.
            dispatch(actions.editedTextUpdate(text));
        }
    }, [text]);

    useEffect(() => {
        if (hasMounted) {
            if (isEditing) {
                inputRef.current.focus();
            } else {
                editButtonRef.current.focus();
            }
        }
    }, [isEditing]);

    const onKeyDown = useCallback(
        e => {
            const keyPressed = e.key;

            if (isEditing && ["ArrowRight", "ArrowLeft"].includes(keyPressed)) {
                // while user is editing, arrows should not trigger ancestor actions
                // UPL - uses left and right arrow to cycle through batch of uploads
                e.stopPropagation();
            }
            if (isEditing && ["Enter"].includes(keyPressed)) {
                e.stopPropagation();
                e.preventDefault();
                onSave();
            }
            if (isEditing && ["Escape"].includes(keyPressed)) {
                e.stopPropagation();
                dispatch(actions.onAbort(text));
            }
        },
        [dispatch, onSave, isEditing]
    );

    useEffect(() => {
        document.addEventListener("keydown", onKeyDown);
        return () => {
            document.removeEventListener("keydown", onKeyDown);
        };
    }, [onKeyDown]);

    return <>{children}</>;
};

// prop-types
MountingWrapper.propTypes = {
    children: PropTypes.any
};
