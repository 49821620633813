import { actionTypes } from "./actionTypes";
import * as Utils from "./utils";

const DEBUG_MODE = false;

/*---------------------------
| Reducer
---------------------------*/
const reducer = (state, action) => {
    const { type } = action;
    let uploadsSelected = { ...state.uploadsSelected };
    let newState = { ...state };

    if (DEBUG_MODE) {
        console.info("SelectContext reducer", action);
    }

    switch (type) {
        case actionTypes.UPDATE_UPLOAD_IDS: {
            uploadsSelected = Utils.getUploadsSelectedFromUIDs(
                state.uploadsSelected,
                action.uploadIds
            );

            newState = {
                ...state,
                uploadIds: action.uploadIds,
                uploadsSelected,
                ...Utils.setSideEffects(uploadsSelected)
            };
            break;
        }
        case actionTypes.TOGGLE_ID: {
            uploadsSelected[action.id] = !uploadsSelected[action.id];

            newState = {
                ...state,
                uploadsSelected,
                ...Utils.setSideEffects(uploadsSelected)
            };

            if (uploadsSelected[action.id] === true) {
                newState.lastSelectedId = action.id;
            }
            break;
        }

        case actionTypes.SELECT_ALL: {
            Object.keys(uploadsSelected).forEach((id, idx) => {
                uploadsSelected[id] = true;
            });

            newState = {
                ...state,
                uploadsSelected,
                prevSelectedDirection: "right",
                lastSelectedId: null,
                ...Utils.setSideEffects(uploadsSelected)
            };
            break;
        }

        case actionTypes.DESELECT_ALL: {
            Object.keys(uploadsSelected).forEach((id, idx) => {
                uploadsSelected[id] = false;
            });

            newState = {
                ...state,
                uploadsSelected,
                lastSelectedId: null,
                ...Utils.setSideEffects(uploadsSelected)
            };
            break;
        }

        case actionTypes.SELECT_IDS: {
            uploadsSelected = Utils.selectOrDeselect({
                uploadsSelected: state.uploadsSelected,
                uploadIds: action.ids,
                isSelecting: true
            });

            newState = {
                ...state,
                uploadsSelected,
                ...Utils.setSideEffects(uploadsSelected)
            };
            break;
        }

        case actionTypes.DESELECT_IDS: {
            uploadsSelected = Utils.selectOrDeselect({
                uploadsSelected: state.uploadsSelected,
                uploadIds: action.ids,
                isSelecting: false
            });

            newState = {
                ...state,
                uploadsSelected,
                ...Utils.setSideEffects(uploadsSelected)
            };
            break;
        }
        case actionTypes.SELECT_DESELECT_RANGE: {
            const { id } = action;
            const {
                prevSelectedDirection,
                lastSelectedId,
                quantitySelected
            } = state;

            const {
                newUploadsSelected,
                newPrevSelectedDirection
            } = Utils.getRangeUploadsSelected({
                clickedUploadId: id,
                uploadsSelected,
                quantitySelected,
                prevSelectedDirection,
                lastSelectedId
            });

            newState = {
                ...state,
                uploadsSelected: newUploadsSelected,
                prevSelectedDirection: newPrevSelectedDirection,
                lastSelectedId: id,
                ...Utils.setSideEffects(newUploadsSelected)
            };
            break;
        }

        case actionTypes.SELECT_NEXT: {
            const { uploadIds } = state;
            const newUploadsSelected = Utils.selectNext(
                uploadIds,
                uploadsSelected
            );

            newState = {
                ...state,
                uploadsSelected: newUploadsSelected
            };
            break;
        }

        case actionTypes.SELECT_PREV: {
            const { uploadIds } = state;

            const newUploadsSelected = Utils.selectPrev(
                uploadIds,
                uploadsSelected
            );

            newState = {
                ...state,
                uploadsSelected: newUploadsSelected
            };
            break;
        }

        default: {
            console.error(`Unknown action type: ${type}`);
        }
    }

    if (DEBUG_MODE) {
        console.info("SelectContext newState", newState);
    }

    return newState;
};

export default reducer;
