import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import Link from "core/Link";

import CreatorProfileMenuDesktop from "js/components/CreatorProfileMenu/Desktop";

import {
    authLinks,
    myStuffMenu,
    myStuffMenuFav,
    myStuffApiMenu,
    myAccountMenu,
    adminMenu
} from "data/links";

// feature flags
import {
    SAVE_ICON_SETS_TO_FAVORITES,
    SAVE_PHOTOS_TO_FAVORITES
} from "frontend/data/featureFlags";
import useFeatureFlag from "frontend/hooks/useFeatureFlag";

import { Heading, Section, Item, Div, LogOutButton } from "./index";

import { convertPxToRem } from "frontend/components/helpers";

const Teams = ({ teams, onTeamClick, linkProps }) => {
    const result = teams.length ? (
        <Section>
            {teams.map(team => (
                <Item key={team.username}>
                    <Link
                        onClick={onTeamClick}
                        href={`/${team.username}/settings/seats/`}
                        {...linkProps}
                    >
                        {team.name}
                    </Link>
                </Item>
            ))}
        </Section>
    ) : null;

    return result;
};

Teams.propTypes = {
    teams: PropTypes.array
};

let Content = ({
    className,
    user,
    hasApiKey,
    showAdminMenu,
    logOut,
    closeMenu,
    isHeroSearchV2
}) => {
    user = user || {};
    const userTeams = user.ownedTeams || {};

    const linkProps = {
        size: "md",
        styleType: "secondary",
        darkMode: !isHeroSearchV2,
        disableVisitedStyle: true
    };

    // feature flag
    const showKitsFavsIconSets = useFeatureFlag(SAVE_ICON_SETS_TO_FAVORITES);
    const showKitsFavsPhotos = useFeatureFlag(SAVE_PHOTOS_TO_FAVORITES);
    const showKitsFavs = showKitsFavsIconSets && showKitsFavsPhotos;

    const getLinks = ({ linkData }) =>
        linkData.map(link => {
            return (
                <Item key={link.name}>
                    <Link
                        onClick={closeMenu}
                        href={
                            typeof link.href === "string"
                                ? link.href
                                : link.href({
                                      username: encodeURIComponent(
                                          user.username
                                      )
                                  })
                        }
                        target={link.external ? "_blank" : "_self"}
                        {...linkProps}
                    >
                        {typeof link.name === "string"
                            ? link.name
                            : link.name({
                                  hasNotifications: user.hasNotifications
                              })}
                    </Link>
                </Item>
            );
        });

    const myStuffMenuLinks = getLinks({
        linkData: showKitsFavs ? myStuffMenuFav : myStuffMenu
    });
    const myStuffApiMenuLinks = getLinks({ linkData: myStuffApiMenu });
    const myAccountMenuLinks = getLinks({ linkData: myAccountMenu });
    const adminMenuLinks = getLinks({ linkData: adminMenu });

    return (
        <Div className={className} isHeroSearchV2={isHeroSearchV2}>
            {(user.isPhotoCreator || user.isIconCreator) && (
                <Section>
                    <CreatorProfileMenuDesktop
                        creatorUsername={user.username}
                        isPhotoCreator={user.isPhotoCreator}
                        isIconCreator={user.isIconCreator}
                        isHeroSearchV2={isHeroSearchV2}
                    />
                </Section>
            )}
            <Section>
                <Heading>My Stuff</Heading>
                {myStuffMenuLinks}
                {hasApiKey && myStuffApiMenuLinks}
            </Section>
            <Section>
                <Heading>My Account</Heading>
                {myAccountMenuLinks}
                {!!userTeams.length && (
                    <Item>
                        <p>Manage Team:</p>
                        <Teams
                            onTeamClick={closeMenu}
                            teams={userTeams}
                            isHeroSearchV2={isHeroSearchV2}
                            linkProps={linkProps}
                        />
                    </Item>
                )}
            </Section>
            {showAdminMenu && (
                <Section>
                    <Heading>Admin</Heading>
                    {adminMenuLinks}
                </Section>
            )}
            <Div style={{ textAlign: "center", marginTop: "1.625rem" }}>
                <LogOutButton
                    isHeroSearchV2={isHeroSearchV2}
                    styleType="outline"
                    size="md"
                    width={convertPxToRem({ px: 137 })}
                    onClick={logOut}
                    darkMode
                >
                    {authLinks.logout.text}
                </LogOutButton>
            </Div>
        </Div>
    );
};

Content = styled(Content)``;

Content.propTypes = {
    user: PropTypes.object,
    logOut: PropTypes.func,
    className: PropTypes.string,
    closeMenu: PropTypes.func,
    isHeroSearchV2: PropTypes.bool,
    showAdminMenu: PropTypes.bool
};

export { Content };
