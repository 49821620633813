import React from "react";
import styled, { css } from "styled-components";

import colors from "core/colors";
import { SHARED_FORM_STYLES } from "core/Form";
import { CustomFocusStyle } from "core/Typography";
import { easingSeconds } from "core/animation";

import { convertPxToRem } from "frontend/components/helpers";
import { handleStylePosition } from "../FormInput/helpers";

import Checkmark from "core/ReusableIcons/Checkmark";

const INPUT_ICON_SIZE = convertPxToRem({ px: 16 });
const INPUT_ICON_MARGIN = convertPxToRem({ px: 8 });

const RIGHT_ICON_POSITION_STYLES = {
    XS: css`
        right: ${convertPxToRem({ px: 6 })};
    `,
    SM: css`
        right: ${convertPxToRem({ px: 8 })};
    `,
    MD: css`
        right: ${convertPxToRem({ px: 14 })};
    `,
    LG: css`
        right: ${convertPxToRem({ px: 16 })};
    `
};

const RIGHT_ICON_INPUT_PADDING_STYLES = {
    XS: `
        padding-right: calc(
            ${INPUT_ICON_SIZE} + ${INPUT_ICON_MARGIN} +
                ${convertPxToRem({ px: 6 })}
        );
    `,

    SM: `
        padding-right: calc(
            ${INPUT_ICON_SIZE} + ${INPUT_ICON_MARGIN} +
                ${convertPxToRem({ px: 8 })}
        );
    `,

    MD: `
        padding-right: calc(
            ${INPUT_ICON_SIZE} + ${INPUT_ICON_MARGIN} +
                ${convertPxToRem({ px: 14 })}
        );
    `,

    LG: `
        padding-right: calc(
            ${INPUT_ICON_SIZE} + ${INPUT_ICON_MARGIN} +
                ${convertPxToRem({ px: 16 })}
        );
    `
};

const LEFT_ICON_POSITION_STYLES = {
    XS: css`
        left: ${convertPxToRem({ px: 6 })};
    `,
    SM: css`
        left: ${convertPxToRem({ px: 8 })};
    `,
    MD: css`
        left: ${convertPxToRem({ px: 14 })};
    `,
    LG: css`
        left: ${convertPxToRem({ px: 16 })};
    `
};

const LEFT_ICON_INPUT_PADDING_STYLES = {
    XS: `
        padding-left: calc(
            ${INPUT_ICON_SIZE} + ${INPUT_ICON_MARGIN} +
                ${convertPxToRem({ px: 6 })}
        );
    `,

    SM: `
        padding-left: calc(
            ${INPUT_ICON_SIZE} + ${INPUT_ICON_MARGIN} +
                ${convertPxToRem({ px: 8 })}
        );
    `,

    MD: `
        padding-left: calc(
            ${INPUT_ICON_SIZE} + ${INPUT_ICON_MARGIN} +
                ${convertPxToRem({ px: 14 })}
        );
    `,

    LG: `
        padding-left: calc(
            ${INPUT_ICON_SIZE} + ${INPUT_ICON_MARGIN} +
                ${convertPxToRem({ px: 16 })}
        );
    `
};

// styled Input:
export const Input = styled.input`
    ${SHARED_FORM_STYLES.BASE}
    width: 100%;
    box-shadow: none;
    border: ${convertPxToRem({ px: 1 })} solid ${colors.secondary.oolong};

    ${({ size, success }) =>
        !!success &&
        handleStylePosition({
            size,
            POSITION_STYLES: RIGHT_ICON_INPUT_PADDING_STYLES
        })}

    ${({ size, iconLeft }) =>
        !!iconLeft &&
        handleStylePosition({
            size,
            POSITION_STYLES: LEFT_ICON_INPUT_PADDING_STYLES
        })}

    // placeholder:
    &::placeholder {
        ${SHARED_FORM_STYLES.PLACEHOLDER}
    }

    // hover:
    &:hover {
        border-color: ${colors.secondary.cosmic};
    }

    // focus:
    &:focus {
        border-color: ${colors.secondary.cosmic};
        outline: none;

        &::placeholder {
            color: transparent;
        }
    }
    ${CustomFocusStyle}

    // disabled:
    &:disabled {
        ${SHARED_FORM_STYLES.DISABLED}
        box-shadow: none;
        border: ${convertPxToRem({ px: 1 })} solid
            ${({ darkMode }) =>
                darkMode ? colors.secondary.oolong : colors.primary.scheifer};
    }
`;

// Success icon:
const SuccessIconWrapper = styled.div`
    position: absolute;
    transition: all ${easingSeconds};
    opacity: ${({ success }) => (!!success ? 1 : 0)};
    bottom: 35%;

    ${({ size }) =>
        handleStylePosition({
            size,
            POSITION_STYLES: RIGHT_ICON_POSITION_STYLES
        })};
`;

const StyledCheckMark = styled(Checkmark).attrs({ size: "sm" })`
    path {
        fill: ${({ darkMode }) =>
            darkMode
                ? colors.alternative.lightMode.chartreuse
                : colors.alternative.lightMode.pine};
    }
`;

const UnwrappedSuccessIcon = ({
    className,
    inputSuccessful,
    darkMode,
    size,
    success
}) => (
    <SuccessIconWrapper
        className={className}
        inputSuccessful={inputSuccessful}
        size={size}
        success={success}
    >
        <StyledCheckMark darkMode={darkMode} />
    </SuccessIconWrapper>
);

export const SuccessIcon = styled(UnwrappedSuccessIcon)``;

// Left & Right icon:
export const IconContainer = styled.div`
    position: absolute;
    bottom: 50%;

    ${({ size, iconLeft }) =>
        handleStylePosition({
            size,
            POSITION_STYLES: iconLeft
                ? LEFT_ICON_POSITION_STYLES
                : RIGHT_ICON_POSITION_STYLES
        })};
`;
