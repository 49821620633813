import { createSlice } from "@reduxjs/toolkit";

import { REDUCERS, QUEUES } from "../constants";

const initialState = {
    name: QUEUES.VIS_MOD
};

export const currentQueueSlice = createSlice({
    name: REDUCERS.CURRENT_QUEUE,
    initialState,
    reducers: {
        setCurrentQueue: (state, action) => {
            state.name = action.payload;
        }
    }
});

export const { setCurrentQueue } = currentQueueSlice.actions;

export default currentQueueSlice.reducer;
