import React from "react";

import colors from "core/colors";
import BaseIcon from "./BaseIcon";

const Checkmark = ({
    size = null,
    ariaLabel = "checkmark",
    ariaHidden = false,
    fillColor,
    fillColorRule,
    className
}) => {
    return (
        <BaseIcon
            size={size}
            fillColor={fillColor}
            fillColorRule={fillColorRule}
            className={className}
            aria-hidden={ariaHidden ? "true" : "false"}
            aria-label={ariaLabel}
        >
            <path
                fill={colors.secondary.orca}
                fillRule="evenodd"
                d="M9.65 19.642a.5.5 0 01-.71 0l-7.59-7.654a.5.5 0 010-.704l1.459-1.471a.5.5 0 01.71 0l5.776 5.823L20.48 4.358a.5.5 0 01.71 0l1.46 1.472a.5.5 0 010 .704l-13 13.108z"
                clipRule="evenodd"
                key="key-0"
            />
        </BaseIcon>
    );
};

export default Checkmark;
