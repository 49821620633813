import React from "react";
import PropTypes from "prop-types";

// styles
import { ActionBarLeftStyled } from "./styles.js";

export const ActionBarLeft = ({ children }) => {
    return <ActionBarLeftStyled>{children}</ActionBarLeftStyled>;
};

// prop-types
ActionBarLeft.propTypes = {
    children: PropTypes.any
};
