import React from "react";
import PropTypes from "prop-types";

// hooks
import { useDeny } from "./useDeny";

// styles
import { ButtonStyled } from "../styles";

// component
export const DenyButton = ({ buttonText = "Deny", variant, successTitle }) => {
    const { onDeny, isEditable } = useDeny({ successTitle });

    return (
        <ButtonStyled
            onClick={onDeny}
            isDisabled={!isEditable}
            variant={variant}
        >
            {buttonText}
        </ButtonStyled>
    );
};

export default DenyButton;

// prop-types
DenyButton.propTypes = {
    buttonText: PropTypes.string,
    variant: PropTypes.string.isRequired,
    successTitle: PropTypes.string
};
