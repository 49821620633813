import React from "react";
import styled, { css } from "styled-components";

// context
import { useSiteHeader } from "core/SiteHeaderV2/context/useSiteHeader.js";

// Drop Down
import { DropDownProvider, Hotspot } from "core/DropDown/index.js";

// data
import { primaryMenu } from "data/links";

// components
import SrOnly from "core/SrOnly";
import EllipsesHorizontal from "core/ReusableIcons/EllipsesHorizontal.js";
import DropDown from "./DropDown";

// styles
import colors from "core/colors";
import { linkStyles } from "core/SiteHeaderV2/components/Link";
import { convertPxToRem } from "frontend/components/helpers";

const TheNounProjectStyled = styled.div`
    display: inline-block;
    margin: 0rem ${convertPxToRem(8)};
`;

// TODO: Focus Styles are too tall on this one
const HostSpotStyled = styled(Hotspot)`
    ${({ $isDarkMode }) => {
        return css`
            ${linkStyles($isDarkMode)}
        `;
    }};

    /*
        To ensure this SVG lines up with it's textual counterparts (e.g. API Tools)
        For some reason the shared styles do not work here with SVG
        Potentially something to do with line-spacing, font-size, soemthing with the SVG.
    */
    position: relative;
    top: ${convertPxToRem(3)};
    height: ${convertPxToRem(24)};

    svg {
        position: relative;
        top: ${convertPxToRem(2)};
    }
`;

// component
export const TheNounProject = () => {
    const { isDarkMode } = useSiteHeader();
    return (
        <TheNounProjectStyled>
            <DropDownProvider
                id="tools-menu"
                hasCloseButton={false}
                hideOnScroll
            >
                <HostSpotStyled $isDarkMode={isDarkMode}>
                    <SrOnly>{primaryMenu.tnp.name}</SrOnly>
                    <EllipsesHorizontal
                        size={{ width: 20, height: 20 }}
                        fillColor={
                            isDarkMode
                                ? colors.primary.diamond
                                : colors.secondary.orca
                        }
                    />
                </HostSpotStyled>
                <DropDown />
            </DropDownProvider>
        </TheNounProjectStyled>
    );
};

export default TheNounProject;
