import PropTypes from "prop-types";
import { reusableComponentSizes } from "core/props";

// originally designed to only accept object e.g. { px: 24 }
// new pixels argument handles both ({ px: 24 }) and (24).
// convertToRem ex: 40 => 2.5rem
// change 16 if our base px size ever changes
export const convertPxToRem = pixels => {
    const px = typeof pixels === "number" ? pixels : pixels.px;
    if (typeof px !== "number") return "0rem";
    const pxToRem = px / 16; // base font size
    return `${pxToRem}rem`;
};

convertPxToRem.propTypes = {
    px: PropTypes.number.isRequired
};

export const handleComponentSize = ({ size, styles }) => {
    switch (size) {
        case "xs":
            return styles.XS;
        case "sm":
            return styles.SM;
        case "md":
            return styles.MD;
        case "lg":
            return styles.LG;
        default:
            return styles.LG;
    }
};

handleComponentSize.propTypes = {
    size: PropTypes.oneOf(reusableComponentSizes).isRequired,
    styles: PropTypes.object.isRequired
};

export const convertCentsToDollars = cents => {
    if (typeof cents !== "number") return null;

    const converted = cents / 100;
    return parseFloat(converted.toFixed(2));
};

// returns a string
export const roundToHundredth = ({ num }) => {
    return num.toFixed(2);
};

roundToHundredth.propTypes = {
    num: PropTypes.oneOf([PropTypes.string, PropTypes.number]).isRequired
};

export const truncateToDecimals = (num, dec = 3) => {
    // returns a string with 21 extra spaces.
    // only here to account for a very large team order AND
    // add 0's to numbers like 2.5
    const numToStr = num.toPrecision(21);
    const decimalPlace = ".";
    const indexOfDec = numToStr.indexOf(decimalPlace);
    // cuts off all extra numbers WITHOUT rounding to maintain product ask (show 19.99 vs 20.00)
    // but also allows for many whole numbers to left of decimal
    return numToStr.slice(0, indexOfDec + dec);
};

truncateToDecimals.propTypes = {
    num: PropTypes.number.isRequired
};

export const capitalizeFirstChar = ({ str }) => {
    return str && str.charAt(0).toUpperCase() + str.slice(1);
};

capitalizeFirstChar.propTypes = {
    str: PropTypes.string.isRequired
};

// this helper will capitalize the
// first character in each word of a string
export const titleCaseStr = ({ str }) => {
    return (
        str &&
        str
            .toLowerCase()
            .split(" ")
            .map(word => {
                return word.charAt(0).toUpperCase() + word.slice(1);
            })
            .join(" ")
    );
};

titleCaseStr.propTypes = {
    str: PropTypes.string.isRequired
};

export const makeTitleCase = value =>
    titleCaseStr({ str: String(value || "") });

export const detectEmoji = str => {
    const pattern = /\p{Extended_Pictographic}/u;
    return pattern.test(str);
};

detectEmoji.propTypes = {
    str: PropTypes.string.isRequired
};

export const objectHasProps = obj => {
    return !(Object.keys(obj).length === 0 && obj.constructor === Object);
};
