import styled from "styled-components";

import SubmissionsHeaderButtons from "./SubmissionsHeaderButtons";
import Heading from "core/Heading";
import PendingGroup from "./PendingGroup";

import breakpoints from "core/breakpoints";
import { convertPxToRem } from "frontend/components/helpers";
import colors from "core/colors";
import { easingSeconds } from "core/animation";

export const SubmissionPhoto = styled.img`
    position: relative;

    span {
        background-size: contain;
        background-color: ${colors.primary.diamond};
    }
`;

export const Italic = styled.span`
    font-style: italic;
`;

/* ---------------------- SUBMISSION HOVER TEXT OVERLAY --------------------- */

export const SubmissionHoverOverlayContainer = styled.div`
    width: 100%;
    background-color: ${colors.secondary.orca};
    position: absolute;
    bottom: 0;
    text-align: right;
    opacity: 0;
    padding: ${convertPxToRem({ px: 4 })} ${convertPxToRem({ px: 8 })}
        ${convertPxToRem({
            px: 4
        })}
        0;
    transition: all ${easingSeconds};

    ${SubmissionPhoto}:hover + & {
        opacity: 1;
        transition: all ${easingSeconds};
    }
`;

/* ------------------------------- CONTAINERS ------------------------------- */

export const SubmissionsHeadingContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: ${convertPxToRem({ px: 32 })};

    @media (max-width: ${breakpoints.beforeTablet.rem}) {
        display: block;
        margin-bottom: ${convertPxToRem({ px: 24 })};

        ${SubmissionsHeaderButtons} {
            display: flex;
            margin-bottom: ${convertPxToRem({ px: 16 })};
        }
    }

    @media (max-width: ${breakpoints.beforeDesktop.rem}) {
        margin-bottom: ${convertPxToRem({ px: 28 })};
    }
`;

export const PendingGroupContainer = styled.div`
    ${Heading} {
        margin-bottom: ${convertPxToRem({ px: 24 })};
    }

    ${SubmissionPhoto} {
        width: 100%;
    }
`;

export const PendingGroupsContainer = styled.div`
    ${PendingGroup} {
        &:not(:last-of-type) {
            margin-bottom: ${convertPxToRem({ px: 40 })};
        }

        @media (max-width: ${breakpoints.beforeTablet.rem}) {
            &:not(:last-of-type) {
                margin-bottom: ${convertPxToRem({ px: 36 })};
            }
        }

        @media (max-width: ${breakpoints.beforeDesktop.rem}) {
            &:not(:last-child) {
                margin-bottom: ${convertPxToRem({ px: 32 })};
            }
        }
    }
`;
