import React, { useEffect, useState } from "react";

// components
import SectionContainer from "core/SectionContainer";
import colors from "core/colors";
import Heading from "core/Heading";
import OffsetGrid from "core/OffsetGrid";
import FormInput from "core/FormInput";
import FormTextArea from "core/FormTextArea";
import TextWithButton from "core/TextWithButton";
import LoadingSpinner from "core/LoadingSpinner";
import breakpoints from "core/breakpoints";

// helpers
import { useHistory } from "react-router-dom";
import { getCsrfTokenFromCookie } from "helpers/csrfToken";
import { useViewport } from "js/hooks/useViewport";
import {
    roleFieldValidation,
    schoolFieldValidation,
    emailFieldValidation,
    howUseIconsFieldValidation,
    checkForErrors
} from "../../helpers";

import LinkInNewRouter from "core/LinkInNewRouter";

// styles
import {
    EmailDescriptionLabel,
    ResponseTimeInfo,
    ButtonContainer,
    ApplyButton,
    EduHeadingContainer,
    ErrorMessage
} from "./styles";

// data
import {
    EDU_APPLICATION_CONFIRMATION,
    NOUN_PRO_EDU_LANDING_PAGE
} from "data/links";
import {
    mainHeading,
    subHeading,
    formTitle,
    rolePlaceholderLabel,
    schoolPlaceholderLabel,
    iconUsageDescription,
    emailPlaceholderLabel,
    emailDescription,
    notificationLabel
} from "./data";

const EduApplication = () => {
    const history = useHistory();
    const { browserWidth } = useViewport();

    const [applicantRole, setApplicantRole] = useState("");
    const [schoolName, setSchoolName] = useState("");
    const [iconUsage, setIconUsage] = useState("");
    const [emailAddr, setEmailAddr] = useState("");
    const [loading, setLoading] = useState(false);
    const [generalFormError, setGeneralFormError] = useState("");

    useEffect(() => {
        // We need to manually take control of the browsers scrollRestoration
        window.history.scrollRestoration = "manual";

        if (window.pageYOffset !== 0) {
            window.scrollTo(0, 0);
        }
    }, []);

    const resetForm = () => {
        setApplicantRole("");
        setSchoolName("");
        setIconUsage("");
        setEmailAddr("");
        setLoading(false);
    };

    const sendApplication = () => {
        const isError = !applicantRole || !schoolName || !emailAddr;

        if (isError) {
            setGeneralFormError("Please fill in all required fields.");
            return;
        }

        const isValidationError = checkForErrors({
            applicantRole,
            schoolName,
            iconUsage,
            emailAddr
        });

        if (isValidationError) {
            setGeneralFormError(isValidationError);
            return;
        }

        setLoading(true);
        const routeURL = "/for-edu/apply/submit/";

        const formData = {
            email: emailAddr,
            title: applicantRole,
            school: schoolName,
            comment: iconUsage
        };

        fetch(routeURL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": getCsrfTokenFromCookie()
            },
            body: JSON.stringify(formData)
        }).then(response => {
            if (response.ok) {
                resetForm();
                history.push(
                    EDU_APPLICATION_CONFIRMATION.replace(":email", emailAddr)
                );
            }
        });
    };

    if (loading) return <LoadingSpinner />;

    return (
        <SectionContainer
            bgColor={colors.secondary.orca}
            removeLeftRightPadding={browserWidth < breakpoints.beforeTablet.px}
        >
            {generalFormError && (
                <ErrorMessage
                    handleDismissClick={() => setGeneralFormError("")}
                    withHeader
                >
                    {generalFormError}
                </ErrorMessage>
            )}
            <EduHeadingContainer>
                <TextWithButton
                    text={mainHeading}
                    subtext={subHeading}
                    bottomSpacing="4xl"
                    headingBottomSpacing="lg"
                    headingAlignment="center"
                    headingColor={colors.primary.diamond}
                    subtextColor={colors.primary.diamond}
                    subtextAlignment="center"
                    headingType="h1"
                />
            </EduHeadingContainer>
            <OffsetGrid lg={8} md={12} sm={12}>
                <SectionContainer
                    bgColor={colors.primary.snow}
                    topSpacing="2xl"
                    bottomSpacing="2xl"
                >
                    <OffsetGrid lg={6} md={8} sm={12}>
                        <Heading size="sm" bottomSpacing="lg" type="h2">
                            {formTitle}
                        </Heading>
                        <FormInput
                            labelText={rolePlaceholderLabel}
                            placeholderText={rolePlaceholderLabel}
                            value={applicantRole}
                            onChange={setApplicantRole}
                            validation={roleFieldValidation}
                        />
                        <FormInput
                            labelText={schoolPlaceholderLabel}
                            placeholderText={schoolPlaceholderLabel}
                            value={schoolName}
                            onChange={setSchoolName}
                            validation={schoolFieldValidation}
                        />
                        <FormTextArea
                            placeholderText={iconUsageDescription}
                            labelText={iconUsageDescription}
                            value={iconUsage}
                            onChange={setIconUsage}
                            validation={howUseIconsFieldValidation}
                        />

                        <EmailDescriptionLabel size="md" type="p">
                            {emailDescription}
                        </EmailDescriptionLabel>

                        <FormInput
                            labelText={emailPlaceholderLabel}
                            placeholderText={emailPlaceholderLabel}
                            value={emailAddr}
                            onChange={setEmailAddr}
                            validation={emailFieldValidation}
                        />

                        <ResponseTimeInfo size="md" type="p">
                            {notificationLabel}
                        </ResponseTimeInfo>

                        <ButtonContainer>
                            <ApplyButton onClick={sendApplication}>
                                Apply for Discount
                            </ApplyButton>
                            <LinkInNewRouter
                                to={NOUN_PRO_EDU_LANDING_PAGE}
                                disableVisitedStyle
                            >
                                Cancel
                            </LinkInNewRouter>
                        </ButtonContainer>
                    </OffsetGrid>
                </SectionContainer>
            </OffsetGrid>
        </SectionContainer>
    );
};

export default EduApplication;
