import React from "react";
import { Redirect } from "react-router-dom";

import {
    PHOTO_UPLOAD_TYPE_SELECT,
    PHOTO_UPLOAD_INDIVIDUAL,
    PHOTO_UPLOAD_PHOTO_SHOOT
} from "data/links";

const usePhotoUploadRedirect = location => {
    const RedirectWithReferrer = ({ to }) => {
        return (
            <Redirect
                to={{
                    pathname: to,
                    state: { referrer: location }
                }}
            />
        );
    };

    const RedirectToTypeSelectPage = () => (
        <RedirectWithReferrer to={PHOTO_UPLOAD_TYPE_SELECT} />
    );

    const RedirectToIndividualUploadPage = () => (
        <RedirectWithReferrer to={PHOTO_UPLOAD_INDIVIDUAL} />
    );

    const RedirectToPhotoShootUploadPage = () => (
        <RedirectWithReferrer to={PHOTO_UPLOAD_PHOTO_SHOOT} />
    );

    return {
        RedirectToTypeSelectPage,
        RedirectToIndividualUploadPage,
        RedirectToPhotoShootUploadPage
    };
};

export default usePhotoUploadRedirect;
