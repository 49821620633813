import React from "react";
import PropTypes from "prop-types";

import MenuGroup from "./MenuGroup";

import { MenuStyled } from "./styles";

const Menu = ({ menuGroups = [] }) => (
    <MenuStyled>
        {menuGroups.map(({ groupName, groupId, groupItems }) => (
            <MenuGroup
                key={groupId}
                groupId={groupId}
                groupName={groupName}
                groupItems={groupItems}
            />
        ))}
    </MenuStyled>
);

Menu.propTypes = {
    menuGroups: PropTypes.array.isRequired
};

export default Menu;
