import React from "react";
import PropTypes from "prop-types";

import {
    spacingSizes,
    headingSizes,
    alignments,
    headingTypes
} from "core/props";

import Grid from "core/Grid";
import ImageOnLeft from "./ImageOnLeft";
import ImageOnRight from "./ImageOnRight";

// this is for our usage of MUI "Hidden" inside ImageOnLeft & ImageOnRight:
import { MuiThemeProvider } from "js/helpers/materialUI";

const CollectionSpotlight = ({
    buttonProps,
    headingType,
    headingSize,
    headingAlignment,
    headingTopSpacing,
    headingBottomSpacing,
    headingColor,
    isRightImg,
    image,
    imageCaption,
    imageAlt,
    collectionHeading,
    collectionText,
    collectionQuoteText,
    collectionQuoteAuthor,
    collectionLink,
    sideBySideContentOnMedium,
    isBottomImageOnMobile,
    imageLink
}) => {
    const mediumScreenSizeGridSpan = sideBySideContentOnMedium ? 6 : 12;

    return (
        <MuiThemeProvider>
            <Grid
                mobileSpacing={32}
                tabletSpacing={sideBySideContentOnMedium ? 16 : 32}
                desktopSpacing={128}
                alignItems="center"
            >
                {isRightImg ? (
                    <ImageOnRight
                        image={image}
                        imageAlt={imageAlt}
                        imageCaption={imageCaption}
                        headingType={headingType}
                        headingSize={headingSize}
                        headingAlignment={headingAlignment}
                        headingTopSpacing={headingTopSpacing}
                        headingBottomSpacing={headingBottomSpacing}
                        headingColor={headingColor}
                        collectionHeading={collectionHeading}
                        collectionText={collectionText}
                        mediumScreenSizeGridSpan={mediumScreenSizeGridSpan}
                        sideBySideContentOnMedium={sideBySideContentOnMedium}
                        isBottomImageOnMobile={isBottomImageOnMobile}
                        collectionQuoteText={collectionQuoteText}
                        collectionQuoteAuthor={collectionQuoteAuthor}
                        collectionLink={collectionLink}
                        buttonProps={buttonProps}
                        imageLink={imageLink}
                    />
                ) : (
                    <ImageOnLeft
                        image={image}
                        imageAlt={imageAlt}
                        imageCaption={imageCaption}
                        headingType={headingType}
                        headingSize={headingSize}
                        headingAlignment={headingAlignment}
                        headingTopSpacing={headingTopSpacing}
                        headingBottomSpacing={headingBottomSpacing}
                        headingColor={headingColor}
                        collectionHeading={collectionHeading}
                        collectionText={collectionText}
                        mediumScreenSizeGridSpan={mediumScreenSizeGridSpan}
                        sideBySideContentOnMedium={sideBySideContentOnMedium}
                        collectionQuoteText={collectionQuoteText}
                        collectionQuoteAuthor={collectionQuoteAuthor}
                        collectionLink={collectionLink}
                        buttonProps={buttonProps}
                        imageLink={imageLink}
                    />
                )}
            </Grid>
        </MuiThemeProvider>
    );
};

CollectionSpotlight.propTypes = {
    buttonProps: PropTypes.object,
    headingType: PropTypes.oneOf(headingTypes),
    headingSize: PropTypes.oneOf(headingSizes),
    headingAlignment: PropTypes.oneOf(alignments),
    headingTopSpacing: PropTypes.oneOf(spacingSizes),
    headingBottomSpacing: PropTypes.oneOf(spacingSizes),
    headingColor: PropTypes.string,
    isRightImg: PropTypes.bool,
    image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    imageCaption: PropTypes.string,
    imageAlt: PropTypes.string.isRequired,
    collectionHeading: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    collectionText: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    collectionQuoteText: PropTypes.string,
    collectionQuoteAuthor: PropTypes.string,
    collectionLink: PropTypes.array,
    sideBySideContentOnMedium: PropTypes.bool,
    isBottomImageOnMobile: PropTypes.bool
};

export default CollectionSpotlight;
