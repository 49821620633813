import styled, { css } from "styled-components";

// core
import { convertPxToRem } from "frontend/components/helpers";

const OverlayStyled = styled.div`
    position: absolute;
    z-index: 1;
    top: 0rem;

    ${({ $animation: { left, right } }) => {
        return css`
            transition: left ${left.speed}ms ease ${left.delay}ms,
                right ${right.speed}ms ease ${right.delay}ms;
            left: ${convertPxToRem(left.value)};
            right: ${convertPxToRem(right.value)};
        `;
    }}
`;

export default OverlayStyled;
