import { useApolloLazyQuery } from "frontend/utils/apolloApi/useApolloLazyQuery.js";

// utils
import { chunkedPromises } from "frontend/utils";

// queries
import { FETCH_PAGINATED_BATCHES } from "frontend/components/fort/mod/photos/gql/queries";

// config
export const ACTION_DESC = "Fetch Batches";
const CHUNK_COUNT = 100; // Chunk Request Count
const DEBUG_BATCH_LIMIT_ON = false;
const DEBUG_BATCH_LIMIT = 20;

export const useFetchPaginatedBatches = () => {
    const { apolloQuery } = useApolloLazyQuery({
        actionDesc: ACTION_DESC,
        gql: FETCH_PAGINATED_BATCHES
    });

    const fetchPaginatedBatches = async ({
        variables,
        page,
        numberOfBatches,
        batchesPerPage
    }) => {
        let batches = [];
        let totalCount = 0;

        const batchSizes = createBatchSizesFromNumber(
            numberOfBatches,
            CHUNK_COUNT
        );

        // Chunked Api Call Promises
        const resolvedPromises = await chunkedPromises(
            batchSizes,
            async (batchSize, idx) => {
                const reqVariables = {
                    ...variables,
                    limit: batchSize,
                    offset: CHUNK_COUNT * idx
                };
                const resp = await apolloQuery({
                    variables: reqVariables,
                    fetchPolicy: "network-only"
                });
                return resp?.ssResp?.data?.batches || [];
            }
        );

        resolvedPromises.forEach(payload => {
            totalCount = payload.totalCount; // this should be the same for every query
            batches = [...batches, ...payload.items];
        });

        // Handle Composite Response
        let batchObjResp = {
            batches,
            totalCount
        };

        if (DEBUG_BATCH_LIMIT_ON) {
            batchObjResp = filterBatches(batchObjResp);
        }

        return {
            batches: batchObjResp.batches,
            totalCount: batchObjResp.totalCount,
            newCurrentPage: getNewCurrentPage(
                page,
                batchObjResp.batches.length,
                batchesPerPage
            )
        };
    };

    return {
        fetchPaginatedBatches
    };
};

/**
 * createBatchSizesFromNumber: convert a total number into batch limits based on sizePerBatch
 * e.g. (number = 534, sizePerBatch = 200) = [200, 200, 134]
 * @param {Int} number
 * @param {Int} sizePerBatch
 * @returns
 */
const createBatchSizesFromNumber = (number, sizePerBatch = 200) => {
    let fullSetsCount = Math.floor(number / sizePerBatch);
    let lastSetSize = number % sizePerBatch;

    let chunksArray = [];

    // Add full sets
    for (let i = 0; i < fullSetsCount; i++) {
        chunksArray.push(sizePerBatch);
    }

    // Add the last set
    if (lastSetSize > 0) {
        chunksArray.push(lastSetSize);
    }

    return chunksArray;
};

/**
 * filterBatches A way to simulate/control response from featching batches (testing 20 batches for example)
 * @param {Object} batchesObj {items, totalCount} response from BE
 * @returns filtered batches, and new count based on filtered batches
 */
const filterBatches = ({ batches, totalCount }) => {
    const filteredBatches = batches.slice(0, DEBUG_BATCH_LIMIT);
    const alteredTotalCount =
        totalCount < DEBUG_BATCH_LIMIT ? totalCount : DEBUG_BATCH_LIMIT;

    return {
        batches: filteredBatches,
        totalCount: alteredTotalCount
    };
};

/**
 * getNewCurrentPage
 * @param {Array} batches
 * @param {Number} batchesPerPage
 * @returns {Number} newCurrentPage
 */
const getNewCurrentPage = (page, fetchedBatchCount, batchesPerPage) => {
    let newCurrentPage = page;

    // make sure the newCurrentPage supports already fetched data
    if (fetchedBatchCount > 0) {
        newCurrentPage = Math.ceil(fetchedBatchCount / batchesPerPage);
    }

    return newCurrentPage;
};
