import styled, { css } from "styled-components";

// styles
import colors from "core/colors";
import { convertPxToRem } from "frontend/components/helpers";

// styled-components
export const FlagListStyled = styled.div`
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 2;

    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
`;

export const FlagStyled = styled.div`
    cursor: default;
    flex: 0 0 ${convertPxToRem(39 + 16 + 12)};
    padding: ${convertPxToRem(4)} ${convertPxToRem(8)};
    background-color: ${({ bgColor }) => bgColor};
    border: solid ${convertPxToRem(6)} ${colors.primary.snow};

    display: flex;
    justify-content: center;
    align-items: center;

    &:hover,
    &:focus {
        border-color: ${colors.secondary.skyBlue};
    }

    ${({ isDisabled = false }) => {
        if (isDisabled) {
            return css`
                cursor: not-allowed;
                color: ${colors.secondary.dolphin};
                border-color: ${colors.primary.whisker};
                background-color: ${colors.primary.whisker};
            `;
        }
    }}
`;

export const FlagTextStyled = styled.span`
    font-size: ${convertPxToRem(12)};
    line-height: 100%;
    margin-right: ${convertPxToRem(4)};
    color: ${colors.primary.snow};

    ${({ isDisabled = false }) => {
        if (isDisabled) {
            return css`
                color: ${colors.secondary.dolphin};
            `;
        }
    }}
`;
