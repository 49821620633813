import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// styles
export const UploadImageStyled = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;

// components
import Flags from "./Flags";
import UploadImage from "./UploadImage.js";

const UploadManager = ({ uploadId }) => {
    return (
        <UploadImageStyled>
            <Flags uploadId={uploadId} />
            <UploadImage uploadId={uploadId} />
        </UploadImageStyled>
    );
};

export default UploadManager;

// prop-types
UploadManager.propTypes = {
    uploadId: PropTypes.string.isRequired
};
