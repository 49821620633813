import gql from "graphql-tag";

// Full Photo Upload Object
export const PHOTO_UPLOAD_WITH_MOD_FRAGMENT = gql`
    fragment ModPhotoUpload on PhotoUploadWithModerationDataType {
        id
        aspectRatio
        thumbnail
        photoTitle
        photoTitleApproved
        photoDescription
        photoDescriptionApproved
        photoBatchId
        status
        originalFilename
        isGem: starred
        mature
        autoNudity
        autoViolence
        autoWarningText
        editorialUseOnly
        hasRecognizablePeopleOrProperty
        license
        userTags
        approvedUserTags
        warningUserTags
        autoTags
        moderationNotes {
            id
            photoUploadId
            actor {
                id
                displayName
                username
                isPhotoModerator
                isManager
            }
            actorDisplayName
            timestamp
            action
        }
    }
`;

export const PHOTO_UPLOAD_TYPE_FRAGMENT = gql`
    fragment PhotoUpload on PhotoUploadType {
        id
        aspectRatio
        thumbnail
        photoTitle
        photoDescription
        photoBatchId
        status
        originalFilename
        editorialUseOnly
        hasRecognizablePeopleOrProperty
        license
        userTags
        approvedUserTags
        warningUserTags
        autoTags
    }
`;
