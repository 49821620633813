export const ESSENTIAL_COOKIE_HEADING = "Essential Cookies (Required)";
export const FUNCTIONALITY_COOKIE_HEADING = "Functionality Cookies";
export const ANALYTICAL_COOKIE_HEADING = "Analytical Cookies";
export const ADVERTISING_COOKIE_HEADING = "Advertising Cookies";

export const ESSENTIAL_COOKIE_DESC =
    "These enable basic functions, such as sign-in, purchasing, page navigation, and your cookie preferences. Noun Project can't function properly without them.";

export const FUNCTIONALITY_COOKIE_DESC =
    "These remember personal settings. In the future these may hold language or layout preferences. Visits may be inconvenient without them.";

export const ANALYTICAL_COOKIE_DESC =
    "These allow us to count visits and understand what is popular or unpopular on our site, when people visit, and other use stats. Information is aggregated (anonymous). Analytics allow us to improve site content and performance for users.";

export const ADVERTISING_COOKIE_DESC =
    "These may be used by the cookie provider to understand your interests and to deliver relevant advertisements here or on other sites. They help support Noun Project via ad revenue.";

export const ESSENTIAL_ARIA_LABEL =
    ESSENTIAL_COOKIE_HEADING + ESSENTIAL_COOKIE_DESC;

export const FUNCTIONALITY_ARIA_LABEL =
    FUNCTIONALITY_COOKIE_HEADING + FUNCTIONALITY_COOKIE_DESC;

export const ANALYTICAL_ARIA_LABEL =
    ANALYTICAL_COOKIE_HEADING + ANALYTICAL_COOKIE_DESC;

export const ADVERTISING_ARIA_LABEL =
    ADVERTISING_COOKIE_HEADING + ADVERTISING_COOKIE_DESC;
