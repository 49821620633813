/*===================================
||
|| PanelOverlayContext
||
===================================*/
import React, { createContext, useReducer, useMemo } from "react";
import PropTypes from "prop-types";

// store
import { reducer } from "./store";

// components
import MountingWrapper from "./components/MountingWrapper";
export { OpenButton } from "./components/OpenButton";
export { PanelOverlay } from "./components/PanelOverlay";

/*---------------------------
| Context
---------------------------*/
export const PanelOverlayContext = createContext();
PanelOverlayContext.displayName = "PanelOverlay"; // Display Name

// Provider
export const PanelOverlayProvider = ({
    children,
    position = "left",
    onOpen = () => {},
    onClose = () => {}
}) => {
    const consumerProps = {
        position,
        onOpen,
        onClose
    };

    const stateProps = {
        hasOpened: false,
        isOpen: false
    };

    // Whatever you want to manage as internal state
    const [state, dispatch] = useReducer(reducer, stateProps);

    // useMemo so it does not pass value on every render
    const value = useMemo(() => ({ state, dispatch, consumerProps }), [
        state,
        dispatch,
        consumerProps
    ]);

    return (
        <PanelOverlayContext.Provider value={value}>
            <MountingWrapper>{children}</MountingWrapper>
        </PanelOverlayContext.Provider>
    );
};

// prop-types
PanelOverlayProvider.propTypes = {
    children: PropTypes.any,
    position: PropTypes.string,
    onOpen: PropTypes.func,
    onClose: PropTypes.func
};
