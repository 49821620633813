import { createStore, applyMiddleware, compose } from "redux";
import { debounce } from "lodash";
import thunk from "redux-thunk";

import logger from "../middleware/logger";
import analytics from "../middleware/analytics";
import api from "../middleware/api";
import metrics from "../middleware/metrics";
import plugin from "../middleware/plugin";
import redirect from "../middleware/redirect";
import asyncDispatch from "../middleware/asyncDispatch";
import sentry from "../middleware/sentry";
import rootReducer from "../reducers";
import { setGlobalConfig, uiWindowResize } from "../actions/actions";

export function configureStore(preloadedState, reducer = rootReducer) {
    const middlewares = [
        thunk,
        api,
        plugin,
        analytics,
        metrics,
        redirect,
        asyncDispatch,
        sentry
    ];

    // Run logger only during development
    if (process.env.NODE_ENV === "development" && process.env.REDUX_LOG) {
        middlewares.push(logger);
    }

    const composeEnhancers =
        process.env.NODE_ENV === "development" &&
        typeof window === "object" &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
            ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
                  name: "TNP Global Store"
              })
            : compose;

    const store = createStore(
        reducer,
        preloadedState,
        composeEnhancers(applyMiddleware(...middlewares))
    );

    if (module.hot) {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept("../reducers", () => {
            const nextReducer = require("../reducers").default;
            store.replaceReducer(nextReducer);
        });
    }

    if (typeof window !== "undefined") {
        const listenResize = () => {
            store.dispatch(
                uiWindowResize(window.innerWidth, window.innerHeight)
            );
        };
        listenResize();
        window.addEventListener("resize", debounce(listenResize, 100));

        if (window._nounConfig) {
            store.dispatch(setGlobalConfig(window._nounConfig));
        } else {
            throw new Error("Could not find _nounConfig");
        }
    }

    return store;
}

export default configureStore();
