import React from "react";
import PropTypes from "prop-types";

import Button from "core/Button";

import { ButtonContainer } from "./styles";

const LoadMoreButton = ({ loadMore, buttonText, loading, href }) => {
    return (
        <ButtonContainer>
            <Button
                href={href}
                onClick={e => {
                    // if no loadMore handler is given, bubble click event to `href` as a fallback
                    if (typeof loadMore === "function") {
                        e.preventDefault();
                        loadMore();
                    }
                }}
                isProcessing={loading}
            >
                {buttonText}
            </Button>
        </ButtonContainer>
    );
};

LoadMoreButton.propTypes = {
    loadMore: PropTypes.func.isRequired,
    buttonText: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired
};

export default LoadMoreButton;
