import { useContext, useCallback } from "react";

// context
import { DropDownContext } from "./index";
import { actionTypes } from "./store";

export const useDropDown = () => {
    const { state, dispatch, nonStateProps } = useContext(DropDownContext);

    const open = () => {
        dispatch({
            type: actionTypes.SET_IS_OPEN,
            isOpen: true
        });
        nonStateProps.onOpen(state);
    };

    const close = useCallback(() => {
        dispatch({
            type: actionTypes.SET_IS_OPEN,
            isOpen: false
        });
        nonStateProps.onClose(state);
    }, [state, nonStateProps]);

    const toggleIsOpen = () => {
        const { isOpen } = state;
        const invertedIsOpen = !isOpen;
        invertedIsOpen ? open() : close();
    };

    return {
        ...state,
        ...nonStateProps,
        toggleIsOpen,
        open,
        close
    };
};
