/*---------------------------
| Data
---------------------------*/
export const moderatorIconKeys = {
    HEART: "heart",
    MATURE_AUDIENCE: "matureAudience",
    SAVE_FOR_LATER: "saveForLater",
    BLUR: "blur",
    EXPAND: "expand"
};

/* ActionBar ---------------------------*/
const getSelectMessage = (uploadSelectedCount, isCompOrSusp = false) => {
    if (uploadSelectedCount > 0) {
        return `${uploadSelectedCount} selected`;
    }
    return isCompOrSusp
        ? `Select an image to view details`
        : `Select an image to edit`;
};

export const actionBar = {
    getSelectMessage
};

/*---------------------------
| Helpers
---------------------------*/
export const footerActions = {
    [moderatorIconKeys.HEART]: false,
    [moderatorIconKeys.MATURE_AUDIENCE]: false,
    [moderatorIconKeys.SAVE_FOR_LATER]: false,
    [moderatorIconKeys.BLUR]: false,
    [moderatorIconKeys.EXPAND]: false
};

const everySelectedUploadHasModIconEnabled = (
    selectedUploadFooterActions,
    modIconKey
) => {
    if (selectedUploadFooterActions.length < 1) {
        return false;
    }

    return selectedUploadFooterActions.every(footerActions => {
        return footerActions?.[modIconKey];
    });
};

export const getActionBarIconActions = selectedUploadFooterActions => {
    return {
        [moderatorIconKeys.HEART]: everySelectedUploadHasModIconEnabled(
            selectedUploadFooterActions,
            moderatorIconKeys.HEART
        ),
        [moderatorIconKeys.MATURE_AUDIENCE]: everySelectedUploadHasModIconEnabled(
            selectedUploadFooterActions,
            moderatorIconKeys.MATURE_AUDIENCE
        ),
        [moderatorIconKeys.SAVE_FOR_LATER]: everySelectedUploadHasModIconEnabled(
            selectedUploadFooterActions,
            moderatorIconKeys.SAVE_FOR_LATER
        ),
        [moderatorIconKeys.BLUR]: everySelectedUploadHasModIconEnabled(
            selectedUploadFooterActions,
            moderatorIconKeys.BLUR
        ),
        [moderatorIconKeys.EXPAND]: false
    };
};
