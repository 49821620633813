import * as Sentry from "@sentry/react";

// Given file, upload to S3, then return object with ok and status properties
export async function uploadToAWS({ url, file, contentType }) {
    const body = new Blob([await file.arrayBuffer()], {
        type: contentType
    });
    const uploadSuccess = await fetch(url, {
        method: "PUT",
        headers: {
            "Content-Type": contentType // required by the S3 upload URL
        },
        body
    })
        .then(s3Response => {
            if (s3Response.status === 200) {
                return { ok: true, status: s3Response.status };
            } else {
                // if S3 upload fails
                Sentry.captureException(
                    new Error(
                        `Failed to upload file to S3. Status: ${s3Response.status}`
                    )
                );
                return { ok: false, status: s3Response.status };
            }
        })
        // if network errors occur
        .catch(e => {
            Sentry.captureException(e);
            return { ok: true, status: null };
        });

    return uploadSuccess;
}
