import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Close from "core/ReusableIcons/Close";
import Checkmark from "core/ReusableIcons/Checkmark";

import { TableColumnHeading } from "core/Table";
import colors from "core/colors";

const browserIsIE =
    typeof window !== "undefined" && window.document.documentMode;

export const FeatureTableMobileColumnHeading = styled(TableColumnHeading)``;

const StyledFeatureTableMobileBullet = styled.td`
    display: flex;
    align-items: center;

    svg {
        position: relative;
        top: -1px;
        margin-right: 0.5rem;
        width: 0.625rem;
        height: auto;
        ${browserIsIE && "height: 0.9375rem;"}
    }
`;

let FeatureTableMobileBullet = ({
    children,
    isFeature,
    className,
    ...otherProps
}) => {
    const Icon = isFeature ? Checkmark : Close;

    return (
        <StyledFeatureTableMobileBullet
            {...otherProps}
            className={className}
            alignItems="center"
        >
            <Icon
                fillColor={
                    isFeature
                        ? colors.accent.lightMode.persian
                        : colors.accent.darkMode.suenosBuenos
                }
            />
            {children}
        </StyledFeatureTableMobileBullet>
    );
};

FeatureTableMobileBullet.propTypes = {
    isFeature: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.string
};

FeatureTableMobileBullet = styled(FeatureTableMobileBullet)``;
export { FeatureTableMobileBullet };

const FeatureTableMobileList = styled.tr`
    display: block;
    height: ${({ height }) => (height ? height : "auto")};
    padding-top: 1.4375rem;
    padding-left: 1.4406rem;

    ${FeatureTableMobileBullet} {
        margin-bottom: 0.5rem;
    }
`;

FeatureTableMobileList.propTypes = {
    height: PropTypes.string
};

export { FeatureTableMobileList };
