import gql from "graphql-tag";

export const GET_SIMILAR_ICONS_DATA = gql`
    query similarIconsData($iconId: ID!, $limit: Int!, $offset: Int = 0) {
        similarIcons(iconId: $iconId, limit: $limit, offset: $offset) {
            items {
                id
                title
                thumbnails {
                    thumbnail200
                }
                url
            }
            totalCount
        }
    }
`;

export const GET_ICON_DETAIL_DATA = gql`
    query iconDetailData($iconId: ID!) {
        iconDetail(iconId: $iconId) {
            title
            url
        }
    }
`;
