import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// components
import Link from "./Link";

// styles
import { convertPxToRem } from "frontend/components/helpers";
const LinkDropDownStyled = styled(Link)`
    display: block;
    text-align: left;
    margin: 0rem 0rem ${convertPxToRem(24)};

    &:last-child {
        margin-bottom: 0rem;
    }

    &:hover {
        opacity: 1;
    }
`;

// component
export const LinkDropDown = ({ className, link }) => {
    return <LinkDropDownStyled className={className} link={link} />;
};

export default LinkDropDown;

// prop-types
LinkDropDown.propTypes = {
    className: PropTypes.string,
    link: PropTypes.object.isRequired
};
