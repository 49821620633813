import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import EllipsesHorizontal from "core/ReusableIcons/EllipsesHorizontal";

import SrOnly from "core/SrOnly";

import { StyledTriggerButton } from "./styles";

const UnstyledTriggerButton = ({
    onClick,
    active,
    isHeroSearchV2,
    size,
    triggerReference,
    isHeader,
    screenReaderText,
    className
}) => {
    return (
        <StyledTriggerButton
            className={className}
            onClick={onClick}
            active={active}
            isHeroSearchV2={isHeroSearchV2}
            ref={triggerReference}
            isHeader={isHeader}
        >
            <SrOnly>{screenReaderText}</SrOnly>
            <EllipsesHorizontal
                size={size}
                onClick={onClick}
                active={active}
                isHeroSearchV2={isHeroSearchV2}
            />
        </StyledTriggerButton>
    );
};

const TriggerButton = styled(UnstyledTriggerButton)``;

TriggerButton.propTypes = {
    screenReaderText: PropTypes.string.isRequired
};

export default TriggerButton;
