import React from "react";
import { useMutation } from "@apollo/client";
import PropTypes from "prop-types";

import { InlineTextEdit, TextArea } from "core/InlineTextEdit";

import { TitleFieldContainer } from "./styles";

import { SET_PHOTO_UPLOAD_TITLE } from "./mutations";

import { getBeToFeErrMsgRemap } from "frontend/components/creator-tools/PhotoUploadPage/helpers.js";
import { errMsgMappings } from "./constants";

const TitleSection = ({ upload }) => {
    const [setPhotoUploadTitle] = useMutation(SET_PHOTO_UPLOAD_TITLE);

    const updateTitle = async title => {
        return await setPhotoUploadTitle({
            variables: {
                id: upload.id,
                value: title
            }
        });
    };

    const onSave = async ({ editedText }) => {
        // assume error by default
        let onSaveResponse = {
            errors: true, // if set to false, exits {editing mode}
            errorMessage: "Sorry, something went wrong. Please try again."
        };

        const response = await updateTitle(editedText);

        // something happened on BE, perhaps server is down?
        if (!response) {
            return onSaveResponse;
        }

        // handle server response
        const {
            data: {
                setPhotoUploadTitle: { ok, errors: beErrMsg }
            }
        } = response;

        // if there were server-side issues
        if (!ok) {
            const customMessage = getBeToFeErrMsgRemap(
                beErrMsg,
                errMsgMappings.title
            );

            const errorMessage = customMessage
                ? customMessage.fe
                : onSaveResponse.errorMessage;

            return {
                ...onSaveResponse,
                errorMessage
            };
        }
        // everything appears to be successful.
        return {
            errors: false,
            errorMessage: ""
        };
    };

    return (
        <TitleFieldContainer>
            <InlineTextEdit
                ariaLabel="Edit title"
                placeholder="Add Title"
                id={`edit-upload-title-${upload.id}`}
                text={upload.photoTitle || ""}
                onSave={onSave}
                saveOnBlur={true}
            >
                <TextArea placeholder="Title (Optional)" />
            </InlineTextEdit>
        </TitleFieldContainer>
    );
};

export default TitleSection;

TitleSection.propTypes = {
    upload: PropTypes.object.isRequired
};
