import React from "react";
import styled from "styled-components";

import Heading from "core/Heading";
import LoadingSpinner from "core/LoadingSpinner";
import SubmissionImageGrid from "./SubmissionImageGrid";
import Link from "core/Link";
import { convertPxToRem } from "frontend/components/helpers";

import { GET_ALL_DENIED_PHOTO_SUBMISSIONS } from "./queries";

import useLoadMore from "frontend/hooks/useLoadMore";

const LinkContainer = styled.div`
    text-align: center;
    margin-top: ${convertPxToRem(24)};
`;

const SubmissionsDenied = () => {
    const { items, totalCount, totalItemsLoaded, LoadMore } = useLoadMore({
        query: GET_ALL_DENIED_PHOTO_SUBMISSIONS,
        pathToListKey: "allDeniedPhotoSubmissions.items",
        pathToTotalCountKey: "allDeniedPhotoSubmissions.totalCount",
        limit: 50
    });

    return (
        <div data-testid="submissions-denied">
            {isNaN(totalItemsLoaded) ? (
                <LoadingSpinner />
            ) : totalCount === 0 ? (
                <Heading size="md">No photos have been denied.</Heading>
            ) : (
                <>
                    <SubmissionImageGrid submissions={items} />
                    <LoadMore buttonText="Load More" />
                    <LinkContainer>
                        <Link
                            href="https://help.thenounproject.com/hc/en-us/articles/23936182082331-Why-were-my-photos-denied"
                            target="_blank"
                        >
                            Learn reasons why photos are denied
                        </Link>
                    </LinkContainer>
                </>
            )}
        </div>
    );
};

export default SubmissionsDenied;
