import { actionTypes } from "./actionTypes";
/*---------------------------
| Actions
---------------------------*/
export const isVisibleUpdate = isVisible => {
    return {
        type: actionTypes.MODAL2_IS_VISIBLE_UPDATE,
        isVisible
    };
};
