import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { useCurrentUserContext } from "contexts/CurrentUserContext";
import { getHeaderVisibility, getDarkHeader } from "frontend/store/layout";
import { useMyModerator } from "frontend/components/fort/mod/photos/hooks/useMyModerator.js";

import SiteHeader from "core/SiteHeader";

import logOutAndRedirect from "helpers/log-out";

import colors from "core/colors";
import { useBanners } from "frontend/context/BannersContext";

const SiteHeaderParent = ({ currentPage }) => {
    const {
        currentUser: { loading, data }
    } = useCurrentUserContext();
    const { currentUser } = data;
    const myModeratorProfile = useMyModerator();
    const myPhotoModProfile = myModeratorProfile?.myModProfile;

    const isDarkHeroAndHeader = useSelector(getDarkHeader);
    const isVisible = useSelector(getHeaderVisibility);

    const heroSearchBgColor = isDarkHeroAndHeader ? colors.secondary.orca : "";

    const {
        hideBanner,
        dismissBanner,
        bannerContent,
        bannerContentMobile,
        bannerKey
    } = useBanners();

    if (!isVisible) return null;

    return (
        <SiteHeader
            hideBanner={hideBanner}
            dismissBanner={dismissBanner}
            bannerContent={bannerContent}
            bannerContentMobile={bannerContentMobile}
            bannerKey={bannerKey}
            isHeroSearchV2={isDarkHeroAndHeader}
            heroSearchBgColor={heroSearchBgColor}
            currentUser={currentUser}
            myPhotoModProfile={myPhotoModProfile}
            transparent={false}
            handleLogOut={logOutAndRedirect}
            loading={loading}
            currentPage={currentPage}
        />
    );
};

export default SiteHeaderParent;

// prop-types
SiteHeaderParent.propTypes = {
    currentPage: PropTypes.string
};
