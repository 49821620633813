import React from "react";
import PropTypes from "prop-types";

// components
export { Heart } from "./Heart";
export { MatureAudience } from "./MatureAudience";
export { Divider } from "./Divider";
export { Clock } from "./Clock";
export { Eye } from "./Eye";
export { Expand } from "./Expand";

// styles
import { ModerationToggleBandStyled } from "./styles.js";

const ModerationToggleBand = ({ children, isWhiteBackground = false }) => {
    return (
        <ModerationToggleBandStyled isWhiteBackground={isWhiteBackground}>
            {children}
        </ModerationToggleBandStyled>
    );
};

export default ModerationToggleBand;

// prop-types
ModerationToggleBand.propTypes = {
    children: PropTypes.any,
    isWhiteBackground: PropTypes.bool
};
