import React from "react";
import styled from "styled-components";

import Heading from "core/Heading";
import BodyText from "core/BodyText";
import Modal from "core/Modal";
import OffsetGrid from "core/OffsetGrid";

import { convertPxToRem } from "frontend/components/helpers";
import { handleBottomSpacing } from "js/helpers/spacingAndFonts";
import colors from "core/colors";
import { medium } from "core/fontStyles";

const FullScreenGrid = styled(OffsetGrid)`
    height: 100%;
`;

// https://developers.google.com/identity/gsi/web/reference/html-reference#data-width
export const GOOGLE_AUTH_BUTTON_MAX_WIDTH_PX = 400;

const ModalContent = styled.div`
    max-width: ${convertPxToRem({ px: GOOGLE_AUTH_BUTTON_MAX_WIDTH_PX })};
    margin-left: auto;
    margin-right: auto;
    ${handleBottomSpacing({ bottomSpacing: "md" })}
`;

// `disableEnforceFocus` configures material-ui to not close hcaptcha's sub-menus
export const FullScreenModal = ({ children, ...props }) => (
    <Modal open size="fullScreen" disableEnforceFocus {...props}>
        <FullScreenGrid
            sm={12}
            md={8}
            lg={4}
            justifyContent="center"
            alignItems="center"
        >
            <ModalContent>{children}</ModalContent>
        </FullScreenGrid>
    </Modal>
);

export const Title = styled(Heading).attrs(({ alignment = "left" }) => ({
    type: "div",
    size: "lg",
    bottomSpacing: "sm",
    headingColor: colors.secondary.orca,
    alignment
}))`
    ${medium}
`;

export const SubTitle = styled(BodyText).attrs({
    type: "div",
    size: "lg",
    copyColor: colors.secondary.orca
})``;
