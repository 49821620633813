export const UPLOAD_TYPES = {
    INDIVIDUAL: "individual",
    PHOTO_SHOOT: "photo-shoot"
};

// for any photo that fails file check, backend adds prefix
// to original filename. this function lets us remove that prefix
// so we can use the original filename in the code
export const removeOriginalFilenamePrefix = originalFilename =>
    originalFilename.replace(/^[^.]+\.\d+-/, "");
