import React from "react";
import PropTypes from "prop-types";

// styles
import { QueueMainStyled, QueueMainContainerStyled } from "./styles";

export const QueueMain = ({ children }) => {
    return (
        <QueueMainStyled>
            <QueueMainContainerStyled>{children}</QueueMainContainerStyled>
        </QueueMainStyled>
    );
};

// prop-types
QueueMain.propTypes = {
    children: PropTypes.any
};
