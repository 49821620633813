import styled from "styled-components";

// styles
import colors from "core/colors";
import { convertPxToRem } from "frontend/components/helpers";
import { medium as mediumFontWeight } from "core/fontStyles";

// styled-components
export const SideBarSectionStyled = styled.section`
    padding: ${convertPxToRem(24)} ${convertPxToRem(16)};
    border-bottom: solid 1px ${colors.primary.scheifer};

    &:last-child {
        border-bottom: none;
    }
`;

export const TitleStyled = styled.h2`
    margin: 0px;
    margin-bottom: ${convertPxToRem(16)};
    font-size: ${convertPxToRem(15)};
    ${mediumFontWeight};
`;
