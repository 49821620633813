import React from "react";

import { PhotoUploadProvider } from "frontend/hooks/usePhotoUpload";
import { SelectProviderForDropzone } from "frontend/components/core/Grid2/GridItemContentTemplates/Select/SelectContext/index.js";
import { EditPhotoUploadProvider } from "../PhotoUpload/Edit/EditPhotoUploadContext";
import { ReleasesProvider } from "../PhotoUpload/Releases/hooks/useReleases";
import { PhotoUploadDropzoneProvider } from "./hooks/usePhotoUploadDropzone";
import { AllPhotoUploadsProvider } from "./context/AllPhotoUploadsContext";

const PhotoUploadProviders = ({
    children,
    initialPhotoUploads,
    selectedUploadIds
}) => {
    return (
        <PhotoUploadProvider initialPhotoUploads={initialPhotoUploads}>
            <AllPhotoUploadsProvider>
                <PhotoUploadDropzoneProvider>
                    <ReleasesProvider>
                        <SelectProviderForDropzone
                            selectedUploadIds={selectedUploadIds}
                        >
                            <EditPhotoUploadProvider>
                                {children}
                            </EditPhotoUploadProvider>
                        </SelectProviderForDropzone>
                    </ReleasesProvider>
                </PhotoUploadDropzoneProvider>
            </AllPhotoUploadsProvider>
        </PhotoUploadProvider>
    );
};

export default PhotoUploadProviders;
