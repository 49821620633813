import styled from "styled-components";
import { Link as RouterLink } from "react-router-dom";

import MessageBanner from "components/MessageBanner";
import FormInput from "core/FormInput";

import { SectionDark } from "core/Layout";
import { easingSeconds } from "core/animation";
import { A, P, BorderBottomLinkStyle } from "core/Typography";
import { OldGrid, Col } from "core/oldGrid";
import { regular } from "core/fontStyles";
import fonts from "core/fonts";
import breakpoints from "core/breakpoints";
import colors from "core/colors";

import NounLogoWordmark from "core/ReusableIcons/NounLogoWordmark";
import Button from "core/Button";

import { convertPxToRem } from "frontend/components/helpers";

// newsletter subscription
export const NewsletterSubscriptionSuccessMessage = styled(MessageBanner)`
    width: 20.125rem;

    @media (max-width: ${breakpoints.tablet.rem}) {
        width: 100%;
    }
`;

export const NewsletterSubscriptionSubmitBtn = styled(Button).attrs({
    darkMode: true,
    size: "md",
    width: convertPxToRem({ px: 95 })
})`
    @media (max-width: ${breakpoints.tablet.rem}) {
        width: 100%;
    }
`;

export const NewsletterSubscriptionForm = styled.form`
    display: flex;

    ${FormInput} {
        margin-right: 0.8125rem;

        /* todo: update this breakpoint when we refactor footer */
        @media (max-width: ${breakpoints.tablet.rem}) {
            width: 100%;
        }

        @media (max-width: ${breakpoints.tablet.rem}) {
            margin-right: 0;
        }
    }

    @media (max-width: ${breakpoints.tablet.rem}) {
        display: block;
    }
`;

export const NewsletterSubscriptionHeading = styled(P)`
    ${regular}
    font-size: 1.125rem;
    color: ${colors.primary.diamond};
`;

export const NewsletterSubscription = styled.div`
    @media (max-width: ${breakpoints.tablet.rem}) {
        width: 100%;
        margin-top: 3rem;
        margin-bottom: 3rem;
    }

    ${NewsletterSubscriptionHeading} {
        margin-top: 0;
        margin-bottom: 1.25rem;
    }
`;

// social media
export const SocialsItem = styled.li`
    width: 2.125rem;
    height: 2.125rem;
    background-color: ${colors.secondary.oolong};
    border-radius: 50%;
    position: relative;
    transition: background-color ${easingSeconds};

    &:hover {
        background-color: ${colors.secondary.dolphin};
    }
`;

export const Socials = styled.ul`
    display: flex;
    margin-top: 1rem;

    ${SocialsItem} {
        margin-right: 0.5rem;
    }

    svg {
        position: absolute;
        width: 1.375rem;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
`;

// logo
export const HomeLink = styled(A)`
    font-size: 13px;
    line-height: 16.5px;
`;

export const NounProjectLogo = styled(NounLogoWordmark)`
    width: 16.5rem;
    transition: opacity ${easingSeconds};

    &:hover {
        opacity: 0.6;
    }
`;

// text links
export const Link = styled(RouterLink)`
    text-decoration: none;
    ${BorderBottomLinkStyle}
    border-bottom: 1px solid ${colors.secondary.cosmic};
    &,
    &:any-link {
        color: ${colors.primary.diamond};

        &:hover {
            text-decoration: none;
            color: ${fonts.fontColors.light.link};
            border-color: ${fonts.fontColors.light.link};
        }
    }
`;

export const TextLink = styled.li``;

export const TextLinks = styled.ul`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @media (max-width: 75rem) {
        margin-top: -1.5rem;
    }

    ${TextLink} {
        margin-left: 1.5rem;

        @media (max-width: ${breakpoints.tablet.rem}) {
            margin-left: 1.875rem;
            margin-top: 1.125rem;
        }
    }
`;

export const DesktopTextLinksCol = styled(Col)`
    align-self: center;
`;

export const DesktopTextLinks = styled(TextLinks)`
    margin-left: -1.5rem;

    @media (max-width: 75rem) {
        display: none;
    }
`;

export const TabletMobileTextLinks = styled(TextLinks)`
    display: none;
    margin-left: -1.5rem;

    @media (max-width: 75rem) {
        display: flex;
        margin-top: ${convertPxToRem({ px: 35 })};
    }

    @media (max-width: ${breakpoints.tablet.rem}) {
        margin-top: -1.125rem;
        margin-left: -1.875rem;
    }
`;

// copyright bar
export const CopyrightBar = styled(SectionDark)`
    ${regular}
    text-align: center;
    width: 100vw;
    background-color: ${colors.secondary.orca};
    padding: 0.5rem 0 0.75rem;
    color: ${colors.primary.diamond};
`;

// layout
export const SiteFooterGrid = styled(OldGrid)`
    overflow-x: visible;
    padding-left: 0;
    padding-right: 0;
`;

export const SiteFooterWrapper = styled(SectionDark)`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: ${colors.secondary.cosmic};
    padding-top: 5rem;
    padding-bottom: 5rem;

    @media (max-width: ${breakpoints.desktop.rem}) {
        padding-top: 3.625rem;
        padding-bottom: 3.625rem;
    }

    @media (max-width: ${breakpoints.tablet.rem}) {
        padding-top: 3.125rem;
        padding-bottom: 3.125rem;
    }

    /* desktop-only - restrict height due to extra 20px from newsletter forminput: */
    @media (min-width: ${breakpoints.desktop.rem}) {
        height: ${convertPxToRem({ px: 240 })};
    }
`;

export const Footer = styled.footer`
    p {
        line-height: 16.5px;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
    }

    ${SiteFooterWrapper},
    ${CopyrightBar} {
        width: 100%;
        left: auto;
        right: auto;
        margin-left: 0;
        margin-right: 0;
    }
`;
