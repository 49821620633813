import React, { useRef, useEffect } from "react";
import { useTheme } from "styled-components";

// Context
import { useFormSelectDialog } from "./index";

// styles
import {
    ControlCaretStyled,
    ControlEditStyled,
    ContentWrapperStyled,
    DisplayTextStyled,
    IconStyled
} from "./styles";

// components
import CaretDown from "core/ReusableIcons/CaretDown";
import Edit from "core/ReusableIcons/Edit";

const Control = () => {
    // state/props
    const {
        id,
        selectedOption,
        controlLabelOverride,
        controlType,
        hasOpenedBefore,
        isOpen,
        setIsOpen,
        isDisabled
    } = useFormSelectDialog();
    const {
        icon: { size }
    } = useTheme();

    const controlButtonRef = useRef();

    const displaytext =
        controlLabelOverride ||
        selectedOption.controlLabelOverride ||
        selectedOption.label;

    let ControlStyled = ControlCaretStyled;
    let Icon = CaretDown;

    if (controlType === "edit") {
        ControlStyled = ControlEditStyled;
        Icon = Edit;
    }

    // methods
    const onClick = () => {
        if (!isDisabled) {
            setIsOpen(!isOpen);
        }
    };

    // side effects
    useEffect(() => {
        if (hasOpenedBefore && !isOpen) {
            controlButtonRef.current.focus();
        }
    }, [hasOpenedBefore, isOpen]);

    // render
    return (
        <ControlStyled
            id={`${id}-dropdown-button`}
            type="button"
            onClick={onClick}
            ref={controlButtonRef}
            isDisabled={isDisabled}
            isOpen={isOpen}
        >
            <ContentWrapperStyled>
                <DisplayTextStyled>{displaytext}</DisplayTextStyled>
                {!isDisabled && (
                    <IconStyled>
                        <Icon size={{ width: size, height: size }} />
                    </IconStyled>
                )}
            </ContentWrapperStyled>
        </ControlStyled>
    );
};

export default Control;
