import React from "react";

//components
import Link from "core/Link";
import PageMeta from "core/PageMeta";

//data
import {
    BROWSE_SIMILAR_BY_PHOTO_ID,
    BROWSE_BY_TAG,
    BROWSE_BY_SHOOT_ID,
    SEARCH,
    BROWSE_BY_CREATOR_USERNAME,
    BROWSE_BY_CAMPAIGN,
    PUBLIC_PROFILE,
    homePath
} from "data/links";

//helpers
import { removeTwoDots } from "js/helpers/functions";

//styles
import { UserAvatar } from "components/ProfileMenu/styles";
import Flexbox from "core/Flexbox";
import AvatarPlaceholderImg from "core/AvatarPlaceholderImg";
import Avatar from "core/Avatar";
import { TitleText } from "./styles";

// eslint-disable-next-line complexity
export const generatePageData = (input, keys) => {
    let headerTitle,
        headerDetails,
        noResultsText,
        photosUrl,
        iconQuery,
        creatorUserName,
        shootDescription,
        shootLocation,
        shootTitle,
        notFound,
        descriptionText,
        tagName;
    const {
        data,
        key,
        tag,
        searchQuery,
        creator: CREATOR_USERNAME,
        shootId,
        photoId
    } = input;
    const {
        BROWSE_PHOTOS_BY_TAG,
        PHOTO_SEARCH,
        BROWSE_PHOTOS_BY_CREATOR,
        SEARCH_CREATOR_PHOTOS,
        GET_PHOTOS_BY_SHOOT_ID,
        BROWSE_SIMILAR_PHOTOS,
        BROWSE_PHOTOS_BY_CAMPAIGNS
    } = keys;

    const iconCollectionsText = window.innerWidth <= 425 ? "" : "Icon";
    const browseSimilarPhotosTitle = data.photoDetail && data.photoDetail.title;

    let moreInfoLink,
        moreInfoText,
        campaignDescription,
        campaignDetail,
        photoShootDetail,
        creator,
        creatorName,
        creatorAvatarUrl,
        creatorProfileUrl,
        metaDescription;

    switch (key) {
        case BROWSE_PHOTOS_BY_TAG:
            // HACK: Reformat the tag with spaces instead of the dashes that
            // are in the url. We should load the tag name from the backend,
            // but there's no way to do that yet.
            tagName = tag.replace(/-/gi, " ");
            headerTitle = `Browse "${tagName}" Photos`;
            noResultsText = `for “${tagName}”`;
            photosUrl = BROWSE_BY_TAG.replace(":tag", tag);
            iconQuery = tagName;
            headerDetails = (
                <PageMeta
                    title={`${tagName} Pictures - Find ${tagName} Photos | Noun Project`}
                    description={removeTwoDots(
                        `Find ${tagName} pictures for your next project. Explore a diverse collection of art-quality ${tagName} photos from photographers at the Noun Project.`
                    )}
                />
            );
            break;
        case BROWSE_PHOTOS_BY_CAMPAIGNS:
            campaignDetail = data.campaignDetail;
            headerTitle = campaignDetail?.title || null;
            moreInfoLink = campaignDetail?.moreInfoLink || null;
            moreInfoText = campaignDetail?.moreInfoText || null;
            campaignDescription = campaignDetail?.description || null;

            if (campaignDescription) {
                if (moreInfoLink && moreInfoText) {
                    descriptionText = (
                        <>
                            {campaignDetail.description}{" "}
                            <a
                                href={`${moreInfoLink}`}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                {moreInfoText}
                            </a>
                        </>
                    );
                } else {
                    descriptionText = <>{campaignDescription}</>;
                }
            }

            noResultsText = `for “${headerTitle}”`;
            photosUrl = campaignDetail
                ? BROWSE_BY_CAMPAIGN.replace(
                      ":campaignDetail",
                      campaignDetail.slugWithId
                  )
                : "";
            iconQuery = headerTitle;
            metaDescription = `Browse the '${headerTitle}' Initiative at Noun Project. Explore a diverse collection of art-quality photos from our curated campaigns today!`;

            headerDetails = (
                <PageMeta
                    useSocialMediaTags
                    previewImg={
                        data[key].items[0]
                            ? data[key].items[0].assets.thumbnailMedium
                            : ""
                    }
                    description={metaDescription}
                    title={`${headerTitle} Initiative Photos | Noun Project`}
                    indexContent="index,follow,max-image-preview:large"
                    structuredData={{
                        "@context": "https://schema.org",
                        "@type": "ImageGallery",
                        additionalType: "photo",
                        name: `${headerTitle} Campaign Photos | Noun Project`,
                        contentUrl: photosUrl,
                        description: `Browse the ${headerTitle} Campaign at Noun Project. Explore a diverse collection of art-quality photos from our curated campaigns today!`,
                        publisher: "The Noun Project"
                    }}
                />
            );
            break;
        case BROWSE_SIMILAR_PHOTOS:
            headerTitle = (
                <>
                    {`Photos Similar to `}
                    <TitleText>{browseSimilarPhotosTitle}</TitleText>
                </>
            );
            noResultsText = `similar to “${browseSimilarPhotosTitle}”`;
            photosUrl = BROWSE_SIMILAR_BY_PHOTO_ID.replace(":photoId", photoId);
            headerDetails = (
                <PageMeta
                    title={`Pictures Similar To Photo ${photoId} | Noun Project`}
                    description="Find free and royalty-free photos at Noun Project. Browse millions of photos and icons today!"
                />
            );
            break;
        case PHOTO_SEARCH:
            // handles regular search and creator-specific search
            headerTitle = `Results for “${searchQuery}”`;
            noResultsText = `for “${searchQuery}”`;
            photosUrl = `${SEARCH}?q=${searchQuery}`;
            iconQuery = searchQuery;
            headerDetails = (
                <PageMeta
                    title="Search Photos | Noun Project"
                    description="Use the Noun Project photo search to browse millions of free photos and royalty-free pictures. Start searching for stock photos today."
                />
            );
            break;
        case GET_PHOTOS_BY_SHOOT_ID:
            photoShootDetail = data.photoShootDetail;
            creatorAvatarUrl =
                (photoShootDetail &&
                    photoShootDetail.creator &&
                    photoShootDetail.creator.avatarUrl) ||
                "";
            creatorProfileUrl =
                (photoShootDetail &&
                    photoShootDetail.creator &&
                    photoShootDetail.creator.username &&
                    PUBLIC_PROFILE.replace(
                        ":username",
                        encodeURIComponent(photoShootDetail.creator.username)
                    )) ||
                "";
            creatorName =
                (photoShootDetail &&
                    photoShootDetail.creator &&
                    photoShootDetail.creator.name) ||
                "Unknown Creator";
            shootDescription =
                (photoShootDetail && photoShootDetail.description) || "";
            shootLocation =
                (photoShootDetail && photoShootDetail.shootLocation) || "";
            shootTitle = (photoShootDetail && photoShootDetail.title) || "";
            notFound = !photoShootDetail;
            headerTitle = (
                <Flexbox alignItems="center">
                    <Avatar
                        src={creatorAvatarUrl}
                        rightMargin="0.5rem"
                        width="1.8125rem"
                    />
                    {shootTitle} by&nbsp;
                    <Link
                        href={creatorProfileUrl}
                        size="lg"
                        styleType="primary"
                        display="inline"
                    >
                        {creatorName}
                    </Link>
                </Flexbox>
            );
            photosUrl = BROWSE_BY_SHOOT_ID.replace(
                ":shootInfo",
                `${shootTitle}-${shootId}`
            );
            metaDescription = removeTwoDots(
                `Explore the ${shootTitle} photo shoot by ${creatorName} at Noun Project. Browse these images and countless others today!`
            );
            headerDetails = (
                <PageMeta
                    title={`${shootTitle} Photos by ${creatorName} | Noun Project`}
                    description={metaDescription}
                    indexContent="index,follow,max-image-preview:large"
                    previewImg="https://static.production.thenounproject.com/img/nounproject_logo.60e860f212a5.png"
                    useSocialMediaTags
                    structuredData={{
                        "@context": "https://schema.org",
                        "@type": "ImageGallery",
                        additionalType: "photo",
                        name: shootTitle,
                        contentUrl:
                            window.location.origin + window.location.pathname,
                        description: metaDescription,
                        publisher: "The Noun Project"
                    }}
                />
            );
            break;
        case BROWSE_PHOTOS_BY_CREATOR:
            creator = data.creatorProfileDetailByUsername;
            creatorName = (creator && creator.name) || "Unknown Creator";
            creatorAvatarUrl = creator && creator.avatarUrl;
            creatorProfileUrl = creator && `${homePath.url}${creator.username}`;
            creatorUserName = (creator && creator.username) || "";
            headerTitle = (
                <>
                    <a
                        href={PUBLIC_PROFILE.replace(
                            ":username",
                            encodeURIComponent(creatorUserName)
                        )}
                    >
                        {creatorAvatarUrl ? (
                            <UserAvatar src={creatorAvatarUrl} />
                        ) : (
                            <AvatarPlaceholderImg />
                        )}
                    </a>
                    {" All Photos by "}
                    <Link
                        href={PUBLIC_PROFILE.replace(
                            ":username",
                            encodeURIComponent(creatorUserName)
                        )}
                        styleType="primary"
                        size="lg"
                        display="inline"
                    >
                        {creatorName}
                    </Link>
                </>
            );
            noResultsText = `by ${creatorName}`;
            photosUrl = BROWSE_BY_CREATOR_USERNAME.replace(
                ":username",
                CREATOR_USERNAME
            );
            iconQuery = CREATOR_USERNAME;
            headerDetails = (
                <PageMeta
                    title={`All Photos by ${creatorName} | Noun Project`}
                    description={`Download JPG photos by ${creatorName} at Noun Project. Browse millions of photos and icons today!`}
                />
            );
            break;
        default:
            break;
    }

    return {
        totalCount: data[key].totalCount || 0,
        iconCount: data[key].iconCount || 0,
        collectionCount: data[key].collectionCount || 0,
        iconCollectionsText,
        headerTitle,
        descriptionText,
        campaignDescription,
        headerDetails,
        shootDescription,
        shootLocation,
        noResultsText,
        photosUrl,
        iconQuery,
        notFound
    };
};
