import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import colors from "core/colors";

const createHelpers = (width, height, css) => {
    // somehow sizes is ending up in markup, even if it is not a valid svg attribute
    // until we have a better solution, just render it empty, instead to '[Object object]'
    const sanitizeSizes = sizes =>
        Object.defineProperty(sizes, "toString", {
            value: () => "",
            enumerable: false
        });

    const getDimensions = (size, sizes) => {
        if (
            size &&
            typeof size.width === "number" &&
            typeof size.height === "number"
        ) {
            return size;
        }

        return size && sizes[size] ? sizes[size] : { width, height };
    };

    const getCss = (size, sizes, fillColor, fillColorRule, noStyles) => {
        if (noStyles) {
            return "";
        }

        const dimensions = getDimensions(size, sizes);
        const fillRule =
            fillColor && fillColorRule
                ? `${fillColorRule}{ fill: ${fillColor}; }`
                : "";
        return css`
            width: ${dimensions.width}px;
            height: ${dimensions.height}px;
            ${fillRule}
        `;
    };

    const propsToCss = ({ size, sizes, fillColor, fillColorRule, noStyles }) =>
        getCss(size, sizes, fillColor, fillColorRule, noStyles);

    return {
        getCss,
        getDimensions,
        propsToCss,
        sanitizeSizes
    };
};

const width = "22";
const height = "22";
const viewBox = "0 0 22 22";

const { getDimensions, getCss, propsToCss, sanitizeSizes } = createHelpers(
    width,
    height,
    css
);

const sizes = sanitizeSizes({
    xs: { width: 14, height: 14 },
    sm: { width: 16, height: 16 },
    md: { width: 20, height: 20 },
    lg: { width: 24, height: 24 },
    xl: { width: 40, height: 40 }
});

const Image = styled.svg`
    ${propsToCss}
`;

const children = (
    <Fragment>
        <path
            d="m11,0C8.08,0,5.28,1.16,3.22,3.22,1.16,5.28,0,8.08,0,11s1.16,5.72,3.22,7.78c2.06,2.06,4.86,3.22,7.78,3.22s5.72-1.16,7.78-3.22c2.06-2.06,3.22-4.86,3.22-7.78s-1.16-5.72-3.22-7.78C16.72,1.16,13.92,0,11,0Zm0,20c-2.39,0-4.68-.95-6.36-2.64-1.69-1.69-2.64-3.98-2.64-6.36s.95-4.68,2.64-6.36c1.69-1.69,3.98-2.64,6.36-2.64s4.68.95,6.36,2.64c1.69,1.69,2.64,3.98,2.64,6.36s-.95,4.68-2.64,6.36c-1.69,1.69-3.98,2.64-6.36,2.64Z"
            fill={colors.secondary.orca}
        />
        <path
            d="m12,10.59v-5.59c0-.36-.19-.69-.5-.87-.31-.18-.69-.18-1,0-.31.18-.5.51-.5.87v6c0,.27.1.52.29.71l3,3c.26.22.61.3.93.2.32-.1.58-.35.68-.68.1-.33.02-.68-.2-.93l-2.7-2.71Z"
            fill={colors.secondary.orca}
        />
    </Fragment>
);

const defaultProps = {
    children,
    viewBox,
    fillColor: null,
    fillColorRule: "&&& path, &&& use, &&& g, &&& circle",
    sizes,
    size: null
};

Image.propTypes /* remove-proptypes */ = {
    fillColor: PropTypes.string,
    fillColorRule: PropTypes.string,
    viewBox: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    size: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            height: PropTypes.number.isRequired,
            width: PropTypes.number.isRequired
        })
    ]),
    sizes: PropTypes.shape({
        height: PropTypes.number,
        width: PropTypes.number
    })
};

export default Object.assign(Image, {
    getDimensions,
    getCss,
    defaultProps,
    displayName: "ClockOutline"
});
