import React from "react";
import styled, { css } from "styled-components";

import BodyText from "core/BodyText";
import FormError from "core/FormError";
import SectionContainer from "core/SectionContainer";
import { FormElementContainer } from "core/Form";
import Button from "core/Button";
import Checkbox from "core/Checkbox";
import { Captcha } from "js/components/captcha";

import {
    handleTopSpacing,
    handleBottomSpacing
} from "js/helpers/spacingAndFonts";
import { textSm, textLg, headingLg, medium } from "core/fontStyles";
import { convertPxToRem } from "frontend/components/helpers";
import breakpoints from "core/breakpoints";
import colors from "core/colors";
import { spacingWithBreakpoints } from "core/spacing";

import { GOOGLE_AUTH_BUTTON_MAX_WIDTH_PX } from "frontend/components/auth/AuthModal/styles";

export const PageWrapper = styled.div`
    width: 100%;
    background-color: ${({ darkMode }) =>
        darkMode ? colors.secondary.orca : colors.primary.snow};
    flex-grow: 1;
`;

export const PageFormContent = styled.div`
    background-color: ${colors.primary.snow};
    margin-top: 0;
    margin-bottom: ${convertPxToRem({ px: 64 })};
    @media (min-width: ${breakpoints.laptop.rem}) {
        max-width: ${convertPxToRem({ px: 874 })};
        margin-left: auto;
        margin-right: auto;
        padding-left: ${convertPxToRem({ px: 148 })};
        padding-right: ${convertPxToRem({ px: 148 })};
        padding-top: ${convertPxToRem({ px: 16 })};
        padding-bottom: ${convertPxToRem({ px: 40 })};
    }
    @media (max-width: ${breakpoints.beforeLaptop.rem}) {
        margin-left: ${convertPxToRem({ px: 20 })};
        margin-right: ${convertPxToRem({ px: 20 })};
        padding-left: 17%;
        padding-right: 17%;
        padding-top: ${convertPxToRem({ px: 12 })};
        padding-bottom: ${convertPxToRem({ px: 36 })};
    }
    @media (max-width: ${breakpoints.beforeTablet.rem}) {
        margin-left: 0;
        margin-right: 0;
        padding-left: ${convertPxToRem({ px: 15 })};
        padding-right: ${convertPxToRem({ px: 15 })};
        padding-top: ${convertPxToRem({ px: 8 })};
        padding-bottom: ${convertPxToRem({ px: 32 })};
    }
`;

export const SocialSignupConfirmationFormContainer = styled(
    SectionContainer
).attrs({
    bgColor: colors.primary.snow,
    topSpacing: "2xl",
    bottomSpacing: "2xl"
})`
    max-width: ${convertPxToRem({ px: 874 })};
    margin: 0 auto;
`;

export const AuthFormPageContent = styled(SectionContainer).attrs({
    bgColor: colors.primary.snow,
    topSpacing: "none",
    bottomSpacing: "2xl"
})`
    max-width: ${convertPxToRem({ px: 874 })};
    margin-left: auto;
    margin-right: auto;
`;

export const FormErrorContainer = styled.div`
    display: flex;
    align-items: flex-end;
    height: ${convertPxToRem({ px: 40 })};

    @media (max-width: ${breakpoints.tablet.rem}) {
        height: ${convertPxToRem({ px: 36 })};
    }

    @media (max-width: ${breakpoints.beforeTablet.rem}) {
        height: ${convertPxToRem({ px: 32 })};
    }

    ${FormError} {
        margin: 0;
    }
`;

export const FormContainer = styled.div`
    max-width: ${convertPxToRem({
        px: GOOGLE_AUTH_BUTTON_MAX_WIDTH_PX
    })};
    margin-left: auto;
    margin-right: auto;
`;

export const PageFeedbackContent = styled.div`
    ${spacingWithBreakpoints.HeroBottom}

    @media (min-width: ${breakpoints.laptop.rem}) {
        max-width: ${convertPxToRem({ px: 428 })};
        margin-left: auto;
        margin-right: auto;
    }
    @media (max-width: ${breakpoints.beforeLaptop.rem}) {
        width: 100%;
        padding-left: 17%;
        padding-right: 17%;
    }
    @media (max-width: ${breakpoints.beforeTablet.rem}) {
        padding-left: ${convertPxToRem({ px: 15 })};
        padding-right: ${convertPxToRem({ px: 15 })};
    }
`;

export const PageTitle = styled.div`
    ${headingLg}
    ${medium}
    ${spacingWithBreakpoints.XL4Top}
    padding-bottom: ${({ bottomSpacing = 8 }) =>
        convertPxToRem({ px: bottomSpacing })};
    color: ${({ darkMode }) =>
        darkMode ? colors.primary.diamond : colors.secondary.orca};
    text-align: center;
`;

export const PageSubtitle = styled.div`
    ${textLg}
    ${spacingWithBreakpoints.XL4Bottom}
    color: ${colors.primary.diamond};
    text-align: center;
`;

export const FooterText = styled(BodyText).attrs({
    type: "div",
    size: "md"
})`
    ${handleTopSpacing({ topSpacing: "lg" })}
`;

export const FeedbackContent = styled.div`
    text-align: center;
`;

export const FeedbackIcon = styled.div`
    margin: ${convertPxToRem({ px: 24 })} 0;
`;

export const FeedbackText = styled(BodyText).attrs({
    type: "div",
    alignment: "center"
})`
    ${textLg}
    ${({ bottomSpacing }) =>
        bottomSpacing
            ? css`
                  padding-bottom: ${convertPxToRem({ px: bottomSpacing })};
              `
            : spacingWithBreakpoints.SMBottom}
    color: ${colors.secondary.orca};
`;

export const FeedbackButtons = styled.div`
    display: flex;
    flex-wrap: wrap;

    ${Button} {
        flex-shrink: 0;
        flex-grow: 1;
        flex-basis: 30%; // start with a static width so buttons grow equally

        @media (max-width: ${breakpoints.beforeTablet.rem}) {
            flex-basis: 100%;
            &:first-child {
                margin-bottom: ${convertPxToRem({ px: 16 })};
            }
        }

        @media (min-width: ${breakpoints.tablet.rem}) {
            &:first-child {
                margin-right: ${convertPxToRem({ px: 24 })};
            }
        }
    }
`;

export const AuthCheckbox = styled(Checkbox)`
    display: block;

    ${({ hasError }) =>
        hasError === undefined &&
        css`
            ${handleBottomSpacing({ bottomSpacing: "md" })}
        `}

    span {
        ${textLg}

        &:before {
            margin-right: ${convertPxToRem({ px: 8 })};
            ${({ hasError }) =>
                hasError &&
                css`
                    border-color: ${colors.functional.lightMode.sangria};
                `}
        }
    }
`;

export const AuthCheckboxError = styled.div`
    min-height: ${convertPxToRem({ px: 24 })};
    color: ${colors.functional.lightMode.sangria};
    ${textSm}
    margin-top: ${convertPxToRem({ px: 2 })};
    padding-left: ${convertPxToRem({ px: 24 })};
`;

export const AuthButton = styled(Button).attrs({
    width: "100%"
})``;

const CaptchaWrapper = styled(FormElementContainer).attrs({
    width: "100%",
    labelText: "",
    hideLabel: true,
    hideErrorMessage: true
})``;

export const AuthCaptcha = ({ ...props }) => (
    <CaptchaWrapper>
        <Captcha {...props} />
    </CaptchaWrapper>
);

export const AlternativeLabel = styled(BodyText).attrs({
    type: "div",
    size: "lg",
    alignment: "center"
})`
    ${handleTopSpacing({ topSpacing: "sm" })}
    ${handleBottomSpacing({ bottomSpacing: "sm" })}
`;

export const GoogleAuthButtonContainer = styled.div`
    margin-bottom: ${convertPxToRem({ px: 16 })};
    min-height: ${convertPxToRem({ px: 38 })};
    height: ${convertPxToRem({ px: 38 })};
`;
