import React from "react";
import PropTypes from "prop-types";
import Button from "core/Button";
import styled from "styled-components";
import colors from "core/colors";

import { useEditPhotoUpload } from "frontend/components/creator-tools/PhotoUploadPage/PhotoUpload/Edit/EditPhotoUploadContext";

const Container = styled.div`
    display: block;
    position: absolute;
    bottom: 0.5rem;
    left: 0.5rem;
    z-index: 3;
    visibility: ${props => (props.isVisible ? "visible" : "hidden")};
    opacity: ${props => (props.isVisible ? "1" : "0")};
    transition: opacity 0.2s ease-in-out;

    button {
        background: ${colors.primary.snow};
    }
`;

const EditButton = ({ photoId, name, isVisible = false }) => {
    const { openEditModal } = useEditPhotoUpload();

    const handleClick = e => {
        e.stopPropagation();
        openEditModal(photoId);
    };

    return (
        <Container isVisible={isVisible}>
            <Button
                styleType="outline"
                size="sm"
                aria-label={`Edit file: "${name}"`}
                onClick={handleClick}
            >
                Edit
            </Button>
        </Container>
    );
};

EditButton.propTypes = {
    photoId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    isVisible: PropTypes.bool
};

export default EditButton;
