import gql from "graphql-tag";

export const GET_CREATOR_NAME = gql`
    query getCreatorName($username: String!) {
        creator: creatorProfileDetailByUsername(username: $username) {
            name
            username
        }
    }
`;

export const GET_USER_UPLOAD_COUNTS = gql`
    query getUserUploadCounts {
        currentUserUploads @client {
            ok
            errors
            photos {
                pendingCount
                deniedCount
                hasApprovedUploads
            }
            icons {
                pendingCount
                deniedCount
                hasApprovedUploads
            }
        }
    }
`;
