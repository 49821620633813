import gql from "graphql-tag";
import { PHOTO_UPLOAD_WITH_MOD_FRAGMENT } from "../fragments/photoUpload";

/* -------------------------------------------------------------------------- */
/*                               moderation band                              */
/* -------------------------------------------------------------------------- */
export const SAVE_UPLOADS_FOR_LATER = gql`
    mutation SaveUploadsForLater($photoUploadIds: [ID!]!) {
        payload: savePhotoUploadsForLater(photoUploadIds: $photoUploadIds) {
            errors
            ok
            photoUploads {
                errors
                ok
                photoUploadId
                photoUpload {
                    ...ModPhotoUpload
                }
            }
        }
    }
    ${PHOTO_UPLOAD_WITH_MOD_FRAGMENT}
`;

export const REMOVE_UPLOADS_FROM_SAVED_FOR_LATER = gql`
    mutation ResumeModeratingSavedForLaterPhotoUploads(
        $photoUploadIds: [ID!]!
    ) {
        payload: resumeModeratingSavedForLaterPhotoUploads(
            photoUploadIds: $photoUploadIds
        ) {
            errors
            ok
            photoUploads {
                errors
                ok
                photoUploadId
                photoUpload {
                    ...ModPhotoUpload
                }
            }
        }
    }
    ${PHOTO_UPLOAD_WITH_MOD_FRAGMENT}
`;

export const SET_GEM_STATUS_ON_UPLOADS = gql`
    mutation SetGemStatusOnUploads($photoUploadIds: [ID!]!, $value: Boolean!) {
        payload: setStarredStatusOnPhotoUploads(
            photoUploadIds: $photoUploadIds
            value: $value
        ) {
            ok
            photoUploads {
                ok
                errors
                photoUploadId
                photoUpload {
                    ...ModPhotoUpload
                }
            }
        }
    }
    ${PHOTO_UPLOAD_WITH_MOD_FRAGMENT}
`;

export const SET_MATURE_ON_UPLOADS = gql`
    mutation SetMatureOnPhotoUploads(
        $photoUploadIds: [ID!]!
        $value: Boolean!
    ) {
        payload: setMatureOnPhotoUploads(
            photoUploadIds: $photoUploadIds
            value: $value
        ) {
            ok
            photoUploads {
                errors
                ok
                photoUploadId
                photoUpload {
                    ...ModPhotoUpload
                }
            }
        }
    }
    ${PHOTO_UPLOAD_WITH_MOD_FRAGMENT}
`;
