import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// context
import { useQueueContext } from "frontend/components/fort/mod/core/Layouts/QueueLayout";

// components
import InactiveMessage from "./InactiveMessage";

// styles
import { convertPxToRem } from "frontend/components/helpers";

export const SidebarWrapperStyled = styled.div`
    padding: ${convertPxToRem(10)};
`;

// component
export const SidebarWrapper = ({ children }) => {
    const { batchIsSelected, uploadType } = useQueueContext();

    const inactiveText =
        uploadType === "photo"
            ? "Select an image to edit"
            : "Select an icon to edit";

    return (
        <SidebarWrapperStyled>
            {batchIsSelected ? (
                <>{children}</>
            ) : (
                <InactiveMessage message={inactiveText} />
            )}
        </SidebarWrapperStyled>
    );
};

export default SidebarWrapper;

// prop-types
SidebarWrapper.propTypes = {
    children: PropTypes.any
};
