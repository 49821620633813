import styled, { css } from "styled-components";

import { convertPxToRem } from "frontend/components/helpers";
import colors from "core/colors";
import { handleBottomSpacing } from "js/helpers/spacingAndFonts";

import BodyText from "core/BodyText";

export const Label = styled.label`
    display: inline-block;
    position: relative;

    ${({ width }) => css`
        width: ${width};
    `}
    ${({ bottomSpacing }) =>
        !!bottomSpacing &&
        css`
            ${handleBottomSpacing({ bottomSpacing })}
        `}
`;

export const LabelText = styled(BodyText).attrs(({ darkMode }) => {
    const copyColor = darkMode
        ? colors.secondary.dolphin
        : colors.secondary.orca;
    return {
        type: "div",
        size: "lg",
        copyColor
    };
})`
    ${handleBottomSpacing({ bottomSpacing: "xs" })}
`;

export const ErrorContainer = styled.div`
    min-height: ${convertPxToRem({ px: 24 })};
    width: 100%;
    word-break: break-word;
    color: ${({ darkMode }) =>
        darkMode
            ? colors.functional.darkMode.desertBloom
            : colors.functional.lightMode.sangria};
    font-size: ${convertPxToRem({ px: 13 })};
    visibility: ${({ showError }) => (showError ? "visible" : "hidden")};

    &:before {
        content: "";
        width: 100%;
        position: relative;
        top: ${convertPxToRem({ px: -3 })};
        height: ${convertPxToRem({ px: 3 })};
        background: ${({ darkMode }) =>
            darkMode
                ? colors.functional.darkMode.desertBloom
                : colors.functional.lightMode.sangria};
        display: block;
    }
`;
