import styled, { css } from "styled-components";

// helpers
import { convertPxToRem } from "frontend/components/helpers";

// components and styles:
import BodyText from "core/BodyText";
import IconButton from "core/IconButton";
import colors from "core/colors";

//constants:
const ICON_CONTAINER_WIDTH_REM = convertPxToRem({ px: 16 });
const ICON_CONTAINER_MARGIN_REM = convertPxToRem({ px: 16 });

const STYLES = {
    transparent: {
        neutral: {
            lightMode: {
                backgroundColor: "transparent",
                icon: colors.secondary.orca,
                text: colors.secondary.orca
            },
            darkMode: {
                backgroundColor: "transparent",
                icon: colors.primary.diamond,
                text: colors.primary.diamond
            }
        },
        info: {
            lightMode: {
                backgroundColor: "transparent",
                icon: colors.accent.lightMode.persian,
                text: colors.secondary.orca
            },
            darkMode: {
                backgroundColor: "transparent",
                icon: colors.accent.darkMode.suenosBuenos,
                text: colors.primary.diamond
            }
        },
        success: {
            lightMode: {
                backgroundColor: "transparent",
                icon: colors.alternative.lightMode.pine,
                text: colors.secondary.orca
            },
            darkMode: {
                backgroundColor: "transparent",
                icon: colors.alternative.lightMode.chartreuse,
                text: colors.primary.diamond
            }
        },
        warning: {
            lightMode: {
                backgroundColor: "transparent",
                icon: colors.alternative.lightMode.tang,
                text: colors.secondary.orca
            },
            darkMode: {
                backgroundColor: "transparent",
                icon: colors.alternative.lightMode.tang,
                text: colors.primary.diamond
            }
        },
        error: {
            lightMode: {
                backgroundColor: "transparent",
                icon: colors.functional.lightMode.laser,
                text: colors.secondary.orca
            },
            darkMode: {
                backgroundColor: "transparent",
                icon: colors.functional.darkMode.desertBloom,
                text: colors.primary.diamond
            }
        }
    },
    solid: {
        neutral: {
            lightMode: {
                backgroundColor: colors.secondary.orca,
                icon: colors.primary.diamond,
                text: colors.primary.diamond
            },
            darkMode: {
                backgroundColor: colors.primary.diamond,
                icon: colors.secondary.orca,
                text: colors.secondary.orca
            }
        },
        info: {
            lightMode: {
                backgroundColor: colors.primary.diamond,
                icon: colors.accent.lightMode.persian,
                text: colors.accent.lightMode.persian
            },
            darkMode: {
                backgroundColor: colors.secondary.basalt,
                icon: colors.accent.darkMode.suenosBuenos,
                text: colors.accent.darkMode.suenosBuenos
            }
        },
        success: {
            lightMode: {
                backgroundColor: colors.alternative.lightMode.chartreuse,
                icon: colors.secondary.orca,
                text: colors.secondary.orca
            },
            darkMode: {
                backgroundColor: colors.alternative.lightMode.chartreuse,
                icon: colors.secondary.orca,
                text: colors.secondary.orca
            }
        },
        warning: {
            lightMode: {
                backgroundColor: colors.alternative.lightMode.tang,
                icon: colors.secondary.orca,
                text: colors.secondary.orca
            },
            darkMode: {
                backgroundColor: colors.alternative.lightMode.tang,
                icon: colors.secondary.orca,
                text: colors.secondary.orca
            }
        },
        error: {
            lightMode: {
                backgroundColor: colors.functional.lightMode.laser,
                icon: colors.primary.diamond,
                text: colors.primary.diamond
            },
            darkMode: {
                backgroundColor: colors.functional.lightMode.laser,
                icon: colors.primary.diamond,
                text: colors.primary.diamond
            }
        }
    }
};

const backgroundColor = ({ styleType, infoType, darkMode }) => {
    return darkMode
        ? STYLES[styleType][infoType].darkMode.backgroundColor
        : STYLES[styleType][infoType].lightMode.backgroundColor;
};

const iconColor = ({ styleType, infoType, darkMode }) => {
    return darkMode
        ? STYLES[styleType][infoType].darkMode.icon
        : STYLES[styleType][infoType].lightMode.icon;
};

const textColor = ({ styleType, infoType, darkMode }) => {
    return darkMode
        ? STYLES[styleType][infoType].darkMode.text
        : STYLES[styleType][infoType].lightMode.text;
};

//styled components:
export const Banner = styled.div`
    display: inline-flex;
    align-items: flex-start;
    justify-content: space-between;
    background-color: ${backgroundColor};
    width: ${({ width }) => width};

    ${({ styleType }) =>
        styleType !== "transparent" &&
        css`
            border-radius: ${convertPxToRem({ px: 4 })};
            padding: ${convertPxToRem({ px: 12 })} ${convertPxToRem({ px: 16 })};
        `};
`;

export const LeftIconContainer = styled.div`
    margin-right: ${convertPxToRem({ px: 16 })};
    height: ${convertPxToRem({ px: 20 })};

    position: relative;
    top: 1px;

    svg {
        path,
        use,
        g,
        circle {
            fill: ${iconColor};
        }
    }

    ${({ hasClickEvent }) =>
        hasClickEvent &&
        css`
            cursor: pointer;
        `}
`;

export const BannerBodyText = styled(BodyText)`
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: ${textColor};
`;

export const IconsContainer = styled.div`
    display: inline-flex;
    height: ${convertPxToRem({ px: 16 })};
    margin-left: ${convertPxToRem({ px: 16 })};

    ${({ contentIconsLength }) =>
        contentIconsLength > 0 &&
        css`
            width: calc(
                (${ICON_CONTAINER_WIDTH_REM} * ${contentIconsLength}) +
                    (${ICON_CONTAINER_MARGIN_REM} * ${contentIconsLength - 1})
            );
        `}
`;

export const TextContainerIconButton = styled(IconButton)`
    width: ${convertPxToRem({ px: 16 })};
    height: ${convertPxToRem({ px: 16 })};
    margin-right: ${convertPxToRem({ px: 8 })};

    &:last-of-type {
        margin-right: 0;
    }

    ${({ iconColor, iconColorHover, messagesStyleType }) =>
        messagesStyleType !== "transparent" &&
        css`
            svg {
                height: ${convertPxToRem({ px: 16 })};
                width: ${convertPxToRem({ px: 16 })};
                path,
                use,
                g,
                circle {
                    fill: ${iconColor};
                }
            }

            &:hover {
                svg {
                    path,
                    use,
                    g,
                    circle {
                        fill: ${iconColorHover};
                    }
                }
            }
        `}
`;

export const TextContainerIcon = styled.div`
    width: ${convertPxToRem({ px: 16 })};
    height: ${convertPxToRem({ px: 16 })};
    margin-right: ${convertPxToRem({ px: 8 })};

    &:last-of-type {
        margin-right: 0;
    }

    ${({ iconColor, messagesStyleType }) =>
        messagesStyleType !== "transparent" &&
        css`
            svg {
                height: ${convertPxToRem({ px: 16 })};
                width: ${convertPxToRem({ px: 16 })};
                path,
                use,
                g,
                circle {
                    fill: ${iconColor};
                }
            }
        `}
`;

export const DismissBannerIconButton = styled(IconButton)`
    margin-left: ${convertPxToRem({ px: 16 })};
    height: ${convertPxToRem({ px: 16 })};

    position: relative;
    top: 5px;

    ${({ closeIconColor, closeIconHoverColor }) =>
        css`
            svg {
                height: ${convertPxToRem({ px: 16 })};
                width: ${convertPxToRem({ px: 16 })};
                path,
                use,
                g,
                circle {
                    fill: ${closeIconColor};
                }
            }

            // override focus color from IconButton
            &:focus {
                svg {
                    path,
                    use,
                    g,
                    circle {
                        fill: ${closeIconColor};
                    }
                }
            }

            &:hover {
                svg {
                    path,
                    use,
                    g,
                    circle {
                        fill: ${closeIconHoverColor};
                    }
                }
            }
        `}
`;
