import React from "react";
import PropTypes from "prop-types";

import {
    spacingSizes,
    headingSizes,
    alignments,
    headingTypes
} from "core/props";

import Heading from "core/Heading";
import Button from "core/Button";
import BlogPostsBoxes from "./BlogPostsBoxes";
import { CTAsection } from "./styles";

const BlogPosts = ({
    headingColor,
    headingType,
    headingSize,
    headingTopSpacing,
    headingBottomSpacing,
    headingAlignment,
    subtitleLinkInverse,
    blogPostHeadingType,
    blogPostHeadingSize,
    subtitleSize,
    blogPostsTitle,
    posts,
    button,
    buttonAlignment
}) => (
    <>
        {blogPostsTitle && (
            <Heading
                headingColor={headingColor}
                type={headingType ? headingType : "h2"}
                size={headingSize ? headingSize : "xl"}
                topSpacing={headingTopSpacing ? headingTopSpacing : "none"}
                bottomSpacing={
                    headingBottomSpacing ? headingBottomSpacing : "4xl"
                }
                alignment={headingAlignment ? headingAlignment : "center"}
            >
                {blogPostsTitle}
            </Heading>
        )}

        <BlogPostsBoxes
            posts={posts}
            subtitleLinkInverse={subtitleLinkInverse}
            blogPostHeadingType={blogPostHeadingType}
            blogPostHeadingSize={blogPostHeadingSize}
            subtitleSize={subtitleSize}
        />

        {button && Object.keys(button).length > 0 && button.url && (
            <CTAsection buttonAlignment={buttonAlignment}>
                <Button
                    href={button.url}
                    target={button.target ? button.target : "_self"}
                >
                    {button.text}
                </Button>
            </CTAsection>
        )}
    </>
);

BlogPosts.propTypes = {
    headingType: PropTypes.oneOf(headingTypes),
    headingSize: PropTypes.oneOf(headingSizes),
    headingTopSpacing: PropTypes.oneOf(spacingSizes),
    headingBottomSpacing: PropTypes.oneOf(spacingSizes),
    headingAlignment: PropTypes.oneOf(alignments),
    headingColor: PropTypes.string,
    subtitleLinkInverse: PropTypes.bool,
    blogPostHeadingType: PropTypes.oneOf(headingTypes),
    blogPostHeadingSize: PropTypes.oneOf(headingSizes),
    subtitleSize: PropTypes.oneOf(headingSizes),
    blogPostsTitle: PropTypes.string,
    posts: PropTypes.array.isRequired,
    button: PropTypes.object,
    buttonAlignment: PropTypes.oneOf(alignments)
};

export default BlogPosts;
