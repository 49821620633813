import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { useMutation } from "@apollo/client";
import PropTypes from "prop-types";

import Button from "core/Button";

import { SUBMIT_PHOTO_UPLOADS_FOR_REVIEW } from "./mutations";
import { PHOTO_UPLOAD_CONFIRM } from "data/links";
import { useMessageStack } from "core/MessageStack/useMessageStack";

const PhotoUploadSubmit = ({
    uploadCount,
    currentlyProcessingCount,
    initialMaximumFiles = 0
}) => {
    const [submissionSuccessful, setSubmissionSuccessful] = useState(false);
    const { addMessages } = useMessageStack();

    const [submitUploads, { loading }] = useMutation(
        SUBMIT_PHOTO_UPLOADS_FOR_REVIEW,
        {
            onCompleted: ({ submitPhotoUploadsForReview }) => {
                if (submitPhotoUploadsForReview.ok) {
                    setSubmissionSuccessful(true);
                } else {
                    const errorReasons = submitPhotoUploadsForReview.errors.map(
                        e => e.reason
                    );
                    const errorReasonsUnique = Array.from(
                        new Set(errorReasons)
                    );

                    addMessages([
                        {
                            infoType: "error",
                            text:
                                errorReasonsUnique.join(" ") ||
                                "Something went wrong. Please try again."
                        }
                    ]);
                }
            }
        }
    );

    const handleButtonClick = () => {
        submitUploads();
    };

    const buttonIsDisabled =
        uploadCount < 1 ||
        currentlyProcessingCount > 0 ||
        uploadCount > initialMaximumFiles;

    if (submissionSuccessful) {
        return <Redirect to={PHOTO_UPLOAD_CONFIRM} />;
    }

    return (
        <Button
            onClick={handleButtonClick}
            isDisabled={buttonIsDisabled}
            isProcessing={loading}
            size="sm"
            styleType="cta"
        >
            Submit
        </Button>
    );
};

PhotoUploadSubmit.propTypes = {
    uploadCount: PropTypes.number,
    currentlyProcessingCount: PropTypes.number,
    initialMaximumFiles: PropTypes.number
};

export default PhotoUploadSubmit;
