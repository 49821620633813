import React from "react";

// components
import LabelValue from "./LabelValue";

// QueueContext
import { useQueueContext } from "frontend/components/fort/mod/core/Layouts/QueueLayout";

// component
export const Profile = () => {
    // state/context
    const queueContext = useQueueContext();

    const {
        uploadTypeTitle,
        selectedBatch,
        state: { singleUploadSelected }
    } = queueContext;

    return (
        <div>
            {/* Batch ID */}
            <LabelValue title="Batch" id={selectedBatch.id} />
            {/* Upload ID */}
            {singleUploadSelected && (
                <LabelValue
                    title={uploadTypeTitle}
                    id={singleUploadSelected.id}
                />
            )}
        </div>
    );
};

export default Profile;
