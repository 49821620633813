import styled from "styled-components";

import { convertPxToRem } from "frontend/components/helpers";

// components
import { SaveButtonStyled } from "core/InlineTextEdit/styles";

// styled-components
export const ApproveButtonBelowStyled = styled.div`
    margin-bottom: ${convertPxToRem(16)};
`;
export const ChkTxtWrapperStyled = styled.div`
    display: flex;
    margin-bottom: ${convertPxToRem(8)};
`;
export const SaveWrapperStyled = styled.div`
    ${SaveButtonStyled} {
        margin-left: 0px;
    }
`;
