/*---------------------------
| Get Default Context State
---------------------------*/
export const getDefaultState = props => {
    const { components, defaultId } = props;

    let defaultIndex = 0;

    if (defaultId) {
        components.forEach((c, index) => {
            if (c.id === defaultId) {
                defaultIndex = index;
            }
        });
    }

    const enhancedComps = components.map((c, idx) => {
        return {
            ...c,
            slideNumber: idx + 1
        };
    });

    const totalSlides = enhancedComps.length;
    const lastSlideKey = totalSlides - 1;

    return {
        ...props,
        totalSlides: totalSlides,
        components: enhancedComps,
        isAnimating: false,
        previous: enhancedComps[defaultIndex - 1]
            ? enhancedComps[defaultIndex - 1].id
            : enhancedComps[lastSlideKey].id,
        current: enhancedComps[defaultIndex].id,
        next: enhancedComps[defaultIndex + 1]
            ? enhancedComps[defaultIndex + 1].id
            : enhancedComps[0].id
    };
};
