import React from "react";
import PropTypes from "prop-types";

import Heading from "core/Heading";
import Grid from "core/Grid";

import colors from "core/colors";
import { BulletGridHeading, BulletIcon, Paragraph } from "./styles";

const Content = ({ bullets, sectionHeading, sectionHeadingColor }) => (
    <>
        {sectionHeading && (
            <Heading
                headingColor={sectionHeadingColor}
                type="h2"
                size="xl"
                bottomSpacing="4xl"
                alignment="center"
            >
                {sectionHeading}
            </Heading>
        )}
        <Grid mobileSpacing={48} tabletSpacing={20} desktopSpacing={48}>
            {bullets.map(bullet => (
                <Grid item sm={12} md={4} lg={4} key={bullet.title}>
                    {bullet.icon && <BulletIcon>{bullet.icon}</BulletIcon>}
                    <BulletGridHeading
                        headingColor={colors.secondary.orca}
                        type="h6"
                        size="xs"
                    >
                        {bullet.title}
                    </BulletGridHeading>
                    <Paragraph>{bullet.text}</Paragraph>
                </Grid>
            ))}
        </Grid>
    </>
);

Content.propTypes = {
    bullets: PropTypes.arrayOf(
        PropTypes.shape({
            icon: PropTypes.element,
            title: PropTypes.string,
            text: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
        })
    ).isRequired,
    sectionHeading: PropTypes.string
};

export default Content;
