import React from "react";

// context
import { useSiteHeader } from "core/SiteHeaderV2/context/useSiteHeader.js";

// components
import LoginButton from "core/SiteHeaderV2/components/LoginButton.js";

// component
const UnauthMenu = () => {
    return <LoginButton />;
};

export default UnauthMenu;
