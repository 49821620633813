import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import colors from "core/colors";

const createHelpers = (width, height, css) => {
    // somehow sizes is ending up in markup, even if it is not a valid svg attribute
    // until we have a better solution, just render it empty, instead to '[Object object]'
    const sanitizeSizes = sizes =>
        Object.defineProperty(sizes, "toString", {
            value: () => "",
            enumerable: false
        });

    const getDimensions = (size, sizes) => {
        if (
            size &&
            typeof size.width === "number" &&
            typeof size.height === "number"
        ) {
            return size;
        }

        return size && sizes[size] ? sizes[size] : { width, height };
    };

    const getCss = (size, sizes, fillColor, fillColorRule, noStyles) => {
        if (noStyles) {
            return "";
        }

        const dimensions = getDimensions(size, sizes);
        const fillRule =
            fillColor && fillColorRule
                ? `${fillColorRule}{ fill: ${fillColor}; }`
                : "";

        return css`
            width: ${dimensions.width}px;
            height: ${dimensions.height}px;
            ${fillRule}
        `;
    };

    const propsToCss = ({ size, sizes, fillColor, fillColorRule, noStyles }) =>
        getCss(size, sizes, fillColor, fillColorRule, noStyles);

    return {
        getCss,
        getDimensions,
        propsToCss,
        sanitizeSizes
    };
};

const width = "190";
const height = "190";
const viewBox = "0 0 190 190";

const { getDimensions, getCss, propsToCss, sanitizeSizes } = createHelpers(
    width,
    height,
    css
);

const sizes = sanitizeSizes({
    sm: { width: 98, height: 98 },
    md: { width: 101, height: 101 },
    lg: { width: 190, height: 190 }
});

const Image = styled.svg`
    ${propsToCss}
`;

const children = (
    <Fragment>
        <path
            fill={colors.secondary.orca}
            fillRule="evenodd"
            d="M55.692 50.888c-1.172-1.172-3.089-1.19-4.26-.018L33.182 69.118l-8.035-8.035c-1.172-1.171-3.089-1.154-4.26.018-1.172 1.172-1.19 3.089-.018 4.26l8.051 8.052c.406.405.9.668 1.423.79.14.467.393.906.76 1.273 1.171 1.171 3.088 1.19 4.26.018L55.71 55.148c1.171-1.172 1.153-3.089-.018-4.26zm-4.26 63.373c1.171-1.171 3.088-1.153 4.26.018 1.171 1.172 1.19 3.089.017 4.26l-20.345 20.346c-1.172 1.172-3.089 1.154-4.26-.018a3.014 3.014 0 01-.76-1.272 2.952 2.952 0 01-1.423-.791l-8.051-8.051c-1.172-1.172-1.154-3.089.018-4.261 1.171-1.171 3.088-1.189 4.26-.017l8.035 8.035 18.248-18.249zm17.71-47.362c-1.658 0-3-1.368-3-3.025 0-1.657 1.342-3.025 3-3.025h97.86c1.656 0 3 1.368 3 3.025 0 1.657-1.344 3.025-3 3.025h-97.86zm-3 59.493c0 1.657 1.342 3.025 3 3.025h97.86c1.656 0 3-1.368 3-3.025 0-1.657-1.344-3.025-3-3.025h-97.86c-1.658 0-3 1.368-3 3.025z"
            clipRule="evenodd"
            key="key-0"
        />
    </Fragment>
);

const defaultProps = {
    children,
    viewBox,
    fillColor: null,
    fillColorRule: "&&& path, &&& use, &&& g",
    sizes,
    size: null
};

Image.propTypes /* remove-proptypes */ = {
    fillColor: PropTypes.string,
    fillColorRule: PropTypes.string,
    viewBox: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    size: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            height: PropTypes.number.isRequired,
            width: PropTypes.number.isRequired
        })
    ]),
    sizes: PropTypes.shape({
        height: PropTypes.number,
        width: PropTypes.number
    })
};

export default Object.assign(Image, {
    getDimensions,
    getCss,
    defaultProps,
    displayName: "NameEmail"
});
