import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { reusableComponentSizes, spacingSizes } from "core/props";
import { getValidationError } from "core/Form/helpers";

// new component - replaces legacy component from core/Form
import FormElementContainer from "core/FormElementContainer";
import { IconContainer, Input, SuccessIcon } from "./styles";

let FormInput = ({
    className,
    inputRef,
    placeholderText,
    labelText,
    hideLabel,
    onChange,
    onKeyPress,
    onBlur,
    errorMessageOverride,
    validation,
    darkMode = false,
    size = "lg",
    width = "100%",
    bottomSpacing = "none",
    isDisabled = false,
    type = "text",
    value = "",
    iconLeft,
    iconRight,
    hideErrorOnFocus = true,
    ...inputAttributes
}) => {
    const [errorMessage, setErrorMessage] = useState(errorMessageOverride);
    const [success, setSuccess] = useState(false);
    const [focused, setFocused] = useState(false);

    const validateInput = input => {
        const validationError = getValidationError({ input, validation });

        setErrorMessage(validationError);
        setSuccess(validationError ? false : true);
    };

    useEffect(() => {
        if (errorMessageOverride === undefined) return;

        setErrorMessage(errorMessageOverride);
        if (errorMessageOverride) {
            setSuccess(false);
        } else {
            setSuccess(true);
        }
    }, [errorMessageOverride, setSuccess]);

    const handleFocus = () => {
        setFocused(true);
    };

    const handleBlur = e => {
        setFocused(false);
        if (validation) validateInput(e.target.value);
        typeof onBlur === "function" && onBlur();
    };

    return (
        <FormElementContainer
            className={className}
            isFocused={hideErrorOnFocus && focused}
            labelText={labelText}
            errorMessage={errorMessage}
            width={width}
            bottomSpacing={bottomSpacing}
            darkMode={darkMode}
            hideLabel={hideLabel}
        >
            {/* show left icon, dont show right icon */}
            {!!iconLeft && !iconRight && (
                <IconContainer size={size} iconLeft={iconLeft}>
                    {iconLeft}
                </IconContainer>
            )}

            <Input
                {...inputAttributes}
                ref={inputRef}
                type={type}
                width={width}
                placeholder={placeholderText}
                onChange={e => e.target && onChange(e.target.value)}
                onBlur={handleBlur}
                onFocus={handleFocus}
                value={value}
                darkMode={darkMode}
                size={size}
                success={success}
                iconLeft={iconLeft}
                iconRight={iconRight}
                disabled={isDisabled}
                onKeyPress={onKeyPress}
            />
            {/*  this is behind a bool because in the event <input type="number"/> this was making the native stepper un-clickable when false which is default */}
            {success && (
                <SuccessIcon
                    darkMode={darkMode}
                    size={size}
                    success={success}
                />
            )}

            {/* dont show left icon, show right icon, only show right icon if success is false */}
            {!iconLeft && !!iconRight && !success && (
                <IconContainer size={size} iconLeft={iconLeft}>
                    {iconRight}
                </IconContainer>
            )}
        </FormElementContainer>
    );
};

FormInput.propTypes = {
    inputRef: PropTypes.object,
    placeholderText: PropTypes.string,
    labelText: PropTypes.string,
    hideLabel: PropTypes.bool,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    type: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onKeyPress: PropTypes.func,
    errorMessageOverride: PropTypes.string,
    validation: PropTypes.arrayOf(
        PropTypes.shape({
            isValid: PropTypes.func,
            errorMsg: PropTypes.string
        })
    ),
    readOnly: PropTypes.bool,
    // use for darkMode color styles
    darkMode: PropTypes.bool,
    size: PropTypes.oneOf(reusableComponentSizes),
    bottomSpacing: PropTypes.oneOf(spacingSizes),
    required: PropTypes.bool,
    iconLeft: PropTypes.any,
    iconRight: PropTypes.any,
    isDisabled: PropTypes.bool
};

FormInput = styled(FormInput)``;

export default FormInput;
