export const handleTopBottomSpacing = ({ size, PADDING_SIZES }) => {
    switch (size) {
        case "xs":
            return PADDING_SIZES.XS;
        case "sm":
            return PADDING_SIZES.SM;
        case "md":
            return PADDING_SIZES.MD;
        case "lg":
            return PADDING_SIZES.LG;
        default:
            return PADDING_SIZES.LG;
    }
};
