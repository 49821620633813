import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useOnClickOutside } from "js/hooks/useOnClickOutside";

// helpers
import { getHeadingText } from "./helpers";

// hooks
import { useFetchReleases } from "frontend/components/fort/mod/photos/hooks/queries/useFetchReleases.js";

// styles
import { DialogStyled, HeaderStyled, BodyStyled } from "./styles";

// components
import ReleaseList from "./ReleaseList";

/**
 * This needs to be a sub element so the keydown and click outside only listens when its open.
 * onMount listens, onDismount removes listeners.
 */
const Dialog = ({
    batchId,
    setIsDialogOpen,
    clickOutsideDomSelector, // container element that click outside bubbles up to, falls back to window object
    positionTop = 25, // number in pixels, will convert to rems
    alignRight = false
}) => {
    const [releases, setReleases] = useState([]);
    const { fetchReleases } = useFetchReleases();

    // Click Outside
    const onClickOutside = e => {
        setIsDialogOpen(false);
    };
    const { targetRef: dialogRef } = useOnClickOutside({
        onClickOutside,
        clickOutsideDomSelector
    });

    // side effects
    useEffect(() => {
        const getReleases = async () => {
            const fetchedReleases = await fetchReleases(batchId);
            setReleases(fetchedReleases);
        };
        getReleases();
    }, []);

    useEffect(() => {
        const onKeyDown = event => {
            if (event.key === "Escape") {
                setIsDialogOpen(false);
            }
        };
        window.addEventListener("keydown", onKeyDown);
        return () => window.removeEventListener("keydown", onKeyDown);
    }, []);

    return (
        <DialogStyled
            alignRight={alignRight}
            positionTop={positionTop}
            ref={dialogRef}
        >
            <HeaderStyled>{getHeadingText(releases)}</HeaderStyled>
            <BodyStyled>
                <ReleaseList releases={releases} />
            </BodyStyled>
        </DialogStyled>
    );
};

Dialog.propTypes = {
    batchId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    setIsDialogOpen: PropTypes.func.isRequired,
    clickOutsideDomSelector: PropTypes.string,
    positionTop: PropTypes.number,
    alignRight: PropTypes.bool
};

export default Dialog;
