import React from "react";
import PropTypes from "prop-types";

// styles
import {
    TemplateStyled,
    TemplateHeaderStyled,
    TemplateComponentStyled
} from "./styles.js";

const Template = ({ title, titleFull, children, width }) => {
    const titleDisplay = titleFull ? (
        <abbr title={titleFull}>{title}</abbr>
    ) : (
        title
    );

    return (
        <TemplateStyled width={width}>
            <TemplateHeaderStyled>{titleDisplay}</TemplateHeaderStyled>
            <TemplateComponentStyled>{children}</TemplateComponentStyled>
        </TemplateStyled>
    );
};

export default Template;

// prop-types
Template.propTypes = {
    title: PropTypes.string.isRequired,
    titleFull: PropTypes.string,
    children: PropTypes.any,
    width: PropTypes.number
};
