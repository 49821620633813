import React, { useEffect } from "react";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";

import { useSelect } from "./";

// component
const MountingWrapper = ({ children, consumerUploadIds }) => {
    const {
        uploadIds,
        updateUploadIds,
        selectNextPrevEnabled,
        selectNext,
        selectPrev
    } = useSelect();

    // Keep In Sync with Consumer: In case consumer changes which ids are present
    useEffect(() => {
        if (!isEqual(consumerUploadIds, uploadIds)) {
            updateUploadIds(consumerUploadIds);
        }
    }, [consumerUploadIds]);

    useEffect(() => {
        // Only add the event listener if selectNextPrevEnabled is true
        if (!selectNextPrevEnabled) return;

        // Handler for right arrow key
        const handleKeyDown = event => {
            switch (event.key) {
                case "ArrowRight":
                    selectNext();
                    break;
                case "ArrowLeft":
                    selectPrev();
                    break;
            }
        };

        // Attach the event listener
        window.addEventListener("keydown", handleKeyDown);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [selectNextPrevEnabled]); // Empty dependency array to run only once on mount

    return <>{children}</>;
};

export default MountingWrapper;

MountingWrapper.propTypes = {
    children: PropTypes.any,
    consumerUploadIds: PropTypes.array.isRequired
};
