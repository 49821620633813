import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import OutsideClickHandler from "react-outside-click-handler";

import CustomDropdownContext from "./context";

import Header from "./Header";

import colors from "core/colors";

import {
    DropdownContainer,
    DropdownBox,
    ChildrenContainer,
    Footer,
    TriggerButton
} from "./styles";

import { convertPxToRem } from "frontend/components/helpers";

import {
    reusableComponentSizes,
    buttonStyleTypes,
    positionalAlignments,
    justifyContentPosition
} from "core/props";

const UnstyledCustomDropdown = ({
    className,
    children,
    triggerText,
    isLinkTriggerStyle = false,
    buttonProps,
    showCloseButton = true,
    showBoxShadow = true,
    width = convertPxToRem({ px: 461 }),
    bgColor = colors.primary.snow,
    absoluteRight,
    absoluteLeft,
    headingColor,
    heading,
    footerChildren,
    isOpen,
    setIsOpen,
    handleOpen,
    handleClose,
    handleCloseOnEsc,
    handleOutsideClick,
    triggerButtonRef,
    dropdownBoxRef
}) => {
    const triggerButtonProps = isLinkTriggerStyle
        ? {
              onClick: handleOpen,
              ref: triggerButtonRef
          }
        : {
              onClick: handleOpen,
              ref: triggerButtonRef,
              ...buttonProps
          };

    const customDropdownContextConstants = {
        // from component:
        triggerText,
        isLinkTriggerStyle,
        buttonProps,
        showCloseButton,
        showBoxShadow,
        absoluteRight,
        absoluteLeft,
        width,
        bgColor,
        headingColor,
        heading,
        footerChildren,
        // from hook:
        handleOpen,
        handleClose,
        handleCloseOnEsc,
        handleOutsideClick,
        isOpen,
        setIsOpen,
        triggerButtonRef,
        dropdownBoxRef
    };

    return (
        <CustomDropdownContext.Provider value={customDropdownContextConstants}>
            <OutsideClickHandler
                display="inline"
                onOutsideClick={handleOutsideClick}
            >
                <TriggerButton
                    isLinkTriggerStyle={isLinkTriggerStyle}
                    {...triggerButtonProps}
                >
                    {triggerText}
                </TriggerButton>

                <DropdownContainer>
                    <DropdownBox
                        className={className}
                        width={width}
                        bgColor={bgColor}
                        showBoxShadow={showBoxShadow}
                        tabIndex={0} // receives focus when open
                        ref={dropdownBoxRef}
                        hasOutsideClick={!isOpen}
                        onKeyDown={e => handleCloseOnEsc(e)}
                        absoluteRight={absoluteRight}
                        absoluteLeft={absoluteLeft}
                        data-testid="custom-dropdown-box"
                    >
                        {(!!heading || showCloseButton) && (
                            <Header
                                heading={heading}
                                showCloseButton={showCloseButton}
                                handleClose={handleClose}
                                headingColor={headingColor}
                            />
                        )}

                        <ChildrenContainer heading={!!heading}>
                            {children}
                        </ChildrenContainer>

                        {!!footerChildren && <Footer>{footerChildren}</Footer>}
                    </DropdownBox>
                </DropdownContainer>
            </OutsideClickHandler>
        </CustomDropdownContext.Provider>
    );
};

const CustomDropdown = styled(UnstyledCustomDropdown)``;

CustomDropdown.propTypes = {
    children: PropTypes.any.isRequired,
    triggerText: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
        .isRequired,
    isLinkTriggerStyle: PropTypes.bool,
    buttonProps: PropTypes.shape({
        size: PropTypes.oneOf(reusableComponentSizes),
        styleType: PropTypes.oneOf(buttonStyleTypes),
        width: PropTypes.string,
        darkMode: PropTypes.bool,
        isDisabled: PropTypes.bool,
        isProcessing: PropTypes.bool,
        iconPosition: PropTypes.oneOf(positionalAlignments),
        processingIconPosition: PropTypes.oneOf(positionalAlignments),
        justifyContent: PropTypes.oneOf(justifyContentPosition),
        isPill: PropTypes.bool,
        isActive: PropTypes.bool,
        isToggled: PropTypes.bool,
        isSubmit: PropTypes.bool
    }),
    showCloseButton: PropTypes.bool,
    showBoxShadow: PropTypes.bool,
    width: PropTypes.string,
    absoluteRight: PropTypes.string,
    absoluteLeft: PropTypes.string,
    bgColor: PropTypes.string,
    heading: PropTypes.string,
    headingColor: PropTypes.string,
    footerChildren: PropTypes.any
};

export default CustomDropdown;
