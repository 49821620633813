import { css } from "styled-components";

import colors from "core/colors";

export const focusBoxShadow = `
    0 0 0 3px ${colors.functional.secondary.parrot}
`;

export const focusInsetBoxShadow = `
    inset 0 0 0 3px ${colors.functional.secondary.parrot}
`;

export const focusOutline = css`
    box-shadow: ${focusBoxShadow};
    outline: none;
`;

export const focusInset = css`
    box-shadow: ${focusInsetBoxShadow};
    outline: none;
`;
