/*---------------------------
| Helpers
---------------------------*/
import { UPLOAD_STATUS_FILTERS } from "./constants";

export const getThisBatch = (batchId, batches) => {
    const thisBatch = batches.find(b => b.id === batchId);
    return thisBatch;
};

export const updateBatchesWithBatch = (mutatedBatch, batches) => {
    const newBatches = batches.map(b => {
        return b.id === mutatedBatch.id ? mutatedBatch : b;
    });
    return newBatches;
};

export const getThisUpload = (uploadId, uploads) => {
    const thisUpload = uploads.find(u => u.id === uploadId);
    return thisUpload;
};

export const updateUploadsWithUpload = (mutatedUpload, uploads) => {
    const newUploads = uploads.map(u => {
        return u.id === mutatedUpload.id ? mutatedUpload : u;
    });
    return newUploads;
};

export const uploadIsSavedForLater = upload => {
    return UPLOAD_STATUS_FILTERS.SAVED_FOR_LATER.includes(upload.status);
};

export const getCurrentViewUploadIds = selectedBatch => {
    const { currentView, visModActiveIds, visModSavedIds } = selectedBatch;
    return currentView === "active" ? visModActiveIds : visModSavedIds;
};

export const isOnlyWhiteSpace = str => {
    return /^\s*$/.test(str);
};

/**
 * addModerationNote
 * Adding moderationNotes from Websocket responses
 * Only add if note does not already exist
 * @param {Object} entity
 * @param {Object} newNote
 * @returns {Array} moderationNotes: updated moderationNotes
 */
export const addModerationNote = (entity, newNote) => {
    let moderationNotes = [...entity.moderationNotes];

    const noteExists = moderationNotes.find(mn => {
        return mn.id === newNote.id;
    });

    if (!noteExists) {
        moderationNotes.push(newNote);
    }

    return moderationNotes;
};
