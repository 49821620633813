/*---------------------------
| Store
---------------------------*/

import { DEBUG } from "./index";

// Action Types
const actionPrefix = "AnimationsContext:";
const actionTypes = {
    SET_IDS: `${actionPrefix} setIds`,
    SET_CURRENT_STEP_NUMBER: `${actionPrefix} setCurrentStepNumber`
};

// Action Creators
export const actions = {
    setIds: ids => {
        return {
            type: actionTypes.SET_IDS,
            ids
        };
    },
    setCurrentStepNumber: currentStepNumber => {
        return {
            type: actionTypes.SET_CURRENT_STEP_NUMBER,
            currentStepNumber
        };
    }
};

// Reducer
export const reducer = (state, action) => {
    const { type } = action;
    if (DEBUG) {
        console.group("AnimationsContext");
        console.log({ actionType: type });
        console.log({ action });
        console.groupEnd();
    }

    switch (type) {
        case actionTypes.SET_IDS: {
            return { ...state, ids: action.ids };
        }
        case actionTypes.SET_CURRENT_STEP_NUMBER: {
            return { ...state, currentStepNumber: action.currentStepNumber };
        }
        default: {
            return { ...state };
        }
    }
};
