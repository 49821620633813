import React from "react";
import { TitleContainer } from "icons/SearchAndBrowseCollectionGrid/styles";
import Skeleton from "core/Skeleton";
import { convertPxToRem } from "frontend/components/helpers";
import { useViewport } from "js/hooks/useViewport";
import breakpoints from "core/breakpoints";

const CollectionTitleSkeleton = () => {
    const { browserWidth } = useViewport();

    const isMobile = browserWidth <= breakpoints.beforeTablet.px;
    const isTablet = browserWidth <= breakpoints.tablet.px;
    const isDesktop = browserWidth > breakpoints.tablet.px;

    const titleWidth = () => {
        switch (true) {
            case isMobile:
                return convertPxToRem({ px: 120 });
            case isTablet:
                return convertPxToRem({ px: 150 });
            case isDesktop:
                return convertPxToRem({ px: 200 });
            default:
                return convertPxToRem({ px: 120 });
        }
    };

    const subtitleWidth = () => {
        switch (true) {
            case isMobile:
                return convertPxToRem({ px: 90 });
            case isTablet:
                return convertPxToRem({ px: 100 });
            case isDesktop:
                return convertPxToRem({ px: 150 });
            default:
                return convertPxToRem({ px: 90 });
        }
    };

    return (
        <>
            <TitleContainer>
                <Skeleton
                    height={convertPxToRem({ px: 25 })}
                    width={titleWidth()}
                />
                <Skeleton
                    height={convertPxToRem({ px: 25 })}
                    width={subtitleWidth()}
                />
            </TitleContainer>
        </>
    );
};

export default CollectionTitleSkeleton;
