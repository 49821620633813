import React from "react";
import styled from "styled-components";

// context
import { useSearchBar } from "core/SiteHeaderV2/components/SearchBar/context/useSearchBar.js";

// components
import FormSelectDialog, { defaultTheme } from "core/FormSelectDialog";

// styles
import { getBorderStyle } from "./index";
import { convertPxToRem } from "frontend/components/helpers";
const DropDownStyled = styled.div`
    flex: 0 1 auto;
    background-color: pink;
`;

// component
export const DropDown = () => {
    const {
        isDarkMode,
        isMobile,
        dropDown,
        setSelectedOption
    } = useSearchBar();

    const { selectedOption, options } = dropDown;

    const onSelect = option => {
        setSelectedOption(option);
    };

    const createTheme = () => {
        let dt = defaultTheme();
        return {
            ...dt,
            control: {
                ...dt.control,
                height: convertPxToRem(40),
                border: getBorderStyle(isDarkMode),
                gap: isMobile ? convertPxToRem(5) : convertPxToRem(12)
            },
            dialog: {
                ...dt.dialog,
                width: convertPxToRem(205),
                top: convertPxToRem(0)
            },
            icon: {
                ...dt.icon,
                top: convertPxToRem(1.5),
                size: 10
            }
        };
    };

    return (
        <DropDownStyled borderStyle={getBorderStyle(isDarkMode)}>
            <FormSelectDialog
                id={`search-the-noun-project-by-type`}
                theme={createTheme}
                options={options}
                selectedOption={selectedOption}
                setSelectedOption={onSelect}
                controlType="caret"
                hideOnScroll
            />
        </DropDownStyled>
    );
};

export default DropDown;
