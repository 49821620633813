import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { Label, Input } from "./styles";
import colors from "core/colors";

const UnstyledCheckbox = ({
    className,
    label,
    isChecked,
    onClick,
    onChange,
    isDisabled = false,
    inputValue,
    ariaLabel,
    isFlexBox = false,
    checkboxStyles = {
        default: colors.primary.snow,
        checked: colors.accent.lightMode.pansy,
        disabled: colors.secondary.dolphin
    },
    ...rest
}) => {
    inputValue = inputValue || label;

    return (
        <Label isDisabled={isDisabled} className={className}>
            <Input
                isDisabled={isDisabled}
                isChecked={isChecked}
                type="checkbox"
                value={inputValue}
                checked={isChecked}
                aria-disabled={isDisabled}
                aria-label={ariaLabel}
                isFlexBox={isFlexBox}
                onClick={e => {
                    if (isDisabled) return;
                    onClick(!isChecked, e);
                }}
                onChange={e => {
                    if (isDisabled) return;
                    if (onChange) onChange(e);
                }}
                checkboxStyles={checkboxStyles}
                {...rest}
            />
            <span
                data-testid={
                    isChecked
                        ? "core-checkbox-checked"
                        : "core-checkbox-unchecked"
                }
            >
                <span>{label}</span>
            </span>
        </Label>
    );
};

const Checkbox = styled(UnstyledCheckbox)``;

UnstyledCheckbox.propTypes = {
    isChecked: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    label: PropTypes.any,
    onChange: PropTypes.func,
    isDisabled: PropTypes.bool,
    isFlexBox: PropTypes.bool,
    className: PropTypes.string,
    inputValue: PropTypes.string,
    checkboxStyles: PropTypes.object,
    ariaLabel: PropTypes.string
};

export default Checkbox;
