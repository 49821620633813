import React from "react";
import PropTypes from "prop-types";

import Link from "core/Link";

import IconsPricingTable from "./IconsPricingTable";

import {
    MarketingPageSectionHeadingContainer,
    MarketingPageSectionEyebrowSubheading,
    MarketingPageSectionHeading,
    MarketingPageSectionBiLine
} from "core/MarketingPageSectionHeading";
import {
    EduDiscount,
    IconsSectionSectionContainer,
    PageAnchor
} from "./styles";

import { NOUN_PRO_EDU_LANDING_PAGE } from "data/links";
import { INDIVIDUAL_SUBSCRIPTION, TEAM_SUBSCRIPTION } from "./data";
import colors from "core/colors";

const IconsSection = ({
    bgColor = colors.primary.snow,
    bestValueHighlight = INDIVIDUAL_SUBSCRIPTION,
    eduPricingLayout = false
}) => (
    <PageAnchor>
        <IconsSectionSectionContainer bgColor={bgColor}>
            <MarketingPageSectionHeadingContainer>
                <MarketingPageSectionEyebrowSubheading>
                    Noun Project
                </MarketingPageSectionEyebrowSubheading>
                <MarketingPageSectionHeading
                    type="h2"
                    size="xl"
                    alignment="center"
                >
                    Icon Plans and Pricing
                </MarketingPageSectionHeading>
                <MarketingPageSectionBiLine>
                    All icons in our collection are available for download
                    regardless of plan. Please note that icon subscription plans
                    apply only to icon content (photos may be purchased
                    separately).
                    {!eduPricingLayout && (
                        <EduDiscount>
                            50% discount for educators and students.{"  "}
                            <Link
                                href={NOUN_PRO_EDU_LANDING_PAGE}
                                size="lg"
                                styleType="primary"
                            >
                                Learn More
                            </Link>
                        </EduDiscount>
                    )}
                </MarketingPageSectionBiLine>
            </MarketingPageSectionHeadingContainer>
            <IconsPricingTable
                bestValueHighlight={bestValueHighlight}
                eduPricingLayout={eduPricingLayout}
            />
        </IconsSectionSectionContainer>
    </PageAnchor>
);

IconsSection.propTypes = {
    bgColor: PropTypes.string,
    bestValueHighlight: PropTypes.oneOf([
        TEAM_SUBSCRIPTION,
        INDIVIDUAL_SUBSCRIPTION
    ]),
    eduPricingLayout: PropTypes.bool
};
export default IconsSection;
