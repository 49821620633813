import React from "react";
import PageMeta from "core/PageMeta";
import SectionContainer from "core/SectionContainer";
import CollectionSpotlight from "core/CollectionSpotlight";
import BlogPosts from "core/BlogPosts";
import BulletSection from "core/BulletSection";
import TextWithButton from "core/TextWithButton";
import Faq from "core/Faq";
import OffsetGrid from "core/OffsetGrid";

import colors from "core/colors";
import twitterPreviewImg from "./images/contribute-to-noun-project.jpg";
import {
    metaTitle,
    twitterTitle,
    twitterCardContent,
    metaDescription,
    twitterDescription,
    previewImgAlt,
    heroImageMain,
    heroTitle,
    mainSectionParagraphs,
    applyNowLinkInfo,
    blogPostsTitle,
    blogPostData,
    sectionThreeTitle,
    sectionThreeSubText,
    BulletData,
    sectionFourTitle,
    sectionFourData,
    sectionFiveTitle,
    sectionFiveValues
} from "./data";

const BecomeAPhotographer = () => {
    return (
        <>
            <PageMeta
                title={metaTitle}
                twitterTitle={twitterTitle}
                twitterCardContent={twitterCardContent}
                description={metaDescription}
                twitterDescription={twitterDescription}
                previewImg={twitterPreviewImg}
                previewImgAlt={previewImgAlt}
                useSocialMediaTags
            />
            <SectionContainer>
                <OffsetGrid sm={10} md={12} lg={10}>
                    <CollectionSpotlight
                        imageAlt={heroTitle}
                        image={heroImageMain}
                        collectionText={mainSectionParagraphs}
                        collectionLink={applyNowLinkInfo}
                        collectionHeading={heroTitle}
                        headingType="h1"
                        headingSize="2xl"
                        headingColor={colors.secondary.orca}
                        sideBySideContentOnMedium
                        isBottomImageOnMobile
                        isRightImg
                    />
                </OffsetGrid>
            </SectionContainer>
            <SectionContainer bgColor={colors.primary.diamond}>
                <OffsetGrid sm={10} md={12} lg={12}>
                    <BlogPosts
                        posts={blogPostData}
                        blogPostsTitle={blogPostsTitle}
                        headingColor={colors.secondary.orca}
                        headingAlignment="left"
                        subtitleLinkInverse
                    />
                </OffsetGrid>
            </SectionContainer>
            <SectionContainer>
                <OffsetGrid sm={10} md={12} lg={12}>
                    <TextWithButton
                        headingType="h2"
                        headingAlignment="left"
                        headingBottomSpacing="sm"
                        subtextAlignment="left"
                        isHeadingGrid1={false}
                        isHeadingGrid2={false}
                        text={sectionThreeTitle}
                        subtext={sectionThreeSubText}
                        bottomSpacing="4xl"
                    />
                    <BulletSection bullets={BulletData} />
                </OffsetGrid>
            </SectionContainer>
            <SectionContainer bgColor={colors.primary.diamond}>
                <OffsetGrid sm={10} md={12} lg={12}>
                    <BlogPosts
                        posts={sectionFourData}
                        blogPostsTitle={sectionFourTitle}
                        headingAlignment="left"
                    />
                </OffsetGrid>
            </SectionContainer>

            <SectionContainer>
                <OffsetGrid sm={10} md={12} lg={12}>
                    <Faq
                        title={sectionFiveTitle}
                        values={sectionFiveValues}
                        headingAlignment="left"
                        headingColor={colors.secondary.orca}
                    />
                </OffsetGrid>
            </SectionContainer>
        </>
    );
};

export default BecomeAPhotographer;
