import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// hooks
import { useSiteHeaderScroll } from "./useSiteHeaderScroll";

// components
import SiteHeaderV2 from "../SiteHeaderV2";
import SiteHeaderContent from "./SiteHeaderContent";

const SiteHeader = props => {
    const [showTnpNewHeader, setShowTnpNewHeader] = useState(false);
    const { isHeroSearchV2, loading, currentPage } = props;

    const {
        isScrolledDown,
        animateElements,
        animateSearch
    } = useSiteHeaderScroll();

    useEffect(() => {
        setShowTnpNewHeader(
            window?.localStorage?.getItem("new-header") === "true"
        );
    }, []);

    return showTnpNewHeader ? (
        <SiteHeaderV2
            isDarkMode={isHeroSearchV2}
            pageIsLoading={!!loading}
            currentPage={currentPage}
        />
    ) : (
        <SiteHeaderContent
            {...props}
            isScrolledDown={isScrolledDown}
            animateElements={animateElements}
            animateSearch={animateSearch}
        />
    );
};

export default SiteHeader;

// prop-types
SiteHeader.propTypes = {
    isHeroSearchV2: PropTypes.bool.isRequired,
    loading: PropTypes.bool,
    currentPage: PropTypes.string
};
