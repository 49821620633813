import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useSelector } from "react-redux";

import { GoogleAuthButtonContainer } from "../styles";
import { getConfig } from "frontend/store/config";

import useContainerWidth from "frontend/hooks/useContainerWidth";

let GoogleAuthButton = ({ className, buttonAttrs }) => {
    const { containerWidth, containerRef } = useContainerWidth();
    const { googleAuthClientId, googleAuthRedirectRelativeUrl } = useSelector(
        getConfig
    );

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://accounts.google.com/gsi/client";
        script.async = true;
        script.defer = true;

        document.body.prepend(script);

        return () => {
            script.remove();
        };
    }, []);

    const getNextParamValue = () => {
        let redirectPath = "";

        const currentSearchParams = new URLSearchParams(window.location.search);

        if (currentSearchParams.has("next")) {
            redirectPath = currentSearchParams.get("next");
        } else {
            redirectPath = encodeURIComponent(
                `${window.location.pathname}${window.location.search}`
            );
        }

        return redirectPath;
    };

    return (
        <GoogleAuthButtonContainer className={className} ref={containerRef}>
            <div
                id="g_id_onload"
                data-client_id={googleAuthClientId}
                data-login_uri={`https://${
                    location.host
                }${googleAuthRedirectRelativeUrl}?next=${getNextParamValue()}`}
                data-auto_prompt="false"
            ></div>
            <div
                className="g_id_signin"
                data-type="standard"
                data-size="large"
                data-theme="outline"
                data-shape="rectangular"
                data-logo_alignment="center"
                data-width={containerWidth}
                {...buttonAttrs}
            ></div>
        </GoogleAuthButtonContainer>
    );
};

GoogleAuthButton.propTypes = {
    buttonAttrs: PropTypes.object
};

GoogleAuthButton = styled(GoogleAuthButton)``;

export default GoogleAuthButton;
