import gql from "graphql-tag";

export const GET_ICON_SEARCH_DATA = gql`
    query iconSearch(
        $query: String!
        $limit: Int
        $offset: Int
        $username: String
    ) {
        iconSearch(
            query: $query
            limit: $limit
            offset: $offset
            username: $username
        ) {
            items {
                id
                url
                title
                thumbnails {
                    thumbnail200
                }
            }
            totalCount
        }
    }
`;
