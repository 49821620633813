import { useSelector } from "react-redux";

// hooks
import { useBatches } from "frontend/components/fort/mod/photos/components/PhotoModQueuePageContent/QueueProviders/BatchesProvider/index.js";
import { useFetchBatchUploads } from "./queries/uploads/useFetchBatchUploads.js";

export const useRefetchBatchesAndUploads = () => {
    const { fetchQueueBatches } = useBatches();
    const { fetchBatchUploads } = useFetchBatchUploads();
    const { selectedBatch } = useSelector(state => state);
    const selectedBatchId = selectedBatch?.id;

    const refetchBatches = async () => {
        fetchQueueBatches();
    };

    const refetchUploads = async ({ showLoading = false } = {}) => {
        // Only refetch uploads if moderator has selected a batch
        if (selectedBatchId) {
            fetchBatchUploads({ showLoading });
        }
    };

    const refetchBatchesAndUploads = async ({ showLoading = false } = {}) => {
        refetchBatches();
        refetchUploads({ showLoading });
    };

    return {
        refetchBatches,
        refetchUploads,
        refetchBatchesAndUploads
    };
};
