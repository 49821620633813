import React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";

// context
import { useSiteHeader } from "core/SiteHeaderV2/context/useSiteHeader.js";

// styles
import { convertPxToRem } from "frontend/components/helpers";
const VerticalStyled = styled.div`
    position: relative;
    z-index: 2;

    ${({ $animation: { top } }) => {
        return css`
            transition: top ${top.speed}ms ease ${top.delay}ms;
            top: ${convertPxToRem(top.value)};
        `;
    }}
`;

// component
const Vertical = ({ children }) => {
    const { animation } = useSiteHeader();
    return <VerticalStyled $animation={animation}>{children}</VerticalStyled>;
};

export default Vertical;

// prop-types
Vertical.propTypes = {
    children: PropTypes.any
};
