import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import Heading from "core/Heading";
import SectionContainer from "core/SectionContainer";
import ButtonGroup from "core/ButtonGroup";

import { P } from "core/Typography";
import Button from "core/Button";
import breakpoints from "core/breakpoints";
import colors from "core/colors";
import { handleBottomSpacing } from "js/helpers/spacingAndFonts";
import {
    FeatureTableMobileColumnHeading,
    FeatureTableMobileList
} from "core/FeatureTableMobile";
import { Table } from "core/Table";
import { medium as mediumFontStyle } from "core/fontStyles";

import CC from "core/ReusableIcons/CC";

import { convertPxToRem } from "frontend/components/helpers";
import Flexbox from "core/Flexbox";

const browserIsIE =
    typeof window !== "undefined" && window.document.documentMode;

export const CreativeCommonsIcon = styled(CC).attrs({
    size: { width: 20, height: 22 }
})``;

export const Image = styled.div`
    ${handleBottomSpacing({ bottomSpacing: "lg" })}
    text-align: center;
`;

export const PageAnchor = styled.div`
    padding-top: 2rem;
    margin-top: -2rem;

    /* Tablet: */
    @media (min-width: ${breakpoints.tablet.rem}) {
        padding-top: 3.125rem;
        margin-top: -3.125rem;
    }

    /* Desktop: */
    @media (min-width: ${breakpoints.desktop.rem}) {
        padding-top: 4rem;
        margin-top: -4rem;
    }
`;

export const StyledPricingMarketingPage = styled.div`
    color: ${colors.secondary.orca};
`;

export const Footnote = styled(P).attrs({ size: 14 })`
    margin: 0 auto;
    text-align: center;
`;

/* -------------------------------------------------------------------------- */
/*                                    HERO                                    */
/* -------------------------------------------------------------------------- */

export const DesktopLineBreak = styled.br`
    @media (max-width: ${breakpoints.beforeDesktop.rem}) {
        display: none;
    }
`;

/* -------------------------------------------------------------------------- */
/*                           ICON PLANS AND PRICING                           */
/* -------------------------------------------------------------------------- */

export const IconsSectionSectionContainer = styled(SectionContainer)`
    ${Footnote} {
        margin-top: 1.5rem; /* 24px custom */

        @media (min-width: ${breakpoints.tablet.rem}) {
            margin-top: 2rem; /* 32px custom */
        }
    }
    background-color: ${({ bgColor }) => bgColor};
`;

export const EduDiscount = styled.span`
    display: block;
    margin: 0.5rem auto 2rem;
    color: ${colors.secondary.orca};
    ${mediumFontStyle}

    @media (max-width: ${breakpoints.beforeTablet.rem}) {
        margin-bottom: 1.5rem;
    }
`;

/* ---------------------------------- TABLE --------------------------------- */

export const IconsPricingTableContainer = styled.div`
    text-align: center;
`;

export const IconsPricingTableButtonGroup = styled(ButtonGroup)`
    margin-bottom: 2rem;

    @media (max-width: ${breakpoints.beforeTablet.rem}) {
        margin-bottom: 1.5rem;
    }
`;

export const IconsPricingFeatureTableMobileColumnHeading = styled(
    FeatureTableMobileColumnHeading
)`
    height: 17.1875rem;
`;

export const IconsPricingTableMobileHighlight = styled(Table)`
    border: 6px solid ${colors.accent.lightMode.persian};

    ${FeatureTableMobileList} {
        height: 17.8125rem;
    }

    ${IconsPricingFeatureTableMobileColumnHeading} {
        height: 16.875rem;
    }
`;

const bestValueBottomMargin = css`
    margin-bottom: 1.0625rem;
`;

export const IconsPricingTableHeaderBestValuePlaceholder = styled.div`
    /* make sure this height is equal
    to the height of the 
    IconsPricingTableHeaderBestValue element  */
    min-height: 1.4431rem; /* 23.09px */
    ${bestValueBottomMargin}
`;

const IconsPricingTableHeaderBestValue = styled.p`
    display: inline-block;
    font-size: 0.8125rem;
    color: ${colors.accent.lightMode.persian};
    padding: 0.16rem 0.6rem;
    border: 1px solid ${colors.accent.lightMode.persian};
    ${bestValueBottomMargin}
`;

IconsPricingTableHeaderBestValue.propTypes = {
    hide: PropTypes.bool
};

export { IconsPricingTableHeaderBestValue };

export const IconsPricingTableHeaderTitle = styled(Heading)`
    margin-bottom: 0.875rem;

    ${CreativeCommonsIcon} {
        position: relative;
        top: 0.25rem; /* 4px */
    }
`;

export const IconsPricingTableHeaderPrice = styled(Heading).attrs({
    type: "p",
    size: "lg",
    alignment: "center"
})`
    line-height: ${convertPxToRem({ px: 44 })};
`;

export const IconsPricingTableHeaderPriceModifier = styled(P).attrs({
    bold: true
})`
    font-size: 0.8125rem;
    line-height: 1rem;
`;

const bestValueCtaColors = css`
    color: ${colors.primary.diamond};
    border: 2px solid ${colors.accent.lightMode.persian};

    &,
    &:focus {
        background-color: ${colors.accent.lightMode.persian};
    }

    &:hover {
        background-color: ${colors.accent.lightMode.pansy};
        border: 2px solid ${colors.accent.lightMode.pansy};
    }
`;

export const EduPriceContainer = styled(Flexbox).attrs({
    flexDirection: "row",
    justifyContent: "center"
})``;

export const EduStrikeThroughPrice = styled(Heading).attrs({
    size: "sm",
    type: "p"
})`
    text-decoration: line-through;
    margin-right: ${convertPxToRem({ px: 8 })};
    line-height: ${convertPxToRem({ px: 44 })};
`;

export const EduPrice = styled(Heading).attrs({
    size: "sm",
    type: "p",
    headingColor: colors.accent.lightMode.persian
})`
    line-height: ${convertPxToRem({ px: 44 })};
`;

export const IconsPricingTableHeaderCta = styled(Button).attrs({
    size: "md",
    width: convertPxToRem({ px: 170 })
})`
    &&& {
        max-width: 80%;
        margin-top: ${convertPxToRem({ px: 16 })};
        ${({ bestValueHighlight }) =>
            bestValueHighlight &&
            css`
                ${bestValueCtaColors};
                /* these styles are necessary so that
                  absolutely positioned on top */
                position: relative;
                z-index: 10;
            `}
    }
`;

export const IconsPricingTableHeader = styled.div`
    text-align: center;
    padding: 2rem 0;

    ${CreativeCommonsIcon} {
        margin: 0 0.125rem;
        ${browserIsIE && "height: 0.9375rem;"}
    }
`;

export const IconsPricingTableDesktopContainer = styled.div`
    position: relative;
    margin: 0 auto;
    max-width: 75rem;
`;
