import React, { createContext, useReducer, useMemo, useCallback } from "react";
import PropTypes from "prop-types";

// store
import { reducer } from "./context/store";

// context
export const SearchBarContext = createContext();
SearchBarContext.displayName = "SearchBar"; // Display Name
import { getDefaultProps } from "./context/getDefaultProps";

// components
import MountingWrapper from "./MountingWrapper";

// styles
import colors from "core/colors";

export const getBorderStyle = isDarkMode => {
    return `solid 3px ${
        isDarkMode ? colors.secondary.dolphin : colors.primary.diamond
    }`;
};

// component
export const SearchBar = ({ device = "mobile", isDarkMode = false }) => {
    // Only want this to run if device changes its value
    const getDefaultPropsMemoized = useCallback(() => getDefaultProps(device), [
        device
    ]);

    const { dropDown, inputValue } = useMemo(() => getDefaultPropsMemoized(), [
        getDefaultPropsMemoized
    ]);

    const nonStateProps = {
        device,
        isDarkMode
    };
    const stateProps = {
        dropDown,
        inputValue
    };

    // Whatever you want to manage as internal state
    const [state, dispatch] = useReducer(reducer, stateProps);

    // useMemo so it does not pass value on every render
    const value = useMemo(() => ({ state, dispatch, nonStateProps }), [
        state,
        dispatch,
        nonStateProps
    ]);

    return (
        <SearchBarContext.Provider value={value}>
            <MountingWrapper />
        </SearchBarContext.Provider>
    );
};

export default SearchBar;

// prop-types
SearchBar.propTypes = {
    device: PropTypes.string,
    isDarkMode: PropTypes.bool
};
