import React from "react";

import CallToPhotographersMain from "./images/call-to-photographers.jpg";
import BlogImageOne from "./images/blogImage1.jpg";
import BlogImageTwo from "./images/blogImage2.jpg";
import BlogImageThree from "./images/blogImage3.jpg";
import CallForPhotosOne from "./images/CallForPhotosOne.jpg";
import CallForPhotosTwo from "./images/CallForPhotosTwo.jpg";
import CallForPhotosThree from "./images/CallForPhotosThree.jpg";
import Grow from "core/ReusableIcons/Grow";
import Payouts from "core/ReusableIcons/Payouts";
import Organization from "core/ReusableIcons/Organization";

// Metadata
export const metaTitle = "Call To Photographers | Noun Project";
export const twitterTitle = "Call To Photographers";
export const twitterCardContent = "summary_large_image";
export const metaDescription =
    "Contribute to an art-quality stock photo collection that celebrates diversity and supports photographers. Earn above-market royalty rates for direct sales.";
export const twitterDescription =
    "Contribute to Noun Project’s curated collection of photos that celebrate diversity and represent a more inclusive world. Earn above-market royalty rates.";
export const previewImgAlt =
    "Photos by Alia Youssef (top left) and by Carina König from Noun Project";

// Section 1: Call to Photographers
export const heroImageMain = CallToPhotographersMain;

export const heroTitle = "Submit Your Photos";

const firstParagraph =
    "We believe photos have the power to shape, reinforce, and change perceptions.";
const secondParagraph =
    "​​Join our global community of creators and make a positive impact while earning royalties.";

export const mainSectionParagraphs = [firstParagraph, secondParagraph];

export const applyNowLinkInfo = [
    {
        text: "Submit Now",
        href: "/creator-tools/contribute/upload-photos/"
    }
];

// Section 2: Blog Previews
export const blogPostsTitle =
    "We are building the most diverse and inclusive collection of professionally curated, art-quality photos.";

export const blogPostData = [
    {
        title: "“Photography absolutely has the power to change the world.”",
        category: "Avel Shah",
        imgSrc: BlogImageOne,
        url: "https://blog.thenounproject.com/photographer-feature-avel-shah/",
        target: "_blank",
        imageIsLink: true,
        headingIsLink: true,
        subtitleIsLink: true
    },
    {
        title:
            "“It can be a great first step in building empathy and understanding.”",
        category: "Alia Youssef",
        imgSrc: BlogImageTwo,
        url:
            "https://blog.thenounproject.com/photographer-feature-alia-youssef/",
        target: "_blank",
        imageIsLink: true,
        headingIsLink: true,
        subtitleIsLink: true
    },
    {
        title:
            "“Photos can help people better understand those who are different and break down cultural barriers.”",
        category: "Denis Kuvaiev",
        imgSrc: BlogImageThree,
        url:
            "https://blog.thenounproject.com/photographer-feature-denis-kuvaiev/",
        target: "_blank",
        imageIsLink: true,
        headingIsLink: true,
        subtitleIsLink: true
    }
];

// Section 3: Why contribute to NP
export const sectionThreeTitle = "Why contribute to Noun Project?";
export const sectionThreeSubText =
    "Share your photos with our community of 10+ million people around the world, make a positive impact, and earn royalties.";

export const BulletData = [
    {
        icon: <Organization size="xl" />,
        title: "Join the Mission",
        text:
            "Our global community of contributors is building the world’s most diverse and comprehensive collection of images. Join us and help set a new precedent for quality, authenticity, diversity, and inclusion in visual media."
    },
    {
        icon: <Payouts size="xl" />,
        title: "Earn Royalties",
        text: (
            <span>
                Photo contributors earn royalties from each direct photo sale on
                our platform. Noun Project pays approximately{" "}
                <span style={{ whiteSpace: "nowrap" }}>$2 million</span> in
                royalties each year to thousands of Creators around the world.
            </span>
        )
    },
    {
        icon: <Grow size="xl" />,
        title: "Grow Your Audience",
        text:
            "Noun Project is used and loved by more than 10 million members, including educators, designers, marketers, and entrepreneurs."
    }
];

// Section 4: Call for Photos
export const sectionFourTitle = "Call for Photos";
export const sectionFourData = [
    {
        title: "Dynamic Aging",
        imgSrc: CallForPhotosOne,
        url: "https://blog.thenounproject.com/call-for-photos-dynamic-aging-2/",
        target: "_blank",
        imageIsLink: true,
        headingIsLink: true,
        subtitleIsLink: true
    },
    {
        title: "Outdoors for All",
        imgSrc: CallForPhotosTwo,
        url:
            "https://blog.thenounproject.com/call-for-photos-outdoors-for-all/",
        target: "_blank",
        imageIsLink: true,
        headingIsLink: true,
        subtitleIsLink: true
    },
    {
        title: "People Everyday",
        imgSrc: CallForPhotosThree,
        url: "https://blog.thenounproject.com/call-for-photos-people-everyday/",
        target: "_blank",
        imageIsLink: true,
        headingIsLink: true,
        subtitleIsLink: true
    }
];

// Section 5: Common Questions
export const sectionFiveTitle = "Common Questions";
const faqGettingStartedText = (
    <>
        Submit your photos using our{" "}
        <a href="/creator-tools/contribute/upload-photos/">photo upload tool</a>
        . For more information about what we look for and how to create great
        stock photos, please refer to our{" "}
        <a
            target="_blank"
            href="https://brand.thenounproject.com/k/Noun-Project-Photographer-Guide-P9J8Wn"
        >
            photographer guide
        </a>
        .
    </>
);
const faqContentText = (
    <>
        We regularly put out{" "}
        <a
            target="_blank"
            href="https://blog.thenounproject.com/?s=call+for+photos"
        >
            calls for photos
        </a>{" "}
        with requests for specific images. You can also view our monthly{" "}
        <a
            target="_blank"
            href="https://blog.thenounproject.com/?s=planning+guide"
        >
            content planning guides
        </a>{" "}
        for inspiration and seasonal themes.
    </>
);
const faqLicenseText = (
    <>
        We use CC Attribution NonCommercial No-Derivates and Royalty-free
        licenses. Please see our TOU for more detailed{" "}
        <a target="_blank" href="/legal/terms-of-use/#photo-licenses">
            photo licensing information
        </a>
        .
    </>
);
const faqPaymentText = (
    <>
        Noun Project{" "}
        <a target="_blank" href="/legal/creator-terms/photos/">
            pays you royalties
        </a>{" "}
        from each paid photo download. Payments are made by the last calendar
        day of the month following the month in which the royalties were earned
        to the PayPal address you provide.
    </>
);
export const sectionFiveValues = [
    {
        title: "How do I get started?",
        text: faqGettingStartedText
    },
    {
        title: "What types of photos are you looking for?",
        text: faqContentText
    },
    {
        title: "What licenses do you use?",
        text: faqLicenseText
    },
    {
        title: "How do photographers get paid?",
        text: faqPaymentText
    },
    {
        title: "Do free downloads affect photo sales?",
        text:
            "The free/paid option has been highly successful for our creators for over 10 years, resulting in millions of sales. Downloads of hi-res photos, editing rights, and more are only available for paid photos."
    },
    {
        title: "Do you require model and property releases?",
        text:
            "We require releases if a photograph includes a recognizable individual or property. If a photo has no release but is newsworthy, it may be accepted as Editorial Use Only."
    }
];
