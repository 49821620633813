import React, { useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// context
import { useManageTags } from "core/ManageTags/context/useManageTags.js";

// styles
import { convertPxToRem } from "frontend/components/helpers";
import colors from "core/colors";

// utils
import { getSuggestionId } from "./utils";

const SuggestionStyled = styled.button`
    display: block;
    cursor: pointer;

    width: 100%;

    border: none;
    border-radius: 0px;
    background-color: ${colors.primary.snow};

    padding: ${convertPxToRem(12)};

    text-align: left;

    &:hover,
    &:focus {
        color: ${colors.primary.snow};
        background-color: ${colors.secondary.onyx};
    }
`;

// component
export const Suggestion = ({ suggestion }) => {
    const { id, dispatch, actions } = useManageTags();
    const suggButtRef = useRef();

    const onClick = () => {
        dispatch(actions.setInputText(suggestion.text));
        dispatch(actions.setShowSuggestionDropDown(false));
        dispatch(actions.setEnabled({ suggestions: false }));
    };

    return (
        <SuggestionStyled
            id={getSuggestionId(id, suggestion.id)}
            data-testid={getSuggestionId(id, suggestion.id)}
            onClick={onClick}
            ref={suggButtRef}
            type="button"
            role="option"
            // tabIndex="0"
        >
            {suggestion.text}
        </SuggestionStyled>
    );
};

export default Suggestion;

// prop-types
Suggestion.propTypes = {
    suggestion: PropTypes.object.isRequired
};
