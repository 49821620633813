/*
 * Redux action creators live here. Read Redux docs for more information:
 * http://redux.js.org/docs/basics/Actions.html
 */
import $ from "jquery";
import debounce from "lodash/debounce";
import defer from "lodash/defer";
import find from "lodash/find";
import isRegExp from "lodash/isRegExp";
import isString from "lodash/isString";
import _isNumber from "lodash/isNumber";
import callApiPromise from "../helpers/call-api-promise";
import downloadjs from "../helpers/download";
import * as Sentry from "@sentry/browser";
import { CALL_API, DECORATE_ACTIONS } from "../middleware/api";
import { user as userNormalizer } from "../middleware/normalizers";
import { licenses, DOWNLOAD_FLOW, constToCSS } from "../reducers/download";
import { SIGNUP as SIGNUP_URL } from "../data/links";
import {
    ALACARTE_TYPE,
    CONSUMING_PREPAID,
    PREPAID_TYPE
} from "../reducers/stripe";
import * as actionTypes from "./actionTypes";
import * as editorActions from "./editor";
import { trackIconDownload } from "core/trackEvent";

import { Schemas } from "../middleware/api";

export const setGlobalConfig = config => ({
    type: actionTypes.SET_GLOBAL_CONFIG,
    config
});

// Fetch actions use API middleware to abstract loading & normalizing data, and handling errors.

// show / dismiss modals

// Message modal sends when dismissed by clicking background
export const MODAL_BG_CLOSE = "MODAL_BG_CLOSE";

export const showModal = (e, component, props) => (dispatch, getState) => {
    if (e) e.preventDefault();

    let { modal } = getState();
    let currentModal = modal.component;

    if (currentModal) {
        dispatch(dismissModal());
    }

    return new Promise((resolve, reject) => {
        props = Object.assign({}, props, {
            onDismiss: resolve
        });

        dispatch(
            uiBodyClassAdd("modal-activated", `modal-${constToCSS(component)}`)
        );
        dispatch({
            type: actionTypes.SHOW_MODAL,
            component: component,
            props: props
        });
    });
};

// Photos
export function saveSearchScrollPosition(position) {
    return {
        payload: position,
        type: actionTypes.SAVE_SEARCH_SCROLL_POSITION
    };
}

export const dismissModal = (component, msg) => (dispatch, getState) => {
    let { modal } = getState();

    if (component) {
        let toRemove = [`modal-${constToCSS(component)}`];
        if (modal.all && Object.keys(modal.all).length == 1) {
            toRemove.push("modal-activated");
        }

        let dismissed = modal.all[component];

        if (dismissed) {
            let childProps = dismissed.props;
            if (childProps.onDismiss) {
                childProps.onDismiss(msg);
            }
        }

        dispatch(uiBodyClassRemove(...toRemove));
    } else {
        dispatch(uiBodyClassRemove(/modal-/));
    }
    dispatch({
        type: actionTypes.DISMISS_MODAL,
        component
    });
};

// search

export function saveQueryInfo(queryInfo) {
    return {
        queryInfo: queryInfo,
        type: actionTypes.SAVE_QUERY_INFO
    };
}

export function setSearchTerm(searchQuery) {
    return {
        query: searchQuery,
        type: actionTypes.SET_SEARCH_QUERY
    };
}

// Register that there is a component ready to accept results without reloading the
// page (so hard redirect is used on pages that do not include a router, eg 404)
export const searchRegisterResults = present => ({
    type: actionTypes.SEARCH_REGISTER_RESULTS,
    present
});

function doFetchAutoCompleteTerms(dispatch, searchQuery) {
    return dispatch({
        searchQuery: searchQuery,
        [CALL_API]: {
            types: [
                actionTypes.FETCH_AUTOCOMPLETE_TERMS,
                actionTypes.FETCH_AUTOCOMPLETE_TERMS_SUCCESS,
                actionTypes.FETCH_AUTOCOMPLETE_TERMS_FAILURE
            ],
            endpoint: `json_icon_list/autocomplete/?term=${searchQuery}`,
            schema: Schemas.SEARCH,
            method: "GET",
            service: "www"
        }
    });
}

doFetchAutoCompleteTerms = debounce(doFetchAutoCompleteTerms, 250);

export const fetchAutoCompleteTerms = searchQuery => (dispatch, getState) => {
    return doFetchAutoCompleteTerms(dispatch, searchQuery);
};

// creators

export function fetchFeaturedCreators(id, page = 1) {
    let limit = 6;

    if (page === 1) {
        limit = 9;
    }
    return {
        [CALL_API]: {
            types: [
                actionTypes.FETCH_FEATURED_CREATORS,
                actionTypes.FETCH_FEATURED_CREATORS_SUCCESS,
                actionTypes.FETCH_FEATURED_CREATORS_FAILURE
            ],
            endpoint: `featured/users/?limit=${limit}&page=${page}`,
            schema: Schemas.CREATOR,
            method: "GET",
            id: id,
            page: page,
            service: "www"
        }
    };
}

export function fetchFeaturedCollectionMetaData() {
    return {
        [CALL_API]: {
            types: [
                actionTypes.FETCH_FEATURED_COLLECTIONS_METADATA,
                actionTypes.FETCH_FEATURED_COLLECTIONS_METADATA_SUCCESS,
                actionTypes.FETCH_FEATURED_COLLECTIONS_METADATA_FAILURE
            ],
            endpoint: "featured/collections/?limit=5",
            schema: Schemas.COLLECTIONS,
            method: "GET",
            service: "www"
        }
    };
}

// icons
export const searchResetIcons = key => dispatch => {
    dispatch({
        type: actionTypes.SEARCH_RESET_ICONS,
        id: key
    });
    dispatch(uiScrollToElement(0));
};

export function fetchHeroIcon(iconId) {
    return {
        icon_id: iconId,
        [CALL_API]: {
            types: [
                actionTypes.FETCH_HERO_ICON,
                actionTypes.FETCH_HERO_ICON_SUCCESS,
                actionTypes.FETCH_HERO_ICON_FAILURE
            ],
            endpoint: `json_icon_plugin/${iconId}/`,
            schema: Schemas.ICONS,
            method: "GET",
            service: "www"
        }
    };
}

export function fetchCollection(collectionId, page = 1, maxIcons = 72) {
    return {
        collectionId: collectionId,
        apiResponse: true,
        limit: maxIcons,
        [CALL_API]: {
            types: [
                actionTypes.SEARCH_ICONS,
                actionTypes.SEARCH_ICONS_SUCCESS,
                actionTypes.SEARCH_ICONS_FAILURE
            ],
            endpoint: `json_icon_list/collections/?response=icons_response&id=${collectionId}&page=${page}&limit=${maxIcons}&raw_html=false`,
            schema: Schemas.ICONS,
            id: "collectionIcon",
            page,
            method: "GET",
            service: "www"
        }
    };
}

export function fetchCollectionIcons(collectionId, page = 1, maxIcons = 72) {
    return {
        collectionId: collectionId,
        limit: maxIcons,
        [CALL_API]: {
            types: [
                actionTypes.SEARCH_ICONS,
                actionTypes.SEARCH_ICONS_SUCCESS,
                actionTypes.SEARCH_ICONS_FAILURE
            ],
            endpoint: `json_icon_list/collections/?response=icons_response&id=${collectionId}&page=${page}&limit=${maxIcons}&raw_html=false`,
            schema: Schemas.ICONS,
            method: "GET",
            service: "www"
        }
    };
}

export function searchCreatorUploads(creator, page = 1, limit = 72) {
    return {
        apiResponse: true,
        creator: creator,
        limit,
        [CALL_API]: {
            types: [
                actionTypes.SEARCH_ICONS,
                actionTypes.SEARCH_ICONS_SUCCESS,
                actionTypes.SEARCH_ICONS_FAILURE
            ],
            endpoint: `${creator}/uploads/json/?page=${page}&limit=${limit}&raw_html=false`,
            schema: Schemas.ICONS,
            id: "userUploads",
            page,
            method: "GET",
            service: "www"
        }
    };
}

export function searchCreatorIcons(searchQuery, creator, page = 1, limit = 72) {
    return {
        searchQuery: searchQuery,
        apiResponse: true,
        creator: creator,
        limit,
        [CALL_API]: {
            types: [
                actionTypes.SEARCH_ICONS,
                actionTypes.SEARCH_ICONS_SUCCESS,
                actionTypes.SEARCH_ICONS_FAILURE
            ],
            endpoint: `search/json/icon/?q=${searchQuery}&creator=${creator}&page=${page}&limit=${limit}&raw_html=false`,
            schema: Schemas.ICONS,
            id: "userIcons",
            page,
            method: "GET",
            service: "www"
        }
    };
}

export function searchCollectionIcons(
    searchQuery,
    collection,
    page = 1,
    limit = 72
) {
    return {
        searchQuery: searchQuery,
        apiResponse: true,
        collection: collection,
        limit,
        [CALL_API]: {
            types: [
                actionTypes.SEARCH_ICONS,
                actionTypes.SEARCH_ICONS_SUCCESS,
                actionTypes.SEARCH_ICONS_FAILURE
            ],
            endpoint: `search/json/icon/?q=${searchQuery}&collection=${collection}&page=${page}&limit=${limit}&raw_html=false`,
            schema: Schemas.ICONS,
            id: "collectionIcons",
            page,
            method: "GET",
            service: "www"
        }
    };
}

export function searchTerm(searchTerm, page = 1, limit = 72) {
    return {
        searchTerm: searchTerm,
        limit,
        apiResponse: true,
        [CALL_API]: {
            types: [
                actionTypes.SEARCH_ICONS,
                actionTypes.SEARCH_ICONS_SUCCESS,
                actionTypes.SEARCH_ICONS_FAILURE
            ],
            endpoint: `json_icon_list/${searchTerm}/?page=${page}&limit=${limit}&raw_html=false`,
            schema: Schemas.ICONS,
            id: searchTerm,
            page,
            method: "GET",
            service: "www"
        }
    };
}

export function searchIcons(searchQuery, page = 1, limit = 72) {
    let urlSafeQuery = (searchQuery || "").trim();
    urlSafeQuery = encodeURIComponent(urlSafeQuery);
    return {
        searchQuery: searchQuery,
        apiResponse: true,
        limit,
        [CALL_API]: {
            types: [
                actionTypes.SEARCH_ICONS,
                actionTypes.SEARCH_ICONS_SUCCESS,
                actionTypes.SEARCH_ICONS_FAILURE
            ],
            endpoint: `search/json/icon/?q=${urlSafeQuery}&page=${page}&limit=${limit}&raw_html=false`,
            schema: Schemas.ICONS,
            id: searchQuery,
            page,
            method: "GET",
            service: "www"
        }
    };
}

export function fetchViewMoreIcons(iconId, limit = 1, offset = 0) {
    return {
        apiResponse: true,
        iconId: iconId,
        [CALL_API]: {
            id: iconId,
            types: [
                actionTypes.FETCH_VIEW_MORE_ICONS,
                actionTypes.FETCH_VIEW_MORE_ICONS_SUCCESS,
                actionTypes.FETCH_VIEW_MORE_ICONS_FAILURE
            ],
            endpoint: `search/view-more-this/${iconId}/?limit=${limit}&offset=${offset}`,
            schema: Schemas.ICONS,
            offset: offset,
            method: "GET",
            service: "www"
        }
    };
}

export function fetchIcons(searchQuery, page = 1) {
    return {
        searchQuery: searchQuery,
        apiResponse: true,
        [CALL_API]: {
            types: [
                actionTypes.FETCH_ICONS,
                actionTypes.FETCH_ICONS_SUCCESS,
                actionTypes.FETCH_ICONS_FAILURE
            ],
            endpoint: `plugin_search/?query=${searchQuery}&page=${page}`,
            schema: Schemas.ICONS,
            method: "GET",
            service: "www"
        }
    };
}

export function fetchIcon(iconId) {
    return {
        iconId: iconId,
        [CALL_API]: {
            types: [
                actionTypes.FETCH_ICON,
                actionTypes.FETCH_ICON_SUCCESS,
                actionTypes.FETCH_ICON_FAILURE
            ],
            endpoint: `json_icon_plugin/${iconId}/`,
            schema: Schemas.ICONS,
            method: "GET",
            service: "www"
        }
    };
}

export const fetchSVGData = (iconURL, iconId, attribution) => (
    dispatch,
    getState
) => {
    let { icons } = getState();
    if (attribution && icons.active_icon && icons.active_icon.id == iconId) {
        if (icons.active_icon.loadingRawSVG == attribution) {
            return;
        }
    }
    return dispatch({
        iconId: iconId,
        [CALL_API]: {
            types: [
                actionTypes.FETCH_SVG_DATA,
                actionTypes.FETCH_SVG_DATA_SUCCESS,
                actionTypes.FETCH_SVG_DATA_FAILURE
            ],
            endpoint: iconURL,
            method: "GET",
            service: "raw",
            binary: iconURL && iconURL.indexOf(".png") >= 0
        },
        [DECORATE_ACTIONS]: {
            attribution
        }
    });
};

export function placePluginSVG(icon, selectedColor, imgSize) {
    return {
        icon: icon,
        selectedColor: selectedColor,
        imgSize: imgSize,
        [CALL_API]: {
            types: [
                actionTypes.PLACE_SVG_FROM_ICONLIST,
                actionTypes.PLACE_SVG_FROM_ICONLIST_SUCCESS,
                actionTypes.PLACE_SVG_FROM_ICONLIST_FAILURE
            ],
            endpoint: icon.icon_url,
            method: "GET",
            service: "raw"
        }
    };
}

export function saveCleanedSVG(cleanedSVGData) {
    return {
        cleanedSVGData: cleanedSVGData,
        type: actionTypes.SAVE_CLEANED_SVG
    };
}

export function setActiveIcon(iconId) {
    return {
        icon_id: iconId,
        type: actionTypes.SET_ACTIVE_ICON
    };
}

export function clearActiveIcon(iconId) {
    return {
        type: actionTypes.CLEAR_ACTIVE_ICON
    };
}

export function getIconMetaData() {
    return {
        type: actionTypes.GET_ICON_META_DATA
    };
}

export function setColor(colorPalette) {
    return {
        color: colorPalette,
        type: actionTypes.SET_ICON_COLOR_PALETTE
    };
}

export function setSize(imgChoices) {
    return {
        imgChoices: imgChoices,
        type: actionTypes.SET_ICON_SIZE
    };
}

export function toggleIconDetail(action, icon) {
    if (action === "show") {
        return {
            type: actionTypes.SHOW_ICON_DETAIL
        };
    } else {
        return {
            type: actionTypes.HIDE_ICON_DETAIL
        };
    }
}

export function fetchRecentIcons(limit = 72, page = 1) {
    return {
        apiResponse: true,
        limit,
        [CALL_API]: {
            types: [
                actionTypes.SEARCH_ICONS,
                actionTypes.SEARCH_ICONS_SUCCESS,
                actionTypes.SEARCH_ICONS_FAILURE
            ],
            endpoint: `json_icon_list/recent_uploads/?page=${page}&limit=${limit}`,
            schema: Schemas.ICONS,
            page: page,
            id: "recent",
            method: "GET",
            service: "www"
        }
    };
}

export function fetchBestCollectionIcons(limit = 36, page = 1) {
    return {
        apiResponse: true,
        limit,
        [CALL_API]: {
            types: [
                actionTypes.FETCH_BEST_COLLECTIONS,
                actionTypes.FETCH_BEST_COLLECTIONS_SUCCESS,
                actionTypes.FETCH_BEST_COLLECTIONS_FAILURE
            ],
            endpoint: `featured/icons/?page=${page}&limit=${limit}`,
            schema: Schemas.ICONS,
            page: page,
            id: "browse",
            method: "GET",
            service: "www"
        }
    };
}

export function fetchFeaturedCollection(collectionId, page = 1, maxIcons = 72) {
    return {
        collectionId: collectionId,
        [CALL_API]: {
            types: [
                actionTypes.FETCH_FEATURED_COLLECTIONS,
                actionTypes.FETCH_FEATURED_COLLECTIONS_SUCCESS,
                actionTypes.FETCH_FEATURED_COLLECTIONS_FAILURE
            ],
            endpoint: `json_icon_list/collections/?response=icons_response&id=${collectionId}&page=${page}&limit=${maxIcons}&raw_html=false`,
            schema: Schemas.ICONS,
            method: "GET",
            service: "www"
        }
    };
}

//

export const saveTeam = (
    plan = "team-yearly",
    seats,
    teamUsername,
    stripeToken
) => (dispatch, getState) => {
    let { stripe } = getState();
    let couponCode;
    if (stripe.coupon) {
        couponCode = stripe.coupon.code;
    }

    return dispatch(
        callApiPromise({
            types: [
                actionTypes.UPGRADE_BILLING,
                actionTypes.UPGRADE_BILLING_SUCCESS,
                actionTypes.UPGRADE_BILLING_FAILURE
            ],
            endpoint: `/${teamUsername}/subscribe/team/`,
            method: "POST",
            data: {
                plan,
                seats,
                coupon: couponCode,
                stripe_token: stripeToken
            }
        })
    );
};

export const createTeam = (
    stripeApi,
    teamUsername,
    seats,
    tokenData,
    planKey
) => (dispatch, getState) => {
    let { stripe } = getState();
    if (stripe.processing) {
        console.log("processing");
        return Promise.resolve("processing");
    }

    if (!stripeApi) {
        let message = "Please enter your payment information.";
        dispatch(stripeCardInputError(message));
        return Promise.resolve(message);
    }

    return dispatch(stripeSaveReceiptInfo(teamUsername))
        .then(() => dispatch(stripeCreateToken(stripeApi, tokenData)))
        .then(token => dispatch(saveTeam(planKey, seats, teamUsername, token)))
        .then(() => {
            return null; // Ultimately resolve with null to signify success
        })
        .catch(error => {
            error = wwwStripeErrorToString(error);
            dispatch(stripeCardInputError(error));
            return error; // Resolve with the error to signify failure
        });
};

// user

export function logOut(logoutNextUrl) {
    // Log out is done via a hidden form on templates/base.html
    // in order to redirect the user somewhere custom on successful logout
    // the form expects the form data to be the url path
    $("input[name=next]").val(logoutNextUrl);
    $("#logout-form").trigger("submit");

    return {
        type: actionTypes.LOGOUT_USER
    };
}

export const getCurrentUser = () => {
    return {
        [CALL_API]: {
            types: [
                actionTypes.GET_CURRENT_USER,
                actionTypes.GET_CURRENT_USER_SUCCESS,
                actionTypes.GET_CURRENT_USER_FAILURE
            ],
            endpoint: `accounts/current-user/`,
            schema: Schemas.USER,
            method: "GET",
            service: "www"
        }
    };
};

export const getCurrentUserPromise = () => (dispatch, getState) => {
    return dispatch(
        callApiPromise({
            types: [
                actionTypes.GET_CURRENT_USER,
                false, //actionTypes.GET_CURRENT_USER_SUCCESS,
                actionTypes.GET_CURRENT_USER_FAILURE
            ],
            endpoint: `/accounts/current-user/`,
            method: "GET",
            data: {},
            service: "www"
        })
    ).then(response => {
        return dispatch({
            type: actionTypes.GET_CURRENT_USER_SUCCESS,
            response: userNormalizer(response)
        });
    });
};

export const authFormInputChange = input => ({
    type: actionTypes.AUTH_FORM_INPUT_CHANGE,
    input
});

export const authFormError = (field, error) => ({
    type: actionTypes.AUTH_FORM_ERROR,
    field,
    error
});

export const authFormClearData = () => ({
    type: actionTypes.AUTH_FORM_CLEAR_DATA
});

export function login(formData) {
    return {
        [CALL_API]: {
            types: [
                actionTypes.LOGIN,
                actionTypes.LOGIN_SUCCESS,
                actionTypes.LOGIN_FAILURE
            ],
            endpoint: `accounts/login/`,
            method: "POST",
            formData,
            service: "www"
        }
    };
}

export const logIn = () => (dispatch, getState) => {
    let { auth } = getState();
    let { login, password, captchaToken: captcha_token } = auth;

    return dispatch(
        callApiPromise({
            types: [
                actionTypes.LOGIN,
                actionTypes.LOGIN_SUCCESS,
                actionTypes.LOGIN_FAILURE
            ],
            endpoint: `/accounts/login/`,
            method: "POST",
            data: {
                login,
                password,
                captcha_token
            },
            service: "www"
        })
    );
};

export const requestLoginCode = () => (dispatch, getState) => {
    let { auth } = getState();
    let { login } = auth;

    return dispatch(
        callApiPromise({
            types: [
                actionTypes.REQUEST_CODE,
                actionTypes.REQUEST_CODE_SUCCESS,
                actionTypes.REQUEST_CODE_FAILURE
            ],
            endpoint: `/_allauth/browser/v1/auth/code/request`,
            method: "POST",
            data: JSON.stringify({
                email: login
            }),
            contentType: "application/json",
            dataType: "json",
            service: "www"
        })
    );
};

export const confirmLoginCode = loginCode => (dispatch, getState) => {
    return dispatch(
        callApiPromise({
            types: [
                actionTypes.CONFIRM_CODE,
                actionTypes.CONFIRM_CODE_SUCCESS,
                actionTypes.CONFIRM_CODE_FAILURE
            ],
            endpoint: `/_allauth/browser/v1/auth/code/confirm`,
            method: "POST",
            data: JSON.stringify({
                code: loginCode
            }),
            contentType: "application/json",
            dataType: "json",
            service: "www"
        })
    );
};

export const clearLoginCode = () => (dispatch, getState) => {
    return dispatch(
        callApiPromise({
            types: [
                actionTypes.CLEAR_LOGIN_CODE,
                actionTypes.CLEAR_LOGIN_CODE_SUCCESS,
                actionTypes.CLEAR_LOGIN_CODE_FAILURE
            ],
            endpoint: `/accounts/clear_login_code/`,
            method: "POST",
            contentType: "application/json",
            dataType: "json",
            service: "www"
        })
    );
};

export const signUp = signupType => (dispatch, getState) => {
    const {
        teamName: team_name,
        login: email,
        password: password1,
        acceptTerms: accept_terms,
        receiveUpdates: receive_updates,
        captchaToken: captcha_token
    } = getState().auth;

    return dispatch(
        callApiPromise({
            types: [
                actionTypes.SIGNUP,
                actionTypes.SIGNUP_SUCCESS,
                actionTypes.SIGNUP_FAILURE
            ],
            endpoint: `/accounts/signup/${signupType}/`,
            method: "POST",
            data: {
                team_name,
                email,
                password1,
                accept_terms,
                receive_updates,
                captcha_token,
                signup_type: signupType
            }
        })
    );
};

export const authFormClearErrors = () => ({
    type: actionTypes.AUTH_FORM_CLEAR_ERRORS
});

export function signup(formData) {
    return {
        [CALL_API]: {
            types: [
                actionTypes.SIGNUP,
                actionTypes.SIGNUP_SUCCESS,
                actionTypes.SIGNUP_FAILURE
            ],
            endpoint: `accounts/signup/`,
            method: "POST",
            formData: formData,
            service: "www"
        }
    };
}

export function createStripeToken(card, user) {
    // Sends customer's card data to stripe & gets token
    // Relies on stripeToken middleware to make stripe call
    return {
        type: actionTypes.CREATE_STRIPE_TOKEN,
        user: user,
        card: card
    };
}

export function saveCreditCard(user, stripeToken, receiptInfo) {
    const data = {};

    if (stripeToken) {
        data.stripe_token = stripeToken;
    }

    if (receiptInfo) {
        data.receipt_info = receiptInfo;
    }

    return {
        userId: user.id,
        [CALL_API]: {
            types: [
                actionTypes.SAVE_CARD,
                actionTypes.SAVE_CARD_SUCCESS,
                actionTypes.SAVE_CARD_FAILURE
            ],
            endpoint: `${user.username}/card/update/`,
            method: "POST",
            data,
            schema: Schemas.USER,
            service: "www"
        }
    };
}

export const upgradeToProAccount = (user, stripeToken, plan = "pro-yearly") => (
    dispatch,
    getState
) => {
    let { stripe } = getState();
    let couponCode;
    if (stripe.coupon) {
        couponCode = stripe.coupon.code;
    }

    return dispatch(
        callApiPromise({
            types: [
                actionTypes.UPGRADE_BILLING,
                actionTypes.UPGRADE_BILLING_SUCCESS,
                actionTypes.UPGRADE_BILLING_FAILURE
            ],
            endpoint: `/${user.username}/subscribe/pro/`,
            method: "POST",
            data: { plan, coupon: couponCode, stripe_token: stripeToken },
            actionPayload: {
                userId: user.id,
                plan
            }
        })
    );
};

export const iconsFetchPurchased = () => {
    return {
        [CALL_API]: {
            types: [
                actionTypes.ICONS_FETCH_PURCHASED,
                actionTypes.ICONS_FETCH_PURCHASED_SUCCESS,
                actionTypes.ICONS_FETCH_PURCHASED_FAILURE
            ],
            endpoint: `accounts/purchased_icon_ids/`,
            method: "GET",
            service: "www"
        }
    };
};

// Icons

export function getTotalIconCount() {
    return {
        [CALL_API]: {
            types: [
                actionTypes.FETCH_TOTAL_ICON_COUNT,
                actionTypes.FETCH_TOTAL_ICON_COUNT_SUCCESS,
                actionTypes.FETCH_TOTAL_ICON_COUNT_FAILURE
            ],
            endpoint: `count/icons/`,
            method: "GET",
            service: "www"
        }
    };
}

// plugin
export function setAdobeAgent() {
    return {
        type: actionTypes.SET_ADOBE_AGENT
    };
}

export function reportPluginUsage(iconId, host, fileType, hexColor) {
    hexColor = hexColor.replace("#", "");
    // GA event
    trackIconDownload({ source: host });
    // royalties tracking
    return {
        [CALL_API]: {
            types: [
                actionTypes.REPORT_PLUGIN_USAGE,
                actionTypes.REPORT_PLUGIN_USAGE_SUCCESS,
                actionTypes.REPORT_PLUGIN_USAGE_FAILURE
            ],
            data: {},
            endpoint: `plugin_report/?icon_id=${iconId}&host=${host}&file_type=${fileType}&hexColor=${hexColor}`,
            method: "POST",
            service: "www"
        }
    };
}

export function setPage(page) {
    return {
        page: page,
        type: actionTypes.SET_PLUGIN_PAGE
    };
}

export function setPluginHost(host) {
    return {
        host: host,
        type: actionTypes.SET_PLUGIN_HOST
    };
}

export function placePluginPNGGSuite(icon, color, size) {
    return {
        apiResponse: true,
        icon: icon,
        color: color,
        imgSize: size,
        [CALL_API]: {
            types: [
                actionTypes.GET_PNG_URL,
                actionTypes.GET_PNG_URL_SUCCESS,
                actionTypes.GET_PNG_URL_FAILURE
            ],
            data: "",
            endpoint: `get_plugin_png_token/${icon.id}/`,
            method: "GET",
            service: "www"
        }
    };
}

export function placePluginPDF(icon, color, size) {
    return {
        apiResponse: true,
        icon: icon,
        imgSize: size,
        [CALL_API]: {
            types: [
                actionTypes.FETCH_PDF_DATA,
                actionTypes.FETCH_PDF_DATA_SUCCESS,
                actionTypes.FETCH_PDF_DATA_FAILURE
            ],
            data: "",
            endpoint: `get_plugin_icon/pdf/${icon.id}/${color}/${size}/`,
            method: "GET",
            service: "www"
        }
    };
}

export function pluginIcons(page = 1) {
    return {
        apiResponse: true,
        [CALL_API]: {
            types: [
                actionTypes.FETCH_PLUGIN_ICONS,
                actionTypes.FETCH_PLUGIN_ICONS_SUCCESS,
                actionTypes.FETCH_PLUGIN_ICONS_FAILURE
            ],
            endpoint: `plugin_icons/${page}/`,
            schema: Schemas.ICONS,
            method: "GET",
            service: "www"
        }
    };
}

// edu

export function sendEduApplication(applicationData) {
    return {
        apiResponse: true,
        [CALL_API]: {
            types: [
                actionTypes.SEND_EDU_APPLICATION,
                actionTypes.SEND_EDU_APPLICATION_SUCCESS,
                actionTypes.SEND_EDU_APPLICATION_FAILURE
            ],
            endpoint: "/for-edu/apply/submit/",
            method: "POST",
            data: applicationData,
            service: "www"
        }
    };
}

// Downloads
export const downloadResetFlow = () => (dispatch, getState) => {
    let { download } = getState();
    let { phaseJumping } = download;
    dispatch({
        type: actionTypes.DOWNLOAD_RESET_FLOW,
        preserveEdits: !!phaseJumping
    });
};

export const downloadSetFlowActive = icon => (dispatch, getState) => {
    let { editor, download, icons } = getState();

    let cachedSvg = icons[icon.id] && icons[icon.id]["raw_svg_no_attribution"];

    dispatch({
        type: actionTypes.DOWNLOAD_SET_FLOW_ACTIVE,
        icon,
        editor,
        cachedSvg
    });
    dispatch({
        type: actionTypes.FETCH_HERO_ICON_SUCCESS,
        response: {},
        ...icon
    });
};

export const downloadFlowGoBack = () => ({
    type: actionTypes.DOWNLOAD_FLOW_GO_BACK
});

export const downloadRequest = () => (dispatch, getState) => {
    let { user, icons } = getState();

    dispatch({
        type: actionTypes.DOWNLOAD_REQUEST,
        user
    });
};

export function downloadSelectLicense(license) {
    return {
        type: actionTypes.DOWNLOAD_SELECT_LICENSE,
        license
    };
}

export function downloadCommitLicense() {
    return {
        type: actionTypes.DOWNLOAD_COMMIT_LICENSE
    };
}

export const downloadShowAuth = (
    resumeFlow = DOWNLOAD_FLOW,
    extra = null
) => dispatch => {
    let modalDismissed = dispatch(showModal(null, "AUTH"));
    dispatch({
        type: actionTypes.DOWNLOAD_SHOW_AUTH,
        resumeFlow,
        extra
    });
    return modalDismissed;
};

export const recreateCurrentPath = location =>
    `${location.pathname}${location.search}`;

export const downloadFlowModalStep = (history, modalType, modalProps = {}) => (
    dispatch,
    getState
) => {
    // Since the modal components are bound in the `modalTypes` file,
    // importing it into this file causes a circular import. Ultimately this
    // action should be implemented as middleware which should avoid the problem
    // (or modalTypes split into two files, one for the constants and another for the bindings)
    const modalTypes = {
        AUTH: "AUTH",
        DOWNLOAD_FLOW: "DOWNLOAD_FLOW",
        ICON_SHARE: "ICON_SHARE"
    };
    switch (modalType) {
        case modalTypes.AUTH:
            dispatch(downloadShowAuth()).then(() => {
                dispatch(downloadFlowHideModal(history));
            });
            break;
        case modalTypes.DOWNLOAD_FLOW:
            dispatch(downloadRequest());
            dispatch(
                showModal(null, modalTypes.DOWNLOAD_FLOW, modalProps)
            ).then(() => {
                dispatch(downloadFlowHideModal(history));
            });
            break;
        case modalTypes.ICON_SHARE:
            dispatch(showModal(null, modalTypes.ICON_SHARE, modalProps)).then(
                () => {
                    let { modal } = getState();
                    // Don't push a modal-less history entry if the download flow modal is open
                    if (modal.all[modalTypes.DOWNLOAD_FLOW]) {
                        if (
                            !history.location.state ||
                            history.location.state.modal !=
                                modalTypes.DOWNLOAD_FLOW
                        ) {
                            dispatch(
                                downloadFlowNavigateToModalStep(
                                    history,
                                    modalTypes.DOWNLOAD_FLOW
                                )
                            );
                        }
                        return;
                    }
                    dispatch(downloadFlowHideModal(history));
                }
            );
            break;
    }
};

export const downloadFlowNavigateToModalStep = (history, modalType) => () => {
    const currentPath = recreateCurrentPath(history.location);
    if (modalType === "AUTH") {
        window.location.assign(
            `${SIGNUP_URL}?next=${encodeURIComponent(currentPath)}`
        );
        return;
    }
    history.push(currentPath, {
        modal: modalType
    });
};

export const downloadFlowHideModal = history => () => {
    history.replace(recreateCurrentPath(history.location), { modal: false });
};

export const downloadCompleteSuccess = (format, downloadType) => (
    dispatch,
    getState
) => {
    dispatch({
        type: actionTypes.DOWNLOAD_COMPLETE_SUCCESS,
        fileType: format,
        downloadType
    });
};

export const downloadStart = format => (dispatch, getState) => {
    let { download, editor } = getState();
    let {
        pendingDownload,
        processing,
        isPremiumUser,
        hasPurchased,
        iconBaseLicense
    } = download;
    if (processing) {
        console.log("Already downloading");
        return;
    }

    if (!format) {
        return dispatch({
            type: actionTypes.DOWNLOAD_START_FAILURE,
            error: "Please select a format to download."
        });
    }

    let { transforms } = editor;
    let { color: selectedColor } = transforms;
    let imgColor;
    if (selectedColor) {
        imgColor = selectedColor;
    }

    let data = {
        icon_id: pendingDownload.id,
        img_type: format
    };

    let isPublicDomain = iconBaseLicense == licenses.PUBLIC_DOMAIN;

    let downloadType = isPremiumUser ? "Pro" : "Public Domain";
    if (hasPurchased) {
        downloadType = "Royalty Free";
    }

    if (isPremiumUser || hasPurchased || isPublicDomain) {
        let { editor } = getState();

        let fileName = makeIconFilename(pendingDownload, null, format);
        dispatch({ type: actionTypes.DOWNLOAD_START, fileType: format });

        let previousExportSize;
        let { needsWrap } = editor;
        if (format == "svg" && needsWrap) {
            ({ exportSize: previousExportSize } = transforms);
            // Re-render svg at 100x100 to maximize compatibilty of nested svg
            dispatch(editorActions.editorTransform("EXPORT_SIZE", 100));
        }

        defer(() => {
            dispatch(editorActions.editorExport(format, fileName))
                .then(() =>
                    dispatch(
                        downloadCompleteSuccess(
                            format,
                            `edited.${downloadType}`
                        )
                    )
                )
                .catch(error => {
                    console.warn(error);
                    dispatch({
                        type: actionTypes.DOWNLOAD_COMPLETE_FAILURE,
                        fileType: format,
                        error
                    });
                })
                .then(() => {
                    if (previousExportSize) {
                        dispatch(
                            editorActions.editorTransform(
                                "EXPORT_SIZE",
                                previousExportSize
                            )
                        );
                    }
                });
        });
        return;
    }

    downloadType = "Creative Commons";
    return dispatch(
        callApiPromise({
            types: [
                actionTypes.DOWNLOAD_START,
                downloadStartSuccess,
                actionTypes.DOWNLOAD_START_FAILURE
            ],
            endpoint: `/icon/${pendingDownload.id}/download/`,
            method: "POST",
            data,
            actionPayload: {
                downloadType,
                fileType: format,
                icon: pendingDownload.id
            }
        })
    ).catch(error => {
        let code = (error && error.status) || "";
        Sentry.captureMessage(`${downloadType} Download failed ${code}`, {
            extra: {
                iconId: pendingDownload.id,
                data,
                error: JSON.stringify(error)
            }
        });
    });
};

const makeIconFilename = (icon, color, fileType) => {
    let { name, id, term } = icon;

    let fileName = `noun_${name || term || ""}_${id}`;
    if (color) {
        fileName = `${fileName}_${color}`;
    }
    return `${fileName}.${fileType}`;
};

export const downloadStartSuccess = ({
    response,
    downloadType,
    imgColor,
    fileType
}) => (dispatch, getState) => {
    dispatch({
        type: actionTypes.DOWNLOAD_START_SUCCESS,
        fileType,
        downloadType,
        imgColor,
        response
    });

    let { download: downloadUrl } = response;

    let { download } = getState();

    let { pendingDownload } = download;
    let { id } = pendingDownload;

    let fileName = makeIconFilename(pendingDownload, imgColor, fileType);

    let error = null;
    let finished = downloadjs(downloadUrl, fileName);
    if (finished.then) {
        return finished
            .then(() => {
                dispatch(downloadCompleteSuccess(fileType, downloadType));
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.DOWNLOAD_COMPLETE_FAILURE,
                    error,
                    downloadType,
                    fileType,
                    icon: id
                });
                let code = (error && error.status) || "";
                let message = `${downloadType} Download failed ${code}`;
                Sentry.captureMessage(message, {
                    extra: {
                        iconId: id,
                        fileName,
                        downloadUrl,
                        error
                    }
                });
            });
    } else {
        dispatch(downloadCompleteSuccess(fileType, downloadType));
    }
};

export const settingsBillingFormSubmit = (stripeApi, tokenData, username) => (
    dispatch,
    getState
) => {
    return dispatch(stripeCreateToken(stripeApi, tokenData))
        .then(token => dispatch(stripeSaveCardBillingInfo(username, token)))
        .then(() => null)
        .catch(error => {
            error = wwwStripeErrorToString(error);
            dispatch(stripeCardInputError(error));
            return error;
        });
};

const stripeSaveCardBillingInfo = (username, token) => (dispatch, getState) => {
    let { stripe } = getState();
    let { billingInfo } = stripe;

    let updateToken = token;

    if (updateToken || billingInfo) {
        const endpoint = `/${username}/card/update/`;
        const data = {
            stripe_token: updateToken,
            receipt_info: billingInfo
        };

        return dispatch(
            callApiPromise({
                method: "post",
                types: [
                    actionTypes.SAVE_CARD,
                    actionTypes.SAVE_CARD_SUCCESS,
                    actionTypes.SAVE_CARD_FAILURE
                ],
                data,
                endpoint
            })
        ).then(response => {
            return token;
        });
    }
};
const stripeSaveReceiptInfo = username => (dispatch, getState) => {
    let { stripe } = getState();
    let { billingInfo } = stripe;
    if (billingInfo) {
        const endpoint = `/${username}/receipt_info/update/`;
        const data = {
            receipt_info: billingInfo
        };

        return dispatch(
            callApiPromise({
                method: "post",
                types: [
                    actionTypes.STRIPE_SAVE_BILLING_INFO,
                    actionTypes.STRIPE_SAVE_BILLING_INFO_SUCCESS,
                    actionTypes.STRIPE_SAVE_BILLING_INFO_FAILURE
                ],
                data,
                endpoint
            })
        );
    } else {
        return Promise.resolve();
    }
};

export const stripeDeleteCard = username => dispatch => {
    return dispatch(
        callApiPromise({
            types: [
                actionTypes.STRIPE_DELETE_CARD,
                actionTypes.STRIPE_DELETE_CARD_SUCCESS,
                actionTypes.STRIPE_DELETE_CARD_FAILURE
            ],
            endpoint: `/${username}/card/delete/`,
            method: "POST",
            data: {},
            service: "www"
        })
    );
};

export const deleteCard = username => (dispatch, getState) => {
    return dispatch(stripeDeleteCard(username))
        .then(() => {
            dispatch(stripeUpdateBillingInfo(null));
            return null;
        })
        .catch(error => error);
};

export const doUpgrade = (stripeApi, user, tokenData, planType) => (
    dispatch,
    getState
) => {
    let { stripe } = getState();
    if (stripe.processing) {
        console.log("processing");
        return Promise.resolve("processing");
    }

    if (!stripeApi) {
        let message = "Please enter your payment information.";
        dispatch(stripeCardInputError(message));
        return Promise.resolve(message);
    }

    return dispatch(stripeSaveReceiptInfo(user.username))
        .then(() => dispatch(stripeCreateToken(stripeApi, tokenData)))
        .then(token => dispatch(upgradeToProAccount(user, token, planType)))
        .then(() => {
            return null; // Ultimately resolve with null to signify success
        })
        .catch(error => {
            error = wwwStripeErrorToString(error);
            dispatch(stripeCardInputError(error));
            return error; // Resolve with the error to signify failure
        });
};

export const wwwStripeErrorToString = error => {
    if (!isString(error)) {
        if (error.errors) {
            error = error.errors;
        }
        if (error.message) {
            error = error.message;
        } else if (error.error && error.error.message) {
            error = error.error.message;
        } else {
            error =
                "Something went wrong, please contact us at info@thenounproject.com";
        }
    }

    return error;
};

export const downloadPurchase = stripeApi => (dispatch, getState) => {
    let { stripe, download, user } = getState();
    let { pendingDownload, processing } = download;
    if (processing) {
        return;
    }

    let iconId = pendingDownload.id;
    let {
        stripeToken,
        currentPurchaseKey,
        purchaseTypes,
        saveCard,
        savedCard,
        useNewCard,
        billingInfo
    } = stripe;

    let currentPurchase = find(purchaseTypes, { key: currentPurchaseKey });

    if (!currentPurchase) {
        return dispatch(downloadPurchaseEnd("Please select a license."));
    }

    let isSubscription = !!currentPurchase.period;
    let isConsumingPrepaidBalance = currentPurchase.type == CONSUMING_PREPAID;
    let isPurchasingPrepaid = currentPurchase.type == PREPAID_TYPE;
    let isAlaCarte = currentPurchase.type == ALACARTE_TYPE;

    let getToken;
    if (isConsumingPrepaidBalance || (savedCard && !useNewCard)) {
        getToken = Promise.resolve();
    } else {
        if (!stripeApi) {
            return dispatch(
                downloadPurchaseEnd("Please enter a payment method.")
            );
        }
        getToken = dispatch(stripeCreateToken(stripeApi));
    }

    let didUpdateSavedCard;
    dispatch(downloadPurchaseStart());
    return getToken
        .then(token => {
            if (billingInfo) {
                return dispatch(stripeSaveReceiptInfo(user.username))
                    .then(response => {
                        // Pass through the token for single purchases that are not saving their card
                        return token;
                    })
                    .catch(error => {
                        console.warn(error);
                        return Promise.reject(
                            "Something went wrong trying to save your billing info, you have not been charged. If the problem persists, please let us know at info@thenounproject.com"
                        );
                    });
            }
            return token;
        })
        .then(token => {
            let data = {};

            if (token) {
                data.stripe_token = token;

                if (isAlaCarte || isPurchasingPrepaid) {
                    data.save_card = saveCard;
                    didUpdateSavedCard = true;
                }
            }

            if (isAlaCarte) {
                data.single_icon_price_cents = currentPurchase.price;
            }
            if (isConsumingPrepaidBalance) {
                data.use_prepaid_balance = true;
            }

            if (isPurchasingPrepaid) {
                data.purchase_prepaid_balance_cents = currentPurchase.price;
            }

            let endpoint = `/icon/${iconId}/purchase/`;
            if (isSubscription) {
                data.plan = `${currentPurchase.type}-${currentPurchase.period}`;
                endpoint = `/${user.username}/subscribe/pro/`;
            }

            return dispatch(
                callApiPromise({
                    endpoint,
                    data,
                    method: "post",
                    types: [
                        actionTypes.DOWNLOAD_PURCHASE,
                        actionTypes.DOWNLOAD_PURCHASE_SUCCESS,
                        actionTypes.DOWNLOAD_PURCHASE_FAILURE
                    ],
                    actionPayload: {
                        purchase: currentPurchase
                    }
                })
            );
        })
        .then(() => {
            dispatch(downloadPurchaseEnd());
            if (didUpdateSavedCard) {
                return dispatch(stripeRefreshSavedCard());
            }
        })
        .catch(error => {
            let safeError = wwwStripeErrorToString(error);
            dispatch(downloadPurchaseEnd(safeError));

            if (didUpdateSavedCard && error && error.did_save_card) {
                return dispatch(stripeRefreshSavedCard());
            }
        });
};

const stripeRefreshSavedCard = () => dispatch => {
    dispatch({
        type: actionTypes.SAVE_CARD_SUCCESS,
        response: {}
    });
    return dispatch(stripeGetSavedCard());
};

export const downloadSelectPurchaseType = purchaseTypeKey => (
    dispatch,
    getState
) => {
    let { download, stripe } = getState();

    if (download.processing || stripe.processing) {
        return;
    }

    return dispatch({
        type: actionTypes.DOWNLOAD_SELECT_PURCHASE_TYPE,
        purchaseTypeKey
    });
};

export const downloadCommitPurchaseType = () => (dispatch, getState) => {
    let { stripe } = getState();
    let { currentPurchaseKey } = stripe;
    dispatch({
        type: actionTypes.DOWNLOAD_COMMIT_PURCHASE_TYPE,
        purchaseTypeKey: currentPurchaseKey
    });
};

export const downloadDoUpsell = () => (dispatch, getState) => {
    let { pendingDownload } = getState().download;
    dispatch(downloadUpsell(pendingDownload));
};

const downloadPhaseJumpStart = ({ repurchasing, license }) => ({
    type: actionTypes.DOWNLOAD_PHASE_JUMP_START,
    repurchasing,
    license
});
const downloadPhaseJumpEnd = () => ({
    type: actionTypes.DOWNLOAD_PHASE_JUMP_END
});

export const downloadResetToLicense = (icon, license) => (
    dispatch,
    getState
) => {
    dispatch(downloadPhaseJumpStart({ license }));
    dispatch(downloadResetFlow());
    dispatch(downloadSetFlowActive(icon));
    dispatch(downloadRequest());
    dispatch(downloadSelectLicense(license));
    dispatch(downloadPhaseJumpEnd());

    dispatch(downloadCommitLicense());
};
export const downloadUpsell = icon => (dispatch, getState) => {
    dispatch({
        type: actionTypes.DOWNLOAD_UPSELL_ACCEPTED
    });
    dispatch(downloadResetToLicense(icon, licenses.ROYALTY_FREE));
};

export const downloadRepurchase = icon => (dispatch, getState) => {
    let license = licenses.ROYALTY_FREE;
    dispatch(downloadPhaseJumpStart({ repurchasing: true, license }));
    dispatch(downloadResetFlow());
    dispatch({
        type: actionTypes.DOWNLOAD_REPURCHASE
    });
    dispatch(downloadSetFlowActive(icon));
    dispatch(downloadRequest());
    dispatch(downloadSelectLicense(license));
    dispatch(downloadCommitLicense());
    dispatch(downloadPhaseJumpEnd());

    let { download } = getState();
    let repurchaseWith = !!download.prepaidBalance
        ? CONSUMING_PREPAID
        : ALACARTE_TYPE;

    dispatch(downloadSelectPurchaseType(repurchaseWith));
};

export const downloadJumpToLicense = license => (dispatch, getState) => {
    let { download } = getState();
    if (download.processing) {
        return;
    }

    let { usingPremiumFeatures } = download;

    let icon = download.pendingDownload;

    dispatch({
        type: actionTypes.DOWNLOAD_JUMP_TO_LICENSE,
        license
    });

    dispatch(downloadResetToLicense(icon, license));
};

export const downloadResetAttribution = () => (dispatch, getState) => {
    let { download } = getState();
    if (download.processing) {
        return;
    }

    let icon = download.pendingDownload;

    dispatch(downloadResetToLicense(icon, licenses.CREATIVE_COMMONS));
};

export const downloadNoPremiumFeatures = icon => (dispatch, getState) => {
    dispatch({
        type: actionTypes.DOWNLOAD_UPSELL_REJECTED
    });
    dispatch(editorActions.editorReset());
};

export function downloadPurchaseStart() {
    return {
        type: actionTypes.DOWNLOAD_PURCHASE_START
    };
}

export function downloadPurchaseEnd(error) {
    return {
        type: actionTypes.DOWNLOAD_PURCHASE_END,
        error
    };
}

// Stripe for icon purchases

export function stripeCreateTokenStart() {
    return {
        type: actionTypes.STRIPE_CREATE_TOKEN
    };
}

export function stripeCreateTokenSuccess(payload) {
    return {
        type: actionTypes.STRIPE_CREATE_TOKEN_SUCCESS,
        payload
    };
}

export function stripeCreateTokenFailure(error) {
    return {
        type: actionTypes.STRIPE_CREATE_TOKEN_FAILURE,
        error
    };
}

export const stripeCreateToken = (stripe, tokenData) => dispatch => {
    if (!stripe) {
        return Promise.reject("Please enter your payment information.");
    }
    dispatch(stripeCreateTokenStart());
    return stripe
        .createToken(tokenData)
        .then(payload => {
            if (payload.error) {
                return Promise.reject(payload.error);
            }
            dispatch(stripeCreateTokenSuccess(payload));
            return payload;
        })
        .then(stripeResponse => {
            if (stripeResponse) {
                let { token } = stripeResponse;
                return token.id;
            }
        })
        .catch(error => {
            dispatch(stripeCreateTokenFailure(error));
            return Promise.reject(error);
        });
};

export function stripeCardInputChange(evt) {
    let { elementType } = evt;

    return {
        type: actionTypes.STRIPE_CARD_INPUT_CHANGE,
        elementType
    };
}

export function stripeCardInputError(error) {
    let elementType;
    if (error && error.message) {
        ({ elementType, message: error } = error);
    }

    return {
        type: actionTypes.STRIPE_CARD_INPUT_ERROR,
        error,
        elementType
    };
}

export const stripeClearErrors = evt => ({
    type: actionTypes.STRIPE_CLEAR_ERRORS
});

export const stripeSaveCardChange = value => (dispatch, getState) => {
    let { download, stripe } = getState();

    if (download.processing || stripe.processing) {
        return;
    }

    return dispatch({
        type: actionTypes.STRIPE_SAVE_CARD_CHANGE,
        value
    });
};

export const stripeGetSavedCard = skipBillingInfo => dispatch => {
    let endpoint = "/get/customer/";

    return dispatch(
        callApiPromise({
            actionPayload: {
                skipBillingInfo
            },
            types: [
                actionTypes.STRIPE_GET_SAVED_CARD,
                actionTypes.STRIPE_GET_SAVED_CARD_SUCCESS,
                actionTypes.STRIPE_GET_SAVED_CARD_FAILURE
            ],
            endpoint,
            method: "GET"
        })
    );
};

export const stripeGetTeamSavedCard = teamUserName => dispatch => {
    let endpoint = `/${teamUserName}/card/`;

    return dispatch(
        callApiPromise({
            types: [
                actionTypes.STRIPE_GET_SAVED_CARD,
                actionTypes.STRIPE_GET_SAVED_CARD_SUCCESS,
                actionTypes.STRIPE_GET_SAVED_CARD_FAILURE
            ],
            endpoint,
            method: "GET"
        })
    );
};

export const stripeUseNewCard = () => (dispatch, getState) => {
    let { download, stripe } = getState();

    if (download.processing || stripe.processing) {
        return;
    }

    return dispatch({
        type: actionTypes.STRIPE_USE_NEW_CARD
    });
};

export const stripeUpdateBillingInfo = value => (dispatch, getState) => {
    let { download, stripe } = getState();

    if (download.processing || stripe.processing) {
        return;
    }

    return dispatch({
        type: actionTypes.STRIPE_UPDATE_BILLING_INFO,
        value
    });
};

export const stripeCouponCodeCheck = () => (dispatch, getState) => {
    let { stripe } = getState();
    let { couponCode } = stripe;
    if (!couponCode) {
        return;
    }
    couponCode = encodeURIComponent(couponCode);
    return dispatch({
        [CALL_API]: {
            types: [
                actionTypes.STRIPE_COUPON_CODE_CHECK,
                actionTypes.STRIPE_COUPON_CODE_CHECK_SUCCESS,
                actionTypes.STRIPE_COUPON_CODE_CHECK_FAILURE
            ],
            endpoint: `coupon/${couponCode}/`,
            method: "GET",
            service: "www"
        }
    });
};

export const stripeCouponCodeChange = couponCode => {
    couponCode = encodeURIComponent(couponCode);
    return {
        type: actionTypes.STRIPE_COUPON_CODE_CHANGE,
        couponCode
    };
};

export function stripePrepaidNumIconsChange(numIcons) {
    return {
        type: actionTypes.STRIPE_PREPAID_NUM_ICONS_CHANGE,
        numIcons
    };
}

export function stripePrepaidNumIconsBlur(numIcons) {
    return {
        type: actionTypes.STRIPE_PREPAID_NUM_ICONS_BLUR,
        numIcons
    };
}

export const stripeNumSeatsChange = (seats, minSeats) => ({
    type: actionTypes.STRIPE_NUM_SEATS_CHANGE,
    seats,
    minSeats
});

export const stripeNumSeatsBlur = (seats, minSeats) => ({
    type: actionTypes.STRIPE_NUM_SEATS_BLUR,
    seats,
    minSeats
});

// UI
export const uiScrollToElement = el => {
    if (el || _isNumber(el)) {
        let offset;
        if (_isNumber(el)) {
            offset = el;
        } else {
            let scrollToEl = $(el);
            if (scrollToEl.length) {
                offset = scrollToEl.offset().top;
            } else {
                console.warn("scroll to element not found", el);
            }
        }

        if (_isNumber(offset)) {
            let contentOffset = 1;
            let mainContentOffset = $("#main-content").offset();
            if (mainContentOffset) {
                contentOffset += mainContentOffset.top - 60;
            }
            let scrollTo = offset - contentOffset;

            $("html,body").scrollTop(scrollTo);
        } else {
            console.warn("scroll to element not found", el);
        }
    }

    return {
        type: actionTypes.UI_SCROLL_TO_ELEMENT,
        element: el && (el.id || el.getAttribute("class"))
    };
};

export const uiBodyClassAdd = (...classes) => {
    if (classes && classes.length) {
        global.document.body.classList.add(...classes);
    }
    return {
        type: actionTypes.UI_BODY_CLASS_ADD,
        classes
    };
};

export const uiBodyClassRemove = (...classes) => {
    let toRemove = [];
    if (classes && classes.length == 1 && isRegExp(classes[0])) {
        let needle = classes[0];

        let classList = global.document.body.classList;

        if (!classList.forEach) {
            classList = [].slice.call(classList);
        }

        classList.forEach(cl => {
            if (needle.test(cl)) {
                toRemove.push(cl);
            }
        });
    } else {
        toRemove = classes;
    }

    if (toRemove.length) {
        global.document.body.classList.remove(...toRemove);
    }

    return {
        type: actionTypes.UI_BODY_CLASS_REMOVE,
        toRemove
    };
};

export const uiWindowResize = (width, height) => ({
    type: actionTypes.UI_WINDOW_RESIZE,
    width,
    height
});

export const redirect = href => ({
    type: actionTypes.REDIRECT,
    href
});

export const experimentsSet = experiments => ({
    type: actionTypes.EXPERIMENTS_SET,
    experiments
});

export const userGetPrepaidBalance = () => {
    return {
        [CALL_API]: {
            types: [
                actionTypes.USER_GET_PREPAID_BALANCE,
                actionTypes.USER_GET_PREPAID_BALANCE_SUCCESS,
                actionTypes.USER_GET_PREPAID_BALANCE_FAILURE
            ],
            endpoint: `user/prepaid/`,
            method: "GET",
            service: "www"
        }
    };
};

export const fetchCollections = (query, creator) => (dispatch, getState) => {
    query = encode(query);
    creator = encode(creator);

    if (!query) return;

    let queryStr = `?q=${query}`;
    queryStr += creator ? `&creator=${creator}` : "";

    return dispatch(
        callApiPromise({
            types: [
                actionTypes.FETCH_COLLECTIONS,
                actionTypes.FETCH_COLLECTIONS_SUCCESS,
                actionTypes.FETCH_COLLECTIONS_FAILURE
            ],
            endpoint: `/search/json/collection/${queryStr}`,
            method: "GET"
        })
    );

    function encode(string) {
        if (typeof string !== "string") return;
        if (string.length < 1) return;

        return encodeURIComponent(string.trim());
    }
};

export const bannerDismiss = bannerType => (dispatch, getState) => {
    let { user } = getState();

    if (user.is_anonymous) {
        dispatch({
            type: actionTypes.BANNER_DISMISS,
            bannerType
        });
        return dispatch({
            type: actionTypes.BANNER_DISMISS_SUCCESS,
            bannerType
        });
    }

    return dispatch({
        [CALL_API]: {
            types: [
                actionTypes.BANNER_DISMISS,
                actionTypes.BANNER_DISMISS_SUCCESS,
                actionTypes.BANNER_DISMISS_FAILURE
            ],
            endpoint: `accounts/hide-banner/${bannerType}`,
            method: "GET",
            service: "www"
        },
        [DECORATE_ACTIONS]: {
            bannerType
        }
    });
};

export const pageview = (path, meta) => ({
    type: actionTypes.PAGEVIEW,
    path,
    meta
});

// Kits

export const kitsShowDropdown = () => ({
    type: actionTypes.KITS_SHOW_DROPDOWN
});
export const kitsHideDropdown = () => ({
    type: actionTypes.KITS_HIDE_DROPDOWN
});

export const kitsAddIcon = (kitId, iconId) => (dispatch, getState) => {
    let { kits } = getState();
    if (kits.processing) {
        return Promise.resolve();
    }

    return dispatch(
        callApiPromise({
            types: [
                actionTypes.KITS_ADD_ICON,
                actionTypes.KITS_ADD_ICON_SUCCESS,
                actionTypes.KITS_ADD_ICON_FAILURE
            ],
            endpoint: `/kit/add/${kitId}/${iconId}/`,
            method: "POST",
            actionPayload: { kitId, iconId }
        })
    );
};

export const kitsRemoveIcon = (kitId, iconId) => (dispatch, getState) => {
    let { kits } = getState();
    if (kits.processing) {
        return Promise.resolve();
    }

    return dispatch(
        callApiPromise({
            types: [
                actionTypes.KITS_REMOVE_ICON,
                actionTypes.KITS_REMOVE_ICON_SUCCESS,
                actionTypes.KITS_REMOVE_ICON_FAILURE
            ],
            endpoint: `/kit/delete/${kitId}/${iconId}/`,
            method: "POST",
            actionPayload: { kitId, iconId }
        })
    );
};

export const kitsFetch = () => {
    return callApiPromise({
        types: [
            actionTypes.KITS_FETCH,
            actionTypes.KITS_FETCH_SUCCESS,
            actionTypes.KITS_FETCH_FAILURE
        ],
        endpoint: `/kit/list/`,
        method: "GET"
    });
};
export const kitsInputChange = (fieldName, value) => ({
    type: actionTypes.KITS_INPUT_CHANGE,
    input: {
        fieldName,
        value
    }
});
export const kitsCreateKit = iconId => (dispatch, getState) => {
    let { kits } = getState();
    let { processing, newKit } = kits;
    if (processing) {
        return Promise.resolve();
    }

    return dispatch(
        callApiPromise({
            types: [
                actionTypes.KITS_CREATE_KIT,
                actionTypes.KITS_CREATE_KIT_SUCCESS,
                actionTypes.KITS_CREATE_KIT_FAILURE
            ],
            endpoint: "/kit/new/",
            method: "POST",
            data: {
                ...newKit,
                icon_id: iconId,
                popup: true
            },
            actionPayload: { iconId }
        })
    );
};
export const kitsSaveToFavorites = (iconId, force = false) => (
    dispatch,
    getState
) => {
    let { kits } = getState();
    let { processing } = kits;
    if (!force && processing) {
        return Promise.resolve();
    }

    return dispatch(
        callApiPromise({
            types: [
                actionTypes.KITS_SAVE_TO_FAVORITES,
                actionTypes.KITS_SAVE_TO_FAVORITES_SUCCESS,
                actionTypes.KITS_SAVE_TO_FAVORITES_FAILURE
            ],
            endpoint: "/kit/save-to-favorites/",
            method: "POST",
            data: {
                icon_id: iconId
            },
            actionPayload: { iconId }
        })
    );
};
export const kitsRemoveFromFavorites = iconId => (dispatch, getState) => {
    let { kits } = getState();
    let { processing } = kits;
    if (processing) {
        return Promise.resolve();
    }

    return dispatch(
        callApiPromise({
            types: [
                actionTypes.KITS_REMOVE_FROM_FAVORITES,
                actionTypes.KITS_REMOVE_FROM_FAVORITES_SUCCESS,
                actionTypes.KITS_REMOVE_FROM_FAVORITES_FAILURE
            ],
            endpoint: "/kit/remove-from-favorites/",
            method: "POST",
            data: {
                icon_id: iconId
            },
            actionPayload: { iconId }
        })
    );
};

export const kitsInitializeNewKit = () => ({
    type: actionTypes.KITS_INITIALIZE_NEW_KIT
});

// Editor
export * from "./editor";
