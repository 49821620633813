import styled, { css } from "styled-components";

import checkIcon from "img/icons/check-mark-approve-white.svg";

import colors from "core/colors";

export const Label = styled.label`
    cursor: ${props => (props.isDisabled ? "not-allowed" : "pointer")};
`;

export const Input = styled.input`
    position: absolute;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
    + span {
        display: ${({ isFlexBox }) => (isFlexBox ? "flex" : "inline-block")};
        color: ${props => (props.isDisabled ? "#999" : colors.secondary.orca)};
        font-size: 0.8125rem;
        line-height: 1.063rem;
        user-select: none;
        &:before {
            flex: 0 0 16px;
            content: "";
            display: inline-block;
            margin-right: 0.75rem;
            width: 1rem;
            height: 1rem;
            position: relative;
            top: 3px;
            border: ${props =>
                props.isChecked
                    ? "1px solid transparent"
                    : `1px solid ${colors.secondary.manatee}`};
            border-radius: 3px;

            ${({ isChecked, isDisabled, checkboxStyles }) => {
                if (isChecked) {
                    return css`
                        background-image: url(${checkIcon.src || checkIcon});
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: cover;
                        background-color: ${isDisabled
                            ? checkboxStyles.disabled
                            : checkboxStyles.checked};
                    `;
                } else {
                    return css`
                        background-color: ${checkboxStyles.default};
                    `;
                }
            }}
        }
        span {
            position: relative;
            top: ${({ isFlexBox }) => (isFlexBox ? "-2px" : 0)};
            flex: 1;
        }
    }

    &:focus + span:before {
        outline: 3px solid ${colors.functional.secondary.parrot};
    }
`;
