import React from "react";
import PropTypes from "prop-types";

export const TruncateText = ({ charLimit, children }) => {
    const text = children;
    const truncated =
        text.length > charLimit ? `${text.substr(0, charLimit)}...` : text;
    // charLimit also considers spaces as a character

    return <>{truncated}</>;
};

TruncateText.propTypes = {
    charLimit: PropTypes.number.isRequired,
    children: PropTypes.string
};

export default TruncateText;

export const truncateText = (text, charLimit = 20) => {
    // charLimit also considers spaces as a character
    const truncated =
        typeof text === "string" && text.length > charLimit
            ? `${text.substr(0, charLimit)}...`
            : text;

    return truncated;
};
