import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import colors from "core/colors";

const createHelpers = (width, height, css) => {
    // somehow sizes is ending up in markup, even if it is not a valid svg attribute
    // until we have a better solution, just render it empty, instead to '[Object object]'
    const sanitizeSizes = sizes =>
        Object.defineProperty(sizes, "toString", {
            value: () => "",
            enumerable: false
        });

    const getDimensions = (size, sizes) => {
        if (
            size &&
            typeof size.width === "number" &&
            typeof size.height === "number"
        ) {
            return size;
        }

        return size && sizes[size] ? sizes[size] : { width, height };
    };

    const getCss = (size, sizes, fillColor, fillColorRule, noStyles) => {
        if (noStyles) {
            return "";
        }

        const dimensions = getDimensions(size, sizes);
        const fillRule =
            fillColor && fillColorRule
                ? `${fillColorRule}{ fill: ${fillColor}; }`
                : "";

        return css`
            width: ${dimensions.width}px;
            height: ${dimensions.height}px;
            ${fillRule}
        `;
    };

    const propsToCss = ({ size, sizes, fillColor, fillColorRule, noStyles }) =>
        getCss(size, sizes, fillColor, fillColorRule, noStyles);

    return {
        getCss,
        getDimensions,
        propsToCss,
        sanitizeSizes
    };
};

const width = "190";
const height = "190";
const viewBox = "0 0 190 190";

const { getDimensions, getCss, propsToCss, sanitizeSizes } = createHelpers(
    width,
    height,
    css
);

const sizes = sanitizeSizes({
    sm: { width: 98, height: 98 },
    md: { width: 101, height: 101 },
    lg: { width: 190, height: 190 }
});

const Image = styled.svg`
    ${propsToCss}
`;

const children = (
    <Fragment>
        <path
            fill={colors.secondary.orca}
            fillRule="evenodd"
            d="M113 23a3 3 0 013.5-2.959c.163-.027.33-.041.5-.041h14a3 3 0 110 6h-12v10h29c12.15 0 22 9.85 22 22v60a3 3 0 01-3 3h-58v46a3 3 0 01-3 3H84a3 3 0 01-3-3v-46H23a3 3 0 01-3-3V58c0-12.15 9.85-22 22-22h71V23zM48 42h-6c-8.837 0-16 7.163-16 16v57h38V58c0-8.837-7.163-16-16-16zm22 73V58a21.939 21.939 0 00-6.9-16H113v37a3 3 0 106 0V42h29c8.837 0 16 7.163 16 16v57H70zm17 6h16v43H87v-43z"
            clipRule="evenodd"
            key="key-0"
        />
    </Fragment>
);

const defaultProps = {
    children,
    viewBox,
    fillColor: null,
    fillColorRule: "&&& path, &&& use, &&& g",
    sizes,
    size: null
};

Image.propTypes /* remove-proptypes */ = {
    fillColor: PropTypes.string,
    fillColorRule: PropTypes.string,
    viewBox: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    size: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            height: PropTypes.number.isRequired,
            width: PropTypes.number.isRequired
        })
    ]),
    sizes: PropTypes.shape({
        height: PropTypes.number,
        width: PropTypes.number
    })
};

export default Object.assign(Image, {
    getDimensions,
    getCss,
    defaultProps,
    displayName: "Mailbox"
});
