import gql from "graphql-tag";

import { LIST_FILE_CHECK_FAILED_PHOTO_UPLOADS } from "frontend/hooks/usePhotoUpload/queries";
import { LIST_PHOTO_UPLOADS_IN_DROPZONE } from "../../queries";

import { apolloClient } from "js/apollo/apollo-client";

/* -------------------------------------------------------------------------- */
/*                               EVENT HANDLERS                               */
/* -------------------------------------------------------------------------- */

export const handleSuccessfulUploadEvent = data =>
    handleEvent(data, LIST_PHOTO_UPLOADS_IN_DROPZONE);

export const handleFailedUploadEvent = data =>
    handleEvent(data, LIST_FILE_CHECK_FAILED_PHOTO_UPLOADS);

export const handleWithdrawnUploadEvent = data => {
    const upload = data;
    let query;

    const previousUploadStatus = getPreviousUploadStatus(upload.id);

    if (previousUploadStatus === "IN_DROPZONE") {
        query = LIST_PHOTO_UPLOADS_IN_DROPZONE;
    } else {
        query = LIST_FILE_CHECK_FAILED_PHOTO_UPLOADS;
    }

    updateStatusInCachedUpload(upload);

    const cached = getCachedData(query) || { listPhotoUploads: { items: [] } };
    const { listPhotoUploads } = cached;
    const uploadList = listPhotoUploads.items;

    let items = [...uploadList];
    const uploadIsInCache = uploadList.some(item => item.id === upload.id);

    if (uploadIsInCache) {
        items = removeUploadFromList(upload, items);
    }

    updateCache(query, items);

    function getPreviousUploadStatus(uploadId) {
        const { status } = apolloClient.readFragment({
            id: `PhotoUploadType:${uploadId}`,
            fragment: gql`
                fragment ReadPhotoUploadTypeStatus on PhotoUploadType {
                    id
                    status
                }
            `
        });

        return status;
    }

    function updateStatusInCachedUpload(upload) {
        apolloClient.writeFragment({
            id: `PhotoUploadType:${upload.id}`,
            fragment: gql`
                fragment WritePhotoUploadTypeStatus${upload.id} on PhotoUploadType {
                    id
                    status
                    __typename: PhotoUploadType
                }
            `,
            data: {
                id: upload.id,
                status: upload.status,
                __typename: "PhotoUploadType"
            }
        });
    }
};

/* -------------------------------------------------------------------------- */
/*                                   HELPERS                                  */
/* -------------------------------------------------------------------------- */

/**
 * handleEvent
 * * updates Apollo cache with new data that streams
 * * in through websocket connection
 *
 * @param data data for individual upload that
 * comes back from server
 * @param query GraphQL query to be updated
 *
 * 1) gets existing cached list of uploads
 * 2) adds typename fields to incoming data
 * 2) checks if upload in question is already in cache
 * 3) if not in cache, adds upload data to cached list
 * and increments list total count
 * 4) if in cache, updates upload data in cached list
 * 5) writes updated data to cache
 * */

function handleEvent(data, query) {
    const upload = data;
    upload.__typename = "PhotoUploadType";
    upload.photoShoot.__typename = "PhotoShootUploadType";

    const cached = getCachedData(query) || { listPhotoUploads: { items: [] } };
    const { listPhotoUploads } = cached;
    const uploadList = listPhotoUploads.items;
    const uploadIsInCache = uploadList.some(item => item.id === upload.id);

    const updatedList = uploadIsInCache
        ? uploadList.map(item => (item.id === upload.id ? upload : item))
        : [...uploadList, upload];

    updateCache(query, updatedList);
}

function getCachedData(query) {
    // apollo client < 3.3 raises error if query is not in cache
    // apollo client 3.3+ returns null if query is not in cache
    let cached;
    try {
        cached = apolloClient.readQuery({
            query
        });
    } catch (err) {
        cached = null;
    }
    return cached;
}

function removeUploadFromList(upload, list) {
    return list.filter(item => item.id !== upload.id);
}

function updateCache(query, items) {
    apolloClient.writeQuery({
        query,
        data: {
            listPhotoUploads: {
                items,
                totalCount: items.length,
                __typename: "PhotoUploadsList"
            }
        }
    });
}
