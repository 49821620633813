import gql from "graphql-tag";

export const GET_LIST_RELEASES_IN_BATCH = gql`
    query GetListReleasesInBatch(
        $limit: Int
        $status: UploadStatus
        $photoBatchId: ID
    ) {
        listReleasesInModerationPhotoBatch(
            limit: $limit
            status: $status
            photoBatchId: $photoBatchId
        ) {
            items {
                id
                createdAt
                uploadedAt
                contentType
                filePath
                name: originalFilename
                status
                releaseType
                batchId
                downloadUrl
            }
            totalCount
        }
    }
`;
