import React from "react";
import styled from "styled-components";

// context
import { DropDown as DropDownContext } from "core/DropDown/index.js";

// components
import AuthProfile from "core/SiteHeaderV2/components/AuthProfile.js";
import LogoutButton from "core/SiteHeaderV2/components/LogoutButton.js";

//styles
import { convertPxToRem } from "frontend/components/helpers";
import colors from "core/colors";

const DropDownStyled = styled(DropDownContext)`
    z-index: 100;
    right: 0rem;
    /* top: ${convertPxToRem(42)}; */
    background-color: ${colors.primary.snow};
    box-shadow: 0rem 0rem ${convertPxToRem(15)} rgba(0, 0, 0, 0.5);
    width: ${convertPxToRem(300)};
    padding: ${convertPxToRem(64)} ${convertPxToRem(24)} ${convertPxToRem(24)};
`;

// component
const DropDown = () => {
    return (
        <DropDownStyled>
            <li>
                <AuthProfile />
            </li>
            <li>
                <LogoutButton />
            </li>
        </DropDownStyled>
    );
};

export default DropDown;
