import styled from "styled-components";

export const MastheadBannerContainer = styled.div`
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    text-align: right;
`;
