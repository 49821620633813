import React from "react";

// helpers
import { truncateText } from "core/TruncateText";

// format tag text and ariaLabel
const getTextStrings = ({ text, ariaLabel, isLoading, isTruncated }) => {
    // Default: Assume component and up to consumer to manage
    let textStrings = {
        text: text,
        truncated: text,
        ariaLabel
    };

    // All tags are lowercase
    textStrings.text = textStrings.text.toLowerCase();

    // While loading show Processing text
    textStrings.text = isLoading ? "Processing" : textStrings.text;

    // truncate if asked to do so.
    textStrings.truncated = isTruncated
        ? truncateText(textStrings.text, 50)
        : textStrings.text;

    // If rely on ariaLabel if provided, otherwise use text
    textStrings.ariaLabel = textStrings.ariaLabel || textStrings.text;

    return textStrings;
};

export const getLinkTextStrings = props => {
    let textStrings = getTextStrings(props);

    textStrings.ariaLabel = `See other tag results for "${textStrings.ariaLabel}" tag`;
    return textStrings;
};

export const getRemoveableTextStrings = props => {
    let textStrings = getTextStrings(props);

    const { isDisabled } = props;

    // we rely on lowercase `text` over `ariaLabel`, assuming consumer has passed a string for text
    textStrings.ariaLabel = `Delete "${textStrings.ariaLabel}" tag`;
    if (isDisabled) {
        textStrings.ariaLabel = `${textStrings.ariaLabel} is disabled`;
    }
    return textStrings;
};
