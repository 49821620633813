import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import debounce from "lodash/debounce";

// context
import { useManageTags } from "core/ManageTags/context/useManageTags.js";

// styles
const InputStyled = styled.div``;

// components
import { InputWithIcon } from "core/FormControls";
import Tag from "core/ReusableIcons/Tag.js";

// component
export const Input = () => {
    const useManageTagsContext = useManageTags();

    const {
        id,
        placeholder,
        inputRef,
        hideLabel,
        actions,
        dispatch,
        suggestions,
        state: { errorMessage, inputText, showSuggestionDropDown, enabled }
    } = useManageTagsContext;

    // debounce: delay and aggregate calls by half a second
    const debounceOnSuggest = useRef(debounce(actions.onSuggest, 500));

    const onChange = e => {
        const userInput = e.target.value;
        dispatch(actions.setInputText(userInput));
        // we disable once they have chosen a suggestion, this starts it back up again
        dispatch(actions.setEnabled({ suggestions: true }));
        // remove error message whenver user starts typing again
        dispatch(actions.setErrorMessage(""));

        if (userInput.length > 2) {
            debounceOnSuggest.current(useManageTagsContext, userInput);
        } else {
            dispatch(actions.setShowSuggestionDropDown(false));
        }
    };

    const onFocus = () => {
        if (
            !showSuggestionDropDown &&
            suggestions.length > 0 &&
            enabled.suggestions &&
            inputText.length > 2
        ) {
            dispatch(actions.setShowSuggestionDropDown(true));
        }
    };

    // side effects
    useEffect(() => {
        return () => {
            debounceOnSuggest.current.cancel();
        };
    }, [debounceOnSuggest]);

    return (
        <InputStyled data-testid={`add-tag-input-${id}`}>
            <InputWithIcon
                controlId={`add-tag-${id}`}
                inputRef={inputRef}
                onChange={onChange}
                value={inputText}
                placeholder={placeholder}
                hideLabel={hideLabel}
                errorMessage={errorMessage}
                disabled={!enabled.add}
                aria-autocomplete="list"
                aria-controls={`add-tag-suggestions-${id}`}
                onFocus={onFocus}
            >
                <Tag size={{ width: 14, height: 14 }} />
            </InputWithIcon>
        </InputStyled>
    );
};

export default Input;
