import { createListenerMiddleware, isAnyOf } from "@reduxjs/toolkit";
import { batchUploadCollectionsSlice } from "frontend/components/fort/mod/photos/slices/batchUploadCollectionsSlice/index.js";

// Action Creator Listeners
import {
    setBatchUploads,
    updateUpload,
    updateUploads,
    uploadStatusesChanged
} from "frontend/components/fort/mod/photos/slices/uploadsSlice/index.js";

export const uploadListenerMiddleware = createListenerMiddleware();

uploadListenerMiddleware.startListening({
    matcher: isAnyOf(
        setBatchUploads,
        updateUpload,
        updateUploads,
        uploadStatusesChanged
    ),
    effect: async (action, listenerApi) => {
        const { uploads, selectedBatch, currentQueue } = listenerApi.getState();
        const { id: selectedBatchId } = selectedBatch;
        const { ids, entities } = uploads;
        const currentQueueName = currentQueue.name;

        listenerApi.dispatch(
            batchUploadCollectionsSlice.actions.setBatchUploadCollections({
                ids,
                entities,
                selectedBatchId,
                currentQueueName
            })
        );
    }
});
