/**
 * Report firing of specific actions to analytics endpoint.
 */

import * as actionTypes from "../actions/actionTypes";
import getCookie from "../helpers/get_cookie";
import metrics from "../legacy/helpers/metrics";
import { getChangedTransforms } from "../actions/editor";

import isFunction from "lodash/isFunction";
import forEach from "lodash/forEach";
import find from "lodash/find";

global.ga = global.ga || (() => {});
const Metrics = new metrics();

// Map action type to event name
const actionEvents = {
    [actionTypes.DOWNLOAD_COMPLETE_SUCCESS]: Metrics.download,
    [actionTypes.EXPERIMENTS_SET]: Metrics.set_experiments_dimension,
    [actionTypes.GET_CURRENT_USER_SUCCESS]: Metrics.set_user_dimension,
    [actionTypes.DOWNLOAD_UPSELL_REJECTED]: Metrics.upsell_result.bind(
        null,
        "rejected"
    ),
    [actionTypes.DOWNLOAD_UPSELL_ACCEPTED]: Metrics.upsell_result.bind(
        null,
        "accepted"
    ),
    [actionTypes.DOWNLOAD_PURCHASE_SUCCESS]:
        Metrics.download_flow_purchase_success,
    [actionTypes.DOWNLOAD_PURCHASE_FAILURE]:
        Metrics.download_flow_purchase_failure,
    [actionTypes.SET_ICON_COLOR_PALETTE]: Metrics.download_flow_color_select,
    [actionTypes.DOWNLOAD_START_FAILURE]: Metrics.download_failed,
    [actionTypes.DOWNLOAD_COMPLETE_FAILURE]: Metrics.download_failed,
    [actionTypes.EDITOR_TRANSFORM]: Metrics.editor_transform,
    [actionTypes.EDITOR_EXPORT_SUCCESS]: Metrics.editor_export,
    [actionTypes.EDITOR_EXPORT_FAILURE]: Metrics.editor_export_failed,
    [actionTypes.DOWNLOAD_SELECT_PURCHASE_TYPE]: Metrics.send_event,
    [actionTypes.UPGRADE_BILLING_SUCCESS]: Metrics.upgrade,
    [actionTypes.UPGRADE_BILLING_FAILURE]: Metrics.failed_upgrade,
    [actionTypes.SAVE_CARD_FAILURE]: Metrics.failed_cardsave,
    [actionTypes.DOWNLOAD_SET_FLOW_ACTIVE]: Metrics.icon_hero,
    [actionTypes.SEARCH_ICONS_SUCCESS]: Metrics.report_empty_searches,
    [actionTypes.PAGEVIEW]: Metrics.send_pageview
};

const actionRegex = {
    DOWNLOAD_ENTER_: Metrics.download_flow_pageview
};

// Attributes to copy from action object to event properties object
const actionProperties = {
    DOWNLOAD_ENTER_: (action, store) => {
        let { download } = store.getState();
        return [download.status, download.phaseJumping];
    },
    [actionTypes.DOWNLOAD_COMPLETE_SUCCESS]: [
        "downloadType",
        "fileType",
        "icon"
    ],
    [actionTypes.PAGEVIEW]: ["path", "meta"],
    [actionTypes.EXPERIMENTS_SET]: ["experiments"],
    [actionTypes.GET_CURRENT_USER_SUCCESS]: action => {
        return [action.response];
    },
    [actionTypes.DOWNLOAD_COMMIT_LICENSE]: (action, store) => {
        let { download } = store.getState();
        return [download.license];
    },
    [actionTypes.DOWNLOAD_PURCHASE_SUCCESS]: (action, store) => {
        let { stripe } = store.getState();

        let { currentPurchaseKey, purchaseTypes } = stripe;
        let currentPurchase = find(purchaseTypes, { key: currentPurchaseKey });

        return [currentPurchase];
    },
    [actionTypes.DOWNLOAD_PURCHASE_FAILURE]: (action, store) => {
        let { stripe, user } = store.getState();

        let { currentPurchaseKey, purchaseTypes } = stripe;
        let currentPurchase = find(purchaseTypes, { key: currentPurchaseKey });

        return [user.id, currentPurchase, action.error];
    },
    [actionTypes.SET_ICON_COLOR_PALETTE]: (action, store) => {
        let { download } = store.getState();
        return [
            download,
            action.color &&
                action.color.selectedColor &&
                action.color.selectedColor.hex
        ];
    },
    [actionTypes.DOWNLOAD_START_FAILURE]: ["downloadType", "icon"],
    [actionTypes.DOWNLOAD_COMPLETE_FAILURE]: ["downloadType", "icon"],

    [actionTypes.EDITOR_TRANSFORM]: ["transform", "data"],
    [actionTypes.EDITOR_EXPORT_SUCCESS]: (action, store) => {
        let {
            editor: { transforms, changed }
        } = store.getState();
        let { format } = action;
        return [format, getChangedTransforms(changed, transforms)];
    },
    [actionTypes.EDITOR_EXPORT_FAILURE]: (action, store) => {
        let {
            editor: { transforms }
        } = store.getState();
        let { format } = action;
        return [format, transforms];
    },
    [actionTypes.DOWNLOAD_SELECT_PURCHASE_TYPE]: action => {
        return ["download", "purchaseType", action.purchaseTypeKey];
    },
    [actionTypes.UPGRADE_BILLING_SUCCESS]: action => {
        return [
            {
                subscription: {
                    plan: {
                        type: action.plan,
                        name: action.plan
                    }
                }
            }
        ];
    },
    [actionTypes.UPGRADE_BILLING_FAILURE]: action => {
        let { error } = action;
        if (error && error.responseText) {
            error = error.responseText;
        }
        return [action.userId, error];
    },
    [actionTypes.SAVE_CARD_FAILURE]: action => {
        let { error } = action;
        if (error && error.responseText) {
            error = error.responseText;
        }
        return [action.userId, error];
    },
    [actionTypes.DOWNLOAD_SET_FLOW_ACTIVE]: (action, store) => {
        let { download } = store.getState();
        let { phaseJumping } = download;

        let { icon } = action;
        if (!icon) {
            return [];
        }
        return [icon.id, icon.term, phaseJumping];
    },
    [actionTypes.SEARCH_ICONS_SUCCESS]: (action, store) => {
        let { response } = action;
        if (response) {
            let firstPage = action.page == 1;
            let isEmpty = action.response.search_total == 0;
            let searchTerm = response.query || action.searchQuery;
            if (firstPage && isEmpty) {
                return [searchTerm, true];
            }
        }
    }
};

const analytics = store => next => action => {
    if (!action) {
        return next(action);
    }

    let actionType = action.type;
    let eventName = actionEvents[actionType];

    forEach(actionRegex, (f, regex) => {
        if (new RegExp(regex).test(actionType)) {
            eventName = f;
            actionType = regex;
            return false;
        }
    });

    if (!eventName) return next(action);

    let eventProperties = actionProperties[actionType],
        properties = [];

    if (eventProperties) {
        if (isFunction(eventProperties)) {
            properties = eventProperties(action, store);
        } else {
            eventProperties.forEach(prop => {
                properties.push(action[prop]);
            });
        }
    }

    if (isFunction(eventName)) {
        eventName.apply(null, properties);
    }

    return next(action);
};

export default analytics;
