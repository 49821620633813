import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

// context
import { QueueProvider } from "frontend/components/fort/mod/core/Layouts/QueueLayout";

// hooks
import { useBatches, LoadMoreButton } from "./BatchesProvider/index.js";
import { useBatchUploads } from "../../../hooks";

// data
import { LICENSE_MAPPINGS } from "frontend/components/fort/mod/photos/constants";

// TODO: This should be where we translate normalized props
// e.g. photo uploads translating photoUploadId to uploadId, title, description etc.
// these would never be added to a reducer state, which QueueProvider does not do.
// rather they would render translate
// this way Photo Mod Redux Store stays pure. Photo Upload objects are 1:1 with Backend DB.
// QueueProvider should only be used within Fort Mod Core

// component
export const PhotoModQueueProvider = ({ children }) => {
    // hooks
    const { selectedBatch, uploads: uploadsObject } = useSelector(
        state => state
    );

    const { visibleUploadIds } = useBatchUploads();

    const batchesObject = useBatches();

    return (
        <QueueProvider
            batchesObject={batchesObject}
            LoadMoreButton={LoadMoreButton}
            uploadsObject={uploadsObject}
            selectedBatch={selectedBatch}
            uploadType="photo"
            licenseMappings={LICENSE_MAPPINGS}
            visibleUploadIds={visibleUploadIds}
        >
            {children}
        </QueueProvider>
    );
};

export default PhotoModQueueProvider;

// prop-types
PhotoModQueueProvider.propTypes = {
    children: PropTypes.any
};
