import React from "react";
import PropTypes from "prop-types";

import IconGrid from "icons/IconGrid";
import SearchAndBrowseIconGridWithAds from "./SearchAndBrowseIconGridWithAds";

import breakpoints from "core/breakpoints";

import { convertPxToRem } from "frontend/components/helpers";
import { useCurrentUser } from "js/contexts/CurrentUserContext";
import { useViewport } from "js/hooks/useViewport";

import { formatDataToAddSkeletonItems } from "./helpers";

const SearchAndBrowseIconGridContainer = ({
    iconData,
    loadingFirstBatch,
    loadingMore,
    LoadMoreButton,
    totalCount,
    loadingItemCount
}) => {
    const { browserWidth } = useViewport();
    const onDesktop = browserWidth >= breakpoints.desktop.px;
    const onTablet =
        browserWidth < breakpoints.desktop.px &&
        browserWidth >= breakpoints.tablet.px;
    const onMobile = browserWidth < breakpoints.tablet.px;

    const user = useCurrentUser();
    const userHasSubscription = user && user.hasSubscription;
    const Grid = userHasSubscription
        ? IconGrid
        : SearchAndBrowseIconGridWithAds;

    let numberOfColumns;
    let gridSpacingSize;

    if (onDesktop) {
        numberOfColumns = 5;
        gridSpacingSize = convertPxToRem({ px: 24 });
    }

    if (onTablet) {
        numberOfColumns = 4;
        gridSpacingSize = convertPxToRem({ px: 24 });
    }

    if (onMobile) {
        numberOfColumns = 2;
        gridSpacingSize = convertPxToRem({ px: 16 });
    }

    const allItemsLoaded = iconData && iconData.length >= totalCount;
    const showLoadMoreButton = !allItemsLoaded && !!iconData && iconData.length;

    return (
        <>
            <Grid
                iconData={formatDataToAddSkeletonItems({
                    iconData,
                    loadingFirstBatch,
                    loadingItemCount,
                    loadingMore
                })}
                numberOfColumns={numberOfColumns}
                gridSpacingSize={gridSpacingSize}
                useRouterLink={true}
            />
            {showLoadMoreButton && <LoadMoreButton />}
        </>
    );
};

SearchAndBrowseIconGridContainer.propTypes = {
    iconData: PropTypes.array.isRequired,
    loadingFirstBatch: PropTypes.bool.isRequired,
    loadingMore: PropTypes.bool.isRequired,
    totalCount: PropTypes.number.isRequired,
    LoadMoreButton: PropTypes.func.isRequired,
    loadingItemCount: PropTypes.number.isRequired
};

export default SearchAndBrowseIconGridContainer;
