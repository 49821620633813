import { useEffect } from "react";
import gql from "graphql-tag";
import { apolloClient } from "js/apollo/apollo-client";

// hooks
import { useWebSocket } from "frontend/hooks/useWebSocket.js";
import { usePhotoUpload } from "frontend/hooks/usePhotoUpload";

import {
    handleSuccessfulUploadEvent,
    handleFailedUploadEvent,
    handleWithdrawnUploadEvent
} from "./helpers";
import { removeOriginalFilenamePrefix } from "../../helpers";

const DEBUG_MODE = false;

const uploadStatus = {
    // waiting for server to validate the file
    AWAITING_FILE_CHECK: "AWAITING_FILE_CHECK",
    FILE_CHECK_IN_PROGRESS: "FILE_CHECK_IN_PROGRESS",
    // user has withdrawn the upload
    WITHDRAWN_BEFORE_SUBMISSION: "WITHDRAWN_BEFORE_SUBMISSION",
    // unable to upload file to S3
    UPLOAD_FAILED: "UPLOAD_FAILED",
    // server has determined the file is invalid
    FILE_INVALID: "FILE_INVALID",
    FILE_CHECK_FAILED: "FILE_CHECK_FAILED",
    // upload ready to be submitted
    IN_DROPZONE: "IN_DROPZONE"
};

const failedStatuses = [
    uploadStatus.UPLOAD_FAILED,
    uploadStatus.FILE_INVALID,
    uploadStatus.FILE_CHECK_FAILED
];

const completeStatuses = [
    uploadStatus.AWAITING_FILE_CHECK,
    uploadStatus.IN_DROPZONE
];

function getCurrentTimestamp(uploadId) {
    const result = apolloClient.readFragment({
        id: `PhotoUploadType:${uploadId}`,
        fragment: gql`
            fragment ReadPhotoUploadTypeUpdatedAt on PhotoUploadType {
                id
                updatedAt
            }
        `
    });

    return result?.updatedAt || null;
}

const uploadTimestamps = {};

const usePhotoUploadWebSocket = ({
    reconnectInterval = 5000,
    reconnectAttempts = 20
} = {}) => {
    const { removeUploads, refetchUploadsData } = usePhotoUpload();
    const { lastJsonMessage } = useWebSocket({
        endpoint: "websockets/photo-uploads/by-creator/",
        options: {
            reconnectInterval,
            reconnectAttempts
        },
        // if the connection closed, and then reopens, we may have missed some events
        onCloseAndReopen: () => {
            refetchUploadsData();
        }
    });

    useEffect(() => {
        if (!lastJsonMessage) return;

        const { id, updatedAt, status, originalFilename } = lastJsonMessage;

        if (DEBUG_MODE) {
            console.warn("Received message", {
                id,
                originalFilename,
                status
            });
        }

        const currentTimestampApollo = getCurrentTimestamp(id);
        const currentTimestampLocal = uploadTimestamps[id] || null;
        const currentTimestamp = [
            currentTimestampApollo,
            currentTimestampLocal
        ].toSorted()[1];

        if (currentTimestamp && updatedAt <= currentTimestamp) {
            if (DEBUG_MODE) {
                console.info(
                    "Ignoring outdated message",
                    `${updatedAt} <= ${currentTimestamp}`,
                    lastJsonMessage
                );
            }
            return;
        }

        if (uploadStatus.WITHDRAWN_BEFORE_SUBMISSION === status) {
            handleWithdrawnUploadEvent(lastJsonMessage);
        }

        if (failedStatuses.includes(status)) {
            removeUploads([removeOriginalFilenamePrefix(originalFilename)]);
            handleFailedUploadEvent(lastJsonMessage);
        }

        if (completeStatuses.includes(status)) {
            handleSuccessfulUploadEvent(lastJsonMessage);
            removeUploads([originalFilename]);
        }

        uploadTimestamps[id] = updatedAt;
    }, [lastJsonMessage]);

    return null;
};

export default usePhotoUploadWebSocket;
