import breakpoints from "core/breakpoints";

export const getIconsArrays = ({ allIcons, isHomepage, browserWidth }) => {
    if (!allIcons) return null;

    let iconsArrays = [];

    if (isHomepage) {
        // only considers 14 total icons for home page:
        // make array of arrays:
        iconsArrays = [allIcons.slice(0, 7), allIcons.slice(7, 14)];
    } else {
        // only considers 10 total icons for icons landing page:
        const isMobile = browserWidth <= breakpoints.beforeTablet.px;

        if (isMobile) {
            iconsArrays = [
                allIcons.slice(0, 2),
                allIcons.slice(2, 5),
                allIcons.slice(5, 7),
                allIcons.slice(7, 10)
            ];
        } else {
            iconsArrays = [
                allIcons.slice(0, 2),
                allIcons.slice(2, 4),
                allIcons.slice(4, 6),
                allIcons.slice(6, 8),
                allIcons.slice(8, 10)
            ];
        }
    }
    return iconsArrays;
};
