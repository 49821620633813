import styled, { css } from "styled-components";

// styles
import { regular as regularFontFamily } from "core/fontStyles";
import { easingSeconds } from "core/animation";
import { focusOutline } from "core/links";
import colors, { focusColor } from "core/colors";

import Processing from "core/ReusableIcons/Processing";

// helpers
import { convertPxToRem } from "frontend/components/helpers";
import { handleBoxShadowOutlineBorder } from "./helpers";

// reusable constants:
export const SIZE_TYPES = {
    XS: css`
        height: ${convertPxToRem({ px: 24 })};
        font-size: ${convertPxToRem({ px: 13 })};
        line-height: ${convertPxToRem({ px: 13 })};
        padding-left: ${convertPxToRem({ px: 6 })};
        padding-right: ${convertPxToRem({ px: 6 })};

        svg {
            top: 0;
        }
    `,

    SM: css`
        height: ${convertPxToRem({ px: 28 })};
        font-size: ${convertPxToRem({ px: 13 })};
        line-height: ${convertPxToRem({ px: 13 })};
        padding-left: ${convertPxToRem({ px: 8 })};
        padding-right: ${convertPxToRem({ px: 8 })};

        svg {
            top: 0;
        }
    `,

    MD: css`
        height: ${convertPxToRem({ px: 40 })};
        font-size: ${convertPxToRem({ px: 13 })};
        line-height: ${convertPxToRem({ px: 13 })};
        padding-left: ${convertPxToRem({ px: 14 })};
        padding-right: ${convertPxToRem({ px: 14 })};

        ${Processing} {
            top: ${convertPxToRem({ px: 1 })};
        }
    `,

    LG: css`
        height: ${convertPxToRem({ px: 48 })};
        font-size: ${convertPxToRem({ px: 15 })};
        line-height: ${convertPxToRem({ px: 15 })};
        padding-left: ${convertPxToRem({ px: 16 })};
        padding-right: ${convertPxToRem({ px: 16 })};

        ${Processing} {
            top: ${convertPxToRem({ px: 1 })};
        }
    `
};

export const STYLE_MODES = {
    SOLID: {
        DARKMODE: css`
            ${({ isToggled, size }) => css`
                background: ${colors.primary.diamond};
                color: ${colors.secondary.cosmic};

                svg {
                    path,
                    use,
                    g,
                    circle {
                        fill: ${colors.secondary.orca};
                    }
                }

                ${isToggled &&
                    css`
                        background: transparent;
                        color: ${colors.primary.diamond};

                        box-shadow: ${handleBoxShadowOutlineBorder({
                            size,
                            color: colors.primary.diamond
                        })};

                        svg {
                            path,
                            use,
                            g,
                            circle {
                                fill: ${colors.primary.diamond};
                            }
                        }
                    `}

                &:hover {
                    background: ${colors.secondary.basalt};
                    box-shadow: none;
                    color: ${colors.primary.diamond};

                    svg {
                        path,
                        use,
                        g,
                        circle {
                            fill: ${colors.primary.diamond};
                        }
                    }
                }
            `}
        `,
        LIGHTMODE: css`
            ${({ isToggled, size }) => css`
                background: ${colors.secondary.orca};
                color: ${colors.primary.diamond};

                svg {
                    path,
                    use,
                    g,
                    circle {
                        fill: ${colors.primary.diamond};
                    }
                }

                ${isToggled &&
                    css`
                        background: transparent;
                        color: ${colors.secondary.orca};

                        box-shadow: ${handleBoxShadowOutlineBorder({
                            size,
                            color: colors.secondary.orca
                        })};

                        svg {
                            path,
                            use,
                            g,
                            circle {
                                fill: ${colors.secondary.orca};
                            }
                        }
                    `}

                &:hover {
                    background: ${colors.secondary.slate};
                    box-shadow: none;

                    svg {
                        path,
                        use,
                        g,
                        circle {
                            fill: ${colors.primary.diamond};
                        }
                    }
                }
            `}
        `
    },
    OUTLINE: {
        DARKMODE: css`
            ${({ size, isToggled }) => css`
                background: transparent;
                color: ${colors.primary.diamond};

                box-shadow: ${handleBoxShadowOutlineBorder({
                    size,
                    color: colors.secondary.basalt
                })};

                svg {
                    path,
                    use,
                    g,
                    circle {
                        fill: ${colors.primary.diamond};
                    }
                }

                ${isToggled &&
                    css`
                        background: ${colors.primary.diamond};
                        color: ${colors.secondary.orca};
                        box-shadow: none;

                        svg {
                            path,
                            use,
                            g,
                            circle {
                                fill: ${colors.secondary.orca};
                            }
                        }
                    `}

                &:hover {
                    background: ${colors.secondary.basalt};
                    color: ${colors.primary.diamond};

                    box-shadow: ${handleBoxShadowOutlineBorder({
                        size,
                        color: "transparent"
                    })};
                }

                &:focus {
                    box-shadow: ${handleBoxShadowOutlineBorder({
                        size,
                        color: colors.secondary.basalt,
                        hasFocusShadow: true
                    })};
                }
            `}
        `,
        LIGHTMODE: css`
            ${({ size, isToggled }) => css`
                background: transparent;
                color: ${colors.secondary.orca};

                box-shadow: ${handleBoxShadowOutlineBorder({
                    size,
                    color: colors.primary.scheifer
                })};

                svg {
                    path,
                    use,
                    g,
                    circle {
                        fill: ${colors.secondary.orca};
                    }
                }

                ${isToggled &&
                    css`
                        background: ${colors.secondary.orca};
                        color: ${colors.primary.diamond};
                        box-shadow: none;

                        svg {
                            path,
                            use,
                            g,
                            circle {
                                fill: ${colors.primary.diamond};
                            }
                        }
                    `}

                &:hover {
                    background: ${colors.primary.moon};
                    color: ${colors.secondary.orca};
                    box-shadow: ${handleBoxShadowOutlineBorder({
                        size,
                        color: colors.accent.darkMode.suenosBuenos
                    })};
                }

                &:focus {
                    box-shadow: ${handleBoxShadowOutlineBorder({
                        size,
                        color: colors.primary.scheifer,
                        hasFocusShadow: true
                    })};
                }
            `}
        `
    },
    CTA: {
        DARKMODE: css`
            ${({ size, isToggled }) => css`
                background: ${colors.accent.darkMode.suenosBuenos};
                color: ${colors.secondary.orca};

                svg {
                    path,
                    use,
                    g,
                    circle {
                        fill: ${colors.secondary.orca};
                    }
                }

                ${isToggled &&
                    css`
                        background: transparent;
                        box-shadow: ${handleBoxShadowOutlineBorder({
                            size,
                            color: colors.accent.darkMode.suenosBuenos
                        })};
                        color: ${colors.accent.darkMode.suenosBuenos};

                        svg {
                            path,
                            use,
                            g,
                            circle {
                                fill: ${colors.accent.darkMode.suenosBuenos};
                            }
                        }
                    `}

                &:hover {
                    background: ${colors.accent.darkMode.floridaWater};
                    color: ${colors.primary.diamond};

                    ${isToggled &&
                        css`
                            box-shadow: none;
                        `}

                    svg {
                        path,
                        use,
                        g,
                        circle {
                            fill: ${colors.primary.diamond};
                        }
                    }
                }
            `}
        `,
        LIGHTMODE: css`
            ${({ size, isToggled }) => css`
                background: ${colors.accent.lightMode.persian};
                color: ${colors.primary.diamond};

                svg {
                    path,
                    use,
                    g,
                    circle {
                        fill: ${colors.primary.diamond};
                    }
                }

                ${isToggled &&
                    css`
                        background: transparent;
                        box-shadow: ${handleBoxShadowOutlineBorder({
                            size,
                            color: colors.accent.lightMode.persian
                        })};
                        color: ${colors.accent.lightMode.persian};

                        svg {
                            path,
                            use,
                            g,
                            circle {
                                fill: ${colors.accent.lightMode.persian};
                            }
                        }
                    `}

                &:hover {
                    background: ${colors.accent.lightMode.pansy};
                    color: ${colors.primary.diamond};

                    ${isToggled &&
                        css`
                            box-shadow: none;
                        `}

                    svg {
                        path,
                        use,
                        g,
                        circle {
                            fill: ${colors.primary.diamond};
                        }
                    }
                }
            `}
        `
    },
    TEXT: {
        DARKMODE: css`
            background: transparent;
            color: ${colors.primary.diamond};

            svg {
                path,
                use,
                g,
                circle {
                    fill: ${colors.primary.diamond};
                }
            }

            &:hover {
                background: ${colors.secondary.basalt};
                color: ${colors.primary.diamond};

                svg {
                    path,
                    use,
                    g,
                    circle {
                        fill: ${colors.primary.diamond};
                    }
                }
            }
        `,
        LIGHTMODE: css`
            background: transparent;
            color: ${colors.secondary.oolong};

            svg {
                path,
                use,
                g,
                circle {
                    fill: ${colors.secondary.oolong};
                }
            }

            &:hover {
                background: ${colors.primary.igloo};
                color: ${colors.secondary.orca};

                svg {
                    path,
                    use,
                    g,
                    circle {
                        fill: ${colors.secondary.orca};
                    }
                }
            }
        `
    },
    NONE: {
        DARKMODE: css`
            background: transparent;
            color: ${colors.primary.diamond};
            height: auto;
            padding: 0;

            svg {
                path,
                use,
                g,
                circle {
                    fill: ${colors.primary.diamond};
                }
            }

            &:hover {
                background: transparent;
                color: ${colors.primary.diamond};

                svg {
                    path,
                    use,
                    g,
                    circle {
                        fill: ${colors.primary.diamond};
                    }
                }
            }
        `,
        LIGHTMODE: css`
            background: transparent;
            color: ${colors.secondary.oolong};
            height: auto;
            padding: 0;

            svg {
                path,
                use,
                g,
                circle {
                    fill: ${colors.secondary.oolong};
                }
            }

            &:hover {
                background: transparent;
                color: ${colors.secondary.orca};

                svg {
                    path,
                    use,
                    g,
                    circle {
                        fill: ${colors.secondary.orca};
                    }
                }
            }
        `
    }
};

const ACTIVE_STYLE = ({ darkMode }) => css`
    background: ${darkMode ? colors.secondary.vampire : colors.primary.whisker};
    color: ${darkMode ? colors.primary.diamond : colors.secondary.orca};
    box-shadow: none;
    border: none;
    outline: none;

    svg {
        path,
        use,
        g,
        circle {
            transition: all ${easingSeconds};
            fill: ${darkMode ? colors.primary.diamond : colors.secondary.orca};
        }
    }

    &:hover {
        background: ${darkMode
            ? colors.primary.whisker
            : colors.secondary.vampire};
        color: ${darkMode ? colors.secondary.orca : colors.primary.diamond};
        box-shadow: none;
        border: none;
        outline: none;

        svg {
            path,
            use,
            g,
            circle {
                fill: ${darkMode
                    ? colors.secondary.orca
                    : colors.primary.diamond};
            }
        }
    }
`;

const PROCESSING_STYLE = ({ darkMode, processingIconPosition }) => css`
    background: ${darkMode ? colors.secondary.vampire : colors.primary.whisker};
    color: ${darkMode ? colors.primary.diamond : colors.secondary.orca};

    ${Processing} {
        ${processingIconPosition === "left"
            ? css`
                  margin-left: 0;
                  margin-right: ${convertPxToRem({ px: 8 })};
              `
            : css`
                  margin-left: ${convertPxToRem({ px: 8 })};
                  margin-right: 0;
              `}

        path {
            fill: ${darkMode ? colors.primary.diamond : colors.secondary.orca};
        }
    }
`;

const ERROR_STYLE = ({ size, darkMode }) => {
    const errorColor = darkMode
        ? colors.functional.darkMode.desertBloom
        : colors.functional.lightMode.sangria;

    return css`
        /* only target the default state, not hover, focus, or active or disabled etc */
        &:not(&:hover, &:focus) {
            border-color: ${errorColor};
            color: ${errorColor};
            background: transparent;

            box-shadow: ${handleBoxShadowOutlineBorder({
                size,
                color: errorColor
            })};
        }
    `;
};

export const ButtonDefaults = css`
    ${regularFontFamily}
    border: none;
    background: none;
    text-decoration: none;
    padding: 0;
    margin: 0;
    transition: all ${easingSeconds};
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    white-space: nowrap;

    width: ${({ width }) => (!!width ? width : "auto")};

    justify-content: ${({ justifyContent }) => justifyContent};

    border-radius: ${({ isPill }) => convertPxToRem({ px: isPill ? 48 : 4 })};

    svg {
        position: relative;
        top: ${convertPxToRem({ px: 1 })};
        width: ${convertPxToRem({ px: 12 })};
        height: ${convertPxToRem({ px: 12 })};
        ${({ iconPosition, isProcessing }) =>
            iconPosition === "left" && !isProcessing
                ? css`
                      margin-right: ${convertPxToRem({ px: 8 })};
                  `
                : css`
                      margin-left: ${convertPxToRem({ px: 8 })};
                  `};

        path,
        use,
        g,
        circle {
            transition: all ${easingSeconds};
        }
    }

    &:focus {
        transition: all ${easingSeconds};
        ${focusOutline};
    }

    // set size:
    // if no size defined, use LG size
    ${({ size }) => (size ? SIZE_TYPES[size.toUpperCase()] : SIZE_TYPES.LG)}

    // set styleType:
    // if no styleType defined, use solid:
    ${({ styleType, darkMode }) =>
        styleType
            ? darkMode
                ? STYLE_MODES[styleType.toUpperCase()].DARKMODE
                : STYLE_MODES[styleType.toUpperCase()].LIGHTMODE
            : darkMode
            ? STYLE_MODES.SOLID.DARKMODE
            : STYLE_MODES.SOLID.LIGHTMODE}

    // error: override styleType default style (not hover/not active/not etc):
    ${({ isError, size, darkMode }) =>
        isError && ERROR_STYLE({ size, darkMode })}

`;

export const AllButtonStyles = css`
     ${ButtonDefaults}

    /* active, isDisabled, isProcessing- stays last so they override properties such as background color, color, etc */
    ${({ isActive }) => isActive && ACTIVE_STYLE}

    &:active {
        ${ACTIVE_STYLE}
    }

    ${({ isDisabled }) =>
        isDisabled &&
        css`
            pointer-events: none;
            background: ${colors.primary.whisker};
            color: ${colors.secondary.dolphin};
            border: none;
            box-shadow: none;
            outline: none;
        `}

    ${({ isProcessing, darkMode, processingIconPosition }) =>
        isProcessing && PROCESSING_STYLE({ darkMode, processingIconPosition })}
`;

// the actual Button-type element:
export const StyledButton = styled.span`
    &,
    &:visited {
        ${AllButtonStyles}
    }
`;

export const UnstyledButton = styled.button`
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    font: inherit; /* Inherit font styles */
    color: inherit; /* Inherit text color */
    cursor: pointer;
    outline: none;
    appearance: none; /* Removes default styling in some browsers */

    &:focus {
        outline: 3px solid ${focusColor};
    }
`;
