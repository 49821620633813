import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { useLazyQuery } from "@apollo/client";

import Heading from "core/Heading";
import ScrollableTable from "core/ScrollableTable";
import Tooltip from "core/Tooltip";
import Question from "core/ReusableIcons/Question";
import InsightsModal from "./InsightsModal";

import { GET_MONTHLY_ROYALTY_INSIGHTS } from "./queries";
import { CREATIVE_PRO_ROYALTIES } from "frontend/data/featureFlags";

import useFeatureFlag from "frontend/hooks/useFeatureFlag";

const TooltipContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 0.25rem;
`;

const headerData = [
    { title: "Month" }, // month
    { title: "Earned" }, // totalEarned
    { title: "Paid" }, // totalPaid
    { title: "Single Photo Purchases" }, // fullResSales, largeSales, standardPhotoSales, premiumPhotoSales
    {
        title: (
            <TooltipContainer>
                <div>Comps</div>
                <Tooltip message="Low-res downloads (CC BY-NC-ND 2.0 License, user must attribute), or Public Domain photos.">
                    <Question size="sm" />
                </Tooltip>
            </TooltipContainer>
        )
    }, // freeDownloadCount
    { title: "Details" } // insightExists
];

const formatDollars = ({ value }) => {
    const val = parseFloat(value);
    if (!val) {
        return "--";
    } else {
        return "$" + value;
    }
};

const ScrollableTableMemoized = React.memo(
    ({ tableHeaderData, tableBodyData, tableAriaLabel }) => {
        return (
            <ScrollableTable
                tableHeaderData={tableHeaderData}
                tableBodyData={tableBodyData}
                tableAriaLabel={tableAriaLabel}
            />
        );
    },
    (prevProps, nextProps) => {
        let prev = prevProps.tableBodyData.map(report => report.month);
        let next = nextProps.tableBodyData.map(report => report.month);
        return JSON.stringify(prev) === JSON.stringify(next);
    }
);

const MonthlyReport = ({ statements, username }) => {
    const [isModalOpen, setIsOpenModal] = useState(false);
    const showCreatorPro = useFeatureFlag(CREATIVE_PRO_ROYALTIES);

    const [
        getMonthlyInsights,
        { data: insightData, loading: insightLoading }
    ] = useLazyQuery(GET_MONTHLY_ROYALTY_INSIGHTS);

    const modalData = insightData ? insightData : false;

    if (statements.items.length === 0) return null;

    if (
        showCreatorPro &&
        !headerData.some(({ title }) => title === "Creator Pro Downloads")
    ) {
        headerData.splice(3, 0, { title: "Creator Pro Downloads" });
    }

    const openModalWithInsights = ({ month }) => {
        setIsOpenModal(true);
        getMonthlyInsights({ variables: { month, username } });
    };

    const formatData = statements.items.map(invoice => {
        const {
            month,
            totalEarned,
            totalPaid,
            fullResSales = 0,
            largeSales = 0,
            standardPhotoSales = 0,
            premiumPhotoSales = 0,
            creatorProDownloads = 0,
            fullResPhotoEarnings = 0,
            largePhotoEarnings = 0,
            premiumPhotoEarnings = 0,
            standardPhotoEarnings = 0,
            creatorProEarnings = 0,
            insightsExist,
            freeDownloadCount
        } = invoice;

        const totalPaidSales =
            fullResSales + largeSales + standardPhotoSales + premiumPhotoSales;

        const totalPaidEarnings =
            parseFloat(standardPhotoEarnings) +
            parseFloat(premiumPhotoEarnings) +
            parseFloat(fullResPhotoEarnings) +
            parseFloat(largePhotoEarnings);

        const insightsObject = insightsExist
            ? {
                  onClick: () => {
                      openModalWithInsights({ month });
                  },
                  title: "Insights"
              }
            : null;

        const row = [
            month,
            formatDollars({ value: totalEarned }),
            formatDollars({ value: totalPaid }),
            <div key={`${month}-single-photo`}>
                {totalPaidEarnings > 0 && showCreatorPro && (
                    <p>${totalPaidEarnings.toFixed(2)}</p>
                )}
                {fullResSales > 0 && <p>{fullResSales} Full-Res</p>}
                {largeSales > 0 && <p>{largeSales} Large</p>}
                {standardPhotoSales > 0 && <p>{standardPhotoSales} Standard</p>}
                {premiumPhotoSales > 0 && <p>{premiumPhotoSales} Premium</p>}
                {totalPaidSales === 0 && <p>--</p>}
            </div>,
            freeDownloadCount ? freeDownloadCount : "--",
            insightsObject ?? "n/a"
        ];

        // add Creator Pro Downloads column if the feature flag is enabled
        if (showCreatorPro) {
            const creatorProValue = creatorProDownloads ? (
                <>
                    <p>${parseFloat(creatorProEarnings).toFixed(2)}</p>
                    <p>{creatorProDownloads}</p>
                </>
            ) : (
                "--"
            );
            row.splice(3, 0, creatorProValue);
        }

        return row;
    });

    const tableHeader = "Monthly Photo Reports";

    return (
        <>
            <Heading size="md" topSpacing="sm" bottomSpacing="lg" type="h2">
                {tableHeader}
            </Heading>
            <ScrollableTableMemoized
                tableHeaderData={headerData}
                tableBodyData={formatData}
                tableAriaLabel={tableHeader}
            />
            <InsightsModal
                isModalOpen={isModalOpen}
                modalData={modalData}
                handleClose={() => setIsOpenModal(false)}
                insightLoading={insightLoading}
            />
        </>
    );
};

MonthlyReport.propTypes = {
    statements: PropTypes.object.isRequired
};

export default MonthlyReport;
