import styled, { css } from "styled-components";

import { regular as regularFontWeight } from "core/fontStyles";
import breakpoints from "core/breakpoints";
import colors from "core/colors";

import Heading from "core/Heading";
import Button from "core/Button";

import {
    handleBottomSpacing,
    handleTopSpacing
} from "js/helpers/spacingAndFonts";
import { convertPxToRem } from "frontend/components/helpers";

export const Container = styled.div`
    ${({ bottomSpacing }) => handleBottomSpacing({ bottomSpacing })}
`;

export const SectionHeading = styled(Heading)`
    ${({ isHeadingGrid1 }) =>
        isHeadingGrid1 &&
        css`
            margin: 0 auto;
            width: 100%;

            /* Mobile: */
            max-width: 15rem; /* 240px */

            /* Tablet: */
            @media (min-width: ${breakpoints.tablet.rem}) {
                max-width: 41.5rem; /* 664px */
            }

            /* Desktop: */
            @media (min-width: ${breakpoints.desktop.rem}) {
                max-width: 57.75rem; /* 924px */
            }

            /* Screens with 700px - 1000px height range - matches tablet: */
            @media ${breakpoints.laptopHeightsRange.rem} {
                @media (min-width: ${breakpoints.desktop.rem}) {
                    max-width: 41.5rem; /* 664px */
                }
            }
        `}

    ${({ isHeadingGrid2 }) =>
        isHeadingGrid2 &&
        css`
            margin: 0 auto;
            width: 100%;

            /* Tablet: */
            @media (min-width: ${breakpoints.tablet.rem}) {
                max-width: 36.75rem; /* 588px */
            }

            /* Desktop: */
            @media (min-width: ${breakpoints.desktop.rem}) {
                max-width: 45.75rem; /* 732px */
            }

            /* Screens with 700px - 1000px height range - matches tablet: */
            @media ${breakpoints.laptopHeightsRange.rem} {
                @media (min-width: ${breakpoints.desktop.rem}) {
                    max-width: 36.75rem; /* 588px */
                }
            }
        `}
`;

export const Paragraph = styled.p`
    ${({ subtextHasTopPadding }) =>
        subtextHasTopPadding &&
        css`
            padding-top: 2rem; /* 32px */
        `}

    ${({ subtextAlignment }) =>
        subtextAlignment &&
        css`
            text-align: ${subtextAlignment};
        `}
        
    font-size: 1rem; /* 16px */
    line-height: 1.625rem; /* 26px */

    color: ${({ subtextColor }) =>
        !!subtextColor ? subtextColor : colors.secondary.orca};

    ${regularFontWeight};
`;

export const CTAsection = styled.div`
    text-align: ${({ buttonAlignment }) =>
        buttonAlignment ? buttonAlignment : "center"};
    ${handleTopSpacing({ topSpacing: "xl" })};

    ${Button} {
        margin-right: ${convertPxToRem({ px: 24 })};

        &:last-of-type {
            margin-right: 0;
        }
    }
`;
