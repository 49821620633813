import { css } from "styled-components";

import breakpoints from "core/breakpoints";
import { convertPxToRem } from "frontend/components/helpers";

export const spacing = {
    desktop: {
        HeroTop: css`
            padding-top: ${convertPxToRem({ px: 96 })};
        `,
        HeroBottom: css`
            padding-bottom: ${convertPxToRem({ px: 128 })};
        `,
        HeroTopAndBottom: css`
            padding-top: ${convertPxToRem({ px: 96 })};
            padding-bottom: ${convertPxToRem({ px: 128 })};
        `,
        HeroLandingTop: css`
            padding-top: ${convertPxToRem({ px: 64 })};
        `,
        HeroLandingBottom: css`
            padding-bottom: ${convertPxToRem({ px: 64 })};
        `,
        HeroLandingTopAndBottom: css`
            padding-top: ${convertPxToRem({ px: 64 })};
            padding-bottom: ${convertPxToRem({ px: 64 })};
        `,
        NoneTop: css`
            padding-top: 0;
        `,
        NoneBottom: css`
            padding-bottom: 0;
        `,
        NoneTopAndBottom: css`
            padding-top: 0;
            padding-bottom: 0;
        `,
        XL5Top: css`
            padding-top: ${convertPxToRem({ px: 128 })};
        `,
        XL5Bottom: css`
            padding-bottom: ${convertPxToRem({ px: 128 })};
        `,
        XL5TopAndBottom: css`
            padding-top: ${convertPxToRem({ px: 128 })};
            padding-bottom: ${convertPxToRem({ px: 128 })};
        `,
        XL4Top: css`
            padding-top: ${convertPxToRem({ px: 64 })};
        `,
        XL4Bottom: css`
            padding-bottom: ${convertPxToRem({ px: 64 })};
        `,
        XL4TopAndBottom: css`
            padding-top: ${convertPxToRem({ px: 64 })};
            padding-bottom: ${convertPxToRem({ px: 64 })};
        `,
        XL3Top: css`
            padding-top: ${convertPxToRem({ px: 48 })};
        `,
        XL3Bottom: css`
            padding-bottom: ${convertPxToRem({ px: 48 })};
        `,
        XL3TopAndBottom: css`
            padding-top: ${convertPxToRem({ px: 48 })};
            padding-bottom: ${convertPxToRem({ px: 48 })};
        `,
        XL2Top: css`
            padding-top: ${convertPxToRem({ px: 40 })};
        `,
        XL2Bottom: css`
            padding-bottom: ${convertPxToRem({ px: 40 })};
        `,
        XL2TopAndBottom: css`
            padding-top: ${convertPxToRem({ px: 40 })};
            padding-bottom: ${convertPxToRem({ px: 40 })};
        `,
        XLTop: css`
            padding-top: ${convertPxToRem({ px: 32 })};
        `,
        XLBottom: css`
            padding-bottom: ${convertPxToRem({ px: 32 })};
        `,
        XLTopAndBottom: css`
            padding-top: ${convertPxToRem({ px: 32 })};
            padding-bottom: ${convertPxToRem({ px: 32 })};
        `,
        LGTop: css`
            padding-top: ${convertPxToRem({ px: 24 })};
        `,
        LGBottom: css`
            padding-bottom: ${convertPxToRem({ px: 24 })};
        `,
        LGTopAndBottom: css`
            padding-top: ${convertPxToRem({ px: 24 })};
            padding-bottom: ${convertPxToRem({ px: 24 })};
        `,
        MDTop: css`
            padding-top: ${convertPxToRem({ px: 16 })};
        `,
        MDBottom: css`
            padding-bottom: ${convertPxToRem({ px: 16 })};
        `,
        MDTopAndBottom: css`
            padding-top: ${convertPxToRem({ px: 16 })};
            padding-bottom: ${convertPxToRem({ px: 16 })};
        `,
        SMTop: css`
            padding-top: ${convertPxToRem({ px: 8 })};
        `,
        SMBottom: css`
            padding-bottom: ${convertPxToRem({ px: 8 })};
        `,
        SMTopAndBottom: css`
            padding-top: ${convertPxToRem({ px: 8 })};
            padding-bottom: ${convertPxToRem({ px: 8 })};
        `,
        XSTop: css`
            padding-top: ${convertPxToRem({ px: 4 })};
        `,
        XSBottom: css`
            padding-bottom: ${convertPxToRem({ px: 4 })};
        `,
        XSTopAndBottom: css`
            padding-top: ${convertPxToRem({ px: 4 })};
            padding-bottom: ${convertPxToRem({ px: 4 })};
        `
    },
    tablet: {
        HeroTop: css`
            padding-top: ${convertPxToRem({ px: 80 })};
        `,
        HeroBottom: css`
            padding-bottom: ${convertPxToRem({ px: 98 })};
        `,
        HeroTopAndBottom: css`
            padding-top: ${convertPxToRem({ px: 80 })};
            padding-bottom: ${convertPxToRem({ px: 98 })};
        `,
        HeroLandingTop: css`
            padding-top: ${convertPxToRem({ px: 56 })};
        `,
        HeroLandingBottom: css`
            padding-bottom: ${convertPxToRem({ px: 56 })};
        `,
        HeroLandingTopAndBottom: css`
            padding-top: ${convertPxToRem({ px: 56 })};
            padding-bottom: ${convertPxToRem({ px: 56 })};
        `,
        NoneTop: css`
            padding-top: 0;
        `,
        NoneBottom: css`
            padding-bottom: 0;
        `,
        NoneTopAndBottom: css`
            padding-top: 0;
            padding-bottom: 0;
        `,
        XL5Top: css`
            padding-top: ${convertPxToRem({ px: 98 })};
        `,
        XL5Bottom: css`
            padding-bottom: ${convertPxToRem({ px: 98 })};
        `,
        XL5TopAndBottom: css`
            padding-top: ${convertPxToRem({ px: 98 })};
            padding-bottom: ${convertPxToRem({ px: 98 })};
        `,
        XL4Top: css`
            padding-top: ${convertPxToRem({ px: 56 })};
        `,
        XL4Bottom: css`
            padding-bottom: ${convertPxToRem({ px: 56 })};
        `,
        XL4TopAndBottom: css`
            padding-top: ${convertPxToRem({ px: 56 })};
            padding-bottom: ${convertPxToRem({ px: 56 })};
        `,
        XL3Top: css`
            padding-top: ${convertPxToRem({ px: 40 })};
        `,
        XL3Bottom: css`
            padding-bottom: ${convertPxToRem({ px: 40 })};
        `,
        XL3TopAndBottom: css`
            padding-top: ${convertPxToRem({ px: 40 })};
            padding-bottom: ${convertPxToRem({ px: 40 })};
        `,
        XL2Top: css`
            padding-top: ${convertPxToRem({ px: 36 })};
        `,
        XL2Bottom: css`
            padding-bottom: ${convertPxToRem({ px: 36 })};
        `,
        XL2TopAndBottom: css`
            padding-top: ${convertPxToRem({ px: 36 })};
            padding-bottom: ${convertPxToRem({ px: 36 })};
        `,
        XLTop: css`
            padding-top: ${convertPxToRem({ px: 28 })};
        `,
        XLBottom: css`
            padding-bottom: ${convertPxToRem({ px: 28 })};
        `,
        XLTopAndBottom: css`
            padding-top: ${convertPxToRem({ px: 28 })};
            padding-bottom: ${convertPxToRem({ px: 28 })};
        `,
        LGTop: css`
            padding-top: ${convertPxToRem({ px: 24 })};
        `,
        LGBottom: css`
            padding-bottom: ${convertPxToRem({ px: 24 })};
        `,
        LGTopAndBottom: css`
            padding-top: ${convertPxToRem({ px: 24 })};
            padding-bottom: ${convertPxToRem({ px: 24 })};
        `,
        MDTop: css`
            padding-top: ${convertPxToRem({ px: 16 })};
        `,
        MDBottom: css`
            padding-bottom: ${convertPxToRem({ px: 16 })};
        `,
        MDTopAndBottom: css`
            padding-top: ${convertPxToRem({ px: 16 })};
            padding-bottom: ${convertPxToRem({ px: 16 })};
        `,
        SMTop: css`
            padding-top: ${convertPxToRem({ px: 8 })};
        `,
        SMBottom: css`
            padding-bottom: ${convertPxToRem({ px: 8 })};
        `,
        SMTopAndBottom: css`
            padding-top: ${convertPxToRem({ px: 8 })};
            padding-bottom: ${convertPxToRem({ px: 8 })};
        `,
        XSTop: css`
            padding-top: ${convertPxToRem({ px: 4 })};
        `,
        XSBottom: css`
            padding-bottom: ${convertPxToRem({ px: 4 })};
        `,
        XSTopAndBottom: css`
            padding-top: ${convertPxToRem({ px: 4 })};
            padding-bottom: ${convertPxToRem({ px: 4 })};
        `
    },
    mobile: {
        HeroTop: css`
            padding-top: ${convertPxToRem({ px: 64 })};
        `,
        HeroBottom: css`
            padding-bottom: ${convertPxToRem({ px: 64 })};
        `,
        HeroTopAndBottom: css`
            padding-top: ${convertPxToRem({ px: 64 })};
            padding-bottom: ${convertPxToRem({ px: 64 })};
        `,
        HeroLandingTop: css`
            padding-top: ${convertPxToRem({ px: 48 })};
        `,
        HeroLandingBottom: css`
            padding-bottom: ${convertPxToRem({ px: 48 })};
        `,
        HeroLandingTopAndBottom: css`
            padding-top: ${convertPxToRem({ px: 48 })};
            padding-bottom: ${convertPxToRem({ px: 48 })};
        `,
        NoneTop: css`
            padding-top: 0;
        `,
        NoneBottom: css`
            padding-bottom: 0;
        `,
        NoneTopAndBottom: css`
            padding-top: 0;
            padding-bottom: 0;
        `,
        XL5Top: css`
            padding-top: ${convertPxToRem({ px: 64 })};
        `,
        XL5Bottom: css`
            padding-bottom: ${convertPxToRem({ px: 64 })};
        `,
        XL5TopAndBottom: css`
            padding-top: ${convertPxToRem({ px: 64 })};
            padding-bottom: ${convertPxToRem({ px: 64 })};
        `,
        XL4Top: css`
            padding-top: ${convertPxToRem({ px: 48 })};
        `,
        XL4Bottom: css`
            padding-bottom: ${convertPxToRem({ px: 48 })};
        `,
        XL4TopAndBottom: css`
            padding-top: ${convertPxToRem({ px: 48 })};
            padding-bottom: ${convertPxToRem({ px: 48 })};
        `,
        XL3Top: css`
            padding-top: ${convertPxToRem({ px: 32 })};
        `,
        XL3Bottom: css`
            padding-bottom: ${convertPxToRem({ px: 32 })};
        `,
        XL3TopAndBottom: css`
            padding-top: ${convertPxToRem({ px: 32 })};
            padding-bottom: ${convertPxToRem({ px: 32 })};
        `,
        XL2Top: css`
            padding-top: ${convertPxToRem({ px: 32 })};
        `,
        XL2Bottom: css`
            padding-bottom: ${convertPxToRem({ px: 32 })};
        `,
        XL2TopAndBottom: css`
            padding-top: ${convertPxToRem({ px: 32 })};
            padding-bottom: ${convertPxToRem({ px: 32 })};
        `,
        XLTop: css`
            padding-top: ${convertPxToRem({ px: 24 })};
        `,
        XLBottom: css`
            padding-bottom: ${convertPxToRem({ px: 24 })};
        `,
        XLTopAndBottom: css`
            padding-top: ${convertPxToRem({ px: 24 })};
            padding-bottom: ${convertPxToRem({ px: 24 })};
        `,
        LGTop: css`
            padding-top: ${convertPxToRem({ px: 24 })};
        `,
        LGBottom: css`
            padding-bottom: ${convertPxToRem({ px: 24 })};
        `,
        LGTopAndBottom: css`
            padding-top: ${convertPxToRem({ px: 24 })};
            padding-bottom: ${convertPxToRem({ px: 24 })};
        `,
        MDTop: css`
            padding-top: ${convertPxToRem({ px: 16 })};
        `,
        MDBottom: css`
            padding-bottom: ${convertPxToRem({ px: 16 })};
        `,
        MDTopAndBottom: css`
            padding-top: ${convertPxToRem({ px: 16 })};
            padding-bottom: ${convertPxToRem({ px: 16 })};
        `,
        SMTop: css`
            padding-top: ${convertPxToRem({ px: 8 })};
        `,
        SMBottom: css`
            padding-bottom: ${convertPxToRem({ px: 8 })};
        `,
        SMTopAndBottom: css`
            padding-top: ${convertPxToRem({ px: 8 })};
            padding-bottom: ${convertPxToRem({ px: 8 })};
        `,
        XSTop: css`
            padding-top: ${convertPxToRem({ px: 4 })};
        `,
        XSBottom: css`
            padding-bottom: ${convertPxToRem({ px: 4 })};
        `,
        XSTopAndBottom: css`
            padding-top: ${convertPxToRem({ px: 4 })};
            padding-bottom: ${convertPxToRem({ px: 4 })};
        `
    }
};

export const spacingWithBreakpoints = {
    HeroTop: css`
        /* Mobile: */
        ${spacing.mobile.HeroTop}

        /* Tablet: */
        @media (min-width: ${breakpoints.tablet.rem}) {
            ${spacing.tablet.HeroTop}
        }

        /* Desktop: */
        @media (min-width: ${breakpoints.desktop.rem}) {
            ${spacing.desktop.HeroTop}

            /* laptop heights within desktop width reflects tablet top & bottom spacing: */
            @media ${breakpoints.laptopHeightsRange.rem} {
                ${spacing.tablet.HeroTop}
            }
        }
    `,
    HeroBottom: css`
        /* Mobile: */
        ${spacing.mobile.HeroBottom}

        /* Tablet: */
        @media (min-width: ${breakpoints.tablet.rem}) {
            ${spacing.tablet.HeroBottom}
        }

        /* Desktop: */
        @media (min-width: ${breakpoints.desktop.rem}) {
            ${spacing.desktop.HeroBottom}

            /* laptop heights within desktop width reflects tablet top & bottom spacing: */
            @media ${breakpoints.laptopHeightsRange.rem} {
                ${spacing.tablet.HeroBottom}
            }
        }
    `,
    HeroTopAndBottom: css`
        /* Mobile: */
        ${spacing.mobile.HeroTop}
        ${spacing.mobile.HeroBottom}

        /* Tablet: */
        @media (min-width: ${breakpoints.tablet.rem}) {
            ${spacing.tablet.HeroTop}
            ${spacing.tablet.HeroBottom}
        }

        /* Desktop: */
        @media (min-width: ${breakpoints.desktop.rem}) {
            ${spacing.desktop.HeroTop}
            ${spacing.desktop.HeroBottom}

            /* laptop heights within desktop width reflects tablet top & bottom spacing: */
            @media ${breakpoints.laptopHeightsRange.rem} {
                ${spacing.tablet.HeroTop}
                ${spacing.tablet.HeroBottom}
            }
        }
    `,
    HeroLandingTop: css`
        /* Mobile: */
        ${spacing.mobile.HeroLandingTop}

        /* Tablet: */
        @media (min-width: ${breakpoints.tablet.rem}) {
            ${spacing.tablet.HeroLandingTop}
        }

        /* Desktop: */
        @media (min-width: ${breakpoints.desktop.rem}) {
            ${spacing.desktop.HeroLandingTop}

            /* laptop heights within desktop width reflects tablet top & bottom spacing: */
            @media ${breakpoints.laptopHeightsRange.rem} {
                ${spacing.tablet.HeroLandingTop}
            }
        }
    `,
    HeroLandingBottom: css`
        /* Mobile: */
        ${spacing.mobile.HeroLandingBottom}

        /* Tablet: */
        @media (min-width: ${breakpoints.tablet.rem}) {
            ${spacing.tablet.HeroLandingBottom}
        }

        /* Desktop: */
        @media (min-width: ${breakpoints.desktop.rem}) {
            ${spacing.desktop.HeroLandingBottom}

            /* laptop heights within desktop width reflects tablet top & bottom spacing: */
            @media ${breakpoints.laptopHeightsRange.rem} {
                ${spacing.tablet.HeroLandingBottom}
            }
        }
    `,
    HeroLandingTopAndBottom: css`
        /* Mobile: */
        ${spacing.mobile.HeroLandingTop}
        ${spacing.mobile.HeroLandingBottom}

        /* Tablet: */
        @media (min-width: ${breakpoints.tablet.rem}) {
            ${spacing.tablet.HeroLandingTop}
            ${spacing.tablet.HeroLandingBottom}
        }

        /* Desktop: */
        @media (min-width: ${breakpoints.desktop.rem}) {
            ${spacing.desktop.HeroLandingTop}
            ${spacing.desktop.HeroLandingBottom}

            /* laptop heights within desktop width reflects tablet top & bottom spacing: */
            @media ${breakpoints.laptopHeightsRange.rem} {
                ${spacing.tablet.HeroLandingTop}
                ${spacing.tablet.HeroLandingBottom}
            }
        }
    `,
    NoneTop: css`
        /* Mobile: */
        ${spacing.mobile.NoneTop}

        /* Tablet: */
        @media (min-width: ${breakpoints.tablet.rem}) {
            ${spacing.tablet.NoneTop}
        }

        /* Desktop: */
        @media (min-width: ${breakpoints.desktop.rem}) {
            ${spacing.desktop.NoneTop}

            /* laptop heights within desktop width reflects tablet top & bottom spacing: */
            @media ${breakpoints.laptopHeightsRange.rem} {
                ${spacing.tablet.NoneTop}
            }
        }
    `,
    NoneBottom: css`
        /* Mobile: */
        ${spacing.mobile.NoneBottom}

        /* Tablet: */
        @media (min-width: ${breakpoints.tablet.rem}) {
            ${spacing.tablet.NoneBottom}
        }

        /* Desktop: */
        @media (min-width: ${breakpoints.desktop.rem}) {
            ${spacing.desktop.NoneBottom}

            /* laptop heights within desktop width reflects tablet top & bottom spacing: */
            @media ${breakpoints.laptopHeightsRange.rem} {
                ${spacing.tablet.NoneBottom}
            }
        }
    `,
    NoneTopAndBottom: css`
        /* Mobile: */
        ${spacing.mobile.NoneTop}
        ${spacing.mobile.NoneBottom}

        /* Tablet: */
        @media (min-width: ${breakpoints.tablet.rem}) {
            ${spacing.tablet.NoneTop}
            ${spacing.tablet.NoneBottom}
        }

        /* Desktop: */
        @media (min-width: ${breakpoints.desktop.rem}) {
            ${spacing.desktop.NoneTop}
            ${spacing.desktop.NoneBottom}

            /* laptop heights within desktop width reflects tablet top & bottom spacing: */
            @media ${breakpoints.laptopHeightsRange.rem} {
                ${spacing.tablet.NoneTop}
                ${spacing.tablet.NoneBottom}
            }
        }
    `,
    XL5Top: css`
        /* Mobile: */
        ${spacing.mobile.XL5Top}

        /* Tablet: */
        @media (min-width: ${breakpoints.tablet.rem}) {
            ${spacing.tablet.XL5Top}
        }

        /* Desktop: */
        @media (min-width: ${breakpoints.desktop.rem}) {
            ${spacing.desktop.XL5Top}

            /* laptop heights within desktop width reflects tablet top & bottom spacing: */
            @media ${breakpoints.laptopHeightsRange.rem} {
                ${spacing.tablet.XL5Top}
            }
        }
    `,
    XL5Bottom: css`
        /* Mobile: */
        ${spacing.mobile.XL5Bottom}

        /* Tablet: */
        @media (min-width: ${breakpoints.tablet.rem}) {
            ${spacing.tablet.XL5Bottom}
        }

        /* Desktop: */
        @media (min-width: ${breakpoints.desktop.rem}) {
            ${spacing.desktop.XL5Bottom}

            /* laptop heights within desktop width reflects tablet top & bottom spacing: */
            @media ${breakpoints.laptopHeightsRange.rem} {
                ${spacing.tablet.XL5Bottom}
            }
        }
    `,
    XL5TopAndBottom: css`
        /* Mobile: */
        ${spacing.mobile.XL5Top}
        ${spacing.mobile.XL5Bottom}

        /* Tablet: */
        @media (min-width: ${breakpoints.tablet.rem}) {
            ${spacing.tablet.XL5Top}
            ${spacing.tablet.XL5Bottom}
        }

        /* Desktop: */
        @media (min-width: ${breakpoints.desktop.rem}) {
            ${spacing.desktop.XL5Top}
            ${spacing.desktop.XL5Bottom}

            /* laptop heights within desktop width reflects tablet top & bottom spacing: */
            @media ${breakpoints.laptopHeightsRange.rem} {
                ${spacing.tablet.XL5Top}
                ${spacing.tablet.XL5Bottom}
            }
        }
    `,
    XL4Top: css`
        /* Mobile: */
        ${spacing.mobile.XL4Top}

        /* Tablet: */
        @media (min-width: ${breakpoints.tablet.rem}) {
            ${spacing.tablet.XL4Top}
        }

        /* Desktop: */
        @media (min-width: ${breakpoints.desktop.rem}) {
            ${spacing.desktop.XL4Top}

            /* laptop heights within desktop width reflects tablet top & bottom spacing: */
            @media ${breakpoints.laptopHeightsRange.rem} {
                ${spacing.tablet.XL4Top}
            }
        }
    `,
    XL4Bottom: css`
        /* Mobile: */
        ${spacing.mobile.XL4Bottom}

        /* Tablet: */
        @media (min-width: ${breakpoints.tablet.rem}) {
            ${spacing.tablet.XL4Bottom}
        }

        /* Desktop: */
        @media (min-width: ${breakpoints.desktop.rem}) {
            ${spacing.desktop.XL4Bottom}

            /* laptop heights within desktop width reflects tablet top & bottom spacing: */
            @media ${breakpoints.laptopHeightsRange.rem} {
                ${spacing.tablet.XL4Bottom}
            }
        }
    `,
    XL4TopAndBottom: css`
        /* Mobile: */
        ${spacing.mobile.XL4Top}
        ${spacing.mobile.XL4Bottom}

        /* Tablet: */
        @media (min-width: ${breakpoints.tablet.rem}) {
            ${spacing.tablet.XL4Top}
            ${spacing.tablet.XL4Bottom}
        }

        /* Desktop: */
        @media (min-width: ${breakpoints.desktop.rem}) {
            ${spacing.desktop.XL4Top}
            ${spacing.desktop.XL4Bottom}

            /* laptop heights within desktop width reflects tablet top & bottom spacing: */
            @media ${breakpoints.laptopHeightsRange.rem} {
                ${spacing.tablet.XL4Top}
                ${spacing.tablet.XL4Bottom}
            }
        }
    `,
    XL3Top: css`
        /* Mobile: */
        ${spacing.mobile.XL3Top}

        /* Tablet: */
        @media (min-width: ${breakpoints.tablet.rem}) {
            ${spacing.tablet.XL3Top}
        }

        /* Desktop: */
        @media (min-width: ${breakpoints.desktop.rem}) {
            ${spacing.desktop.XL3Top}

            /* laptop heights within desktop width reflects tablet top & bottom spacing: */
            @media ${breakpoints.laptopHeightsRange.rem} {
                ${spacing.tablet.XL3Top}
            }
        }
    `,
    XL3Bottom: css`
        /* Mobile: */
        ${spacing.mobile.XL3Bottom}

        /* Tablet: */
        @media (min-width: ${breakpoints.tablet.rem}) {
            ${spacing.tablet.XL3Bottom}
        }

        /* Desktop: */
        @media (min-width: ${breakpoints.desktop.rem}) {
            ${spacing.desktop.XL3Bottom}

            /* laptop heights within desktop width reflects tablet top & bottom spacing: */
            @media ${breakpoints.laptopHeightsRange.rem} {
                ${spacing.tablet.XL3Bottom}
            }
        }
    `,
    XL3TopAndBottom: css`
        /* Mobile: */
        ${spacing.mobile.XL3Top}
        ${spacing.mobile.XL3Bottom}

        /* Tablet: */
        @media (min-width: ${breakpoints.tablet.rem}) {
            ${spacing.tablet.XL3Top}
            ${spacing.tablet.XL3Bottom}
        }

        /* Desktop: */
        @media (min-width: ${breakpoints.desktop.rem}) {
            ${spacing.desktop.XL3Top}
            ${spacing.desktop.XL3Bottom}

            /* laptop heights within desktop width reflects tablet top & bottom spacing: */
            @media ${breakpoints.laptopHeightsRange.rem} {
                ${spacing.tablet.XL3Top}
                ${spacing.tablet.XL3Bottom}
            }
        }
    `,
    XL2Top: css`
        /* Mobile: */
        ${spacing.mobile.XL2Top}

        /* Tablet: */
        @media (min-width: ${breakpoints.tablet.rem}) {
            ${spacing.tablet.XL2Top}
        }

        /* Desktop: */
        @media (min-width: ${breakpoints.desktop.rem}) {
            ${spacing.desktop.XL2Top}

            /* laptop heights within desktop width reflects tablet top & bottom spacing: */
            @media ${breakpoints.laptopHeightsRange.rem} {
                ${spacing.tablet.XL2Top}
            }
        }
    `,
    XL2Bottom: css`
        /* Mobile: */
        ${spacing.mobile.XL2Bottom}

        /* Tablet: */
        @media (min-width: ${breakpoints.tablet.rem}) {
            ${spacing.tablet.XL2Bottom}
        }

        /* Desktop: */
        @media (min-width: ${breakpoints.desktop.rem}) {
            ${spacing.desktop.XL2Bottom}

            /* laptop heights within desktop width reflects tablet top & bottom spacing: */
            @media ${breakpoints.laptopHeightsRange.rem} {
                ${spacing.tablet.XL2Bottom}
            }
        }
    `,
    XL2TopAndBottom: css`
        /* Mobile: */
        ${spacing.mobile.XL2Top}
        ${spacing.mobile.XL2Bottom}

        /* Tablet: */
        @media (min-width: ${breakpoints.tablet.rem}) {
            ${spacing.tablet.XL2Top}
            ${spacing.tablet.XL2Bottom}
        }

        /* Desktop: */
        @media (min-width: ${breakpoints.desktop.rem}) {
            ${spacing.desktop.XL2Top}
            ${spacing.desktop.XL2Bottom}

            /* laptop heights within desktop width reflects tablet top & bottom spacing: */
            @media ${breakpoints.laptopHeightsRange.rem} {
                ${spacing.tablet.XL2Top}
                ${spacing.tablet.XL2Bottom}
            }
        }
    `,
    XLTop: css`
        /* Mobile: */
        ${spacing.mobile.XLTop}

        /* Tablet: */
        @media (min-width: ${breakpoints.tablet.rem}) {
            ${spacing.tablet.XLTop}
        }

        /* Desktop: */
        @media (min-width: ${breakpoints.desktop.rem}) {
            ${spacing.desktop.XLTop}

            /* laptop heights within desktop width reflects tablet top & bottom spacing: */
            @media ${breakpoints.laptopHeightsRange.rem} {
                ${spacing.tablet.XLTop}
            }
        }
    `,
    XLBottom: css`
        /* Mobile: */
        ${spacing.mobile.XLBottom}

        /* Tablet: */
        @media (min-width: ${breakpoints.tablet.rem}) {
            ${spacing.tablet.XLBottom}
        }

        /* Desktop: */
        @media (min-width: ${breakpoints.desktop.rem}) {
            ${spacing.desktop.XLBottom}

            /* laptop heights within desktop width reflects tablet top & bottom spacing: */
            @media ${breakpoints.laptopHeightsRange.rem} {
                ${spacing.tablet.XLBottom}
            }
        }
    `,
    XLTopAndBottom: css`
        /* Mobile: */
        ${spacing.mobile.XLTop}
        ${spacing.mobile.XLBottom}

        /* Tablet: */
        @media (min-width: ${breakpoints.tablet.rem}) {
            ${spacing.tablet.XLTop}
            ${spacing.tablet.XLBottom}
        }

        /* Desktop: */
        @media (min-width: ${breakpoints.desktop.rem}) {
            ${spacing.desktop.XLTop}
            ${spacing.desktop.XLBottom}

            /* laptop heights within desktop width reflects tablet top & bottom spacing: */
            @media ${breakpoints.laptopHeightsRange.rem} {
                ${spacing.tablet.XLTop}
                ${spacing.tablet.XLBottom}
            }
        }
    `,
    LGTop: css`
        /* Mobile: */
        ${spacing.mobile.LGTop}

        /* Tablet: */
        @media (min-width: ${breakpoints.tablet.rem}) {
            ${spacing.tablet.LGTop}
        }

        /* Desktop: */
        @media (min-width: ${breakpoints.desktop.rem}) {
            ${spacing.desktop.LGTop}

            /* laptop heights within desktop width reflects tablet top & bottom spacing: */
            @media ${breakpoints.laptopHeightsRange.rem} {
                ${spacing.tablet.LGTop}
            }
        }
    `,
    LGBottom: css`
        /* Mobile: */
        ${spacing.mobile.LGBottom}

        /* Tablet: */
        @media (min-width: ${breakpoints.tablet.rem}) {
            ${spacing.tablet.LGBottom}
        }

        /* Desktop: */
        @media (min-width: ${breakpoints.desktop.rem}) {
            ${spacing.desktop.LGBottom}

            /* laptop heights within desktop width reflects tablet top & bottom spacing: */
            @media ${breakpoints.laptopHeightsRange.rem} {
                ${spacing.tablet.LGBottom}
            }
        }
    `,
    LGTopAndBottom: css`
        /* Mobile: */
        ${spacing.mobile.LGTop}
        ${spacing.mobile.LGBottom}

        /* Tablet: */
        @media (min-width: ${breakpoints.tablet.rem}) {
            ${spacing.tablet.LGTop}
            ${spacing.tablet.LGBottom}
        }

        /* Desktop: */
        @media (min-width: ${breakpoints.desktop.rem}) {
            ${spacing.desktop.LGTop}
            ${spacing.desktop.LGBottom}

            /* laptop heights within desktop width reflects tablet top & bottom spacing: */
            @media ${breakpoints.laptopHeightsRange.rem} {
                ${spacing.tablet.LGTop}
                ${spacing.tablet.LGBottom}
            }
        }
    `,
    MDTop: css`
        /* Mobile: */
        ${spacing.mobile.MDTop}

        /* Tablet: */
        @media (min-width: ${breakpoints.tablet.rem}) {
            ${spacing.tablet.MDTop}
        }

        /* Desktop: */
        @media (min-width: ${breakpoints.desktop.rem}) {
            ${spacing.desktop.MDTop}

            /* laptop heights within desktop width reflects tablet top & bottom spacing: */
            @media ${breakpoints.laptopHeightsRange.rem} {
                ${spacing.tablet.MDTop}
            }
        }
    `,
    MDBottom: css`
        /* Mobile: */
        ${spacing.mobile.MDBottom}

        /* Tablet: */
        @media (min-width: ${breakpoints.tablet.rem}) {
            ${spacing.tablet.MDBottom}
        }

        /* Desktop: */
        @media (min-width: ${breakpoints.desktop.rem}) {
            ${spacing.desktop.MDBottom}

            /* laptop heights within desktop width reflects tablet top & bottom spacing: */
            @media ${breakpoints.laptopHeightsRange.rem} {
                ${spacing.tablet.MDBottom}
            }
        }
    `,
    MDTopAndBottom: css`
        /* Mobile: */
        ${spacing.mobile.MDTop}
        ${spacing.mobile.MDBottom}

        /* Tablet: */
        @media (min-width: ${breakpoints.tablet.rem}) {
            ${spacing.tablet.MDTop}
            ${spacing.tablet.MDBottom}
        }

        /* Desktop: */
        @media (min-width: ${breakpoints.desktop.rem}) {
            ${spacing.desktop.MDTop}
            ${spacing.desktop.MDBottom}

            /* laptop heights within desktop width reflects tablet top & bottom spacing: */
            @media ${breakpoints.laptopHeightsRange.rem} {
                ${spacing.tablet.MDTop}
                ${spacing.tablet.MDBottom}
            }
        }
    `,
    SMTop: css`
        /* Mobile: */
        ${spacing.mobile.SMTop}

        /* Tablet: */
        @media (min-width: ${breakpoints.tablet.rem}) {
            ${spacing.tablet.SMTop}
        }

        /* Desktop: */
        @media (min-width: ${breakpoints.desktop.rem}) {
            ${spacing.desktop.SMTop}

            /* laptop heights within desktop width reflects tablet top & bottom spacing: */
            @media ${breakpoints.laptopHeightsRange.rem} {
                ${spacing.tablet.SMTop}
            }
        }
    `,
    SMBottom: css`
        /* Mobile: */
        ${spacing.mobile.SMBottom}

        /* Tablet: */
        @media (min-width: ${breakpoints.tablet.rem}) {
            ${spacing.tablet.SMBottom}
        }

        /* Desktop: */
        @media (min-width: ${breakpoints.desktop.rem}) {
            ${spacing.desktop.SMBottom}

            /* laptop heights within desktop width reflects tablet top & bottom spacing: */
            @media ${breakpoints.laptopHeightsRange.rem} {
                ${spacing.tablet.SMBottom}
            }
        }
    `,
    SMTopAndBottom: css`
        /* Mobile: */
        ${spacing.mobile.SMTop}
        ${spacing.mobile.SMBottom}

        /* Tablet: */
        @media (min-width: ${breakpoints.tablet.rem}) {
            ${spacing.tablet.SMTop}
            ${spacing.tablet.SMBottom}
        }

        /* Desktop: */
        @media (min-width: ${breakpoints.desktop.rem}) {
            ${spacing.desktop.SMTop}
            ${spacing.desktop.SMBottom}

            /* laptop heights within desktop width reflects tablet top & bottom spacing: */
            @media ${breakpoints.laptopHeightsRange.rem} {
                ${spacing.tablet.SMTop}
                ${spacing.tablet.SMBottom}
            }
        }
    `,
    XSTop: css`
        /* Mobile: */
        ${spacing.mobile.XSTop}

        /* Tablet: */
        @media (min-width: ${breakpoints.tablet.rem}) {
            ${spacing.tablet.XSTop}
        }

        /* Desktop: */
        @media (min-width: ${breakpoints.desktop.rem}) {
            ${spacing.desktop.XSTop}

            /* laptop heights within desktop width reflects tablet top & bottom spacing: */
            @media ${breakpoints.laptopHeightsRange.rem} {
                ${spacing.tablet.XSTop}
            }
        }
    `,
    XSBottom: css`
        /* Mobile: */
        ${spacing.mobile.XSBottom}

        /* Tablet: */
        @media (min-width: ${breakpoints.tablet.rem}) {
            ${spacing.tablet.XSBottom}
        }

        /* Desktop: */
        @media (min-width: ${breakpoints.desktop.rem}) {
            ${spacing.desktop.XSBottom}

            /* laptop heights within desktop width reflects tablet top & bottom spacing: */
            @media ${breakpoints.laptopHeightsRange.rem} {
                ${spacing.tablet.XSBottom}
            }
        }
    `,
    XSTopAndBottom: css`
        /* Mobile: */
        ${spacing.mobile.XSTop}
        ${spacing.mobile.XSBottom}

        /* Tablet: */
        @media (min-width: ${breakpoints.tablet.rem}) {
            ${spacing.tablet.XSTop}
            ${spacing.tablet.XSBottom}
        }

        /* Desktop: */
        @media (min-width: ${breakpoints.desktop.rem}) {
            ${spacing.desktop.XSTop}
            ${spacing.desktop.XSBottom}

            /* laptop heights within desktop width reflects tablet top & bottom spacing: */
            @media ${breakpoints.laptopHeightsRange.rem} {
                ${spacing.tablet.XSTop}
                ${spacing.tablet.XSBottom}
            }
        }
    `
};
