/* Upload Statuses ---------------------------*/
const UPLOAD_STATUS_KEYS = {
    // Visual Mod
    VIS_MOD: "VISUAL_MOD",
    VIS_MOD_SAVED_FOR_LATER: "VISUAL_MOD_SAVED_FOR_LATER",

    // MetaData
    META_MOD: "METADATA_MOD",
    META_MOD_SAVED_FOR_LATER: "METADATA_MOD_SAVED_FOR_LATER",

    // Fully Published
    AWAITING_FULL_PUB_COLOR_TAGGING: "AWAITING_FULL_PUB_COLOR_TAGGING",
    FULL_PUB_COLOR_TAGGING_IN_PROGRESS: "FULL_PUB_COLOR_TAGGING_IN_PROGRESS",
    FULL_PUB_COLOR_TAGGING_FAILED: "FULL_PUB_COLOR_TAGGING_FAILED",
    PUBLISHED_FULLY: "PUBLISHED_FULLY",

    // Denied
    DENIED_AUTO: "DENIED_AUTO",
    DENIED_MANUAL: "DENIED_MANUAL",
    DENIED_BANNED: "DENIED_BANNED",
    DENIED_REVOKED: "DENIED_REVOKED",
    AUTO_DENIED_BATCH_HAS_BANNED_IMAGERY: "AUTO_DENIED_BATCH_HAS_BANNED_IMAGERY"
};

export const UPLOAD_STATUSES = {
    VISUAL: {
        active: [UPLOAD_STATUS_KEYS.VIS_MOD],
        savedForLater: [UPLOAD_STATUS_KEYS.VIS_MOD_SAVED_FOR_LATER]
    },
    META: {
        active: [UPLOAD_STATUS_KEYS.META_MOD],
        savedForLater: [UPLOAD_STATUS_KEYS.META_MOD_SAVED_FOR_LATER]
    },
    FULL: {
        active: [
            UPLOAD_STATUS_KEYS.AWAITING_FULL_PUB_COLOR_TAGGING,
            UPLOAD_STATUS_KEYS.FULL_PUB_COLOR_TAGGING_IN_PROGRESS,
            UPLOAD_STATUS_KEYS.FULL_PUB_COLOR_TAGGING_FAILED,
            UPLOAD_STATUS_KEYS.PUBLISHED_FULLY
        ],
        savedForLater: []
    },
    DENIED: {
        active: [
            UPLOAD_STATUS_KEYS.DENIED_AUTO,
            UPLOAD_STATUS_KEYS.DENIED_MANUAL,
            UPLOAD_STATUS_KEYS.DENIED_BANNED,
            UPLOAD_STATUS_KEYS.DENIED_REVOKED,
            UPLOAD_STATUS_KEYS.AUTO_DENIED_BATCH_HAS_BANNED_IMAGERY
        ],
        savedForLater: []
    }
};

export const UPLOAD_STATUS_BY_QUEUE = {
    VISUAL: [
        ...UPLOAD_STATUSES.VISUAL.active,
        ...UPLOAD_STATUSES.VISUAL.savedForLater
    ],
    META: [
        ...UPLOAD_STATUSES.META.active,
        ...UPLOAD_STATUSES.META.savedForLater
    ],
    FULL: [
        ...UPLOAD_STATUSES.FULL.active,
        ...UPLOAD_STATUSES.FULL.savedForLater
    ],
    DENIED: [
        ...UPLOAD_STATUSES.DENIED.active,
        ...UPLOAD_STATUSES.DENIED.savedForLater
    ]
};

/* Active and Saved For Later ---------------------------*/
export const UPLOAD_STATUS_FILTERS = {
    ACTIVE: [
        ...UPLOAD_STATUSES.VISUAL.active,
        ...UPLOAD_STATUSES.META.active,
        ...UPLOAD_STATUSES.FULL.active,
        ...UPLOAD_STATUSES.DENIED.active
    ],
    SAVED_FOR_LATER: [
        ...UPLOAD_STATUSES.VISUAL.savedForLater,
        ...UPLOAD_STATUSES.META.savedForLater,
        ...UPLOAD_STATUSES.FULL.savedForLater,
        ...UPLOAD_STATUSES.DENIED.savedForLater
    ]
};
