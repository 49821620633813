import styled, { css } from "styled-components";

import ImagePreventRightClick from "core/ImagePreventRightClick";

import breakpoints from "core/breakpoints";
import { focusInset } from "core/links";

import { convertPxToRem } from "frontend/components/helpers";

const HEIGHTS_AND_WIDTHS = {
    HOME_PAGE: {
        DESKTOP: {
            CONTAINER_HEIGHT_PX: 229,
            ICON_HEIGHT_PX: 75,
            ICON_ROW_BOTTOM_MARGIN_PX: 79
        },
        MOBILE: {
            CONTAINER_HEIGHT_PX: 147,
            ICON_HEIGHT_PX: 40,
            ICON_ROW_BOTTOM_MARGIN_PX: 67
        }
    },
    ICONS_LANDING_PAGE: {
        DESKTOP: {
            CONTAINER_HEIGHT_PX: 538,
            CONTAINER_WIDTH_PX: 578,
            ICONROW_WIDTH_PX: 401.39,
            ICON_HEIGHT_PX: 80
        },
        TABLET: {
            CONTAINER_HEIGHT_PX: 389,
            CONTAINER_WIDTH_PX: 417,
            ICONROW_WIDTH_PX: 289,
            ICON_HEIGHT_PX: 58
        }
    }
};

export const OuterContainer = styled.div`
    position: relative;
    width: 100%;
    display: inline-block;

    /* home page: */
    ${({ isHomepage }) => css`
        ${isHomepage &&
            css`
                height: ${convertPxToRem({
                    px: HEIGHTS_AND_WIDTHS.HOME_PAGE.DESKTOP.CONTAINER_HEIGHT_PX
                })};
                overflow: hidden;

                @media (max-width: ${breakpoints.beforeTablet.rem}) {
                    height: ${convertPxToRem({
                        px:
                            HEIGHTS_AND_WIDTHS.HOME_PAGE.MOBILE
                                .CONTAINER_HEIGHT_PX
                    })};
                }
            `}
    `}

    /* icons landing page: */
    ${({ isHomepage }) =>
        !isHomepage &&
        css`
            max-width: ${convertPxToRem({
                px:
                    HEIGHTS_AND_WIDTHS.ICONS_LANDING_PAGE.DESKTOP
                        .CONTAINER_WIDTH_PX
            })};

            /* tablet: */
            @media (max-width: ${breakpoints.beforeDesktop.rem}) {
                max-width: ${convertPxToRem({
                    px:
                        HEIGHTS_AND_WIDTHS.ICONS_LANDING_PAGE.TABLET
                            .CONTAINER_WIDTH_PX
                })};
            }

            /* mobile: */
            @media (max-width: ${breakpoints.beforeTablet.rem}) {
                max-width: 100%;
            }
        `}
`;

export const InnerContainer = styled.div`
    position: ${({ isHomepage }) => (isHomepage ? "absolute" : "relative")};

    /* home page: */
    ${({ isHomepage }) =>
        isHomepage &&
        css`
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        `}

    /* icons landing page: */
    ${({ isHomepage }) =>
        !isHomepage &&
        css`
            height: ${convertPxToRem({
                px:
                    HEIGHTS_AND_WIDTHS.ICONS_LANDING_PAGE.DESKTOP
                        .CONTAINER_HEIGHT_PX
            })};
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-top: ${convertPxToRem({ px: 60 })};

            /* Tablet: */
            @media (max-width: ${breakpoints.beforeDesktop.rem}) {
                height: ${convertPxToRem({
                    px:
                        HEIGHTS_AND_WIDTHS.ICONS_LANDING_PAGE.TABLET
                            .CONTAINER_HEIGHT_PX
                })};
            }

            /* Mobile: */
            @media (max-width: ${breakpoints.beforeTablet.rem}) {
                padding-top: 0;
                height: auto;
            }
        `}
`;

export const IconRow = styled.div`
    position: relative;

    /* home page: */
    ${({ isHomepage }) =>
        isHomepage &&
        css`
            width: ${convertPxToRem({ px: 1359 })};
            margin: 0 auto;
            margin-bottom: ${convertPxToRem({
                px:
                    HEIGHTS_AND_WIDTHS.HOME_PAGE.DESKTOP
                        .ICON_ROW_BOTTOM_MARGIN_PX
            })};

            &:first-of-type {
                left: ${convertPxToRem({
                    px: -HEIGHTS_AND_WIDTHS.HOME_PAGE.DESKTOP.ICON_HEIGHT_PX
                })};
            }

            &:last-of-type {
                margin-bottom: 0;
                right: ${convertPxToRem({
                    px: -(
                        HEIGHTS_AND_WIDTHS.HOME_PAGE.DESKTOP.ICON_HEIGHT_PX / 2
                    )
                })};
            }

            @media (max-width: ${breakpoints.beforeTablet.rem}) {
                width: ${convertPxToRem({ px: 767 })};
                margin-bottom: ${convertPxToRem({
                    px:
                        HEIGHTS_AND_WIDTHS.HOME_PAGE.MOBILE
                            .ICON_ROW_BOTTOM_MARGIN_PX
                })};

                &:first-of-type {
                    left: ${convertPxToRem({
                        px: -HEIGHTS_AND_WIDTHS.HOME_PAGE.MOBILE.ICON_HEIGHT_PX
                    })};
                }
                &:last-of-type {
                    margin-bottom: 0;
                    right: ${convertPxToRem({
                        px: -(
                            HEIGHTS_AND_WIDTHS.HOME_PAGE.MOBILE.ICON_HEIGHT_PX /
                            2
                        )
                    })};
                }
            }
        `}

    /* icons landing page: */
    ${({ isHomepage }) =>
        !isHomepage &&
        css`
            width: ${convertPxToRem({
                px:
                    HEIGHTS_AND_WIDTHS.ICONS_LANDING_PAGE.DESKTOP
                        .ICONROW_WIDTH_PX
            })};

            &:nth-child(even) {
                margin-left: calc(
                    100% -
                        ${convertPxToRem({
                            px:
                                HEIGHTS_AND_WIDTHS.ICONS_LANDING_PAGE.DESKTOP
                                    .ICONROW_WIDTH_PX
                        })}
                );
            }

            /* Tablet: */
            @media (max-width: ${breakpoints.beforeDesktop.rem}) {
                width: ${convertPxToRem({
                    px:
                        HEIGHTS_AND_WIDTHS.ICONS_LANDING_PAGE.TABLET
                            .ICONROW_WIDTH_PX
                })};

                &:nth-child(even) {
                    margin-left: calc(
                        100% -
                            ${convertPxToRem({
                                px:
                                    HEIGHTS_AND_WIDTHS.ICONS_LANDING_PAGE.TABLET
                                        .ICONROW_WIDTH_PX
                            })}
                    );
                }
            }

            /* Mobile: */
            @media (max-width: ${breakpoints.beforeTablet.rem}) {
                height: 100%;
                width: 100%;
                padding-bottom: 4.5%;

                ${InnerRowContainer} {
                    justify-content: space-around;
                }

                &:nth-child(even) {
                    width: 100%;
                    margin: 0;

                    ${InnerRowContainer} {
                        justify-content: space-between;
                    }
                }

                &:last-child {
                    padding-bottom: 0;
                }
            }
        `}
`;

export const InnerRowContainer = styled.div`
    display: flex;
    justify-content: space-between; // space-around for mobile !isHomepage

    ${({ isHomepage }) =>
        isHomepage &&
        css`
            @media (max-width: ${breakpoints.beforeTablet.rem}) {
                justify-content: space-around;
            }
        `}
`;

export const HomeIconContainer = styled(ImagePreventRightClick)`
    img {
        filter: invert(1); // make icons white
    }

    width: auto;
    max-width: ${convertPxToRem({
        px: HEIGHTS_AND_WIDTHS.HOME_PAGE.DESKTOP.ICON_HEIGHT_PX
    })};
    height: ${convertPxToRem({
        px: HEIGHTS_AND_WIDTHS.HOME_PAGE.DESKTOP.ICON_HEIGHT_PX
    })};

    /* mobile-only: */
    @media (max-width: ${breakpoints.beforeTablet.rem}) {
        max-width: ${convertPxToRem({
            px: HEIGHTS_AND_WIDTHS.HOME_PAGE.MOBILE.ICON_HEIGHT_PX
        })};
        height: ${convertPxToRem({
            px: HEIGHTS_AND_WIDTHS.HOME_PAGE.MOBILE.ICON_HEIGHT_PX
        })};
    }
`;

export const IconContainer = styled(ImagePreventRightClick)`
    img {
        filter: invert(1); // make icons white
    }

    &:hover {
        opacity: 0.6;
    }

    // only show focus on keyboard not with mouse:
    &:focus:not(.focus-visible) {
        outline: none;
        box-shadow: none;
        border: none;
    }

    &:focus {
        ${focusInset}
    }

    width: auto;
    max-width: ${convertPxToRem({
        px: HEIGHTS_AND_WIDTHS.ICONS_LANDING_PAGE.DESKTOP.ICON_HEIGHT_PX
    })};
    height: ${convertPxToRem({
        px: HEIGHTS_AND_WIDTHS.ICONS_LANDING_PAGE.DESKTOP.ICON_HEIGHT_PX
    })};

    /* Tablet: */
    @media (max-width: ${breakpoints.beforeDesktop.rem}) {
        max-width: ${convertPxToRem({
            px: HEIGHTS_AND_WIDTHS.ICONS_LANDING_PAGE.TABLET.ICON_HEIGHT_PX
        })};
        height: ${convertPxToRem({
            px: HEIGHTS_AND_WIDTHS.ICONS_LANDING_PAGE.TABLET.ICON_HEIGHT_PX
        })};
    }

    /* mobile: */
    @media (max-width: ${breakpoints.beforeTablet.rem}) {
        max-width: unset;
        width: calc(100% / 5); // 5 is total amount of columns
        height: 100%;

        img {
            background-position: center center;
            background-size: 66%;
            width: 72.5%;
            margin: 0 auto;
            display: block;
        }
    }
`;

export const IconsLandingPageMobileConstraintImg = styled.img`
    /* this image holds the container dimensions 
    as the browser scales for the icons landing page 
    mobile icons using ImagePreventRightClick: */
    width: 100%;
    height: auto;
`;

export const RowContainer = styled.div`
    /* icons landing page: */
    ${({ isHomepage }) =>
        !isHomepage &&
        css`
            height: 100%;

            /* mobile: */
            @media (max-width: ${breakpoints.beforeTablet.rem}) {
                position: absolute;
                width: 100%;
            }
        `}
`;

export const RowFlex = styled.div`
    /* icons landing page: */
    ${({ isHomepage }) =>
        !isHomepage &&
        css`
            display: flex;
            flex-direction: column;

            @media (min-width: ${breakpoints.tablet.rem}) {
                justify-content: space-between;
                height: 100%;
            }
        `}
`;
