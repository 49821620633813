import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

// styles
import { FlagManagementStyled } from "./styles";

// components
import ButtonLooksLikeLink from "frontend/components/core/ButtonLooksLikeLink";

// hooks
import { useSelect } from "frontend/components/core/Grid2/GridItemContentTemplates/Select/SelectContext";
import { useQueueContext } from "frontend/components/fort/mod/core/Layouts/QueueLayout";

import {
    useClearReleaseFlag,
    useClearExplicitFlag,
    useClearHasWarningText,
    useClearEditorialUseOnlyFlag
} from "../../hooks/mutations/flags";

const FlagManagement = ({ hide = false }) => {
    const { clearReleaseFlag } = useClearReleaseFlag();
    const { clearExplicitFlag } = useClearExplicitFlag();
    const { clearHasWarningText } = useClearHasWarningText();
    const { clearEditorialUseOnlyFlag } = useClearEditorialUseOnlyFlag();

    const { selectedBatchObj } = useQueueContext();
    const [relFlagCount, setRelFlagCount] = useState(0);
    const [euoFlagCount, setEuoFlagCount] = useState(0);
    const [txtFlagCount, setTxtFlagCount] = useState(0);
    const [expFlagCount, setExpFlagCount] = useState(0);

    const { uploads } = useSelector(state => state);

    const { uploadsSelected, getSelectedUploadIds } = useSelect();
    const selectedUploadIds = useMemo(
        () => getSelectedUploadIds(uploadsSelected),
        [uploadsSelected]
    );

    const setDefaultState = () => {
        setRelFlagCount(0);
        setEuoFlagCount(0);
        setTxtFlagCount(0);
        setExpFlagCount(0);
    };

    useEffect(() => {
        setDefaultState();

        selectedUploadIds.forEach(id => {
            const upload = uploads.entities[id];

            if (upload.hasRecognizablePeopleOrProperty) {
                setRelFlagCount(prev => prev + 1);
            }

            if (upload.editorialUseOnly) {
                setEuoFlagCount(prev => prev + 1);
            }

            if (upload.autoWarningText) {
                setTxtFlagCount(prev => prev + 1);
            }

            if (upload.autoNudity || upload.autoViolence) {
                setExpFlagCount(prev => prev + 1);
            }
        });
    }, [uploadsSelected, uploads]);

    return (
        <FlagManagementStyled>
            {!hide && selectedBatchObj?.isEditable?.uploadFlags && (
                <>
                    {!!relFlagCount && (
                        <ButtonLooksLikeLink
                            onClick={() => clearReleaseFlag(selectedUploadIds)}
                            value="Clear REL"
                        >
                            Clear REL ({relFlagCount})
                        </ButtonLooksLikeLink>
                    )}
                    {!!euoFlagCount && (
                        <ButtonLooksLikeLink
                            onClick={() =>
                                clearEditorialUseOnlyFlag(selectedUploadIds)
                            }
                            value="Clear EUO"
                        >
                            Clear EUO ({euoFlagCount})
                        </ButtonLooksLikeLink>
                    )}
                    {!!txtFlagCount && (
                        <ButtonLooksLikeLink
                            onClick={() =>
                                clearHasWarningText(selectedUploadIds)
                            }
                            value="Clear TXT"
                        >
                            Clear TXT ({txtFlagCount})
                        </ButtonLooksLikeLink>
                    )}
                    {!!expFlagCount && (
                        <ButtonLooksLikeLink
                            onClick={() => clearExplicitFlag(selectedUploadIds)}
                            value="Clear EXP"
                        >
                            Clear EXP ({expFlagCount})
                        </ButtonLooksLikeLink>
                    )}
                </>
            )}
        </FlagManagementStyled>
    );
};

export default FlagManagement;

// prop-types
FlagManagement.propTypes = {
    hide: PropTypes.bool
};
