import { useEffect, useState } from "react";

import { useFetchMyModeratorProfile } from "./queries/moderators/useFetchMyModeratorProfile.js";
import { useCurrentUserContext } from "contexts/CurrentUserContext";

export const useMyModerator = () => {
    const [myModProfile, setMyModProfile] = useState({ profileFetched: false });
    const { fetchMyModeratorProfile } = useFetchMyModeratorProfile();

    const currentUserContext = useCurrentUserContext();
    const currentUserId =
        currentUserContext?.currentUser?.data?.currentUser?.id;

    useEffect(() => {
        const getMyModProfile = async () => {
            const profile = await fetchMyModeratorProfile();
            setMyModProfile({ ...profile, profileFetched: true });
        };
        getMyModProfile();
    }, [currentUserId]);

    return {
        myModProfile
    };
};
