// APPLIES TO /PHOTOS/ PROJECT //
// -------------------------- //
import gql from "graphql-tag";
import * as ApolloReactHooks from "@apollo/client";

import { PrepaidBalancePartsFragmentDoc } from "icons/fragments";

export const PhotoDownloadType = {
    Public: "FULL_RES",
    Basic: "FREE",
    Large: "LARGE",
    FullRes: "FULL_RES",
    Standard: "STANDARD_PHOTO",
    Premium: "PREMIUM_PHOTO"
};

export const PhotoPurchaseType = {
    /** Large photo */
    Large: "LARGE",

    /** Full-res photo */
    FullRes: "FULL_RES",

    /** Standard photo */
    Standard: "STANDARD_PHOTO",

    /** Premium photo */
    Premium: "PREMIUM_PHOTO"
};

export const UploadLicense = {
    /** Public Domain */
    Publicdomain: "PUBLICDOMAIN",

    /** Creative Commons */
    Creativecommons: "CREATIVECOMMONS"
};

export const PhotoDetailPartsFragmentDoc = gql`
    fragment PhotoDetailParts on PhotoType {
        id
        slugWithId
        title
        description
        license
        isPremium
        editorialUseOnly
        creator {
            id
            name
            username
            avatarUrl
            country
        }
        assets {
            preview
            thumbnailMedium
            thumbnailLarge
        }
        tags {
            slug
            name
        }
        ratio
        createdAt
        freeDimensions {
            pixelWidth
            pixelHeight
            printDpi
            printWidth
            printHeight
        }
        largeDimensions {
            pixelWidth
            pixelHeight
            printDpi
            printWidth
            printHeight
        }
        fullDimensions {
            pixelWidth
            pixelHeight
            printDpi
            printWidth
            printHeight
        }
    }
`;

export const PhotoListPartsFragmentDoc = gql`
    fragment PhotoListParts on PhotoType {
        id
        slugWithId
        title
        isPremium
        assets {
            thumbnailSmall
            thumbnailMedium
        }
        ratio
    }
`;

// The shape of this query needs to match the query in `get_apollo_current_user`,
// which pre-caches this query in Apollo. Location: `noun_project/schema.py`
//
// We also need to update mock tests here: Location: `frontend/components/photos/__mocks__/user.js`
export const GET_CURRENT_USER_DOCUMENT = gql`
    query currentUser {
        currentUser {
            id
            username
            email
            avatarUrl
            featureFlags
            isAnonymous
            isCreator
            isIconCreator
            isPhotoCreator
            isSuperuser
            isEligibleForCreatorPro
            hasNotifications
            hasCreatorProSubscription
            ...PrepaidBalanceParts
            ownedTeams {
                username
                name
            }
            hasIconProSubscription
            isIconProTeamOwner
            isCreatorProTeamOwner
            isCreatorProTeamMember
            isIconProTeamMember
            hasEduSubscription
        }
    }
    ${PrepaidBalancePartsFragmentDoc}
`;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions) {
    return ApolloReactHooks.useQuery(GET_CURRENT_USER_DOCUMENT, baseOptions);
}

export const LoginDocument = gql`
    mutation login($input: LoginInput!) {
        login(input: $input) {
            ok
            token
            user {
                id
                username
                avatarUrl
                isCreator
                isSuperuser
                hasSubscription
                teams {
                    username
                }
            }
        }
    }
`;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(baseOptions) {
    return ApolloReactHooks.useMutation(LoginDocument, baseOptions);
}

export const LogoutDocument = gql`
    mutation logout {
        logout {
            ok
        }
    }
`;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions) {
    return ApolloReactHooks.useMutation(LogoutDocument, baseOptions);
}

export const PHOTO_SEARCH = gql`
    query photoSearch(
        $query: String!
        $creator: String
        $limit: Int
        $offset: Int
    ) {
        photoSearch(
            query: $query
            creator: $creator
            limit: $limit
            offset: $offset
        ) {
            totalCount
            items {
                ...PhotoListParts
            }
            iconCount
            collectionCount
        }
    }
    ${PhotoListPartsFragmentDoc}
`;

export const PhotoDetailDocument = gql`
    query photoDetail($photoId: ID!) {
        photoDetail(photoId: $photoId) {
            ...PhotoDetailParts
        }
    }
    ${PhotoDetailPartsFragmentDoc}
`;

export const PhotoMoreFromThisShootDocument = gql`
    query photoMoreFromThisShoot($tag: String!, $limit: Int) {
        photoSearch(query: $tag, limit: $limit) {
            items {
                ...PhotoListParts
            }
        }
    }
    ${PhotoListPartsFragmentDoc}
`;
