import styled, { css } from "styled-components";

// styles
import colors from "core/colors";
import breakpoints from "core/breakpoints";
import { convertPxToRem } from "frontend/components/helpers";
import {
    textLg,
    regular as regularFontWeight,
    medium as mediumFontWeight,
    bold as boldFontWeight
} from "core/fontStyles";
import { handleHeadingSize } from "js/helpers/spacingAndFonts";
import { getLinkElementStyles } from "core/Link/styles";
import { H3 } from "core/Typography/index.js";
import { Image as ExternalLinkIcon } from "core/ReusableIcons/ExternalLink.js";

// components
import TextWithPopOutLink from "./TextWithPopOutLink";

// styled-components
export const TemplateStyled = styled.div`
    ${({ width }) => {
        if (width) {
            return css`
                width: ${convertPxToRem(width)};
            `;
        }
    }}
`;
export const TemplateHeaderStyled = styled(H3).attrs({})`
    font-size: ${convertPxToRem(12)};
    ${boldFontWeight};
`;
export const TemplateComponentStyled = styled.div`
    font-size: ${convertPxToRem(15)};
    padding-top: 4px;
    ${regularFontWeight};
`;

// ContentComponentRow
export const ContentComponentRowStyled = styled.div`
    display: inline-flex;
`;

// Shared by many
export const TextWithPopOutLinkStyled = styled.div`
    display: flex;
    align-items: flex-start;

    span {
        vertical-align: top;
    }

    a {
        display: inline-block;
        height: 16px;
        line-height: 100%;
        margin: 0px;
        padding: 0px;
        margin-left: 8px;
        ${ExternalLinkIcon} {
            path {
                fill: ${colors.accent.lightMode.persian};
            }
        }
    }
`;

// BatchID
export const BatchIDContainerStyled = styled.div`
    display: inline-flex;
    align-items: center;
`;
export const FlagStyled = styled.div`
    margin-right: ${convertPxToRem(8)};
`;

// Contributor
export const ContributorStyled = styled.div`
    display: flex;
    align-items: flex-start;
    padding-right: 10px;

    img {
        border-radius: 50%;
        position: relative;
        top: -5px;
        margin-right: 5px;
        width: ${convertPxToRem(24)};
        height: ${convertPxToRem(24)};
    }
`;

// Fully Published
export const FullyPublishedStyled = styled(TextWithPopOutLink)`
    width: ${convertPxToRem(69)};
`;
