import React from "react";
import {
    LocationDropdownBox,
    LocationDropdownContainer,
    LocationListItem
} from "./styles";
import BodyText from "core/BodyText";

const SuggestedLocations = ({
    dropdownRef,
    suggestedLocations,
    handleConfirm,
    focusedItem
}) => {
    return (
        <LocationDropdownContainer ref={dropdownRef}>
            <LocationDropdownBox>
                <ul role="listbox">
                    {suggestedLocations.map((location, idx) => {
                        const { formattedLocation } = location;
                        return (
                            <LocationListItem
                                key={idx}
                                aria-label={formattedLocation}
                                role="option"
                                onClick={() => handleConfirm(location)}
                                isHovered={idx === focusedItem}
                            >
                                <BodyText size="md">
                                    {formattedLocation}
                                </BodyText>
                            </LocationListItem>
                        );
                    })}
                </ul>
            </LocationDropdownBox>
        </LocationDropdownContainer>
    );
};

export default SuggestedLocations;
