/*
 * Auth Hook
 * ---------
 *
 * Most common props to grab from useAuth:
 *
 *   const { isLoggedIn, currentUser, confirmAuth } = useAuth();
 *
 * Example where we hide something if a user is not logged in:
 *
 *   const { isLoggedIn } = useAuth();
 *   if (isLoggedIn) {
 *     return <div>Secret stuff!</div>
 *   }
 *   return <div>Public stuff</div>
 *
 * If you want to protect an entire screen, use <ProtectedRoute> component in routes.js.
 *
 * Example where we check the user is logged in before doing something:
 *
 *   const { confirmAuth } = useAuth();
 *   const [ foo, setFoo ] = useState(false);
 *   const onClick = () => {
 *     confirmAuth(() => setFoo(true))
 *   }
 *   return <button onClick={onClick}>Click Me!</button>
 *
 * Similar example, but show the login screen first instead of the signup screen:
 *
 *   import { LOGIN_MODE, useAuth } from "frontend/hook/useAuth";
 *   ...
 *   const { confirmAuth } = useAuth();
 *   const [ foo, setFoo ] = useState(false);
 *   const onClick = () => confirmAuth({
 *     mode: LOGIN_MODE,
 *     onSuccess: () => setFoo(true)
 *   })
 *   return <button onClick={onClick}>Click Me!</button>
 *
 * If your `onSuccess` logic depends on the currentUser object after logging in, use:
 *
 *   confirmAuth({
 *     onSuccess(currentUser) {
 *       ...
 *     }
 *   })
 *
 * If you would like to redirect the user to the home page if they refuse to login:
 *
 *   confirmAuth({ redirectToHomeOnCancel: true });
 *
 * Or, add custom logic if they refuse to login:
 *
 *   const { confirmAuth } = useAuth();
 *   const [ foo, setFoo ] = useState(false);
 *   const onClick = () => confirmAuth({
 *     onSuccess: () => setFoo(true),
 *     onCancel: () => setFoo(false)
 *   })
 *   return <button onClick={onClick}>Click Me!</button>
 *
 */
import React, {
    useState,
    useEffect,
    useCallback,
    useContext,
    createContext
} from "react";
import queryString from "query-string";
import { useDispatch } from "react-redux";

import {
    SOCIAL_LOGIN_TRY_AGAIN,
    SIGNUP_TYPE,
    NOUN_PRO_TEAM_INFO_PAGE,
    SOCIAL_SIGNUP
} from "js/data/links";
import { TEAM_YEARLY_PLAN } from "frontend/components/subscriptions/data";

import { useCurrentUserContext } from "contexts/CurrentUserContext";
import { setUser } from "frontend/store/user";
import { getCsrfTokenFromCookie } from "helpers/csrfToken";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState(null);

    return (
        <AuthContext.Provider value={{ auth, setAuth }}>
            {children}
        </AuthContext.Provider>
    );
};

export const SIGNUP_MODE = 1;
export const LOGIN_MODE = 2;
export const FORGOT_PASSWORD_MODE = 3;
export const EMAILED_PASSWORD_MODE = 4;
export const SOCIAL_TRY_AGAIN_MODE = 5;

export const useAuth = () => {
    const {
        currentUser: { loading: isLoading, data }
    } = useCurrentUserContext();
    const currentUser = data?.currentUser;
    const isLoggedIn = !!currentUser?.email;

    const { auth, setAuth } = useContext(AuthContext);
    const authMode = (auth && auth.mode) || null;
    const isAuthFormRendered = !!authMode;

    /*
     * Pass in either a callback or an object:
     *   { mode, redirectToHomeOnCancel, onSuccess, onCancel }
     * Passing in a callback will be treated as a shortcut for { onSuccess }.
     * Default mode is signup. LOGIN_MODE / SIGNUP_MODE are exported from this
     * file in case you want to customize it.
     */
    const confirmAuth = useCallback(
        props => {
            /* NOTE:
             * Because we sometimes have extra renders in components, it's possible
             * for a component to invoke confirmAuth multiple times. If the user has
             * navigated to the reset password mode, an extra confirmAuth() could take
             * them back to the login screen accidentally.
             * Thus, confirmAuth will stop itself if the modal is already showing.
             */
            if (isAuthFormRendered) {
                return;
            }
            let mode, redirectToHomeOnCancel, onSuccess, onCancel;
            if (typeof props === "function") {
                mode = SIGNUP_MODE;
                redirectToHomeOnCancel = false;
                onSuccess = props;
            } else if (props && typeof props === "object") {
                ({
                    mode = SIGNUP_MODE,
                    redirectToHomeOnCancel = false,
                    onSuccess,
                    onCancel
                } = props);
            }

            if (!isLoggedIn) {
                setAuth({ mode, redirectToHomeOnCancel, onSuccess, onCancel });
            } else if (isLoggedIn && typeof onSuccess === "function") {
                onSuccess(currentUser);
            }
        },
        [isAuthFormRendered, isLoggedIn]
    );

    return {
        authMode,
        isLoading,
        isLoggedIn,
        currentUser,
        confirmAuth
    };
};

// Should only be used by auth modals
export const useAuthModal = () => {
    const {
        currentUser: { refetch: refetchUser }
    } = useCurrentUserContext();
    const { auth, setAuth } = useContext(AuthContext);
    const { authMode, isLoggedIn, currentUser } = useAuth();
    const dispatch = useDispatch();

    const redirectToHomeOnCancel =
        (auth && auth.redirectToHomeOnCancel) || false;
    const onSuccess = (auth && auth.onSuccess) || null;
    const onCancel = (auth && auth.onCancel) || null;
    const setAuthMode = useCallback(
        mode => {
            setAuth({ mode, redirectToHomeOnCancel, onSuccess, onCancel });
        },
        [redirectToHomeOnCancel, onSuccess, onCancel]
    );

    const cancelAuth = useCallback(() => {
        if (redirectToHomeOnCancel) {
            window.location.href = "/";
            return;
        }
        if (typeof onCancel === "function") {
            onCancel();
        }
        setAuth(null);
    }, [setAuth, redirectToHomeOnCancel, onCancel]);

    const [isRefetching, setIsRefetching] = useState(false);

    // wait until refetch is complete before invoking callbacks & closing the modal
    useEffect(() => {
        if (isLoggedIn && isRefetching) {
            setIsRefetching(false);
            // callback for the component that triggered the auth check
            if (typeof onSuccess === "function") {
                onSuccess(currentUser);
            }
            // update user in SSR store
            dispatch(setUser(currentUser));
            // close modal
            setAuth(null);
        }
    }, [isLoggedIn, isRefetching, onSuccess]);

    const afterAuth = useCallback(() => {
        refetchUser();
        setIsRefetching(true);
    }, [refetchUser]);

    // If using FB to login / signup
    window._nounAfterAuth = afterAuth;
    window._nounCancelAuth = useCallback(() => {
        setAuthMode(SOCIAL_TRY_AGAIN_MODE);
    }, []);

    return { authMode, setAuthMode, afterAuth, cancelAuth };
};

// Should only be used by standalone auth pages
export const useAuthPage = () => {
    const { isLoggedIn } = useAuth();

    const afterAuth = useCallback(() => {
        const params = queryString.parse(window.location.search);
        // after team signup, navigate to page to create team
        const TEAM_SIGNUP = SIGNUP_TYPE.replace(":signupType", "team");
        if (!params.next && window.location.pathname === TEAM_SIGNUP) {
            const TEAM_SETTINGS =
                NOUN_PRO_TEAM_INFO_PAGE.replace(":planType", TEAM_YEARLY_PLAN) +
                window.location.search;
            window.location.replace(TEAM_SETTINGS);
        } else {
            window.location.replace(params.next || "/");
        }
    }, []);

    // If using FB to login / signup
    window._nounAfterAuth = afterAuth;
    window._nounCancelAuth = useCallback(() => {
        window.location.replace(
            SOCIAL_LOGIN_TRY_AGAIN + window.location.search
        );
    }, []);

    // If the user is already logged in, redirect them to the next page
    useEffect(() => {
        if (isLoggedIn) {
            afterAuth();
        }
    }, [isLoggedIn]);

    return {
        afterAuth
    };
};

// Login or Signup with Facebook using a popup window.
export const openSocialAuth = () => {
    const queries = window.location.search || encodeURIComponent("/");
    window.open("", "facebook-login");

    const form = document.createElement("form");
    form.method = "POST";
    form.action = `/accounts/facebook/login/?next=${queries}`;
    form.target = "facebook-login";

    const csrfInput = document.createElement("input");
    csrfInput.type = "hidden";
    csrfInput.name = "csrfmiddlewaretoken";
    csrfInput.value = getCsrfTokenFromCookie();
    form.appendChild(csrfInput);

    document.body.appendChild(form);
    form.submit();
    form.remove();
};

// Let the parent window know when auth is complete.
export const afterSocialAuth = next => {
    // facebook auth happens in tab, close when it is done
    if (window.opener && typeof window.opener._nounAfterAuth === "function") {
        try {
            window.opener._nounAfterAuth();
            window.close();
        } catch (_) {
            // if parent window is not accessible, redirect to home
            window.location.replace("/");
        }

        return;
    } else {
        // google auth
        window.location = !next || next === SOCIAL_SIGNUP ? "/" : next;
    }
};

// Let the parent window know when auth is cancelled.
export const cancelSocialAuth = () => {
    if (window.opener && typeof window.opener._nounCancelAuth === "function") {
        window.opener._nounCancelAuth();
        window.close();
    } else {
        window.location.replace(
            SOCIAL_LOGIN_TRY_AGAIN + window.location.search
        );
    }
};
