import styled from "styled-components";

// styles
import Heading from "core/Heading";
import colors from "core/colors";
import { convertPxToRem } from "frontend/components/helpers";
import { regular as regularFontWeight } from "core/fontStyles";

// styled-components
export const LocationStyled = styled.div`
    margin-top: ${convertPxToRem(24)};
`;

/*---------------------------
| Reasons for Denial
---------------------------*/
export const ReasonsForDenialStyled = styled.div`
    margin-top: ${convertPxToRem(16)};
`;
export const ReasonsForDenialTitleStyled = styled(Heading)`
    font-size: ${convertPxToRem(11)};
    margin-bottom: ${convertPxToRem(16)};
    color: ${colors.secondary.orca};
`;

export const ReasonUlStyled = styled.ul`
    list-style-type: disc;
    padding-left: ${convertPxToRem(20)};
`;
export const ReasonLiStyled = styled.li`
    font-size: ${convertPxToRem(15)};
    margin-bottom: ${convertPxToRem(8)};
    ${regularFontWeight}
    color: ${colors.secondary.orca};
`;
