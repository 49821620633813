import React from "react";
import Loading from "./loading";
import callApiPromise from "../helpers/call-api-promise";
import { connect } from "react-redux";

function loadScript(src) {
    return new Promise(function(resolve, reject) {
        var script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function() {
            resolve();
        });
        script.addEventListener("error", function(e) {
            reject(e);
        });
        document.body.appendChild(script);
    });
}

global.captchaSetLoaded = () => {
    global.CaptchaLoaded = true;
};

class CaptchaUnwrapped extends React.PureComponent {
    constructor(props) {
        super(props);
        this.captchaContainer = React.createRef();
    }
    state = {
        success: false,
        loading: true,
        score: null
    };
    onSuccessCaptcha = token => {
        console.log("onssucccess", token);

        if (this.props.handleToken) {
            this.props.handleToken(token);
            return;
        }
    };
    onErrorCaptcha = e => {
        console.log("onerror", e);
        this.setState({
            error: true
        });
    };
    onExpireCaptcha = e => {
        console.log("onexpire", e);
        this.setState({
            loading: true,
            error: false,
            success: false
        });
    };

    componentDidMount() {
        let scriptLoaded = !!global.hcaptcha
            ? Promise.resolve()
            : loadScript(`https://js.hcaptcha.com/1/api.js?render=explicit`);

        scriptLoaded
            .then(() => {
                if (global.hcaptcha) {
                    // eslint-disable-next-line no-undef
                    hcaptcha.render(this.captchaContainer.current, {
                        sitekey: this.props.captchaKey,
                        callback: this.onSuccessCaptcha,
                        "error-callback": this.onErrorCaptcha,
                        "expired-callback": this.onExpireCaptcha
                    });
                } else {
                    this.setState({
                        error: true
                    });
                }
            })
            .catch(e => {
                console.error(e);
                this.setState({
                    error: true
                });
            });
    }
    render() {
        let content;
        if (this.state.success) {
            content = this.props.children;
        } else if (this.state.error) {
            content = (
                <div className="current-plan warning">
                    Something went wrong, please disable any active browser
                    extensions, reload the page and try again. If you continue
                    to have problems, let us know at{" "}
                    <a href="mailto:info@thenounproject.com" target="_blank">
                        info@thenounproject.com
                    </a>
                </div>
            );
        } else if (this.state.loading) {
            content = null;
        } else {
            content = <div className="current-plan warning">You are robot</div>;
        }
        return (
            <React.Fragment>
                {content}
                <div
                    data-testid="captcha-container"
                    ref={this.captchaContainer}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    // Legacy redux store has `auth`, and new store has `config`
    const { captchaKey } = state.config || state.auth;
    return { captchaKey };
};

export const Captcha = connect(mapStateToProps)(CaptchaUnwrapped);
