import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import WarningIcon from "core/ReusableIcons/Warning";
import colors from "core/colors";

const Image = styled.img`
    width: 100%;
`;

const ErrorContainer = styled.div`
    display: flex;
    background-color: ${colors.secondary.ridge};
    justify-content: center;
    &:before {
        content: "";
        display: block;
        padding-top: 100%;
    }
`;

const ErrorCentered = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    svg {
        margin-bottom: 1rem;
    }
`;

const RefreshingImage = ({
    src,
    alt,
    fetchNewUrl,
    imageProps,
    maxRefreshAttempts = 3,
    className // https://styled-components.com/docs/advanced#existing-css
}) => {
    const [refetchCount, setRefetchCount] = useState(0);

    const handleImageError = () => {
        if (refetchCount >= maxRefreshAttempts) return;

        setRefetchCount(refetchCount + 1);
        fetchNewUrl();
    };

    useEffect(() => {
        setRefetchCount(0);
    }, [src, setRefetchCount]);

    // stop attempting to fetch image after 3 tries
    if (refetchCount >= maxRefreshAttempts) {
        return (
            <ErrorContainer>
                <ErrorCentered>
                    <WarningIcon
                        size="xl"
                        fillColor={colors.secondary.oolong}
                        title="Error loading image"
                    />
                    <p>Error loading image</p>
                </ErrorCentered>
            </ErrorContainer>
        );
    }

    return (
        <Image
            className={className}
            src={src}
            alt={alt}
            onError={handleImageError}
            {...imageProps}
        />
    );
};

RefreshingImage.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    fetchNewUrl: PropTypes.func.isRequired,
    imageProps: PropTypes.object,
    maxRefreshAttempts: PropTypes.number,
    className: PropTypes.string
};

export default RefreshingImage;
