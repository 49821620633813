import React from "react";
import PropTypes from "prop-types";

import CircleButton from "core/CircleButton";
import ProcessingSpinner from "core/ProcessingSpinner";
import { CarouselBtnContainer, EmptyPlaceholder } from "./styles";

const CarouselButton = ({ title, icon, onClick, isDisabled, isProcessing }) => {
    if (isDisabled) {
        return <EmptyPlaceholder />;
    } else {
        // NOTE:
        // Avoid using `CircleButton` `isProcessing` attribute; if used, clicking
        // the CarouselButton while processing will accidentally close the modal.
        return (
            <CarouselBtnContainer>
                <CircleButton
                    title={title}
                    icon={isProcessing ? <ProcessingSpinner /> : icon}
                    styleType="secondary"
                    size="md"
                    onClick={e => {
                        e.stopPropagation(); // avoid bubbling event to outside click handler (closes modal)
                        if (isProcessing) {
                            return e.preventDefault();
                        }
                        onClick(e);
                    }}
                />
            </CarouselBtnContainer>
        );
    }
};

CarouselButton.propTypes = {
    title: PropTypes.string,
    icon: PropTypes.element,
    onClick: PropTypes.func,
    isDisabled: PropTypes.bool,
    isProcessing: PropTypes.bool
};

export default CarouselButton;
