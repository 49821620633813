import React from "react";
import { useRouteMatch } from "react-router-dom";

import Menu from "../../../core/Menu";

import { getMenuGroups } from "./menuGroups.js";

const PhotoModHomeMenu = () => {
    const { url } = useRouteMatch();
    return <Menu menuGroups={getMenuGroups(url)} />;
};

export default PhotoModHomeMenu;
