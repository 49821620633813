import React from "react";

import { PageWrapper } from "../styles";
import FacebookTryAgain from "../FacebookTryAgain";

import { LOGIN, SIGNUP } from "data/links";

const FacebookTryAgainPage = () => (
    <PageWrapper>
        <FacebookTryAgain
            loginClickHandler={{
                href: LOGIN
            }}
            signupClickHandler={{
                href: SIGNUP
            }}
        />
    </PageWrapper>
);

export default FacebookTryAgainPage;
