import gql from "graphql-tag";

export const REGISTER_RELEASE_UPLOAD = gql`
    mutation photoReleaseUpload($filenames: [String!]) {
        photoReleaseUpload(filenames: $filenames) {
            errors {
                filename
                reason
            }
            uploads {
                id
                url
                filename
            }
        }
    }
`;

export const SUBMIT_RELEASE = gql`
    mutation photoReleaseUploadFinishedNotification(
        $id: ID!
        $result: PhotoUploadResult!
    ) {
        photoReleaseUploadFinishedNotification(
            releaseUploadId: $id
            result: $result
        ) {
            ok
            error
        }
    }
`;

export const WITHDRAW_PHOTO_RELEASES = gql`
    mutation WithdrawPhotoReleases($photoReleaseIds: [ID!]!) {
        withdrawPhotoReleases(photoReleaseIds: $photoReleaseIds) {
            ok
            releases {
                ok
                errors
                photoReleaseId
                photoRelease {
                    id
                    originalFilename
                    status
                }
            }
        }
    }
`;

export const REFRESH_RELEASE_UPLOAD_URLS = gql`
    mutation RefreshReleaseUploadUrls($releaseIds: [ID!]!) {
        getNewUploadUrlsForPhotoReleaseUploads(releaseIds: $releaseIds) {
            id
            filename
            url
        }
    }
`;
