import PropTypes from "prop-types";

export const batchUploadCollectionShape = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    collectionUploadIds: PropTypes.array.isRequired,
    activeIds: PropTypes.array.isRequired,
    savedForLaterIds: PropTypes.array.isRequired,
    view: PropTypes.string.isRequired,
    showCollectionTitle: PropTypes.bool.isRequired,
    supportedQueues: PropTypes.array.isRequired,
    showActiveAndSavedFilter: PropTypes.bool.isRequired
};
