import React from "react";
import PropTypes from "prop-types";

import { USER_SETTINGS, USER_EMAIL_SETTING } from "data/links";
import { useCurrentUser } from "contexts/CurrentUserContext";
import colors from "core/colors";

import {
    Container,
    Title,
    Message,
    Buttons,
    Button,
    IconContainer
} from "./styles";
import ResetIcon from "core/ReusableIcons/Reset";
import NameEmail from "core/IllustrativeIcons/NameEmail";

const AccountError = ({ missingName = false, missingEmail = false }) => {
    const user = useCurrentUser();
    const username = (user && user.username) || "";
    return (
        <Container>
            <Title>We need a few things to get started uploading.</Title>
            <Message>
                To become a creator, you must add a name to your profile and
                verify your email address.
            </Message>
            <Buttons>
                {missingName && (
                    <Button
                        href={USER_SETTINGS.replace(":username", username)}
                        target="_blank"
                    >
                        Add Name to Profile
                    </Button>
                )}
                {missingEmail && (
                    <Button href={USER_EMAIL_SETTING} target="_blank">
                        Verify Email Address
                    </Button>
                )}
                <Button
                    styleType="outline"
                    onClick={() => {
                        window.location.reload();
                    }}
                >
                    <ResetIcon />
                    Refresh
                </Button>
            </Buttons>
            <IconContainer>
                <NameEmail fillColor={colors.accent.lightMode.pansy} />
            </IconContainer>
        </Container>
    );
};

AccountError.propTypes = {
    missingName: PropTypes.bool,
    missingEmail: PropTypes.bool
};

export default AccountError;
