import styled from "styled-components";

import colors from "core/colors";
import breakpoints from "core/breakpoints";

import ImagePreventRightClick from "core/ImagePreventRightClick";

export const AnchorLink = styled.a``;

export const IconGridItemContainer = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: ${colors.primary.diamond};
    width: 100%;
    height: 100%;

    /* desktop */
    ${ImagePreventRightClick} {
        height: 61.475%;
        width: 61.475%;
    }

    /* tablet */
    @media (max-width: ${breakpoints.beforeDesktop.rem}) {
        ${ImagePreventRightClick} {
            height: 58.62%;
            width: 58.62%;
        }
    }

    /* mobile */
    @media (max-width: ${breakpoints.beforeTablet.rem}) {
        ${ImagePreventRightClick} {
            height: 45.58%;
            width: 45.58%;
        }
    }
`;
