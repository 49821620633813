import React, { useEffect } from "react";

// components
import SectionContainer from "core/SectionContainer";
import TextWithButton from "core/TextWithButton";
import IconsSection from "./IconsSection";
import Faq from "core/Faq";
import BlogPosts from "core/BlogPosts";
import TestimonialSection from "core/TestimonialSection";
import CollectionSpotlight from "core/CollectionSpotlight";
import OffsetGrid from "core/OffsetGrid";
import BulletSection from "core/BulletSection";
import PageMeta from "core/PageMeta";
import Edu from "core/ReusableIcons/Edu";

import CollectionSpotlightIMG from "./images/collectionSpotlightImg.png";

// styles
import colors from "core/colors";
import { Image } from "./styles";

// data
import { svgSizes } from "subscriptions/data";
import {
    metaTitle,
    metaDescription,
    heroTitle,
    heroSubtitle,
    heroButton,
    sectionHeading,
    bullets,
    collectionTitle,
    collectionText,
    collectionLink,
    blogPostsTitle,
    blogPostData,
    testimonial,
    faqTitle,
    faqValues
} from "./data";

const NounProEdu = () => {
    useEffect(() => {
        // We need to manually take control of the browsers scrollRestoration
        window.history.scrollRestoration = "manual";

        if (window.pageYOffset !== 0) {
            window.scrollTo(0, 0);
        }
    }, []);

    return (
        <>
            <PageMeta
                title={metaTitle}
                indexContent="index,follow"
                description={metaDescription}
            />
            <SectionContainer bgColor={colors.secondary.orca}>
                <Image>
                    <Edu size={svgSizes} fillColor={colors.primary.diamond} />
                </Image>
                <OffsetGrid sm={10} md={10} lg={8}>
                    <TextWithButton
                        headingColor={colors.primary.diamond}
                        subtextColor={colors.primary.diamond}
                        headingAlignment="center"
                        headingType="h1"
                        headingSize="3xl"
                        text={heroTitle}
                        subtext={heroSubtitle}
                        buttons={heroButton}
                        buttonAlignment="center"
                    />
                </OffsetGrid>
            </SectionContainer>

            <SectionContainer bgColor={colors.primary.diamond}>
                <OffsetGrid sm={10} md={12} lg={12}>
                    <BulletSection
                        bullets={bullets}
                        sectionHeading={sectionHeading}
                    />
                </OffsetGrid>
            </SectionContainer>

            <SectionContainer>
                <OffsetGrid sm={10} md={8} lg={10}>
                    <CollectionSpotlight
                        image={CollectionSpotlightIMG}
                        imageAlt={collectionTitle}
                        collectionHeading={collectionTitle}
                        collectionText={collectionText}
                        collectionLink={collectionLink}
                        headingSize="md"
                    />
                </OffsetGrid>
            </SectionContainer>

            <SectionContainer bgColor={colors.primary.diamond}>
                <TestimonialSection testimonials={testimonial} />
            </SectionContainer>

            <SectionContainer>
                <BlogPosts
                    posts={blogPostData}
                    blogPostsTitle={blogPostsTitle}
                />
            </SectionContainer>

            <IconsSection bgColor={colors.primary.diamond} eduPricingLayout />

            <SectionContainer>
                <OffsetGrid sm={10} md={12} lg={12}>
                    <Faq title={faqTitle} values={faqValues} />
                </OffsetGrid>
            </SectionContainer>
        </>
    );
};

export default NounProEdu;
