import React from "react";
import classnames from "classnames";
import isEqual from "lodash/isEqual";
import * as editorActions from "../../actions/editor";
import { connect } from "react-redux";
import { EditorTransforms } from "../../reducers/editor";

import cleanPreviewIcon from "../../helpers/clean_svg_string";
import shapes from "./editor-backgrounds";

class EditorRenderer extends React.Component {
    componentDidMount() {
        this.editorCacheSvg();
    }

    componentDidUpdate(prevProps) {
        if (!isEqual(this.props, prevProps)) {
            this.editorCacheSvg();
        }
    }

    // This function renders this component to a string when it is mounted in the DOM
    // Mostly this is useful for the test fixture where XSS is less of a concern.
    editorCacheSvg = () => {
        if (this.iconEl) {
            let sourceSvg;
            let svgEl = this.iconEl.cloneNode(true);
            let { exportSize } = this.props;
            if (exportSize) {
                svgEl.setAttribute(
                    "viewBox",
                    `0 0 ${exportSize} ${exportSize}`
                );
                svgEl.setAttribute("width", exportSize);
                svgEl.setAttribute("height", exportSize);
            }

            // Remove any extraneous foreignObjects to prevent tainting the canvas
            Array.prototype.forEach.call(
                svgEl.querySelectorAll("foreignObject"),
                function(node) {
                    node.parentNode.removeChild(node);
                }
            );

            if (svgEl.outerHTML) {
                sourceSvg = svgEl.outerHTML;
            } else {
                // IE svg element does not have outerHTML property
                let parser = document.createElement("div");
                parser.appendChild(svgEl);
                sourceSvg = parser.innerHTML;
                // IE adds extraneous attributes
                sourceSvg = sourceSvg.replace(`xmlns:NS1=""`, "");
                sourceSvg = sourceSvg.replace(
                    `NS1:xmlns:xlink="http://www.w3.org/1999/xlink"`,
                    ""
                );
                sourceSvg = sourceSvg.replace(
                    `xmlns="http://www.w3.org/2000/svg"`,
                    ""
                );
            }

            if (this.props.editorCacheSvg) {
                this.props.editorCacheSvg(sourceSvg);
            }
        }
    };

    getSvg() {
        let __html = this.props.svg
            ? cleanPreviewIcon(this.props.svg, this.props.color)
            : "";
        return {
            __html
        };
    }
    setIconEl = el => {
        this.iconEl = el;
    };

    render() {
        let className = classnames("nounproject-icon-editor-renderer");
        let {
            scaleX,
            scaleY,
            backgroundShape,
            backgroundColor,
            color,
            exportSize,
            rotate
        } = this.props;

        let transform = "";

        if (exportSize) {
            let hWidth = exportSize / 2;
            let hHeight = exportSize / 2;
            let transformScale = `scale(${scaleX} ${scaleY})`;
            let transformRotate = `rotate(${rotate})`;

            transform = `translate(${hWidth} ${hHeight}) ${transformScale} ${transformRotate} translate(${-hWidth} ${-hHeight})`;
        }

        let Background;
        if (backgroundShape) {
            Background = shapes[backgroundShape];
        }
        let icon = (
            <React.Fragment>
                {Background && (
                    <Background
                        color={backgroundColor}
                        exportSize={exportSize}
                    />
                )}
                <g
                    transform={transform}
                    style={{
                        fill: color
                    }}
                    dangerouslySetInnerHTML={this.getSvg()}
                />
            </React.Fragment>
        );

        let width, height;
        width = height = exportSize || "100%";

        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width={width}
                height={height}
                viewBox={`0 0 ${exportSize} ${exportSize}`}
                ref={this.setIconEl}
            >
                {icon}
            </svg>
        );
    }
}

function mapStateToProps(state, props) {
    let { editor } = state;
    let {
        scaleX,
        scaleY,
        backgroundColor,
        backgroundShape,
        color,
        exportSize,
        rotate
    } = editor.transforms;
    return {
        scaleX,
        scaleY,
        backgroundColor,
        backgroundShape,
        color,
        exportSize,
        rotate
    };
}

export const UnwrappedEditorRenderer = EditorRenderer;

export default connect(mapStateToProps, {
    editorCacheSvg: editorActions.editorCacheSvg
})(EditorRenderer);
