import { useMutation } from "@apollo/client";
import { WITHDRAW_PHOTO_UPLOADS } from "../mutations";

const useWithdrawPhotoUpload = () => {
    const [withdrawPhotos] = useMutation(WITHDRAW_PHOTO_UPLOADS, {
        onCompleted: ({ withdrawPhotoUploads }) => {
            if (!withdrawPhotoUploads.ok) return;
        }
    });

    return { withdrawPhotos };
};

export default useWithdrawPhotoUpload;
