import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { alignments, bodyTextSizes, bodyTextTypes } from "core/props";

import { StyledBodyText } from "./styles";

const BodyTextElement = ({
    children,
    className,
    type,
    size,
    copyColor,
    alignment,
    bold = false,
    ...props
}) => (
    <StyledBodyText
        className={className}
        as={type}
        size={size}
        copyColor={copyColor}
        alignment={alignment}
        bold={bold}
        {...props}
    >
        {children}
    </StyledBodyText>
);

const BodyText = styled(BodyTextElement)``;

BodyText.propTypes = {
    type: PropTypes.oneOf(bodyTextTypes),
    size: PropTypes.oneOf(bodyTextSizes),
    alignment: PropTypes.oneOf(alignments),
    copyColor: PropTypes.string,
    bold: PropTypes.bool
};

export default BodyText;
