import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import colors from "core/colors";

const createHelpers = (width, height, css) => {
    // somehow sizes is ending up in markup, even if it is not a valid svg attribute
    // until we have a better solution, just render it empty, instead to '[Object object]'
    const sanitizeSizes = sizes =>
        Object.defineProperty(sizes, "toString", {
            value: () => "",
            enumerable: false
        });

    const getDimensions = (size, sizes) => {
        if (
            size &&
            typeof size.width === "number" &&
            typeof size.height === "number"
        ) {
            return size;
        }

        return size && sizes[size] ? sizes[size] : { width, height };
    };

    const getCss = (size, sizes, fillColor, fillColorRule, noStyles) => {
        if (noStyles) {
            return "";
        }

        const dimensions = getDimensions(size, sizes);
        const fillRule =
            fillColor && fillColorRule
                ? `${fillColorRule}{ fill: ${fillColor}; }`
                : "";
        return css`
            width: ${dimensions.width}px;
            height: ${dimensions.height}px;
            ${fillRule}
        `;
    };

    const propsToCss = ({ size, sizes, fillColor, fillColorRule, noStyles }) =>
        getCss(size, sizes, fillColor, fillColorRule, noStyles);

    return {
        getCss,
        getDimensions,
        propsToCss,
        sanitizeSizes
    };
};

const width = "40";
const height = "40";
const viewBox = "0 0 40 40";

const { getDimensions, getCss, propsToCss, sanitizeSizes } = createHelpers(
    width,
    height,
    css
);

const sizes = sanitizeSizes({
    xs: { width: 14, height: 14 },
    sm: { width: 16, height: 16 },
    md: { width: 20, height: 20 },
    lg: { width: 24, height: 24 },
    xl: { width: 40, height: 40 }
});

const Image = styled.svg`
    ${propsToCss}
`;

const children = (
    <Fragment>
        <path
            fill={colors.functional.darkMode.marshmallow}
            d="M20 30.6807C21.1046 30.6807 22 29.7852 22 28.6807C22 27.5761 21.1046 26.6807 20 26.6807C18.8954 26.6807 18 27.5761 18 28.6807C18 29.7852 18.8954 30.6807 20 30.6807Z"
        />
        <path
            fill={colors.functional.darkMode.marshmallow}
            d="M19.8005 13.0799C18.5605 13.1999 17.6405 14.2799 17.7605 15.5199L18.5205 23.7199C18.6005 24.4399 19.1605 24.9999 19.8805 25.0799C20.7205 25.1599 21.4405 24.5599 21.5205 23.7199L22.2805 15.5199C22.2805 15.3999 22.2805 15.2399 22.2805 15.1199C22.1205 13.8799 21.0405 12.9599 19.8005 13.0799Z"
        />
        <path
            fill={colors.functional.darkMode.marshmallow}
            d="M38.2018 28.4002L24.7618 5.9202C23.7618 4.2002 21.9618 3.2002 20.0018 3.2002C18.0418 3.2002 16.2418 4.2002 15.2418 5.9202L1.80176 28.4002C0.761755 30.1602 0.721755 32.2402 1.72176 34.0002C2.72176 35.7602 4.52176 36.8002 6.56176 36.8002H33.4018C35.4418 36.8002 37.2418 35.7602 38.2418 34.0002C39.2818 32.2402 39.2418 30.1202 38.2018 28.4002ZM35.4418 32.4002C35.0418 33.1202 34.2818 33.5602 33.4418 33.5602H6.56176C5.72176 33.5602 4.96176 33.1202 4.56176 32.4002C4.16176 31.6802 4.16176 30.8002 4.60176 30.0802L18.0018 7.5602C18.4418 6.8402 19.1618 6.4402 20.0018 6.4402C20.8418 6.4402 21.5618 6.8402 22.0018 7.5602L35.4418 30.0402C35.8418 30.8002 35.8418 31.6402 35.4418 32.4002Z"
        />
    </Fragment>
);

const defaultProps = {
    children,
    viewBox,
    fillColor: null,
    fillColorRule: "&&& path, &&& use, &&& g, &&& circle",
    sizes,
    size: null
};

Image.propTypes /* remove-proptypes */ = {
    fillColor: PropTypes.string,
    fillColorRule: PropTypes.string,
    viewBox: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    size: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            height: PropTypes.number.isRequired,
            width: PropTypes.number.isRequired
        })
    ]),
    sizes: PropTypes.shape({
        height: PropTypes.number,
        width: PropTypes.number
    })
};

export default Object.assign(Image, {
    getDimensions,
    getCss,
    defaultProps,
    displayName: "WarningTriangle"
});
