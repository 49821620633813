import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import colors from "core/colors";

const createHelpers = (width, height, css) => {
    // somehow sizes is ending up in markup, even if it is not a valid svg attribute
    // until we have a better solution, just render it empty, instead to '[Object object]'
    const sanitizeSizes = sizes =>
        Object.defineProperty(sizes, "toString", {
            value: () => "",
            enumerable: false
        });

    const getDimensions = (size, sizes) => {
        if (
            size &&
            typeof size.width === "number" &&
            typeof size.height === "number"
        ) {
            return size;
        }

        return size && sizes[size] ? sizes[size] : { width, height };
    };

    const getCss = (size, sizes, fillColor, fillColorRule, noStyles) => {
        if (noStyles) {
            return "";
        }

        const dimensions = getDimensions(size, sizes);
        const fillRule =
            fillColor && fillColorRule
                ? `${fillColorRule}{ fill: ${fillColor}; }`
                : "";
        return css`
            width: ${dimensions.width}px;
            height: ${dimensions.height}px;
            ${fillRule}
        `;
    };

    const propsToCss = ({ size, sizes, fillColor, fillColorRule, noStyles }) =>
        getCss(size, sizes, fillColor, fillColorRule, noStyles);

    return {
        getCss,
        getDimensions,
        propsToCss,
        sanitizeSizes
    };
};

// Must match icon to its dimensions in order to achieve full icon size (matching other icons)
const width = "10";
const height = "13";
const viewBox = "0 0 10 13";

const { getDimensions, getCss, propsToCss, sanitizeSizes } = createHelpers(
    width,
    height,
    css
);

const sizes = sanitizeSizes({
    xs: { width: 14, height: 14 },
    sm: { width: 16, height: 16 },
    md: { width: 20, height: 20 },
    lg: { width: 24, height: 24 },
    xl: { width: 40, height: 40 }
});

export const Image = styled.svg`
    ${propsToCss}
`;

const children = (
    <Fragment>
        <path
            fill={colors.secondary.orca}
            d="M11.2776 6.90638V6.94236C11.2764 6.97875 11.2715 7.0149 11.2632 7.05035C11.256 7.06476 11.256 7.07197 11.2488 7.08632C11.2339 7.12171 11.2119 7.15365 11.184 7.17996C11.184 7.18716 11.1696 7.20158 11.1624 7.20878C10.2023 8.03167 8.99774 8.51501 7.73519 8.58398C7.42711 8.58633 7.11989 8.55006 6.82078 8.47594C6.35771 8.35535 5.90114 8.21116 5.45279 8.04398C4.40473 7.54078 3.20309 7.46086 2.09759 7.82074L2.64479 13.2063C2.65493 13.3008 2.62727 13.3954 2.56798 13.4696C2.50862 13.5438 2.42237 13.5915 2.32798 13.6024H2.28479C2.19602 13.6027 2.11036 13.5695 2.04497 13.5094C1.97959 13.4494 1.93921 13.3669 1.93201 13.2784L1.35604 7.62636C1.35012 7.60772 1.34766 7.58827 1.34883 7.56882V7.56161L0.722399 1.41281V1.37683C0.718824 1.32393 0.731364 1.27119 0.758375 1.2256C0.792242 1.15494 0.84773 1.09693 0.916805 1.06002C0.937781 1.04906 0.959401 1.03945 0.981609 1.03119C1.63568 0.780286 2.33366 0.663691 3.03391 0.688471C3.73405 0.713197 4.42205 0.878731 5.05681 1.17521C5.48806 1.33628 5.92792 1.47333 6.37441 1.58559C8.13121 2.03202 9.81601 0.692791 10.032 0.520006L10.0608 0.491178C10.0875 0.465396 10.1194 0.445768 10.1544 0.433581C10.1733 0.420046 10.1959 0.412546 10.2192 0.412018C10.2323 0.403991 10.2471 0.399069 10.2624 0.397604H10.2984C10.3379 0.397135 10.377 0.404459 10.4136 0.419225H10.4208C10.4511 0.433288 10.48 0.450163 10.5072 0.469616C10.5295 0.482448 10.5491 0.499558 10.5648 0.520007C10.5881 0.541687 10.6054 0.568933 10.6152 0.599225C10.6343 0.623951 10.6444 0.654362 10.644 0.685592C10.653 0.695436 10.6581 0.708268 10.6584 0.721627V0.757603L11.2776 6.90638Z"
        />
    </Fragment>
);

const defaultProps = {
    children,
    viewBox,
    fillColor: null,
    fillColorRule: "&&& path, &&& use, &&& g, &&& circle",
    sizes,
    size: null
};

Image.propTypes /* remove-proptypes */ = {
    fillColor: PropTypes.string,
    fillColorRule: PropTypes.string,
    viewBox: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    size: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            height: PropTypes.number.isRequired,
            width: PropTypes.number.isRequired
        })
    ]),
    sizes: PropTypes.shape({
        height: PropTypes.number,
        width: PropTypes.number
    })
};

export default Object.assign(Image, {
    getDimensions,
    getCss,
    defaultProps,
    displayName: "Flag"
});
