import React from "react";
import PropTypes from "prop-types";

import { TabContainer, TabText } from "./styles";
import colors from "core/colors";
import breakpoints from "core/breakpoints";

import { useViewport } from "js/hooks/useViewport";

const Tab = ({ icon, isActive, handleClick, children }) => {
    const { browserWidth } = useViewport();
    const isMobile = browserWidth <= breakpoints.beforeTablet.px;
    const Icon = icon;

    const ACTIVE_COLOR = colors.secondary.orca;
    const INACTIVE_COLOR = colors.secondary.oolong;

    return (
        <TabContainer
            isActive={isActive}
            onClick={e => {
                e.preventDefault();
                handleClick();
            }}
        >
            {!isMobile && (
                <Icon
                    fillColor={isActive ? ACTIVE_COLOR : INACTIVE_COLOR}
                    size="md"
                />
            )}
            <TabText
                bold={isActive}
                copyColor={isActive ? ACTIVE_COLOR : INACTIVE_COLOR}
            >
                {children}
            </TabText>
        </TabContainer>
    );
};

Tab.propTypes = {
    icon: PropTypes.elementType.isRequired,
    isActive: PropTypes.bool,
    handleClick: PropTypes.func.isRequired,
    elementType: PropTypes.string,
    children: PropTypes.node.isRequired
};

export default Tab;
