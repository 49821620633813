import React from "react";
import PropTypes from "prop-types";

// styles
import { HistoryListItemStyled, DateStyled, MessageStyled } from "./styles.js";

const convertTimestamp = timestamp => {
    const date = new Date(timestamp);

    const pad = num => (num < 10 ? "0" + num : num);

    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1); // Months are zero-indexed
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());

    return `${month}.${day}.${year} ${hours}:${minutes}`;
};

const HistoryList = ({ moderationNotes }) => {
    return (
        <div>
            {moderationNotes.map(note => {
                return <HistoryListItem key={note.id} note={note} />;
            })}
        </div>
    );
};

const HistoryListItem = ({ note }) => {
    const { id, action, actorDisplayName, timestamp } = note;

    const message = `${actorDisplayName} ${action}`;

    return (
        <HistoryListItemStyled data-testid={id}>
            <DateStyled>{convertTimestamp(timestamp)}</DateStyled>
            <MessageStyled>{message}</MessageStyled>
        </HistoryListItemStyled>
    );
};

export default HistoryList;

// prop-types
HistoryList.propTypes = {
    moderationNotes: PropTypes.array
};
HistoryListItem.propTypes = {
    note: PropTypes.object.isRequired
};
