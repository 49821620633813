import React from "react";
import styled, { css } from "styled-components";

// context
import { useSearchBar } from "core/SiteHeaderV2/components/SearchBar/context/useSearchBar.js";

// components
import Search from "core/ReusableIcons/Search";

// styles
import { regular } from "core/fontStyles";
import colors from "core/colors";
import { convertPxToRem } from "frontend/components/helpers";
import { UnstyledButton } from "core/Button/styles";

const SearchButtonStyled = styled(UnstyledButton)`
    flex: 0 0 ${convertPxToRem(135)};
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${colors.secondary.orca};
    color: ${colors.primary.diamond};

    ${({ $isDarkMode }) => {
        if ($isDarkMode) {
            return css`
                color: ${colors.secondary.orca};
                background-color: ${colors.accent.darkMode.suenosBuenos};
            `;
        }
    }}
`;

const TextStyled = styled.span`
    ${regular}
    display: inline-block;
    font-size: ${convertPxToRem(15)};
    margin-right: ${convertPxToRem(8)};
`;

// component
export const SearchButton = () => {
    const { isDarkMode } = useSearchBar();
    return (
        <SearchButtonStyled $isDarkMode={isDarkMode} type="submit">
            <TextStyled>Search</TextStyled>
            <Search
                fillColor={
                    isDarkMode ? colors.secondary.orca : colors.primary.diamond
                }
                size={{ width: 16, height: 16 }}
            />
        </SearchButtonStyled>
    );
};

export default SearchButton;
