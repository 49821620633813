import React from "react";

import defer from "lodash/defer";
import includes from "lodash/includes";
import forEach from "lodash/forEach";

import { MILLIONS_OF_ICONS } from "frontend/components/core/constants";
import * as actionTypes from "../actions/actionTypes";
import * as actions from "../actions/actions";
import * as Sentry from "@sentry/browser";
import {
    CONSUMING_PREPAID,
    ALACARTE_TYPE,
    PREPAID_TYPE,
    PRO_TYPE
} from "./stripe";

export const constToCSS = constant =>
    // eslint-disable-next-line no-useless-escape
    constant && constant.toLowerCase().replace(/[_\.]/g, "-");

export const phases = {
    INITIAL: "INITIAL",
    SELECT_LICENSE: "SELECT_LICENSE",
    PAYMENT: "PAYMENT",
    SELECT_PURCHASE_TYPE: "SELECT_PURCHASE_TYPE",
    UPSELL: "UPSELL",
    DOWNLOAD_READY: "DOWNLOAD_READY",
    USE_PREPAID: "USE_PREPAID"
};

export const DOWNLOAD_ENTER_PHASE = {};
forEach(phases, k => (DOWNLOAD_ENTER_PHASE[k] = `DOWNLOAD_ENTER_PHASE_${k}`));

export const phasesDisplay = {
    SELECT_LICENSE: "License",
    PAYMENT: "Payment",
    UPSELL: "Keep Edits",
    DOWNLOAD_READY: "Download"
};

export const licenses = {
    ROYALTY_FREE: "ROYALTY_FREE",
    CREATIVE_COMMONS: "CREATIVE_COMMONS",
    PUBLIC_DOMAIN: "PUBLIC_DOMAIN",
    NOUN_PRO: "NOUN_PRO",
    ALREADY_PURCHASED_ROYALTY_FREE: "ALREADY_PURCHASED_ROYALTY_FREE",
    PREPAID: "PREPAID"
};

export const singleIconPricePlaceholder = "{ICONPRICE}";
export const licensesDisplay = {
    ROYALTY_FREE: {
        title: "Pro Download",
        description: "No need to give credit",
        notes: [
            {
                className: "pro-blue ui_eye-dropper nowrap",
                text: "Fully customize icon color, rotation and background"
            },
            `${singleIconPricePlaceholder} royalty–free license`,
            `No need to attribute the icon,
your payment directly supports this creator.`
        ],
        thankYouTitle: "Thanks for your purchase!",
        thankYouText:
            "Your purchase supports the creator and you get to use it however you like."
    },
    CREATIVE_COMMONS: {
        title: "Basic Download",
        description: "Creative Commons",
        notes: [
            {
                className: "ui_close weight-medium color-black nowrap",
                text: "No customization features, download only in black"
            },
            "$0 Creative Commons license",
            `Must attribute the creator of this icon
per license requirements`
        ]
    },
    PUBLIC_DOMAIN: {
        title: "Public Domain",
        description: "Public Domain",
        notes: ["Customize Icon"]
    },
    NOUN_PRO: {
        thankYouTitle: "Thanks for signing up for NounPro!",
        description: "No need to attribute, you're NounPro",
        thankYouText: () => (
            <React.Fragment>
                Enjoy unlimited access to over {MILLIONS_OF_ICONS} million
                icons, and be sure to check out our{" "}
                <a target="_blank" href="/apps">
                    apps
                </a>
                !
            </React.Fragment>
        ),
        title: "Royalty-Free",
        notes: ["Customize Icon"]
    },
    ALREADY_PURCHASED_ROYALTY_FREE: {
        title: "Royalty-Free",
        description: "No need to give credit",
        notes: ["Customize Icon"]
    },
    PREPAID: {
        title: "Prepaid Icons",
        description: "No need to give credit",
        thankYouTitle: "Thanks for your purchase!",
        thankYouText:
            "Your purchase supports the creator and you get to use it however you like."
    }
};

export const steps = {
    [licenses.ROYALTY_FREE]: [
        phases.INITIAL,
        phases.SELECT_LICENSE,
        phases.SELECT_PURCHASE_TYPE,
        phases.PAYMENT,
        phases.DOWNLOAD_READY
    ],
    [licenses.CREATIVE_COMMONS]: [
        phases.INITIAL,
        phases.SELECT_LICENSE,
        phases.UPSELL,
        phases.DOWNLOAD_READY
    ],
    [licenses.NOUN_PRO]: [phases.DOWNLOAD_READY],
    [licenses.PUBLIC_DOMAIN]: [phases.DOWNLOAD_READY],
    [licenses.ALREADY_PURCHASED_ROYALTY_FREE]: [phases.DOWNLOAD_READY],
    [licenses.PREPAID]: [
        phases.INITIAL,
        phases.PAYMENT,
        phases.USE_PREPAID,
        phases.DOWNLOAD_READY
    ]
};

export const proLicenses = [
    licenses.NOUN_PRO,
    licenses.ALREADY_PURCHASED_ROYALTY_FREE,
    licenses.ROYALTY_FREE,
    licenses.PREPAID
];

export const formats = ["png", "svg"];

// Modal decision constants
export const NO_PREMIUM_FEATURES = "NO_PREMIUM_FEATURES";
export const YES_DO_UPSELL = "YES_DO_UPSELL";

// Constant for resuming flow after auth modal
export const DOWNLOAD_FLOW = "DOWNLOAD_FLOW";

export const licenseDescriptionToLicense = licenseDescription =>
    licenseDescription == "public-domain"
        ? licenses.PUBLIC_DOMAIN
        : licenses.CREATIVE_COMMONS;

const nextPhase = (status, license) => {
    if (!license) {
        license = licenses.ROYALTY_FREE;
    }

    let licenseFlow = steps[license];

    let currentIndex = licenseFlow.indexOf(status);

    let nextIndex = Math.min(licenseFlow.length - 1, currentIndex + 1);
    return licenseFlow[nextIndex];
};

const shouldUpsellForPhase = status => {
    return [phases.SELECT_LICENSE, phases.DOWNLOAD_READY].includes(status);
};

const shouldUpsellForLicense = license => {
    return !!license && license == licenses.CREATIVE_COMMONS;
};

const reducers = {
    [phases.INITIAL]: (state = {}, action) => {
        let { status, isPremiumUser } = state;
        switch (action.type) {
            case actionTypes.GET_CURRENT_USER_SUCCESS: {
                let { waitingForAuth, license } = state;
                if (state.active && waitingForAuth) {
                    status = nextPhase(status, license);
                    waitingForAuth = false;
                }

                let user = action.response;
                let loggedIn = !user.is_anonymous;

                return {
                    ...state,
                    waitingForAuth,
                    status,
                    license,
                    loggedIn
                };
            }
            case actionTypes.DOWNLOAD_SHOW_AUTH: {
                let { resumeFlow } = action;
                let waitingForAuth;
                if (resumeFlow == DOWNLOAD_FLOW) {
                    waitingForAuth = true;
                }
                return {
                    ...state,
                    waitingForAuth
                };
            }
            case actionTypes.DOWNLOAD_REQUEST: {
                let { user } = action;
                let { license } = state;

                let waitingForAuth;
                if (user && !user.is_anonymous) {
                    status = nextPhase(status, license);
                } else {
                    waitingForAuth = true;
                }

                return {
                    ...state,
                    waitingForAuth,
                    status
                };
            }
        }

        return { ...state, status };
    },
    [phases.SELECT_LICENSE]: (state, action) => {
        let { status } = state;

        switch (action.type) {
            case actionTypes.DOWNLOAD_SELECT_LICENSE: {
                let { license } = action;
                let error = null;
                if (
                    state.usingPremiumFeatures &&
                    license != licenses.ROYALTY_FREE
                ) {
                    let upsellRejectedPhase =
                        state.upsellRejectedPhase || phases.SELECT_LICENSE;
                    status = phases.UPSELL;
                    return {
                        ...state,
                        status,
                        license,
                        upsellRejectedPhase,
                        error
                    };
                }

                return {
                    ...state,
                    license,
                    status,
                    error
                };
            }
            case actionTypes.DOWNLOAD_COMMIT_LICENSE: {
                let { license, error } = state;
                if (license) {
                    status = nextPhase(status, license);
                } else {
                    error = "Please select a license.";
                }
                return {
                    ...state,
                    status,
                    error
                };
            }
        }

        return { ...state, status };
    },
    [phases.DOWNLOAD_READY]: (state, action) => {
        let { status, processing, error } = state;
        switch (action.type) {
            case DOWNLOAD_ENTER_PHASE.DOWNLOAD_READY: {
                let { license, isPremiumUser, iconBaseLicense } = state;

                if (iconBaseLicense == licenses.PUBLIC_DOMAIN) {
                    license = licenses.PUBLIC_DOMAIN;
                } else if (isPremiumUser) {
                    license = licenses.NOUN_PRO;
                }

                return {
                    license
                };
            }
            case actionTypes.DOWNLOAD_START: {
                processing = true;
                let { fileType } = action;
                return {
                    ...state,
                    status,
                    processing,
                    format: fileType
                };
            }
            case actionTypes.DOWNLOAD_START_FAILURE: {
                let { error } = action;
                console.log(error);
                if (typeof error != "string") {
                    error = "Something went wrong with your download.";
                }
                processing = false;
                let format = null;
                return {
                    ...state,
                    processing,
                    error,
                    format
                };
            }
            case actionTypes.DOWNLOAD_START_SUCCESS: {
                processing = true;
                error = null;

                let { response, imgColor, fileType } = action;
                let { download: fileUrl } = response;

                return {
                    ...state,
                    processing,
                    fileUrl,
                    error,
                    format: fileType
                };
            }
            case actionTypes.DOWNLOAD_COMPLETE_FAILURE: {
                processing = false;
                let { error } = action;
                console.log(error);
                if (typeof error != "string") {
                    error = "Something went wrong with your download.";
                }
                return {
                    ...state,
                    error,
                    processing,
                    format: null
                };
            }
            case actionTypes.DOWNLOAD_COMPLETE_SUCCESS: {
                processing = false;
                return {
                    ...state,
                    processing,
                    format: null,
                    error: null
                };
            }
        }

        return {
            ...state,
            status
        };
    },
    [phases.UPSELL]: (state, action) => {
        let {
            usingPremiumFeatures,
            license,
            status,
            upsellRejectedPhase
        } = state;
        switch (action.type) {
            case DOWNLOAD_ENTER_PHASE.UPSELL: {
                if (!usingPremiumFeatures) {
                    return {
                        ...state,
                        upsellRejectedPhase: null,
                        status: nextPhase(status, license)
                    };
                } else {
                    return {
                        ...state,
                        upsellRejectedPhase:
                            upsellRejectedPhase || nextPhase(status, license)
                    };
                }
            }
            case actionTypes.EDITOR_RESET:
            case actionTypes.EDITOR_TRANSFORMED: {
                if (!usingPremiumFeatures) {
                    return {
                        ...state,
                        status:
                            state.upsellRejectedPhase ||
                            nextPhase(status, state.license)
                    };
                }
                break;
            }

            case actionTypes.DOWNLOAD_UPSELL_ACCEPTED: {
                return {
                    ...state,
                    license: licenses.ROYALTY_FREE
                };
            }
            case actionTypes.DOWNLOAD_UPSELL_REJECTED: {
                return {
                    ...state,
                    license: licenses.CREATIVE_COMMONS
                };
            }
        }

        return state;
    },
    [phases.SELECT_PURCHASE_TYPE]: (state, action) => {
        let { status } = state;
        switch (action.type) {
            case DOWNLOAD_ENTER_PHASE.SELECT_PURCHASE_TYPE: {
                let newState = {
                    ...state,
                    upsellRejectedPhase: phases.DOWNLOAD_READY
                };
                return newState;
            }
            case actionTypes.DOWNLOAD_COMMIT_PURCHASE_TYPE: {
                let error;
                let { purchaseTypeKey } = action;
                if (purchaseTypeKey) {
                    let { license } = state;
                    status = nextPhase(status, license);
                    error = null;
                } else {
                    error = "Please select how you'd like to pay.";
                }

                return {
                    ...state,
                    status,
                    error
                };
            }
            case actionTypes.USER_GET_PREPAID_BALANCE_SUCCESS: {
                let { prepaidBalance, status } = state;
                if (prepaidBalance) {
                    status = phases.USE_PREPAID;
                    return {
                        ...state,
                        status
                    };
                }

                return state;
            }
            default:
                return state;
        }
    },
    [phases.PAYMENT]: (state, action) => {
        let { status, processing, error, license } = state;
        switch (action.type) {
            case DOWNLOAD_ENTER_PHASE.PAYMENT: {
                if (!global.Stripe) {
                    // Defer this until redux finishes processing the action as
                    // the Sentry middleware will try to call getState on the store
                    defer(() => {
                        Sentry.captureMessage(
                            "Stripe not available for download flow."
                        );
                    });
                    return {
                        error:
                            "Could not connect to Stripe, please disable any active browser extensions and reload the page. If the problem persists, let us know at info@thenounproject.com"
                    };
                }

                let { prepaidBalance } = state;
                if (prepaidBalance && license == licenses.PREPAID) {
                    // Skip the payment step if the user has a prepaid balance
                    status = nextPhase(status, license);
                    return {
                        ...state,
                        status
                    };
                }

                return state;
            }
            case actionTypes.DOWNLOAD_PURCHASE_START: {
                processing = true;
                error = null;
                return {
                    ...state,
                    processing,
                    error
                };
            }
            case actionTypes.DOWNLOAD_PURCHASE_END: {
                let { error } = action;
                if (!error) {
                    status = nextPhase(status, license);
                }

                processing = false;
                return {
                    ...state,
                    processing,
                    status,
                    error
                };
            }
            case actionTypes.DOWNLOAD_PURCHASE_SUCCESS: {
                let { purchase } = action;

                let {
                    isPremiumUser,
                    purchasedIcons,
                    license,
                    prepaidBalance,
                    status
                } = state;

                let hasPurchased;
                switch (purchase.type) {
                    case PRO_TYPE: {
                        isPremiumUser = true;
                        action.asyncDispatch(actions.getCurrentUser());
                        break;
                    }
                    case PREPAID_TYPE: {
                        license = licenses.PREPAID;
                        prepaidBalance = purchase.price;
                        break;
                    }
                    case ALACARTE_TYPE: {
                        hasPurchased = true;
                        purchasedIcons = purchasedIcons || [];
                        purchasedIcons.push(parseInt(state.pendingDownload.id));
                        break;
                    }
                }

                return {
                    ...state,
                    status,
                    license,
                    isPremiumUser,
                    purchasedIcons,
                    prepaidBalance,
                    hasPurchased,
                    justPurchased: true
                };
            }
            case actionTypes.USER_GET_PREPAID_BALANCE_SUCCESS: {
                let { prepaidBalance, status } = state;
                if (prepaidBalance) {
                    status = phases.USE_PREPAID;
                    return {
                        ...state,
                        status
                    };
                }

                return state;
            }
        }

        return {
            ...state,
            status
        };
    },
    [phases.USE_PREPAID]: (state, action) => {
        let { status, processing, error, license } = state;
        switch (action.type) {
            case DOWNLOAD_ENTER_PHASE.USE_PREPAID: {
                action.asyncDispatch(
                    actions.downloadSelectPurchaseType(CONSUMING_PREPAID)
                );
                return {
                    ...state,
                    upsellRejectedPhase: phases.DOWNLOAD_READY
                };
            }
            case actionTypes.DOWNLOAD_PURCHASE_START: {
                processing = true;
                error = null;
                return {
                    ...state,
                    processing,
                    error
                };
            }
            case actionTypes.DOWNLOAD_PURCHASE_END: {
                let { error } = action;
                let hasPurchased;
                if (!error) {
                    status = nextPhase(status, license);
                    hasPurchased = true;
                }

                processing = false;
                return {
                    ...state,
                    processing,
                    status,
                    error,
                    hasPurchased
                };
            }
            case actionTypes.DOWNLOAD_PURCHASE_SUCCESS: {
                let { purchase } = action;

                let { price } = purchase;
                let { prepaidBalance, purchasedIcons } = state;

                prepaidBalance = Math.max(0, prepaidBalance - price);

                purchasedIcons = purchasedIcons || [];
                purchasedIcons.push(parseInt(state.pendingDownload.id));

                return {
                    ...state,
                    prepaidBalance,
                    purchasedIcons,
                    hasPurchased: true,
                    justPurchased: true
                };
            }

            case actionTypes.DOWNLOAD_SELECT_LICENSE: {
                let { license } = action;

                if (license == licenses.CREATIVE_COMMONS) {
                    let newState = {
                        ...state,
                        license
                    };
                    newState.status = phases.DOWNLOAD_READY;
                    return newState;
                }
                return state;
            }
        }

        return {
            ...state,
            status
        };
    }
};

export default (state = {}, action) => {
    let { status } = state;

    let newState = {
        ...state
    };

    // Reducer for global non phase specific actions
    switch (action.type) {
        case actionTypes.DOWNLOAD_RESET_FLOW: {
            let newState = {
                usingPremiumFeatures: state.usingPremiumFeatures,
                isPremiumUser: state.isPremiumUser,
                prepaidBalance: state.prepaidBalance,
                purchasedIcons: state.purchasedIcons,
                loggedIn: state.loggedIn,
                phaseJumping: state.phaseJumping
            };

            if (action.preserveEdits) {
                newState.upsellRejectedPhase = state.upsellRejectedPhase;
            }

            return newState;
        }
        case actionTypes.STRIPE_CARD_INPUT_CHANGE: {
            newState.error = null;
            break;
        }
        case actionTypes.GET_CURRENT_USER_SUCCESS: {
            let user = action.response;
            let isPremiumUser = user?.has_premium_account;
            let loggedIn = !user.is_anonymous;

            let { license } = newState;
            if (isPremiumUser) {
                license = licenses.NOUN_PRO;
                if (state.active) {
                    status = nextPhase(status, license);
                }
            }

            Object.assign(newState, {
                isPremiumUser,
                loggedIn,
                license,
                status
            });

            if (loggedIn && !isPremiumUser) {
                action.asyncDispatch(actions.iconsFetchPurchased());
                action.asyncDispatch(actions.userGetPrepaidBalance());
            }
            break;
        }
        case actionTypes.USER_GET_PREPAID_BALANCE_SUCCESS: {
            let prepaid = action.response;
            let { prepaid_balance } = prepaid;
            let balance = 0;
            if (prepaid_balance) {
                ({ balance } = prepaid_balance);
            }

            let {
                pendingDownload,
                iconBaseLicense,
                status,
                license,
                hasPurchased,
                prepaidBalance: previousFetchedBalance
            } = state;

            if (balance) {
                if (pendingDownload && !hasPurchased) {
                    // If the user is buying a license for an icon switch them to prepaid
                    if (
                        licenseDescriptionToLicense(iconBaseLicense) !=
                            licenses.PUBLIC_DOMAIN &&
                        license != licenses.CREATIVE_COMMONS
                    ) {
                        license = licenses.PREPAID;
                    }
                }
            } else if (previousFetchedBalance) {
                // If user previously had a balance but now does not don't show them the use prepaid screen
                balance = 0;
                if (status == phases.USE_PREPAID) {
                    action.asyncDispatch(
                        actions.downloadResetToLicense(
                            pendingDownload,
                            licenses.ROYALTY_FREE,
                            true
                        )
                    );
                }
            }

            newState = {
                ...state,
                license,
                status,
                prepaidBalance: parseInt(balance)
            };
            break;
        }
        case actionTypes.DOWNLOAD_COMMIT_LICENSE: {
            let { license } = state;
            if (license != licenses.CREATIVE_COMMONS) {
                action.asyncDispatch(actions.userGetPrepaidBalance());
            }
            break;
        }
        case actionTypes.EDITOR_RESET: {
            newState.usingPremiumFeatures = false;
            break;
        }
        case actionTypes.EDITOR_TRANSFORMED: {
            let { editor } = action;

            let usingPremiumFeatures = editor.shouldUpsell;
            if (usingPremiumFeatures) {
                if (
                    shouldUpsellForPhase(status) &&
                    shouldUpsellForLicense(state.license)
                ) {
                    newState.status = phases.UPSELL;
                    newState.upsellRejectedPhase = steps[
                        licenses.CREATIVE_COMMONS
                    ].includes(status)
                        ? status
                        : phases.DOWNLOAD_READY;
                }
            }

            newState.usingPremiumFeatures = usingPremiumFeatures;
            break;
        }
        case actionTypes.DOWNLOAD_SET_FLOW_ACTIVE: {
            let { isPremiumUser, prepaidBalance, loggedIn } = state;
            let { icon, editor } = action;

            let repurchasing;
            if (state.phaseJumping) {
                ({ repurchasing } = state.phaseJumping);
            }

            if (!repurchasing) {
                delete state.hasPurchased;
            }

            let license, hasPurchased;

            let iconBaseLicense = licenseDescriptionToLicense(
                icon.license_description
            );
            if (iconBaseLicense == licenses.PUBLIC_DOMAIN) {
                license = iconBaseLicense;
            }

            let userHasPurchased =
                icon.userHasPurchased ||
                includes(state.purchasedIcons, parseInt(icon.id));
            if (iconBaseLicense != licenses.PUBLIC_DOMAIN) {
                if (isPremiumUser) {
                    license = licenses.NOUN_PRO;
                } else if (userHasPurchased && state.hasPurchased !== false) {
                    // User is not repurchasing RF license
                    license = licenses.ALREADY_PURCHASED_ROYALTY_FREE;
                    hasPurchased = true;
                } else if (prepaidBalance && prepaidBalance > 0) {
                    if (
                        !state.phaseJumping ||
                        state.phaseJumping.license == licenses.ROYALTY_FREE
                    ) {
                        // User is going to use Prepaid Balance to purchase
                        license = licenses.PREPAID;
                    } else {
                        // User has a prepaid balance but wants a CC download of this icon
                        license = state.phaseJumping.license;
                    }
                } else if (iconBaseLicense == licenses.CREATIVE_COMMONS) {
                    // Default to RF selected
                    license = licenses.ROYALTY_FREE;
                }
            }

            if (loggedIn) {
                status = steps[license][0];
            } else {
                status = phases.INITIAL;
            }

            Object.assign(newState, {
                ...state,
                error: null,
                active: true,
                pendingDownload: icon,
                license,
                iconBaseLicense,
                hasPurchased,
                status,
                usingPremiumFeatures: editor.shouldUpsell
            });

            break;
        }
        case actionTypes.FETCH_SVG_DATA_SUCCESS: {
            let { iconId, response } = action;

            let { pendingDownload } = state;

            if (pendingDownload && pendingDownload.id == iconId) {
                pendingDownload = {
                    ...pendingDownload,
                    raw_svg: response
                };
            }

            return {
                ...state,
                pendingDownload
            };
        }
        case actionTypes.DOWNLOAD_REPURCHASE: {
            let hasPurchased = false;
            return {
                ...state,
                hasPurchased,
                isRepurchasing: true
            };
        }

        case actionTypes.ICONS_FETCH_PURCHASED_SUCCESS:
            let { response } = action;
            let { icon_ids } = response;

            let { pendingDownload, hasPurchased } = state;

            if (pendingDownload) {
                if (includes(icon_ids, pendingDownload.id)) {
                    hasPurchased = true;
                }
            }

            return {
                ...state,
                purchasedIcons: icon_ids,
                hasPurchased
            };
        case actionTypes.DOWNLOAD_PHASE_JUMP_START: {
            let { repurchasing, license } = action;
            return {
                ...state,
                phaseJumping: {
                    repurchasing,
                    license
                }
            };
        }
        case actionTypes.DOWNLOAD_PHASE_JUMP_END: {
            return {
                ...state,
                phaseJumping: false
            };
        }
    }

    if (action.type.includes("DOWNLOAD_ENTER_PHASE")) {
        if (
            [phases.PAYMENT, phases.USE_PREPAID].filter(p =>
                action.type.includes(p)
            ).length
        ) {
            if (!state.phaseJumping) {
                action.asyncDispatch(actions.userGetPrepaidBalance());
            }
        }
    }

    if (reducers[status]) {
        Object.assign(newState, reducers[status](newState, action));
    }

    if (!newState) {
        // console.warn("Download store no newState, from", status);
        return state;
    }

    if (
        newState.status != state.status ||
        action.type == actionTypes.DOWNLOAD_SET_FLOW_ACTIVE
    ) {
        if (action.asyncDispatch) {
            // Dispatch an action in case other reducers are interested in state transition
            // Or for initalizing the phase inside this store
            action.asyncDispatch({
                type: DOWNLOAD_ENTER_PHASE[newState.status],
                ...newState
            });
        }
    }

    return newState;
};
