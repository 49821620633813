import React from "react";
import PropTypes from "prop-types";

// styles
import {
    ReasonsForDenialStyled,
    ReasonUlStyled,
    ReasonLiStyled,
    ReasonsForDenialTitleStyled
} from "./styles.js";

// QueueContext
import { useQueueContext } from "frontend/components/fort/mod/core/Layouts/QueueLayout";

export const ReasonsForDenial = () => {
    // state/context
    const queueContext = useQueueContext();

    const {
        state: { singleUploadSelected }
    } = queueContext;

    if (!singleUploadSelected) {
        return null;
    }

    // Upload
    const { reasonsForDenial } = singleUploadSelected;

    if (!reasonsForDenial) {
        return null;
    }

    return (
        <ReasonsForDenialStyled>
            <ReasonsForDenialTitleStyled size="md" type="h3">
                Reason for Denial
            </ReasonsForDenialTitleStyled>

            <ReasonUlStyled>
                {reasonsForDenial.map(reason => {
                    return <Reason key={reason.id} reason={reason} />;
                })}
            </ReasonUlStyled>
        </ReasonsForDenialStyled>
    );
};

const Reason = ({ reason }) => {
    return <ReasonLiStyled>{reason.message}</ReasonLiStyled>;
};

export default ReasonsForDenial;

// prop-types
Reason.propTypes = {
    reason: PropTypes.object.isRequired
};
