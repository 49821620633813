import { useApolloMutation } from "frontend/utils/apolloApi/useApolloMutation.js";

// gql
import {
    SAVE_UPLOADS_FOR_LATER,
    REMOVE_UPLOADS_FROM_SAVED_FOR_LATER
} from "frontend/components/fort/mod/photos/gql/mutations";

// utils
import { useApiUploadUpdate } from "frontend/components/fort/mod/photos/hooks/useApiUploadUpdate.js";

// hooks
import { useSelect } from "frontend/components/core/Grid2/GridItemContentTemplates/Select/SelectContext/index.js";

export const useSetSaveForLater = () => {
    const { deselectAll } = useSelect();
    const { apiUploadUpdate } = useApiUploadUpdate();

    const { apolloMutate: apolloMutateSaveForLater } = useApolloMutation({
        actionDesc: "Upload: Set Saved for Later",
        gql: SAVE_UPLOADS_FOR_LATER
    });
    const { apolloMutate: apolloMutateActive } = useApolloMutation({
        actionDesc: "Upload: Set Active",
        gql: REMOVE_UPLOADS_FROM_SAVED_FOR_LATER
    });

    // isActivated === active
    const setSaveForLater = async ({ photoUploadIds, isActivated }) => {
        const apolloMutate = isActivated
            ? apolloMutateSaveForLater
            : apolloMutateActive;

        const { ssResp } = await apolloMutate({
            variables: {
                photoUploadIds
            }
        });

        apiUploadUpdate(ssResp);

        // as we move uploads from one bucket to the next, we need to deselect to avoid confusion
        deselectAll();
    };

    return {
        setSaveForLater
    };
};
