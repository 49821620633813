import gql from "graphql-tag";

export const CREATE_PHOTO_SHOOT = gql`
    mutation createPhotoShoot($title: String = null) {
        createPhotoShoot(title: $title) {
            ok
            errors
            photoShoot {
                id
                title
            }
        }
    }
`;
