import { useRef, useState, useEffect } from "react";

const useContainerWidth = () => {
    const [containerWidth, setContainerWidth] = useState(0);
    const containerRef = useRef();

    useEffect(() => {
        const handleResize = () => {
            setContainerWidth(containerRef.current.offsetWidth);
        };

        if (containerRef.current) {
            handleResize();
        }

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [containerRef]);

    return { containerWidth, containerRef };
};

export default useContainerWidth;
