import React from "react";

import Link from "core/Link";

import Signup from "../Signup";
import {
    PageTitle,
    PageSubtitle,
    PageWrapper,
    AuthFormPageContent,
    FormContainer
} from "../styles";

import { useAuthPage } from "frontend/hooks/useAuth";
import { LOGIN } from "data/links";

const SignupPage = () => {
    const { afterAuth } = useAuthPage();
    const queries = window.location.search;
    return (
        <PageWrapper darkMode>
            <PageTitle darkMode>Create a Free Account</PageTitle>
            <PageSubtitle>
                Already have an account?{" "}
                <Link size="lg" darkMode href={LOGIN + queries}>
                    Log In
                </Link>
            </PageSubtitle>
            <AuthFormPageContent>
                <FormContainer>
                    <Signup isStandaloneAuthPage afterAuth={afterAuth} />
                </FormContainer>
            </AuthFormPageContent>
        </PageWrapper>
    );
};

export default SignupPage;
