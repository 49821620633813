import React from "react";
import { Router, Switch, Route, Redirect, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { createBrowserHistory } from "history";

// components:
import ErrorBoundary from "frontend/utils/ErrorBoundary";
import GoogleAnalaytics from "frontend/utils/GoogleAnalytics";
import SiteFooter from "core/SiteFooter";
import SiteHeaderParent from "core/SiteHeaderParent";
import NotFound from "core/NotFound";
import PhotosSearchPage from "photos/PhotoSearchPage";
import LoginRequired from "core/LoginRequired";
import AuthModal from "frontend/components/auth/AuthModal";
import CookiesBanner from "core/CookiesBanner";
import { PhotosProvider } from "photos/context";
import MessageStackProvider from "core/MessageStack";
import Search from "icons/SearchPage";

// Photos related paths:
import {
    LOGIN,
    FORGOT_PASSWORD,
    RESET_PASSWORD,
    SIGNUP,
    SIGNUP_TYPE,
    SOCIAL_LOGIN,
    SOCIAL_LOGIN_CANCELLED,
    SOCIAL_LOGIN_TRY_AGAIN,
    SOCIAL_SIGNUP,
    BROWSE_BY_TAG,
    BROWSE_BY_SHOOT_ID,
    BROWSE_BY_CREATOR_USERNAME,
    SEARCH_CREATOR_PHOTOS,
    BROWSE_SIMILAR_BY_PHOTO_ID,
    BROWSE_BY_CAMPAIGN,
    aboutPath,
    photosLandingPath,
    lingoMarketingPagePath,
    BECOME_PHOTOGRAPHER_LANDING_PAGE,
    CAREERS_PATH,
    NOUN_PRO_EDU_LANDING_PAGE,
    NOUN_PRO_EDU_APPLICATION,
    EDU_APPLICATION_CONFIRMATION,
    BROWSE_ICONS_SIMILAR,
    SEARCH_CONTENT,
    BROWSE_ICONS_FEATURED,
    BROWSE_CONTENT,
    BROWSE_ICONS_BY_CREATOR,
    authLinks
} from "data/links";

// Icons related paths:
import {
    BROWSE_ICON_COLLECTIONS_BY_CREATOR,
    SEARCH_ICONS_CREATOR
} from "data/links";

// dashboard related paths:
import {
    CREATOR_DASHBOARD_PHOTOS_REDIRECT,
    CREATOR_DASHBOARD_PHOTOS,
    CREATOR_DASHBOARD_PHOTOS_DEFAULT
} from "data/links";

// creator tools related paths:
import {
    PHOTO_UPLOAD,
    PHOTO_UPLOAD_CONFIRM,
    PHOTO_UPLOAD_MAX_QUEUE,
    PHOTO_UPLOAD_MAX_TIME,
    PHOTO_UPLOAD_BAD_ACCOUNT,
    PHOTO_UPLOAD_INDIVIDUAL,
    PHOTO_UPLOAD_PHOTO_SHOOT,
    PHOTO_UPLOAD_TYPE_SELECT
} from "data/links";

// Photo Moderation related paths:
import { MOD_PHOTOS } from "data/links";

import { RootMessageStackZone } from "frontend/components/core/MessageStack";

// new static/marketing pages:
import About from "marketing-pages/About";
import PhotosLanding from "marketing-pages/PhotosLanding";
import LingoMarketingPage from "marketing-pages/LingoMarketingPage";
import BecomeAPhotographer from "marketing-pages/BecomeAPhotographer";
import Careers from "marketing-pages/Careers";

import NounProEdu from "subscriptions/NounProEdu/LandingPage";
import EduApplication from "subscriptions/NounProEdu/EduApplication";
import EduConfirmation from "subscriptions/NounProEdu/EduApplication/EduConfirmation";

// icons related pages:
import SearchIconsCreator from "icons/SearchIconsCreator";

// browse
import BrowseIconsSimilar from "icons/BrowseIconsSimilar";
import BrowseIconsFeatured from "icons/BrowseIconsFeatured";
import BrowsePage from "core/BrowsePage";
import BrowseIconsByCreator from "icons/BrowseIconsByCreator";
import BrowseCollectionsByCreator from "icons/BrowseCollectionsByCreator";

// auth
import LoginPage from "frontend/components/auth/LoginPage";
import ForgotPasswordPage from "frontend/components/auth/ForgotPasswordPage";
import ResetPasswordPage from "frontend/components/auth/ResetPasswordPage";
import SignupPage from "frontend/components/auth/SignupPage";
import SocialLoginSuccessPage from "frontend/components/auth/SocialLoginSuccessPage";
import SocialSignupConfirmationPage from "frontend/components/auth/SocialSignupConfirmationPage";
import FacebookCancelledPage from "frontend/components/auth/FacebookCancelledPage";
import FacebookTryAgainPage from "frontend/components/auth/FacebookTryAgainPage";

// dashboard
import CreatorDashboardPhotos from "frontend/components/creator-dashboard/photos";

// creator tools
import PhotoUploadPage from "frontend/components/creator-tools/PhotoUploadPage";
import PhotoUploadIndividual from "frontend/components/creator-tools/PhotoUploadPage/PhotoUploadIndividual";
import PhotoUploadPhotoShoot from "frontend/components/creator-tools/PhotoUploadPage/PhotoUploadPhotoShoot";
import PhotoUploadTypeSelect from "frontend/components/creator-tools/PhotoUploadPage/PhotoUploadTypeSelect";
import PhotoUploadConfirmation from "frontend/components/creator-tools/PhotoUploadPage/Status/Confirmation";

// Photo Moderation related paths:
import PhotoMod from "frontend/components/fort/mod/photos";

// styles:
import { SiteContentWrapper } from "core/Layout";

const LoginRequiredNounProEduApplication = props => (
    <LoginRequired component={EduApplication} {...props} />
);

const LoginRequiredPhotoUploadPage = props => (
    <LoginRequired component={PhotoUploadPage} {...props} />
);

const LoginRequiredPhotoUploadConfirmation = props => (
    <LoginRequired component={PhotoUploadConfirmation} {...props} />
);

const LoginRequiredPhotoUploadIndividualPage = props => (
    <LoginRequired component={PhotoUploadIndividual} {...props} />
);

const LoginRequiredPhotoUploadPhotoShootPage = props => (
    <LoginRequired component={PhotoUploadPhotoShoot} {...props} />
);

const LoginRequiredPhotoUploadTypeSelectPage = props => (
    <LoginRequired component={PhotoUploadTypeSelect} {...props} />
);

export const photoRoutes = [
    {
        path: BROWSE_BY_TAG,
        component: PhotosSearchPage
    },
    {
        path: BROWSE_BY_SHOOT_ID,
        component: PhotosSearchPage
    },
    {
        path: BROWSE_BY_CREATOR_USERNAME,
        component: PhotosSearchPage
    },
    {
        path: SEARCH_CREATOR_PHOTOS,
        component: PhotosSearchPage
    },
    {
        path: BROWSE_SIMILAR_BY_PHOTO_ID,
        component: PhotosSearchPage
    },
    {
        path: BROWSE_BY_CAMPAIGN,
        component: PhotosSearchPage
    }
];

export const iconRoutes = [
    {
        path: SEARCH_CONTENT,
        component: Search
    },
    // Delete BrowsePage when ready to launch SSR for Browse pages
    {
        path: BROWSE_CONTENT,
        component: BrowsePage
    },
    {
        path: BROWSE_ICONS_SIMILAR,
        component: BrowseIconsSimilar
    },
    {
        path: BROWSE_ICON_COLLECTIONS_BY_CREATOR,
        component: BrowseCollectionsByCreator
    },
    {
        path: BROWSE_ICONS_FEATURED,
        component: BrowseIconsFeatured
    },
    {
        path: SEARCH_ICONS_CREATOR,
        component: SearchIconsCreator
    },
    {
        path: BROWSE_ICONS_BY_CREATOR,
        component: BrowseIconsByCreator
    }
];

export const subscriptionRoutes = [
    {
        path: NOUN_PRO_EDU_LANDING_PAGE,
        component: NounProEdu
    },
    {
        path: NOUN_PRO_EDU_APPLICATION,
        component: LoginRequiredNounProEduApplication
    },
    {
        path: EDU_APPLICATION_CONFIRMATION,
        component: EduConfirmation
    }
];

export const creatorRoutes = [
    {
        path: PHOTO_UPLOAD,
        component: LoginRequiredPhotoUploadPage
    },
    {
        path: PHOTO_UPLOAD_CONFIRM,
        component: LoginRequiredPhotoUploadConfirmation
    },
    {
        path: PHOTO_UPLOAD_MAX_QUEUE,
        component: LoginRequiredPhotoUploadPage
    },
    {
        path: PHOTO_UPLOAD_MAX_TIME,
        component: LoginRequiredPhotoUploadPage
    },
    {
        path: PHOTO_UPLOAD_BAD_ACCOUNT,
        component: LoginRequiredPhotoUploadPage
    },
    {
        path: PHOTO_UPLOAD_TYPE_SELECT,
        component: LoginRequiredPhotoUploadTypeSelectPage
    },
    {
        path: PHOTO_UPLOAD_INDIVIDUAL,
        component: LoginRequiredPhotoUploadIndividualPage
    },
    {
        path: PHOTO_UPLOAD_PHOTO_SHOOT,
        component: LoginRequiredPhotoUploadPhotoShootPage
    }
];

const ProtectedCreatorDashboardPhotosRedirect = props => {
    const { username } = useParams();

    return (
        <Redirect
            to={CREATOR_DASHBOARD_PHOTOS_DEFAULT.replace(":username", username)}
            {...props}
            loginPath={authLinks.login.href}
        />
    );
};

export const dashboardRoutes = [
    {
        path: CREATOR_DASHBOARD_PHOTOS,
        component: props => (
            <LoginRequired
                component={CreatorDashboardPhotos}
                loginPath={authLinks.login.href}
                {...props}
            />
        )
    },
    {
        path: CREATOR_DASHBOARD_PHOTOS_REDIRECT,
        component: props => (
            <ProtectedCreatorDashboardPhotosRedirect {...props} />
        )
    }
];

export const otherRoutes = [
    {
        path: aboutPath,
        component: About
    },
    {
        path: photosLandingPath,
        component: PhotosLanding
    },
    {
        path: lingoMarketingPagePath,
        component: LingoMarketingPage
    },
    {
        path: BECOME_PHOTOGRAPHER_LANDING_PAGE,
        component: BecomeAPhotographer
    },
    {
        path: CAREERS_PATH,
        component: Careers
    },
    {
        path: LOGIN,
        component: LoginPage
    },
    {
        path: FORGOT_PASSWORD,
        component: ForgotPasswordPage
    },
    {
        path: RESET_PASSWORD,
        component: ResetPasswordPage
    },
    {
        path: SIGNUP_TYPE,
        component: SignupPage
    },
    {
        path: SIGNUP,
        component: SignupPage
    },
    {
        path: SOCIAL_LOGIN,
        component: SocialLoginSuccessPage
    },
    {
        path: SOCIAL_SIGNUP,
        component: SocialSignupConfirmationPage
    },
    {
        path: SOCIAL_LOGIN_CANCELLED,
        component: FacebookCancelledPage
    },
    {
        path: SOCIAL_LOGIN_TRY_AGAIN,
        component: FacebookTryAgainPage
    },
    // Please make sure that this `NotFound` route is always last in the route list
    {
        path: "*",
        component: NotFound
    }
];

export const modRoutes = [
    {
        path: MOD_PHOTOS,
        component: PhotoMod,
        exact: false,
        // this is being rendered inside Mod tool component to control positioning
        includeMessageStackZone: false
    }
];

export const allRoutes = [
    ...photoRoutes,
    ...iconRoutes,
    ...subscriptionRoutes,
    ...creatorRoutes,
    ...dashboardRoutes,
    ...modRoutes,
    ...otherRoutes
];

export const Routes = ({ routeData }) => {
    return (
        <Switch>
            {routeData &&
                routeData.map(
                    (
                        {
                            path,
                            component: Component,
                            exact = true,
                            includeMessageStackZone = true
                        },
                        idx
                    ) => (
                        <Route
                            key={idx}
                            exact={exact}
                            path={path}
                            render={props => (
                                <PhotosProvider {...props}>
                                    <CookiesBanner />
                                    <SiteContentWrapper>
                                        <SiteHeaderParent />
                                        <MessageStackProvider>
                                            {includeMessageStackZone && (
                                                <RootMessageStackZone />
                                            )}
                                            <Component {...props} />
                                        </MessageStackProvider>
                                        <AuthModal />
                                    </SiteContentWrapper>
                                    <SiteFooter />
                                </PhotosProvider>
                            )}
                        />
                    )
                )}
        </Switch>
    );
};

export const AppRouter = () => {
    const history = createBrowserHistory();

    return (
        <>
            <Helmet>
                <title>Noun Project</title>
            </Helmet>
            <ErrorBoundary>
                <Router history={history}>
                    <GoogleAnalaytics debug={false} />
                    <Routes routeData={allRoutes} />
                </Router>
            </ErrorBoundary>
        </>
    );
};
