import styled, { css } from "styled-components";

// styles
import colors, { focusBorder } from "core/colors";

// components
import { Image as CaretDownIcon } from "core/ReusableIcons/CaretDown";
import { Image as EditIcon } from "core/ReusableIcons/Edit";

// styled-components
export const FormSelectDialogStyled = styled.div`
    position: relative;
    width: ${({ controlWidth }) => controlWidth};
`;

/*---------------------------
| Control Button: Shared Styles
---------------------------*/
export const getSharedListItemControlStyles = ({
    width,
    padding,
    height,
    border,
    fontSize,
    backgroundColor
}) => {
    return css`
        display: inline-flex;
        align-items: center;
        box-sizing: border-box;
        border: none;
        border-radius: 0px;
        background-color: transparent;
        cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};
        width: ${width};
        padding: 0rem;
        height: ${height};
        font-size: ${fontSize};
        border: ${border};
        background-color: ${backgroundColor};
        padding-left: ${padding.left};
        padding-right: ${padding.right};

        &:focus {
            outline: none;
            ${focusBorder};
        }
    `;
};

/*---------------------------
| Control Button: Caret Icon
---------------------------*/
export const ControlCaretStyled = styled.button`
    ${({
        isDisabled,
        theme: {
            control,
            icon: { top }
        }
    }) => {
        return css`
            ${getSharedListItemControlStyles({ ...control, isDisabled })};

            &:hover,
            &:focus,
            &:active {
                background-color: ${control.hover.backgroundColor};
            }

            ${CaretDownIcon} {
                position: relative;
                top: ${top};
                ${({ isOpen }) => {
                    if (isOpen) {
                        return css`
                            transform: rotate(180deg);
                        `;
                    }
                }}
            }
        `;
    }};
`;

/*---------------------------
| Control Button: Edit Icon
---------------------------*/
export const ControlEditStyled = styled.button`
    ${({
        isDisabled,
        theme: {
            control,
            icon: { top }
        }
    }) => {
        return css`
            ${getSharedListItemControlStyles({ ...control, isDisabled })};
            ${EditIcon} {
                position: relative;
                top: ${top};
                path {
                    fill: ${colors.accent.lightMode.persian};
                }
            }
        `;
    }};
`;

/*---------------------------
| Control Button: Children
---------------------------*/
export const ContentWrapperStyled = styled.div`
    width: 100%;
    min-width: 0; /* Prevents unintended expansion */
    display: flex;
    gap: ${({ theme }) => theme.control.gap};
`;

export const DisplayTextStyled = styled.div`
    flex: 0 0 auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

export const IconStyled = styled.div`
    flex: 0 0 16px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
