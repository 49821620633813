import styled from "styled-components";

import breakpoints from "core/breakpoints";

export const LoadingContainer = styled.div`
    /* Mobile-only: */
    @media (max-width: ${breakpoints.beforeTablet.rem}) {
        height: 100vh;
    }
`;
