import styled from "styled-components";

import BodyText from "core/BodyText";

import { convertPxToRem } from "frontend/components/helpers";
import colors from "core/colors";
import { easingSeconds } from "core/animation";
import { bold as boldTextStyle } from "core/fontStyles";
import breakpoints from "core/breakpoints";
import Heading from "core/Heading";
import SectionContainer from "core/SectionContainer";

export const PhotosContainer = styled(SectionContainer)`
    > section {
        padding-bottom: ${convertPxToRem({ px: 0 })};
    }

    @media (min-width: ${breakpoints.desktop.rem}) {
        padding-bottom: ${convertPxToRem({ px: 30 })};
    }
`;

export const CreatorDashboardContainer = styled(SectionContainer)`
    > section {
        padding-bottom: ${convertPxToRem({ px: 0 })};
    }
    padding-bottom: ${convertPxToRem({ px: 64 })};

    @media (min-width: ${breakpoints.tablet.rem}) {
        padding-bottom: ${convertPxToRem({ px: 96 })};
    }
    @media (min-width: ${breakpoints.desktop.rem}) {
        padding-bottom: ${convertPxToRem({ px: 98 })};
    }
`;

export const HeadingContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${convertPxToRem({ px: 48 })};

    @media (max-width: ${breakpoints.beforeTablet.rem}) {
        display: block;
        margin-bottom: ${convertPxToRem({ px: 32 })};

        ${Heading} {
            display: block;
            margin-bottom: ${convertPxToRem({ px: 8 })};
        }
    }

    @media (min-width: ${breakpoints.beforeDesktop.rem}) {
        margin-bottom: ${convertPxToRem({ px: 40 })};
    }
`;

export const NavContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: inset 0px -1px 0px ${colors.primary.scheifer};

    @media (max-width: ${breakpoints.beforeTablet.rem}) {
        display: block;
    }
`;

export const IconsDashboardLinkContainer = styled.a`
    display: flex;
    align-items: center;
    text-decoration: none;

    ${BodyText} {
        margin-right: ${convertPxToRem({ px: 6 })};
        transition: all ${easingSeconds};
    }

    svg {
        path,
        use,
        g,
        circle {
            fill: ${colors.accent.lightMode.persian};
            transition: all ${easingSeconds};
        }
    }

    &:hover {
        ${BodyText} {
            color: ${colors.secondary.cosmic};
            transition: all ${easingSeconds};
        }

        svg {
            path,
            use,
            g,
            circle {
                fill: ${colors.secondary.cosmic};
                transition: all ${easingSeconds};
            }
        }
    }

    &:active {
        ${BodyText} {
            color: ${colors.secondary.orca};
            transition: all ${easingSeconds};
            ${boldTextStyle}
        }

        svg {
            path,
            use,
            g,
            circle {
                fill: ${colors.secondary.orca};
                transition: all ${easingSeconds};
            }
        }
    }
`;
