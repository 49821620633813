import React from "react";

import Ad from "./Ad";
import SkeletonGridAd from "core/SkeletonGridAd";

import { IconGrid } from "./styles";

export const buildSubgridArray = ({
    maxNumberOfIconsPerGridWithAd,
    iconData
}) => {
    let subgrid = [];
    let gridWithAd = [];

    iconData.forEach(icon => {
        const gridWithAdIsFull =
            gridWithAd.length === maxNumberOfIconsPerGridWithAd;
        if (gridWithAdIsFull) {
            addGridWithAdToSubgrid();
        }

        gridWithAd.push(icon);
    });

    if (!!gridWithAd.length) {
        addGridWithAdToSubgrid();
    }

    return subgrid;

    function addGridWithAdToSubgrid() {
        subgrid = [...subgrid, gridWithAd];
        gridWithAd = [];
    }
};

export const buildSubgrid = ({
    numberOfColumns,
    numberOfRowsBetweenAds,
    gridSpacingSize,
    iconData,
    useRouterLink
}) => {
    const maxNumberOfIconsPerGridWithAd =
        numberOfRowsBetweenAds * numberOfColumns;

    return buildSubgridArray({
        maxNumberOfIconsPerGridWithAd,
        iconData
    }).map((dataForGridWithAd, index) => {
        const appendAd =
            dataForGridWithAd.length === maxNumberOfIconsPerGridWithAd;

        const lastItemInGrid =
            dataForGridWithAd &&
            dataForGridWithAd[dataForGridWithAd.length - 1];
        const showSkeletonAd = lastItemInGrid && lastItemInGrid.showSkeleton;

        const ad = showSkeletonAd ? <SkeletonGridAd /> : <Ad />;

        return (
            <React.Fragment key={index}>
                <IconGrid
                    iconData={dataForGridWithAd}
                    numberOfColumns={numberOfColumns}
                    gridSpacingSize={gridSpacingSize}
                    useRouterLink={useRouterLink}
                />
                {appendAd && ad}
            </React.Fragment>
        );
    });
};
