/******************************************************
NOTE:
when using this component, you must wrap it in a SectionContainer on the page it's being used on with the following props:

            <SectionContainer
                bgColor={colors.secondary.orca}
                textColor={colors.primary.diamond}
                topSpacing="4xl"
                bottomSpacing="4xl"
            >

                <HeroLandingPage
                    (props)
                />

            </SectionContainer>

******************************************************/
import React from "react";
import PropTypes from "prop-types";

import Hidden from "core/Hidden";
import HeroClickableIcons from "core/HeroClickableIcons";
import Heading from "core/Heading";
import Grid from "core/Grid";
import SearchWrapper from "core/search/SearchWrapper";
import SearchForm from "core/search/SearchForm";

import {
    HeroLandingPageContainer,
    HeadingAndInputContainer,
    MobileImg,
    IconsLandingPageIconsContainer
} from "./styles";

const HeroLandingPage = ({
    heroImages,
    heading,
    headingColor,
    placeholder,
    bgSizeTablet,
    bgPositionTablet,
    bgSizeDesktop,
    bgPositionDesktop,
    isIconLandingPage = false
}) => {
    // support both webpack (string) & next.js (object) image imports
    const { desktop, tablet, mobile } = heroImages;
    const desktopImg = !!desktop && desktop.src ? desktop.src : desktop;
    const tabletImg = !!tablet && tablet.src ? tablet.src : tablet;
    const mobileImg = !!mobile && mobile.src ? mobile.src : mobile;

    const conditionalProps = isIconLandingPage
        ? {}
        : {
              desktopImg,
              desktopMinHeight: "592px",
              tabletImg,
              tabletMinHeight: "500px",
              bgSizeTablet: bgSizeTablet,
              bgPositionTablet: bgPositionTablet,
              bgSizeDesktop: bgSizeDesktop,
              bgPositionDesktop: bgPositionDesktop
          };

    return (
        <HeroLandingPageContainer {...conditionalProps}>
            <HeadingAndInputContainer>
                <Grid mobileSpacing={16} tabletSpacing={16} desktopSpacing={16}>
                    <Grid item sm={12} md={5} lg={6}>
                        <Heading
                            size="2xl"
                            bottomSpacing="3xl"
                            type="h1"
                            headingColor={headingColor}
                        >
                            {heading}
                        </Heading>
                        <SearchWrapper className="landing-page-hero">
                            <SearchForm
                                compact={true}
                                selectedSearchType={
                                    isIconLandingPage ? "Icons" : "Photos"
                                }
                                placeholder={placeholder}
                                hideButtonOnMobile={false}
                            />
                        </SearchWrapper>
                        {!isIconLandingPage && (
                            <Hidden type="div" breakpoint="mdLg">
                                <MobileImg src={mobileImg} />
                            </Hidden>
                        )}
                    </Grid>

                    {isIconLandingPage && (
                        <IconsLandingPageIconsContainer
                            item
                            sm={12}
                            md={7}
                            lg={6}
                        >
                            <HeroClickableIcons isHomepage={false} />
                        </IconsLandingPageIconsContainer>
                    )}
                </Grid>
            </HeadingAndInputContainer>
        </HeroLandingPageContainer>
    );
};

HeroLandingPage.propTypes = {
    heroImages: PropTypes.object.isRequired,
    heading: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    headingColor: PropTypes.string,
    bgSizeTablet: PropTypes.string,
    bgPositionTablet: PropTypes.string,
    bgSizeDesktop: PropTypes.string,
    bgPositionDesktop: PropTypes.string,
    isIconLandingPage: PropTypes.bool
};

export default HeroLandingPage;

//this is on photos and icons landing pages as of Aug 31, 2022
