import React from "react";
import PropTypes from "prop-types";

// styles
import { FlagStyled, FlagTextStyled } from "./styles.js";
import colors from "core/colors";

// components
import CloseButton from "core/ButtonPlain/CloseButton.js";

const Flag = ({ upload, flag, onFlagClear, isDisabled = false }) => {
    const { id, text, bgColor } = flag;

    const onClick = e => {
        e.stopPropagation();
        if (!isDisabled) {
            onFlagClear({ uploadId: upload.id, flagId: id, flagText: text });
        }
    };

    const closeButtonFillColor = isDisabled
        ? colors.secondary.dolphin
        : colors.primary.snow;

    return (
        <FlagStyled bgColor={bgColor} isDisabled={isDisabled}>
            <FlagTextStyled isDisabled={isDisabled}>{text}</FlagTextStyled>
            <CloseButton
                onClick={onClick}
                iconProps={{
                    size: { height: 12, width: 12 },
                    fillColor: closeButtonFillColor
                }}
            />
        </FlagStyled>
    );
};

export default Flag;

// prop-types
Flag.propTypes = {
    upload: PropTypes.object.isRequired,
    flag: PropTypes.object.isRequired,
    onFlagClear: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool
};
