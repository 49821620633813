import React from "react";
import styled from "styled-components";

import colors from "core/colors";
import { convertPxToRem } from "frontend/components/helpers";

import Close from "core/ReusableIcons/Close";
import ProcessingSpinner from "core/ProcessingSpinner";
import IconButton from "core/IconButton";
import { useReleases } from "./hooks/useReleases";

export const ReleaseItem = styled("div")`
    display: flex;
    justify-content: space-between;
    background-color: ${colors.primary.diamond};
    color: ${({ isUploading }) =>
        isUploading ? colors.secondary.dolphin : colors.secondary.orca};
    margin-bottom: ${convertPxToRem({ px: 16 })};
    border-radius: ${convertPxToRem({ px: 4 })};
    padding: ${convertPxToRem({ px: 12 })} ${convertPxToRem({ px: 16 })};

    &:last-of-type {
        margin-bottom: 0;
    }
`;

export const SpinnerContainer = styled("div")`
    display: flex;
    margin-left: ${convertPxToRem({ px: 8 })};
    align-items: center;
    justify-content: center;
`;

export const RemoveButton = styled(IconButton)`
    margin-left: ${convertPxToRem({ px: 8 })};

    svg {
        height: ${convertPxToRem({ px: 16 })};
        width: ${convertPxToRem({ px: 16 })};
        path {
            fill: ${colors.secondary.orca}};
        }
    }

    &:hover {
        svg {
            path {
                fill: ${colors.secondary.slate};
            }
        }
    }
`;

const ReleaseList = () => {
    const { removeRelease, releases } = useReleases();
    return releases.map(({ id, filename, status }) => {
        const isUploading = status !== "UPLOAD_COMPLETE";
        return (
            <ReleaseItem key={filename} isUploading={isUploading}>
                {filename}
                {isUploading ? (
                    <SpinnerContainer>
                        <ProcessingSpinner
                            width="16"
                            height="16"
                            iconColor={colors.secondary.dolphin}
                        />
                    </SpinnerContainer>
                ) : (
                    <RemoveButton
                        ariaLabel="Remove release"
                        svgElementType={Close}
                        buttonProps={{ onClick: () => removeRelease(id) }}
                    />
                )}
            </ReleaseItem>
        );
    });
};

export default ReleaseList;
