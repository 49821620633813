import { uploadFooterActionsSlice } from "./index";

import * as Helpers from "../../helpers";
import { moderatorIconKeys } from "../../config";

export const initializeUploadFooterActions = uploads => (
    dispatch,
    getState
) => {
    const uploadFooterActions = uploads.map(upload => {
        const { autoNudity, autoViolence } = upload;

        // EXP: Explicit flag should set this to blurred by default : true
        const isExplicit = autoNudity || autoViolence;

        return {
            id: upload.id,
            [moderatorIconKeys.SAVE_FOR_LATER]: Helpers.uploadIsSavedForLater(
                upload
            ),
            [moderatorIconKeys.HEART]: upload.creatorIsPremium || upload.isGem,
            [moderatorIconKeys.MATURE_AUDIENCE]: upload.mature,
            [moderatorIconKeys.BLUR]: isExplicit
        };
    });

    dispatch(
        uploadFooterActionsSlice.actions.footerActionsCreated(
            uploadFooterActions
        )
    );

    return uploadFooterActions;
};

export const blurUpload = id => (dispatch, getState) => {
    persistUploadBlur();
    dispatch(uploadFooterActionsSlice.actions.blurUpload(id));

    function persistUploadBlur() {
        let blurredUploads =
            JSON.parse(localStorage.getItem("modBlurredUploads")) || [];

        if (!blurredUploads.includes(id)) {
            blurredUploads = [...blurredUploads, id];
        }

        localStorage.setItem(
            "modBlurredUploads",
            JSON.stringify(blurredUploads)
        );
    }
};

export const unblurUpload = id => (dispatch, getState) => {
    unpersistUploadBlur();
    dispatch(uploadFooterActionsSlice.actions.unblurUpload(id));

    function unpersistUploadBlur() {
        let blurredUploads =
            JSON.parse(localStorage.getItem("modBlurredUploads")) || [];

        blurredUploads = blurredUploads.filter(uploadId => uploadId !== id);

        localStorage.setItem(
            "modBlurredUploads",
            JSON.stringify(blurredUploads)
        );
    }
};
