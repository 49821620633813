import React from "react";
import PropTypes from "prop-types";

// styles
import { InputStyled } from "./styles.js";

export const Input = ({
    value,
    onChange,
    inputRef,
    controlId,
    ariaLabel,
    placeholder,
    onBlur,
    errorMessage,
    hideLabel = false,
    height = 28,
    ...rest
}) => {
    return (
        <InputStyled
            type="text"
            ref={inputRef}
            id={controlId}
            data-testid={`input-${controlId}`}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            ariaLabel={ariaLabel}
            placeholder={placeholder}
            height={height}
            {...rest}
        />
    );
};

export default Input;

// prop-types
Input.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    inputRef: PropTypes.any,
    controlId: PropTypes.string,
    ariaLabel: PropTypes.string,
    placeholder: PropTypes.string,
    errorMessage: PropTypes.string,
    hideLabel: PropTypes.bool,
    height: PropTypes.number
};
