import { useContext } from "react";

// context
import { PanelOverlayContext } from "./index";
import { actionTypes } from "./store";

export const usePanelOverlay = () => {
    const { state, dispatch, consumerProps } = useContext(PanelOverlayContext);

    const setHasOpened = hasOpened => {
        dispatch({
            type: actionTypes.SET_HAS_MOUNTED,
            hasOpened
        });
    };

    const open = () => {
        dispatch({
            type: actionTypes.SET_IS_OPEN,
            isOpen: true
        });
        consumerProps.onOpen(state);
    };

    const close = () => {
        dispatch({
            type: actionTypes.SET_IS_OPEN,
            isOpen: false
        });
        consumerProps.onClose(state);
    };

    return {
        ...state,
        ...consumerProps,
        setHasOpened,
        open,
        close
    };
};
