import React from "react";

import SearchAndBrowsePageContainer from "core/SearchAndBrowsePageContainer";
import SearchAndBrowsePageHeadline from "core/SearchAndBrowsePageHeadline";
import SearchAndBrowseIconGridContainer from "icons/SearchAndBrowseIconGridContainer";

import useLoadMore from "frontend/hooks/useLoadMore";

import { CONTENT_TYPE } from "core/constants";
import { GET_FEATURED_ICONS_DATA } from "./queries";
import PageMeta from "core/PageMeta";

const BrowseIconsFeatured = () => {
    const {
        items,
        loading,
        LoadMore,
        totalCount,
        loadingFirstBatch,
        loadingItemCount
    } = useLoadMore({
        limit: 200,
        query: GET_FEATURED_ICONS_DATA,
        queryVariables: {},
        pathToListKey: "featuredIcons.items",
        pathToTotalCountKey: "featuredIcons.totalCount",
        contentType: CONTENT_TYPE.icon
    });

    const pageMetaTitle = "Featured Icons | Noun Project";
    const pageMetaDescription =
        "Find diverse icons for your next project. Download SVG and PNG icons. Check out the newest free icons or unlimited royalty-free icons with NounPro today";

    return (
        <>
            <PageMeta
                title={pageMetaTitle}
                indexContent="index,follow,max-image-preview:large"
                description={pageMetaDescription}
            />
            <SearchAndBrowsePageContainer>
                <SearchAndBrowsePageHeadline showSkeleton={loadingFirstBatch}>
                    Featured Icons
                </SearchAndBrowsePageHeadline>
                <SearchAndBrowseIconGridContainer
                    iconData={items}
                    totalCount={totalCount}
                    loadingFirstBatch={loadingFirstBatch}
                    loadingMore={loading}
                    LoadMoreButton={LoadMore}
                    loadingItemCount={loadingItemCount}
                />
            </SearchAndBrowsePageContainer>
        </>
    );
};

export default BrowseIconsFeatured;
