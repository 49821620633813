import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Modal from "core/Modal";
import Button from "core/Button";

import { convertPxToRem } from "frontend/components/helpers";
import { ModalChildrenContainer } from "../PhotoUpload/styles";

export const ClearButton = styled(Button)`
    margin-right: ${convertPxToRem({ px: 24 })};
`;

export const RefreshButton = styled(Button)`
    margin-top: ${convertPxToRem({ px: 24 })};
`;

const ClearProcessingPhotosModal = ({
    processingPhotosCount = 0,
    isOpen,
    onClose,
    onConfirm,
    hasCancellationError
}) => {
    const isMultiple = processingPhotosCount !== 1;
    const heading = hasCancellationError
        ? "Sorry, there was a problem"
        : `Are you sure you want to clear ${processingPhotosCount} loading photo${
              isMultiple ? "s" : ""
          }?`;

    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            handleClose={onClose}
            heading={heading}
        >
            <ModalChildrenContainer>
                {hasCancellationError ? (
                    <>
                        <p>Please refresh the page and try again</p>
                        <RefreshButton
                            onClick={() => location.reload()}
                            styleType="cta"
                        >
                            Refresh
                        </RefreshButton>
                    </>
                ) : (
                    <>
                        <ClearButton
                            onClick={onConfirm}
                            isDisabled={processingPhotosCount === 0}
                        >
                            {`Yes, clear photo${isMultiple ? "s" : ""}`}
                        </ClearButton>
                        <Button onClick={onClose} styleType="outline">
                            Cancel
                        </Button>
                    </>
                )}
            </ModalChildrenContainer>
        </Modal>
    );
};

ClearProcessingPhotosModal.propTypes = {
    processingPhotosCount: PropTypes.number,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    respErrors: PropTypes.array
};

export default ClearProcessingPhotosModal;
