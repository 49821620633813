import { convertPxToRem } from "frontend/components/helpers";

export const handleModalHeight = ({
    footerChildren,
    heading,
    NONMOBILE_MODALCONTENT_MAXHEIGHT_PX,
    HEADER_FOOTER_HEIGHT_PX
}) => {
    if (!heading && !footerChildren) {
        return convertPxToRem({
            px: NONMOBILE_MODALCONTENT_MAXHEIGHT_PX
        });
    } else if (!footerChildren || !heading) {
        return convertPxToRem({
            px: NONMOBILE_MODALCONTENT_MAXHEIGHT_PX - HEADER_FOOTER_HEIGHT_PX
        });
    } else if (!!heading && !!footerChildren) {
        return convertPxToRem({
            px:
                NONMOBILE_MODALCONTENT_MAXHEIGHT_PX -
                HEADER_FOOTER_HEIGHT_PX * 2
        });
    } else {
        return convertPxToRem({ px: NONMOBILE_MODALCONTENT_MAXHEIGHT_PX });
    }
};
