import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import colors from "core/colors";

const createHelpers = (width, height, css) => {
    // somehow sizes is ending up in markup, even if it is not a valid svg attribute
    // until we have a better solution, just render it empty, instead to '[Object object]'
    const sanitizeSizes = sizes =>
        Object.defineProperty(sizes, "toString", {
            value: () => "",
            enumerable: false
        });

    const getDimensions = (size, sizes) => {
        if (
            size &&
            typeof size.width === "number" &&
            typeof size.height === "number"
        ) {
            return size;
        }

        return size && sizes[size] ? sizes[size] : { width, height };
    };

    const getCss = (size, sizes, fillColor, fillColorRule, noStyles) => {
        if (noStyles) {
            return "";
        }

        const dimensions = getDimensions(size, sizes);
        const fillRule =
            fillColor && fillColorRule
                ? `${fillColorRule}{ fill: ${fillColor}; }`
                : "";

        return css`
            width: ${dimensions.width}px;
            height: ${dimensions.height}px;
            ${fillRule}
        `;
    };

    const propsToCss = ({ size, sizes, fillColor, fillColorRule, noStyles }) =>
        getCss(size, sizes, fillColor, fillColorRule, noStyles);

    return {
        getCss,
        getDimensions,
        propsToCss,
        sanitizeSizes
    };
};

const width = "190";
const height = "190";
const viewBox = "0 0 190 190";

const { getDimensions, getCss, propsToCss, sanitizeSizes } = createHelpers(
    width,
    height,
    css
);

const sizes = sanitizeSizes({
    sm: { width: 98, height: 98 },
    md: { width: 101, height: 101 },
    lg: { width: 190, height: 190 }
});

const Image = styled.svg`
    ${propsToCss}
`;

const children = (
    <Fragment>
        <mask
            id="s-bb364d9f7d-a"
            width="143"
            height="151"
            x="14"
            y="19"
            fill="#000"
            maskUnits="userSpaceOnUse"
            key="[object Object]"
        >
            <path fill="#fff" d="M14 19h143v151H14z" />
            <path
                fillRule="evenodd"
                d="M120.191 74.787c0 27.838-22.566 50.404-50.404 50.404-27.837 0-50.404-22.566-50.404-50.404 0-27.837 22.567-50.404 50.404-50.404 27.838 0 50.404 22.567 50.404 50.404zm-18.368 44.45c-9.01 6.505-20.075 10.337-32.036 10.337C39.53 129.574 15 105.045 15 74.787S39.53 20 69.787 20c30.258 0 54.787 24.53 54.787 54.787 0 16.129-6.969 30.63-18.06 40.655l48.194 48.194a3.001 3.001 0 01-4.243 4.243l-48.642-48.642z"
                clipRule="evenodd"
            />
        </mask>
        <path
            fill={colors.secondary.orca}
            fillRule="evenodd"
            d="M120.191 74.787c0 27.838-22.566 50.404-50.404 50.404-27.837 0-50.404-22.566-50.404-50.404 0-27.837 22.567-50.404 50.404-50.404 27.838 0 50.404 22.567 50.404 50.404zm-18.368 44.45c-9.01 6.505-20.075 10.337-32.036 10.337C39.53 129.574 15 105.045 15 74.787S39.53 20 69.787 20c30.258 0 54.787 24.53 54.787 54.787 0 16.129-6.969 30.63-18.06 40.655l48.194 48.194a3.001 3.001 0 01-4.243 4.243l-48.642-48.642z"
            clipRule="evenodd"
            key="key-0"
        />
        <path
            fill={colors.secondary.orca}
            d="M101.823 119.237l.707-.707-.602-.602-.69.498.585.811zm4.691-3.795l-.671-.741-.78.705.744.744.707-.708zm48.194 48.194l-.708.707.708-.707zm0 4.243l-.708-.707.708.707zm-84.92-41.688c28.389 0 51.403-23.014 51.403-51.404h-2c0 27.285-22.118 49.404-49.404 49.404v2zM18.382 74.787c0 28.39 23.014 51.404 51.404 51.404v-2c-27.285 0-49.404-22.119-49.404-49.404h-2zm51.404-51.404c-28.39 0-51.404 23.014-51.404 51.404h2c0-27.285 22.119-49.404 49.404-49.404v-2zm51.404 51.404c0-28.39-23.014-51.404-51.404-51.404v2c27.285 0 49.404 22.119 49.404 49.404h2zm-51.404 55.787c12.178 0 23.448-3.903 32.622-10.526l-1.171-1.622c-8.845 6.386-19.707 10.148-31.45 10.148v2zM14 74.787c0 30.811 24.977 55.787 55.787 55.787v-2C40.081 128.574 16 104.493 16 74.787h-2zM69.787 19C38.977 19 14 43.977 14 74.787h2C16 45.081 40.081 21 69.787 21v-2zm55.787 55.787c0-30.81-24.976-55.787-55.787-55.787v2c29.706 0 53.787 24.081 53.787 53.787h2zm-18.39 41.397c11.293-10.207 18.39-24.974 18.39-41.397h-2c0 15.834-6.84 30.069-17.731 39.914l1.341 1.483zm-1.377-.034L154 164.343l1.415-1.414-48.194-48.194-1.414 1.415zM154 164.343a2 2 0 010 2.829l1.415 1.414a4 4 0 000-5.657L154 164.343zm0 2.829a2 2 0 01-2.828 0l-1.414 1.414a4 4 0 005.657 0L154 167.172zm-2.828 0L102.53 118.53l-1.414 1.414 48.642 48.642 1.414-1.414z"
            mask="url(#s-bb364d9f7d-a)"
            key="key-1"
        />
    </Fragment>
);

const defaultProps = {
    children,
    viewBox,
    fillColor: null,
    fillColorRule: "&&& path, &&& use, &&& g",
    sizes,
    size: null
};

Image.propTypes /* remove-proptypes */ = {
    fillColor: PropTypes.string,
    fillColorRule: PropTypes.string,
    viewBox: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    size: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            height: PropTypes.number.isRequired,
            width: PropTypes.number.isRequired
        })
    ]),
    sizes: PropTypes.shape({
        height: PropTypes.number,
        width: PropTypes.number
    })
};

export default Object.assign(Image, {
    getDimensions,
    getCss,
    defaultProps,
    displayName: "NotFound404"
});
