import React from "react";
import { useSelector } from "react-redux";

import { getFooterVisibility } from "frontend/store/layout";

import { TextLinksContent } from "./TextLinksContent";
import { Socials } from "./Socials";
import { NounGazetteSubscription } from "./NounGazetteSubscription";

import Flexbox from "core/Flexbox";
import { Row, Col } from "core/oldGrid";
import colors from "core/colors";
import {
    Footer,
    SiteFooterWrapper,
    SiteFooterGrid,
    CopyrightBar,
    NounProjectLogo,
    DesktopTextLinks,
    DesktopTextLinksCol,
    TabletMobileTextLinks,
    HomeLink
} from "./styles";

const StyledFooter = () => {
    return (
        <Footer id="site-footer" data-testid="site-footer">
            <SiteFooterWrapper fullWidth>
                <SiteFooterGrid>
                    <Row between="xs" top="md">
                        <Col sm={12} md={6} lg={3}>
                            <HomeLink href="/" alt="Home">
                                <NounProjectLogo
                                    title="Noun Project Logo"
                                    fillColor={colors.primary.diamond}
                                />
                            </HomeLink>
                            <Socials />
                        </Col>
                        <DesktopTextLinksCol sm={false} lg={6}>
                            <DesktopTextLinks>
                                <TextLinksContent />
                            </DesktopTextLinks>
                        </DesktopTextLinksCol>
                        <Col sm={12} md={6} lg={3}>
                            <Flexbox justifyContent="flex-end">
                                <NounGazetteSubscription />
                            </Flexbox>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <TabletMobileTextLinks>
                                <TextLinksContent />
                            </TabletMobileTextLinks>
                        </Col>
                    </Row>
                </SiteFooterGrid>
            </SiteFooterWrapper>
            <CopyrightBar fullWidth>© Noun Project Inc.</CopyrightBar>
        </Footer>
    );
};

const SiteFooter = () => {
    const isVisible = useSelector(getFooterVisibility);
    if (!isVisible) {
        return null;
    }
    return <StyledFooter />;
};

// Default component can be used by SPA & SSR pages.
export default SiteFooter;

// Old legacy pages, like /apps/, don't have a redux store at all; so
// we return a simplified component that isn't customizable via Redux.
export const LegacySiteFooter = StyledFooter;
