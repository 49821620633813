import React from "react";
import PropTypes from "prop-types";

import SegmentedControlResults from "core/SegmentedControlResults";
import PhotosGrid from "photos/SearchPhotosResults/Grid";
import SearchEmptyResults from "core/SearchEmptyResults";

import { useBrowsePageContext } from "core/BrowsePage/context";

import { TYPES } from "core/BrowsePage/constants";
import { CONTENT_TYPE } from "core/constants";

const BrowsePhotosByTagResults = ({ term }) => {
    const { activeType, photos } = useBrowsePageContext();

    const {
        firstBatchFetched,
        loadFirstBatchLazy,
        loadingFirstBatch,
        items,
        totalCount,
        LoadMore
    } = photos;

    const allItemsLoaded = items && items.length >= totalCount;
    const showLoadMoreButton = !allItemsLoaded && !!items.length;

    return (
        <SegmentedControlResults
            firstBatchFetched={firstBatchFetched}
            loadFirstBatchLazy={loadFirstBatchLazy}
            isActive={activeType === TYPES.PHOTOS}
        >
            {!!firstBatchFetched && !loadingFirstBatch && !totalCount ? (
                <SearchEmptyResults
                    contentType={CONTENT_TYPE.photo}
                    query={term}
                />
            ) : (
                <>
                    <PhotosGrid items={items} />
                    {showLoadMoreButton && <LoadMore />}
                </>
            )}
        </SegmentedControlResults>
    );
};

BrowsePhotosByTagResults.propTypes = {
    term: PropTypes.string.isRequired
};

export default BrowsePhotosByTagResults;
