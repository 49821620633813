import breakpoints from "core/breakpoints";

export const grid = {
    gridSize: 12,
    gutterWidth: 1,
    outerMargin: 0,
    mediaQuery: "only screen",
    breakpoints
};

export const containerMaxWidth = "82.375rem";
