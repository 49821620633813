import React, { useEffect, useState } from "react";

// components
import Link from "core/Link";
import SearchAndBrowsePageContainer from "core/SearchAndBrowsePageContainer";
import SearchAndBrowsePageHeadlineWithAvatar from "core/SearchAndBrowsePageHeadlineWithAvatar";
import CollectionLoader from "icons/CollectionLoader";
import SearchAndBrowseCollectionGrid from "icons/SearchAndBrowseCollectionGrid";
import PageMeta from "core/PageMeta";

// helpers
import { useParams, Redirect } from "react-router-dom";
import { useApolloClient, useQuery } from "@apollo/client";
import { useHistory, useLocation } from "react-router";
import querystring from "query-string";
import { getButtonText } from "frontend/hooks/useLoadMore/helpers";

// queries:
import { BROWSE_COLLECTIONS_BY_CREATOR } from "./queries";
import LoadMoreButton from "icons/LoadMoreButton";

import { homePath, PUBLIC_PROFILE } from "data/links";
import { PROD_BASE_URL, CONTENT_TYPE } from "core/constants";

const BrowseCollectionsByCreator = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const { username } = useParams();
    const client = useApolloClient();
    const location = useLocation();
    const history = useHistory();

    const [page, setPage] = useState(1);
    const limit = 99;
    const iconsPerCollectionLimit = 6;
    const QUERY = BROWSE_COLLECTIONS_BY_CREATOR;

    const updateURL = ({ pageNum }) => {
        let newLocation = {
            ...location,
            search: `p=${pageNum}`
        };

        history.replace(newLocation);
    };

    useEffect(() => {
        const { p } = querystring.parse(location.search);

        if (p > 1) {
            const pageWasCached = cacheContainsPageData({ p });

            if (pageWasCached) {
                setPage(parseInt(p));
            } else {
                updateURL({ pageNum: 1 });
            }
        } else {
            updateURL({ pageNum: 1 });
        }
    }, []);

    const { loading, data, fetchMore } = useQuery(QUERY, {
        variables: {
            username,
            iconsPerCollectionLimit,
            limit
        },
        notifyOnNetworkStatusChange: true
    });

    const usernameIsInvalid = data && !data.browseCollectionsByCreator;
    if (usernameIsInvalid) {
        return <Redirect to={homePath.url} />;
    }

    const items = data ? data.browseCollectionsByCreator.items : [];
    const avatarUrl = data
        ? data.browseCollectionsByCreator.creator.avatarUrl
        : "";
    const totalCount = data ? data.browseCollectionsByCreator.totalCount : 0;
    const name = data ? data.browseCollectionsByCreator.creator.name : "";
    const pageMetaTitle = `All Icon Sets by ${name} | Noun Project`;
    const pageMetaDescription = `Download SVG and PNG icon sets by ${name} at Noun Project. Browse these icons and millions more today!`;
    const canonicalURL =
        PROD_BASE_URL +
        PUBLIC_PROFILE.replace(":username", encodeURIComponent(username));

    const fetchMoreItems = () => {
        fetchMore({
            variables: {
                offset: page * limit
            },
            updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;

                fetchMoreResult.browseCollectionsByCreator.items = [
                    ...prevResult.browseCollectionsByCreator.items,
                    ...fetchMoreResult.browseCollectionsByCreator.items
                ];

                return Object.assign({}, prevResult, fetchMoreResult);
            }
        });
        let newPageNum = page + 1;
        updateURL({ pageNum: newPageNum });
        setPage(newPageNum);
    };

    function cacheContainsPageData({ p }) {
        if (p <= 1) {
            return false;
        } else {
            try {
                client.readQuery({
                    query: QUERY,
                    variables: {
                        username,
                        iconsPerCollectionLimit,
                        limit
                    }
                });
            } catch {
                return false;
            }

            return true;
        }
    }

    const itemsLength = items.length;
    // if num of results is divisible by 3,
    // then we have an even grid and this will return 0
    const isDivisible = itemsLength % 3;
    const subtractRemainder = itemsLength - isDivisible;

    // if items is an empty arr, or equals the exact amount of results,
    // or isDivisible returns a 0, then we just return original items
    // we want to keep the grid at an even 3 so when the user clicks
    // Load More, we have a smooth loading animation experience without an empty box
    // on the already loaded items
    const shouldReturnItems =
        !items || itemsLength === totalCount || !isDivisible;

    const collectionData = shouldReturnItems
        ? items
        : isDivisible
        ? items.slice(0, subtractRemainder)
        : items;

    const LoadMore = () => (
        <LoadMoreButton
            buttonText={getButtonText({
                limit,
                contentType: CONTENT_TYPE.iconSet,
                totalCount,
                currentPage: page
            })}
            loading={loading}
            loadMore={fetchMoreItems}
        />
    );

    return (
        <>
            <PageMeta
                title={pageMetaTitle}
                canonicalURL={canonicalURL}
                indexContent="noindex,follow"
                description={pageMetaDescription}
            />

            <SearchAndBrowsePageContainer>
                <SearchAndBrowsePageHeadlineWithAvatar
                    showSkeleton={loading}
                    avatarUrl={avatarUrl}
                >
                    All Icon Sets by{" "}
                    <Link
                        href={PUBLIC_PROFILE.replace(
                            ":username",
                            encodeURIComponent(username)
                        )}
                        size="lg"
                        styleType="primary"
                        display="inline"
                    >
                        {name}
                    </Link>
                </SearchAndBrowsePageHeadlineWithAvatar>

                <SearchAndBrowseCollectionGrid
                    totalCount={totalCount}
                    items={collectionData}
                    LoadMore={LoadMore}
                />

                {loading && <CollectionLoader loadingItemCount={limit} />}
            </SearchAndBrowsePageContainer>
        </>
    );
};

export default BrowseCollectionsByCreator;
