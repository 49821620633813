import React from "react";
import PropTypes from "prop-types";

import PhotoUploadMastheadTooltip from "../PhotoUploadCore/PhotoUploadMastheadTooltip";
import PhotoUploadPhotoShootName from "../PhotoUploadPhotoShootName";
import PhotoUploadInactiveTabMessage from "../PhotoUploadInactiveTabMessage";

import { usePhotoSubmissionStatus } from "../../PhotoUploadPage/PhotoUploadCore/hooks";
import Flexbox from "core/Flexbox";
import {
    MastheadContainer,
    MastheadHeading,
    MastheadSecondaryText,
    MastheadHeadingContainer,
    MastheadSecondLine
} from "../PhotoUploadCore/styles";

const PhotoUploadPhotoShootMasthead = ({
    currentPhotoShootId,
    uploadsInDropzoneCount
}) => {
    const { batchPhotoSubmissionBalance } = usePhotoSubmissionStatus();

    return (
        <MastheadContainer>
            <Flexbox justifyContent="space-between" alignItems="center">
                <MastheadHeadingContainer>
                    <MastheadHeading>Upload Photo Shoot</MastheadHeading>
                    <PhotoUploadMastheadTooltip />
                </MastheadHeadingContainer>
                {/* reinstate inactive tab message when FAQ link is ready */}
                {/* <PhotoUploadInactiveTabMessage
                    uploadsInDropzoneCount={uploadsInDropzoneCount}
                /> */}
            </Flexbox>
            <MastheadSecondLine>
                <Flexbox justifyContent="space-between" alignItems="center">
                    <MastheadSecondaryText>
                        <PhotoUploadPhotoShootName
                            currentPhotoShootId={currentPhotoShootId}
                        />
                    </MastheadSecondaryText>
                    <MastheadSecondaryText>
                        Submit up to {batchPhotoSubmissionBalance} photos
                    </MastheadSecondaryText>
                </Flexbox>
            </MastheadSecondLine>
        </MastheadContainer>
    );
};

PhotoUploadPhotoShootMasthead.propTypes = {
    currentPhotoShootId: PropTypes.string,
    // suppressing console log by removing required until this is resolved - see above
    // uploadsInDropzoneCount: PropTypes.number.isRequired
    uploadsInDropzoneCount: PropTypes.number
};

export default PhotoUploadPhotoShootMasthead;
