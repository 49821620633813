export const isNotEmpty = value => {
    // Check if value is not null and not undefined
    if (value !== null && value !== undefined) {
        // Check if value is not an empty string
        if (typeof value === "string" && value.trim() !== "") {
            return true;
        }
        // Check if value is not an empty array
        if (Array.isArray(value) && value.length > 0) {
            return true;
        }
    }
    return false;
};
