import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// context
import { useSearchBar } from "core/SiteHeaderV2/components/SearchBar/context/useSearchBar.js";

// styles
import { convertPxToRem } from "frontend/components/helpers";
import colors from "core/colors";

const FormStyled = styled.form`
    background-color: ${colors.primary.snow};
    display: flex;
    align-items: center;
    height: ${convertPxToRem(40)};
`;

// component
export const Form = ({ children }) => {
    const {
        dropDown: {
            selectedOption: { searchParamKey }
        },
        inputValue
    } = useSearchBar();

    const onSubmit = e => {
        e.preventDefault();
        const searchUrl = `/search/${searchParamKey}/?q=${inputValue}`;
        window.location.href = searchUrl; // Change to your desired URL
    };

    return <FormStyled onSubmit={onSubmit}>{children}</FormStyled>;
};

export default Form;

// prop-types
Form.propTypes = {
    children: PropTypes.any
};
