import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { useReleases, releaseUploadStatus } from "./hooks/useReleases";

import { ModalContainer } from "./styles";
import ReleaseList from "./ReleaseList";
import ReleasesUploadError from "./ReleasesUploadError";
import ReleaseUploadButton from "./ReleaseUploadButton";

function getHeadingText(count) {
    if (count === 1) {
        return `${count} Release For This Batch`;
    } else if (count > 1) {
        return `${count} Releases For This Batch`;
    } else {
        return "Releases For This Batch";
    }
}

const ManageReleasesModal = ({ onClose, isModalOpen }) => {
    const { releases, getReleases } = useReleases();

    useEffect(() => {
        if (isModalOpen) {
            getReleases();
        }
    }, [isModalOpen]);

    const completeReleaseCount = releases.filter(
        ({ status }) => status === releaseUploadStatus.UPLOAD_COMPLETE
    ).length;

    const handleClose = () => {
        onClose();
    };

    const footerChildren = <ReleaseUploadButton />;

    return (
        <ModalContainer
            open={isModalOpen}
            onClose={handleClose}
            handleClose={handleClose}
            heading={getHeadingText(completeReleaseCount)}
            footerChildren={footerChildren}
        >
            <ReleasesUploadError />
            <ReleaseList />
        </ModalContainer>
    );
};

ManageReleasesModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    isModalOpen: PropTypes.bool.isRequired
};

export default ManageReleasesModal;
