import React from "react";
import { useParams } from "react-router-dom";

import Link from "core/Link";
import SearchAndBrowsePageContainer from "core/SearchAndBrowsePageContainer";
import SearchAndBrowsePageHeadlineWithAvatar from "core/SearchAndBrowsePageHeadlineWithAvatar";
import SearchAndBrowseIconGridContainer from "icons/SearchAndBrowseIconGridContainer";
import PageMeta from "core/PageMeta";

import useLoadMore from "frontend/hooks/useLoadMore";

import { GET_BROWSE_ICONS_BY_CREATOR_DATA } from "./queries";
import { PROD_BASE_URL, CONTENT_TYPE } from "core/constants";
import { PUBLIC_PROFILE } from "data/links";

const BrowseIconsByCreator = () => {
    const { username } = useParams();

    const {
        items,
        loading,
        LoadMore,
        totalCount,
        loadingFirstBatch,
        loadingItemCount,
        data
    } = useLoadMore({
        limit: 200,
        query: GET_BROWSE_ICONS_BY_CREATOR_DATA,
        queryVariables: {
            username
        },
        pathToListKey: "browseIconsByCreator.items",
        pathToTotalCountKey: "browseIconsByCreator.totalCount",
        contentType: CONTENT_TYPE.icon
    });

    const creator =
        data && data.browseIconsByCreator && data.browseIconsByCreator.creator;

    const creatorUsername = (creator && creator.username) || "";
    const creatorName = (creator && creator.name) || "";
    const creatorAvatarUrl = (creator && creator.avatarUrl) || "";

    const pageMetaTitle = `All Icons by ${creatorName} | Noun Project`;
    const pageMetaDescription = `Download SVG and PNG icons by ${creatorName} at Noun Project. Browse these icons and millions more today!`;

    const canonicalURL =
        PROD_BASE_URL +
        PUBLIC_PROFILE.replace(
            ":username",
            encodeURIComponent(creatorUsername || username)
        );

    return (
        <>
            <PageMeta
                title={pageMetaTitle}
                canonicalURL={canonicalURL}
                indexContent="noindex,follow"
                description={pageMetaDescription}
            />
            <SearchAndBrowsePageContainer>
                <SearchAndBrowsePageHeadlineWithAvatar
                    showSkeleton={loadingFirstBatch}
                    avatarUrl={creatorAvatarUrl}
                >
                    All Icons by{" "}
                    <Link
                        href={PUBLIC_PROFILE.replace(
                            ":username",
                            encodeURIComponent(creatorUsername || username)
                        )}
                        size="lg"
                        styleType="primary"
                        display="inline"
                    >
                        {creatorName}
                    </Link>
                </SearchAndBrowsePageHeadlineWithAvatar>
                <SearchAndBrowseIconGridContainer
                    iconData={items}
                    totalCount={totalCount}
                    loadingFirstBatch={loadingFirstBatch}
                    loadingMore={loading}
                    LoadMoreButton={LoadMore}
                    loadingItemCount={loadingItemCount}
                />
            </SearchAndBrowsePageContainer>
        </>
    );
};

export default BrowseIconsByCreator;
