import { useApolloMutation } from "frontend/utils/apolloApi/useApolloMutation.js";
import { useDispatch } from "react-redux";

// gql
import { SET_HAS_WARNING_TEXT_ON_UPLOADS } from "frontend/components/fort/mod/photos/gql/mutations";

// utils
import { useApiUploadUpdate } from "frontend/components/fort/mod/photos/hooks/useApiUploadUpdate.js";

export const useClearHasWarningText = () => {
    const { apiUploadUpdate } = useApiUploadUpdate();

    const { apolloMutate } = useApolloMutation({
        actionDesc: "Uploads: Flags: Has Warning",
        gql: SET_HAS_WARNING_TEXT_ON_UPLOADS
    });

    const clearHasWarningText = async photoUploadIds => {
        const { ssResp } = await apolloMutate({
            variables: {
                photoUploadIds,
                value: false
            }
        });

        apiUploadUpdate(ssResp);
    };

    return {
        clearHasWarningText
    };
};
