import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import Tabs from "core/Tabs";
import Payouts from "core/ReusableIcons/Payouts";
import Upload from "core/ReusableIcons/Upload";

import breakpoints from "core/breakpoints";
import { useViewport } from "js/hooks/useViewport";

import {
    CREATOR_DASHBOARD_PHOTOS_ROYALTIES,
    CREATOR_DASHBOARD_PHOTOS_PENDING
} from "data/links";

const CreatorDashboardPhotosTabs = () => {
    const { pageContent, username } = useParams();
    const { browserWidth } = useViewport();

    const isMobile = browserWidth <= breakpoints.beforeTablet.px;

    let defaultTabIndex = 0;

    if (pageContent === "photo-royalties") {
        defaultTabIndex = 0;
    }
    if (pageContent === "photos-pending" || pageContent === "photos-denied") {
        defaultTabIndex = 1;
    }

    const tabData = [
        {
            name: isMobile ? "Royalties" : "Photo Royalties",
            icon: Payouts,
            href: CREATOR_DASHBOARD_PHOTOS_ROYALTIES.replace(
                ":username",
                username
            )
        },
        {
            name: isMobile ? "Submissions" : "Photo Submissions",
            icon: Upload,
            href: CREATOR_DASHBOARD_PHOTOS_PENDING.replace(
                ":username",
                username
            )
        }
    ];

    return <Tabs defaultTabIndex={defaultTabIndex} tabData={tabData} />;
};

CreatorDashboardPhotosTabs.propTypes = {
    isMobile: PropTypes.bool
};

export default CreatorDashboardPhotosTabs;
