import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { FormElementContainer } from "core/Form";

import { convertPxToRem } from "frontend/components/helpers";
import { getValidationError } from "core/Form/helpers";

import { TextArea } from "./styles";

const UnstyledFormTextArea = ({
    className,
    placeholderText,
    labelText,
    onChange,
    errorMessageOverride,
    validation,
    darkMode = false,
    size = "md",
    width = "100%",
    isDisabled = false,
    isRequired = false,
    readOnly = false,
    height = convertPxToRem({ px: 150 }),
    inputRef,
    ...textAreaProps
}) => {
    const [errorMessage, setErrorMessage] = useState(null);
    const [showFormLabel, updateFormLabelVisibility] = useState(false);

    const validateInput = input => {
        const validationError = getValidationError({ input, validation });

        setErrorMessage(validationError);
    };

    useEffect(() => {
        if (errorMessageOverride === undefined) return;

        setErrorMessage(errorMessageOverride);
        if (errorMessageOverride) {
            updateFormLabelVisibility(false);
        }
    }, [errorMessageOverride, showFormLabel]);

    const handleFocus = e => {
        e.preventDefault();
        updateFormLabelVisibility(true);
    };

    const handleBlur = e => {
        e.preventDefault();
        updateFormLabelVisibility(false);
        if (validation) validateInput(e.target.value);
    };

    const handleChange = e => {
        e.preventDefault();
        e.target && // check to see if theres a target
            // e.target.value && // check to see if target has a value
            onChange(e.target.value);
    };

    return (
        <FormElementContainer
            className={className}
            width={width}
            darkMode={darkMode}
            labelText={labelText}
            hideLabel={!showFormLabel}
            hideErrorMessage={showFormLabel}
            errorMessage={errorMessage}
        >
            <TextArea
                height={height}
                onFocus={handleFocus}
                onBlur={handleBlur}
                placeholder={placeholderText}
                darkMode={darkMode}
                disabled={isDisabled || readOnly}
                required={isRequired}
                readOnly={readOnly}
                onChange={handleChange}
                size={size}
                ref={inputRef}
                {...textAreaProps}
            />
        </FormElementContainer>
    );
};

const FormTextArea = styled(UnstyledFormTextArea)``;

FormTextArea.propTypes = {
    placeholderText: PropTypes.string.isRequired,
    labelText: PropTypes.string.isRequired,
    width: PropTypes.string,
    height: PropTypes.string,
    darkMode: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isRequired: PropTypes.bool,
    readOnly: PropTypes.bool,
    onChange: PropTypes.func,
    inputRef: PropTypes.object,
    errorMessageOverride: PropTypes.string,
    validation: PropTypes.arrayOf(
        PropTypes.shape({
            isValid: PropTypes.func,
            errorMsg: PropTypes.string
        })
    )
};

export default FormTextArea;
