import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

// components
import Modal2Provider from "core/Modal2";

// redux
import { largeImageViewSlice } from "../../../slices/largeImageViewSlice";

// component
export const ModalProvider = ({ children }) => {
    const dispatch = useDispatch();
    return (
        <Modal2Provider
            onClose={() => dispatch(largeImageViewSlice.actions.close())}
        >
            {children}
        </Modal2Provider>
    );
};

export default ModalProvider;

// prop-types
ModalProvider.propTypes = {
    children: PropTypes.any
};
