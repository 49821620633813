import React, { useState, useContext } from "react";

import Banners from "core/Banners";
import Link from "core/Link";
import Button from "core/Button";
import CookiesModal from "core/CookiesBanner/CookiesModal";
import CookieConsentContext from "frontend/context/CookieConsentContext";

import {
    Container,
    ContentWrapper,
    ManageLink,
    BannerText,
    LinkContainer
} from "./styles";

export default () => {
    const { hasConsentSettings } = useContext(CookieConsentContext);

    if (hasConsentSettings) {
        return null;
    }

    return <CookiesBanner />;
};

const CookiesBanner = () => {
    const { acceptAllCookies } = useContext(CookieConsentContext);
    const [isModalOpen, setModalOpen] = useState(false);

    const handleAcceptCookies = () => {
        setModalOpen(false);
        acceptAllCookies();
    };

    const handleClose = () => {
        setModalOpen(false);
    };

    const bannerContent = (
        <ContentWrapper>
            <BannerText>
                We use cookies per our{" "}
                <a href="/legal/cookie-policy/" target="_blank">
                    Cookie Policy
                </a>{" "}
                to make your experience better.
            </BannerText>

            <LinkContainer>
                <ManageLink
                    isButton
                    disableVisitedStyle
                    onClick={() => setModalOpen(true)}
                >
                    Manage
                </ManageLink>
                <Button size="lg" onClick={handleAcceptCookies}>
                    Accept
                </Button>
            </LinkContainer>
        </ContentWrapper>
    );

    return (
        <Container>
            <Banners
                bannerKey="CookiesBanner"
                bannerContent={bannerContent}
                bannerContentMobile={bannerContent}
                isDismissible={false}
                isCookieBanner
            />
            <CookiesModal isModalOpen={isModalOpen} handleClose={handleClose} />
        </Container>
    );
};
