import styled from "styled-components";

import appColors from "core/colors";

import { easingSeconds, rapidEasing } from "core/animation";
import { focusOutline } from "core/links";

const colors = {
    primary: {
        lightMode: {
            icon: appColors.secondary.orca,
            hover: appColors.secondary.oolong
        },
        darkMode: {
            icon: appColors.primary.diamond,
            hover: appColors.secondary.dolphin
        }
    },
    secondary: {
        lightMode: {
            icon: appColors.secondary.oolong,
            hover: appColors.secondary.orca
        },
        darkMode: {
            icon: appColors.accent.darkMode.suenosBuenos,
            hover: appColors.primary.diamond
        }
    }
};

const iconColor = ({ darkMode, styleType }) =>
    darkMode
        ? colors[styleType].darkMode.icon
        : colors[styleType].lightMode.icon;

const hoverColor = ({ darkMode, styleType }) =>
    darkMode
        ? colors[styleType].darkMode.hover
        : colors[styleType].lightMode.hover;

export const StyledButton = styled.button`
    display: inline-block;
    background-color: transparent;
    padding: 0;
    border: none;
    width: ${({ width }) => (width ? width : "auto")};
    cursor: pointer;
    line-height: 0; // removes extra space at the bottom of button

    svg {
        width: 100%;
        height: auto;

        path,
        use,
        g,
        circle {
            transition: all ${easingSeconds};
            fill: ${iconColor};
        }
    }

    &:hover {
        svg {
            path,
            use,
            g,
            circle {
                transition: all ${easingSeconds};
                fill: ${hoverColor};
            }
        }
    }

    &:focus {
        transition: all ${rapidEasing};
        ${focusOutline}

        svg {
            path,
            use,
            g,
            circle {
                transition: all ${easingSeconds};
                fill: ${hoverColor};
            }
        }
    }
`;
