import { useApolloMutation } from "frontend/utils/apolloApi/useApolloMutation.js";

// GQL
import { REJECT_PHOTO_UPLOAD_TITLE } from "frontend/components/fort/mod/photos/gql/mutations";

export const useRejectPhotoUploadTitle = () => {
    const { apolloMutate, loading } = useApolloMutation({
        actionDesc: "Reject Photo Upload Title",
        gql: REJECT_PHOTO_UPLOAD_TITLE
    });

    const rejectPhotoUploadTitle = async variables => {
        return await apolloMutate({ variables });
    };

    return {
        rejectPhotoUploadTitle,
        rejectPhotoUploadTitleLoading: loading
    };
};
