import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

// styles
import Heading from "core/Heading";
import colors from "core/colors";
import { convertPxToRem } from "frontend/components/helpers";
import { regular as regularFontWeight } from "core/fontStyles";

export const LicenseTitleStyled = styled(Heading)`
    ${regularFontWeight}
    font-size: ${convertPxToRem(15)};
    margin-bottom: ${convertPxToRem(16)};
    color: ${colors.secondary.orca};
`;
// component
export const LicenseTitle = ({ text }) => {
    return (
        <LicenseTitleStyled size="md" type="h3">
            {text}
        </LicenseTitleStyled>
    );
};

export default LicenseTitle;

// prop-types
LicenseTitle.propTypes = {
    text: PropTypes.string
};
