import styled, { css } from "styled-components";

import BodyText from "core/BodyText";
import Flexbox from "core/Flexbox";
import { textSm } from "core/fontStyles";
import { ErrorContainer } from "core/Form/styles";
import colors from "core/colors";
import { focusOutline } from "core/links";
import { BorderBottomLinkStyle } from "core/Typography";
import { convertPxToRem } from "frontend/components/helpers";

const ERROR_CONTAINER_TOP_MARGIN_REM = css`-${convertPxToRem({ px: 3 })}`;

const TAG_SPACING_PX = 8;

export const LabelBodyText = styled(BodyText).attrs({
    type: "label",
    size: "lg"
})`
    display: inline-block;
    margin-bottom: ${convertPxToRem({ px: 8 })};

    &:after {
        ${textSm}
        display: inline-block;
        margin-left: ${convertPxToRem({ px: 8 })};

        ${({ isRequired }) =>
            isRequired
                ? css`
                      content: "Required";
                  `
                : css`
                      content: "Optional";
                  `}
    }
`;

export const FlexContainer = styled.div``;

export const FlexboxColumn = styled(Flexbox).attrs({
    flexDirection: "column"
})`
    flex-basis: auto;
    flex: 1;

    &:first-child {
        flex-basis: 85%;
        width: 85%;
    }

    &:last-child {
        margin-left: ${convertPxToRem({ px: 16 })};
    }

    input[type="text"],
    input[type="text"]:read-only:hover {
        margin-bottom: ${convertPxToRem({ px: 4 })};
        // height property matches height of md sized FormInput:
        height: ${convertPxToRem({ px: 40 })};
    }
`;

export const FlexboxRow = styled(Flexbox).attrs({
    flexDirection: "row",
    width: "100%"
})`
    &:last-child {
        ${FlexboxColumn}:last-child {
            min-width: ${convertPxToRem({ px: 50 })};
        }
    }
`;

export const ErrorTextContainer = styled(ErrorContainer).attrs({
    showError: true
})`
    ${textSm}

    ${({ hasTagsAdded }) =>
        hasTagsAdded &&
        css`
            margin-bottom: ${convertPxToRem({ px: 8 })};
        `}

    &:before {
        top: -${convertPxToRem({ px: 6 })};
    }

    span {
        display: block;
        margin-top: ${ERROR_CONTAINER_TOP_MARGIN_REM};
    }
`;

export const InformationalTextContainer = styled(ErrorContainer).attrs({
    showError: true
})`
    ${textSm}
    color: ${colors.secondary.orca};
    margin-top: ${ERROR_CONTAINER_TOP_MARGIN_REM};

    ${({ hasTagsAdded }) =>
        hasTagsAdded &&
        css`
            margin-bottom: ${convertPxToRem({ px: 8 })};
        `}


    a {
        ${BorderBottomLinkStyle}
        color: ${colors.accent.lightMode.persian};
        border-color: ${colors.accent.lightMode.persian};
        padding-bottom: ${convertPxToRem({ px: 1 })};

        &:hover {
            color: ${colors.secondary.cosmic};
            border-color: ${colors.secondary.cosmic};
        }


        // only show focus on keyboard not with mouse:
        &:focus:not(.focus-visible),
        &:active {
            outline: none;
            box-shadow: none;
        }

        &:focus {
            ${focusOutline}
        }
    }

    &:before {
        background-color: transparent;
    }
`;

export const TagsContainer = styled.div`
    margin-bottom: -${convertPxToRem({ px: TAG_SPACING_PX })};
`;

////////// INPUT ////////////

import { SHARED_FORM_STYLES } from "core/Form";

export const TagsMainContainer = styled.div``;
export const DropdownContainer = styled.div``;

export const Input = styled.input``;

export const InputWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;

    ${Input} {
        ${SHARED_FORM_STYLES.BASE}
        margin-bottom: 0;
        padding-top: 0;
        padding-bottom: 0;
        width: 100%;
        position: relative;
        //padding-left: 2rem;

        &:focus {
            outline: 0;
            ${SHARED_FORM_STYLES.FOCUS}
        }

        &:hover {
            ${SHARED_FORM_STYLES.HOVER}
        }

        &::placeholder {
            ${SHARED_FORM_STYLES.PLACEHOLDER}
        }

        &:read-only {
            &:hover {
                ${SHARED_FORM_STYLES.BASE}
                margin-bottom: 0;
                width: 100%;
                position: relative;
            }
            &::placeholder {
                color: ${colors.secondary.orca};
            }
        }
    }
`;

export const TagsDropdownContainer = styled.div`
    position: relative;
    z-index: 999;
`;

export const TagsDropdownBox = styled.div`
    z-index: 999;
    position: absolute;
    background: ${colors.primary.snow};
    width: 100%;
    box-shadow: 0.0625rem 0.0625rem 0.25rem rgba(0, 0, 0, 0.1);
    max-height: ${({ maxHeight }) => maxHeight || "auto"};
    overflow-y: auto;

    // only show focus on keyboard not with mouse:
    &:focus:not(.focus-visible) {
        outline: none;
        border-radius: 0;
    }

    &.focus-visible,
    &:focus-visible {
        border: none;
        ${focusOutline};
    }
`;

export const TagListItem = styled.li`
    padding: 12px;
    cursor: pointer;
    background-color: #fff;

    ${({ isHovered }) =>
        isHovered &&
        css`
            background-color: #000;

            ${BodyText} {
                color: #fff;
            }
        `}

    &:hover {
        background-color: #000;

        ${BodyText} {
            color: #fff;
        }
    }
`;
