import styled from "styled-components";
import { Link } from "react-router-dom";

import colors from "core/colors";
import Heading from "core/Heading";

import { regular } from "core/fontStyles";

export const MenuGroupNameStyled = styled(Heading).attrs({
    type: "h2",
    size: "md"
})`
    ${regular};
`;

export const MenuItemStyled = styled(Link)`
    display: inline-flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;

    padding: 1.25rem 2rem;

    border: none;
    background-color: transparent;
    text-decoration: none;

    font-size: 1.4375rem;

    cursor: pointer;
`;

export const MenuGroupListStyled = styled.div`
    outline: 1px solid ${colors.primary.scheifer};
    border-radius: 0.125rem;

    ${MenuItemStyled}:not(:last-of-type) {
        border-bottom: 1px solid ${colors.primary.scheifer};
    }
`;

export const MenuGroupStyled = styled.div`
    ${MenuGroupNameStyled} {
        margin-bottom: 1.25rem;
    }
`;

export const MenuStyled = styled.div`
    ${MenuGroupStyled}:not(:last-of-type) {
        margin-bottom: 3rem;
    }
`;
