import styled from "styled-components";

import { OldGrid } from "core/oldGrid";
import breakpoints from "core/breakpoints";

export const SearchGrid = styled(OldGrid).attrs({ fluid: true })`
    width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    max-width: 83rem;
    margin-bottom: 2rem;

    /* Tablet */
    @media (min-width: ${breakpoints.customMobileMax
            .rem}) and (max-width: ${breakpoints.tablet.rem}) {
        margin-top: -3.75rem;
    }
`;
