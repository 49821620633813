import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Link from "core/Link";
import Modal from "core/Modal";
import BodyText from "core/BodyText";
import Button from "core/Button";

import { convertPxToRem } from "frontend/components/helpers";

export const CtaContainer = styled.div`
    margin-top: ${convertPxToRem({ px: 32 })};

    ${Button} {
        margin-right: ${convertPxToRem({ px: 24 })};
    }
`;

const PhotoUploadDuplicateFilenameModal = ({
    replacePhotos,
    keepAllPhotos,
    closeModal,
    errors
}) => {
    const duplicateCount = errors ? errors.length : 0;

    if (duplicateCount === 0) return null;

    const handleReplaceButtonClick = () => {
        replacePhotos();
        closeModal();
    };

    const handleKeepAllPhotosButtonClick = () => {
        keepAllPhotos();
        closeModal();
    };

    const getHeading = () => {
        if (duplicateCount > 1) {
            return `${duplicateCount} photo names already exist. Do you want to replace them?`;
        } else {
            return `"${errors[0].filename}" already exists. Do you want to replace it?`;
        }
    };

    const getBodyText = () => {
        if (duplicateCount > 1) {
            return "Multiple photos share the same names. Replacing them will overwrite these photos.";
        } else {
            return "A photo with the same name already exists. Replacing it will overwrite the photo.";
        }
    };

    const getReplaceText = () => {
        if (duplicateCount > 1) {
            return "Yes, replace photos";
        } else {
            return "Yes, replace photo";
        }
    };

    const getKeepAllText = () => {
        if (duplicateCount > 1) {
            return "No, keep all photos";
        } else {
            return "No, keep both photos";
        }
    };

    return (
        <Modal
            open={true}
            handleClose={handleKeepAllPhotosButtonClick}
            heading={getHeading()}
            headingCharLimit={null}
            closeOnEscape={true}
            closeOnBackDropClick={true}
        >
            <BodyText size="md">{getBodyText()}</BodyText>
            <CtaContainer>
                <Button onClick={handleReplaceButtonClick}>
                    {getReplaceText()}
                </Button>
                <Link
                    onClick={handleKeepAllPhotosButtonClick}
                    size="md"
                    styleType="primary"
                    isButton
                    disableVisitedStyle
                >
                    {getKeepAllText()}
                </Link>
            </CtaContainer>
        </Modal>
    );
};

PhotoUploadDuplicateFilenameModal.propTypes = {
    modalIsOpen: PropTypes.bool,
    closeModal: PropTypes.func.isRequired,
    replacePhotos: PropTypes.func.isRequired,
    keepAllPhotos: PropTypes.func.isRequired,
    errors: PropTypes.array.isRequired
};

export default PhotoUploadDuplicateFilenameModal;
