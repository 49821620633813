import React from "react";
import PropTypes from "prop-types";

// components
import SideBarSection from "../SideBarSection";

export const Overview = ({ children, title = "Overview" }) => {
    return <SideBarSection title={title}>{children}</SideBarSection>;
};

export default Overview;

// prop-types
Overview.propTypes = {
    children: PropTypes.any,
    title: PropTypes.string
};
