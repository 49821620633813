import React from "react";
import styled from "styled-components";

// Auth
import { LOGIN_MODE } from "frontend/hooks/useAuth";

// context
import { useSiteHeader } from "core/SiteHeaderV2/context/useSiteHeader.js";

// components
import Button from "core/Button";

// styles
import { convertPxToRem } from "frontend/components/helpers";
const LoginButtonStyled = styled(Button)`
    height: ${convertPxToRem(40)};
`;

// component
export const LoginButton = () => {
    const { isDarkMode, auth } = useSiteHeader();
    const onClick = () => {
        auth.confirmAuth({ mode: LOGIN_MODE });
    };

    return (
        <LoginButtonStyled
            onClick={onClick}
            darkMode={isDarkMode}
            size="lg"
            styleType="outline"
        >
            Log In
        </LoginButtonStyled>
    );
};

export default LoginButton;
