import React from "react";

import IconGridItem from "./IconGridItem";
import SkeletonGridItem from "icons/SkeletonIconGrid/SkeletonIconGridItem";

export const formatDataAsGridContent = ({ iconData }) => {
    return iconData.map(icon => {
        let iconThumbnail = "";

        if (icon.thumbnails.thumbnail200)
            iconThumbnail = icon.thumbnails.thumbnail200;

        return icon.showSkeleton ? (
            <SkeletonGridItem />
        ) : (
            <IconGridItem
                key={icon.id}
                iconId={icon.id}
                iconTitle={icon.title}
                iconPreviewUrl={iconThumbnail}
                iconDetailPermalink={icon.url}
            />
        );
    });
};
