import React from "react";
import PropTypes from "prop-types";

import { RootMessageStackZone } from "frontend/components/core/MessageStack";

// styles
import { QueueHeaderStyled } from "./styles";

// config
const padding = 24;
const paddingPlusHeader = 68 + padding;
const paddingPlusSidebar = 268 + padding;

export const QueueHeader = ({ children }) => {
    return (
        <QueueHeaderStyled>
            <RootMessageStackZone
                anchorPosition={{
                    position: "absolute",
                    small: {
                        top: paddingPlusHeader,
                        right: paddingPlusSidebar,
                        left: padding
                    },
                    mediumLarge: {
                        top: paddingPlusHeader,
                        right: paddingPlusSidebar,
                        left: "auto"
                    }
                }}
            />
            {children}
        </QueueHeaderStyled>
    );
};

// prop-types
QueueHeader.propTypes = {
    children: PropTypes.any
};
