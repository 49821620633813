import gql from "graphql-tag";
import { PHOTO_UPLOAD_WITH_MOD_FRAGMENT } from "../fragments/photoUpload";

/* -------------------------------------------------------------------------- */
/*                                   UPLOADS                                  */
/* -------------------------------------------------------------------------- */
export const FETCH_BATCH_UPLOADS = gql`
    query fetchBatchUploads($batchId: ID!, $limit: Int = 10000) {
        list: listPhotoUploadsInModerationBatch(
            batchId: $batchId
            limit: $limit
        ) {
            items {
                ...ModPhotoUpload
            }
        }
    }
    ${PHOTO_UPLOAD_WITH_MOD_FRAGMENT}
`;

export const FETCH_UPLOAD_IDS_FOR_BATCHES = gql`
    query getPhotoUploadIdsForBatches($batchIds: [ID!]!) {
        list: getPhotoUploadIdsInBatches(batchIds: $batchIds) {
            id: batchId
            uploadIds: photoUploadIds
        }
    }
`;

export const FETCH_BATCH_UPLOAD_LARGE_IMAGE_URLS = gql`
    query fetchBatchUploadLargeImageUrls($batchId: ID!, $limit: Int = 10000) {
        list: listPhotoUploadsInModerationBatch(
            batchId: $batchId
            limit: $limit
        ) {
            items {
                id
                uploadedImageUrl
            }
        }
    }
`;

export const FETCH_PHOTO_COUNTS_FOR_BATCHES = gql`
    query getPhotoCountsForBatches(
        $batchIds: [ID!]!
        $showOnlySuspendedCreators: Boolean! = false
    ) {
        counts: getPhotoCountsForBatches(
            batchIds: $batchIds
            showOnlySuspendedCreators: $showOnlySuspendedCreators
        ) {
            id
            fullyPublishedCount
            photoUploadCount
            visuallyPublishedCount
            inVisualModerationCount
            deniedCount
        }
    }
`;
