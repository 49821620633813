import React, { useState, useEffect } from "react";

import PhotoUploadDuplicateFilenameModal from "./PhotoUploadDuplicateFilenameModal";

import { usePhotoUpload } from "frontend/hooks/usePhotoUpload";
import { useWithdrawPhotoUpload } from "../PhotoUploadCore/hooks";

const PhotoUploadDuplicateFilename = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const openModal = () => setModalIsOpen(true);
    const closeModal = () => setModalIsOpen(false);

    const {
        photoUploads,
        duplicateFilenameErrors,
        clearDuplicateFilenameErrors,
        uploadPhotos
    } = usePhotoUpload();
    const { withdrawPhotos } = useWithdrawPhotoUpload();

    useEffect(() => {
        if (duplicateFilenameErrors && !!duplicateFilenameErrors.length) {
            openModal();
        }
    }, [duplicateFilenameErrors]);

    const reuploadPhotos = files => {
        clearDuplicateFilenameErrors();
        uploadPhotos(files);
    };

    const replacePhotos = () => {
        const idsToWithdraw = duplicateFilenameErrors.map(error => {
            const uploadToWithdraw = photoUploads.find(
                upload => error.filename === upload.originalFilename
            );

            return uploadToWithdraw.id;
        });

        withdrawPhotos({
            variables: { photoUploadIds: idsToWithdraw }
        }).then(res => {
            if (res.data.withdrawPhotoUploads.ok) {
                const filesToReupload = duplicateFilenameErrors.map(
                    error => error.file
                );

                reuploadPhotos(filesToReupload);
            }
        });
    };

    const keepAllPhotos = () => {
        const filenamesInBatch = photoUploads.map(u => u.filename);
        const renamedFiles = duplicateFilenameErrors.map(({ file }) => {
            const filenameParts = file.name.split(".");
            const extension = filenameParts.pop();
            const name = filenameParts.join("");
            let i = 0;

            // handle case where same file name is uploaded more than twice
            while (
                filenamesInBatch.includes(`${name}-copy${i || ""}.${extension}`)
            ) {
                i++;
            }

            return new File([file], `${name}-copy${i || ""}.${extension}`, {
                type: file.type
            });
        });

        reuploadPhotos(renamedFiles);
    };

    if (!modalIsOpen) return null;

    return (
        <PhotoUploadDuplicateFilenameModal
            errors={duplicateFilenameErrors}
            closeModal={closeModal}
            keepAllPhotos={keepAllPhotos}
            replacePhotos={replacePhotos}
        />
    );
};

export default PhotoUploadDuplicateFilename;
