import React from "react";
import PropTypes from "prop-types";

import Heading from "core/Heading";

import { TestimonialContainer, Source } from "./styles";

const TestimonialSection = ({
    testimonials,
    headingType = "h4",
    headingSize = "md",
    alignment = "center"
}) => {
    return (
        <>
            {testimonials.map((testimonial, index) => {
                return (
                    <TestimonialContainer key={index}>
                        <Heading
                            type={headingType}
                            size={headingSize}
                            alignment={alignment}
                        >
                            {testimonial.text}
                        </Heading>
                        <Source alignment={alignment}>
                            {testimonial.source}
                        </Source>
                    </TestimonialContainer>
                );
            })}
        </>
    );
};

TestimonialSection.propTypes = {
    testimonials: PropTypes.array.isRequired,
    headingType: PropTypes.string,
    headingSize: PropTypes.string
};

export default TestimonialSection;
