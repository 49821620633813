export const EVENT_LISTENER_BATCH_NOTE = "submissions.PhotoBatchModerationNote";
export const EVENT_LISTENER_UPLOAD_NOTE =
    "submissions.PhotoUploadModerationNote";

/**
 * getModerationNote
 * Batch and Upload Moderation Note Updates
 * @param {Object} modNote
 * @returns {Mixed} modNote object || null if not a match
 */
export const getModerationNote = ({ eventType, data }) => {
    let modNote = null;

    switch (eventType) {
        case EVENT_LISTENER_BATCH_NOTE:
            modNote = { eventType, data: transformBatchNote(data) };
            break;
        case EVENT_LISTENER_UPLOAD_NOTE:
            modNote = { eventType, data: transformUploadNote(data) };
            break;
    }

    return modNote;
};

/**
 * transformBatchNote
 * Normalizing to match GQL response objects so we do not get mixed data types
 * @param {Object} batchModNote
 * @returns {Object} batchModNote transformed
 */
const transformBatchNote = batchModNote => {
    return {
        ...batchModNote,
        photoBatchId: String(batchModNote.photoBatchId)
    };
};

/**
 * transformUploadNote
 * Normalizing to match GQL response objects so we do not get mixed data types
 * @param {Object} uploadModNote
 * @returns {Object} uploadModNote transformed
 */
const transformUploadNote = uploadModNote => {
    return {
        ...uploadModNote,
        photoUploadId: String(uploadModNote.photoUploadId)
    };
};
