import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { debounce } from "lodash";

// context
import { useDropDown } from "../useDropDown";

// hooks
import { useOnClickOutside } from "js/hooks/useOnClickOutside";

//styles
const MountingWrapperStyled = styled.div`
    display: inline-block;
    position: relative;
`;

// MountingWrapper
const MountingWrapper = ({ children }) => {
    const { close, open, toggleOnHover, hideOnScroll } = useDropDown();

    const { targetRef: mountingWrapperRef } = useOnClickOutside({
        onClickOutside: () => {
            close();
        }
    });

    useEffect(() => {
        const onKeyDown = event => {
            if (event.key === "Escape") {
                close();
            }
        };
        const onScroll = debounce(() => {
            if (hideOnScroll) {
                close();
            }
        }, 300);

        // Add event listener
        document.addEventListener("keydown", onKeyDown);
        document.addEventListener("scroll", onScroll);

        // Cleanup the event listener
        return () => {
            document.removeEventListener("keydown", onKeyDown);
            document.removeEventListener("scroll", onScroll);
        };
    }, [close, hideOnScroll]);

    const onMouseEnter = () => {
        if (toggleOnHover) open();
    };
    const onMouseLeave = () => {
        if (toggleOnHover) close();
    };

    return (
        <MountingWrapperStyled
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            ref={mountingWrapperRef}
        >
            {children}
        </MountingWrapperStyled>
    );
};

export default MountingWrapper;

// prop-types
MountingWrapper.propTypes = {
    children: PropTypes.any
};
