import React from "react";
import PropTypes from "prop-types";

import Menu from "./Menu";
import {
    Item,
    ItemHeading as Heading
} from "js/components/ProfileMenuMobile/styles";

const CreatorProfileMenuMobile = ({
    creatorUsername,
    isPhotoCreator = false,
    isIconCreator = false
}) => {
    if (!isIconCreator && !isPhotoCreator) return null;

    return (
        <>
            <Heading>Creator Tools</Heading>
            <Menu
                creatorUsername={creatorUsername}
                linkComponent={Item}
                isPhotoCreator={isPhotoCreator}
                isIconCreator={isIconCreator}
            />
        </>
    );
};

CreatorProfileMenuMobile.propTypes = {
    creatorUsername: PropTypes.string.isRequired
};

export default CreatorProfileMenuMobile;
