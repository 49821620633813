import React, { useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import PropTypes from "prop-types";

import IconsPricingTableDesktop from "./IconsPricingTableDesktop";
import IconsPricingTableMobile from "./IconsPricingTableMobile";

import { useAuth } from "frontend/hooks/useAuth";
import { useCurrentUserActiveSub } from "frontend/hooks/useCurrentUserActiveSub";
import {
    IconsPricingTableButtonGroup,
    IconsPricingTableContainer
} from "./styles";

import { SHOW_DESKTOP_TABLE_MIN_SCREEN_WIDTH } from "./data";
import {
    ICON_LANDING_PAGE,
    INDIVIDUAL_SUBSCRIPTION_CHECKOUT,
    NOUN_PRO_EDU_APPLICATION,
    NOUN_PRO_EDU_LANDING_PAGE,
    NOUN_PRO_TEAM_INFO_PAGE,
    PRICING
} from "data/links";
import { TEAM_MONTHLY_PLAN, TEAM_YEARLY_PLAN } from "subscriptions/data";
import {
    YEARLY_CHECKOUT_TYPE,
    MONTHLY_CHECKOUT_TYPE
} from "frontend/store/checkout";

const IconsPricingTable = ({ bestValueHighlight, eduPricingLayout }) => {
    const [planType, setPlanType] = useState("yearly");
    const showMonthlyPlanTable = () => setPlanType("monthly");
    const showYearlyPlanTable = () => setPlanType("yearly");

    const { confirmAuth } = useAuth();
    const {
        username,
        hasSubscription,
        activeSubscription
    } = useCurrentUserActiveSub();

    const isServerSide = typeof window === "undefined";

    const individualColumnClick = () => {
        // without this line, next will throw an error give
        // that the window obj is not present on render
        if (isServerSide) return;

        const path = window.location.pathname;

        // if the user does not have a subscription, activeSubscription will be falsy
        const { cancelAtPeriodEnd = false } = activeSubscription || {};

        switch (path) {
            case PRICING:
            case ICON_LANDING_PAGE:
                if (hasSubscription && !cancelAtPeriodEnd) {
                    window.location.assign(`/${username}/settings/billing/`);
                    return;
                }

                confirmAuth({
                    onSuccess() {
                        window.location.assign(
                            INDIVIDUAL_SUBSCRIPTION_CHECKOUT.replace(
                                ":checkoutType",
                                planType === "yearly"
                                    ? YEARLY_CHECKOUT_TYPE
                                    : MONTHLY_CHECKOUT_TYPE
                            )
                        );
                    }
                });
                break;

            case NOUN_PRO_EDU_LANDING_PAGE:
                window.location.assign(NOUN_PRO_EDU_APPLICATION);
                break;
        }
    };

    const teamColumnClick = () => {
        confirmAuth({
            onSuccess() {
                window.location.assign(
                    NOUN_PRO_TEAM_INFO_PAGE.replace(
                        ":planType",

                        planType === "yearly"
                            ? TEAM_YEARLY_PLAN
                            : TEAM_MONTHLY_PLAN
                    )
                );
            }
        });
    };

    const showDesktopTable = useMediaQuery(
        `(min-width: ${SHOW_DESKTOP_TABLE_MIN_SCREEN_WIDTH})`
    );
    const table = showDesktopTable ? (
        <IconsPricingTableDesktop
            planType={planType}
            bestValueHighlight={bestValueHighlight}
            eduPricingLayout={eduPricingLayout}
            individualColumnClick={individualColumnClick}
            teamColumnClick={teamColumnClick}
        />
    ) : (
        <IconsPricingTableMobile
            planType={planType}
            bestValueHighlight={bestValueHighlight}
            eduPricingLayout={eduPricingLayout}
            individualColumnClick={individualColumnClick}
            teamColumnClick={teamColumnClick}
        />
    );

    return (
        <IconsPricingTableContainer>
            <IconsPricingTableButtonGroup
                buttons={
                    eduPricingLayout
                        ? []
                        : [
                              {
                                  label: "Yearly Icon Plans",
                                  isActive: planType === "yearly",
                                  onClick: showYearlyPlanTable
                              },
                              {
                                  label: "Monthly Icon Plans",
                                  isActive: planType === "monthly",
                                  onClick: showMonthlyPlanTable
                              }
                          ]
                }
            />

            {table}
        </IconsPricingTableContainer>
    );
};

IconsPricingTable.propTypes = {
    bestValueHighlight: PropTypes.string, // this determines which column to highlight blue
    eduPricingLayout: PropTypes.bool // used for special styling when on EDU LP
};

export default IconsPricingTable;
