import styled from "styled-components";

import Button from "core/Button";

import { convertPxToRem } from "frontend/components/helpers";

export const OptionButton = styled(Button)`
    margin-right: ${convertPxToRem({ px: 16 })};

    &:last-child {
        margin-right: 0;
    }
`;
