import React from "react";
import styled from "styled-components";

//styles
const AvatarStyled = styled.div``;

// component
const Avatar = () => {
    return (
        <AvatarStyled>
            <img
                className="prototype"
                alt="avatar"
                src={`https://mitchellgohman.com/assets/img/tnp/avatar.png`}
            />
        </AvatarStyled>
    );
};

export default Avatar;
