import styled from "styled-components";

import { bold } from "core/fontStyles";
import fonts from "core/fonts";
import { spacingWithBreakpoints } from "core/spacing";
import colors from "core/colors";

export const Div = styled.div`
    text-align: center;

    ${spacingWithBreakpoints.LGTopAndBottom}

    svg {
        display: inline-block;
        animation-name: spin;
        animation-duration: 3s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }

    p {
        display: inline-block;
        position: relative;
        top: -0.325rem;
        margin-right: 0.625rem;
        color: ${({ loadingColor }) =>
            loadingColor ? loadingColor : colors.accent.lightMode.pansy};
        ${fonts.typeScale[16]};
        ${bold};
    }

    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
`;
