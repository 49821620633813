import React from "react";

// components
import ModeratorOption from "./ModeratorOption";
import ModeratorOptionChosen from "./ModeratorOptionChosen";

const createDialogOptionFromModerator = moderator => {
    const { id } = moderator;

    // Standard Option in Drop Down
    const Option = (
        <ModeratorOption key={`${id}-modOption`} moderator={moderator} />
    );

    // Drop Down Control Display Version
    const OptionChosen = (
        <ModeratorOptionChosen
            key={`${id}-modOptionChosen`}
            moderator={moderator}
        />
    );

    return { id, label: Option, controlLabelOverride: OptionChosen };
};

// The 1st option should be Unassigned and 2nd option should be the current user, the rest of the options should be ordered alphabetically by name or by username (if name is unavailable)
export const getOptions = (moderators, currentUser) => {
    let options = [];

    // 1st option should be Unassigned
    options.push({
        id: "unassigned",
        label: "Unassigned",
        controlLabelOverride: "Unassigned"
    });

    // 2nd option should be the current user
    const chosenModerator = moderators.find(m => m.id === currentUser.id);

    if (chosenModerator) {
        options.push(createDialogOptionFromModerator(chosenModerator));
    }

    // the rest of the options should be ordered alphabetically by name or by username (if name is unavailable)
    return [
        ...options,
        ...moderators
            .filter(m => m.id !== currentUser.id) // remove current user, already accounted for above
            .sort((modA, modB) => {
                // sort by moderator.displayName
                const nameA = modA.displayName.toLowerCase();
                const nameB = modB.displayName.toLowerCase();
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
                return 0; // No sorting
            })
            .map(m => createDialogOptionFromModerator(m))
    ];
};

export const getSelectedOption = (moderator, options) => {
    // Defaulting to Unassigned
    let selectedOption = options[0];

    if (moderator && moderator.id && moderator.id !== 0) {
        const option = options.find(o => o.id === moderator.id);

        // only assigning if found.
        if (option) {
            selectedOption = option;
        }
    }

    return selectedOption;
};
