import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import colors from "core/colors";

const createHelpers = (width, height, css) => {
    // somehow sizes is ending up in markup, even if it is not a valid svg attribute
    // until we have a better solution, just render it empty, instead to '[Object object]'
    const sanitizeSizes = sizes =>
        Object.defineProperty(sizes, "toString", {
            value: () => "",
            enumerable: false
        });

    const getDimensions = (size, sizes) => {
        if (
            size &&
            typeof size.width === "number" &&
            typeof size.height === "number"
        ) {
            return size;
        }

        return size && sizes[size] ? sizes[size] : { width, height };
    };

    const getCss = (size, sizes, fillColor, fillColorRule, noStyles) => {
        if (noStyles) {
            return "";
        }

        const dimensions = getDimensions(size, sizes);
        const fillRule =
            fillColor && fillColorRule
                ? `${fillColorRule}{ fill: ${fillColor}; }`
                : "";
        return css`
            width: ${dimensions.width}px;
            height: ${dimensions.height}px;
            ${fillRule}
        `;
    };

    const propsToCss = ({ size, sizes, fillColor, fillColorRule, noStyles }) =>
        getCss(size, sizes, fillColor, fillColorRule, noStyles);

    return {
        getCss,
        getDimensions,
        propsToCss,
        sanitizeSizes
    };
};

const width = "24";
const height = "24";
const viewBox = "0 0 24 24";

const { getDimensions, getCss, propsToCss, sanitizeSizes } = createHelpers(
    width,
    height,
    css
);

const sizes = sanitizeSizes({
    xs: { width: 14, height: 14 },
    sm: { width: 16, height: 16 },
    md: { width: 20, height: 20 },
    lg: { width: 24, height: 24 },
    xl: { width: 40, height: 40 }
});

const Image = styled.svg`
    ${propsToCss}
`;

const children = (
    <Fragment>
        <path
            fill={colors.secondary.orca}
            fillRule="evenodd"
            d="M2 11.998C2 6.477 6.477 2 12 2s10 4.476 10 9.998C22 17.522 17.523 22 12 22S2 17.521 2 11.998zm11.1 4.142a.5.5 0 00-.5-.5h-1.3a.5.5 0 00-.5.5v1.31a.5.5 0 00.5.5h1.3a.5.5 0 00.5-.5v-1.31zM8.032 9.547a.467.467 0 00.477.508h1.404c.276 0 .495-.225.534-.499a2.92 2.92 0 01.065-.322c.065-.263.164-.487.295-.673a1.51 1.51 0 01.525-.46c.22-.12.476-.18.772-.18.438 0 .777.12 1.018.36.252.242.377.614.377 1.117.011.296-.043.542-.164.739-.11.197-.257.377-.443.541a9.835 9.835 0 01-.607.493 3.936 3.936 0 00-.624.591 3.408 3.408 0 00-.526.804c-.142.318-.23.712-.262 1.182 0 .276.224.502.5.502h1.33a.387.387 0 00.386-.387c.044-.328.148-.602.312-.82.175-.22.372-.411.59-.575.22-.175.45-.345.69-.509.252-.175.476-.383.673-.624.208-.24.378-.53.51-.87.142-.34.213-.771.213-1.297 0-.317-.072-.656-.214-1.018a2.764 2.764 0 00-.673-1.034c-.317-.317-.739-.58-1.264-.788C13.412 6.11 12.766 6 11.99 6a4.18 4.18 0 00-1.642.312 3.63 3.63 0 00-1.247.837c-.34.361-.608.788-.805 1.28-.13.348-.219.72-.263 1.118z"
            clipRule="evenodd"
            key="key-0"
        />
    </Fragment>
);

const defaultProps = {
    children,
    viewBox,
    fillColor: null,
    fillColorRule: "&&& path, &&& use, &&& g, &&& circle",
    sizes,
    size: null
};

Image.propTypes /* remove-proptypes */ = {
    fillColor: PropTypes.string,
    fillColorRule: PropTypes.string,
    viewBox: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    size: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            height: PropTypes.number.isRequired,
            width: PropTypes.number.isRequired
        })
    ]),
    sizes: PropTypes.shape({
        height: PropTypes.number,
        width: PropTypes.number
    })
};

export default Object.assign(Image, {
    getDimensions,
    getCss,
    defaultProps,
    displayName: "Question"
});
