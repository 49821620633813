import styled from "styled-components";

import Heading from "core/Heading";
import BodyText from "core/BodyText";
import { spacingWithBreakpoints } from "core/spacing";
import { convertPxToRem } from "frontend/components/helpers";

import CoreButton from "core/Button";

export const Container = styled.div`
    text-align: center;
    padding: 0 ${convertPxToRem({ px: 20 })};
`;

export const Title = styled(Heading).attrs({
    type: "h1",
    size: "xl",
    topSpacing: "5xl",
    alignment: "center"
})``;

export const Message = styled(BodyText).attrs({
    type: "p",
    size: "lg",
    alignment: "center"
})`
    ${spacingWithBreakpoints.XLTop}
`;

export const Buttons = styled.div`
    ${spacingWithBreakpoints.XLTop}
`;

export const Button = styled(CoreButton)`
    margin-right: ${convertPxToRem({ px: 28 })};

    &:last-child {
        margin-right: 0;
    }
`;

export const IconContainer = styled.div`
    ${spacingWithBreakpoints.XL4Top}
    ${spacingWithBreakpoints.XL5Bottom}
`;
