import React from "react";
import PropTypes from "prop-types";

// components
import Dialog from "./Dialog";

// component
export const ManageReleasesDialog = props => {
    return !props.isDialogOpen ? null : <Dialog {...props} />;
};

export default ManageReleasesDialog;

// prop-types
ManageReleasesDialog.propTypes = {
    isDialogOpen: PropTypes.bool.isRequired
};
