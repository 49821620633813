import React from "react";
import PropTypes from "prop-types";

// components
import FormInput from "core/FormInput2";
import SearchIcon from "core/ReusableIcons/Search";

// styles
import { FormSearchStyled, SubmitButtonStyled } from "./styles";

const FormSearch = ({
    labelText,
    placeholderText,
    value,
    onChange,
    onButtonClick
}) => {
    return (
        <FormSearchStyled>
            <FormInput
                labelText={labelText}
                onChange={onChange}
                placeholderText={placeholderText}
                value={value}
                hideLabel={true}
            />
            <SubmitButtonStyled
                isSubmit
                aria-label="Search"
                onClick={onButtonClick}
            >
                <SearchIcon size="md" />
            </SubmitButtonStyled>
        </FormSearchStyled>
    );
};

export default FormSearch;

// prop-types
FormSearch.propTypes = {
    labelText: PropTypes.string,
    placeholderText: PropTypes.string,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onButtonClick: PropTypes.func // optional: we may want to leverage root form onSubmit instead
};
