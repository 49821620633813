import React, { createContext, useContext } from "react";
import { connect } from "react-redux";

import {
    useCurrentUserQuery,
    useLoginMutation,
    useLogoutMutation,
    GET_CURRENT_USER_DOCUMENT
} from "../graphql";

const CurrentUserContext = createContext({}); // get user from Apollo
const LegacyUserContext = createContext(); // get user from REST -> Redux

function useCurrentUserContext() {
    const context = useContext(CurrentUserContext);
    if (!context) {
        throw new Error(`useCount must be used within a CountProvider`);
    }
    return context;
}

const useLoginStatus = () => {
    const {
        currentUser: { loading, data }
    } = useCurrentUserContext();
    const isAnonymous =
        !loading &&
        data &&
        (data.currentUser === null || data.currentUser.isAnonymous);
    return {
        isLoggedIn: !isAnonymous,
        isLoggedOut: isAnonymous
    };
};

const useCurrentUser = () => {
    const { currentUser: { data } = {} } = useCurrentUserContext();
    if (data && data.currentUser) {
        return data.currentUser;
    }
    return null;
};

const useLegacyUser = () => {
    const context = useContext(LegacyUserContext) || null;
    return context;
};

const CurrentUserContextProvider = ({ trustCache, ...props }) => {
    // All next.js pages query the user object already and inject into Apollo's cache before hydration;
    // whereas non-SSR pages may or may not have the user object yet.
    //
    // However, SPA pages ONLY set the CSRF token during Apollo query. Thus, all SPA pages
    // have to send at least 1 Apollo query in order for the auth modal to work.
    // So SSR can use cache, whereas SPA must use network.
    const currentUser = useCurrentUserQuery({
        fetchPolicy: trustCache ? "cache-first" : "cache-and-network"
    });

    const [login] = useLoginMutation({
        update: (proxy, { data: { login } }) => {
            const loggedInUser = {
                __typename: "Query",
                currentUser: null
            };

            if (login && login.ok) {
                loggedInUser.currentUser = login.user;
            }

            proxy.writeQuery({
                query: GET_CURRENT_USER_DOCUMENT,
                data: loggedInUser
            });
        }
    });
    const [logout] = useLogoutMutation({
        update: (proxy, { data: { logout } }) => {
            const loggedOutUser = {
                __typename: "Query",
                currentUser: null
            };
            proxy.writeQuery({
                query: GET_CURRENT_USER_DOCUMENT,
                data: loggedOutUser
            });
        }
    });

    const value = React.useMemo(
        () => ({
            currentUser,
            login,
            logout
        }),
        [currentUser, login, logout]
    );
    return <CurrentUserContext.Provider value={value} {...props} />;
};

const getLegacyUser = state => ({
    legacyUser: state.user
});

const LegacyUserContextProvider = connect(getLegacyUser)(
    ({ legacyUser, ...props }) => {
        return <LegacyUserContext.Provider value={legacyUser} {...props} />;
    }
);

export {
    CurrentUserContextProvider,
    LegacyUserContextProvider,
    useCurrentUserContext,
    useCurrentUser,
    useLegacyUser,
    useLoginStatus
};
