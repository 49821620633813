import styled, { css } from "styled-components";

// styles
import colors from "core/colors";
import breakpoints from "core/breakpoints";
import { convertPxToRem } from "frontend/components/helpers";
import {
    textLg,
    regular as regularFontWeight,
    medium as mediumFontWeight
} from "core/fontStyles";
import { handleHeadingSize } from "js/helpers/spacingAndFonts";
import { getLinkElementStyles } from "core/Link/styles";

import { getFlexWidthFixedGutter } from "frontend/components/core/flexBoxGridStyles.js";

// styled-components
export const UploadStyled = styled.div`
    background-color: ${({ bgColor }) => bgColor};
    overflow: hidden;

    /* give asset container 1:1 aspect ratio */
    height: 100%;
    aspect-ratio: 1/1;

    /* We can freely position center image in container since it no longer influences dimensions of container */
    position: relative;
    img {
        /* center absolutely positioned asset */
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        /* resize asset so longest side spans parent container */
        max-height: 100%;
        max-width: 100%;
    }
`;

export const GalleryUploadItemStyled = styled.div``;
export const GalleryUploadsStyled = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    ${GalleryUploadItemStyled}, &::after {
        margin-bottom: ${convertPxToRem(16)};

        ${getFlexWidthFixedGutter(2, 16)}

        @media (min-width: ${breakpoints.desktop.rem}) {
            ${getFlexWidthFixedGutter(3, 16)}
        }
    }

    /*
        Nice magic trick
        Ensures last row does not justify when there is not enough to fill row
        collapses if wrapped to next line
    */
    &::after {
        content: "";
        margin-bottom: 0px;
    }
`;
