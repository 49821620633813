import React from "react";
import styled from "styled-components";

// data
import { primaryMenu } from "data/links";

// context
import { DropDown as DropDownContext } from "core/DropDown/index.js";

// components
import Link from "core/SiteHeaderV2/components/Link";

//styles
import { convertPxToRem } from "frontend/components/helpers";
import colors from "core/colors";

const DropDownStyled = styled(DropDownContext)`
    position: absolute;
    z-index: 100;
    right: 0rem;
    background-color: ${colors.primary.snow};
    box-shadow: 0rem 0rem ${convertPxToRem(15)} rgba(0, 0, 0, 0.5);
    width: ${convertPxToRem(300)};
    padding: ${convertPxToRem(64)} ${convertPxToRem(24)} ${convertPxToRem(24)};
`;

// component
const DropDown = () => {
    const {
        mac,
        office,
        adobe,
        google,
        lingo
    } = primaryMenu.products.links.tools.links;

    return (
        <DropDownStyled>
            <Link link={mac} />
            <Link link={office} />
            <Link link={adobe} />
            <Link link={google} />
            <Link link={lingo} />
        </DropDownStyled>
    );
};

export default DropDown;
