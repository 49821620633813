/*===================================
||
|| BatchRemoveAnimationContext
||
===================================*/
import React, { useEffect } from "react";
import PropTypes from "prop-types";

// redux
import { useDispatch, useSelector } from "react-redux";
import { batchesSlice } from "frontend/components/fort/mod/photos/slices/batchesSlice";
import { animationsSlice } from "frontend/components/fort/mod/photos/slices/animationsSlice.js";
import { selectedBatchSlice } from "frontend/components/fort/mod/photos/slices/selectedBatchSlice/index.js";

// Hooks
import AnimationsContextProvider, {
    useAnimations
} from "frontend/hooks/useAnimations";
import { useBatches } from "../BatchesProvider/index";

// constants
import { ANIMATIONS } from "frontend/components/fort/mod/photos/constants";

// Provider
const RemoveBatchAnimationProvider = ({ children }) => {
    const dispatch = useDispatch();
    const { fetchQueueBatches } = useBatches();
    const selectedBatchId = useSelector(state => state.selectedBatch.id);

    const batchRemoveAnimation = {
        id: ANIMATIONS.BATCH_REMOVE,
        title: "Batch Remove Animation",
        steps: [
            {
                description: "Collapse BatchHeader",
                onStep: animationApi => {
                    const batchId = animationApi.ids[0];
                    dispatch(
                        batchesSlice.actions.updateOne({
                            id: batchId,
                            changes: {
                                isCollapsed: true
                            }
                        })
                    );

                    // move to next step
                    setTimeout(() => {
                        animationApi.animate.next();
                    }, 1500);
                }
            },
            {
                description:
                    "Aninmation Complete: Refresh paginated results and set to complete",
                onStep: animationApi => {
                    const batchId = animationApi.ids[0];
                    // refetching batches based on current page, will remove the batch as well.
                    fetchQueueBatches();

                    // if currently selctedBatch is the one we removed, set to null
                    if (selectedBatchId === batchId) {
                        dispatch(selectedBatchSlice.actions.reset());
                    }

                    animationApi.animate.complete();
                }
            }
        ]
    };

    return (
        <AnimationsContextProvider animationConfig={batchRemoveAnimation}>
            <MountingWrapper>{children}</MountingWrapper>
        </AnimationsContextProvider>
    );
};

export default RemoveBatchAnimationProvider;

// prop-types
RemoveBatchAnimationProvider.propTypes = {
    children: PropTypes.any
};

// useRemoveBatchAnimation
export const useRemoveBatchAnimation = () => {
    const animationApi = useAnimations();

    return {
        animationApi
    };
};

// MountingWrapper
const MountingWrapper = ({ children }) => {
    const { animations } = useSelector(state => state.animations);
    const dispatch = useDispatch();
    const { animationApi } = useRemoveBatchAnimation();
    const { animate } = animationApi;

    useEffect(() => {
        const thisAnimation = animations.find(
            a => a.id === ANIMATIONS.BATCH_REMOVE
        );

        if (thisAnimation) {
            const {
                id,
                payload: { batchId }
            } = thisAnimation;
            if (!batchId) {
                console.error(
                    `You need to supply a batchId for remove batch to work.`,
                    thisAnimation
                );
            } else {
                animate.start([batchId]); // move to step 1
                dispatch(animationsSlice.actions.removeAnimation(id));
            }
        }
    }, [animations]);

    return <>{children}</>;
};

// prop-types
MountingWrapper.propTypes = {
    children: PropTypes.any
};
