import * as Actions from "../context/actions";

export const sampleMessages = [
    {
        id: 1,
        infoType: "error",
        text: `<b>Error:</b> [Dev "timeout" set to 4s] I eat <b>grapes</b>. longer than some with <a href="https://apple.com/" target="_blank">SAMPLE LINK</a>`,
        timed: true,
        timeout: 4000 // 4 seconds
    },
    {
        id: 2,
        infoType: "error",
        text: `<b>Error:</b> [Dev "timeout" set to 5s] I eat <b>apples</b> longest.`,
        timed: true,
        timeout: 5000 // 5 seconds
    },
    {
        id: 3,
        infoType: "error",
        text: `<b>Error:</b> [Dev "timeout" set to 3s] I eat <b>bananas</b>.`,
        timed: true,
        timeout: 3000 // 3 seconds
    },
    {
        id: 4,
        infoType: "success",
        text: `<b>Success:</b> [Dev "timeout" set to 10s] I eat <b>apples</b>.`,
        timed: true,
        timeout: 10000 // 10 seconds
    },
    {
        id: 5,
        infoType: "info",
        text: `<b>Info:</b> [Dev "timed" is false, timeout ignored this will persist until closed.] I eat <b>oranges</b> forever. `,
        timed: false, // persists until user dismisses by clicking X
        timeout: null
    },
    {
        id: 6,
        infoType: "error",
        text: `<b>Error:</b> [Dev "timed" set to true, but "timeout" is null, "timeout" will be calculated based on number of characters times ${Actions.msPerChar} ] Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean id tincidunt erat, vitae mattis est. Donec et egestas dui, sit amet vestibulum nisl. Curabitur pretium leo eu lectus accumsan, ac fermentum ante sollicitudin. Etiam maximus est quis nisi hendrerit condimentum. Nam rutrum congue sem in lobortis. Proin sit amet nulla vel massa pellentesque varius. Aenean et diam justo. Vestibulum id sem enim.`,
        timed: true,
        timeout: null // relies on timeout calculation based on number of characters in text string
    },
    {
        id: 7,
        infoType: "warning",
        text: `<b>Warning:</b> [Dev "timed" set to true, but "timeout" is null, "timeout" will be calculated based on number of characters times ${Actions.msPerChar} ] Lorem ipsum dolor sit amet, consectetur adipiscing elit. <b>Aenean</b> id tincidunt erat, vitae mattis est. Donec et egestas dui, sit amet vestibulum nisl.`,
        timed: true,
        timeout: null // relies on timeout calculation based on number of characters in text string
    }
];
