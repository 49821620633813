export const TAGS_INIT_STATES = {
    ERRORS: [],
    TERM_ADDED: []
};

export const TAGS_PRESUBMIT_ERRORS = {
    EMPTY_TERM: "EMPTY_TERM",
    INVALID_CHAR: "INVALID_CHAR"
};

export const TAG_DISALLOWED_ERROR = "Tag not saved because term is disallowed.";
