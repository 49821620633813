const getSearchBar = currentPage => {
    let isVisible, visibilityShouldToggle;

    switch (currentPage) {
        case "home":
        case "icons":
        case "photos":
            visibilityShouldToggle = true;
            isVisible = false;
            break;
        default:
            visibilityShouldToggle = false;
            isVisible = true;
            break;
    }

    return {
        isVisible,
        config: {
            visibilityShouldToggle
        }
    };
};

export default getSearchBar;
