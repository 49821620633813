import styled from "styled-components";

import BodyText from "core/BodyText";

import { focusOutline } from "core/links";
import { rapidEasing } from "core/animation";
import { convertPxToRem } from "frontend/components/helpers";
import colors from "core/colors";

export const Text = styled(BodyText).attrs({
    type: "span"
})`
    cursor: pointer;
    font-style: ${({ isPlaceholder }) => (isPlaceholder ? "italic" : "normal")};
`;

export const Button = styled.button`
    cursor: pointer;
    background: transparent;
    margin: 0 0 0 ${convertPxToRem({ px: 8 })};
    padding: 0;
    border: 0;
    font-size: 0;
    line-height: 0;

    svg {
        transition: all ${rapidEasing};

        path {
            fill: ${colors.accent.lightMode.persian};
        }
    }

    &:hover {
        svg path {
            fill: ${colors.secondary.oolong};
        }
    }

    // only show focus on keyboard not with mouse:
    &:focus:not(.focus-visible) {
        outline: none;
        box-shadow: none;
        border: none;
    }

    &:focus {
        ${focusOutline}
    }
`;
