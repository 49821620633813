import React from "react";
import colors from "core/colors";
import styled from "styled-components";

import CrownIcon from "core/ReusableIcons/Crown";

const Badge = styled.div`
    display: flex;
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    gap: 0.5rem;
    background-color: rgb(0 0 0 / 0.8);
    color: ${colors.secondary.sunshine};
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    pointer-events: none;
    z-index: 1;
`;

const PremiumBadge = () => {
    return (
        <Badge>
            <CrownIcon size="sm" />
            <div>Premium</div>
        </Badge>
    );
};

export default PremiumBadge;
