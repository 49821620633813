import React from "react";
import PropTypes from "prop-types";

import Tooltip from "core/Tooltip";
import QuestionIcon from "core/ReusableIcons/Question";
import PhotoUploadTypeSelectForm from "./PhotoUploadTypeSelectForm";

import breakpoints from "core/breakpoints";
import MobileError from "../Status/Mobile";

import { MastheadContainer, MastheadHeadingContainer } from "./styles";
import { MastheadHeading } from "../PhotoUploadCore/styles";
import { useViewport } from "js/hooks/useViewport";

const TooltipText = () => (
    <>
        “Photo shoots” contain images with a common subject, aesthetic,
        location, etc.
        <br />
        <br />
        “Individual photos” can include a range of subjects and styles.
    </>
);

const PhotoUploadTypeSelectMasthead = ({
    setCurrentUploadType,
    currentUploadType,
    handleSubmit,
    isLoading
}) => {
    const { browserWidth } = useViewport();
    const isMobile = browserWidth < breakpoints.tablet.px;

    if (isMobile) return <MobileError />;
    return (
        <MastheadContainer>
            <MastheadHeadingContainer>
                <MastheadHeading>Select Upload Type</MastheadHeading>
                <Tooltip
                    message={<TooltipText />}
                    clickToOpen
                    // since there is no focussable element in the tooltip
                    // it should be non-interactive - otherwise FocusTrap throws an error
                    interactive={false}
                >
                    <QuestionIcon size={{ height: 24, width: 24 }} />
                </Tooltip>
            </MastheadHeadingContainer>
            <PhotoUploadTypeSelectForm
                setCurrentUploadType={setCurrentUploadType}
                currentUploadType={currentUploadType}
                handleSubmit={handleSubmit}
                isLoading={isLoading}
            />
        </MastheadContainer>
    );
};

PhotoUploadTypeSelectMasthead.propTypes = {
    setCurrentUploadType: PropTypes.func.isRequired,
    currentUploadType: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired
};

export default PhotoUploadTypeSelectMasthead;
