import gql from "graphql-tag";

export const GET_TAG_SUGGESTIONS = gql`
    query tagAutocompleteSuggestions($name: String!) {
        tagAutocompleteSuggestions(name: $name) {
            items
            errors
            __typename
        }
        __typename
    }
`;

export const GET_LOCATION_SUGGESTIONS = gql`
    query suggestLocationAutocomplete($query: String!) {
        suggestLocationAutocomplete(query: $query) {
            formattedLocation
            placeId
            __typename
        }
        __typename
    }
`;

// fetches new photo upload image URLs (thumbnail and full size), since they can expire
export const PHOTO_UPLOAD_URLS = gql`
    query photoUploadDetails($photoUploadIds: [ID!]!) {
        photoUploadDetails(photoUploadIds: $photoUploadIds) {
            id
            status
            thumbnail
            uploadedImageUrl
            __typename
        }
        __typename
    }
`;
