// styles
import colors from "core/colors";
import { convertPxToRem } from "frontend/components/helpers";
import { dialogDropShadow } from "core/boxShadows.js";

// constants
const CONTROL_HEIGHT = 48;

const getDialogProps = (dialogAlignment = "left") => {
    let alignment = {
        left: "auto",
        right: "auto",
        transform: "none"
    };

    switch (dialogAlignment) {
        case "center":
            alignment = {
                ...alignment,
                left: "50%",
                transform: "translate(-50%, 0)"
            };
            break;
        case "right":
            alignment = {
                ...alignment,
                right: "0px"
            };
            break;
        default:
            alignment = {
                ...alignment,
                left: "0px"
            };
            break;
    }

    return {
        position: "absolute",
        zIndex: 1,
        top: convertPxToRem(5), // cushion
        ...alignment,
        width: convertPxToRem(300),
        backgroundColor: colors.primary.snow,
        boxShadow: dialogDropShadow,
        border: `1px solid ${colors.primary.diamond}`
    };
};

export const defaultTheme = ({ dialogAlignment = "left" } = {}) => {
    return {
        control: {
            width: "100%",
            padding: {
                left: convertPxToRem(12),
                right: convertPxToRem(12)
            },
            gap: convertPxToRem(15),
            height: convertPxToRem(CONTROL_HEIGHT),
            backgroundColor: colors.primary.snow,
            border: `3px solid ${colors.primary.diamond}`,
            fontSize: convertPxToRem(15),
            hover: {
                backgroundColor: colors.primary.diamond
            }
        },
        dialog: getDialogProps(dialogAlignment),
        dialogOption: {
            backgroundColor: "transparent",
            textAlign: "left",
            font: {
                size: convertPxToRem(16),
                lineHeight: convertPxToRem(48)
            },
            hover: {
                backgroundColor: colors.primary.moon
            }
        },
        icon: {
            top: 0,
            size: 16
        }
    };
};
