import React from "react";

import BodyText from "core/BodyText";
import ArrowLongRight from "core/ReusableIcons/ArrowLongRight";

import { IconsDashboardLinkContainer } from "./styles";
import colors from "core/colors";

import { useCurrentUser } from "js/contexts/CurrentUserContext";

const IconsDashboardLink = () => {
    const user = useCurrentUser();

    const isIconCreator = user && user.isIconCreator;

    if (!isIconCreator) return null;

    return (
        <IconsDashboardLinkContainer href="/dashboard/">
            <BodyText size="sm" copyColor={colors.accent.lightMode.persian}>
                Go to icons dashboard
            </BodyText>
            <ArrowLongRight size="md" />
        </IconsDashboardLinkContainer>
    );
};

export default IconsDashboardLink;
