import React from "react";
import PropTypes from "prop-types";

import BodyText from "core/BodyText";
import CaretRight from "core/ReusableIcons/CaretRight";

import { MenuItemStyled } from "./styles";

const MenuItem = ({ children, href = "" }) => (
    <MenuItemStyled to={href}>
        <BodyText bold>{children}</BodyText>
        <CaretRight />
    </MenuItemStyled>
);

MenuItem.propTypes = {
    href: PropTypes.string.isRequired
};

export default MenuItem;
