import React from "react";
import PropTypes from "prop-types";

// styles
import { ContributorStyled } from "./styles";

// components
import Template from "./Template.js";

const Contributor = ({ avatarUrl, name, isFeatured }) => {
    return (
        <Template title="Contributor" width={200}>
            <ContributorStyled>
                <img src={avatarUrl} alt={`${name} avatarUrl`} />
                <span data-testid="creator-name">
                    {isFeatured ? "*" : ""}
                    {name}
                </span>
            </ContributorStyled>
        </Template>
    );
};

export default Contributor;

// prop-types
Contributor.propTypes = {
    avatarUrl: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
};
