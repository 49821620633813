import React from "react";

import Link from "core/Link";

import { FeedbackContent, FeedbackIcon, FeedbackText } from "../styles";
import Mailbox from "core/IllustrativeIcons/Mailbox";

import colors from "core/colors";

const ForgotPasswordSuccess = () => (
    <FeedbackContent>
        <FeedbackIcon>
            <Mailbox fillColor={colors.accent.lightMode.pansy} />
        </FeedbackIcon>
        <FeedbackText>
            If your email is registered, you will receive an email to reset your
            password.
        </FeedbackText>
        <Link
            href="https://thenounproject.zendesk.com/hc/en-us"
            target="_blank"
            size="lg"
        >
            Need help?
        </Link>
    </FeedbackContent>
);

export default ForgotPasswordSuccess;
