import React from "react";

// context
import { useSiteHeader } from "core/SiteHeaderV2/context/useSiteHeader.js";

// components
import SearchBar from "core/SiteHeaderV2/components/SearchBar";

// styles
import OverlayStyled from "./styles";

// Holds the Search Bar
export const Overlay = () => {
    const { isDarkMode, animation, device } = useSiteHeader();

    return (
        <OverlayStyled $animation={animation}>
            <SearchBar device={device} isDarkMode={isDarkMode} />
        </OverlayStyled>
    );
};

export default Overlay;
