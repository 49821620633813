import React from "react";
import { EditorBackgroundShapes } from "../../reducers/editor";

const scalingFactor = 0.05;
const shapes = {
    [EditorBackgroundShapes.CIRCLE]: props => {
        let { color } = props;
        return <circle stroke="none" fill={color} r="48%" cx="50%" cy="50%" />;
    },
    [EditorBackgroundShapes.RING]: props => {
        let { color, exportSize } = props;
        let strokeWidth = exportSize * scalingFactor;

        return (
            <svg viewBox="0 0 99.999997 99.999997">
                <g transform="translate(-149.6453,-589.21878)">
                    <path
                        d="m 199.64552,591.21879 a 48.000099,48.000099 0 0 0 -48.00022,48.00022 48.000099,48.000099 0 0 0 48.00022,47.99977 48.000099,48.000099 0 0 0 47.99977,-47.99977 48.000099,48.000099 0 0 0 -47.99977,-48.00022 z m 0,5.42088 a 42.107528,42.578935 0 0 1 42.10718,42.57934 42.107528,42.578935 0 0 1 -42.10718,42.57887 42.107528,42.578935 0 0 1 -42.10763,-42.57887 42.107528,42.578935 0 0 1 42.10763,-42.57934 z"
                        fill={color}
                    />
                </g>
            </svg>
        );
    },
    [EditorBackgroundShapes.SQUARE]: props => {
        let { color } = props;
        return <rect width="100%" height="100%" fill={color} />;
    },
    [EditorBackgroundShapes.ROUNDED_RECT]: props => {
        let { color, exportSize } = props;
        let radius = exportSize * scalingFactor;
        return (
            <rect
                width="100%"
                height="100%"
                fill={color}
                rx={radius}
                ry={radius}
            />
        );
    },
    [EditorBackgroundShapes.NONE]: props => {
        if (props.checkered) {
            return (
                <line
                    x1={0}
                    y1="0"
                    x2="100%"
                    y2="100%"
                    stroke="#FF0000"
                    strokeWidth="3"
                />
            );
        } else {
            return null;
        }
    }
};

export default shapes;
