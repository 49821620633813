import React from "react";

import PhotoUploadLayout from "../PhotoUploadCore/PhotoUploadLayout";
import PhotoUploadIndividualMasthead from "./PhotoUploadIndividualMasthead";
import PhotoUploadProviders from "../PhotoUploadCore/PhotoUploadProviders";
import RedirectOnInvalidReferrer from "../PhotoUploadCore/RedirectOnValidReferrer";

const PhotoUploadIndividualUnwrapped = () => {
    return (
        <>
            <RedirectOnInvalidReferrer />
            <PhotoUploadLayout masthead={PhotoUploadIndividualMasthead} />
        </>
    );
};

const PhotoUploadIndividual = props => (
    <PhotoUploadProviders>
        <PhotoUploadIndividualUnwrapped {...props} />
    </PhotoUploadProviders>
);

export default PhotoUploadIndividual;
