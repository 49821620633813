import styled from "styled-components";
import InfiniteScroll from "react-infinite-scroll-component";

import colors from "core/colors";
import { easingSeconds } from "core/animation";

export const PhotoInfiniteScroll = styled(InfiniteScroll)`
    margin-right: 0.75rem;
    overflow: hidden !important; /* Note that we have to use important because InfiniteScroll library is setting this property directly on the component */
`;

// Note: using attrs is more performant on elements that change often
export const PhotoGrid = styled.div.attrs(({ height }) => ({
    style: {
        height
    }
}))`
    display: flex;
    flex-wrap: wrap;
    position: relative;
    min-height: 367px;
`;

export const PhotoContainer = styled.div.attrs(
    ({ width, height, top, left, backgroundImage }) => ({
        style: {
            width,
            height,
            top,
            left,
            backgroundImage
        }
    })
)`
    position: absolute;
    background-color: ${colors.primary.scheifer};
    background-size: cover;
    transition: opacity ${easingSeconds} ease 0s;
    &:hover {
        opacity: 0.6;
    }
`;

export const PhotoLink = styled.a.attrs(({ width, height }) => ({
    style: {
        width,
        height
    }
}))`
    z-index: 1;
    display: block;
`;

export const AdContainerParent = styled.div.attrs(({ top }) => ({
    style: {
        top
    }
}))`
    position: absolute;
    margin: auto;
    left: 50%;
    transform: translate(-50%);
`;
