import React, { createContext, useReducer, useMemo, useContext } from "react";
import PropTypes from "prop-types";
import reducer from "./context/reducer";
import * as Actions from "./context/actions";

// Components
export { Shadow } from "./components/Shadow";
export { Light } from "./components/Light";

/*---------------------------
| Context
---------------------------*/
const Modal2Context = createContext();

/*---------------------------
| Modal2 Context Provider
---------------------------*/
const Modal2Provider = props => {
    const { children, isVisible = false, onClose } = props;

    const [state, dispatch] = useReducer(
        reducer,
        getDefaultState({
            isVisible
        })
    );

    // Fix the object passed as the value prop to the Context provider changes every render
    const value = useMemo(() => ({ state, dispatch, onClose }), [
        state,
        dispatch,
        onClose
    ]);

    return (
        <Modal2Context.Provider value={value}>
            {children}
        </Modal2Context.Provider>
    );
};

export default Modal2Provider;

Modal2Provider.propTypes = {
    children: PropTypes.any,
    isVisible: PropTypes.bool,
    onClose: PropTypes.func
};

/*---------------------------
| getDefaultState
---------------------------*/
const getDefaultState = props => {
    return {
        ...props
    };
};

/*---------------------------
| useModal2 :: an unwrapped Modal2 specific hook
---------------------------*/
export const useModal2 = () => {
    const { state, dispatch, onClose } = useContext(Modal2Context);

    const showModal = () => {
        dispatch(Actions.isVisibleUpdate(true));
    };
    const hideModal = () => {
        dispatch(Actions.isVisibleUpdate(false));
        !!onClose && onClose();
    };

    return {
        ...state,
        dispatch,
        showModal,
        hideModal,
        Actions
    };
};
