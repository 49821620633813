import styled, { css } from "styled-components";

import { HeroContainer } from "core/Hero/styles";

import { boxShadow } from "core/SiteHeader/constants";
import { easingSeconds } from "core/animation";
import { CustomFocusStyle } from "core/Typography";
import { regular as regularFontWeight } from "core/fontStyles";
import breakpoints from "core/breakpoints";
import colors from "core/colors";
import CaretDown from "core/ReusableIcons/CaretDown";
import { StyledSiteHeader } from "core/SiteHeader/styles";

const DEVICE_TAP_COLOR = "rgba(2,37,207, .10)";

const LINK_STYLES = css`
    cursor: pointer;
    border-radius: 0;
    padding: 0;
    border: 0;
    background: ${colors.primary.snow};
    -webkit-tap-highlight-color: ${DEVICE_TAP_COLOR};
    transition: all ${easingSeconds};

    &:hover {
        background: ${colors.primary.moon};
    }

    &:focus {
        border: 1px solid ${colors.functional.secondary.parrot};
        -webkit-tap-highlight-color: ${DEVICE_TAP_COLOR};
        outline: none;
    }
`;

const HERO_TOGGLEBUTTON_WIDTH = 9.125; // rem units

const BUTTON_LAYOUT = css`
    padding-left: 1rem; /* 16px */
    padding-right: 1rem; /* 16px */
    display: inline-flex;
    justify-content: center;
    align-items: center;
`;

export const Dropdown = styled.nav`
    width: 2.625rem; /* 42px */
    position: absolute;
    top: 0.1875rem; /* 3px */
    left: 0.1875rem; /* 3px */

    /* hides dropdown until button is clicked &
    hides selected element in the list so we only show available items to choose: */
    .selected {
        display: none;
    }

    svg.arrow-down {
        /* Mobile only: */
        @media (max-width: ${breakpoints.beforeTablet.rem}) {
            position: relative;
            top: -2px;
            margin-top: 0;
        }
        margin-top: 0.25rem; /* 4px */
        width: 8px;
        height: 8px;
    }

    /* Tablet and desktop nav container styles: */
    @media (min-width: ${breakpoints.tablet.rem}) {
        width: ${HERO_TOGGLEBUTTON_WIDTH}rem;
    }
`;

export const ToggleButton = styled.button`
    ${LINK_STYLES}
    width: 100%;
    display: inline-block;
    border-right: 1px solid ${colors.primary.scheifer};

    &:active,
    &:focus {
        border: 0;
    }
    ${CustomFocusStyle}

    /* targets the search type icon svg: */
    svg:first-of-type {
        margin-right: 0.3125rem; /* 5px */

        /* Mobile-only: */
        @media (max-width: ${breakpoints.beforeTablet.rem}) {
            width: 1.25rem; /* 20px */
            height: auto;
        }
    }

    ${CaretDown} {

        /* Mobile-only: */
        @media (max-width: ${breakpoints.beforeTablet.rem}) {
            position: relative;
            top: -0.1875rem; /* -3px */
        }

        /* Tablet & Desktop: */
         @media (min-width: ${breakpoints.tablet.rem}) {
            margin-top: 0.1875rem; /* 3px */
        }
    }

    ${HeroContainer} & {
        height: 2.125rem; /* 34px since hero search input is larger*/
    }

    ${StyledSiteHeader} & {
        height: 1.938rem; /* 31px since header search input is smaller */
    }

    /* Tablet and desktop button styles: */
    @media (min-width: ${breakpoints.tablet.rem}) {
        ${BUTTON_LAYOUT}

        /* when search appears in home page hero section: */
        ${HeroContainer} & {
            height: 3.875rem; /* 62px */
        }

        /* when search appears in header: */
        ${StyledSiteHeader} & {
            height: 2.25rem; /* 36px - 42px input height minus 3px for top border and minus 3px for bottom border */
        }
    }
`;

export const DropdownContents = styled.ul`
    list-style-type: none;
    margin: 0 auto;
    padding: 0;
    position: absolute;
    width: 100%;
    margin-top: 0.1875rem; /* 3px - to position the menu below the input border */
    margin-left: -0.1875rem; /* 3px - to position the menu up against the left border of the input */
    box-shadow: ${boxShadow};
`;

export const ButtonText = styled.span`
    ${regularFontWeight}
    font-size: 1rem; /* 16px */
    color: ${colors.secondary.oolong};
    margin-right: 0.5rem; /* 8px */
    text-align: left;
    width: calc(100% - 1rem - 0.5rem - 0.5rem - 0.5rem);
    /* ^^ 100% minus svg width (16px) minus svg margin (8px) minus text margin width (8px) minus arrow-down svg width (8px) */

    /* Hide text in button on mobile devices: */
    @media (max-width: ${breakpoints.beforeTablet.rem}) {
        display: none;
    }
`;

export const DropdownOption = styled.li`
    ${LINK_STYLES}
    width: 2.813rem; /* 45px */
    height: 2.313rem; /* 37px */
    display: inline-flex;
    align-content: center;
    align-items: center;
    justify-content: center;

    /* Tablet and desktop LI option styles: */
    @media (min-width: ${breakpoints.tablet.rem}) {
        ${BUTTON_LAYOUT}
        /* adds 3px to HERO_TOGGLEBUTTON_WIDTH
        convert 3px to rem by dividing 3 by 16 */
        width: ${HERO_TOGGLEBUTTON_WIDTH + 3 / 16}rem;

        ${ButtonText} {
            margin-left: 0.5rem; /* 8px */
        }
        /* when search appears in home page hero section: */
        ${HeroContainer} & {
            height: 4.063rem; /* 65px */
            margin-left: 0;
        }

        /* when search appears in header: */
        ${StyledSiteHeader} & {
            height: 2.625rem; /* 42px */
            margin-left: 1px;
        }
    }
`;
