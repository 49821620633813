import queryString from "query-string";

import {
    CPRO_TEAM_CHECKOUT_TYPE,
    TEAM_YEARLY_CHECKOUT_TYPE,
    TEAM_MONTHLY_CHECKOUT_TYPE
} from "frontend/store/checkout";

/* -------------------------------------------------------------------------- */
/*                              INDIVIDUAL PATHS                              */
/* -------------------------------------------------------------------------- */

export const homePath = {
    url: "/",
    alt: "Noun Project home"
};

export const eduPath = "/for-edu/";

export const eduApplyPath = "/for-edu/apply/submit/";

export const teamPath = "/team/";

export const proTeamSignupPath = "/accounts/signup/team/";

export const apiGetStartedPath = "/developers/apps/";

export const contactCustomerSupportLink = "mailto:info@thenounproject.com";

export const customerSupportLink = {
    name: "Customer Support",
    href: "https://thenounproject.zendesk.com/",
    external: true
};

export const PHOTO_PATH = "/photos/";

export const photosLandingPath = "/photos/";

export const lingoMarketingPagePath = "/lingo/";

export const accountUpgradePath = "/accounts/pricing/";

export const apiMarketingPagePath = "/developers/";

export const appsAndPluginsPath = "/apps/";

export const proSignupPath = "/accounts/signup/pro/";

export const aboutPath = "/about/";

export const legalPath = "/legal/";

export const LICENSE_PATH = "/legal/terms-of-use/#icon-licenses";

export const PHOTO_LICENSE_PATH = "/legal/terms-of-use/#photo-licenses";

export const CAREERS_PATH = "/jobs/";

export const AFFILIATES_PATH = "/affiliates/";

export const FOR_MAC_PATH = "/for-mac/";

export const FOR_ADOBE_PATH = "/for-adobe/";

export const FOR_GOOGLE_PATH = "/for-google/";

export const FOR_OFFICE_PATH = "/for-office/";

export const NP_STORE_LINK = "https://store.thenounproject.com/";

export const API_LANDING_PAGE = "/api/";

export const PHOTOGRAPHER_LIST_LANDING_PAGE = "/thelist/photographers-2025/";
export const PHOTOGRAPHER_LIST_INTERVIEW_PAGE = `${PHOTOGRAPHER_LIST_LANDING_PAGE}:photographer-slug/`;
export const PHOTOGRAPHER_LIST_ABOUT_PAGE = `${PHOTOGRAPHER_LIST_LANDING_PAGE}about/`;

export const prepaidBalancePath = ({ username }) =>
    `/${username}/settings/prepaid-balance/`;

// /upload/ banner link:
export const UPLOADS_SUBMISSION_POLICY =
    "https://medium.com/@lindsaystuart12/c4970c98b050";

export const PRICING = "/pricing/";
export const AFFILIATES_PAGE = "/affiliates/";

// Photos Related Routes
export const SEARCH = `/search/photos/`;
export const BECOME_PHOTOGRAPHER_LANDING_PAGE = "/call-to-photographers/";
// :purchaseType should be lowercase
export const PHOTO_CHECKOUT_ALC = "/checkout/photo/payment/:photoId/";
export const PHOTO_CHECKOUT = "/checkout/photo/:checkoutType/:photoId/";

export const PHOTO_DETAIL = "/photo/:photoSlugWithId/";

export const BROWSE_BY_TAG = "/browse/term/photos/:tag/";
// BROWSE_BY_SHOOT_ID :shootInfo consist of {shoot-title}-{shoot-id}
export const BROWSE_BY_SHOOT_ID = "/browse/photo-shoot/:shootInfo/";
export const BROWSE_BY_CREATOR_USERNAME = "/browse/creator/:username/photos/";
export const SEARCH_CREATOR_PHOTOS = "/browse/creator/:username/photos/search/";
export const BROWSE_SIMILAR_BY_PHOTO_ID = "/browse/similar/photos/:photoId/";
// BROWSE_BY_CAMPAIGN :shootInfo consist of {campaign-title}-{campaign-id}
export const BROWSE_BY_CAMPAIGN = "/browse/campaign/:campaignDetail/";

export const SOCIAL_SIGNUP = "/accounts/3rdparty/signup/";
export const SOCIAL_LOGIN = "/accounts/social/login/clear_referrer/";
export const SOCIAL_LOGIN_CANCELLED = "/accounts/3rdparty/login/cancelled/";
export const SOCIAL_LOGIN_TRY_AGAIN = "/accounts/social/login/try-again/";
export const LOGIN = "/accounts/login/";
export const FORGOT_PASSWORD = "/accounts/password/reset/";
export const RESET_PASSWORD = "/accounts/password/reset/from-email/:resetKey/";
export const SIGNUP = "/accounts/signup/";
export const SIGNUP_TYPE = "/accounts/signup/:signupType/";
export const LOGOUT = "/accounts/logout/";

// Icons (2020) Related Routes
export const ICON_LANDING_PAGE = "/icons/";
export const ICON_DETAIL_PAGE = "/icon/:slugWithId/";
export const ICON_CHECKOUT = "/checkout/icon/:checkoutType/:iconId/";
export const ICON_CHECKOUT_ALC = ICON_CHECKOUT.replace(
    ":checkoutType",
    "payment"
);

export const ICON_SET_CHECKOUT =
    "/checkout/collection-icon/cpro-yearly/:iconSetId/";

export const BROWSE_ICONS_SIMILAR = "/browse/icons/similar/:slugWithId/";
export const BROWSE_ICONS_FEATURED = "/browse/icons/recent/";
export const BROWSE_ICONS_BY_CREATOR = "/browse/creator/:username/icons/";

export const BROWSE_ICON_COLLECTIONS_BY_CREATOR =
    "/browse/creator/:username/icon-collections/";
export const BROWSE_ICONS_IN_COLLECTION =
    "/browse/collection-icon/:collectionSlugWithId/";

export const BROWSE_CONTENT = "/browse/:type/term/:term/";
export const BROWSE_ICONS_BY_TAG = BROWSE_CONTENT.replace(":type", "icons");
export const BROWSE_PHOTOS_BY_TAG = BROWSE_CONTENT.replace(":type", "photos");

export const SEARCH_CONTENT = "/search/:searchType/";
export const SEARCH_ICONS = SEARCH_CONTENT.replace(":searchType", "icons");
export const SEARCH_ICON_SETS = SEARCH_CONTENT.replace(
    ":searchType",
    "icon-collections"
);

export const SEARCH_ICONS_CREATOR = "/browse/creator/:username/search/";
/* -------------------------------------------------------------------------- */
/*                    Subscription LP & Checkout Flows                        */
/* -------------------------------------------------------------------------- */

// Individual subscription
export const INDIVIDUAL_SUBSCRIPTION_CHECKOUT =
    "/checkout/subscription/individual/payment/:checkoutType/";
export const NOUN_PRO_CONFIRMATION_PAGE =
    "/checkout/subscription/individual/confirmation/";
export const CREATOR_PRO_CONFIRMATION_PAGE =
    "/checkout/subscription/creator-pro/confirmation/";
export const CREATOR_PRO_TEAM_CONFIRMATION_PAGE =
    "/checkout/subscription/cpro-team/confirmation/:teamUsername/";

// Icon Pro Team
export const getTeamSubscriptionCheckoutPath = ({
    checkoutType,
    teamName,
    numSeats,
    teamId,
    assetDetailPagePath
}) => {
    const validPlanTypes = [
        CPRO_TEAM_CHECKOUT_TYPE,
        TEAM_YEARLY_CHECKOUT_TYPE,
        TEAM_MONTHLY_CHECKOUT_TYPE
    ];

    if (!validPlanTypes.includes(checkoutType)) {
        throw new Error(
            "please pass valid checkout type to getTeamSubscriptionCheckoutPath"
        );
    }

    let queryParamObj = {};

    if (!!teamId) {
        queryParamObj = {
            ...queryParamObj,
            teamId
        };
    }

    if (!!assetDetailPagePath) {
        queryParamObj = {
            ...queryParamObj,
            assetDetailPagePath
        };
    }

    if (typeof window === "object" && window.location.search) {
        const { coupon } = queryString.parse(window.location.search) || {};
        if (coupon) {
            queryParamObj = {
                ...queryParamObj,
                coupon
            };
        }
    }

    const queryParams = !!queryString.stringify(queryParamObj)
        ? `?${queryString.stringify(queryParamObj)}`
        : "";

    return `/checkout/subscription/team/payment/${checkoutType}/${encodeURIComponent(
        teamName
    )}/${numSeats}/${queryParams}`;
};
export const NOUN_PRO_TEAM_CHANGE_SEATS_CHECKOUT =
    "/checkout/subscription/team/change-seats/";
export const NOUN_PRO_TEAM_CONFIRMATION_PAGE =
    "/checkout/subscription/icon-pro-team/confirmation/";
export const NOUN_PRO_TEAM_INFO_PAGE =
    "/checkout/subscription/team/name/:planType/";
export const NOUN_PRO_TEAM_SETTINGS_REDIRECT = "/settings/seats/";
export const NOUN_PRO_TEAM_SEAT_SETTINGS = "/:teamUser/settings/seats/";

// Icon Pro for Education
export const NOUN_PRO_EDU_LANDING_PAGE =
    "/pricing/icon-subscription/nounpro-edu/";
export const NOUN_PRO_EDU_CHECKOUT = "/checkout/subscription/edu/payment/";
export const NOUN_PRO_EDU_CONFIRMATION_PAGE =
    "/checkout/subscription/edu/confirmation/";
export const NOUN_PRO_EDU_APPLICATION =
    "/checkout/subscription/edu/apply/form/";
export const EDU_APPLICATION_CONFIRMATION =
    "/checkout/subscription/edu/apply/confirmation/:email/";

// API routes
export const ICON_EDITOR_PREVIEW_ENDPOINT = "/api/private/icons/:iconId/edit/";

export const iconEditorTokenEndpoint = "/api/private/icons/get-key/";

// Billing routes
export const BILLING_PAGE = "/:username/settings/billing/";
export const INVOICES_PAGE = "/:username/settings/invoices/";
export const DOWNGRADE_PATH = "/:username/settings/downgrade/";

/* -------------------------------------------------------------------------- */
/*                               KITS FAVORITES                               */
/* -------------------------------------------------------------------------- */

export const KITS_FAVS = "/kits/favorites";
export const KITS_FAVS_ICON_SETS = `${KITS_FAVS}/icon-sets`;
export const KITS_FAVS_PHOTOS = `${KITS_FAVS}/photos`;

/* -------------------------------------------------------------------------- */
/*                                  DASHBOARD                                 */
/* -------------------------------------------------------------------------- */

/* --------------------------- DASHBOARD - PHOTOS --------------------------- */
export const CREATOR_DASHBOARD_PHOTOS_REDIRECT = `/account/:username/photo-dashboard/`;
export const CREATOR_DASHBOARD_PHOTOS = `${CREATOR_DASHBOARD_PHOTOS_REDIRECT}:pageContent/`;
export const CREATOR_DASHBOARD_PHOTOS_ROYALTIES = CREATOR_DASHBOARD_PHOTOS.replace(
    ":pageContent",
    "photo-royalties"
);
export const CREATOR_DASHBOARD_PHOTOS_PENDING = CREATOR_DASHBOARD_PHOTOS.replace(
    ":pageContent",
    "photos-pending"
);
export const CREATOR_DASHBOARD_PHOTOS_DENIED = CREATOR_DASHBOARD_PHOTOS.replace(
    ":pageContent",
    "photos-denied"
);
export const CREATOR_DASHBOARD_PHOTOS_DEFAULT = CREATOR_DASHBOARD_PHOTOS_ROYALTIES;

/* -------------------------------------------------------------------------- */
/*                               CREATOR PROFILE                              */
/* -------------------------------------------------------------------------- */
export const PUBLIC_PROFILE = "/creator/:username/";
export const PROFILE_KITS = `/:username/kits/`;
export const PROFILE_KITS_AUTH_REDIRECT = `/kits/icons/`;
export const TEAM_PROFILE = "/:username/";

/* -------------------------------------------------------------------------- */
/*                                CREATOR TOOLS                               */
/* -------------------------------------------------------------------------- */

/* --------------------------- TOOLS - PHOTOS --------------------------- */
export const PHOTO_UPLOAD = "/creator-tools/contribute/upload-photos/";
export const PHOTO_UPLOAD_CONFIRM = `${PHOTO_UPLOAD}confirmation/`;
export const PHOTO_UPLOAD_MAX_QUEUE = `${PHOTO_UPLOAD}hld-q/`;
export const PHOTO_UPLOAD_MAX_TIME = `${PHOTO_UPLOAD}hld-time/`;
export const PHOTO_UPLOAD_BAD_ACCOUNT = `${PHOTO_UPLOAD}incomplete-acct/`;
export const PHOTO_UPLOAD_TYPE_SELECT = `${PHOTO_UPLOAD}type-select/`;
export const PHOTO_UPLOAD_INDIVIDUAL = `${PHOTO_UPLOAD}individual/`;
export const PHOTO_UPLOAD_PHOTO_SHOOT = `${PHOTO_UPLOAD}photo-shoot/`;

/* -------------------------------------------------------------------------- */
/*                                    MENUS                                   */
/* -------------------------------------------------------------------------- */

export const ICON_DASHBOARD = "/dashboard/";
export const ICON_ACTIVITY = "/:username/activity/";
export const ICON_ROYALTIES = "/:username/royalties/";
export const ICON_UPLOADS = "/upload/";
export const PHOTO_UPLOADS = PHOTO_UPLOAD;
export const ICON_CREATOR_HANDBOOK =
    "https://brand.thenounproject.com/s/Rzneqx/?v=0";
export const PHOTO_CREATOR_HANDBOOK =
    "https://brand.thenounproject.com/k/Noun-Project-Photographer-Guide-P9J8Wn";

export const USER_SETTINGS = "/:username/settings/basics/";
export const USER_EMAIL_SETTING = "/accounts/email/";

export const authLinks = {
    login: {
        href: LOGIN,
        text: "Log In"
    },
    signup: {
        href: SIGNUP,
        text: "Join"
    },
    logout: {
        href: LOGOUT,
        text: "Log Out"
    }
};

export const adminMenu = [
    {
        name: "Icon Moderation",
        href: "/fort/modparty/"
    },
    {
        name: "Photo Moderation",
        href: "/private/mod/photos"
    },
    {
        name: "Members",
        href: "/fort/members/"
    },
    {
        name: "Icons",
        href: "/fort/icon/"
    },
    {
        name: "Icon Sets",
        href: "/fort/collections/"
    },
    {
        name: "Photos",
        href: "/fort/admin/photos/photo/"
    },
    {
        name: "Photo shoots",
        href: "/fort/admin/photos/photoshoot/"
    }
];

export const myAccountMenu = [
    {
        name: "Settings",
        href: ({ username }) => USER_SETTINGS.replace(":username", username)
    },
    {
        name: "Billing Info",
        href: ({ username }) => `/${username}/settings/billing/`
    },
    {
        name: "Invoices",
        href: ({ username }) => `/${username}/settings/invoices/`
    },
    {
        name: "Prepaid Balance",
        href: prepaidBalancePath
    }
];

export const myStuffMenu = [
    {
        name: "Profile",
        href: ({ username }) => PUBLIC_PROFILE.replace(":username", username)
    },
    {
        name: "Download History",
        href: ({ username }) => `/${username}/settings/icon-history/`
    },
    {
        name: "Saved Icons",
        href: ({ username }) => PROFILE_KITS.replace(":username", username)
    }
];

export const myStuffMenuFav = [
    {
        name: "Profile",
        href: ({ username }) => PUBLIC_PROFILE.replace(":username", username)
    },
    {
        name: "Download History",
        href: ({ username }) => `/${username}/settings/icon-history/`
    },
    {
        name: "Saved Icons",
        href: ({ username }) => PROFILE_KITS.replace(":username", username)
    },
    {
        name: "Saved Icon Sets",
        href: KITS_FAVS_ICON_SETS
    },
    {
        name: "Saved Photos",
        href: KITS_FAVS_PHOTOS
    }
];

export const myStuffApiMenu = [
    {
        name: "Manage API Apps",
        href: "/developers/apps/"
    },
    {
        name: "API Documentation",
        href: "https://api.thenounproject.com/",
        external: true
    }
];

export const SUBMIT_ICONS = "/creators/";

export const nounProjectMenu = [
    {
        name: "About",
        href: aboutPath
    },
    {
        name: "Blog",
        href: "https://blog.thenounproject.com/",
        external: true
    },
    {
        name: "Jobs",
        href: CAREERS_PATH
    },
    {
        name: "Iconathons",
        href: "/Iconathon1/",
        external: true
    },
    {
        name: "Submit Icons",
        href: SUBMIT_ICONS
    },
    {
        name: "Submit Photos",
        href: BECOME_PHOTOGRAPHER_LANDING_PAGE
    },
    customerSupportLink,
    {
        name: "Affiliate Program",
        href: AFFILIATES_PATH
    },
    {
        name: "Legal",
        href: "/legal/"
    }
];

export const productMenu = [
    {
        name: "Home",
        href: homePath.url
    },
    {
        name: "Icons",
        href: ICON_LANDING_PAGE
    },
    {
        name: "Photos",
        href: photosLandingPath
    },
    {
        name: "Pricing",
        href: PRICING
    },
    {
        name: "Apps & Plugins",
        href: appsAndPluginsPath
    },
    {
        name: "API",
        href: API_LANDING_PAGE
    },
    {
        name: "Lingo",
        href: lingoMarketingPagePath
    }
];

export const socialMedia = [
    {
        name: "Twitter",
        href: "https://www.twitter.com/nounproject"
    },
    {
        name: "Facebook",
        href: "https://www.facebook.com/thenounproject"
    },
    {
        name: "Instagram",
        href: "https://www.instagram.com/nounproject"
    },
    // {
    //     name: "Dribbble",
    //     href: "https://www.dribbble.com/nounproject"
    // },
    {
        name: "Behance",
        href: "https://www.behance.com/noun_project"
    }
];

export const footerMenu = [
    {
        name: "About Us",
        href: "/about/",
        external: false
    },
    {
        name: "Contact Us",
        href: `${contactCustomerSupportLink}?subject=Wanted to get in contact`,
        external: true
    },
    customerSupportLink,
    {
        name: "Jobs",
        href: CAREERS_PATH
    },
    {
        name: "Legal",
        href: "/legal/",
        external: false
    }
];

/* -------------------------------------------------------------------------- */
/*                                 MODERATION                                 */
/* -------------------------------------------------------------------------- */

export const MOD_PHOTOS = "/private/mod/photos";

/* -------------------------------------------------------------------------- */
/*                                PRIMARY MENU                                */
/* -------------------------------------------------------------------------- */

export const primaryMenu = {
    products: {
        name: "Products",
        links: {
            home: {
                name: "Home",
                href: homePath.url
            },
            icons: {
                name: "Icons",
                href: ICON_LANDING_PAGE
            },
            photos: {
                name: "Photos",
                href: photosLandingPath
            },
            api: {
                name: "API",
                href: API_LANDING_PAGE
            },
            pricing: {
                name: "Pricing",
                href: PRICING
            },
            tools: {
                name: "Tools",
                links: {
                    mac: {
                        name: "Mac App",
                        href: FOR_MAC_PATH
                    },
                    office: {
                        name: "Office Add-Ins",
                        href: FOR_OFFICE_PATH
                    },
                    adobe: {
                        name: "Adobe Add-Ins",
                        href: FOR_ADOBE_PATH
                    },
                    google: {
                        name: "Google Add-Ons",
                        href: FOR_GOOGLE_PATH
                    },
                    lingo: {
                        name: "Lingo Brand Manager",
                        href: lingoMarketingPagePath
                    }
                }
            }
        }
    },
    tnp: {
        name: "The Noun Project",
        links: {
            about: {
                name: "About",
                href: aboutPath
            },
            blog: {
                name: "Blog",
                href: "https://blog.thenounproject.com/",
                target: "_blank"
            },
            submitIcons: {
                name: "Submit Icons",
                href: SUBMIT_ICONS
            },
            submitPhotos: {
                name: "Submit Photos",
                href: BECOME_PHOTOGRAPHER_LANDING_PAGE
            },
            customerSupport: {
                name: "Customer Support",
                href: "https://thenounproject.zendesk.com/",
                target: "_blank"
            },
            affiliates: {
                name: "Affiliate Program",
                href: AFFILIATES_PATH
            },
            legal: {
                name: "Legal",
                href: "/legal/"
            },
            jobs: {
                name: "Jobs",
                href: CAREERS_PATH
            },
            iconathons: {
                name: "Iconathons",
                href: "/Iconathon1/"
            }
        }
    }
};
