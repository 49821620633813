import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import colors from "core/colors";

const createHelpers = (width, height, css) => {
    // somehow sizes is ending up in markup, even if it is not a valid svg attribute
    // until we have a better solution, just render it empty, instead to '[Object object]'
    const sanitizeSizes = sizes =>
        Object.defineProperty(sizes, "toString", {
            value: () => "",
            enumerable: false
        });

    const getDimensions = (size, sizes) => {
        if (
            size &&
            typeof size.width === "number" &&
            typeof size.height === "number"
        ) {
            return size;
        }

        return size && sizes[size] ? sizes[size] : { width, height };
    };

    const getCss = (size, sizes, fillColor, fillColorRule, noStyles) => {
        if (noStyles) {
            return "";
        }

        const dimensions = getDimensions(size, sizes);
        const fillRule =
            fillColor && fillColorRule
                ? `${fillColorRule}{ fill: ${fillColor}; }`
                : "";

        return css`
            width: ${dimensions.width}px;
            height: ${dimensions.height}px;
            ${fillRule}
        `;
    };

    const propsToCss = ({ size, sizes, fillColor, fillColorRule, noStyles }) =>
        getCss(size, sizes, fillColor, fillColorRule, noStyles);

    return {
        getCss,
        getDimensions,
        propsToCss,
        sanitizeSizes
    };
};

const width = "40";
const height = "40";
const viewBox = "0 0 40 40";

const { getDimensions, getCss, propsToCss, sanitizeSizes } = createHelpers(
    width,
    height,
    css
);

const sizes = sanitizeSizes({});

const Image = styled.svg`
    ${propsToCss}
`;

const children = (
    <Fragment>
        <path
            fill={colors.secondary.orca}
            fillRule="evenodd"
            d="M33.626 0A6.374 6.374 0 0140 6.374v27.252A6.374 6.374 0 0133.626 40H6.374A6.374 6.374 0 010 33.626V6.374A6.374 6.374 0 016.374 0h27.252zM20.251 5.938h-.074l-.518.005c-1.234 0-5.418.344-7.391 4.769-.664 1.489-.505 4.017-.378 6.049l.045.728c-.102.057-.288.127-.582.127-.392 0-.855-.123-1.376-.368a1.11 1.11 0 00-.474-.097c-.61 0-1.341.402-1.455 1-.082.432.111 1.06 1.497 1.608.125.05.275.097.434.147.573.182 1.438.457 1.673 1.01.122.286.073.655-.144 1.096l-.014.03c-.076.177-1.908 4.353-5.974 5.022a.621.621 0 00-.519.647c.006.096.028.19.066.279.305.713 1.593 1.236 3.937 1.6.079.106.16.48.21.708.049.225.1.457.171.702.071.24.255.528.726.528.191 0 .416-.043.676-.095.39-.076.926-.18 1.595-.18.371 0 .756.032 1.144.096.756.126 1.399.58 2.143 1.107 1.066.753 2.273 1.606 4.108 1.606.05 0 .1-.001.15-.005.06.003.138.005.22.005 1.835 0 3.042-.853 4.107-1.606.746-.527 1.388-.981 2.145-1.107.378-.063.76-.095 1.143-.096.638 0 1.144.08 1.595.169.282.055.51.083.675.083h.035c.348 0 .594-.184.692-.518.07-.24.12-.466.17-.694.05-.228.132-.6.21-.706 2.345-.364 3.632-.886 3.936-1.596a.826.826 0 00.068-.28.62.62 0 00-.519-.647c-4.069-.67-5.899-4.845-5.975-5.022a.455.455 0 00-.014-.03c-.217-.441-.265-.81-.143-1.096.234-.553 1.1-.828 1.672-1.01.16-.05.31-.098.435-.147 1.014-.4 1.523-.893 1.512-1.462-.009-.448-.357-.847-.911-1.045h-.002c-.198-.08-.41-.12-.624-.12-.148 0-.368.02-.573.116-.482.226-.916.35-1.29.366a1.146 1.146 0 01-.502-.125l.039-.64.005-.086c.128-2.033.287-4.564-.377-6.054-1.975-4.427-6.167-4.771-7.405-4.771z"
            clipRule="evenodd"
            key="key-0"
        />
    </Fragment>
);

const defaultProps = {
    children,
    viewBox,
    fillColor: null,
    fillColorRule: "&&& path, &&& use, &&& g, &&& circle",
    sizes,
    size: null
};

Image.propTypes /* remove-proptypes */ = {
    fillColor: PropTypes.string,
    fillColorRule: PropTypes.string,
    viewBox: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    size: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            height: PropTypes.number.isRequired,
            width: PropTypes.number.isRequired
        })
    ]),
    sizes: PropTypes.shape({
        height: PropTypes.number,
        width: PropTypes.number
    })
};

export default Object.assign(Image, {
    getDimensions,
    getCss,
    defaultProps,
    displayName: "Snapchat"
});
