import React from "react";
import PropTypes from "prop-types";

// SVG logo:
import NpHeaderLogo from "./images/NpHeaderLogo";

// components:
import { OverflowMenu } from "components/OverflowMenu";
import { ProfileMenu } from "components/ProfileMenu";
import DefaultSearch from "core/search/DefaultSearch";

// links:
import { homePath } from "data/links";

// shared styles/components:
import { Col } from "core/oldGrid";
import colors from "core/colors";
import {
    HeaderLogoLink,
    MobileContentWrapper,
    LogoAndSearch,
    MenuAndAvatar,
    HeaderRow
} from "./styles";

const MobileContent = ({
    transparent,
    currentUser,
    myPhotoModProfile,
    loginLink,
    joinLink,
    handleLogOut,
    isHeroSearchV2
}) => {
    const loggedOut = !currentUser || currentUser.isAnonymous;

    return (
        <MobileContentWrapper>
            <HeaderRow middle="sm">
                <Col sm={9}>
                    <LogoAndSearch alignItems="center">
                        <HeaderLogoLink
                            href={homePath.url}
                            isHeroSearchV2={isHeroSearchV2}
                        >
                            <NpHeaderLogo
                                title={homePath.alt}
                                fillColor={
                                    isHeroSearchV2
                                        ? colors.primary.snow
                                        : colors.secondary.onyx
                                }
                                width={38}
                                height={10}
                            />
                        </HeaderLogoLink>

                        <DefaultSearch />
                    </LogoAndSearch>
                </Col>
                <Col sm={3}>
                    <MenuAndAvatar
                        loggedOut={loggedOut}
                        flexDirection={loggedOut && "row-reverse"}
                        alignItems="center"
                        justifyContent={loggedOut ? "flex-start" : "flex-end"}
                    >
                        <OverflowMenu
                            transparent={transparent}
                            isHeroSearchV2={isHeroSearchV2}
                        />
                        <ProfileMenu
                            isHeroSearchV2={isHeroSearchV2}
                            currentUser={currentUser}
                            myPhotoModProfile={myPhotoModProfile}
                            loginLink={loginLink}
                            joinLink={joinLink}
                            handleLogOut={handleLogOut}
                        />
                    </MenuAndAvatar>
                </Col>
            </HeaderRow>
        </MobileContentWrapper>
    );
};

MobileContent.propTypes = {
    isHeroSearchV2: PropTypes.bool,
    transparent: PropTypes.bool,
    currentUser: PropTypes.object,
    myPhotoModProfile: PropTypes.object,
    loginLink: PropTypes.oneOfType([PropTypes.node, PropTypes.element])
        .isRequired,
    joinLink: PropTypes.oneOfType([PropTypes.node, PropTypes.element])
        .isRequired,
    handleLogOut: PropTypes.func.isRequired
};

export { MobileContent };
