import styled, { css } from "styled-components";

import { focusOutline } from "core/links";
import { easingSeconds } from "core/animation";

export const ImageContainer = styled.div`
    display: block;
    width: 100%;
    height: 100%;

    ${({ as }) =>
        as === "a" &&
        css`
            transition: all ${easingSeconds};

            &:focus {
                ${focusOutline}
            }
        `}
`;

/*
 * NOTE: disabled for now. If you need it:
 * 1. add `position: relative` to `ImageContainer`.
 * 2. add `<RightClickDefender />` as the next sibling to the img
 *
export const RightClickDefender = styled.img.attrs({
    src:
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
})`
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;
*/

export const Image = styled.img`
    display: block;
    height: 100%;
    width: 100%;

    ${({ preventPointerEvents }) =>
        preventPointerEvents &&
        css`
            pointer-events: none;
        `}
`;

export const PlaceholderImage = styled.img.attrs(({ source }) => ({
    style: {
        backgroundImage: `url(${source})`
    }
}))`
    vertical-align: bottom;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: ${({ backgroundSize }) =>
        backgroundSize ? backgroundSize : "100% auto"};
    background-position: ${({ backgroundPosition }) =>
        backgroundPosition ? backgroundPosition : "bottom center"};
`;
