import styled from "styled-components";

import Heading from "core/Heading";

import breakpoints from "core/breakpoints";
import { spacingWithBreakpoints } from "core/spacing";
import { P } from "core/Typography";
import { regular as regularFontWeight } from "core/fontStyles";
import colors from "core/colors";

const MarketingPageSectionEyebrowSubheading = styled(P).attrs({
    bold: true
})`
    text-transform: uppercase;
    font-size: 0.7188rem; /* 11.5px custom size */
    line-height: 100%;
    letter-spacing: 0.0625rem;
    ${spacingWithBreakpoints.XSBottom}
    text-align: center;
`;

const MarketingPageSectionHeading = styled(Heading)`
    color: ${colors.secondary.orca};

    /* Headings that are preceded by EyebrowSubHeadings
    have different spacing than those that aren't */
    ${MarketingPageSectionEyebrowSubheading} + & {
        ${spacingWithBreakpoints.LGBottom}
    }
`;

const MarketingPageSectionBiLine = styled.p`
    ${regularFontWeight}
    font-size: 0.875rem; /* 14px custom */
    line-height: 1.3125rem;
    text-align: center;

    /* Tablet: */
    @media (min-width: ${breakpoints.tablet.rem}) {
        line-height: 1.4rem;
    }

    /* Desktop: */
    @media (min-width: ${breakpoints.desktop.rem}) {
        font-size: 1rem; /* 16px custom */
        line-height: 1.625rem;
    }
`;

const MarketingPageSectionHeadingContainer = styled.div`
    color: ${colors.secondary.orca};
    margin: 0 auto;
    text-align: center;
    width: 100%;

    @media (min-width: ${breakpoints.tablet.rem}) {
        max-width: 38.75rem;
    }

    @media (min-width: ${breakpoints.desktop.rem}) {
        max-width: 43.75rem;
    }
`;

export {
    MarketingPageSectionHeadingContainer,
    MarketingPageSectionEyebrowSubheading,
    MarketingPageSectionHeading,
    MarketingPageSectionBiLine
};
