import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

import { getLinkElementStyles, STYLE_TYPE_DEFAULTS } from "core/Link/styles";

const LinkInNewRouter = styled(Link)`
    ${({ size, darkMode, disableVisitedStyle }) =>
        getLinkElementStyles({
            size: size || "md",
            darkMode: darkMode || false,
            styleType: "primary",
            $disableVisitedStyle: disableVisitedStyle || false
        })}

    ${({ disableVisitedStyle }) =>
        disableVisitedStyle &&
        css`
            &:visited {
                ${STYLE_TYPE_DEFAULTS.lightMode.primary}
            }
        `}
`;

export default LinkInNewRouter;
