import React, { useReducer, useMemo } from "react";
import PropTypes from "prop-types";

// MessageStackContext
import MessageStackContext from "core/MessageStack/context";
import reducer from "core/MessageStack/context/reducer";
import * as Actions from "core/MessageStack/context/actions";

// components
export { RootMessageStackZone } from "core/MessageStack/components/RootMessageStackZone";

/*
This core component is used to create a list of timed messages for user to see and interact with.

- Message can be timed or persist - if persist, user can still interact and dismiss
- timed messages are based on length of message, or timed override
- if user mouses over timed message
    - it pauses
    - when they mouse out, it starts timer over (or just persists if persist is what was chosen)
- fade in and fade out
- up to 3 messages will show at a time
- oldest message will dissapear, and messages will slide up. If messages exceed 3 - the next newest will pop in line
- useMessageStack gives Dev access to
    - messages (can show elsewhere, or count messages)
    - addMessages to add message at will

Message shape

- See `core/MessageStack/sampleMessages.js` for sample message object shape
- Each message object must have a unique id. React uses this to distinguish each message in the mapping order. Weird results will occur if they are not unique. One item may seem to persist in DOM when it should be removed.
*/
const MessageStackProvider = ({
    defaultMessages = [],
    stackLimit = 3,
    children
}) => {
    const [state, dispatch] = useReducer(reducer, {
        messages: Actions.sanatizeMessages(defaultMessages)
    });

    // Fix the object passed as the value prop to the Context provider changes every render
    const value = useMemo(() => ({ state, dispatch, stackLimit }), [
        state,
        dispatch,
        stackLimit
    ]);

    return (
        <MessageStackContext.Provider
            value={value}
            displayName="Message Stack Context"
        >
            {children}
        </MessageStackContext.Provider>
    );
};

export default MessageStackProvider;

// prop-types
MessageStackProvider.propTypes = {
    defaultMessages: PropTypes.array,
    stackLimit: PropTypes.number,
    children: PropTypes.any
};
