import { useApolloLazyQuery } from "frontend/utils/apolloApi/useApolloLazyQuery.js";

// queries
import { FETCH_RELEASES_IN_BATCH } from "frontend/components/fort/mod/photos/gql/queries";

export const useFetchReleases = () => {
    const { apolloQuery } = useApolloLazyQuery({
        actionDesc: "Fetch Batch Releases",
        gql: FETCH_RELEASES_IN_BATCH
    });

    const fetchReleases = async batchId => {
        const { ssResp } = await apolloQuery({
            variables: { photoBatchId: batchId }
        });

        return ssResp?.data?.releaseList?.items || [];
    };

    return {
        fetchReleases
    };
};
