// This component uses CSS to hide; if JS is needed to hide, we can refactor
import React from "react";
import PropTypes from "prop-types";

import { HiddenContainer } from "./styles";

const Hidden = ({ children, className, type, breakpoint, ...props }) => (
    <HiddenContainer
        className={className}
        as={type}
        breakpoint={breakpoint}
        {...props}
    >
        {children}
    </HiddenContainer>
);

Hidden.propTypes = {
    type: PropTypes.oneOf(["span", "div"]),
    breakpoint: PropTypes.oneOf(["sm", "smMd", "md", "mdLg", "lg"]).isRequired
};

export default Hidden;
