import gql from "graphql-tag";

const ItemsPartsFragment = gql`
    fragment ItemsParts on PhotoType {
        id
        slugWithId
        title
        isPremium
        creator {
            id
            name
            username
            country
            description
        }
        assets {
            thumbnailSmall
            thumbnailMedium
        }
        ratio
    }
`;

export const BROWSE_PHOTOS_BY_CAMPAIGNS = gql`
    query browsePhotosByCampaign(
        $campaignId: ID!
        $campaignDetailId: ID!
        $limit: Int
        $offset: Int
    ) {
        browsePhotosByCampaign(
            campaignId: $campaignId
            limit: $limit
            offset: $offset
        ) {
            items {
                ...ItemsParts
            }
            totalCount
        }

        campaignDetail(campaignId: $campaignDetailId) {
            id
            title
            description
            moreInfoLink
            moreInfoText
            slugWithId
        }
    }
    ${ItemsPartsFragment}
`;

export const BROWSE_SIMILAR_PHOTOS = gql`
    query browseSimilarPhotos(
        $photoId: String!
        $limit: Int
        $offset: Int
        $photoDetailId: ID!
    ) {
        browseSimilarPhotos(photoId: $photoId, limit: $limit, offset: $offset) {
            items {
                ...ItemsParts
            }
            totalCount
        }
        photoDetail(photoId: $photoDetailId) {
            id
            title
        }
    }
    ${ItemsPartsFragment}
`;

export const GET_PHOTOS_BY_TAG = gql`
    query browsePhotosByTag($tag: String!, $limit: Int, $offset: Int) {
        browsePhotosByTag(tag: $tag, limit: $limit, offset: $offset) {
            items {
                ...ItemsParts
            }
            totalCount
        }
    }
    ${ItemsPartsFragment}
`;

export const GET_PHOTOS_BY_CREATOR = gql`
    query browsePhotosByCreator($creator: String!, $limit: Int, $offset: Int) {
        browsePhotosByCreator(
            creator: $creator
            limit: $limit
            offset: $offset
        ) {
            items {
                id
                slugWithId
                title
                isPremium
                assets {
                    thumbnailSmall
                    thumbnailMedium
                }
                ratio
            }
            totalCount
        }
        creatorProfileDetailByUsername(username: $creator) {
            id
            name
            username
            country
            description
            avatarUrl
        }
    }
`;

export const GET_CREATOR_INFO = gql`
    query getCreator($username: String!) {
        creator: creatorProfileDetailByUsername(username: $username) {
            name
            username
            avatarUrl
        }
    }
`;

export const GET_PHOTOS_BY_SHOOT_ID = gql`
    query browsePhotosByShoot(
        $photoShootId: ID!
        $photoShootDetailId: String!
        $limit: Int
        $offset: Int
    ) {
        browsePhotosByShoot(
            photoShootId: $photoShootId
            limit: $limit
            offset: $offset
        ) {
            items {
                id
                slugWithId
                title
                isPremium
                assets {
                    thumbnailSmall
                    thumbnailMedium
                }
                ratio
            }
            totalCount
        }
        photoShootDetail(photoShootId: $photoShootDetailId) {
            id
            description
            title
            shootLocation
            creator {
                id
                name
                username
                country
                description
                avatarUrl
            }
        }
    }
`;

export const GET_PHOTO_DETAILS = gql`
    query photoDetail($photoId: ID!) {
        photoDetail(photoId: $photoId) {
            id
            slugWithId
            title
            description
            license
            isPremium
            editorialUseOnly
            creator {
                id
                name
                username
                avatarUrl
                country
            }
            assets {
                preview
                thumbnailMedium
                thumbnailLarge
            }
            tags {
                slug
                name
            }
            ratio
            createdAt
            freeDimensions {
                pixelWidth
                pixelHeight
                printDpi
                printWidth
                printHeight
            }
            largeDimensions {
                pixelWidth
                pixelHeight
                printDpi
                printWidth
                printHeight
            }
            fullDimensions {
                pixelWidth
                pixelHeight
                printDpi
                printWidth
                printHeight
            }
            campaigns {
                id
                title
                slugWithId
                publishEndTime
                publishStartTime
                isDisabled
                hideFromUsersInUx
            }
        }
    }
`;

export const MORE_PHOTOS_FROM_SHOOT_BY_PHOTO_ID = gql`
    query morePhotosFromThisShoot($photoId: ID!, $limit: Int, $offset: Int) {
        morePhotosFromThisShoot(
            photoId: $photoId
            limit: $limit
            offset: $offset
        ) {
            items {
                id
                slugWithId
                title
                isPremium
                photoShoot {
                    id
                    slugWithId
                    description
                    title
                    shootLocation
                }
                creator {
                    id
                    name
                    username
                    country
                    description
                }
                assets {
                    thumbnailSmall
                    thumbnailMedium
                }
                ratio
            }
            totalCount
        }
    }
`;
