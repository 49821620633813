import React, { useEffect, useState, useCallback } from "react";
import { useMutation, useQuery } from "@apollo/client";

import AddTags from "core/AddTags";
import { AddTagsContainer } from "./styles";

import { TAGS_INIT_STATES } from "core/AddTags/constants";
import {
    ADD_TAGS_ERRORS,
    maxTagsError,
    maxTagsAllowed,
    maxTagsBatchError
} from "./constants";

import { useAddTags } from "core/AddTags/__hooks__/useAddTags";
import { useRateLimitError } from "frontend/hooks/useRateLimitError";

import { getBatchMergedTags, handleAddTagCompleted } from "./helpers";

import { SUBMIT_TAGS, DELETE_TAG } from "./mutations";
import { GET_TAG_SUGGESTIONS } from "./queries";

import { useEditPhotoUpload } from "./EditPhotoUploadContext";

const AddTagsSection = () => {
    const [isDisabled, setDisabled] = useState(false);
    const { isRateLimited } = useRateLimitError();

    const { uploadIds, uploads = [], isBatchEditing } = useEditPhotoUpload();

    const addTagsProps = useAddTags({
        photoUploadIds: uploadIds,
        ariaId: "photos",
        labelText: "Tags",
        placeholderText: "Add Tags",
        minLength: 3,
        buttonText: "Add",
        isDisabled,
        onSubmit: values => handleTagsSubmit({ values }),
        onDelete: val => handleTagDelete({ val })
    });

    const { setInputError, setTermAdded, setSuggestedTags } = addTagsProps;

    const tags = isBatchEditing
        ? getBatchMergedTags({ data: uploads })
        : uploads[0]
        ? uploads[0].userTags
        : [];

    const hasMaxTags = !!uploads.filter(photo => {
        return photo.userTags.length === maxTagsAllowed;
    }).length;

    const onTagRateLimit = useCallback(() => {
        setSuggestedTags([]);
        setInputError([
            {
                reason: ADD_TAGS_ERRORS.RATE_LIMITED,
                tags: "",
                message:
                    "Editing speed limited for security. Please wait a moment and try again."
            }
        ]);
    }, [setInputError]);

    const { refetch } = useQuery(GET_TAG_SUGGESTIONS, {
        variables: {
            name: ""
        }
    });

    const handleSuggestedResults = query => {
        refetch({
            name: query
        })
            .then(({ data }) => {
                if (!!data) {
                    const lowercaseTags = data.tagAutocompleteSuggestions.items.map(
                        item => item.toLowerCase()
                    );
                    setSuggestedTags(lowercaseTags, query);
                }
            })
            .catch(error => {
                if (isRateLimited(error)) {
                    onTagRateLimit();
                }
            });
    };

    const handleTagsSubmit = ({ values }) => {
        const formatTags = values.slice(0, 50).map(val => val.normalize("NFC"));
        return submitTags({
            variables: {
                photoUploadIds: uploadIds,
                tags: formatTags
            }
        });
    };

    const handleTagDelete = ({ val }) => {
        const optimisticResponsePhotoUploads = uploadIds.map(id => {
            return {
                id,
                userTags: [...tags]
            };
        });

        const optimisticResponseTagsRemoved = uploadIds.map(id => {
            return {
                tag: val,
                photoUploadId: id,
                __typename: "TagsAddedOrRemovedType"
            };
        });

        return removeTag({
            variables: {
                photoUploadIds: uploadIds,
                tags: [val]
            },
            optimisticResponse: {
                removeTagsFromPhotoUploads: {
                    tagsNotRemoved: null,
                    tagsRemoved: optimisticResponseTagsRemoved,
                    photoUploads: optimisticResponsePhotoUploads,
                    __typename: "RemoveTagsFromPhotoUploads"
                }
            }
        });
    };

    const [submitTags] = useMutation(SUBMIT_TAGS, {
        onCompleted: data =>
            handleAddTagCompleted({
                data,
                hasMaxTags,
                setInputError,
                setSuggestedTags
            }),
        onError: error => {
            if (isRateLimited(error)) {
                onTagRateLimit();
            }
        }
    });

    const [removeTag] = useMutation(DELETE_TAG, {
        onError: error => {
            if (isRateLimited(error)) {
                onTagRateLimit();
            }
        }
    });

    // disables field in UI if max tags on page load:
    useEffect(() => {
        if (hasMaxTags) {
            setInputError([
                {
                    reason: ADD_TAGS_ERRORS.TOO_MANY_TAGS,
                    tags: [],
                    message: isBatchEditing ? maxTagsBatchError : maxTagsError
                }
            ]);
            setDisabled(true);
            setTermAdded(TAGS_INIT_STATES.TERM_ADDED);
        } else {
            setInputError(TAGS_INIT_STATES.ERRORS);
            setDisabled(false);
        }
    }, [hasMaxTags]);

    return (
        <AddTagsContainer>
            <AddTags
                tags={tags}
                handleSuggestedResults={handleSuggestedResults}
                handleChange={() => setInputError(TAGS_INIT_STATES.TERM_ADDED)}
                {...addTagsProps}
            />
        </AddTagsContainer>
    );
};

export default AddTagsSection;
