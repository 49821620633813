import React, { useEffect, useRef } from "react";

// context
import { useManageTags } from "../context/useManageTags";

// components
import AddTagForm from "./AddTagForm";
import Tags from "./Tags/index";

/*---------------------------
| MountingWrapper
---------------------------*/
const MountingWrapper = () => {
    const {
        mediaTypeTitle,
        dispatch,
        actions,
        suggestions,
        isDisabled,
        tags,
        tagLimit
    } = useManageTags();

    let prevSuggestionsRef = useRef([]);

    useEffect(() => {
        // we should only ever dispatch if suggestions actually changes, not every re-render.
        const suggestionsChanged =
            JSON.stringify(prevSuggestionsRef.current) !==
            JSON.stringify(suggestions);
        if (suggestionsChanged) {
            dispatch(actions.setShowSuggestionDropDown(suggestions.length > 0));
        }
        prevSuggestionsRef.current = suggestions;
    }, [suggestions]);

    useEffect(() => {
        // Note: There is potential for this to undo a state change internally
        // but given the current business requirements - we should be ok here.
        // from consumer perspective, Tags are either enabled or disabled
        // Internally, disabling state is based on interaction
        let isEnabled = !isDisabled;
        dispatch(
            actions.setEnabled({
                add: isEnabled,
                remove: isEnabled,
                approval: isEnabled,
                suggestions: isEnabled
            })
        );
    }, [isDisabled]);

    // detect if they have reached Tag Limit
    useEffect(() => {
        if (tags.length >= tagLimit) {
            dispatch(
                actions.setErrorMessage(
                    `${mediaTypeTitle}(s) have reached the ${tagLimit}-tag maximum`
                )
            );
            dispatch(
                actions.setEnabled({
                    add: false
                })
            );
        }
    }, [tags.length]);

    return (
        <>
            {!isDisabled && <AddTagForm />}
            <Tags />
        </>
    );
};

export default MountingWrapper;
