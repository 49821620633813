import React from "react";
import PropTypes from "prop-types";

// styles
import { UploadStyled } from "./styles.js";
import colors from "core/colors";

const ModalUpload = ({
    uploadId = "",
    UploadGraphicComponent,
    bgColor = colors.primary.diamond
}) => {
    return (
        <UploadStyled bgColor={bgColor}>
            <UploadGraphicComponent uploadId={uploadId} />
        </UploadStyled>
    );
};

export default ModalUpload;

// prop-types
ModalUpload.propTypes = {
    uploadId: PropTypes.string.isRequired,
    UploadGraphicComponent: PropTypes.any.isRequired,
    bgColor: PropTypes.string
};
