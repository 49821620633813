import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Modal from "core/Modal";
import Link from "core/Link";
import ErrorItem from "./ErrorItem";

const FaqLink = styled(Link)`
    margin-left: 0.5rem;
`;

const ErrorModal = ({ open, handleClose, uploadErrors = [] }) => {
    return (
        <Modal
            open={open}
            handleClose={handleClose}
            heading={
                <>
                    Error Details{" "}
                    <FaqLink
                        size="sm"
                        target="_blank"
                        href="https://help.thenounproject.com/hc/en-us/articles/22485142650779-Photo-Format-and-Resolution"
                    >
                        See FAQ
                    </FaqLink>
                </>
            }
            closeOnEscape
            closeOnBackDropClick
        >
            {uploadErrors.map(({ filename, error }, idx) => (
                <ErrorItem
                    key={idx}
                    uploadName={filename}
                    errorMessage={error}
                />
            ))}
        </Modal>
    );
};

ErrorModal.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    uploadErrors: PropTypes.array.isRequired
};

export default ErrorModal;
