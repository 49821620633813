import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import ModalBase from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";

import CaretRight from "core/ReusableIcons/CaretRight";
import CaretLeft from "core/ReusableIcons/CaretLeft";
import colors from "core/colors";

import ModalBody from "./ModalBody";
import CarouselButton from "./CarouselButton";

import { Paper, modalStyles, ModalFlexbox } from "./styles";

import { modalTypes, modalSizes, headingSizes, headingTypes } from "core/props";
import { MuiThemeProvider } from "assets/js/helpers/materialUI";

const UnstyledModal = ({
    open,
    handleClose = () => {},
    heading,
    headingCharLimit = 50, // set to null for unlimited characters
    headingSize = "xs",
    headingType = "div",
    type = "text",
    size = "lg",
    footerChildren,
    className,
    children,
    handleChangeItem,
    currentItemIndex,
    carouselLength,
    isPrevProcessing = false,
    isNextProcessing = false,
    closeOnEscape = false,
    closeOnBackDropClick = false,
    ...otherProps
}) => {
    // begin "carousel" type logic:
    const isCarouselType = type === "carousel";
    const isPrevDisabled = isCarouselType && currentItemIndex === 0;
    const isNextDisabled = isCarouselType && currentItemIndex >= carouselLength;

    const handleKeyPress = e => {
        if (isCarouselType) {
            if (e.key === "ArrowRight" && !isNextDisabled) {
                handleChangeItem(currentItemIndex + 1);
            } else if (e.key === "ArrowLeft" && !isPrevDisabled) {
                handleChangeItem(currentItemIndex - 1);
            }
        } else {
            return;
        }
    };

    // Material UI onClose preferred for handling these events
    // https://mui.com/material-ui/api/modal/
    const onClose = (e, reason) => {
        if (closeOnEscape && reason === "escapeKeyDown") {
            handleClose();
        }
        if (closeOnBackDropClick && reason === "backdropClick") {
            handleClose();
        }
    };

    useEffect(() => {
        if (isCarouselType) {
            window.addEventListener("keydown", handleKeyPress);
            return () => {
                window.removeEventListener("keydown", handleKeyPress);
            };
        }
    }, [handleKeyPress]);

    return (
        <MuiThemeProvider>
            <ModalBase
                className={className}
                open={open}
                BackdropComponent={Backdrop}
                closeAfterTransition
                onClose={onClose}
                {...otherProps}
            >
                <Fade in={open}>
                    <ModalFlexbox
                        onClick={isCarouselType ? handleClose : undefined}
                        alignItems="center"
                        justifyContent="center"
                        size={size}
                        type={type}
                        heading={heading}
                        footerChildren={footerChildren}
                        data-testid="modal-flex-container"
                    >
                        {isCarouselType && (
                            <CarouselButton
                                title="Previous"
                                onClick={() =>
                                    handleChangeItem(currentItemIndex - 1)
                                }
                                isDisabled={isPrevDisabled}
                                isProcessing={isPrevProcessing}
                                icon={
                                    <CaretLeft
                                        fillColor={colors.secondary.orca}
                                        size="xl"
                                    />
                                }
                            />
                        )}

                        <Paper
                            size={size}
                            heading={heading}
                            onClick={e => {
                                e.stopPropagation();
                            }}
                        >
                            <ModalBody
                                heading={heading}
                                headingCharLimit={headingCharLimit}
                                headingSize={headingSize}
                                headingType={headingType}
                                type={type}
                                size={size}
                                handleClose={handleClose}
                                footerChildren={footerChildren}
                            >
                                {children}
                            </ModalBody>
                        </Paper>

                        {isCarouselType && (
                            <CarouselButton
                                title="Next"
                                isDisabled={isNextDisabled}
                                isProcessing={isNextProcessing}
                                onClick={() =>
                                    handleChangeItem(currentItemIndex + 1)
                                }
                                icon={
                                    <CaretRight
                                        fillColor={colors.secondary.orca}
                                        size="xl"
                                    />
                                }
                            />
                        )}
                    </ModalFlexbox>
                </Fade>
            </ModalBase>
        </MuiThemeProvider>
    );
};

const Modal = styled(UnstyledModal)`
    ${modalStyles}
`;

Modal.propTypes = {
    className: PropTypes.string,
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func,
    heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    headingCharLimit: PropTypes.number,
    headingSize: PropTypes.oneOf(headingSizes),
    headingType: PropTypes.oneOf(headingTypes),
    type: PropTypes.oneOf(modalTypes),
    size: PropTypes.oneOf(modalSizes),
    currentItemIndex: PropTypes.number,
    handleChangeItem: PropTypes.func,
    carouselLength: PropTypes.number,
    isPrevProcessing: PropTypes.bool,
    isNextProcessing: PropTypes.bool,
    closeOnEscape: PropTypes.bool,
    closeOnBackDropClick: PropTypes.bool,
    footerChildren: PropTypes.any
};

export default Modal;
