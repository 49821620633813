import React, { useState, useCallback } from "react";

import ResetPassword from "../ResetPassword";
import ResetPasswordSuccess from "../ResetPasswordSuccess";
import ResetPasswordTimeout from "../ResetPasswordTimeout";
import {
    PageTitle,
    PageSubtitle,
    PageWrapper,
    PageFormContent,
    PageFeedbackContent
} from "../styles";

const ResetPasswordPage = () => {
    const [showSuccess, setShowSuccess] = useState(false);
    const [keyIsExpired, setKeyIsExpired] = useState(false);
    const afterSubmit = useCallback(() => setShowSuccess(true), []);

    if (showSuccess) {
        return (
            <PageWrapper>
                <PageFeedbackContent>
                    <PageTitle>Your Password Has Been Updated</PageTitle>
                    <ResetPasswordSuccess />
                </PageFeedbackContent>
            </PageWrapper>
        );
    }

    if (keyIsExpired) {
        return (
            <PageWrapper>
                <PageFeedbackContent>
                    <PageTitle>Set a New Password</PageTitle>
                    <ResetPasswordTimeout />
                </PageFeedbackContent>
            </PageWrapper>
        );
    }

    return (
        <PageWrapper darkMode>
            <PageTitle darkMode>Set a New Password</PageTitle>
            <PageSubtitle>Commit this to memory.</PageSubtitle>
            <PageFormContent>
                <ResetPassword
                    afterSubmit={afterSubmit}
                    setKeyIsExpired={setKeyIsExpired}
                />
            </PageFormContent>
        </PageWrapper>
    );
};

export default ResetPasswordPage;
