import { useDispatch, useSelector } from "react-redux";
import { useApolloMutation } from "frontend/utils/apolloApi/useApolloMutation.js";

// auth
import { useAuth } from "frontend/hooks/useAuth";

// redux
import { batchesSlice } from "../../slices/batchesSlice";
import { messagesSlice } from "../../slices/messagesSlice";

// mutations
import { ASSIGN_BATCH_TO_MOD } from "frontend/components/fort/mod/photos/gql/mutations";

// utils
import { isEditableCheck } from "frontend/components/fort/mod/photos/components/PhotoModQueuePageContent/QueueProviders/BatchesProvider/helpers/isEditableCheck.js";

export const useAssignBatchToMod = () => {
    const { currentUser } = useAuth();
    const dispatch = useDispatch();
    const { currentQueue } = useSelector(state => state);

    const { apolloMutate, loading } = useApolloMutation({
        actionDesc: "Assign Batch To Mod",
        gql: ASSIGN_BATCH_TO_MOD
    });

    const assignBatchToMod = async ({ photoBatchId, moderatorId }) => {
        dispatch(batchesSlice.actions.setApiStatusToCalling());

        const { ssResp } = await apolloMutate({
            variables: { photoBatchId, moderatorId }
        });

        const assign = ssResp?.data?.assign;
        const ok = assign?.ok;

        if (ok) {
            const { photoBatch } = assign;
            dispatch(
                messagesSlice.actions.addMessage({
                    text: "Batch Assigned Successfully",
                    timed: true,
                    timeout: 2000,
                    infoType: "success"
                })
            );
            dispatch(
                batchesSlice.actions.setModToBatch({
                    batch: {
                        ...photoBatch,
                        isEditable: isEditableCheck({
                            batch: photoBatch,
                            currentQueue,
                            currentUser
                        })
                    }
                })
            );
        } else {
            const text =
                assign?.errors ||
                "Something went wrong with moderator assignment";
            dispatch(
                messagesSlice.actions.addMessage({
                    text,
                    timed: false,
                    infoType: "error"
                })
            );
            dispatch(batchesSlice.actions.setApiStatusToError());
        }
    };

    return {
        assignBatchToMod,
        assignBatchToModLoading: loading
    };
};
