import React from "react";
import PropTypes from "prop-types";

import { spacingSizes } from "core/props";

import { OuterContainer, Container } from "./styles";

const SectionContainer = ({
    fullWidth,
    bgColor,
    textColor,
    topSpacing = "5xl",
    bottomSpacing = "5xl",
    removePadding = false,
    removeLeftRightPadding = false,
    className,
    "data-testid": dataTestId,
    children,
    style,
    hasOverflowHiddenInMobile = true
}) => {
    return (
        <OuterContainer
            className={className}
            data-testid={dataTestId}
            fullWidth={fullWidth}
            bgColor={bgColor}
            textColor={textColor}
            style={style}
            hasOverflowHiddenInMobile={hasOverflowHiddenInMobile}
        >
            <Container
                fullWidth={fullWidth}
                topSpacing={topSpacing}
                bottomSpacing={bottomSpacing}
                removePadding={removePadding}
                removeLeftRightPadding={removeLeftRightPadding}
            >
                {children}
            </Container>
        </OuterContainer>
    );
};

SectionContainer.propTypes = {
    fullWidth: PropTypes.bool,
    bgColor: PropTypes.string,
    textColor: PropTypes.string,
    topSpacing: PropTypes.oneOf(spacingSizes),
    bottomSpacing: PropTypes.oneOf(spacingSizes),
    removePadding: PropTypes.bool,
    removeLeftRightPadding: PropTypes.bool,
    "data-testid": PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object
};

export default SectionContainer;
