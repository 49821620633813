import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import ProcessingSpinner from "core/ProcessingSpinner";
import {
    reusableComponentSizes,
    circleButtonStyleTypes,
    linkTargets
} from "core/props";

import { CircleButtonElement } from "./styles";

const CircleElement = ({
    className,
    size = "lg",
    styleType = "primary",
    darkMode = false,
    href,
    target = "_self",
    onClick,
    isDisabled = false,
    isProcessing = false,
    isActive = false,
    isToggled = false,
    toggledBgColor,
    toggledIconColor,
    title,
    icon,
    ref
}) => {
    // elementProps decides what link-related props to give to the element based on whether it is a button or an anchor element:
    const elementProps = href
        ? {
              href,
              onClick,
              target
          }
        : {
              onClick: event => {
                  if (isDisabled || isProcessing) return event.preventDefault();
                  return onClick(event);
              }
          };

    return (
        <CircleButtonElement
            as={href ? "a" : "button"}
            className={className}
            aria-disabled={isDisabled || isProcessing}
            size={size}
            styleType={styleType}
            darkMode={darkMode}
            isDisabled={isDisabled}
            isProcessing={isProcessing}
            isActive={isActive}
            isToggled={isToggled}
            toggledBgColor={toggledBgColor}
            toggledIconColor={toggledIconColor}
            ref={ref}
            aria-label={`${isProcessing ? "Processing" : title}`}
            {...elementProps}
        >
            {isProcessing ? <ProcessingSpinner /> : icon}
        </CircleButtonElement>
    );
};

const CircleButton = styled(CircleElement)``;

CircleButton.propTypes = {
    size: PropTypes.oneOf(reusableComponentSizes),
    styleType: PropTypes.oneOf(circleButtonStyleTypes),
    darkMode: PropTypes.bool,
    href: PropTypes.string,
    target: PropTypes.oneOf(linkTargets),
    onClick: PropTypes.func,
    isDisabled: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isActive: PropTypes.bool,
    isToggled: PropTypes.bool,
    toggledBgColor: PropTypes.string,
    toggledIconColor: PropTypes.string,
    title: PropTypes.string.isRequired,
    icon: PropTypes.element.isRequired,
    ref: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.elementType })
    ])
};

export default CircleButton;
