import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import colors from "core/colors";
import { Helmet } from "react-helmet";

import PhotoUploadSectionContainer from "./PhotoUploadSectionContainer";
import PhotoUploadToolbar from "../PhotoUploadToolbar";
import PhotoUploadGrid from "../PhotoUploadGrid";
import Dropzone from "core/Dropzone";
import Edit from "../PhotoUpload/Edit";
import PhotoUploadFooter from "../PhotoUploadFooter";
import PhotoUploadDuplicateFilename from "../PhotoUploadDuplicateFilename";
import LoadingSpinner from "core/LoadingSpinner";

import { useFailedPhotoUploads } from "./hooks";
import useConfirmBrowserNav from "../../../../hooks/useConfirmBrowserNav";
import { usePhotoUpload } from "../../../../hooks/usePhotoUpload";
import { usePhotoUploadWebSocket } from "./hooks";
import usePhotoUploadDropzone from "./hooks/usePhotoUploadDropzone";

import ErrorIcon from "core/ReusableIcons/Error";

const ErrorContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4rem;
    margin-top: 4rem;
    color: ${colors.functional.lightMode.firecracker};
    p {
        margin-top: 1rem;
        font-size: 1rem;
    }
`;

const PhotoUploadLayout = ({ masthead }) => {
    // listen for upload status change events
    usePhotoUploadWebSocket();

    const PhotoUploadMasthead = masthead;

    const {
        editableUploads,
        photoBatchError,
        isCancellingBatch,
        currentlyProcessing
    } = usePhotoUpload();
    const {
        fetchFileCheckFailedUploads,
        allFailedUploadsCount,
        allUploadErrors
    } = useFailedPhotoUploads();
    const { dropzoneProps } = usePhotoUploadDropzone();
    const initialMaximumFiles =
        (dropzoneProps && dropzoneProps.initialMaximumFiles) || 0;
    const currentlyProcessingCount = currentlyProcessing.length;
    const successfulUploadsCount = editableUploads.length;

    const showSpinner = isCancellingBatch;

    useConfirmBrowserNav(currentlyProcessingCount > 0);

    useEffect(() => {
        fetchFileCheckFailedUploads();
    }, []);

    return (
        <>
            <Helmet>
                <title>Submit Photos | Noun Project</title>
                <meta
                    name="description"
                    content="Contribute by submitting your photos to Noun Project. Get started here."
                />
            </Helmet>
            <PhotoUploadToolbar
                uploadCount={successfulUploadsCount}
                failedUploadCount={allFailedUploadsCount}
                currentlyProcessingCount={currentlyProcessingCount}
                initialMaximumFiles={
                    dropzoneProps && dropzoneProps.initialMaximumFiles
                }
            />
            <PhotoUploadSectionContainer>
                {photoBatchError ? (
                    <ErrorContainer>
                        <ErrorIcon
                            size="xl"
                            fillColor={colors.functional.lightMode.firecracker}
                        />
                        <p>Please try again later.</p>
                    </ErrorContainer>
                ) : showSpinner ? (
                    <LoadingSpinner />
                ) : (
                    <>
                        <PhotoUploadMasthead
                            uploadsInDropzoneCount={successfulUploadsCount}
                        />
                        <Dropzone
                            {...dropzoneProps}
                            currentlyProcessingUploads={Boolean(
                                (currentlyProcessing || []).length
                            )}
                        />
                        <PhotoUploadGrid />
                        <Edit />
                    </>
                )}
            </PhotoUploadSectionContainer>
            <PhotoUploadFooter
                uploadCount={successfulUploadsCount}
                failedUploadCount={allFailedUploadsCount}
                currentlyProcessingCount={currentlyProcessingCount}
                initialMaximumFiles={initialMaximumFiles}
                allUploadErrors={allUploadErrors}
            />

            <PhotoUploadDuplicateFilename />
        </>
    );
};

PhotoUploadLayout.propTypes = {
    masthead: PropTypes.func.isRequired
};

export default PhotoUploadLayout;
