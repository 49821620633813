import React, { useContext } from "react";

// Context
import { SlideshowContext } from "./index.js";

// styles
import { CounterStyled } from "./styles.js";

const Counter = () => {
    const { state } = useContext(SlideshowContext);
    const { showCounter } = state;

    const { components, totalSlides, current } = state;

    const currentSlide = components.find(c => c.id === current);

    if (!showCounter) {
        return null;
    }

    return (
        <CounterStyled>
            {currentSlide.slideNumber} / {totalSlides}
        </CounterStyled>
    );
};

export default Counter;
