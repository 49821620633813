import styled, { css } from "styled-components";

// styles
import { convertPxToRem } from "frontend/components/helpers";

// components
import { Image as CaretRightStyled } from "core/ReusableIcons/CaretRight";

// styled-components
export const BatchHeaderStyled = styled.div`
    background-color: ${({ backgroundColor }) => backgroundColor};
    min-height: ${convertPxToRem({ px: 106 })};

    display: grid;
    gap: ${convertPxToRem({ px: 18 })};
    grid-template-columns: ${convertPxToRem({ px: 68 })} auto;

    /* We need to absolute position child elements against this container (e.g. suspended icon)*/
    position: relative;

    /* collapsing when deleting animation */
    max-height: 300px;
    transition: max-height ease-in-out 0.5s;

    ${({ batch }) => {
        if (batch.isCollapsed) {
            return css`
                min-height: 0px;
                max-height: 0px;
                overflow: hidden;
            `;
        }
    }}
`;

// 68 pixels wide
export const BatchToggleStyled = styled.div``;
export const BatchToggleButtonStyled = styled.button`
    padding: 16px 0px 16px 24px;
    background: transparent;
    border: none;

    display: block;
    display: flex;
    align-items: flex-start;

    width: 100%;
    height: 100%;

    cursor: pointer;
`;
export const BatchToggleCaretStyled = styled.div`
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background-color: black;

    display: flex;
    justify-content: center;
    align-items: center;

    transition: 0.25s transform ease-in-out;
    transform: ${({ batchContentIsOpen }) =>
        batchContentIsOpen ? `rotate(90deg)` : `rotate(0)`};

    ${CaretRightStyled} {
        path {
            fill: white;
        }
    }
`;

export const BatchProfileStyled = styled.div`
    padding: 16px 0px;
`;
