/* -------------------------------------------------------------------------- */
/*                           USED BY ALL PAGES                                */
/* -------------------------------------------------------------------------- */
export const licenseType = "subscription";

export const subscribeURL = "/subscribe/pro/";
export const subscribeTeamURL = "/subscribe/team/";
export const MonthlyIconSubscription = "Monthly Icon Subscription";
export const YearlyIconSubscription = "Yearly Icon Subscription";

export const checkoutFormHeader = "Subscription Options";

export const perMonth = "/month";
export const perYear = "/year";

export const svgSizes = {
    width: 60,
    height: 60
};

export const disclaimerPayPal =
    "You will be redirected to the PayPal site to complete your payment. After payment, you will be redirected back to Noun Project to complete your order.";

export const disclaimerTextMonthly =
    "You will be charged now and monthly when your account automatically renews. Cancel automatic renewal at any time.";

export const disclaimerTextYearly =
    "You will be charged now and yearly when your account automatically renews. Cancel automatic renewal at any time.";

/* -------------------------------------------------------------------------- */
/*                           Icon Pro                                          */
/* -------------------------------------------------------------------------- */

export const PRO_MONTHLY_PLAN = "pro-monthly";
export const PRO_YEARLY_PLAN = "pro-yearly";

// Icon Pro Confirmation Heading
export const nounProConfirmationHeading =
    "Thanks for Your Icon Pro Subscription";

/* -------------------------------------------------------------------------- */
/*                           Icon Pro for EDU                                  */
/* -------------------------------------------------------------------------- */

export const nounProEduPageTitle = "Get Started with Icon Pro for Education";
export const EDU_PRO_YEARLY_PLAN = "edu-yearly";

export const nounProEduConfirmationHeading =
    "Thanks for Your Icon Pro for Education Subscription";

/* -------------------------------------------------------------------------- */
/*                           Icon Pro for Teams                                */
/* -------------------------------------------------------------------------- */

export const TEAM_YEARLY_PLAN = "team-yearly";
export const TEAM_MONTHLY_PLAN = "team-monthly";
export const perUserPerYear = "/member/year";
export const perUserPerMonth = "/member/month";

/* -------------------------------------------------------------------------- */
/*                           PROMO CODE                                       */
/* -------------------------------------------------------------------------- */

export const toolTipMsg = "Remove promo code";
