import styled, { css } from "styled-components";

// styles
import { convertPxToRem } from "frontend/components/helpers";

// styled-components

export const CounterStyled = styled.div`
    position: absolute;
    bottom: 0px;
    right: 0px;
    z-index: 10;
    opacity: 0;

    padding: 20px;

    background-color: rgba(0 0 0 / 0.2);

    transition: opacity 0.5s ease-in-out;
`;

export const SlideshowStyled = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;

    &:hover {
        ${CounterStyled} {
            opacity: 1;
        }
    }
`;

export const SlideListStyled = styled.div`
    position: relative;
    z-index: 1;
    overflow: hidden;
    width: 100%;
    height: 100%;
`;

const sharedButtonStyles = css`
    background-color: transparent;
    border: none;
    outline: none;
    color: white;
    font-size: ${convertPxToRem(40)};
    cursor: pointer;
    padding: ${convertPxToRem(10)};
    flex: 0 0 ${convertPxToRem(70)};
    display: flex;
    justify-content: center;
    align-items: center;

    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
`;

export const PrevButtonStyled = styled.button`
    ${sharedButtonStyles}
    left: 0px;
`;
export const NextButtonStyled = styled.button`
    ${sharedButtonStyles}
    right: 0px;
`;

export const SlideStyled = styled.div`
    position: absolute;
    z-index: -1;
    left: 100%;
    top: 0px;
    bottom: 0px;

    transition: left 250ms ease-in;
    width: 100%;
    height: 100%;

    background-color: white;

    display: flex;
    justify-content: center;
    align-items: center;

    &.currentSlide {
        left: 0%;
        z-index: 10;
    }
    &.nextSlide {
        left: 100%;
        z-index: 9;
    }
    &.previousSlide {
        left: -100%;
        z-index: 8;
    }
`;
