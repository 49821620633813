import cleanPreviewIcon from "../helpers/clean_svg_string";
import localStorage from "../legacy/helpers/localStorage";

/**
 * Logs all actions and states after they are dispatched.
 */

let placeAdobeIcon = function(assetData, imgSize, imgType, icon) {
    let csInterface = new CSInterface(),
        tempSVGPath =
            // SystemPath comes from the Adobe plugin environment.
            // eslint-disable-next-line no-undef
            csInterface.getSystemPath(SystemPath.USER_DATA) +
            "/com.thenounproject.nounPanel/",
        imagePath = `${tempSVGPath}${icon.term_slug}_${icon.id}.${imgType}`,
        result;
    window.cep.fs.makedir(tempSVGPath);

    if (imgType === "svg")
        assetData = btoa(unescape(encodeURIComponent(assetData)));

    result = window.cep.fs.writeFile(
        imagePath,
        assetData,
        window.cep.encoding.Base64
    );

    if (0 == result.err) {
        var adobeAgent, script;
        script =
            "if (BridgeTalk.appName == 'photoshop') { var actDesc = new ActionDescriptor(); actDesc.putPath(charIDToTypeID('null'), new File(decodeURI('" +
            imagePath +
            "'))); actDesc.putEnumerated( charIDToTypeID('FTcs'), charIDToTypeID('QCSt'), charIDToTypeID('Qcsa')); executeAction(charIDToTypeID('Plc '), actDesc, DialogModes.NO); } else if(BridgeTalk.appName == 'illustrator') { var doc = app.activeDocument; var svgFile = new File('" +
            imagePath +
            "'); doc.groupItems.createFromFile(svgFile); var item = doc.pageItems[0];var imageSize = '" +
            imgSize +
            "'; if(item.width > item.height){var longerDimension = item.width }else{var longerDimension = item.height}; var scale = parseInt((imageSize / longerDimension * 100)); item.resize(scale,scale,true, true,true,true,scale,undefined); } else if(BridgeTalk.appName == 'indesign') { var userDefinedSize = " +
            imgSize +
            "; app.scriptPreferences.measurementUnit = MeasurementUnits.PIXELS; var page = app.activeWindow.activePage, pageWidth = page.bounds[3] - page.bounds[1], pageHeight = page.bounds[2] - page.bounds[0], containerFrame = page.rectangles.add(); if( userDefinedSize > pageWidth || userDefinedSize > pageHeight ){if( pageWidth > pageHeight ){ userDefinedSize = pageHeight; }else{ userDefinedSize = pageWidth; };}; var newX = (pageHeight / 2) - (userDefinedSize / 2), newY = (pageWidth / 2) - (userDefinedSize / 2); containerFrame.geometricBounds = [newX,newY,newX + userDefinedSize,newY + userDefinedSize];var pdfFile = File('" +
            imagePath +
            "'); containerFrame.place(pdfFile); containerFrame.graphics[0].itemLink.unlink(); containerFrame.fit(FitOptions.PROPORTIONALLY); }";
        csInterface.evalScript(script, function(r) {
            console.log(r);
            window.cep.fs.deleteFile(imagePath);
        });
    } else {
        console.log("file not written");
    }
};

const plugin = store => next => action => {
    let imgSize, selectedColor;

    switch (action.type) {
        case "PLACE_ICON":
            let active_icon = store.getState().icons.active_icon;
            imgSize = parseInt(store.getState().icons.imgChoices.selectedSize);
            selectedColor = store
                .getState()
                .icons.selectedColor.hex.replace("#", "");

            switch (action.type) {
                case "PLACE_ICON": // for adobe plugin
                    // place the svg data (used by adobe)

                    assetData = action.assetData;
                    placeAdobeIcon(assetData, imgSize, "svg", action.icon);
                    break;
            }
            break;
        case "FETCH_PDF_DATA_SUCCESS":
            let assetData = action.response.encoded_pdf;

            selectedColor = store
                .getState()
                .icons.selectedColor.hex.replace("#", "");

            return placeAdobeIcon(
                assetData,
                action.imgSize,
                "pdf",
                action.icon
            );
            break;
        case "PLACE_SVG_FROM_ICONLIST_SUCCESS":
            // Despite the name, this action fires when the SVG is downloaded.
            // It also fires for both the list and detail views.
            selectedColor = action.selectedColor.hex
                ? action.selectedColor.hex
                : "#000000";
            let cleanedSVG = cleanPreviewIcon(
                action.response,
                selectedColor,
                action.imgSize
            );

            placeAdobeIcon(cleanedSVG, parseInt(imgSize), "svg", action.icon);
            break;
        case "SET_ICON_COLOR_PALETTE":
            // set local storage
            if (Array.isArray(action.color.customColors)) {
                if (action.color.customColors.length) {
                    localStorage.setItem(
                        "customColorPalette",
                        JSON.stringify(action.color.customColors)
                    );
                }
                localStorage.setItem(
                    "selectedColor",
                    JSON.stringify(action.color.selectedColor)
                );
            } else {
                localStorage.removeItem("customColorPalette");
                localStorage.removeItem("selectedColor");
            }

            if (action.color.selectedColor === undefined) {
                localStorage.removeItem("selectedColor");
            }
            break;
        case "GET_PNG_URL_SUCCESS":
            // gsuite

            let color = action.color,
                query,
                size = action.imgSize;

            if (color !== undefined && size !== undefined) {
                query = `?color=${action.color}&dimensions=${action.imgSize}`;
            } else if (color === undefined && size !== undefined) {
                query = `?dimensions=${action.imgSize}`;
            } else if (color !== undefined && size === undefined) {
                query = `?color=${action.color}&dimensions=200`;
            }

            window.parent.postMessage(
                {
                    action: "insertIcon",
                    url: `${action.response.url}${query}`
                },
                "*"
            );
            break;
    }

    return next(action);
};

export default plugin;
