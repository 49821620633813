import React from "react";

import { AuthButton } from "../styles";

import { openSocialAuth } from "frontend/hooks/useAuth";

const FacebookAuthButton = ({ children }) => {
    return <AuthButton onClick={openSocialAuth}>{children}</AuthButton>;
};

export default FacebookAuthButton;
