import React from "react";
import styled from "styled-components";

// context
import { useSiteHeader } from "core/SiteHeaderV2/context/useSiteHeader.js";

// links:
import { homePath } from "data/links";

// components
import TNPLogoTypeSVG from "core/TNPLogoType";

// styles
import { focusColor } from "core/colors";
import { convertPxToRem } from "frontend/components/helpers";
import { easingSeconds } from "core/animation";
const TNPLogoTypeStyled = styled.a`
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    position: relative;
    top: ${convertPxToRem(2.5)};

    svg {
        transition: opacity ${easingSeconds};
    }
    &:hover svg {
        opacity: 0.6;
    }
    &:focus {
        border: none;
        outline: none;
        svg {
            outline: solid ${convertPxToRem(4)} ${focusColor};
        }
    }
`;

// component
export const TNPLogoType = () => {
    const { isDarkMode } = useSiteHeader();
    return (
        <TNPLogoTypeStyled href={homePath.url}>
            <TNPLogoTypeSVG isDarkMode={isDarkMode} />
        </TNPLogoTypeStyled>
    );
};

export default TNPLogoType;
