import React from "react";

// components
import TextWithPopOutLink from "./TextWithPopOutLink";

const VisuallyApproved = props => {
    return (
        <TextWithPopOutLink
            title="Vis Appr"
            titleFull="Visually Approved"
            width={72}
            {...props}
        />
    );
};

export default VisuallyApproved;
