import { useApolloLazyQuery } from "frontend/utils/apolloApi/useApolloLazyQuery.js";

import { FETCH_BATCH_UPLOAD_LARGE_IMAGE_URLS } from "frontend/components/fort/mod/photos/gql/queries";

export const useFetchBatchUploadLargeImageUrls = () => {
    const { apolloQuery } = useApolloLazyQuery({
        actionDesc: "Fetch Batch Upload Large Image Urls",
        gql: FETCH_BATCH_UPLOAD_LARGE_IMAGE_URLS
    });

    const fetchBatchUploadLargeImageUrls = async batchId => {
        const { ssResp } = await apolloQuery({
            variables: { batchId },
            fetchPolicy: "network-only"
        });

        return ssResp?.data?.list?.items || [];
    };

    return {
        fetchBatchUploadLargeImageUrls
    };
};
