/**
 * enhanceUploads: Frontend Specific Transformation of Uploads
 * @param {Array} uploads
 * @param {Object} batchEntity
 * @returns {Array} uploads transformed
 */
export const enhanceUploads = (uploads, batchEntity) => {
    const creatorIsPremium = batchEntity?.creator?.isPremiumPhotographer;
    return uploads.map(upload => ({
        ...upload,
        creatorIsPremium,

        // TODO: should we remove this and alter at shared /fort/core implentations?
        title: upload.photoTitle,
        description: upload.photoDescription,
        location: upload.photoLocation,
        batchId: upload.photoBatchId
    }));
};
