import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

// RTK
import { batchUploadCollectionsSlice } from "../../slices/batchUploadCollectionsSlice";

// hooks
import { useBatchUploads } from "../../hooks";
import { useFetchBatchUploads } from "frontend/components/fort/mod/photos/hooks/queries/uploads/useFetchBatchUploads.js";

// core components
import { BatchBody } from "frontend/components/fort/mod/core/Batch";

// components
import PhotoBatchGrid from "./PhotoBatchGrid";

const PhotoModBatchBody = ({ batch, batchContentIsOpen }) => {
    // redux
    const dispatch = useDispatch();
    const { uploads } = useSelector(state => state);

    // hooks
    const { batchUploadCollections, allBatchUploadIds } = useBatchUploads();
    const { fetchBatchUploads } = useFetchBatchUploads();

    // props
    const { status: uploadsStatus } = uploads;
    const { id } = batch;

    // side effects
    useEffect(() => {
        if (batchContentIsOpen) {
            fetchBatchUploads();
        }
    }, [batchContentIsOpen, id]);

    const setActiveSaveView = (bucId, view) => {
        dispatch(
            batchUploadCollectionsSlice.actions.setBatchUploadCollectionView({
                bucId,
                view
            })
        );
    };

    if (!batchContentIsOpen) return null;

    return (
        <BatchBody
            batch={batch}
            batchContentIsOpen={batchContentIsOpen}
            batchUploadCollections={batchUploadCollections}
            allBatchUploadIds={allBatchUploadIds}
            BatchGridComponent={PhotoBatchGrid}
            uploadsStatus={uploadsStatus}
            setActiveSaveView={setActiveSaveView}
        />
    );
};

// prop-types
PhotoModBatchBody.propTypes = {
    batch: PropTypes.object.isRequired,
    batchContentIsOpen: PropTypes.bool
};

export default PhotoModBatchBody;
