import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import colors from "core/colors";

const createHelpers = (width, height, css) => {
    // somehow sizes is ending up in markup, even if it is not a valid svg attribute
    // until we have a better solution, just render it empty, instead to '[Object object]'
    const sanitizeSizes = sizes =>
        Object.defineProperty(sizes, "toString", {
            value: () => "",
            enumerable: false
        });

    const getDimensions = (size, sizes) => {
        if (
            size &&
            typeof size.width === "number" &&
            typeof size.height === "number"
        ) {
            return size;
        }

        return size && sizes[size] ? sizes[size] : { width, height };
    };

    const getCss = (size, sizes, fillColor, fillColorRule, noStyles) => {
        if (noStyles) {
            return "";
        }

        const dimensions = getDimensions(size, sizes);
        const fillRule =
            fillColor && fillColorRule
                ? `${fillColorRule}{ fill: ${fillColor}; }`
                : "";

        return css`
            width: ${dimensions.width}px;
            height: ${dimensions.height}px;
            ${fillRule}
        `;
    };

    const propsToCss = ({ size, sizes, fillColor, fillColorRule, noStyles }) =>
        getCss(size, sizes, fillColor, fillColorRule, noStyles);

    return {
        getCss,
        getDimensions,
        propsToCss,
        sanitizeSizes
    };
};

const width = "24";
const height = "24";
const viewBox = "0 0 24 24";

const { getDimensions, getCss, propsToCss, sanitizeSizes } = createHelpers(
    width,
    height,
    css
);

const sizes = sanitizeSizes({
    xs: { width: 14, height: 14 },
    sm: { width: 16, height: 16 },
    md: { width: 20, height: 20 },
    lg: { width: 24, height: 24 },
    xl: { width: 40, height: 40 }
});

const Image = styled.svg`
    ${propsToCss}
`;

const children = (
    <Fragment>
        <path
            fill={colors.secondary.orca}
            fillRule="evenodd"
            d="M15.947 5.78c.94-.417 1.662-1.088 2.289-1.88.396-.497.653-.82.747-1.05a.27.27 0 00-.082-.304.28.28 0 00-.1-.053c-4.15-1.263-5.348.216-5.691.933a1.678 1.678 0 00-.09.337 1.9 1.9 0 00.195 1.154c.5 1 1.622 1.356 2.732.863zm-.195 14.982c2.688-1.724 4.261-4.236 4.697-7.469a5.479 5.479 0 00-.327-2.742 5.614 5.614 0 00-1.63-2.258 5.815 5.815 0 00-2.538-1.227 5.9 5.9 0 00-2.83.101c-.308.087-.383.185-.418.173-.026-.01-.03-.083-.095-.274-.438-1.297-1.562-2.697-2.519-3.673l-.069-.072c-.228-.24-.465-.49-.836-.168-.41.354-.907 1.02-.677 1.267.216.234.424.432.622.62.584.558 1.072 1.024 1.382 2.026a4.993 4.993 0 01-.424-.096c-.085-.023-.088-.024-.42-.048-3.749-.264-6.774 3.097-6.067 6.821.554 2.877 1.973 5.165 4.287 6.806A6.072 6.072 0 0011.94 22a6.162 6.162 0 003.812-1.237z"
            clipRule="evenodd"
            key="key-0"
        />
    </Fragment>
);

const defaultProps = {
    children,
    viewBox,
    fillColor: null,
    fillColorRule: "&&& path, &&& use, &&& g, &&& circle",
    sizes,
    size: null
};

Image.propTypes /* remove-proptypes */ = {
    fillColor: PropTypes.string,
    fillColorRule: PropTypes.string,
    viewBox: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    size: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            height: PropTypes.number.isRequired,
            width: PropTypes.number.isRequired
        })
    ]),
    sizes: PropTypes.shape({
        height: PropTypes.number,
        width: PropTypes.number
    })
};

export default Object.assign(Image, {
    getDimensions,
    getCss,
    defaultProps,
    displayName: "Edu"
});
