import styled, { css } from "styled-components";

// styles
import colors from "core/colors";
import { convertPxToRem } from "frontend/components/helpers";
import { regular as regularFontWeight } from "core/fontStyles";

// components
import CheckboxButton from "frontend/components/core/CheckboxButton/index.js";
import { Label } from "frontend/components/core/CheckboxButton/styles.js";

// styled-components
export const ActionBarStyled = styled.div`
    display: grid;
    grid-template-columns: 280px auto;
    gap: 36px;

    padding: ${convertPxToRem(20)} ${convertPxToRem(24)} 0px;
`;

export const ActionBarLeftStyled = styled.div`
    height: ${convertPxToRem(28)};
`;
export const ActionBarRightStyled = styled.div`
    height: ${convertPxToRem(28)};
    display: grid;
    grid-template-columns: auto 210px 363px;
    gap: ${convertPxToRem(32)};
`;

export const SelectAllAndMessagingStyled = styled.div`
    ${({ showSelectAllCheckbox }) => {
        if (showSelectAllCheckbox) {
            return css`
                display: grid;
                grid-template-columns: 56px auto;
                gap: ${convertPxToRem(16)};
            `;
        }
    }}
`;

export const CheckboxButtonStyled = styled(CheckboxButton)`
    ${Label} {
        font-size: ${convertPxToRem(12)};
        padding: ${convertPxToRem(5)} ${convertPxToRem(8)};
        height: auto;
        text-align: center;
        border-width: ${convertPxToRem(1)};
    }
`;
export const MessageStyled = styled.span`
    display: inline-block;
    line-height: ${convertPxToRem(28)};
    ${regularFontWeight};
    font-size: ${convertPxToRem(15)};
    color: ${colors.secondary.orca};
`;
