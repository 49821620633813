import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// RTK
import { uploadsSlice } from "frontend/components/fort/mod/photos/slices/uploadsSlice";

// components
import ExpandedModal from "frontend/components/fort/mod/core/ExpandedModal";
import PhotoModSingleExpandedUpload from "../PhotoModSingleExpandedUpload";
import PhotoModSlideshowUploads from "../PhotoModSlideshowUploads";
import UploadManager from "frontend/components/fort/mod/photos/components/PhotoModUpload/UploadManager.js";
import GalleryUploads from "frontend/components/fort/mod/core/ExpandedModal/GalleryUploads";

// hooks
import { useBatchUploads } from "../../hooks";
import { useFetchBatchUploadLargeImageUrls } from "../../hooks/queries/useFetchBatchUploadLargeImageUrls";
import { useSelect } from "frontend/components/core/Grid2/GridItemContentTemplates/Select/SelectContext/index.js";
import { useLargeImageView } from "../../hooks/useLargeImageView";

// utils
import * as Utils from "./utils";

const PhotoModExpandedModal = () => {
    // RTK
    const dispatch = useDispatch();
    const largeImageView = useSelector(state => state.largeImageView);
    const { openLargeView } = useLargeImageView();
    const selectedBatch = useSelector(state => state.selectedBatch);
    const { id: selectedBatchId } = selectedBatch;
    const { uploadsSelected = {}, getSelectedUploadIds } = useSelect();
    const selectedUploadIds = getSelectedUploadIds(uploadsSelected);

    // hooks
    const { visibleUploadEntities } = useBatchUploads();
    const {
        fetchBatchUploadLargeImageUrls
    } = useFetchBatchUploadLargeImageUrls();

    // props
    const hasMultipleUploadsSelected = selectedUploadIds.length > 1;

    // Determine verbiage based on gallery and number of uploads selected
    const modalTitle = hasMultipleUploadsSelected
        ? `Viewing ${selectedUploadIds.length} selected photos`
        : `Viewing selected photo`;

    // methods
    const evaluateLargeImageUrls = async () => {
        let shouldRehydrate = false;
        visibleUploadEntities.forEach(uploadEntity => {
            const { uploadedImageUrl } = uploadEntity;

            // if uploads do not have large URL prop or their timestamp has expired
            if (
                !uploadedImageUrl ||
                Utils.hasImageUrlExpired(uploadedImageUrl)
            ) {
                shouldRehydrate = true;
            }
        });

        if (shouldRehydrate) {
            dispatch(uploadsSlice.actions.setLargeUrlsAreLoading(true));

            // FETCH LARGE URL UPLOADS
            const newUploads = await fetchBatchUploadLargeImageUrls(
                selectedBatchId
            );

            // Augment Uploads with large URLs, and setLargeUrlsAreLoading to false
            dispatch(uploadsSlice.actions.setLargeUrls(newUploads));
        }
    };

    // side effects
    useEffect(() => {
        if (largeImageView.isActive) {
            evaluateLargeImageUrls();
            openLargeView();
        }
    }, [largeImageView.isActive]);

    // render
    if (!selectedBatchId) return null;

    let modalContent = null;
    switch (largeImageView.kind) {
        case "gallery":
            modalContent = (
                <GalleryUploads
                    uploadIds={largeImageView.uploadIds}
                    UploadGraphicComponent={props => (
                        <UploadManager {...props} />
                    )}
                />
            );
            break;
        default:
            modalContent = (
                <PhotoModSlideshowUploads
                    uploadIds={largeImageView.uploadIds}
                    defaultId={largeImageView.selectedUploadId}
                />
            );
            break;
    }

    return <ExpandedModal title={modalTitle}>{modalContent}</ExpandedModal>;
};

export default PhotoModExpandedModal;
