import React from "react";
import PropTypes from "prop-types";

// Modal2
import { Shadow, Light } from "core/Modal2";

const ExpandedModal = ({ title, children }) => {
    return (
        <Shadow left="0px" right="268px" top="68px" bottom="0px">
            <Light title={title} isFullScreen={true}>
                {children}
            </Light>
        </Shadow>
    );
};

export default ExpandedModal;

// prop-types
ExpandedModal.propTypes = {
    title: PropTypes.string.isRequired
};
