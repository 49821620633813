import React, { useState, useEffect } from "react";

import { useReleases } from "../PhotoUpload/Releases/hooks/useReleases";

import ManageReleasesModal from "../PhotoUpload/Releases/ManageReleasesModal";
import { ToolbarButton } from "./styles";

function determineReleaseButtonTitle(count) {
    if (count === 0) {
        return "Add or Manage Releases";
    } else if (count === 1) {
        return `Add or Manage 1 Release`;
    } else {
        return `Add or Manage ${count} Releases`;
    }
}

const ManageReleasesButton = () => {
    const [manageReleaseModalIsOpen, setManageReleaseModalIsOpen] = useState(
        false
    );

    const { releases, getReleases } = useReleases();

    useEffect(() => {
        getReleases();
    }, []);

    const closeModal = () => {
        setManageReleaseModalIsOpen(false);
    };

    return (
        <>
            <ToolbarButton onClick={() => setManageReleaseModalIsOpen(true)}>
                {determineReleaseButtonTitle(releases.length)}
            </ToolbarButton>
            <ManageReleasesModal
                onClose={closeModal}
                isModalOpen={manageReleaseModalIsOpen}
            />
        </>
    );
};

export default ManageReleasesButton;
