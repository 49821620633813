import { configureStore, combineReducers } from "@reduxjs/toolkit";

// Batches/Uploads
import batchesReducer from "./slices/batchesSlice";
import selectedBatchReducer from "./slices/selectedBatchSlice";
import batchUploadCollectionsReducer from "./slices/batchUploadCollectionsSlice/index.js";
import uploadsReducer from "./slices/uploadsSlice";
import uploadApprovalReducer from "./slices/uploadApprovalSlice";
import uploadFooterActionsReducer from "./slices/uploadFooterActionsSlice";

// Misc
import currentQueueReducer from "./slices/currentQueueSlice";
import largeImageViewReducer from "./slices/largeImageViewSlice";
import animationsSliceReducer from "./slices/animationsSlice";
import messagesSliceReducer from "./slices/messagesSlice";
import websocketSynchQueueSliceReducer from "./slices/websocketSynchQueueSlice";

// Middleware
import { getMiddleware } from "./middleware/index.js";

import { REDUCERS } from "./constants";

export const photoModReducer = combineReducers({
    // Batches / Uploads
    [REDUCERS.BATCHES]: batchesReducer,
    [REDUCERS.SELECTED_BATCH]: selectedBatchReducer,
    [REDUCERS.BATCH_UPLOAD_COLLECTIONS]: batchUploadCollectionsReducer,
    [REDUCERS.UPLOADS]: uploadsReducer,
    [REDUCERS.UPLOAD_APPROVAL]: uploadApprovalReducer,
    [REDUCERS.UPLOAD_FOOTER_ACTIONS]: uploadFooterActionsReducer,

    // misc
    [REDUCERS.CURRENT_QUEUE]: currentQueueReducer,
    [REDUCERS.LARGE_IMAGE_VIEW]: largeImageViewReducer,
    [REDUCERS.ANIMATIONS]: animationsSliceReducer,
    [REDUCERS.MESSAGES]: messagesSliceReducer,
    [REDUCERS.WEBSOCKET_SYNCH_QUEUE]: websocketSynchQueueSliceReducer
});

const photoModStore = configureStore({
    reducer: photoModReducer,
    middleware: getMiddleware,
    devTools: {
        name: "TNP Mod Photo Store"
    }
});

export default photoModStore;
