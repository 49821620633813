import React from "react";

import { socialMedia } from "data/links";

import colors from "core/colors";
import Facebook from "core/ReusableIcons/Facebook";
import Twitter from "core/ReusableIcons/Twitter";
import Behance from "core/ReusableIcons/Behance";
import Dribbble from "core/ReusableIcons/Dribbble";
import Instagram from "core/ReusableIcons/Instagram";

import { A } from "core/Typography";
import { Socials as StyledSocials, SocialsItem } from "./styles";

const iconComponent = {
    Facebook,
    Twitter,
    Behance,
    Dribbble,
    Instagram
};

export const Socials = () => (
    <StyledSocials>
        {socialMedia.map(link => {
            const { name, href } = link;

            const Icon = iconComponent[name];

            return (
                <SocialsItem key={name}>
                    <A href={href} alt={`Noun Project ${name}`} target="_blank">
                        <Icon
                            title={`${name} logo`}
                            fillColor={colors.primary.diamond}
                        />
                    </A>
                </SocialsItem>
            );
        })}
    </StyledSocials>
);

// TODO: update how icon component is being rendered
