import * as actionTypes from "../actions/actionTypes";
import filter from "lodash/filter";
import pickBy from "lodash/pickBy";
import localStorage from "../legacy/helpers/localStorage";
import { ToastTypes } from "../constants/toastTypes";

let bannersKey = "banners.dismissed";

export default (state = { all: [], dismissed: null, toasts: {} }, action) => {
    if (!state.dismissed) {
        let dismissed;

        try {
            dismissed = localStorage.getItem(bannersKey);
            dismissed = JSON.parse(dismissed);
        } catch (e) {}
        dismissed = dismissed || [];
        state = {
            ...state,
            dismissed
        };
    }

    switch (action.type) {
        case actionTypes.SET_GLOBAL_CONFIG: {
            let { banners } = action.config;
            let { dismissed } = state;
            let all = pickBy(
                banners,
                (showing, bannerType) =>
                    showing &&
                    !dismissed.includes(bannerType) &&
                    !ToastTypes[bannerType]
            );

            return {
                ...state,
                all
            };
        }
        case actionTypes.BANNER_DISMISS: {
            let { bannerType } = action;

            let { all, dismissed, toasts } = state;
            all = filter(all, (v, type) => type != bannerType);
            toasts = {
                ...toasts,
                [bannerType]: false
            };
            dismissed = dismissed.slice();
            dismissed.push(bannerType);
            try {
                localStorage.setItem(bannersKey, JSON.stringify(dismissed));
            } catch (e) {}
            return {
                ...state,
                all,
                toasts,
                dismissed,
                processing: true
            };
        }
        case actionTypes.BANNER_SHOW_TOAST: {
            let { bannerType } = action;
            let { toasts } = state;

            if (!ToastTypes[bannerType]) {
                console.warn(`${bannerType} toast not found.`);
            }

            toasts = {
                ...toasts,
                [bannerType]: true
            };

            return {
                ...state,
                toasts
            };
        }
        case actionTypes.BANNER_DISMISS_FAILURE:
        case actionTypes.BANNER_DISMISS_SUCCESS: {
            return {
                ...state,
                processing: false
            };
        }
        default:
            return state;
            break;
    }

    // It looks like this line is unreachable anyhow.
    // eslint-disable-next-line no-undef
    return Object.assign({}, state, nextState);
};
