import React from "react";
import PropTypes from "prop-types";

import { Label, LabelText, ErrorContainer } from "./styles";

import { spacingSizes } from "core/props";

const FormElementContainer = ({
    className,
    isFocused = false,
    labelText = "",
    errorMessage = "",
    width = "100%",
    bottomSpacing = "none",
    darkMode = false,
    hideLabel = false,
    children
}) => {
    const showLabelText = !hideLabel && !!labelText;

    return (
        <Label
            className={className}
            width={width}
            bottomSpacing={bottomSpacing}
        >
            {!!showLabelText && (
                <LabelText darkMode={darkMode}>{labelText}</LabelText>
            )}
            {children}
            <ErrorContainer
                showError={!isFocused && !!errorMessage}
                darkMode={darkMode}
            >
                {errorMessage}
            </ErrorContainer>
        </Label>
    );
};

FormElementContainer.propTypes = {
    isFocused: PropTypes.bool,
    labelText: PropTypes.string,
    errorMessage: PropTypes.string,
    width: PropTypes.string,
    bottomSpacing: PropTypes.oneOf(spacingSizes),
    darkMode: PropTypes.bool,
    hideLabel: PropTypes.bool
};

export default FormElementContainer;
