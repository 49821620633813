import * as actionTypes from "../actions/actionTypes";
import { sendPageView, sendEvent } from "../utils/google-analytics";

const analytics = store => next => action => {
    switch (action.type) {
        case actionTypes.LOGIN_SUCCESS:
            sendPageView("/accounts/login/success/");
            break;

        case actionTypes.SIGNUP_SUCCESS:
            sendPageView("/accounts/signup/success/");
            break;

        case actionTypes.UPGRADE_BILLING_SUCCESS:
            sendPageView("/accounts/upgrade/success/");
            break;

        case actionTypes.SET_SEARCH_QUERY:
            sendPageView("/search/?q=" + action.query);
            break;

        case actionTypes.REPORT_PLUGIN_USAGE:
            sendEvent("icon", "placed");
            break;
    }

    return next(action);
};

export default analytics;
