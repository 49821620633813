import gql from "graphql-tag";

/*---------------------------
| Photo Shoot
---------------------------*/
export const APPROVE_PHOTO_SHOOT_TITLE = gql`
    mutation approvePhotoShootTitle($photoShootId: ID!) {
        approvePhotoShootTitle(photoShootId: $photoShootId) {
            errors
            ok
            photoShoot {
                id
                title
                titleApproved
            }
        }
    }
`;

export const REJECT_PHOTO_SHOOT_TITLE = gql`
    mutation rejectPhotoShootTitle($photoShootId: ID!, $title: String) {
        rejectPhotoShootTitle(photoShootId: $photoShootId) {
            errors
            ok
            photoShoot {
                id
                title
                titleApproved
            }
        }
        editPhotoShoot(photoShootId: $photoShootId, title: $title) {
            ok
            errors
            photoShoot {
                id
                title
                titleApproved
            }
        }
    }
`;

// If Moderator edits photo shoot title, they are also approving it
export const UPDATE_AND_APPROVE_PHOTO_SHOOT_TITLE = gql`
    mutation editPhotoShoot($photoShootId: ID!, $title: String) {
        editPhotoShoot(photoShootId: $photoShootId, title: $title) {
            ok
            errors
            photoShoot {
                id
                title
                titleApproved
            }
        }
        approvePhotoShootTitle(photoShootId: $photoShootId) {
            errors
            ok
            photoShoot {
                id
                title
                titleApproved
            }
        }
    }
`;
