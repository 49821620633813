import { useApolloMutation } from "frontend/utils/apolloApi/useApolloMutation.js";
import { MESSAGING } from "frontend/components/fort/mod/photos/constants";

// GQL
import { UPDATE_AND_APPROVE_PHOTO_UPLOAD_DESCRIPTION } from "frontend/components/fort/mod/photos/gql/mutations";

const errorHandler = normalizedResp => {
    const { message, errors } = normalizedResp;
    let newMessage = message;

    if (errors.includes("Submitted value contains disallowed terms")) {
        newMessage = MESSAGING.errors.containsDisallowedTerms("description");
    }

    return {
        ...normalizedResp,
        message: newMessage
    };
};

export const useUpdateAndApprovePhotoUploadDescription = () => {
    const { apolloMutate, loading } = useApolloMutation({
        actionDesc: "Update and Approve Photo Upload Description",
        gql: UPDATE_AND_APPROVE_PHOTO_UPLOAD_DESCRIPTION,
        errorHandler
    });

    const updateAndApprovePhotoUploadDescription = async variables => {
        return await apolloMutate({ variables });
    };

    return {
        updateAndApprovePhotoUploadDescription,
        updateAndApprovePhotoUploadDescriptionLoading: loading
    };
};
