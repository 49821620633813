import React from "react";

// components
import Row1 from "./Layout/Row1/index";
import Row2 from "./Layout/Row2/index";

// component
export const Mobile = () => {
    return (
        <>
            <Row1 />
            <Row2 />
        </>
    );
};

export default Mobile;
