import styled, { css } from "styled-components";

import Heading from "core/Heading";
import Flexbox from "core/Flexbox";
import colors from "core/colors";

import { convertPxToRem } from "frontend/components/helpers";

export const TopHeading = styled(Heading).attrs({
    type: "h2",
    size: "lg"
})`
    border-bottom: ${convertPxToRem({ px: 1 })} solid ${colors.secondary.onyx};
    padding-bottom: ${convertPxToRem({ px: 5 })};
`;

export const Container = styled.div`
    &:nth-of-type(2) {
        ${TopHeading} {
            margin-top: ${convertPxToRem({ px: 24 })};
        }
    }
`;

export const PhotoRow = styled(Flexbox).attrs({
    flexDirection: "row",
    alignItems: "center"
})`
    padding-top: ${convertPxToRem({ px: 16 })};
    padding-bottom: ${convertPxToRem({ px: 16 })};
    border-bottom: ${convertPxToRem({ px: 1 })} solid ${colors.primary.pigeon};
    width: 100%;
`;

export const TitleAndAmount = styled(Flexbox).attrs({
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
})`
    width: 100%;
    margin-left: ${convertPxToRem({ px: 24 })};
    margin-right: ${convertPxToRem({ px: 16 })};
`;

export const Image = styled.img`
    width: ${convertPxToRem({ px: 75 })};
    height: ${convertPxToRem({ px: 75 })};
`;
