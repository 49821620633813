import { HEADER_PINNED_SCROLL_Y_BREAKPOINT } from "./constants";

const getScrollYRange = searchBarIntroduced => {
    return {
        start: searchBarIntroduced,
        end: searchBarIntroduced + HEADER_PINNED_SCROLL_Y_BREAKPOINT
    };
};

const getPageProps = currentPage => {
    let scrollYRange, secondRowHeight, secondRowMarginTop;

    switch (currentPage) {
        case "home":
            scrollYRange = getScrollYRange(300);
            secondRowHeight = { start: 0, end: 40 };
            secondRowMarginTop = { start: 0, end: 12 };
            break;
        case "icons":
        case "photos":
            scrollYRange = getScrollYRange(433);
            secondRowHeight = { start: 0, end: 40 };
            secondRowMarginTop = { start: 0, end: 12 };
            break;
        default:
            scrollYRange = getScrollYRange(0);
            secondRowHeight = { start: 40, end: 40 };
            secondRowMarginTop = { start: 12, end: 12 };
            break;
    }

    return {
        scrollYRange,
        secondRowHeight,
        secondRowMarginTop
    };
};

const getHeaderProps = currentPage => {
    const { scrollYRange, secondRowHeight, secondRowMarginTop } = getPageProps(
        currentPage
    );

    const elementProps = {
        // top position is negative inverse of the window.scrollY pixels travelled
        top: { start: 0, end: HEADER_PINNED_SCROLL_Y_BREAKPOINT * -1 },
        paddingBottom: { start: 18, end: 10 },
        secondRowHeight,
        secondRowMarginTop
    };

    return {
        top: elementProps.top.start,
        paddingBottom: elementProps.paddingBottom.start,
        secondRowHeight: elementProps.secondRowHeight.start,
        secondRowMarginTop: elementProps.secondRowMarginTop.start,
        config: {
            scrollYRange,
            elementProps
        }
    };
};

export default getHeaderProps;
