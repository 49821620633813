import styled, { css } from "styled-components";

import colors from "core/colors";
import { focusOutline } from "core/links";
import BodyText from "core/BodyText";

import { convertPxToRem } from "frontend/components/helpers";
import { getLinkElementStyles } from "core/Link/styles";

const BANNER_HEIGHT_REM = convertPxToRem({ px: 48 });

export const CloseBannerButton = styled.button`
    cursor: pointer;
    background: transparent;
    border: 0;
    padding: 0;
    margin-left: ${convertPxToRem({ px: 16 })};
    height: ${convertPxToRem({ px: 16 })};
    width: ${convertPxToRem({ px: 16 })};
    line-height: 0;
    font-size: 0;

    // only show focus on keyboard not with mouse:
    &:focus:not(.focus-visible),
    &:active {
        outline: none;
        box-shadow: none;
        border: none;
    }

    &:focus {
        ${focusOutline}
    }
`;

export const Banner = styled.div`
    ${({ isCookieBanner }) =>
        isCookieBanner
            ? css`
                  position: relative;
              `
            : css`
                  position: fixed;
              `}
    z-index: 9999;
    transition: all 0.3s ease;
    text-align: center;
    overflow: hidden;
    width: 100%;
    height: ${BANNER_HEIGHT_REM};
    padding-top: ${convertPxToRem({ px: 11 })};
    padding-right: ${convertPxToRem({ px: 20 })};
    padding-bottom: ${convertPxToRem({ px: 11 })};
    padding-left: ${convertPxToRem({ px: 52 })};

    display: flex;
    justify-content: space-between;
    align-items: center;

    ${({ type, size }) => css`
        ${type === "neutral" &&
            css`
                background-color: ${colors.secondary.orca};

                a {
                    ${getLinkElementStyles({
                        size: size,
                        darkMode: true,
                        styleType: "primary"
                    })}
                }
            `}

        
        ${type === "info" &&
            css`
                background-color: ${colors.accent.darkMode.suenosBuenos};

                a {
                    ${getLinkElementStyles({
                        size: size,
                        styleType: "primary"
                    })}
                }
            `}
        
        ${type === "promo" &&
            css`
                background-color: ${colors.alternative.darkMode.pistachio};

                a {
                    ${getLinkElementStyles({
                        size: size,
                        styleType: "primary"
                    })}
                }
            `}
    `}

    ${({ textColor }) => css`
        ${BodyText} {
            svg {
                width: ${convertPxToRem({ px: 20 })};
                height: ${convertPxToRem({ px: 20 })};
                margin: 0 ${convertPxToRem({ px: 8 })} 0 0;

                path {
                    fill: ${textColor};
                }
            }
        }
    `}

    //apply necessary margin for size of banner to top of page:
    + section#site-header {
        margin-top: ${BANNER_HEIGHT_REM};
    }

    ${BodyText} {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;
