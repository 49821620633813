import { useDispatch } from "react-redux";

// hooks
import { useWebSocket } from "frontend/hooks/useWebSocket.js";

// rtk
import { websocketSynchQueueSlice } from "frontend/components/fort/mod/photos/slices/websocketSynchQueueSlice.js";

export const useModWebSocket = () => {
    const dispatch = useDispatch();

    const { lastJsonMessage } = useWebSocket({
        endpoint: "websockets/photo-uploads/moderation/",
        onCloseAndReopen: () => {
            // if the connection closed, and then reopens, we may have missed some events - refetch batches
            dispatch(
                websocketSynchQueueSlice.actions.setShouldRefetchBatches({
                    shouldRefetchBatches: true
                })
            );
        }
    });

    return {
        lastJsonMessage: lastJsonMessage || {}
    };
};
