/*===================================
||
|| AnimationsContext
||
===================================*/
import React, {
    createContext,
    useReducer,
    useMemo,
    useContext,
    useEffect
} from "react";
import PropTypes from "prop-types";

// store
import { reducer, actions } from "./store";

// utils
import * as Utils from "./utils";

// constants
export const DEBUG = false;

/*---------------------------
| Context
---------------------------*/
const AnimationsContext = createContext();

// Provider
export const AnimationsContextProvider = ({ children, animationConfig }) => {
    const initialArg = {
        ids: [],
        currentStepNumber: null
    };

    const animationConfigEnhanced = {
        ...animationConfig,
        steps: animationConfig.steps.map((s, idx) => {
            return {
                step: idx + 1,
                ...s
            };
        })
    };

    // Whatever you want to manage as internal state
    const [state, dispatch] = useReducer(reducer, initialArg);

    // useMemo so it does not pass value on every render
    const value = useMemo(
        () => ({ state, dispatch, animationConfig: animationConfigEnhanced }),
        [state, dispatch, animationConfigEnhanced]
    );

    return (
        <AnimationsContext.Provider value={value}>
            <MountingWrapper>{children}</MountingWrapper>
        </AnimationsContext.Provider>
    );
};

export default AnimationsContextProvider;

// Display Name
AnimationsContext.displayName = "Animations";

// prop-types
AnimationsContextProvider.propTypes = {
    children: PropTypes.any,
    animationConfig: PropTypes.object.isRequired
};

// useAnimations
export const useAnimations = () => {
    const { state, dispatch, animationConfig } = useContext(AnimationsContext);

    const { currentStepNumber, ids } = state;
    const { id, title, steps } = animationConfig;

    const currentStepObj = Utils.getStepByNumber(currentStepNumber, steps);

    const setCurrentStepNumber = stepNumber => {
        dispatch(actions.setCurrentStepNumber(stepNumber));
    };
    const getNextStep = () => {
        return Utils.findNextObject(steps, currentStepNumber);
    };

    let animate = {
        start: ids => {
            const firtStep = steps[0];
            dispatch(actions.setIds(ids));
            setCurrentStepNumber(firtStep.step);
        },
        next: () => {
            const nextObject = getNextStep();
            if (nextObject) {
                dispatch(actions.setCurrentStepNumber(nextObject.step));
            } else {
                console.error(
                    "You triggered next step, but we could not find one. Have you come to the end of your animation? if so, please use complete()",
                    { state }
                );
            }
        },
        complete: () => {
            setCurrentStepNumber(null);
        }
    };

    return {
        id,
        title,
        steps,
        currentStepNumber,
        currentStepObj,
        ids,
        animate
    };
};

// MountingWrapper
const MountingWrapper = ({ children }) => {
    const animationApi = useAnimations();

    const { currentStepNumber, currentStepObj } = animationApi;

    useEffect(() => {
        if (currentStepObj) {
            if (DEBUG) {
                console.log({
                    step: `Firing Step ${currentStepObj.step}: ${currentStepObj.description}`
                });
            }
            currentStepObj?.onStep?.(animationApi);
        }
    }, [currentStepNumber]);

    return <>{children}</>;
};

// prop-types
MountingWrapper.propTypes = {
    children: PropTypes.any
};
