import React from "react";

// context
import { useBatches } from "frontend/components/fort/mod/photos/components/PhotoModQueuePageContent/QueueProviders/BatchesProvider/index.js";

const calculateMessage = ({
    showLoading,
    currentPage,
    batchIds = [],
    textSearch,
    pagination
}) => {
    if (showLoading) return `Loading results...`;

    const { totalCount, batchesPerPage, isLastPage } = pagination;

    let message = "";

    // Batch Segment
    if (batchIds.length < batchesPerPage) {
        // Single Page
        if (batchIds.length === 0) {
            message = `0 batches`;
        } else if (batchIds.length === 1) {
            message = `1 batch`;
        } else {
            message = `1-${batchIds.length} batches`;
        }
    } else {
        // Multi Page
        let multiPageCount = isLastPage
            ? totalCount
            : currentPage * batchesPerPage;
        message = `1-${multiPageCount} of ${totalCount} batches`;
    }

    // Search Segment: Should never be empty spaces, but just in case...
    if (textSearch.trim().length > 0) {
        message = `${message} | Searching for "${textSearch}"`;
    }

    return message;
};

// component
export const StatusMessage = () => {
    const {
        ids: batchIds,
        searchParams,
        pagination,
        showLoading
    } = useBatches();

    const { textSearch, currentPage } = searchParams;

    return (
        <div>
            {calculateMessage({
                currentPage,
                showLoading,
                batchIds,
                textSearch,
                pagination
            })}
        </div>
    );
};

export default StatusMessage;
