import { css } from "styled-components";

import breakpoints from "core/breakpoints";

export const handleHideAtBreakpoint = ({ breakpoint }) => {
    switch (breakpoint) {
        case "sm":
            return css`
                /* mobile-only: */
                @media (min-width: ${breakpoints.zero
                        .rem}) and (max-width: ${breakpoints.beforeTablet
                        .rem}) {
                    display: none;
                }
            `;
        case "smMd":
            return css`
                /* mobile + tablet: */
                @media (min-width: ${breakpoints.zero
                        .rem}) and (max-width: ${breakpoints.beforeDesktop
                        .rem}) {
                    display: none;
                }
            `;
        case "md":
            return css`
                /* tablet-only: */
                @media (min-width: ${breakpoints.tablet
                        .rem}) and (max-width: ${breakpoints.beforeDesktop
                        .rem}) {
                    display: none;
                }
            `;
        case "mdLg":
            return css`
                /* tablet + desktop: */
                @media (min-width: ${breakpoints.tablet.rem}) {
                    display: none;
                }
            `;
        case "lg":
            return css`
                /* desktop-only: */
                @media (min-width: ${breakpoints.desktop.rem}) {
                    display: none;
                }
            `;
        default:
            return null;
    }
};
