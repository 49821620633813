import React from "react";
import PropTypes from "prop-types";

import {
    spacingSizes,
    headingSizes,
    alignments,
    headingTypes
} from "core/props";

import {
    Paragraph,
    Blockquote,
    SpotlightTextContentsHeading,
    CollectionSpotlightLink
} from "./styles";

const TextContent = ({
    buttonProps,
    headingType,
    headingSize,
    headingAlignment,
    headingTopSpacing,
    headingBottomSpacing,
    headingColor,
    collectionHeading,
    collectionText,
    collectionQuoteText,
    collectionQuoteAuthor,
    collectionLink
}) => (
    <>
        {!!collectionHeading && (
            <SpotlightTextContentsHeading
                type={headingType ? headingType : "h4"}
                size={headingSize ? headingSize : "md"}
                alignment={headingAlignment ? headingAlignment : "left"}
                topSpacing={headingTopSpacing && headingTopSpacing}
                bottomSpacing={headingBottomSpacing && headingBottomSpacing}
                headingColor={headingColor}
            >
                {collectionHeading}
            </SpotlightTextContentsHeading>
        )}

        {!!collectionText && typeof collectionText === "string" && (
            <Paragraph>{collectionText}</Paragraph>
        )}
        {Array.isArray(collectionText) &&
            collectionText.length > 0 &&
            collectionText.map((paragraph, index) => (
                <Paragraph key={index}>{paragraph}</Paragraph>
            ))}

        {!!collectionQuoteText && (
            <>
                <Blockquote>&ldquo;{collectionQuoteText}&rdquo;</Blockquote>
                {!!collectionQuoteAuthor && (
                    <Paragraph>&mdash; {collectionQuoteAuthor}</Paragraph>
                )}
            </>
        )}

        {collectionLink &&
            collectionLink.map((link, index) => (
                <CollectionSpotlightLink
                    href={link.href}
                    target={link.target ? link.target : null}
                    key={index}
                    {...buttonProps}
                >
                    {link.text}
                </CollectionSpotlightLink>
            ))}
    </>
);

TextContent.propTypes = {
    headingType: PropTypes.oneOf(headingTypes),
    headingSize: PropTypes.oneOf(headingSizes),
    headingAlignment: PropTypes.oneOf(alignments),
    headingTopSpacing: PropTypes.oneOf(spacingSizes),
    headingBottomSpacing: PropTypes.oneOf(spacingSizes),
    headingColor: PropTypes.string,
    collectionHeading: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    collectionText: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    collectionQuoteText: PropTypes.string,
    collectionQuoteAuthor: PropTypes.string,
    collectionLink: PropTypes.array
};

export default TextContent;
