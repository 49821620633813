import { textXs, textSm, textMd, textLg, textXl } from "core/fontStyles";

export const handleTextSizeAndFont = ({ size }) => {
    switch (size) {
        case "xs":
            return textXs;
        case "sm":
            return textSm;
        case "md":
            return textMd;
        case "lg":
            return textLg;
        case "xl":
            return textXl;
        default:
            return null;
    }
};
