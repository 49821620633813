import styled from "styled-components";
import breakpoints from "core/breakpoints";

const SearchWrapper = styled.div`
    position: relative;
    z-index: 2;

    &.landing-page-hero {
        width: 100%;
        height: 3rem; /* 48px */
        margin-bottom: 2rem;

        form {
            height: 100%;
        }

        input[type="search"] {
            height: 100%;
            &,
            &:placeholder {
                font-size: 1rem; /* 16px */
            }
        }

        @media (min-width: ${breakpoints.tablet.rem}) {
            width: 18.5rem;
        }
    }

    &.home-page-hero {
        margin: 0 auto;
        height: 2.5rem; /* 40px */

        > div {
            height: 100%;
        }

        form {
            height: 100%;
        }

        input[type="search"] {
            height: 100%;

            &,
            &:placeholder {
                font-size: 1.125rem; /* 18px */
            }
        }

        /* Tablet - Desktop: */
        @media (min-width: ${breakpoints.tablet
                .rem}) and (max-width: ${breakpoints.beforeDesktop.rem}) {
            max-width: 30rem; /* 30px */
        }

        /* Tablet +: */
        @media (min-width: ${breakpoints.tablet.rem}) {
            height: 4.235rem; /* 67.76px */
        }

        /* Desktop +: */
        @media (min-width: ${breakpoints.desktop.rem}) {
            max-width: 54.5rem; /* 872px */
        }
    }
`;

export default SearchWrapper;
