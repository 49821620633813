import gql from "graphql-tag";

/* -------------------------------------------------------------------------- */
/*                                  RELEASES                                  */
/* -------------------------------------------------------------------------- */

export const FETCH_RELEASES_IN_BATCH = gql`
    query GetListReleasesInBatch(
        $status: UploadStatus = UPLOAD_COMPLETE
        $photoBatchId: ID!
        $limit: Int = 10000
    ) {
        releaseList: listReleasesInModerationPhotoBatch(
            status: $status
            photoBatchId: $photoBatchId
            limit: $limit
        ) {
            items {
                id
                contentType
                originalFilename
                batchId
            }
            totalCount
        }
    }
`;

export const FETCH_RELEASE_URLS = gql`
    query PhotoReleaseUrls($photoReleaseIds: [ID!]!) {
        urls: photoReleaseDetails(photoReleaseIds: $photoReleaseIds) {
            id
            downloadUrl
            originalFilename
        }
    }
`;
