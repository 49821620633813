import { actionTypes } from "./actionTypes";

/*---------------------------
| Reducer
---------------------------*/
const reducer = (state, action) => {
    switch (action.type) {
        case actionTypes.CC_SELECT_NEXT: {
            let newPrevious, newCurrent, newNext;
            const {
                components,
                current,
                next,
                isAnimating,
                isCarousel,
                totalSlides
            } = state;

            // Do nothing if we are in transition
            // or if !isCarousel and they have reached the end of the line
            const currSlide = components.find(c => current === c.id);
            const shouldDoNothing =
                isAnimating ||
                (!isCarousel && currSlide.slideNumber === totalSlides);

            if (shouldDoNothing) {
                return { ...state };
            }

            newPrevious = current;
            newCurrent = next;

            components.forEach((comp, idx) => {
                if (comp.id === next) {
                    // need to get first slide if we are at the end
                    const nextNextKey = idx + 1;
                    newNext = components[nextNextKey]
                        ? components[nextNextKey].id
                        : components[0].id;
                }
            });

            return {
                ...state,
                isAnimating: true,
                previous: newPrevious,
                current: newCurrent,
                next: newNext
            };
        }
        case actionTypes.CC_SELECT_PREVIOUS: {
            let newPrevious, newCurrent, newNext;

            const {
                components,
                current,
                previous,
                isAnimating,
                isCarousel
            } = state;

            // Do nothing if we are in transition
            // or if !isCarousel and they have reached the end of the line
            const currSlide = components.find(c => current === c.id);
            const shouldDoNothing =
                isAnimating || (!isCarousel && currSlide.slideNumber === 1);

            if (shouldDoNothing) {
                return { ...state };
            }

            newNext = current;
            newCurrent = previous;

            const lastKey = components.length - 1;

            components.forEach((comp, idx) => {
                if (comp.id === previous) {
                    // need to get first slide if we are at the end
                    const nextNextKey = idx - 1;
                    newPrevious = components[nextNextKey]
                        ? components[nextNextKey].id
                        : components[lastKey].id;
                }
            });

            return {
                ...state,
                isAnimating: true,
                previous: newPrevious,
                current: newCurrent,
                next: newNext
            };
        }
        case actionTypes.CC_SET_IS_ANIMATING: {
            return {
                ...state,
                isAnimating: action.isAnimating
            };
        }
        default: {
            return { ...state };
        }
    }
};

export default reducer;
