import React, { useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useLocation, useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { ButtonGroup } from "core/ButtonGroup";
import Skeleton from "core/Skeleton";

import { GET_TOTAL_COUNTS_FOR_BROWSE_BY_TAG_RESULTS } from "./queries";

import { useBrowsePageContext } from "./context";

import { convertPxToRem } from "frontend/components/helpers";
import { TYPES } from "./constants";
import { BROWSE_ICONS_BY_TAG, BROWSE_PHOTOS_BY_TAG } from "data/links";

let BrowseSegmentedControlButtons = ({ className }) => {
    const {
        activeType,
        term,
        loadingFirstBatch: showSkeleton,
        setIconsTotalCount,
        setPhotosTotalCount
    } = useBrowsePageContext();

    const history = useHistory();
    const location = useLocation();

    const redirectTo = ({ pathname }) => () => {
        const newLocation = {
            ...location,
            pathname: pathname.replace(":term", term)
        };

        history.push(newLocation);
    };

    const { data, loading } = useQuery(
        GET_TOTAL_COUNTS_FOR_BROWSE_BY_TAG_RESULTS,
        {
            variables: { term }
        }
    );

    let iconsTotal = 0;
    let photosTotal = 0;

    if (!loading && !!data) {
        iconsTotal =
            data && data.icons && data.icons.totalCount
                ? data.icons.totalCount
                : 0;
        photosTotal =
            data && data.photos && data.photos.totalCount
                ? data.photos.totalCount
                : 0;
    }

    useEffect(() => {
        setIconsTotalCount(iconsTotal);
        setPhotosTotalCount(photosTotal);
    }, [iconsTotal, photosTotal]);

    const addPlural = ({ totalCount }) => (totalCount === 1 ? "" : "s");

    return showSkeleton ? (
        <Skeleton
            className={className}
            height={convertPxToRem({ px: 26 })}
            width={convertPxToRem({ px: 300 })}
        />
    ) : (
        <ButtonGroup
            className={className}
            buttons={[
                {
                    label: `${iconsTotal} Icon${addPlural({
                        totalCount: iconsTotal
                    })}`,
                    isActive: TYPES.ICONS === activeType,
                    onClick: redirectTo({ pathname: BROWSE_ICONS_BY_TAG })
                },

                {
                    label: `${photosTotal} Photo${addPlural({
                        totalCount: photosTotal
                    })}`,
                    isActive: TYPES.PHOTOS === activeType,
                    onClick: redirectTo({
                        pathname: BROWSE_PHOTOS_BY_TAG
                    })
                }
            ]}
        />
    );
};

BrowseSegmentedControlButtons.propTypes = {
    className: PropTypes.string
};

BrowseSegmentedControlButtons = styled(BrowseSegmentedControlButtons)``;

export default BrowseSegmentedControlButtons;
