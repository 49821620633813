import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import colors from "core/colors";

const createHelpers = (width, height, css) => {
    // somehow sizes is ending up in markup, even if it is not a valid svg attribute
    // until we have a better solution, just render it empty, instead to '[Object object]'
    const sanitizeSizes = sizes =>
        Object.defineProperty(sizes, "toString", {
            value: () => "",
            enumerable: false
        });

    const getDimensions = (size, sizes) => {
        if (
            size &&
            typeof size.width === "number" &&
            typeof size.height === "number"
        ) {
            return size;
        }

        return size && sizes[size] ? sizes[size] : { width, height };
    };

    const getCss = (size, sizes, fillColor, fillColorRule, noStyles) => {
        if (noStyles) {
            return "";
        }

        const dimensions = getDimensions(size, sizes);
        const fillRule =
            fillColor && fillColorRule
                ? `${fillColorRule}{ fill: ${fillColor}; }`
                : "";
        return css`
            width: ${dimensions.width}px;
            height: ${dimensions.height}px;
            ${fillRule}
        `;
    };

    const propsToCss = ({ size, sizes, fillColor, fillColorRule, noStyles }) =>
        getCss(size, sizes, fillColor, fillColorRule, noStyles);

    return {
        getCss,
        getDimensions,
        propsToCss,
        sanitizeSizes
    };
};

const width = "24";
const height = "24";
const viewBox = "0 0 24 24";

const { getDimensions, getCss, propsToCss, sanitizeSizes } = createHelpers(
    width,
    height,
    css
);

const sizes = sanitizeSizes({
    xs: { width: 14, height: 14 },
    sm: { width: 16, height: 16 },
    md: { width: 20, height: 20 },
    lg: { width: 24, height: 24 },
    xl: { width: 40, height: 40 }
});

const Image = styled.svg`
    ${propsToCss}
`;

const children = (
    <Fragment>
        <path
            fill={colors.secondary.orca}
            fillRule="evenodd"
            d="M2 12C2 6.48 6.48 2 12 2s10 4.48 10 10-4.48 10-10 10S2 17.52 2 12zm7.828 1.604c.279 0 .495.232.551.505.056.27.164.498.326.683.255.29.57.475.948.555v-2.76a1.107 1.107 0 00-.172-.052 1.356 1.356 0 00-.222-.066c-.303-.088-.603-.19-.899-.304a3.229 3.229 0 01-.8-.475 2.3 2.3 0 01-.554-.74c-.14-.299-.209-.668-.209-1.108 0-.45.078-.845.234-1.189.164-.343.377-.629.64-.858.263-.237.566-.418.911-.54a3.72 3.72 0 011.071-.239V6h.726v1.016c.37.045.715.133 1.035.265.328.123.615.299.861.528.246.22.448.497.604.831.093.2.162.421.209.665.053.281-.18.523-.466.523h-.732c-.272 0-.482-.222-.569-.48a1.354 1.354 0 00-.227-.417c-.197-.247-.435-.37-.715-.37v2.337l.296.079c.107.026.217.057.332.092.608.176 1.075.396 1.404.66.328.256.57.524.726.806.156.281.246.563.27.845.034.281.05.532.05.752 0 .194-.045.44-.135.74-.09.29-.255.576-.493.857-.23.282-.541.533-.935.753-.394.22-.899.352-1.515.396V18h-.726v-1.122c-.92-.07-1.637-.374-2.154-.911-.424-.44-.702-1.049-.833-1.826-.05-.288.183-.537.475-.537h.687zm2.551-.792v2.535c.148-.018.3-.053.456-.106.164-.062.308-.145.43-.25a1.27 1.27 0 00.32-.384 1.19 1.19 0 00.124-.554c0-.343-.103-.599-.308-.766-.197-.176-.537-.334-1.022-.475zm-.726-2.1v-2.15c-.14 0-.275.021-.406.065a1 1 0 00-.357.211.965.965 0 00-.259.344c-.057.132-.086.29-.086.475 0 .29.086.515.259.673.172.158.455.286.85.383z"
            clipRule="evenodd"
            key="key-0"
        />
    </Fragment>
);

const defaultProps = {
    children,
    viewBox,
    fillColor: null,
    fillColorRule: "&&& path, &&& use, &&& g, &&& circle",
    sizes,
    size: null
};

Image.propTypes /* remove-proptypes */ = {
    fillColor: PropTypes.string,
    fillColorRule: PropTypes.string,
    viewBox: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    size: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            height: PropTypes.number.isRequired,
            width: PropTypes.number.isRequired
        })
    ]),
    sizes: PropTypes.shape({
        height: PropTypes.number,
        width: PropTypes.number
    })
};

export default Object.assign(Image, {
    getDimensions,
    getCss,
    defaultProps,
    displayName: "Payouts"
});
