import React, { useState, useCallback } from "react";

import Link from "core/Link";

import ForgotPassword from "../ForgotPassword";
import ForgotPasswordSuccess from "../ForgotPasswordSuccess";
import {
    PageTitle,
    PageSubtitle,
    PageWrapper,
    PageFormContent,
    PageFeedbackContent
} from "../styles";

import { SIGNUP } from "data/links";

const ForgotPasswordPage = () => {
    const [showSuccess, setShowSuccess] = useState(false);
    const afterSubmit = useCallback(() => setShowSuccess(true), []);
    const queries = window.location.search;

    if (showSuccess) {
        return (
            <PageWrapper>
                <PageFeedbackContent>
                    <PageTitle>Another Trip to the Mailbox</PageTitle>
                    <ForgotPasswordSuccess />
                </PageFeedbackContent>
            </PageWrapper>
        );
    }

    return (
        <PageWrapper darkMode>
            <PageTitle darkMode>Reset Your Password</PageTitle>
            <PageSubtitle>
                New?{" "}
                <Link size="lg" darkMode href={SIGNUP + queries}>
                    Create an Account
                </Link>
            </PageSubtitle>
            <PageFormContent>
                <ForgotPassword afterSubmit={afterSubmit} />
            </PageFormContent>
        </PageWrapper>
    );
};

export default ForgotPasswordPage;
