export const findNextObject = (arrayOfObjects, currentStepNumber) => {
    // Find the index of the currentObject in the array
    const currentIndex = arrayOfObjects.findIndex(
        obj => obj.step === currentStepNumber
    );

    // If the currentObject is not found or it's the last object in the array, return null
    if (currentIndex === -1 || currentIndex === arrayOfObjects.length - 1) {
        return null;
    }

    // Return the next object in the array
    return arrayOfObjects[currentIndex + 1];
};

export const getStepByNumber = (stepNumber, steps) => {
    return steps.find(s => s.step === stepNumber);
};
