import React from "react";
import PropTypes from "prop-types";

// context
import { useInlineTextEdit } from "core/InlineTextEdit/index.js";

// styles
import { SaveButtonStyled, ButtonStyled } from "./styles.js";

export const SaveButton = ({
    children,
    ariaLabel = "Save",
    saveButtonProps = {}
}) => {
    const contextStore = useInlineTextEdit();
    const { isEditing, onSave } = contextStore;

    const onClick = () => {
        onSave(contextStore);
    };

    return (
        <SaveButtonStyled isEditing={isEditing}>
            <ButtonStyled
                onClick={onClick}
                aria-label={ariaLabel}
                type="button"
                size="sm"
                {...saveButtonProps}
            >
                {children}
            </ButtonStyled>
        </SaveButtonStyled>
    );
};

// prop-types
SaveButton.propTypes = {
    children: PropTypes.any,
    ariaLabel: PropTypes.string,
    saveButtonProps: PropTypes.object
};
