import React from "react";
import PropTypes from "prop-types";

// styles
import { CircleCountStyled, NumberStyled } from "./styles.js";

const CircleCount = props => {
    const { number } = props;

    return (
        <CircleCountStyled {...props}>
            <NumberStyled {...props}>{number}</NumberStyled>
        </CircleCountStyled>
    );
};

export default CircleCount;

// prop-types
CircleCount.propTypes = {
    number: PropTypes.number.isRequired,
    pxWidth: PropTypes.number,
    pxHeight: PropTypes.number,
    numberPxFontSize: PropTypes.number,
    circleColor: PropTypes.string,
    circleBorderPxWidth: PropTypes.number,
    isSolid: PropTypes.bool,
    isGrayscale: PropTypes.bool
};
