import React from "react";

import Grid from "core/Grid";
import OffsetGrid from "core/OffsetGrid";

import { MarketingPageSectionEyebrowSubheading } from "core/MarketingPageSectionHeading";

import colors from "core/colors";

import {
    HeroHeadingIcon,
    HeroH1,
    HeroCtaButton,
    HeroCtaButtonLeft,
    HeroBiLine,
    HeroImage,
    HeroImageContainer,
    HeroSectionGreyContainer
} from "./styles";

const HeroSection = () => (
    <div>
        <HeroSectionGreyContainer
            bottomSpacing="none"
            bgColor={colors.primary.diamond}
            textColor={colors.secondary.orca}
        >
            <HeroHeadingIcon fillColor={colors.functional.secondary.lingo} />
            <MarketingPageSectionEyebrowSubheading>
                Lingo by Noun Project
            </MarketingPageSectionEyebrowSubheading>
            <HeroH1 text="The Visual Home for Your Brand" />
            <OffsetGrid sm={10} md={10} lg={12}>
                <Grid mobileSpacing={16} tabletSpacing={16} desktopSpacing={16}>
                    <Grid item sm={12} md={12} lg={12}>
                        <HeroBiLine>
                            Visually organize all your assets in one place.
                            Create and share living style guides, asset
                            libraries and more.
                        </HeroBiLine>
                    </Grid>
                </Grid>
            </OffsetGrid>
            <OffsetGrid sm={10} md={10} lg={12}>
                <Grid mobileSpacing={24} tabletSpacing={16} desktopSpacing={16}>
                    <Grid item sm={12} md={6} lg={6}>
                        <HeroCtaButtonLeft
                            target="_blank"
                            href="https://www.lingoapp.com/get-started/"
                        >
                            Get Started
                        </HeroCtaButtonLeft>
                    </Grid>
                    <Grid item sm={12} md={6} lg={6}>
                        <HeroCtaButton
                            target="_blank"
                            href="https://www.lingoapp.com/"
                        >
                            Learn More
                        </HeroCtaButton>
                    </Grid>
                </Grid>
            </OffsetGrid>
        </HeroSectionGreyContainer>
        <HeroImageContainer>
            <Grid desktopSpacing={0} justifyContent="center">
                <Grid item sm={10} md={12} lg={8}>
                    <HeroImage alt="" />
                </Grid>
            </Grid>
        </HeroImageContainer>
    </div>
);

export default HeroSection;
