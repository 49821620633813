import React, { useState, createRef } from "react";

import { getCsrfTokenFromCookie } from "js/helpers/csrfToken";
import { convertPxToRem } from "frontend/components/helpers";

import colors from "core/colors";
import {
    NewsletterSubscription,
    NewsletterSubscriptionForm,
    NewsletterSubscriptionHeading,
    NewsletterSubscriptionSubmitBtn,
    NewsletterSubscriptionSuccessMessage
} from "./styles";

import Success from "core/ReusableIcons/Success";
import FormInput from "core/FormInput";

export const NounGazetteSubscription = () => {
    const [inputValue, setInputValue] = useState("");

    const [subscriptionSuccessful, setSubscriptionSuccessful] = useState(false);
    const [requestSent, setRequestSent] = useState(false);

    const formRef = createRef();

    const sendRequest = async () => {
        const formData = new FormData(formRef.current);
        formData.append("email", formData.get("newsletter-sub"));
        formData.delete("newsletter-sub");

        let response = await fetch("/mailing-list/subscribe/", {
            method: "POST",
            headers: { "X-CSRFToken": getCsrfTokenFromCookie() },
            // FormData object structure required by server
            // FormData needs reference to form element
            body: formData
        });

        response = await response.json();
        return response;
    };

    const handleSubmit = async e => {
        e.preventDefault();

        const response = await sendRequest();
        setRequestSent(true);

        setSubscriptionSuccessful(response.success);
    };

    const successMessage = (
        <NewsletterSubscriptionSuccessMessage
            icon={Success}
            accentColor={colors.alternative.lightMode.chartreuse}
        >
            Thanks for subscribing!
        </NewsletterSubscriptionSuccessMessage>
    );

    const form = (
        <NewsletterSubscriptionForm
            ref={formRef}
            autoComplete="off"
            onSubmit={handleSubmit}
            name="newsletter-subscription-form"
        >
            <FormInput
                onChange={setInputValue}
                value={inputValue}
                aria-label="Enter your address"
                type="email"
                placeholderText="you@example.com"
                labelText="Enter your address"
                width={convertPxToRem({ px: 214 })}
                autoComplete="off"
                darkMode
                // naming the field "email" seems to cause lastpass to autofill the field,
                // and potentially submitting it automatically - so we call it "_email"
                name="newsletter-sub"
                required
            />
            <NewsletterSubscriptionSubmitBtn type="submit">
                Submit
            </NewsletterSubscriptionSubmitBtn>
        </NewsletterSubscriptionForm>
    );

    return (
        <NewsletterSubscription>
            <NewsletterSubscriptionHeading>
                Get free design resources in your inbox
            </NewsletterSubscriptionHeading>
            {requestSent && subscriptionSuccessful ? successMessage : form}
        </NewsletterSubscription>
    );
};
