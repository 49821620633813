import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import Button from "core/Button";
import colors from "core/colors";
import { Container, SectionHeading, Paragraph, CTAsection } from "./styles";

import {
    spacingSizes,
    headingSizes,
    alignments,
    headingTypes,
    linkTargets,
    buttonStyleTypes,
    reusableComponentSizes
} from "core/props";

let TextWithButton = ({
    className,
    children,
    headingType,
    headingSize = "xl",
    headingColor,
    headingBottomSpacing = "xl",
    headingAlignment,
    isHeadingGrid1,
    isHeadingGrid2,
    text,
    subtext,
    subtextHasTopPadding = false,
    subtextAlignment = "center",
    subtextColor,
    bottomSpacing = "none",
    buttons,
    buttonAlignment
}) => {
    return (
        <Container bottomSpacing={bottomSpacing}>
            {text && (
                <SectionHeading
                    className={className}
                    type={headingType}
                    size={headingSize}
                    headingColor={
                        headingColor ? headingColor : colors.secondary.orca
                    }
                    bottomSpacing={headingBottomSpacing}
                    alignment={headingAlignment ? headingAlignment : "center"}
                    isHeadingGrid1={isHeadingGrid1}
                    isHeadingGrid2={isHeadingGrid2}
                >
                    {text}
                </SectionHeading>
            )}

            {subtext && (
                <Paragraph
                    className={className}
                    subtextHasTopPadding={subtextHasTopPadding}
                    subtextAlignment={subtextAlignment}
                    subtextColor={subtextColor}
                >
                    {subtext}
                </Paragraph>
            )}

            {!!buttons && (
                <CTAsection
                    className={className}
                    buttonAlignment={buttonAlignment}
                >
                    {buttons.map(individualButton => (
                        <Button
                            key={individualButton.text}
                            href={individualButton.url}
                            target={individualButton.target || "_self"}
                            styleType={individualButton.styleType || "solid"}
                            size={individualButton.size || "lg"}
                            darkMode={individualButton.darkMode}
                        >
                            {individualButton.text}
                        </Button>
                    ))}
                </CTAsection>
            )}

            {children}
        </Container>
    );
};

TextWithButton.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    headingType: PropTypes.oneOf(headingTypes),
    headingSize: PropTypes.oneOf(headingSizes),
    headingColor: PropTypes.string,
    headingBottomSpacing: PropTypes.oneOf(spacingSizes),
    headingAlignment: PropTypes.oneOf(alignments),
    isHeadingGrid1: PropTypes.bool,
    isHeadingGrid2: PropTypes.bool,
    text: PropTypes.string,
    subtext: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    subtextHasTopPadding: PropTypes.bool,
    subtextAlignment: PropTypes.oneOf(alignments),
    subtextColor: PropTypes.string,
    bottomSpacing: PropTypes.oneOf(spacingSizes),
    buttons: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string,
            href: PropTypes.string,
            target: PropTypes.oneOf(linkTargets),
            styleType: PropTypes.oneOf(buttonStyleTypes),
            size: PropTypes.oneOf(reusableComponentSizes),
            darkMode: PropTypes.bool
        })
    ),
    buttonAlignment: PropTypes.oneOf(alignments)
};

TextWithButton = styled(TextWithButton)``;

export default TextWithButton;
