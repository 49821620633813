import styled from "styled-components";

import { boxShadow } from "core/SiteHeader/constants";
import colors from "core/colors";

export const Wrapper = styled.nav`
    position: absolute;
    left: 0;
    z-index: 100;
    background-color: ${({ isHeroSearchV2 }) =>
        isHeroSearchV2 ? colors.primary.snow : colors.secondary.orca};
    width: 13.125rem;
    padding: 1.6875rem 1.875rem 1.8125rem;

    ${({ isHeroSearchV2 }) => isHeroSearchV2 && `box-shadow: ${boxShadow}`};

    li {
        &:not(:last-of-type) {
            margin-bottom: 1.5rem;
        }
    }
`;
