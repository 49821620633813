import React from "react";
import PropTypes from "prop-types";

//components
import ImagePreventRightClick from "core/ImagePreventRightClick";
import SquareGrid from "frontend/components/icons/SquareGrid";

//styles
import { ImageContainer } from "./styles";

const GraphicContainer = ({
    imageSrcAndTitle,
    backgroundSize,
    backgroundPosition,
    usePlaceholder
}) => {
    const hasManyImages = Array.isArray(imageSrcAndTitle);

    const manyIcons =
        hasManyImages &&
        imageSrcAndTitle.slice(0, 6).map((data, index) => {
            const { imageSrc, title } = data;
            return (
                <ImagePreventRightClick
                    key={index}
                    imageSrc={imageSrc}
                    srText={title}
                    backgroundSize="auto 100%"
                    usePlaceholder={usePlaceholder}
                />
            );
        });

    return hasManyImages ? (
        <ImageContainer>
            <SquareGrid
                gridItemContent={manyIcons}
                numberOfColumns={3}
                gridSpacingSize="0" // this changes via breakpoints so it is handled inside styles.js
            />
        </ImageContainer>
    ) : (
        <ImageContainer>
            <ImagePreventRightClick
                imageSrc={imageSrcAndTitle.imageSrc}
                srText={imageSrcAndTitle.title}
                backgroundSize={backgroundSize}
                backgroundPosition={backgroundPosition}
                usePlaceholder={false}
            />
        </ImageContainer>
    );
};

GraphicContainer.propTypes = {
    imageSrcAndTitle: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
        .isRequired,
    backgroundSize: PropTypes.string,
    backgroundPosition: PropTypes.string,
    usePlaceholder: PropTypes.bool
};

export default GraphicContainer;
