import React from "react";
import PropTypes from "prop-types";

// context
import { useInlineTextEdit } from "core/InlineTextEdit/index.js";

// styles
import { EditStyled } from "./styles.js";

const Edit = ({ children }) => {
    const { isEditing } = useInlineTextEdit();
    return <EditStyled isEditing={isEditing}>{children}</EditStyled>;
};

export default Edit;

// prop-types
Edit.propTypes = {
    children: PropTypes.any
};
