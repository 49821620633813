import React, { useContext } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

// Context
import { SlideshowContext } from "./index.js";
import * as Actions from "./Context/actions";

// styles
import { SlideStyled } from "./styles.js";

const Slide = ({ component }) => {
    const { state, dispatch } = useContext(SlideshowContext);
    const { current, previous, next, speed } = state;

    const { id, Component } = component;

    const onTransitionEnd = () => {
        dispatch(Actions.setIsAnimating(false));
    };

    const theClassName = classnames({
        Slide: true,
        currentSlide: current === id,
        previousSlide: previous === id,
        nextSlide: next === id
    });

    return (
        <SlideStyled
            onTransitionEnd={onTransitionEnd}
            className={theClassName}
            speed={speed}
        >
            {Component}
        </SlideStyled>
    );
};

export default Slide;

Slide.propTypes = {
    component: PropTypes.object.isRequired
};
