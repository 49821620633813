import React from "react";
import styled from "styled-components";

import PhotoModLargeUploadImage from "../PhotoModLargeUploadImage";
import ModalUpload from "../../../core/ExpandedModal/ModalUpload";

import { convertPxToRem } from "frontend/components/helpers";

const Wrapper = styled.div`
    padding: ${convertPxToRem(24)};

    display: flex;
    justify-content: center;
    align-items: center;

    height: 100%;
`;

const PhotoModSingleExpandedUpload = ({ uploadId }) => (
    <Wrapper>
        <ModalUpload
            uploadId={uploadId}
            UploadGraphicComponent={props => (
                <PhotoModLargeUploadImage {...props} />
            )}
        />
    </Wrapper>
);

export default PhotoModSingleExpandedUpload;
