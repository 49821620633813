import React from "react";

import SectionContainer from "core/SectionContainer";
import TextWithButton from "core/TextWithButton";
import OffsetGrid from "core/OffsetGrid";
import HeroImage from "core/HeroImage";
import CollectionSpotlight from "core/CollectionSpotlight";
import Timeline from "core/Timeline";
import TestimonialSection from "core/TestimonialSection";
import BlogPosts from "core/BlogPosts";
import Faq from "core/Faq";
import PageMeta from "core/PageMeta";

import colors from "core/colors";

import {
    heroImageDesktop,
    heroImageTablet,
    heroImageMobile,
    heroTitle,
    secondaryHeroText,
    iconCollectionImage,
    iconCollectionCaption,
    iconCollectionAlt,
    iconCollectionHeading,
    iconCollectionText,
    photoCollectionImage,
    photoCollectionCaption,
    photoCollectionAlt,
    photoCollectionHeading,
    photoCollectionText,
    testimonials,
    timelineTitle,
    timelineContent,
    leadershipTitle,
    leadershipTeam,
    leadershipButton,
    companyValuesTitle,
    companyValues,
    nounProjectPressTitle,
    nounProjectPressSubtext,
    nounProjectPressButton
} from "./data";

const About = () => {
    return (
        <>
            <PageMeta title="Noun Project" />
            <SectionContainer textColor={colors.secondary.orca}>
                <TextWithButton
                    headingType="h1"
                    headingSize="2xl"
                    headingAlignment="center"
                    isHeadingGrid1
                    text={heroTitle}
                />
            </SectionContainer>

            <HeroImage
                heroImageDesktop={heroImageDesktop}
                heroImageTablet={heroImageTablet}
                heroImageMobile={heroImageMobile}
            />

            <SectionContainer
                textColor={colors.secondary.orca}
                bottomSpacing="none"
            >
                <TextWithButton
                    headingType="h4"
                    headingSize="md"
                    headingAlignment="center"
                    isHeadingGrid2
                    text={secondaryHeroText}
                />
            </SectionContainer>

            <SectionContainer textColor={colors.secondary.orca}>
                <OffsetGrid sm={10} md={8} lg={10}>
                    <CollectionSpotlight
                        image={iconCollectionImage}
                        imageCaption={iconCollectionCaption}
                        imageAlt={iconCollectionAlt}
                        collectionHeading={iconCollectionHeading}
                        collectionText={iconCollectionText}
                    />
                </OffsetGrid>
            </SectionContainer>

            <SectionContainer
                bgColor={colors.primary.diamond}
                textColor={colors.secondary.orca}
            >
                <OffsetGrid sm={10} md={8} lg={10}>
                    <CollectionSpotlight
                        isRightImg
                        image={photoCollectionImage}
                        imageCaption={photoCollectionCaption}
                        imageAlt={photoCollectionAlt}
                        collectionHeading={photoCollectionHeading}
                        collectionText={photoCollectionText}
                    />
                </OffsetGrid>
            </SectionContainer>

            <SectionContainer textColor={colors.secondary.orca}>
                <OffsetGrid sm={10} md={12} lg={10}>
                    <Timeline title={timelineTitle} content={timelineContent} />
                </OffsetGrid>
            </SectionContainer>

            <SectionContainer
                textColor={colors.secondary.orca}
                bgColor={colors.primary.diamond}
            >
                <OffsetGrid sm={10} md={8} lg={10}>
                    <TestimonialSection testimonials={testimonials} />
                </OffsetGrid>
            </SectionContainer>

            <SectionContainer textColor={colors.secondary.orca}>
                <OffsetGrid sm={10} md={12} lg={12}>
                    <BlogPosts
                        blogPostsTitle={leadershipTitle}
                        posts={leadershipTeam}
                        button={leadershipButton}
                        buttonAlignment="center"
                    />
                </OffsetGrid>
            </SectionContainer>

            <SectionContainer bgColor={colors.primary.diamond}>
                <OffsetGrid sm={10} md={12} lg={10}>
                    <Faq title={companyValuesTitle} values={companyValues} />
                </OffsetGrid>
            </SectionContainer>

            <SectionContainer textColor={colors.secondary.orca}>
                <OffsetGrid sm={10} md={12} lg={10}>
                    <TextWithButton
                        headingType="h2"
                        headingAlignment="center"
                        text={nounProjectPressTitle}
                        subtext={nounProjectPressSubtext}
                        buttons={nounProjectPressButton}
                    />
                </OffsetGrid>
            </SectionContainer>
        </>
    );
};

export default About;
