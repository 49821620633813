import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

let SquareGridRow = ({ className, children }) => (
    <li className={className} data-testid="SquareGrid-row">
        <ul>{children}</ul>
    </li>
);

SquareGridRow = styled(SquareGridRow)`
    ${SquareGridContainer} & ul {
        display: flex;
    }
`;

SquareGridRow.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node
};

export { SquareGridRow };

const squareAspectRatio = css`
    width: 100%;

    &:after {
        content: "";
        display: block;
        padding-bottom: 100%;
    }
`;

const gridItemContentContainerStyles = css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

export const SquareGridItem = styled.li`
    ${SquareGridRow} & {
        position: relative;
        ${squareAspectRatio}

        & > * {
            ${gridItemContentContainerStyles}
        }
    }
`;

export const SquareGridContainer = styled.ul(({ gridSpacingSize }) => {
    return css`
        display: flex;
        flex-direction: column;

        ${SquareGridRow} {
            margin-bottom: ${gridSpacingSize};

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        ${SquareGridItem} {
            margin-right: ${gridSpacingSize};

            &:last-of-type {
                margin-right: 0;
            }
        }
    `;
});
