import React from "react";
import PropTypes from "prop-types";

// styles
import { ErrorMessageStyled } from "./styles.js";

export const ErrorMessage = ({ children, controlId }) => {
    if (!children) return null;

    return (
        <ErrorMessageStyled data-testid={`error-message-${controlId}`}>
            {children}
        </ErrorMessageStyled>
    );
};

export default ErrorMessage;

// prop-types
ErrorMessage.propTypes = {
    children: PropTypes.string,
    controlId: PropTypes.string
};
