import React from "react";
import PropTypes from "prop-types";
import { Route, useRouteMatch } from "react-router-dom";

// hooks
import { useHasAccess } from "frontend/components/fort/mod/photos/hooks/useHasAccess.js";

// components
import PageMeta from "core/PageMeta";
import NotFound from "frontend/components/core/NotFound";
import LoadingSpinner from "core/LoadingSpinner";

const ProtectedRoutes = ({ children }) => {
    const { path } = useRouteMatch();
    const { isLoading, hasAccess } = useHasAccess();

    // Wait for currentUser && myModProfile to load
    if (isLoading) {
        return <LoadingSpinner />;
    }

    return (
        <Route path={path}>
            <PageMeta
                title={`Photo Moderation | Noun Project`}
                indexContent="noindex,nofollow"
            />
            {hasAccess ? children : <NotFound />}
        </Route>
    );
};

export default ProtectedRoutes;

// prop-types
ProtectedRoutes.propTypes = {
    children: PropTypes.array
};
