import React from "react";
import PropTypes from "prop-types";

// styles
import colors from "core/colors";
import { OffsetIconStyled } from "./styles";

// Components
import ToggleButton from "./ToggleButton";
import HeartSolid from "core/ReusableIcons/HeartSolid";
import HeartOutline from "core/ReusableIcons/HeartOutline";

export const Heart = ({
    isDisabled = false,
    isActivated = false,
    onToggle,
    moderationIconKey
}) => {
    const HeartIcon = isActivated ? HeartSolid : HeartOutline;

    const fillColor = isDisabled
        ? colors.secondary.dolphin
        : colors.accent.lightMode.persian;

    return (
        <ToggleButton
            onToggle={onToggle}
            isActivated={isActivated}
            isDisabled={isDisabled}
            moderationIconKey={moderationIconKey}
            title="Favorite"
        >
            <OffsetIconStyled verticalOffset="2px">
                <HeartIcon
                    size={{ width: 22, height: 22 }}
                    fillColor={fillColor}
                />
            </OffsetIconStyled>
        </ToggleButton>
    );
};

// prop-types
Heart.propTypes = {
    moderationIconKey: PropTypes.string.isRequired,
    onToggle: PropTypes.func.isRequired,
    isActivated: PropTypes.bool,
    isDisabled: PropTypes.bool
};
