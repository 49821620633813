import styled from "styled-components";

import Heading from "core/Heading";
import BulletSection from "core/BulletSection";
import TextWithButton from "core/TextWithButton";
import SectionContainer from "core/SectionContainer";

import { MarketingPageSectionBiLine } from "core/MarketingPageSectionHeading";
import Button from "core/Button";
import { spacingWithBreakpoints } from "core/spacing";
import breakpoints from "core/breakpoints";
import colors from "core/colors";

import Lingo from "core/ReusableIcons/Lingo";
import heroImagePath from "./images/lingo-marketing-hero.png";

/* -------------------------------------------------------------------------- */
/*                                HERO SECTION                                */
/* -------------------------------------------------------------------------- */

export const HeroSectionGreyContainer = styled(SectionContainer)`
    padding-bottom: 6.1875rem;

    @media (min-width: ${breakpoints.tablet.rem}) {
        padding-bottom: 13.625rem;
    }

    @media (min-width: ${breakpoints.desktop.rem}) {
        padding-bottom: 12.125rem;
    }
`;

export const HeroHeadingIcon = styled(Lingo)`
    display: block;
    margin: 0 auto 1rem;
    width: auto;
    height: 1.375rem;

    @media (min-width: ${breakpoints.desktop.rem}) {
        height: 2.5rem;
    }
`;

export const HeroH1 = styled(TextWithButton).attrs({
    headingType: "h1",
    headingSize: "2xl"
})`
    ${spacingWithBreakpoints.XLBottom}
`;

export const HeroBiLine = styled(MarketingPageSectionBiLine)`
    font-size: 1.125rem; /* 18px for all screens; custom */
    line-height: 1.8rem;
    ${spacingWithBreakpoints.XLBottom}
`;

export const HeroCtaButton = styled(Button)`
    display: flex;

    @media (min-width: ${breakpoints.tablet.rem}) {
        width: 11.25rem;
    }

    @media (min-width: ${breakpoints.desktop.rem}) {
        width: 12.5rem;
    }
`;

export const HeroCtaButtonLeft = styled(HeroCtaButton)`
    margin-right: 0;
    margin-left: auto;
`;

export const HeroImageContainer = styled.div`
    margin-top: -4.6875rem;

    @media (min-width: ${breakpoints.tablet.rem}) {
        margin-top: -11.9375rem;
    }

    @media (min-width: ${breakpoints.desktop.rem}) {
        margin-top: -10.1875rem;
    }
`;

export const HeroImage = styled.img.attrs({
    src: heroImagePath
})`
    width: 100%;
`;

/* -------------------------------------------------------------------------- */
/*                               BULLET SECTION                               */
/* -------------------------------------------------------------------------- */

export const LingoMarketingPageBulletSection = styled(BulletSection)`
    svg {
        height: 2.5rem;
        width: auto;
    }
`;

/* -------------------------------------------------------------------------- */
/*                           BRANDS YOU KNOW SECTION                          */
/* -------------------------------------------------------------------------- */

export const BrandsYouKnowHeading = styled(TextWithButton).attrs({
    headingType: "h2"
})`
    margin-bottom: 1.5rem; /* 24px custom */

    @media (min-width: ${breakpoints.tablet.rem}) and (max-width: ${breakpoints
            .beforeDesktop.rem}) {
        /* 28px custom */
        margin-bottom: 1.75rem;
    }
`;

export const LogoSetContainer = styled.div`
    margin: 0 auto;
`;

const SPACE_BETWEEN_LOGOS_IN_REM = 3;
const SPACE_BETWEEN_LOGO_ROWS = 1.625;
export const LogoSet = styled.div`
    /* the negative margins below offset the margins that were 
    added between and under the logo SVGS. it was done this way
    to flexibly handle the logos wrapping to the next row. */
    margin: 0 -${SPACE_BETWEEN_LOGOS_IN_REM / 2}rem -${SPACE_BETWEEN_LOGO_ROWS}rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;

    svg {
        margin-left: ${SPACE_BETWEEN_LOGOS_IN_REM / 2}rem;
        margin-right: ${SPACE_BETWEEN_LOGOS_IN_REM / 2}rem;
        margin-bottom: ${SPACE_BETWEEN_LOGO_ROWS}rem;
    }
`;

/* -------------------------------------------------------------------------- */
/*                                 CTA SECTION                                */
/* -------------------------------------------------------------------------- */

export const CtaSectionLogoAndTextContainer = styled.div`
    @media (min-width: ${breakpoints.tablet.rem}) {
        display: flex;
        align-items: center;
    }
`;

export const CtaSectionLingoLogo = styled(Lingo)`
    height: 1.375rem;
    width: auto;
    margin-bottom: 1rem;

    @media (min-width: ${breakpoints.tablet.rem}) {
        height: 3.125rem;
        margin-right: 1rem;
        margin-bottom: 0;
    }
`;

export const CtaSectionPrimaryText = styled(Heading)``;
export const CtaSectionSecondaryText = styled(Heading)`
    color: ${colors.secondary.oolong};
`;

export const HideTextOnTablet = styled.span`
    @media (min-width: ${breakpoints.tablet.rem}) {
        @media (max-width: ${breakpoints.beforeDesktop.rem}) {
            display: none;
        }
    }
`;

export const CtaSectionButton = styled(Button).attrs({
    size: "md",
    width: "100%"
})`
    display: flex;

    &:first-child {
        margin-right: 1rem;
    }
    // TODO: width from Robin?

    @media (min-width: ${breakpoints.tablet.rem}) {
        width: 8.5rem;
    }

    @media (min-width: ${breakpoints.tablet.rem}) {
        width: 8.75rem;
    }
`;
