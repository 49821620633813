import styled from "styled-components";

export const HomeLeftCol = styled.div``;
export const HomeRightCol = styled.div``;

export const HomeLayoutStyled = styled.div`
    display: flexbox;
    justify-content: space-between;

    padding: 3rem 0 5rem 0;
    margin: 0 auto;

    width: 100%;
    max-width: 105rem;

    ${HomeLeftCol} {
        width: 35%;
    }

    ${HomeRightCol} {
        width: 61%;
    }
`;
