import { createSlice, createSelector } from "@reduxjs/toolkit";

import {
    ICONS,
    ICON_SETS,
    PHOTOS,
    BROWSE_LIST_TYPE,
    SEARCH_LIST_TYPE
} from "core/constants";
import {
    OPEN_SEARCH_LIMIT,
    ICONS_LIMIT,
    COLLECTIONS_LIMIT,
    PHOTOS_LIMIT,
    MAX_ICONS_PAGE,
    MAX_COLLECTIONS_PAGE,
    MAX_PHOTOS_PAGE,
    ICONS_DOM_PAGE_LIMIT,
    COLLECTIONS_DOM_PAGE_LIMIT,
    PHOTOS_DOM_PAGE_LIMIT
} from "ssr/screens/BrowsePage/constants";
import { trackQuickView } from "core/trackEvent";
import { makeTitleCase } from "ssr/helpers";

const CACHE_DATA = "browse-page-data";

const QV_URL_KEY = "qv";

export const ICONS_CACHE = "icons";
export const COLLECTIONS_CACHE = "collections";
export const PHOTOS_CACHE = "photos";

const initialState = {
    pageType: BROWSE_LIST_TYPE, // BROWSE_LIST_TYPE, SEARCH_LIST_TYPE
    activeType: null, // ICONS, ICON_SETS, PHOTOS
    searchTerm: "", // raw url browse term or search query
    dataMeta: "", // benchmarks: cache ms, [db ms]
    isFetchingNext: false,
    isFetchingPrev: false,
    quickViewId: null, // item id - icon, photo, etc.

    // icons
    iconTerm: null, // { name, slug } if valid, `null` if not found
    hasFetchedIcons: false,
    onIconsPage: 1,
    iconsTotal: 0,
    allIconsData: [], // [{ page: 1, results: [...] }]
    iconTags: null, // [{ name, slug, url, count }, ...]
    styleFilters: null, // { style, weight }

    // collections
    collectionTerm: null,
    hasFetchedCollections: false,
    onCollectionsPage: 1,
    collectionsTotal: 0,
    allCollectionsData: [],

    // photos
    photoTerm: null,
    hasFetchedPhotos: false,
    onPhotosPage: 1,
    photosTotal: 0,
    allPhotosData: [],
    photoTags: null
};

const styleFiltersToParams = styleFilters => {
    if (!styleFilters) {
        return {};
    }
    const { style, weight } = styleFilters;
    const params = {};
    if (style?.length > 0) {
        params.style = style.join(",");
    }
    if (weight?.length === 2) {
        params.weight = weight.join("-");
    }
    return params;
};

const setAssetTypeState = (state, assetType, data) => {
    if (assetType === ICONS) {
        state.iconTerm = data.iconTerm || null;
        state.hasFetchedIcons = true;
        state.iconsTotal = Number(data.iconsTotal) || 0;
        state.onIconsPage = Number(data.onIconsPage || 1);
        state.iconTags = data.iconTags || null;
        state.iconFacets = data.iconFacets || null;
        const { iconItems } = data;
        if (Array.isArray(iconItems) && iconItems.length) {
            state.allIconsData = [
                {
                    page: state.onIconsPage,
                    results: iconItems
                }
            ];
        }
    }

    if (assetType === ICON_SETS) {
        state.collectionTerm = data.collectionTerm || null;
        state.hasFetchedCollections = true;
        state.collectionsTotal = Number(data.collectionsTotal) || 0;
        state.onCollectionsPage = Number(data.onCollectionsPage || 1);
        const { collectionItems } = data;
        if (Array.isArray(collectionItems) && collectionItems.length) {
            state.allCollectionsData = [
                {
                    page: state.onCollectionsPage,
                    results: collectionItems
                }
            ];
        }
    }

    if (assetType === PHOTOS) {
        state.photoTerm = data.photoTerm || null;
        state.hasFetchedPhotos = true;
        state.photosTotal = Number(data.photosTotal) || 0;
        state.onPhotosPage = Number(data.onPhotosPage || 1);
        state.photoTags = data.photoTags || null;
        const { photoItems } = data;
        if (Array.isArray(photoItems) && photoItems.length) {
            state.allPhotosData = [
                {
                    page: state.onPhotosPage,
                    results: photoItems
                }
            ];
        }
    }
};

const saveData = state => {
    const dataFields = {};
    switch (state.activeType) {
        case ICONS:
            dataFields[ICONS_CACHE] = {
                page: state.onIconsPage,
                data: state.allIconsData
            };
            break;
        case ICON_SETS:
            dataFields[COLLECTIONS_CACHE] = {
                page: state.onCollectionsPage,
                data: state.allCollectionsData
            };
            break;
        case PHOTOS:
            dataFields[PHOTOS_CACHE] = {
                page: state.onPhotosPage,
                data: state.allPhotosData
            };
            break;
    }
    localStorage.setItem(
        CACHE_DATA,
        JSON.stringify({
            slug: state.searchTerm,
            styleFilters: state.styleFilters,
            pageType: state.pageType,
            activeType: state.activeType,
            ...dataFields
        })
    );
};

const readData = () => {
    try {
        const json = localStorage.getItem(CACHE_DATA);
        if (json) {
            return JSON.parse(json) || null;
        }
        return null;
    } catch (e) {
        return null;
    }
};

const getFirstPage = data => {
    if (Array.isArray(data) && data.length && data[0]) {
        return data[0];
    }
    return null;
};

const filtersAreEqual = (a, b) => {
    try {
        return JSON.stringify(a) === JSON.stringify(b);
    } catch (e) {
        return false;
    }
};

export const browsePageSlice = createSlice({
    name: "browsePageSlice",
    initialState,
    reducers: {
        populateSliceOnLoad(state, action) {
            state.pageType = action.payload.pageType || BROWSE_LIST_TYPE;
            state.activeType = action.payload.activeType;
            state.searchTerm = action.payload.searchTerm;
            setAssetTypeState(state, state.activeType, action.payload);
            state.styleFilters = action.payload.styleFilters || null;
            state.dataMeta = action.payload.dataMeta || "";
        },
        restoreState(state) {
            const data = readData();
            if (
                data &&
                data.slug === state.searchTerm &&
                filtersAreEqual(data.styleFilters, state.styleFilters) &&
                data.pageType === state.pageType &&
                data.activeType === state.activeType
            ) {
                // restore photos
                if (
                    data.activeType === PHOTOS &&
                    state.onPhotosPage > 1 &&
                    data[PHOTOS_CACHE]?.page === state.onPhotosPage
                ) {
                    state.allPhotosData = data[PHOTOS_CACHE].data;
                }
                // restore icons
                if (
                    data.activeType === ICONS &&
                    state.onIconsPage > 1 &&
                    data[ICONS_CACHE]?.page === state.onIconsPage
                ) {
                    state.allIconsData = data[ICONS_CACHE].data;
                }
                // restore collections
                if (
                    data.activeType === ICON_SETS &&
                    state.onCollectionsPage > 1 &&
                    data[COLLECTIONS_CACHE]?.page === state.onCollectionsPage
                ) {
                    state.allCollectionsData = data[COLLECTIONS_CACHE].data;
                }
            }
            // restore quick-view modal, if exists in URL
            if (typeof window === "object") {
                const params = new URLSearchParams(window.location.search);
                if (params.has(QV_URL_KEY)) {
                    state.quickViewId = params.get(QV_URL_KEY);
                } else {
                    state.quickViewId = null;
                }
            }
        },
        setInactiveTypeData(state, action) {
            setAssetTypeState(state, action.payload.assetType, action.payload);
        },
        restoreStyleFilters(state, action) {
            // only restore if not already set (ie from URL params)
            if (!state.styleFilters) {
                state.styleFilters = action.payload;
            }
        },
        changeActiveType(state, action) {
            state.activeType = action.payload;
            state.isFetchingPrev = false;
            state.isFetchingNext = false;
        },
        startFetching(state, action) {
            if (action.payload && action.payload.previous) {
                state.isFetchingPrev = true;
            } else {
                state.isFetchingNext = true;
            }
        },
        addResults(state, action) {
            const { activeType } = state;
            const dataKey =
                activeType === ICONS
                    ? "allIconsData"
                    : activeType === ICON_SETS
                    ? "allCollectionsData"
                    : "allPhotosData";
            const pageKey =
                activeType === ICONS
                    ? "onIconsPage"
                    : activeType === ICON_SETS
                    ? "onCollectionsPage"
                    : "onPhotosPage";
            const firstPage = getFirstPage(state[dataKey]);
            const newPage = {
                page: Number(action.payload.page),
                results: action.payload.data
            };
            if (firstPage && newPage.page < firstPage.page) {
                // adding previous page
                state[dataKey] = [newPage, ...state[dataKey]];
                state.isFetchingPrev = false;
            } else {
                // adding next page
                state[pageKey] = newPage.page;
                state[dataKey] = [...state[dataKey], newPage];
                state.isFetchingNext = false;
            }
            saveData(state);
        },
        changeQuickView(state, action) {
            const prevId = state.quickViewId;

            if (action.payload && action.payload.id) {
                state.quickViewId = action.payload.id;
            } else {
                state.quickViewId = null;
            }

            // Update URL query
            if (typeof window === "object") {
                const params = new URLSearchParams(window.location.search);
                if (state.quickViewId) {
                    params.set(QV_URL_KEY, state.quickViewId);
                } else {
                    params.delete(QV_URL_KEY);
                }
                const nextParams = params.toString();
                const pathname = window.location.pathname;
                const nextUrl = pathname + (nextParams ? "?" + nextParams : "");
                window.history.replaceState(null, "", nextUrl);
            }

            // Add GA4 tracking - if opening QV or changing icon; ignore closing event
            if (action.payload) {
                trackQuickView({ isOpening: !prevId });
            }
        }
    }
});

// selectors
const selectSelf = state => state.browse;

export const getStyleParams = createSelector(selectSelf, state => {
    return styleFiltersToParams(state?.styleFilters);
});

export const getBrowseData = createSelector(selectSelf, state => {
    if (!state) {
        return {};
    }
    const activeData =
        state.activeType === ICONS
            ? state.allIconsData
            : state.activeType === ICON_SETS
            ? state.allCollectionsData
            : state.allPhotosData;
    const activePage =
        state.activeType === ICONS
            ? state.onIconsPage
            : state.activeType === ICON_SETS
            ? state.onCollectionsPage
            : state.onPhotosPage;
    const allActiveData = Array.isArray(activeData)
        ? activeData.reduce((all, page) => {
              if (page && Array.isArray(page.results) && page.results.length) {
                  return all.concat(page.results);
              }
              return all;
          }, [])
        : [];

    const activeRelatedTags =
        state.activeType === ICON_SETS
            ? null
            : state.activeType === ICONS
            ? state.iconTags
            : state.photoTags;

    const iconFacets = state.iconFacets || {};

    const quickViewItemIndex = !!state.quickViewId
        ? allActiveData.map(item => item.id).indexOf(state.quickViewId)
        : -1;
    const quickViewItem =
        quickViewItemIndex >= 0 ? allActiveData[quickViewItemIndex] : null;

    const isSearchPage = state.pageType === SEARCH_LIST_TYPE;
    const isIconsPage = state.activeType === ICONS;
    const isCollectionsPage = state.activeType === ICON_SETS;

    const activeTotal = isIconsPage
        ? state.iconsTotal
        : isCollectionsPage
        ? state.collectionsTotal
        : state.photosTotal;

    const _activeTerm =
        state.activeType === ICONS
            ? state.iconTerm
            : state.activeType === ICON_SETS
            ? state.collectionTerm
            : state.photoTerm;
    const isTermIndexable = !!_activeTerm;
    const slug = _activeTerm?.slug || state.searchTerm;
    const formattedTerm = isSearchPage
        ? state.searchTerm
        : makeTitleCase(_activeTerm?.name || state.searchTerm);

    // Calculate the last possible index for QuickView carousel.
    const fetchLimit = Math.min(activeTotal, OPEN_SEARCH_LIMIT);
    const firstPage = getFirstPage(activeData) || {};
    const firstPageNumber = Number(firstPage.page) || 1;
    const itemsPerPage = isIconsPage
        ? ICONS_LIMIT
        : isCollectionsPage
        ? COLLECTIONS_LIMIT
        : PHOTOS_LIMIT;
    // (if starting on page 2+, decrease the fetchLimit by whatever previous pages haven't been fetched yet)
    const indexOffset = (firstPageNumber - 1) * itemsPerPage;
    const lastQuickViewIndex = Math.max(0, fetchLimit - indexOffset - 1);

    const lastIconsPage = Math.min(
        MAX_ICONS_PAGE,
        Math.ceil(state.iconsTotal / ICONS_LIMIT)
    );
    const lastCollectionsPage = Math.min(
        MAX_COLLECTIONS_PAGE,
        Math.ceil(state.collectionsTotal / COLLECTIONS_LIMIT)
    );
    const lastPhotosPage = Math.min(
        MAX_PHOTOS_PAGE,
        Math.ceil(state.photosTotal / PHOTOS_LIMIT)
    );

    const canLoadMore = isIconsPage
        ? state.onIconsPage < lastIconsPage
        : isCollectionsPage
        ? state.onCollectionsPage < lastCollectionsPage
        : state.onPhotosPage < lastPhotosPage;

    // At a certain point, it's better to navigate to the next page
    // instead of adding more and more results to the DOM.
    const activeDomPageLimit =
        state.activeType === ICONS
            ? ICONS_DOM_PAGE_LIMIT
            : state.activeType === ICON_SETS
            ? COLLECTIONS_DOM_PAGE_LIMIT
            : PHOTOS_DOM_PAGE_LIMIT;
    const canAddMorePages = activeDomPageLimit > activeData.length;

    return {
        ...state,
        // add computed props
        isSearchPage,
        isIconsPage,
        isCollectionsPage,
        lastIconsPage,
        lastCollectionsPage,
        lastPhotosPage,
        canLoadMore,
        canAddMorePages,
        activeTotal,
        activeData,
        allActiveData,
        activeRelatedTags,
        activePage,
        slug,
        formattedTerm,
        isTermIndexable,
        quickViewItemIndex,
        quickViewItem,
        lastQuickViewIndex,
        iconFacets,
        styleParams: styleFiltersToParams(state.styleFilters)
    };
});

export const getActiveType = state => state.browse.activeType;

export const {
    populateSliceOnLoad,
    restoreState,
    setInactiveTypeData,
    restoreStyleFilters,
    changeActiveType,
    startFetching,
    addResults,
    changeQuickView
} = browsePageSlice.actions;
export default browsePageSlice.reducer;
