import React from "react";
import PropTypes from "prop-types";

import {
    CREATOR_DASHBOARD_PHOTOS_REDIRECT,
    ICON_DASHBOARD,
    ICON_UPLOADS,
    PHOTO_UPLOADS,
    ICON_CREATOR_HANDBOOK,
    PHOTO_CREATOR_HANDBOOK
} from "data/links";

const CreatorProfileMenu = ({
    linkComponent,
    creatorUsername,
    isPhotoCreator,
    isIconCreator,
    isHeroSearchV2
}) => {
    const Link = linkComponent;
    const linkProps = {
        size: "md",
        styleType: "secondary",
        darkMode: !isHeroSearchV2,
        disableVisitedStyle: true
    };

    const sanitizedUsername = encodeURIComponent(creatorUsername);
    const photoDashboardHref = `${CREATOR_DASHBOARD_PHOTOS_REDIRECT.replace(
        ":username",
        sanitizedUsername
    )}`;

    return (
        <>
            {isIconCreator && <Link href={ICON_DASHBOARD}>Icon Dashboard</Link>}
            {isPhotoCreator && (
                <Link href={photoDashboardHref} {...linkProps}>
                    Photo Dashboard
                </Link>
            )}
            {isIconCreator && (
                <Link href={ICON_UPLOADS} {...linkProps}>
                    Submit Icons
                </Link>
            )}
            {isPhotoCreator && (
                <Link href={PHOTO_UPLOADS} {...linkProps}>
                    Submit Photos
                </Link>
            )}
            {isIconCreator && (
                <Link
                    href={ICON_CREATOR_HANDBOOK}
                    {...linkProps}
                    target="_blank"
                >
                    Icon Handbook
                </Link>
            )}
            {isPhotoCreator && (
                <>
                    <Link
                        href={PHOTO_CREATOR_HANDBOOK}
                        {...linkProps}
                        target="_blank"
                    >
                        Photo Handbook
                    </Link>
                </>
            )}
        </>
    );
};

CreatorProfileMenu.propTypes = {
    linkComponent: PropTypes.elementType.isRequired,
    creatorUsername: PropTypes.string.isRequired,
    isPhotoCreator: PropTypes.bool.isRequired,
    isIconCreator: PropTypes.bool.isRequired
};

export default CreatorProfileMenu;
