import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import SearchAndBrowsePageHeadline from "core/SearchAndBrowsePageHeadline";
import AvatarPlaceholderImg from "core/AvatarPlaceholderImg";
import Avatar from "core/Avatar";

import { convertPxToRem } from "frontend/components/helpers";

let SearchAndBrowsePageHeadlineWithAvatar = ({
    className,
    children,
    avatarUrl,
    avatarTitle = "Creator avatar",
    showSkeleton
}) => {
    return (
        <SearchAndBrowsePageHeadline
            showSkeleton={showSkeleton}
            className={className}
        >
            {!!avatarUrl ? (
                <Avatar
                    src={avatarUrl}
                    title={avatarTitle}
                    height={convertPxToRem({ px: 30 })}
                    width={convertPxToRem({ px: 30 })}
                    rightMargin={convertPxToRem({ px: 8 })}
                />
            ) : (
                <AvatarPlaceholderImg title={avatarTitle} />
            )}
            <span>{children}</span>
        </SearchAndBrowsePageHeadline>
    );
};

SearchAndBrowsePageHeadlineWithAvatar.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    avatarUrl: PropTypes.string.isRequired,
    showSkeleton: PropTypes.bool
};

SearchAndBrowsePageHeadlineWithAvatar = styled(
    SearchAndBrowsePageHeadlineWithAvatar
)`
    display: flex;
    align-items: center;
`;

export default SearchAndBrowsePageHeadlineWithAvatar;
