import React, { useState } from "react";
import PropTypes from "prop-types";

import TrashIcon from "core/ReusableIcons/Trash";
import DeletePhotosModal from "./DeletePhotosModal";
import Link from "core/Link";

const DeletePhotosButton = ({
    quantitySelected,
    uploadsSelected,
    getSelectedUploadIds,
    deselectAll
}) => {
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

    if (quantitySelected < 1) return null;

    return (
        <>
            <Link size="sm" isButton onClick={() => setDeleteModalIsOpen(true)}>
                <TrashIcon />
                {` Delete ${quantitySelected} Photo${
                    quantitySelected > 1 ? "s" : ""
                }`}
            </Link>
            <DeletePhotosModal
                isDeletePhotoModalOpen={deleteModalIsOpen}
                onClose={() => setDeleteModalIsOpen(false)}
                numPhotosToDelete={quantitySelected}
                uploadsSelected={uploadsSelected}
                getSelectedUploadIds={getSelectedUploadIds}
                deselectAll={deselectAll}
            />
        </>
    );
};

DeletePhotosButton.propTypes = {
    quantitySelected: PropTypes.number,
    uploadsSelected: PropTypes.object,
    getSelectedUploadIds: PropTypes.func,
    deselectAll: PropTypes.func
};

export default DeletePhotosButton;
