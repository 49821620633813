import React from "react";
import PropTypes from "prop-types";

import {
    spacingSizes,
    headingSizes,
    alignments,
    headingTypes
} from "core/props";

import TextContent from "./TextContent";
import ImageContent from "./ImageContent";

import { CollectionSpotlightGridItem } from "./styles";

const ImageOnLeft = ({
    buttonProps,
    headingType,
    headingSize,
    headingAlignment,
    headingTopSpacing,
    headingBottomSpacing,
    headingColor,
    image,
    imageCaption,
    imageAlt,
    collectionHeading,
    collectionText,
    collectionQuoteText,
    collectionQuoteAuthor,
    collectionLink,
    mediumScreenSizeGridSpan,
    imageLink
}) => {
    return (
        <>
            <CollectionSpotlightGridItem
                item
                sm={12}
                md={mediumScreenSizeGridSpan}
                lg={6}
            >
                <ImageContent
                    image={image}
                    imageAlt={imageAlt}
                    imageCaption={imageCaption}
                    imageLink={imageLink}
                    collectionLink={collectionLink}
                />
            </CollectionSpotlightGridItem>
            <CollectionSpotlightGridItem
                item
                sm={12}
                md={mediumScreenSizeGridSpan}
                lg={6}
            >
                <TextContent
                    headingType={headingType}
                    headingSize={headingSize}
                    headingAlignment={headingAlignment}
                    headingTopSpacing={headingTopSpacing}
                    headingBottomSpacing={headingBottomSpacing}
                    headingColor={headingColor}
                    collectionHeading={collectionHeading}
                    collectionText={collectionText}
                    collectionQuoteText={collectionQuoteText}
                    collectionQuoteAuthor={collectionQuoteAuthor}
                    collectionLink={collectionLink}
                    buttonProps={buttonProps}
                />
            </CollectionSpotlightGridItem>
        </>
    );
};

ImageOnLeft.propTypes = {
    buttonProps: PropTypes.object,
    headingType: PropTypes.oneOf(headingTypes),
    headingSize: PropTypes.oneOf(headingSizes),
    headingAlignment: PropTypes.oneOf(alignments),
    headingTopSpacing: PropTypes.oneOf(spacingSizes),
    headingBottomSpacing: PropTypes.oneOf(spacingSizes),
    headingColor: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    imageCaption: PropTypes.string,
    imageAlt: PropTypes.string.isRequired,
    collectionHeading: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    collectionText: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    collectionQuoteText: PropTypes.string,
    collectionQuoteAuthor: PropTypes.string,
    collectionLink: PropTypes.array,
    mediumScreenSizeGridSpan: PropTypes.number.isRequired
};

export default ImageOnLeft;
