import React from "react";

import colors from "core/colors";
import BaseIcon from "./BaseIcon";

const Close = ({
    size = null,
    ariaLabel = "close",
    ariaHidden = false,
    fillColor,
    fillColorRule,
    className
}) => {
    return (
        <BaseIcon
            size={size}
            fillColor={fillColor}
            fillColorRule={fillColorRule}
            aria-hidden={ariaHidden ? "true" : "false"}
            aria-label={ariaLabel}
            className={className}
        >
            <path
                fill={colors.secondary.orca}
                fillRule="evenodd"
                d="M20.646 5.452a.5.5 0 000-.707l-1.391-1.391a.5.5 0 00-.707 0L12 9.9 5.452 3.354a.5.5 0 00-.707 0L3.354 4.745a.5.5 0 000 .707L9.9 12l-6.547 6.548a.5.5 0 000 .707l1.391 1.391a.5.5 0 00.707 0L12 14.1l6.548 6.547a.5.5 0 00.707 0l1.392-1.391a.5.5 0 000-.707L14.098 12l6.547-6.548z"
                clipRule="evenodd"
                key="key-0"
            />
        </BaseIcon>
    );
};

export default Close;
