import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";

// redux
import { setCurrentQueue } from "../../slices/currentQueueSlice";

// data
import { QUEUES } from "frontend/components/fort/mod/photos/constants/index.js";

// Layout
import QueueLayout, {
    QueueHeader,
    QueueMain,
    QueueAside
} from "frontend/components/fort/mod/core/Layouts/QueueLayout";

// hooks/context: Order: Parent -> Child -> Child ...
import { LoadMoreButton } from "./QueueProviders/BatchesProvider/index.js";
import QueueProviders from "./QueueProviders/index";

// components
import LoadingSpinner from "core/LoadingSpinner";
import PhotoModQueueHeader from "../PhotoModQueueHeader";
import PhotoModQueueMasthead from "../PhotoModQueueMasthead";
import PhotoModBatches from "../PhotoModBatches";
import PhotoModQueueSidebar from "../PhotoModQueueSidebar";
import PhotoModExpandedModal from "../PhotoModExpandedModal";

// styles
import { convertPxToRem } from "frontend/components/helpers";

export const PhotoModQueuePageContentStyled = styled.div`
    padding: ${convertPxToRem(10)};
`;

// component
export const PhotoModQueuePageContent = ({ currentQueue = QUEUES.VIS_MOD }) => {
    // Whenever we change Queue Layout routes, we need to get currentQueue before we render the rest.
    const dispatch = useDispatch();
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const loadState = async () => {
            await dispatch(setCurrentQueue(currentQueue));
            setIsLoaded(true);
        };

        loadState();
    }, [dispatch, currentQueue]);

    if (!isLoaded) {
        return <LoadingSpinner />;
    }

    return (
        <QueueProviders>
            <PhotoModQueuePageContentStyled>
                <QueueLayout>
                    <QueueHeader>
                        <PhotoModQueueHeader />
                    </QueueHeader>
                    <QueueMain>
                        <PhotoModQueueMasthead />
                        <PhotoModBatches />
                        <LoadMoreButton />
                    </QueueMain>
                    <QueueAside>
                        <PhotoModQueueSidebar />
                    </QueueAside>
                    <PhotoModExpandedModal />
                </QueueLayout>
            </PhotoModQueuePageContentStyled>
        </QueueProviders>
    );
};

export default PhotoModQueuePageContent;

// prop-types
PhotoModQueuePageContent.propTypes = {
    currentQueue: PropTypes.string
};
