import React from "react";
import PropTypes from "prop-types";

import { GroupContainer, Button, Label } from "./styles";

export const ButtonGroup = ({ buttons, className }) => {
    const renderButtons = buttons.map(
        ({ label, ariaLabel, isActive, href, onClick }, idx) => (
            <Button
                as={href ? "a" : "button"}
                type={href ? undefined : "button"}
                aria-label={ariaLabel}
                $isActive={isActive}
                $groupLength={buttons.length}
                tabindex="0"
                data-testid="button-group-item"
                key={idx}
                href={href}
                onClick={onClick}
            >
                <Label>{label}</Label>
            </Button>
        )
    );

    return (
        <GroupContainer
            className={className}
            role="group"
            aria-label="button group"
        >
            {renderButtons}
        </GroupContainer>
    );
};

ButtonGroup.propTypes = {
    buttons: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
                .isRequired,
            isActive: PropTypes.bool.isRequired,
            onClick: PropTypes.func,
            href: PropTypes.string
        })
    ).isRequired,
    className: PropTypes.string
};
export default ButtonGroup;
