import React from "react";
import PropTypes from "prop-types";

import { usePhotoSubmissionStatus } from "../../PhotoUploadPage/PhotoUploadCore/hooks";

import PhotoUploadMastheadTooltip from "../PhotoUploadCore/PhotoUploadMastheadTooltip";
import PhotoUploadInactiveTabMessage from "../PhotoUploadInactiveTabMessage";

import Flexbox from "core/Flexbox";

import {
    MastheadContainer,
    MastheadHeading,
    MastheadSecondaryText,
    MastheadHeadingContainer
} from "../PhotoUploadCore/styles";
import { MastheadBannerContainer } from "./styles";

const PhotoUploadIndividualMasthead = ({ uploadsInDropzoneCount }) => {
    const { batchPhotoSubmissionBalance } = usePhotoSubmissionStatus();

    return (
        <MastheadContainer>
            <Flexbox justifyContent="space-between" alignItems="center">
                <MastheadHeadingContainer>
                    <MastheadHeading>Upload Individual Photos</MastheadHeading>
                    <PhotoUploadMastheadTooltip />
                </MastheadHeadingContainer>
                <MastheadSecondaryText>
                    Submit up to {batchPhotoSubmissionBalance} photos
                </MastheadSecondaryText>
            </Flexbox>
            <MastheadBannerContainer>
                {/* reinstate inactive tab message when FAQ link is ready */}
                {/* <PhotoUploadInactiveTabMessage
                    uploadsInDropzoneCount={uploadsInDropzoneCount}
                /> */}
            </MastheadBannerContainer>
        </MastheadContainer>
    );
};

PhotoUploadInactiveTabMessage.propTypes = {
    uploadsInDropzoneCount: PropTypes.number.isRequired
};

export default PhotoUploadIndividualMasthead;
