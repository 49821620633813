import React from "react";
import PropTypes from "prop-types";

// components
import BatchToggle from "./BatchToggle";
import BatchProfile from "./BatchProfile";
import MetaBatch from "./MetaBatch";

// styles
import { BatchHeaderStyled } from "./styles";

export const BatchHeader = ({
    children,
    batch,
    onBatchToggle,
    batchContentIsOpen,
    backgroundColor,
    backgroundIcon
}) => {
    const BgIcon = backgroundIcon;

    return (
        <BatchHeaderStyled backgroundColor={backgroundColor} batch={batch}>
            {/* placing BgIcon here to leverage natural sort order z-index */}
            {BgIcon && <BgIcon size={{ width: 40, height: 40 }} />}
            <MetaBatch batch={batch} />
            <BatchToggle
                batch={batch}
                batchContentIsOpen={batchContentIsOpen}
                onBatchToggle={onBatchToggle}
            />
            <BatchProfile>{children}</BatchProfile>
        </BatchHeaderStyled>
    );
};

BatchHeader.propTypes = {
    children: PropTypes.any,
    batch: PropTypes.object.isRequired,
    onBatchToggle: PropTypes.func.isRequired,
    batchContentIsOpen: PropTypes.bool.isRequired,
    backgroundColor: PropTypes.string.isRequired,
    backgroundIcon: PropTypes.object
};
