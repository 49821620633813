import styled from "styled-components";

import BodyText from "core/BodyText";

import { convertPxToRem } from "frontend/components/helpers";
import colors from "core/colors";
import breakpoints from "core/breakpoints";

export const RoyaltiesNoActivityBodyText = styled(BodyText).attrs({
    type: "p",
    size: "lg"
})`
    margin-top: ${convertPxToRem({ px: 8 })};
`;

export const StatsSecondaryInfoText = styled(BodyText).attrs({
    size: "lg",
    copyColor: colors.secondary.orca
})``;

export const StatsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: ${convertPxToRem({ px: 24 })};

    @media (max-width: ${breakpoints.beforeTablet.rem}) {
        display: block;
    }

    & > div {
        &:not(:last-of-type) {
            margin-bottom: ${convertPxToRem({ px: 32 })};
        }
    }
`;
