import React from "react";
import PropTypes from "prop-types";

// styles
import {
    ModeratorOptionStyled,
    ModOptAvatarStyled,
    ModOptDisplayStyled
} from "./styles.js";

const ModeratorOption = ({ moderator }) => {
    const { displayName, avatarUrl } = moderator;

    return (
        <ModeratorOptionStyled>
            <ModOptAvatarStyled>
                <img src={avatarUrl} alt={displayName} />
            </ModOptAvatarStyled>{" "}
            <ModOptDisplayStyled>{displayName}</ModOptDisplayStyled>
        </ModeratorOptionStyled>
    );
};

export default ModeratorOption;

// prop-types
ModeratorOption.propTypes = {
    moderator: PropTypes.object
};
