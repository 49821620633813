import styled, { css } from "styled-components";

// styles
import { focusOutline } from "core/links";
import { convertPxToRem } from "frontend/components/helpers";
import { regular as regularFontWeight } from "core/fontStyles";
import colors from "core/colors";

// components
import ProcessingSpinner from "core/ProcessingSpinner";

export const ProcessingSpinnerIcon = styled(ProcessingSpinner)`
    width: ${convertPxToRem({ px: 12 })};
    height: ${convertPxToRem({ px: 12 })};
    margin: 0 0 0 ${convertPxToRem({ px: 8 })};
`;

export const TagWrapper = styled.div`
    display: inline-block;
    margin-top: 0px;
    margin-right: ${convertPxToRem({ px: 16 })};
    margin-bottom: ${convertPxToRem({ px: 16 })};

    &:last-of-type {
        margin-right: 0;
    }
`;

export const sharedTagContainerStyles = css`
    ${regularFontWeight};
    font-size: ${convertPxToRem({ px: 15 })};
    line-height: ${convertPxToRem({ px: 16 })};
    display: inline-flex;
    align-items: center;
    padding: ${convertPxToRem({ px: 6 })} ${convertPxToRem({ px: 16 })};
    cursor: pointer;

    &:focus {
        ${focusOutline}
    }

    ${({ isStrikethrough }) =>
        isStrikethrough &&
        css`
            span {
                text-decoration: line-through;
            }
        `}

    ${({ hasWarning }) =>
        hasWarning &&
        css`
            span {
                color: ${colors.functional.lightMode.firecracker};
            }
        `}
`;
