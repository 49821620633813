export const ADD_TAGS_ERRORS = {
    DUPLICATE: "DUPLICATE",
    TAG_TOO_LONG: "TAG_TOO_LONG",
    TOO_MANY_TAGS: "TOO_MANY_TAGS",
    DISALLOWED: "DISALLOWED",
    INVALID: "INVALID",
    RATE_LIMITED: "RATE_LIMITED"
};

export const maxTagsAllowed = 50;
export const maxTagsError = `You've reached the ${maxTagsAllowed}-tag maximum`;
export const maxTagsBatchError = `Photo(s) in selection already have maximum tags (${maxTagsAllowed}). Remove some tags to update.`;
export const tagDisallowedError = "Tag not saved because term is disallowed.";
export const tagInvalidError =
    "Tag not saved because it contains invalid characters.";
export const maxCharsAllowed = 50;

export const tooManyReleasesErrorMsg =
    "Release(s) denied. 100 release maximum per submission.";

export const PUBLIC_DOMAIN_BE_VALUE = "PUBLICDOMAIN";
export const CREATIVE_COMMONS_BE_VALUE = "CREATIVECOMMONS";
export const PUBLIC_DOMAIN = "Public Domain";
export const CREATIVE_COMMONS = "Creative Commons";

export const titleInvalidCharError = "Edit title contains invalid characters.";

export const errMsgMappings = {
    title: [
        {
            be: "Submitted value contains disallowed terms",
            fe: "Title contains disallowed terms. Please try a different title."
        },
        {
            be: "Submitted value contains disallowed characters",
            fe: "Title not saved because it contains invalid characters."
        }
    ]
};
