import React from "react";

// context
import {
    PanelOverlayProvider,
    PanelOverlay,
    OpenButton
} from "core/SiteHeaderV2/Mobile/components/PanelOverlay/index.js";

// components
import Hamburger from "core/SiteHeaderV2/components/Hamburger.js";

// component
const PrimaryMenu = () => {
    return (
        <PanelOverlayProvider position="left">
            <OpenButton>
                <Hamburger />
            </OpenButton>
            <PanelOverlay>Hamburger</PanelOverlay>
        </PanelOverlayProvider>
    );
};

export default PrimaryMenu;
