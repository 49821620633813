import React from "react";
import PropTypes from "prop-types";

/*---------------------------
| Exposed Components
---------------------------*/
// Header
export { BatchHeader } from "./BatchHeader";

// Body
export { BatchBody } from "./BatchBody";
export { BatchGrid } from "./BatchBody/BatchGrid";

// styles
import { BatchStyled } from "./styles";

const Batch = props => {
    const { children, onBatchSelect, batch, selectedBatchId } = props;

    const { id, paginationItemNumber, page } = batch;

    const onClick = () => {
        if (!selectedBatchId || batch.id !== selectedBatchId) {
            onBatchSelect(batch);
        }
    };

    return (
        <BatchStyled
            onClick={onClick}
            batch={batch}
            selectedBatchId={selectedBatchId}
            data-count={paginationItemNumber}
            data-batchid={id}
            data-page={page}
        >
            {children}
        </BatchStyled>
    );
};

export default Batch;

// prop-types
Batch.propTypes = {
    children: PropTypes.any,
    batch: PropTypes.object.isRequired,
    onBatchSelect: PropTypes.func.isRequired,
    selectedBatchId: PropTypes.string
};
