import React from "react";

// context
import { useModal2 } from "../index.js";

// styles
import { CloseButtonStyled } from "./styles.js";

// components
import CloseIcon from "core/ReusableIcons/Close.js";

const CloseButton = () => {
    const { hideModal } = useModal2();

    return (
        <CloseButtonStyled type="button" onClick={() => hideModal()}>
            <CloseIcon size={{ width: 16, height: 16 }} />
        </CloseButtonStyled>
    );
};

export default CloseButton;
