import React from "react";
import styled from "styled-components";
import { convertPxToRem } from "frontend/components/helpers";

import CC from "core/ReusableIcons/CC";
import Tooltip from "core/Tooltip";

import colors from "core/colors";

export const TooltipText = styled.p`
    display: block;
    margin-bottom: ${convertPxToRem({ px: 8 })};
`;

export default ({ iconColor = colors.secondary.orca, darkMode = false }) => (
    <Tooltip
        title="Creative Commons CC BY 3.0"
        clickToOpen
        darkMode={darkMode}
        message={
            <>
                <TooltipText>CC BY 3.0 License</TooltipText>
                <TooltipText>
                    With any use, you are required to give credit to the artist.
                    Purchase a Pro sub or a one-time option for attribution-free
                    use.
                </TooltipText>
                <a
                    href="https://help.thenounproject.com/hc/en-us/articles/200509928-How-do-I-give-creators-credit-in-my-work"
                    target="_blank"
                >
                    Learn more
                </a>
            </>
        }
    >
        <CC fillColor={iconColor} />
    </Tooltip>
);
