import PropTypes from "prop-types";

import { buildSubgrid } from "./helpers";

const IconGridWithAds = ({
    numberOfRowsBetweenAds,
    iconData,
    numberOfColumns,
    gridSpacingSize,
    useRouterLink
}) => {
    if (!iconData || !iconData.length) return null;

    return buildSubgrid({
        numberOfColumns,
        numberOfRowsBetweenAds,
        gridSpacingSize,
        iconData,
        useRouterLink
    });
};

IconGridWithAds.propTypes = {
    numberOfRowsBetweenAds: PropTypes.number.isRequired,
    iconData: PropTypes.array,
    numberOfColumns: PropTypes.number.isRequired,
    gridSpacingSize: PropTypes.string.isRequired
};

export default IconGridWithAds;
