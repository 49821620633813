import styled from "styled-components";

// styles
import Heading from "core/Heading";
import BodyText from "core/BodyText";
import colors from "core/colors";
import breakpoints from "core/breakpoints";
import { convertPxToRem } from "frontend/components/helpers";
import {
    textLg,
    regular as regularFontWeight,
    medium as mediumFontWeight
} from "core/fontStyles";
import { handleHeadingSize } from "js/helpers/spacingAndFonts";
import { getLinkElementStyles } from "core/Link/styles";

// styled-components
export const SortStyled = styled.div``;
