import { useLazyQuery } from "@apollo/client";
import { apolloApi } from "./api/index.js";

export const useApolloLazyQuery = props => {
    const { gql } = props;

    const [apiCall, { loading }] = useLazyQuery(gql);

    const apolloQuery = apolloApi({
        actionDesc: "Make Apollo Query Hook",
        ...props,
        apiCall
    });

    return {
        apolloQuery,
        loading
    };
};
