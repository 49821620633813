import styled, { css } from "styled-components";

import { easingSeconds } from "core/animation";
import { bold as boldStyles } from "core/fontStyles";
import fonts from "core/fonts";
import colors, { focusColor } from "core/colors";
// helpers
import { convertPxToRem } from "frontend/components/helpers";

const Link = styled.a`
    text-decoration: underline;
`;

const textStyles = css`
    ${({ size }) =>
        size === "inherit" ? "font-size: inherit" : fonts.typeScale[size]};
    ${({ bold }) => (bold ? boldStyles : null)};
    color: ${({ color }) => (color ? color : "inherit")};
`;

const A = styled.a`
    ${textStyles};
    text-decoration: underline;
`;

/* use for text that looks like a link,
but doesn't actually take the user to a new page */
const ButtonLink = styled.button`
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    ${textStyles};
    text-decoration: underline;
`;

/* use for an anchor tag that has a border-bottom instead of an underline;
    style is exported in case we need to add it to a link.
    Exported the hover because we need only the hover state for some links
*/

const getDefaultLinkColor = ({ mode, linkType }) => {
    if (mode === "dark" && linkType === "primary")
        return colors.accent.darkMode.suenosBuenos;

    return colors.secondary.oolong;
};

const getHoverLinkColor = ({ mode, linkType }) => {
    if (mode === "dark" && linkType === "primary")
        return colors.primary.diamond;

    return colors.secondary.cosmic;
};

export const BorderBottomLinkStylesDefault = css`
    color: ${({ mode, linkType }) => getDefaultLinkColor({ mode, linkType })};
    text-decoration: none;
    padding-bottom: 0.1875rem; /* 3px */
    border-bottom: 1px solid
        ${({ mode, linkType }) => getDefaultLinkColor({ mode, linkType })};
    transition: all ${easingSeconds};
`;

export const BorderBottomLinkStylesHover = css`
    text-decoration: none;
    color: ${({ mode, linkType }) => getHoverLinkColor({ mode, linkType })};
    border-bottom: 1px solid
        ${({ mode, linkType }) => getHoverLinkColor({ mode, linkType })};
`;

export const BorderBottomLinkStyle = css`
    ${BorderBottomLinkStylesDefault}
    &:hover {
        ${BorderBottomLinkStylesHover}
    }
`;

export const BorderBottomLink = styled.a`
    ${BorderBottomLinkStyle}
`;

const P = styled.p`
    ${textStyles};
`;

// use headings.js instead of these headings - we will deprecate these:
const H1 = styled.h1`
    ${textStyles};
`;

const H2 = styled.h2`
    ${textStyles};
`;

const H3 = styled.h3`
    ${textStyles};
`;

const CustomFocusStyle = css`
    /* focus transition */
    outline: 0px solid ${focusColor};
    transition: outline ${easingSeconds} ease-in;

    &:focus {
        outline: 3px solid ${focusColor};
    }
`;

const Italic = styled.span`
    font-style: italic;
`;

export const BlueBorderBottomLinkStylesDefault = css`
    padding-bottom: ${convertPxToRem({ px: 1 })};
    text-decoration: none;
    color: ${colors.accent.lightMode.persian};
    border-bottom: 1px solid ${colors.accent.lightMode.persian};
    transition: all ${easingSeconds};
`;

export const BlueBorderBottomLinkStylesHover = css`
    text-decoration: none;
    color: ${colors.secondary.cosmic};
    border-color: ${colors.secondary.cosmic};
`;

export const BlueBorderBottomLinkStyle = css`
    &&,
    &:visited {
        ${BlueBorderBottomLinkStylesDefault}

        &:hover {
            ${BlueBorderBottomLinkStylesHover}
        }
    }
`;

export const BlueBorderBottomLink = styled.a`
    ${BlueBorderBottomLinkStyle}
`;

export { Link, A, P, H1, H2, H3, ButtonLink, CustomFocusStyle, Italic };
