import React from "react";
import PropTypes from "prop-types";

// styles
import { FileNameStyled } from "./styles.js";

// components
import TruncateText from "core/TruncateText";

const FileName = ({ showFileName = true, isHovered, name }) => {
    return (
        showFileName &&
        isHovered && (
            <FileNameStyled>
                <TruncateText charLimit={15}>{name}</TruncateText>
            </FileNameStyled>
        )
    );
};

export default FileName;

// prop-types
FileName.propTypes = {
    showFileName: PropTypes.bool,
    isHovered: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired
};
