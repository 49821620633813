import React from "react";
import PropTypes from "prop-types";

import { OptionButton } from "./styles";

const Button = ({
    selectedButtonText,
    text,
    setSelectedButtonText,
    handleButtonClick,
    handleClose,
    handleSubmit,
    width = "auto"
}) => {
    const isSelected = selectedButtonText === text;

    const buttonStyle = isSelected ? "solid" : "outline";

    const handleClick = e => {
        e.preventDefault();

        if (isSelected) {
            e.preventDefault();
        } else {
            setSelectedButtonText(text);

            if (handleSubmit) {
                handleSubmit(text);
            }

            if (handleButtonClick) {
                handleButtonClick(text);
            }
        }

        handleClose();
    };

    return (
        <OptionButton
            size="md"
            styleType={buttonStyle}
            aria-pressed={isSelected}
            onClick={e => handleClick(e)}
            width={width}
        >
            {text}
        </OptionButton>
    );
};

Button.propTypes = {
    selectedButtonText: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    setSelectedButtonText: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func,
    handleButtonClick: PropTypes.func,
    width: PropTypes.string
};

export default Button;
