import React from "react";
import PropTypes from "prop-types";

import PendingGroup from "./PendingGroup";

import { PendingGroupsContainer } from "./styles";

const PendingGroups = ({ data = [] }) => (
    <PendingGroupsContainer>
        {data.map(groupData => (
            <PendingGroup key={groupData.id} data={groupData} />
        ))}
    </PendingGroupsContainer>
);

PendingGroups.propTypes = {
    data: PropTypes.array.isRequired
};

export default PendingGroups;
