import React, { useEffect } from "react";

import SearchAndBrowsePageContainer from "core/SearchAndBrowsePageContainer";
import SearchAndBrowsePageHeadline from "core/SearchAndBrowsePageHeadline";
import BrowseSegmentedControlButtons from "./BrowseSegmentedControlButtons";
import BrowseIconsByTagResults from "icons/BrowseIconsByTagResults";
import BrowsePhotosByTagResults from "photos/BrowsePhotosByTagResults";
import PageMeta from "core/PageMeta";

import { BrowsePageProvider, useBrowsePageContext } from "./context";

import { TYPES } from "./constants";
import { capitalizeFirstChar } from "frontend/components/helpers";

const UnwrappedBrowsePage = () => {
    useEffect(() => {
        // We need to manually take control of the browsers scrollRestoration
        window.history.scrollRestoration = "manual";

        if (window.pageYOffset !== 0) {
            window.scrollTo(0, 0);
        }
    }, []);

    const state = useBrowsePageContext();
    const {
        term,
        activeType,
        loadingFirstBatch,
        icons,
        iconsTotalCount,
        photosTotalCount
    } = state;

    let pageMetaTitle = "";
    let pageMetaDescription = "";
    let twitterDescription = "";
    let type = "";
    let structuredDataForSEO;
    let useSocialMediaTags = false;
    let previewImg = "";
    let previewImgAlt = "";

    const itemListElementArray = icons.items.map(iconInfo => {
        const { url } = iconInfo;
        return {
            "@type": "ItemPage",
            url: window.location.origin + url
        };
    });

    const capitalizedTerm = capitalizeFirstChar({ str: term });

    if (activeType === TYPES.ICONS) {
        // IMPORTANT NOTE:
        // Some of these values also exist in templates/frontend/browse_icons_by_tag.html
        pageMetaTitle = `${capitalizedTerm} Icons - Free SVG & PNG ${capitalizedTerm} Images - Noun Project`;
        pageMetaDescription = `Find ${iconsTotalCount} ${capitalizedTerm} images and millions more royalty free PNG & vector images from the world's most diverse collection of free icons.`;
        type = "Icons";
        // Add social & schema if the term is valid (has icons)
        if (icons.items.length && icons.items[0].thumbnails) {
            useSocialMediaTags = true;
            previewImg = icons.items[0].thumbnails.thumbnail200;
            previewImgAlt = `${capitalizedTerm} icon`;
            twitterDescription = `Love these ${capitalizedTerm} icons from @NounProject`;
            structuredDataForSEO = {
                "@context": "https://schema.org",
                "@type": "CollectionPage",
                url: window.location.origin + window.location.pathname,
                image: previewImg,
                mainEntity: {
                    "@type": "ItemList",
                    itemListElement: itemListElementArray
                }
            };
        }
    }

    if (activeType === TYPES.PHOTOS) {
        // IMPORTANT NOTE:
        // Some of these values also exist in templates/frontend/browse_by_tag.html
        pageMetaTitle = `${capitalizedTerm} Photos - Find ${photosTotalCount} Free Images - Noun Project`;
        pageMetaDescription = `Download ${term} pictures & millions more royalty free images. Our free stock photos are curated for quality & diversity.`;
        twitterDescription = pageMetaDescription;
        type = "Images";
        useSocialMediaTags = true;
        previewImg =
            "https://static.production.thenounproject.com/img/nounproject_logo.60e860f212a5.png";
        structuredDataForSEO = {
            "@context": "https://schema.org",
            "@type": "ImageGallery",
            additionalType: "photo",
            name: capitalizedTerm,
            contentUrl: window.location.origin + window.location.pathname,
            description: pageMetaDescription,
            keywords: [capitalizedTerm],
            publisher: "The Noun Project"
        };
    }

    return (
        <>
            <PageMeta
                title={pageMetaTitle}
                indexContent="index,follow,max-image-preview:large"
                structuredData={structuredDataForSEO}
                previewImg={previewImg}
                previewImgAlt={previewImgAlt}
                description={pageMetaDescription}
                twitterDescription={twitterDescription}
                useSocialMediaTags={useSocialMediaTags}
            />
            <SearchAndBrowsePageContainer containsSegmentedControls>
                <SearchAndBrowsePageHeadline showSkeleton={loadingFirstBatch}>
                    Browse {capitalizedTerm} {type}
                </SearchAndBrowsePageHeadline>
                <BrowseSegmentedControlButtons />
                <BrowseIconsByTagResults term={term} />
                <BrowsePhotosByTagResults term={term} />
            </SearchAndBrowsePageContainer>
        </>
    );
};

export default props => (
    <BrowsePageProvider>
        <UnwrappedBrowsePage {...props} />
    </BrowsePageProvider>
);
