import { useEffect, useState } from "react";

// Hooks
import { useMyModerator } from "./useMyModerator.js";
import { useFetchModerators } from "./queries/moderators/useFetchModerators";

export const useModerators = () => {
    const [moderators, setModerators] = useState([]);
    const [filteredModerators, setFilteredModerators] = useState([]);
    const { myModProfile } = useMyModerator();
    const { fetchModerators } = useFetchModerators();

    useEffect(() => {
        const getModerationData = async myModProfile => {
            const mods = await fetchModerators();
            const isManager = myModProfile?.isManager || false;
            const moderatorId = myModProfile?.moderatorId;

            // non-managers are only allowed to assign themselves to batches
            const filteredMods = isManager
                ? mods
                : mods.filter(mod => moderatorId === mod.id);

            setFilteredModerators(filteredMods);
            setModerators(mods);
        };

        if (myModProfile) {
            getModerationData(myModProfile);
        }
    }, [myModProfile]);

    return {
        moderators,
        filteredModerators,
        myModProfile
    };
};
