import React from "react";

import SquarespaceAd from "core/SquarespaceAd";
import { LingoAd } from "icons/IconGridWithAds/styles";
import PremiumBadge from "core/PremiumBadge";

// Helpers
import { PHOTO_DETAIL } from "data/links";

// Styles
import { AdContainerParent, PhotoContainer, PhotoLink } from "./styles";

const selectAd = (isMobile, row, idx, customTop = null) => {
    // We have to use the second from last item
    // in any given row unless its only 1 items in
    // the row to set the top position of the ad
    const lastRowTop = row[row.length - 2]
        ? row[row.length - 2]
        : row[row.length - 1];

    return isMobile
        ? {
              AdComponent: (
                  <AdContainerParent
                      key={`adRow-${idx}`}
                      top={customTop ? customTop : lastRowTop.top}
                  >
                      <LingoAd />
                  </AdContainerParent>
              )
          }
        : {
              AdComponent: (
                  <AdContainerParent
                      data-testid="ad-container"
                      key={`adRow-${idx}`}
                      top={customTop ? customTop : lastRowTop.top}
                  >
                      <SquarespaceAd />
                  </AdContainerParent>
              )
          };
};

export const generateAds = (
    mergedResults,
    isMobile,
    containerWidth,
    hasMaxRows,
    subscribed
) => {
    let tempRow = [];
    let tempRowWidth = 0;
    let rowCount = 0;
    let adCount = 0;
    const rows = [];
    const resultsWithAds = [];
    const adHeightWtihPadding = !isMobile ? 116 : 62;

    if (!subscribed) {
        // We need to use the container width and each photos width to place to ads

        mergedResults.forEach((photo, idx) => {
            const { width } = photo;
            const islastPhoto = mergedResults.length - 1 === idx;

            if (
                tempRowWidth + Math.ceil(width) <= containerWidth ||
                islastPhoto
            ) {
                tempRowWidth += Math.ceil(width);
                tempRow = [...tempRow, photo];

                // If this is the last photo push the row on the list of rows
                if (
                    (!hasMaxRows && islastPhoto) ||
                    // We must never reach this line because maxRows isn't defined.
                    // Disable linting for now so we don't break anything.
                    // eslint-disable-next-line no-undef
                    (islastPhoto && hasMaxRows && rowCount < maxRows)
                ) {
                    rows.push(tempRow);
                    rowCount += 1;
                }
            } else {
                tempRowWidth = 0;
                tempRowWidth += Math.ceil(width);
                // We must never reach this line because maxRows isn't defined.
                // Disable linting for now so we don't break anything.
                // eslint-disable-next-line no-undef
                if (!hasMaxRows || (hasMaxRows && rowCount < maxRows)) {
                    rows.push(tempRow);
                    rowCount += 1;
                }

                tempRow = [];
                tempRow = [...tempRow, photo];
            }
        });

        rows.forEach((row, idx) => {
            // Insert ad based on row
            if (idx + 1 === 3 || (idx + 1 >= 3 && (idx + 1 - 3) % 7 === 0)) {
                adCount += 1;
                // TODO: Replace with Square Space Mobile Ads
                resultsWithAds.push(selectAd(isMobile, row, idx));
                // Updated photos top position to account for ads
                rows.forEach((localRow, index) => {
                    if (index >= idx) {
                        localRow.forEach(photo => {
                            photo.top += adHeightWtihPadding;
                        });
                    }
                });
            }

            // Add photos
            resultsWithAds.push(row);
        });

        // In the event there is 2 rows or less of photos
        // and the user does not have a subscription,
        // we still want to show 1 ad for revenue
        // A bit hacky, but dealing with a lot of absolute positioning,
        // we calc the last photo height and space from top and add 24px of margin-top
        if (rowCount === 1 || rowCount === 2) {
            adCount += 1;

            const row = rows[rows.length - 1];
            const idx = rows.length;

            const customLastTop = row[row.length - 1];
            const customTop = customLastTop.top + customLastTop.height + 24;

            resultsWithAds.push(selectAd(isMobile, row, idx, customTop));
        }
    }

    return { resultsWithAds, adCount, adHeightWtihPadding };
};

export const Photos = ({ subscribed, mergedResults, resultsWithAds }) => {
    return (subscribed ? mergedResults : resultsWithAds.flat()).map(
        (
            {
                width,
                height,
                left,
                top,
                assets,
                isPremium,
                slugWithId,
                title,
                id,
                AdComponent
            },
            idx
        ) => {
            // If id (photo id) does not exist, we know its an ad component to show based on grid calc
            if (!id) return AdComponent;
            const adjustedWidth = !!width ? width : 0;

            return (
                <PhotoContainer
                    key={idx}
                    width={adjustedWidth}
                    height={height}
                    top={top}
                    left={left}
                    backgroundImage={`url(${assets.thumbnailMedium})`}
                >
                    {isPremium && <PremiumBadge />}
                    <PhotoLink
                        tabIndex="0"
                        role="heading"
                        aria-level="3"
                        aria-label={title}
                        title={title}
                        width={adjustedWidth}
                        height={height}
                        href={PHOTO_DETAIL.replace(
                            ":photoSlugWithId",
                            slugWithId
                        )}
                    />
                </PhotoContainer>
            );
        }
    );
};
