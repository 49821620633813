import React from "react";

import SectionContainer from "core/SectionContainer";
import TextWithButton from "core/TextWithButton";
import OffsetGrid from "core/OffsetGrid";
import CollectionSpotlight from "core/CollectionSpotlight";

import CtaSection from "./CtaSection";

import colors from "core/colors";

import {
    productFundamentalOneImage,
    productFundamentalOneAlt,
    productFundamentalOneHeading,
    productFundamentalOneText,
    productFundamentalTwoImage,
    productFundamentalTwoAlt,
    productFundamentalTwoHeading,
    productFundamentalTwoText,
    productFundamentalThreeImage,
    productFundamentalThreeAlt,
    productFundamentalThreeHeading,
    productFundamentalThreeText
} from "./data";

const ProductFundamentalSection = () => (
    <>
        <SectionContainer
            removeLeftRightPadding
            textColor={colors.secondary.orca}
        >
            <TextWithButton
                headingType="h2"
                headingAlignment="center"
                textColor={colors.secondary.orca}
                text="Lingo—One Tool for All Your Assets"
            />
            <SectionContainer>
                <OffsetGrid sm={10} md={12} lg={10}>
                    <CollectionSpotlight
                        image={productFundamentalOneImage}
                        imageAlt={productFundamentalOneAlt}
                        collectionHeading={productFundamentalOneHeading}
                        collectionText={productFundamentalOneText}
                        sideBySideContentOnMedium
                    />
                </OffsetGrid>
            </SectionContainer>
            <SectionContainer topSpacing="none" bottomSpacing="none">
                <OffsetGrid sm={10} md={12} lg={10}>
                    <CollectionSpotlight
                        isRightImg
                        image={productFundamentalTwoImage}
                        imageAlt={productFundamentalTwoAlt}
                        collectionHeading={productFundamentalTwoHeading}
                        collectionText={productFundamentalTwoText}
                        sideBySideContentOnMedium
                    />
                </OffsetGrid>
            </SectionContainer>
            <SectionContainer>
                <OffsetGrid sm={10} md={12} lg={10}>
                    <CollectionSpotlight
                        textColor={colors.secondary.orca}
                        image={productFundamentalThreeImage}
                        imageAlt={productFundamentalThreeAlt}
                        collectionHeading={productFundamentalThreeHeading}
                        collectionText={productFundamentalThreeText}
                        sideBySideContentOnMedium
                    />
                </OffsetGrid>
            </SectionContainer>
            <CtaSection />
        </SectionContainer>
    </>
);

export default ProductFundamentalSection;
