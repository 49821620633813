import { useApolloMutation } from "frontend/utils/apolloApi/useApolloMutation.js";

// GQL
import { APPROVE_PHOTO_SHOOT_TITLE } from "frontend/components/fort/mod/photos/gql/mutations";

const errorHandler = normalizedResp => {
    const { message, errors, actionDesc } = normalizedResp;

    let newMessage = message;

    if (
        errors.includes(
            "A photo shoot must have a title before you can approve the title"
        )
    ) {
        newMessage = `Sorry, we were unable to ${actionDesc}. A photo shoot title is required and appears to be empty.`;
    }

    return {
        ...normalizedResp,
        message: newMessage
    };
};

export const useApprovePhotoShootTitle = () => {
    const { apolloMutate, loading } = useApolloMutation({
        actionDesc: "Approve Photo Shoot",
        gql: APPROVE_PHOTO_SHOOT_TITLE,
        errorHandler
    });

    const approvePhotoShootTitle = async variables => {
        return await apolloMutate({ variables });
    };

    return {
        approvePhotoShootTitle,
        approvePhotoShootTitleLoading: loading
    };
};
