export const getExpiresParamValue = url => {
    // Create a URL object
    const urlObj = new URL(url);

    // Use the URLSearchParams object to get the value of the 'Expires' parameter
    const expiresValue = urlObj.searchParams.get("Expires");

    // Return the value
    return expiresValue;
};

export const isTimestampInThePast = timestamp => {
    // Get the current time in seconds since the Unix epoch
    const currentTime = Math.floor(Date.now() / 1000);

    // Compare the current time with the timestamp
    return timestamp < currentTime;
};

export const hasImageUrlExpired = url => {
    const expiresValue = getExpiresParamValue(url);
    return isTimestampInThePast(expiresValue);
};
