import styled, { css, keyframes } from "styled-components";
import colors from "core/colors";

const pulse = keyframes`
  0% {
    background-color: ${colors.primary.whisker};
  }
  75% {
    background-color: ${colors.primary.scheifer};
  }
`;

export const SkeletonBox = styled.div`
    position: relative;
    overflow: hidden;
    background-color: ${colors.primary.whisker};

    border-radius: ${({ isCircle }) => (isCircle ? "50%" : 0)};
    animation: ${({ animationSpeedMs }) => animationSpeedMs}s ${pulse} infinite;

    width: ${({ width }) => width};

    height: ${({ height }) => height};
`;
