import styled, { css } from "styled-components";

// styles
import { focusOutline } from "core/links";
import { rapidEasing } from "core/animation";
import { convertPxToRem } from "frontend/components/helpers";

// Parent Styles
import { sharedTagContainerStyles, ProcessingSpinnerIcon } from "../styles";

// dls
import { dls } from "./dls";

// components
import Close from "core/ReusableIcons/Close";

// styled-components

export const RemoveButton = styled.button`
    cursor: pointer;
    background: transparent;
    margin: 0 0 0 ${convertPxToRem({ px: 8 })};
    padding: 0;
    border: 0;
    line-height: 0;
    font-size: 0;

    &:focus {
        ${focusOutline}
    }
`;

export const CloseIcon = styled(Close)`
    path {
        transition: all ${rapidEasing};
    }
`;

export const TagRemoveableStyled = styled.div`
    ${sharedTagContainerStyles};

    /* TagRemoveable Specific Default Styles */

    /* DSL specific styling */
    ${({ isDarkMode = false }) => {
        const mode = isDarkMode ? "dark" : "light";
        const dlsObj = dls[mode];

        return css`
            color: ${dlsObj.static.color};
            background: ${dlsObj.static.background};
            border: ${isDarkMode ? `1px solid ${dlsObj.static.color}` : `none`};

            ${RemoveButton} {
                ${CloseIcon} path {
                    fill: ${dlsObj.static.color};
                }
            }

            &:hover {
                color: ${dlsObj.hover.color};
                background: ${dlsObj.hover.background};
                border: ${isDarkMode
                    ? `1px solid ${dlsObj.hover.color}`
                    : `none`};

                ${RemoveButton} {
                    ${CloseIcon} path {
                        fill: ${dlsObj.hover.color};
                    }
                }
            }

            ${ProcessingSpinnerIcon} {
                fill: ${dlsObj.static.color};
            }

            ${({ isDisabled = false }) => {
                if (isDisabled) {
                    return css`
                        cursor: not-allowed;
                        color: ${dlsObj.disabled.color};
                        background: ${dlsObj.disabled.background};

                        ${RemoveButton} {
                            ${CloseIcon} path {
                                fill: ${dlsObj.disabled.color};
                            }
                        }

                        &:hover {
                            color: ${dlsObj.disabled.color};
                            background: ${dlsObj.disabled.background};

                            ${RemoveButton} {
                                ${CloseIcon} path {
                                    fill: ${dlsObj.disabled.color};
                                }
                            }
                        }
                    `;
                }
            }}
        `;
    }}
`;
