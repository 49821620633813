import React from "react";
import PropTypes from "prop-types";

import Heading from "core/Heading";
import TruncateText from "core/TruncateText";
import CloseModalButton from "core/Modal/CloseModalButton";
import BodyText from "core/BodyText";

import { HeadingFlexbox, ModalContent, ModalFooter } from "./styles";

import { modalTypes, modalSizes, headingSizes, headingTypes } from "core/props";

const ModalBody = ({
    heading,
    headingCharLimit,
    headingSize,
    headingType,
    handleClose,
    type,
    size,
    children,
    footerChildren
}) => {
    const renderedHeading = headingCharLimit ? (
        <TruncateText charLimit={headingCharLimit}>{heading}</TruncateText>
    ) : (
        heading
    );

    return (
        <>
            {!!heading && (
                <HeadingFlexbox justifyContent="space-between">
                    <Heading type={headingType} size={headingSize}>
                        {renderedHeading}
                    </Heading>

                    <CloseModalButton
                        handleClose={handleClose}
                        size={size}
                        heading={heading}
                    />
                </HeadingFlexbox>
            )}

            <ModalContent data-testid="modal-body-content">
                {!heading && (
                    <CloseModalButton handleClose={handleClose} size={size} />
                )}

                {(type === "text" && size !== "fullScreen") ||
                (type === "kits" && size !== "fullScreen") ? (
                    <BodyText type="div" size="lg">
                        {children}
                    </BodyText>
                ) : (
                    children
                )}
            </ModalContent>

            {!!footerChildren && size !== "fullScreen" && (
                <ModalFooter>{footerChildren}</ModalFooter>
            )}
        </>
    );
};

ModalBody.propTypes = {
    handleClose: PropTypes.func.isRequired,
    heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    headingCharLimit: PropTypes.number,
    headingSize: PropTypes.oneOf(headingSizes),
    headingType: PropTypes.oneOf(headingTypes),
    type: PropTypes.oneOf(modalTypes),
    size: PropTypes.oneOf(modalSizes),
    footerChildren: PropTypes.any
};

export default ModalBody;
