import React from "react";
import PropTypes from "prop-types";

// Provider Components
import BatchesProvider from "./BatchesProvider/index";
import AnimationProviders from "./AnimationProviders/index";
import ModalProvider from "./ModalProvider";
import PhotoModQueueProvider from "./PhotoModQueueProvider";
import WebsocketSynchProvider from "./WebsocketSynchProvider";

const QueueProviders = ({ children }) => {
    return (
        <BatchesProvider>
            <WebsocketSynchProvider>
                <AnimationProviders>
                    <ModalProvider>
                        <PhotoModQueueProvider>
                            {children}
                        </PhotoModQueueProvider>
                    </ModalProvider>
                </AnimationProviders>
            </WebsocketSynchProvider>
        </BatchesProvider>
    );
};

export default QueueProviders;

// prop-types
QueueProviders.propTypes = {
    children: PropTypes.any
};
