import styled from "styled-components";
import SectionContainer from "core/SectionContainer";

export const PhotoUploadFooterSectionContainer = styled(SectionContainer).attrs(
    {
        topSpacing: "2xl",
        bottomSpacing: "2xl"
    }
)`
    position: sticky;
    width: 100%;
    bottom: 0;
    padding-bottom: 0.5rem;
    z-index: 2;
    background: none;
    pointer-events: none;
`;
