import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import * as actions from "actions/actions";

import { authLinks, LOGIN, SIGNUP } from "data/links";
import { useAuth, LOGIN_MODE, SIGNUP_MODE } from "frontend/hooks/useAuth";

import { Login, Join } from "components/ProfileMenu/styles";

const onLoginPage = () =>
    typeof window !== "undefined" && window.location.pathname === LOGIN;
const onSignupPage = () =>
    typeof window !== "undefined" &&
    window.location.pathname.indexOf(SIGNUP) === 0; // there are multiple signup URLs

const nextURL = () => {
    // If switching from signup <-> login, keep existing redirects
    if (onLoginPage() || onSignupPage()) {
        return window.location.search;
    }

    const toEncode = window.location.pathname + window.location.search;
    return `?next=${encodeURIComponent(toEncode)}`;
};

const LoginLinkUnwrapped = ({ redirect, isHeroSearchV2, isScrolledDown }) => {
    const { confirmAuth } = useAuth();

    // Avoid showing a login link if they're already on the login page
    if (onLoginPage()) {
        return null;
    }

    // Avoid stacking auth forms - don't show an auth modal if they're already on an auth page.
    const showModal = !onSignupPage();

    const handleClickProps = showModal
        ? {
              onClick: () => confirmAuth({ mode: LOGIN_MODE })
          }
        : {
              href: authLinks.login.href,
              onClick: e => {
                  e.preventDefault();
                  redirect(authLinks.login.href + nextURL());
              }
          };

    return (
        <Login
            data-test="login-link"
            isHeroSearchV2={isHeroSearchV2}
            isScrolledDown={isScrolledDown}
            {...handleClickProps}
        >
            {authLinks.login.text}
        </Login>
    );
};

const LoginLink = connect(null, { redirect: actions.redirect })(
    LoginLinkUnwrapped
);

LoginLink.propTypes = {
    isHeroSearchV2: PropTypes.bool
};

const JoinLinkUnwrapped = ({ redirect, isHeroSearchV2, isScrolledDown }) => {
    const { confirmAuth } = useAuth();

    if (onSignupPage()) {
        return null;
    }

    const showModal = !onLoginPage();

    const handleClickProps = showModal
        ? {
              onClick: () => confirmAuth({ mode: SIGNUP_MODE })
          }
        : {
              href: authLinks.signup.href,
              onClick: e => {
                  e.preventDefault();
                  redirect(authLinks.signup.href + nextURL());
              }
          };

    return (
        <Join
            isHeroSearchV2={isHeroSearchV2}
            isScrolledDown={isScrolledDown}
            {...handleClickProps}
        >
            {authLinks.signup.text}
        </Join>
    );
};

const JoinLink = connect(null, { redirect: actions.redirect })(
    JoinLinkUnwrapped
);

JoinLink.propTypes = {
    isHeroSearchV2: PropTypes.bool
};

export { LoginLink, JoinLink };
