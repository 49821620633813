import React from "react";

// context
import { useBatches } from "frontend/components/fort/mod/photos/components/PhotoModQueuePageContent/QueueProviders/BatchesProvider/index.js";
import { SORT_BY_OPTIONS } from "frontend/components/fort/mod/photos/constants";

// components
import CoreSort from "frontend/components/fort/mod/core/Masthead/Sort.js";

// component
export const SortBy = () => {
    const { searchParams, setUrlSearchParams } = useBatches();

    const sortBy = searchParams?.sortBy;

    const onSelect = ({ value }) => {
        setUrlSearchParams([
            {
                key: "sortBy",
                value
            }
        ]);
    };

    const selectedOption =
        SORT_BY_OPTIONS.find(opt => opt.value === sortBy) || SORT_BY_OPTIONS[0];

    return (
        <CoreSort
            options={SORT_BY_OPTIONS}
            selectedOption={selectedOption}
            onSelect={onSelect}
        />
    );
};

export default SortBy;
