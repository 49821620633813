import React from "react";
import styled from "styled-components";

// context
import { useSiteHeader } from "core/SiteHeaderV2/context/useSiteHeader.js";

// components
import Vertical from "./Vertical";
import Overlay from "./Overlay";
import Underlay from "./Underlay";

// styles
import { convertPxToRem } from "frontend/components/helpers";

const Row2Styled = styled.div`
    position: relative;
    margin-top: ${({ $marginTop }) => convertPxToRem($marginTop)};
    height: ${({ $height }) => convertPxToRem($height)};
    overflow: ${({ $isVisible }) => ($isVisible ? "visible" : "hidden")};
`;

// component
export const Row2 = () => {
    const {
        searchBar: { isVisible },
        header: { secondRowHeight, secondRowMarginTop }
    } = useSiteHeader();
    return (
        <Row2Styled
            $isVisible={isVisible}
            $height={secondRowHeight}
            $marginTop={secondRowMarginTop}
        >
            <Vertical>
                <Overlay />
                <Underlay />
            </Vertical>
        </Row2Styled>
    );
};

export default Row2;
