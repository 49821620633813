export const TRACK_ITEM_ICON = "icons"; // default
export const TRACK_ITEM_PHOTO = "photos";
export const TRACK_ITEM_ICON_SET = "icon-set";
export const TRACK_ITEM_CREATIVE = "creative"; // creative pro sub only

export const TRACK_PURCHASE_PRO_YEAR = "pro_year";
export const TRACK_PURCHASE_PRO_MONTH = "pro_month";
export const TRACK_PURCHASE_CPRO = "creative_pro_year";
export const TRACK_PURCHASE_ALC = "purchase_alc";
export const TRACK_CANCELLATION = "subscription_canceled";

export const TRACK_USER_CHECKOUT = "user"; // default
export const TRACK_TEAM_CHECKOUT = "team";
export const TRACK_EDU_CHECKOUT = "edu";

export const TRACK_SIGN_UP = "signup";
export const TRACK_SIGN_IN = "login";

export const TRACK_DOWNLOAD_ICON = "download_icon";
export const TRACK_DOWNLOAD_ICON_SET = "download_icon_set";
export const TRACK_DOWNLOAD_PHOTO = "download_photo";

export const TRACK_QV_OPEN = "quick_view_open";
export const TRACK_QV_NEXT = "quick_view_next";

export const TRACK_ICON_FILTER = "icon_filter";

export const TRACK_PHOTO_ROYALTY_PDF = "photo_royalty_pdf";

// Generic event tracking
export const trackEvent = (eventName, params = {}) => {
    if (!window?.dataLayer || !eventName || typeof eventName !== "string") {
        return;
    }

    window.dataLayer.push({
        event: eventName,
        ...params
    });
};

// Track when user's load a detail page
export const trackViewItem = ({
    itemType = TRACK_ITEM_ICON,
    itemId,
    itemName
}) => {
    if (!window.dataLayer) return;

    window.dataLayer.push({
        event: "view_item",
        ecommerce: {
            currency: "USD",
            items: [
                {
                    item_id: itemId,
                    item_name: itemName,
                    item_category: itemType
                }
            ]
        }
    });
};

// Track when user's open the purchase-options drawer
export const trackViewPurchaseOptions = ({
    itemType = TRACK_ITEM_ICON,
    itemId,
    itemName
}) => {
    if (!window.dataLayer) return;

    window.dataLayer.push({
        event: "add_to_cart",
        ecommerce: {
            currency: "USD",
            items: [
                {
                    item_id: itemId,
                    item_name: itemName,
                    item_category: itemType
                }
            ]
        }
    });
};

// Track when users load a checkout page
export const trackViewCheckout = ({
    itemType = TRACK_ITEM_ICON,
    itemId,
    itemName,
    purchaseType,
    userType = TRACK_USER_CHECKOUT,
    price = "0.00"
}) => {
    if (!window.dataLayer) return;

    window.dataLayer.push({
        event: "begin_checkout",
        ecommerce: {
            currency: "USD",
            value: price,
            items: [
                {
                    item_id: itemId || `${itemType}_${purchaseType}`,
                    item_name: itemName || "Subscription",
                    // icon|photo > pro_year|pro_month|purchase_alc > user|team|edu
                    item_category: itemType,
                    item_category2: purchaseType,
                    item_category3: userType,
                    price
                }
            ]
        }
    });
};

// Track when a successful purchase is made.
export const trackPurchase = ({
    itemType = TRACK_ITEM_ICON,
    itemId,
    itemName,
    purchaseType,
    userType = TRACK_USER_CHECKOUT,
    price = "0.00"
}) => {
    if (!window.dataLayer) return;

    window.dataLayer.push({
        event: "purchase",
        conversionValue: price,
        ecommerce: {
            currency: "USD",
            value: price,
            items: [
                {
                    item_id: itemId || `${itemType}_${purchaseType}`,
                    item_name: itemName || "Subscription",
                    item_category: itemType,
                    item_category2: purchaseType,
                    item_category3: userType,
                    price
                }
            ]
        }
    });
};

// Track when quick-view modal is opened
export const trackQuickView = ({ isOpening = false }) =>
    trackEvent(isOpening ? TRACK_QV_OPEN : TRACK_QV_NEXT);

// Track asset download
export const trackIconDownload = (params = { source: "web" }) =>
    trackEvent(TRACK_DOWNLOAD_ICON, params);
export const trackIconSetDownload = () => trackEvent(TRACK_DOWNLOAD_ICON_SET);
export const trackPhotoDownload = () => trackEvent(TRACK_DOWNLOAD_PHOTO);
