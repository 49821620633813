import { createSlice } from "@reduxjs/toolkit";

// RTK
import { REDUCERS } from "../constants";

export const websocketSynchQueueSlice = createSlice({
    name: REDUCERS.WEBSOCKET_SYNCH_QUEUE,
    initialState: {
        shouldRefetchBatches: false,
        moderationNotes: []
    },
    reducers: {
        setShouldRefetchBatches: (state, action) => {
            state.shouldRefetchBatches = action.payload.shouldRefetchBatches;
        },
        addModNote: (state, action) => {
            state.moderationNotes = [
                ...state.moderationNotes,
                action.payload.modNote
            ];
        },
        clearModNotes: state => {
            state.uploadIds = [];
        }
    }
});

export default websocketSynchQueueSlice.reducer;
