import React from "react";
import styled, { css } from "styled-components";

import colors from "core/colors";
import { SHARED_FORM_STYLES } from "core/Form";
import { easingSeconds } from "core/animation";

import Checkmark from "core/ReusableIcons/Checkmark";

import { convertPxToRem } from "frontend/components/helpers";
import { handleStylePosition } from "./helpers"; // TODO: move to core/Form/

// optional/success icon constants:
const INPUT_ICON_SIZE = convertPxToRem({ px: 16 });
const INPUT_ICON_MARGIN = convertPxToRem({ px: 8 });

const RIGHT_ICON_POSITION_STYLES = {
    XS: css`
        right: ${convertPxToRem({ px: 6 })};
    `,
    SM: css`
        right: ${convertPxToRem({ px: 8 })};
    `,
    MD: css`
        right: ${convertPxToRem({ px: 14 })};
    `,
    LG: css`
        right: ${convertPxToRem({ px: 16 })};
    `
};

const RIGHT_ICON_INPUT_PADDING_STYLES = {
    XS: `
        padding-right: calc(
            ${INPUT_ICON_SIZE} + ${INPUT_ICON_MARGIN} +
                ${convertPxToRem({ px: 6 })}
        );
    `,

    SM: `
        padding-right: calc(
            ${INPUT_ICON_SIZE} + ${INPUT_ICON_MARGIN} +
                ${convertPxToRem({ px: 8 })}
        );
    `,

    MD: `
        padding-right: calc(
            ${INPUT_ICON_SIZE} + ${INPUT_ICON_MARGIN} +
                ${convertPxToRem({ px: 14 })}
        );
    `,

    LG: `
        padding-right: calc(
            ${INPUT_ICON_SIZE} + ${INPUT_ICON_MARGIN} +
                ${convertPxToRem({ px: 16 })}
        );
    `
};

// left icon constants:
const LEFT_ICON_POSITION_STYLES = {
    XS: css`
        left: ${convertPxToRem({ px: 6 })};
    `,
    SM: css`
        left: ${convertPxToRem({ px: 8 })};
    `,
    MD: css`
        left: ${convertPxToRem({ px: 14 })};
    `,
    LG: css`
        left: ${convertPxToRem({ px: 16 })};
    `
};

export const LEFT_ICON_INPUT_PADDING_STYLES = {
    XS: `
        padding-left: calc(
            ${INPUT_ICON_SIZE} + ${INPUT_ICON_MARGIN} +
                ${convertPxToRem({ px: 6 })}
        );
    `,

    SM: `
        padding-left: calc(
            ${INPUT_ICON_SIZE} + ${INPUT_ICON_MARGIN} +
                ${convertPxToRem({ px: 8 })}
        );
    `,

    MD: `
        padding-left: calc(
            ${INPUT_ICON_SIZE} + ${INPUT_ICON_MARGIN} +
                ${convertPxToRem({ px: 14 })}
        );
    `,

    LG: `
        padding-left: calc(
            ${INPUT_ICON_SIZE} + ${INPUT_ICON_MARGIN} +
                ${convertPxToRem({ px: 16 })}
        );
    `
};

// styled Input:
export const Input = styled.input`
    ${SHARED_FORM_STYLES.BASE}
    width: 100%;

    ${({ size, success }) =>
        !!success &&
        handleStylePosition({
            size,
            POSITION_STYLES: RIGHT_ICON_INPUT_PADDING_STYLES
        })}

    ${({ size, iconLeft }) =>
        !!iconLeft &&
        handleStylePosition({
            size,
            POSITION_STYLES: LEFT_ICON_INPUT_PADDING_STYLES
        })}

    // placeholder:
    &::placeholder {
        ${SHARED_FORM_STYLES.PLACEHOLDER}
    }

    // hover:
    &:hover {
        ${SHARED_FORM_STYLES.HOVER}
    }

    // focus:
    &:focus {
        ${SHARED_FORM_STYLES.FOCUS}
    }

    // disabled:
    &:disabled {
        ${SHARED_FORM_STYLES.DISABLED}
    }
`;

// Success icon:
export const SuccessIconWrapper = styled.div`
    position: absolute;
    transition: all ${easingSeconds};
    opacity: ${({ success }) => (!!success ? 1 : 0)};
    bottom: 47%;

    ${({ size }) =>
        handleStylePosition({
            size,
            POSITION_STYLES: RIGHT_ICON_POSITION_STYLES
        })};
`;

export const StyledCheckMark = styled(Checkmark).attrs({ size: "sm" })`
    path {
        fill: ${({ darkMode }) =>
            darkMode
                ? colors.alternative.lightMode.chartreuse
                : colors.alternative.lightMode.pine};
    }
`;

let SuccessIcon = ({ inputSuccessful, darkMode, size, success, className }) => (
    <SuccessIconWrapper
        inputSuccessful={inputSuccessful}
        size={size}
        success={success}
        className={className}
    >
        <StyledCheckMark darkMode={darkMode} />
    </SuccessIconWrapper>
);

SuccessIcon = styled(SuccessIcon)``;
export { SuccessIcon };

// Left & Right icon:
export const IconContainer = styled.div`
    position: absolute;
    bottom: 50%;

    ${({ size, iconLeft }) =>
        handleStylePosition({
            size,
            POSITION_STYLES: iconLeft
                ? LEFT_ICON_POSITION_STYLES
                : RIGHT_ICON_POSITION_STYLES
        })};
`;
