import React from "react";
import PropTypes from "prop-types";

import Link from "core/Link";
import SrOnly from "core/SrOnly";

import {
    TableContainer,
    Table,
    HeaderBodyText,
    HeaderElement,
    BodyTableText,
    ColumnHeading,
    TableBody,
    LinkContainer,
    TableData,
    TableDataAsHead,
    AlignCells
} from "./styles";

const ScrollableTable = ({
    tableHeaderData,
    tableBodyData,
    maxTableHeight = null,
    tableAriaLabel
}) => {
    const headerLength = tableHeaderData.length;

    // will return num of keys for all children
    const bodyLength = tableBodyData.map(obj => Object.keys(obj).length);

    // will reduce num of keys above to 1 value => example: [8,8,8] = [8]
    const uniqueLength = [...new Set(bodyLength)];

    // if uniqueLen has multiple values, it means at least 1 child obj had a diff amount of values
    // and therefore will throw off table
    const isTitleAndBodyEqual =
        uniqueLength.length === 1 && headerLength === uniqueLength[0];

    const errorMsg =
        "ScrollableTable requires the number of table header values to be equal to the number of cell data values. Make sure tableHeaderData has the same number of values as the children inside tableBodyData.";

    if (!isTitleAndBodyEqual) return new Error(errorMsg);

    const TableCell = ({ data, index }) => {
        if (index === 0) {
            return (
                <TableDataAsHead tabIndex={0} scope="row">
                    <AlignCells>{data}</AlignCells>
                </TableDataAsHead>
            );
        } else {
            return (
                <TableData tabIndex={0}>
                    <AlignCells>{data}</AlignCells>
                </TableData>
            );
        }
    };

    const HeaderCell = ({ isString, title }) => {
        return (
            <ColumnHeading tabIndex={0} scope="col">
                {isString ? (
                    <HeaderBodyText>{title}</HeaderBodyText>
                ) : (
                    <HeaderElement>{title}</HeaderElement>
                )}
            </ColumnHeading>
        );
    };

    return (
        <TableContainer maxTableHeight={maxTableHeight}>
            <Table aria-label={tableAriaLabel}>
                <thead>
                    <tr>
                        {tableHeaderData.map((data, idx) => {
                            const { title } = data;
                            const isString = typeof title === "string";

                            return (
                                <HeaderCell
                                    key={idx}
                                    isString={isString}
                                    title={title}
                                />
                            );
                        })}
                    </tr>
                </thead>
                <TableBody>
                    {tableBodyData.map((dataRow, idx) => {
                        return (
                            <tr key={idx}>
                                {dataRow.map((cellData, index) => {
                                    const {
                                        title,
                                        onClick,
                                        href,
                                        target = "_self"
                                    } = cellData;

                                    const isLink = href || onClick;
                                    const linkProps = {
                                        size: "md",
                                        styleType: "primary",
                                        disableVisitedStyle: true
                                    };
                                    const clickProps = href
                                        ? {
                                              ...linkProps,
                                              href: href,
                                              target: target,
                                              // passing through onClick even when there is a href
                                              // so that we can track the click (set target to _blank)
                                              onClick: onClick
                                          }
                                        : {
                                              ...linkProps,
                                              isButton: true,
                                              onClick: onClick,
                                              name: title
                                          };

                                    const data = isLink ? (
                                        <LinkContainer>
                                            <Link {...clickProps}>{title}</Link>
                                        </LinkContainer>
                                    ) : (
                                        <BodyTableText>
                                            {cellData === "--" && (
                                                <SrOnly>Not Applicable</SrOnly>
                                            )}
                                            {cellData}
                                        </BodyTableText>
                                    );

                                    return (
                                        <TableCell
                                            key={index}
                                            index={index}
                                            data={data}
                                        />
                                    );
                                })}
                            </tr>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

ScrollableTable.propTypes = {
    tableHeaderData: PropTypes.arrayOf(PropTypes.object).isRequired,
    tableBodyData: PropTypes.arrayOf(PropTypes.array).isRequired,
    maxTableHeight: PropTypes.string,
    tableAriaLabel: PropTypes.string.isRequired
};

export default ScrollableTable;
