// See ../readme.md for details
export const getUploadsSelectedFromUIDs = (uploadsSelected, uploadIds) => {
    let uplSel = {};

    // remove any that are no longer in array
    uplSel = filterUploadsSelected(uploadsSelected, uploadIds);

    uploadIds.forEach(id => {
        if (!uploadsSelected[id]) {
            // add missing uploadIds if there are any
            uplSel[id] = false; // all `uploadIds` default to false (not selected) when first added
        } else {
            // preserves selected state if already exists
            uplSel[id] = !!uploadsSelected[id];
        }
    });

    return uplSel;
};

export const convertUidsToSelectedUidObj = uploadIds => {
    let selectedUidObj = {};

    uploadIds.forEach(uid => {
        selectedUidObj[uid] = true;
    });

    return selectedUidObj;
};

const filterUploadsSelected = (uploadsSelected, uploadIds) => {
    return Object.keys(uploadsSelected)
        .filter(key => {
            return uploadIds.includes(key);
        })
        .reduce((obj, key) => {
            obj[key] = uploadsSelected[key];
            return obj;
        }, {});
};

// get array of upload keys
export const getUploadIds = uploadsSelected => {
    return uploadsSelected ? Object.keys(uploadsSelected) : [];
};
export const getSelectedUploadIds = uploadsSelected => {
    return getUploadIds(uploadsSelected).filter(id => !!uploadsSelected[id]);
};

// Counting
export const getUploadsLength = uploadsSelected => {
    const uploadIds = getUploadIds(uploadsSelected);
    return uploadIds.length;
};
export const getSelectedLength = uploadsSelected => {
    const checkedIds = getSelectedUploadIds(uploadsSelected);
    return checkedIds.length;
};
export const isAllSelectedCheck = uploadsSelected => {
    const quantitySelected = getSelectedLength(uploadsSelected);
    const totalUploads = getUploadsLength(uploadsSelected);

    if (totalUploads === 0) return false;

    return quantitySelected === totalUploads;
};

// Every time uploadsSelected changes, we need to update these props
export const setSideEffects = uploadsSelected => {
    return {
        quantitySelected: getSelectedLength(uploadsSelected),
        isAllSelected: isAllSelectedCheck(uploadsSelected)
    };
};

// Takes a range of uploadIds and either selected or deselects
export const selectOrDeselect = ({
    uploadsSelected,
    uploadIds,
    isSelecting = true
}) => {
    uploadIds.forEach(id => {
        uploadsSelected[id] = isSelecting;
    });
    return uploadsSelected;
};

// Upload ID: This creates a normalized uploadObject with { id, gridIndex, isSelected }
export const getUploadObjectById = (id, uploadsSelected) => {
    let gridIndex;
    getUploadIds(uploadsSelected).forEach((us, idx) => {
        if (us === id) {
            gridIndex = idx;
        }
    });

    return {
        id,
        gridIndex,
        isSelected: uploadsSelected[id]
    };
};

// Upload GridIndex: This creates a normalized uploadObject with { id, gridIndex, isSelected }
export const getUploadObjectByGridIndex = (gridIndex, uploadsSelected) => {
    let id;
    getUploadIds(uploadsSelected).forEach((us, idx) => {
        if (idx === gridIndex) {
            id = us;
        }
    });

    return {
        id,
        gridIndex,
        isSelected: uploadsSelected[id]
    };
};

/**
 * Range Selection Business Logic
 *
 *  - Range: includes currentItem, destinationItem, and everything item in between
 *      [currentItem ...item[n]... destinationItem]
 *
 *  - Only gets triggered when shift + click occurs and there are more than 2 uploads in grid
 *
 *  - Things we need to know
 *      - clickedUploadId: (string || number) upload ID of what the user just clicked on
 *      - uploadsSelected: (object: { uploadID: true || false }) this is our source object of upload id's and their current selected state
 *      - quantitySelected: (number) how many are currently selected
 *      - prevSelectedDirection: (string: ["right", "left"])  Selected direction determines destinationItem for deselection. When user selects all, we assume "right"
 *
 * We can then determine:
 *      - isSelecting: (bool) is User Selecting, or deselecting
 *
 *  - Is Selecting
 *      - If NO Grid Items are selected, destinationItem = firstItem (index 0)
 *      - else destinationItem = nearest selected neighboring item
 *  - Is DE-Selecting
 *      - if prevSelectedDirection === "right"
 *          - always deselect from last item selected
 *      - else if prevSelectedDirection === "left"
 *          - always deselect from first item selected
 */
export const getRangeUploadsSelected = ({
    clickedUploadId,
    uploadsSelected,
    quantitySelected,
    prevSelectedDirection,
    lastSelectedId
}) => {
    const clickedUpload = getUploadObjectById(clickedUploadId, uploadsSelected);

    // isSelecting = destination. This will always be oppositte of current selection state
    // e.g. if user clicked a selected item, destination will be unselected
    const isSelecting = !clickedUpload.isSelected;

    // get destination index
    const {
        destinationUpload,
        newPrevSelectedDirection
    } = getDestinationUploadAndDirection({
        clickedUpload,
        uploadsSelected,
        quantitySelected,
        isSelecting,
        prevSelectedDirection,
        lastSelectedId
    });

    // Get range of Upload IDs to
    const uploadIds = getUploadIdRange({
        uploadsSelected,
        clickedUpload,
        destinationUpload
    });

    // return updated uploadsSelected
    const newUploadsSelected = selectOrDeselect({
        uploadsSelected,
        uploadIds,
        isSelecting
    });

    return { newUploadsSelected, newPrevSelectedDirection };
};

// Handler for selecting and deselecting an array of uploadIds
const getDestinationUploadAndDirection = ({
    clickedUpload,
    uploadsSelected,
    quantitySelected,
    isSelecting,
    prevSelectedDirection,
    lastSelectedId
}) => {
    // defaults
    let destUplObjAndDirection = {
        newPrevSelectedDirection: "right",
        destinationUpload: getUploadObjectByGridIndex(0, uploadsSelected)
    };

    // only evaluate further if something is selected
    if (quantitySelected > 0) {
        destUplObjAndDirection = isSelecting
            ? getDestUplObjAndDirectionSelecting({
                  clickedUpload,
                  uploadsSelected,
                  lastSelectedId
              })
            : getDestUplObjAndDirectionDeselecting({
                  clickedUpload,
                  uploadsSelected,
                  prevSelectedDirection
              });
    }

    return destUplObjAndDirection;
};

const getNearestPriorUpload = (clickedUpload, uploadsSelected) => {
    const firstIndex = 0;
    const secondIndex = 1;

    // assume first index
    let nearestPriorUpload = getUploadObjectByGridIndex(
        firstIndex,
        uploadsSelected
    );

    // if what they are clicking on is the second or greater
    // then there is a chance its not the first index
    if (clickedUpload.gridIndex > secondIndex) {
        let found = false;
        for (
            let gridIndex = clickedUpload.gridIndex - 1;
            gridIndex >= secondIndex;
            gridIndex--
        ) {
            const thisUpload = getUploadObjectByGridIndex(
                gridIndex,
                uploadsSelected
            );

            if (!found && thisUpload.isSelected) {
                found = true;
                nearestPriorUpload = { ...thisUpload };
            }
        }
    }

    return nearestPriorUpload;
};
const getNearestNextUpload = (clickedUpload, uploadsSelected) => {
    const uploadsCount = getUploadIds(uploadsSelected).length;

    const lastIndex = uploadsCount - 1;
    const secondTolastIndex = lastIndex - 1;

    // assume last index
    let nearestNextUpload = getUploadObjectByGridIndex(
        lastIndex,
        uploadsSelected
    );

    // if what they are clicking on is less than the secondTolastIndex
    // then there is a chance its not the last index
    if (clickedUpload.gridIndex < secondTolastIndex) {
        let found = false;
        for (
            let gridIndex = clickedUpload.gridIndex + 1;
            gridIndex <= secondTolastIndex;
            gridIndex++
        ) {
            const thisUpload = getUploadObjectByGridIndex(
                gridIndex,
                uploadsSelected
            );
            if (!found && thisUpload.isSelected) {
                found = true;
                nearestNextUpload = { ...thisUpload };
            }
        }
    }

    return nearestNextUpload;
};

const getDestUplObjAndDirectionSelecting = ({
    clickedUpload,
    uploadsSelected,
    lastSelectedId
}) => {
    // get last selected upload
    const lastSelectedUpload = lastSelectedId
        ? getUploadObjectById(lastSelectedId, uploadsSelected)
        : null;

    // get nearest prior upload
    const priorUpload = getNearestPriorUpload(clickedUpload, uploadsSelected);

    // get nearest next upload
    const nextUpload = getNearestNextUpload(clickedUpload, uploadsSelected);

    const priorUploadDiff = clickedUpload.gridIndex - priorUpload.gridIndex;
    const nextUploadDiff = nextUpload.gridIndex - clickedUpload.gridIndex;
    let destinationUpload;

    // if both are selected, return closest, prioritze previous if they match
    if (lastSelectedUpload) {
        destinationUpload = lastSelectedUpload;
    } else if (priorUpload.isSelected && nextUpload.isSelected) {
        destinationUpload =
            priorUploadDiff <= nextUploadDiff ? priorUpload : nextUpload;
    } else {
        // if only one is selected, return selected destinationUpload -  prioritze previous if neither are selected
        destinationUpload = nextUpload.isSelected ? nextUpload : priorUpload;
    }

    const newPrevSelectedDirection =
        clickedUpload.gridIndex >= destinationUpload.gridIndex
            ? "right"
            : "left";

    return { destinationUpload, newPrevSelectedDirection };
};

const getDestUplObjAndDirectionDeselecting = ({
    uploadsSelected,
    prevSelectedDirection
}) => {
    let destinationUpload;
    const uploadCount = getUploadIds(uploadsSelected).length;
    const lastIndex = uploadCount - 1;
    let found = false;

    if (prevSelectedDirection === "right") {
        // get last selected upload
        for (let gridIndex = lastIndex; gridIndex >= 0; gridIndex--) {
            const thisUpload = getUploadObjectByGridIndex(
                gridIndex,
                uploadsSelected
            );
            if (!found && thisUpload.isSelected) {
                found = true;
                destinationUpload = { ...thisUpload };
            }
        }
    } else {
        // get first selected upload
        for (let gridIndex = 0; gridIndex < lastIndex; gridIndex++) {
            const thisUpload = getUploadObjectByGridIndex(
                gridIndex,
                uploadsSelected
            );
            if (!found && thisUpload.isSelected) {
                found = true;
                destinationUpload = { ...thisUpload };
            }
        }
    }

    return {
        destinationUpload,
        newPrevSelectedDirection: prevSelectedDirection
    };
};

export const getUploadIdRange = ({
    uploadsSelected,
    clickedUpload,
    destinationUpload
}) => {
    const uploadIds = getUploadIds(uploadsSelected);

    if (!clickedUpload || !destinationUpload) {
        return [];
    }

    //  Min and Max indexes
    const minIndex = Math.min(
        destinationUpload.gridIndex,
        clickedUpload.gridIndex
    );
    const maxIndex = Math.max(
        destinationUpload.gridIndex,
        clickedUpload.gridIndex
    );

    // slice() extracts up to but not including end. So we need to add + 1
    const uploadIdRange = uploadIds.slice(minIndex, maxIndex + 1);

    return uploadIdRange;
};
/**
 * selectNext
 * @param {Array} uploadIds
 * @param {Object} uploadsSelected : uploads selected object with key value pairs of selected state (e.g. {uploadId: isSelected})
 * @returns {Object} newUploadsSelected : Revised uploadsSelected based on next selected uploadId
 */
export const selectNext = (uploadIds, uploadsSelected) => {
    // filter to array of selected ids
    const selectedUploadIds = getSelectedUploadIds(uploadsSelected);

    // determine which item should be selected
    // if zero or multiple items are selected, select first item in the grid
    let selectedUploadId = uploadIds[0];

    // if one item is selected
    if (selectedUploadIds.length === 1) {
        const prevSelectedUploadId = selectedUploadIds[0];
        const nextUploadId = getNextItem(uploadIds, prevSelectedUploadId);
        // - if last item (nextUploadId === null), keep prevSelectedUploadId
        // - otherwise, select next item in uploadIds array
        selectedUploadId = nextUploadId || prevSelectedUploadId;
    }

    const newUploadsSelected = selectOnlyTheseUploadIds(
        [selectedUploadId],
        uploadsSelected
    );

    return newUploadsSelected;
};

/**
 * selectPrev
 * @param {Array} uploadIds
 * @param {Object} uploadsSelected : uploads selected object with key value pairs of selected state (e.g. {uploadId: isSelected})
 * @returns {Object} newUploadsSelected : Revised uploadsSelected based on previous selected uploadId
 */
export const selectPrev = (uploadIds, uploadsSelected) => {
    // filter to array of selected ids
    const selectedUploadIds = getSelectedUploadIds(uploadsSelected);

    // determine which item should be selected
    // if zero or multiple items are selected, select first item in the grid
    let selectedUploadId = uploadIds[0];

    // if one item is selected
    if (selectedUploadIds.length === 1) {
        const prevSelectedUploadId = selectedUploadIds[0];
        const prevUploadId = getPrevItem(uploadIds, prevSelectedUploadId);

        // - if last item (nextUploadId === null), keep prevSelectedUploadId
        // - otherwise, select next item in uploadIds array
        selectedUploadId = prevUploadId || prevSelectedUploadId;
    }

    const newUploadsSelected = selectOnlyTheseUploadIds(
        [selectedUploadId],
        uploadsSelected
    );

    return newUploadsSelected;
};

/**
 * getNextItem
 * @param {Array} array: haystack of strings
 * @param {String} item : needle
 * @returns next item in line, or null if last in line
 */
const getNextItem = (array, item) => {
    const index = array.indexOf(item);
    if (index !== -1 && index < array.length - 1) {
        return array[index + 1];
    }
    return null; // Return null if the item is not found or it's the last item
};

/**
 * getPrevItem
 * @param {Array} array: haystack of strings
 * @param {String} item : needle
 * @returns next item in line, or null if last in line
 */
const getPrevItem = (array, item) => {
    const index = array.indexOf(item);
    return index > 0 ? array[index - 1] : null;
};

/**
 * selectOnlyTheseUploadIds
 * @param {Array} array: array of upload ids you want selected
 * @param {Object} uploadsSelected : uploads selected object with key value pairs of selected state (e.g. {uploadId: isSelected})
 * @returns next item in line, or null if last in line
 */
const selectOnlyTheseUploadIds = (uploadIds, selectedUploadIds) => {
    let newUploadsSelected = Object.fromEntries(
        Object.entries(selectedUploadIds).map(([key]) => {
            const newValue = uploadIds.includes(key);
            return [key, newValue];
        })
    );

    return newUploadsSelected;
};
