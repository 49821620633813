import styled, { css } from "styled-components";

// styles
import { convertPxToRem } from "frontend/components/helpers";
import { ControlEditStyled } from "core/FormSelectDialog/styles.js";

// styled-components
/*---------------------------
| Moderator
---------------------------*/
export const ModeratorStyled = styled.div`
    ${ControlEditStyled} {
        position: relative;
        top: -4px;
    }
`;

/*---------------------------
| Shared Avatar Styles
---------------------------*/
const moderatorAvatarStyles = css`
    display: block;
    border-radius: 50%;
    width: ${convertPxToRem({ px: 24 })};
    height: ${convertPxToRem({ px: 24 })};
`;

/*---------------------------
| Moderator Option
---------------------------*/
export const ModeratorOptionStyled = styled.div`
    display: flex;
    display: flex;
    align-items: center;
`;
export const ModOptAvatarStyled = styled.div`
    flex: 0 0 16px;
    display: flex;
    align-items: center;
    margin-right: 12px;

    img {
        ${moderatorAvatarStyles}
    }
`;
export const ModOptDisplayStyled = styled.div`
    flex-grow: 1;
`;

/*---------------------------
| Moderator Option Chosen
---------------------------*/
export const ModeratorOptionChosenStyled = styled.span`
    img {
        ${moderatorAvatarStyles}
    }
`;
