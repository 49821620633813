import { BATCH_TYPE_KEYS } from "frontend/components/fort/mod/photos/constants";

const batchTypeKeyStrings = Object.values(BATCH_TYPE_KEYS);

const setUrlParams = (enabledKeys = []) => {
    let urlParamString = "?currentPage=1&sortBy=oldeset-to-newest&textSearch=";

    batchTypeKeyStrings.forEach(str => {
        const bool = enabledKeys.includes(str) ? "true" : "false";
        const strValuePair = `&${str}=${bool}`;
        urlParamString += strValuePair;
    });

    return urlParamString;
};

const getVisualMenu = url => {
    const baseUrl = `${url}/visual-queue/`;

    return {
        groupName: "Visual Moderation",
        groupId: "1",
        groupItems: [
            {
                id: "1",
                text: "My Batches",
                href: `${baseUrl}${setUrlParams([BATCH_TYPE_KEYS.MY_BATCHES])}`
            },
            {
                id: "2",
                text: "Unassigned Batches",
                href: `${baseUrl}${setUrlParams([BATCH_TYPE_KEYS.UNASSIGNED])}`
            },
            {
                id: "3",
                text: "No Flags in Batch",
                href: `${baseUrl}${setUrlParams([BATCH_TYPE_KEYS.NO_FLAGS])}`
            },
            {
                id: "4",
                text: "Release & Editorial Flags",
                href: `${baseUrl}${setUrlParams([BATCH_TYPE_KEYS.REL_EUO])}`
            },
            {
                id: "5",
                text: "Explicit & Text Flags",
                href: `${baseUrl}${setUrlParams([BATCH_TYPE_KEYS.EXP_TXT])}`
            },
            {
                id: "6",
                text: "Saved for Later in Batch",
                href: `${baseUrl}${setUrlParams([BATCH_TYPE_KEYS.SAVED])}`
            },
            {
                id: "7",
                text: "All Batches",
                href: `${baseUrl}${setUrlParams([])}`
            }
        ]
    };
};

const getMetaDataMenu = url => {
    const baseUrl = `${url}/metadata-queue/`;

    return {
        groupName: "Metadata Moderation",
        groupId: "2",
        groupItems: [
            {
                id: "1",
                text: "All Batches",
                href: `${baseUrl}${setUrlParams([])}`
            },
            {
                id: "2",
                text: "Premium Photos in Batch",
                href: `${baseUrl}${setUrlParams([BATCH_TYPE_KEYS.PREMIUM])}`
            },
            {
                id: "3",
                text: "No Premium Photos in Batch",
                href: `${baseUrl}${setUrlParams([BATCH_TYPE_KEYS.NO_PREMIUM])}`
            },
            {
                id: "4",
                text: "Saved for Later in Batch",
                href: `${baseUrl}${setUrlParams([BATCH_TYPE_KEYS.SAVED])}`
            }
        ]
    };
};

const getAdminMenu = url => {
    return {
        groupName: "Admin Links",
        groupId: "3",
        groupItems: [
            {
                id: "1",
                text: "Completed Batches",
                href: `${url}/completed-queue/`
            },
            {
                id: "2",
                text: "Suspended Batches",
                href: `${url}/suspended-queue/`
            }
        ]
    };
};

export const getMenuGroups = url => {
    return [getVisualMenu(url), getMetaDataMenu(url), getAdminMenu(url)];
};
