import React from "react";
import styled from "styled-components";

import { Wrapper } from "./styles";

import Link from "core/Link";

import { nounProjectMenu } from "data/links";

let OverflowMenuDesktop = ({ closeMenu, className, isHeroSearchV2 }) => (
    <Wrapper className={className} isHeroSearchV2={isHeroSearchV2}>
        <ul>
            {nounProjectMenu.map(link => (
                <li key={link.name}>
                    <Link
                        onClick={closeMenu}
                        href={link.href}
                        target={link.external ? "_blank" : "_self"}
                        size="md"
                        styleType="secondary"
                        darkMode={!isHeroSearchV2}
                        disableVisitedStyle
                    >
                        {link.name}
                    </Link>
                </li>
            ))}
        </ul>
    </Wrapper>
);

OverflowMenuDesktop = styled(OverflowMenuDesktop)``;
export { OverflowMenuDesktop };
