import styled, { css } from "styled-components";

// core
import { convertPxToRem } from "frontend/components/helpers";

const UnderlayStyled = styled.div`
    display: grid;
    grid-template-columns: ${convertPxToRem(84)} 1fr ${convertPxToRem(149)};
    gap: ${convertPxToRem(24)};

    ${({ $animation: { opacity } }) => {
        return css`
            transition: opacity ${opacity.speed}ms ease ${opacity.delay}ms;
            opacity: ${opacity.value};
        `;
    }}
`;

export default UnderlayStyled;
