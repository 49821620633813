import { isEditableCheck } from "./isEditableCheck";

/**
 * enhanceBatches
 * @param {Object} props
 * @returns {Array} enhancedBatches
 */
export const getEnhancedBatches = async ({
    batches,
    batchesPerPage,
    currentQueue,
    currentUser,
    photoCountsForBatches,
    uploadIdsForBatches
}) => {
    try {
        let enhancedBatches = [];

        batches.map(async (batch, idx) => {
            const paginationItemNumber = idx + 1;
            const page = Math.ceil(paginationItemNumber / batchesPerPage); // reverse lookup, what page am I on?

            let enhancedBatch = enhanceBatch({
                batch,
                paginationItemNumber,
                page,
                currentQueue,
                currentUser,
                photoCountsForBatches,
                uploadIdsForBatches
            });

            enhancedBatches.push(enhancedBatch);
        });

        return enhancedBatches;
    } catch (error) {
        const errorMessage = `enhanceBatches: Error: ${error}`;
        console.error(errorMessage);
        throw new Error(error);
    }
};

/**
 * enhanceBatch: Adding Props necessary for client-side needs
 * @param {Object} props
 * @returns Enhanced Batch
 */
export const enhanceBatch = ({
    batch,
    paginationItemNumber,
    page,
    currentQueue,
    currentUser,
    photoCountsForBatches,
    uploadIdsForBatches
}) => {
    return {
        ...batch,
        page: page,
        paginationItemNumber,
        photoCounts: photoCountsForBatches.find(bpc => bpc.id === batch.id),
        selectedUploadIds: batch.selectedUploadIds || [],
        uploadIds: getThisBatchUploadIds(uploadIdsForBatches, batch.id),
        photoShoot: enhancePhotoShoot(batch.photoShoot),
        isEditable: isEditableCheck({ batch, currentQueue, currentUser }),
        isFaded: false,
        isCollapsed: false,
        transitionRemoveStatus: "idle"
    };
};

/**
 * getThisBatchUploadIds
 * @param {Array} uploadIdsForBatches
 * @param {Number} batchId
 * @returns {Array} batchUploadIds
 */
const getThisBatchUploadIds = (uploadIdsForBatches, batchId) => {
    const thisBatchUploadIds = uploadIdsForBatches.find(
        abuObj => abuObj.id === batchId
    );
    return thisBatchUploadIds?.uploadIds || [];
};

/**
 * getThisBatchUploadIds
 * @param {Object} photoShoot
 * @returns {Object} enahcnedPhotoShoot
 */
const enhancePhotoShoot = photoShoot => {
    try {
        if (!photoShoot) {
            return null;
        }

        return {
            ...photoShoot,
            errorMessage: ""
        };
    } catch (error) {
        const errorMessage = `enhancePhotoShoot: Error: ${error}`;
        console.error(errorMessage, { photoShoot });
        throw error;
    }
};
