/***********************
 *
 * NOTE:
 * always use rem key; px is for JS-related viewport features (see core/Hero/helpers.js for example)
 * for breakpoints, we typically have 3 main screen sizes we deal with;
 * it's more about screen size and less about actual "device":
 *
 * * MOBILE: 0px - 767px
 * * * * -> ex range: zero.rem to beforeTablet.rem === 0px - 767px
 *
 * * TABLET: 768px - 1199px
 * * * * -> ex range: tablet.rem to beforeDesktop.rem === 768px - 1199px
 *
 * * DESKTOP+: 1200px+
 * * * * -> ex range: desktop.rem === 1200px+
 *
 */

import { convertPxToRem } from "frontend/components/helpers";

const breakpoints = {
    zero: {
        // typically sm-min
        rem: convertPxToRem({ px: 0 }),
        px: 0
    },
    customMobileMax: {
        // not used often - double check
        rem: convertPxToRem({ px: 426 }),
        px: 426
    },
    beforeTablet: {
        // typically sm-max
        rem: convertPxToRem({ px: 767 }),
        px: 767
    },
    tablet: {
        // typically md-min
        rem: convertPxToRem({ px: 768 }),
        px: 768
    },
    beforeLaptop: {
        // not used often - double check
        rem: convertPxToRem({ px: 1023 }),
        px: 1023
    },
    laptop: {
        // not used often - double check
        rem: convertPxToRem({ px: 1024 }),
        px: 1024
    },
    beforeDesktop: {
        // typically md-max
        rem: convertPxToRem({ px: 1199 }),
        px: 1199
    },
    desktop: {
        // typically lg-min
        rem: convertPxToRem({ px: 1200 }),
        px: 1200
    },
    laptopHeightsRange: {
        // not used often - double check
        rem: `(min-height: ${convertPxToRem({
            px: 0
        })}) and (max-height: ${convertPxToRem({ px: 1000 })})`,
        px: "(min-height: 0px and (max-height: 1000px)"
    },
    outsideLaptopHeightsRange: {
        // not used often - double check
        rem: convertPxToRem({ px: 1001 }),
        px: 1001
    },
    browserSupport: {
        ie10And11: `screen and (-ms-high-contrast: active), (-ms-high-contrast: none)`
    }
};

export default breakpoints;

// TODO: rename breakpoints const & file to "mediaqueries"
