import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import {
    authLinks,
    myStuffMenu,
    myStuffMenuFav,
    myStuffApiMenu,
    myAccountMenu,
    adminMenu
} from "data/links";

// feature flags
import {
    SAVE_ICON_SETS_TO_FAVORITES,
    SAVE_PHOTOS_TO_FAVORITES
} from "frontend/data/featureFlags";
import useFeatureFlag from "frontend/hooks/useFeatureFlag";

import CreatorProfileMenuMobile from "js/components/CreatorProfileMenu/Mobile";

import { Section } from "core/Layout";
import { P } from "core/Typography";
import { OldGrid, Row, Col } from "core/oldGrid";
import colors from "core/colors";
import {
    Wrapper,
    Header,
    ContainedGrid,
    HeaderContent,
    CloseIcon,
    IconButton,
    ItemHeading,
    Item,
    Items,
    Teams,
    LogOutButton,
    ArrowLeftIcon,
    MobileSpacing
} from "./styles";

let ProfileMenuMobile = ({
    user,
    hasApiKey,
    closeMenu,
    logOut,
    className,
    showAdminMenu
}) => {
    user = user || {};
    const userTeams = user.ownedTeams || {};

    // feature flag
    const showKitsFavsIconSets = useFeatureFlag(SAVE_ICON_SETS_TO_FAVORITES);
    const showKitsFavsPhotos = useFeatureFlag(SAVE_PHOTOS_TO_FAVORITES);
    const showKitsFavs = showKitsFavsIconSets && showKitsFavsPhotos;

    const getLinks = ({ linkData }) =>
        linkData.map(link => {
            return (
                <Item
                    key={link.name}
                    onClick={closeMenu}
                    href={
                        typeof link.href === "string"
                            ? link.href
                            : link.href({
                                  username: encodeURIComponent(user.username)
                              })
                    }
                    target={link.external ? "_blank" : "_self"}
                >
                    {typeof link.name === "string"
                        ? link.name
                        : link.name({
                              hasNotifications: user.hasNotifications
                          })}
                </Item>
            );
        });

    const myStuffMenuLinks = getLinks({
        linkData: showKitsFavs ? myStuffMenuFav : myStuffMenu
    });
    const myStuffApiMenuLinks = getLinks({ linkData: myStuffApiMenu });
    const myAccountMenuLinks = getLinks({ linkData: myAccountMenu });
    const adminMenuLinks = getLinks({ linkData: adminMenu });

    return (
        <Wrapper className={className}>
            <Header>
                <ContainedGrid>
                    <HeaderContent
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <IconButton onClick={closeMenu}>
                            <ArrowLeftIcon
                                fillColor={colors.secondary.oolong}
                                onClick={closeMenu}
                            />
                        </IconButton>
                        <P size={16} bold>
                            Profile
                        </P>
                        <IconButton onClick={closeMenu}>
                            <CloseIcon fillColor={colors.secondary.oolong} />
                        </IconButton>
                    </HeaderContent>
                </ContainedGrid>
            </Header>
            {(user.isPhotoCreator || user.isIconCreator) && (
                <Items>
                    <CreatorProfileMenuMobile
                        creatorUsername={user.username}
                        isPhotoCreator={user.isPhotoCreator}
                        isIconCreator={user.isIconCreator}
                    />
                </Items>
            )}
            <ItemHeading>My Stuff</ItemHeading>
            <Items>
                {myStuffMenuLinks}
                {hasApiKey && <>{myStuffApiMenuLinks}</>}
            </Items>
            <ItemHeading>My Account</ItemHeading>
            <Items>
                {myAccountMenuLinks}
                {!!userTeams.length && (
                    <Item noLink>
                        Manage Team:
                        <Teams teams={userTeams} />
                    </Item>
                )}
            </Items>
            {showAdminMenu && (
                <>
                    <ItemHeading>Admin</ItemHeading>
                    <Items>{adminMenuLinks}</Items>
                </>
            )}
            <Section>
                <OldGrid>
                    <Row center="sm">
                        <Col sm={12} md={3}>
                            <LogOutButton
                                size="md"
                                width="100%"
                                styleType="outline"
                                onClick={logOut}
                            >
                                {authLinks.logout.text}
                            </LogOutButton>
                        </Col>
                    </Row>
                </OldGrid>
            </Section>

            <MobileSpacing />
        </Wrapper>
    );
};

ProfileMenuMobile = styled(ProfileMenuMobile)``;

ProfileMenuMobile.propTypes = {
    user: PropTypes.object,
    closeMenu: PropTypes.func,
    logOut: PropTypes.func,
    className: PropTypes.string,
    showAdminMenu: PropTypes.bool
};

export default ProfileMenuMobile;
