import merge from "lodash/merge";

import * as actionTypes from "../actions/actionTypes";

const defaultState = {
    collections: []
};

export const collections = (state = defaultState, action) => {
    let collections = [],
        nextState = {};

    switch (action.type) {
        case actionTypes.FETCH_FORT_COLLECTION_SUCCESS:
            nextState = action.response;
            return merge({}, state, nextState);
            break;
        case actionTypes.PREVIEW_COLLECTION_ICONS_SUCCESS:
            nextState = merge({}, state);
            nextState[action.collection_id].icons = action.response;
            nextState[action.collection_id].iconKeys = action.response.keys;
            return nextState;
            break;
        case actionTypes.SAVE_COLLECTION_SUCCESS:
        case actionTypes.SAVE_COLLECTION_FAILURE:
            return merge({}, state);
            break;
        case actionTypes.FEATURE_COLLECTION_SUCCESS:
            nextState = merge(
                {
                    [action.collection_id]: {}
                },
                state
            );
            nextState[action.collection_id].featured_collection = true;
            return merge({}, state, nextState);
            break;
        case actionTypes.UNFEATURE_COLLECTION_SUCCESS:
            nextState = merge(
                {
                    [action.collection_id]: {}
                },
                state
            );
            nextState[action.collection_id].featured_collection = false;
            return merge({}, state, nextState);
            break;
        case actionTypes.FETCH_FEATURED_COLLECTIONS_METADATA_SUCCESS:
            nextState = action.response;
            return merge({}, state, nextState);
            break;
        case actionTypes.FETCH_COLLECTIONS:
            nextState = {
                processing: true
            };
            return merge({}, state, nextState);
        case actionTypes.FETCH_COLLECTIONS_SUCCESS:
            const { collections, total: totalCollections } = action.response;

            nextState = {
                processing: false,
                collections,
                totalCollections
            };

            return merge({}, state, nextState);
        case actionTypes.FETCH_COLLECTIONS_FAILURE:
            nextState = {
                processing: false
            };
            return merge({}, state, nextState);
        default:
            return state;
            break;
    }

    return Object.assign({}, state, nextState);
};
