import styled, { css } from "styled-components";

// styles:
import colors from "core/colors";
import { easingSeconds } from "core/animation";
import { focusOutline } from "core/links";

// helpers:
import {
    convertPxToRem,
    handleComponentSize
} from "frontend/components/helpers";

// reusable:
const DISABLED_STYLE = css`
    pointer-events: none;
    background: ${colors.accent.darkMode.suenosBuenos};
    border: none;
    box-shadow: none;
    outline: none;

    svg {
        path,
        use,
        g,
        circle {
            fill: ${colors.secondary.oolong};
        }
    }
`;

const ACTIVE_STYLE = ({ darkMode }) => css`
    background: ${darkMode ? colors.secondary.vampire : colors.primary.whisker};
    box-shadow: none;
    border: none;
    outline: none;

    svg {
        path,
        use,
        g,
        circle {
            fill: ${darkMode ? colors.primary.diamond : colors.secondary.orca};
        }
    }

    &:hover {
        background: ${darkMode
            ? colors.primary.whisker
            : colors.secondary.vampire};
        box-shadow: none;
        border: none;
        outline: none;

        svg {
            path,
            use,
            g,
            circle {
                fill: ${darkMode
                    ? colors.secondary.orca
                    : colors.primary.diamond};
            }
        }
    }
`;

const TOGGLED_STYLE = ({ toggledBgColor, toggledIconColor }) =>
    css`
        background: ${!!toggledBgColor
            ? toggledBgColor
            : colors.alternative.darkMode.canyon};
        svg {
            path,
            use,
            g,
            circle {
                fill: ${!!toggledIconColor
                    ? toggledIconColor
                    : colors.secondary.orca};
            }
        }

        &:hover {
            background: ${!!toggledBgColor
                ? toggledBgColor
                : colors.secondary.orca};

            svg {
                path,
                use,
                g,
                circle {
                    fill: ${!!toggledIconColor
                        ? toggledIconColor
                        : colors.alternative.darkMode.canyon};
                }
            }
        }
    `;

const SIZE_TYPES = {
    XS: css`
        height: ${convertPxToRem({ px: 24 })};
        width: ${convertPxToRem({ px: 24 })};

        svg {
            height: ${convertPxToRem({ px: 14 })};
            width: ${convertPxToRem({ px: 14 })};
        }
    `,

    SM: css`
        height: ${convertPxToRem({ px: 28 })};
        width: ${convertPxToRem({ px: 28 })};

        svg {
            height: ${convertPxToRem({ px: 16 })};
            width: ${convertPxToRem({ px: 16 })};
        }
    `,

    MD: css`
        height: ${convertPxToRem({ px: 40 })};
        width: ${convertPxToRem({ px: 40 })};

        svg {
            height: ${convertPxToRem({ px: 24 })};
            width: ${convertPxToRem({ px: 24 })};
        }
    `,

    LG: css`
        height: ${convertPxToRem({ px: 48 })};
        width: ${convertPxToRem({ px: 48 })};

        svg {
            height: ${convertPxToRem({ px: 28 })};
            width: ${convertPxToRem({ px: 28 })};
        }
    `
};

const STYLE_TYPES = {
    PRIMARY: css`
        ${({ darkMode, isProcessing, isDisabled, isActive, isToggled }) => css`
            background: ${
                darkMode ? colors.primary.diamond : colors.secondary.orca
            };

            svg {
                path,
                use,
                g,
                circle {
                    fill: ${
                        darkMode
                            ? colors.secondary.orca
                            : colors.primary.diamond
                    };
                }
            }

            &:hover {
                background: ${colors.secondary.basalt};

                svg {
                    path,
                    use,
                    g,
                    circle {
                        fill: ${colors.primary.diamond};
                    }
                }
            }

            ${isActive && ACTIVE_STYLE}

            &:active {
                ${ACTIVE_STYLE}
            }

            ${isToggled && TOGGLED_STYLE}

            ${isProcessing &&
                css`
                    pointer-events: none;
                    background: ${darkMode
                        ? colors.primary.diamond
                        : colors.secondary.orca};

                    svg {
                        path,
                        use,
                        g,
                        circle {
                            fill: ${darkMode
                                ? colors.secondary.orca
                                : colors.primary.diamond};
                        }
                    }
                `}

            ${isDisabled && DISABLED_STYLE}
        `}
    `,
    SECONDARY: css`
        ${({ darkMode, isProcessing, isDisabled, isActive, isToggled }) => css`
            background: ${
                darkMode ? colors.secondary.basalt : colors.primary.snow
            };

            svg {
                path,
                use,
                g,
                circle {
                    fill: ${
                        darkMode
                            ? colors.primary.diamond
                            : colors.secondary.orca
                    };
                }
            }

            &:hover {
                background: ${
                    darkMode ? colors.secondary.slate : colors.secondary.shark
                };

                svg {
                    path,
                    use,
                    g,
                    circle {
                        fill: ${colors.primary.diamond};
                    }
                }
            }

            ${isActive && ACTIVE_STYLE}

            &:active {
                ${ACTIVE_STYLE}
            }
            
            ${isToggled && TOGGLED_STYLE}

            ${isProcessing &&
                css`
                    pointer-events: none;
                    background: ${darkMode
                        ? colors.secondary.basalt
                        : colors.primary.snow};

                    svg {
                        path,
                        use,
                        g,
                        circle {
                            fill: ${darkMode
                                ? colors.primary.diamond
                                : colors.secondary.orca};
                        }
                    }
                `}

            ${isDisabled && DISABLED_STYLE}
        `}
    `,
    CTA: css`
        ${({ darkMode, isProcessing, isDisabled, isActive, isToggled }) => css`
            background: ${
                darkMode
                    ? colors.accent.darkMode.suenosBuenos
                    : colors.accent.lightMode.persian
            };

            svg {
                path,
                use,
                g,
                circle {
                    fill: ${
                        darkMode
                            ? colors.secondary.orca
                            : colors.primary.diamond
                    };
                }
            }

            &:hover {
                background: ${
                    darkMode
                        ? colors.accent.darkMode.floridaWater
                        : colors.accent.lightMode.pansy
                };

                svg {
                    path,
                    use,
                    g,
                    circle {
                        fill: ${
                            darkMode
                                ? colors.primary.diamond
                                : colors.primary.diamond
                        };
                    }
                }
            }

            ${isActive && ACTIVE_STYLE}

            &:active {
                ${ACTIVE_STYLE}
            }
            
            ${isToggled && TOGGLED_STYLE}

            ${isProcessing &&
                css`
                    pointer-events: none;
                    background: ${darkMode
                        ? colors.secondary.basalt
                        : colors.primary.snow};

                    svg {
                        path,
                        use,
                        g,
                        circle {
                            fill: ${darkMode
                                ? colors.primary.diamond
                                : colors.secondary.orca};
                        }
                    }
                `}

            ${isDisabled && DISABLED_STYLE}
        `}
    `,
    NONE: css`
        ${({ darkMode, isProcessing, isDisabled, isActive, isToggled }) => css`
            background: transparent;

            svg {
                path,
                use,
                g,
                circle {
                    fill: ${
                        darkMode
                            ? colors.primary.diamond
                            : colors.secondary.orca
                    };
                }
            }

            &:hover {
                background: transparent;

                svg {
                    path,
                    use,
                    g,
                    circle {
                        fill: ${
                            darkMode
                                ? colors.primary.snow
                                : colors.primary.diamond
                        };
                    }
                }
            }

            ${isActive && ACTIVE_STYLE}

            &:active {
                ${ACTIVE_STYLE}
            }
            
            ${isToggled && TOGGLED_STYLE}

            ${isProcessing &&
                css`
                    pointer-events: none;
                    background: ${darkMode
                        ? colors.secondary.basalt
                        : colors.primary.snow};

                    svg {
                        path,
                        use,
                        g,
                        circle {
                            fill: ${darkMode
                                ? colors.primary.diamond
                                : colors.secondary.orca};
                        }
                    }
                `}

            ${isDisabled && DISABLED_STYLE}
        `}
    `
};

export const AllCircleButtonStyles = css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 100%;
    border: none;
    font-size: 0;
    transition: all ${easingSeconds};

    svg,
    svg path {
        transition: all ${easingSeconds};
    }

    &:focus {
        ${focusOutline};
    }

    ${({ styleType }) =>
        styleType === "secondary"
            ? STYLE_TYPES.SECONDARY
            : styleType === "none"
            ? STYLE_TYPES.NONE
            : styleType === "cta"
            ? STYLE_TYPES.CTA
            : STYLE_TYPES.PRIMARY}

    ${({ size }) => handleComponentSize({ size, styles: SIZE_TYPES })}
`;

export const CircleButtonElement = styled.span`
    ${AllCircleButtonStyles}
`;

//todo: refactor CircleButton styles to match core/Button/styles.js
