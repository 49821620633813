import React from "react";
import { useDispatch } from "react-redux";

// context
import { useQueueContext } from "frontend/components/fort/mod/core/Layouts/QueueLayout/QueueContext.js";

// redux
import { uploadsSlice } from "frontend/components/fort/mod/photos/slices/uploadsSlice/index.js";
import { messagesSlice } from "frontend/components/fort/mod/photos/slices/messagesSlice.js";

// components
import { Description } from "frontend/components/fort/mod/core/Sidebar/Overview/Description.js";

// api
import {
    useUpdateAndApprovePhotoUploadDescription,
    useApprovePhotoUploadDescription,
    useRejectPhotoUploadDescription
} from "./api";

// component
export const PhotoDescription = () => {
    const {
        state: { singleUploadSelected }
    } = useQueueContext();
    const dispatch = useDispatch();

    // DESCRIPTION
    const {
        updateAndApprovePhotoUploadDescription
    } = useUpdateAndApprovePhotoUploadDescription();
    const {
        approvePhotoUploadDescription
    } = useApprovePhotoUploadDescription();
    const { rejectPhotoUploadDescription } = useRejectPhotoUploadDescription();

    const onSaveDescription = async ({ editedText: photoDescription }) => {
        const editedTextisEmpty =
            photoDescription === null || photoDescription === "";

        const resp = editedTextisEmpty
            ? await rejectPhotoUploadDescription({
                  photoUploadId: singleUploadSelected.id
              })
            : await updateAndApprovePhotoUploadDescription({
                  photoUploadId: singleUploadSelected.id,
                  value: photoDescription
              });

        if (resp.success) {
            // update redux
            dispatch(
                uploadsSlice.actions.updateUpload({
                    id: singleUploadSelected.id,
                    changes: {
                        photoDescription: photoDescription,
                        photoDescriptionApproved: !editedTextisEmpty
                    }
                })
            );
        } else {
            // send error to inline text edit
            return {
                errors: true, // if set to true, remains in {editing mode} and errorMessage is displayed
                errorMessage: resp.message
            };
        }
    };
    const onToggleApproveDescription = async approve => {
        const gqVariables = { photoUploadId: singleUploadSelected.id };

        const resp = approve
            ? await approvePhotoUploadDescription(gqVariables)
            : await rejectPhotoUploadDescription(gqVariables);

        if (resp.success) {
            // update redux
            dispatch(
                uploadsSlice.actions.updateUpload({
                    id: singleUploadSelected.id,
                    changes: {
                        photoDescriptionApproved: approve
                    }
                })
            );
        } else {
            // pop message stack error
            dispatch(
                messagesSlice.actions.addMessage({
                    text: resp.message,
                    timed: false,
                    timeout: null,
                    infoType: "error"
                })
            );
        }
    };

    return (
        <Description
            onSaveDescription={onSaveDescription}
            onToggleApproveDescription={onToggleApproveDescription}
        />
    );
};

export default PhotoDescription;
