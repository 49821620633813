import { QUEUES } from "frontend/components/fort/mod/photos/constants";

/**
 * isEditableCheck - checks wether features are editable at the Batch level onLoad
 * @param {Object} props
 * @returns
 */
export const isEditableCheck = ({ batch, currentQueue, currentUser }) => {
    try {
        if (!batch || !currentQueue || !currentUser) {
            throw new Error(
                "isEditableCheck Error: You are missing one of the required props"
            );
        }

        const isNotAssignedToCurrentUser =
            batch.moderator?.id !== currentUser.id;

        const { releaseCount } = batch;

        const defaultIsEditable = defaultIsEditableCheck(
            isNotAssignedToCurrentUser,
            currentQueue
        );

        // set defaults
        return {
            moderator: moderatorIsEditableCheck(currentQueue),
            releases: releaseIsEditableCheck(
                releaseCount,
                isNotAssignedToCurrentUser
            ),
            photoShoot: defaultIsEditable,
            tags: defaultIsEditable,
            uploadToggleBand: defaultIsEditable,
            uploadFlags: defaultIsEditable,
            uploadTitle: defaultIsEditable,
            uploadDesc: defaultIsEditable,
            publishing: defaultIsEditable
        };
    } catch (error) {
        const errorMessage = `isEditableCheck: Error: ${error.message}`;
        console.error(errorMessage, { batch, currentQueue, currentUser });
        throw error;
    }
};

const defaultIsEditableCheck = (isNotAssignedToCurrentUser, currentQueue) => {
    let isEditable = true;

    if (isNotAssignedToCurrentUser) {
        isEditable = false;
    }

    // queue view
    if ([QUEUES.COMPLETED, QUEUES.SUSPENDED].includes(currentQueue)) {
        isEditable = false;
    }

    return isEditable;
};

const releaseIsEditableCheck = (releaseCount, isNotAssignedToCurrentUser) => {
    let isEditable = !isNotAssignedToCurrentUser;

    if (releaseCount === 0) {
        isEditable = false;
    }

    return isEditable;
};

const moderatorIsEditableCheck = currentQueue => {
    let isEditable = true;

    // If the batch is complete, withdrawn, or in one of the banned states, you can't change who owns the batch
    // so while this is simlar to defaultIsEditableCheck, it will change once we address other views (e.g. withdrawn, banned)
    if ([QUEUES.COMPLETED, QUEUES.SUSPENDED].includes(currentQueue)) {
        isEditable = false;
    }

    return isEditable;
};
