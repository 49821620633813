import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// styles
import colors from "core/colors";

const OptionLabelOverrideMobileStyled = styled.div`
    display: flex;
    align-items: center;
`;

// component
export const OptionLabelOverrideMobile = ({ text, Icon }) => {
    return (
        <OptionLabelOverrideMobileStyled title={text}>
            <Icon
                size={{ height: 16, width: 16 }}
                fillColor={colors.secondary.orca}
            />
        </OptionLabelOverrideMobileStyled>
    );
};

export default OptionLabelOverrideMobile;

// prop-types
OptionLabelOverrideMobile.propTypes = {
    text: PropTypes.string.isRequired,
    Icon: PropTypes.any.isRequired
};
