import React from "react";
import PropTypes from "prop-types";

import EditIcon from "core/ReusableIcons/Edit";

import { Text, Button } from "./styles";

import { bodyTextSizes } from "core/props";

import Flexbox from "core/Flexbox";

const EditTextDefaultMode = ({
    displayText,
    defaultDisplayText,
    buttonAriaLabel,
    enterEditingMode,
    textSize = "md"
}) => {
    return (
        <Flexbox flexDirection="row" alignItems="center">
            <Text
                isPlaceholder={defaultDisplayText && !displayText}
                size={textSize}
                onClick={enterEditingMode}
            >
                {displayText || defaultDisplayText}
            </Text>
            <Button
                data-testid="edit-text-icon-button"
                aria-label={buttonAriaLabel}
                onClick={enterEditingMode}
            >
                <EditIcon size="sm" />
            </Button>
        </Flexbox>
    );
};

EditTextDefaultMode.propTypes = {
    displayText: PropTypes.string,
    defaultDisplayText: PropTypes.string.isRequired,
    buttonAriaLabel: PropTypes.string.isRequired,
    enterEditingMode: PropTypes.func.isRequired,
    textSize: PropTypes.oneOf(bodyTextSizes)
};

export default EditTextDefaultMode;
