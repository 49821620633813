import React from "react";
import { useQuery } from "@apollo/client";

import SubmissionsHeader from "./SubmissionsHeader";
import Content from "./Content";

import { GET_PHOTO_SUBMISSIONS_COUNTS } from "./queries";

const Submissions = () => {
    const { data } = useQuery(GET_PHOTO_SUBMISSIONS_COUNTS);

    const pendingCount = data?.myPhotoSubmissionCounts?.pendingCount || 0;
    const deniedCount = data?.myPhotoSubmissionCounts?.deniedCount || 0;

    return (
        <>
            <SubmissionsHeader
                pendingCount={pendingCount}
                deniedCount={deniedCount}
            />
            <Content />
        </>
    );
};

export default Submissions;
