import { extractSsErrors } from "./errorHandlers";
import { getDefaultSuccessMessage, getDefaultErrorMessage } from "./messaging";

/**
 * getEnhancedResponse
 *  - extract all potential errors
 *  - enhanced response for Frontend use
 * @param {Object} ssResp Server Side Response
 * @returns enhancedResponse
 */
export const getEnhancedResponse = ({
    ssResp,
    gqlParams,
    actionDesc,
    successMessageOverride,
    errorMessageOverride,
    gql
}) => {
    const errors = extractSsErrors(ssResp);
    const success = errors.length === 0;
    const message = success
        ? getDefaultSuccessMessage(actionDesc, successMessageOverride)
        : getDefaultErrorMessage(actionDesc, errorMessageOverride);

    // Enhanced Response Shape
    return {
        actionDesc,
        success,
        message,
        errors,
        ssResp,
        gql,
        gqlParams
    };
};
