import { fontColors, typeScale } from "./fonts";
import { textMargin } from "./spacing";
import { grid as flexboxgrid, containerMaxWidth } from "./grid";
import { breakpoints } from "./breakpoints";
import { colors, backgroundColors } from "./colors";

const theme = {
    textMargin,
    fontColors,
    typeScale,
    flexboxgrid,
    breakpoints,
    containerMaxWidth,
    colors,
    backgroundColors
};

export default theme;
