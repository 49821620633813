import React from "react";
import PropTypes from "prop-types";

import { sitewideBannerTypes, bodyTextSizes } from "core/props";

import colors from "core/colors";
import BodyText from "core/BodyText";
import Hidden from "core/Hidden";
import { Banner, CloseBannerButton } from "./styles";

import CloseIcon from "core/ReusableIcons/Close";

const Banners = ({
    bannerKey,
    bannerDismiss,
    bannerContent,
    bannerContentMobile,
    hideIconMobile = false,
    dataShowAnonymous = true,
    type = "info",
    bodyTextSize = "md",
    icon,
    isCookieBanner = false,
    isDismissible = true
}) => {
    const textColor =
        type === "neutral" ? colors.primary.diamond : colors.secondary.orca;

    const content = !!bannerContentMobile ? (
        <>
            {/* Hide mobile content on md screens (tablet) & lg screens (desktop): */}
            <Hidden breakpoint="mdLg">{bannerContentMobile}</Hidden>

            {/* Hide desktop/tablet content on sm (mobile) screens: */}
            <Hidden breakpoint="sm">{bannerContent}</Hidden>
        </>
    ) : (
        <span>{bannerContent}</span>
    );

    const iconToShow = hideIconMobile ? (
        <>
            {/* Hide desktop/tablet icon on sm (mobile) screens: */}
            <Hidden breakpoint="sm">{icon}</Hidden>
        </>
    ) : (
        icon
    );

    return (
        <Banner
            type={type}
            textColor={textColor}
            data-callback={`accounts/hide-banner/${bannerKey}`}
            data-banner-show-anonymous={dataShowAnonymous}
            data-banner-key={type}
            isCookieBanner={isCookieBanner}
        >
            <BodyText
                type="div"
                size={bodyTextSize}
                copyColor={textColor}
                alignment="center"
            >
                {iconToShow}
                {content}
            </BodyText>

            {isDismissible && (
                <CloseBannerButton onClick={bannerDismiss}>
                    <CloseIcon
                        size="sm"
                        fillColor={textColor}
                        ariaLabel="Dismiss banner"
                    />
                </CloseBannerButton>
            )}
        </Banner>
    );
};

Banners.propTypes = {
    bannerKey: PropTypes.string.isRequired,
    bannerDismiss: PropTypes.func,
    bannerContent: PropTypes.any.isRequired,
    bannerContentMobile: PropTypes.any,
    hideIconMobile: PropTypes.bool,
    dataShowAnonymous: PropTypes.bool,
    type: PropTypes.oneOf(sitewideBannerTypes),
    bodyTextSize: PropTypes.oneOf(bodyTextSizes),
    icon: PropTypes.any,
    isDismissible: PropTypes.bool,
    isCookieBanner: PropTypes.bool
};

export default Banners;
