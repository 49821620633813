import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

// context
import { useSiteHeader } from "core/SiteHeaderV2/context/useSiteHeader.js";

// styles
import { UnstyledListItemStyled } from "core/SiteHeaderV2/styles.js";
import colors, { focusColor } from "core/colors";
import { convertPxToRem } from "frontend/components/helpers";
import { regular as regularFontWeight } from "core/fontStyles";

export const linkStyles = ($isDarkMode = false) => {
    return css`
        ${regularFontWeight}
        display: inline-block;
        text-decoration: none;

        line-height: 24px;
        color: ${$isDarkMode ? colors.primary.diamond : colors.secondary.orca};
        font-size: ${convertPxToRem(15)};
        border-bottom: solid 1px transparent;

        transition: opacity 0.5s ease;

        &:hover {
            opacity: 0.6;
            border-bottom-color: ${$isDarkMode
                ? colors.primary.diamond
                : colors.secondary.orca};
        }

        &:focus {
            border: none;
            outline: solid ${convertPxToRem(3)} ${focusColor};
        }
    `;
};

const LinkListItemStyled = styled(UnstyledListItemStyled)`
    margin: 0rem ${convertPxToRem(8)};
`;

const AnchorStyled = styled.a`
    ${({ $isDarkMode }) => {
        return css`
            ${linkStyles($isDarkMode)}
        `;
    }};
`;

// component
export const Link = ({ className, link }) => {
    const { isDarkMode } = useSiteHeader();

    return (
        <LinkListItemStyled className={className}>
            <AnchorStyled
                href={link.href}
                target={link?.target || "_self"}
                $isDarkMode={isDarkMode}
            >
                {link.name}
            </AnchorStyled>
        </LinkListItemStyled>
    );
};

export default Link;

// prop-types
Link.propTypes = {
    className: PropTypes.string,
    link: PropTypes.object.isRequired
};
