import React from "react";

// context
import { useSiteHeader } from "core/SiteHeaderV2/context/useSiteHeader.js";

// components
import TNPLogo from "core/SiteHeaderV2/components/TNPLogo";
import SecondaryMenu from "../../SecondaryMenu";

// styles
import UnderlayStyled from "./styles";

// component
export const Underlay = () => {
    const { animation } = useSiteHeader();
    return (
        <UnderlayStyled $animation={animation}>
            <div>
                <TNPLogo />
            </div>
            <div></div>
            <div>
                <SecondaryMenu />
            </div>
        </UnderlayStyled>
    );
};

export default Underlay;
