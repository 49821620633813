import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import colors, { focusBorder } from "core/colors";
import breakpoints from "core/breakpoints";
import { easingSeconds } from "core/animation";
import { regular as regularFontWeight } from "core/fontStyles";
import { convertPxToRem } from "frontend/components/helpers";

import Button from "core/Button";
import Search from "core/ReusableIcons/Search";

import { useUrlQuery } from "ssr/helpers";
import { useRestoreStyleFilters } from "frontend/ssr/hooks/useRestoreStyleFilters";

import { useSelector } from "react-redux";
import { getStyleParams } from "frontend/store/browse";

import { SEARCH, SEARCH_ICONS, SEARCH_ICON_SETS } from "data/links";

export const searchURLPath = ({ searchType }) => {
    let path;

    if (searchType === "Photos") {
        path = SEARCH;
    } else if (searchType === "Icons") {
        path = SEARCH_ICONS;
    } else if (searchType === "Icon Sets") {
        path = SEARCH_ICON_SETS;
    } else {
        throw new Error(`Unknown search type "${searchType}"`);
    }

    return path;
};

export const SearchInput = styled.input.attrs({
    type: "search"
})`
    width: 100%;
    border: 3px solid ${colors.primary.diamond};
    transition: all ${easingSeconds};
    border-radius: 0;
    -webkit-appearance: none !important;
    -webkit-logical-width: -webkit-fill-available;
    font-family: inherit;
    color: ${colors.secondary.onyx};
    font-size: 1rem; /* 16px to avoid zoom on mobile devices */
    padding: 0.875rem 1rem 0.875rem 2.625rem;
    margin: 0;
    box-sizing: border-box !important; /* overrides main.css */
    ${regularFontWeight}

    @media (max-width: ${breakpoints.beforeTablet.rem}) {
        padding: 0.375rem 0.625rem 0.375rem 3.125rem;
        /* ^^ 6px 10px 6px 50px - in the design, placeholder text is ~53px to the right of left side of input, making space for mobile search type toggle; then we subtract 3px for input border.  */
    }

    @media (min-width: ${breakpoints.tablet.rem}) {
        /* padding for search input paired with toggle; more padding on the left so we make space for the toggle & on the right so we make space for the submit button */
        padding: 0.875rem 2.5rem 0.875rem 10rem; /* 14px 40px 14px 144px */
        height: 2.625rem; /* 42px - set height to prevent variable height on different browsers */
    }

    &.compact {
        padding: 0.625rem 1rem 0.625rem 0.625rem;
        @media (max-width: ${breakpoints.beforeTablet.rem}) {
            padding: 0.375rem 0.625rem 0.375rem 0.375rem;
        }
        @media (min-width: ${breakpoints.tablet.rem}) {
            padding: 0.625rem 2.5rem 0.625rem 0.625rem;
        }
    }

    &::placeholder {
        opacity: 1;
        color: ${colors.secondary.oolong};
    }

    &:hover {
        border-color: ${colors.secondary.oolong};
    }

    &:focus {
        ${focusBorder};
        outline: none;
        &::placeholder {
            color: transparent;
            /* hides placeholder text on input focus */
        }
    }
`;

export const SubmitButton = styled(Button)`
    border-radius: 0;
    position: absolute;
    top: 0.1875rem; /* 3px */
    right: 0.1875rem; /* 3px */
    cursor: pointer;
    padding: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - 0.375rem); /* 6px */
    aspect-ratio: 1;

    ${({ isDisabled }) =>
        isDisabled &&
        css`
            background-color: ${colors.secondary.onyx};
            cursor: not-allowed;
        `}

    ${Search} {
        width: ${convertPxToRem({ px: 20 })};
        height: ${convertPxToRem({ px: 20 })};
        top: 0;
        margin-right: 0;
    }

    /* Hide button on mobile devices: */
    @media (max-width: ${breakpoints.beforeTablet.rem}) {
        display: ${({ hiddenOnMobile }) =>
            hiddenOnMobile ? "none" : "inline-flex"};
    }
`;

const SearchForm = ({
    placeholder,
    selectedSearchType,
    compact,
    hideButtonOnMobile
}) => {
    const params = useUrlQuery();
    const initialQuery = params.q || "";
    useRestoreStyleFilters();
    // NOTE: SearchForm is sometimes used outside Redux context, so data may be null
    const styleParams = useSelector(getStyleParams) || {};

    const [query, setQuery] = useState(initialQuery);

    const handleInputChange = e => {
        setQuery(e.target.value);
    };

    const searchPath = searchURLPath({
        searchType: selectedSearchType
    });

    return (
        <form action={searchPath}>
            <SearchInput
                aria-label="Search term"
                name="q"
                placeholder={placeholder}
                value={query || ""}
                onChange={handleInputChange}
                className={compact ? "compact" : ""}
            />
            {styleParams?.style && selectedSearchType === "Icons" && (
                <input type="hidden" name="style" value={styleParams.style} />
            )}
            {styleParams?.weight && selectedSearchType === "Icons" && (
                <input type="hidden" name="weight" value={styleParams.weight} />
            )}
            <SubmitButton
                isSubmit
                aria-label="Submit search term"
                isDisabled={!query}
                hiddenOnMobile={hideButtonOnMobile}
            >
                <Search fillColor={colors.primary.diamond} size="md" />
            </SubmitButton>
        </form>
    );
};

SearchForm.propTypes = {
    placeholder: PropTypes.string,
    selectedSearchType: PropTypes.string
};

export default SearchForm;
