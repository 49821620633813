import gql from "graphql-tag";

export const LIST_FILE_CHECK_FAILED_PHOTO_UPLOADS = gql`
    query ListFileCheckFailedPhotoUploads(
        $showFailedBeforeSubmission: Boolean = true
    ) {
        listPhotoUploads(
            showFailedBeforeSubmission: $showFailedBeforeSubmission
        ) {
            totalCount
            items {
                id
                status
                autoDenied
                errors
                originalFilename
                uploadedImageUrl
                photoShoot {
                    id
                    isForSinglePhoto
                    __typename
                }
                __typename
            }
            __typename
        }
    }
`;

export const GET_PHOTO_SHOOT_FOR_ADDING_UPLOADS = gql`
    query GetPhotoShootForAddingPhotoUploads {
        shoot: getPhotoShootForAddingPhotoUploads {
            id
            isForSinglePhoto
            title
        }
    }
`;
