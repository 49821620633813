import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import colors from "core/colors";

const createHelpers = (width, height, css) => {
    // somehow sizes is ending up in markup, even if it is not a valid svg attribute
    // until we have a better solution, just render it empty, instead to '[Object object]'
    const sanitizeSizes = sizes =>
        Object.defineProperty(sizes, "toString", {
            value: () => "",
            enumerable: false
        });

    const getDimensions = (size, sizes) => {
        if (
            size &&
            typeof size.width === "number" &&
            typeof size.height === "number"
        ) {
            return size;
        }

        return size && sizes[size] ? sizes[size] : { width, height };
    };

    const getCss = (size, sizes, fillColor, fillColorRule, noStyles) => {
        if (noStyles) {
            return "";
        }

        const dimensions = getDimensions(size, sizes);
        const fillRule =
            fillColor && fillColorRule
                ? `${fillColorRule}{ fill: ${fillColor}; }`
                : "";

        return css`
            width: ${dimensions.width}px;
            height: ${dimensions.height}px;
            ${fillRule}
        `;
    };

    const propsToCss = ({ size, sizes, fillColor, fillColorRule, noStyles }) =>
        getCss(size, sizes, fillColor, fillColorRule, noStyles);

    return {
        getCss,
        getDimensions,
        propsToCss,
        sanitizeSizes
    };
};

const width = "24";
const height = "24";
const viewBox = "0 0 24 24";

const { getDimensions, getCss, propsToCss, sanitizeSizes } = createHelpers(
    width,
    height,
    css
);

const sizes = sanitizeSizes({
    xs: { width: 14, height: 14 },
    sm: { width: 16, height: 16 },
    md: { width: 20, height: 20 },
    lg: { width: 24, height: 24 },
    xl: { width: 40, height: 40 }
});

const Image = styled.svg`
    ${propsToCss}
`;

const children = (
    <Fragment>
        <path
            fill={colors.secondary.orca}
            d="M10.5 11h3v9.5a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5V11z"
            key="key-0"
        />
        <path
            fill={colors.secondary.orca}
            fillRule="evenodd"
            d="M20.315 3.923c.003.104.003.21.003.313-.004 1.033-.14 2.087-.459 3.071-.203.63-.477 1.245-.856 1.789a5.537 5.537 0 01-1.54 1.499c-1.057.687-2.31 1.017-3.552 1.158-.577.065-1.173.101-1.753.052a.452.452 0 01-.438-.438 6.63 6.63 0 01-.018-.575c.005-1.032.14-2.087.46-3.07.203-.63.475-1.246.854-1.79a5.55 5.55 0 011.541-1.498c1.06-.687 2.31-1.018 3.553-1.157a9.545 9.545 0 011.537-.067c.072.004.144.008.216.015a.44.44 0 01.258.083l.001.001.005.003a.06.06 0 01.006.004l.008.008.005.004.008.007.004.002-.002-.001.032.03a.041.041 0 01.004.007l.005.005.005.005a.026.026 0 00.006.009.444.444 0 01.092.27c.008.087.013.174.015.261zM15.92 6.531c.463-.301 1.046-.518 1.736-.657l-1.736.657zm1.736-.657c-.048.23-.106.452-.175.665-.15.466-.328.841-.528 1.127l-.001.003c-.239.343-.52.615-.848.829-.463.3-1.047.518-1.738.657.047-.23.105-.45.174-.663v-.001c.153-.47.33-.845.528-1.13h.001c.238-.342.52-.616.85-.83m4.171-3.128h-.008.008z"
            clipRule="evenodd"
            key="key-1"
        />
        <path
            fill={colors.secondary.orca}
            fillRule="evenodd"
            d="M11.601 14.815a9.615 9.615 0 01-.313.003c-1.033-.004-2.086-.14-3.07-.459-.63-.203-1.246-.477-1.79-.856a5.536 5.536 0 01-1.498-1.54c-.687-1.057-1.018-2.31-1.158-3.552-.065-.577-.102-1.173-.052-1.753a.452.452 0 01.438-.438c.19-.017.384-.018.574-.018 1.033.005 2.088.14 3.071.46.629.203 1.246.476 1.79.855a5.55 5.55 0 011.498 1.54c.687 1.06 1.017 2.311 1.157 3.553a9.55 9.55 0 01.067 1.537 6.19 6.19 0 01-.015.216.44.44 0 01-.084.258v.001l-.004.005-.004.006c-.002.002-.005.004-.007.008l-.005.005a.063.063 0 00-.006.008l-.003.004.002-.002-.03.032a.04.04 0 01-.007.005c-.002 0-.004.002-.005.004l-.006.005a.029.029 0 00-.008.006.05.05 0 00-.01.008h-.001a.444.444 0 01-.26.084 4.274 4.274 0 01-.26.015zM8.994 10.42c.3.463.518 1.046.656 1.736l-.656-1.736zm.656 1.736a6.36 6.36 0 01-.664-.175c-.466-.15-.842-.328-1.128-.528l-.002-.001a3.037 3.037 0 01-.83-.848c-.3-.463-.518-1.047-.657-1.738.23.048.451.106.663.174h.002c.47.153.845.33 1.129.529.342.238.616.52.83.85m3.128 4.171v-.008.008z"
            clipRule="evenodd"
            key="key-2"
        />
    </Fragment>
);

const defaultProps = {
    children,
    viewBox,
    fillColor: null,
    fillColorRule: "&&& path, &&& use, &&& g",
    sizes,
    size: null
};

Image.propTypes /* remove-proptypes */ = {
    fillColor: PropTypes.string,
    fillColorRule: PropTypes.string,
    viewBox: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    size: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            height: PropTypes.number.isRequired,
            width: PropTypes.number.isRequired
        })
    ]),
    sizes: PropTypes.shape({
        height: PropTypes.number,
        width: PropTypes.number
    })
};

export default Object.assign(Image, {
    getDimensions,
    getCss,
    defaultProps,
    displayName: "Grow"
});
