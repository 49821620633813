import styled, { css } from "styled-components";

import breakpoints from "core/breakpoints";

const heroContainerTabletLeftRightPadding = css`
    padding-left: 1.25rem; /* 20px */
    padding-right: 1.25rem; /* 20px */
`;

export const HeroContainer = styled.div`
    /* copied from SectionContainer: */
    margin: 0 auto;
    position: relative;
    width: 100%;
    max-width: 85.38rem; /* 1366px */

    ${({ containerBottomSpacing }) => containerBottomSpacing}

    /* Mobile: */
    padding-left: 0.9375rem; /* 15px */
    padding-right: 0.9375rem; /* 15px */

    /* Tablet: */
    @media (min-width: ${breakpoints.tablet.rem}) {
        ${heroContainerTabletLeftRightPadding};
    }

    /* Desktop: */
    @media (min-width: ${breakpoints.desktop.rem}) {
        padding-left: 1.5rem; /* 24px */
        padding-right: 1.5rem; /* 24px */
    }

    /* Screens with 700px - 1000px height range: */
    @media ${breakpoints.laptopHeightsRange.rem} {
        @media (min-width: ${breakpoints.desktop.rem}) {
            ${heroContainerTabletLeftRightPadding};
        }
    }

    .stuck-searchbar {
        display: none;
    }
`;
// ^^ Above spacing mimics default SectionContainer padding, but puts it inside a container inside the hero so the svg image at the bottom can reach the edges of the SectionContainer
