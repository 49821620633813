/*
 * Normalizers are used by the API middleware to take responses and convert them be used in our
 * Redux state tree. Part of the work is converting the taxonomy the API uses (project, collection)
 * to the user-facing taxonomy (kit, group). The more important aspect is normalizing the nested
 * objects into a flat representation, so that the entities can be shared without duplicating data.
 */

if (!global._babelPolyfill) {
    require("@babel/polyfill");
}

import * as normalizr from "normalizr";

//
// Search
//

export const search = searchJson => {
    let searchData = {
            suggestionKeys: [],
            query: searchJson.term
        },
        query = searchJson.term;

    if (Array.isArray(searchJson.suggestions)) {
        // search suggestions
        searchJson.suggestions.forEach(suggestion => {
            searchData.suggestionKeys.push(
                searchJson.suggestions.indexOf(suggestion)
            );
            searchData[searchJson.suggestions.indexOf(suggestion)] = {
                term: suggestion,
                selected: false,
                query: query
            };
        });
    }

    if (searchJson.icon_match) {
        searchData.suggestionKeys.push(searchJson.icon_match.id);
        searchData[searchJson.icon_match.id] = searchJson.icon_match;
        searchData[searchJson.icon_match.id].matched_icon = true;
    }

    return searchData;
};

//
// Collections
//

export const collections = collectionJson => {
    let collectionData = {
        keys: []
    };

    if (collectionJson.featured_collections) {
        collectionJson.featured_collections.forEach(collection => {
            collectionData.keys.push(collection.id);
            collectionData[collection.id] = collection;
        });
    } else {
        collectionData.keys.push(collectionJson.collection.id);
        collectionData[collectionJson.collection.id] =
            collectionJson.collection;

        if (collectionJson.icons) {
            collectionData[collectionJson.collection.id].icons = {};
            collectionData[collectionJson.collection.id].iconKeys = [];
            collectionJson.icons.forEach(icon => {
                collectionData[collectionJson.collection.id].iconKeys.push(
                    icon.id
                );
                collectionData[collectionJson.collection.id].icons[
                    icon.id
                ] = icon;
            });
        }
    }

    return collectionData;
};

//
// Icons
//

export const icons = iconJson => {
    let iconData = {
        keys: []
    };

    if (iconJson.query_info) {
        if (iconJson.query_info.query)
            iconData.query = iconJson.query_info.query;
        if (iconJson.query_info.result_type)
            iconData.result_type = iconJson.query_info.result_type;
        iconData.scope_id = iconJson.query_info.scope_id;
        iconData.scope = iconJson.query_info.scope_type;
        iconData.did_you_mean = iconJson.suggestion;
        iconData.counts = iconJson.counts;
    }

    if (iconJson.collection_info) {
        iconData.result_type = "icons";
        iconData.collection = {
            name: iconJson.collection_info.name,
            permalink: iconJson.collection_info.permalink
        };
        iconData.scope_id = iconJson.query_info.scope_id;
        iconData.scope = iconJson.query_info.scope_type;
    }

    if (iconJson.creator_info) {
        iconData.result_type = "icons";
        iconData.creator = {
            id: iconJson.creator_info.id,
            name: iconJson.creator_info.name,
            permalink: iconJson.creator_info.permalink,
            username: iconJson.creator_info.username
        };
        iconData.scope_id = iconJson.query_info.scope_id;
        iconData.scope = iconJson.query_info.scope_type;
    }

    if (iconJson.total) {
        iconData.search_total = iconJson.total;
    }

    if (iconJson.icon) {
        if (Array.isArray(iconJson.icon)) {
            iconJson.icon.forEach(icon => {
                iconData.keys.push(icon.id);
                iconData[icon.id] = icon;
            });
        } else {
            iconData.keys.push(iconJson.icon.id);
            iconData[iconJson.icon.id] = iconJson.icon;
        }

        iconData[iconJson.icon.id].moderator = iconJson.moderator;
    } else {
        let icons = iconJson;

        if (iconJson.featured_icons) {
            iconJson.icons = iconJson.featured_icons;
            delete iconJson.featured_icons;
        }

        if (iconJson.icons) {
            icons = [];
            for (var key in iconJson.icons) {
                if (iconJson.icons.hasOwnProperty(key)) {
                    icons.push(iconJson.icons[key]);
                }
            }
        }

        if (iconJson.collection) {
            iconData.collection = iconJson.collection;
        }

        if (iconJson.total_related_count) {
            iconData.total_related_count = iconJson.total_related_count;
        }

        icons.forEach(icon => {
            if (icon.freemium === "1" || icon.freemium === 1) {
                icon.freemium = true;
            } else {
                icon.freemium = false;
            }

            iconData.keys.push(icon.id);
            iconData[icon.id] = icon;
        });

        if (iconJson.total !== undefined) {
            iconData.search_total = iconJson.total;
        }
    }

    return iconData;
};

//
// User
//

export const user = userJson => {
    let user = {};
    if (userJson.user) {
        user = userJson.user;
        user.csrf = userJson.csrf;

        if (user.has_pro_account || user.has_team_account) {
            user.has_premium_account = true;
        } else {
            user.has_premium_account = false;
        }
        delete user.has_pro_account;
        delete user.has_team_account;
    }

    if (userJson.card) {
        user.card = userJson.card;
    }
    return user;
};

//
// Creators
//

function cleanedCreatorData(creatorResponse, creator) {
    creatorResponse.featured = creatorResponse.featured_user;
    delete creatorResponse.featured_user;

    if (creatorResponse.auth_user) {
        creator.id = creator.user_ptr_id;
        creator.username = creatorResponse.auth_user.username;
        creator.email = creatorResponse.auth_user.email;
        creator.data_joined = creatorResponse.auth_user.data_joinedde;
        creator.is_active = creatorResponse.auth_user.is_active;
        creator.is_staff = creatorResponse.auth_user.is_staff;
        creator.is_superuser = creatorResponse.auth_user.is_superuser;
        creator.last_login = creatorResponse.auth_user.last_login;
        creator.date_joined = creatorResponse.auth_user.date_joined;
        delete creator.auth_user;
        delete creator.user_ptr_id;

        creator.user_environment = window.userEnvironment;
        creator.last_premium_download = creatorResponse.last_premium_download;
    }

    if (creator.organizations) {
        creator.organization_keys = [];
        creator.orgs = creator.organizations;
        creator.organizations = {};
        creator.orgs.forEach(org => {
            creator.organization_keys.push(org.id);
            creator.organizations[org.id] = org;
            creator.organizations[org.id].active = org.team.active;
            creator.organizations[org.id].customer_id = org.team.customer_id;
            creator.organizations[org.id].seats = org.team.seats;
            creator.organizations[org.id].slug = org.team.slug;
            creator.organizations[org.id].owner_id = org.team.owner_id;
            creator.organizations[org.id].owner_name = org.team.owner_name;
            delete creator.organizations[org.id].team;
        });
        delete creator.orgs;
    }

    for (var prop in creator) {
        if (creator.hasOwnProperty(prop)) {
            if (!prop.includes("id")) {
                // make sure we dont convert the value of any key with "id" featured in the name
                if (creator[prop] === "0") {
                    creator[prop] = "False";
                }
                if (creator[prop] === "1") {
                    creator[prop] = "True";
                }
                if (creator[prop] === 0) {
                    creator[prop] = false;
                }
                if (creator[prop] === 1) {
                    creator[prop] = true;
                }
            }
        }
    }

    // Probably safer to ignore than change.
    // eslint-disable-next-line no-redeclare
    for (var prop in creator.creator_info) {
        if (creator.creator_info.hasOwnProperty(prop)) {
            if (creator.creator_info[prop] === 0) {
                creator.creator_info[prop] = false;
            }
            if (creator.creator_info[prop] === 1) {
                creator.creator_info[prop] = true;
            }
        }
    }

    return creator;
}

export const creator = creatorJson => {
    let creator,
        creatorResponse,
        creatorData = {
            keys: []
        };

    if (creatorJson.user) {
        // regular creator
        creatorResponse = creatorJson.user;
    }
    if (creatorJson.featured_users) {
        // featured creator
        creatorResponse = creatorJson.featured_users;
    }

    if (creatorResponse.auth_user) {
        creatorData.keys.push(creatorResponse.auth_user.id);

        creator = creatorData[creatorResponse.auth_user.id] = creatorResponse;
        creator = cleanedCreatorData(creatorResponse, creator);
    } else {
        if (Array.isArray(creatorResponse)) {
            creatorResponse.forEach(creator => {
                creatorData.keys.push(creator.id);

                creatorData[creator.id] = creator;

                creator = cleanedCreatorData(creatorResponse, creator);
            });
        } else {
            creatorData.keys.push(creatorResponse.id);

            creatorData[creatorResponse.id] = creatorResponse;
            creator = creatorResponse;
            creator = cleanedCreatorData(creatorResponse, creator);
        }
    }

    return creatorData;
};

//
// Uploads
//

const cleanUpload = upload => {
    let icons;

    // moderator
    delete upload.moderator_id;

    // creator - this is currently seperate from the creator normalizr merge them
    upload.creator = upload.user;
    upload.creator.icon_rating = upload.creator.creator_info.icon_percentage;
    upload.creator.notes = upload.creator.creator_info.notes;
    if (upload.creator.creator_info.important_note === 0) {
        upload.creator.important_note = false;
    } else {
        upload.creator.important_note = true;
    }

    creator.avatar = creator.avatar;
    upload.creator.star_rating = upload.creator.creator_info.star_rating;
    upload.creator.is_suspended = upload.creator.creator_info.is_suspended;
    upload.creator.suspension_reason =
        upload.creator.creator_info.suspension_reason;
    delete upload.creator.creator_info;
    delete upload.user;

    if (!upload.moderator) {
        // inject empty moderator name
        upload.moderator = {
            name: null
        };
    }

    // icons
    upload.iconKeys = [];
    icons = upload.icons;

    if ("icons" in upload) {
        upload.icons = {};

        icons.forEach(icon => {
            upload.iconKeys.push(icon.id);

            // set default feature state
            icon.feature_icon = false;
            icon.feature_collection = false;

            if (!icon.collection) {
                icon.collection = {};
            }

            icon.url = icon.svg_url;
            delete icon.svg_url;

            let { suggested, terms } = icon.keywords;
            delete icon.keywords;

            icon.selected = false;

            upload.icons[icon.id] = icon;
            icon.name = suggested;

            icon.tags = terms;
        });
    }

    return upload;
};

export const upload = uploadJson => {
    let upload = uploadJson.upload,
        uploads = uploadJson.uploads,
        uploadData = {
            counts: uploadJson.counts,
            keys: [],
            has_active: uploadJson.has_active
        };

    if (uploads) {
        uploads.forEach(upload => {
            uploadData.keys.push(upload.id);
            uploadData[upload.id] = cleanUpload(upload);
        });
    } else {
        uploadData.keys.push(upload.id);
        uploadData[upload.id] = cleanUpload(upload);
    }

    return uploadData;
};
