import React from "react";
import PropTypes from "prop-types";

import Banners from "core/Banners";
import { NonMobileContent } from "./NonMobileContent";
import { MobileContent } from "./MobileContent";

import { SiteHeaderPlaceholderStyled, StyledSiteHeader } from "./styles";
import LoadingSpinner from "core/LoadingSpinner";
import { LoginLink, JoinLink } from "./HeaderLoggedOutLinks";

const SiteHeaderContent = ({
    currentUser,
    myPhotoModProfile,
    transparent,
    handleLogOut,
    heroSearchBgColor,
    isHeroSearchV2,
    loading,
    hideBanner,
    dismissBanner,
    bannerContent,
    bannerContentMobile,
    bannerKey,
    loginLinkLegacy,
    joinLinkLegacy,
    isScrolledDown,
    animateElements,
    animateSearch
}) => {
    const loginLink = loginLinkLegacy ? (
        loginLinkLegacy
    ) : (
        <LoginLink
            isHeroSearchV2={isHeroSearchV2}
            isScrolledDown={isScrolledDown}
        />
    );

    const joinLink = joinLinkLegacy ? (
        joinLinkLegacy
    ) : (
        <JoinLink
            isHeroSearchV2={isHeroSearchV2}
            isScrolledDown={isScrolledDown}
        />
    );

    return (
        <>
            {!hideBanner && (
                <Banners
                    bannerDismiss={dismissBanner}
                    bannerKey={bannerKey}
                    bannerContent={bannerContent}
                    bannerContentMobile={bannerContentMobile}
                    type={"info"} // make sure to edit this to correct banner type
                />
            )}
            <SiteHeaderPlaceholderStyled>
                <StyledSiteHeader
                    data-testid="site-header"
                    transparent={transparent}
                    heroSearchBgColor={heroSearchBgColor}
                    isHeroSearchV2={isHeroSearchV2}
                    id="site-header"
                    isScrolledDown={isScrolledDown}
                    animateElements={animateElements}
                    animateSearch={animateSearch}
                >
                    {!currentUser && loading ? (
                        <LoadingSpinner />
                    ) : (
                        <>
                            <NonMobileContent
                                heroSearchBgColor={heroSearchBgColor}
                                isHeroSearchV2={isHeroSearchV2}
                                currentUser={currentUser}
                                myPhotoModProfile={myPhotoModProfile}
                                transparent={transparent}
                                loginLink={loginLink}
                                joinLink={joinLink}
                                handleLogOut={handleLogOut}
                                animateElements={animateElements}
                            />
                            <MobileContent
                                heroSearchBgColor={heroSearchBgColor}
                                isHeroSearchV2={isHeroSearchV2}
                                currentUser={currentUser}
                                myPhotoModProfile={myPhotoModProfile}
                                transparent={transparent}
                                loginLink={loginLink}
                                joinLink={joinLink}
                                handleLogOut={handleLogOut}
                            />
                        </>
                    )}
                </StyledSiteHeader>
            </SiteHeaderPlaceholderStyled>
        </>
    );
};

SiteHeaderContent.propTypes = {
    currentUser: PropTypes.object,
    myPhotoModProfile: PropTypes.object,
    transparent: PropTypes.bool,
    loginLinkLegacy: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
    joinLinkLegacy: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
    handleLogOut: PropTypes.func.isRequired,
    heroSearchBgColor: PropTypes.string,
    isHeroSearchV2: PropTypes.bool,
    loading: PropTypes.bool,
    isScrolledDown: PropTypes.bool,
    animateElements: PropTypes.bool,
    animateSearch: PropTypes.bool
};

export default React.memo(SiteHeaderContent);
