import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

// redux
import {
    blurUpload,
    unblurUpload
} from "../../../../../slices/uploadFooterActionsSlice";

// styles
import { FooterStyled } from "./styles.js";

// components
import ModerationToggleBand, {
    Heart,
    MatureAudience,
    Divider,
    Clock,
    Eye,
    Expand
} from "frontend/components/fort/mod/core/ModerationToggleBand";

// config
import * as Config from "frontend/components/fort/mod/photos/config.js";

// hooks
import { useModerationBandIsEnabled } from "../../../../../hooks/useModerationBandIsEnabled.js";
import { useLargeImageView } from "../../../../../hooks/useLargeImageView.js";
import { useSetMature } from "frontend/components/fort/mod/photos/hooks/mutations/moderationBand/useSetMature.js";
import { useSetFavorite } from "frontend/components/fort/mod/photos/hooks/mutations/moderationBand/useSetFavorite.js";
import { useSetSaveForLater } from "frontend/components/fort/mod/photos/hooks/mutations/moderationBand/useSetSaveForLater.js";

import { useSelect } from "core/Grid2/GridItemContentTemplates/Select/SelectContext/index.js";

const Footer = ({ upload }) => {
    const { setMature } = useSetMature();
    const { setFavorite } = useSetFavorite();
    const { setSaveForLater } = useSetSaveForLater();
    const { uploadsSelected, getSelectedUploadIds, selectIds } = useSelect();
    const selectedUploadIds = getSelectedUploadIds(uploadsSelected);

    const dispatch = useDispatch();
    const { openLargeView } = useLargeImageView();

    const modBandEnabledObj = useModerationBandIsEnabled();
    const {
        upload: { defaultIsEnabled, expandIsEnabled, heartIsEnabled }
    } = modBandEnabledObj;

    const { id } = upload;
    const footerActions =
        useSelector(state => state.uploadFooterActions.entities[id]) || {};

    // side effects
    useEffect(() => {
        if (!expandIsEnabled) return;

        const handleKeyDown = event => {
            if (
                event.key === "Enter" &&
                event.shiftKey &&
                selectedUploadIds.includes(id)
            ) {
                event.stopPropagation();
                onExpandToggle({ isActivated: true });
            }
        };

        // Attach the event listener
        window.addEventListener("keydown", handleKeyDown);

        // Cleanup the event listener when selectNextPrevEnabled changes or component unmounts
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [expandIsEnabled, uploadsSelected, selectedUploadIds]);

    // functions
    const onSaveForLaterToggle = ({ isActivated }) => {
        setSaveForLater({ photoUploadIds: [upload.id], isActivated });
    };

    const onHeartToggle = ({ isActivated }) => {
        setFavorite({ photoUploadIds: [upload.id], isActivated });
    };

    const onMatureToggle = ({ isActivated }) => {
        setMature({ photoUploadIds: [upload.id], isActivated });
    };

    const onEyeToggle = ({ isActivated }) => {
        isActivated
            ? dispatch(blurUpload(upload.id))
            : dispatch(unblurUpload(upload.id));
    };

    const onExpandToggle = ({ isActivated }) => {
        if (isActivated) {
            // Select Grid Item if nothing is selected
            if (selectedUploadIds.length === 0) {
                selectIds([id]);
            }
            // Open Large View
            openLargeView(id);
        }
    };

    return (
        <FooterStyled>
            <ModerationToggleBand>
                <Heart
                    isDisabled={!heartIsEnabled || upload.creatorIsPremium}
                    isActivated={footerActions[Config.moderatorIconKeys.HEART]}
                    moderationIconKey={Config.moderatorIconKeys.HEART}
                    onToggle={onHeartToggle}
                />
                <MatureAudience
                    isActivated={
                        footerActions[Config.moderatorIconKeys.MATURE_AUDIENCE]
                    }
                    moderationIconKey={Config.moderatorIconKeys.MATURE_AUDIENCE}
                    isDisabled={!defaultIsEnabled}
                    onToggle={onMatureToggle}
                />
                <Divider />
                <Clock
                    isActivated={
                        footerActions[Config.moderatorIconKeys.SAVE_FOR_LATER]
                    }
                    moderationIconKey={Config.moderatorIconKeys.SAVE_FOR_LATER}
                    isDisabled={!defaultIsEnabled}
                    onToggle={onSaveForLaterToggle}
                    testId={`mod-band-save-for-later-${id}`}
                />
                <Eye
                    isActivated={footerActions[Config.moderatorIconKeys.BLUR]}
                    moderationIconKey={Config.moderatorIconKeys.BLUR}
                    isDisabled={!defaultIsEnabled}
                    onToggle={onEyeToggle}
                />
                <Expand
                    isActivated={footerActions[Config.moderatorIconKeys.EXPAND]}
                    moderationIconKey={Config.moderatorIconKeys.EXPAND}
                    isDisabled={!expandIsEnabled}
                    onToggle={onExpandToggle}
                />
            </ModerationToggleBand>
        </FooterStyled>
    );
};

export default Footer;

// prop-types
Footer.propTypes = {
    upload: PropTypes.object.isRequired
};
