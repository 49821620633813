import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

// components
import CorePhotoShootOrIndividual from "frontend/components/fort/mod/core/Batch/ContentComponents/PhotoShootOrIndividual";

// redux
import { batchesSlice } from "frontend/components/fort/mod/photos/slices/batchesSlice/index.js";
import { messagesSlice } from "frontend/components/fort/mod/photos/slices/messagesSlice.js";

// api
import {
    useUpdateAndApprovePhotoShootTitle,
    useApprovePhotoShootTitle,
    useRejectPhotoShootTitle
} from "./api";

// component
export const PhotoShootOrIndividual = ({ batch }) => {
    const { photoShoot, isEditable } = batch;
    const dispatch = useDispatch();
    const {
        updateAndApprovePhotoShootTitle
    } = useUpdateAndApprovePhotoShootTitle();
    const { approvePhotoShootTitle } = useApprovePhotoShootTitle();
    const { rejectPhotoShootTitle } = useRejectPhotoShootTitle();

    const onSave = async ({ editedText }) => {
        const editedTextisEmpty = editedText === null || editedText === "";

        const resp = editedTextisEmpty
            ? await rejectPhotoShootTitle({
                  photoShootId: photoShoot.id
              })
            : await updateAndApprovePhotoShootTitle({
                  photoShootId: photoShoot.id,
                  title: editedText
              });

        if (resp.success) {
            // update redux
            dispatch(
                batchesSlice.actions.updateOne({
                    id: batch.id,
                    changes: {
                        photoShoot: {
                            ...photoShoot,
                            title: editedText,
                            titleApproved: !editedTextisEmpty
                        }
                    }
                })
            );
        } else {
            // let InlineTextEdit know if there WERE errors.
            return {
                errors: true, // if set to true, remains in {editing mode} and errorMessage is displayed
                errorMessage: resp.message
            };
        }
    };

    const onToggleApprove = async approve => {
        const resp = approve
            ? await approvePhotoShootTitle({ photoShootId: photoShoot.id })
            : await rejectPhotoShootTitle({
                  photoShootId: photoShoot.id
              });

        if (resp.success) {
            // update redux
            dispatch(
                batchesSlice.actions.updateOne({
                    id: batch.id,
                    changes: {
                        photoShoot: {
                            ...photoShoot,
                            titleApproved: approve
                        }
                    }
                })
            );
        } else {
            // pop message stack error
            dispatch(
                messagesSlice.actions.addMessage({
                    text: resp.message,
                    timed: false,
                    timeout: null,
                    infoType: "error"
                })
            );
        }
    };

    return (
        <CorePhotoShootOrIndividual
            photoShoot={photoShoot}
            onSave={onSave}
            onToggleApprove={onToggleApprove}
            isEditable={isEditable?.photoShoot}
        />
    );
};

export default PhotoShootOrIndividual;

// prop-types
PhotoShootOrIndividual.propTypes = {
    batch: PropTypes.object.isRequired
};
