import styled from "styled-components";

import { easingSeconds } from "core/animation";
import colors from "core/colors";
import Flexbox from "core/Flexbox";

import Twitter from "core/ReusableIcons/Twitter";

export const SocialsWrapper = styled(Flexbox)`
    ${Twitter} {
        margin-left: 13px;
    }

    svg {
        transition: opacity ${easingSeconds};

        &:hover {
            opacity: 0.6;
        }
    }
`;

export const IconWrapper = styled.div``;

export const TextWrapper = styled.div`
    color: ${colors.primary.diamond};
`;

export const Wrapper = styled.div`
    width: 100%;
    padding: 1rem;

    ${IconWrapper} {
        margin-right: 1rem;
    }

    ${SocialsWrapper} {
        margin-left: 10px;
    }
`;
