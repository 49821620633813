import styled from "styled-components";

import SectionContainer from "core/SectionContainer";
import Heading from "core/Heading";
import BodyText from "core/BodyText";

import { convertPxToRem } from "frontend/components/helpers";

export const MastheadContainer = styled(SectionContainer).attrs({
    removeLeftRightPadding: true,
    topSpacing: "2xl",
    bottomSpacing: "2xl"
})`
    position: relative;
`;

export const MastheadHeading = styled(Heading).attrs({
    type: "h1",
    size: "md"
})``;

export const MastheadHeadingContainer = styled.div`
    display: flex;
    align-items: center;

    ${MastheadHeading} {
        margin-right: ${convertPxToRem({ px: 10 })};
    }
`;

export const MastheadSecondaryText = styled(BodyText).attrs({
    bold: true,
    size: "md"
})``;

export const MastheadSecondLine = styled(SectionContainer).attrs({
    removeLeftRightPadding: true,
    topSpacing: "sm",
    bottomSpacing: "none"
})``;
