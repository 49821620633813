import styled, { css } from "styled-components";

// styles
import { convertPxToRem } from "frontend/components/helpers";
import colors from "core/colors";
import SearchIcon from "core/ReusableIcons/Search";

// components
import Button from "core/Button";
import { ErrorContainer } from "core/FormElementContainer/styles.js";
import { Input } from "core/FormInput2/styles.js";

// styled-components
export const FormSearchStyled = styled.div`
    display: grid;
    grid-template-columns: auto 42px;
    gap: 0px;
    border: 3px solid ${colors.primary.diamond};

    ${ErrorContainer} {
        display: none;
    }

    ${Input} {
        border: none;
        color: ${colors.secondary.oolong};
        height: ${convertPxToRem({ px: 42 })};
    }
`;

export const SubmitButtonStyled = styled(Button)`
    border-radius: 0;
    cursor: pointer;
    padding: 0;
    height: ${convertPxToRem({ px: 42 })};

    display: inline-flex;
    align-items: center;
    justify-content: center;

    ${({ isDisabled }) =>
        isDisabled &&
        css`
            background-color: ${colors.secondary.onyx};
            cursor: not-allowed;
        `}

    ${SearchIcon} {
        width: ${convertPxToRem({ px: 20 })};
        height: ${convertPxToRem({ px: 20 })};
        top: 0;
        margin-right: 0;
        fill: ${colors.primary.diamond};
    }
`;
