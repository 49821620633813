import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import fetch from "cross-fetch";

export const cache = new InMemoryCache({
    cacheRedirects: {
        Query: {
            uploadDetail: (_, args, { getCacheKey }) =>
                getCacheKey({
                    __typename: "UploadType",
                    id: args.uploadId
                })
        }
    }
});

export const apolloClient = new ApolloClient({
    ssrMode: false,
    link: createHttpLink({
        // uri: () => window?._nounConfig?.localGraphqlEndpoint || "",
        uri: window?._nounConfig?.localGraphqlEndpoint,
        credentials: "include",
        fetch
    }),
    cache
});
