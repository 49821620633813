/**
 * calcElementPropByScroll Calulate a set of properties value based on Window Scroll Range and the Elements Scroll Range and update its state if necessary
 * @param {Object} {
 *      {Number} scrollY,
 *      {Object} elementConfig the elements configuration {scrollYRange, elementProps being influenced}
 *      {Function} action State Action Updater
 * }
 */
export const calcElementPropByScroll = ({ scrollY, elementConfig }) => {
    let propsToUpdate = {};

    const { scrollYRange, elementProps } = elementConfig;
    const { start: scrollStart, end: scrollEnd } = scrollYRange;

    Object.entries(elementProps).forEach(([propKey, propRange]) => {
        const { start: propStart, end: propEnd } = propRange;
        const isBeforeRange = scrollY <= scrollStart;
        const isAfterRange = scrollY >= scrollEnd;

        if (isBeforeRange) {
            propsToUpdate[propKey] = propStart;
        } else if (isAfterRange) {
            propsToUpdate[propKey] = propEnd;
        } else {
            // Calculate the proportion of scroll progress within the range
            const scrollProgress =
                (scrollY - scrollStart) / (scrollEnd - scrollStart);
            // Interpolate the element property within its range
            propsToUpdate[propKey] =
                propStart + (propEnd - propStart) * scrollProgress;
        }
    });

    return propsToUpdate;
};
