import React from "react";
import styled from "styled-components";

// components
import TNPLogo from "core/SiteHeaderV2/components/TNPLogo";
import TNPLogoType from "core/SiteHeaderV2/components/TNPLogoType";
import ProductsMenu from "../../components/PrimaryMenu/ProductsMenu/index";
import SecondaryMenu from "../SecondaryMenu";

// styles
import { convertPxToRem } from "frontend/components/helpers";
const Row1Styled = styled.div`
    display: grid;
    grid-template-columns: ${convertPxToRem(84)} ${convertPxToRem(183)} ${convertPxToRem(
            293
        )} 1fr;
    gap: ${convertPxToRem(24)};
`;

const RightBlockStyled = styled.div`
    display: flex;
    justify-content: flex-end;
`;

// component
export const Row1 = () => {
    return (
        <Row1Styled>
            <div>
                <TNPLogo />
            </div>
            <div>
                <TNPLogoType />
            </div>
            <ProductsMenu />
            <RightBlockStyled>
                <SecondaryMenu />
            </RightBlockStyled>
        </Row1Styled>
    );
};

export default Row1;
