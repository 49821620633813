import { useDispatch, useSelector } from "react-redux";

// TNP Apollo Custom Hooks
import { useApolloLazyQuery } from "frontend/utils/apolloApi/useApolloLazyQuery.js";

// queries
import { FETCH_BATCH_UPLOADS } from "frontend/components/fort/mod/photos/gql/queries";

// RTK
import { initializeUploadFooterActions } from "frontend/components/fort/mod/photos/slices/uploadFooterActionsSlice/index.js";
import { uploadsSlice } from "../../../slices/uploadsSlice";
import { batchesSlice } from "../../../slices/batchesSlice";

// utils
import { enhanceUploads } from "../../utils";

export const useFetchBatchUploads = () => {
    const { apolloQuery } = useApolloLazyQuery({
        actionDesc: "Fetch Batch Uploads",
        gql: FETCH_BATCH_UPLOADS
    });
    const selectedBatch = useSelector(state => state.selectedBatch);
    const { entities: batchEntities } = useSelector(state => state.batches);
    const dispatch = useDispatch();

    const fetchBatchUploads = async ({ showLoading = true } = {}) => {
        const selectedBatchId = selectedBatch?.id;
        if (selectedBatchId) {
            const batchEntity = batchEntities[selectedBatchId];

            if (showLoading) {
                dispatch(uploadsSlice.actions.setFetchStatus("loading"));
            }

            const resp = await apolloQuery({
                variables: { batchId: selectedBatchId },
                fetchPolicy: "network-only"
            });

            // adding sort order: backend provides natural sort order, we need to preserve order in various views
            const uploads =
                resp?.ssResp?.data?.list?.items.map((i, idx) => {
                    return { ...i, order: idx + 1 };
                }) || [];

            // enhance uploads for frontend needs
            const enhancedUploads = enhanceUploads(uploads, batchEntity);

            // Upload Footer Actions
            dispatch(initializeUploadFooterActions(enhancedUploads));

            // Batch Uploads Fetch Status: Reduces fetching to a single request. not every timue we select a batchj
            dispatch(
                batchesSlice.actions.updateOne({
                    id: selectedBatchId,
                    changes: {
                        uploadsFetched: true
                    }
                })
            );

            dispatch(uploadsSlice.actions.setBatchUploads(enhancedUploads));
        } else {
            console.warn(
                "fetchBatchUploads: You must first select a batch before fetching batch uploads."
            );
        }
    };

    return {
        fetchBatchUploads
    };
};
