import React from "react";
import PropTypes from "prop-types";

// styles
import { LabelStyled } from "./styles.js";

export const Label = ({ children, controlId, hideLabel }) => {
    return (
        <LabelStyled
            htmlFor={controlId}
            hideLabel={hideLabel}
            data-testid={`label-${controlId}`}
        >
            {children}
        </LabelStyled>
    );
};

export default Label;

// prop-types
Label.propTypes = {
    children: PropTypes.any,
    controlId: PropTypes.string,
    hideLabel: PropTypes.bool
};
