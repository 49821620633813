import React, { useState } from "react";
import PropTypes from "prop-types";
import Script from "next/script";

import { AdLink, AdImg } from "./styles";
import { ADS } from "./data";

import useCookieConsent from "frontend/hooks/useCookieConsent";

const SquarespaceAd = ({ className }) => {
    // Rotate ads
    const [adIndex] = useState(Math.floor(Math.random() * ADS.length));
    const ad = ADS[adIndex];

    // We want a different URL each time we show an ad.
    // The goal is to make sure the URL is never cached by the browser;
    // we want squarespace's server to know the ad was displayed.
    //
    // https://support.google.com/dcm/answer/2837459
    // https://support.google.com/dcm/answer/2837435
    const [cacheBust] = useState(Math.random() * 1000000000000000000);

    // Determine if user consented to marketing cookies
    const cookieConsent = useCookieConsent();
    const limitTracking = cookieConsent.advertising ? "0" : "1";

    // Build URLs
    const imgSrc = ad.img;
    const clickTracker = ad.click + limitTracking;
    const impressionTracker =
        ad.impression + limitTracking + `;ord=${cacheBust}`;

    return (
        <AdLink className={className} href={clickTracker} target="_blank">
            {/* next/script works on SSR & SPA pages */}
            <Script src={impressionTracker} strategy="lazyOnload" />
            <AdImg src={imgSrc} alt="Advertisement" height={90} width={728} />
        </AdLink>
    );
};

SquarespaceAd.propTypes = {
    className: PropTypes.string
};

export default SquarespaceAd;
