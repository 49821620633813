import { css } from "styled-components";

import breakpoints from "core/breakpoints";
import { convertPxToRem } from "frontend/components/helpers";

export const regularFamily = "ArbeitRegular";

export const mediumFamily = "ArbeitMedium";

export const boldFamily = "ArbeitBold";

export const regular = css`
    font-family: "${regularFamily}", "Helvetica", Arial, sans-serif;
`;

export const medium = css`
    font-family: "${mediumFamily}", "Helvetica", Arial, sans-serif;
`;

export const bold = css`
    font-family: "${boldFamily}", "Helvetica", Arial, sans-serif;
`;

export const fontStyles = css`
    body {
        font-family: "${mediumFamily}", "Helvetica", Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        font-size: 0.8125rem;
        line-height: 0.9375rem;

        @media (min-width: 48rem) {
            font-size: 0.8125rem;
            line-height: 1.03125rem;
        }
    }
`;

/*********************************************************
 *
 *          reusable text size constants
 *
 **********************************************************/

const textStyle = styles => css`
    /* applies to every breakpoint: */
    ${styles.baseStyle}

    /* mobile styles: */
    ${styles.mobile}

    /* tablet styles: */
    @media (min-width: ${breakpoints.tablet.rem}) {
        ${styles.tablet}
    }

    /* desktop styles: */
    @media (min-width: ${breakpoints.desktop.rem}) {
        ${styles.desktop}
    }

    /* laptop height styles */
    @media ${breakpoints.laptopHeightsRange.rem} {
        @media (min-width: ${breakpoints.desktop.rem}) {
            ${styles.tablet}
        }
    }
`;

export const textXs = textStyle({
    baseStyle: css`
        ${medium}
    `,
    mobile: css`
        font-size: ${convertPxToRem({ px: 12 })};
        line-height: 148%;
        letter-spacing: 0;
    `,
    tablet: css`
        font-size: ${convertPxToRem({ px: 12 })};
        line-height: 148%;
        letter-spacing: 0;
    `,
    desktop: css`
        font-size: ${convertPxToRem({ px: 12 })};
        line-height: 148%;
        letter-spacing: 0;
    `
});

export const textSm = textStyle({
    baseStyle: css`
        ${medium}
    `,
    mobile: css`
        font-size: ${convertPxToRem({ px: 13 })};
        line-height: 148%;
        letter-spacing: 0;
    `,
    tablet: css`
        font-size: ${convertPxToRem({ px: 13 })};
        line-height: 148%;
        letter-spacing: 0;
    `,
    desktop: css`
        font-size: ${convertPxToRem({ px: 13 })};
        line-height: 148%;
        letter-spacing: 0;
    `
});

export const textMd = textStyle({
    baseStyle: css`
        ${regular}
    `,
    mobile: css`
        font-size: ${convertPxToRem({ px: 15 })};
        line-height: 160%;
        letter-spacing: 0;
    `,
    tablet: css`
        font-size: ${convertPxToRem({ px: 15 })};
        line-height: 160%;
        letter-spacing: 0;
    `,
    desktop: css`
        font-size: ${convertPxToRem({ px: 15 })};
        line-height: 160%;
        letter-spacing: 0;
    `
});

export const textLg = textStyle({
    baseStyle: css`
        ${regular}
    `,
    mobile: css`
        font-size: ${convertPxToRem({ px: 16 })};
        line-height: 160%;
        letter-spacing: 0;
    `,
    tablet: css`
        font-size: ${convertPxToRem({ px: 16 })};
        line-height: 160%;
        letter-spacing: 0;
    `,
    desktop: css`
        font-size: ${convertPxToRem({ px: 16 })};
        line-height: 160%;
        letter-spacing: 0;
    `
});

export const textXl = textStyle({
    baseStyle: css`
        ${regular}
    `,
    mobile: css`
        font-size: ${convertPxToRem({ px: 18 })};
        line-height: 160%;
        letter-spacing: 0;
    `,
    tablet: css`
        font-size: ${convertPxToRem({ px: 18 })};
        line-height: 160%;
        letter-spacing: 0;
    `,
    desktop: css`
        font-size: ${convertPxToRem({ px: 18 })};
        line-height: 160%;
        letter-spacing: 0;
    `
});

/*********************************************************
 *
 *          reusable heading size constants
 *
 **********************************************************/

export const headingXs = textStyle({
    mobile: css`
        font-size: ${convertPxToRem({ px: 16 })};
        line-height: 150%;
    `,
    tablet: css`
        /* same as MOBILE for this one: */
        font-size: ${convertPxToRem({ px: 16 })};
        line-height: 150%;
    `,
    desktop: css`
        font-size: ${convertPxToRem({ px: 18 })};
        line-height: 160%;
    `
});

export const headingSm = textStyle({
    mobile: css`
        font-size: ${convertPxToRem({ px: 18 })};
        line-height: 150%;
        letter-spacing: 0;
    `,
    tablet: css`
        /* same as MOBILE for this one: */
        font-size: ${convertPxToRem({ px: 18 })};
        line-height: 150%;
        letter-spacing: 0;
    `,
    desktop: css`
        font-size: ${convertPxToRem({ px: 23 })};
        line-height: 125%;
        letter-spacing: -0.0019rem;
    `
});

export const headingMd = textStyle({
    mobile: css`
        font-size: ${convertPxToRem({ px: 20 })};
        line-height: 1.5625rem;
    `,
    tablet: css`
        font-size: ${convertPxToRem({ px: 22 })};
        line-height: 125%;
    `,
    desktop: css`
        font-size: ${convertPxToRem({ px: 28 })};
        line-height: 125%;
    `
});

export const headingLg = textStyle({
    baseStyle: css`
        line-height: 125%;
    `,
    mobile: css`
        font-size: ${convertPxToRem({ px: 24 })};
    `,
    tablet: css`
        font-size: ${convertPxToRem({ px: 26 })};
    `,
    desktop: css`
        font-size: ${convertPxToRem({ px: 35 })};
    `
});

export const headingXl = textStyle({
    baseStyle: css`
        line-height: 125%;
    `,
    mobile: css`
        font-size: ${convertPxToRem({ px: 26 })};
    `,
    tablet: css`
        font-size: ${convertPxToRem({ px: 35 })};
    `,
    desktop: css`
        font-size: ${convertPxToRem({ px: 44 })};
        letter-spacing: -0.0875rem;
    `
});

export const heading2Xl = textStyle({
    baseStyle: css`
        line-height: 125%;
    `,
    mobile: css`
        font-size: ${convertPxToRem({ px: 30 })};
        letter-spacing: -0.0313rem; /* -0.5px */
    `,
    tablet: css`
        font-size: ${convertPxToRem({ px: 44 })};
        letter-spacing: -0.0875rem; /* -1.4px */
    `,
    desktop: css`
        font-size: ${convertPxToRem({ px: 55 })};
        letter-spacing: 0;
    `
});

export const heading3Xl = textStyle({
    mobile: css`
        font-size: ${convertPxToRem({ px: 30 })};
        line-height: 125%;
    `,
    tablet: css`
        font-size: ${convertPxToRem({ px: 48 })};
    `,
    desktop: css`
        font-size: ${convertPxToRem({ px: 80 })};
    `
});

export const heading4Xl = textStyle({
    mobile: css`
        font-size: ${convertPxToRem({ px: 30 })};
        line-height: 125%;
    `,
    tablet: css`
        font-size: ${convertPxToRem({ px: 52 })};
    `,
    desktop: css`
        font-size: ${convertPxToRem({ px: 120 })};
    `
});
