import { useApolloLazyQuery } from "frontend/utils/apolloApi/useApolloLazyQuery.js";

// queries
import { FETCH_MOD_LIST } from "frontend/components/fort/mod/photos/gql/queries";

export const useFetchModerators = () => {
    const { apolloQuery } = useApolloLazyQuery({
        actionDesc: "Fetch Moderators",
        gql: FETCH_MOD_LIST
    });

    const fetchModerators = async () => {
        const { ssResp } = await apolloQuery();
        return ssResp?.data?.modList?.items || [];
    };

    return {
        fetchModerators
    };
};
