import styled, { css } from "styled-components";

// styles
import colors from "core/colors";
import { convertPxToRem } from "frontend/components/helpers";
import {
    regular as regularFontWeight,
    bold as boldFontWeight
} from "core/fontStyles";

import { srOnlyStyles } from "core/SrOnly/index.js";

// styled-components
export const FormControlGroupStyled = styled.div``;
const sharedControlStyles = css`
    display: block;
    width: 100%;
    box-shadow: none;
    border: ${convertPxToRem(1)} solid ${colors.primary.diamond};
    padding: ${convertPxToRem(2)} ${convertPxToRem(14)} 0px;
    font-size: ${convertPxToRem(16)};
    line-height: ${convertPxToRem(16)};
    font-style: normal;
    color: ${colors.secondary.oolong};
    ${regularFontWeight};
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;
export const LabelStyled = styled.label`
    ${({ hideLabel }) => hideLabel && srOnlyStyles}
    ${boldFontWeight};
    font-size: ${convertPxToRem(12)};
`;
export const ErrorMessageStyled = styled.div`
    word-break: break-word;
    color: ${colors.functional.lightMode.sangria};
    font-size: ${convertPxToRem({ px: 13 })};

    &:before {
        content: "";
        display: block;
        position: relative;
        top: ${convertPxToRem({ px: -3 })};
        height: ${convertPxToRem({ px: 3 })};
        background: ${colors.functional.lightMode.sangria};
    }
`;

/*---------------------------
| Controls
---------------------------*/
export const InputStyled = styled.input`
    ${sharedControlStyles};
    ${({ height }) => `height: ${convertPxToRem(height)};`}
`;
export const TextAreaStyled = styled.textarea`
    ${sharedControlStyles};
    padding-top: ${convertPxToRem(7)};
    ${({ height }) =>
        css`
            height: ${convertPxToRem(height)};
        `}
`;
