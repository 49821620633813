import styled from "styled-components";

// styles
import { convertPxToRem } from "frontend/components/helpers";

// components
import Button from "core/Button";
import { Input as CheckboxInput } from "core/Checkbox/styles";

// styled-components
export const InlineTextEditWrapperStyled = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;
export const CheckboxStyled = styled.div`
    flex: 0 0 ${convertPxToRem(16)};
    margin-right: ${convertPxToRem(8)};

    display: flex;
    align-items: center;

    ${({ isEditing }) => isEditing && `display: none;`}

    ${CheckboxInput} {
        + span {
            &:before {
                margin-right: 0px;
            }
        }
    }
`;
export const SaveButtonStyled = styled.div`
    flex: 0 0 ${convertPxToRem(76)};
    margin-left: ${convertPxToRem(16)};
    display: flex;
    align-items: center;

    ${({ isEditing }) => !isEditing && `display: none;`}
`;

export const ButtonStyled = styled(Button)``;
