import React from "react";
import styled from "styled-components";

//styles
const AvatarStyled = styled.div`
    width: 149px;
    display: flex;
    justify-content: flex-end;
`;

// context
import {
    PanelOverlayProvider,
    OpenButton
} from "core/SiteHeaderV2/Mobile/components/PanelOverlay/index.js";

// components
import PanelOverlay from "./PanelOverlay";
import Avatar from "core/SiteHeaderV2/components/Avatar.js";

// component
const AuthMenu = () => {
    return (
        <PanelOverlayProvider position="right">
            <OpenButton>
                <AvatarStyled>
                    <Avatar />
                </AvatarStyled>
            </OpenButton>
            <PanelOverlay />
        </PanelOverlayProvider>
    );
};

export default AuthMenu;
