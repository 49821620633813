import React from "react";
import PropTypes from "prop-types";

// styles
import colors from "core/colors";

// Components
import CircleCount from "frontend/components/core/CircleCount";
import ToggleButton from "./ToggleButton";

export const MatureAudience = ({
    isDisabled = false,
    isActivated = false,
    onToggle,
    moderationIconKey
}) => {
    return (
        <ToggleButton
            onToggle={onToggle}
            isActivated={isActivated}
            isDisabled={isDisabled}
            moderationIconKey={moderationIconKey}
            title="Mature Audiences"
        >
            <CircleCount
                circleColor={colors.accent.lightMode.persian}
                number={18}
                isSolid={isActivated}
                isGrayscale={isDisabled}
            />
        </ToggleButton>
    );
};

// prop-types
MatureAudience.propTypes = {
    moderationIconKey: PropTypes.string.isRequired,
    onToggle: PropTypes.func.isRequired,
    isActivated: PropTypes.bool,
    isDisabled: PropTypes.bool
};
