import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import PropTypes from "prop-types";

// styles
const BatchAppovalStyled = styled.div`
    display: grid;
    gap: 16px;
    grid-template-columns: 105px 1px 105px 105px;
`;

// data
import { QUEUES } from "frontend/components/fort/mod/photos/constants";

// components
import VisModControls from "./VisModControls.js";
import MetadataControls from "./MetadataControls.js";

const BatchAppoval = ({ hide = false }) => {
    const currentQueueName = useSelector(state => state.currentQueue.name);

    let ModControls;

    switch (currentQueueName) {
        case QUEUES.METADATA_MOD:
            ModControls = MetadataControls;
            break;
        case QUEUES.SUSPENDED:
            // TODO
            ModControls = VisModControls;
            break;
        default:
            // QUEUES.VIS_MOD
            ModControls = VisModControls;
            break;
    }

    return <BatchAppovalStyled>{!hide && <ModControls />}</BatchAppovalStyled>;
};

export default BatchAppoval;

// prop-types
BatchAppoval.propTypes = {
    hide: PropTypes.bool
};
