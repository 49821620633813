import styled, { css } from "styled-components";
import PropTypes from "prop-types";

import { spacing } from "core/spacing";
import breakpoints from "core/breakpoints";
import colors from "core/colors";

import {
    handleBottomSpacing,
    handleTopSpacing
} from "js/helpers/spacingAndFonts";
import { convertPxToRem } from "frontend/components/helpers";

// OuterContainer:
export const OuterContainer = styled.section`
    ${({ bgColor }) =>
        `background-color: ${bgColor ? bgColor : colors.primary.snow};`}
    ${({ textColor }) => textColor && `color: ${textColor};`}
    ${({ fullWidth }) =>
        fullWidth &&
        `width: 100%;
        position: relative;
    `}

    @media (max-width: ${breakpoints.beforeTablet.rem}) {
        ${({ hasOverflowHiddenInMobile }) =>
            hasOverflowHiddenInMobile && `overflow-x: hidden;`}
    }
`;

OuterContainer.propTypes = {
    bgColor: PropTypes.string
};

// Container:
const containerProps = css`
    ${({ removePadding }) => removePadding && "padding: 0 !important;"}

    ${({ removeLeftRightPadding }) =>
        removeLeftRightPadding &&
        "padding-left: 0 !important; padding-right: 0 !important;"}

    ${({ fullWidth }) =>
        fullWidth &&
        css`
            max-width: 100%;
        `}
`;

export const Container = styled.section`
    margin: 0 auto;
    position: relative;
    width: 100%;
    max-width: 85.38rem; /* 1366px */


    /* alternative top & bottom spacing to change the default spacing: */
    ${({ topSpacing }) => handleTopSpacing({ topSpacing })}
    ${({ bottomSpacing }) => handleBottomSpacing({ bottomSpacing })}

    /* Mobile - Default spacing for SectionContainer: */
    padding-left: ${convertPxToRem({ px: 15 })};
    padding-right: ${convertPxToRem({ px: 15 })};

    ${containerProps};

    /* Tablet - Default spacing for SectionContainer: */
    @media (min-width: ${breakpoints.tablet.rem}) {
        padding-left: ${convertPxToRem({ px: 20 })};
        padding-right: ${convertPxToRem({ px: 20 })};

        ${containerProps}
    }

    /* Desktop - Default spacing for SectionContainer: */
    @media (min-width: ${breakpoints.desktop.rem}) {
        padding-left: ${convertPxToRem({ px: 24 })};
        padding-right: ${convertPxToRem({ px: 24 })};

        ${containerProps};

         /* Screens with laptop height range - Default spacing for SectionContainer: */
        @media ${breakpoints.laptopHeightsRange.rem} {
            ${containerProps}
        }

    }
`;

Container.propTypes = {
    removePadding: PropTypes.bool,
    removeLeftRightPadding: PropTypes.bool
};
