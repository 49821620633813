import styled from "styled-components";
import { Grid as GridBase } from "react-styled-flexboxgrid";

import { containerMaxWidth } from "core/widths";

export const OldGrid = styled(GridBase).attrs({ fluid: true })`
    width: 100%;
    overflow-x: hidden;
    max-width: ${containerMaxWidth};
`;

export { Row, Col } from "react-styled-flexboxgrid";
