export const removeTwoDots = text =>
    text.replaceAll ? text.replaceAll(/\.\./gi, ".") : "";

export const setLocalStorage = (key, data) => {
    if (typeof window !== "undefined" && window.localStorage) {
        localStorage.setItem(key, JSON.stringify(data));
    }
};

export const getLocalStorage = key => {
    if (typeof window !== "undefined" && window.localStorage) {
        const data = localStorage.getItem(key);
        try {
            return data ? JSON.parse(data) : null;
        } catch (e) {
            return null; // if not valid JSON
        }
    }
    return null;
};

export const removeLocalStorage = key => {
    if (typeof window !== "undefined" && window.localStorage) {
        localStorage.removeItem(key);
    }
};
