import React, { useContext } from "react";
import PropTypes from "prop-types";

// context
import MessageStackContext from "core/MessageStack/context";

// styles
import { MessageListStyled } from "./styles";

// components
import Message from "./Message";

const MessageList = ({
    anchorPosition = {
        position: "fixed",
        small: { top: 68, right: 12, left: 12 },
        mediumLarge: { top: 180, right: 24, left: "auto" }
    }
}) => {
    const {
        state: { messages },
        stackLimit
    } = useContext(MessageStackContext);

    return (
        <MessageListStyled anchorPosition={anchorPosition}>
            {messages
                ?.filter((m, idx) => idx < stackLimit)
                .map((m, idx) => {
                    return (
                        <Message key={m.id} message={m} placeInLine={idx + 1} />
                    );
                })}
        </MessageListStyled>
    );
};

export default MessageList;

// prop-types
MessageList.propTypes = {
    anchorPosition: PropTypes.object
};
