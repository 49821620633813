import React from "react";

// context
import { useManageTags } from "core/ManageTags/context/useManageTags.js";

// components
import TagsWithApproval from "./TagsWithApproval/index.js";
import TagsInline from "./TagsInline/index.js";

// component
export const Tags = () => {
    const { id, tags, hasApproval } = useManageTags();

    return (
        <div data-testid={`add-tag-tags-${id}`}>
            {hasApproval ? (
                <TagsWithApproval tags={tags} />
            ) : (
                <TagsInline tags={tags} />
            )}
        </div>
    );
};

export default Tags;
