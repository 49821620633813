import React from "react";

// styles
import { DividerStyled } from "./styles.js";

// context
import BatchApprovalProvider from "./useBatchApproval";

// constants
import {
    QUEUES,
    MODERATION_TYPES
} from "frontend/components/fort/mod/photos/constants";

// components
import UpdateOnlyButton from "./updateOnly/UpdateOnlyButton.js";
import FullyPublishButton from "./fullyPublish/FullyPublishButton.js";
import DenyButton from "./deny/DenyButton.js";

// component
export const MetadataControls = () => {
    return (
        <BatchApprovalProvider
            moderationType={MODERATION_TYPES[QUEUES.METADATA_MOD]}
        >
            <UpdateOnlyButton variant="clear" />
            <DividerStyled />
            <DenyButton
                variant="dark"
                buttonText="Revoke & Deny"
                successTitle="Photo revoked and denied"
            />
            <FullyPublishButton variant="green" />
        </BatchApprovalProvider>
    );
};

export default MetadataControls;
