import { useState, useEffect } from "react";

// hooks
import { useApolloMutation } from "frontend/utils/apolloApi/useApolloMutation.js";
import { useBatchApproval } from "../useBatchApproval";

// GQL
import { FULLY_PUBLISH_PHOTO_UPLOADS } from "frontend/components/fort/mod/photos/gql/mutations";

export const useFullyPublish = () => {
    // Hooks/State
    const { apolloMutate, loading } = useApolloMutation({
        actionDesc: "Fully Publish Upload(s)",
        gql: FULLY_PUBLISH_PHOTO_UPLOADS
    });
    const [isEditable, setIsEditable] = useState(true);

    // Props
    const title = "Fully Publish";

    const useBatchApprovalConfig = {
        title,
        onApprovalAction: async ({ photoUploadIds }) => {
            return await apolloMutate({ variables: { photoUploadIds } });
        },
        successTitle: "Photo fully published"
    };

    const {
        selectedUploadIds,
        allFlagsCleared,
        publishingIsEditable,
        decoratedOnApproval
    } = useBatchApproval(useBatchApprovalConfig);

    // side effects
    useEffect(() => {
        let isEditable =
            !loading && selectedUploadIds.length > 0 && publishingIsEditable;

        if (!allFlagsCleared) {
            isEditable = false;
        }

        setIsEditable(isEditable);
    }, [selectedUploadIds, publishingIsEditable, allFlagsCleared, loading]);

    return {
        onFullyPublish: decoratedOnApproval,
        isEditable
    };
};
