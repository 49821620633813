import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

import PageMeta from "core/PageMeta";

import {
    CREATOR_DASHBOARD_PHOTOS_ROYALTIES,
    CREATOR_DASHBOARD_PHOTOS_PENDING,
    CREATOR_DASHBOARD_PHOTOS_DENIED
} from "data/links";
import { GET_CREATOR_NAME } from "./queries";

const DashboardPageMeta = () => {
    const { username, pageContent } = useParams();
    const decodedUsername = decodeURIComponent(username);
    const { data } = useQuery(GET_CREATOR_NAME, {
        variables: {
            username: decodedUsername
        }
    });

    let page = "";
    let urlPath = "";

    if (pageContent === "photo-royalties") {
        page = "Photo Royalties";
        urlPath = CREATOR_DASHBOARD_PHOTOS_ROYALTIES.replace(
            ":username",
            decodedUsername
        );
    }
    if (pageContent === "photos-pending") {
        page = "Pending Photos";
        urlPath = CREATOR_DASHBOARD_PHOTOS_PENDING.replace(
            ":username",
            decodedUsername
        );
    }
    if (pageContent === "photos-denied") {
        page = "Denied Photos";
        urlPath = CREATOR_DASHBOARD_PHOTOS_DENIED.replace(
            ":username",
            decodedUsername
        );
    }

    const displayName = data
        ? data.creator.name
            ? data.creator.name
            : data.creator.username
        : "";

    return (
        <PageMeta
            title={`${displayName} Dashboard - ${page} | Noun Project`}
            canonicalURL={`${window.location.origin}${urlPath}`}
        />
    );
};

export default DashboardPageMeta;
