import styled from "styled-components";

// styles
import colors from "core/colors";
import { convertPxToRem } from "frontend/components/helpers";

// components
import Button from "core/Button";

// styled-components
export const ReleasesStyled = styled.div`
    position: relative;
`;

export const ButtonStyled = styled(Button)`
    text-decoration: underline;
    background: transparent;
    color: ${({ isDisabled }) =>
        isDisabled
            ? colors.secondary.dolphin
            : colors.accent.lightMode.persian};
`;
export const ReleaseLinkStyled = styled.div`
    a {
        font-size: ${convertPxToRem(16)};
        display: block;
        text-decoration: none;
        color: ${colors.secondary.orca};
        background-color: ${colors.primary.diamond};
        padding: ${convertPxToRem(12)} ${convertPxToRem(8)};
        margin-bottom: ${convertPxToRem(16)};
    }
`;
