import React, { useMemo } from "react";
import PropTypes from "prop-types";

// hooks
import { useSelect } from "core/Grid2/GridItemContentTemplates/Select/SelectContext/index.js";

// components
import {
    SlideshowProvider,
    Slideshow
} from "frontend/components/core/Slideshow";
import PhotoModCarouselImage from "../PhotoModCarouselImage";
import ModalUpload from "../../../core/ExpandedModal/ModalUpload";

const PhotoModSlideshowUploads = ({ uploadIds, defaultId = "" }) => {
    const { selectNext, selectPrev } = useSelect();

    const components = useMemo(
        () =>
            uploadIds.map(id => ({
                id,
                Component: (
                    <ModalUpload
                        uploadId={id}
                        bgColor="transparent"
                        UploadGraphicComponent={PhotoModCarouselImage}
                    />
                )
            })),
        [uploadIds]
    );

    const onSlideNextClick = () => {
        selectNext();
    };

    const onSlidePrevClick = () => {
        selectPrev();
    };

    return (
        <SlideshowProvider
            defaultId={defaultId}
            components={components}
            onSlideNextClick={onSlideNextClick}
            onSlidePrevClick={onSlidePrevClick}
        >
            <Slideshow />
        </SlideshowProvider>
    );
};

PhotoModSlideshowUploads.propTypes = {
    uploadIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    defaultId: PropTypes.string
};

export default PhotoModSlideshowUploads;
