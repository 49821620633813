import React, { useState, useContext } from "react";
import PropTypes from "prop-types";

import BodyText from "core/BodyText";

import {
    ModalContainer,
    ModalDescription,
    IndividualBox,
    CookieTitle,
    CookieDesc,
    Footer,
    FooterText
} from "core/CookiesBanner/styles";
import Link from "core/Link";
import Button from "core/Button";
import Checkbox from "core/Checkbox";
import { useViewport } from "js/hooks/useViewport";
import breakpoints from "core/breakpoints";
import CookieConsentContext from "frontend/context/CookieConsentContext";

import {
    ESSENTIAL_COOKIE_HEADING,
    ESSENTIAL_COOKIE_DESC,
    ESSENTIAL_ARIA_LABEL,
    FUNCTIONALITY_COOKIE_HEADING,
    FUNCTIONALITY_COOKIE_DESC,
    FUNCTIONALITY_ARIA_LABEL,
    ANALYTICAL_COOKIE_HEADING,
    ANALYTICAL_COOKIE_DESC,
    ANALYTICAL_ARIA_LABEL,
    ADVERTISING_COOKIE_HEADING,
    ADVERTISING_COOKIE_DESC,
    ADVERTISING_ARIA_LABEL
} from "./data";

const CookiesModal = ({ isModalOpen, handleClose, className }) => {
    const { saveConsentSettings } = useContext(CookieConsentContext);
    const [isFunctionalityCookie, setFunctionalityCookie] = useState(true);
    const [isAnalyticalCookie, setAnalyticalCookie] = useState(true);
    const [isAdvertisingCookie, setAdvertisingCookie] = useState(true);

    const { browserWidth } = useViewport();
    const isMobile = browserWidth < breakpoints.tablet.px;

    const buttonWidth = isMobile ? "100%" : "auto";
    const footerTextSize = isMobile ? "xs" : "sm";
    const modalSize = isMobile ? "fullScreen" : "lg";

    const saveCookiePreferences = () => {
        handleClose();
        saveConsentSettings({
            functionality: isFunctionalityCookie,
            analytics: isAnalyticalCookie,
            advertising: isAdvertisingCookie
        });
    };

    return (
        <ModalContainer
            open={isModalOpen}
            handleClose={handleClose}
            onClose={handleClose}
            heading={"Manage Cookie Preferences"}
            size={modalSize}
            className={className}
        >
            <ModalDescription>
                Cookies make our site work better for you. For example, cookies
                enable things like signing in or moving from page to page, or
                remembering download or language preferences.
            </ModalDescription>

            {/*ESSENTIAL COOKIES*/}
            <IndividualBox>
                <CookieTitle>
                    <Checkbox
                        isChecked={true}
                        isDisabled={true}
                        onClick={() => {}}
                        ariaLabel={ESSENTIAL_ARIA_LABEL}
                    />
                    <BodyText size="md">{ESSENTIAL_COOKIE_HEADING}</BodyText>
                </CookieTitle>
                <CookieDesc>{ESSENTIAL_COOKIE_DESC}</CookieDesc>
            </IndividualBox>

            {/*FUNCTIONALITY COOKIES*/}
            <IndividualBox>
                <CookieTitle>
                    <Checkbox
                        isChecked={isFunctionalityCookie}
                        onClick={() =>
                            setFunctionalityCookie(!isFunctionalityCookie)
                        }
                        ariaLabel={FUNCTIONALITY_ARIA_LABEL}
                    />
                    <BodyText size="md">
                        {FUNCTIONALITY_COOKIE_HEADING}
                    </BodyText>
                </CookieTitle>
                <CookieDesc>{FUNCTIONALITY_COOKIE_DESC}</CookieDesc>
            </IndividualBox>

            {/*ANALYTICAL COOKIES*/}
            <IndividualBox>
                <CookieTitle>
                    <Checkbox
                        isChecked={isAnalyticalCookie}
                        onClick={() => setAnalyticalCookie(!isAnalyticalCookie)}
                        ariaLabel={ANALYTICAL_ARIA_LABEL}
                    />
                    <BodyText size="md">{ANALYTICAL_COOKIE_HEADING}</BodyText>
                </CookieTitle>
                <CookieDesc>{ANALYTICAL_COOKIE_DESC}</CookieDesc>
            </IndividualBox>

            {/*ADVERTISING COOKIES*/}
            <IndividualBox>
                <CookieTitle>
                    <Checkbox
                        isChecked={isAdvertisingCookie}
                        onClick={() =>
                            setAdvertisingCookie(!isAdvertisingCookie)
                        }
                        ariaLabel={ADVERTISING_ARIA_LABEL}
                    />
                    <BodyText size="md">{ADVERTISING_COOKIE_HEADING}</BodyText>
                </CookieTitle>
                <CookieDesc>{ADVERTISING_COOKIE_DESC}</CookieDesc>
            </IndividualBox>

            <Footer>
                <FooterText size={footerTextSize}>
                    See our{" "}
                    <Link
                        href={"/legal/cookie-policy/"}
                        target="_blank"
                        size={footerTextSize}
                    >
                        Cookie Policy
                    </Link>{" "}
                    for detailed information.
                </FooterText>

                <Button
                    size={"lg"}
                    width={buttonWidth}
                    onClick={saveCookiePreferences}
                >
                    Save Preferences
                </Button>
            </Footer>
        </ModalContainer>
    );
};

CookiesModal.propTypes = {
    isModalOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired
};

export default CookiesModal;
