import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { AuthButton } from "../styles";
import FormInput from "core/FormInput2";
import FormError from "core/FormError";

import {
    validEmail,
    attemptAuth,
    FORGOT_PASSWORD_BACKEND_URL
} from "../helpers";

const ForgotPassword = ({ afterSubmit }) => {
    const [email, setEmail] = useState("");
    const [backendError, setBackendError] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);

    const onClick = () => {
        setIsProcessing(true);
        setBackendError(null);
        attemptAuth({
            url: FORGOT_PASSWORD_BACKEND_URL,
            form: {
                email
            },
            onSuccess: afterSubmit, // show success view
            onFail(errorMsg) {
                setIsProcessing(false);
                setBackendError(errorMsg);
            }
        });
    };

    return (
        <>
            <FormError>{backendError}</FormError>
            <FormInput
                value={email}
                onChange={setEmail}
                labelText="Email Address"
                placeholderText="yourname@domain.com"
                validation={[
                    {
                        errorMsg: "Please enter a valid email.",
                        isValid: validEmail
                    }
                ]}
                size="lg"
            />
            <AuthButton
                styleType="cta"
                isProcessing={isProcessing}
                isDisabled={!validEmail(email)}
                onClick={onClick}
            >
                Reset My Password
            </AuthButton>
        </>
    );
};

ForgotPassword.propTypes = {
    afterSubmit: PropTypes.func.isRequired
};

export default ForgotPassword;
