import * as actionTypes from "../actions/actionTypes";

export default (state = {}, action) => {
    switch (action.type) {
        case actionTypes.UI_WINDOW_RESIZE: {
            let { width, height } = action;
            return {
                width,
                height
            };
        }
        default:
            return state;
            break;
    }
};
