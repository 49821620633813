import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Flexbox from "core/Flexbox";
import { Wrapper, IconWrapper, TextWrapper } from "./styles";

const UnwrappedMessageBanner = ({
    className,
    accentColor,
    icon,
    iconTitle,
    children
}) => {
    const Icon = icon;

    return (
        <Wrapper className={className} accentColor={accentColor}>
            <Flexbox alignItems="center" justifyContent="space-between">
                <Flexbox alignItems="center">
                    {/* 'size' and 'fillColor' props are used
                    to style "styled-svg" components only */}
                    {!!icon && (
                        <IconWrapper>
                            <Icon
                                title={iconTitle}
                                size="lg"
                                fillColor={accentColor}
                            />
                        </IconWrapper>
                    )}
                    <TextWrapper>{children}</TextWrapper>
                </Flexbox>
            </Flexbox>
        </Wrapper>
    );
};

UnwrappedMessageBanner.propTypes = {
    className: PropTypes.string,
    children: PropTypes.any,
    accentColor: PropTypes.string,
    iconTitle: PropTypes.string,
    // pass React component, like an SVG styled-component
    icon: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.func,
        PropTypes.element
    ])
};

const MessageBanner = styled(UnwrappedMessageBanner)``;

export default MessageBanner;
