import React from "react";

import Flexbox from "core/Flexbox";
import Link from "core/Link";

import { useReleases } from "./hooks/useReleases";

import {
    ReleasesDropdownError,
    ReleasesDropdownErrorContainer
} from "./styles";

import { tooManyReleasesErrorMsg } from "../../PhotoUpload/Edit/constants";

const ReleasesUploadError = () => {
    const { uploadErrorMessage } = useReleases();

    const showRequirementsLink = uploadErrorMessage !== tooManyReleasesErrorMsg;

    return uploadErrorMessage ? (
        <ReleasesDropdownErrorContainer>
            <Flexbox
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <ReleasesDropdownError>
                    {uploadErrorMessage}
                </ReleasesDropdownError>

                {showRequirementsLink && (
                    <Link
                        href="https://help.thenounproject.com/hc/en-us/articles/20487895422235-Model-Property-Release-requirements"
                        target="_blank"
                        disableVisitedStyle
                    >
                        Requirements
                    </Link>
                )}
            </Flexbox>
        </ReleasesDropdownErrorContainer>
    ) : null;
};

export default ReleasesUploadError;
