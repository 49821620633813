import React from "react";
import PropTypes from "prop-types";

// styles
import { UploadCountStyled } from "./styles.js";

const UploadCount = ({
    showUploadCount = true,
    isHovered,
    gridIndex,
    gridItemCount
}) => {
    return (
        showUploadCount &&
        isHovered && (
            <UploadCountStyled>
                {gridIndex + 1} of {gridItemCount}
            </UploadCountStyled>
        )
    );
};

export default UploadCount;

// prop-types
UploadCount.propTypes = {
    showUploadCount: PropTypes.bool,
    isHovered: PropTypes.bool.isRequired,
    gridIndex: PropTypes.number.isRequired,
    gridItemCount: PropTypes.number
};
