import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";

import { GET_BATCH_QUEUE_STATUS } from "../queries";
import { GET_PHOTO_UPLOAD_QUEUE_STATUS } from "../queries";

export const LOADING_STATUS = "loading"; // queries still loading
export const INCOMPLETE_ACCOUNT_STATUS = "incomplete-account"; // has not completed account setup
export const UPLOAD_STATUS = "upload"; // can upload photos
export const SUBMIT_BATCH_STATUS = "submit-batch"; // cannot upload, but can submit pending batch
export const MAX_QUEUE_STATUS = "max-queue"; // unmoderated photo submission limit reached
export const MAX_TIME_STATUS = "max-time"; // 30-day photo submission limit reached

/**
 * @name usePhotoSubmissionStatus
 * @description Queries API for photo submissions, and determines if the user can submit photos, and if not, why.
 */
export const usePhotoSubmissionStatus = () => {
    const [currentStatus, setCurrentStatus] = useState(LOADING_STATUS);

    // account status & account-level upload/submission limits
    const { data: uploadStatus } = useQuery(GET_PHOTO_UPLOAD_QUEUE_STATUS);

    const {
        uploadStatusMessage,
        hasFullName,
        hasVerifiedEmail,
        isSuperPhotographer,
        photoUploadBalance,
        canUploadMorePhotos,
        hasReachedMaximumPhotosInQueue
    } = uploadStatus ? uploadStatus.photoUploadQueueStatus : {};

    // batch-level upload/submission limits
    const { data: batchStatus } = useQuery(GET_BATCH_QUEUE_STATUS);

    const {
        photoSubmissionBalance: batchPhotoSubmissionBalance,
        hasReachedMaximumPhotosInQueue: batchHasReachedMaximumPhotosInQueue,
        canSubmitMorePhotos: batchCanSubmitMorePhotos
    } = batchStatus ? batchStatus.photoBatchQueueStatus : {};

    // using existence of data to determine if queries are still loading so that
    // refetching doesn't cause a flash of the loading state
    const isLoading = !uploadStatus || !batchStatus;

    useEffect(() => {
        if (isLoading) {
            setCurrentStatus(LOADING_STATUS);
        } else if (!hasFullName || !hasVerifiedEmail) {
            setCurrentStatus(INCOMPLETE_ACCOUNT_STATUS);
        } else if (hasReachedMaximumPhotosInQueue && batchCanSubmitMorePhotos) {
            setCurrentStatus(SUBMIT_BATCH_STATUS);
        } else if (hasReachedMaximumPhotosInQueue) {
            setCurrentStatus(MAX_QUEUE_STATUS);
        } else if (!batchCanSubmitMorePhotos) {
            setCurrentStatus(MAX_TIME_STATUS);
        } else {
            setCurrentStatus(UPLOAD_STATUS);
        }
    }, [
        isLoading,
        hasFullName,
        hasVerifiedEmail,
        hasReachedMaximumPhotosInQueue,
        batchCanSubmitMorePhotos,
        canUploadMorePhotos
    ]);

    return {
        currentStatus,
        account: {
            complete: hasFullName && hasVerifiedEmail,
            hasFullName,
            hasVerifiedEmail,
            isSuperPhotographer
        },
        // global limits
        uploadStatusMessage,
        photoUploadBalance,
        canUploadMorePhotos,
        hasReachedMaximumPhotosInQueue,
        // batch limits
        batchPhotoSubmissionBalance,
        batchCanSubmitMorePhotos,
        batchHasReachedMaximumPhotosInQueue
    };
};

export default usePhotoSubmissionStatus;
