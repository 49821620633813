import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { GridContainer, GridItem } from "./styles";

import {
    gridItemSizes,
    gridItemTypes,
    gridContainerTypes,
    justifyContentPosition,
    alignItemsPosition,
    requiredIf
} from "core/props";

const UnstyledGrid = ({
    className,
    children,
    desktopSpacing,
    tabletSpacing,
    mobileSpacing,
    lg,
    md,
    sm,
    containerAs = "div",
    itemAs = "div",
    item = false,
    justifyContent = "flex-start",
    alignItems,
    ...otherProps
}) => {
    return item ? (
        <GridItem
            className={className}
            lg={lg}
            md={md}
            sm={sm}
            as={itemAs}
            {...otherProps}
        >
            {children}
        </GridItem>
    ) : (
        <GridContainer
            className={className}
            desktopSpacing={desktopSpacing}
            tabletSpacing={tabletSpacing}
            mobileSpacing={mobileSpacing}
            as={containerAs}
            justifyContent={justifyContent}
            alignItems={alignItems}
            {...otherProps}
        >
            {children}
        </GridContainer>
    );
};

const Grid = styled(UnstyledGrid)``;

Grid.propTypes = {
    desktopSpacing: requiredIf({
        type: PropTypes.number,
        condition: props => props.item === false
    }),
    tabletSpacing: PropTypes.number,
    mobileSpacing: PropTypes.number,
    lg: requiredIf({
        type: PropTypes.oneOf(gridItemSizes),
        condition: props => props.item === true
    }),
    md: PropTypes.oneOf(gridItemSizes),
    sm: PropTypes.oneOf(gridItemSizes),
    itemAs: PropTypes.oneOf(gridItemTypes),
    containerAs: PropTypes.oneOf(gridContainerTypes),
    item: PropTypes.bool,
    justifyContent: PropTypes.oneOf(justifyContentPosition),
    alignItems: PropTypes.oneOf(alignItemsPosition)
};

export default Grid;
