import React from "react";
import styled, { css } from "styled-components";
import { useSelector } from "react-redux";

import { getDarkBackground, getFlexLayout } from "frontend/store/layout";
import breakpoints from "core/breakpoints";
import fonts from "core/fonts";
import { A, ButtonLink } from "core/Typography";
import colors from "core/colors";

/*************************************************************************
 *
 *
 * NOTE ABOUT THIS FILE:
 * use core/SectionContainer instead of any of these "Sections" -
 * we will deprecate any of the "Section*" components soon
 *
 *
 *************************************************************************/

const Layout = styled.div`
    width: 100%;
    max-width: ${({ containerMaxWidth }) =>
        containerMaxWidth}; /* TODO: fix this properly */
    margin-left: auto;
    margin-right: auto;

    /* for sticky footer */
    display: flex;
    min-height: 100vh;
    flex-direction: column;
`;

const SiteContentWrapper = props => {
    const darkBackground = useSelector(getDarkBackground);
    const displayFlex = useSelector(getFlexLayout);
    return (
        <StyledContentWrapper
            displayFlex={displayFlex}
            darkBackground={darkBackground}
            {...props}
        />
    );
};

const StyledContentWrapper = styled.div`
    ${({ displayFlex }) =>
        // allows for site content to take up all available
        // vertical space between site's header and footer
        displayFlex &&
        css`
            display: flex;
            flex-direction: column;
        `}
    flex: 1 1 auto;
    ${({ darkBackground }) =>
        darkBackground && `background-color: ${colors.secondary.orca}`};
`;

export const fullWidthStyles = css`
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
`;

const removeFullWidthStyles = css`
    width: 100%;
    position: static;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
`;

const getPaddingY = (paddingY, screenSize) =>
    paddingY &&
    css`
        padding-top: ${paddingY[screenSize]}rem;
        padding-bottom: ${paddingY[screenSize]}rem;
    `;

/*************************************************************************
 *
 *
 * NOTE ABOUT THIS FILE:
 * use core/SectionContainer instead of any of these "Sections" -
 * we will deprecate any of the "Section*" components soon
 *
 *
 *************************************************************************/
const Section = styled.section`
    width: 100%;
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;

    ${({ fullWidth }) => (fullWidth === "sm" || fullWidth) && fullWidthStyles}
    ${({ removeFullWidth }) =>
        removeFullWidth === "sm" && removeFullWidthStyles}

    ${({ paddingY }) => getPaddingY(paddingY, "sm")}

    @media (min-width: ${breakpoints.tablet.rem}) {
        padding-left: 1.25rem;
        padding-right: 1.25rem;

        ${({ paddingY }) => getPaddingY(paddingY, "md")}

        ${({ fullWidth }) => fullWidth === "md" && fullWidthStyles}
        ${({ removeFullWidth }) =>
            removeFullWidth === "md" && removeFullWidthStyles}
    }

    @media (min-width: ${breakpoints.desktop.rem}) {
        padding-left: 1.5rem;
        padding-right: 1.5rem;

        ${({ paddingY }) => getPaddingY(paddingY, "lg")}

        ${({ fullWidth }) => fullWidth === "lg" && fullWidthStyles}
        ${({ removeFullWidth }) =>
            removeFullWidth === "lg" && removeFullWidthStyles}
    }
`;

/*************************************************************************
 *
 *
 * NOTE ABOUT THIS FILE:
 * use core/SectionContainer instead of any of these "Sections" -
 * we will deprecate any of the "Section*" components soon
 *
 *
 *************************************************************************/
const SectionDark = styled(Section)`
    background-color: ${colors.secondary.orca};
    color: ${fonts.fontColors.light.primary};

    ${A}, ${ButtonLink} {
        &,
        &:active,
        &:hover,
        &:visited {
            color: ${fonts.fontColors.light.link};
        }
    }
`;

/*************************************************************************
 *
 *
 * NOTE ABOUT THIS FILE:
 * use core/SectionContainer instead of any of these "Sections" -
 * we will deprecate any of the "Section*" components soon
 *
 *
 *************************************************************************/
const SectionWhite = styled(Section)`
    background-color: ${colors.primary.snow};
    color: ${colors.secondary.onyx};

    ${A}, ${ButtonLink} {
        &,
        &:active,
        &:hover,
        &:visited {
            color: ${colors.secondary.oolong};
        }
    }

    label {
        color: ${colors.secondary.oolong};
    }
`;

/*************************************************************************
 *
 *
 * NOTE ABOUT THIS FILE:
 * use core/SectionContainer instead of any of these "Sections" -
 * we will deprecate any of the "Section*" components soon
 *
 *
 *************************************************************************/
const SectionGray = styled(Section)`
    background-color: #f2f2f2;
    color: ${colors.secondary.onyx};

    ${A}, ${ButtonLink} {
        &,
        &:active,
        &:hover,
        &:visited {
            color: ${colors.secondary.oolong};
        }
    }
`;

export {
    Layout,
    Section,
    SectionDark,
    SectionGray,
    SectionWhite,
    SiteContentWrapper
};
