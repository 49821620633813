import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// styles
import colors from "core/colors";
import { convertPxToRem } from "frontend/components/helpers";

export const BatchUploadCollectionStyled = styled.div`
    padding: 0px ${convertPxToRem(24)} ${convertPxToRem(24)};
    &:not(:first-child) {
        border-top: solid ${convertPxToRem(1)} ${colors.primary.scheifer};
    }
`;

// core components
import BatchTitle from "./BatchTitle";
import BatchFilterMenu from "./BatchFilterMenu";

import { QUEUE_VIEWS } from "../../../constants";
import { batchUploadCollectionShape } from "./batchUploadCollectionShape";

// component
export const BatchUploadCollection = ({
    batch,
    batchUploadCollection,
    BatchGridComponent,
    setActiveSaveView
}) => {
    const {
        title,
        showCollectionTitle,
        showActiveAndSavedFilter,
        activeIds,
        savedForLaterIds,
        view
    } = batchUploadCollection;

    const uploadIds =
        QUEUE_VIEWS.ACTIVE === view ? activeIds : savedForLaterIds;

    return (
        <BatchUploadCollectionStyled data-testid="batch-collection">
            {showCollectionTitle && <BatchTitle text={title} />}
            {showActiveAndSavedFilter && (
                <BatchFilterMenu
                    batchUploadCollection={batchUploadCollection}
                    setActiveSaveView={setActiveSaveView}
                />
            )}
            <BatchGridComponent batch={batch} uploadIds={uploadIds} />
        </BatchUploadCollectionStyled>
    );
};

export default BatchUploadCollection;

BatchUploadCollection.propTypes = {
    batch: PropTypes.object.isRequired,
    batchUploadCollection: PropTypes.shape(batchUploadCollectionShape)
        .isRequired,
    BatchGridComponent: PropTypes.elementType.isRequired,
    setActiveSaveView: PropTypes.func.isRequired
};
