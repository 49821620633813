import React from "react";
import styled, { css } from "styled-components";

import IconGridBase from "icons/IconGrid";

import lingoAdSrc from "img/ads/lingo-600x100.png";

import { convertPxToRem } from "frontend/components/helpers";

export const lingoAdDimensionStyles = css`
    width: 100%;
    height: 100%;
`;

const LingoAdImage = styled.img.attrs({
    src: lingoAdSrc.src ? lingoAdSrc.src : lingoAdSrc
})`
    ${lingoAdDimensionStyles}
`;

export const LingoAd = () => (
    <a href="https://www.lingoapp.com/?utm_source=noun_project" target="_blank">
        <LingoAdImage />
    </a>
);

export const AdContainer = styled.div.attrs({
    "data-testid": "icon-grid-ad"
})`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: ${convertPxToRem({ px: 24 })} auto 0;
`;

export const IconGrid = styled(IconGridBase)`
    ${AdContainer} + & {
        margin-top: ${convertPxToRem({ px: 24 })};
    }
`;
