import React from "react";
import PropTypes from "prop-types";

import {
    spacingSizes,
    headingSizes,
    alignments,
    headingTypes
} from "core/props";

// to hide right image container on desktop that appears as the first grid item
import Hidden from "@mui/material/Hidden";

import TextContent from "./TextContent";
import ImageContent from "./ImageContent";

import { CollectionSpotlightGridItem } from "./styles";

const ImageOnRight = ({
    buttonProps,
    headingType,
    headingSize,
    headingAlignment,
    headingTopSpacing,
    headingBottomSpacing,
    headingColor,
    image,
    imageCaption,
    imageAlt,
    collectionHeading,
    collectionText,
    collectionQuoteText,
    collectionQuoteAuthor,
    collectionLink,
    sideBySideContentOnMedium,
    isBottomImageOnMobile,
    mediumScreenSizeGridSpan,
    imageLink
}) => {
    return (
        <>
            {isBottomImageOnMobile ? (
                <>
                    <CollectionSpotlightGridItem
                        item
                        sm={12}
                        md={mediumScreenSizeGridSpan}
                        lg={6}
                    >
                        <TextContent
                            buttonProps={buttonProps}
                            headingType={headingType}
                            headingSize={headingSize}
                            headingAlignment={headingAlignment}
                            headingTopSpacing={headingTopSpacing}
                            headingBottomSpacing={headingBottomSpacing}
                            headingColor={headingColor}
                            collectionHeading={collectionHeading}
                            collectionText={collectionText}
                            collectionQuoteText={collectionQuoteText}
                            collectionQuoteAuthor={collectionQuoteAuthor}
                            collectionLink={collectionLink}
                        />
                    </CollectionSpotlightGridItem>

                    <CollectionSpotlightGridItem
                        item
                        sm={12}
                        md={mediumScreenSizeGridSpan}
                        lg={6}
                    >
                        <ImageContent
                            image={image}
                            imageAlt={imageAlt}
                            imageCaption={imageCaption}
                            imageLink={imageLink}
                            collectionLink={collectionLink}
                        />
                    </CollectionSpotlightGridItem>
                </>
            ) : (
                <>
                    <Hidden
                        {...{
                            lgUp: !sideBySideContentOnMedium,
                            mdUp: sideBySideContentOnMedium
                        }}
                    >
                        <CollectionSpotlightGridItem
                            item
                            sm={12}
                            md={mediumScreenSizeGridSpan}
                            lg={6}
                        >
                            <ImageContent
                                image={image}
                                imageAlt={imageAlt}
                                imageCaption={imageCaption}
                                imageLink={imageLink}
                                collectionLink={collectionLink}
                            />
                        </CollectionSpotlightGridItem>
                    </Hidden>

                    <CollectionSpotlightGridItem
                        item
                        sm={12}
                        md={mediumScreenSizeGridSpan}
                        lg={6}
                    >
                        <TextContent
                            buttonProps={buttonProps}
                            headingType={headingType}
                            headingSize={headingSize}
                            headingAlignment={headingAlignment}
                            headingTopSpacing={headingTopSpacing}
                            headingBottomSpacing={headingBottomSpacing}
                            headingColor={headingColor}
                            collectionHeading={collectionHeading}
                            collectionText={collectionText}
                            collectionQuoteText={collectionQuoteText}
                            collectionQuoteAuthor={collectionQuoteAuthor}
                            collectionLink={collectionLink}
                        />
                    </CollectionSpotlightGridItem>

                    <Hidden
                        {...{
                            mdDown: !sideBySideContentOnMedium,
                            smDown: sideBySideContentOnMedium
                        }}
                    >
                        <CollectionSpotlightGridItem
                            item
                            sm={12}
                            md={mediumScreenSizeGridSpan}
                            lg={6}
                        >
                            <ImageContent
                                image={image}
                                imageAlt={imageAlt}
                                imageCaption={imageCaption}
                                imageLink={imageLink}
                                collectionLink={collectionLink}
                            />
                        </CollectionSpotlightGridItem>
                    </Hidden>
                </>
            )}
        </>
    );
};

ImageOnRight.propTypes = {
    buttonProps: PropTypes.object,
    headingType: PropTypes.oneOf(headingTypes),
    headingSize: PropTypes.oneOf(headingSizes),
    headingAlignment: PropTypes.oneOf(alignments),
    headingTopSpacing: PropTypes.oneOf(spacingSizes),
    headingBottomSpacing: PropTypes.oneOf(spacingSizes),
    headingColor: PropTypes.string,
    isRightImg: PropTypes.bool,
    image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    imageCaption: PropTypes.string,
    imageAlt: PropTypes.string.isRequired,
    collectionHeading: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    collectionText: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    collectionQuoteText: PropTypes.string,
    collectionQuoteAuthor: PropTypes.string,
    collectionLink: PropTypes.array,
    sideBySideContentOnMedium: PropTypes.bool,
    isBottomImageOnMobile: PropTypes.bool,
    mediumScreenSizeGridSpan: PropTypes.number.isRequired
};

export default ImageOnRight;
