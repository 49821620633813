import gql from "graphql-tag";

import {
    PHOTO_UPLOAD_TYPE_FRAGMENT,
    PHOTO_UPLOAD_WITH_MOD_FRAGMENT
} from "../fragments/photoUpload";

/* -------------------------------------------------------------------------- */
/*                                upload flags                                */
/* -------------------------------------------------------------------------- */
export const SET_HAS_WARNING_TEXT_ON_UPLOADS = gql`
    mutation SetHasFlaggedTextOnPhotoUploads(
        $photoUploadIds: [ID!]!
        $value: Boolean!
    ) {
        payload: setHasFlaggedTextOnPhotoUploads(
            photoUploadIds: $photoUploadIds
            value: $value
        ) {
            ok
            photoUploads {
                errors
                ok
                photoUploadId
                photoUpload {
                    ...ModPhotoUpload
                }
            }
        }
    }
    ${PHOTO_UPLOAD_WITH_MOD_FRAGMENT}
`;

export const SET_EXPLICIT_STATUS_ON_UPLOADS = gql`
    mutation SetExplicitStatusOnUploads(
        $photoUploadIds: [ID!]!
        $value: Boolean!
    ) {
        payload: setExplicitContentOnPhotoUploads(
            photoUploadIds: $photoUploadIds
            value: $value
        ) {
            ok
            photoUploads {
                errors
                ok
                photoUploadId
                photoUpload {
                    ...ModPhotoUpload
                }
            }
        }
    }
    ${PHOTO_UPLOAD_WITH_MOD_FRAGMENT}
`;
/**
 * NOTE: Unfortunately some of these queries are shared with UPL, and therefore,
 * we cannot use PHOTO_UPLOAD_WITH_MOD_FRAGMENT to keep mod props synched. *
 * For these queries we will do separate api calls to get mod specific props
 */
export const SET_NEEDS_RELEASE_STATUS_ON_UPLOADS = gql`
    mutation SetNeedsReleaseStatusOnUploads(
        $photoUploadIds: [ID!]!
        $value: Boolean!
    ) {
        payload: setHasRecognizablePeopleOrPropertyOnPhotoUploads(
            photoUploadIds: $photoUploadIds
            value: $value
        ) {
            ok
            photoUploads {
                errors
                ok
                photoUploadId
                photoUpload {
                    ...PhotoUpload
                }
            }
        }
    }
    ${PHOTO_UPLOAD_TYPE_FRAGMENT}
`;

export const SET_EDITORIAL_USE_ONLY_ON_UPLOADS = gql`
    mutation SetEditorialUseOnlyOnPhotoUploads(
        $photoUploadIds: [ID!]!
        $value: Boolean!
    ) {
        payload: setEditorialUseOnlyOnPhotoUploads(
            photoUploadIds: $photoUploadIds
            value: $value
        ) {
            ok
            photoUploads {
                errors
                ok
                photoUploadId
                photoUpload {
                    ...PhotoUpload
                }
            }
        }
    }
    ${PHOTO_UPLOAD_TYPE_FRAGMENT}
`;
