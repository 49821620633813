import React, {
    useState,
    useEffect,
    createContext,
    useMemo,
    useContext
} from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";

// redux
import { setContentIsOpen } from "frontend/components/fort/mod/photos/slices/selectedBatchSlice/index.js";
import { batchesSlice } from "frontend/components/fort/mod/photos/slices/batchesSlice";
import updateBatch from "frontend/components/fort/mod/photos/slices/batchesSlice/updateBatch";
import { messagesSlice } from "frontend/components/fort/mod/photos/slices/messagesSlice.js";
import { isEditableCheck } from "frontend/components/fort/mod/photos/components/PhotoModQueuePageContent/QueueProviders/BatchesProvider/helpers/isEditableCheck.js";
import { animationsSlice } from "frontend/components/fort/mod/photos/slices/animationsSlice.js";

// hooks
import { useSelect } from "frontend/components/core/Grid2/GridItemContentTemplates/Select/SelectContext";
import { useAuth } from "frontend/hooks/useAuth";
import { useQueueContext } from "frontend/components/fort/mod/core/Layouts/QueueLayout/QueueContext.js";
import { useRefetchBatchesAndUploads } from "../../../hooks/useRefetchBatchesAndUploads";

// constants
import {
    ANIMATIONS,
    QUEUES,
    MODERATION_TYPES
} from "frontend/components/fort/mod/photos/constants";

// mocking and debugging
import { mockApiCall, DEBUG, MOCK_IS_SUCCESS } from "./mockApiCall";

// context
const BatchApprovalContext = createContext();

const BatchApprovalProvider = ({
    children,
    moderationType = MODERATION_TYPES[QUEUES.VIS_MOD]
}) => {
    const contextProps = {
        moderationType
    };

    // useMemo so it does not pass value on every render
    const value = useMemo(() => contextProps, [contextProps]);

    return (
        <BatchApprovalContext.Provider value={value}>
            {children}
        </BatchApprovalContext.Provider>
    );
};

export default BatchApprovalProvider;

// prop-types
BatchApprovalProvider.propTypes = {
    children: PropTypes.any,
    moderationType: PropTypes.string
};

const isPublishingEditableCheck = (
    selectedUploadIds,
    batchUploadCollections,
    selectedBatchObj
) => {
    let publishingIsEditable = selectedBatchObj?.isEditable?.publishing;

    // Check if saved for later uploads are selected.
    batchUploadCollections?.forEach(buc => {
        const { savedForLaterIds } = buc;
        savedForLaterIds?.forEach(sflid => {
            if (selectedUploadIds.includes(sflid)) {
                publishingIsEditable = false;
            }
        });
    });

    return publishingIsEditable;
};

export const useBatchApproval = ({
    title,
    onApprovalAction,
    successTitle = "Visual review complete", // Product wants a more generic success message "Visual review complete" for most
    usesBatchRemoveAnimation = true,
    refetchBatches = true
}) => {
    // external state
    const dispatch = useDispatch();

    const { uploadsSelected, getSelectedUploadIds, deselectAll } = useSelect();
    const {
        state: { allUploadsSelectedInBatch }
    } = useQueueContext();

    const { refetchBatchesAndUploads } = useRefetchBatchesAndUploads();

    // redux
    const { entities: uploadEntities } = useSelector(state => state.uploads);
    const { entities: batchEntities } = useSelector(state => state.batches);
    const batchUploadCollections = useSelector(
        state => state.batchUploadCollections
    );
    const currentQueueName = useSelector(state => state.currentQueue.name);
    const { currentUser } = useAuth();

    // internal state
    const { moderationType } = useContext(BatchApprovalContext);
    const [selectedUploadIds, setSelectedUploadIds] = useState([]);
    const [allFlagsCleared, setAllFlagsCleared] = useState(false);

    // props
    const selectedBatchId = useSelector(state => state.selectedBatch.id);
    const selectedBatchObj = batchEntities[selectedBatchId];
    const publishingIsEditable = useMemo(
        () =>
            isPublishingEditableCheck(
                selectedUploadIds,
                batchUploadCollections,
                selectedBatchObj
            ),
        [selectedUploadIds, batchUploadCollections, selectedBatchObj]
    );

    // methods
    const decoratedOnApproval = async () => {
        if (!selectedUploadIds.length) return;

        const applyAnimation =
            usesBatchRemoveAnimation && allUploadsSelectedInBatch;

        /*---------------------------
        | Pre API Call
        ---------------------------*/
        dispatch(
            messagesSlice.actions.addMessage({
                text: `Processing ${title} request`,
                timed: true,
                timeout: 3000,
                infoType: "info"
            })
        );

        // Batch Remove Animation only occurs when ALL uploads are selected, and moderator clicks Visually Approve, deny, Fully Publish
        if (applyAnimation) {
            dispatch(setContentIsOpen(false));
            dispatch(
                updateBatch.isEditable({
                    id: selectedBatchId,
                    changes: {
                        releases: false,
                        photoShoot: false,
                        flags: false,
                        uploadToggleBand: false,
                        uploadFlags: false,
                        uploadTitle: false,
                        uploadDesc: false,
                        publishing: false
                    }
                })
            );

            dispatch(
                batchesSlice.actions.updateOne({
                    id: selectedBatchId,
                    changes: {
                        isFaded: true
                    }
                })
            );
        }

        /*---------------------------
        | API Call
        ---------------------------*/
        const mockPromise = mockApiCall({
            title,
            success: MOCK_IS_SUCCESS,
            photoUploadIds: selectedUploadIds
        });

        const { success, message } = DEBUG
            ? await mockPromise()
            : await onApprovalAction({
                  photoUploadIds: selectedUploadIds
              });

        /*---------------------------
        | POST api call
        ---------------------------*/
        if (!success) {
            const errorMessage = `${title} Error (BatchID: ${selectedBatchObj.id}): <p>${message}.</p> <p>Please refresh the browser and try again.</p>`;
            if (DEBUG) console.log({ errorMessage });

            dispatch(
                messagesSlice.actions.addMessage({
                    text: errorMessage,
                    timed: false,
                    timeout: null,
                    infoType: "error"
                })
            );
            // restore to previous editablility state on Batch
            dispatch(
                batchesSlice.actions.updateOne({
                    id: selectedBatchObj.id,
                    changes: {
                        isEditable: isEditableCheck({
                            batch: selectedBatchObj,
                            currentQueue: currentQueueName,
                            currentUser
                        }),
                        isFaded: false
                    }
                })
            );
        } else {
            if (applyAnimation) {
                dispatch(
                    animationsSlice.actions.addAnimation({
                        id: ANIMATIONS.BATCH_REMOVE,
                        payload: {
                            batchId: selectedBatchObj.id
                        }
                    })
                );
            } else if (refetchBatches) {
                refetchBatchesAndUploads();
            }

            // adding here for debugging if needed.
            const successMessage = `${title} Success (BatchID: ${selectedBatchObj.id})`;
            if (DEBUG) console.log({ successMessage });

            dispatch(
                messagesSlice.actions.addMessage({
                    text: successTitle,
                    timed: true,
                    timeout: 5000,
                    infoType: "success"
                })
            );

            // Need to deselect uploads upon success
            deselectAll();
        }
    };

    // Side Effects
    useEffect(() => {
        setSelectedUploadIds(getSelectedUploadIds(uploadsSelected));
    }, [uploadsSelected]);

    useEffect(() => {
        const selectedUploadsHaveFlags = selectedUploadIds.some(id => {
            let hasFlags = false;
            const upload = uploadEntities[id];

            if (upload.hasRecognizablePeopleOrProperty) {
                hasFlags = true;
            }

            if (upload.editorialUseOnly) {
                hasFlags = true;
            }

            if (upload.autoWarningText) {
                hasFlags = true;
            }

            if (upload.autoNudity || upload.autoViolence) {
                hasFlags = true;
            }

            return hasFlags;
        });

        setAllFlagsCleared(!selectedUploadsHaveFlags);
    }, [selectedUploadIds, uploadEntities]);

    return {
        moderationType,
        selectedUploadIds,
        allFlagsCleared,
        publishingIsEditable,
        decoratedOnApproval
    };
};
