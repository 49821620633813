import React from "react";
import PropTypes from "prop-types";

// styles
import { BatchProfileStyled } from "./styles";

const BatchProfile = ({ children }) => {
    return <BatchProfileStyled>{children}</BatchProfileStyled>;
};

export default BatchProfile;

BatchProfile.propTypes = {
    children: PropTypes.any
};
