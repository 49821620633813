import styled from "styled-components";

const Avatar = styled.img`
    height: ${props => props.width || "2.5rem"};
    width: ${props => props.width || "2.5rem"};
    border-radius: 50%;
    ${({ rightMargin }) => {
        return rightMargin ? `margin-right: ${rightMargin}` : null;
    }};
`;

export default Avatar;
