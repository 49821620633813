import React, { useContext, useRef, useCallback, useState } from "react";

import CustomDropdownContext from "../context";

export const useCustomDropdown = ({
    triggerText,
    content,
    isLinkTriggerStyle,
    buttonProps = { styleType: "solid", size: "lg" },
    showCloseButton,
    showBoxShadow,
    width,
    absoluteRight,
    absoluteLeft,
    bgColor,
    heading,
    headingColor,
    footerChildren,
    customOnClose = () => {}
} = {}) => {
    const { isOpen, setIsOpen } = useContext(CustomDropdownContext);

    const triggerButtonRef = useRef();
    const dropdownBoxRef = useRef();

    const handleOpen = useCallback(async () => {
        await setIsOpen(!isOpen);

        (await dropdownBoxRef) &&
            dropdownBoxRef.current &&
            dropdownBoxRef.current.focus();
    }, [isOpen, dropdownBoxRef]);

    const handleClose = useCallback(async () => {
        await setIsOpen(false);
        customOnClose();
        (await triggerButtonRef) &&
            triggerButtonRef.current &&
            triggerButtonRef.current.focus();
    }, [triggerButtonRef]);

    const handleCloseOnEsc = useCallback(e => {
        if (e.keyCode === 27) {
            handleClose();
        }
    }, []);

    const handleOutsideClick = useCallback(() => {
        if (isOpen) {
            handleClose();
        }
    }, [isOpen]);

    return {
        triggerText,
        content,
        isLinkTriggerStyle,
        buttonProps,
        showCloseButton,
        showBoxShadow,
        width,
        absoluteRight,
        absoluteLeft,
        bgColor,
        heading,
        headingColor,
        footerChildren,

        isOpen,
        setIsOpen,
        handleOpen,
        handleClose,
        handleCloseOnEsc,
        handleOutsideClick,
        triggerButtonRef,
        dropdownBoxRef
    };
};

export const CustomDropdownProvider = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <CustomDropdownContext.Provider
            value={{
                isOpen,
                setIsOpen
            }}
        >
            {children}
        </CustomDropdownContext.Provider>
    );
};
