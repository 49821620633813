import gql from "graphql-tag";

export const UPDATE_PHOTO_SHOOT_TITLE = gql`
    mutation UpdatePhotoShootTitle($photoShootId: ID!, $title: String) {
        editPhotoShoot(photoShootId: $photoShootId, title: $title) {
            ok
            errors
            photoShoot {
                id
                title
                __typename
            }
        }
    }
`;
