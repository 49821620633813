import React from "react";
import PropTypes from "prop-types";

// utils
import * as Utils from "../utils.js";
import * as PhotoUtils from "./utils.js";

// api
import {
    useGetSuggestions,
    useAddTags,
    useRemoveTags,
    useApproveTags,
    useRejectTags
} from "./api/index.js";

// components
import ManageTags from "../index.js";

// component
export const ManagePhotoTags = ({
    uploads = [],
    id = "manage-tags-photo",
    display = "inline", // "inline", "stacked"
    onUpdateTags,
    onUpdateTagsError,
    hasApproval = false,
    isDisabled = false
}) => {
    // state/props/hooks
    const { suggestions, getTagSuggestions } = useGetSuggestions();
    const { addTags, addTagsLoading } = useAddTags();
    const { removeTags, removeTagsLoading } = useRemoveTags();
    const { approveTags, approveTagsLoading } = useApproveTags();
    const { rejectTags, unapproveTagsLoading } = useRejectTags();

    // are some loading?
    const isProcessingRequest = [
        addTagsLoading,
        removeTagsLoading,
        approveTagsLoading,
        unapproveTagsLoading
    ].some(Boolean);

    const selectedUploadIds = uploads.map(u => u.id);

    /*---------------------------
    | Methods
    ---------------------------*/
    const handleResp = ({ type, resp }) => {
        const handler = resp.success ? onUpdateTags : onUpdateTagsError;
        handler?.({ type, resp });
    };

    /* Suggestions ---------------------------*/
    const onSuggest = async inputText => {
        const resp = await getTagSuggestions(inputText);
        if (!resp.success) {
            // not currently handling suggestion errors from backend,
            // for now we just update suggestions with empty array
            // see useGetSuggestions
        }
    };

    /* Add ---------------------------*/
    const onAddTag = async ({ inputText }) => {
        const tags = Utils.getTagsFromInput(inputText);
        const resp = await addTags({ photoUploadIds: selectedUploadIds, tags });
        handleResp({ type: "add", resp });
    };

    /* Remove ---------------------------*/
    const onRemoveTag = async ({ tagObject }) => {
        const tags = [tagObject.name];
        const resp = await removeTags({
            photoUploadIds: selectedUploadIds,
            tags
        });
        handleResp({ type: "remove", resp });
    };

    /* Approve ---------------------------*/
    const onApproveTag = async ({ tagObject }) => {
        const tags = [tagObject.name];
        const resp = await approveTags({
            photoUploadIds: selectedUploadIds,
            tags
        });
        handleResp({ type: "approve", resp });
    };

    /* Reject ---------------------------*/
    const onRejectTag = async ({ tagObject }) => {
        const tags = [tagObject.name];
        const resp = await rejectTags({
            photoUploadIds: selectedUploadIds,
            tags
        });
        handleResp({ type: "reject", resp });
    };

    return (
        <ManageTags
            uploads={PhotoUtils.augmentUploadsWithTagObjects(uploads)}
            id={id}
            display={display}
            hasApproval={hasApproval}
            isDisabled={isDisabled || isProcessingRequest}
            // suggestions
            suggestions={suggestions}
            onSuggest={onSuggest}
            // add/remove
            onAddTag={onAddTag}
            onRemoveTag={onRemoveTag}
            // approval
            onApproveTag={onApproveTag}
            onRejectTag={onRejectTag}
        />
    );
};

export default ManagePhotoTags;

// prop-types
ManagePhotoTags.propTypes = {
    uploads: PropTypes.array,
    id: PropTypes.string,
    display: PropTypes.oneOf(["inline", "stacked"]),
    hasApproval: PropTypes.bool,
    isDisabled: PropTypes.bool,
    onUpdateTags: PropTypes.func,
    onUpdateTagsError: PropTypes.func
};
