import {
    getValidationError,
    minChars,
    maxChars,
    validEmail
} from "core/Form/helpers";

export const roleFieldValidation = [
    {
        isValid: minChars(1),
        errorMsg: "Please enter your role in education."
    },
    {
        isValid: maxChars(255),
        errorMsg: "Must not exceed 255 characters."
    }
];

export const schoolFieldValidation = [
    {
        isValid: minChars(1),
        errorMsg: "Please enter your school name."
    },
    {
        isValid: maxChars(255),
        errorMsg: "Must not exceed 255 characters."
    }
];

export const emailFieldValidation = [
    {
        isValid: minChars(1),
        errorMsg: "Please add your email address."
    },
    {
        isValid: maxChars(254),
        errorMsg: "Must not exceed 254 characters."
    },
    {
        isValid: validEmail,
        errorMsg: "Please enter a valid email address."
    }
];

export const howUseIconsFieldValidation = [
    {
        isValid: maxChars(255),
        errorMsg: "Must not exceed 255 characters."
    }
];

// EDU application validation checks
export const checkForErrors = ({
    applicantRole,
    schoolName,
    iconUsage,
    emailAddr
}) => {
    const isRoleErr = getValidationError({
        input: applicantRole,
        validation: roleFieldValidation
    });

    const isSchoolErr = getValidationError({
        input: schoolName,
        validation: schoolFieldValidation
    });

    const isIconsErr = getValidationError({
        input: iconUsage,
        validation: howUseIconsFieldValidation
    });

    const isEmailErr = getValidationError({
        input: emailAddr,
        validation: emailFieldValidation
    });

    if (isRoleErr) {
        return isRoleErr;
    } else if (isSchoolErr) {
        return isSchoolErr;
    } else if (isIconsErr) {
        return isIconsErr;
    } else if (isEmailErr) {
        return isEmailErr;
    } else {
        return "";
    }
};
