import * as Sentry from "@sentry/react";

let tags = {};
window._nounConfig = window._nounConfig || {};

if (window._nounConfig.pluginHost) {
    tags = window._nounConfig.pluginHost;
}

const sentryConfig = {
    dsn: window._nounConfig.sentryDSN,
    debug: window._nounConfig.debugMode,
    release: window._nounConfig.release,
    environment: window._nounConfig.environment,
    ignoreErrors: [
        "Cannot read property 'undefined' of undefined",
        "AMCore is not defined"
    ],
    // AllowUrls is equivalent to whiteListsUrls
    allowUrls: [
        // eslint-disable-next-line no-useless-escape
        /static\.(test|staging|production)?\.?thenounproject\.com\/assets\/bundles\/[a-z0-9\-]*.js/
    ],
    // Equivalent to shouldSendCallback
    beforeSend(event) {
        // Log errors for the dev environment, but don't send to Sentry
        if (window._nounConfig.environment === "dev") {
            console.log("Sentry error not sent because environment=dev", event);
            return null;
        }

        // Modify the event here

        let ieString;
        let ref;
        ieString = navigator.userAgent.match(/MSIE \d+.0/);

        if (
            ieString &&
            ((ref = ieString[0]) === "MSIE 7.0" ||
                ref === "MSIE 8.0" ||
                ref === "MSIE 9.0")
        ) {
            return null;
        }

        return event;
    }
};

// Let exceptions from webpack files through to Sentry handling code in development
// but stop them from actually being sent in shouldSendCallback
if (process.env.NODE_ENV != "production") {
    sentryConfig.allowUrls.push(/webpack/);
}

const sentryDisabled = localStorage.getItem("DISABLE_JS_SENTRY");

if (sentryDisabled) {
    console.warn("Sentry disabled.");
} else {
    Sentry.setTag(tags);
    Sentry.init(sentryConfig);
}
