import styled from "styled-components";

// styles + components:
import colors from "core/colors";

// helpers:
import { convertPxToRem } from "frontend/components/helpers";
import BodyText from "core/BodyText";
import IconButton from "core/IconButton";
import Modal from "core/Modal";
import { ModalContent, ModalFooter } from "core/Modal/styles";

// styled components:

////////////////////////////////////
///////// RELEASES MODAL ///////////
////////////////////////////////////

export const ModalContainer = styled(Modal)`
    ${ModalContent} {
        max-height: ${convertPxToRem({ px: 620 })};
    }

    ${ModalFooter} {
        justify-content: center;
    }
`;

export const ReleasesDropdownError = styled(BodyText).attrs({
    size: "md",
    type: "div",
    copyColor: colors.functional.lightMode.sangria
})``;

export const ReleasesDropdownErrorContainer = styled.div`
    margin-bottom: ${convertPxToRem({ px: 24 })};
`;
