import React from "react";
import PropTypes from "prop-types";

// components
import BatchUploadCollection from "./BatchUploadCollection";

// component
export const BatchUploadCollectionsList = ({
    batch,
    batchUploadCollections,
    BatchGridComponent,
    setActiveSaveView
}) => {
    return (
        <div
            className="BatchUploadCollectionsList"
            data-testid="batch-collection-list"
        >
            {batchUploadCollections.map(batchUploadCollection => {
                return (
                    <BatchUploadCollection
                        key={batchUploadCollection.id}
                        batch={batch}
                        batchUploadCollection={batchUploadCollection}
                        BatchGridComponent={BatchGridComponent}
                        setActiveSaveView={setActiveSaveView}
                    />
                );
            })}
        </div>
    );
};

export default BatchUploadCollectionsList;

// prop-types
BatchUploadCollectionsList.propTypes = {
    batch: PropTypes.object.isRequired,
    batchUploadCollections: PropTypes.array.isRequired,
    BatchGridComponent: PropTypes.any.isRequired,
    setActiveSaveView: PropTypes.func.isRequired
};
