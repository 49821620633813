import React from "react";
import PropTypes from "prop-types";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
    selectBatch,
    toggleBatch
} from "frontend/components/fort/mod/photos/slices/selectedBatchSlice/index.js";

// core components
import Batch from "frontend/components/fort/mod/core/Batch";

// components
import PhotoModBatchBody from "../PhotoModBatchBody";
import PhotoModBatchHeader from "../PhotoModBatchHeader";
import PhotoModBatchProfileRow1 from "../PhotoModBatchProfileRow1";
import PhotoModBatchProfileRow2 from "../PhotoModBatchProfileRow2";

const PhotoModBatch = ({ batch }) => {
    // Redux
    const dispatch = useDispatch();
    const selectedBatch = useSelector(state => state.selectedBatch);

    const batchContentIsOpen =
        batch.id === selectedBatch.id && selectedBatch.contentIsOpen;

    const onBatchToggle = batchId => {
        batch.id !== selectedBatch.id && dispatch(selectBatch(batchId));
        dispatch(toggleBatch(batchId));
    };

    const onBatchSelect = batch => {
        dispatch(selectBatch(batch.id));
    };

    return (
        <Batch
            batch={batch}
            selectedBatchId={selectedBatch.id}
            onBatchSelect={onBatchSelect}
        >
            <PhotoModBatchHeader
                batch={batch}
                batchContentIsOpen={batchContentIsOpen}
                onBatchToggle={onBatchToggle}
            >
                <PhotoModBatchProfileRow1 batch={batch} />
                <PhotoModBatchProfileRow2 batch={batch} />
            </PhotoModBatchHeader>
            <PhotoModBatchBody
                batch={batch}
                batchContentIsOpen={batchContentIsOpen}
            />
        </Batch>
    );
};

export default PhotoModBatch;

// prop-types
PhotoModBatch.propTypes = {
    batch: PropTypes.object.isRequired
};
