import { useState, useRef, useEffect, useCallback } from "react";

// hooks
import { useWindowResize } from "frontend/hooks/useWindowResize.js";

// data
const BATCH_MIN_HEIGHT_WHEN_OPEN = 300;

export const useCalculateHeight = ({
    allBatchUploadIds,
    batchContentIsOpen,
    uploadsStatus
}) => {
    const [containerHeight, setContainerHeight] = useState(0);
    const innerChildRef = useRef();

    const calculatedHeight = useCallback(() => {
        if (batchContentIsOpen) {
            let calculatedHeight = BATCH_MIN_HEIGHT_WHEN_OPEN;

            if (innerChildRef.current && uploadsStatus !== "loading") {
                calculatedHeight = innerChildRef.current.scrollHeight;
            }

            setContainerHeight(calculatedHeight);
        } else {
            setContainerHeight(0);
        }
    }, [batchContentIsOpen, uploadsStatus]);

    useWindowResize(calculatedHeight);

    useEffect(() => {
        calculatedHeight();
    }, [
        batchContentIsOpen,
        allBatchUploadIds,
        uploadsStatus,
        calculatedHeight
    ]);

    return {
        innerChildRef,
        containerHeight
    };
};
