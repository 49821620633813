import gql from "graphql-tag";

/* -------------------------------------------------------------------------- */
/*                                   BATCHES                                  */
/* -------------------------------------------------------------------------- */

// sortNewestToOldest flag if set to True will reverse default sort
export const FETCH_PAGINATED_BATCHES = gql`
    query fetchPaginatedBatches(
        $limit: Int! = 10
        $offset: Int! = 0
        $moderationType: ModerationType! = ANY
        $textSearch: String!
        $hasNoFlags: Boolean
        $hasUploadsWithRelOrEuoFlag: Boolean
        $hasUploadsWithExpOrTxtFlag: Boolean
        $hasSavedForLaterUploads: Boolean
        $isUnassigned: Boolean
        $assignedToMe: Boolean
        $isPremium: Boolean
        $showOnlySuspendedCreators: Boolean
        $sortNewestToOldest: Boolean! = false
        $batchIds: [ID!]
    ) {
        batches: listPhotoUploadBatches(
            limit: $limit
            offset: $offset
            moderationType: $moderationType
            textSearch: $textSearch
            hasNoFlags: $hasNoFlags
            hasUploadsWithRelOrEuoFlag: $hasUploadsWithRelOrEuoFlag
            hasUploadsWithExpOrTxtFlag: $hasUploadsWithExpOrTxtFlag
            hasSavedForLaterUploads: $hasSavedForLaterUploads
            isUnassigned: $isUnassigned
            assignedToMe: $assignedToMe
            isPremium: $isPremium
            showOnlySuspendedCreators: $showOnlySuspendedCreators
            sortNewestToOldest: $sortNewestToOldest
            batchIds: $batchIds
        ) {
            totalCount
            items {
                id
                containsReleaseOrEditorialFlags
                containsExplicitOrTextFlags
                containsSavedForLater
                createdAt
                moderationStatus
                releaseCount
                submittedAt
                moderator {
                    id
                    displayName
                    username
                    isPhotoModerator
                    isManager
                }
                photoShoot {
                    id
                    title
                    titleApproved
                }
                creator {
                    id
                    name
                    username
                    avatarUrl
                    isActive
                    isPremiumPhotographer
                }
                creatorTier {
                    internalDisplayName
                }
                moderationNotes {
                    id
                    photoBatchId
                    actor {
                        id
                        displayName
                        username
                        isPhotoModerator
                        isManager
                    }
                    actorDisplayName
                    action
                    timestamp
                }
            }
        }
    }
`;
