import React from "react";
import styled from "styled-components";

import PhotoIcon from "core/ReusableIcons/Photo";
import colors from "core/colors";

const Container = styled.div`
    display: flex;
    position: relative;
    width: 100%;
    aspect-ratio: 1 / 1;
    justify-content: center;
    align-items: center;
    background-color: ${colors.primary.diamond};
`;

const IndividualUploadLoader = () => {
    return (
        <Container>
            <PhotoIcon size="xl" fillColor={colors.secondary.dolphin} />
        </Container>
    );
};

export default IndividualUploadLoader;
