import gql from "graphql-tag";

// TODO: nail down which fields we actually need
// TODO: it's still undecided how we will grab photoshoot details.
export const LIST_PHOTO_UPLOADS_IN_DROPZONE = gql`
    query ListPhotoUploadsInDropzone(
        $excludeSubmittedAndWithdrawnPhotos: Boolean = true
        $limit: Int = 300 # limit is set to 300 to prevent pagination
    ) {
        listPhotoUploads(
            limit: $limit
            excludeSubmittedAndWithdrawnPhotos: $excludeSubmittedAndWithdrawnPhotos
        ) {
            totalCount
            items {
                aspectRatio
                id
                status
                updatedAt
                autoDenied
                errors
                thumbnail
                originalFilename
                uploadedImageUrl
                license
                photoTitle
                photoDescription
                modelReleaseSubmitted
                propertyReleaseSubmitted
                anyReleaseSubmitted
                editorialUseOnly
                hasRecognizablePeopleOrProperty
                photoLocation
                autoTags
                userTags
                photoShoot {
                    id
                    slugWithId
                    creatorId
                    createdAt
                    updatedAt
                    description
                    shootLocation
                    title
                    isForSinglePhoto
                    __typename
                }
                __typename
            }
            __typename
        }
    }
`;

export const GET_PHOTO_UPLOAD_QUEUE_STATUS = gql`
    query photoUploadQueueStatus {
        photoUploadQueueStatus {
            uploadStatusMessage
            hasFullName
            hasVerifiedEmail
            photoUploadBalance
            isSuperPhotographer
            canUploadMorePhotos
            hasReachedMaximumPhotosInQueue
        }
    }
`;

export const GET_BATCH_QUEUE_STATUS = gql`
    query photoBatchQueueStatus {
        photoBatchQueueStatus {
            photoSubmissionBalance
            canSubmitMorePhotos
            hasReachedMaximumPhotosInQueue
        }
    }
`;

export const FETCH_UPLOAD_BATCHES = gql`
    query FetchUploadBatches(
        $moderationStatuses: [PhotoModerationStatus!] = null
    ) {
        batches: listUnmoderatedPhotoBatches(
            moderationStatuses: $moderationStatuses
        ) {
            items {
                id
                moderationStatus
                photoShoot {
                    id
                    isForSinglePhoto
                    title
                }
            }
        }
    }
`;
