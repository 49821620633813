import styled from "styled-components";

// styles
import { convertPxToRem } from "frontend/components/helpers";
import { regular as regularFontWeight } from "core/fontStyles";

// components
import Checkbox from "core/Checkbox";
import { Input } from "core/Checkbox/styles";

// styled-components
export const LegendStyled = styled.legend`
    ${regularFontWeight};
    margin-bottom: ${convertPxToRem({ px: 5 })};
`;
export const CheckboxesStyled = styled.div``;
export const CheckboxStyled = styled(Checkbox)`
    margin-right: ${convertPxToRem({ px: 16 })};

    ${Input} {
        + span {
            ${regularFontWeight};
            font-size: ${convertPxToRem({ px: 15 })};
            &:before {
                margin-right: ${convertPxToRem({ px: 6 })};
            }
        }
    }
`;
