import React from "react";
import PropTypes from "prop-types";

import { LoggedOutFlexbox } from "./styles";

const LoggedOutLinks = ({ loginLink, joinLink }) => (
    <LoggedOutFlexbox justifyContent="flex-end" alignItems="center">
        {loginLink}
        {joinLink}
    </LoggedOutFlexbox>
);

LoggedOutLinks.propTypes = {
    loginLink: PropTypes.oneOfType([PropTypes.node, PropTypes.element])
        .isRequired,
    joinLink: PropTypes.oneOfType([PropTypes.node, PropTypes.element])
        .isRequired
};

export default LoggedOutLinks;
