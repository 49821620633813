import React from "react";
import PropTypes from "prop-types";

import { useQuery, useMutation } from "@apollo/client";

import EditShootNameModal from "./EditShootNameModal";
import EditText from "core/EditText";

import { FETCH_PHOTO_SHOOT_TITLE } from "./queries";
import { UPDATE_PHOTO_SHOOT_TITLE } from "./mutations";

const EditShootName = ({ currentPhotoShootId }) => {
    const { data } = useQuery(FETCH_PHOTO_SHOOT_TITLE, {
        variables: {
            photoShootId: currentPhotoShootId
        },
        skip: !currentPhotoShootId,
        fetchPolicy: "cache-and-network"
    });

    const [savePhotoShootName] = useMutation(UPDATE_PHOTO_SHOOT_TITLE, {
        refetchQueries: ["FetchPhotoShootTitle"]
    });

    const updatePhotoShootName = shootName => {
        const title = !!shootName ? shootName.trim() : "";

        return savePhotoShootName({
            variables: {
                photoShootId: currentPhotoShootId,
                title
            }
        });
    };

    const photoShootName =
        (!!data && data.photoShootDetail && data.photoShootDetail.title) || "";

    return (
        <EditText
            defaultDisplayText="Add Name"
            displayText={photoShootName}
            buttonAriaLabel={`edit photo shoot name "${photoShootName}"`}
            editingMode={exitEditingMode => (
                <EditShootNameModal
                    open
                    defaultShootName={photoShootName}
                    onClose={exitEditingMode}
                    updateShootName={newShootName =>
                        updatePhotoShootName(newShootName)
                    }
                />
            )}
        />
    );
};

EditShootName.propTypes = {
    currentPhotoShootId: PropTypes.string
};

export default EditShootName;
