import React from "react";
import PropTypes from "prop-types";

// components
import { GridItemContentSelect } from "frontend/components/core/Grid2/GridItemContentTemplates/Select/index.js";
import Footer from "./Footer";
import UploadManager from "frontend/components/fort/mod/photos/components/PhotoModUpload/UploadManager.js";

// styles
import { GridItemLoadingSkeletonStyled } from "./styles.js";

const GridItem = ({
    batch,
    upload,
    gridIndex,
    selectedUploadIds,
    gridItemCount
}) => {
    const { id, photoTitle, originalFilename } = upload;

    return (
        <div data-testid="batch-grid-item" id={`photo-upload-${id}`}>
            <GridItemContentSelect
                id={id}
                name={photoTitle || originalFilename}
                gridIndex={gridIndex}
                gridItemCount={gridItemCount}
                showUploadCount={true}
                showFileName={false}
            >
                <UploadManager uploadId={id} />
            </GridItemContentSelect>
            <Footer upload={upload} selectedUploadIds={selectedUploadIds} />
        </div>
    );
};

export default GridItem;

// prop-types
GridItem.propTypes = {
    batch: PropTypes.object.isRequired,
    upload: PropTypes.object.isRequired,
    gridIndex: PropTypes.number.isRequired,
    selectedUploadIds: PropTypes.array.isRequired,
    gridItemCount: PropTypes.number
};

const generateGridItemLoadingSkeletons = () => {
    return [1, 2, 3, 4, 5, 6].map(num => {
        return (
            <GridItemLoadingSkeletonStyled
                key={`grid-item-loading-skeleton${num}`}
            />
        );
    });
};

export const generateGridItem = (
    batch,
    uploadIds,
    selectedUploadIds,
    uploadEntities,
    loadingUploads
) => {
    if (loadingUploads) {
        return generateGridItemLoadingSkeletons();
    }

    return uploadIds.map((uid, idx) => {
        return (
            <GridItem
                key={uid}
                batch={batch}
                upload={uploadEntities[uid]}
                gridIndex={idx}
                selectedUploadIds={selectedUploadIds}
                gridItemCount={uploadIds.length}
            />
        );
    });
};
