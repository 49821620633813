import { useApolloMutation } from "frontend/utils/apolloApi/useApolloMutation.js";

// gql
import { SET_MATURE_ON_UPLOADS } from "frontend/components/fort/mod/photos/gql/mutations";

// utils
import { useApiUploadUpdate } from "frontend/components/fort/mod/photos/hooks/useApiUploadUpdate.js";

export const useSetMature = () => {
    const { apiUploadUpdate } = useApiUploadUpdate();

    const { apolloMutate } = useApolloMutation({
        actionDesc: "Upload: Set Mature",
        gql: SET_MATURE_ON_UPLOADS
    });

    const setMature = async ({ photoUploadIds, isActivated }) => {
        const { ssResp } = await apolloMutate({
            variables: {
                photoUploadIds: photoUploadIds,
                value: isActivated
            }
        });

        apiUploadUpdate(ssResp);
    };

    return {
        setMature
    };
};
