import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

// components
import Providers from "./Providers";
import ProtectedRoutes from "./ProtectedRoutes/index";
import HomePage from "./pages/HomePage";
import VisualQueuePage from "./pages/VisualQueuePage";
import SuspendedQueuePage from "./pages/SuspendedQueuePage";
import CompletedQueuePage from "./pages/CompletedQueuePage";
import MetadataQueuePage from "./pages/MetadataQueuePage";

// Mock Component Queries: Test individual Apollo Query Mocks

// store
import photoModStore from "./store";

export const PhotoMod = () => {
    const { path } = useRouteMatch();

    return (
        <Providers reduxStore={photoModStore}>
            <Switch>
                <ProtectedRoutes>
                    <Route exact path={path}>
                        <HomePage />
                    </Route>
                    <Route path={`${path}/visual-queue`}>
                        <VisualQueuePage />
                    </Route>
                    <Route path={`${path}/metadata-queue`}>
                        <MetadataQueuePage />
                    </Route>
                    <Route path={`${path}/completed-queue`}>
                        <CompletedQueuePage />
                    </Route>
                    <Route path={`${path}/suspended-queue`}>
                        <SuspendedQueuePage />
                    </Route>
                </ProtectedRoutes>
            </Switch>
        </Providers>
    );
};

export default PhotoMod;
