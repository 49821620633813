import React from "react";

// context
import { useQueueContext } from "frontend/components/fort/mod/core/Layouts/QueueLayout/QueueContext.js";

// components
import { Location } from "frontend/components/fort/mod/core/Sidebar/Overview/Location.js";

// component
export const PhotoLocation = () => {
    const {
        state: { singleUploadSelected }
    } = useQueueContext();

    // TODO: Location
    const onLocationUpdate = ({ location: photoLocation }) => {
        // console.log({ photoLocation });
    };

    return <Location onLocationUpdate={onLocationUpdate} />;
};

export default PhotoLocation;
