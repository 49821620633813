/*---------------------------
| Store
---------------------------*/
// Action Types
const actionPrefix = "InlineTextEditContext:";
const actionTypes = {
    HAS_MOUNTED_UPDATE: `${actionPrefix} hasMountedUpdate`,
    IS_EDITING_UPDATE: `${actionPrefix} isEditingUpdate`,
    EDITED_TEXT_UPDATE: `${actionPrefix} editedTextUpdate`,
    ERROR_MESSAGE_UPDATE: `${actionPrefix} errorMessageUpdate`,
    ON_ABORT: `${actionPrefix} onAbort`
};

// Action Creators
export const actions = {
    hasMountedUpdate: hasMounted => {
        return {
            type: actionTypes.HAS_MOUNTED_UPDATE,
            hasMounted
        };
    },
    editedTextUpdate: editedText => {
        return {
            type: actionTypes.EDITED_TEXT_UPDATE,
            editedText
        };
    },
    errorMessageUpdate: errorMessage => {
        return {
            type: actionTypes.ERROR_MESSAGE_UPDATE,
            errorMessage
        };
    },
    isEditingUpdate: isEditing => {
        return {
            type: actionTypes.IS_EDITING_UPDATE,
            isEditing
        };
    },
    onAbort: consumerText => {
        return {
            type: actionTypes.ON_ABORT,
            consumerText
        };
    },
    // Magical Async method to team up with Consumer for both client-side and server-side validation
    onSave: async (
        state,
        dispatch,
        consumerOnSave,
        validationRules,
        ariaLabel
    ) => {
        const { editedText } = state;

        // internal actions like validation
        const errorMessage = clientSideValidate(
            editedText,
            validationRules,
            ariaLabel
        );

        if (!errorMessage) {
            // Let consumer take it from here...
            const consumerResp = await consumerOnSave({ editedText });
            let consumerErrorMessage = consumerResp?.errorMessage || "";
            if (consumerErrorMessage) {
                return dispatch(
                    actions.errorMessageUpdate(
                        consumerResp?.errorMessage ||
                            "Sorry, something went wrong. Please try again."
                    )
                );
            }
            return dispatch(actions.isEditingUpdate(false));
        }

        return dispatch(actions.errorMessageUpdate(errorMessage));
    }
};

// Reducer
export const reducer = (state, action) => {
    const { type } = action;

    switch (type) {
        case actionTypes.HAS_MOUNTED_UPDATE: {
            return {
                ...state,
                hasMounted: action.hasMounted
            };
        }
        case actionTypes.IS_EDITING_UPDATE: {
            return {
                ...state,
                isEditing: action.isEditing
            };
        }
        case actionTypes.EDITED_TEXT_UPDATE: {
            return {
                ...state,
                editedText: action.editedText,
                errorMessage: "" // The moment user starts typing, clear any error messages from validation
            };
        }
        case actionTypes.ERROR_MESSAGE_UPDATE: {
            return {
                ...state,
                errorMessage: action.errorMessage
            };
        }
        case actionTypes.ON_ABORT: {
            return {
                ...state,
                editedText: action.consumerText,
                errorMessage: "",
                isEditing: false
            };
        }
    }
};

const clientSideValidate = (editedText, validationRules, ariaLabel) => {
    let errorMessage = "";
    let trimmedText = editedText.trim();

    validationRules?.some(rule => {
        const { isValid, message } = rule(ariaLabel, trimmedText);
        if (!isValid) {
            errorMessage = message;
            return true;
        }
        return false;
    });

    return errorMessage;
};
