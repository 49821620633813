import React from "react";
import PropTypes from "prop-types";

import { productMenu, homePath } from "data/links";

import NpHeaderLogo from "./images/NpHeaderLogo";

import { OverflowMenu } from "components/OverflowMenu";
import { ProfileMenu } from "components/ProfileMenu";
import DefaultSearch from "core/search/DefaultSearch";

import Link from "core/Link";
import Flexbox from "core/Flexbox";
import { Col } from "core/oldGrid";
import colors from "core/colors";

import {
    NonMobileContentWrapper,
    TextLinkGrid,
    NonMobileTopRow,
    MainLinksCol,
    ProfileMenuCol,
    ProfileMenuPinnedStyled,
    SearchContainerRow,
    MainLinks,
    HeaderLogoLink
} from "./styles";

const NonMobileContent = ({
    transparent,
    currentUser,
    myPhotoModProfile,
    loginLink,
    joinLink,
    handleLogOut,
    isHeroSearchV2,
    animateElements
}) => {
    return (
        <NonMobileContentWrapper>
            <TextLinkGrid>
                <NonMobileTopRow between="md" middle="md">
                    <MainLinksCol
                        lg={5}
                        md={5}
                        animateElements={animateElements}
                    >
                        <MainLinks
                            alignItems="center"
                            transparent={transparent}
                            aria-hidden={animateElements}
                        >
                            {productMenu.map(link => (
                                <Link
                                    key={link.name}
                                    href={link.href}
                                    target={link.external ? "_blank" : "_self"}
                                    size="md"
                                    styleType="secondary"
                                    darkMode={isHeroSearchV2}
                                    disableVisitedStyle
                                >
                                    {link.name}
                                </Link>
                            ))}

                            <OverflowMenu
                                transparent={transparent}
                                isHeroSearchV2={isHeroSearchV2}
                            />
                        </MainLinks>
                    </MainLinksCol>
                    <ProfileMenuCol mdOffset={5} md={2} lgOffset={5} lg={2}>
                        <ProfileMenuPinnedStyled>
                            <Flexbox justifyContent="flex-end">
                                <ProfileMenu
                                    isHeroSearchV2={isHeroSearchV2}
                                    currentUser={currentUser}
                                    myPhotoModProfile={myPhotoModProfile}
                                    loginLink={loginLink}
                                    joinLink={joinLink}
                                    handleLogOut={handleLogOut}
                                />
                            </Flexbox>
                        </ProfileMenuPinnedStyled>
                    </ProfileMenuCol>
                </NonMobileTopRow>
                <SearchContainerRow middle="md" isHeroSearchV2={isHeroSearchV2}>
                    <Col md={2}>
                        <HeaderLogoLink href={homePath.url}>
                            <NpHeaderLogo
                                title={homePath.alt}
                                fillColor={
                                    isHeroSearchV2
                                        ? colors.primary.snow
                                        : colors.secondary.onyx
                                }
                            />
                        </HeaderLogoLink>
                    </Col>
                    <Col md={8}>
                        <DefaultSearch />
                    </Col>
                </SearchContainerRow>
            </TextLinkGrid>
        </NonMobileContentWrapper>
    );
};

NonMobileContent.propTypes = {
    isHeroSearchV2: PropTypes.bool,
    transparent: PropTypes.bool,
    currentUser: PropTypes.object,
    hasApiKey: PropTypes.bool,
    myPhotoModProfile: PropTypes.object,
    loginLink: PropTypes.oneOfType([PropTypes.node, PropTypes.element])
        .isRequired,
    joinLink: PropTypes.oneOfType([PropTypes.node, PropTypes.element])
        .isRequired,
    handleLogOut: PropTypes.func.isRequired,
    animateElements: PropTypes.bool
};

export { NonMobileContent };
