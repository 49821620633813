import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Messages from "core/Messages";
import BodyText from "core/BodyText";
import Link from "core/Link";

import { useViewport } from "js/hooks/useViewport";

export const DISMISSAL_DATE_KEY = "inactiveTabMessageDismissedAt";

const PhotoUploadInactiveTabMessage = ({ uploadsInDropzoneCount }) => {
    const [messageDismissalDate, setMessageDismissalDate] = useState(null);
    const [messageDismissed, setMessageDismissed] = useState(false);
    const [showMessage, setShowMessage] = useState(false);

    const { browserWidth } = useViewport();
    const textSize = browserWidth < 1041 ? "sm" : "md";

    useEffect(() => {
        if (!!messageDismissalDate) {
            return;
        }

        const storedMessageDismissalDate = localStorage.getItem(
            DISMISSAL_DATE_KEY
        );

        if (storedMessageDismissalDate) {
            setMessageDismissalDate(storedMessageDismissalDate);
        }
    }, [messageDismissalDate]);

    useEffect(() => {
        if (messageDismissed) {
            saveTimeDismissed();
        }

        function saveTimeDismissed() {
            localStorage.setItem(DISMISSAL_DATE_KEY, new Date());
        }
    }, [messageDismissed]);

    useEffect(() => {
        if (messageDismissalDate) {
            const daysElapsed = getDaysElapsed(messageDismissalDate);
            if (daysElapsed > 30) {
                return setShowMessage(true);
            }
        } else if (uploadsInDropzoneCount < 1) {
            return setShowMessage(true);
        }

        return setShowMessage(false);

        function getDaysElapsed(date) {
            const dateOne = new Date(date);
            const today = Date.now();
            const diff = today.getTime() - dateOne.getTime();

            return diff / (1000 * 3600 * 24);
        }
    }, [uploadsInDropzoneCount, messageDismissalDate]);

    const handleDismissClick = () => {
        setMessageDismissed(true);
    };

    // rules for showing message:
    // show only if dropzone is empty
    // if user dismisses message, wait 30 days to show message again
    if (!showMessage) return null;

    return (
        <Messages
            handleDismissClick={handleDismissClick}
            styleType="solid"
            isDismissable
            infoType="info"
            width="50%"
        >
            <BodyText size={textSize}>
                Leaving or minimizing this tab during upload may cause failure.{" "}
                <Link href="" size={textSize}>
                    Updating browser settings
                </Link>{" "}
                can prevent this problem.
            </BodyText>
        </Messages>
    );
};

PhotoUploadInactiveTabMessage.propTypes = {
    uploadsInDropzoneCount: PropTypes.number.isRequired
};

export default PhotoUploadInactiveTabMessage;
