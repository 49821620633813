import React from "react";
import { Reset } from "styled-reset";
import { createGlobalStyle, ThemeProvider } from "styled-components";

// Components
import { AppRouter } from "./routes";
import { Layout } from "core/Layout";
import { fontStyles } from "core/fontStyles";
import breakpoints from "core/breakpoints";

// Helpers
import { AuthProvider } from "frontend/hooks/useAuth";
import { CurrentUserContextProvider } from "contexts/CurrentUserContext";
import theme from "js/themes/theme";

import { BannersProvider } from "frontend/context/BannersContext";
import { CookieConsentProvider } from "frontend/context/CookieConsentContext";

export const GlobalStyle = createGlobalStyle`
     html {
        box-sizing: border-box;
        font-size: 100%;
    }

    body {
        overflow-x: hidden;
    }

    *, *:before, *:after {
        box-sizing: inherit;
    }

    * {
        word-spacing: 0.1em;
    }

    ${fontStyles}

    // styles for downtime banner from static/assets/sass/base/_base.sass:
    /* mobile-only: */
    @media (max-width: ${breakpoints.beforeTablet.rem}) {
        .show-lg {
            display: none;
        }
    }
    /* tablet + desktop: */
    @media (min-width: ${breakpoints.tablet.rem}) {
        .show-sm {
            display: none;
        }
    }
`;

const App = () => (
    <CurrentUserContextProvider>
        <AuthProvider>
            <BannersProvider>
                <CookieConsentProvider>
                    <ThemeProvider theme={theme}>
                        <Layout>
                            <Reset />
                            <GlobalStyle />
                            <AppRouter />
                        </Layout>
                    </ThemeProvider>
                </CookieConsentProvider>
            </BannersProvider>
        </AuthProvider>
    </CurrentUserContextProvider>
);

export default App;

// NOTE: these themes and globalstyles are also in storybook/preview.js
