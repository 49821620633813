import styled from "styled-components";
import { Link } from "react-router-dom";

import Heading from "core/Heading";

import { BorderBottomLinkStyle } from "core/Typography";
import { Row } from "core/oldGrid";
import breakpoints from "core/breakpoints";
import colors from "core/colors";

export const TitleText = styled.span`
    font-style: italic;
`;

export const LocationIconContainer = styled.div`
    margin-right: 0.5rem;
`;

export const HeaderTitle = styled(Heading)`
    color: ${colors.secondary.orca};
    text-transform: ${({ tag }) => (tag ? "capitalize" : "")};

    /* default for mobile */
    letter-spacing: 0px;

    /* @media (min-width: 48rem) inherits default */

    /* (min-width: 1200px) and (min-height: 1001px) */
    @media (min-width: ${breakpoints.desktop
            .rem}) and (min-height: ${breakpoints.outsideLaptopHeightsRange
            .rem}) {
        letter-spacing: -0.01px;
    }
`;

export const ShootDetail = styled.h6`
    font-size: 0.875rem;
    margin-right: 1.2rem;
    line-height: 160%;
    color: ${colors.secondary.oolong};
`;

export const SuggestionText = styled.p`
    font-size: 0.9rem;
    margin-left: 0.625rem;
    margin-bottom: 1.5rem;
`;

export const SearchInfo = styled.div`
    margin-left: 0.375rem;
`;

export const NoResultsText = styled.div`
    font-size: 2.2rem;
    color: ${colors.secondary.oolong};
`;

export const EmptyResults = styled(Row)`
    display: flex;
    background-color: ${colors.primary.diamond};
    margin: 2.5rem 0.625rem;
    border-radius: 0.0625rem;
    align-items: center;
    justify-content: center;
    padding: 10.63rem 0;
    line-height: 1;
    text-align: center;
`;

export const SearchResultsRow = styled(Row)`
    margin: 2rem 0 1.5rem 0;

    @media (min-width: ${breakpoints.tablet.rem}) {
        margin-top: 2.25rem /* 36px */;
        margin-bottom: 1.75rem;
    }

    /* (min-width: 1200px) and (min-height: 1001px) */
    @media (min-width: ${breakpoints.desktop
            .rem}) and (min-height: ${breakpoints.outsideLaptopHeightsRange
            .rem}) {
        margin-top: 2.5rem; /* 40px */
        margin-bottom: 2rem;
    }

    @media (min-width: ${breakpoints.customMobileMax
            .rem}) and (max-width: ${breakpoints.tablet.rem}) {
        margin-top: 5.5rem;
    }
`;

export const SubSearchResultsRow = styled(Row)`
    a {
        ${BorderBottomLinkStyle}
    }
    margin: 1rem 0 2rem 0;

    /* mobile lg */
    @media (min-width: ${breakpoints.customMobileMax
            .rem}) and (max-width: ${breakpoints.tablet.rem}) {
        margin: 1rem 0 1.5rem 0;
    }
`;

export const ButtonGroupRow = styled(Row)`
    margin-left: 0.1875rem;
    margin-bottom: 2rem;

    @media (min-width: ${breakpoints.desktop
            .rem}) and (min-height: ${breakpoints.outsideLaptopHeightsRange
            .rem}) {
        margin-bottom: 3rem;
    }

    @media (min-width: ${breakpoints.tablet.rem}) {
        margin-bottom: 2.5rem;
    }
`;

export const QueryLink = styled(Link)`
    margin-left: 0.3125rem;
    color: ${colors.secondary.oolong};
`;
