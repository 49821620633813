import React from "react";
import PropTypes from "prop-types";

// components
import Template from "./Template.js";

const Total = ({ total }) => {
    return (
        <Template title="Total" width={47}>
            <div data-testid="batch-upload-count">{total}</div>
        </Template>
    );
};

export default Total;

// prop-types
Total.propTypes = {
    date: PropTypes.string
};
