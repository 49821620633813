import React from "react";

import { useViewport } from "js/hooks/useViewport";

import SectionContainer from "core/SectionContainer";
import Faq from "core/Faq";
import TextWithButton from "core/TextWithButton";
import OffsetGrid from "core/OffsetGrid";
import HeroImage from "core/HeroImage";
import TestimonialSection from "core/TestimonialSection";
import PageMeta from "core/PageMeta";
import OfficePets from "./OfficePets";

import colors from "core/colors";
import breakpoints from "core/breakpoints";

import {
    titleTag,
    metaDescription,
    sectionOneText,
    sectionOneSubText,
    sectionTwoTitle,
    sectionTwoValues,
    testimonials,
    sectionFourText,
    sectionFourSubText,
    sectionSixTitle,
    sectionSixValues,
    sectionSevenText,
    sectionSevenSubText,
    joinUsBtn
} from "./data";

import CareersMainPhotoDesktop from "./images/careers_with_np.png";
import CareersMainPhotoTablet from "./images/careers_with_np_tablet.png";
import CareersMainPhotoMobile from "./images/careers_with_np_mobile.png";
import WhoWeArePhoto from "./images/team-large.png";
import WhoWeAreTablet from "./images/team-medium.png";
import WhoWeAreMobile from "./images/team-small.png";

const CareersPage = () => {
    const { browserWidth } = useViewport();
    const isMobile = browserWidth < breakpoints.tablet.px;

    return (
        <>
            <PageMeta title={titleTag} description={metaDescription} />
            <SectionContainer bottomSpacing="none">
                <OffsetGrid
                    sm={10}
                    md={10}
                    lg={7}
                    justifyContent={isMobile ? "center" : "flex-start"}
                >
                    <TextWithButton
                        text={sectionOneText}
                        subtext={sectionOneSubText}
                        headingType="h1"
                        headingAlignment="left"
                        headingSize="2xl"
                        subtextAlignment="left"
                        bottomSpacing="3xl"
                    />
                </OffsetGrid>
                <HeroImage
                    heroImageDesktop={CareersMainPhotoDesktop}
                    heroImageTablet={CareersMainPhotoTablet}
                    heroImageMobile={CareersMainPhotoMobile}
                />
            </SectionContainer>

            <SectionContainer>
                <OffsetGrid sm={10} md={12} lg={12}>
                    <Faq
                        title={sectionTwoTitle}
                        values={sectionTwoValues}
                        headingAlignment="left"
                        headingColor={colors.secondary.orca}
                    />
                </OffsetGrid>
            </SectionContainer>

            <SectionContainer
                bgColor={colors.primary.diamond}
                topSpacing="5xl"
                bottomSpacing="5xl"
            >
                <OffsetGrid sm={10} md={8} lg={10}>
                    <TestimonialSection testimonials={testimonials} />
                </OffsetGrid>
            </SectionContainer>
            <SectionContainer>
                <OffsetGrid
                    sm={10}
                    md={10}
                    lg={7}
                    justifyContent={isMobile ? "center" : "flex-start"}
                >
                    <TextWithButton
                        text={sectionFourText}
                        subtext={sectionFourSubText}
                        headingType="h2"
                        subtextAlignment="left"
                        bottomSpacing="3xl"
                        headingAlignment="left"
                    />
                </OffsetGrid>
                <HeroImage
                    heroImageDesktop={WhoWeArePhoto}
                    heroImageTablet={WhoWeAreTablet}
                    heroImageMobile={WhoWeAreMobile}
                />
            </SectionContainer>

            <SectionContainer bgColor={colors.primary.diamond}>
                <OfficePets />
            </SectionContainer>

            <SectionContainer>
                <OffsetGrid sm={10} md={12} lg={12}>
                    <Faq
                        title={sectionSixTitle}
                        values={sectionSixValues}
                        headingAlignment="left"
                        headingColor={colors.secondary.orca}
                    />
                </OffsetGrid>
            </SectionContainer>
            <SectionContainer bgColor={colors.primary.diamond}>
                <OffsetGrid
                    sm={10}
                    md={10}
                    lg={7}
                    justifyContent={isMobile ? "center" : "flex-start"}
                >
                    <TextWithButton
                        text={sectionSevenText}
                        subtext={sectionSevenSubText}
                        headingType="h2"
                        subtextAlignment="left"
                        headingAlignment="left"
                        buttons={joinUsBtn}
                        buttonAlignment="left"
                    />
                </OffsetGrid>
            </SectionContainer>
        </>
    );
};

export default CareersPage;
