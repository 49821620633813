import React from "react";
import { useRouter } from "next/router";
import queryString from "query-string";
import { capitalizeFirstChar } from "frontend/components/helpers";
import { format, addMinutes } from "date-fns";

export const isServerSide = () => {
    return typeof window === "undefined";
};

export const formatTerm = term => {
    // first capitalizes the first char
    // then capitalizes the first char after every hyphen
    const capFirst = capitalizeFirstChar({ str: term });
    return capFirst.replace(/-[a-z]/g, match => match.toUpperCase());
};

export const makeTitleCase = value => {
    value = String(value || "");
    return (
        value.charAt(0).toUpperCase() +
        value.slice(1).replace(/[-\s]+[a-z]{1}/g, match => match.toUpperCase())
    );
};

// Pretty-print number based on localization; and support SSR
// by defaulting to US when rendering server-side.
export const getFormattedNumber = num => {
    const localization = isServerSide() ? "en-US" : undefined;
    return Number(num).toLocaleString(localization);
};

// Used for showing uploaded date on icon & photo detail pages
export const getFormattedDate = dateString => {
    if (!dateString) return null;

    let date;
    if (dateString.includes("T")) {
        // if date string already includes time, just format it (photo detail)
        date = new Date(dateString);
    } else {
        // if date string doesn't include time, add time to it (icon detail)
        date = new Date(dateString + "T00:00:00Z");
    }

    // shift the date from UTC to the user's local time
    // this prevents server from rendering different date than client
    const dateWithOffset = addMinutes(date, date.getTimezoneOffset());
    return format(dateWithOffset, "MMMM DD, YYYY");
};

// Ability to grab URL query on server-side, client-side, and legacy SPA pages
export const useUrlQuery = () => {
    if (typeof window === "undefined") {
        const router = useRouter();
        return router.query;
    } else {
        return queryString.parse(window.location.search);
    }
};

export const setLocalStorage = (key, data) => {
    const onServer = isServerSide();
    if (onServer) return;
    if (window.localStorage) {
        localStorage.setItem(key, JSON.stringify(data));
    }
};

export const getLocalStorage = key => {
    const onServer = isServerSide();
    if (onServer) return;
    if (window.localStorage) {
        const data = localStorage.getItem(key);
        try {
            return data ? JSON.parse(data) : null;
        } catch (e) {
            return null; // if not valid JSON
        }
    }
    return null;
};

// Convert query string params for style filters into object expected by GraphQL
// and validate the input
export const buildStyleFilters = ({ style, weight }) => {
    const validStyles = ["line", "solid"];
    const styleFilters = {
        style: style ? style.split(",") : null,
        weight: weight ? weight.split("-").map(v => parseInt(v)) : null
    };

    // check if weight contains valid integers
    if (styleFilters.weight) {
        styleFilters.weight = styleFilters.weight.filter(
            v => typeof v === "number" && !isNaN(v)
        );
        if (styleFilters.weight.length !== 2) {
            styleFilters.weight = null;
        }
    }

    // check if style contains only 'line' and or 'solid'
    if (styleFilters.style) {
        styleFilters.style = styleFilters.style.filter(v =>
            validStyles.includes(v)
        );
        if (styleFilters.style.length === 0) {
            styleFilters.style = null;
        }
    }

    return styleFilters;
};

// Fetch, but will retry if network errors occur.
const MAX_RETRIES = 3;
export const tryFetch = async (url, headers, retries = 0) => {
    try {
        const res = await fetch(url, headers);
        return res;
    } catch (error) {
        // network error occurred, try again
        if (retries < MAX_RETRIES) {
            return tryFetch(url, headers, retries + 1);
        }
        return { ok: false, error };
    }
};

// Given user object & team's username, return the team's ID
export const getTeamId = ({ currentUser, teamUser }) => {
    if (teamUser && currentUser && Array.isArray(currentUser.ownedTeams)) {
        const match = currentUser.ownedTeams.find(
            team => team && team.username === teamUser
        );
        if (match && match.id) {
            return match.id;
        }
    }
    return null;
};

/**
 * getCurrentPage
 * SSR: Gives us the ability to inform sub elements which page we are on
 * Inspired by the need to change the header in Home, Icons and Photos landing pages
 * And can be used for other purposes.
 * @param {Object} router NextJS Router
 * @returns {String} || undefined
 */
export const getCurrentPage = router => {
    const { asPath } = router; // `asPath` includes the path without the domain

    // returns undefined if we do not specify a value
    let currentPage;

    switch (asPath) {
        case "/":
            currentPage = "home";
            break;
        case "/icons/":
            currentPage = "icons";
            break;
        case "/photos/":
            currentPage = "photos";
            break;
    }

    return currentPage;
};
