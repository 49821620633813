import styled from "styled-components";

import { P } from "core/Typography";
import fonts from "core/fonts";
import { Item } from "./index";

const Heading = styled(P).attrs({ size: 14, bold: true })`
    color: ${fonts.fontColors.light.primary};
`;

const Section = styled.ul`
    display: inline-block;
    text-align: left;
    vertical-align: top;

    ${Heading} {
        margin-bottom: 2.0625rem;
    }

    ${Item} {
        &:not(:last-of-type) {
            margin-bottom: 1.3125rem;
        }
    }

    ${Item} & {
        display: block;

        ${Item} {
            margin-bottom: 0;
        }
    }
`;

export { Heading, Section };
