import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
// styles
import colors from "core/colors";
import { convertPxToRem } from "frontend/components/helpers";
import {
    regular as regularFontWeight,
    medium as mediumFontWeight
} from "core/fontStyles";
const MetaListItemStyled = styled.li`
    font-size: ${convertPxToRem(15)};
    margin-bottom: ${convertPxToRem(8)};
    ${regularFontWeight}
    color: ${colors.secondary.orca};

    list-style-type: disc;

    b {
        ${mediumFontWeight}
    }
`;

// component
export const MetaListItem = ({ item }) => {
    return (
        <MetaListItemStyled dangerouslySetInnerHTML={{ __html: item.text }} />
    );
};

export default MetaListItem;

// prop-types
MetaListItem.propTypes = {
    item: PropTypes.object.isRequired
};
