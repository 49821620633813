import React from "react";

import { useViewportText } from "frontend/hooks/useViewportText";

import PageMeta from "core/PageMeta";
import SectionContainer from "core/SectionContainer";
import BulletSection from "core/BulletSection";
import OffsetGrid from "core/OffsetGrid";
import HeroLandingPage from "core/HeroLandingPage";
import Collections from "core/Collections";
import BlogPosts from "core/BlogPosts";
import PlanSection from "marketing-pages/PlanSection";

import colors from "core/colors";

import {
    heroImages,
    heroHeading,
    heroSearchPlaceholder,
    bullets,
    collectionsTitle,
    collections,
    pillsTitle,
    pills,
    featuredTitle,
    features
} from "./data";

const PhotosLanding = () => {
    const placeholder = useViewportText(heroSearchPlaceholder);

    const structuredDataForSEO = {
        "@context": "https://schema.org",
        "@type": "WebSite",
        name: "The Noun Project",
        alternateName: "The Noun Project, Inc.",
        url: "https://thenounproject.com/photos/",
        headline: heroHeading,
        description:
            "Noun Project is building the best and most useful collection of stock photos. Browse a variety of royalty-free images representing a more inclusive world.",
        logo:
            "https://static.production.thenounproject.com/img/nounproject_logo.60e860f212a5.png",
        inLanguage: "en",
        sameAs: [
            "https://twitter.com/nounproject",
            "https://www.facebook.com/thenounproject",
            "https://www.instagram.com/nounproject/",
            "https://dribbble.com/nounproject",
            "https://www.behance.net/noun_project",
            "https://en.wikipedia.org/wiki/The_Noun_Project"
        ],
        potentialAction: {
            "@type": "SearchAction",
            target:
                "https://thenounproject.com/search/photos/?q={search_term_string}",
            "query-input": "required name=search_term_string"
        }
    };

    return (
        <>
            <PageMeta
                title="Stock Photos & Royalty-Free Images | Noun Project"
                description="Noun Project is building the best and most useful collection of stock photos. Browse a variety of royalty-free images representing a more inclusive world."
                indexContent="index,follow,max-image-preview:large"
                structuredData={structuredDataForSEO}
                canonicalURL="https://thenounproject.com/photos/"
            />
            <SectionContainer
                bgColor={colors.secondary.orca}
                textColor={colors.primary.diamond}
                topSpacing="none"
                bottomSpacing="4xl"
            >
                <OffsetGrid sm={10} md={12} lg={12}>
                    <HeroLandingPage
                        heroImages={heroImages}
                        heading={heroHeading}
                        placeholder={placeholder}
                        headingColor={colors.primary.snow}
                        bgSizeTablet="100%"
                        bgPositionTablet="100% bottom"
                    />
                </OffsetGrid>
            </SectionContainer>

            <SectionContainer>
                <OffsetGrid sm={10} md={12} lg={12}>
                    <BulletSection bullets={bullets} hasMobileOffsetOneCol />
                </OffsetGrid>
            </SectionContainer>

            <SectionContainer
                bgColor={colors.primary.diamond}
                textColor={colors.secondary.orca}
            >
                <Collections
                    title={collectionsTitle}
                    collections={collections}
                    pillsTitle={pillsTitle}
                    pills={pills}
                    hasTitleOffset
                />
            </SectionContainer>

            <SectionContainer bgColor={colors.primary.snow}>
                <PlanSection />
            </SectionContainer>

            <SectionContainer bgColor={colors.primary.diamond}>
                <OffsetGrid sm={10} md={12} lg={12}>
                    <BlogPosts
                        blogPostsTitle={featuredTitle}
                        posts={features}
                    />
                </OffsetGrid>
            </SectionContainer>

            {/* ^ this component contains a SectionContainer on account of a page anchor */}
        </>
    );
};

export default PhotosLanding;
