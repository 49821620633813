import * as actionTypes from "../actions/actionTypes";
import * as actions from "../actions/actions";
import _uniq from "lodash/uniq";
import _forEach from "lodash/forEach";
import _isNumber from "lodash/isNumber";

const defaultState = {
    all: {},
    savedIconIds: {}
};

export const KITS_FLOW = "KITS_FLOW";

const addKit = (k, state) => {
    let { all, savedIconIds } = state;
    all = { ...all };
    savedIconIds = { ...savedIconIds };
    all[k.id] = k;
    k.icon_ids.forEach(iconId => {
        savedIconIds[iconId] = savedIconIds[iconId] || [];
        savedIconIds[iconId].push(k.id);
    });

    return {
        ...state,
        all,
        savedIconIds
    };
};

const addIconToKit = (iconId, kitId, state) => {
    let newState = { ...state };

    let current = newState.all[kitId];
    if (current) {
        current.icon_ids = current.icon_ids.concat([iconId]);
        newState.all[kitId] = { ...current };
        newState.all = { ...newState.all };

        let iconSavedInKits = newState.savedIconIds[iconId] || [];
        iconSavedInKits = _uniq(iconSavedInKits.concat([current.id]));
        newState.savedIconIds = {
            ...newState.savedIconIds,
            [iconId]: iconSavedInKits
        };
        return newState;
    }
    return state;
};

const removeIconFromKit = (iconId, kitId, state) => {
    let newState = { ...state };
    let current = newState.all[kitId];
    if (current) {
        current.icon_ids = current.icon_ids.filter(id => id != iconId);
        newState.all[kitId] = { ...current };
        newState.all = { ...newState.all };

        let iconSavedInKits = newState.savedIconIds[iconId] || [];
        iconSavedInKits = iconSavedInKits.filter(kitId => kitId != current.id);
        if (!iconSavedInKits.length) {
            delete newState.savedIconIds[iconId];
        } else {
            newState.savedIconIds = {
                ...newState.savedIconIds,
                [iconId]: iconSavedInKits
            };
        }
        return newState;
    }
    return state;
};

const initializeNewKit = state => {
    let { kitCreators } = state;
    let owner_id = kitCreators[0] && kitCreators[0].value;
    return {
        name: "",
        description: "",
        is_public: true,
        owner_id
    };
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case actionTypes.GET_CURRENT_USER_SUCCESS: {
            let user = action.response;
            let isPro = user?.has_premium_account;
            let kitCreators = [];
            if (user) {
                if (isPro) {
                    kitCreators.push({
                        value: user.id,
                        name: user.username
                    });
                }
                if (user.teams) {
                    user.teams.forEach(team =>
                        kitCreators.push({
                            name: team.name,
                            value: team.team_user_id
                        })
                    );
                }
            }

            let { waitingForAuth, dropdownShowing } = state;
            if (waitingForAuth) {
                dropdownShowing = true;
                if (!isPro && _isNumber(waitingForAuth)) {
                    action.asyncDispatch(
                        actions.kitsSaveToFavorites(waitingForAuth, true)
                    );
                }
            }
            waitingForAuth = false;
            return {
                ...state,
                kitCreators,
                dropdownShowing,
                waitingForAuth
            };
        }
        case actionTypes.KITS_FETCH:
        case actionTypes.KITS_CREATE_KIT:
        case actionTypes.KITS_ADD_ICON:
        case actionTypes.KITS_SAVE_TO_FAVORITES:
        case actionTypes.KITS_REMOVE_FROM_FAVORITES:
        case actionTypes.KITS_REMOVE_ICON: {
            let processing = true;
            return {
                ...state,
                processing
            };
        }
        case actionTypes.KITS_FETCH_FAILURE:
        case actionTypes.KITS_ADD_ICON_FAILURE:
        case actionTypes.KITS_SAVE_TO_FAVORITES_FAILURE:
        case actionTypes.KITS_REMOVE_FROM_FAVORITES_FAILURE:
        case actionTypes.KITS_REMOVE_ICON_FAILURE: {
            let processing = false;
            return {
                ...state,
                processing
            };
        }

        case actionTypes.KITS_FETCH_SUCCESS: {
            let { kits } = action.response;
            if (kits) {
                let newState = { ...state, all: {} };
                kits.forEach(k => {
                    newState = addKit(k, newState);
                });
                return {
                    ...newState,
                    processing: false
                };
            } else {
                return state;
            }
        }
        case actionTypes.KITS_ADD_ICON_SUCCESS: {
            let newState = {
                ...state,
                processing: false,
                dropdownShowing: false
            };
            newState = addIconToKit(action.iconId, action.kitId, newState);
            return newState;
        }

        case actionTypes.KITS_REMOVE_ICON_SUCCESS: {
            let newState = { ...state, processing: false };
            newState = removeIconFromKit(action.iconId, action.kitId, newState);
            return newState;
        }

        case actionTypes.DOWNLOAD_SHOW_AUTH: {
            let { resumeFlow } = action;
            if (resumeFlow == KITS_FLOW) {
                let { extra } = action;
                let waitingForAuth = true;
                if (extra && extra.iconId) {
                    waitingForAuth = parseInt(extra.iconId) || true;
                }
                return {
                    ...state,
                    waitingForAuth
                };
            } else {
                return state;
            }
        }

        case actionTypes.KITS_SHOW_DROPDOWN: {
            return {
                ...state,
                dropdownShowing: true
            };
        }

        case actionTypes.KITS_HIDE_DROPDOWN: {
            return {
                ...state,
                dropdownShowing: false,
                newKit: null
            };
        }

        case actionTypes.KITS_INPUT_CHANGE: {
            let { input } = action;
            let { fieldName, value } = input;
            let { newKit, kitCreators } = state;
            newKit = newKit || initializeNewKit(state);
            return {
                ...state,
                fieldErrors: null,
                newKit: {
                    ...newKit,
                    [fieldName]: value
                }
            };
        }

        case actionTypes.KITS_CREATE_KIT_SUCCESS: {
            let newState = {
                ...state,
                newKit: false,
                dropdownShowing: false,
                processing: false
            };
            let kitDirty = action.response;
            let kit = {};
            _forEach(kitDirty, (value, key) => {
                kit[key.replace("kit_", "")] = value;
            });
            newState = addKit(kit, newState);

            return {
                ...newState
            };
        }

        case actionTypes.KITS_CREATE_KIT_FAILURE: {
            let fieldErrors = {};
            let { error } = action;
            if (error) {
                let { errors } = error;
                if (errors) {
                    _forEach(errors, (fieldName, errArr) => {
                        fieldErrors[fieldName] = errArr[0];
                    });
                }
            }

            return {
                ...state,
                processing: false,
                fieldErrors
            };
        }

        case actionTypes.KITS_SAVE_TO_FAVORITES_SUCCESS: {
            let { kit } = action.response;
            let kitId = kit.id;

            let newState = { ...state, processing: false };
            newState = addIconToKit(action.iconId, kitId, newState);
            return newState;
        }

        case actionTypes.KITS_REMOVE_FROM_FAVORITES_SUCCESS: {
            let { kit } = action.response;
            let kitId = kit.id;

            let newState = { ...state, processing: false };
            newState = removeIconFromKit(action.iconId, kitId, newState);
            return newState;
        }

        case actionTypes.KITS_INITIALIZE_NEW_KIT: {
            let newKit = initializeNewKit(state);
            return {
                ...state,
                newKit
            };
        }
    }
    return state;
};
