import React from "react";
import PropTypes from "prop-types";

// components + styles:
import SrOnly from "core/SrOnly";

// helpers:
import { getLabelOrText } from "./helpers";

const ButtonContent = ({ option }) => {
    const Image = option.img && option.img;

    const conditionalImageProps = option.fillColor && {
        fillColor: option.fillColor
    };

    // if arialabel and its the same as text, give it the text, else label.
    const ariaLabelOrText = getLabelOrText({
        text: option.text,
        ariaLabel: option.ariaLabel
    });

    return option.img ? (
        <>
            <SrOnly>{ariaLabelOrText}</SrOnly>
            <Image alt="" {...conditionalImageProps} />
        </>
    ) : (
        <>
            {/* if arialabel & doesnt match text, display both: */}
            {option.ariaLabel && option.ariaLabel !== option.text ? (
                <>
                    {/* show arialabel to screen readers but hide in ui: */}
                    <SrOnly>{option.ariaLabel}</SrOnly>

                    {/* hide text from screen readers but show in ui: */}
                    <span aria-hidden={true}>{option.text}</span>
                </>
            ) : (
                // if no arialabel, show text:
                option.text
            )}
        </>
    );
};

ButtonContent.propTypes = {
    option: PropTypes.object.isRequired
};

export default ButtonContent;
