import React from "react";
import styled from "styled-components";

// context
import { useManageTags } from "core/ManageTags/context/useManageTags.js";

// styles
const AddTagButtonStyled = styled.div``;

// components
import Button from "core/Button";

// component
export const AddTagButton = () => {
    const {
        id,
        buttonText,
        state: { enabled }
    } = useManageTags();
    return (
        <AddTagButtonStyled data-testid={`add-tag-submit-button-${id}`}>
            <Button size="sm" type="submit" isDisabled={!enabled.add}>
                {buttonText}
            </Button>
        </AddTagButtonStyled>
    );
};

export default AddTagButton;
