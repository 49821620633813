import React, { useLayoutEffect } from "react";

import { afterSocialAuth } from "frontend/hooks/useAuth";

const SocialLoginSuccessPage = () => {
    // notify parent window & close this window
    useLayoutEffect(() => afterSocialAuth({ provider: "facebook" }), []);
    return null;
};

export default SocialLoginSuccessPage;
