import { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";

// URLSearchParams returns an iterator of decoded [key,value] tuples
// paramsToObject converts this tuple to js object literal
const paramsToObject = entries => {
    const result = {};
    for (const [key, value] of entries) {
        // each 'entry' is a [key, value] tupple
        result[key] = value;
    }
    return result;
};

/**
 * useUrlSearchParams is used to simplify interacting with react Router Dom URL search params
 * @returns searchParams: holds the URLSearchParams converted to JS object literal
 * @returns setUrlSearchParams: sets UrlParam in URL bar
 * NOTE: The intention is to rely on the URL as the single source of truth,
 * which is why `setUrlSearchParams` does not change the searchParams useState
 * Instead, we update searchParams by listening to changes in URL
 */
export const useUrlSearchParams = () => {
    const [searchParams, setSearchParams] = useState({});
    const history = useHistory();
    const location = useLocation();

    if (!history || !location) {
        console.error(
            "useUrlSearchParams can only be used inside of React Router dom."
        );
    }

    const setUrlSearchParams = params => {
        let errors = false;

        const urlSearchParams = new URLSearchParams(location.search);

        params.forEach(p => {
            const { key, value } = p;
            if (!key) {
                errors = true;
                console.warn(
                    `You must provide a key to setUrlSearchParam key: ${key}`
                );
                return false;
            }
            urlSearchParams.set([key], value);
        });

        if (!errors) {
            history.replace(`${location.pathname}?${urlSearchParams}`);
        }
    };

    // whenever a change to the URL occurs, we recalculate the searchParams state object literal
    useEffect(() => {
        let params = new URLSearchParams(location.search);
        params = paramsToObject(params.entries());
        setSearchParams(params);
    }, [location]);

    return {
        searchParams,
        setUrlSearchParams
    };
};
