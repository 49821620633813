import styled from "styled-components";
import { Link } from "react-router-dom";

// styles
import colors from "core/colors";
import { convertPxToRem } from "frontend/components/helpers";
import { getLinkElementStyles } from "core/Link/styles";

// components
import { H1 } from "core/Typography/index.js";

// styled-components
export const MastheadStyled = styled.div`
    border-bottom: solid 1px ${colors.primary.scheifer};
    padding: ${convertPxToRem(24)};
`;

export const BreadCrumbLinkStyled = styled(Link)`
    display: inline-block;
    margin-bottom: ${convertPxToRem(18)};

    ${({ display, isButton, size, darkMode, styleType, disableVisitedStyle }) =>
        getLinkElementStyles({
            display,
            isButton,
            size,
            darkMode,
            styleType,
            disableVisitedStyle
        })}
`;

export const PageTitleStyled = styled(H1)`
    font-size: ${convertPxToRem(23)};
    margin-bottom: ${convertPxToRem(28)};
`;

export const PageMenuUiStyled = styled.div`
    display: grid;
    grid-template-columns: auto ${convertPxToRem(266)} ${convertPxToRem(89)};
    gap: ${convertPxToRem(20)};

    min-height: ${convertPxToRem(48)};
`;
