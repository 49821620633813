import React from "react";
import PropTypes from "prop-types";

// components
import {
    InlineTextEdit,
    Checkbox,
    TextArea,
    SaveButton
} from "core/InlineTextEdit";

// styles
import colors from "core/colors";
import {
    ApproveButtonBelowStyled,
    ChkTxtWrapperStyled,
    SaveWrapperStyled
} from "./styles.js";

const ApproveButtonBelow = ({
    inlineTextHandlers,
    ariaLabel,
    isDisabled = false,
    placeholder
}) => {
    const {
        id,
        text,
        onSave,
        isApproved = false,
        onToggleApprove
    } = inlineTextHandlers;

    const checkboxStyles = {
        default: colors.primary.snow,
        checked: colors.alternative.lightMode.shamrock,
        disabled: colors.secondary.dolphin
    };

    return (
        <ApproveButtonBelowStyled>
            <InlineTextEdit
                {...{ id, text, onSave, disabled: isDisabled, placeholder }}
                ariaLabel={ariaLabel}
            >
                <div>
                    <ChkTxtWrapperStyled>
                        <Checkbox
                            {...{ isApproved, onToggleApprove, checkboxStyles }}
                        />
                        <TextArea />
                    </ChkTxtWrapperStyled>
                    <SaveWrapperStyled>
                        <SaveButton ariaLabel="Approve">Approve</SaveButton>
                    </SaveWrapperStyled>
                </div>
            </InlineTextEdit>
        </ApproveButtonBelowStyled>
    );
};

export default ApproveButtonBelow;

// prop-types
ApproveButtonBelow.propTypes = {
    inlineTextHandlers: PropTypes.object.isRequired,
    ariaLabel: PropTypes.string.isRequired,
    isDisabled: PropTypes.bool,
    placeholder: PropTypes.string.isRequired
};
