import styled, { css } from "styled-components";
import colors from "core/colors";
import { focusOutline } from "core/links";
import BodyText from "core/BodyText";
import Location from "core/ReusableIcons/Location";
import { convertPxToRem } from "frontend/components/helpers";
import { rapidEasing } from "core/animation";
import { SHARED_FORM_STYLES } from "core/Form";

export const LocationContainer = styled.div``;

export const Input = styled.input``;

export const IconLeftContainer = styled.div`
    position: absolute;
    left: ${convertPxToRem({ px: 8 })};
    z-index: 1;
    margin-top: ${convertPxToRem({ px: 5 })};
`;

export const LocationInputWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;

    ${Input} {
        ${SHARED_FORM_STYLES.BASE}
        margin-bottom: 0;
        padding-top: 0;
        padding-bottom: 0;
        width: 100%;
        position: relative;
        padding-left: 2rem;

        &:focus {
            outline: 0;
            ${SHARED_FORM_STYLES.FOCUS}
        }

        &:hover {
            ${SHARED_FORM_STYLES.HOVER}
        }

        &::placeholder {
            ${SHARED_FORM_STYLES.PLACEHOLDER}
        }

        &:read-only {
            &:hover {
                ${SHARED_FORM_STYLES.BASE}
                margin-bottom: 0;
                width: 100%;
                position: relative;
            }
            &::placeholder {
                color: ${colors.secondary.orca};
            }
        }
    }
`;

export const LocationDropdownBox = styled.div`
    z-index: 1;
    position: absolute;
    background: ${colors.primary.snow};
    width: 100%;
    box-shadow: 0.0625rem 0.0625rem 0.25rem rgba(0, 0, 0, 0.1);

    // only show focus on keyboard not with mouse:
    &:focus:not(.focus-visible) {
        outline: none;
        border-radius: 0;
    }

    &.focus-visible,
    &:focus-visible {
        border: none;
        ${focusOutline};
    }
`;

export const LocationDropdownContainer = styled.div`
    position: relative;
`;

export const LocationListItem = styled.li`
    padding: 12px;
    cursor: pointer;
    background-color: #fff;

    ${({ isHovered }) =>
        isHovered &&
        css`
            background-color: #000;

            ${BodyText} {
                color: #fff;
            }
        `}

    &:hover {
        background-color: #000;

        ${BodyText} {
            color: #fff;
        }
    }
`;

export const LocationIcon = styled(Location).attrs({
    fillColor: colors.secondary.oolong
})`
    width: ${convertPxToRem({ px: 16 })};
    height: ${convertPxToRem({ px: 16 })};
    margin-right: 16px;

    path {
        transition: all ${rapidEasing};
    }
`;
