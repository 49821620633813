import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import BodyText from "core/BodyText";

import { convertPxToRem } from "frontend/components/helpers";

export const ErrorContainer = styled.div`
    &:not(:last-of-type) {
        margin-bottom: ${convertPxToRem({ px: 24 })};
    }
`;

const ErrorItem = ({ uploadName, errorMessage }) => (
    <ErrorContainer>
        <BodyText>{uploadName}</BodyText>
        <br />
        <BodyText>
            {errorMessage || "Upload failed, please try again."}
        </BodyText>
    </ErrorContainer>
);

ErrorItem.propTypes = {
    uploadName: PropTypes.string.isRequired,
    errorMessage: PropTypes.string
};

export default ErrorItem;
