// Allowed WS Responses
// These can be partial matches
const ALLOWED = {
    batchReassigned: {
        event: "submissions.PhotoBatchModerationNote",
        actions: [
            // current user receives - the one who initated the action
            "released claim on batch",
            "claimed batch",
            // other moderator's receive:
            "assigned batch to",
            "removed assigned moderator"
        ]
    }
};

/**
 * isAllowedWsResponseCheck
 * @param {Object} allowed from ALLOWED constants
 * @param {String} eventType
 * @param {String} action
 * @returns {Boolean}
 */
const isAllowedWsResponseCheck = (allowed, eventType, action) => {
    const isEvent = eventType.includes(allowed.event);
    const isAction = allowed.actions.some(a => {
        return action.includes(a);
    });

    return isEvent && isAction;
};

/**
 * batchHasBeenReassignedCheck
 * we only want to refetch batches if the WS was triggered by a different moderator, unless they have multiple tabs open
 * @param {String} eventType
 * @param {Object} data Websocket Data
 * @param {Object} myModProfile Currently Logged in Moderator
 * @param {Boolean} multipleTabsAreOpen
 * @returns
 */
const batchHasBeenReassignedCheck = (
    eventType,
    data,
    myModProfile,
    multipleTabsAreOpen
) => {
    const { action = "", actor = {} } = data;
    const { moderatorId: currentUserId } = myModProfile;
    const actorId = actor?.id;

    // should this user refetch?
    const isCurrentUser = actorId === currentUserId;
    const thisUserShouldRefetch = !multipleTabsAreOpen && !isCurrentUser;

    // is this an allowed event and action?
    const isAllowedWsResponse = isAllowedWsResponseCheck(
        ALLOWED.batchReassigned,
        eventType,
        action
    );

    return thisUserShouldRefetch && isAllowedWsResponse;
};

/**
 * refetchBatchesCheck
 * used to determine if batches need to be refetched
 * @param {String} eventType
 * @param {Object} data Websocket Data
 * @param {Object} myModProfile Currently Logged in Moderator
 * @param {Boolean} multipleTabsAreOpen
 * @returns
 */
export const refetchBatchesCheck = ({
    eventType,
    data = {},
    myModProfile = {},
    multipleTabsAreOpen
}) => {
    return batchHasBeenReassignedCheck(
        eventType,
        data,
        myModProfile,
        multipleTabsAreOpen
    );
};
