import React from "react";
import PropTypes from "prop-types";

// styles
import { BatchProfileRow2Styled } from "./styles";

// components
import ContentComponentRow from "frontend/components/fort/mod/core/Batch/ContentComponents/ContentComponentRow";
import PhotoShootOrIndividual from "./PhotoShootOrIndividual";

const PhotoModBatchProfileRow2 = ({ batch }) => {
    return (
        <BatchProfileRow2Styled>
            <ContentComponentRow>
                <PhotoShootOrIndividual batch={batch} />
            </ContentComponentRow>
        </BatchProfileRow2Styled>
    );
};

export default PhotoModBatchProfileRow2;

// prop-types
PhotoModBatchProfileRow2.propTypes = {
    batch: PropTypes.object.isRequired
};
