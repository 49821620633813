import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { useViewport } from "js/hooks/useViewport";
import breakpoints from "core/breakpoints";

import { LingoAd, AdContainer } from "./styles";
import SquarespaceAd from "core/SquarespaceAd";

let Ad = ({ className }) => {
    const { browserWidth } = useViewport();
    const onMobile = browserWidth < breakpoints.tablet.px;

    return (
        <AdContainer className={className}>
            {onMobile ? <LingoAd /> : <SquarespaceAd />}
        </AdContainer>
    );
};

Ad.propTypes = {
    className: PropTypes.string
};

Ad = styled(Ad)``;
export default Ad;
