import React from "react";

import { SquareGridContainer, SquareGridRow, SquareGridItem } from "./styles";

export const buildGrid = ({
    className,
    numberOfColumns,
    gridItemContent,
    gridSpacingSize,
    footerBottomHeight
}) => {
    if (!numberOfColumns) return null;

    const grid = build();
    return grid;

    function build() {
        let grid = [];
        let currentRow = [];

        gridItemContent.forEach(content => {
            const rowIsFull = currentRow.length === numberOfColumns;
            if (rowIsFull) addRowToGrid();

            addItemToCurrentRow();

            function addRowToGrid() {
                grid.push(
                    <SquareGridRow key={grid.length}>
                        {currentRow}
                    </SquareGridRow>
                );
                currentRow = [];
            }

            function addItemToCurrentRow() {
                currentRow.push(
                    <SquareGridItem
                        key={currentRow.length}
                        data-testid="SquareGrid-item"
                    >
                        {content}
                    </SquareGridItem>
                );
            }
        });

        const lastRowIsNotFull = !!currentRow.length;
        if (lastRowIsNotFull) {
            const lastRow = buildLastRow();
            grid.push(lastRow);
        }

        grid = (
            <SquareGridContainer
                data-testid="SquareGrid"
                className={className}
                gridSpacingSize={gridSpacingSize}
                footerBottomHeight={footerBottomHeight}
            >
                {grid}
            </SquareGridContainer>
        );
        return grid;

        function buildLastRow() {
            let placeholderGridItems;
            let lastRow;

            placeholderGridItems = getPlaceholderGridItems();
            lastRow = [...currentRow, ...placeholderGridItems];
            lastRow = (
                <SquareGridRow key={grid.length}>{lastRow}</SquareGridRow>
            );

            return lastRow;
        }

        function getPlaceholderGridItems() {
            const array = [];
            const numberOfRemainingSpaces = numberOfColumns - currentRow.length;

            while (array.length < numberOfRemainingSpaces) {
                array.push(
                    <SquareGridItem key={currentRow.length + array.length} />
                );
            }

            return array;
        }
    }
};
