import gql from "graphql-tag";

export const GET_TOTAL_COUNTS_FOR_SEARCH_TYPES = gql`
    query totalCountsForSearchTypes(
        $query: String!
        $limit: Int = 0
        $offset: Int = 0
    ) {
        collections: collectionSearch(query: $query) {
            totalCount
        }
        icons: iconSearch(query: $query, limit: $limit, offset: $offset) {
            totalCount
        }
        photos: photoSearch(query: $query) {
            totalCount
        }
    }
`;
