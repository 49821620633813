import gql from "graphql-tag";

export const WITHDRAW_PHOTO_BATCH = gql`
    mutation withdrawPhotoBatch {
        withdrawPhotoBatch {
            ok
            error
        }
    }
`;
