import React from "react";
import PropTypes from "prop-types";

// context
import { usePanelOverlay } from "../usePanelOverlay";

// components
import { UnstyledButton } from "core/Button/styles.js";

// component
export const OpenButton = ({ children }) => {
    const { open } = usePanelOverlay();

    return <UnstyledButton onClick={open}>{children}</UnstyledButton>;
};

export default OpenButton;

// prop-types
OpenButton.propTypes = {
    children: PropTypes.any
};
