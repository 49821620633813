import { css } from "styled-components";
import { convertPxToRem } from "frontend/components/helpers";

/*
    Each Gutter represents a split shared by columns
    1 gutter, 2 columans: column [gutter] column
    2 gutter, 3 columans: column [gutter] column [gutter]] column

    Formula: gutterWidth * numCols / numGutters

    For example:

    1 column = 16 * 1 / 2
    2 column = 16 * 2 / 3
*/
export const getFlexWidthFixedGutter = (numCols, gutter) => {
    const numGutters = numCols - 1;
    const percent = `${100 / numCols}%`;
    const gapOffset = convertPxToRem((gutter * numGutters) / numCols);

    return css`
        width: calc(${percent} - ${gapOffset});
    `;
};
