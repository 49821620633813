import React from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";

import LinkInNewRouter from "core/LinkInNewRouter";

import { GET_CREATORS_TOTAL_PHOTO_COUNT } from "./queries";
import { BROWSE_BY_CREATOR_USERNAME } from "data/links";

const LinkToApprovedPhotos = ({ creatorUsername }) => {
    const { data, loading } = useQuery(GET_CREATORS_TOTAL_PHOTO_COUNT);

    const { publishedPhotos } = !!data && data;
    const { totalCount } = !!publishedPhotos && publishedPhotos;

    if (loading || !totalCount) return null;

    return (
        <LinkInNewRouter
            to={BROWSE_BY_CREATOR_USERNAME.replace(
                ":username",
                creatorUsername
            )}
            disableVisitedStyle
        >
            {totalCount === 1
                ? "See 1 approved photo"
                : `See all ${totalCount} approved photos`}
        </LinkInNewRouter>
    );
};

LinkToApprovedPhotos.propTypes = {
    creatorUsername: PropTypes.string.isRequired
};

export default LinkToApprovedPhotos;
