import React from "react";
import PropTypes from "prop-types";

import PhotoModUploadImage from "frontend/components/fort/mod/photos/components/PhotoModUpload/UploadImage.js";

const PhotoModLargeUploadImage = ({ uploadId }) => {
    return <PhotoModUploadImage uploadId={uploadId} isLarge={true} />;
};

export default PhotoModLargeUploadImage;

// prop-types
PhotoModLargeUploadImage.propTypes = {
    uploadId: PropTypes.string.isRequired
};
