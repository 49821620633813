import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import SquareGrid from "icons/SquareGrid";

import { formatDataAsGridContent } from "./helpers";
import { convertPxToRem } from "frontend/components/helpers";

let IconGrid = ({
    className,
    iconData = [],
    numberOfColumns = 5,
    gridSpacingSize = convertPxToRem({ px: 24 })
}) => {
    return (
        <SquareGrid
            data-testid="icon-grid"
            className={className}
            gridItemContent={formatDataAsGridContent({
                iconData
            })}
            numberOfColumns={numberOfColumns}
            gridSpacingSize={gridSpacingSize}
        />
    );
};

IconGrid = styled(IconGrid)``;

IconGrid.propTypes = {
    className: PropTypes.string,
    iconData: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
            title: PropTypes.string,
            thumbnails: PropTypes.shape({
                thumbnail200: PropTypes.string.isRequired
            })
        })
    ).isRequired,
    numberOfColumns: PropTypes.number.isRequired,
    gridSpacingSize: PropTypes.string.isRequired
};

export default IconGrid;
