import React from "react";

import AddIcon from "core/ReusableIcons/Add";
import { ToolbarButton } from "./styles";

import usePhotoUploadDropzone from "../PhotoUploadCore/hooks/usePhotoUploadDropzone";

const AddPhotosButton = () => {
    const { dropzoneProps } = usePhotoUploadDropzone();

    const {
        openFilePicker,
        filesInDropzoneCount,
        initialMaximumFiles
    } = dropzoneProps;

    return (
        <ToolbarButton
            styleType="outline"
            tabIndex={0}
            isDisabled={filesInDropzoneCount >= initialMaximumFiles}
            onClick={openFilePicker}
        >
            <AddIcon />
            Add Photos
        </ToolbarButton>
    );
};

export default AddPhotosButton;
