import React from "react";

import LinkInNewRouter from "core/LinkInNewRouter";

import { PHOTO_UPLOAD } from "data/links";

const UploadPhotosLink = () => (
    <LinkInNewRouter to={PHOTO_UPLOAD} disableVisitedStyle>
        Upload photos
    </LinkInNewRouter>
);

export default UploadPhotosLink;
