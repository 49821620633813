import { useContext } from "react";

import ManageTagsContext from "./index";
import { actions } from "./actions";

/*---------------------------
| useManageTags
---------------------------*/
export const useManageTags = () => {
    const { state, dispatch, nonStateProps } = useContext(ManageTagsContext);

    const clearErrorMessage = () => {
        dispatch(actions.setErrorMessage(""));
    };

    return {
        state,
        dispatch,
        actions,
        clearErrorMessage,
        ...nonStateProps
    };
};
