import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Modal from "core/Modal";
import Button from "core/Button";

import { convertPxToRem } from "frontend/components/helpers";
import { ModalChildrenContainer } from "../PhotoUpload/styles";

import { useWithdrawPhotoUpload } from "frontend/components/creator-tools/PhotoUploadPage/PhotoUploadCore/hooks";

export const DeleteButton = styled(Button)`
    margin-right: ${convertPxToRem({ px: 24 })};
`;

const DeletePhotosModal = ({
    onClose,
    numPhotosToDelete,
    isDeletePhotoModalOpen,
    uploadsSelected,
    getSelectedUploadIds,
    deselectAll
}) => {
    const isQuantityMultiple = numPhotosToDelete > 1;

    const headingSingle = "Are you sure you want to delete this photo?";
    const headingMultiple = `Are you sure you want to delete ${numPhotosToDelete} photos?`;

    const heading = isQuantityMultiple ? headingMultiple : headingSingle;

    const buttonText = `Yes, delete photo${isQuantityMultiple ? "s" : ""}`;

    // WITHDRAW PHOTO MUTATION
    const { withdrawPhotos } = useWithdrawPhotoUpload();

    const handleDeleteSelected = () => {
        let currentlyCheckedPhotoIds = getSelectedUploadIds(uploadsSelected);
        deselectAll();
        onClose();
        withdrawPhotos({
            variables: { photoUploadIds: currentlyCheckedPhotoIds }
        });
    };

    const footerChildren = (
        <>
            <DeleteButton onClick={handleDeleteSelected}>
                {buttonText}
            </DeleteButton>
            <Button onClick={onClose} styleType={"outline"}>
                Cancel
            </Button>
        </>
    );

    return (
        <Modal
            open={isDeletePhotoModalOpen}
            onClose={onClose}
            handleClose={onClose}
            heading={heading}
        >
            <ModalChildrenContainer>{footerChildren}</ModalChildrenContainer>
        </Modal>
    );
};

DeletePhotosModal.propTypes = {
    numPhotosToDelete: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
    isDeletePhotoModalOpen: PropTypes.bool.isRequired,
    uploadsSelected: PropTypes.object.isRequired,
    getSelectedUploadIds: PropTypes.func.isRequired,
    deselectAll: PropTypes.func.isRequired
};

export default DeletePhotosModal;
