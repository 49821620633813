import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { useViewport } from "assets/js/hooks/useViewport";
import breakpoints from "core/breakpoints";
import { getPath } from "./helpers";

import Loading from "./Loading";
import MobileError from "./Status/Mobile";
import MaxQueueError from "./Status/MaxQueue";
import MaxTimeError from "./Status/MaxTime";
import AccountError from "./Status/Account";
import PhotoUploadView from "./PhotoUploadView";
import {
    LOADING_STATUS,
    MAX_QUEUE_STATUS,
    MAX_TIME_STATUS,
    INCOMPLETE_ACCOUNT_STATUS,
    usePhotoSubmissionStatus
} from "frontend/components/creator-tools/PhotoUploadPage/PhotoUploadCore/hooks/usePhotoSubmissionStatus";

const PhotoUploadPage = () => {
    const history = useHistory();
    const { browserWidth } = useViewport();
    const isMobile = browserWidth < breakpoints.tablet.px;

    const {
        currentStatus,
        account,
        uploadStatusMessage
    } = usePhotoSubmissionStatus();

    // NOTE: We update the URL for google analytics tracking
    useEffect(() => {
        const desiredPath = getPath(currentStatus);

        if (desiredPath && history.location.pathname !== desiredPath) {
            window.history.replaceState(null, null, desiredPath); // update URL without rendering component again
        }
    }, [currentStatus]);

    // Mobile check
    if (isMobile) {
        return <MobileError />;
    }

    switch (currentStatus) {
        case LOADING_STATUS:
            return <Loading />;
        case MAX_QUEUE_STATUS:
            return <MaxQueueError />;
        case MAX_TIME_STATUS:
            return <MaxTimeError uploadStatusMessage={uploadStatusMessage} />;
        case INCOMPLETE_ACCOUNT_STATUS:
            return (
                <AccountError
                    missingName={!account.hasFullName}
                    missingEmail={!account.hasVerifiedEmail}
                />
            );
        default:
            return <PhotoUploadView />;
    }
};

export default PhotoUploadPage;
