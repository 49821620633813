import React from "react";
import styled from "styled-components";

// context
import { useSiteHeader } from "core/SiteHeaderV2/context/useSiteHeader.js";

// data
import { primaryMenu } from "data/links";

// components
import Link from "./Link.js";

// styles
import colors from "core/colors";
import { convertPxToRem } from "frontend/components/helpers";

const PricingLinkStyled = styled(Link)`
    border: none;
    color: ${({ $isDarkMode }) =>
        $isDarkMode
            ? colors.accent.darkMode.surf
            : colors.accent.lightMode.persian};
    margin: 0rem ${convertPxToRem(16)} 0rem 0rem;
`;

// component
export const PricingLink = () => {
    const { isDarkMode } = useSiteHeader();
    const { pricing } = primaryMenu.products.links;
    return <PricingLinkStyled link={pricing} $isDarkMode={isDarkMode} />;
};

export default PricingLink;
