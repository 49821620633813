import styled from "styled-components";

import Flexbox from "core/Flexbox";
import breakpoints from "core/breakpoints";

import { convertPxToRem } from "frontend/components/helpers";

export const PetContainer = styled(Flexbox).attrs({
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap"
})`
    padding: ${convertPxToRem({ px: 8 })};
    width: 25%;

    /* Mobile: */
    @media (max-width: ${breakpoints.beforeTablet.rem}) {
        width: 50%;
        // random edge case - since we dont listen for browser width changes after load
        // there is a chance a user could be on desktop view and see 4 pets then move to a mobile
        // width and still 4 pets which causes wonky css. This selects the 3,4 child and hides it
        &:nth-child(n + 3) {
            display: none;
        }
    }
`;

export const PetImage = styled.img`
    border-radius: 50%;
    width: ${convertPxToRem({ px: 100 })};
    height: ${convertPxToRem({ px: 100 })};
`;
