import gql from "graphql-tag";

export const GET_FEATURED_ICONS_DATA = gql`
    query featuredIcons($limit: Int, $offset: Int) {
        featuredIcons(limit: $limit, offset: $offset) {
            items {
                id
                thumbnails {
                    thumbnail200
                }
                url
                title
            }
            totalCount
        }
    }
`;
