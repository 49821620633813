import styled, { css } from "styled-components";
import PropTypes from "prop-types";

import { ProfileMenuDesktop } from "components/ProfileMenuDesktop";
import ProfileMenuMobile from "components/ProfileMenuMobile";

import { openMobileMenuStyles } from "components/ProfileMenuMobile/styles";
import Button from "core/Button";
import { STYLE_MODES } from "core/Button/styles";

import { easingSeconds } from "core/animation";
import Flexbox from "core/Flexbox";
import breakpoints from "core/breakpoints";

import { convertPxToRem } from "frontend/components/helpers";

// for LoggedOutLinks
export const LoggedOutFlexbox = styled(Flexbox)``;

export const Join = styled(Button).attrs({ size: "sm" })`
    margin-left: ${convertPxToRem({ px: 8 })};

    ${({ isHeroSearchV2, isScrolledDown }) => {
        if (isHeroSearchV2) {
            return isScrolledDown
                ? STYLE_MODES.SOLID.LIGHTMODE
                : STYLE_MODES.SOLID.DARKMODE;
        }
    }}

    @media (max-width: ${breakpoints.beforeTablet.rem}) {
        display: none;
    }
`;

export const Login = styled(Button).attrs({
    size: "sm",
    styleType: "text"
})`
    ${({ isHeroSearchV2, isScrolledDown }) => {
        if (isHeroSearchV2) {
            return isScrolledDown
                ? STYLE_MODES.TEXT.LIGHTMODE
                : STYLE_MODES.TEXT.DARKMODE;
        }
    }}
`;

// for LoggedInLinks
export const AvatarWrapper = styled.div`
    cursor: pointer;
    width: 1.5rem;
    height: 1.5rem;
`;

const UserAvatar = styled.img`
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    opacity: ${({ active }) => (active ? "0.6" : "1")};
    vertical-align: middle;
    transition: all ${easingSeconds};

    &:hover {
        opacity: 0.6;
    }
`;

UserAvatar.propTypes = {
    active: PropTypes.bool
};

export { UserAvatar };

// for ProfileMenu
const Wrapper = styled.div`
    ${ProfileMenuDesktop} {
        /* toggle menu */
        display: ${({ open }) => (open ? "block" : "none")};

        /* hide menu on smaller screens */
        @media (max-width: ${breakpoints.beforeTablet.rem}) {
            display: none;
        }
    }

    ${ProfileMenuMobile} {
        display: none;

        @media (max-width: ${breakpoints.beforeTablet.rem}) {
            /* toggle menu  */
            ${({ open }) => open && openMobileMenuStyles}
        }
    }

    /* Tablet +: */
    @media (min-width: ${breakpoints.tablet.rem}) {
        position: relative;
        height: 28px;
        /* to prevent content bouncing when navigating from page to page */
    }
`;

Wrapper.propTypes = {
    open: PropTypes.bool
};

export { Wrapper };
