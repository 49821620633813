import React from "react";
import PropTypes from "prop-types";

// styles
import { ContentComponentRowStyled } from "./styles.js";

const ContentComponentRow = ({ children }) => {
    return <ContentComponentRowStyled>{children}</ContentComponentRowStyled>;
};

export default ContentComponentRow;

// prop-types
ContentComponentRow.propTypes = {
    children: PropTypes.any,
    count: PropTypes.number
};
