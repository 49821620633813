import React, { createContext, useContext, useEffect } from "react";

import { usePhotoUpload, hiddenStatuses } from "frontend/hooks/usePhotoUpload";
import { useDropzone } from "core/Dropzone/hooks/useDropzone";

import { usePhotoSubmissionStatus } from "./usePhotoSubmissionStatus";

export const PhotoUploadDropzoneContext = createContext();

export const PhotoUploadDropzoneProvider = ({ children }) => {
    const {
        uploadPhotos,
        setValidationErrors,
        photoUploads
    } = usePhotoUpload();
    const { batchPhotoSubmissionBalance } = usePhotoSubmissionStatus();

    const dropzoneProps = useDropzone({
        uploadFiles: uploadPhotos,
        handleRejections: validationErrors =>
            setValidationErrors(current => [...current, ...validationErrors]),
        filesInDropzoneCount: photoUploads.filter(
            u => !hiddenStatuses.includes(u.status)
        ).length,
        initialMaximumFiles: batchPhotoSubmissionBalance
    });

    const contextValues = {
        dropzoneProps
    };

    return (
        <PhotoUploadDropzoneContext.Provider value={contextValues}>
            {children}
        </PhotoUploadDropzoneContext.Provider>
    );
};

export const usePhotoUploadDropzone = () => {
    const context = useContext(PhotoUploadDropzoneContext);

    if (context === undefined) {
        throw new Error(
            "usePhotoUploadDropzone must be used within a PhotoUploadDropzoneProvider"
        );
    }

    return context;
};

export default usePhotoUploadDropzone;
