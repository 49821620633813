import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import {
    spacingSizes,
    headingSizes,
    alignments,
    headingTypes
} from "core/props";

import { StyledHeading } from "./styles";

const HeadingElement = ({
    children,
    className,
    type,
    size,
    topSpacing,
    bottomSpacing,
    headingColor,
    alignment
}) => (
    <StyledHeading
        className={className}
        as={type}
        size={size}
        topSpacing={topSpacing}
        bottomSpacing={bottomSpacing}
        headingColor={headingColor}
        alignment={alignment}
    >
        {children}
    </StyledHeading>
);

const Heading = styled(HeadingElement)``;

Heading.propTypes = {
    type: PropTypes.oneOf(headingTypes),
    size: PropTypes.oneOf(headingSizes),
    topSpacing: PropTypes.oneOf(spacingSizes),
    bottomSpacing: PropTypes.oneOf(spacingSizes),
    alignment: PropTypes.oneOf(alignments),
    headingColor: PropTypes.string
};

export default Heading;
