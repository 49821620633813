import React from "react";

import Grid from "core/Grid";
import SectionContainer from "core/SectionContainer";
import OffsetGrid from "core/OffsetGrid";

import colors from "core/colors";
import Flexbox from "core/Flexbox";
import {
    CtaSectionLingoLogo,
    CtaSectionPrimaryText,
    CtaSectionSecondaryText,
    HideTextOnTablet,
    CtaSectionButton,
    CtaSectionLogoAndTextContainer
} from "./styles";

const CtaSection = () => (
    <SectionContainer topSpacing="none" bottomSpacing="none">
        <OffsetGrid sm={10} md={12} lg={10}>
            <Grid
                alignItems="center"
                mobileSpacing={16}
                tabletSpacing={16}
                desktopSpacing={16}
            >
                <Grid item sm={12} lg={7} md={7}>
                    <CtaSectionLogoAndTextContainer>
                        <CtaSectionLingoLogo
                            fillColor={colors.functional.secondary.lingo}
                        />
                        <div>
                            <CtaSectionPrimaryText
                                type="h5"
                                size="sm"
                                alignment="left"
                            >
                                Try Lingo for Free
                            </CtaSectionPrimaryText>
                            <CtaSectionSecondaryText
                                type="h5"
                                size="sm"
                                alignment="left"
                            >
                                Fully featured 30-day free trial{" "}
                                <HideTextOnTablet>
                                    • No credit card required
                                </HideTextOnTablet>
                            </CtaSectionSecondaryText>
                        </div>
                    </CtaSectionLogoAndTextContainer>
                </Grid>
                <Grid item sm={12} lg={5} md={5}>
                    <Flexbox justifyContent="flex-end">
                        <CtaSectionButton
                            target="_blank"
                            href="https://www.lingoapp.com/get-started/"
                        >
                            Get Started
                        </CtaSectionButton>
                        <CtaSectionButton
                            target="_blank"
                            href="https://www.lingoapp.com/"
                        >
                            Learn More
                        </CtaSectionButton>
                    </Flexbox>
                </Grid>
            </Grid>
        </OffsetGrid>
    </SectionContainer>
);

export default CtaSection;
