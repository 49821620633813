import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router";
import { Helmet } from "react-helmet";

import PhotoUploadSectionContainer from "../PhotoUploadCore/PhotoUploadSectionContainer";
import PhotoUploadTypeSelectMasthead from "./PhotoUploadTypeSelectMasthead";
import Link from "core/Link";
import RedirectOnInvalidReferrer from "../PhotoUploadCore/RedirectOnValidReferrer";

import breakpoints from "core/breakpoints";
import MobileError from "../Status/Mobile";

import { GuidelinesMessage } from "./styles";

import PhotoUploadProviders from "../PhotoUploadCore/PhotoUploadProviders";

import { usePhotoUpload } from "frontend/hooks/usePhotoUpload";

import { UPLOAD_TYPES } from "../PhotoUploadCore/helpers";
import { PHOTO_UPLOAD_INDIVIDUAL, PHOTO_UPLOAD_PHOTO_SHOOT } from "data/links";
import { updateQueryParams, useCurrentUploadType } from "./helpers";
import { useViewport } from "js/hooks/useViewport";

const PhotoUploadTypeSelectLayout = () => {
    const history = useHistory();
    const location = useLocation();
    const { setCurrentUploadType, currentUploadType } = useCurrentUploadType(
        location
    );
    const { createPhotoBatch, createPhotoShoot } = usePhotoUpload();
    const [isLoading, setIsLoading] = useState(false);

    const { browserWidth } = useViewport();
    const isMobile = browserWidth < breakpoints.tablet.px;

    useEffect(() => {
        updateQueryParams(history, location, currentUploadType);
    }, [currentUploadType]);

    const handleSubmit = async e => {
        setIsLoading(true);
        await createPhotoBatch();
        if (currentUploadType === UPLOAD_TYPES.PHOTO_SHOOT) {
            await createPhotoShoot();
            setIsLoading(false);
            history.push(PHOTO_UPLOAD_PHOTO_SHOOT);
        } else {
            setIsLoading(false);
            history.push(PHOTO_UPLOAD_INDIVIDUAL);
        }
    };

    return (
        <>
            <RedirectOnInvalidReferrer />
            {isMobile ? (
                <MobileError />
            ) : (
                <>
                    <PhotoUploadSectionContainer>
                        <PhotoUploadTypeSelectMasthead
                            setCurrentUploadType={setCurrentUploadType}
                            currentUploadType={currentUploadType}
                            handleSubmit={handleSubmit}
                            isLoading={isLoading}
                        />
                        <GuidelinesMessage>
                            For more information, please read our{" "}
                            <Link
                                href="https://brand.thenounproject.com/k/Noun-Project-Photographer-Guide-P9J8Wn"
                                target="_blank"
                            >
                                photographer guide
                            </Link>
                            .
                        </GuidelinesMessage>
                    </PhotoUploadSectionContainer>
                </>
            )}
        </>
    );
};

const PhotoUploadTypeSelect = () => {
    return (
        <PhotoUploadProviders>
            <Helmet>
                <title>Submit Photos | Noun Project</title>
                <meta
                    name="description"
                    content="Contribute by submitting your photos to Noun Project. Get started here."
                />
            </Helmet>
            <RedirectOnInvalidReferrer />
            <PhotoUploadTypeSelectLayout />
        </PhotoUploadProviders>
    );
};

export default PhotoUploadTypeSelect;
