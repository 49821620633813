import React from "react";
import { Helmet } from "react-helmet";

import colors from "core/colors";

import { Container, Title, Buttons, IconContainer } from "./styles";
import SubmissionStatusButton from "./SubmissionStatusButton";
import CheersIcon from "core/IllustrativeIcons/Cheers";

const Confirmation = () => (
    <Container>
        <Helmet>
            <title>Submit Photos - Confirmation | Noun Project</title>
            <meta
                name="description"
                content="Your photos have been submitted to Noun Project."
            />
        </Helmet>
        <Title>
            Cheers! We just need to take a look at your photos before
            they&rsquo;re approved.
        </Title>
        <Buttons>
            <SubmissionStatusButton />
        </Buttons>
        <IconContainer>
            <CheersIcon fillColor={colors.accent.lightMode.pansy} />
        </IconContainer>
    </Container>
);

export default Confirmation;
