import querystring from "query-string";
import _ from "lodash";

import { makeTitleCase } from "../../components/helpers";

export const getButtonText = ({
    limit,
    contentType,
    totalCount,
    currentPage
}) => {
    let remainingNum;
    let formattedContentType;

    const currentIconsShown = limit * currentPage;
    const howManyResultsLeft = totalCount - currentIconsShown;

    if (howManyResultsLeft < limit) {
        remainingNum = howManyResultsLeft;
    } else {
        remainingNum = limit;
    }

    if (remainingNum === 1) {
        formattedContentType = makeTitleCase(contentType);
    } else {
        formattedContentType = `${makeTitleCase(contentType)}s`;
    }

    return `Load Next ${remainingNum} ${formattedContentType}`;
};

export const getPaginationOffset = ({ page, limit }) => {
    return (page - 1) * limit;
};

export const getItemsFromData = ({ data, pathToListKey }) =>
    _.get(data, pathToListKey);

export const getTotalItemCountFromData = ({ data, pathToTotalCountKey }) =>
    _.get(data, pathToTotalCountKey);

export const getPageQueryParamValue = ({
    routerLocation,
    pageQueryParamKey
}) => {
    const queryString = routerLocation.search;
    return +querystring.parse(queryString)[pageQueryParamKey] || 0;
};

export const getVariables = ({ page, queryVariables, limit }) => ({
    ...queryVariables,
    limit,
    offset: getPaginationOffset({ page, limit })
});

export const goToPage = ({
    page,
    pageQueryParamKey,
    sessionHistory,
    routerLocation
}) => {
    updateUrlForScrollPosition({
        sessionHistory,
        routerLocation,
        page,
        pageQueryParamKey
    });
};

export const updateDataWithNewPaginationItems = ({
    prevResult,
    fetchMoreResult,
    pathToListKey
}) => {
    const previousPaginationItems = getItemsFromData({
        data: prevResult,
        pathToListKey
    });
    const newPaginationItems = getItemsFromData({
        data: fetchMoreResult,
        pathToListKey
    });
    const updatedPaginationItems = [
        ...previousPaginationItems,
        ...newPaginationItems
    ];

    const updatedData = {
        ...fetchMoreResult
    };
    _.set(updatedData, pathToListKey, updatedPaginationItems);
    return updatedData;
};

export const updateUrlForScrollPosition = ({
    sessionHistory,
    routerLocation,
    page,
    pageQueryParamKey
}) => {
    const currentQueryStringObj = querystring.parse(routerLocation.search);
    const newQueryStringObj = {
        ...currentQueryStringObj,
        [pageQueryParamKey]: page
    };
    const newQueryString = querystring.stringify(newQueryStringObj);
    const updatedLocation = {
        ...routerLocation,
        search: newQueryString
    };

    sessionHistory.replace(updatedLocation);
};
