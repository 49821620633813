import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { checkboxButtonSizeTypes, checkboxButtonStyleTypes } from "core/props";

import ProcessingSpinner from "core/ProcessingSpinner";
import Checkmark from "core/ReusableIcons/Checkmark";
import Minus from "core/ReusableIcons/Minus";

import colors from "core/colors";
import {
    CheckboxButtonContainer,
    CheckboxContainer,
    Label,
    Input,
    LabelTextContainer
} from "./styles";

const UnstyledCheckboxButton = ({
    className,
    label,
    ariaId,
    isChecked,
    onClick,
    onChange,
    ariaLabel,
    inputValue,
    size = "lg",
    styleType = "default",
    darkMode = false,
    isDisabled = false,
    isProcessing = false,
    width = "auto",
    isIndeterminate = false,
    otherCheckboxInputProps
}) => {
    inputValue = inputValue || label;

    styleType = isProcessing ? "processing" : styleType;

    const interiorLabelComponent = isProcessing ? (
        <>
            Processing
            <ProcessingSpinner
                iconColor={
                    darkMode ? colors.primary.diamond : colors.secondary.orca
                }
            />
        </>
    ) : (
        <>
            <CheckboxContainer isChecked={isChecked}>
                {isChecked ? (
                    <Checkmark
                        fillColor={colors.primary.snow}
                        size={{ width: "10", height: "10" }}
                    />
                ) : isIndeterminate ? (
                    <Minus fillColor={colors.primary.snow} />
                ) : null}
            </CheckboxContainer>

            <LabelTextContainer isDisabled={isDisabled} isChecked={isChecked}>
                {label}
            </LabelTextContainer>
        </>
    );

    const handleClick = e => {
        if (isDisabled || isProcessing) {
            return e.preventDefault();
        }

        if (onClick) {
            onClick();
        }
    };

    const handleChange = () => {
        if (onChange) {
            onChange();
        }
    };

    const conditionalInputProps = (!isDisabled || !isProcessing) && {
        onClick: e => handleClick(e),
        onChange: handleChange
    };

    return (
        <CheckboxButtonContainer width={width} className={className}>
            <Input
                className={className}
                id={ariaId}
                aria-disabled={isDisabled || isProcessing}
                isDisabled={isDisabled}
                isProcessing={isProcessing}
                isChecked={isChecked}
                styleType={styleType}
                darkMode={darkMode}
                type="checkbox"
                value={inputValue}
                checked={isChecked}
                aria-label={ariaLabel}
                {...conditionalInputProps}
                {...otherCheckboxInputProps}
            />

            <Label
                htmlFor={ariaId}
                className={className}
                isIndeterminate={isIndeterminate}
                isDisabled={isDisabled}
                isProcessing={isProcessing}
                isChecked={isChecked}
                size={size}
                styleType={styleType}
                darkMode={darkMode}
            >
                {interiorLabelComponent}
            </Label>
        </CheckboxButtonContainer>
    );
};

const CheckboxButton = styled(UnstyledCheckboxButton)``;

CheckboxButton.propTypes = {
    label: PropTypes.any.isRequired,
    ariaId: PropTypes.string.isRequired,
    isChecked: PropTypes.bool.isRequired,
    onClick: PropTypes.func,
    ariaLabel: PropTypes.string,
    inputValue: PropTypes.string,
    size: PropTypes.oneOf(checkboxButtonSizeTypes),
    styleType: PropTypes.oneOf(checkboxButtonStyleTypes),
    darkMode: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isIndeterminate: PropTypes.bool,
    width: PropTypes.string
};

export default CheckboxButton;
