import { useDispatch, useSelector } from "react-redux";

// rtk
import { uploadsSlice } from "../slices/uploadsSlice";

// utils
import { enhanceUploads } from "./utils";

export const useApiUploadUpdate = () => {
    const dispatch = useDispatch();
    const { entities: batchEntities } = useSelector(state => state.batches);
    const { entities: uploadEntities } = useSelector(state => state.uploads);
    const { id: selectedBatchId } = useSelector(state => state.selectedBatch);
    const batchEntity = batchEntities[selectedBatchId];

    const apiUploadUpdate = ssResp => {
        // Payload should have photoUploads
        let uploads = ssResp?.data?.payload?.photoUploads || [];

        // extract `photoUpload` from response objects array
        uploads = uploads.map(({ photoUpload }) => {
            return { ...photoUpload };
        });

        /**
         * Some API calls do not have MOD data props in them
         * defer to what is in RTK if a prop is missing from API response
         */
        uploads = uploads.map(u => {
            const uploadEntity = uploadEntities[u.id];
            const moderationNotes =
                u.moderationNotes || uploadEntity?.moderationNotes || [];

            return {
                ...u,
                moderationNotes
            };
        });

        // enhance uploads
        uploads = enhanceUploads(uploads, batchEntity);

        // convert to RTK Entity Adapter Update Objects
        const uploadUpdateEntityObjects = uploads.map(u => {
            const { id, ...changesWithoutId } = u;

            return {
                id,
                changes: changesWithoutId
            };
        });

        // Update redux store
        dispatch(uploadsSlice.actions.updateUploads(uploadUpdateEntityObjects));
    };

    return {
        apiUploadUpdate
    };
};
