import { createSlice } from "@reduxjs/toolkit";

import { REDUCERS } from "../constants";

const initialState = {
    isActive: false,
    kind: "slideshow", // slideshow || single || gallery
    uploadIds: [],
    selectedUploadId: null
};

export const largeImageViewSlice = createSlice({
    name: REDUCERS.LARGE_IMAGE_VIEW,
    initialState,
    reducers: {
        open: (state, action) => {
            const { kind, uploadIds, selectedUploadId } = action.payload;
            state.isActive = true;
            state.kind = kind;
            state.uploadIds = uploadIds;
            state.selectedUploadId = selectedUploadId;
        },
        close: state => {
            state.isActive = false;
            state.uploadIds = [];
            state.selectedUploadId = null;
        }
    }
});

export default largeImageViewSlice.reducer;
