import React from "react";
import PropTypes from "prop-types";

// styles
import {
    SelectAllAndMessagingStyled,
    CheckboxButtonStyled,
    MessageStyled
} from "./styles";

export const SelectAllAndMessaging = ({
    selectMessage,
    showSelectAllCheckbox,
    allSelected,
    onClick
}) => {
    return (
        <SelectAllAndMessagingStyled
            showSelectAllCheckbox={showSelectAllCheckbox}
        >
            {showSelectAllCheckbox && (
                <CheckboxButtonStyled
                    label="All"
                    ariaId="select-all-uploads-toggle"
                    isChecked={allSelected}
                    onClick={onClick}
                />
            )}
            <MessageStyled>{selectMessage}</MessageStyled>
        </SelectAllAndMessagingStyled>
    );
};

// prop-types
SelectAllAndMessaging.propTypes = {
    selectMessage: PropTypes.string,
    allSelected: PropTypes.bool,
    onClick: PropTypes.func,
    showSelectAllCheckbox: PropTypes.bool.isRequired
};
