/*---------------------------
| Store
---------------------------*/
// Action Types
const actionPrefix = "SearchBarContext:";
export const actionTypes = {
    SET_DROP_DOWN: `${actionPrefix} setDropDown`,
    SET_SELECTED_OPTION: `${actionPrefix} setSelectedOption`,
    SET_INPUT_VALUE: `${actionPrefix} setInputValue`
};

// Reducer
export const reducer = (state, action) => {
    switch (action.type) {
        case actionTypes.SET_DROP_DOWN: {
            return {
                ...state,
                dropDown: action.dropDown
            };
        }
        case actionTypes.SET_SELECTED_OPTION: {
            return {
                ...state,
                dropDown: {
                    ...state.dropDown,
                    selectedOption: action.selectedOption
                }
            };
        }
        case actionTypes.SET_INPUT_VALUE: {
            return {
                ...state,
                inputValue: action.inputValue
            };
        }
        default: {
            return { ...state };
        }
    }
};
