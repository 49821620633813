import { useState, useEffect } from "react";

// hooks
import { useApolloMutation } from "frontend/utils/apolloApi/useApolloMutation.js";
import { useBatchApproval } from "../useBatchApproval";

// GQL
import { DENY_PHOTO_UPLOADS } from "frontend/components/fort/mod/photos/gql/mutations";

export const useDeny = ({ successTitle = "Photo denied" } = {}) => {
    // Hooks/State
    const { apolloMutate, loading } = useApolloMutation({
        actionDesc: "Deny Upload(s)",
        gql: DENY_PHOTO_UPLOADS
    });
    const [isEditable, setIsEditable] = useState(true);

    // Props
    const title = "Deny";

    const useBatchApprovalConfig = {
        title,
        onApprovalAction: async ({ photoUploadIds }) => {
            return await apolloMutate({ variables: { photoUploadIds } });
        },
        successTitle
    };

    const {
        selectedUploadIds,
        publishingIsEditable,
        decoratedOnApproval
    } = useBatchApproval(useBatchApprovalConfig);

    // side effects
    useEffect(() => {
        let isEditable =
            !loading && selectedUploadIds.length > 0 && publishingIsEditable;

        setIsEditable(isEditable);
    }, [selectedUploadIds, publishingIsEditable, loading]);

    return {
        onDeny: decoratedOnApproval,
        isEditable
    };
};
