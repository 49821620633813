import * as normalizr from "normalizr";
import * as normalizers from "./normalizers";

import getCookie from "../helpers/get_cookie";

const csrfSafeMethod = function(method) {
    return /^(GET|HEAD|OPTIONS|TRACE)$/.test(method);
};

function callApi(
    service,
    endpoint,
    schema,
    method = "GET",
    data = null,
    formData = null,
    binary,
    done,
    error
) {
    let requestBody = null,
        serviceRoot;

    switch (service) {
        case "api":
            serviceRoot = "/fort/modparty/api_proxy/";
            break;
        case "api_gql":
            serviceRoot = "/fort/modparty/";
            break;
        case "www":
            serviceRoot = "/";
            break;
        case "raw":
            serviceRoot = "";
            break;
    }

    const fullUrl = serviceRoot + endpoint;

    var xhr = new XMLHttpRequest();
    xhr.open(method, fullUrl, true);
    if (service !== "raw") {
        xhr.withCredentials = true;
    } else {
        xhr.withCredentials = false;
    }
    xhr.onload = function() {
        let response;
        if (service !== "raw") {
            try {
                response = JSON.parse(this.responseText);
            } catch (err) {
                console.warn(fullUrl, "JSON parse failed", err);
                return error({
                    code: this.status,
                    message: "Something went wrong."
                });
            }
        } else {
            if (binary) {
                var arr = new Uint8Array(this.response);

                // String.fromCharCode returns a 'string' from the specified sequence of Unicode values
                // but has a maximum number fo arguments, so reduce the array item by item
                response = arr.reduce(
                    (data, byte) => data + String.fromCharCode(byte),
                    ""
                );
            } else {
                response = this.responseText;
            }
        }

        if (this.status >= 400 || response.errors) {
            if (!response.errors) {
                let messageForUser = response.form_errors || response.error;
                return error({ code: this.status, message: messageForUser });
            } else {
                return error({
                    code: response.errors.error.code,
                    message: response.errors.error.message
                });
            }
        }

        if (schema) {
            let normalizedResponse = Object.assign(
                {},
                schema.normalizer(response, schema.schema)
            );
            done(normalizedResponse);
        } else {
            done(response);
        }
    };
    xhr.onerror = function() {
        return error({ code: this.status });
    };
    if (data) {
        if (method === "GET") {
            console.warn(
                `GET request (${endpoint}) includes JSON data. Check action creator.`
            );
        }
        requestBody = JSON.stringify(data);

        let csrfCookie;
        if (!global.csrfCookieName) {
            console.warn("global.csrfCookieName not set");
        } else {
            csrfCookie = getCookie(csrfCookieName);
        }
        if (!csrfSafeMethod(method) && csrfCookie) {
            xhr.setRequestHeader("X-CSRFToken", csrfCookie);
        }
        xhr.setRequestHeader("Content-Type", "application/json");
    }

    if (formData) {
        if (method === "GET")
            console.warn(
                "GET request includes form data. Check action creator."
            );
        requestBody = formData;
        xhr.setRequestHeader(
            "Content-type",
            "application/x-www-form-urlencoded"
        );
        if (!csrfSafeMethod(method)) {
            xhr.setRequestHeader("X-CSRFToken", getCookie(csrfCookieName));
        }
    }

    if (binary) {
        xhr.responseType = "arraybuffer";
    }

    xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");

    xhr.send(requestBody);
}

const getResponseErrorMessage = function(error) {
    // Take an error object, and return a string to display in error tracking service
    if (typeof error === "string") return error;
    if (error.message) return error.message;
    if (error.field_errors) {
        if (error.field_errors.__all__)
            return error.field_errors.__all__[0].code;
        return "field_errors";
    }
};

// We use this Normalizr schemas to transform API responses from a nested form
// to a flat form where repos and users are placed in `entities`, and nested
// JSON objects are replaced with their IDs. This is very convenient for
// consumption by reducers, because we can easily build a normalized tree
// and keep it updated as we fetch more data.

// Read more about Normalizr: https://github.com/paularmstrong/normalizr

const Creator = new normalizr.schema.Entity("creator");
const User = new normalizr.schema.Entity("user");
const Collections = new normalizr.schema.Entity("collections");
const Icons = new normalizr.schema.Entity("icons");
const Search = new normalizr.schema.Entity("search");
const Upload = new normalizr.schema.Entity("batch");

export const Schemas = {
    ICONS: { schema: Icons, normalizer: normalizers.icons },
    COLLECTIONS: { schema: Collections, normalizer: normalizers.collections },
    UPLOAD: { schema: Upload, normalizer: normalizers.upload },
    CREATOR: { schema: Creator, normalizer: normalizers.creator },
    USER: { schema: User, normalizer: normalizers.user },
    SEARCH: { schema: Search, normalizer: normalizers.search }
};

// Action key that carries API call info interpreted by this Redux middleware.
export const CALL_API = Symbol("Call API");
// Action key that includes payload to merge into dispatched SUCCESS/FAIL actions
export const DECORATE_ACTIONS = Symbol("Decorate Actions");

// A Redux middleware that interprets actions with CALL_API info specified.
// Performs the call and promises when such actions are dispatched.
export default store => next => action => {
    const callAPI = action[CALL_API];

    if (typeof callAPI === "undefined") {
        return next(action);
    }

    let { endpoint } = callAPI,
        notifyModal = false;
    const { service, schema, types, data, formData, method, binary } = callAPI;

    if (callAPI.notifyModal) notifyModal = true;

    if (typeof endpoint === "function") {
        endpoint = endpoint(store.getState());
    }

    if (typeof endpoint !== "string") {
        throw new Error("Specify a string endpoint URL.");
    }

    if (!Array.isArray(types) || types.length !== 3) {
        throw new Error("Expected an array of three action types.");
    }
    if (!types.every(type => typeof type === "string")) {
        throw new Error("Expected action types to be strings.");
    }

    function actionWith(data) {
        const finalAction = Object.assign({}, action, data);
        delete finalAction[CALL_API];

        if (action[DECORATE_ACTIONS]) {
            Object.assign(finalAction, action[DECORATE_ACTIONS]);
            delete finalAction[DECORATE_ACTIONS];
        }

        return finalAction;
    }

    const [requestType, successType, failureType] = types;

    next(
        actionWith({
            formData: callAPI.formData,
            type: requestType,
            notifyModal: notifyModal,
            page: callAPI.page,
            id: callAPI.id,
            contentType: callAPI.contentType
        })
    );

    return callApi(
        service,
        endpoint,
        schema,
        method,
        data,
        formData,
        binary,
        response =>
            next(
                actionWith({
                    type: successType,
                    response,
                    notifyModal: notifyModal,
                    page: callAPI.page,
                    id: callAPI.id,
                    apiResponse: true,
                    contentType: callAPI.contentType
                })
            ),
        error =>
            next(
                actionWith({
                    formData: callAPI.formData,
                    type: failureType,
                    id: callAPI.id,
                    notifyModal: notifyModal,
                    statusCode: error.code,
                    contentType: callAPI.contentType,
                    apiResponse: true,
                    error: error.message || "Sorry, an error occurred."
                })
            )
    );
};
