import { useApolloMutation } from "frontend/utils/apolloApi/useApolloMutation.js";

// GQL
import { REJECT_PHOTO_SHOOT_TITLE } from "frontend/components/fort/mod/photos/gql/mutations";

export const useRejectPhotoShootTitle = () => {
    const { apolloMutate, loading } = useApolloMutation({
        actionDesc: "Reject Photo Shoot",
        gql: REJECT_PHOTO_SHOOT_TITLE
    });

    const rejectPhotoShootTitle = async variables => {
        return await apolloMutate({
            variables: {
                ...variables,
                title: null
            }
        });
    };

    return {
        rejectPhotoShootTitle,
        rejectPhotoShootTitleLoading: loading
    };
};
