import styled from "styled-components";

import { regular as regularFontWeight } from "core/fontStyles";
import { BorderBottomLinkStyle } from "core/Typography";

import colors from "core/colors";

export const TestimonialContainer = styled.div`
    a {
        ${BorderBottomLinkStyle}
    }
`;

export const Source = styled.div`
    text-align: ${({ alignment }) => alignment};
    padding-top: 1rem; /* 16px */
    font-size: 1rem; /* 16px */
    line-height: 1.625rem; /* 26px */
    color: ${colors.secondary.oolong};
    ${regularFontWeight}
`;
