import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import ButtonGroup from "core/ButtonGroup";

import { QUEUE_VIEWS } from "../../../constants";

// styles
import { convertPxToRem } from "frontend/components/helpers";
export const BatchFilterMenuStyled = styled.div`
    margin: ${convertPxToRem(24)} 0px;
`;

// Hooks
import { useSelect } from "frontend/components/core/Grid2/GridItemContentTemplates/Select/SelectContext/index.js";

// styles
import { batchUploadCollectionShape } from "./batchUploadCollectionShape";

export const BatchFilterMenu = ({
    batchUploadCollection,
    setActiveSaveView
}) => {
    const { id, activeIds, savedForLaterIds, view } = batchUploadCollection;
    const { deselectAll } = useSelect();

    const setToActive = () => {
        setActiveSaveView(id, QUEUE_VIEWS.ACTIVE);
        deselectAll();
    };

    const setToSaveForLater = () => {
        setActiveSaveView(id, QUEUE_VIEWS.SAVED);
        deselectAll();
    };

    return (
        <BatchFilterMenuStyled data-testid="batch-filter-menu">
            <ButtonGroup
                buttons={[
                    {
                        label: `${activeIds.length} Active`,
                        isActive: view === QUEUE_VIEWS.ACTIVE,
                        onClick: setToActive
                    },
                    {
                        label: `${savedForLaterIds.length} Saved for Later`,
                        isActive: view === QUEUE_VIEWS.SAVED,
                        onClick: setToSaveForLater
                    }
                ]}
            />
        </BatchFilterMenuStyled>
    );
};

export default BatchFilterMenu;

BatchFilterMenu.propTypes = {
    batchUploadCollection: PropTypes.shape(batchUploadCollectionShape)
        .isRequired,
    setActiveSaveView: PropTypes.func.isRequired
};
