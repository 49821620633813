// WARNING: We now have Faker, please use Faker instead
// Faker API Reference: https://fakerjs.dev/api/
// Example in our codebase: frontend/components/fort/mod/photos/VisualQueuePage/__tests__/mockData/index.js

// Deprecated
// custom UUID
// Note: This is a poor man's UUID. Prone to collisions. Ok for small test scenarios, mock data
const alpha = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
const numeric = "0123456789";
const alphaNum = `${alpha}${numeric}`;

export const cuuid = (length, characters = alphaNum) => {
    let result = "";

    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
        );
        counter += 1;
    }
    return result;
};

export const cuuidNumeric = length => {
    return cuuid(length, numeric);
};

export const cuuidAlpha = length => {
    return cuuid(length, alpha);
};

// Handy way to make incremental numbers, emulating Database IDs for example.
// You can set starting number or rely on random number generated by length
export const uniqueIncr = ({ start, length = 20 }) => {
    let number = start || cuuidNumeric(length);

    const get = () => {
        return number++;
    };

    return {
        get
    };
};
