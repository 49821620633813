import { batchesSlice } from "./index";

const isEditable = ({ id, changes }) => (dispatch, getState) => {
    const batchEntities = getState().batches.entities;
    const thisBatch = batchEntities[id];

    const newIsEditable = {
        ...thisBatch.isEditable,
        ...changes
    };

    dispatch(
        batchesSlice.actions.updateOne({
            id,
            changes: {
                isEditable: newIsEditable
            }
        })
    );
};

export default {
    isEditable
};
