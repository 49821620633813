import gql from "graphql-tag";

export const SET_PHOTO_UPLOAD_TITLE = gql`
    mutation SetPhotoUploadTitle($id: ID!, $value: String) {
        setPhotoUploadTitle(photoUploadId: $id, value: $value) {
            ok
            errors
            # photoUpload field needs to be included for proper cache updates
            photoUpload {
                id
                photoTitle
                updatedAt
                __typename
            }
            __typename
        }
    }
`;

export const SET_HAS_RECOGNIZABLE_PEOPLE_PROPERTY = gql`
    mutation SetHasRecognizablePeopleOrPropertyOnPhotoUploads(
        $photoUploadIds: [ID!]!
        $value: Boolean!
    ) {
        setHasRecognizablePeopleOrPropertyOnPhotoUploads(
            photoUploadIds: $photoUploadIds
            value: $value
        ) {
            ok
            photoUploads {
                ok
                errors
                # photoUpload field needs to be included for proper cache updates
                photoUpload {
                    id
                    hasRecognizablePeopleOrProperty
                    updatedAt
                    __typename
                }
                __typename
            }
            __typename
        }
    }
`;

export const SET_PHOTO_UPLOAD_EDITORIAL_USE = gql`
    mutation SetEditorialUseOnlyOnPhotoUploads(
        $photoUploadIds: [ID!]!
        $value: Boolean!
    ) {
        setEditorialUseOnlyOnPhotoUploads(
            photoUploadIds: $photoUploadIds
            value: $value
        ) {
            ok
            photoUploads {
                ok
                errors
                # photoUpload field needs to be included for proper cache updates
                photoUpload {
                    id
                    editorialUseOnly
                    updatedAt
                    __typename
                }
                __typename
            }
            __typename
        }
    }
`;

export const SUBMIT_TAGS = gql`
    mutation addTagsToPhotoUploads($photoUploadIds: [ID!]!, $tags: [String!]!) {
        addTagsToPhotoUploads(photoUploadIds: $photoUploadIds, tags: $tags) {
            tagsNotAdded {
                tag
                reason
                photoUploadId
            }
            tagsAdded {
                tag
                photoUploadId
                __typename
            }
            # photoUpload field needs to be included for proper cache updates
            photoUploads {
                id
                userTags
                updatedAt
                __typename
            }
            __typename
        }
    }
`;

export const DELETE_TAG = gql`
    mutation removeTagsFromPhotoUploads(
        $photoUploadIds: [ID!]
        $tags: [String!]
    ) {
        removeTagsFromPhotoUploads(
            photoUploadIds: $photoUploadIds
            tags: $tags
        ) {
            tagsNotRemoved {
                tag
                reason
                photoUploadId
                __typename
            }
            tagsRemoved {
                tag
                photoUploadId
                __typename
            }
            # photoUpload field needs to be included for proper cache updates
            photoUploads {
                id
                userTags
                updatedAt
                __typename
            }

            __typename
        }
    }
`;

export const SET_LICENSE_ON_PHOTO_UPLOADS = gql`
    mutation SetLicenseOnPhotoUploads(
        $photoUploadIds: [ID!]!
        $value: License!
    ) {
        setLicenseOnPhotoUploads(
            photoUploadIds: $photoUploadIds
            value: $value
        ) {
            ok
            photoUploads {
                ok
                errors
                # photoUpload field needs to be included for proper cache updates
                photoUpload {
                    id
                    license
                    updatedAt
                    __typename
                }
                __typename
            }
            __typename
        }
    }
`;

export const SET_LOCATION_ON_PHOTO_UPLOADS = gql`
    mutation SetLocationOnPhotoUploads(
        $photoUploadIds: [ID!]!
        $placeId: ID
        $locationName: String!
    ) {
        setLocationOnPhotoUploads(
            photoUploadIds: $photoUploadIds
            placeId: $placeId
            locationName: $locationName
        ) {
            ok
            photoUploads {
                photoUpload {
                    id
                    updatedAt
                    photoLocation
                }
            }
            __typename
        }
    }
`;
