import React from "react";
import { Provider } from "react-redux";
import PropTypes from "prop-types";

// component
export const ReduxProvider = ({ children, store }) => {
    return <Provider store={store}>{children}</Provider>;
};

export default ReduxProvider;

// prop-types
ReduxProvider.propTypes = {
    children: PropTypes.any,
    store: PropTypes.object.isRequired
};
