import { withRouter } from "react-router-dom";
import { sendPageView } from "utils/google-analytics";

const GoogleAnalaytics = ({ location, debug = false }) => {
    if (typeof window.ga !== "function") {
        if (debug) {
            console.warn("window.ga is not loaded");
        }
        return null;
    }
    const path = location.pathname + location.search;

    // TODO: Since this code runs before any GraphQL queries finish, it's likely
    // that react-helmet hasn't set the right page title yet. We can set that
    // manually here with a bit of work. These page titles would just be for
    // interal use.
    // Temporarily set the page title to "Noun Project" until we can handle it
    // per page. Otherwise, the page title will always be the *previously* viewed
    // page, and that will be very confusing to see in Google Analytics.
    const title = "Noun Project";

    sendPageView(path, { title: title });

    if (debug) {
        console.log(`GA PAGEVIEW: ${path}`);
    }
    return null;
};

export default withRouter(GoogleAnalaytics);
