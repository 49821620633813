import React from "react";
import styled from "styled-components";

// context
import { useSearchBar } from "core/SiteHeaderV2/components/SearchBar/context/useSearchBar.js";

// styles
import { getBorderStyle } from "./index";
import { regular } from "core/fontStyles";
import colors, { focusBorder } from "core/colors";
import { convertPxToRem } from "frontend/components/helpers";

const InputStyled = styled.input`
    flex: 1;
    background-color: ${colors.primary.snow};
    border: none;
    border-top: ${({ borderStyle }) => borderStyle};
    border-bottom: ${({ borderStyle }) => borderStyle};
    height: 100%;
    padding: ${convertPxToRem(10)};
    ${regular}
    font-size: ${convertPxToRem(15)};

    display: flex;
    align-items: center;
    justify-content: center;

    &:focus {
        outline: none;
        ${focusBorder};
    }
`;

// component
export const Input = () => {
    const {
        isDarkMode,
        inputValue,
        setInputValue,
        dropDown: {
            selectedOption: { placeholder }
        }
    } = useSearchBar();
    return (
        <InputStyled
            name="q"
            type="search"
            placeholder={placeholder}
            borderStyle={getBorderStyle(isDarkMode)}
            value={inputValue}
            onChange={e => setInputValue(e.target.value)}
        />
    );
};

export default Input;
