/*===================================
||
|| DropDownContext
||
===================================*/
import React, { createContext, useReducer, useMemo } from "react";
import PropTypes from "prop-types";

// store
import { reducer } from "./store";

// components
import MountingWrapper from "./components/MountingWrapper";
export { Hotspot } from "./components/Hotspot";
export { DropDown } from "./components/DropDown";

/*---------------------------
| Context
---------------------------*/
export const DropDownContext = createContext();
DropDownContext.displayName = "DropDown"; // Display Name

// Provider
export const DropDownProvider = ({
    children,
    hasCloseButton = true,
    toggleOnHover = false,
    hideOnScroll = false,
    id,
    onOpen = () => {},
    onClose = () => {}
}) => {
    const nonStateProps = {
        hasCloseButton,
        toggleOnHover,
        hideOnScroll,
        id,
        onOpen,
        onClose
    };

    const stateProps = {
        isOpen: false
    };

    // Whatever you want to manage as internal state
    const [state, dispatch] = useReducer(reducer, stateProps);

    // useMemo so it does not pass value on every render
    const value = useMemo(() => ({ state, dispatch, nonStateProps }), [
        state,
        dispatch,
        nonStateProps
    ]);

    return (
        <DropDownContext.Provider value={value}>
            <MountingWrapper>{children}</MountingWrapper>
        </DropDownContext.Provider>
    );
};

// prop-types
DropDownProvider.propTypes = {
    children: PropTypes.any,
    hasCloseButton: PropTypes.bool,
    toggleOnHover: PropTypes.bool,
    hideOnScroll: PropTypes.bool,
    id: PropTypes.string.isRequired,
    onOpen: PropTypes.func,
    onClose: PropTypes.func
};
