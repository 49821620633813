import { useApolloLazyQuery } from "frontend/utils/apolloApi/useApolloLazyQuery.js";

// utils
import {
    chunkedPromises,
    convertArrayToChunks,
    mergeArrays
} from "frontend/utils";

// queries
import { FETCH_UPLOAD_IDS_FOR_BATCHES } from "frontend/components/fort/mod/photos/gql/queries";

// config
export const ACTION_DESC = "Get Upload Ids For All Batches";
const CHUNK_COUNT = 50; // Chunk Requests Capped at 50 batchIds per request

export const useGetUploadIdsForBatches = () => {
    const { apolloQuery } = useApolloLazyQuery({
        actionDesc: ACTION_DESC,
        gql: FETCH_UPLOAD_IDS_FOR_BATCHES
    });

    const getUploadIdsForBatches = async batchIds => {
        const batches = convertArrayToChunks(batchIds, CHUNK_COUNT);
        const responses = await chunkedPromises(
            batches,
            async chunkedBatchIds => {
                const resp = await apolloQuery({
                    variables: {
                        batchIds: chunkedBatchIds
                    },
                    fetchPolicy: "network-only"
                });

                return resp?.ssResp?.data?.list || [];
            }
        );
        return mergeArrays(responses);
    };

    return {
        getUploadIdsForBatches
    };
};
