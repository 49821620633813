import { useApolloMutation } from "frontend/utils/apolloApi/useApolloMutation.js";

// GQL
import { REJECT_PHOTO_UPLOAD_DESCRIPTION } from "frontend/components/fort/mod/photos/gql/mutations";

export const useRejectPhotoUploadDescription = () => {
    const { apolloMutate, loading } = useApolloMutation({
        actionDesc: "Reject Photo Upload Description",
        gql: REJECT_PHOTO_UPLOAD_DESCRIPTION
    });

    const rejectPhotoUploadDescription = async variables => {
        return await apolloMutate({ variables });
    };

    return {
        rejectPhotoUploadDescription,
        rejectPhotoUploadDescriptionLoading: loading
    };
};
