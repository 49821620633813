import styled from "styled-components";

// styles
import { convertPxToRem } from "frontend/components/helpers";
import colors from "core/colors";
import {
    regular as regularFontWeight,
    medium as mediumFontWeight
} from "core/fontStyles";

export const DialogStyled = styled.div`
    position: absolute;
    ${({ alignRight }) => (alignRight ? `right: 0px` : `left: 0px`)};
    top: ${({ positionTop }) => convertPxToRem(positionTop)};
    z-index: 2;

    background-color: white;
    width: ${convertPxToRem(460)};

    box-shadow: 0px 5px 5px rgb(0 0 0 / 20%);
`;

export const HeaderStyled = styled.div`
    ${regularFontWeight}
    font-size: ${convertPxToRem(12)};
    padding: ${convertPxToRem(24)} ${convertPxToRem(24)} ${convertPxToRem(16)};
    color: ${colors.secondary.orca}
`;
export const BodyStyled = styled.div`
    ${regularFontWeight}

    max-height: ${convertPxToRem(225)};
    font-size: ${convertPxToRem(12)};
    padding: ${convertPxToRem(4)} ${convertPxToRem(24)} ${convertPxToRem(24)};

    overflow: auto;
`;
