import React, { useEffect } from "react";
import PropTypes from "prop-types";

// context
import { usePanelOverlay } from "../usePanelOverlay";

// MountingWrapper
const MountingWrapper = ({ children }) => {
    const { setHasOpened, hasOpened, isOpen, close } = usePanelOverlay();

    useEffect(() => {
        if (isOpen && !hasOpened) {
            setHasOpened(true);
        }
    }, [isOpen, hasOpened]);

    useEffect(() => {
        if (isOpen) {
            // Apply styles when the component mounts
            document.body.style.paddingRight = "15px";
            document.body.style.overflow = "hidden";
        }

        // Cleanup styles when the component unmounts
        return () => {
            document.body.style.paddingRight = "";
            document.body.style.overflow = "";
        };
    }, [isOpen]);

    useEffect(() => {
        const handleKeyDown = event => {
            if (event.key === "Escape") {
                close();
            }
        };

        // Add event listener
        document.addEventListener("keydown", handleKeyDown);

        // Cleanup the event listener
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [close]); // Add onEscape to dependency array

    return <>{children}</>;
};

export default MountingWrapper;

// prop-types
MountingWrapper.propTypes = {
    children: PropTypes.any
};
