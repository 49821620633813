import React from "react";

import Link from "core/Link";

import { FeedbackContent, FeedbackIcon, FeedbackText } from "../styles";
import Timeout from "core/IllustrativeIcons/Timeout";

import colors from "core/colors";
import { FORGOT_PASSWORD } from "data/links";

const ResetPasswordTimeout = () => (
    <FeedbackContent>
        <FeedbackIcon>
            <Timeout fillColor={colors.accent.lightMode.pansy} />
        </FeedbackIcon>
        <FeedbackText>
            The link to reset your password has expired or is invalid.
        </FeedbackText>
        <Link size="lg" href={FORGOT_PASSWORD}>
            Please generate a new url
        </Link>
    </FeedbackContent>
);

export default ResetPasswordTimeout;
