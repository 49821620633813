import React from "react";

import Styleguide from "core/ReusableIcons/Styleguide";
import CollectionsCollaborative from "core/ReusableIcons/CollectionsCollaborative";
import Icon from "core/ReusableIcons/Icon";
import Snapchat from "core/PartnerIcons/Snapchat";
import Oscar from "core/PartnerIcons/Oscar";
import Target from "core/PartnerIcons/Target";
import Lowes from "core/PartnerIcons/Lowes";
import Ticketmaster from "core/PartnerIcons/Ticketmaster";

/* -------------------------------------------------------------------------- */
/*                               BULLET SECTION                               */
/* -------------------------------------------------------------------------- */

export const bullets = [
    {
        icon: <Styleguide size="xl" />,
        title: "Brand Style Guide",
        text:
            "Create living style guides and achieve brand consistency at scale."
    },
    {
        icon: <CollectionsCollaborative size="xl" />,
        title: "Design System Manager",
        text:
            "A drag-and-drop hub for UI components to help you and your team design faster."
    },
    {
        icon: <Icon size="xl" />,
        title: "Digital Asset Library",
        text:
            "Create digital asset libraries that empower your team to create on their own."
    }
];

/* -------------------------------------------------------------------------- */
/*                          FEATURE SPOTLIGHT SECTION                         */
/* -------------------------------------------------------------------------- */

/* --------------------------- SPOTLIGHT ONE -------------------------- */

import productFundamentalOneImage2x from "./images/product-fundamental-1-2x.png";

export const productFundamentalOneImage = productFundamentalOneImage2x;
export const productFundamentalOneAlt =
    "Screenshot of content library in Lingo";

export const productFundamentalOneHeading =
    "The Visual Way to Organize Your Assets with Lingo";

export const productFundamentalOneText = [
    "Part style guide, part digital asset manager — Lingo's canvas is designed to flex with your needs. Bring content and context together in a single location."
];

/* --------------------------- SPOTLIGHT TWO -------------------------- */

import productFundamentalTwoImage2x from "./images/product-fundamental-2-2x.png";

export const productFundamentalTwoImage = productFundamentalTwoImage2x;
export const productFundamentalTwoAlt = "Screenshot of sharing a kit in Lingo";

export const productFundamentalTwoHeading =
    "Share your brand's assets with just your team or the entire world.";

export const productFundamentalTwoText = [
    "Private, password protected or public. With Lingo, you control who can access your brand's assets."
];

/* --------------------------- SPOTLIGHT THREE -------------------------- */

import productFundamentalThreeImage2x from "./images/product-fundamental-3-2x.png";

export const productFundamentalThreeImage = productFundamentalThreeImage2x;
export const productFundamentalThreeAlt =
    "Screenshot of downloading asset in Lingo";

export const productFundamentalThreeHeading =
    "Empower your team to create on their own while ensuring consistency";

export const productFundamentalThreeText = [
    "Lingo has powerful image processing capabilities that automate repetitive tasks like file conversion and resizing. With Lingo your team will be able find the file they need, in the format and size they need all without needing your help."
];

/* -------------------------------------------------------------------------- */
/*                               BRANDS YOU KNOW                              */
/* -------------------------------------------------------------------------- */

export const brandLogos = [
    {
        icon: <Snapchat title="Snapchat" />
    },
    {
        icon: <Target title="Target" />
    },
    {
        icon: <Oscar title="Oscar" />
    },
    {
        icon: <Lowes title="Lowes" />
    },
    {
        icon: <Ticketmaster title="Ticketmaster" />
    }
];
