import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Link from "core/Link";

import Messages from "core/Messages";
import ErrorModal from "./ErrorModal";

export const BannerContainer = styled.div`
    position: absolute;
    bottom: 0;
    pointer-events: initial;
`;

export const ErrorModalButton = styled(Link).attrs({
    disableVisitedStyle: true,
    darkMode: true
})`
    margin-left: 0.25rem;
`;

const getSuccessText = (uploadsInDropzoneCount = 0) => {
    if (uploadsInDropzoneCount < 1) return "";
    return `${uploadsInDropzoneCount} uploaded successfully.`;
};

const getFailedText = (allFailedUploadsCount = 0) => {
    if (allFailedUploadsCount < 1) return "";
    if (allFailedUploadsCount === 1) return `1 error.`;
    return `${allFailedUploadsCount} errors.`;
};

const getProcessingText = (currentlyProcessingCount = 0) => {
    if (currentlyProcessingCount < 1) return "";
    return `Processing ${currentlyProcessingCount} more. This may take a while.`;
};

export const MessageText = ({
    successText = "",
    failedText = "",
    processingText = "",
    openErrorModal
}) => {
    const handleSeeDetailsClick = () => openErrorModal();
    const showErrorButton = failedText;

    if (`${successText}${failedText}${processingText}` === "") return null;

    // if all uploads have failed, show this message
    if (failedText && !successText && !processingText) {
        return (
            <span>
                No photos uploaded. {failedText}{" "}
                <ErrorModalButton onClick={handleSeeDetailsClick}>
                    See details
                </ErrorModalButton>
            </span>
        );
    }

    return (
        <span>
            {successText} {processingText} {failedText}{" "}
            {showErrorButton && (
                <ErrorModalButton onClick={handleSeeDetailsClick}>
                    See details
                </ErrorModalButton>
            )}
        </span>
    );
};

const PhotoUploadUploadStatus = ({
    uploadsInDropzoneCount = 0,
    allFailedUploadsCount = 0,
    currentlyProcessingCount = 0,
    uploadErrors = []
}) => {
    const [messageDisplayPaused, setMessageDisplayPaused] = useState(false);
    const handleDismissClick = () => {
        setMessageDisplayPaused(true);
    };

    const [errorModalIsOpen, setErrorModalIsOpen] = useState(false);
    const openErrorModal = () => setErrorModalIsOpen(true);
    const closeErrorModal = () => setErrorModalIsOpen(false);

    const successText = getSuccessText(uploadsInDropzoneCount);
    const failedText = getFailedText(allFailedUploadsCount);
    const processingText = getProcessingText(currentlyProcessingCount);
    const statusText = `${successText}${failedText}${processingText}`;

    useEffect(() => {
        let timeout;
        if (messageDisplayPaused) {
            // if user dismisses banner, it should stay
            // dismissed for at least 10 seconds
            timeout = setTimeout(() => setMessageDisplayPaused(false), [10000]);
        }
        return clearTimeout(timeout);
    }, [messageDisplayPaused]);

    if (messageDisplayPaused || statusText === "") return null;

    return (
        <BannerContainer>
            <Messages
                overrideDismissClick={handleDismissClick}
                styleType="solid"
                isDismissable
                infoType="neutral"
            >
                <MessageText
                    successText={successText}
                    failedText={failedText}
                    processingText={processingText}
                    openErrorModal={openErrorModal}
                />
            </Messages>
            <ErrorModal
                open={errorModalIsOpen}
                handleClose={closeErrorModal}
                uploadErrors={uploadErrors}
            />
        </BannerContainer>
    );
};

PhotoUploadUploadStatus.propTypes = {
    uploadsInDropzoneCount: PropTypes.number.isRequired,
    allFailedUploadsCount: PropTypes.number.isRequired,
    currentlyProcessingCount: PropTypes.number.isRequired,
    uploadErrors: PropTypes.array.isRequired
};

export default PhotoUploadUploadStatus;
