import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// styles
import colors from "core/colors";
import { convertPxToRem } from "frontend/components/helpers";
import { medium } from "core/fontStyles";

const OptionLabelStyled = styled.div`
    display: flex;
    align-items: center;
`;
const DisplayTextStyled = styled.div`
    ${medium}
    margin-left: ${convertPxToRem(4)};
`;

// component
export const OptionLabel = ({ text, Icon }) => {
    return (
        <OptionLabelStyled title={text}>
            <Icon
                size={{ height: 16, width: 16 }}
                fillColor={colors.secondary.orca}
            />
            <DisplayTextStyled>{text}</DisplayTextStyled>
        </OptionLabelStyled>
    );
};

export default OptionLabel;

// prop-types
OptionLabel.propTypes = {
    text: PropTypes.string.isRequired,
    Icon: PropTypes.any.isRequired
};
