import React, { useState } from "react";
import PropTypes from "prop-types";

import EditTextDefaultMode from "./EditTextDefaultMode";

const EditText = ({
    defaultDisplayText,
    displayText,
    buttonAriaLabel = "Edit text",
    editingMode
}) => {
    const [inEditingMode, setInEditingMode] = useState(false);
    const enterEditingMode = () => setInEditingMode(true);
    const exitEditingMode = () => setInEditingMode(false);

    return !inEditingMode ? (
        <EditTextDefaultMode
            displayText={displayText}
            defaultDisplayText={defaultDisplayText}
            buttonAriaLabel={buttonAriaLabel}
            enterEditingMode={enterEditingMode}
        />
    ) : (
        editingMode(exitEditingMode)
    );
};

EditText.propTypes = {
    defaultDisplayText: PropTypes.string.isRequired,
    displayText: PropTypes.string,
    buttonAriaLabel: PropTypes.string,
    editingMode: PropTypes.func.isRequired
};

export default EditText;
