import React, { useState } from "react";
import PropTypes from "prop-types";

import { AuthButton } from "../styles";
import FormInput from "core/FormInput2";
import FormError from "core/FormError";

import {
    validNewPassword,
    attemptAuth,
    RESET_PASSWORD_BACKEND_URL
} from "../helpers";

const ResetPassword = ({ afterSubmit, setKeyIsExpired }) => {
    const [password1, setPassword1] = useState("");
    const [password2, setPassword2] = useState("");
    const [backendError, setBackendError] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);

    const confirmNewPassword = input => input === password1;

    const onClick = () => {
        setIsProcessing(true);
        setBackendError(null);
        attemptAuth({
            url: RESET_PASSWORD_BACKEND_URL,
            form: {
                password1,
                password2
            },
            onSuccess: afterSubmit, // show success view
            onFail(errorMsg) {
                if (errorMsg === "The password reset token was invalid.") {
                    setKeyIsExpired(true);
                    return;
                }
                setIsProcessing(false);
                setBackendError(errorMsg);
            }
        });
    };

    return (
        <>
            <FormError>{backendError}</FormError>
            <FormInput
                type="password"
                value={password1}
                onChange={setPassword1}
                labelText="New password"
                placeholderText="Enter password"
                validation={[
                    {
                        errorMsg: "Password must be a minimum of 8 characters.",
                        isValid: validNewPassword
                    }
                ]}
                size="lg"
            />
            <FormInput
                type="password"
                value={password2}
                onChange={setPassword2}
                labelText="New password confirmation"
                placeholderText="Enter password again"
                validation={[
                    {
                        errorMsg: "Passwords do not match.",
                        isValid: confirmNewPassword
                    }
                ]}
                size="lg"
            />
            <AuthButton
                styleType="cta"
                isProcessing={isProcessing}
                isDisabled={
                    !validNewPassword(password1) ||
                    !confirmNewPassword(password2)
                }
                onClick={onClick}
            >
                Reset My Password
            </AuthButton>
        </>
    );
};

ResetPassword.propTypes = {
    afterSubmit: PropTypes.func.isRequired,
    setKeyIsExpired: PropTypes.func.isRequired
};

export default ResetPassword;
