import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import colors from "core/colors";

const createHelpers = (width, height, css) => {
    // somehow sizes is ending up in markup, even if it is not a valid svg attribute
    // until we have a better solution, just render it empty, instead to '[Object object]'
    const sanitizeSizes = sizes =>
        Object.defineProperty(sizes, "toString", {
            value: () => "",
            enumerable: false
        });

    const getDimensions = (size, sizes) => {
        if (
            size &&
            typeof size.width === "number" &&
            typeof size.height === "number"
        ) {
            return size;
        }

        return size && sizes[size] ? sizes[size] : { width, height };
    };

    const getCss = (size, sizes, fillColor, fillColorRule, noStyles) => {
        if (noStyles) {
            return "";
        }

        const dimensions = getDimensions(size, sizes);
        const fillRule =
            fillColor && fillColorRule
                ? `${fillColorRule}{ fill: ${fillColor}; }`
                : "";

        return css`
            width: ${dimensions.width}px;
            height: ${dimensions.height}px;
            ${fillRule}
        `;
    };

    const propsToCss = ({ size, sizes, fillColor, fillColorRule, noStyles }) =>
        getCss(size, sizes, fillColor, fillColorRule, noStyles);

    return {
        getCss,
        getDimensions,
        propsToCss,
        sanitizeSizes
    };
};

const width = "70";
const height = "40";
const viewBox = "0 0 70 40";

const { getDimensions, getCss, propsToCss, sanitizeSizes } = createHelpers(
    width,
    height,
    css
);

const sizes = sanitizeSizes({});

const Image = styled.svg`
    ${propsToCss}
`;

const children = (
    <Fragment>
        <path
            fill={colors.secondary.orca}
            fillRule="evenodd"
            d="M53.083 9.71h11.272v3.146H70V34H0V12.856h5.65V9.711h11.277L35.004 2l18.079 7.71zm-1.385 5.624l-.002 3.418 2.222.004.463-3.42-2.683-.002zm12.06 3.372h-.204v-.347h.232c.118 0 .244.026.244.161 0 .174-.13.186-.272.186zm-.204.627v-.485h.195l.296.485h.19l-.318-.492c.166-.02.29-.106.29-.308 0-.22-.132-.319-.396-.319h-.428v1.12h.17l.001-.001zm.205.25c-.446 0-.793-.344-.793-.81 0-.46.347-.807.793-.807.442 0 .788.347.788.806 0 .467-.346.81-.788.81v.001zm-.989-.81c0 .56.453.97.989.97a.969.969 0 100-1.938c-.536 0-.989.41-.989.967v.001zm-51.603 8.209V15.334h-3.2v14.422h8.151v-2.774H11.167zm32.209 2.774h7.615V26.98h-4.4v-1.812h4.4v-2.673h-4.4v-1.82h4.4v-2.79h-7.615v11.871zm-22.76-2.789v-6.293h2.814v6.293h-2.814zm6.02 1.272v-8.838c0-.837-.682-1.515-1.522-1.515h-6.18c-.841 0-1.522.678-1.522 1.515v8.838c0 .837.68 1.517 1.521 1.517h6.181c.84 0 1.522-.68 1.522-1.517zM38.34 17.886v9.096h-1.932v-9.096h-2.813v9.096h-1.932v-9.096h-3.206v10.353a1.52 1.52 0 001.522 1.517h3.79c.638 0 1.232-.439 1.232-1.075 0 .636.596 1.075 1.234 1.075h3.788a1.52 1.52 0 001.523-1.517V17.886H38.34zm23.886 7.384l.001.001-.02-.042a1.048 1.048 0 01-.037-.075l-.02-.037c-.508-1.012-1.643-1.86-3.47-2.587l-.064-.024c-.365-.142-1.465-.572-1.586-1.268a.798.798 0 01.264-.688c.23-.233.585-.352 1.049-.352.744 0 1.593.303 2.03.483.926.382 1.736.724 1.744.726l.081.033v-2.59l-.027-.017c-.017-.012-1.765-1.108-3.532-1.36a7.122 7.122 0 00-.597-.026c-1.624 0-2.856.588-3.66 1.748-.673.954-.747 2.46-.174 3.586.736 1.274 1.917 1.803 3.06 2.319.47.21.958.43 1.412.7l.006.005c.517.307.778.779.67 1.199-.112.433-.612.726-1.243.726-.086 0-.174-.007-.259-.018l-.03-.004c-1.212-.168-3.672-1.08-3.696-1.089l-.079-.029v2.84l.04.015c.024.007 2.403.792 4.515.792h.003c1.152 0 2.024-.23 2.588-.686.013-.011 1.303-1.044 1.36-2.802a3.198 3.198 0 00-.33-1.48l.001.001z"
            clipRule="evenodd"
            key="key-0"
        />
    </Fragment>
);

const defaultProps = {
    children,
    viewBox,
    fillColor: null,
    fillColorRule: "&&& path, &&& use, &&& g, &&& circle",
    sizes,
    size: null
};

Image.propTypes /* remove-proptypes */ = {
    fillColor: PropTypes.string,
    fillColorRule: PropTypes.string,
    viewBox: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    size: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            height: PropTypes.number.isRequired,
            width: PropTypes.number.isRequired
        })
    ]),
    sizes: PropTypes.shape({
        height: PropTypes.number,
        width: PropTypes.number
    })
};

export default Object.assign(Image, {
    getDimensions,
    getCss,
    defaultProps,
    displayName: "Lowes"
});
