import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { format } from "date-fns";

import Heading from "core/Heading";
import SubmissionImageGrid from "./SubmissionImageGrid";

import { PendingGroupContainer, Italic } from "./styles";

let PendingGroup = ({ className, data }) => {
    const formattedShootCreationDate = format(
        new Date(data.createdAt),
        "MMM D, YYYY"
    );

    const getTitle = ({ data }) => {
        if (
            data.photoShoot === null ||
            data.photoShoot.isForSinglePhoto === true
        ) {
            return "Individual Photos";
        }

        if (data.photoShoot.title) {
            return <Italic>{data.photoShoot.title}</Italic>;
        } else {
            return "Photo Shoot";
        }
    };

    return (
        <PendingGroupContainer className={className}>
            <Heading size="md" type="h2">
                {formattedShootCreationDate} {getTitle({ data })}
            </Heading>
            <SubmissionImageGrid submissions={data.photoUploads} />
        </PendingGroupContainer>
    );
};

PendingGroup.propTypes = {
    data: PropTypes.object.isRequired
};

PendingGroup = styled(PendingGroup)``;
export default PendingGroup;
