import styled from "styled-components";

import { convertPxToRem } from "frontend/components/helpers";

import colors from "core/colors";
import Processing from "core/ReusableIcons/Processing";

export const AnimatedProcessingIcon = styled(Processing)`
    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }

    & {
        width: ${({ width }) => (width ? width : convertPxToRem({ px: 12 }))};
        height: ${({ height }) =>
            height ? height : convertPxToRem({ px: 12 })};

        animation-name: spin;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;

        path {
            fill: ${({ iconColor }) =>
                iconColor ? iconColor : colors.secondary.orca};
        }
    }
`;
