import styled from "styled-components";

import {
    handleHeadingSize,
    handleTopSpacing,
    handleBottomSpacing
} from "js/helpers/spacingAndFonts";
import colors from "core/colors";

export const StyledHeading = styled.span`
    ${({ size }) => handleHeadingSize({ size })}
    ${({ topSpacing }) => handleTopSpacing({ topSpacing })}
    ${({ bottomSpacing }) => handleBottomSpacing({ bottomSpacing })}
    color: ${({ headingColor }) =>
        headingColor ? headingColor : colors.secondary.orca};
    text-align: ${({ alignment }) => (alignment ? alignment : "left")};
`;
