import React from "react";
import PropTypes from "prop-types";

// styles
import { TextEditStyled } from "./styles.js";

// Components
import Display from "./Display.js";
import Edit from "./Edit.js";

export const TextEdit = ({ children }) => {
    return (
        <TextEditStyled>
            <Display />
            <Edit>{children}</Edit>
        </TextEditStyled>
    );
};

export default TextEdit;

// prop-types
TextEdit.propTypes = {
    children: PropTypes.any
};
