/*===================================
||
|| ManageTagsContext
||
===================================*/
import React, { useReducer, useMemo, useRef } from "react";
import PropTypes from "prop-types";

// Debug
export const DEBUG = false;

// components
import MountingWrapper from "./components/MountingWrapper";

// validation
import { inputTextValidation } from "./validation";
const {
    noEmojis,
    notEmpty,
    noCommasOnly,
    tagLimitCheck,
    maxLength
} = inputTextValidation;

// utils
import { getTagObjects, toTitleCase } from "./utils";

/*---------------------------
| Context
---------------------------*/
import ManageTagsContext from "./context";
import { reducer } from "./context/reducer";

// Provider
export const ManageTags = ({
    // Media Type
    mediaType = "photo", // photo || icon

    // Form Elements
    id,
    label = "Tags",
    hideLabel = true,
    placeholder = "Add Tags",
    buttonText = "Add",

    // Enabled/Disabled
    isDisabled = false,

    // Layout
    display = "inline", // "inline", "stacked"

    // Tag Management
    tagLimit = 50,
    hasApproval = false,
    uploads = [],
    suggestions = [],

    // Listeners
    onSuggest,
    onAddTag,
    onRemoveTag,
    onApproveTag,
    onRejectTag,

    // Error Management
    validationRules = [
        noEmojis,
        notEmpty,
        noCommasOnly,
        tagLimitCheck,
        maxLength(50)
    ] // Internal Validation Rules
}) => {
    const nonStateProps = {
        // Media Type
        mediaType,
        mediaTypeTitle: toTitleCase(mediaType),

        // Form Elements
        inputRef: useRef(),
        id,
        label,
        hideLabel,
        placeholder,
        buttonText,
        isDisabled,

        // Layout
        display,

        // Tag Management
        tagLimit,
        hasApproval,
        uploads,
        tags: getTagObjects(uploads), // converts tags from uploads to tag objects {id, approved, ...}
        suggestions,

        // Listeners
        onSuggest,
        onAddTag,
        onRemoveTag,
        onApproveTag,
        onRejectTag,

        // Error Management
        validationRules
    };

    const defaultState = {
        inputText: "",
        showSuggestionDropDown: false,
        enabled: {
            add: true,
            remove: true,
            approval: true,
            suggestions: true // there are times when we do not want suggestions to fire off (e.g. they chose a suggestion and click on input should not show suggestions again)
        },
        errorMessage: ""
    };

    // Whatever you want to manage as internal state
    const [state, dispatch] = useReducer(reducer, defaultState);

    // useMemo so it does not pass value on every render
    const value = useMemo(() => ({ state, dispatch, nonStateProps }), [
        state,
        dispatch,
        nonStateProps
    ]);

    return (
        <ManageTagsContext.Provider value={value}>
            <MountingWrapper />
        </ManageTagsContext.Provider>
    );
};

// Display Name
ManageTagsContext.displayName = "ManageTags";

// prop-types
ManageTags.propTypes = {
    // media Type
    mediaType: PropTypes.oneOf(["photo", "icon"]),

    // Form Elements
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    hideLabel: PropTypes.bool,
    placeholder: PropTypes.string,
    buttonText: PropTypes.string,
    isDisabled: PropTypes.bool,

    // Layout
    display: PropTypes.oneOf(["inline", "stacked"]),

    // Tag Management
    tagLimit: PropTypes.number,
    hasApproval: PropTypes.bool,
    uploads: PropTypes.array,
    suggestions: PropTypes.array,

    // Listeners
    onSuggest: PropTypes.func.isRequired,
    onAddTag: PropTypes.func.isRequired,
    onRemoveTag: PropTypes.func.isRequired,
    onApproveTag: PropTypes.func.isRequired,
    onRejectTag: PropTypes.func.isRequired,

    // Error Management
    validationRules: PropTypes.array
};

export default ManageTags;
