import styled from "styled-components";

// styles
import colors from "core/colors";
import { convertPxToRem } from "frontend/components/helpers";
import { rapidEasing } from "core/animation";
import { focusOutline } from "core/links";

// components
import { LabelAndError } from "frontend/components/core/Form/styles.js";

// styled-components
export const TextEditStyled = styled.div`
    flex: 1;
`;

/*---------------------------
| Edit Styles
---------------------------*/
export const EditStyled = styled.div`
    ${({ isEditing }) => !isEditing && `display: none;`}

    ${LabelAndError} {
        min-height: 0px;
    }
`;
