import colors from "core/colors";

import Info from "core/ReusableIcons/Info";
import Success from "core/ReusableIcons/Success";
import Warning from "core/ReusableIcons/Warning";
import ErrorIcon from "core/ReusableIcons/Error";

export const getCloseButtonColor = ({ infoType, darkMode }) => {
    let closeIconColor;
    let closeIconHoverColor;

    if (darkMode) {
        if (infoType === "info") {
            closeIconColor = colors.accent.darkMode.suenosBuenos;
            closeIconHoverColor = colors.secondary.dolphin;
        } else if (
            infoType !== "info" &&
            (infoType === "neutral" ||
                infoType === "success" ||
                infoType === "warning")
        ) {
            closeIconColor = colors.secondary.orca;
            closeIconHoverColor = colors.accent.lightMode.persian;
        } else {
            closeIconColor = colors.primary.diamond;
            closeIconHoverColor = colors.secondary.dolphin;
        }
    } else {
        if (infoType === "info") {
            closeIconColor = colors.accent.lightMode.persian;
            closeIconHoverColor = colors.accent.lightMode.persian;
        } else if (
            infoType !== "info" &&
            (infoType === "success" || infoType === "warning")
        ) {
            closeIconColor = colors.secondary.orca;
            closeIconHoverColor = colors.accent.lightMode.persian;
        } else {
            closeIconColor = colors.primary.diamond;
            closeIconHoverColor = colors.secondary.dolphin;
        }
    }

    return {
        closeIconColor,
        closeIconHoverColor
    };
};

export const getContainerIconColor = ({ infoType, darkMode }) => {
    let iconColor;
    let iconColorHover;

    if (darkMode) {
        if (infoType === "info") {
            iconColor = colors.accent.darkMode.suenosBuenos;
            iconColorHover = colors.secondary.dolphin;
        } else if (
            infoType !== "info" &&
            (infoType === "neutral" ||
                infoType === "success" ||
                infoType === "warning")
        ) {
            iconColor = colors.secondary.orca;
            iconColorHover = colors.accent.lightMode.persian;
        } else {
            iconColor = colors.primary.diamond;
            iconColorHover = colors.secondary.dolphin;
        }
    } else {
        if (infoType === "info") {
            iconColor = colors.accent.lightMode.persian;
            iconColorHover = colors.accent.lightMode.persian;
        } else if (
            infoType !== "info" &&
            (infoType === "success" || infoType === "warning")
        ) {
            iconColor = colors.secondary.orca;
            iconColorHover = colors.accent.lightMode.persian;
        } else {
            iconColor = colors.primary.diamond;
            iconColorHover = colors.secondary.dolphin;
        }
    }

    return {
        iconColor,
        iconColorHover
    };
};

export const getIcon = ({ infoType }) => {
    switch (infoType) {
        case "neutral":
            return null;
        case "info":
            return Info;
        case "success":
            return Success;
        case "warning":
            return Warning;
        case "error":
            return ErrorIcon;
        default:
            return null;
    }
};
