// Modal reducer is used for showing & hiding modals, as well as passing data to modals. For a
// modal to be rendered, it must have a constant mapped to the component in constants/modalTypes.
//
// modal.component: constant representing component to be rendered
// modal.props: single object to be passed to modal component as props
// modal.result: name of action with notifyModal: true will be set here so modal knows when
//               relevant actions have completed

if (!global._babelPolyfill) {
    require("@babel/polyfill");
}
import merge from "lodash/merge";

import * as actionTypes from "../actions/actionTypes";

export default function modal(state = { all: {}, result: {} }, action) {
    let nextState;

    if (action.type === actionTypes.SHOW_MODAL) {
        let newModal = {
            component: action.component,
            props: action.props
        };
        return {
            ...state,
            all: {
                ...state.all,
                [action.component]: newModal
            }
        };
    } else if (action.type === actionTypes.DISMISS_MODAL) {
        if (action.component) {
            let { all } = state;
            delete all[action.component];
            return {
                ...state,
                all: {
                    ...all
                }
            };
        } else {
            return {
                ...state,
                all: {}
            };
        }
    } else if (action.notifyModal) {
        return {
            ...state,
            result: { actionType: action.type, error: action.error }
        };
    } else {
        return state;
    }

    return nextState;
}
