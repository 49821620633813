import styled, { css, keyframes } from "styled-components";
import { convertPxToRem } from "frontend/components/helpers";
import colors from "core/colors";

const leftSlideIn = keyframes`
    from {
        left: -100vw;
    }
    to {
        left: 0;
    }
`;

const leftSlideOut = keyframes`
    from {
        left: 0;
    }
    to {
        left: -100vw;
    }
`;

const rightSlideIn = keyframes`
    from {
        right: -100vw;
    }
    to {
        right: 0;
    }
`;

const rightSlideOut = keyframes`
    from {
        right: 0;
    }
    to {
        right: -100vw;
    }
`;

const createAnimation = animation => {
    return css`
        animation: ${animation} 0.3s ease-out forwards;
    `;
};

export const leftStyles = ({ $isOpen, $hasOpened }) => {
    let animation = css``;
    if ($hasOpened) {
        animation = $isOpen
            ? createAnimation(leftSlideIn)
            : createAnimation(leftSlideOut);
    }
    return css`
        left: -100vw;
        ${animation}
    `;
};
export const rightStyles = ({ $isOpen, $hasOpened }) => {
    let animation = css``;
    if ($hasOpened) {
        animation = $isOpen
            ? createAnimation(rightSlideIn)
            : createAnimation(rightSlideOut);
    }
    return css`
        right: -100vw;
        ${animation}
    `;
};

export const PanelStyled = styled.div`
    position: fixed;
    top: 0px;
    bottom: 0px;
    z-index: 2001;
    width: calc(100vw - ${convertPxToRem(32)});
    background-color: ${colors.primary.snow};

    ${props => {
        const { $position } = props;
        return $position === "left" ? leftStyles(props) : rightStyles(props);
    }}
`;
