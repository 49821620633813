import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import IndividualUploadLoader from "./IndividualUploadLoader";
import IndividualUploadError from "./IndividualUploadError";

import { usePhotoUpload, hiddenStatuses } from "frontend/hooks/usePhotoUpload";

import { GridItemContentSelect } from "frontend/components/core/Grid2/GridItemContentTemplates/Select/index.js";
import { Image } from "frontend/components/core/Grid2/GridItemContentTemplates/components/Image";

// This is used to trigger onload/onerror events for the image
// without actually displaying it
const HiddenImagePreloader = styled.img`
    display: none;
`;

const ItemContent = ({ id, name, thumbnail, gridIndex }) => {
    const [imageDownloaded, setImageDownloaded] = useState(false); // Show image once download is complete
    const [imageLoadError, setImageLoadError] = useState(false); // Show error message if image fails to load
    const [renderHiddenLoader, setRenderHiddenLoader] = useState(false); // Render loader to trigger image load event

    const { photoUploads } = usePhotoUpload();

    // when image has loaded, show image and remove loader
    const handleImageOnLoad = () => {
        setRenderHiddenLoader(false);
        setImageLoadError(false);
        setImageDownloaded(true);
    };

    // when image has failed to load, show error state
    const handleImageError = () => {
        console.error("Photo upload thumbnail failed to load");
        setRenderHiddenLoader(false);
        setImageLoadError(true);
        setImageDownloaded(false);
    };

    // when thumbnail is available (or changes) render loader
    useEffect(() => {
        if (thumbnail) {
            setRenderHiddenLoader(true);
        }
    }, [thumbnail]);

    return (
        <div>
            {renderHiddenLoader && (
                <HiddenImagePreloader
                    src={thumbnail}
                    onLoad={handleImageOnLoad}
                    onError={handleImageError}
                />
            )}
            <GridItemContentSelect
                id={id}
                name={name}
                gridIndex={gridIndex}
                showEditButton={true}
                gridItemCount={
                    photoUploads.filter(u => !hiddenStatuses.includes(u.status))
                        .length
                }
            >
                {imageDownloaded ? (
                    <Image
                        imageSrc={thumbnail}
                        imgTestId={`added-file-image-${gridIndex}`}
                    />
                ) : imageLoadError ? (
                    <IndividualUploadError />
                ) : (
                    <IndividualUploadLoader />
                )}
            </GridItemContentSelect>
        </div>
    );
};

ItemContent.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    thumbnail: PropTypes.string.isRequired,
    gridIndex: PropTypes.number.isRequired
};

export default ItemContent;
