import React, { useEffect } from "react";
import styled, { css } from "styled-components";

// Context
import { useFormSelectDialog, focusPrevOption, focusNextOption } from "./index";

// styles
const DialogWrapperStyled = styled.div`
    position: relative;
`;
const DialogStyled = styled.ul`
    margin: 0px;
    padding: 0px;

    ${({
        theme: {
            dialog: {
                position,
                zIndex,
                top,
                right,
                left,
                transform,
                width,
                backgroundColor,
                boxShadow,
                border
            }
        }
    }) => {
        return css`
            position: ${position};
            z-index: ${zIndex};
            top: ${top};
            right: ${right};
            left: ${left};
            transform: ${transform};
            width: ${width};
            background-color: ${backgroundColor};
            box-shadow: ${boxShadow};
            border: ${border};
        `;
    }};
`;

// components
import DialogOption from "./DialogOption";

const Dialog = () => {
    const { id, options, isOpen } = useFormSelectDialog();

    // side effects
    useEffect(() => {
        const onKeyDown = event => {
            switch (event.key) {
                case "ArrowUp":
                    focusPrevOption(options, id);
                    break;
                case "ArrowDown":
                    focusNextOption(options, id);
                    break;
                default:
                    return;
            }
        };

        window.addEventListener("keydown", onKeyDown);

        return () => {
            window.removeEventListener("keydown", onKeyDown);
        };
    }, []);

    return (
        <DialogWrapperStyled>
            <DialogStyled
                role="listbox"
                aria-labelledby={`${id}-dropdown-button`}
                hidden={!isOpen}
            >
                {options.map(option => {
                    return <DialogOption key={option.id} option={option} />;
                })}
            </DialogStyled>
        </DialogWrapperStyled>
    );
};

export default Dialog;
