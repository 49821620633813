import React from "react";
import styled from "styled-components";

// QueueContext
import { useQueueContext } from "frontend/components/fort/mod/core/Layouts/QueueLayout";

// styles
import { convertPxToRem } from "frontend/components/helpers";
export const MetaListStyled = styled.ul`
    list-style-type: disc;
    padding-left: ${convertPxToRem(20)};
`;

// Components
import MetaListItem from "./MetaListItem";

// data
const items = [
    {
        id: 1,
        text: `<b>Editorial use only</b>`,
        booleanKey: "editorialUseOnly"
    },
    {
        id: 2,
        text: `Recognizable people/property`,
        booleanKey: "hasRecognizablePeopleOrProperty"
    },
    {
        id: 3,
        text: `Not editable`,
        booleanKey: "isNotEditable"
    }
];

const getUploadBooleanByKey = (upload, booleanKey = "editorialUseOnly") => {
    if (!upload) return false;
    return upload[booleanKey];
};

const isEnabledByBooleanKeyCheck = ({
    singleUploadSelected,
    uploadSelectedCount,
    selectedUploads,
    booleanKey = "editorialUseOnly"
}) => {
    if (uploadSelectedCount > 1) {
        let isEnabled = getUploadBooleanByKey(selectedUploads[0], booleanKey);

        // Make sure all selected have same boolean value for editorialUseOnly
        selectedUploads.forEach(ul => {
            const thisIsEnabled = getUploadBooleanByKey(ul, booleanKey);

            if (thisIsEnabled !== isEnabled) {
                isEnabled = false;
            }
        });

        return isEnabled;
    }

    return getUploadBooleanByKey(singleUploadSelected, booleanKey);
};

const MetaList = () => {
    // state/context
    const { state } = useQueueContext();

    const {
        selectedUploads,
        singleUploadSelected,
        uploadSelectedCount
    } = state;

    return (
        <MetaListStyled>
            {items
                .filter(item => {
                    return isEnabledByBooleanKeyCheck({
                        singleUploadSelected,
                        uploadSelectedCount,
                        selectedUploads,
                        booleanKey: item.booleanKey
                    });
                })
                .map(item => {
                    return <MetaListItem key={item.id} item={item} />;
                })}
        </MetaListStyled>
    );
};

export default MetaList;
