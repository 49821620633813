import styled, { css } from "styled-components";
import isNumber from "lodash/isNumber";

// styles
import { convertPxToRem } from "frontend/components/helpers";
import breakpoints from "core/breakpoints";
import { medium as mediumFontWeight } from "core/fontStyles";

// config
export const config = {
    speed: {
        border: 300, // 300 milliseconds
        fading: 300, // 300 milliseconds
        sliding: 500 // 500 milliseconds
    }
};

// components
import CoreMessageBanner from "core/Messages";

// styled-components
export const RootMessageStackZoneStyled = styled.div`
    position: relative;
    width: 100%;
    margin: 0 auto;
`;

export const MessageListStyled = styled.div`
    text-align: right;
    z-index: 10000;

    ${({ anchorPosition }) => {
        const { position, small, mediumLarge } = anchorPosition;

        const smallTop = convertPxToRem(small.top);
        const smallRight = convertPxToRem(small.right);
        const smallLeft = convertPxToRem(small.left);

        const mediumLargeTop = convertPxToRem(mediumLarge.top);
        const mediumLargeRight = convertPxToRem(mediumLarge.right);
        const mediumLargeLeft = isNumber(mediumLarge.left)
            ? convertPxToRem(mediumLarge.left)
            : mediumLarge.left;

        return css`
            position: ${position};
            top: ${smallTop};
            right: ${smallRight};
            left: ${smallLeft};

            @media (min-width: ${breakpoints.tablet.rem}) {
                top: ${mediumLargeTop};
                right: ${mediumLargeRight};
                left: ${mediumLargeLeft};
                max-width: ${convertPxToRem({ px: 600 })};
            }
        `;
    }}
`;

export const MessageWrapperStyled = styled.div`
    max-height: 300px;
    opacity: 0;
    overflow: hidden; // handles collapsing animation max-height
    display: block;

    transition: opacity ${config.speed.fading / 1000}s linear,
        max-height ${config.speed.sliding / 1000}s linear;

    ${({ messageLife }) => {
        if (messageLife === "fadeIn") {
            return css`
                opacity: 1;
            `;
        }
        if (messageLife === "fadeOut") {
            return css`
                max-height: 0;
                opacity: 0;
            `;
        }
    }}
`;
export const MessageWrapperInnerStyled = styled.div`
    display: inline-block;
    width: auto;
    max-width: 100%;
    pointer-events: none; /* This allows clicks to pass through */
`;

export const MessageStyled = styled(CoreMessageBanner)`
    margin-bottom: ${convertPxToRem({ px: 5 })};

    border: solid 2px rgba(0, 0, 0, 0);
    transition: border ${config.speed.border / 1000}s linear;

    pointer-events: auto; /* This makes any child clickable again */

    &:hover {
        border: solid 2px rgba(0, 0, 0, 0.2);
    }
`;

// dangerously set html could contain bold, italics, etc
export const TextStyled = styled.span`
    b,
    strong {
        ${mediumFontWeight}
    }
`;
