import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import colors from "core/colors";

const createHelpers = (width, height, css) => {
    // somehow sizes is ending up in markup, even if it is not a valid svg attribute
    // until we have a better solution, just render it empty, instead to '[Object object]'
    const sanitizeSizes = sizes =>
        Object.defineProperty(sizes, "toString", {
            value: () => "",
            enumerable: false
        });

    const getDimensions = (size, sizes) => {
        if (
            size &&
            typeof size.width === "number" &&
            typeof size.height === "number"
        ) {
            return size;
        }

        return size && sizes[size] ? sizes[size] : { width, height };
    };

    const getCss = (size, sizes, fillColor, fillColorRule, noStyles) => {
        if (noStyles) {
            return "";
        }

        const dimensions = getDimensions(size, sizes);
        const fillRule =
            fillColor && fillColorRule
                ? `${fillColorRule}{ fill: ${fillColor}; }`
                : "";

        return css`
            width: ${dimensions.width}px;
            height: ${dimensions.height}px;
            ${fillRule}
        `;
    };

    const propsToCss = ({ size, sizes, fillColor, fillColorRule, noStyles }) =>
        getCss(size, sizes, fillColor, fillColorRule, noStyles);

    return {
        getCss,
        getDimensions,
        propsToCss,
        sanitizeSizes
    };
};

const width = "24";
const height = "24";
const viewBox = "0 0 24 24";

const { getDimensions, getCss, propsToCss, sanitizeSizes } = createHelpers(
    width,
    height,
    css
);

const sizes = sanitizeSizes({
    xs: { width: 14, height: 14 },
    sm: { width: 16, height: 16 },
    md: { width: 20, height: 20 },
    lg: { width: 24, height: 24 },
    xl: { width: 40, height: 40 }
});

const Image = styled.svg`
    ${propsToCss}
`;

const children = (
    <Fragment>
        <path
            fill={colors.secondary.orca}
            fillRule="evenodd"
            d="M13.967 5.036a.82.82 0 01-.81.83.82.82 0 01-.808-.83.82.82 0 01.809-.83.82.82 0 01.809.83zm6.033 0a.82.82 0 01-.81.83.82.82 0 01-.808-.83.82.82 0 01.809-.83.82.82 0 01.809.83zM20 12a.82.82 0 01-.81.83.82.82 0 01-.808-.83.82.82 0 01.809-.83A.82.82 0 0120 12zm0 6.964a.82.82 0 01-.81.83.82.82 0 01-.808-.83.82.82 0 01.809-.83.82.82 0 01.809.83zM13.967 12a.82.82 0 01-.81.83.82.82 0 01-.808-.83.82.82 0 01.809-.83.82.82 0 01.809.83zm-9.962 6.987c0-1.123.866-2.06 1.96-2.06 1.095 0 1.984.937 1.984 2.06 0 1.1-.89 2.013-1.983 2.013-1.095 0-1.961-.913-1.961-2.013zm0-6.964c0-1.123.866-2.06 1.96-2.06 1.095 0 1.984.937 1.984 2.06 0 1.1-.89 2.013-1.983 2.013-1.095 0-1.961-.913-1.961-2.013zm.037-7.38c.226-1.099 1.261-1.832 2.333-1.6 1.07.231 1.753 1.335 1.527 2.434-.22 1.077-1.274 1.782-2.345 1.55-1.07-.23-1.735-1.307-1.515-2.384zm7.144 14.344c0-1.123.866-2.06 1.96-2.06 1.095 0 1.984.937 1.984 2.06 0 1.1-.89 2.013-1.983 2.013-1.095 0-1.961-.913-1.961-2.013z"
            clipRule="evenodd"
            key="key-0"
        />
    </Fragment>
);

const defaultProps = {
    children,
    viewBox,
    fillColor: null,
    fillColorRule: "&&& path, &&& use, &&& g, &&& circle",
    sizes,
    size: null
};

Image.propTypes /* remove-proptypes */ = {
    fillColor: PropTypes.string,
    fillColorRule: PropTypes.string,
    viewBox: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    size: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            height: PropTypes.number.isRequired,
            width: PropTypes.number.isRequired
        })
    ]),
    sizes: PropTypes.shape({
        height: PropTypes.number,
        width: PropTypes.number
    })
};

export default Object.assign(Image, {
    getDimensions,
    getCss,
    defaultProps,
    displayName: "Lingo"
});
