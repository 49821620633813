import React from "react";

// Context
import { useSlideshow } from "./index.js";

// styles
import { NextButtonStyled } from "./styles.js";

// components
import SrOnly from "core/SrOnly";
import CaretRight from "core/ReusableIcons/CaretRight.js";

const NextButton = () => {
    // State/Props/Methods/Hooks
    const {
        slideNext,
        showControls,
        isCarousel,
        current,
        components,
        totalSlides,
        onSlideNextClick
    } = useSlideshow();

    const currSlide = components.find(c => current === c.id);

    const isDisabled = !isCarousel && currSlide.slideNumber === totalSlides;

    const onClick = () => {
        onSlideNextClick();
        slideNext();
    };

    if (!showControls) {
        return null;
    }

    return (
        <NextButtonStyled type="button" onClick={onClick} disabled={isDisabled}>
            <SrOnly>Next</SrOnly>
            <CaretRight size={{ width: 40, height: 40 }} />
        </NextButtonStyled>
    );
};

export default NextButton;
