import merge from "lodash/merge";

import * as actionTypes from "../actions/actionTypes";

export const plugin = (state = { fetchingIcons: false }, action) => {
    let plugin = {},
        nextState = {};

    switch (action.type) {
        case actionTypes.SET_ADOBE_AGENT:
            let csInterface = new CSInterface();

            nextState = merge({}, state);
            nextState.adobeAgent = csInterface.getHostEnvironment().appId;
            return merge({}, state, nextState);
            break;
        case actionTypes.SET_PLUGIN_PAGE:
            return merge({}, state, {
                page: action.page
            });
        case actionTypes.SET_SEARCH_QUERY:
            return {
                ...state,
                page: 1
            };
        case actionTypes.SET_PLUGIN_HOST:
            return merge({}, state, {
                host: action.host
            });
            break;

        case actionTypes.FETCH_ICONS:
        case actionTypes.FETCH_PLUGIN_ICONS:
        case actionTypes.FETCH_RECENTLY_USED_PLUGIN_ICONS:
            return {
                ...state,
                fetchingIcons: true
            };

        case actionTypes.FETCH_ICONS_SUCCESS:
        case actionTypes.FETCH_PLUGIN_ICONS_SUCCESS:
        case actionTypes.FETCH_RECENTLY_USED_PLUGIN_ICONS_SUCCESS:
        case actionTypes.FETCH_ICONS_FAILURE:
        case actionTypes.FETCH_PLUGIN_ICONS_FAILURE:
        case actionTypes.FETCH_RECENTLY_USED_PLUGIN_ICONS_FAILURE:
            return {
                ...state,
                fetchingIcons: false
            };

        default:
            return state;
            break;
    }

    return Object.assign({}, state, nextState);
};
