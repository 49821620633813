import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// components
import ButtonPlain from "core/ButtonPlain";
import Close from "core/ReusableIcons/Close.js";

// styles
export const CloseButtonStyled = styled(ButtonPlain)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

// component
export const CloseButton = ({ onClick, iconProps }) => {
    return (
        <CloseButtonStyled onClick={onClick}>
            <Close {...iconProps} />
        </CloseButtonStyled>
    );
};

export default CloseButton;

// prop-types
CloseButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    iconProps: PropTypes.object
};
