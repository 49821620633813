import { createSlice } from "@reduxjs/toolkit";

import { REDUCERS } from "../../constants";

const initialState = {
    status: "idle", // ["idle", "loading"]
    approvalType: null, // ["deny", "visuallyPublish", "fullyPublish"]
    currentRequestId: undefined,
    error: null
};

export const uploadApprovalSlice = createSlice({
    name: REDUCERS.UPLOAD_APPROVAL,
    initialState,
    reducers: {}
});

export default uploadApprovalSlice.reducer;
