/* eslint-disable complexity */
/* eslint-disable max-statements */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";

// Components
import Link from "core/Link";
import PhotosGrid from "photos/PhotosGrid";
import { useCurrentUserContext } from "contexts/CurrentUserContext";
import NotFound from "core/NotFound";
import LoadingSpinner from "core/LoadingSpinner";
import Location from "core/ReusableIcons/Location";
import SearchAndBrowsePageHeadlineWithAvatar from "core/SearchAndBrowsePageHeadlineWithAvatar";

// Redux
import * as actions from "actions/actions";

// GraphQL
import { PHOTO_SEARCH as PHOTO_SEARCH_QUERY } from "js/graphql";
import {
    GET_PHOTOS_BY_TAG,
    GET_PHOTOS_BY_CREATOR,
    GET_CREATOR_INFO,
    GET_PHOTOS_BY_SHOOT_ID as GET_PHOTOS_BY_SHOOT_ID_QUERY,
    BROWSE_SIMILAR_PHOTOS as BROWSE_SIMILAR_PHOTOS_QUERY,
    BROWSE_PHOTOS_BY_CAMPAIGNS as BROWSE_PHOTOS_BY_CAMPAIGNS_QUERY
} from "./queries";

// Helpers
import { PUBLIC_PROFILE } from "data/links";
import { generatePageData } from "./helpers";

import colors from "core/colors";
import {
    HeaderTitle,
    SearchInfo,
    SearchResultsRow,
    EmptyResults,
    NoResultsText,
    ShootDetail,
    LocationIconContainer,
    SubSearchResultsRow
} from "./styles";
import styled from "styled-components";

const HeadlineLink = styled(Link)`
    font-weight: bold;
`;

const CreatorHeadline = ({ searchQuery, username }) => {
    const { data } = useQuery(GET_CREATOR_INFO, {
        variables: {
            username
        },
        fetchPolicy: "cache-and-network"
    });
    const name = data?.creator?.name || "";
    const avatarUrl = data?.creator?.avatarUrl || "";

    return (
        <SearchAndBrowsePageHeadlineWithAvatar
            avatarUrl={avatarUrl}
            showSkeleton={!data?.creator}
        >
            Results for "{searchQuery}" by{" "}
            <HeadlineLink
                href={PUBLIC_PROFILE.replace(
                    ":username",
                    encodeURIComponent(username)
                )}
            >
                {name}
            </HeadlineLink>
        </SearchAndBrowsePageHeadlineWithAvatar>
    );
};

export const PhotoSearchResults = ({
    searchQuery,
    tag,
    creator,
    shootId,
    photoId,
    campaignId,
    pageWidth
}) => {
    let key, variables, QUERY_TO_EXECUTE;

    const { currentUser } = useCurrentUserContext();
    const [isSubscribed, setIsSubscribed] = useState(false);
    const isCreatorSearch = !!creator && !!searchQuery;

    const BROWSE_PHOTOS_BY_TAG = "browsePhotosByTag";
    const PHOTO_SEARCH = "photoSearch";
    const BROWSE_PHOTOS_BY_CREATOR = "browsePhotosByCreator";
    const SEARCH_CREATOR_PHOTOS = "photoSearch";
    const GET_PHOTOS_BY_SHOOT_ID = "browsePhotosByShoot";
    const BROWSE_SIMILAR_PHOTOS = "browseSimilarPhotos";
    const BROWSE_PHOTOS_BY_CAMPAIGNS = "browsePhotosByCampaign";

    if (tag) {
        key = BROWSE_PHOTOS_BY_TAG;
        QUERY_TO_EXECUTE = GET_PHOTOS_BY_TAG;
        variables = {
            tag
        };
    } else if (isCreatorSearch) {
        key = SEARCH_CREATOR_PHOTOS;
        QUERY_TO_EXECUTE = PHOTO_SEARCH_QUERY;
        variables = {
            query: searchQuery,
            creator
        };
    } else if (searchQuery) {
        key = PHOTO_SEARCH;
        QUERY_TO_EXECUTE = PHOTO_SEARCH_QUERY;
        variables = {
            query: searchQuery
        };
    } else if (creator) {
        key = BROWSE_PHOTOS_BY_CREATOR;
        QUERY_TO_EXECUTE = GET_PHOTOS_BY_CREATOR;
        variables = {
            creator
        };
    } else if (shootId) {
        key = GET_PHOTOS_BY_SHOOT_ID;
        QUERY_TO_EXECUTE = GET_PHOTOS_BY_SHOOT_ID_QUERY;
        variables = {
            photoShootId: shootId,
            photoShootDetailId: shootId
        };
    } else if (photoId) {
        key = BROWSE_SIMILAR_PHOTOS;
        QUERY_TO_EXECUTE = BROWSE_SIMILAR_PHOTOS_QUERY;
        variables = {
            photoId,
            photoDetailId: photoId
        };
    } else if (campaignId) {
        key = BROWSE_PHOTOS_BY_CAMPAIGNS;
        QUERY_TO_EXECUTE = BROWSE_PHOTOS_BY_CAMPAIGNS_QUERY;
        variables = {
            campaignId,
            campaignDetailId: campaignId
        };
    }

    const { data, fetchMore } = useQuery(QUERY_TO_EXECUTE, {
        variables: {
            ...variables,
            limit: 50
        },
        fetchPolicy: "cache-and-network"
    });

    useEffect(() => {
        if (currentUser.data && currentUser.data.currentUser) {
            if (currentUser.data.currentUser.hasSubscription) {
                setIsSubscribed(true);
            }
        }
    }, [currentUser, currentUser.data]);

    // We need to wait for available data instead of loading, using loading here will break the scroll more feature
    if (!data) {
        return <LoadingSpinner />;
    }

    const {
        totalCount,
        headerTitle,
        headerDetails,
        noResultsText,
        shootDescription,
        shootLocation,
        notFound,
        descriptionText,
        campaignDescription
    } = generatePageData(
        {
            data,
            key,
            tag,
            searchQuery,
            creator,
            shootId,
            photoId,
            campaignId
        },
        {
            BROWSE_PHOTOS_BY_TAG,
            PHOTO_SEARCH,
            BROWSE_PHOTOS_BY_CREATOR,
            SEARCH_CREATOR_PHOTOS,
            GET_PHOTOS_BY_SHOOT_ID,
            BROWSE_SIMILAR_PHOTOS,
            BROWSE_PHOTOS_BY_CAMPAIGNS
        }
    );

    return notFound ? (
        <NotFound />
    ) : (
        <>
            {headerDetails}
            <SearchInfo>
                <SearchResultsRow>
                    {isCreatorSearch ? (
                        <CreatorHeadline
                            username={creator}
                            searchQuery={searchQuery}
                        />
                    ) : (
                        <HeaderTitle tag={tag} type="h6" size="xs">
                            {headerTitle}
                        </HeaderTitle>
                    )}
                </SearchResultsRow>

                {campaignId && campaignDescription && (
                    <SubSearchResultsRow>
                        <ShootDetail>{descriptionText}</ShootDetail>
                    </SubSearchResultsRow>
                )}

                {shootId && (shootLocation || shootDescription) && (
                    <SubSearchResultsRow>
                        <LocationIconContainer>
                            <Location
                                size="md"
                                fillColor={colors.secondary.oolong}
                            />
                        </LocationIconContainer>

                        <ShootDetail>{shootLocation}</ShootDetail>
                        <ShootDetail>{shootDescription}</ShootDetail>
                    </SubSearchResultsRow>
                )}
            </SearchInfo>

            {totalCount === 0 && (
                <EmptyResults>
                    <NoResultsText>
                        {`We didn’t find any photos ${noResultsText}`}
                    </NoResultsText>
                </EmptyResults>
            )}

            <PhotosGrid
                data={data}
                subscribed={isSubscribed}
                itemKey={key}
                pageWidth={pageWidth}
                fetchMore={fetchMore}
            />
        </>
    );
};

PhotoSearchResults.propTypes = {
    searchQuery: PropTypes.string,
    tag: PropTypes.string,
    creator: PropTypes.string,
    shootId: PropTypes.string,
    photoId: PropTypes.string,
    campaignId: PropTypes.string,
    pageWidth: PropTypes.number.isRequired
};

function mapStateToProps({ viewport }) {
    return {
        pageWidth: viewport.width
    };
}

export default connect(mapStateToProps, {
    saveScroll: actions.saveSearchScrollPosition
})(PhotoSearchResults);
