import { useState } from "react";
import querystring from "query-string";

import { UPLOAD_TYPES } from "../PhotoUploadCore/helpers";

export const useCurrentUploadType = location => {
    const { selected: selectedQueryParam } = querystring.parse(location.search);

    const [currentUploadType, setCurrentUploadType] = useState(
        selectedQueryParam || UPLOAD_TYPES.INDIVIDUAL
    );

    const selectIndividualUploadType = () =>
        setCurrentUploadType(UPLOAD_TYPES.INDIVIDUAL);

    const selectPhotoShootUploadType = () => {
        setCurrentUploadType(UPLOAD_TYPES.PHOTO_SHOOT);
    };

    return {
        currentUploadType,
        setCurrentUploadType,
        selectIndividualUploadType,
        selectPhotoShootUploadType
    };
};

export const updateQueryParams = (history, location, currentUploadType) => {
    const newQueryString = querystring.stringify({
        ...querystring.parse(location.search),
        selected: currentUploadType
    });
    const currentLocationWithNewQueryString = {
        pathname: location.pathname,
        search: newQueryString
    };

    history.replace(currentLocationWithNewQueryString);
};
