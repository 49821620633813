/*---------------------------
| Store
---------------------------*/
// Action Types
const actionPrefix = "FormSelectDialogContext:";
const actionTypes = {
    SET_HAS_OPENED_BEFORE: `${actionPrefix} setHasOpenedBefore`,
    SET_IS_OPEN: `${actionPrefix} setIsOpen`,
    SET_OPEN_CLOSE_BUTTON_IS_IN_FOCUS: `${actionPrefix} setOpenCloseButtonIsInFocus`
};

// Action Creators
export const actions = {
    setHasOpenedBefore: hasOpenedBefore => {
        return {
            type: actionTypes.SET_HAS_OPENED_BEFORE,
            hasOpenedBefore
        };
    },
    setIsOpen: isOpen => {
        return {
            type: actionTypes.SET_IS_OPEN,
            isOpen
        };
    },
    setOpenCloseButtonIsInFocus: openCloseButtonIsInFocus => {
        return {
            type: actionTypes.SET_OPEN_CLOSE_BUTTON_IS_IN_FOCUS,
            openCloseButtonIsInFocus
        };
    }
};

// Reducer
export const reducer = (state, action) => {
    const { type } = action;

    switch (type) {
        case actionTypes.SET_HAS_OPENED_BEFORE: {
            return { ...state, hasOpenedBefore: action.hasOpenedBefore };
        }
        case actionTypes.SET_IS_OPEN: {
            return { ...state, isOpen: action.isOpen };
        }
        case actionTypes.SET_OPEN_CLOSE_BUTTON_IS_IN_FOCUS: {
            return {
                ...state,
                openCloseButtonIsInFocus: action.openCloseButtonIsInFocus
            };
        }
        default: {
            return { ...state };
        }
    }
};
