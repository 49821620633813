/**
 * Exports a Redux middleware that logs actions & store state to Sentry.
 *
 */

import * as Sentry from "@sentry/browser";
import createSentryMiddleware from "redux-sentry-middleware";
import omit from "lodash/omit";
import pick from "lodash/pick";

// These action types will not be added to breadcrumbs, since they would clutter the logs
const breadcrumbActionBlacklist = [];

/**
 * Whitelist known small pieces of state
 */
const stateTransformer = function(state) {
    let filtered = pick(state, [
        "plugin",
        "search",
        "user",
        "apiResponse",
        "stripe",
        "download",
        "experiments",
        "editor",
        "viewport"
    ]);

    filtered = omit(filtered, [
        "editor.svg",
        "download.pendingDownload.raw_svg"
    ]);

    return filtered;
};
/**
 * Filters out some of the larger pieces of actions
 */
const actionTransformer = function(actions) {
    return omit(actions, ["icons", "response", "asyncDispatch"]);
};
const filterBreadcrumbActions = function(action) {
    return !breadcrumbActionBlacklist.includes(action.type);
};

export default createSentryMiddleware(Sentry, {
    stateTransformer,
    actionTransformer,
    filterBreadcrumbActions
});
