import React from "react";

import CreatorDashboardPhotosTabs from "./CreatorDashboardPhotosTabs";
import IconsDashboardLink from "./IconsDashboardLink";
import UploadPhotosLink from "./UploadPhotosLink";
import Heading from "core/Heading";

import { NavContainer, HeadingContainer } from "./styles";

import breakpoints from "core/breakpoints";
import { useViewport } from "js/hooks/useViewport";

const CreatorDashboardPhotosMasthead = () => {
    const { browserWidth } = useViewport();
    const isMobile = browserWidth <= breakpoints.beforeTablet.px;

    return (
        <>
            <HeadingContainer>
                <Heading type="h1" size="xl">
                    Your Photographer Dashboard
                </Heading>
                <UploadPhotosLink />
            </HeadingContainer>
            <NavContainer>
                {isMobile && <IconsDashboardLink />}
                <CreatorDashboardPhotosTabs isMobile={isMobile} />
                {!isMobile && <IconsDashboardLink />}
            </NavContainer>
        </>
    );
};

export default CreatorDashboardPhotosMasthead;
