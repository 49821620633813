import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import {
    spacingSizes,
    headingSizes,
    alignments,
    headingTypes
} from "core/props";

import colors from "core/colors";
import Heading from "core/Heading";

import { Container, IconContainer, Icon } from "./styles";

const UnstyledSocialProof = ({
    className,
    icons,
    headingType = "div",
    headingSize = "xl",
    headingColor = colors.secondary.orca,
    headingBottomSpacing = "2xl",
    headingAlignment = "center",
    headingText = "Trusted by Brands You Know",
    iconsAlignment = "center"
}) => {
    return (
        <Container className={className}>
            <Heading
                type={headingType}
                size={headingSize}
                headingColor={headingColor}
                bottomSpacing={headingBottomSpacing}
                alignment={headingAlignment}
            >
                {headingText}
            </Heading>

            <IconContainer iconsAlignment={iconsAlignment}>
                {icons.map((individualIcon, index) => (
                    <Icon key={index}>
                        {!!individualIcon.link ? (
                            // if there is a link, render link + icon:
                            <a
                                href={individualIcon.link}
                                target={
                                    individualIcon.linkTarget
                                        ? individualIcon.linkTarget
                                        : "_self"
                                }
                            >
                                {individualIcon.icon}
                            </a>
                        ) : (
                            // else no link, just render icon:
                            individualIcon.icon
                        )}
                    </Icon>
                ))}
            </IconContainer>
        </Container>
    );
};

const SocialProof = styled(UnstyledSocialProof)``;

SocialProof.propTypes = {
    icons: PropTypes.arrayOf(
        PropTypes.shape({
            icon: PropTypes.element.isRequired,
            title: PropTypes.string,
            link: PropTypes.string,
            linkTarget: PropTypes.string
        })
    ).isRequired,
    headingType: PropTypes.oneOf(headingTypes),
    headingSize: PropTypes.oneOf(headingSizes),
    headingBottomSpacing: PropTypes.oneOf(spacingSizes),
    headingAlignment: PropTypes.oneOf(alignments),
    headingColor: PropTypes.string,
    headingText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    iconsAlignment: PropTypes.oneOf(alignments)
};

export default SocialProof;
