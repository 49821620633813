import React from "react";

// styles
import { DividerStyled } from "./styles.js";

// context
import BatchApprovalProvider from "./useBatchApproval";

// constants
import {
    QUEUES,
    MODERATION_TYPES
} from "frontend/components/fort/mod/photos/constants";

// components
import FullyPublishButton from "./fullyPublish/FullyPublishButton.js";
import DenyButton from "./deny/DenyButton.js";
import VisuallyApproveButton from "./visuallyApprove/VisuallyApproveButton.js";

// component
export const VisModControls = () => {
    return (
        <BatchApprovalProvider
            moderationType={MODERATION_TYPES[QUEUES.VIS_MOD]}
        >
            <FullyPublishButton variant="clear" />
            <DividerStyled />
            <DenyButton variant="dark" />
            <VisuallyApproveButton variant="blue" />
        </BatchApprovalProvider>
    );
};

export default VisModControls;
