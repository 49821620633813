import React, { useRef, useCallback } from "react";
import styled from "styled-components";

import { useReleases, MAX_RELEASES } from "../Releases/hooks/useReleases";
import Link from "core/Link";
import { textLg } from "core/fontStyles";

const FilepickerInput = styled.input`
    display: none;
`;

const UploadButton = styled(Link)`
    ${textLg};
`;

const ReleaseUploadButton = () => {
    const { uploadFiles, releases } = useReleases();
    const filePickerRef = useRef();

    const handleOpenFilePicker = useCallback(
        e => {
            e.preventDefault();
            filePickerRef.current.click();
        },
        [filePickerRef]
    );

    const totalReleaseCount = releases.length;

    return (
        <>
            <UploadButton
                onClick={e => handleOpenFilePicker(e)}
                aria-disabled={totalReleaseCount >= MAX_RELEASES}
                isButton
            >
                Upload a Release
            </UploadButton>
            <FilepickerInput
                ref={filePickerRef}
                type="file"
                accept=".pdf, application/pdf"
                multiple
                value={""} // need empty val for same file name upload
                onChange={e => uploadFiles(Array.from(e.target.files))}
            />
        </>
    );
};

export default ReleaseUploadButton;
