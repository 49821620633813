export const backgroundColors = {
    black: "#16161d",
    grayLight: "#f2f2f2"
};

export const colors = {
    black: "#000000",
    white: "#fafafa",
    darkSkyBlue: "#3ea9f5",
    coral: "#f44e4e",
    darkMint: "#43c978",
    veryLightPinkTwo: "#bababa",
    brownGrey: "#949494",
    brownishGrey: "#6d6d6d",
    dark: "#16161d",
    aquaGreen: "#11ebaa",
    duskBlue: "#1f587f",
    bluish: "#2e80b7",
    paleAqua: "#cbf4dc",
    jungleGreen: "#087957",
    aquaGreenTwo: "#10ca93",
    lightPink: "#fdebec",
    dullRed: "#b73b3b",
    brownGreyTwo: "#999999",
    charcoalGrey: "#41414d",
    blackDuplicate: "#1f1f1f",
    whiteDuplicate: "#ffffff",
    duskBlueTwo: "#1f577d",
    darkTwo: "#111115",
    charcoalGreyTwo: "#2d2d34",
    veryLightPinkTwoDuplicate: "#dddddd",
    slateGrey: "#616171",
    veryLightPinkThree: "#cccccc"
    // create RGBA helper for these to reuse existing hex values:
    // white10: rgba(255, 255, 255, 0.1),
};
