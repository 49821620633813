export const OPEN_SEARCH_LIMIT = 10000;

export const ICONS_LIMIT = 200;
export const COLLECTIONS_LIMIT = 99;
export const PHOTOS_LIMIT = 50;

export const ICONS_PER_COLLECTION = 6;

export const MAX_ICONS_PAGE = Math.floor(OPEN_SEARCH_LIMIT / ICONS_LIMIT);
export const MAX_COLLECTIONS_PAGE = Math.floor(
    OPEN_SEARCH_LIMIT / COLLECTIONS_LIMIT
);
export const MAX_PHOTOS_PAGE = Math.floor(OPEN_SEARCH_LIMIT / PHOTOS_LIMIT);

export const ICONS_DOM_PAGE_LIMIT = 10;
export const COLLECTIONS_DOM_PAGE_LIMIT = 5;
export const PHOTOS_DOM_PAGE_LIMIT = 10;
