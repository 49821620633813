import merge from "lodash/merge";
import omit from "lodash/omit";
import union from "lodash/union";
import isArray from "lodash/isArray";
import _isNumber from "lodash/isNumber";
import _isString from "lodash/isString";

import * as actionTypes from "../actions/actionTypes";
import localStorage from "../legacy/helpers/localStorage";

export const WITH_ATTRIBUTION = "WITH_ATTRIBUTION";
export const NO_ATTRIBUTION = "NO_ATTRIBUTION";

export const makeSvgKey = attribution => `raw_svg_${attribution.toLowerCase()}`;

export const pluginsRecentlyUsed = (state = {}, action) => {
    let arrays = {},
        icons = {},
        nextState = {},
        imgChoices;

    switch (action.type) {
        case actionTypes.SEARCH_RESET_ICONS: {
            let newState = { ...state };
            if (state.keys) {
                state.keys.forEach(id => delete newState[id]);
            }
            return {
                ...newState,
                keys: null,
                endOfResults: false
            };
        }
        case actionTypes.FETCH_HERO_ICON_SUCCESS:
            nextState = action.response;
            let keys = [];
            if (state.keys) {
                keys = state.keys;
            }

            keys.forEach(iconId => {
                if (!nextState.keys) {
                    return;
                }
                if (iconId === nextState.keys[0]) {
                    nextState = omit(nextState, "keys");
                }
            });
            nextState = merge({}, state, nextState);
            nextState = merge({}, nextState, {
                active_icon: { id: action.icon_id }
            });
            return nextState;
            break;
        case actionTypes.SEARCH_ICONS:
        case actionTypes.FETCH_BEST_COLLECTIONS: {
            let { searchQuery } = action;
            if (!searchQuery) {
                searchQuery = true;
            }
            return {
                ...state,
                processing: searchQuery
            };
        }
        case actionTypes.FETCH_BEST_COLLECTIONS_FAILURE:
        case actionTypes.SEARCH_ICONS_FAILURE:
            return {
                ...state,
                processing: false
            };
        case actionTypes.FETCH_BEST_COLLECTIONS_SUCCESS:
        case actionTypes.SEARCH_ICONS_SUCCESS: {
            let { searchQuery } = action;
            if (searchQuery && _isString(state.processing)) {
                if (searchQuery != state.processing) {
                    console.log("query mismatch", {
                        waiting: state.processing,
                        searchQuery
                    });
                    return state;
                }
            }
            let { active_icon } = state;
            let activeId;
            if (active_icon) {
                activeId = active_icon.id;
            }

            arrays = {
                union: ["keys"],
                replace: ["counts"]
            };

            if (activeId) {
                arrays.union.push(activeId);
            }

            icons = action.response && { ...action.response };
            if (state.keys) {
                nextState = mergeEntity("icons", null, state, icons, arrays);
            } else {
                nextState = Object.assign({}, state, icons);
            }
            nextState.result = action.type;

            let totalResults, endOfResults;
            if (_isNumber(nextState.search_total)) {
                totalResults = nextState.search_total;
            } else if (nextState.counts) {
                totalResults = nextState.counts.icons;
            } else {
                let { limit } = action;
                if (_isNumber(limit) && action.response) {
                    if (action.response && action.response.keys) {
                        if (action.response.keys.length < limit) {
                            endOfResults = true;
                        }
                    }
                }
            }
            endOfResults =
                endOfResults ||
                (_isNumber(totalResults) &&
                    totalResults == nextState.keys.length);
            return {
                ...state,
                ...nextState,
                totalResults,
                endOfResults,
                processing: false
            };
        }
        case actionTypes.FETCH_VIEW_MORE_ICONS_FOR_RECENTLY_USED_ICON_DETAIL_PAGE_SUCCESS:
            arrays = {
                union: ["keys"],
                replace: ["counts"]
            };

            icons.viewMore = action.response;
            if (icons.viewMore.keys.length > 0) {
                icons.viewMore.keys.forEach(uploadId => {
                    nextState = mergeEntity(
                        "icons",
                        uploadId,
                        state,
                        icons,
                        arrays
                    );
                });
            } else {
                nextState = Object.assign({}, state);
                nextState.viewMore = {
                    total_related_count: 0,
                    keys: []
                };
            }
            if (state.viewMore !== undefined) {
                let icon = state.viewMore[action.iconId];
                if (icon !== undefined) {
                    nextState[icon.id] = icon;
                }
            }
            nextState.result = action.type;
            nextState.viewMore.iconId = action.iconId;
            return Object.assign({}, state, nextState);
            break;
        case actionTypes.FETCH_RECENTLY_USED_PLUGIN_ICONS_SUCCESS:
            nextState = action.response;
            nextState.colorPalette = state.colorPalette;
            nextState.imgChoices = state.imgChoices;
            nextState.selectedColor = state.selectedColor;
            nextState.show_icon_detail = state.show_icon_detail;
            return merge({}, nextState);

        case actionTypes.FETCH_RECENTLY_USED_SVG_DATA: {
            let { attribution } = action;
            return merge({}, state, {
                active_icon: {
                    id: action.iconId,
                    loadingRawSVG: attribution || true
                }
            });
            break;
        }
        case actionTypes.FETCH_RECENTLY_USED_SVG_DATA_FAILURE:
            return merge({}, state, {
                active_icon: {
                    loadingRawSVG: false
                }
            });
            break;
        case actionTypes.FETCH_RECENTLY_USED_SVG_DATA_SUCCESS:
            let { iconId, attribution } = action;

            let icon = (icon = state[action.iconId]);

            let svgKey;

            if (attribution) {
                svgKey = makeSvgKey(attribution);
                if (icon) {
                    state[action.iconId] = merge({}, icon, {
                        [svgKey]: action.response
                    });
                } else {
                    // console.warn(action.iconId, "not found");
                    state[action.iconId] = {
                        id: action.iconId,
                        [svgKey]: action.response
                    };

                    state.keys = state.keys || [];
                    state.keys.push(iconId);
                }
            } else {
                svgKey = attribution
                    ? makeSvgKey(attribution)
                    : makeSvgKey(NO_ATTRIBUTION);
            }

            let { active_icon } = state;
            if (!active_icon || active_icon.id == action.iconId) {
                active_icon = {
                    id: action.iconId,
                    raw_svg: action.response,
                    [svgKey]: action.response,
                    loadingRawSVG: false
                };
            }

            return merge({}, state, {
                active_icon
            });
        case actionTypes.SAVE_CLEANED_RECENTLY_USED_SVG:
            return merge({}, state, {
                active_icon: { cleaned_svg: action.cleanedSVGData }
            });
            break;
        case actionTypes.FETCH_RECENTLY_USED_ICON_SUCCESS:
            nextState = action.response;
            if (
                nextState[action.iconId] &&
                state[action.iconId] &&
                nextState[action.iconId].icon_url.includes("cloudfront.net")
            ) {
                // until api icon lookup is updated dont use old cloudfront urls

                nextState[action.iconId].icon_url =
                    state[action.iconId].icon_url;
                nextState[action.iconId].preview_url =
                    state[action.iconId].preview_url;
                nextState[action.iconId].preview_url_42 =
                    state[action.iconId].preview_url_42;
                nextState[action.iconId].preview_url_84 =
                    state[action.iconId].preview_url_84;
            }
            // NOTE: this may need to be added back and changed for the new hero
            // nextState = omit(nextState, "keys");
            nextState = merge({}, state, nextState);
            return nextState;
            break;
        case actionTypes.SET_ACTIVE_RECENTLY_USED_ICON:
            return merge({}, state, { active_icon: { id: action.icon_id } });
            break;
        case actionTypes.DOWNLOAD_SET_FLOW_ACTIVE: {
            let { icon } = action;
            let cached = state[icon.id];

            let active_icon = merge({}, cached, {
                id: icon.id
            });

            return {
                ...state,
                active_icon
            };
        }
        case actionTypes.CLEAR_ACTIVE_ICON:
            nextState = merge({}, state);
            nextState = omit(nextState, "active_icon");
            return nextState;
            break;
        case actionTypes.SET_ICON_COLOR_PALETTE:
            return merge({}, state, {
                colorPalette: action.color.customColors,
                selectedColor: action.color.selectedColor
            });
            break;
        case actionTypes.GET_ICON_META_DATA:
            let localStoragePalette = localStorage.getItem(
                    "customColorPalette"
                ),
                selectedColor = localStorage.getItem("selectedColor");

            imgChoices = localStorage.getItem("imgChoices");

            if (localStoragePalette === null) {
                localStoragePalette = [
                    { hex: "#EC5D57" },
                    { hex: "#F38F19" },
                    { hex: "#F5D327" },
                    { hex: "#70C041" },
                    { hex: "#51A7F9" },
                    { hex: "#B36AE2" }
                ];
            } else {
                localStoragePalette = JSON.parse(localStoragePalette);
            }

            if (selectedColor === null) {
                selectedColor = "#000000";
            } else {
                if (
                    selectedColor === "undefined" ||
                    selectedColor === undefined
                ) {
                    localStorage.removeItem("selectedColor");
                    selectedColor = "#000000";
                } else {
                    selectedColor = JSON.parse(selectedColor).hex;
                }
            }

            if (imgChoices === null) {
                const sizeKeys = [
                        "24",
                        "84",
                        "150",
                        "300",
                        "600",
                        "1200",
                        "custom"
                    ],
                    imgSizes = {};
                sizeKeys.forEach(size => {
                    imgSizes[size] = { size: `${size}px` };

                    if (size === "custom") {
                        imgSizes[size] = { size: "" };
                    }
                });

                imgChoices = {
                    sizes: imgSizes,
                    keys: sizeKeys,
                    selectedSize: "300px"
                };
            } else {
                imgChoices = JSON.parse(imgChoices);
            }

            nextState = merge({}, state, {
                colorPalette: localStoragePalette,
                selectedColor: { hex: selectedColor },
                imgChoices: imgChoices
            });
            return nextState;
            break;
        case actionTypes.SET_ICON_SIZE:
            // set local storage
            imgChoices = {};
            imgChoices["sizes"] = action.imgChoices.sizes;
            imgChoices["selectedSize"] = action.imgChoices.selectedSize;
            imgChoices["keys"] = action.imgChoices.keys;
            if (window.localStorage) {
                localStorage.setItem("imgChoices", JSON.stringify(imgChoices));
            }

            nextState = merge({}, state, {
                imgChoices: action.imgChoices
            });
            break;
        case actionTypes.SHOW_RECENTLY_USED_ICON_DETAIL:
            nextState = {
                show_icon_detail: true
            };
            return merge({}, state, nextState);
            break;

        case actionTypes.HIDE_RECENTLY_USED_ICON_DETAIL:
            return merge({}, state, {
                show_icon_detail: false
            });
            break;

        case actionTypes.FETCH_FEATURED_COLLECTIONS_SUCCESS:
            nextState = action.response;
            return merge({}, state, {
                featured_collections: { [action.collectionId]: nextState }
            });
            break;
        case actionTypes.FETCH_FORT_ICON_SUCCESS:
            nextState = action.response;
            return mergeEntity("icons", null, state, action.response, {
                union: [],
                replace: []
            });
            break;
        case actionTypes.FEATURE_ICON_SUCCESS: {
            nextState = merge({}, state);
            let icon = nextState[action.icon_upload_id];
            if (icon) {
                icon.featured_icon = true;
            }
            return merge({}, state, nextState);
            break;
        }
        case actionTypes.UNFEATURE_ICON_SUCCESS:
            nextState = merge({}, state);
            nextState[action.icon_upload_id].featured_icon = false;
            return merge({}, state, nextState);
            break;
        case actionTypes.REGENERATE_ICON_INFO_SUCCESS:
            nextState = merge({}, state);
            return merge({}, state, nextState);
            break;
        case actionTypes.DISABLE_ICON_SUCCESS:
            nextState = merge({}, state);
            nextState[action.icon_id].is_active = "0";
            return merge({}, state, nextState);
            break;
        case actionTypes.FETCH_TOTAL_ICON_COUNT_SUCCESS:
            nextState = merge({}, state);
            nextState.totalIcons = action.response.icons;
            return merge({}, state, nextState);
        case actionTypes.SAVE_ICON_INFO:
            return {
                ...state,
                processing: true
            };
        case actionTypes.SAVE_ICON_INFO_SUCCESS:
        case actionTypes.SAVE_ICON_INFO_FAILURE:
            return {
                ...state,
                processing: false
            };
        default:
            return state;
            break;
    }

    return Object.assign({}, state, nextState);
};

const mergeEntity = (type, id, state, newObj, arrays = {}) => {
    if (state) {
        arrays.union.forEach(a => {
            if (newObj[a] && state[a]) {
                if (isArray(newObj[a])) {
                    newObj[a] = union(state[a], newObj[a]);
                } else {
                    newObj[a] = merge(state[a], newObj[a]);
                }
            }
        });
        arrays.replace.forEach(a => {
            if (newObj[a] && state[a]) {
                state[a] = newObj[a];
            }
        });
        newObj = Object.assign({}, state[id], newObj);
    }

    return Object.assign({}, state, newObj);
};
