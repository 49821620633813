import React from "react";
import PropTypes from "prop-types";

// styles
import { LocationStyled } from "./styles.js";

// QueueContext
import { useQueueContext } from "frontend/components/fort/mod/core/Layouts/QueueLayout";

export const Location = ({ onLocationUpdate }) => {
    // state/context
    const queueContext = useQueueContext();

    const {
        uploadType,
        state: { singleUploadSelected }
    } = queueContext;

    if (!singleUploadSelected) return null;

    const location =
        uploadType === "photo"
            ? singleUploadSelected.photoLocation
            : singleUploadSelected.iconLocation;

    const handleOnLocationUpdate = () => {
        onLocationUpdate({ location: "WIP: Upload Location" });
    };

    if (!location) return null;

    return (
        <LocationStyled onClick={handleOnLocationUpdate}>
            {location}
        </LocationStyled>
    );
};

export default Location;

// prop-types
Location.propTypes = {
    onLocationUpdate: PropTypes.func.isRequired
};
