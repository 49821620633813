import { useFetchBatchUploads } from "./uploads/useFetchBatchUploads";

/**
 * GQL `PhotoUploadType` is the basic upload response object
 * GQL `PhotoUploadWithModerationDataType` is the upload response object that
 *  contains all the additional MOD props needed for synching
 * Some GQLs are shared with UPL. In these cases we must rely on GQL `PhotoUploadType`
 * This means we cannot rely on it for synching MOD props (e.g. moderationNotes)
 * In these cases, we will refetch uploads for selected batch
 * Websockets takes care of this for fellow moderators, this takes care of it for current moderator. *
 */
export const useFetchNonModMutationSynch = () => {
    const { fetchBatchUploads } = useFetchBatchUploads();

    const fetchNonModMutationSynch = async () => {
        await fetchBatchUploads({ showLoading: false });
    };

    return {
        fetchNonModMutationSynch
    };
};
