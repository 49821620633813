import { createSlice } from "@reduxjs/toolkit";

import { REDUCERS } from "../../constants";
import { QUEUE_VIEWS } from "../../../core/constants";

const initialState = {
    id: null,
    contentIsOpen: false,
    currentView: QUEUE_VIEWS.ACTIVE,
    previousView: null,
    largeImageIds: []
};

export const selectedBatchSlice = createSlice({
    name: REDUCERS.SELECTED_BATCH,
    initialState,
    reducers: {
        selectBatch: (state, action) => {
            if (state.id !== action.payload) {
                return {
                    ...initialState,
                    id: action.payload,
                    contentIsOpen: false
                };
            }
        },
        toggleBatch: (state, action) => {
            state.contentIsOpen = !state.contentIsOpen;
        },
        setContentIsOpen: (state, action) => {
            state.contentIsOpen = action.payload;
        },
        setActiveView: state => {
            if (state.currentView !== QUEUE_VIEWS.ACTIVE) {
                state.previousView = state.currentView;
                state.currentView = QUEUE_VIEWS.ACTIVE;
                state.largeImageIds = [];
            }
        },
        setSavedView: state => {
            if (state.currentView !== QUEUE_VIEWS.SAVED) {
                state.previousView = state.currentView;
                state.currentView = QUEUE_VIEWS.SAVED;
                state.largeImageIds = [];
            }
        },
        reset: () => initialState
    }
});

export const {
    setActiveView,
    setSavedView,
    selectBatch,
    toggleBatch,
    setContentIsOpen
} = selectedBatchSlice.actions;

export default selectedBatchSlice.reducer;
