import React from "react";
import PropTypes from "prop-types";

import Content from "./Content";

const BulletSection = ({
    bullets,
    sectionHeading,
    sectionHeadingColor,
    className
}) => (
    <div className={className}>
        <Content
            sectionHeadingColor={sectionHeadingColor}
            sectionHeading={sectionHeading}
            bullets={bullets}
        />
    </div>
);

BulletSection.propTypes = {
    bullets: PropTypes.arrayOf(
        PropTypes.shape({
            icon: PropTypes.element,
            title: PropTypes.string,
            text: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
        })
    ).isRequired,
    sectionHeading: PropTypes.string,
    className: PropTypes.string
};

export default BulletSection;
