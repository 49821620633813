import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { useViewport } from "js/hooks/useViewport";

import { OverflowMenuMobile } from "components/OverflowMenuMobile";
import { OverflowMenuDesktop } from "components/OverflowMenuDesktop";
import TriggerButton from "./TriggerButton";

import Div from "core/Div";
import breakpoints from "core/breakpoints";
import { Wrapper } from "./styles";

let OverflowMenu = ({ transparent, isHeroSearchV2 }) => {
    const [overflowMenuIsOpen, setOverflowMenuIsOpen] = useState(false);
    const toggleOverflowMenu = () => setOverflowMenuIsOpen(!overflowMenuIsOpen);
    const closeOverflowMenu = () => setOverflowMenuIsOpen(false);

    const trigger = useRef();
    const desktopMenu = useRef();
    const mobileMenu = useRef();

    const { browserWidth } = useViewport();
    const mobileBreakpoint = breakpoints.beforeTablet.px;

    useEffect(() => {
        if (overflowMenuIsOpen) {
            document.addEventListener("mousedown", handleClick);
            if (browserWidth < mobileBreakpoint) {
                document.querySelector("body").style.overflowY = "hidden";
            }
        }

        return () => {
            document.removeEventListener("mousedown", handleClick);
            if (browserWidth < mobileBreakpoint) {
                document.querySelector("body").style.overflowY = "auto";
            }
        };
    }, [overflowMenuIsOpen]);

    const handleClick = e => {
        const clickedInsideDesktopMenu =
            desktopMenu && desktopMenu.current.contains(e.target);
        const clickedInsideMobileMenu =
            mobileMenu && mobileMenu.current.contains(e.target);
        const clickedTrigger = trigger && trigger.current.contains(e.target);

        if (
            clickedInsideMobileMenu ||
            clickedInsideDesktopMenu ||
            clickedTrigger
        ) {
            return;
        }

        closeOverflowMenu();
    };

    return (
        <Wrapper open={overflowMenuIsOpen}>
            <TriggerButton
                size="sm"
                screenReaderText="Overflow Menu"
                transparent={transparent}
                triggerReference={trigger}
                onClick={toggleOverflowMenu}
                active={overflowMenuIsOpen}
                isHeroSearchV2={isHeroSearchV2}
                isHeader
            />

            <Div ref={desktopMenu}>
                <OverflowMenuDesktop
                    closeMenu={closeOverflowMenu}
                    isHeroSearchV2={isHeroSearchV2}
                />
            </Div>
            <Div ref={mobileMenu}>
                <OverflowMenuMobile
                    closeMenu={closeOverflowMenu}
                    isHeroSearchV2={isHeroSearchV2}
                />
            </Div>
        </Wrapper>
    );
};

OverflowMenu = styled(OverflowMenu)``;

OverflowMenu.propTypes = {
    transparent: PropTypes.bool
};

export { OverflowMenu };
