import React from "react";
import PropTypes from "prop-types";

// styles
import { BatchBodyStyled, BatchInnerChildStyled } from "./styles";

// components
import BatchUploadCollectionsList from "./BatchUploadCollectionsList";

// hooks
import { useCalculateHeight } from "./useCalculateHeight";

export const BatchBody = ({
    batch,
    batchContentIsOpen,
    batchUploadCollections,
    allBatchUploadIds,
    BatchGridComponent,
    uploadsStatus = "loading",
    setActiveSaveView
}) => {
    const { innerChildRef, containerHeight } = useCalculateHeight({
        allBatchUploadIds,
        batchContentIsOpen,
        uploadsStatus
    });

    return (
        <BatchBodyStyled containerHeight={containerHeight}>
            <BatchInnerChildStyled ref={innerChildRef}>
                <BatchUploadCollectionsList
                    batch={batch}
                    batchContentIsOpen={batchContentIsOpen}
                    batchUploadCollections={batchUploadCollections}
                    BatchGridComponent={BatchGridComponent}
                    setActiveSaveView={setActiveSaveView}
                />
            </BatchInnerChildStyled>
        </BatchBodyStyled>
    );
};

BatchBody.propTypes = {
    batch: PropTypes.object.isRequired,
    batchContentIsOpen: PropTypes.bool,
    batchUploadCollections: PropTypes.array.isRequired,
    allBatchUploadIds: PropTypes.array.isRequired,
    BatchGridComponent: PropTypes.any.isRequired,
    setActiveSaveView: PropTypes.func.isRequired,
    uploadsStatus: PropTypes.string
};
