import React from "react";

// Used in tests, don't remove
const TestToast = () => {
    <div data-test-id="test-toast">Test toast</div>;
};
export const TEST_TOAST = "TEST_TOAST";
export const ToastTypes = {
    [TEST_TOAST]: TestToast
};
