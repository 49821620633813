import $ from "jquery";
import isFunction from "lodash/isFunction";
import isString from "lodash/isString";
import getCookie from "../helpers/get_cookie";

// Like the CALL_API middleware but returns promise that resolves when request completes
const callApiPromise = ({
    types,
    endpoint,
    method,
    data,
    actionPayload = {},
    contentType,
    dataType
}) => (dispatch, getState) => {
    let requestType, successType, failureType;

    if (types) {
        [requestType, successType, failureType] = types;
    }

    let csrfCookie;
    if (!global.csrfCookieName) {
        console.warn("global.csrfCookieName not set");
    } else {
        csrfCookie = getCookie(csrfCookieName);
    }

    if (requestType) {
        const requestTypeAction = isFunction(requestType)
            ? requestType()
            : { ...actionPayload, type: requestType };
        dispatch && dispatch(requestTypeAction);
    }
    return new Promise((resolve, reject) => {
        const onError = error => {
            console.error("failed request", endpoint, method, error.statusText);
            if (isString(error)) {
                try {
                    error = JSON.parse(error);
                } catch (e) {}
            }
            const payload = { error };

            if (failureType) {
                const failureTypeAction = isFunction(failureType)
                    ? failureType(payload)
                    : { ...actionPayload, type: failureType, ...payload };
                dispatch && dispatch(failureTypeAction);
            }
            return reject(error);
        };

        $.ajax({
            type: method,
            url: endpoint,
            data,
            json: true,
            contentType,
            dataType,
            beforeSend: function(xhr) {
                if (csrfCookie) {
                    xhr.setRequestHeader("X-CSRFToken", csrfCookie);
                }
            }
        })
            .done(response => {
                if (
                    dataType == "text" &&
                    contentType &&
                    contentType.indexOf("application/json") >= 0
                ) {
                    try {
                        response = JSON.parse(response);
                    } catch (e) {
                        console.warn(
                            "JSON api call, but JSON parsing failed",
                            e
                        );
                    }
                }
                if (response && response.errors) {
                    return onError(response);
                }
                const payload = {
                    ...actionPayload,
                    response,
                    apiResponse: true
                };
                if (successType) {
                    const successTypeAction = isFunction(successType)
                        ? successType(payload)
                        : { type: successType, ...payload };
                    dispatch && dispatch(successTypeAction);
                }
                return resolve(response);
            })
            .fail(onError);
    });
};

export default callApiPromise;
