/* eslint-disable no-mixed-operators */
import styled, { css } from "styled-components";
import PropTypes from "prop-types";

import colors from "core/colors";

export const TableColumnHighlight = styled.div(
    ({ width, height, color, borderWidth, colNumber }) => css`
        position: absolute;
        border: ${borderWidth}px solid ${color};
        bottom: -${borderWidth / 2}px;
        left: ${width * (colNumber - 1) - borderWidth / 2}px;
        min-width: ${width + borderWidth * 2}px;
        min-height: ${height + borderWidth}px;
    `
);

export const TableRow = styled.tr`
    display: flex;
`;

export const TableHead = styled.thead``;

export const TableBody = styled.tbody``;

const getJustifyContentValue = ({ align }) => {
    if (align === "left") return "flex-start";
    if (align === "right") return "flex-end";
    if (align === "center") return "center";

    return "center";
};

const tableCellStyles = css`
    display: flex;
    align-items: center;
    padding-left: 0.5625rem;
    height: 2.875rem;
    width: 100%;
    font-size: ${({ fontSize }) => fontSize || "0.875rem"};
    justify-content: ${getJustifyContentValue};
    text-align: ${({ align }) => align || "center"};
`;

const emptyTableDataStyles = css`
    height: auto;
    padding: 0;
`;

let TableData = styled.td`
    ${tableCellStyles};
    ${({ empty }) => empty && emptyTableDataStyles};
`;

TableData.propTypes = {
    align: PropTypes.oneOf(["left", "right", "center"]),
    fontSize: PropTypes.string,
    empty: PropTypes.bool
};

export { TableData };

let TableRowHeading = styled.th`
    ${tableCellStyles};
`;

TableRowHeading.propTypes = {
    align: PropTypes.oneOf(["left", "right", "center"]),
    fontSize: PropTypes.string
};

export { TableRowHeading };

export const TableColumnHeading = styled.th`
    display: block;
    width: 100%;
    text-align: ${({ align }) => align || "center"};
`;

TableColumnHeading.propTypes = {
    align: PropTypes.oneOf(["left", "right", "center"])
};

export const TableSubheading = styled.th`
    height: 3.5rem;
    padding-top: 1.875rem;
    padding-left: 0.5625rem;
    font-size: 1.125rem;
`;

export const Table = styled.table`
    width: 100%;
    border: 1px solid ${colors.primary.scheifer};

    /* Desktop: */
        ${TableHead},
        ${TableRow}:not(:last-child) {
            border-bottom: 1px solid ${colors.primary.scheifer};
        }
        
        ${TableRowHeading},
        ${TableData}:not(:last-child),
        ${TableColumnHeading}:not(:last-child) {
            border-right: 1px solid ${colors.primary.scheifer};
        }
        
`;
