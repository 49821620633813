import gql from "graphql-tag";
import { useApolloMutation } from "frontend/utils/apolloApi/useApolloMutation.js";

// GQL
const APPROVE_TAGS = gql`
    mutation approveUserTagsOnPhotoUploads(
        $photoUploadIds: [ID!]
        $tags: [String!]
    ) {
        payload: approveUserTagsOnPhotoUploads(
            photoUploadIds: $photoUploadIds
            tags: $tags
        ) {
            ok
            errors {
                photoUploadId
                reason
                tag
            }
            photoUploads {
                id
                userTags
                warningUserTags
                autoTags
                approvedUserTags
            }
        }
    }
`;

export const useApproveTags = () => {
    const { apolloMutate, loading } = useApolloMutation({
        actionDesc: "Approve Photo Upload Tag",
        gql: APPROVE_TAGS
    });

    const approveTags = async variables => {
        return await apolloMutate({ variables });
    };

    return {
        approveTags,
        approveTagsLoading: loading
    };
};
