/*---------------------------
| Batches
---------------------------*/
export const BATCH_TYPE_KEYS = {
    NO_FLAGS: "no-flags",
    REL_EUO: "rel-euo",
    EXP_TXT: "exp-txt",
    SAVED: "saved",
    UNASSIGNED: "unassigned",
    MY_BATCHES: "my-batches",
    PREMIUM: "premium",
    NO_PREMIUM: "no-premium",
    SUSPENDED_CONTRIBUTOR: "suspended-contributor"
};

/**
 * These optional filters have 3 state values
 * 1: null: means that the filter is not applied
 * 2: true: adds filter where Batch HAS this attribute
 * 3: false: adds filter where Batch DOES NOT HAVE this attribute
 */
export const BATCH_TYPES = [
    {
        id: 1,
        options: [
            {
                key: BATCH_TYPE_KEYS.NO_FLAGS,
                label: `No Flags`,
                db: {
                    key: "hasNoFlags",
                    valueWhenChecked: true
                }
            }
        ]
    },
    {
        id: 2,
        options: [
            {
                key: BATCH_TYPE_KEYS.REL_EUO,
                label: `REL/EUO`,
                db: {
                    key: "hasUploadsWithRelOrEuoFlag",
                    valueWhenChecked: true
                }
            }
        ]
    },
    {
        id: 3,
        options: [
            {
                key: BATCH_TYPE_KEYS.EXP_TXT,
                label: `EXP/TXT`,
                db: {
                    key: "hasUploadsWithExpOrTxtFlag",
                    valueWhenChecked: true
                }
            }
        ]
    },
    {
        id: 4,
        options: [
            {
                key: BATCH_TYPE_KEYS.SAVED,
                label: `Saved`,
                db: {
                    key: "hasSavedForLaterUploads",
                    valueWhenChecked: true
                }
            }
        ]
    },
    {
        id: 5,
        options: [
            {
                key: BATCH_TYPE_KEYS.UNASSIGNED,
                label: `Unassigned`,
                db: {
                    key: "isUnassigned",
                    valueWhenChecked: true
                }
            }
        ]
    },
    {
        id: 6,
        options: [
            {
                key: BATCH_TYPE_KEYS.MY_BATCHES,
                label: `My Batches`,
                db: {
                    key: "assignedToMe",
                    valueWhenChecked: true
                }
            }
        ]
    },
    {
        id: 7,
        options: [
            {
                key: BATCH_TYPE_KEYS.PREMIUM,
                label: `Premium`,
                db: {
                    key: "isPremium",
                    valueWhenChecked: true
                }
            },
            {
                key: BATCH_TYPE_KEYS.NO_PREMIUM,
                label: `Non-Premium`,
                db: {
                    key: "isPremium",
                    valueWhenChecked: false
                }
            }
        ]
    },
    {
        id: 8,
        options: [
            {
                key: BATCH_TYPE_KEYS.SUSPENDED_CONTRIBUTOR,
                label: `Suspended Contributor`,
                db: {
                    key: "showOnlySuspendedCreators",
                    valueWhenChecked: true
                }
            }
        ]
    }
];
