import React from "react";
import PropTypes from "prop-types";

// styles
import { BatchIDContainerStyled, FlagStyled } from "./styles.js";

// components
import Flag from "core/ReusableIcons/Flag";
import Template from "./Template.js";

const BatchID = ({ batchId, showFlag = false }) => {
    return (
        <Template title="Batch ID" width={93}>
            <BatchIDContainerStyled>
                {showFlag && (
                    <FlagStyled>
                        <Flag size={{ width: 13, height: 13 }} />
                    </FlagStyled>
                )}
                <div data-testid="batch-id">{batchId}</div>
            </BatchIDContainerStyled>
        </Template>
    );
};

export default BatchID;

// prop-types
BatchID.propTypes = {
    batchId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    showFlag: PropTypes.bool
};
