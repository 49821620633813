import React from "react";
import PropTypes from "prop-types";

// styles
import colors from "core/colors";
import { OffsetIconStyled } from "./styles";

// Components
import ToggleButton from "./ToggleButton";
import EyeBlind from "core/ReusableIcons/EyeBlind";
import EyeOpen from "core/ReusableIcons/Eye";

export const Eye = ({
    isDisabled = false,
    isActivated = false,
    onToggle,
    moderationIconKey
}) => {
    const EyeIcon = isActivated ? EyeBlind : EyeOpen;
    const fillColor = isDisabled
        ? colors.secondary.dolphin
        : colors.accent.lightMode.persian;

    return (
        <ToggleButton
            onToggle={onToggle}
            isActivated={isActivated}
            isDisabled={isDisabled}
            moderationIconKey={moderationIconKey}
            title="Blur"
        >
            <OffsetIconStyled verticalOffset="2px">
                <EyeIcon
                    size={{ width: 22, height: 22 }}
                    fillColor={fillColor}
                />
            </OffsetIconStyled>
        </ToggleButton>
    );
};

// prop-types
Eye.propTypes = {
    moderationIconKey: PropTypes.string.isRequired,
    onToggle: PropTypes.func.isRequired,
    isActivated: PropTypes.bool,
    isDisabled: PropTypes.bool
};
