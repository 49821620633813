import React from "react";

import { Container, Title, Message } from "./styles";

// TODO:
// Still waiting on a design for this.

const MobileError = () => {
    return (
        <Container>
            <Title>Photo uploading is not available on mobile.</Title>
            <Message>
                Please revisit this page on a desktop, laptop, or tablet device.
            </Message>
        </Container>
    );
};

export default MobileError;
