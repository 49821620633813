import React from "react";
import styled from "styled-components";

// Drop Down
import { DropDownProvider, Hotspot } from "core/DropDown/index.js";

//styles
const AvatarStyled = styled.nav`
    width: 149px;
    display: flex;
    justify-content: flex-end;
`;

// components
import DropDown from "./DropDown";
import Avatar from "core/SiteHeaderV2/components/Avatar.js";

// component
const AuthMenu = () => {
    return (
        <AvatarStyled>
            <DropDownProvider id="auth-menu" hideOnScroll>
                <Hotspot>
                    <Avatar />
                </Hotspot>
                <DropDown />
            </DropDownProvider>
        </AvatarStyled>
    );
};

export default AuthMenu;
