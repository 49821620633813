export { QUEUES, QUEUE_CONFIGS, QUEUE_VIEWS, MODERATION_TYPES } from "./queues";
export { REDUCERS } from "./reducers";
export { BATCH_TYPE_KEYS, BATCH_TYPES } from "./batches";
export { MESSAGING } from "./messaging";
export { SORT_BY_VALUES, SORT_BY_OPTIONS } from "./sortBy";
export {
    UPLOAD_STATUSES,
    UPLOAD_STATUS_BY_QUEUE,
    UPLOAD_STATUS_FILTERS
} from "./uploadStatuses";

/* License Mappings ---------------------------*/
export const LICENSE_MAPPINGS = {
    CREATIVECOMMONS: "Creative Commons",
    PUBLICDOMAIN: "Public Domain"
};

/* Animations ---------------------------*/
export const ANIMATIONS = {
    BATCH_REMOVE: "batch-remove"
};
