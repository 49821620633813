import React, { useEffect } from "react";
import PropTypes from "prop-types";

// context
import { useSearchBar } from "./context/useSearchBar";

// components
import Form from "./Form";
import DropDown from "./DropDown";
import Input from "./Input";
import SearchButton from "./SearchButton";

// component
const MountingWrapper = () => {
    const {
        device,
        setDropDown,
        dropDown: { selectedOption }
    } = useSearchBar();

    useEffect(() => {
        setDropDown(device, selectedOption.id);
    }, [device]);

    return (
        <>
            <Form>
                <DropDown />
                <Input />
                <SearchButton />
            </Form>
        </>
    );
};

export default MountingWrapper;

// prop-types
MountingWrapper.propTypes = {
    children: PropTypes.any
};
