import { useContext } from "react";

// context
import { SearchBarContext } from "../index";
import { actionTypes } from "./store";

// default state
import { getDropDownProps } from "./getDefaultProps/dropDown/getDropDownProps";

export const useSearchBar = () => {
    const { state, dispatch, nonStateProps } = useContext(SearchBarContext);

    const setSelectedOption = selectedOption => {
        dispatch({
            type: actionTypes.SET_SELECTED_OPTION,
            selectedOption
        });
    };

    const setDropDown = (device = "mobile", selectedOptionId = 1) => {
        dispatch({
            type: actionTypes.SET_DROP_DOWN,
            dropDown: getDropDownProps(device, selectedOptionId)
        });
    };

    const setInputValue = inputValue => {
        dispatch({
            type: actionTypes.SET_INPUT_VALUE,
            inputValue
        });
    };

    return {
        ...state,
        ...nonStateProps,
        setSelectedOption,
        setDropDown,
        setInputValue
    };
};
