import React from "react";
import PropTypes from "prop-types";

// context
import { useModal2 } from "../index.js";

// styles
import { ShadowStyled } from "./styles.js";

export const Shadow = props => {
    const {
        children,
        position = "fixed",
        left = "0px",
        right = "0px",
        top = "0px",
        bottom = "0px",
        zIndex = 501
    } = props;

    const { isVisible, hideModal } = useModal2();

    // render
    if (!isVisible) {
        return null;
    }

    return (
        <ShadowStyled
            {...{ position, left, right, top, bottom, zIndex }}
            onClick={() => hideModal()}
        >
            {children}
        </ShadowStyled>
    );
};

Shadow.propTypes = {
    children: PropTypes.any,
    position: PropTypes.string,
    left: PropTypes.string,
    right: PropTypes.string,
    top: PropTypes.string,
    bottom: PropTypes.string,
    zIndex: PropTypes.number
};
