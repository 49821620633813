import React from "react";

import Button from "core/Button";
import colors from "core/colors";
import {
    StyledSectionContainer,
    NotFoundImage,
    NotFoundMessageText
} from "./styles";

import { homePath } from "data/links";

import { convertPxToRem } from "frontend/components/helpers";

const NotFound = () => (
    <StyledSectionContainer bgColor={colors.primary.diamond}>
        <NotFoundImage />
        <NotFoundMessageText
            type="h6"
            size="xs"
            alignment="center"
            color={colors.secondary.orca}
        >
            Hm, the page you were looking for doesn’t seem to exist anymore.
        </NotFoundMessageText>
        <Button href={homePath.url} width={convertPxToRem({ px: 200 })}>
            Back to Noun Project
        </Button>
    </StyledSectionContainer>
);

export default NotFound;
