import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

// core components
import { BatchGrid } from "frontend/components/fort/mod/core/Batch";
import Heading from "frontend/components/core/Heading";

// helpers
import { generateGridItem } from "./GridItem";

import { useSelect } from "frontend/components/core/Grid2/GridItemContentTemplates/Select/SelectContext/index.js";

const PhotoBatchGrid = ({ batch, uploadIds }) => {
    const { entities: uploadEntities, status: uploadsStatus } = useSelector(
        state => state.uploads
    );

    const { uploadsSelected = {}, getSelectedUploadIds } = useSelect();

    const gridItemContent = useMemo(() => {
        const selectedUploadIds = getSelectedUploadIds(uploadsSelected);
        return generateGridItem(
            batch,
            uploadIds,
            selectedUploadIds,
            uploadEntities,
            uploadsStatus === "loading"
        );
    }, [uploadIds, uploadsSelected, uploadsStatus, batch]);

    if (uploadsStatus !== "loading" && !uploadIds.length)
        return <Heading size="xs">None to display</Heading>;

    return <BatchGrid gridItemContent={gridItemContent} />;
};

export default PhotoBatchGrid;

// prop-types
PhotoBatchGrid.propTypes = {
    batch: PropTypes.object.isRequired,
    uploadIds: PropTypes.array.isRequired
};
