import React from "react";
import styled, { css } from "styled-components";

import BodyText from "core/BodyText";
import Button from "core/Button";
import Flexbox from "core/Flexbox";
import Messages from "core/Messages";

import breakpoints from "core/breakpoints";
import { convertPxToRem } from "frontend/components/helpers";
import {
    handleBottomSpacing,
    handleTopSpacing
} from "js/helpers/spacingAndFonts";
import {
    mobileHeaderHeight,
    desktopHeaderHeight
} from "core/SiteHeader/constants";

export const EduHeadingContainer = styled.div`
    @media (max-width: ${breakpoints.beforeTablet.rem}) {
        padding-left: ${convertPxToRem({ px: 15 })};
        padding-right: ${convertPxToRem({ px: 15 })};
    }
`;

export const EmailDescriptionLabel = styled(BodyText)`
    ${handleBottomSpacing({ bottomSpacing: "lg" })};
`;

export const ResponseTimeInfo = styled(BodyText)`
    ${handleBottomSpacing({ bottomSpacing: "2xl" })};
    ${handleTopSpacing({ bottomSpacing: "2xl" })};
`;

export const ApplyButton = styled(Button).attrs({
    styleType: "cta",
    width: "50%"
})`
    @media (max-width: ${breakpoints.beforeTablet.rem}) {
        width: 70%;
    }
`;

export const ButtonContainer = styled(Flexbox).attrs({
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
})``;

export const ErrorMessage = styled(Messages).attrs({
    styleType: "solid",
    infoType: "error",
    width: "auto"
})`
    position: fixed;
    z-index: 100000;
    right: ${convertPxToRem({ px: 40 })};
    top: ${convertPxToRem({ px: 40 })};

    @media (max-width: ${breakpoints.beforeTablet.rem}) {
        ${({ withHeader }) =>
            withHeader &&
            css`
                right: ${convertPxToRem({ px: 24 })};
                top: ${convertPxToRem({ px: mobileHeaderHeight + 24 })};
            `}
    }

    @media (min-width: ${breakpoints.tablet.rem}) {
        ${({ withHeader }) =>
            withHeader &&
            css`
                right: ${convertPxToRem({ px: 24 })};
                top: ${convertPxToRem({
                    px: desktopHeaderHeight + 24
                })};
            `}
    }
`;
