import React from "react";
import {
    IconLeftContainer,
    Input,
    LocationIcon,
    LocationInputWrapper
} from "./styles";
import {
    LabelAndError,
    LabelContainer,
    ErrorContainer
} from "core/Form/styles";

const LocationInput = ({
    photoLocation = "",
    placeholderText,
    handleLocationOnChange,
    inputId,
    hideLabel,
    error,
    isFocused
}) => {
    return (
        <>
            <LocationInputWrapper>
                <>
                    <IconLeftContainer>
                        <LocationIcon />
                    </IconLeftContainer>
                    <Input
                        value={photoLocation}
                        placeholder={placeholderText}
                        autoComplete="off"
                        onChange={e => handleLocationOnChange(e.target.value)}
                        onKeyDown={e => {
                            if (e.key === "ArrowLeft" || e.key === "ArrowRight")
                                e.stopPropagation();
                        }}
                        id={inputId}
                        type="text"
                    />
                </>
            </LocationInputWrapper>

            {!hideLabel && (
                <LabelAndError>
                    {error && (
                        <ErrorContainer as="label" htmlFor={inputId} showError>
                            {error}
                        </ErrorContainer>
                    )}
                    {isFocused && !error && (
                        <LabelContainer
                            as="label"
                            htmlFor={inputId}
                            showFormLabel
                        >
                            {placeholderText}
                        </LabelContainer>
                    )}
                </LabelAndError>
            )}
        </>
    );
};

export default LocationInput;
