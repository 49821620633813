import React from "react";

import { FeedbackContent, FeedbackIcon, AuthButton } from "../styles";
import Complete from "core/IllustrativeIcons/Complete";

import colors from "core/colors";
import { LOGIN } from "data/links";

const ResetPasswordSuccess = () => (
    <FeedbackContent>
        <FeedbackIcon>
            <Complete fillColor={colors.accent.lightMode.pansy} />
        </FeedbackIcon>
        <AuthButton styleType="cta" href={LOGIN}>
            Log In
        </AuthButton>
    </FeedbackContent>
);

export default ResetPasswordSuccess;
