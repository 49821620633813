import React, { useRef } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";

import colors from "core/colors";
import { convertPxToRem } from "frontend/components/helpers";

import { FETCH_RELEASE_URLS } from "../../../../gql/queries";

import { messagesSlice } from "../../../../slices/messagesSlice";

export const ReleaseItem = styled("div")`
    background-color: ${colors.primary.diamond};
    color: ${({ isUploading }) =>
        isUploading ? colors.secondary.dolphin : colors.secondary.orca};
    margin-bottom: ${convertPxToRem({ px: 16 })};
    border-radius: ${convertPxToRem({ px: 4 })};

    &:last-of-type {
        margin-bottom: 0;
    }
`;

const FileNameStyled = styled.button`
    text-decoration: none;
    color: inherit;
    display: block;
    background: transparent;
    border: none;
    width: 100%;
    text-align: left;
    padding: ${convertPxToRem({ px: 12 })} ${convertPxToRem({ px: 16 })}
        ${convertPxToRem({ px: 12 })} ${convertPxToRem({ px: 16 })};

    &:hover {
        cursor: pointer;
        background-color: ${colors.primary.igloo};
    }
`;

const HiddenLink = styled.a`
    display: none;
`;

const ReleaseList = ({ releases = [] }) => {
    const dispatch = useDispatch();
    const hiddenLinkRef = useRef(null);

    const [fetchReleaseUrl] = useLazyQuery(FETCH_RELEASE_URLS, {
        fetchPolicy: "network-only"
    });

    const handleFileNameClick = async (e, id) => {
        e.preventDefault();

        const response = await fetchReleaseUrl({
            variables: {
                photoReleaseIds: [id]
            }
        });

        const { data: { urls = [] } = {} } = response;
        const { id: respId, downloadUrl } = urls[0];

        if (respId === id) {
            hiddenLinkRef.current.href = downloadUrl;
            hiddenLinkRef.current.click();
        } else {
            dispatch(
                messagesSlice.actions.addMessage({
                    text: "Could not download release. Please try again.",
                    timed: false,
                    timeout: null,
                    infoType: "error"
                })
            );
        }
    };

    return [...releases]
        .sort((a, b) => a.originalFilename.localeCompare(b.originalFilename))
        .map(({ id, originalFilename }) => {
            return (
                <ReleaseItem key={id}>
                    <FileNameStyled
                        onClick={e => handleFileNameClick(e, id)}
                        target="_blank"
                    >
                        {originalFilename}
                    </FileNameStyled>
                    <HiddenLink ref={hiddenLinkRef} target="_blank" />
                </ReleaseItem>
            );
        });
};

export default ReleaseList;

ReleaseList.propTypes = {
    releases: PropTypes.array.isRequired
};
