import merge from "lodash/merge";
import union from "lodash/union";

import * as actionTypes from "../actions/actionTypes";

//
// For saved for later success just update status to saved and dispatch event in under review compoent to fire call to get new active
//
export const creator = (state = {}, action) => {
    let arrays = {},
        creator = {},
        nextState = {};

    switch (action.type) {
        case actionTypes.FETCH_FEATURED_CREATORS_SUCCESS:
            arrays = {
                replace: [],
                union: ["keys"]
            };

            if (action.response.keys.length > 0) {
                creator = { featured_creators: action.response };
            } else {
                creator = state;
            }
            if (state.featured_creators) {
                if (state.featured_creators.keys.length > 0) {
                    creator.featured_creators.keys.forEach(creatorId => {
                        nextState = mergeEntity(
                            "creator",
                            creatorId,
                            state.featured_creators,
                            creator.featured_creators,
                            arrays
                        );
                    });
                    let clonedState = Object.assign({}, state);
                    clonedState.featured_creators = nextState;
                    nextState = Object.assign({}, state, clonedState);
                } else {
                    nextState = Object.assign({}, state, creator);
                }
            } else {
                nextState = Object.assign({}, state, creator);
            }
            return nextState;
            break;

        case actionTypes.FETCH_CREATOR_SUCCESS:
            nextState = action.response;
            return nextState;

        case actionTypes.TOGGLE_FEATURE_CREATOR_SUCCESS:
            nextState = merge({}, state);
            if (action.status === "add") {
                nextState[action.creator_id].featured = true;
            } else {
                nextState[action.creator_id].featured = false;
            }
            return merge({}, state, nextState);
            break;

        case actionTypes.UNSUSPEND_CREATOR:
        case actionTypes.DISABLE_CREATOR:
        case actionTypes.BAN_CREATOR:
        case actionTypes.SUSPEND_CREATOR:
            nextState = merge({}, state, {
                processing: true
            });
            break;
        case actionTypes.UNSUSPEND_CREATOR_FAILURE:
        case actionTypes.DISABLE_CREATOR_FAILURE:
        case actionTypes.BAN_CREATOR_FAILURE:
        case actionTypes.SUSPEND_CREATOR_FAILURE:
            nextState = merge({}, state, {
                processing: false
            });
            break;
        case actionTypes.UNSUSPEND_CREATOR_SUCCESS:
        case actionTypes.DISABLE_CREATOR_SUCCESS:
        case actionTypes.BAN_CREATOR_SUCCESS:
        case actionTypes.SUSPEND_CREATOR_SUCCESS:
            creator = action.response[action.id];
            nextState = merge({}, state, {
                [action.id]: creator,
                processing: false
            });
            return nextState;
            break;
        case actionTypes.SAVE_CREATOR_NOTE_SUCCESS:
            nextState = merge({}, state);
            if (nextState[action.response.user.id]) {
                nextState[action.response.user.id].creator_info.notes =
                    action.response.user.creator_info.notes;
                if (action.response.user.creator_info.important_note === 0) {
                    nextState[
                        action.response.user.id
                    ].creator_info.important_note = false;
                } else {
                    nextState[
                        action.response.user.id
                    ].creator_info.important_note = true;
                }
            }
            return merge({}, state, nextState);
            break;
        case actionTypes.FORT_REFRESH_MEMBER_SUBSCRIPTION_SUCCESS:
            let { userId, response: plans } = action;
            let user = state[userId];
            if (user) {
                if (user) {
                    user = {
                        ...user,
                        plans
                    };
                }
                nextState = merge({}, state, {
                    [userId]: user
                });
            }
            return nextState;
            break;
        case actionTypes.USER_GET_PREPAID_BALANCE_SUCCESS: {
            let { userId, response } = action;
            let { prepaid_balance } = response;
            let user = state[userId];

            if (user) {
                user = {
                    ...user,
                    prepaid_balance
                };

                nextState = merge({}, state, {
                    [userId]: user
                });
            }

            return nextState;
        }
        case actionTypes.CREATOR_NOTE_CHANGE: {
            let { notes, userId } = action;
            let user = state[userId];

            if (user) {
                let { creator_info } = user;

                creator_info = creator_info || {};
                creator_info = { ...creator_info, notes };
                user = {
                    ...user,
                    creator_info
                };

                nextState = merge({}, state, {
                    [userId]: user
                });
            }

            return nextState;
        }
        case actionTypes.FORT_FETCH_DUPLICATE_PAYPAL_EMAILS: {
            return {
                ...state,
                fetchingDuplicatePayPalEmails: true
            };
        }
        case actionTypes.FORT_FETCH_DUPLICATE_PAYPAL_EMAILS_FAILURE: {
            return {
                ...state,
                fetchingDuplicatePayPalEmails: false,
                duplicatePayPalEmails: { error: true }
            };
        }
        case actionTypes.FORT_FETCH_DUPLICATE_PAYPAL_EMAILS_SUCCESS: {
            let { response } = action;
            return {
                ...state,
                duplicatePayPalEmails: response,
                fetchingDuplicatePayPalEmails: false
            };
        }

        default:
            return state;
            break;
    }

    return Object.assign({}, state, nextState);
};

const mergeEntity = (type, id, state, newObj, arrays = {}) => {
    if (state) {
        arrays.union.forEach(a => {
            if (newObj[a] && state[a]) {
                newObj[a] = union(state[a], newObj[a]);
            }
        });
        arrays.replace.forEach(a => {
            if (newObj[a] && state[a]) {
                state[a] = newObj[a];
            }
        });
        newObj = Object.assign({}, state, newObj);
    }

    return Object.assign({}, state, newObj);
};
