import { actionTypes } from "./actionTypes.js";

/*---------------------------
| Actions (Action Creators)
---------------------------*/
export const addMessages = messages => {
    return {
        type: actionTypes.MS_ADD_MESSAGES,
        messages: sanatizeMessages(messages)
    };
};
export const removeMessage = messageId => {
    return {
        type: actionTypes.MS_REMOVE_MESSAGE,
        messageId: messageId
    };
};

/*---------------------------
| Helpers
---------------------------*/
export const sanatizeMessages = messages => {
    const arrayOfMessages = Array.isArray(messages) ? messages : [messages];

    return arrayOfMessages.map(m => {
        const { text, timed, timeout } = m;
        m.timeout = calculateTimeOut(text, timed, timeout);
        return m;
    });
};

const stripHtmlFromString = string => {
    const regex = /(<([^>]+)>)/gi;
    return string.replace(regex, "");
};

const getCharCountMinusHtml = stringWithHtml => {
    const strippedString = stripHtmlFromString(stringWithHtml);
    return strippedString.length;
};

const getTimeoutByCharacter = text => {
    const charCount = getCharCountMinusHtml(text);
    return charCount * msPerChar;
};

// Multiply Charachter Count by 50 Milliseconds to get Timeout
export const msPerChar = 50;
export const calculateTimeOut = (text, timed, timeout) => {
    let actualTimeout = null;
    const timeOutByCharacter = getTimeoutByCharacter(text);

    /*
        `timeout`: timeout provided by Dev

        - If !`timed`, banner will persist until user dismisses.
        - If the `timeOutByCharacter` is longer than `timeout`, `timeOutByCharacter` prevails.
        - If `timeout` is longer than the `timeOutByCharacter`, `timeout` prevails.
    */
    if (timed) {
        actualTimeout =
            timeOutByCharacter > timeout ? timeOutByCharacter : timeout;
    }

    return actualTimeout;
};
