import styled, { css } from "styled-components";

import { Item } from "./Item";
import { Section, Heading } from "./Section";
import { Content } from "./Content";
import { Wrapper } from "./Wrapper";

import Link from "core/Link";
import { STYLE_TYPES, STYLE_TYPE_DEFAULTS } from "core/Link/styles";
import SharedDiv from "core/Div";
import breakpoints from "core/breakpoints";
import colors from "core/colors";
import Button from "core/Button";
import { STYLE_MODES } from "core/Button/styles";

export const LogOutButton = styled(Button)`
    ${({ isHeroSearchV2 }) =>
        isHeroSearchV2 &&
        css`
            &&,
            .scrolledUp & {
                ${STYLE_MODES.OUTLINE.LIGHTMODE}
            }

            .scrolledDown & {
                ${STYLE_MODES.OUTLINE.DARKMODE}
            }
        `}
`;

const ProfileMenuDesktop = styled.nav`
    position: absolute;
    right: 0;
    width: 100vw;
    z-index: 100;
    text-align: right;

    ${Section} {
        margin-right: 6.25rem; /* 100px */

        &:last-of-type {
            margin-right: 3.75rem; /* 60px */
        }

        @media (min-width: ${breakpoints.tablet
                .rem}) and (max-width: ${breakpoints.beforeDesktop.rem}) {
            margin-right: 2.188rem; /* 35px */
        }
    }

    ${Wrapper} {
        position: relative;
        top: 2.5rem;
    }

    @media (max-width: ${breakpoints.desktop.rem}) {
        display: none;
    }
`;

const Div = styled(SharedDiv)`
    ${({ isHeroSearchV2 }) =>
        isHeroSearchV2 &&
        css`
            ${Heading} {
                color: ${colors.secondary.cosmic};
            }

            ${Link} {
                ${STYLE_TYPES.lightMode.secondary}

                &:visited {
                    ${STYLE_TYPE_DEFAULTS.lightMode.secondary}
                }
            }

            // for ProfileMenu Desktop links when isHeroSearchV2:
            .scrolledDown & {
                ${Link} {
                    ${STYLE_TYPES.darkMode.secondary}

                    &:visited {
                        ${STYLE_TYPE_DEFAULTS.darkMode.secondary}
                    }
                }
            }

            .scrolledUp & {
                ${Link} {
                    ${STYLE_TYPES.lightMode.secondary}

                    &:visited {
                        ${STYLE_TYPE_DEFAULTS.lightMode.secondary}
                    }
                }
            }
        `}
`;

export { ProfileMenuDesktop, Wrapper, Item, Heading, Section, Content, Div };
