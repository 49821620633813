import React from "react";
import PropTypes from "prop-types";

// hooks
import { useVisuallyApprove } from "./useVisuallyApprove";

// styles
import { ButtonStyled } from "../styles";

// component
export const VisuallyApproveButton = ({
    buttonText = "Visually Approve",
    variant
}) => {
    const { onVisuallyPublish, isEditable } = useVisuallyApprove();

    return (
        <ButtonStyled
            onClick={onVisuallyPublish}
            isDisabled={!isEditable}
            variant={variant}
        >
            {buttonText}
        </ButtonStyled>
    );
};

export default VisuallyApproveButton;

// prop-types
VisuallyApproveButton.propTypes = {
    buttonText: PropTypes.string,
    variant: PropTypes.string.isRequired
};
