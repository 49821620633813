import React from "react";
import styled from "styled-components";

// context
import { useSiteHeader } from "core/SiteHeaderV2/context/useSiteHeader.js";

//styles
const AuthProfileStyled = styled.div`
    margin-bottom: 24px;
`;

// component
const AuthProfile = () => {
    const { isMobile } = useSiteHeader();
    const image = isMobile
        ? `avatar-auth-menu-mobile.png`
        : `avatar-auth-menu.png`;
    const src = `https://mitchellgohman.com/assets/img/tnp/${image}`;

    return (
        <AuthProfileStyled>
            <img className="prototype" alt="avatar-auth-menu" src={src} />
        </AuthProfileStyled>
    );
};

export default AuthProfile;
