import React from "react";
import styled from "styled-components";

// auth
import logOutAndRedirect from "helpers/log-out";

//styles
import Button from "core/Button";
const LogoutButtonStyled = styled(Button)``;

// component
const LogoutButton = () => {
    return (
        <LogoutButtonStyled
            onClick={logOutAndRedirect}
            styleType="outline"
            width="100%"
        >
            Log Out
        </LogoutButtonStyled>
    );
};

export default LogoutButton;
