import React from "react";

// components
import {
    SidebarWrapper,
    License,
    History
} from "frontend/components/fort/mod/core/Sidebar/index.js";
import Tags from "./Tags";

import Overview from "./Overview";

const PhotoModQueueSidebar = () => {
    return (
        <SidebarWrapper>
            <Overview />
            <Tags />
            <License />
            <History />
        </SidebarWrapper>
    );
};

export default PhotoModQueueSidebar;
