import { actionTypes } from "./actionTypes.js";

/*---------------------------
| State Updater (reducer)
---------------------------*/
const reducer = (state, action) => {
    switch (action.type) {
        case actionTypes.MS_ADD_MESSAGES: {
            const currentIds = state.messages.map(m => m.id);
            return {
                ...state,
                messages: [
                    ...state.messages,
                    ...action.messages.filter(m => !currentIds.includes(m.id))
                ]
            };
        }
        case actionTypes.MS_REMOVE_MESSAGE: {
            const messages = state.messages.filter(
                m => m.id !== action.messageId
            );
            return {
                ...state,
                messages
            };
        }
        default: {
            return { ...state };
        }
    }
};

export default reducer;
