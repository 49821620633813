import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

// Context
import { useFormSelectDialog, optionShape, getDialogOptionId } from "./index";

// styles
import colors from "core/colors";
const DialogOptionStyled = styled.li`
    ${({
        theme: {
            dialogOption: { backgroundColor, hover }
        }
    }) => {
        return css`
            display: block;
            cursor: pointer;
            list-style-type: none;
            margin: 0px;
            padding: 0px;

            width: 100%;

            border: none;
            border-radius: 0px;
            background-color: ${backgroundColor};

            padding: 0px;

            display: grid;
            gap: 0px;
            grid-template-columns: 35px auto;

            &:hover {
                background-color: ${hover.backgroundColor};
            }
        `;
    }};
`;

const CheckMarkWrapperStyled = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const DialogOptionTextStyled = styled.div`
    ${({
        theme: {
            dialogOption: { textAlign, font }
        }
    }) => {
        return css`
            text-align: ${textAlign};
            font-size: ${font.size};
            line-height: ${font.lineHeight};
        `;
    }};
`;

// components
import Checkmark from "core/ReusableIcons/Checkmark";

const DialogOption = ({ option }) => {
    // state/props
    const {
        id,
        hasOpenedBefore,
        isOpen,
        setIsOpen,
        selectedOption,
        setSelectedOption
    } = useFormSelectDialog();

    const dialogOptionRef = useRef();
    const isSelected = selectedOption.id === option.id;

    // methods
    const selectOption = () => {
        setSelectedOption(option);
        setIsOpen(false);
    };

    // Handle enter and spacekey
    const handleKeyDown = e => {
        if (!isOpen) return;
        switch (e.key) {
            case "Enter":
            case " ":
                e.preventDefault();
                e.stopPropagation();
                selectOption();
                break;
            default:
                break;
        }
    };

    // side effects
    useEffect(() => {
        if (hasOpenedBefore && isOpen && isSelected) {
            dialogOptionRef.current.focus();
        }
    }, [hasOpenedBefore, isOpen]);

    return (
        <DialogOptionStyled
            id={getDialogOptionId(id, option.id)}
            role="option"
            tabIndex="0"
            onClick={selectOption}
            onKeyDown={handleKeyDown}
            ref={dialogOptionRef}
            aria-selected={isSelected}
            data-testid="dialog-option"
        >
            <CheckMarkWrapperStyled>
                {isSelected && (
                    <Checkmark
                        size={"sm"}
                        fillColor={colors.secondary.oolong}
                    />
                )}
            </CheckMarkWrapperStyled>
            <DialogOptionTextStyled>{option.label}</DialogOptionTextStyled>
        </DialogOptionStyled>
    );
};

export default DialogOption;

// prop-types
DialogOption.propTypes = {
    option: PropTypes.shape(optionShape).isRequired
};
