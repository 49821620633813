import React from "react";

// context
import { useInlineTextEdit } from "core/InlineTextEdit/index.js";

// components
import {
    FormControlGroup,
    Input as CoreInput
} from "frontend/components/core/FormControls";
import TextEdit from "../TextEdit";

export const Input = ({ ...rest }) => {
    const {
        inputRef,
        id,
        editedText,
        ariaLabel,
        placeholder,
        onInputChange,
        onInputBlur,
        errorMessage,
        minLength,
        maxLength
    } = useInlineTextEdit();

    const sharedProps = {
        controlId: id,
        ariaLabel: ariaLabel,
        errorMessage: errorMessage
    };

    return (
        <TextEdit>
            <FormControlGroup hideLabel={true} {...sharedProps}>
                <CoreInput
                    inputRef={inputRef}
                    value={editedText}
                    onChange={onInputChange}
                    onBlur={onInputBlur}
                    placeholder={placeholder}
                    minLength={minLength}
                    maxLength={maxLength}
                    {...sharedProps}
                    {...rest}
                />
            </FormControlGroup>
        </TextEdit>
    );
};

export default Input;
