// Simply puts last api response action type into state, along with error message if applicable.
// Allows components to respond to async actions they have initiated (eg show error).

if (!global._babelPolyfill) {
    require("@babel/polyfill");
}
import merge from "lodash/merge";

export default function apiResponse(
    state = { actionType: null, error: null, statusCode: null },
    action
) {
    if (action.apiResponse) {
        return {
            actionType: action.type,
            error: action.error,
            statusCode: action.statusCode
        };
    } else if (action.apiRequest) {
        return {
            actionType: null,
            error: null,
            statusCode: null
        };
    }

    return state;
}
