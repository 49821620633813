import React from "react";

// components
import Skeleton from "core/Skeleton";
import SquareGrid from "icons/SquareGrid";
import breakpoints from "core/breakpoints";
import Flexbox from "core/Flexbox";

// helpers
import { useViewport } from "js/hooks/useViewport";
import { convertPxToRem } from "frontend/components/helpers";

const CollectionSkeletonBox = () => {
    const { browserWidth } = useViewport();

    const isMobile = browserWidth <= breakpoints.beforeTablet.px;
    const isTablet = browserWidth <= breakpoints.tablet.px;
    const isDesktop = browserWidth > breakpoints.tablet.px;

    const circleDim = () => {
        switch (true) {
            case isMobile:
                return convertPxToRem({ px: 43 });
            case isTablet:
                return convertPxToRem({ px: 38 });
            case isDesktop:
                return convertPxToRem({ px: 68 });
            default:
                return convertPxToRem({ px: 43 });
        }
    };

    const formatDataAsGridContent = () => {
        let skeletonElementArr = [];
        for (let i = 0; i < 6; i++) {
            skeletonElementArr.push(
                <Flexbox
                    key={i}
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Skeleton
                        width={circleDim()}
                        height={circleDim()}
                        isCircle
                        key={i}
                    />
                </Flexbox>
            );
        }
        return skeletonElementArr;
    };

    return (
        <SquareGrid
            gridItemContent={formatDataAsGridContent()}
            numberOfColumns={3}
            gridSpacingSize={convertPxToRem({ px: 24 })}
        />
    );
};

export default CollectionSkeletonBox;
