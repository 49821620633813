import React, {
    useContext,
    useEffect,
    useCallback,
    useReducer,
    createContext
} from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import { GET_LIST_RELEASES_IN_BATCH } from "../queries";
import { FETCH_UPLOAD_BATCHES } from "frontend/components/creator-tools/PhotoUploadPage/PhotoUploadCore/queries";
import {
    REGISTER_RELEASE_UPLOAD,
    SUBMIT_RELEASE,
    WITHDRAW_PHOTO_RELEASES,
    REFRESH_RELEASE_UPLOAD_URLS
} from "../mutations";

import { uploadToAWS } from "./helpers";

const DEBUG_MODE = false;
export const MAX_RELEASES = 100;
const MAX_BYTES = 3300000; // 3MB: 3145728: Binary (base 2), 3300000: padded
const MIN_BYTES = 1;
const PDF_FILE_TYPE = "application/pdf";
const SUBMISSION_ABORTED = "ABORTED";
const SUBMISSION_SUCCESS = "SUCCESS";
const SUBMISSION_FAIL = "FAILURE";

const ReleasesContext = createContext();

// should match backend error message
const DUPLICATE_FILE_MSG = "Duplicate file name(s). Rename to try again.";
// should match backend error message
const MAX_FILES_MSG = `Release(s) denied. ${MAX_RELEASES} release limit per batch.`;
const SERVER_ERROR_MSG = "An unexpected error has occurred. Please try again.";
const FILE_TOO_SMALL_MSG = "File is too small";
const FILE_TOO_LARGE_MSG = "File is too large";
const FILE_NOT_PDF_MSG = "File is not a PDF";

export const releaseUploadActions = {
    ADD_FILES: "ADD_FILES",
    CLAIM_UPLOADS: "CLAIM_UPLOADS",
    SET_ERROR: "SET_ERROR",
    SET_VALIDATED: "SET_VALIDATED",
    SET_UPLOADING: "SET_UPLOADING",
    SET_EXPIRED_URL: "SET_EXPIRED_URL",
    SET_UPLOAD_SUCCESS: "SET_UPLOAD_SUCCESS",
    SET_UPLOAD_FAILURE: "SET_UPLOAD_FAILURE",
    SET_COMPLETE: "SET_COMPLETE"
};

export const releaseUploadStatus = {
    PENDING: "PENDING", // file submitted
    VALIDATED: "VALIDATED", // client side validation passed
    UPLOADING: "UPLOADING", // backend notified of upload, ready to upload to S3
    EXPIRED_URL: "EXPIRED_URL", // S3 upload URL expired
    UPLOAD_SUCESS: "UPLOAD_SUCESS", // file upload to S3 complete
    UPLOAD_FAILURE: "UPLOAD_FAILURE", // file upload to S3 failed
    UPLOAD_COMPLETE: "UPLOAD_COMPLETE", // file upload to S3 complete, and backend notified
    WITHDRAWN: "WITHDRAWN", // file withdrawn from batch; returned from server
    ERROR: "ERROR" // something failed; see upload error property
};

export const sortReleases = releases => {
    try {
        return releases.sort((a, b) => a.filename.localeCompare(b.filename));
    } catch (e) {
        console.error(e);
        return releases;
    }
};

// Rather than showing all error messages, design requests a single error message
// based on a hierarchy of error severity. This function takes an object of
// error messages and counts, and returns the most severe error message.
export const formatErrorMessage = (uploadErrors, uploadCount) => {
    // uploadErrors is an object with error messages as keys, and counts as values
    const errorsArray = Object.entries(uploadErrors)
        .filter(([error, count]) => count > 0)
        .map(([error, count]) => error);

    if (errorsArray.length === 0) {
        return null;
    }

    const failureCountMsg = `${errorsArray.length}/${uploadCount} releases failed to upload.`;

    if (errorsArray.includes(SERVER_ERROR_MSG)) {
        return SERVER_ERROR_MSG;
    } else if (errorsArray.includes(MAX_FILES_MSG)) {
        return MAX_FILES_MSG;
    } else if (errorsArray.includes(FILE_TOO_SMALL_MSG)) {
        return failureCountMsg;
    } else if (errorsArray.includes(FILE_TOO_LARGE_MSG)) {
        return failureCountMsg;
    } else if (errorsArray.includes(FILE_NOT_PDF_MSG)) {
        return failureCountMsg;
    } else if (errorsArray.includes(DUPLICATE_FILE_MSG)) {
        return DUPLICATE_FILE_MSG;
    } else {
        return errorsArray[0];
    }
};

export const useReleases = () => {
    const {
        getReleases,
        uploadFiles,
        withdrawPhotoReleases,
        releaseItems,
        releaseUploads,
        isLoading
    } = useContext(ReleasesContext);

    // combine server data with in-process uploads to create release list
    const releases = sortReleases(
        [
            ...releaseItems.map(r => ({
                id: r.id,
                filename: r.name,
                status: r.status
            })),
            ...Object.values(releaseUploads)
                .filter(u => !releaseItems.map(r => r.id).includes(u.id))
                .map(u => ({
                    id: u.id,
                    filename: u.filename,
                    status: u.status
                }))
        ].filter(
            r =>
                r.status !== releaseUploadStatus.ERROR &&
                r.status !== releaseUploadStatus.WITHDRAWN
        )
    );

    const uploadErrors =
        Object.values(releaseUploads)
            .filter(u => !!u.error)
            .reduce((result, item) => {
                if (!result[item.error]) {
                    result[item.error] = 1;
                } else {
                    result[item.error]++;
                }
                return result;
            }, {}) || {};

    const uploadErrorMessage = formatErrorMessage(
        uploadErrors,
        Object.values(releaseUploads).length
    );

    const removeRelease = id => {
        withdrawPhotoReleases({
            variables: {
                photoReleaseIds: [id]
            }
        });
    };

    return {
        // data
        releases,
        isLoading,
        uploadErrorMessage,
        // methods
        getReleases,
        uploadFiles,
        removeRelease
    };
};

const uploadInitialState = {
    status: releaseUploadStatus.PENDING,
    id: null,
    filename: null,
    key: null,
    fileData: null,
    s3Url: null,
    error: null,
    urlRefreshAttempts: 0
};

function getFileKey(file) {
    return `${file.name}_${file.size}_${file.lastModified}`;
}

export const releaseUploadReducer = (state, action) => {
    const actions = releaseUploadActions;
    const status = releaseUploadStatus;
    const { type, payload } = action;

    if (DEBUG_MODE) {
        console.info(type, payload);
    }

    switch (type) {
        // initialize uploads from an array of File objects
        case actions.ADD_FILES: {
            return {
                // when adding new files, remove any errored uploads
                ...Object.fromEntries(
                    Object.entries(state).filter(([_, value]) => {
                        return value.status !== status.ERROR;
                    })
                ),
                ...Object.fromEntries(
                    payload.files.map(f => {
                        const fileKey = getFileKey(f);
                        if (state[fileKey]) {
                            // file already exists in state, fail fast with duplicate name error
                            return [
                                `${fileKey}_DUPLICATE`,
                                {
                                    ...uploadInitialState,
                                    status: status.ERROR,
                                    error: DUPLICATE_FILE_MSG
                                }
                            ];
                        }
                        return [
                            fileKey,
                            {
                                ...uploadInitialState,
                                fileData: f,
                                key: fileKey,
                                filename: f.name
                            }
                        ];
                    })
                )
            };
        }
        // claim files for processing - this just updates status so it can't be
        // double-processed (eg. uploaded twice)
        case actions.CLAIM_UPLOADS: {
            return {
                ...state,
                ...Object.fromEntries(
                    payload.fileKeys.map(key => {
                        const upload = state[key];
                        if (upload.status.startsWith("_")) {
                            return [key, upload]; // already claimed
                        }
                        return [
                            key,
                            {
                                ...upload,
                                status: `_${upload.status}`
                            }
                        ];
                    })
                )
            };
        }
        // set error on a single upload
        case actions.SET_ERROR: {
            const fileKey = payload.fileKey;
            return {
                ...state,
                [fileKey]: {
                    ...state[fileKey],
                    error: payload.error,
                    status: status.ERROR
                }
            };
        }
        // set validated status on a single upload
        case actions.SET_VALIDATED: {
            const fileKey = payload.fileKey;
            return {
                ...state,
                [fileKey]: {
                    ...state[fileKey],
                    status: status.VALIDATED
                }
            };
        }
        // set uploading status on array of uploads
        case actions.SET_UPLOADING: {
            return {
                ...state,
                ...Object.fromEntries(
                    payload.uploads.map(u => {
                        const fileKey = u.key;
                        const upload = state[fileKey];
                        return [
                            fileKey,
                            {
                                ...upload,
                                ...u,
                                status: status.UPLOADING
                            }
                        ];
                    })
                )
            };
        }
        case actions.SET_EXPIRED_URL: {
            const fileKey = payload.fileKey;
            const upload = state[fileKey];

            if (upload.urlRefreshAttempts >= 3) {
                console.error(
                    `Upload ${upload.id} has exceeded maximum URL refresh attempts.`
                );

                return {
                    ...upload,
                    status: status.UPLOAD_FAILURE,
                    error: "Upload failed, please try again."
                };
            }

            return {
                ...state,
                [fileKey]: {
                    ...upload,
                    status: status.EXPIRED_URL,
                    urlRefreshAttempts: upload.urlRefreshAttempts + 1
                }
            };
        }
        // set sets status indicating upload to S3 was successful
        case actions.SET_UPLOAD_SUCCESS: {
            const fileKey = payload.fileKey;
            return {
                ...state,
                [fileKey]: {
                    ...state[fileKey],
                    status: status.UPLOAD_SUCESS
                }
            };
        }
        // set sets status indicating upload to S3 failed
        case actions.SET_UPLOAD_FAILURE: {
            const fileKey = payload.fileKey;
            return {
                ...state,
                [fileKey]: {
                    ...state[fileKey],
                    status: status.UPLOAD_FAILURE
                }
            };
        }
        // set complete status on a single upload
        case actions.SET_COMPLETE: {
            const fileKey = payload.fileKey;
            return {
                ...state,
                [fileKey]: {
                    ...state[fileKey],
                    // we can now release the File object from memory
                    fileData: null,
                    status: status.UPLOAD_COMPLETE
                }
            };
        }
        // given an array of upload ids, remove them from the state
        case actions.REMOVE_UPLOADS: {
            const ids = payload.ids;
            const fileKeys = Object.values(state)
                .filter(u => ids.includes(u.id))
                .map(u => u.key);

            return Object.fromEntries(
                Object.entries(state).filter(
                    ([key, value]) => !fileKeys.includes(key)
                )
            );
        }
        default: {
            throw new Error(`Unhandled action type: ${type}`);
        }
    }
};

export const ReleasesProvider = ({
    children,
    // default arguments meant to be used for testing
    defaultReleaseItems = [],
    defaultReleaseUploads = {}
}) => {
    const [releaseUploads, dispatch] = useReducer(
        releaseUploadReducer,
        defaultReleaseUploads
    );

    // build a deterministic string from the file upload statuses
    // if this changes, we know that the file statuses have changed
    const statusHash = Object.values(releaseUploads)
        .map(upload => `${upload.key}:${upload.status}`)
        .sort()
        .join(",");

    const { data: batchData } = useQuery(FETCH_UPLOAD_BATCHES, {
        variables: { moderationStatuses: ["BATCH_UNSUBMITTED"] }
    });
    const unsubmittedBatch = batchData?.batches?.[0];
    const unsubmitedBatchId = unsubmittedBatch?.id ?? null;

    const [getReleases, { data, loading }] = useLazyQuery(
        GET_LIST_RELEASES_IN_BATCH,
        {
            variables: {
                limit: MAX_RELEASES,
                status: "UPLOAD_COMPLETE",
                photoBatchId: unsubmitedBatchId
            }
        }
    );

    const releaseItems =
        (data &&
            data.listReleasesInModerationPhotoBatch &&
            data.listReleasesInModerationPhotoBatch.items) ||
        defaultReleaseItems;

    const [withdrawPhotoReleases] = useMutation(WITHDRAW_PHOTO_RELEASES, {
        onCompleted: data => {
            const ids = data.withdrawPhotoReleases.releases.map(
                r => r.photoReleaseId
            );
            dispatch({
                type: releaseUploadActions.REMOVE_UPLOADS,
                payload: { ids }
            });
        },
        refetchQueries: ["GetListReleasesInBatch"]
    });

    const [registerRelease] = useMutation(REGISTER_RELEASE_UPLOAD);
    const [submitRelease] = useMutation(SUBMIT_RELEASE);
    const [refreshReleaseUploadUrls] = useMutation(REFRESH_RELEASE_UPLOAD_URLS);

    // add file objects to state
    const uploadFiles = newFiles => {
        dispatch({
            type: releaseUploadActions.ADD_FILES,
            payload: { files: newFiles }
        });
    };

    // claim uploads for processing - prevents double-processing
    const claimUploads = useCallback(
        status => {
            const uploads = Object.values(releaseUploads).filter(
                u => status === u.status
            );

            if (uploads.length === 0) return [];

            dispatch({
                type: releaseUploadActions.CLAIM_UPLOADS,
                payload: { fileKeys: uploads.map(u => u.key) }
            });
            return uploads;
        },
        [releaseUploads]
    );

    // perform client-side validation on File object data
    const validateFiles = useCallback(() => {
        const uploads = claimUploads(releaseUploadStatus.PENDING);

        uploads.forEach(upload => {
            const { size, type } = upload.fileData;
            const fileKey = upload.key;
            let error = null;

            if (size < MIN_BYTES) {
                error = "File is too small";
            }

            if (size > MAX_BYTES) {
                error = "File is too large";
            }

            if (type !== PDF_FILE_TYPE) {
                error = "File is not a PDF";
            }

            if (error) {
                dispatch({
                    type: releaseUploadActions.SET_ERROR,
                    payload: { fileKey, error }
                });
            } else {
                dispatch({
                    type: releaseUploadActions.SET_VALIDATED,
                    payload: { fileKey }
                });
            }
        });
    }, [dispatch, claimUploads]);

    // create release upload objects in backend, and get S3 upload URLs
    const getUploadUrls = useCallback(async () => {
        const mutationName = "photoReleaseUpload";
        const uploads = claimUploads(releaseUploadStatus.VALIDATED);

        if (uploads.length === 0) return;

        const keyMap = Object.fromEntries(
            uploads.map(u => [u.filename, u.key])
        );

        const res = await registerRelease({
            variables: {
                filenames: uploads.map(u => u.filename)
            }
        });

        if (!res.data || !res.data[mutationName]) {
            uploads.forEach(upload => {
                dispatch({
                    type: releaseUploadActions.SET_ERROR,
                    payload: {
                        fileKey: upload.key,
                        error: SERVER_ERROR_MSG
                    }
                });
            });
            return;
        }

        const resData = res.data[mutationName];

        if (resData.errors) {
            resData.errors.forEach(({ filename, reason }) => {
                dispatch({
                    type: releaseUploadActions.SET_ERROR,
                    payload: { fileKey: keyMap[filename], error: reason }
                });
            });
        }

        if (resData.uploads && resData.uploads.length > 0) {
            dispatch({
                type: releaseUploadActions.SET_UPLOADING,
                payload: {
                    uploads: resData.uploads.map(u => ({
                        id: u.id,
                        key: keyMap[u.filename],
                        s3Url: u.url
                    }))
                }
            });
        }
    }, [claimUploads, dispatch]);

    // upload files to S3
    const initiateUpload = useCallback(async () => {
        const uploads = claimUploads(releaseUploadStatus.UPLOADING);

        if (uploads.length === 0) return;

        uploads.map(async file => {
            const { key: fileKey, s3Url, fileData } = file;
            const uploadResult = await uploadToAWS({
                url: s3Url,
                file: fileData,
                contentType: PDF_FILE_TYPE
            });

            if (uploadResult.ok === true) {
                dispatch({
                    type: releaseUploadActions.SET_UPLOAD_SUCCESS,
                    payload: { fileKey }
                });
            } else if (
                uploadResult.ok === false &&
                uploadResult.status === 403
            ) {
                // set expired URL status, to get a new S3 URL
                dispatch({
                    type: releaseUploadActions.SET_EXPIRED_URL,
                    payload: {
                        fileKey
                    }
                });
            } else {
                dispatch({
                    type: releaseUploadActions.SET_UPLOAD_FAILURE,
                    payload: { fileKey }
                });
            }
        });
    }, [claimUploads, dispatch]);

    // get new S3 upload URLs for expired URLs
    const refreshUploadUrls = useCallback(async () => {
        const uploads = claimUploads(releaseUploadStatus.EXPIRED_URL);

        if (uploads.length === 0) return;

        const keyMap = Object.fromEntries(uploads.map(u => [u.id, u.key]));
        const releaseIds = Object.keys(keyMap);

        const { data, error } = await refreshReleaseUploadUrls({
            variables: {
                releaseIds
            }
        });

        if (error) {
            // move them back to uploading status to try again, even though we don't have new URLs
            return dispatch({
                type: releaseUploadActions.SET_UPLOADING,
                payload: {
                    uploads: uploads.map(({ key, urlRefreshAttempts }) => ({
                        key,
                        urlRefreshAttempts: urlRefreshAttempts + 1
                    }))
                }
            });
        }

        if (data && data.getNewUploadUrlsForPhotoReleaseUploads) {
            const newUploads = data.getNewUploadUrlsForPhotoReleaseUploads;
            dispatch({
                type: releaseUploadActions.SET_UPLOADING,
                payload: {
                    uploads: newUploads.map(u => ({
                        key: keyMap[u.id],
                        s3Url: u.url
                    }))
                }
            });
        }
    }, [claimUploads, dispatch]);

    // notify backend that upload to S3 failed
    const failUpload = useCallback(async () => {
        const uploads = claimUploads(releaseUploadStatus.UPLOAD_FAILURE);

        if (uploads.length === 0) return;

        uploads.map(async file => {
            const { id, key: fileKey } = file;
            // we will ignore error message from backend, this would not be actionable
            await submitRelease({
                variables: {
                    id,
                    result: SUBMISSION_FAIL
                }
            });

            dispatch({
                type: releaseUploadActions.SET_ERROR,
                payload: {
                    fileKey,
                    error: SERVER_ERROR_MSG
                }
            });
        });
    }, [claimUploads, dispatch]);

    // notify backend that upload to S3 is complete
    const completeUpload = useCallback(async () => {
        const uploads = claimUploads(releaseUploadStatus.UPLOAD_SUCESS);

        if (uploads.length === 0) return;

        uploads.map(async file => {
            const { id, key: fileKey } = file;
            const result = await submitRelease({
                variables: {
                    id,
                    result: SUBMISSION_SUCCESS
                }
            });

            const errorMessage =
                result.data &&
                result.data.photoReleaseUploadFinishedNotification &&
                result.data.photoReleaseUploadFinishedNotification.error;

            if (errorMessage) {
                // unable to mark upload as complete, will still be in uploading state
                dispatch({
                    type: releaseUploadActions.SET_ERROR,
                    payload: { fileKey, error: errorMessage }
                });
            } else {
                dispatch({
                    type: releaseUploadActions.SET_COMPLETE,
                    payload: { fileKey }
                });
            }
        });
    }, [claimUploads, dispatch]);

    // our "event loop" - when file statuses change, initiate steps to process uploads
    useEffect(() => {
        if (DEBUG_MODE == true) {
            console.info(releaseUploads);
        }

        validateFiles();
        getUploadUrls();
        refreshUploadUrls();
        initiateUpload();
        failUpload();
        completeUpload();
    }, [statusHash]);

    return (
        <ReleasesContext.Provider
            value={{
                releaseItems, // graphql data
                getReleases,
                isLoading: loading,
                releaseUploads, // upload objects
                uploadFiles, // initiate release uploads
                withdrawPhotoReleases
            }}
        >
            {children}
        </ReleasesContext.Provider>
    );
};
