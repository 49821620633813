import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";

// RTK
import { REDUCERS } from "../../constants";

// helpers
import { addModerationNote } from "frontend/components/fort/mod/photos/helpers.js";

/*---------------------------
| Uploads Entity Adapater
---------------------------*/
export const uploadsAdapter = createEntityAdapter();

// this is a nice helper method for merging all uploads back into an array format instead of ids and entities
export const uploadsSelectors = uploadsAdapter.getSelectors();

/*---------------------------
| Uploads Slice
---------------------------*/
export const uploadsSlice = createSlice({
    name: REDUCERS.UPLOADS,
    initialState: uploadsAdapter.getInitialState({
        status: "idle",
        largeUrlsAreLoading: true
    }),
    reducers: {
        setBatchUploads: (state, action) => {
            uploadsAdapter.setMany(state, action.payload);
            state.status = "idle";
        },
        setFetchStatus: (state, action) => {
            state.status = action.payload;
        },
        setLargeUrlsAreLoading: (state, action) => {
            state.largeUrlsAreLoading = action.largeUrlsAreLoading;
        },
        setLargeUrls: (state, action) => {
            const uploads = action.payload;

            // uploadsAdapter.updateMany accepts and array of"update objects" containing an entity ID and an object containing one or more
            // new field values to update inside a changes field, and performs a shallow update on the corresponding entity.
            const uploadChanges = uploads.map(u => {
                return {
                    id: u.id,
                    changes: {
                        uploadedImageUrl: u.uploadedImageUrl
                    }
                };
            });
            uploadsAdapter.updateMany(state, uploadChanges);
            state.largeUrlsAreLoading = false;
        },
        updateUpload: (state, action) => {
            uploadsAdapter.updateOne(state, action.payload);
        },
        updateUploads: (state, action) => {
            const uploads = action.payload;
            if (uploads) {
                uploadsAdapter.updateMany(state, uploads);
            } else {
                console.error("uploadsSlice: updateUploads: Missing uploads");
            }
        },
        uploadStatusesChanged: (state, action) => {
            uploadsAdapter.upsertMany(state, action.payload);
        },
        setGemStatusToTrue: (state, action) => {
            uploadsAdapter.upsertOne(state, {
                id: action.payload,
                isGem: true
            });
        },
        setGemStatusToFalse: (state, action) => {
            uploadsAdapter.upsertOne(state, {
                id: action.payload,
                isGem: false
            });
        },
        setMatureToTrue: (state, action) => {
            uploadsAdapter.upsertOne(state, {
                id: action.payload,
                mature: true
            });
        },
        setMatureToFalse: (state, action) => {
            uploadsAdapter.upsertOne(state, {
                id: action.payload,
                mature: false
            });
        },
        setExplicitStatusToFalse: (state, action) => {
            uploadsAdapter.upsertOne(state, {
                id: action.payload,
                autoNudity: false,
                autoViolence: false
            });
        },
        needsReleaseStatusSetToFalse: (state, action) => {
            uploadsAdapter.upsertOne(state, {
                id: action.payload,
                hasRecognizablePeopleOrProperty: false
            });
        },
        editorialUseOnlySetToFalse: (state, action) => {
            uploadsAdapter.upsertOne(state, {
                id: action.payload,
                editorialUseOnly: false
            });
        },
        hasWarningTextSetToFalse: (state, action) => {
            uploadsAdapter.upsertOne(state, {
                id: action.payload,
                autoWarningText: false
            });
        },
        addModerationNote: (state, action) => {
            const note = action.payload.note;
            const photoUploadId = note.photoUploadId;
            const uploadEntity = state.entities[photoUploadId];

            // only add if upload entity already exists
            if (uploadEntity) {
                const moderationNotes = addModerationNote(uploadEntity, note);
                uploadsAdapter.updateOne(state, {
                    id: photoUploadId,
                    changes: { moderationNotes }
                });
            }
        }
    }
});

export const {
    setBatchUploads,
    setFetchStatus,
    setLargeUrlsAreLoading,
    setLargeUrls,
    updateUpload,
    updateUploads,
    uploadSavedForLater,
    resumedModForUpload,
    uploadDenied,
    uploadVisuallyPublished,
    uploadFullyPublished,
    setGemStatusToTrue,
    setGemStatusToFalse,
    setMatureToTrue,
    setMatureToFalse,
    setExplicitStatusToFalse,
    needsReleaseStatusSetToFalse,
    editorialUseOnlySetToFalse,
    hasWarningTextSetToFalse,
    uploadStatusesChanged
} = uploadsSlice.actions;
export default uploadsSlice.reducer;
