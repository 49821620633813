import React from "react";
import PropTypes from "prop-types";

import AvatarPlaceholderImg from "core/AvatarPlaceholderImg";
import { UserAvatar, AvatarWrapper } from "./styles";

const LoggedInLinks = ({ userAvatarUrl, userAvatarTitle, active }) => (
    <AvatarWrapper>
        {userAvatarUrl ? (
            <UserAvatar
                src={userAvatarUrl}
                active={active}
                alt={userAvatarTitle ? userAvatarTitle : ""}
            />
        ) : (
            <AvatarPlaceholderImg active={active} />
        )}
    </AvatarWrapper>
);

LoggedInLinks.propTypes = {
    userAvatarTitle: PropTypes.string,
    userAvatarUrl: PropTypes.string,
    active: PropTypes.bool
};

export { LoggedInLinks };
