import styled, { css } from "styled-components";

import { convertPxToRem } from "frontend/components/helpers";

import { textXs, textSm, textMd, textLg, textXl } from "core/fontStyles"; //BodyText styles
import colors from "core/colors";
import { rapidEasing } from "core/animation";
import { focusOutline } from "core/links";

// helpers:
const getSize = ({ size }) => {
    switch (size) {
        case "xs":
            return textXs;
        case "sm":
            return textSm;
        case "md":
            return textMd;
        case "lg":
            return textLg;
        case "xl":
            return textXl;
        case "inherit":
            return css`
                font-size: inherit;
                line-height: inherit;
                font-family: inherit;
                letter-spacing: inherit;
            `;
        case "initial":
            return css`
                font-size: initial;
                line-height: initial;
                font-family: initial;
                letter-spacing: initial;
            `;
        default:
            return textMd;
    }
};

export const getInlinePadding = ({ size }) => {
    switch (size) {
        case "xs":
            return css`
                ${convertPxToRem({ px: 1 })}
            `;
        case "sm":
            return css`
                ${convertPxToRem({ px: 1 })}
            `;
        case "md":
            return css`
                ${convertPxToRem({ px: 3.25 })}
            `;
        case "lg":
            return css`
                ${convertPxToRem({ px: 3.5 })}
            `;
        case "xl":
            return css`
                ${convertPxToRem({ px: 4 })}
            `;
        case "inherit":
        case "initial":
            return css`
                ${convertPxToRem({ px: 3.25 })}
            `;
        default:
            return css`
                ${convertPxToRem({ px: 3.25 })}
            `;
    }
};

export const getLinkElementStyles = ({
    display = "inline-flex",
    isButton = false,
    size = "md",
    darkMode = false,
    styleType = "primary",
    spanUnderline = false
}) => {
    return css`
        cursor: pointer;
        background: transparent;
        text-decoration: none;
        transition: all ${rapidEasing};
        align-items: center;
        svg {
            margin-right: ${convertPxToRem({ px: 8 })};
            path {
                transition: all ${rapidEasing};
            }
            ${
                size == "xs"
                    ? css`
                          height: ${convertPxToRem({ px: 18 })};
                      `
                    : size == "sm"
                    ? css`
                          height: ${convertPxToRem({ px: 20 })};
                      `
                    : size == "md"
                    ? css`
                          height: ${convertPxToRem({ px: 24 })};
                      `
                    : css`
                          height: auto;
                      `
            }
        }

        ${
            spanUnderline
                ? css`
                      border-bottom: none;
                      transition: all ${rapidEasing};
                      > span {
                          padding-bottom: ${getInlinePadding({ size })};
                          border-bottom-style: solid;
                          border-bottom-width: ${convertPxToRem({ px: 1 })};
                          transition: all ${rapidEasing};
                      }
                  `
                : css`
                      border-bottom-style: solid;
                      border-bottom-width: ${convertPxToRem({ px: 1 })};
                      transition: all ${rapidEasing};
                  `
        }
        

        ${
            display === "inline" && !isButton
                ? css`
                      display: inline;
                      padding-bottom: ${getInlinePadding({ size })};
                  `
                : css`
                      display: ${display};
                  `
        }

        ${isButton &&
            css`
                border: none;
                padding: 0;
                margin: 0;
            `}

        ${getSize({ size })}

        ${
            darkMode
                ? STYLE_TYPES.darkMode[styleType]
                : STYLE_TYPES.lightMode[styleType]
        }

        // only show focus on keyboard not with mouse:
        &:focus:not(:focus-visible) {
            outline: none;
            box-shadow: none;
            border-top: none;
            border-right: none;
            border-left: none;
        }

        &:focus {
            ${focusOutline}
        }
    `;
};

// style types:
export const STYLE_TYPE_DEFAULTS = {
    lightMode: {
        primary: css`
            border-bottom-color: ${colors.accent.lightMode.persian};
            > span {
                border-bottom-color: ${colors.accent.lightMode.persian};
            }
            color: ${colors.accent.lightMode.persian};
            svg path {
                fill: ${colors.accent.lightMode.persian};
            }
        `,
        secondary: css`
            border-bottom-color: transparent;
            > span {
                border-bottom-color: transparent;
            }
            color: ${colors.secondary.orca};
            svg path {
                fill: ${colors.secondary.orca};
            }
        `
    },
    darkMode: {
        primary: css`
            border-bottom-color: ${colors.accent.darkMode.suenosBuenos};
            color: ${colors.accent.darkMode.suenosBuenos};
            svg path {
                fill: ${colors.accent.darkMode.suenosBuenos};
            }
        `,
        secondary: css`
            border-bottom-color: transparent;
            > span {
                border-bottom-color: transparent;
            }
            color: ${colors.primary.diamond};
            svg path {
                fill: ${colors.primary.diamond};
            }
        `
    }
};

export const STYLE_TYPE_HOVERS = {
    lightMode: {
        primary: css`
            border-bottom-color: ${colors.accent.lightMode.pansy};
            > span {
                border-bottom-color: ${colors.accent.lightMode.pansy};
            }
            color: ${colors.accent.lightMode.pansy};
            svg path {
                fill: ${colors.accent.lightMode.pansy};
            }
        `,
        secondary: css`
            border-bottom-color: ${colors.secondary.orca};
            > span {
                border-bottom-color: ${colors.secondary.orca};
            }
            color: ${colors.secondary.orca};
            svg path {
                fill: ${colors.secondary.orca};
            }
        `
    },
    darkMode: {
        primary: css`
            border-bottom-color: ${colors.accent.darkMode.surf};
            > span {
                border-bottom-color: ${colors.accent.darkMode.surf};
            }
            color: ${colors.accent.darkMode.surf};
            svg path {
                fill: ${colors.accent.darkMode.surf};
            }
        `,
        secondary: css`
            border-bottom-color: ${colors.primary.diamond};
            > span {
                border-bottom-color: ${colors.primary.diamond};
            }
            color: ${colors.primary.diamond};
            svg path {
                fill: ${colors.primary.diamond};
            }
        `
    }
};

export const DISABLED_STYLE = css`
    pointer-events: none;
    color: ${colors.secondary.dolphin};
    border-bottom-color: ${colors.secondary.dolphin};
    > span {
        border-bottom-color: ${colors.secondary.dolphin};
    }
`;

export const STYLE_TYPES = {
    lightMode: {
        primary: css`
            ${({ $disableVisitedStyle }) => css`
                ${STYLE_TYPE_DEFAULTS.lightMode.primary}

                &:hover {
                    ${STYLE_TYPE_HOVERS.lightMode.primary};
                }

                //active is "pressed"
                &:active {
                    border-bottom-color: ${colors.accent.darkMode.apollo};
                    color: ${colors.accent.darkMode.apollo};
                }

                &:visited {
                    border-bottom-color: ${$disableVisitedStyle
                        ? colors.accent.lightMode.persian
                        : colors.primary.scheifer};

                    > span {
                        border-bottom-color: ${$disableVisitedStyle
                            ? colors.accent.lightMode.persian
                            : colors.primary.scheifer};
                    }

                    color: ${$disableVisitedStyle
                        ? colors.accent.lightMode.persian
                        : colors.secondary.basalt};

                    &:hover {
                        ${STYLE_TYPE_HOVERS.lightMode.primary};
                    }
                }

                &[aria-disabled="true"] {
                    ${DISABLED_STYLE}
                }
            `}
        `,
        secondary: css`
            ${({ $disableVisitedStyle }) => css`
                ${STYLE_TYPE_DEFAULTS.lightMode.secondary}
                > span {
                    border-bottom-color: transparent;
                }

                &:hover {
                    ${STYLE_TYPE_HOVERS.lightMode.secondary};
                }

                //active is "pressed"
                &:active {
                    border-bottom-color: ${colors.primary.scheifer};
                    color: ${colors.secondary.basalt};
                }

                &:visited {
                    border-bottom-color: transparent;
                    color: ${$disableVisitedStyle
                        ? colors.secondary.orca
                        : colors.secondary.basalt};

                    &:hover {
                        ${STYLE_TYPE_HOVERS.lightMode.secondary};
                    }
                }

                &[aria-disabled="true"] {
                    ${DISABLED_STYLE}
                }
            `}
        `
    },
    darkMode: {
        primary: css`
            ${({ $disableVisitedStyle }) => css`
                ${STYLE_TYPE_DEFAULTS.darkMode.primary}

                &:hover {
                    ${STYLE_TYPE_HOVERS.darkMode.primary};
                }

                //active is "pressed"
                &:active {
                    border-bottom-color: ${colors.accent.darkMode.riviera};
                    color: ${colors.accent.darkMode.riviera};
                }

                &:visited {
                    border-bottom-color: ${$disableVisitedStyle
                        ? colors.accent.darkMode.suenosBuenos
                        : colors.secondary.basalt};
                    color: ${$disableVisitedStyle
                        ? colors.accent.darkMode.suenosBuenos
                        : colors.secondary.dolphin};

                    &:hover {
                        ${STYLE_TYPE_HOVERS.darkMode.primary};
                    }
                }

                &[aria-disabled="true"] {
                    ${DISABLED_STYLE}
                }
            `}
        `,
        secondary: css`
            ${({ $disableVisitedStyle }) => css`
                ${STYLE_TYPE_DEFAULTS.darkMode.secondary}

                &:hover {
                    ${STYLE_TYPE_HOVERS.darkMode.secondary};
                }

                //active is "pressed"
                &:active {
                    border-bottom-color: ${colors.secondary.basalt};
                    color: ${colors.secondary.dolphin};
                }

                &:visited {
                    border-bottom-color: transparent;
                    color: ${$disableVisitedStyle
                        ? colors.primary.diamond
                        : colors.secondary.dolphin};

                    &:hover {
                        ${STYLE_TYPE_HOVERS.darkMode.secondary};
                    }
                }

                &[aria-disabled="true"] {
                    ${DISABLED_STYLE}
                }
            `}
        `
    }
};

export const LinkElement = styled.a`
    ${({
        display,
        isButton,
        size,
        darkMode,
        styleType,
        $disableVisitedStyle,
        spanUnderline = true
    }) =>
        getLinkElementStyles({
            display,
            isButton,
            size,
            darkMode,
            styleType,
            $disableVisitedStyle,
            spanUnderline: spanUnderline
        })}
`;
