import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import colors from "core/colors";

const createHelpers = (width, height, css) => {
    // somehow sizes is ending up in markup, even if it is not a valid svg attribute
    // until we have a better solution, just render it empty, instead to '[Object object]'
    const sanitizeSizes = sizes =>
        Object.defineProperty(sizes, "toString", {
            value: () => "",
            enumerable: false
        });

    const getDimensions = (size, sizes) => {
        if (
            size &&
            typeof size.width === "number" &&
            typeof size.height === "number"
        ) {
            return size;
        }

        return size && sizes[size] ? sizes[size] : { width, height };
    };

    const getCss = (size, sizes, fillColor, fillColorRule, noStyles) => {
        if (noStyles) {
            return "";
        }

        const dimensions = getDimensions(size, sizes);
        const fillRule =
            fillColor && fillColorRule
                ? `${fillColorRule}{ fill: ${fillColor}; }`
                : "";
        return css`
            width: ${dimensions.width}px;
            height: ${dimensions.height}px;
            ${fillRule}
        `;
    };

    const propsToCss = ({ size, sizes, fillColor, fillColorRule, noStyles }) =>
        getCss(size, sizes, fillColor, fillColorRule, noStyles);

    return {
        getCss,
        getDimensions,
        propsToCss,
        sanitizeSizes
    };
};

const width = "13";
const height = "12";
const viewBox = "0 0 13 12";

const { getDimensions, getCss, propsToCss, sanitizeSizes } = createHelpers(
    width,
    height,
    css
);

const sizes = sanitizeSizes({
    xs: { width: 14, height: 14 },
    sm: { width: 16, height: 16 },
    md: { width: 20, height: 20 },
    lg: { width: 24, height: 24 },
    xl: { width: 40, height: 40 }
});

const Image = styled.svg`
    ${propsToCss}
`;

const children = (
    <Fragment>
        <path
            fill={colors.secondary.orca}
            fillRule="evenodd"
            d="M11.2996 1.09971C11.4593 1.21924 11.5326 1.42221 11.4862 1.61619L9.57403 9.61619C9.54016 9.75764 9.44645 9.87732 9.31727 9.94408C9.18813 10.0109 9.03622 10.0181 8.90133 9.96388L7.36173 9.34533L5.85353 10.8535C5.71052 10.9965 5.49548 11.0393 5.30865 10.9619C5.12181 10.8845 4.99998 10.7022 4.99998 10.5V8.39658L1.81358 7.11638C1.63573 7.04486 1.5144 6.87806 1.5012 6.68681C1.48796 6.49564 1.58522 6.31369 1.75159 6.21849L10.7516 1.06609C10.9246 0.966992 11.14 0.980235 11.2997 1.09977L11.2996 1.09971ZM5.99993 8.79821V9.29282L6.35278 8.93997L5.99993 8.79821ZM7.33603 8.25739L6.29203 7.8379L10.0256 3.4255L8.73613 8.8199L7.52883 8.33478C7.46961 8.29497 7.40387 8.26915 7.3361 8.2574L7.33603 8.25739ZM8.78403 3.34469L5.31443 7.44519L3.14443 6.57335L8.78403 3.34469Z"
            clipRule="evenodd"
            key="key-0"
        />
    </Fragment>
);

const defaultProps = {
    children,
    viewBox,
    fillColor: null,
    fillColorRule: "&&& path, &&& use, &&& g, &&& circle",
    sizes,
    size: null
};

Image.propTypes /* remove-proptypes */ = {
    fillColor: PropTypes.string,
    fillColorRule: PropTypes.string,
    viewBox: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    size: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            height: PropTypes.number.isRequired,
            width: PropTypes.number.isRequired
        })
    ]),
    sizes: PropTypes.shape({
        height: PropTypes.number,
        width: PropTypes.number
    })
};

export default Object.assign(Image, {
    getDimensions,
    getCss,
    defaultProps,
    displayName: "PaperAirplane"
});
