import styled, { css } from "styled-components";

import colors from "core/colors";
import { rapidEasing } from "core/animation";
import breakpoints from "core/breakpoints";
import { regular } from "core/fontStyles";
import { convertPxToRem } from "frontend/components/helpers";

export const GroupContainer = styled.div`
    display: inline-flex;
    border-radius: 0.25rem;
`;

export const buttonGroupControlStyles = css`
    ${regular}
    display: inline-block;
    text-decoration: none;
    cursor: pointer;
    min-width: 2.5rem;
    margin: 0;
    padding: 0.125rem 1.375rem;
    font-size: 0.8125rem;
    line-height: 1.75;

    transition: background-color ${rapidEasing}, box-shadow ${rapidEasing},
        border ${rapidEasing};

    background-color: transparent;
    color: ${colors.secondary.orca};
    border: 1px solid ${colors.primary.scheifer};

    ${({ $isActive }) => {
        if ($isActive) {
            return css`
                background-color: ${colors.secondary.orca};
                color: ${colors.primary.diamond};
                border: 1px solid rgba(0, 0, 0, 0.23);
            `;
        }
    }}

    &:focus {
        outline: 3px solid ${colors.functional.secondary.parrot};
    }

    &:hover {
        background-color: ${colors.primary.scheifer};
        color: ${colors.secondary.cosmic};

        ${({ $isActive }) => {
            if ($isActive) {
                return css`
                    background-color: ${colors.secondary.orca};
                    color: ${colors.primary.snow};
                `;
            }
        }}
    }

    @media (max-width: ${breakpoints.beforeTablet.rem}) {
        padding: ${convertPxToRem({ px: 1 })} ${convertPxToRem({ px: 16 })};
    }

    /* Border Radius Styles */
    border-radius: 0.25rem;

    ${({ $groupLength }) => {
        if ($groupLength > 1) {
            return css`
                :first-child {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                    border-right-color: transparent;
                }
                :not(:first-child):not(:last-child) {
                    border-radius: 0;
                    border-right-color: transparent;
                }
                :last-child {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }
            `;
        }
    }}
`;

export const Button = styled.button`
    ${buttonGroupControlStyles}
`;

export const Label = styled.span`
    width: 100%;
    display: inherit;
    align-items: inherit;
    justify-content: inherit;
`;
