import { GET_CURRENT_USER_DOCUMENT } from "../graphql";

export function preCacheCurrentUser(client) {
    // Load the JSON of the currentUser query from the DOM.
    const jsonElement = document.getElementById("current-user");
    const userData = jsonElement && JSON.parse(jsonElement.innerHTML);

    // Pre-cache the results of the currentUser query.
    client.writeQuery({
        query: GET_CURRENT_USER_DOCUMENT,
        data: userData
    });
}

export function clearCurrentUserCache(client) {
    /* NOTE:
     * The current page might depend on currentUser being an object.
     * If we remove the currentUser from cache, we don't want to publish
     * those changes to the current page component before we redirect to "/"
     *
     * While we're still on v2 of apollo-client, we don't have the new
     * `broadcast` flag. The workaround is to use `writeQuery` directly
     * on the `cache` object, which lets us drop the currentUser object
     * from cache without triggering the page component to re-render.
     */
    if (client && client.cache) {
        client.cache.writeQuery({
            query: GET_CURRENT_USER_DOCUMENT,
            data: { currentUser: null }
        });
    }
}
