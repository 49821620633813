/*---------------------------
| Action Types
---------------------------*/
const actionPrefix = "ManageTagsContext:";
export const actionTypes = {
    SET_INPUT_TEXT: `${actionPrefix} setInputText`,
    SET_SHOW_SUGGESTION_DROP_DOWN: `${actionPrefix} setShowSuggestionDropDown`,
    SET_ENABLED: `${actionPrefix} setEnabled`,
    SET_ERROR_MESSAGE: `${actionPrefix} setErrorMessage`
};
