import React from "react";
import PropTypes from "prop-types";

// styles
import {
    BatchToggleStyled,
    BatchToggleButtonStyled,
    BatchToggleCaretStyled
} from "./styles";

// components
import CaretRight from "core/ReusableIcons/CaretRight";

const BatchToggle = ({ batch, onBatchToggle, batchContentIsOpen }) => {
    const { id } = batch;

    const onClick = e => {
        e.stopPropagation();
        onBatchToggle(id, !batchContentIsOpen);
    };

    return (
        <BatchToggleStyled>
            <BatchToggleButtonStyled
                onClick={onClick}
                type="button"
                data-testid="batch-toggle"
            >
                <BatchToggleCaretStyled batchContentIsOpen={batchContentIsOpen}>
                    <CaretRight size={{ width: 16, height: 16 }} />
                </BatchToggleCaretStyled>
            </BatchToggleButtonStyled>
        </BatchToggleStyled>
    );
};

export default BatchToggle;

// prop-types
BatchToggle.propTypes = {
    batch: PropTypes.object.isRequired,
    onBatchToggle: PropTypes.func.isRequired,
    batchContentIsOpen: PropTypes.bool.isRequired
};
