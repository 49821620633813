import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import CustomDropdown from "core/CustomDropdown";
import {
    useCustomDropdown,
    CustomDropdownProvider
} from "core/CustomDropdown/hooks/useCustomDropdown";

import Button from "./Button";

import {
    reusableComponentSizes,
    buttonStyleTypes,
    positionalAlignments,
    justifyContentPosition
} from "core/props";

const UnstyledButtonOptions = ({
    className,
    triggerText,
    heading,
    buttonData,
    width,
    showCloseButton,
    defaultButtonSelected,
    handleSubmit,

    isLinkTriggerStyle,
    buttonProps,
    showBoxShadow,
    bgColor,
    headingColor,
    footerChildren
}) => {
    if (!buttonData || buttonData.length === 0) return null;

    const [selectedButtonText, setSelectedButtonText] = useState(
        defaultButtonSelected
    );

    const customDropdownProps = useCustomDropdown({
        triggerText: triggerText,
        isLinkTriggerStyle: isLinkTriggerStyle,
        buttonProps: buttonProps,
        showBoxShadow: showBoxShadow,
        bgColor: bgColor,
        footerChildren: footerChildren,
        width: width,
        heading: heading,
        headingColor: headingColor,
        showCloseButton: showCloseButton
    });

    const buttonGroupAriaText = `${heading} options; ${
        selectedButtonText ? selectedButtonText : "no"
    } option is currently selected`;

    const Content = () => {
        return (
            <div
                role="group"
                aria-label={buttonGroupAriaText}
                className={className}
            >
                {buttonData.map((button, index) => {
                    const {
                        text,
                        handleButtonClick,
                        width: buttonWidth
                    } = button;

                    const buttonProps = {
                        text,
                        handleButtonClick,
                        width: buttonWidth ? buttonWidth : "auto",
                        selectedButtonText,
                        setSelectedButtonText,
                        handleClose: customDropdownProps.handleClose,
                        handleSubmit
                    };

                    return <Button key={index} {...buttonProps} />;
                })}
            </div>
        );
    };

    return (
        <CustomDropdown {...customDropdownProps}>
            <Content />
        </CustomDropdown>
    );
};

const ButtonOptionsComponent = styled(UnstyledButtonOptions)``;

const ButtonOptions = ({
    className,
    triggerText,
    heading,
    buttonData,
    width = "auto",
    showCloseButton = true,
    defaultButtonSelected = "",
    handleSubmit,
    isLinkTriggerStyle = true,
    buttonProps,
    showBoxShadow,
    bgColor,
    headingColor,
    footerChildren
}) => {
    return (
        <CustomDropdownProvider>
            <ButtonOptionsComponent
                className={className}
                triggerText={triggerText}
                heading={heading}
                buttonData={buttonData}
                width={width}
                showCloseButton={showCloseButton}
                defaultButtonSelected={defaultButtonSelected}
                handleSubmit={handleSubmit}
                isLinkTriggerStyle={isLinkTriggerStyle}
                buttonProps={buttonProps}
                showBoxShadow={showBoxShadow}
                bgColor={bgColor}
                headingColor={headingColor}
                footerChildren={footerChildren}
            />
        </CustomDropdownProvider>
    );
};

ButtonOptions.propTypes = {
    triggerText: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
        .isRequired,
    buttonData: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string.isRequired,
            handleButtonClick: PropTypes.func,
            width: PropTypes.string
        })
    ).isRequired,
    isLinkTriggerStyle: PropTypes.bool,
    buttonProps: PropTypes.shape({
        size: PropTypes.oneOf(reusableComponentSizes),
        styleType: PropTypes.oneOf(buttonStyleTypes),
        width: PropTypes.string,
        darkMode: PropTypes.bool,
        isDisabled: PropTypes.bool,
        isProcessing: PropTypes.bool,
        iconPosition: PropTypes.oneOf(positionalAlignments),
        processingIconPosition: PropTypes.oneOf(positionalAlignments),
        justifyContent: PropTypes.oneOf(justifyContentPosition),
        isPill: PropTypes.bool,
        isActive: PropTypes.bool,
        isToggled: PropTypes.bool,
        isSubmit: PropTypes.bool
    }),
    defaultButtonSelected: PropTypes.string,
    showCloseButton: PropTypes.bool,
    showBoxShadow: PropTypes.bool,
    width: PropTypes.string,
    bgColor: PropTypes.string,
    headingColor: PropTypes.string,
    heading: PropTypes.string,
    footerChildren: PropTypes.any,
    handleSubmit: PropTypes.func
};

export default ButtonOptions;
