import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// styles
import { convertPxToRem } from "frontend/components/helpers";
import { H3 } from "core/Typography/index.js";
export const BatchTitleStyled = styled(H3)`
    font-size: ${convertPxToRem(23)};
    margin: ${convertPxToRem(24)} 0px;
`;

export const BatchTitle = ({ text }) => {
    if (!text) return null;
    return <BatchTitleStyled>{text}</BatchTitleStyled>;
};

export default BatchTitle;

// prop-types
BatchTitle.propTypes = {
    text: PropTypes.string
};
