import { useMessageStack } from "core/MessageStack/useMessageStack";

const DEFAULT_ERROR_MESSAGE =
    "Request rate limited for security. Please wait a moment and try again.";

export const isRateLimited = error => {
    if (!error || !error.graphQLErrors || !error.graphQLErrors.length)
        return false;

    return error.graphQLErrors.some(e => e.message === "rate_limited");
};

/**
 * Abstracts code for identifying and handling rate limit errors from GraphQL.
 *
 * `handleError` takes an ApolloError object, and optional message and callback.
 * If passed directly to the onError callback on a useMutation or useQuery hook,
 * it will add a default error message to the MessageStack when a rate limit error
 * is detected. If you don't want to use MessageStack, pass a callback to `handleError`.
 *
 * `isRateLimited` is a helper function that returns true if the ApolloError object
 * contains a rate limit error. This provides more flexibility to consumers.
 *
 * @returns {Object} - {handleError, isRateLimited}
 */
export const useRateLimitError = () => {
    const { addMessages } = useMessageStack();

    const handleError = (
        error,
        { message = DEFAULT_ERROR_MESSAGE, callback = null } = {}
    ) => {
        if (isRateLimited(error)) {
            if (callback) {
                callback(message);
            } else {
                addMessages([
                    {
                        id: "rate-limited",
                        infoType: "error",
                        text: message
                    }
                ]);
            }
        }
    };

    return { handleError, isRateLimited };
};
