import React, { useMemo, useState, useEffect } from "react";
import { useDispatch, useStore, useSelector } from "react-redux";

// styles
import { ModerationToggleBandContainerStyled } from "./styles";

// Config
import * as Config from "frontend/components/fort/mod/photos/config";

// hooks
import { useModerationBandIsEnabled } from "../../hooks/useModerationBandIsEnabled";

// components
import CoreModerationToggleBand, {
    Heart,
    MatureAudience,
    Divider,
    Clock,
    Eye,
    Expand
} from "frontend/components/fort/mod/core/ModerationToggleBand";

import { useSelect } from "frontend/components/core/Grid2/GridItemContentTemplates/Select/SelectContext";

import {
    blurUpload,
    unblurUpload
} from "../../slices/uploadFooterActionsSlice";

import { useLargeImageView } from "../../hooks/useLargeImageView";

import {
    uploadsSelectors,
    uploadFooterActionsSelectors
} from "../../selectors";

import { useSetMature } from "frontend/components/fort/mod/photos/hooks/mutations/moderationBand/useSetMature.js";
import { useSetFavorite } from "frontend/components/fort/mod/photos/hooks/mutations/moderationBand/useSetFavorite.js";
import { useSetSaveForLater } from "frontend/components/fort/mod/photos/hooks/mutations/moderationBand/useSetSaveForLater.js";

const ModerationToggleBand = () => {
    const { setMature } = useSetMature();
    const { setFavorite } = useSetFavorite();
    const { setSaveForLater } = useSetSaveForLater();

    const store = useStore();
    const dispatch = useDispatch();
    const { openLargeView } = useLargeImageView();

    const {
        queueHeader: { defaultIsEnabled, expandIsEnabled, heartIsEnabled }
    } = useModerationBandIsEnabled();

    const [actionBarIconActions, setActionBarIconActions] = useState({});

    const { getSelectedUploadIds, uploadsSelected = {} } = useSelect();
    const { uploadFooterActions } = useSelector(state => state);

    const selectedUploadIds = useMemo(
        () => getSelectedUploadIds(uploadsSelected),
        [uploadsSelected]
    );

    useEffect(() => {
        const selectedUploadFooterActions = selectedUploadIds.map(id =>
            uploadFooterActionsSelectors.selectById(store.getState(), id)
        );

        // This maps to the moderatorIconKeys keys in Config
        const actions = Config.getActionBarIconActions(
            selectedUploadFooterActions
        );
        setActionBarIconActions(actions);
    }, [selectedUploadIds, uploadFooterActions]);

    const onSaveForLaterToggle = ({ isActivated }) => {
        if (!selectedUploadIds.length) return;
        setSaveForLater({ photoUploadIds: selectedUploadIds, isActivated });
    };

    const onHeartToggle = ({ isActivated }) => {
        if (!selectedUploadIds.length) return;
        setFavorite({ photoUploadIds: selectedUploadIds, isActivated });
    };

    const onMatureToggle = ({ isActivated }) => {
        if (!selectedUploadIds.length) return;
        setMature({ photoUploadIds: selectedUploadIds, isActivated });
    };

    const onEyeToggle = ({ isActivated }) => {
        selectedUploadIds.forEach(id => {
            const upload = uploadsSelectors.selectById(store.getState(), id);

            isActivated
                ? dispatch(blurUpload(upload.id))
                : dispatch(unblurUpload(upload.id));
        });
    };

    const onExpandToggle = ({ isActivated }) => {
        if (!selectedUploadIds.length) return;
        if (isActivated) {
            openLargeView();
        }
    };

    return (
        <ModerationToggleBandContainerStyled>
            <CoreModerationToggleBand isWhiteBackground>
                <Heart
                    isDisabled={!heartIsEnabled}
                    isActivated={
                        actionBarIconActions[Config.moderatorIconKeys.HEART]
                    }
                    moderationIconKey={Config.moderatorIconKeys.HEART}
                    onToggle={onHeartToggle}
                />
                <MatureAudience
                    isActivated={
                        actionBarIconActions[
                            Config.moderatorIconKeys.MATURE_AUDIENCE
                        ]
                    }
                    moderationIconKey={Config.moderatorIconKeys.MATURE_AUDIENCE}
                    isDisabled={!defaultIsEnabled}
                    onToggle={onMatureToggle}
                />
                <Divider />
                <Clock
                    isActivated={
                        actionBarIconActions[
                            Config.moderatorIconKeys.SAVE_FOR_LATER
                        ]
                    }
                    moderationIconKey={Config.moderatorIconKeys.SAVE_FOR_LATER}
                    isDisabled={!defaultIsEnabled}
                    onToggle={onSaveForLaterToggle}
                />
                <Eye
                    isActivated={
                        actionBarIconActions[Config.moderatorIconKeys.BLUR]
                    }
                    moderationIconKey={Config.moderatorIconKeys.BLUR}
                    isDisabled={!defaultIsEnabled}
                    onToggle={onEyeToggle}
                />
                <Expand
                    isActivated={
                        actionBarIconActions[Config.moderatorIconKeys.EXPAND]
                    }
                    moderationIconKey={Config.moderatorIconKeys.EXPAND}
                    isDisabled={!expandIsEnabled}
                    onToggle={onExpandToggle}
                />
            </CoreModerationToggleBand>
        </ModerationToggleBandContainerStyled>
    );
};

export default ModerationToggleBand;
