import React, { createContext, useMemo, useState, useCallback } from "react";

import { CONSENT_COOKIE_NAME, writeCookie, readCookie } from "core/cookieKeys";

const CookieConsentContext = createContext({
    hasConsentSettings: true, // hide banner from jest snapshots
    cookies: {
        functionality: true,
        analytics: true,
        advertising: true
    },
    saveConsentSettings() {},
    acceptAllCookies() {}
});

export const CookieConsentProvider = ({ ssrConsentSettings, children }) => {
    // If cookie values change, update `check` value to break cache & look up new values.
    const [check, setCheck] = useState(null);

    const saveConsentSettings = useCallback(cookieTypes => {
        const { functionality, analytics, advertising } = cookieTypes;
        setConsentCookie({ functionality, analytics, advertising });
        setCheck(Date.now());
    }, []);

    const acceptAllCookies = useCallback(() => {
        saveConsentSettings({
            functionality: true,
            analytics: true,
            advertising: true
        });
    }, []);

    const value = useMemo(() => {
        const { cookies, hasConsentSettings } = getConsentCookie(
            ssrConsentSettings
        );
        return {
            // values
            cookies,
            hasConsentSettings,
            // methods
            saveConsentSettings,
            acceptAllCookies
        };
    }, [check]);

    return (
        <CookieConsentContext.Provider value={value}>
            {children}
        </CookieConsentContext.Provider>
    );
};

export default CookieConsentContext;

// Save consent settings to cookie so that both server & client
// HTML match - showing / hiding the consent banner.
const setConsentCookie = ({ functionality, analytics, advertising }) => {
    writeCookie({
        name: CONSENT_COOKIE_NAME,
        value: {
            dateOfConsent: Date.now(),
            functionality: !!functionality, // force to be a boolean
            analytics: !!analytics,
            advertising: !!advertising
        }
    });
};

// Determine if consent has been set.
// NOTE:
// - For plugins, we treat all users as if they've consented.
// - `ssrConsentSettings` is for when server-side is generating HTML
//   and does not have access to `document.cookie`.
// - The purpose of storing cookie_consent in a cookie, and not in
//   local-storage, is so that the client & server have access to
//   the consent settings. We want the server HTML to include the
//   consent banner for performance / SEO. But the server & client
//   HTML need to match during hydration. Thus, we use cookies.
const getConsentCookie = ssrConsentSettings => {
    const defaultSettings = {
        hasConsentSettings: false,
        cookies: {
            functionality: true,
            analytics: true,
            advertising: true
        }
    };

    try {
        if (typeof document === "undefined") {
            if (ssrConsentSettings) {
                return {
                    hasConsentSettings: true,
                    cookies: ssrConsentSettings
                };
            }
            return defaultSettings;
        }

        if (window.location.pathname.startsWith("/plugin/")) {
            return {
                ...defaultSettings,
                hasConsentSettings: true
            };
        }

        const consentCookie = readCookie(CONSENT_COOKIE_NAME);
        if (consentCookie) {
            return {
                hasConsentSettings: true,
                cookies: consentCookie
            };
        }

        return defaultSettings;
    } catch (e) {
        // ignore JSON errors
        return defaultSettings;
    }
};
