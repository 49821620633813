import styled from "styled-components";

import colors from "core/colors";

export const StyledSearchEmptyResults = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${colors.primary.diamond};
    margin-top: 2.5rem;
`;
