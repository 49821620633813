import React from "react";
import PropTypes from "prop-types";

// styles
import { LegendStyled, CheckboxesStyled, CheckboxStyled } from "./styles";

const CheckboxGroup = ({ legend, checkboxes, onClick }) => {
    return (
        <fieldset>
            <div>{legend && <LegendStyled>{legend}</LegendStyled>}</div>
            <CheckboxesStyled>
                {checkboxes.map(cb => (
                    <CheckboxStyled key={cb.id} {...cb} onClick={onClick} />
                ))}
            </CheckboxesStyled>
        </fieldset>
    );
};

export default CheckboxGroup;

// prop-types
CheckboxGroup.propTypes = {
    legend: PropTypes.string,
    checkboxes: PropTypes.array.isRequired
};
