import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { messagesStyleTypes, messagesInfoTypes, alignments } from "core/props";

import { convertPxToRem } from "frontend/components/helpers";
import { getContainerIconColor } from "./helpers";

import {
    BannerBodyText,
    IconsContainer,
    TextContainerIconButton,
    TextContainerIcon
} from "./styles";

const UnstyledTextContainer = ({
    className,
    children,
    styleType,
    infoType,
    darkMode,
    textAlignment,
    contentIcons
}) => {
    const bodyTextSize = styleType === "transparent" ? "sm" : "md";

    const contentIconsLength = contentIcons && contentIcons.length;

    const { iconColor, iconColorHover } = getContainerIconColor({
        infoType,
        darkMode
    });

    return (
        <>
            <BannerBodyText
                className={className}
                type="div"
                size={bodyTextSize}
                darkMode={darkMode}
                styleType={styleType}
                infoType={infoType}
                alignment={textAlignment}
            >
                <div>{children}</div>

                {!!contentIconsLength && contentIconsLength > 0 && (
                    <IconsContainer contentIconsLength={contentIconsLength}>
                        {contentIcons.map((icon, index) => {
                            const hasLink = icon.href;

                            const Icon = icon.svgElementType;

                            const iconTarget = icon.target
                                ? icon.target
                                : "_self";

                            const sharedIconProps = {
                                title: icon.ariaLabel,
                                ariaLabel: icon.ariaLabel,
                                messagesStyleType: styleType, // `messagesStyleType`: `styleType` conflicts with base component IconButton
                                iconColor,
                                iconColorHover,
                                key: index
                            };

                            const conditionalIconProps = !hasLink && {
                                fillColor: icon.fillColor
                            };

                            return hasLink ? (
                                <TextContainerIconButton
                                    darkMode={darkMode}
                                    width={convertPxToRem({ px: 16 })}
                                    svgElementType={Icon}
                                    href={icon.href}
                                    hrefTarget={iconTarget}
                                    {...sharedIconProps}
                                />
                            ) : (
                                <TextContainerIcon {...sharedIconProps}>
                                    <Icon size="sm" {...conditionalIconProps} />
                                </TextContainerIcon>
                            );
                        })}
                    </IconsContainer>
                )}
            </BannerBodyText>
        </>
    );
};

const TextContainer = styled(UnstyledTextContainer)``;

TextContainer.propTypes = {
    children: PropTypes.any.isRequired,
    width: PropTypes.string,
    darkMode: PropTypes.bool,
    styleType: PropTypes.oneOf(messagesStyleTypes),
    infoType: PropTypes.oneOf(messagesInfoTypes),
    textAlignment: PropTypes.oneOf(alignments),
    contentIcons: PropTypes.arrayOf(
        PropTypes.shape({
            svgElementType: PropTypes.elementType.isRequired,
            ariaLabel: PropTypes.string.isRequired,
            href: PropTypes.string,
            target: PropTypes.string,
            fillColor: PropTypes.string
        })
    )
};

export default TextContainer;
