import React from "react";
import PropTypes from "prop-types";

import { StyledBodyText } from "./styles";

import { alignments } from "core/props";

const Title = ({
    textAlignment = "left",
    darkMode = false,
    isSmallTooltip,
    message
}) => {
    return (
        <StyledBodyText
            alignment={textAlignment}
            size={isSmallTooltip ? "sm" : "md"}
            type="div"
            darkMode={darkMode}
        >
            {message}
        </StyledBodyText>
    );
};

Title.propTypes = {
    darkMode: PropTypes.bool,
    isSmallTooltip: PropTypes.bool,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    textAlignment: PropTypes.oneOf(alignments)
};

export default Title;
