import React from "react";
import PropTypes from "prop-types";

// styles
import { GalleryUploadsStyled, GalleryUploadItemStyled } from "./styles.js";

// components
import ModalUpload from "./ModalUpload.js";

const GalleryUploads = ({ uploadIds, UploadGraphicComponent }) => {
    return (
        <GalleryUploadsStyled>
            {uploadIds.map(id => {
                return (
                    <GalleryUploadItemStyled key={id}>
                        <ModalUpload
                            uploadId={id}
                            UploadGraphicComponent={UploadGraphicComponent}
                        />
                    </GalleryUploadItemStyled>
                );
            })}
        </GalleryUploadsStyled>
    );
};

export default GalleryUploads;

// prop-types
GalleryUploads.propTypes = {
    uploadIds: PropTypes.array.isRequired,
    UploadGraphicComponent: PropTypes.any.isRequired
};
