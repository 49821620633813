import React, { useReducer, useMemo, createContext, useContext } from "react";
import PropTypes from "prop-types";

// context
export const SlideshowContext = createContext();
import reducer from "./Context/reducer";
import * as Actions from "./Context/actions";
import { getDefaultState } from "./Context/getDefaultState";

// components
export { Slideshow } from "./Slideshow.js";

export const SlideshowProvider = ({
    children,
    showControls = true,
    showCounter = true,
    isCarousel = false,
    onSlideNextClick = () => {},
    onSlidePrevClick = () => {},
    ...rest
}) => {
    const [state, dispatch] = useReducer(reducer, {}, () =>
        getDefaultState({
            showControls,
            showCounter,
            isCarousel,
            ...rest
        })
    );

    const consumerProps = {
        onSlideNextClick,
        onSlidePrevClick
    };

    // useMemo so it does not pass value on every render
    const value = useMemo(() => ({ state, dispatch, consumerProps }), [
        state,
        dispatch,
        consumerProps
    ]);

    return (
        <SlideshowContext.Provider
            value={value}
            displayName="Slideshow Context"
        >
            {children}
        </SlideshowContext.Provider>
    );
};

SlideshowProvider.propTypes = {
    children: PropTypes.any,
    components: PropTypes.array.isRequired,
    showControls: PropTypes.bool,
    showCounter: PropTypes.bool,
    isCarousel: PropTypes.bool,
    onSlideNextClick: PropTypes.func,
    onSlidePrevClick: PropTypes.func
};

export const useSlideshow = () => {
    const { state, dispatch, consumerProps } = useContext(SlideshowContext);

    const slideNext = () => {
        dispatch(Actions.next());
    };
    const slidePrev = () => {
        dispatch(Actions.previous());
    };

    return {
        ...state,
        ...consumerProps,
        dispatch,
        slideNext,
        slidePrev
    };
};
