import PropTypes from "prop-types";
import reactRequiredIf from "frontend/components/helpers/react-required-if";

export const spacingSizes = [
    "none",
    "xs",
    "sm",
    "md",
    "lg",
    "xl",
    "2xl",
    "3xl",
    "4xl",
    "5xl"
];

export const headingSizes = ["xs", "sm", "md", "lg", "xl", "2xl", "3xl", "4xl"];

export const headingTypes = [
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "span",
    "div",
    "p"
];

export const alignments = ["left", "center", "right", "justify"];

export const bodyTextSizes = ["xs", "sm", "md", "lg", "xl"];
export const bodyTextTypes = ["p", "span", "div", "a", "button", "label"];

export const linkTargets = ["_blank", "_self"];
export const reusableComponentSizes = ["xs", "sm", "md", "lg"];
export const buttonStyleTypes = ["solid", "outline", "cta", "text", "none"];
export const circleButtonStyleTypes = ["primary", "secondary", "cta", "none"];
export const positionalAlignments = ["left", "right", "center"];

export const justifyContentPosition = [
    "space-around",
    "space-between",
    "flex-start",
    "center",
    "flex-end"
];
export const alignItemsPosition = [
    "stretch",
    "center",
    "flex-start",
    "flex-end",
    "baseline",
    "initial",
    "inherit"
];
export const alignContentPosition = [
    "stretch",
    "center",
    "flex-start",
    "flex-end",
    "space-between",
    "space-around",
    "space-evenly",
    "initial",
    "inherit"
];

export const modalTypes = ["text", "video", "kit", "warning", "carousel"];

export const modalSizes = ["lg", "xl", "fullScreen"];

export const tooltipSizes = ["large", "small"];

export const contentTypes = ["photo", "icon", "subscription"];

export const proSubscriptionTerms = ["/month", "/month paid yearly"];

export const collectionsTypes = ["primary", "secondary", "darkMode"];

export const popoverPlacements = [
    "auto-start",
    "auto",
    "auto-end",
    "top-start",
    "top",
    "top-end",
    "right-start",
    "right",
    "right-end",
    "bottom-end",
    "bottom",
    "bottom-start",
    "left-end",
    "left",
    "left-start"
];

export const messagesStyleTypes = ["transparent", "solid"];
export const messagesInfoTypes = [
    "success",
    "warning",
    "info",
    "error",
    "neutral"
];

export const checkboxButtonSizeTypes = ["xs", "sm", "md", "lg"];
export const checkboxButtonStyleTypes = ["default", "processing", "error"];

export const reusableIconSizes = ["xs", "sm", "md", "lg", "xl"];

// 2.4 has been added to force 5 columns. 12 % 2.4 = 5
export const gridItemSizes = [0, 1, 2, 2.4, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
export const gridItemTypes = ["div", "article", "a"];
export const gridContainerTypes = ["div", "section"];

export const sitewideBannerTypes = ["neutral", "info", "promo"];

export const linkSizes = [...bodyTextSizes, "inherit", "initial"];
export const linkStyleTypes = ["primary", "secondary"];

export const displayTypes = [
    "inline",
    "inline-block",
    "block",
    "inline-flex",
    "flex",
    "inherit"
];

/********** ********** ********** **********
 *
 * "requiredIf":
 * to make a prop required if a condition is true or false
 *
 * usage example to make ariaLabel prop required if trigger prop is not a string:
 *
 * Popover.propTypes = {
 *    ariaLabel: requiredIf({
 *        type: PropTypes.string,
 *        condition: props => typeof props.trigger !== "string"
 *    })
 * };
 *
 ********** ********** ********** **********/
export const requiredIf = reactRequiredIf;

/********** ********** ********** **********
 *
 * "oneOfConditional":
 * to return PropTypes.oneOf(conditionArray1) if condition is true,
 *      else PropTypes.oneOf(conditionArray2) if condition is false
 *
 * usage example to make buttonStyleType proptypes oneOf circleButtonStyleTypes if isCircleTrigger = true,
 *      else buttonStyleTypes if isCircleTrigger = false:
 *
 * Popover.propTypes = {
 *    buttonStyleType: oneOfConditional({
 *        conditionArray1: circleButtonStyleTypes,
 *        conditionArray2: buttonStyleTypes,
 *        condition: props => props.isCircleTrigger === true
 *    })
 * };
 *
 ********** ********** ********** **********/
export const oneOfConditional = ({
    conditionArray1,
    conditionArray2,
    condition
}) => {
    return function(props, propName, componentName) {
        if (typeof conditionArray1 !== "object") {
            return new Error(
                "Invalid react-required-if prop type supplied to " +
                    componentName +
                    ". Validation failed."
            );
        }

        if (typeof conditionArray2 !== "object") {
            return new Error(
                "Invalid react-required-if prop type supplied to " +
                    componentName +
                    ". Validation failed."
            );
        }

        if (typeof condition !== "function") {
            return new Error(
                "Invalid react-required-if condition supplied to " +
                    componentName +
                    ". Validation failed."
            );
        }

        const test = condition(props)
            ? PropTypes.oneOf(conditionArray1)
            : PropTypes.oneOf(conditionArray2);
        return test.apply(this, arguments);
    };
};
