import { css } from "styled-components";

import breakpoints from "core/breakpoints";
import { spacingWithBreakpoints } from "core/spacing";
import {
    headingXs,
    headingSm,
    headingMd,
    headingLg,
    headingXl,
    heading2Xl,
    heading3Xl,
    heading4Xl
} from "core/fontStyles";

export const handleHeadingSize = ({ size }) => {
    switch (size) {
        case "xs":
            return headingXs;
        case "sm":
            return headingSm;
        case "md":
            return headingMd;
        case "lg":
            return headingLg;
        case "xl":
            return headingXl;
        case "2xl":
            return heading2Xl;
        case "3xl":
            return heading3Xl;
        case "4xl":
            return heading4Xl;
        default:
            return null;
    }
};

export const handleBottomSpacing = ({ bottomSpacing }) => {
    switch (bottomSpacing) {
        case "none":
            return spacingWithBreakpoints.NoneBottom;
        case "xs":
            return spacingWithBreakpoints.XSBottom;
        case "sm":
            return spacingWithBreakpoints.SMBottom;
        case "md":
            return spacingWithBreakpoints.MDBottom;
        case "lg":
            return spacingWithBreakpoints.LGBottom;
        case "xl":
            return spacingWithBreakpoints.XLBottom;
        case "2xl":
            return spacingWithBreakpoints.XL2Bottom;
        case "3xl":
            return spacingWithBreakpoints.XL3Bottom;
        case "4xl":
            return spacingWithBreakpoints.XL4Bottom;
        case "5xl":
            return spacingWithBreakpoints.XL5Bottom;
        default:
            return null;
    }
};

export const handleTopSpacing = ({ topSpacing }) => {
    switch (topSpacing) {
        case "none":
            return spacingWithBreakpoints.NoneTop;
        case "xs":
            return spacingWithBreakpoints.XSTop;
        case "sm":
            return spacingWithBreakpoints.SMTop;
        case "md":
            return spacingWithBreakpoints.MDTop;
        case "lg":
            return spacingWithBreakpoints.LGTop;
        case "xl":
            return spacingWithBreakpoints.XLTop;
        case "2xl":
            return spacingWithBreakpoints.XL2Top;
        case "3xl":
            return spacingWithBreakpoints.XL3Top;
        case "4xl":
            return spacingWithBreakpoints.XL4Top;
        case "5xl":
            return spacingWithBreakpoints.XL5Top;
        default:
            return null;
    }
};
