import styled, { css } from "styled-components";

import Flexbox from "core/Flexbox";
import colors from "core/colors";
import { easingSeconds } from "core/animation";
import { regular as regularFont } from "core/fontStyles";
import breakpoints from "core/breakpoints";

import { handleModalHeight } from "./helpers";
import { convertPxToRem } from "frontend/components/helpers";

const NONMOBILE_MODALCONTENT_MAXHEIGHT_PX = 454;
const HEADER_FOOTER_HEIGHT_PX = 72;
const HEADER_FOOTER_HEIGHT = convertPxToRem({ px: HEADER_FOOTER_HEIGHT_PX });
const MOBILE_MARGIN_TB_PAPER = convertPxToRem({ px: 20 });
const MOBILE_MARGIN_LR_PAPER = convertPxToRem({ px: 10 });

export const XL_LEFT_RIGHT_SPACING_REM = convertPxToRem({ px: 88 });
export const XL_TOP_BOTTOM_SPACING_REM = convertPxToRem({ px: 59 });

const getSize = ({ size, type, heading, footerChildren }) => {
    const hasHeadingAndHasFooter = heading && footerChildren;
    const hasOnlyHeadingOrFooter = !heading || !footerChildren;
    const hasNoHeadingAndHasFooter = !heading && !!footerChildren;
    const hasHeadingAndHasNoFooter = !!heading && !footerChildren;
    const hasNoHeadingAndHasNoFooter = !heading && !footerChildren;

    if (size === "fullScreen") {
        if (hasNoHeadingAndHasNoFooter) {
            return css`
                max-width: 100%;
                width: 100%;
                height: 100%;

                @media (max-width: ${breakpoints.beforeTablet.rem}) {
                    margin: ${MOBILE_MARGIN_TB_PAPER} 0;
                }

                ${ModalContent} {
                    max-height: none;
                    height: 100%;
                }
            `;
        } else if (hasHeadingAndHasFooter) {
            return css`
                max-width: 100%;
                width: 100%;
                height: 100%;

                @media (max-width: ${breakpoints.beforeTablet.rem}) {
                    margin: ${MOBILE_MARGIN_TB_PAPER} 0;
                }

                ${ModalContent} {
                    max-height: none;
                    height: calc(100% - (${HEADER_FOOTER_HEIGHT}));
                }
            `;
        } else if (hasNoHeadingAndHasFooter) {
            return css`
                max-width: 100%;
                width: 100%;
                height: 100%;

                @media (max-width: ${breakpoints.beforeTablet.rem}) {
                    margin: ${MOBILE_MARGIN_TB_PAPER} 0;
                }

                ${ModalContent} {
                    max-height: none;
                    height: 100%;
                }
            `;
        } else if (hasHeadingAndHasNoFooter) {
            return css`
                max-width: 100%;
                width: 100%;
                height: 100%;

                @media (max-width: ${breakpoints.beforeTablet.rem}) {
                    margin: ${MOBILE_MARGIN_TB_PAPER} 0;
                }

                ${ModalContent} {
                    max-height: none;
                    height: calc(100% - (${HEADER_FOOTER_HEIGHT}));
                }
            `;
        }
    } else if (size === "xl") {
        const carouselXLWidth =
            type === "carousel"
                ? css`
                      width: 100%;
                  `
                : css`
                      width: calc(
                          100% - (${XL_LEFT_RIGHT_SPACING_REM}) -
                              (${XL_LEFT_RIGHT_SPACING_REM})
                      );
                  `;

        if (hasNoHeadingAndHasNoFooter) {
            return css`
                ${carouselXLWidth}

                ${ModalContent} {
                    max-height: none;
                    height: calc(
                        100vh - (${XL_TOP_BOTTOM_SPACING_REM}) -
                            (${XL_TOP_BOTTOM_SPACING_REM})
                    );
                }
            `;
        } else if (hasOnlyHeadingOrFooter) {
            return css`
                ${carouselXLWidth}

                ${ModalContent} {
                    max-height: none;
                    height: calc(
                        100vh - (${XL_TOP_BOTTOM_SPACING_REM}) -
                            (${XL_TOP_BOTTOM_SPACING_REM}) -
                            (${HEADER_FOOTER_HEIGHT})
                    );
                }
            `;
        } else if (hasHeadingAndHasFooter) {
            return css`
                ${carouselXLWidth}

                ${ModalContent} {
                    max-height: none;
                    height: calc(
                        100vh - (${XL_TOP_BOTTOM_SPACING_REM}) -
                            (${XL_TOP_BOTTOM_SPACING_REM}) -
                            (${HEADER_FOOTER_HEIGHT}) -
                            (${HEADER_FOOTER_HEIGHT})
                    );
                }
            `;
        }
    } else if (size === "lg") {
        const DEFAULT_LG_STYLES = css`
            width: 100%;
            max-width: ${convertPxToRem({ px: 500 })};

            ${ModalContent} {
                max-height: ${handleModalHeight({
                    footerChildren,
                    heading,
                    NONMOBILE_MODALCONTENT_MAXHEIGHT_PX,
                    HEADER_FOOTER_HEIGHT_PX
                })};
            }
        `;

        if (type === "video") {
            return css`
                ${DEFAULT_LG_STYLES}
                max-width: ${convertPxToRem({ px: 704 })};
            `;
        } else if (type === "carousel") {
            return css`
                ${Paper} {
                    width: ${convertPxToRem({ px: 500 })};

                    /* Mobile-only: */
                    @media (max-width: ${breakpoints.beforeTablet.rem}) {
                        width: 100%;
                    }
                }

                ${ModalContent} {
                    max-height: ${handleModalHeight({
                        footerChildren,
                        heading,
                        NONMOBILE_MODALCONTENT_MAXHEIGHT_PX,
                        HEADER_FOOTER_HEIGHT_PX
                    })};
                }
            `;
        } else if (type === "kit") {
            if (heading && !footerChildren) {
                return css`
                    width: 100%;
                    max-width: ${convertPxToRem({ px: 500 })};

                    ${ModalContent} {
                        max-height: ${convertPxToRem({ px: 454 })};
                    }
                `;
            } else {
                return css`
                    width: 100%;
                    max-width: ${convertPxToRem({ px: 500 })};

                    ${ModalContent} {
                        max-height: ${handleModalHeight({
                            footerChildren,
                            heading,
                            NONMOBILE_MODALCONTENT_MAXHEIGHT_PX,
                            HEADER_FOOTER_HEIGHT_PX
                        })};
                    }
                `;
            }
        }

        return DEFAULT_LG_STYLES;
    }
};

export const modalStyles = css`
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.6);
`;

export const ModalFlexbox = styled(Flexbox)`
    ${({ size, type, heading, footerChildren }) =>
        getSize({ size, type, heading, footerChildren })}
`;

export const ModalContent = styled.div`
    padding: ${convertPxToRem({ px: 24 })};
    padding-top: ${convertPxToRem({ px: 4 })};
    // ^^ when there is a Button, for example, the focus style needs space around it to show
    overflow: auto;
    ${regularFont}
`;

export const Paper = styled.div`
    position: relative;
    width: 100%;
    background-color: ${colors.primary.snow};
    overflow-y: auto;
    border-radius: ${convertPxToRem({ px: 4 })};
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);

    ${({ heading }) =>
        !heading &&
        css`
            ${ModalContent} {
                padding-top: ${convertPxToRem({ px: 24 })};
            }
        `}

    ${({ size }) =>
        size === "fullScreen" &&
        css`
            height: 100%;
        `}

    /* Mobile-only: */
    @media (max-width: ${breakpoints.beforeTablet.rem}) {
        margin: ${MOBILE_MARGIN_TB_PAPER} ${MOBILE_MARGIN_LR_PAPER};
    }
`;

export const CloseButton = styled.button`
    border: none;
    background: none;
    padding: 0;
    margin: 0;
    cursor: pointer;

    svg,
    svg path {
        transition: all ${easingSeconds};
    }

    &:hover {
        svg path {
            opacity: 0.6;
        }
    }

    ${({ heading }) =>
        heading
            ? css`
                  margin-left: ${convertPxToRem({ px: 8 })};
              `
            : css`
                  position: absolute;
                  top: ${convertPxToRem({ px: 15 })};
                  right: ${convertPxToRem({ px: 15 })};
              `}
`;

export const HeadingFlexbox = styled(Flexbox)`
    padding: ${convertPxToRem({ px: 24 })};
    min-height: ${HEADER_FOOTER_HEIGHT};
`;

export const ModalFooter = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    padding: ${convertPxToRem({ px: 24 })};
    min-height: ${HEADER_FOOTER_HEIGHT};
    border-top: 1px solid ${colors.primary.scheifer};
`;

export const CarouselBtnContainer = styled.div`
    margin: ${convertPxToRem({ px: 20 })};
    z-index: 10001; // same z-index as Modal

    @media (max-width: ${breakpoints.beforeDesktop.rem}) {
        margin: ${convertPxToRem({ px: 18 })};
    }
`;

export const EmptyPlaceholder = styled.div`
    margin: ${convertPxToRem({ px: 20 })};

    height: ${convertPxToRem({ px: 40 })};
    width: ${convertPxToRem({ px: 40 })};
`;
