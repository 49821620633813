import React, { useState, useEffect } from "react";

// context
import { useBatches } from "frontend/components/fort/mod/photos/components/PhotoModQueuePageContent/QueueProviders/BatchesProvider/index.js";

// components
import FormSearch from "core/FormSearch";

// helpers
import { isOnlyWhiteSpace } from "frontend/components/fort/mod/photos/helpers.js";

// component
export const Search = () => {
    const { searchParams, setUrlSearchParams } = useBatches();

    const textSearch = searchParams?.textSearch || "";

    const [inputTextSearch, setInputTextSearch] = useState(textSearch);

    const onChange = value => {
        const newText = isOnlyWhiteSpace(value) ? value.trim() : value;
        setInputTextSearch(newText); // empty spaces not permitted
    };

    const onSubmit = e => {
        e.preventDefault(); // don't want form to post data through URL and refresh page
        setUrlSearchParams([
            {
                key: "textSearch",
                value: inputTextSearch.trim()
            }
        ]);
    };

    useEffect(() => {
        setInputTextSearch(textSearch);
    }, [textSearch]);

    return (
        <div>
            {/* Using Form onSubmit, gives ua access to enter key for free, better accessibility */}
            <form onSubmit={onSubmit}>
                <FormSearch
                    labelText="Search"
                    placeholderText="Search"
                    onChange={onChange}
                    value={inputTextSearch || ""}
                />
            </form>
        </div>
    );
};

export default Search;
