import React from "react";
import styled, { css } from "styled-components";

import PropTypes from "prop-types";

// context
import { useSiteHeader } from "core/SiteHeaderV2/context/useSiteHeader.js";

// styles
import { convertPxToRem } from "frontend/components/helpers";
import colors from "core/colors";

/**
 * ScrollPlaceholder (Placeholder)
 * This ensures the content below header scrolls naturally
 * As upper positioned fix layers will not
 */
const ScrollPlaceholderStyled = styled.div`
    text-align: center;
    height: ${convertPxToRem(128)};

    /* Light Mode */
    background-color: transparent;

    /* Dark Mode */
    ${({ $isDarkMode }) => {
        if ($isDarkMode) {
            return css`
                background-color: ${colors.secondary.orca};
            `;
        }
    }}
`;

/**
 * ScrollToPinned
 * This will scroll based on window.scollY range, not every page uses the same range see core/SiteHeaderV2/context/getHeaderProps.js
 */
const ScrollToPinnedStyled = styled.div`
    position: fixed;
    left: 0px;
    right: 0px;
    z-index: 1000;
    padding: ${convertPxToRem(18)};

    /* Light Mode */
    background-color: ${colors.primary.snow};
    border-bottom: solid 1px ${colors.primary.scheifer};

    /* Dark Mode */
    ${({ $isDarkMode }) => {
        if ($isDarkMode) {
            return css`
                background-color: ${colors.secondary.orca};
                border-bottom: solid 1px ${colors.secondary.orca};
            `;
        }
    }}

    ${({ $top, $paddingBottom }) => {
        return css`
            top: ${convertPxToRem($top)};
            padding-bottom: ${convertPxToRem($paddingBottom)};
        `;
    }}
`;

const ScrolledToPinnedInnerStyled = styled.div`
    width: 100%;
    max-width: ${convertPxToRem(1366)};
    margin: 0px auto;
`;

// component
export const ScrollToPinned = ({ children }) => {
    const {
        header: { top, paddingBottom },
        isDarkMode
    } = useSiteHeader();

    return (
        <ScrollPlaceholderStyled $isDarkMode={isDarkMode}>
            <ScrollToPinnedStyled
                $isDarkMode={isDarkMode}
                $top={top}
                $paddingBottom={paddingBottom}
            >
                <ScrolledToPinnedInnerStyled>
                    {children}
                </ScrolledToPinnedInnerStyled>
            </ScrollToPinnedStyled>
        </ScrollPlaceholderStyled>
    );
};

export default ScrollToPinned;

// prop-types
ScrollToPinned.propTypes = {
    children: PropTypes.any
};
