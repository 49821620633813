import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { P } from "core/Typography";
import colors from "core/colors";
import {
    Wrapper,
    Header,
    ContainedGrid,
    HeaderContent,
    DummyElement,
    CloseIcon,
    Links,
    Link,
    LinkHeading,
    MobileSpacing
} from "./styles";

import { productMenu, nounProjectMenu } from "data/links";

const CloseButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
`;

let OverflowMenuMobile = ({ closeMenu, className }) => {
    const getLinks = ({ linkData }) =>
        linkData.map(link => (
            <Link
                key={link.name}
                onClick={closeMenu}
                href={link.href}
                target={link.external ? "_blank" : "_self"}
            >
                {link.name}
            </Link>
        ));

    const productMenuLinks = getLinks({ linkData: productMenu });
    const nounProjectMenuLinks = getLinks({ linkData: nounProjectMenu });

    return (
        <Wrapper className={className}>
            <Header>
                <ContainedGrid>
                    <HeaderContent
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <DummyElement />
                        <P size={16} bold>
                            Menu
                        </P>
                        <CloseButton onClick={closeMenu}>
                            <CloseIcon
                                fillColor={colors.secondary.oolong}
                                ariaLabel="Close menu"
                            />
                        </CloseButton>
                    </HeaderContent>
                </ContainedGrid>
            </Header>
            <LinkHeading>Products</LinkHeading>
            <Links>{productMenuLinks}</Links>
            <LinkHeading>Noun Project</LinkHeading>
            <Links>{nounProjectMenuLinks}</Links>
            <MobileSpacing />
        </Wrapper>
    );
};

OverflowMenuMobile = styled(OverflowMenuMobile)``;

OverflowMenuMobile.propTypes = {
    closeMenu: PropTypes.func
};

export { OverflowMenuMobile };
