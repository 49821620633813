import React, { useEffect } from "react";

// Context
import { useSlideshow } from "./index.js";

// styles
import { SlideshowStyled } from "./styles.js";

// hooks
import { useKeyboardListener } from "js/hooks/useKeyboardListener";

// components
import SlideList from "./SlideList.js";
import PrevButton from "./PrevButton.js";
import NextButton from "./NextButton.js";

export const Slideshow = () => {
    const { slideNext, slidePrev } = useSlideshow();

    const { keyPressed, evObj } = useKeyboardListener();

    useEffect(() => {
        if (["ArrowRight"].includes(keyPressed)) {
            slideNext();
        }
        if (["ArrowLeft"].includes(keyPressed)) {
            slidePrev();
        }
    }, [keyPressed, evObj]);

    return (
        <SlideshowStyled>
            <PrevButton />
            <SlideList />
            <NextButton />
        </SlideshowStyled>
    );
};
