import styled, { css } from "styled-components";

//styles/components:
import Grid from "core/Grid";
import colors from "core/colors";
import { BorderBottomLinkStyle } from "core/Typography";
import BodyText from "core/BodyText";
import Flexbox from "core/Flexbox";
import { focusOutline } from "core/links";
import Checkbox from "core/Checkbox";
import { textMd } from "core/fontStyles";
import Modal from "core/Modal";
import { Paper } from "core/Modal/styles";
import { DropdownBox } from "core/CustomDropdown/styles";
import { ChildrenContainer } from "core/CustomDropdown/styles.js";

//helpers:
import { convertPxToRem } from "frontend/components/helpers";

export const EditModal = styled(Modal)`
    ${Paper} {
        max-width: ${convertPxToRem(1366)};
    }
`;

export const BlueLink = styled.a`
    border: 0;
    ${BorderBottomLinkStyle}
    background: transparent;
    padding: 0;
    padding-bottom: ${convertPxToRem(1)};
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
    cursor: pointer;
    color: ${colors.accent.lightMode.persian};
    border-color: ${colors.accent.lightMode.persian};

    &:hover {
        color: ${colors.secondary.cosmic};
        border-color: ${colors.secondary.cosmic};
    }

    // only show focus on keyboard not with mouse:
    &:focus:not(.focus-visible) {
        outline: none;
        box-shadow: none;
    }

    &:focus {
        box-shadow: inset 0 0 0 3px ${colors.functional.secondary.parrot};
        outline: none;
    }
`;

export const TooltipButton = styled.button`
    font-size: 0;
    line-height: 0;
    background: transparent;
    border: none;
    padding: 0;
    margin: 0 ${convertPxToRem(8)};
    cursor: pointer;

    // only show focus on keyboard not with mouse:
    &:focus:not(.focus-visible),
    &:active {
        outline: none;
        box-shadow: none;
        border: none;
    }

    &:focus {
        ${focusOutline}
    }
`;

export const SectionContainer = styled.div`
    width: 100%;
`;

// left col (images)
export const LeftColGridItem = styled(Grid)`
    overflow: auto;
    overflow-x: hidden;
`;

//all sections inside right col modal:
export const RightColGridItem = styled(Grid)``;

//license selection:
export const LicenseFlexbox = styled(Flexbox)`
    margin-bottom: ${convertPxToRem(24)};

    ${BodyText} {
        display: inline-block;
    }
`;

export const LicenseTextContainer = styled.div``;

export const LicenseTypeBodyTextHeading = styled(BodyText)`
    //todo: there is no spacing in figma, this is a guess:
    margin-right: ${convertPxToRem(4)};
`;

export const LicenseTooltipContainer = styled.div`
    font-size: 0;
    line-height: 0;
`;

export const LicenseButtonContainer = styled.div``;

//title:
export const TitleFieldContainer = styled.div`
    min-height: ${convertPxToRem(64)};
    margin-bottom: ${convertPxToRem(6)};
`;

//RecognizablePeopleOrProperty:
export const RecognizablePeopleOrPropertyContainer = styled.div`
    margin-bottom: ${convertPxToRem(24)};

    ${Checkbox} {
        input + span {
            ${textMd}
        }
    }

    ${BodyText} {
        margin-left: ${convertPxToRem({
            px: 16 + 12
        })}; // 16px: width of checkbox; 12px: margin between checkbox and label
    }
`;

//Releases:
export const ReleasesTitleBodyText = styled(BodyText)`
    //todo: there is no spacing in figma, this is a guess:
    margin-right: ${convertPxToRem(4)};
`;

export const ReleasesRequiredBodyText = styled(BodyText)``;

export const ReleasesFlexboxContainer = styled(Flexbox)`
    margin-bottom: ${convertPxToRem(24)};
`;

export const ReleasesTitleFlexboxContainer = styled(Flexbox)`
    ${BodyText} {
        display: inline-block;
    }
`;

export const ReleasesDropdownContainer = styled.div`
    ${DropdownBox} {
        box-shadow: ${convertPxToRem(1)} ${convertPxToRem(4)}
            ${convertPxToRem(8)} rgba(0, 0, 0, 0.25);
        max-width: ${convertPxToRem(461)};
    }
`;

export const ReleasesDropdownHeadingBodyText = styled(BodyText)`
    ${({ addBottomSpacing }) =>
        addBottomSpacing &&
        css`
            margin-bottom: ${convertPxToRem(16)};
        `}
`;

//Editorial Use Only:
export const EditorialUseOnlyContainer = styled.div`
    margin-bottom: ${convertPxToRem(24)};

    ${Checkbox} {
        input + span {
            ${textMd}
        }
    }

    ${BodyText} {
        margin-left: ${convertPxToRem({
            px: 16 + 12
        })}; // 16px: width of checkbox; 12px: margin between checkbox and label
    }
`;

//Add tags:
export const AddTagsContainer = styled.div`
    margin-bottom: ${convertPxToRem(24)};
`;

export const ButtonOptionsStyled = styled.div`
    ${ChildrenContainer} {
        /* overflow was getting in way of focuse outline styles - clipping them */
        overflow: inherit;
    }
`;
