import React from "react";
import PropTypes from "prop-types";

import { justifyContentPosition, alignItemsPosition } from "core/props";

import Grid from "core/Grid";

const OffsetGrid = ({
    className,
    sm,
    md,
    lg,
    justifyContent = "center",
    alignItems,
    children
}) => (
    <Grid
        className={className}
        desktopSpacing={0}
        justifyContent={justifyContent}
        alignItems={alignItems}
    >
        <Grid item sm={sm} md={md} lg={lg}>
            {children}
        </Grid>
    </Grid>
);

OffsetGrid.propTypes = {
    lg: PropTypes.number.isRequired,
    sm: PropTypes.number,
    md: PropTypes.number,
    justifyContent: PropTypes.oneOf(justifyContentPosition),
    alignItems: PropTypes.oneOf(alignItemsPosition)
};

export default OffsetGrid;

// the purpose of this file is so we can nest an offset column grid;
// pass in the col width with offset subtracted
// example: if there is an offset of 1 col on either side on mobile, for sm you'd pass in 10 because 12 - 1 - 1 = 10
