export const handleStylePosition = ({ size, POSITION_STYLES }) => {
    switch (size) {
        case "xs":
            return POSITION_STYLES.XS;
        case "sm":
            return POSITION_STYLES.SM;
        case "md":
            return POSITION_STYLES.MD;
        case "lg":
            return POSITION_STYLES.LG;
        default:
            return POSITION_STYLES.LG;
    }
};
