import React from "react";
import PropTypes from "prop-types";

// components
import PaperAirplane from "core/ReusableIcons/PaperAirplane";

// hooks
import { useFullyPublish } from "./useFullyPublish";

// styles
import { ButtonStyled } from "../styles";

// component
export const FullyPublishButton = ({
    buttonText = "Fully Publish",
    variant
}) => {
    const { onFullyPublish, isEditable } = useFullyPublish();

    return (
        <ButtonStyled
            onClick={onFullyPublish}
            isDisabled={!isEditable}
            variant={variant}
        >
            {buttonText}
            <PaperAirplane size={{ width: 12, height: 12 }} />
        </ButtonStyled>
    );
};

export default FullyPublishButton;

// prop-types
FullyPublishButton.propTypes = {
    buttonText: PropTypes.string,
    variant: PropTypes.string.isRequired
};
