import React from "react";

import LoadingSpinner from "core/LoadingSpinner";

import { LoadingContainer } from "./styles";

const Loading = () => {
    return (
        <LoadingContainer>
            <LoadingSpinner />
        </LoadingContainer>
    );
};

export default Loading;
