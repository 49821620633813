/**
 * chunkedPromises: executes an array of promises
 * @param {Array} array
 * @param {Function} promiseHandler
 * @returns
 */
export const chunkedPromises = async (array, promiseHandler) => {
    return await Promise.all(array.map(promiseHandler));
};

/**
 * mergeArrays
 * Shared chunkPromises promisesRespHandler for getBatchPhotoCounts and fetchUploadIds
 */
export const mergeArrays = arrayOfArrays => {
    return [].concat(...arrayOfArrays);
};

/**
 * convertArrayToChunks
 * takes an array and splits into chunks
 * Great for chunking api calls, and could be used for other things.
 */
export const convertArrayToChunks = (array, sizePerChunk = 200) => {
    const batches = [];
    for (let i = 0; i < array.length; i += sizePerChunk) {
        batches.push(array.slice(i, i + sizePerChunk));
    }
    return batches;
};
