import React from "react";
import styled from "styled-components";

// hooks
import { useBatches } from "frontend/components/fort/mod/photos/components/PhotoModQueuePageContent/QueueProviders/BatchesProvider/index.js";

// styles
import { convertPxToRem } from "frontend/components/helpers";
import colors from "core/colors";

export const NoBatchesStyled = styled.div`
    color: ${colors.secondary.orca};
    font-size: ${convertPxToRem(35)};
    line-height: 125%;

    margin: ${convertPxToRem(200)} 0px;
    padding: 0px ${convertPxToRem(20)};
    text-align: center;
`;

// components
import BatchesLoadingSkeleton from "frontend/components/fort/mod/core/Batch/BatchesLoadingSkeleton.js";
import PhotoModBatch from "../PhotoModBatch";

const PhotoModBatches = () => {
    const { ids: batchIds, entities, showLoading } = useBatches();

    if (showLoading) return <BatchesLoadingSkeleton />;

    if (batchIds.length === 0)
        return <NoBatchesStyled>No Batches Available</NoBatchesStyled>;

    return (
        <div data-testid="batches-container">
            {batchIds.map(id => {
                return <PhotoModBatch key={id} batch={entities[id]} />;
            })}
        </div>
    );
};

export default PhotoModBatches;
