import React from "react";
import { useContext } from "react";

// Context
import { SlideshowContext } from "./index.js";

// styles
import { SlideListStyled } from "./styles.js";

// components
import Slide from "./Slide.js";
import Counter from "./Counter.js";

const SlideList = () => {
    const { state } = useContext(SlideshowContext);
    const { components, height } = state;

    return (
        <SlideListStyled height={height}>
            {components.map(c => {
                return <Slide key={c.id} component={c} />;
            })}

            <Counter />
        </SlideListStyled>
    );
};

export default SlideList;
