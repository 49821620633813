/*
 * Feature Flags
 * =============
 *
 * Each constant should be assigned to a string that
 * represents a feature flag listed in:
 *
 *   noun_project/settings/base.py > `FLAGS`
 *
 * Please remove flags that are no longer in use.
 */
export const CREATIVE_PRO_ROYALTIES = "CREATIVE_PRO_ROYALTIES";
export const SAVE_PHOTOS_TO_FAVORITES = "SAVE_PHOTOS_TO_FAVORITES";
export const SAVE_ICON_SETS_TO_FAVORITES = "SAVE_ICON_SETS_TO_FAVORITES";
export const CPRO_TEAM_CHECKOUT = "CPRO_TEAM_CHECKOUT";
export const CPRO_TEAM_UPGRADE = "CPRO_TEAM_UPGRADE";
