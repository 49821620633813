import React from "react";
import PropTypes from "prop-types";

import CloseIcon from "core/ReusableIcons/Close";

import { HeaderContainer, DropdownHeading, CloseButton } from "./styles";

const Header = ({ heading, showCloseButton, handleClose, headingColor }) => {
    const closeButtonAria = heading
        ? `close ${heading} dropdown`
        : "close dropdown";

    return (
        <HeaderContainer aria-label={heading}>
            {!!heading && (
                <DropdownHeading
                    type="h2"
                    size="xs"
                    showCloseButton={showCloseButton}
                    headingColor={headingColor}
                >
                    {heading}
                </DropdownHeading>
            )}

            {showCloseButton && (
                <CloseButton aria-label={closeButtonAria} onClick={handleClose}>
                    <CloseIcon size="sm" fillColor={headingColor} />
                </CloseButton>
            )}
        </HeaderContainer>
    );
};

Header.propTypes = {
    heading: PropTypes.string,
    handleClose: PropTypes.func,
    showCloseButton: PropTypes.bool,
    headingColor: PropTypes.string
};

export default Header;
