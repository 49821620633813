import styled, { css } from "styled-components";

// styles
import { rapidEasing } from "core/animation";

// Parent Styles
import { sharedTagContainerStyles, ProcessingSpinnerIcon } from "../styles";

// dls
import { dls } from "./dls";

// styled-components
export const TagLinkStyled = styled.a`
    ${sharedTagContainerStyles};

    /* TagLink Specific Default Styles */
    transition: all ${rapidEasing};
    text-decoration: none;

    /* DSL specific styling */
    ${({ isDarkMode = false }) => {
        const mode = isDarkMode ? "dark" : "light";
        const dlsObj = dls[mode];

        return css`
            color: ${dlsObj.static.color};
            background: ${dlsObj.static.background};
            border: ${isDarkMode ? `1px solid ${dlsObj.static.color}` : `none`};

            &:hover {
                text-decoration: none;
                color: ${dlsObj.hover.color};
                background: ${dlsObj.hover.background};
                border: ${isDarkMode
                    ? `1px solid ${dlsObj.hover.color}`
                    : `none`};
            }

            ${ProcessingSpinnerIcon} {
                fill: ${dlsObj.static.color};
            }

            ${({ isDisabled = false }) => {
                if (isDisabled) {
                    return css`
                        cursor: not-allowed;
                        color: ${dlsObj.disabled.color};
                        background: ${dlsObj.disabled.background};

                        &:hover {
                            color: ${dlsObj.disabled.color};
                            background: ${dlsObj.disabled.background};
                        }
                    `;
                }
            }}
        `;
    }}
`;
