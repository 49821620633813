import React from "react";
import styled from "styled-components";

const sizes = {
    xs: { width: "14", height: "14" },
    sm: { width: "16", height: "16" },
    md: { width: "20", height: "20" },
    lg: { width: "24", height: "24" },
    xl: { width: "40", height: "40" }
};

const Svg = styled.svg`
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    ${props => (props.fillStyle ? props.fillStyle : "")}
`;

const BaseIcon = ({
    size,
    defaultSize = sizes.lg,
    viewBox = "0 0 24 24",
    fillColor,
    fillColorRule = "&&& path, &&& use, &&& g, &&& circle",
    children,
    ...props
}) => {
    const fillStyle =
        fillColor && fillColorRule
            ? `${fillColorRule}{ fill: ${fillColor}; }`
            : "";

    const { width, height } =
        size?.width && size?.height ? size : sizes[size] || defaultSize;

    return (
        <Svg
            role="img"
            width={width}
            height={height}
            viewBox={viewBox}
            fillStyle={fillStyle}
            {...props}
        >
            {children}
        </Svg>
    );
};

export default BaseIcon;
