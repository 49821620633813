import { useState, useEffect } from "react";

// hooks
import { useApolloMutation } from "frontend/utils/apolloApi/useApolloMutation.js";
import { useBatchApproval } from "../useBatchApproval";

// GQL
import { UPDATE_ONLY_PHOTO_UPLOADS } from "frontend/components/fort/mod/photos/gql/mutations";

export const useUpdateOnly = () => {
    // Hooks/State
    const { apolloMutate, loading } = useApolloMutation({
        actionDesc: "Update Only Upload(s)",
        gql: UPDATE_ONLY_PHOTO_UPLOADS
    });
    const [isEditable, setIsEditable] = useState(true);

    // Props
    const title = "Update Only";

    const useBatchApprovalConfig = {
        title,
        onApprovalAction: async ({ photoUploadIds }) => {
            return await apolloMutate({ variables: { photoUploadIds } });
        },
        successTitle: "Photo metadata updated",
        usesBatchRemoveAnimation: false,
        refetchBatches: false
    };

    const {
        selectedUploadIds,
        allFlagsCleared,
        publishingIsEditable,
        decoratedOnApproval
    } = useBatchApproval(useBatchApprovalConfig);

    // side effects
    useEffect(() => {
        let isEditable =
            !loading && selectedUploadIds.length > 0 && publishingIsEditable;

        if (!allFlagsCleared) {
            isEditable = false;
        }

        setIsEditable(isEditable);
    }, [selectedUploadIds, publishingIsEditable, allFlagsCleared, loading]);

    return {
        onUpdateOnly: decoratedOnApproval,
        isEditable
    };
};
