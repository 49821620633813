import React from "react";
import PropTypes from "prop-types";

// context
import { useManageTags } from "core/ManageTags/context/useManageTags.js";

// components
import { TagRemoveable } from "core/Tag/TagRemoveable";

// component
export const Tag = ({ tag }) => {
    const useManageTagsContext = useManageTags();
    const {
        id,
        actions,
        state: { enabled }
    } = useManageTagsContext;
    const { name, hasWarning } = tag;

    const handleOnRemove = () => {
        actions.onRemoveTag(useManageTagsContext, tag);
    };

    return (
        <TagRemoveable
            dataTestId={`add-tag-tag-${id}-tag-${tag.id}`}
            onClick={handleOnRemove}
            text={name}
            ariaLabel={name}
            hasWarning={hasWarning}
            isDisabled={!enabled.remove}
        />
    );
};

export default Tag;

// prop-types
Tag.propTypes = {
    tag: PropTypes.object.isRequired
};
