import React from "react";

import IconGrid from "icons/IconGrid";
import IconGridWithAds from "icons/IconGridWithAds";
import SkeletonGridAd from "core/SkeletonGridAd";

import Ad from "icons/IconGridWithAds/Ad";

const NUMBER_OF_ROWS_BEFORE_FIRST_AD = 2;

const SearchAndBrowseIconGridWithAds = ({
    iconData,
    numberOfColumns,
    gridSpacingSize,
    useRouterLink
}) => {
    const numberOfIconsBeforeFirstAd =
        NUMBER_OF_ROWS_BEFORE_FIRST_AD * numberOfColumns;

    const dataForFirstGrid = iconData.slice(0, numberOfIconsBeforeFirstAd);
    const restOfData = iconData.slice(numberOfIconsBeforeFirstAd);

    const lastItemInFirstGrid =
        dataForFirstGrid && dataForFirstGrid[dataForFirstGrid.length - 1];
    const showSkeletonAd =
        lastItemInFirstGrid && lastItemInFirstGrid.showSkeleton;

    const firstGridIsFull = iconData.length >= numberOfIconsBeforeFirstAd;

    return (
        <>
            <IconGrid
                iconData={dataForFirstGrid}
                numberOfColumns={numberOfColumns}
                gridSpacingSize={gridSpacingSize}
                useRouterLink={useRouterLink}
            />
            {firstGridIsFull && showSkeletonAd ? <SkeletonGridAd /> : <Ad />}
            <IconGridWithAds
                numberOfRowsBetweenAds={6}
                iconData={restOfData}
                numberOfColumns={numberOfColumns}
                gridSpacingSize={gridSpacingSize}
                useRouterLink={useRouterLink}
            />
        </>
    );
};

export default SearchAndBrowseIconGridWithAds;
