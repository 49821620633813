import React from "react";
import PropTypes from "prop-types";

import colors from "core/colors";
import {
    TooltipButton,
    ReleasesFlexboxContainer,
    ReleasesTitleFlexboxContainer,
    ReleasesDropdownContainer,
    ReleasesTitleBodyText,
    ReleasesRequiredBodyText,
    ReleasesDropdownHeadingBodyText
} from "./styles";

//hooks/providers:
import {
    useCustomDropdown,
    CustomDropdownProvider
} from "core/CustomDropdown/hooks/useCustomDropdown";
import { useReleases } from "../Releases/hooks/useReleases";

//components
import Tooltip from "core/Tooltip";
import CustomDropdown from "core/CustomDropdown";
import ReleasesUploadError from "../Releases/ReleasesUploadError";
import ReleaseUploadButton from "../Releases/ReleaseUploadButton";
import ReleaseList from "../Releases/ReleaseList";

// icons:
import InfoIcon from "core/ReusableIcons/Info";
import { determineReleaseButtonTitle } from "frontend/components/creator-tools/PhotoUploadPage/helpers";

const ReleasesSectionComponent = ({
    isRecognizablePeoplePropertyChecked,
    isEditorialUseChecked
}) => {
    const { releases } = useReleases();

    const numOfReleases = releases ? releases.length : 0;
    const hasUploadedReleases = numOfReleases > 0;

    const hasReleasesError =
        isRecognizablePeoplePropertyChecked &&
        numOfReleases === 0 &&
        !isEditorialUseChecked;

    const hideRequiredText =
        isRecognizablePeoplePropertyChecked && !isEditorialUseChecked;

    const releasesTooltipText =
        "Submissions with recognizable people or property must include releases, unless images are newsworthy and marked editorial.";

    const releasesTitleTextColor = hasReleasesError
        ? colors.functional.lightMode.sangria
        : colors.secondary.orca;
    const releasesRequiredTextColor = hasReleasesError
        ? colors.functional.lightMode.sangria
        : colors.secondary.oolong;

    const releaseButtonTitle = determineReleaseButtonTitle(numOfReleases);

    const customDropdownProps = useCustomDropdown({
        triggerText: hasUploadedReleases ? releaseButtonTitle : `Add Release`,
        buttonProps: {
            styleType: "outline",
            size: "lg",
            isError: hasReleasesError
        },
        showCloseButton: false,
        absoluteRight: "0",
        footerChildren: <ReleaseUploadButton />
    });

    return (
        <ReleasesFlexboxContainer
            justifyContent="space-between"
            alignItems="center"
        >
            <ReleasesTitleFlexboxContainer alignItems="center">
                <ReleasesTitleBodyText
                    size="md"
                    type="div"
                    copyColor={releasesTitleTextColor}
                >
                    Releases
                </ReleasesTitleBodyText>

                {hideRequiredText && (
                    <ReleasesRequiredBodyText
                        size="sm"
                        type="div"
                        copyColor={releasesRequiredTextColor}
                    >
                        Required
                    </ReleasesRequiredBodyText>
                )}
                <Tooltip
                    message={
                        <>
                            {releasesTooltipText}{" "}
                            <a
                                href="https://help.thenounproject.com/hc/en-us/articles/20487895422235-Model-Property-Release-requirements"
                                target="_blank"
                            >
                                Release requirements
                            </a>
                        </>
                    }
                >
                    <TooltipButton aria-label={releasesTooltipText}>
                        <InfoIcon
                            size="sm"
                            fillColor={colors.secondary.oolong}
                        />
                    </TooltipButton>
                </Tooltip>
            </ReleasesTitleFlexboxContainer>

            <ReleasesDropdownContainer>
                <CustomDropdown {...customDropdownProps}>
                    <ReleasesDropdownHeadingBodyText
                        size="sm"
                        type="div"
                        addBottomSpacing={hasUploadedReleases}
                    >
                        Releases For This Batch
                    </ReleasesDropdownHeadingBodyText>

                    <ReleasesUploadError />
                    <ReleaseList />
                </CustomDropdown>
            </ReleasesDropdownContainer>
        </ReleasesFlexboxContainer>
    );
};

const ReleasesSection = ({
    isRecognizablePeoplePropertyChecked,
    isEditorialUseChecked
}) => {
    return (
        <CustomDropdownProvider>
            <ReleasesSectionComponent
                isRecognizablePeoplePropertyChecked={
                    isRecognizablePeoplePropertyChecked
                }
                isEditorialUseChecked={isEditorialUseChecked}
            />
        </CustomDropdownProvider>
    );
};

export default ReleasesSection;
