import React from "react";

import TextWithButton from "core/TextWithButton";
import SectionContainer from "core/SectionContainer";
import colors from "core/colors";

import { confirmationHeading, confirmationBtn } from "./data";

const EduConfirmation = ({ match }) => {
    const { email } = match.params;
    const subHeading = `We will email you at ${email} in 1-3 business days once you're approved.`;
    return (
        <>
            <SectionContainer bgColor={colors.primary.snow}>
                <TextWithButton
                    headingType="h1"
                    headingSize="3xl"
                    headingAlignment="left"
                    subtextAlignment="left"
                    headingBottomSpacing="xl"
                    text={confirmationHeading}
                    subtext={subHeading}
                    buttons={confirmationBtn}
                    buttonAlignment="left"
                />
            </SectionContainer>
        </>
    );
};

export default EduConfirmation;
