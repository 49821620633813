import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { collectionsTypes } from "core/props";

import Grid from "core/Grid";
import Heading from "core/Heading";
import BodyText from "core/BodyText";
import GraphicContainer from "./GraphicContainer";

import {
    CollectionBoxLink,
    CollectionContent,
    TextContainer,
    BoxHeadingContainer
} from "./styles";

import { getCollectionIcons } from "./helpers";

const BoxStructure = ({ url, type, isPhoto, title, box, isLink }) => {
    return (
        <Grid item sm={12} md={4} lg={4}>
            <CollectionBoxLink
                {...(isLink ? { to: url } : { href: url })}
                type={type}
                isPhoto={isPhoto}
                as={isLink ? Link : "a"}
            >
                <CollectionContent>
                    <TextContainer>{title}</TextContainer>

                    {box}
                </CollectionContent>
            </CollectionBoxLink>
        </Grid>
    );
};

const CollectionBoxes = ({ id, collections, isLink = false }) => {
    return (
        <Grid id={id} mobileSpacing={32} tabletSpacing={20} desktopSpacing={48}>
            {collections.map((collection, idx) => {
                const {
                    title = collection.name,
                    url = collection.url,
                    imageSrcAndTitle = getCollectionIcons({ collection }),
                    type = "secondary",
                    backgroundSize = "auto 100%",
                    backgroundPosition,
                    usePlaceholder = true,
                    isPhoto = false
                } = collection;

                const subtitle = collection.subtitle
                    ? collection.subtitle
                    : collection.icons
                    ? collection.icons.totalCount + " icons"
                    : null;

                return (
                    <BoxStructure
                        key={idx}
                        url={url}
                        type={type}
                        isPhoto={isPhoto}
                        isLink={isLink}
                        title={
                            <>
                                <BoxHeadingContainer>
                                    <Heading
                                        type="h5"
                                        size="sm"
                                        alignment="left"
                                    >
                                        {title}
                                    </Heading>
                                </BoxHeadingContainer>
                                {subtitle && (
                                    <BodyText
                                        type="p"
                                        size="lg"
                                        alignment="left"
                                    >
                                        {subtitle}
                                    </BodyText>
                                )}
                            </>
                        }
                        box={
                            <GraphicContainer
                                imageSrcAndTitle={imageSrcAndTitle}
                                backgroundSize={backgroundSize}
                                backgroundPosition={backgroundPosition}
                                usePlaceholder={usePlaceholder}
                            />
                        }
                    />
                );
            })}
        </Grid>
    );
};

CollectionBoxes.propTypes = {
    id: PropTypes.string,
    collections: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            url: PropTypes.string,
            imageSrcAndTitle: PropTypes.oneOfType([
                PropTypes.array,
                PropTypes.object
            ]),
            subtitle: PropTypes.string,
            type: PropTypes.oneOf(collectionsTypes),
            backgroundSize: PropTypes.string,
            backgroundPosition: PropTypes.string,
            usePlaceholder: PropTypes.bool,
            isPhoto: PropTypes.bool,
            showSkeleton: PropTypes.bool
        })
    ),
    isLink: PropTypes.bool
};

export default CollectionBoxes;
