import React from "react";
import PropTypes from "prop-types";

// context
import { usePanelOverlay } from "../usePanelOverlay";

// components
import Shadow from "./Shadow";
import CloseButton from "./CloseButton";

// styles
import { PanelStyled } from "./panelStyles";

// component
export const PanelOverlay = ({ children }) => {
    const { hasOpened, isOpen, position } = usePanelOverlay();
    return (
        <>
            <Shadow />
            <PanelStyled
                $isOpen={isOpen}
                $position={position}
                $hasOpened={hasOpened}
            >
                {children}
                <CloseButton />
            </PanelStyled>
        </>
    );
};

// prop-types
PanelOverlay.propTypes = {
    children: PropTypes.any
};
