import React from "react";
import PropTypes from "prop-types";

import { Figure } from "./styles";

const ImageContent = ({ image, imageAlt, imageCaption, imageLink }) => {
    const imageEl = <img src={image} alt={imageAlt} title={imageAlt} />;
    const imageElWithLink = <a href={imageLink}>{imageEl}</a>;

    return (
        <Figure>
            {imageLink ? imageElWithLink : imageEl}
            {imageCaption && <figcaption>{imageCaption}</figcaption>}
        </Figure>
    );
};

ImageContent.propTypes = {
    image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    imageCaption: PropTypes.string,
    imageAlt: PropTypes.string.isRequired
};

export default ImageContent;
