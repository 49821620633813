import { useApolloLazyQuery } from "frontend/utils/apolloApi/useApolloLazyQuery.js";

// utils
import {
    chunkedPromises,
    convertArrayToChunks,
    mergeArrays
} from "frontend/utils";

// queries
import { FETCH_PHOTO_COUNTS_FOR_BATCHES } from "frontend/components/fort/mod/photos/gql/queries";

export const ACTION_DESC = "Get Batch Photo Counts For Batches";
const CHUNK_COUNT = 50; // Chunk Requests Capped at 50 batchIds per request

export const useGetPhotoCountsForBatches = () => {
    const { apolloQuery } = useApolloLazyQuery({
        actionDesc: ACTION_DESC,
        gql: FETCH_PHOTO_COUNTS_FOR_BATCHES
    });

    const getPhotoCountsForBatches = async (
        batchIds,
        showOnlySuspendedCreators = false
    ) => {
        const batches = convertArrayToChunks(batchIds, CHUNK_COUNT);
        const responses = await chunkedPromises(
            batches,
            async chunkedBatchIds => {
                const resp = await apolloQuery({
                    variables: {
                        batchIds: chunkedBatchIds,
                        showOnlySuspendedCreators
                    },
                    fetchPolicy: "network-only"
                });

                return resp?.ssResp?.data?.counts || [];
            }
        );
        return mergeArrays(responses);
    };

    return {
        getPhotoCountsForBatches
    };
};
