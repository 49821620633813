import gql from "graphql-tag";

export const FETCH_PHOTO_SHOOT_TITLE = gql`
    query FetchPhotoShootTitle($photoShootId: String!) {
        photoShootDetail(photoShootId: $photoShootId) {
            id
            title
            __typename
        }
    }
`;
