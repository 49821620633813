/*---------------------------
| Action Types
---------------------------*/
const prefix = "Select:";

export const actionTypes = {
    UPDATE_UPLOAD_IDS: `${prefix} updateUploadIds`,
    TOGGLE_ID: `${prefix} toggleId`,
    SELECT_ALL: `${prefix} selectAll`,
    DESELECT_ALL: `${prefix} deselectAll`,
    SELECT_IDS: `${prefix} selectIds`,
    DESELECT_IDS: `${prefix} deselectIds`,
    SELECT_DESELECT_RANGE: `${prefix} selectDeselectRange`,
    SELECT_NEXT: `${prefix} selectNext`,
    SELECT_PREV: `${prefix} selectPrev`
};
