import styled from "styled-components";

// styles
import colors from "core/colors";
import { convertPxToRem } from "frontend/components/helpers";

// config
const PINNED_HEADER_HEIGHT = 68;
const PINNED_HEADER_HEIGHT_REM = convertPxToRem({ px: PINNED_HEADER_HEIGHT });

const PINNED_SIDEBAR_WIDTH = 268;
const PINNED_SIDEBAR_WIDTH_REM = convertPxToRem({ px: PINNED_SIDEBAR_WIDTH });

const MAIN_MIN_WIDTH = 1366 - PINNED_SIDEBAR_WIDTH;
const MAIN_MIN_WIDTH_REM = convertPxToRem({ px: MAIN_MIN_WIDTH });

// styled-components
export const QueueLayoutStyled = styled.div`
    overflow: hidden;

    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 1;

    background-color: #1e1e1e;
`;
export const QueueContainerStyled = styled.div`
    overflow: hidden;

    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 2;

    margin: auto;

    width: 100%;
    max-width: 1920px;

    background-color: white;
`;

export const QueueHeaderStyled = styled.header`
    height: ${PINNED_HEADER_HEIGHT_REM};

    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    z-index: 520;

    background-color: ${colors.primary.snow};
    border-bottom: solid 1px ${colors.primary.scheifer};
    box-shadow: 0px 2px 10px rgb(0 0 0 / 15%);
`;

export const QueueMainStyled = styled.main`
    position: absolute;
    top: ${PINNED_HEADER_HEIGHT_REM};
    right: ${PINNED_SIDEBAR_WIDTH_REM};
    left: 0px;
    bottom: 0px;
    z-index: 500;
    overflow: auto;
`;
export const QueueMainContainerStyled = styled.div`
    min-width: ${MAIN_MIN_WIDTH_REM};
`;

export const QueueAsideStyled = styled.aside`
    border-left: solid 1px ${colors.primary.scheifer};

    width: ${PINNED_SIDEBAR_WIDTH_REM};

    position: absolute;
    bottom: 0px;
    right: 0px;
    top: ${PINNED_HEADER_HEIGHT_REM};
    z-index: 510;

    overflow-y: auto;

    background-color: white;
`;
