import styled, { css } from "styled-components";

import { convertPxToRem } from "frontend/components/helpers";

export const GridItemContainer = styled.div.attrs(
    ({ top, left, height, width }) => ({
        style: {
            top: convertPxToRem({ px: top }),
            left: convertPxToRem({ px: left }),
            width: convertPxToRem({ px: width }),
            height: convertPxToRem({ px: height })
        }
    })
)`
    position: absolute;
`;

export const GridContainer = styled.div.attrs({
    "data-testid": "justified-grid"
})`
    position: relative;
    height: ${({ height }) => convertPxToRem({ px: height })};
`;
