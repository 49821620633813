import gql from "graphql-tag";

export const SUBMIT_PHOTO_UPLOADS_FOR_REVIEW = gql`
    mutation SubmitPhotoUploadsForReview {
        submitPhotoUploadsForReview(cancelPendingUploads: true) {
            ok
            errors {
                photoUploadId
                reason
            }
        }
    }
`;
