/*---------------------------
| Store
---------------------------*/
// Action Types
const actionPrefix = "SiteHeaderContext:";
export const actionTypes = {
    RESET_STATE_BY_PAGE: `${actionPrefix} Reset State By Page`,
    SET_SEARCH_BAR: `${actionPrefix} Set Search Bar`,
    SET_HEADER: `${actionPrefix} Set Header`,
    SET_ANIMATION: `${actionPrefix} Set Animation`
};

// Reducer
export const reducer = (state, action) => {
    switch (action.type) {
        case actionTypes.RESET_STATE_BY_PAGE: {
            // TODO: when using NextJS `router.push(searchUrl);`
            // it seems as though state gets replaced, unsure if this has something to do with router.push
            // Keeping this here for now in case we want to leverage this in the future.
            // console.log({ resetStateByPage: action.stateProps });
            return {
                ...state,
                ...action.stateProps
            };
        }
        case actionTypes.SET_SEARCH_BAR: {
            return {
                ...state,
                searchBar: action.searchBar
            };
        }
        case actionTypes.SET_HEADER: {
            return {
                ...state,
                header: action.header
            };
        }
        case actionTypes.SET_ANIMATION: {
            return {
                ...state,
                animation: action.animation
            };
        }
        default: {
            return { ...state };
        }
    }
};
