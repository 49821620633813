import { useDispatch } from "react-redux";

// rtk
import { largeImageViewSlice } from "../slices/largeImageViewSlice";

// hooks
import { useModal2 } from "core/Modal2";
import useBatchUploads from "./useBatchUploads";
import { useSelect } from "frontend/components/core/Grid2/GridItemContentTemplates/Select/SelectContext/index.js";

export const useLargeImageView = () => {
    const dispatch = useDispatch();
    const { showModal, hideModal } = useModal2();
    const { visibleUploadIds, getUploadEntity } = useBatchUploads();
    const { uploadsSelected = {}, getSelectedUploadIds } = useSelect();
    const selectedUploadIds = getSelectedUploadIds(uploadsSelected);
    const numberSelected = selectedUploadIds.length;

    // useSelect does not know the original sort order returned from upload fetch
    // this ensures we match the order of the Batch Upload Grid
    const sortedUploadIds = selectedUploadIds.slice().sort((a, b) => {
        const uploadEntityA = getUploadEntity(a);
        const uploadEntityB = getUploadEntity(b);

        if (uploadEntityA.order < uploadEntityB.order) {
            return -1;
        }
        if (uploadEntityA.order > uploadEntityB.order) {
            return 1;
        }
        return 0;
    });

    const openLargeView = uploadId => {
        let kind = "slideshow";
        let uploadIds = sortedUploadIds;
        let selectedUploadId = sortedUploadIds[0];

        if (numberSelected > 1) {
            kind = "gallery";
        } else {
            // None selected
            uploadIds = visibleUploadIds;
            selectedUploadId = uploadId; // use the uploadId clicked on
        }

        dispatch(
            largeImageViewSlice.actions.open({
                kind,
                uploadIds,
                selectedUploadId
            })
        );

        showModal();
    };

    const closeLargeView = () => {
        dispatch(largeImageViewSlice.actions.close());
        hideModal();
    };

    return {
        openLargeView,
        closeLargeView
    };
};
