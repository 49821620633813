import React from "react";
import PropTypes from "prop-types";

import PhotoUploadUploadStatus from "../PhotoUploadUploadStatus";

import { PhotoUploadFooterSectionContainer } from "./styles";

const PhotoUploadFooter = ({
    uploadCount = 0,
    failedUploadCount = 0,
    currentlyProcessingCount = 0,
    allUploadErrors
}) => {
    return (
        <PhotoUploadFooterSectionContainer>
            <PhotoUploadUploadStatus
                uploadsInDropzoneCount={uploadCount}
                allFailedUploadsCount={failedUploadCount}
                currentlyProcessingCount={currentlyProcessingCount}
                uploadErrors={allUploadErrors}
            />
        </PhotoUploadFooterSectionContainer>
    );
};

PhotoUploadFooter.propTypes = {
    uploadCount: PropTypes.number.isRequired,
    failedUploadCount: PropTypes.number.isRequired,
    currentlyProcessingCount: PropTypes.number.isRequired,
    allUploadErrors: PropTypes.array
};

export default PhotoUploadFooter;
