import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

export const PageMeta = ({
    title,
    useSocialMediaTags,
    previewImg,
    previewImgAlt,
    description,
    twitterTitle,
    twitterDescription,
    twitterCardContent,
    noindex,
    indexContent,
    structuredData,
    canonicalURL,
    prevPage,
    nextPage,
    children
}) => {
    // Remove seo-related noscript content
    useEffect(() => {
        const noscriptContent = document.getElementById("content_summary");
        if (noscriptContent) {
            noscriptContent.remove();
        }
    }, []);

    const canonical = canonicalURL
        ? canonicalURL
        : typeof window === "undefined"
        ? ""
        : window.location.origin + window.location.pathname;

    const robotsContent = indexContent ? indexContent : "index,follow";

    const metaDescription =
        description ||
        "The most diverse collection of icons ever. Download SVG and PNG. Millions of free icons or unlimited royalty-free icons with NounPro.";

    return useSocialMediaTags ? (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={metaDescription} />
            {structuredData && (
                <script
                    className="structured-data-list"
                    type="application/ld+json"
                >
                    {JSON.stringify(structuredData)}
                </script>
            )}
            {/* if we don't want to index, pass noindex prop */}
            {noindex ? (
                <meta name="robots" content="noindex" />
            ) : (
                <meta name="robots" content={robotsContent} />
            )}
            {!!canonical && <link rel="canonical" href={canonical} />}
            {!!prevPage && <link rel="prev" href={prevPage} />}
            {!!nextPage && <link rel="next" href={nextPage} />}
            {children}

            {/* Facebook Meta tags */}
            <meta property="og:image" content={`${previewImg}`} />
            <meta property="og:url" content={canonical} />
            <meta property="og:title" content={`${title}`} />
            <meta property="og:description" content={description} />
            <meta property="og:site_name" content="The Noun Project" />
            <meta property="og:type" content="website" />

            {/* Twitter meta tags */}
            <meta
                name="twitter:card"
                content={twitterCardContent ? twitterCardContent : "summary"}
            />
            <meta name="twitter:site" content="@nounproject" />
            <meta
                name="twitter:title"
                content={`${twitterTitle ? twitterTitle : title}`}
            />
            <meta
                name="twitter:description"
                content={twitterDescription ? twitterDescription : description}
            />
            <meta name="twitter:image:src" content={`${previewImg}`} />
            {!!previewImgAlt && (
                <meta name="twitter:image:alt" content={`${previewImgAlt}`} />
            )}
            <meta name="twitter:domain" content="https://thenounproject.com" />
        </Helmet>
    ) : (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={metaDescription} />
            {structuredData && (
                <script
                    className="structured-data-list"
                    type="application/ld+json"
                >
                    {JSON.stringify(structuredData)}
                </script>
            )}
            {/* if we don't want to index, pass noindex prop */}
            {noindex ? (
                <meta name="robots" content="noindex" />
            ) : (
                <meta name="robots" content={robotsContent} />
            )}
            <link rel="canonical" href={canonical} />
            {children}
        </Helmet>
    );
};

PageMeta.propTypes = {
    title: PropTypes.string.isRequired,
    useSocialMediaTags: PropTypes.bool,
    noindex: PropTypes.bool,
    previewImg: PropTypes.string,
    previewImgAlt: PropTypes.string,
    description: PropTypes.string,
    twitterTitle: PropTypes.string,
    twitterDescription: PropTypes.string,
    twitterCardContent: PropTypes.string,
    indexContent: PropTypes.string,
    canonicalURL: PropTypes.string,
    structuredData: PropTypes.object,
    children: PropTypes.any
};

export default PageMeta;
