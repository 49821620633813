import React from "react";
import classnames from "classnames";

class NounProjectLoading extends React.Component {
    render() {
        let classes = classnames("loading clearfix", this.props.className);
        return (
            <div className={classes}>
                <div className="dot dot1" />
                <div className="dot dot2" />
                <div className="dot dot3" />
                <div className="dot dot4" />
            </div>
        );
    }
}

export default NounProjectLoading;
